import { Reducer } from 'redux';
import {
  SEARCH_LEGAL_PERSON_SUCCESS,
  SEARCH_NATURAL_PERSON_ERROR,
  SEARCH_LEGAL_PERSON_REQUEST,
  RESET_SEARCH_NATURAL_PERSON_STATE,
  CREATE_LEGAL_FOREIGN_PERSON_ERROR,
  CREATE_LEGAL_FOREIGN_PERSON_REQUEST,
  CREATE_LEGAL_FOREIGN_PERSON_SUCCESS,
  RESET_CREATE_LEGAL_FOREIGN_PERSON_STATE,
  RESET_LEGAL_SEARCH_QUERY,
  SELECT_LEGAL_SEARCH_QUERY
} from './legalDataActionTypes';
import { LegalPersonSearchData, LegalPersonSearchQuery } from './legalDataTypes';

export interface LegalDataState {
  loadingLegalPersonSearchResults: boolean;
  legalPersonSearchResults?: LegalPersonSearchData[];
  legalPersonSearchResultsError?: Error;
  creatingLegalForeignPerson: boolean;
  legalForeignPersonCreated: boolean;
  creatingLegalForeignPersonError?: Error;

  selectedLegalPersonSearchQuery?: LegalPersonSearchQuery;
}

export const initialState: LegalDataState = {
  loadingLegalPersonSearchResults: false,
  legalPersonSearchResults: undefined,
  legalPersonSearchResultsError: undefined,
  creatingLegalForeignPerson: false,
  legalForeignPersonCreated: false,
  creatingLegalForeignPersonError: undefined,

  selectedLegalPersonSearchQuery: undefined
};

export const legalDataReducer: Reducer<LegalDataState> = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_LEGAL_PERSON_REQUEST:
      return { ...state, loadingLegalPersonSearchResults: true };
    case SEARCH_LEGAL_PERSON_SUCCESS:
      return {
        ...state,
        loadingLegalPersonSearchResults: false,
        legalPersonSearchResults: action.payload
      };
    case SEARCH_NATURAL_PERSON_ERROR:
      return {
        ...state,
        loadingLegalPersonSearchResults: false,
        legalPersonSearchResultsError: action?.payload?.data
      };
    case RESET_SEARCH_NATURAL_PERSON_STATE:
      return {
        ...state,
        loadingLegalPersonSearchResults: false,
        legalPersonSearchResults: undefined,
        legalPersonSearchResultsError: undefined
      };
    case CREATE_LEGAL_FOREIGN_PERSON_REQUEST:
      return { ...state, creatingLegalForeignPerson: true };
    case CREATE_LEGAL_FOREIGN_PERSON_SUCCESS:
      return {
        ...state,
        creatingLegalForeignPerson: false,
        legalForeignPersonCreated: true
      };
    case CREATE_LEGAL_FOREIGN_PERSON_ERROR:
      return {
        ...state,
        creatingLegalForeignPerson: false,
        creatingLegalForeignPersonError: action?.payload?.data
      };
    case RESET_CREATE_LEGAL_FOREIGN_PERSON_STATE:
      return {
        ...state,
        creatingLegalForeignPerson: false,
        legalForeignPersonCreated: false,
        creatingLegalForeignPersonError: undefined
      };
    case SELECT_LEGAL_SEARCH_QUERY:
      return {
        ...state,
        selectedLegalPersonSearchQuery: action.payload
      };
    case RESET_LEGAL_SEARCH_QUERY:
      return {
        ...state,
        selectedLegalPersonSearchQuery: undefined
      };
    default:
      return state;
  }
};
