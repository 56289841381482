import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  annulEthicalNonComplianceRecord,
  deleteRemoveEthicalNonCompliance,
  getEthicalNonComplianceDataByDocId,
  getEthicalNonComplianceDataPage,
  getEthicalNonComplianceDataVirs,
  getEthicalNonComplianceDocument,
  postCreateEthicalNonCompliance,
  putUpdateEthicalNonCompliance
} from './ethicalNonCompliancesApi';
import {
  annulEthicalNonCompliance,
  annulEthicalNonComplianceError,
  annulEthicalNonComplianceSuccess,
  createEthicalNonCompliance,
  createEthicalNonComplianceError,
  createEthicalNonComplianceSuccess,
  fetchEthicalNonComplianceByDocId,
  fetchEthicalNonComplianceData,
  fetchEthicalNonComplianceDataError,
  fetchEthicalNonComplianceDataSuccess,
  fetchVirsEthicalNonComplianceData,
  fetchVirsEthicalNonComplianceDataError,
  fetchVirsEthicalNonComplianceDataSuccess,
  getVirsVirsEthicalNonComplianceDocumentInNewTabRequest,
  removeEthicalNonCompliance,
  removeEthicalNonComplianceError,
  removeEthicalNonComplianceSuccess,
  resetAnnulEthicalNonComplianceState,
  resetEthicalNonComplianceRemovingState,
  resetEthicalNonComplianceUpdatingState,
  updateEthicalNonCompliance,
  updateEthicalNonComplianceError,
  updateEthicalNonComplianceSuccess
} from './ethicalNonCompliancesActions';
import {
  ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST,
  CREATE_ETHICAL_NON_COMPLIANCE_REQUEST,
  FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID,
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DOCUMENT_PDF_REQUEST,
  REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST,
  UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST
} from './ethicalNonCompliancesActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  getEthicalNonComplianceInstPageRequest,
  selectEthicalNonComplianceTableDataState
} from '../ethicalNonComplianceTable/ethicalNonComplienceTableUtilities';
import { EthicalNonComplianceDataTableState } from '../ethicalNonComplianceTable/ethicalNonComplianceTableReducer';
import { updateTempDataState } from '../ethicalNonComplianceTable/ethicalNonComplianceTableAction';

function* handleEthicalNonComplianceDataFetchSaga() {
  try {
    const { tableDataState }: EthicalNonComplianceDataTableState = yield select(
      selectEthicalNonComplianceTableDataState
    );
    yield put(updateTempDataState(tableDataState));
    const { data } = yield call(
      getEthicalNonComplianceDataPage,
      getEthicalNonComplianceInstPageRequest(tableDataState)
    );
    yield put(fetchEthicalNonComplianceDataSuccess(data));
  } catch (err) {
    yield put(fetchEthicalNonComplianceDataError(err));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* handleCreateEthicalNonComplianceSaga(
  action: ActionType<typeof createEthicalNonCompliance>
) {
  try {
    const { newEthicalNonCompliance } = action.payload;
    yield call(postCreateEthicalNonCompliance, newEthicalNonCompliance);
    yield put(fetchEthicalNonComplianceData());
    yield put(createEthicalNonComplianceSuccess());
    yield put(sendMessage('success', 'Profesinės etikos nesilaikymo įrašas išsaugotas'));
  } catch (err) {
    yield put(createEthicalNonComplianceError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateEthicalNonComplianceSaga(
  action: ActionType<typeof updateEthicalNonCompliance>
) {
  try {
    const { updatedEthicalNonCompliance, id } = action.payload;
    yield call(putUpdateEthicalNonCompliance, updatedEthicalNonCompliance, id);
    yield put(fetchEthicalNonComplianceData());
    yield put(resetEthicalNonComplianceUpdatingState());
    yield put(updateEthicalNonComplianceSuccess());
    yield put(sendMessage('success', 'Profesinės etikos nesilaikymo įrašas atnaujintas'));
  } catch (err) {
    yield put(updateEthicalNonComplianceError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleRemoveEthicalNonComplianceSaga(
  action: ActionType<typeof removeEthicalNonCompliance>
) {
  try {
    const { ethicalNonComplianceId } = action.payload;
    yield call(deleteRemoveEthicalNonCompliance, ethicalNonComplianceId);
    yield put(removeEthicalNonComplianceSuccess());
    yield put(fetchEthicalNonComplianceData());
    yield put(resetEthicalNonComplianceRemovingState());
    yield put(sendMessage('success', 'Profesinės etikos nesilaikymo įrašas pašalintas'));
  } catch (err) {
    yield put(removeEthicalNonComplianceError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleAnnulEthicalNonComplianceSaga(
  action: ActionType<typeof annulEthicalNonCompliance>
) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulEthicalNonComplianceRecord, documentStatusId, annulRecord);
    yield put(annulEthicalNonComplianceSuccess());
    yield put(fetchEthicalNonComplianceData());
    yield put(resetAnnulEthicalNonComplianceState());
    yield put(sendMessage('success', 'Profesinės etikos nesilaikymo įrašas anuliuotas'));
  } catch (err) {
    yield put(annulEthicalNonComplianceError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleVirsEthicalNonComplianceDataFetchSaga(
  action: ActionType<typeof fetchVirsEthicalNonComplianceData>
) {
  try {
    const virsId = action.payload;
    const { data } = yield call(getEthicalNonComplianceDataVirs, virsId);
    yield put(fetchVirsEthicalNonComplianceDataSuccess(data.ethicalNonCompliancesData));
  } catch (err) {
    yield put(fetchVirsEthicalNonComplianceDataError(err));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleVirsEthicalNonComplianceDocumentRequestSaga(
  action: ActionType<typeof getVirsVirsEthicalNonComplianceDocumentInNewTabRequest>
) {
  try {
    const { documentId, documentNumber } = action.payload;
    const { data } = yield call(getEthicalNonComplianceDocument, documentId);
    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]), `${documentNumber}.pdf`);
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleEthicalNonComplianceDataByDocIdFetchSaga(
  action: ActionType<typeof fetchEthicalNonComplianceByDocId>
) {
  try {
    const { data } = yield call(getEthicalNonComplianceDataByDocId, action.payload);
    yield put(fetchEthicalNonComplianceDataSuccess(data));
  } catch (err) {
    yield put(fetchEthicalNonComplianceDataError(err));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* ethicalNonCompliancesSaga() {
  yield takeLatest(
    FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
    handleEthicalNonComplianceDataFetchSaga
  );
  yield takeEvery(CREATE_ETHICAL_NON_COMPLIANCE_REQUEST, handleCreateEthicalNonComplianceSaga);
  yield takeEvery(UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST, handleUpdateEthicalNonComplianceSaga);
  yield takeEvery(REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST, handleRemoveEthicalNonComplianceSaga);
  yield takeEvery(
    FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
    handleVirsEthicalNonComplianceDataFetchSaga
  );
  yield takeEvery(
    FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DOCUMENT_PDF_REQUEST,
    handleVirsEthicalNonComplianceDocumentRequestSaga
  );
  yield takeEvery(ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST, handleAnnulEthicalNonComplianceSaga);
  yield takeEvery(
    FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID,
    handleEthicalNonComplianceDataByDocIdFetchSaga
  );
}

export default ethicalNonCompliancesSaga;
