import React from 'react';
import { CheckboxField } from './CheckboxField/CheckboxField';
import { Strings } from '../../../../../../../utils/strings/Strings';

interface Props {
  disabled?: boolean;
}

export const EndOfParticipationField: React.FC<Props> = ({ disabled }) => {
  return (
    <CheckboxField
      name="endOfParticipation"
      type="checkbox"
      value={Strings.shareholder__notParticipating}
      disabled={disabled}
    />
  );
};
