import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  virsName: string;
  virsId: number;
  virsLegalCode?: number;
}

export const VirsNameLink: React.FC<Props> = ({ virsName, virsId, virsLegalCode }) => {
  if (!virsName) {
    return null;
  }
  return (
    <>
      <Link
        to={`/virs-duomenys/${virsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`}
        target="_blank"
      >
        {virsName}
      </Link>
      {virsLegalCode && <Typography>{virsLegalCode}</Typography>}
    </>
  );
};
