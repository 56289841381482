import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { GenericBooleanField } from '../../../components/FormikFields/GenericFormikWrappers/GenericBooleanField';
import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';
import { GenericTextField } from '../../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';
import { defaultDate } from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import { resetForm, State, submitForm } from './store';
import { defaultValues, MessageTypeInput } from './types';

const FormContent: React.FC<FormikProps<MessageTypeInput>> = (formikProps) => {
  const {
    values: { messageTypeId, messageTypeName }
  } = formikProps;
  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{messageTypeId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Pranešimo tipas" spacing="1em">
        <Typography variant="h4">{messageTypeName}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Ar siunčiama" spacing="1em">
        <GenericBooleanField label="" field="enabled" formikProps={formikProps} />
      </GridFieldGroup>

      <GridFieldGroup label="Laiško antraštė" spacing="1em">
        <GenericTextField
          label=""
          field="subject"
          formikProps={formikProps}
          style={{ container: { minWidth: '500px' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Laiško šablonas" spacing="1em">
        <GenericTextField
          label=""
          field="body"
          formikProps={formikProps}
          style={{ container: { minWidth: '500px' } }}
          multiline
          rowsCount={10}
          maxLength={65535}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja nuo" spacing="1em">
        <GenericDateField
          label=""
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const MessageTypeForm: React.FC = () => {
  const validationSchema = Yup.object().shape({
    messageTypeId: Yup.number().required(),
    messageTypeName: Yup.string().required(),
    enabled: Yup.boolean().required(),
    subject: Yup.string()
      .required('Privaloma įvesti')
      .max(500, 'Negali būti ilgesnis nei 500 simbolių'),
    body: Yup.string().required('Privaloma įvesti'),
    validFrom: defaultDate().required('Privaloma pasirinkti datą')
  });
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error } = useSelector((state: State) => state.form);

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="Siunčiamo pranešimo įvedimas"
    />
  );
};
