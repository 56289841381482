import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { saga } from './saga';

import { getOptionedTableReducer } from '../GenericClassifierTable/genericReducers/optionedTableReducer';
import { OutletType, OutletTypeInput, OutletTypeOptions } from './types';
import { FormSagaContext } from '../../../components/FormikFields/GenericFormikWrappers/types';

export const {
  loadData,
  resetForm,
  setData,
  submitForm,
  setLoading,
  setFormOpen,
  setFormLoading,
  setFormError,
  setError,
  setFormOptions,
  initialState,
  reducer
} = getOptionedTableReducer<OutletType, OutletTypeInput, OutletTypeOptions>({
  outletGroups: [],
  enterpriseTypes: []
});

export function getOutletTypeStore(context: FormSagaContext) {
  const middleware = createSagaMiddleware({ context });
  const store = createStore(reducer, applyMiddleware(middleware));
  middleware.run(saga);
  return store;
}

export type State = typeof initialState;
