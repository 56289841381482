import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEditionCheckData } from '../../store/editionChecks/editionChecksActions';
import { EditionCheckDataTableWithContext } from '../../containers/EditionCheckTableInst/Context';

import { ApplicationState } from '../../store';
import {
  fetchAnnulmentTypesRequest,
  fetchEnterpriseTypesRequest
} from '../../store/classifiers/classifiersActions';

const InstEditionCheckPage: React.FC = () => {
  const reduxDispatch = useDispatch();

  const { documentStatusId } = useParams<{ documentStatusId?: string }>();
  const { annulmentTypes, enterpriseTypes } = useSelector(
    (state: ApplicationState) => state.classifiers
  );

  useEffect(() => {
    if (!documentStatusId) reduxDispatch(fetchEditionCheckData());
  }, [reduxDispatch, documentStatusId]);

  useEffect(() => {
    if (!enterpriseTypes) {
      reduxDispatch(fetchEnterpriseTypesRequest());
    }
  }, [reduxDispatch, enterpriseTypes]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  return (
    <div className="data-page">
      <div className="data-page-content">
        <EditionCheckDataTableWithContext filterByDocumentId={documentStatusId} />
      </div>
    </div>
  );
};

export default withRouter(InstEditionCheckPage);
