interface PartialMatch {
  index: number;
  length: number;
}

export function getPartialMatches(term: string, text: string, minLenght = 3): PartialMatch[] {
  return Array.from(text.matchAll(term.slice(0, minLenght) as any))
    .map(({ index }) => (index !== undefined ? index : Infinity))
    .map((index) => {
      return {
        index,
        length: Array.from(text.slice(index, index + term.length)).reduce(
          (prev, current, i) => (term[i] === current ? prev + 1 : prev),
          0
        )
      };
    });
}

export function getSearchTermDistance(term: string, text: string, partialOffset: number): number {
  const termLower = term.toLowerCase();
  const textLower = text.toLowerCase();

  if (termLower === textLower) {
    return -1;
  }

  const termIndex = textLower.indexOf(termLower);
  if (termIndex >= 0) {
    return termIndex;
  }

  const longestPartialMatch = getPartialMatches(termLower, textLower).reduce(
    (prev, current) => (prev ? (current.length > prev.length ? current : prev) : current),
    undefined as PartialMatch | undefined
  );

  if (longestPartialMatch) {
    return (
      partialOffset + (termLower.length - longestPartialMatch.length) * longestPartialMatch.index
    );
  }

  return Infinity;
}
