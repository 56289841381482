import React, { useContext, createContext, Dispatch, useReducer } from 'react';
import { AuditsTable } from './Table';
import {
  AuditsTableAction,
  AuditsTableState,
  initialAuditsTableState
} from './tableState/tableInitialStateAndTypes';
import { auditsTableReducer } from './tableState/tableReducer';

const AuditsTableStateContext = createContext<{
  state: AuditsTableState;
}>({
  state: initialAuditsTableState
});

const AuditsTableDispatchContext = createContext<{
  dispatch: Dispatch<AuditsTableAction>;
}>({
  dispatch: (action) => action
});

export const AuditsTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(auditsTableReducer, initialAuditsTableState);
  return (
    <AuditsTableStateContext.Provider value={{ state }}>
      <AuditsTableDispatchContext.Provider value={{ dispatch }}>
        <AuditsTable />
      </AuditsTableDispatchContext.Provider>
    </AuditsTableStateContext.Provider>
  );
};

export function useAuditsTableState() {
  return useContext(AuditsTableStateContext);
}
export function useAuditsTableDispatch() {
  return useContext(AuditsTableDispatchContext);
}
