import MomentUtils from '@date-io/moment';
import { Box, Button, FormGroup, makeStyles, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { FormikFormSelectField } from '../FormikFields/FormikFormSelectField';
import {
  fetchVirsisReceivesFromSystemRequest,
  fetchVirsisSendsToSystemRequest,
  fetchIntegratedSystemsAuditsDataRequest
} from '../../store/audits/auditsActions';
import { ExternalSytemInfoData } from '../../store/audits/auditsTypes';
import { clearFormikForm } from '../../utils/InputValueFunctions';
import { IntegrationalSystemUsageAuditDateAndTimeFields } from './IntegrationalSystemUsageAuditDateAndTimeFields';
import { getAuditsDateTimeFrom, getAuditsDateTimeTo } from '../../utils/tableDataFunctions';

export interface Values {
  systemDataIsReceivedFrom: ExternalSytemInfoData;
  systemDataIsSentTo: ExternalSytemInfoData;
  requestDateFrom: string | null;
  requestTimeFrom: string | null;
  requestDateTo: string | null;
  requestTimeTo: string | null;
  responseDateFrom: string | null;
  responseTimeFrom: string | null;
  responseDateTo: string | null;
  responseTimeTo: string | null;
}

const useFormStyles = makeStyles((theme) => ({
  inputLabel: {
    margin: 'auto',
    width: 330,
    lineHeight: '25px',
    textAlign: 'right'
  },
  inputContainer: {
    margin: 4,
    display: 'grid',
    gridTemplateColumns: '330px 500px 30px',
    gap: '12px',
    '& button': {
      padding: '4px'
    }
  },
  button: {
    height: '34px',
    width: '100px',
    float: 'right',
    marginRight: '131px'
  },
  formGroup: {
    maxWidth: 'fit-content'
  },
  buttonBox: {
    marginTop: 20
  },
  [theme.breakpoints.down('sm')]: {
    formGroup: {
      maxWidth: 'unset'
    },
    inputLabel: {
      width: '100%',
      textAlign: 'left'
    },
    selectInput: {
      flex: 1,
      minWidth: 'unset'
    },
    textField: {
      width: '100%',
      minWidth: 'unset'
    },
    inputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 5,
      width: '100%'
    },
    buttonBox: {
      display: 'flex',
      gap: 10,
      margin: 4
    },
    button: {
      width: '100%',
      marginRight: '0 !important',
      marginLeft: 0
    }
  }
}));

export const IntegrationalSytemUsageAuditSearchForm = () => {
  const classes = useFormStyles();
  const reduxDispatch = useDispatch();

  const {
    auditsData: { sendsToSystemData, receivesFromsSystemData, loadingIntegratedSystemsAudits }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    if (!sendsToSystemData) reduxDispatch(fetchVirsisSendsToSystemRequest());
  }, [reduxDispatch, sendsToSystemData]);

  useEffect(() => {
    if (!receivesFromsSystemData) reduxDispatch(fetchVirsisReceivesFromSystemRequest());
  }, [reduxDispatch, receivesFromsSystemData]);

  const initValues: Partial<Values> = {
    systemDataIsReceivedFrom: undefined,
    systemDataIsSentTo: undefined,
    requestDateFrom: null,
    requestTimeFrom: null,
    requestDateTo: null,
    requestTimeTo: null,
    responseDateFrom: null,
    responseTimeFrom: null,
    responseDateTo: null,
    responseTimeTo: null
  };

  const validationSchema = Yup.object().shape({
    systemDataIsReceivedFrom: Yup.object().shape({
      systemName: Yup.string()
    }),
    systemDataIsSentTo: Yup.object().shape({
      systemName: Yup.string()
    })
  });

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        const {
          systemDataIsSentTo,
          systemDataIsReceivedFrom,
          requestDateFrom,
          requestTimeFrom,
          requestDateTo,
          requestTimeTo,
          responseDateFrom,
          responseTimeFrom,
          responseDateTo,
          responseTimeTo
        } = values;

        reduxDispatch(
          fetchIntegratedSystemsAuditsDataRequest({
            systemDataIsSentTo: systemDataIsSentTo ? systemDataIsSentTo.systemName : null,
            systemDataIsReceivedFrom: systemDataIsReceivedFrom
              ? systemDataIsReceivedFrom.systemName
              : null,
            requestDateTimeFrom: getAuditsDateTimeFrom(requestDateFrom, requestTimeFrom),
            requestDateTimeTo: getAuditsDateTimeTo(requestDateTo, requestTimeTo),

            responseDateTimeFrom: getAuditsDateTimeFrom(responseDateFrom, responseTimeFrom),
            responseDateTimeTo: getAuditsDateTimeTo(responseDateTo, responseTimeTo)
          })
        );
      }}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Form>
              <FormGroup className={classes.formGroup}>
                <FormikFormSelectField
                  isClearable
                  getOptionLabel={(option: ExternalSytemInfoData) => option.systemName}
                  formikKey="systemDataIsReceivedFrom"
                  value={values.systemDataIsReceivedFrom}
                  errors={errors.systemDataIsReceivedFrom}
                  options={receivesFromsSystemData || []}
                  isRequired={false}
                  styledClasses={classes}
                  label="Sistema, iš kurios gaunami duomenys"
                  setFieldValue={setFieldValue}
                />
                <FormikFormSelectField
                  isClearable
                  getOptionLabel={(option: ExternalSytemInfoData) => option.systemName}
                  formikKey="systemDataIsSentTo"
                  value={values.systemDataIsSentTo}
                  errors={errors.systemDataIsSentTo}
                  options={sendsToSystemData || []}
                  isRequired={false}
                  styledClasses={classes}
                  label="Sistema, į kurią siunčiami duomenys"
                  setFieldValue={setFieldValue}
                />

                <IntegrationalSystemUsageAuditDateAndTimeFields
                  labelText="Užklausos data ir laikas"
                  dateFromFieldName="requestDateFrom"
                  timeFromFieldName="requestTimeFrom"
                  dateToFieldName="requestDateTo"
                  timeToFieldName="requestTimeTo"
                  setFieldValue={setFieldValue}
                  dateFrom={values.requestDateFrom}
                  timeFrom={values.requestTimeFrom}
                  dateTo={values.requestDateTo}
                  timeTo={values.requestTimeTo}
                />

                <IntegrationalSystemUsageAuditDateAndTimeFields
                  labelText="Atsakymo data ir laikas"
                  dateFromFieldName="responseDateFrom"
                  timeFromFieldName="responseTimeFrom"
                  dateToFieldName="responseDateTo"
                  timeToFieldName="responseTimeTo"
                  setFieldValue={setFieldValue}
                  dateFrom={values.responseDateFrom}
                  timeFrom={values.responseTimeFrom}
                  dateTo={values.responseDateTo}
                  timeTo={values.responseTimeTo}
                />

                <Box className={classes.buttonBox}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      isValidating ||
                      loadingIntegratedSystemsAudits ||
                      (!values.systemDataIsReceivedFrom &&
                        !values.systemDataIsSentTo &&
                        !values.requestDateFrom &&
                        !values.requestTimeFrom &&
                        !values.requestDateTo &&
                        !values.requestTimeTo &&
                        !values.responseDateFrom &&
                        !values.responseTimeFrom &&
                        !values.responseDateTo &&
                        !values.responseTimeTo)
                    }
                    onClick={submitForm}
                    className={classes.button}
                    startIcon={<SearchIcon />}
                  >
                    <Typography color="inherit" variant="h5">
                      Ieškoti
                    </Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    disabled={isValidating}
                    onClick={() => clearFormikForm(setFieldValue, values)}
                    className={classes.button}
                    style={{ marginRight: '4px' }}
                    startIcon={<ClearIcon />}
                  >
                    <Typography color="inherit">Išvalyti</Typography>
                  </Button>
                </Box>
              </FormGroup>
            </Form>
          </MuiPickersUtilsProvider>
        );
      }}
    </Formik>
  );
};
