import React, { useContext } from 'react';
import { TableRow, TableCell, Typography, TableHead, TableSortLabel } from '@material-ui/core';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import { useDispatch, useSelector } from 'react-redux';
import { EditionCheckDataTableStateContext } from './Context';
import { calculateEditionCheckColSpan } from './tableState/tableStateFunctions';
import { EditionCheckDataTableField } from './tableState/tableTypesAndActions';
import { sortingChanged } from '../../store/editionChecksTable/editionChecksTableActions';
import { ApplicationState } from '../../store';

export const EditionCheckDataTableHeader: React.FC = () => {
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const reduxDispatch = useDispatch();

  const { tableDataState } = useSelector((state: ApplicationState) => state.editionDataTable);

  const setSorting = (column: EditionCheckDataTableField) => {
    reduxDispatch(sortingChanged(column));
  };

  const createSortHandler = (column: EditionCheckDataTableField) => () => {
    setSorting(column);
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.virsName && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={tableDataState.sortBy === 'virsName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('virsName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'virsName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'virsName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIRS</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(tableState.columnsDisplayStatus.checkDate || tableState.columnsDisplayStatus.docNr) && (
          <TableCell
            align="left"
            colSpan={calculateEditionCheckColSpan(tableState.columnsDisplayStatus)}
            variant="head"
          >
            <Typography variant="h4">Tiražo tikrinimo</Typography>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.outletName && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={tableDataState.sortBy === 'outletName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('outletName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'outletName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'outletName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Susijusios VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.periodName && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={tableDataState.sortBy === 'periodName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('periodName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'periodName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'periodName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Tiražo laikotarpis</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.conclusion && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={tableDataState.sortBy === 'conclusion' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('conclusion')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'conclusion' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'conclusion' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Išvada dėl patikrinto tiražo atitikties</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell align="left" rowSpan={2} variant="head" className="column-of-buttons">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
        <TableCell align="left" rowSpan={2} variant="head" className="column-of-buttons">
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.checkDate && (
          <TableCell
            align="left"
            variant="head"
            sortDirection={tableDataState.sortBy === 'checkDate' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('checkDate')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'checkDate' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'checkDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Data</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.docNr && (
          <TableCell
            align="left"
            variant="head"
            sortDirection={tableDataState.sortBy === 'docNr' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('docNr')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'docNr' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'docNr' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nr.</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
