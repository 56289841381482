import { ProfessionalMisconductDataInst } from '../../../store/professionalMisconducts/professionalMisconductsTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import {
  ColumnDisplayStatus,
  ColumnHeader,
  TableCustomFilter,
  TableGenericFilterOption
} from '../../../utils/tableTypes';
import { columnNames } from '../Table';
import { getTableExportDefinition } from './misconductTableFunctions';

export const initialColumnDisplay: ProfessionalMisconductInstColumnsDisplayStatus = {
  virsName: true,
  decisionDate: true,
  documentNumber: true,
  outletName: true,
  professionalMisconduct: true,
  sanction: true,
  validFrom: true,
  validTo: true
};

export const initialProfessionalMisconductInstTableState: ProfessionalMisconductInstTableState = {
  columnsDisplayStatus: initialColumnDisplay,
  customFilterOn: false,
  showNewRecord: false,
  companyCode: null,
  tableExportDefinition: getTableExportDefinition(columnNames, initialColumnDisplay)
};

export type ProfessionalMisconductInstTableField =
  | 'virsName'
  | 'decisionDate'
  | 'documentNumber'
  | 'outletName'
  | 'professionalMisconduct'
  | 'sanction'
  | 'validFrom'
  | 'validTo';

export type ProfessionalMisconductInstColumnsDisplayStatus =
  ColumnDisplayStatus<ProfessionalMisconductInstTableField>;

export type ProfessionalMisconductInstCustomFilter =
  TableCustomFilter<ProfessionalMisconductInstTableField>;

export type ProfessionalMisconductFilterOptions =
  TableGenericFilterOption<ProfessionalMisconductInstTableField>;

export type ProfessionalMisconductColumnHeader = ColumnHeader<ProfessionalMisconductInstTableField>;

export interface ProfessionalMisconductInstTableState {
  columnsDisplayStatus: ProfessionalMisconductInstColumnsDisplayStatus;
  customFilterOn: boolean;
  showNewRecord: boolean;
  companyCode: string | null;
  tableExportDefinition: TableExportDefinition<ProfessionalMisconductDataInst>;
}

export type ProfessionalMisconductInstTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: ProfessionalMisconductInstTableField;
    }
  | { type: 'CREATE_RECORD_CLICKED'; companyCode: string | null }
  | { type: 'CLOSE_CREATING_RECORD_CLICKED' }
  | { type: 'RESET_COMPANY_CODE' };
