import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles({
  redColor: { color: 'red' }
});

export const CloseIconButton: React.FC<Props> = ({ onClick }) => {
  const { redColor } = useStyles();
  return (
    <IconButton className={redColor} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};
