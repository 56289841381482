import React, { ReactNode } from 'react';
import { TableCell } from '@material-ui/core/';
import { EditionColumnsDisplayStatus } from '../../tablesState/editionTableTypes';
import { RowStyleOnDifferentStatus } from '../../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../../components/Tooltips/TooltipOnRowRibbon';

interface Props {
  columnsToDisplay: EditionColumnsDisplayStatus;
  periodic: boolean;
  first?: ReactNode;
  firstInput?: ReactNode;
  second?: ReactNode;
  secondInput?: ReactNode;
  third?: ReactNode;
  thirdInput?: ReactNode;
  actions?: ReactNode;
  rowStatus?: string;
  id: string;
}

export const EditionRowBasic: React.FC<Props> = ({
  columnsToDisplay,
  periodic,
  first,
  firstInput,
  second,
  secondInput,
  third,
  thirdInput,
  actions,
  rowStatus,
  id
}) => {
  return (
    <RowStyleOnDifferentStatus id={id} status={rowStatus}>
      {columnsToDisplay.periodName && periodic && !firstInput && (
        <TooltipOnRowRibbon status={rowStatus}>
          <TableCell>{first} </TableCell>
        </TooltipOnRowRibbon>
      )}
      {columnsToDisplay.periodName && periodic && !first && firstInput}
      {columnsToDisplay.editionDate && !periodic && !secondInput && (
        <TooltipOnRowRibbon status={rowStatus}>
          <TableCell>{second} </TableCell>
        </TooltipOnRowRibbon>
      )}
      {columnsToDisplay.editionDate && !periodic && secondInput && secondInput}
      {columnsToDisplay.circulation && !thirdInput && <TableCell>{third} </TableCell>}
      {columnsToDisplay.circulation && thirdInput && thirdInput}
      <TableCell align="right" scope="row">
        {actions}
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
