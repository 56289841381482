import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { RowActionButtonGeneric } from '../TableButtons/RowActionButtons/RowActionButtonGeneric';

interface PopoverProps {
  onButtonClick: () => void;
  isProcessing?: boolean;
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
}

export const RowActionButton: React.FC<PopoverProps> = ({
  onButtonClick,
  isProcessing,
  label,
  icon,
  disabled
}) => {
  return (
    <>
      {label && (
        <RowActionButtonGeneric
          label={isProcessing ? '' : label}
          onButtonClicked={onButtonClick}
          disabled={isProcessing || disabled}
          icon={
            isProcessing ? (
              <CircularProgress style={{ width: '20px', height: '20px' }} color="secondary" />
            ) : (
              icon || <></>
            )
          }
        />
      )}
    </>
  );
};
