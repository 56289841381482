import { Reducer } from 'redux';
import { OutletEditionPeriod, OutletInfo } from './outletInfoDataTypes';
import {
  FETCH_OUTLET_INFO_DATA_REQUEST,
  FETCH_OUTLET_INFO_DATA_SUCCESS,
  FETCH_OUTLET_INFO_DATA_ERROR,
  CREATE_EDITION_REQUEST,
  CREATE_EDITION_SUCCESS,
  CREATE_EDITION_ERROR,
  RESET_CREATE_EDITION_STATE,
  UPDATE_EDITION_REQUEST,
  UPDATE_EDITION_SUCCESS,
  UPDATE_EDITION_ERROR,
  RESET_UPDATE_EDITION_STATE,
  REMOVE_EDITION_ERROR,
  REMOVE_EDITION_SUCCESS,
  REMOVE_EDITION_REQUEST,
  RESET_REMOVE_EDITION_STATE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  RESET_CREATE_CATEGORY_STATE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  RESET_UPDATE_CATEGORY_STATE,
  REMOVE_CATEGORY_REQUEST,
  REMOVE_CATEGORY_SUCCESS,
  REMOVE_CATEGORY_ERROR,
  RESET_REMOVE_CATEGORY_STATE,
  RESET_REMOVE_REPRESENTATIVE_STATE,
  REMOVE_REPRESENTATIVE_ERROR,
  REMOVE_REPRESENTATIVE_SUCCESS,
  REMOVE_REPRESENTATIVE_REQUEST,
  RESET_UPDATE_REPRESENTATIVE_STATE,
  UPDATE_REPRESENTATIVE_ERROR,
  UPDATE_REPRESENTATIVE_SUCCESS,
  UPDATE_REPRESENTATIVE_REQUEST,
  RESET_CREATE_REPRESENTATIVE_STATE,
  CREATE_REPRESENTATIVE_ERROR,
  CREATE_REPRESENTATIVE_REQUEST,
  CREATE_REPRESENTATIVE_SUCCESS,
  SET_REPRESENTATIVE_FOR_NEW_RECORD,
  RESET_REPRESENTATIVE_FOR_NEW_RECORD,
  CONTINUE_ADDING_REPRESENTATIVE_CLICKED,
  CANCEL_ADDING_REPRESENTATIVE_CLICKED,
  CREATE_ADVERTISEMENT_REQUEST,
  CREATE_ADVERTISEMENT_SUCCESS,
  CREATE_ADVERTISEMENT_ERROR,
  RESET_CREATE_ADVERTISEMENT_STATE,
  UPDATE_ADVERTISEMENT_REQUEST,
  UPDATE_ADVERTISEMENT_SUCCESS,
  UPDATE_ADVERTISEMENT_ERROR,
  RESET_UPDATE_ADVERTISEMENT_STATE,
  RESET_REMOVE_ADVERTISEMENT_STATE,
  REMOVE_ADVERTISEMENT_REQUEST,
  REMOVE_ADVERTISEMENT_SUCCESS,
  REMOVE_ADVERTISEMENT_ERROR,
  FETCH_MISSING_PERIODS_REQUEST,
  FETCH_MISSING_PERIODS_SUCCESS,
  RESET_MISSING_PERIODS,
  FETCH_MISSING_PERIODS_ERROR
} from './outletInfoDataActionTypes';

import { NaturalPersonSearchData } from '../persons/personsTypes';

export interface OutletInfoDataState {
  loadingOutletInfo: boolean;
  outletInfo?: OutletInfo;
  outletInfoError?: Error;
  creatingEdition: boolean;
  editionCreated: boolean;
  creatingEditionError?: Error;
  updatingEdition: boolean;
  editionUpdated: boolean;
  updatingEditionError?: Error;
  removingEdition: boolean;
  editionRemoved: boolean;
  removingEditionError?: Error;
  missingPeriodsForOutlet: Map<number, OutletEditionPeriod[]>;
  loadingMissingPeriods: boolean;
  missingPeriodsError?: Error;
  creatingCategory: boolean;
  categoryCreated: boolean;
  creatingCategoryError?: Error;
  updatingCategory: boolean;
  categoryUpdated: boolean;
  updatingCategoryError?: Error;
  removingCategory: boolean;
  categoryRemoved: boolean;
  removingCategoryError?: Error;
  representativeForNewRecord?: NaturalPersonSearchData;
  representativeNewRecordRowOn: boolean;
  creatingRepresentative: boolean;
  representativeCreated: boolean;
  creatingRepresentativeError?: Error;
  updatingRepresentative: boolean;
  representativeUpdated: boolean;
  updatingRepresentativeError?: Error;
  removingRepresentative: boolean;
  representativeRemoved: boolean;
  removingRepresentativeError?: Error;
  creatingAdvertisement: boolean;
  advertisementCreated: boolean;
  creatingAdvertisementError: undefined;
  updatingAdvertisement: boolean;
  updatingAdvertisementId?: number;
  updatingAdvertisementError?: Error;
  removingAdvertisement: boolean;
  advertisementRemoved: boolean;
  removingAdvertisementError?: Error;
}

export const initialState: OutletInfoDataState = {
  loadingOutletInfo: false,
  outletInfo: undefined,
  outletInfoError: undefined,
  creatingEdition: false,
  editionCreated: false,
  creatingEditionError: undefined,
  updatingEdition: false,
  editionUpdated: false,
  updatingEditionError: undefined,
  removingEdition: false,
  editionRemoved: false,
  missingPeriodsForOutlet: new Map(),
  loadingMissingPeriods: false,
  removingEditionError: undefined,
  creatingCategory: false,
  categoryCreated: false,
  creatingCategoryError: undefined,
  updatingCategory: false,
  categoryUpdated: false,
  updatingCategoryError: undefined,
  removingCategory: false,
  categoryRemoved: false,
  removingCategoryError: undefined,
  representativeForNewRecord: undefined,
  representativeNewRecordRowOn: false,
  creatingRepresentative: false,
  representativeCreated: false,
  creatingRepresentativeError: undefined,
  updatingRepresentative: false,
  representativeUpdated: false,
  updatingRepresentativeError: undefined,
  removingRepresentative: false,
  representativeRemoved: false,
  removingRepresentativeError: undefined,
  creatingAdvertisement: false,
  advertisementCreated: false,
  creatingAdvertisementError: undefined,
  updatingAdvertisement: false,
  updatingAdvertisementId: undefined,
  updatingAdvertisementError: undefined,
  removingAdvertisement: false,
  advertisementRemoved: false,
  removingAdvertisementError: undefined
};

export const outletInfoDataReducer: Reducer<OutletInfoDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_OUTLET_INFO_DATA_REQUEST: {
      return { ...state, loadingOutletInfo: true };
    }
    case FETCH_OUTLET_INFO_DATA_SUCCESS: {
      return {
        ...state,
        loadingOutletInfo: false,
        outletInfo: action.payload,
        outletInfoError: undefined
      };
    }
    case FETCH_OUTLET_INFO_DATA_ERROR: {
      return {
        ...state,
        loadingOutletInfo: false,
        outletInfoError: action.payload
      };
    }
    case CREATE_EDITION_REQUEST:
      return {
        ...state,
        creatingEdition: true
      };
    case CREATE_EDITION_SUCCESS:
      return {
        ...state,
        creatingEdition: false,
        editionCreated: true,
        creatingEditionError: undefined
      };
    case CREATE_EDITION_ERROR:
      return {
        ...state,
        creatingEdition: false,
        creatingEditionError: action?.payload?.data
      };
    case RESET_CREATE_EDITION_STATE:
      return {
        ...state,
        creatingEdition: false,
        editionCreated: false,
        creatingEditionError: undefined
      };
    case UPDATE_EDITION_REQUEST:
      return {
        ...state,
        updatingEdition: true
      };
    case UPDATE_EDITION_SUCCESS:
      return {
        ...state,
        updatingEdition: false,
        editionUpdated: true,
        updatingEditionError: undefined
      };
    case UPDATE_EDITION_ERROR:
      return {
        ...state,
        updatingEdition: false,
        editionUpdated: false,
        updatingEditionError: action?.payload?.data
      };
    case RESET_UPDATE_EDITION_STATE:
      return {
        ...state,
        updatingEdition: false,
        editionUpdated: false,
        updatingEditionError: undefined
      };
    case REMOVE_EDITION_REQUEST:
      return {
        ...state,
        removingEdition: true
      };
    case REMOVE_EDITION_SUCCESS:
      return {
        ...state,
        removingEdition: false,
        editionRemoved: true,
        removingEditionError: undefined
      };
    case REMOVE_EDITION_ERROR:
      return {
        ...state,
        removingEdition: false,
        editionRemoved: false,
        removingEditionError: action?.payload?.data
      };
    case RESET_REMOVE_EDITION_STATE:
      return {
        ...state,
        removingEdition: false,
        editionRemoved: false,
        removingEditionError: undefined
      };
    case FETCH_MISSING_PERIODS_REQUEST:
      return {
        ...state,
        loadingMissingPeriods: true
      };
    case FETCH_MISSING_PERIODS_SUCCESS:
      return {
        ...state,
        loadingMissingPeriods: false,
        missingPeriodsForOutlet: state.missingPeriodsForOutlet.set(
          action.payload.outletId,
          action.payload.data
        )
      };
    case RESET_MISSING_PERIODS:
      return {
        ...state,
        missingPeriodsForOutlet: new Map()
      };
    case FETCH_MISSING_PERIODS_ERROR:
      return {
        ...state,
        loadingMissingPeriods: false,
        missingPeriodsError: action?.payload?.data
      };
    case CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        creatingCategory: true
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        creatingCategory: false,
        categoryCreated: true,
        creatingCategoryError: undefined
      };
    case CREATE_CATEGORY_ERROR:
      return {
        ...state,
        creatingCategory: false,
        creatingCategoryError: action?.payload?.data
      };
    case RESET_CREATE_CATEGORY_STATE:
      return {
        ...state,
        creatingCategory: false,
        categoryCreated: false,
        creatingCategoryError: undefined
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        updatingCategory: true
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updatingCategory: false,
        categoryUpdated: true,
        updatingCategoryError: undefined
      };
    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updatingCategory: false,
        categoryUpdated: false,
        updatingCategoryError: action?.payload?.data
      };
    case RESET_UPDATE_CATEGORY_STATE:
      return {
        ...state,
        updatingCategory: false,
        categoryUpdated: false,
        updatingCategoryError: undefined
      };
    case REMOVE_CATEGORY_REQUEST:
      return {
        ...state,
        removingCategory: true
      };
    case REMOVE_CATEGORY_SUCCESS:
      return {
        ...state,
        removingCategory: false,
        categoryRemoved: true,
        removingCategoryError: undefined
      };
    case REMOVE_CATEGORY_ERROR:
      return {
        ...state,
        removingCategory: false,
        removingCategoryError: action?.payload?.data
      };
    case RESET_REMOVE_CATEGORY_STATE:
      return {
        ...state,
        removingCategory: false,
        categoryRemoved: false,
        removingCategoryError: undefined
      };
    case SET_REPRESENTATIVE_FOR_NEW_RECORD:
      return {
        ...state,
        representativeForNewRecord: action.payload
      };
    case RESET_REPRESENTATIVE_FOR_NEW_RECORD:
      return {
        ...state,
        representativeForNewRecord: undefined
      };
    case CONTINUE_ADDING_REPRESENTATIVE_CLICKED:
      return {
        ...state,
        representativeNewRecordRowOn: true
      };
    case CANCEL_ADDING_REPRESENTATIVE_CLICKED:
      return {
        ...state,
        representativeNewRecordRowOn: false
      };
    case CREATE_REPRESENTATIVE_REQUEST:
      return {
        ...state,
        creatingRepresentative: true
      };
    case CREATE_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        creatingRepresentative: false,
        representativeNewRecordRowOn: false,
        representativeCreated: true,
        creatingRepresentativeError: undefined
      };
    case CREATE_REPRESENTATIVE_ERROR:
      return {
        ...state,
        creatingRepresentative: false,
        creatingRepresentativeError: action?.payload?.data
      };
    case RESET_CREATE_REPRESENTATIVE_STATE:
      return {
        ...state,
        creatingRepresentative: false,
        representativeCreated: false,
        creatingRepresentativeError: undefined
      };
    case UPDATE_REPRESENTATIVE_REQUEST:
      return {
        ...state,
        updatingRepresentative: true
      };
    case UPDATE_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        updatingRepresentative: false,
        representativeUpdated: true,
        updatingRepresentativeError: undefined
      };
    case UPDATE_REPRESENTATIVE_ERROR:
      return {
        ...state,
        updatingRepresentative: false,
        representativeUpdated: false,
        updatingRepresentativeError: action?.payload?.data
      };
    case RESET_UPDATE_REPRESENTATIVE_STATE:
      return {
        ...state,
        updatingRepresentative: false,
        representativeUpdated: false,
        updatingRepresentativeError: undefined
      };
    case REMOVE_REPRESENTATIVE_REQUEST:
      return {
        ...state,
        removingRepresentative: true
      };
    case REMOVE_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        removingRepresentative: false,
        representativeRemoved: true,
        removingRepresentativeError: undefined
      };
    case REMOVE_REPRESENTATIVE_ERROR:
      return {
        ...state,
        removingRepresentative: false,
        representativeRemoved: false,
        removingRepresentativeError: action?.payload?.data
      };
    case RESET_REMOVE_REPRESENTATIVE_STATE:
      return {
        ...state,
        removingRepresentative: false,
        representativeRemoved: false,
        removingRepresentativeError: undefined
      };
    case CREATE_ADVERTISEMENT_REQUEST:
      return {
        ...state,
        creatingAdvertisement: true
      };
    case CREATE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        creatingAdvertisement: false,
        advertisementCreated: true,
        creatingAdvertisementError: undefined
      };
    case CREATE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        creatingAdvertisement: false,
        creatingAdvertisementError: action?.payload?.data
      };
    case RESET_CREATE_ADVERTISEMENT_STATE:
      return {
        ...state,
        creatingAdvertisement: false,
        advertisementCreated: false,
        creatingAdvertisementError: undefined
      };
    case UPDATE_ADVERTISEMENT_REQUEST:
      return {
        ...state,
        updatingAdvertisement: true,
        updatingAdvertisementId: action.payload.outletAdPrintingId
      };
    case UPDATE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        updatingAdvertisement: false,
        updatingAdvertisementId: undefined,
        updatingAdvertisementError: undefined
      };
    case UPDATE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        updatingAdvertisement: false,
        updatingAdvertisementId: undefined,
        updatingAdvertisementError: action?.payload?.data
      };
    case RESET_UPDATE_ADVERTISEMENT_STATE:
      return {
        ...state,
        updatingAdvertisement: false,
        updatingAdvertisementId: undefined,
        updatingAdvertisementError: undefined
      };
    case RESET_REMOVE_ADVERTISEMENT_STATE:
      return {
        ...state,
        removingAdvertisement: false,
        advertisementRemoved: false,
        removingAdvertisementError: undefined
      };
    case REMOVE_ADVERTISEMENT_REQUEST:
      return {
        ...state,
        removingAdvertisement: true
      };
    case REMOVE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        removingAdvertisement: false,
        advertisementRemoved: true,
        removingAdvertisementError: undefined
      };
    case REMOVE_ADVERTISEMENT_ERROR:
      return {
        ...state,
        removingAdvertisement: false,
        removingAdvertisementError: action?.payload?.data
      };
    default: {
      return state;
    }
  }
};
