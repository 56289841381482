import React, { useEffect } from 'react';
import { TableBody } from '@material-ui/core';
import { ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import { TableRowsContainer } from '../TableRows/TableRowsContainer';
import {
  getAllMonthsInPeriod,
  getFilteredMixedDates,
  getYearsArray
} from '../utilities/functionsWithDateFormat';

import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../ShareholdersTableWithContext';
import FilteredViewTableHeader from './FilteredViewTableHeader';

interface Props {
  sortedShareholderData: ShareholdersData;
  openCalendar: () => void;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  loadingShareholdersData: boolean;
  allEventsDates: string[];
  periodFilterFromDate: string;
  periodFilterToDate: string;
}

const TableByMonth: React.FC<Props> = ({
  sortedShareholderData,
  openCalendar,
  noValidDates,
  allShareholdersFromJADIS,
  loadingShareholdersData,
  allEventsDates,
  periodFilterFromDate,
  periodFilterToDate
}) => {
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const {
    state: { datesInPerdiodWithEvents }
  } = useShareholdersTableState();

  const allMonthsInPeriod = getAllMonthsInPeriod(sortedShareholderData.virsStartDate);

  useEffect(() => {
    tableDispatch({
      type: 'INITIAL_MONTHS_HEADER_DATES',
      allEventsDates: allEventsDates,
      months: allMonthsInPeriod
    });
  }, []);

  const filteredDates = getFilteredMixedDates(
    datesInPerdiodWithEvents,
    {
      periodFilterFromDate,
      periodFilterToDate
    },
    'month'
  );

  const onlyDates = filteredDates.map((x) => x.date);
  const yearsArray = getYearsArray(sortedShareholderData.virsStartDate);

  return (
    <>
      <FilteredViewTableHeader
        openCalendar={openCalendar}
        allEventsDates={allEventsDates}
        yearsArray={yearsArray}
        shareholdersData={sortedShareholderData}
        noValidDates={noValidDates}
        allShareholdersFromJADIS={allShareholdersFromJADIS}
        loadingShareholdersData={loadingShareholdersData}
        datesInPerdiodWithEvents={filteredDates}
      />

      <TableBody>
        <TableRowsContainer data={sortedShareholderData} allEventsDates={onlyDates} />
      </TableBody>
    </>
  );
};

export default TableByMonth;
