import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  level: number;
  virs?: boolean;
  showChildShareholder?: boolean;
  halfBottom?: boolean;
  middle?: boolean;
  isLastShareholder?: boolean;
  drawPreviousLevelMarks?: boolean;
  parentsLevelBarStatus?: boolean[];
  isParentLastShareholder?: boolean;
  isItGroupShareholder?: boolean;
}

interface StyleProps {
  halfBottomLeft: number;
  verticalLeft: number;
  verticalBottom: string;
  middleWidth: number;
  middleLeft: number;
}

const useStyles = makeStyles((theme) => ({
  levelMark: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  halfBottom: ({ halfBottomLeft }: StyleProps) => ({
    borderLeft: `1px solid ${theme.palette.info.main} `,
    height: '43%',
    bottom: '-1px',
    left: `${halfBottomLeft}px`
  }),
  vertical: ({ verticalLeft, verticalBottom }: StyleProps) => ({
    borderLeft: `1px solid ${theme.palette.info.main} `,
    left: `${verticalLeft}px`,
    top: '-1px',
    bottom: `${verticalBottom}`
  }),
  fullHeight: {
    bottom: '-1px'
  },
  middle: ({ middleWidth, middleLeft }: StyleProps) => ({
    width: `${middleWidth}px`,
    height: '50%',
    borderBottom: `1px solid ${theme.palette.info.main} `,
    bottom: '50%',
    left: `${middleLeft}px`
  }),
  levelNum: {
    left: '304px',
    border: '1px solid #006FB2',
    padding: '2px',
    borderRadius: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'white',
    backgroundColor: '#006FB2',
    zIndex: 5
  },
  negativeLeft10: {
    left: '-10px'
  }
}));

export const LevelBar: React.FC<Props> = ({
  level,
  virs,
  showChildShareholder,
  halfBottom,
  middle,
  isLastShareholder,
  drawPreviousLevelMarks,
  parentsLevelBarStatus,
  isParentLastShareholder,
  isItGroupShareholder
}) => {
  const levelValue = level < 10 ? level : 10;
  const leftValueHalfBottom = 7 + 24 + 12.5 + 30 * levelValue; // ribbon, expandButton, 1/2 triangleButton, marginLeft value
  const leftMiddleValue = 7 + 6.5 + 30 * levelValue; // ribbon, expandButton, 1/2 triangleButton, marginLeft value

  const classes = useStyles({
    halfBottomLeft: virs ? 42.5 : leftValueHalfBottom,
    verticalLeft: level === 1 ? 42.5 : leftMiddleValue,
    verticalBottom: isLastShareholder ? '50%' : '-1px',
    middleWidth: halfBottom ? 24 : 40,
    middleLeft: level === 1 ? 42.5 : leftMiddleValue
  });

  return (
    <>
      {showChildShareholder && level < 10 && !isItGroupShareholder && (
        <div className={`${classes.levelMark} ${halfBottom ? classes.halfBottom : undefined}`} />
      )}

      {middle && level <= 10 && (
        <>
          <div className={`${classes.levelMark} ${classes.middle}`} />
          <div className={`${classes.levelMark} ${classes.vertical}`} />
          {level === 10 && (
            <Typography variant="h5" className={classes.levelNum}>
              {level}
            </Typography>
          )}
        </>
      )}

      {drawPreviousLevelMarks && !isParentLastShareholder && level <= 11 && (
        <div className={`${classes.levelMark} ${classes.vertical} ${classes.fullHeight}`}>
          {level === 11 && (
            <Typography variant="h5" className={`${classes.levelNum} ${classes.negativeLeft10}`}>
              {level + 1}
            </Typography>
          )}
        </div>
      )}

      {!drawPreviousLevelMarks && level >= 11 && (
        <Typography variant="h5" className={classes.levelNum}>
          {level}
        </Typography>
      )}

      {parentsLevelBarStatus &&
        parentsLevelBarStatus?.length > 0 &&
        parentsLevelBarStatus.map((status, index) =>
          !status ? (
            <LevelBar
              key={`${status}${index}`}
              level={index + 1}
              drawPreviousLevelMarks
              isParentLastShareholder={status}
            />
          ) : null
        )}
    </>
  );
};
