import React from 'react';
import { createStyles, Divider, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { UserMessage } from '../../../../store/userMessages/userMessageTypes';

interface Props {
  message: UserMessage;
  openMessageDialog: (message: UserMessage) => void;
}

const MessagesMenuItem: React.FC<Props> = ({ message, openMessageDialog }) => {
  const classes = makeStyles((theme) =>
    createStyles({
      dot: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.error.dark,
        marginRight: 14
      },
      menuItem: {
        width: '100%',
        borderRadius: 5,
        '&:hover': {
          backgroundColor: '#F5F8FF',

          '& p': {
            textDecoration: 'underline'
          }
        }
      },
      messageContainer: {
        display: 'flex',
        alignItems: 'center'
      },
      message: {
        color: theme.palette.primary.main,
        fontWeight: 600
      },
      date: { color: theme.palette.info.main, marginLeft: 22 }
    })
  )();

  return (
    <>
      <MenuItem onClick={() => openMessageDialog(message)} className={classes.menuItem}>
        <div>
          <Typography variant="h6" className={classes.date}>
            {message.receivedDate}
          </Typography>
          <div className={classes.messageContainer}>
            <div className={classes.dot} />
            <Typography className={classes.message}>{message.header}</Typography>
          </div>
        </div>
      </MenuItem>
      <Divider light style={{ margin: '8px 0' }} />
    </>
  );
};

export default MessagesMenuItem;
