import React, { useReducer, useContext, Dispatch, createContext } from 'react';
import { enterpriseDataTableReducer } from './tableState/tableReducer';
import {
  EnterpriseDataTableAction,
  EnterpriseDataTableState,
  initialEnterpriseTableState
} from './tableState/initialStateAndTypes';
import { EnterpriseTableProps, EnterpriseTable } from './Table';

const EnterpriseTableStateContext = createContext<{
  state: EnterpriseDataTableState;
}>({
  state: initialEnterpriseTableState
});

const EnterpriseTableDispatchContext = createContext<{
  dispatch: Dispatch<EnterpriseDataTableAction>;
}>({
  dispatch: () => {}
});

const EnterpriseDataTable: React.FC<EnterpriseTableProps> = (props) => {
  const [state, dispatch] = useReducer(enterpriseDataTableReducer, initialEnterpriseTableState);
  return (
    <EnterpriseTableStateContext.Provider value={{ state }}>
      <EnterpriseTableDispatchContext.Provider value={{ dispatch }}>
        <EnterpriseTable {...props} />
      </EnterpriseTableDispatchContext.Provider>
    </EnterpriseTableStateContext.Provider>
  );
};

function useEnterpriseTableState() {
  return useContext(EnterpriseTableStateContext);
}
function useEnterpriseTableDispatch() {
  return useContext(EnterpriseTableDispatchContext);
}

export { EnterpriseDataTable, useEnterpriseTableState, useEnterpriseTableDispatch };
