import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import { OutletCategory } from '../../classifiers/OutletCategotyTable/types';
import {
  OutletCategoryHeaderField,
  outletCategoryTableColumns,
  OutletCategoryTableState
} from '../tablesState/categoryTableTypes';

export function getTableExportDefinition(
  tableState: OutletCategoryTableState
): TableExportDefinition<OutletCategory> {
  const { stringValueColumn } = getColumnBuilder<OutletCategory, OutletCategoryHeaderField>(
    outletCategoryTableColumns,
    tableState.columnsDisplayStatus
  );
  return {
    title: 'Kategorija',
    columnGroups: [
      {
        columns: [stringValueColumn('categoryTypeName', { width: 45 })]
      },
      {
        header: 'VIP valdoma',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      }
    ]
  };
}
