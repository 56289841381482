import React, { useContext, useEffect, useReducer, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { DropdownMultipleInputCell } from '../../../components/TableInputs/DropdownMultipleInput';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { DateInputCellOnlyYear } from '../../../components/TableInputs/DateInputOnlyYear';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { ApplicationState } from '../../../store';
import {
  annulFundsReceived,
  fetchFundsReceivedDataLegal,
  removeFundsReceived,
  resetAnnulFundsReceivedState,
  resetFundsReceivedRemovingState,
  resetFundsReceivedUpdatingState,
  updateFundsReceived
} from '../../../store/fundsReceived/fundsReceivedActions';
import {
  FundsReceivedDataLegal,
  FundsReceivedRecord,
  TransactionType
} from '../../../store/fundsReceived/fundsReceivedDataTypes';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { FundsReceivedLegalRow } from './RowBasic';
import { FundsReceivedLegalTableInputs } from './RowInputs';
import { editFundsReceivedReducer } from './rowState/editReducer';
import { initialFundsReceivedEditRowState } from './rowState/rowInitialStateAndTypes';
import { SignDocumentButton } from '../../../components/TableButtons/RowActionButtons/SignDocumentButton';
import { AnnulDocumentButton } from '../../../components/TableButtons/RowActionButtons/AnnulDocumentButton';
import PreviewAndSignDocumentDialog from '../../document/PreviewAndSignDocumentDialog';
import { MoneyInputCell } from '../../../components/TableInputs/MoneyInput';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import { AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import { FundsReceivedLegalTableStateContext } from '../Context';
import { validateEditFundsReceivedRecordState } from './rowState/reducerFunctions';
import { ViewDocumentButton } from '../../../components/TableButtons/RowActionButtons/ViewDocumentButton';
import {
  disableOthersIfFictitiousSelected,
  getOutletMultiOption
} from '../../../components/TableInputs/Utilities';
import { AuthorisedEditAndRemoveActions } from '../../../components/TableRowActions/AuthorisedEditAndRemoveActions';

interface Props {
  record: FundsReceivedDataLegal;
}

const FundsReceivedLegalRowContainer: React.FC<Props & RouteComponentProps> = ({ record }) => {
  const [openDocumentDialog, setOpenDocumentDialog] = useState<boolean>(false);

  const [rowState, rowDispatch] = useReducer(
    editFundsReceivedReducer,
    initialFundsReceivedEditRowState
  );

  const { state: tableState } = useContext(FundsReceivedLegalTableStateContext);

  const {
    fundsReceivedData: {
      transactionTypes,
      isFundsReceivedUpdated,
      updatingFundsReceived,
      removingFundsReceived,
      isFundsReceivedRemoved,
      removingFundsReceivedError,
      annullingFundsReceivedRecord,
      annullingFundsReceivedError
    },
    classifiers: { enterpriseTypes, fictitiousOutlet },
    virsis: { currentUser },
    classifiers: { annulmentTypes }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  function turnOnEditing() {
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      transactionTypes: transactionTypes || [],
      fictitiousOutlet: fictitiousOutlet || []
    });
  }

  function openFindVirsDialog() {
    rowDispatch({
      type: 'VIRS_INPUT_CLICKED'
    });
  }

  function closeFindVirsDialog() {
    rowDispatch({
      type: 'FIND_VIRS_DIALOG_CLOSED'
    });
  }

  function selectVirs(virs: VirsSearchData) {
    rowDispatch({
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
      virs
    });
  }

  function setFundsReceivedYear(fundsReceivedYear: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'FUNDS_RECEIVED_YEAR_CHANGED', fundsReceivedYear });
  }

  function setFundsReceivedSum(fundsReceivedSum: string | null) {
    rowDispatch({ type: 'FUNDS_RECEIVED_SUM_CHANGED', fundsReceivedSum });
  }

  function setOutlets(outlets: OutletShortData[]) {
    rowDispatch({ type: 'OUTLETS_CHANGED', outlets });
  }

  function setTransactionType(transaction: TransactionType | null) {
    rowDispatch({ type: 'TRANSACTION_CHANGED', transaction });
  }

  function cancelEditing() {
    rowDispatch({ type: 'EDITING_CANCELLED' });
  }

  function updateFundsReceivedRecord() {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateEditFundsReceivedRecordState(rowState).updateRecordConfirmationOn) {
      const fundsReceivedRecord: FundsReceivedRecord = {
        virsId: rowState.virs.value ? rowState.virs.value.virId : null,
        fundsReceivedYear: rowState.fundsReceivedYear.value
          ? rowState.fundsReceivedYear.value.format('L').toString()
          : null,
        fundsReceivedSum: rowState.fundsReceivedSum.value ? rowState.fundsReceivedSum.value : null,
        fundsSourceCode: currentUser ? String(currentUser.personId) : null,
        fundsReceivedOutlets: rowState.fundsReceivedOutlets.values.map((outlet) => outlet.outletId),
        transactionTypeId: rowState.transaction.value
          ? rowState.transaction.value.transactionTypeId
          : null
      };
      reduxDispatch(updateFundsReceived(fundsReceivedRecord, record.fundsReceivedId));
    }
  }

  function onRemoveRecordClicked() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function removeRecord() {
    reduxDispatch(removeFundsReceived(record.fundsReceivedId));
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  function closeOnRemoveError() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetFundsReceivedRemovingState());
  }

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || []
    });
  }

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulFundsReceivedState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    reduxDispatch(annulFundsReceived(record.documentStatusId, annulRecord));
    closeAnnulDocumentDialog();
  }

  useEffect(() => {
    if (isFundsReceivedUpdated) {
      reduxDispatch(resetFundsReceivedUpdatingState());
      rowDispatch({ type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
    }
  }, [reduxDispatch, isFundsReceivedUpdated]);

  return (
    <>
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={closeFindVirsDialog}
          closeAndContinueWithVirs={selectVirs}
        />
      )}
      {record.documentStatus.id === 'RUOSIAMAS' && rowState.editingOn && (
        <FundsReceivedLegalTableInputs
          virsInput={<VirsInputCell openSearchDialog={openFindVirsDialog} state={rowState.virs} />}
          fundsReceivedYearInput={
            <DateInputCellOnlyYear
              state={rowState.fundsReceivedYear}
              setDate={setFundsReceivedYear}
              isRequired
            />
          }
          fundsReceivedSumInput={
            <MoneyInputCell
              setValue={setFundsReceivedSum}
              inputState={rowState.fundsReceivedSum}
              isRequired
            />
          }
          fundsSourceNameInput={currentUser?.amnName}
          fundsSourceCodeInput={currentUser?.amnCode}
          outletsInput={
            <DropdownMultipleInputCell
              renderOption={getOutletMultiOption}
              selectValues={setOutlets}
              state={rowState.fundsReceivedOutlets}
              getOptionLabel={(option) =>
                `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
              }
              getOptionSelected={(option, value) =>
                value ? option.outletId === value.outletId : false
              }
              getOptionDisabled={disableOthersIfFictitiousSelected(
                fictitiousOutlet && fictitiousOutlet[0]
              )}
            />
          }
          transactionInput={
            <DropdownInputCell
              selectValue={setTransactionType}
              dropdownState={rowState.transaction}
              getOptionLabel={(transaction) => transaction.transactionTypeName}
              getOptionSelected={(option, value) =>
                option.transactionTypeId === value.transactionTypeId
              }
            />
          }
          actions={
            <SaveAndCancelActions
              handleConfirmationYes={updateFundsReceivedRecord}
              isProcessing={updatingFundsReceived}
              handleCancelButtonClick={cancelEditing}
            />
          }
        />
      )}
      {record.documentStatus.id === 'RUOSIAMAS' && !rowState.editingOn && (
        <FundsReceivedLegalRow
          record={record}
          display={tableState.columnsDisplayStatus}
          actions={
            record.removable && (
              <AuthorisedEditAndRemoveActions
                handleEditButtonClick={turnOnEditing}
                handleRemoveButtonClick={onRemoveRecordClicked}
                confirmationOn={rowState.removeRecordConfirmationOn}
                handleConfirmationYes={removeRecord}
                handleConfirmationNo={closeRemoveConfirmation}
                isProcessing={removingFundsReceived}
                isSuccess={isFundsReceivedRemoved}
                error={removingFundsReceivedError}
                onErrorClose={closeOnRemoveError}
                recordCreatedByCode={record.fundsSourceCode}
                editingWarningMessage={record.editingWarningMessage}
              />
            )
          }
          elDocument={
            record.removable && <SignDocumentButton onClick={() => setOpenDocumentDialog(true)} />
          }
        />
      )}

      {record.documentStatus.id === 'PASIRASYTAS' && (
        <FundsReceivedLegalRow
          display={tableState.columnsDisplayStatus}
          record={record}
          elDocument={
            <>
              <ViewDocumentButton onClick={() => setOpenDocumentDialog(true)} />
              <AnnulDocumentButton
                annulmentDisablementReason={record.annulmentDisablementReason}
                onClick={openAnnulDocumentDialog}
              />
            </>
          }
        />
      )}
      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingFundsReceivedError}
        isProcessing={annullingFundsReceivedRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingFundsReceivedError}
      />
      <PreviewAndSignDocumentDialog
        openDialog={openDocumentDialog}
        documentPath={`funds-received/${record.documentStatusId}`}
        documentId={record.documentStatusId}
        documentStatus={record.documentStatus.id}
        onClose={() => setOpenDocumentDialog(false)}
        documentErrors={[]}
        onSignSuccess={() => reduxDispatch(fetchFundsReceivedDataLegal())}
      />
    </>
  );
};

export default withRouter(FundsReceivedLegalRowContainer);
