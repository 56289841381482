import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { CircularProgress, Container, Divider, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { HeaderTabPanel } from '../../components/Tabs/HeaderTab';
import { ApplicationState } from '../../store';
import { RowActionButton } from '../../components/TableRowActions/RowActionButton';
import {
  createGraphicalRepresentationRecordRequest,
  fetchAvailableVirsIdRequest,
  fetchGraphicalRepresentationDataRequest,
  fetchGraphicalRepresentationVersionRequest,
  publishGraphicalRepresentationVersionRequest
} from '../../store/graphicalRepresentationTools/graphicalRepresentationToolsActions';
import {
  GraphicalRecord,
  GraphicalRecordCreationInput,
  GraphicalRepresentationToolsTypes
} from '../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';
import { graphicalPerceptionPageReducer } from './state/reducer';
import { initialGraphicalPerceptionTableState } from './state/initialStateAndTypes';
import GraphicalRepresentationHeaderTabs from './components/GraphicalRepresentationHeaderTabs';
import GraphicalRepresentationTabPanelContents from './components/GraphicalRepresentationTabPanelContents';
import GraphicalRepresentationVersionSelector from './components/GraphicalRepreseentationVersionSelector';

const GraphicalRepresentationPage: React.FC = () => {
  const reduxDispatch = useDispatch();

  const [tableState, tableDispatch] = useReducer(
    graphicalPerceptionPageReducer,
    initialGraphicalPerceptionTableState
  );

  const { activeTab } = tableState;
  const setActiveTab = (recordType: GraphicalRepresentationToolsTypes) => {
    tableDispatch({ type: 'SET_TYPE', recordType });
  };

  const {
    selectedGraphicalVersionId,
    graphicalRepresentationVersion,
    graphicalRepresentationRecord,
    loadingPublishGraphicalRepresentationRecord,
    loadingGraphicalRepresentationVersion,
    loadingCreateGraphicalRepresentationRecord,
    selectedVirsId
  } = useSelector((state: ApplicationState) => state.graphicalRepresentation);

  const validationSchema = Yup.object().shape({
    records: Yup.array().of(
      Yup.object().shape({
        tableNameLt: Yup.string().required('Kortelės pavadinimas privalomas'),
        tableNameEn: Yup.string().required('Kortelės pavadinimas privalomas'),
        urlLt: Yup.string().required('URL privalomas'),
        urlEn: Yup.string().required('URL privalomas'),
        styleFileName: Yup.string().required('Stilius privalomas'),
        isActive: Yup.boolean()
      })
    )
  });

  const filterRecords = useCallback(
    (type?: GraphicalRepresentationToolsTypes) => {
      return graphicalRepresentationRecord?.filter((r) => r.type === type) || [];
    },
    [graphicalRepresentationRecord]
  );

  const [virsRecords, setVirsRecords] = useState<GraphicalRecord[]>([]);
  const [vipRecords, setVipRecords] = useState<GraphicalRecord[]>([]);
  const [dfaRecords, setDfaRecords] = useState<GraphicalRecord[]>([]);
  const [djaRecords, setDjaRecords] = useState<GraphicalRecord[]>([]);

  const handlePublication = () => {
    if (selectedGraphicalVersionId) {
      reduxDispatch(publishGraphicalRepresentationVersionRequest(selectedGraphicalVersionId));
    }
  };

  const handleCorrectingRowNr = (graphicalRecord: GraphicalRecord[]) => {
    return graphicalRecord.map((record, index) => ({
      ...record,
      rowNr: String(index + 1)
    }));
  };

  const handleSubmit = () => {
    if (selectedGraphicalVersionId) {
      const record: GraphicalRecordCreationInput = {
        graphicalVersionId: selectedGraphicalVersionId,
        graphicalRecord: [
          ...handleCorrectingRowNr(virsRecords),
          ...handleCorrectingRowNr(vipRecords),
          ...handleCorrectingRowNr(djaRecords),
          ...handleCorrectingRowNr(dfaRecords)
        ]
      };
      reduxDispatch(createGraphicalRepresentationRecordRequest(record));
    }
  };

  const initialValues = () => {
    if (activeTab === 'VIRS') return virsRecords;
    if (activeTab === 'VIP') return vipRecords;
    if (activeTab === 'DJA') return djaRecords;
    if (activeTab === 'DFA') return dfaRecords;
    return [];
  };

  const hasUnsavedChanges = () => {
    return (
      JSON.stringify(filterRecords('VIRS')) !== JSON.stringify(virsRecords) ||
      JSON.stringify(filterRecords('VIP')) !== JSON.stringify(vipRecords) ||
      JSON.stringify(filterRecords('DFA')) !== JSON.stringify(dfaRecords) ||
      JSON.stringify(filterRecords('DJA')) !== JSON.stringify(djaRecords)
    );
  };

  const handleConfigurationPreview = () => {
    let url = window.location.origin;

    if (process.env.REACT_APP_DEV_PUBLIC_ADDRESS) url = process.env.REACT_APP_DEV_PUBLIC_ADDRESS;
    else if (process.env.REACT_APP_DEV) url = url.concat(':81');
    url = url.concat(`/virs/${selectedVirsId}/${selectedGraphicalVersionId}`);

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (graphicalRepresentationVersion === undefined) {
      reduxDispatch(fetchGraphicalRepresentationVersionRequest());
    }
  }, [graphicalRepresentationVersion, reduxDispatch]);

  useEffect(() => {
    if (selectedGraphicalVersionId) {
      reduxDispatch(fetchAvailableVirsIdRequest());
      reduxDispatch(fetchGraphicalRepresentationDataRequest(selectedGraphicalVersionId));
    }
  }, [selectedGraphicalVersionId, reduxDispatch]);

  useEffect(() => {
    setDjaRecords(filterRecords('DJA'));
    setDfaRecords(filterRecords('DFA'));
    setVipRecords(filterRecords('VIP'));
    setVirsRecords(filterRecords('VIRS'));
  }, [filterRecords, graphicalRepresentationRecord]);

  return (
    <div className="data-page">
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={validationSchema}
        initialValues={{ records: initialValues() }}
        onSubmit={handleSubmit}
      >
        {({ ...formikProps }) => (
          <Container maxWidth="lg" style={{ paddingBottom: '2em' }}>
            <Typography variant="h1" style={{ margin: '10px 0' }}>
              Grafinio atvaizdavimo įrankio administravimas
            </Typography>
            <Divider />
            {loadingGraphicalRepresentationVersion ? (
              <CircularProgress />
            ) : (
              <GraphicalRepresentationVersionSelector
                graphicalRepresentationVersion={graphicalRepresentationVersion || []}
              />
            )}
            <GraphicalRepresentationHeaderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <HeaderTabPanel>
              {activeTab === 'VIRS' && (
                <GraphicalRepresentationTabPanelContents
                  formikProps={formikProps}
                  activeTab={activeTab}
                  setTabRecords={setVirsRecords}
                />
              )}
              {activeTab === 'VIP' && (
                <GraphicalRepresentationTabPanelContents
                  formikProps={formikProps}
                  activeTab={activeTab}
                  setTabRecords={setVipRecords}
                />
              )}
              {activeTab === 'DJA' && (
                <GraphicalRepresentationTabPanelContents
                  formikProps={formikProps}
                  activeTab={activeTab}
                  setTabRecords={setDjaRecords}
                />
              )}
              {activeTab === 'DFA' && (
                <GraphicalRepresentationTabPanelContents
                  formikProps={formikProps}
                  activeTab={activeTab}
                  setTabRecords={setDfaRecords}
                />
              )}
            </HeaderTabPanel>
            <RowActionButton
              label="Peržiūrėti"
              disabled={hasUnsavedChanges() || !selectedVirsId}
              onButtonClick={handleConfigurationPreview}
            />
            {hasUnsavedChanges() && (
              <RowActionButton
                label="Išsaugoti"
                isProcessing={loadingCreateGraphicalRepresentationRecord}
                onButtonClick={formikProps.submitForm}
              />
            )}
            {!hasUnsavedChanges() && (
              <RowActionButton
                label="Publikuoti"
                isProcessing={loadingPublishGraphicalRepresentationRecord}
                onButtonClick={handlePublication}
              />
            )}
          </Container>
        )}
      </Formik>
    </div>
  );
};

export default GraphicalRepresentationPage;
