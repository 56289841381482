import { createAction } from 'typesafe-actions';
import { FileType } from '../../FilesTable/types';

export function getBasicTableActions<TData, TInput>() {
  const setLoading = createAction('SET_LOADING', (isLoading: boolean) => ({
    isLoading
  }))();

  const setError = createAction('SET_ERROR', (error: boolean) => ({
    error
  }))();

  const setData = createAction('SET_DATA', (data: TData[]) => ({
    data
  }))();

  const loadData = createAction('LOAD_DATA')();

  const setFormOpen = createAction('SET_FORM_OPEN', (open: boolean, initialValues?: TInput) => ({
    open,
    initialValues
  }))();

  const setFormLoading = createAction('SET_FORM_LOADING', (loading: boolean) => ({
    loading
  }))();

  const setFormError = createAction('SET_FORM_ERROR', (error: boolean) => ({
    error
  }))();

  const submitForm = createAction('FORM_SUBMIT', (data: TInput) => ({
    data
  }))();

  const resetForm = createAction('FORM_RESET')();

  const setRemoveOpen = createAction('SET_REMOVE_OPEN', (open: boolean, id?: number) => ({
    open,
    id
  }))();

  const setRemoveError = createAction('SET_REMOVE_ERROR', (error: boolean) => ({
    error
  }))();

  const setRemoveLoading = createAction('SET_REMOVE_LOADING', (loading: boolean) => ({
    loading
  }))();

  const submitRemove = createAction('SUBMIT_REMOVE', (id: number) => ({
    id
  }))();

  const resetRemove = createAction('RESET_REMOVE')();

  const download = createAction('DOWNLOAD', (fileType: FileType | null) => ({
    fileType
  }))();

  const actions = {
    setLoading,
    setError,
    setData,
    loadData,
    setFormOpen,
    setFormLoading,
    setFormError,
    submitForm,
    resetForm,
    setRemoveOpen,
    setRemoveError,
    setRemoveLoading,
    submitRemove,
    resetRemove,
    download
  };

  return actions;
}

export function getOptionedTableActions<TData, TInput, TOptions>() {
  const setFormOptions = createAction('SET_FORM_OPTIONS', (options: TOptions) => ({
    options
  }))();
  return { ...getBasicTableActions<TData, TInput>(), setFormOptions };
}
