import React from 'react';
import { LinearProgress, TableCell, TableRow } from '@material-ui/core';

interface Props {
  isLoading: boolean;
  colSpan: number;
}

export const TableLinearProgressRow: React.FC<Props> = ({ isLoading, colSpan: colspan }) => {
  return (
    <>
      {isLoading && (
        <TableRow>
          <TableCell style={{ margin: 0, padding: 0, height: 0 }} colSpan={colspan}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
