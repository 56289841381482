import React, { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AdBasicRow } from './RowBasic';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { OutletAdRecord, OutletInfo } from '../../../../store/outletInfoData/outletInfoDataTypes';
import { ApplicationState } from '../../../../store';
import { createAdvertisement } from '../../../../store/outletInfoData/outletInfoDataActions';
import { adPrintingRowReducer } from './rowState/adPrintingRowReducer';
import { initialAdPrintingRowState } from './rowState/rowInitialStateAndTypes';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { AdColumnsDisplayStatus } from '../../tablesState/adPrintingTableTypes';
import { validateAdPrintingNewRecordState } from './rowState/rowReducerFunctions';

interface Props {
  outletInfo: OutletInfo;
  columnsDisplay: AdColumnsDisplayStatus;
  closeNewRecordRow: () => void;
}

export const AdDataRowNewRecordContainer: React.FC<Props> = ({
  outletInfo,
  columnsDisplay,
  closeNewRecordRow
}) => {
  const [newRowState, newRowDispatch] = useReducer(adPrintingRowReducer, initialAdPrintingRowState);

  const { creatingAdvertisement } = useSelector((state: ApplicationState) => state.outletInfoData);

  const reduxDispatch = useDispatch();

  const setAdValidFrom = (validFrom: MaterialUiPickersDate | null) => {
    newRowDispatch({
      type: 'AD_PRINTING_FROM_DATE_CHANGED',
      validFrom
    });
  };

  const setAdValidTo = (validTo: MaterialUiPickersDate | null) => {
    newRowDispatch({
      type: 'AD_PRINTING_TO_DATE_CHANGED',
      validTo
    });
  };

  const onCreateRecordConfirmed = () => {
    newRowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateAdPrintingNewRecordState(newRowState).createStateValidated) {
      const newAd: OutletAdRecord = {
        validFrom: newRowState.validFrom.value
          ? newRowState.validFrom.value.format('L').toString()
          : null,
        validTo: newRowState.validTo.value ? newRowState.validTo.value.format('L').toString() : null
      };
      if (outletInfo.outletId) {
        reduxDispatch(createAdvertisement(outletInfo.outletId, newAd));
      }
    }
  };

  useEffect(() => {
    newRowDispatch({
      type: 'NEW_RECORD_INITIALIZED',
      outletControlledFrom: outletInfo.controlledFrom,
      outletControlledTo: outletInfo.controlledTo || null
    });
  }, [outletInfo.controlledFrom, outletInfo.controlledTo]);

  return (
    <AdBasicRow
      id="new-record"
      columnsToDisplay={columnsDisplay}
      validFromInput={
        <DateInputImprovedCell state={newRowState.validFrom} setDate={setAdValidFrom} isRequired />
      }
      validToInput={<DateInputImprovedCell state={newRowState.validTo} setDate={setAdValidTo} />}
      actions={
        <SaveAndCancelActions
          handleConfirmationYes={onCreateRecordConfirmed}
          isProcessing={creatingAdvertisement}
          handleCancelButtonClick={closeNewRecordRow}
        />
      }
    />
  );
};
