export const FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST =
  '@fundsReceivedLegal/FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST';
export const FETCH_FUNDS_RECEIVED_DATA_LEGAL_SUCCESS =
  '@fundsReceivedLegal/FETCH_FUNDS_RECEIVED_DATA_LEGAL_SUCCESS';
export const FETCH_FUNDS_RECEIVED_DATA_LEGAL_ERROR =
  '@fundsReceivedLegal/FETCH_FUNDS_RECEIVED_DATA_LEGAL_ERROR';

export const CREATE_FUNDS_RECEIVED_REQUEST = '@fundsReceivedLegal/CREATE_FUNDS_RECEIVED_REQUEST';
export const CREATE_FUNDS_RECEIVED_SUCCESS = '@fundsReceivedLegal/CREATE_FUNDS_RECEIVED_SUCCESS';
export const CREATE_FUNDS_RECEIVED_ERROR = '@fundsReceivedLegal/CREATE_FUNDS_RECEIVED_ERROR';
export const RESET_CREATE_FUNDS_RECEIVED_STATE =
  '@fundsReceivedLegal/RESET_CREATE_FUNDS_RECEIVED_STATE';

export const UPDATE_FUNDS_RECEIVED_REQUEST = '@fundsReceivedLegal/UPDATE_FUNDS_RECEIVED_REQUEST';
export const UPDATE_FUNDS_RECEIVED_SUCCESS = '@fundsReceivedLegal/UPDATE_FUNDS_RECEIVED_SUCCESS';
export const UPDATE_FUNDS_RECEIVED_ERROR = '@fundsReceivedLegal/UPDATE_FUNDS_RECEIVED_ERROR';
export const RESET_FUNDS_RECEIVED_UPDATE_STATE =
  '@fundsReceivedLegal/RESET_UPDATE_FUNDS_RECEIVED_STATE';

export const REMOVE_FUNDS_RECEIVED_REQUEST = '@fundsReceivedLegal/REMOVE_FUNDS_RECEIVED_REQUEST';
export const REMOVE_FUNDS_RECEIVED_SUCCESS = '@fundsReceivedLegal/REMOVE_FUNDS_RECEIVED_SUCCESS';
export const REMOVE_FUNDS_RECEIVED_ERROR = '@fundsReceivedLegal/REMOVE_FUNDS_RECEIVED_ERROR';
export const RESET_REMOVE_FUNDS_RECEIVED_STATE =
  '@fundsReceivedLegal/RESET_REMOVE_FUNDS_RECEIVED_STATE';

export const FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST =
  '@fundsReceivedVirs/FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST';
export const FETCH_FUNDS_RECEIVED_DATA_VIRS_SUCCESS =
  '@fundsReceivedVirs/FETCH_FUNDS_RECEIVED_DATA_VIRS_SUCCESS';
export const FETCH_FUNDS_RECEIVED_DATA_VIRS_ERROR =
  '@fundsReceivedVirs/FETCH_FUNDS_RECEIVED_DATA_VIRS_ERROR';

export const FUNDS_RECEIVED_DATA_VIRS_PDF_REQUEST =
  '@fundsReceivedVirs/FUNDS_RECEIVED_DATA_VIRS_PDF_REQUEST';

export const ANNUL_FUNDS_RECEIVED_DATA_REQUEST =
  '@fundsReceivedLegal/ANNUL_FUNDS_RECEIVED_DATA_REQUEST';
export const ANNUL_FUNDS_RECEIVED_DATA_SUCCESS =
  '@fundsReceivedLegal/ANNUL_FUNDS_RECEIVED_DATA_SUCCESS';
export const ANNUL_FUNDS_RECEIVED_DATA_ERROR =
  '@fundsReceivedLegal/ANNUL_FUNDS_RECEIVED_DATA_ERROR';
export const RESET_ANNUL_FUNDS_RECEIVED_DATA_STATE =
  '@fundsReceivedLegal/RESET_ANNUL_FUNDS_RECEIVED_DATA_STATE';

export const FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST =
  '@fundsReceivedLegal/FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST';
