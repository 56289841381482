import moment from 'moment';
import { SortOrder } from '../../utils/tableTypes';
import { ApplicationState } from '../index';
import { PaymentDataHeaderField } from '../../containers/PaymentTableInst/tableState/paymentTableInitialStateAndTypes';
import { PaymentsTableDataState, PaymentsTablePageRequest } from './paymentsTableTypes';
import { PaymentsDataTableState } from './paymentsTableReducer';

const getFilterDate = (
  state: PaymentsTableDataState,
  value: PaymentDataHeaderField
): string | null => {
  return state.customFilter[value][0]?.trim()
    ? moment(new Date(state.customFilter[value][0].trim())).format('YYYY-MM-DD')
    : null;
};

const getFilterString = (
  state: PaymentsTableDataState,
  value: PaymentDataHeaderField
): string | null => state.customFilter[value][0]?.trim() || null;

const getFilterNumber = (
  state: PaymentsTableDataState,
  value: PaymentDataHeaderField
): number | null => Number(state.customFilter[value][0]) || null;

const getSorting = (
  state: PaymentsTableDataState,
  value: PaymentDataHeaderField
): SortOrder | null => (state.sortBy === value ? state.order : null);

export const getPaymentsPageRequest = (state: PaymentsTableDataState): PaymentsTablePageRequest => {
  return {
    filterVirsId: getFilterNumber(state, 'virsName'),
    filterDecisionDate: getFilterDate(state, 'decisionDate'),
    filterDocumentNumber: getFilterString(state, 'paymentDocuments'),
    filterOutletId: getFilterNumber(state, 'paymentOutlets'),
    filterPaymentAmount: getFilterNumber(state, 'paymentAmount'),
    filterValidFrom: getFilterDate(state, 'validFrom'),
    filterValidTo: getFilterDate(state, 'validTo'),
    pageNr: state.rowsPerPage * state.page + 1,
    pageSize: state.rowsPerPage,
    sortingDecisionDate: getSorting(state, 'decisionDate'),
    sortingDocumentNumber: getSorting(state, 'paymentDocuments'),
    sortingOutletName: getSorting(state, 'paymentOutlets'),
    sortingPaymentAmount: getSorting(state, 'paymentAmount'),
    sortingValidFrom: getSorting(state, 'validFrom'),
    sortingValidTo: getSorting(state, 'validTo'),
    sortingVirsName: getSorting(state, 'virsName'),
    docStatusNotSigned: state.showStatusNotSigned,
    docStatusRelevant: state.showStatusRelevant,
    docStatusOutdated: state.showStatusOutdated
  };
};

export const selectPaymentsTableDataState = (state: ApplicationState): PaymentsDataTableState =>
  state.paymentTableData;
