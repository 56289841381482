import moment from 'moment';
import * as Yup from 'yup';
import {
  MAXIMAL_RECORD_DATE_FUTURE,
  MINIMAL_RECORD_DATE_DEFAULT
} from '../../../utils/InputValueFunctions';
import { getDateString } from '../FormikFormDateField';

export function noUndefinedString() {
  return Yup.string().defined().nullable();
}

export function noUndefinedNumber() {
  return Yup.number().defined().nullable();
}

export const minDate = MINIMAL_RECORD_DATE_DEFAULT.date || moment('1800-01-01');
export const maxDate = MAXIMAL_RECORD_DATE_FUTURE.date || moment('2200-01-01');

export function defaultDate(schema?: Yup.DateSchema<Date | null | undefined>) {
  return (schema || Yup.date())
    .typeError('Neteisinga data')
    .min(minDate.toDate(), `Negali būti ankstesnė nei ${getDateString(minDate.toDate())}`)
    .max(maxDate.toDate(), `Negali būti vėlesnė nei ${getDateString(maxDate.toDate())}`)
    .defined()
    .nullable();
}
