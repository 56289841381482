import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';

export const initialOutletEditionDataTableState: EditionDataTableState = {
  periodic: false,
  order: 'asc',
  sortBy: 'periodName',
  page: 0,
  recordsPassed: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    periodName: true,
    circulation: true,
    editionDate: true
  },
  customFilterOn: false,
  customFilter: {
    periodName: [],
    circulation: [],
    editionDate: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: true,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showNewRecord: false
};

export type EditionColumnHeaderField = 'periodName' | 'circulation' | 'editionDate';

export type EditionColumnsDisplayStatus = {
  periodName: boolean;
  circulation: boolean;
  editionDate: boolean;
};

export type EditionTableCustomFilter = {
  periodName: string[];
  circulation: string[];
  editionDate: string[];
};

export type EditionColumnHeader = ColumnHeader<EditionColumnHeaderField>;

export interface EditionDataTableState {
  periodic: boolean;
  sortBy: EditionColumnHeaderField;
  order: SortOrder;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: EditionColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: EditionTableCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showNewRecord: boolean;
}

export type EditionTableAction =
  | {
      type: 'EDITION_CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: EditionColumnHeaderField;
      value: string | null;
    }
  | { type: 'EDITION_SORTING_CHANGED'; sortBy: EditionColumnHeaderField }
  | {
      type: 'EDITION_COLUMN_DISPLAY_TOGGLED';
      column: EditionColumnHeaderField;
    };

export const editionTablecolumns: EditionColumnHeader[] = [
  {
    id: 'periodName',
    label: 'Laikotarpis'
  },
  {
    id: 'editionDate',
    label: 'Tiražo data'
  },
  {
    id: 'circulation',
    label: 'Leidinio tiražas, egz.'
  }
];
