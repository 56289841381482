import React from 'react';
import { Typography, TextField } from '@material-ui/core';

interface Prop {
  label: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  errorText?: string;
}

const LabelInputField: React.FC<Prop> = ({ label, value, onChange, errorText }) => {
  return (
    <div>
      <Typography variant="h5">
        <b>{label}</b>
      </Typography>
      <TextField
        id="email-input"
        value={value}
        onChange={(e) => onChange(e)}
        color="primary"
        helperText={errorText}
        error={!!errorText}
        variant="outlined"
        style={{
          width: '100%',
          marginTop: 10
        }}
      />
    </div>
  );
};

export default LabelInputField;
