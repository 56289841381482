import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ConfirmRemoveDialog } from '../../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import {
  deleteShareholderDateRequest,
  resetShareholderDateDeletionDialog
} from '../../../../store/shareholders/shareholdersActions';
import { ApplicationState } from '../../../../store';
import { getSubsystem } from '../../../../utils/roleHelper';

const DeleteDateDialog: React.FC = () => {
  const reduxDispatch = useDispatch();
  const {
    shareholdersData: {
      dateDeletionError,
      showDateDeletionDialog,
      selectedDateForFunctions,
      loadingDateDeletion
    },
    virsis: { virsData, currentUser, selectedVirsId }
  } = useSelector((state: ApplicationState) => state);

  const subsystem = getSubsystem(currentUser);
  const history = useHistory();
  const close = () => {
    reduxDispatch(resetShareholderDateDeletionDialog());
  };

  const deleteShareholderDate = () => {
    if (virsData?.virsId && selectedDateForFunctions) {
      reduxDispatch(deleteShareholderDateRequest(virsData.virsId, selectedDateForFunctions));
      if (subsystem === 'VIRSIS') {
        history.push('/duomenu-perziura-ir-teikimas/dalyviai');
      } else {
        history.push(`/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/dalyviai`);
      }
    }
  };

  return (
    <ConfirmRemoveDialog
      open={showDateDeletionDialog}
      dialogTitle="Datos pašalinimas"
      dialogText="Ar tikrai norite pašalinti pažymėtą datą?"
      mainButtonText="Pašalinti datą"
      onClose={close}
      onSubmit={deleteShareholderDate}
      isProcessing={loadingDateDeletion}
      errorMessage={`Klaida. ${dateDeletionError?.message}` || ''}
      isError={!!dateDeletionError}
      onErrorClose={close}
    />
  );
};

export default DeleteDateDialog;
