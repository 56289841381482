import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import InstitutionProvidedDataPage from '../../../../pages/virs/InstitutionProvidedDataPage';
import ExternalSystemDataPage from '../../../../pages/virs/ExternalSystemDataPage';
import SecondaryVirsDataEditingTabMenuHeader from '../navigation/VirsDataSubmissionSecondaryHeader';
import VirsDataProvidedNavigation from '../navigation/VirsDataProvidedNavigation';

const VirsDataProvidedContainer: React.FC = () => {
  const match = useRouteMatch();

  return (
    <>
      <SecondaryVirsDataEditingTabMenuHeader />
      <VirsDataProvidedNavigation />
      <Switch>
        <Route path={`${match?.url}/instituciju-įstaigų`}>
          <InstitutionProvidedDataPage />
        </Route>
        <Route path={`${match?.url}/išorinių-sistemų`}>
          <ExternalSystemDataPage kmSubsystem />
        </Route>
      </Switch>
    </>
  );
};

export default VirsDataProvidedContainer;
