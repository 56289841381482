import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Button,
  Divider,
  DialogContent,
  Box,
  DialogProps,
  DialogActions,
  makeStyles,
  createStyles
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { PdfPreview } from '../../components/PdfPreview/PdfPreview';
import { DocumentValidationError } from '../../store/document/documentTypes';
import { PaginationJumpPage } from '../../components/TablePagination/PaginationJumpPage';
import { PaginationIteratePage } from '../../components/TablePagination/PaginationIteratePage';
import downloadPdf from '../../utils/PdfDownload';
import { PreviewErrorsPanel } from './PreviewErrorsPanel';
import { CloseDialogButton } from '../../components/Dialogs/Dialog/CloseButton';
import { AlertDialog } from '../../components/Dialogs/AlertDialog';
import { CloseContinueButton } from '../../components/Dialogs/Dialog/CloseContinueButton';
import { WordStatus } from '../../utils/tableTypes';

type Props = DialogProps & {
  onSignButtonClicked: () => void;
  documentPath: string;
  documentErrors?: DocumentValidationError[];
  disableSign: boolean;
  documentStatus: WordStatus;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonBox: {
      display: 'flex'
    },
    paginationBox: {
      display: 'flex'
    },
    [theme.breakpoints.down('sm')]: {
      paginationBox: {
        '& .pagination-item': {
          margin: 'auto 10px'
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      buttonBox: {
        width: '100%',
        display: 'contents',
        '& button': {
          width: '100%'
        }
      },
      paginationBox: {
        '& .pagination-item': {
          margin: 'auto 0'
        }
      }
    }
  })
);

export const PreviewDocumentDialog: React.FC<Props> = ({
  onSignButtonClicked,
  documentPath,
  documentErrors,
  disableSign,
  documentStatus,
  ...props
}) => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [page, setPage] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(true);

  const handleDownload = () => {
    downloadPdf(documentPath);
  };

  const warnings = documentErrors?.filter(({ priority }) => priority === 'W') || [];
  const classes = useStyles();

  const handleConfirmationClose = () => {
    setIsOpen(false);
  };

  const handleConfirmationOpen = () => {
    setIsOpen(true);
  };

  const handleConfirmation = () => {
    handleConfirmationClose();
    onSignButtonClicked();
  };

  return (
    <Dialog {...props}>
      <AlertDialog
        isOpen={isOpen}
        dialogText="Po pasirašymo duomenys bus paviešinti. Ar tikrai norite pasirašyti nepilnai suvestus duomenis?"
        onContinue={handleConfirmation}
        onClose={handleConfirmationClose}
        mainButtonText="Pasirašyti"
        closeButtonText="Uždaryti"
      />
      <div className="dialog-title">
        <Typography variant="h2">El. dokumentas</Typography>

        <Button onClick={() => handleDownload()} variant="outlined" color="primary">
          <GetAppIcon fontSize="small" style={{ marginRight: 5 }} />

          <Typography variant="h5" color="primary">
            Atsisiųsti
          </Typography>
        </Button>
      </div>

      <Divider light style={{ margin: '0px 35px' }} />

      <DialogContent>
        <div className="dialog-main-content">
          <Box className="dialog-main-content-box">
            <div
              style={{
                width: '600px',
                overflow: 'auto'
              }}
            >
              <PdfPreview
                width="600"
                page={page + 1}
                documentPath={documentPath}
                onDocumentLoad={({ pageCount: count }) => {
                  setPageCount(count);
                  setDocumentLoading(false);
                }}
              />
            </div>

            {documentStatus === 'RUOSIAMAS' && documentErrors && documentErrors.length > 0 && (
              <PreviewErrorsPanel
                errors={documentErrors.filter(({ priority }) => priority === 'E')}
                warnings={warnings}
                onErrorLinkClicked={(event) =>
                  props.onClose && props.onClose(event, 'escapeKeyDown')
                }
              />
            )}
          </Box>
        </div>
      </DialogContent>

      <DialogActions
        className="dialog-actions"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box className={classes.paginationBox}>
          {!documentLoading && (
            <>
              <PaginationIteratePage
                currentPage={page}
                previousPage={() => setPage(page - 1)}
                nextPage={() => setPage(page + 1)}
                pagesCount={pageCount}
              />

              <PaginationJumpPage
                currentPage={page}
                setPage={setPage}
                pagesCount={pageCount}
                disabled={false}
              />
            </>
          )}
        </Box>

        <Box className={classes.buttonBox}>
          <CloseDialogButton
            label="Uždaryti"
            onClick={(event) => {
              if (props.onClose) {
                props.onClose(event, 'escapeKeyDown');
              }
            }}
          />
          <CloseContinueButton
            label="Pasirašyti"
            onClick={warnings.length ? handleConfirmationOpen : onSignButtonClicked}
            disabled={disableSign}
            withIcon={true}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
