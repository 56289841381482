import { ColumnHeader } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';

export type EditionCheckDataTableHeaderType = ColumnHeader<EditionCheckDataVirsTableField>;

export type EditionCheckDataVirsTableField =
  | 'checkDate'
  | 'docNr'
  | 'outletName'
  | 'periodName'
  | 'conclusion'
  | 'checkedBy';

export interface EditionCheckDataTableState {
  customFilter: EditionCheckDataTableCustomFilter;
  customFilterOn: boolean;
  order: Order;
  sortBy: EditionCheckDataVirsTableField;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: EditionCheckDataTableColumnsDisplayStatus;
}

export interface EditionCheckDataTableCustomFilter {
  checkDate: string[];
  docNr: string[];
  outletName: string[];
  periodName: string[];
  conclusion: string[];
  checkedBy: string[];
}

export interface EditionCheckDocsCustomFilter {
  docNr: string[];
}

export interface EditionCheckVipsCustomFilter {
  outletName: string[];
  editionCheckConclusionsCustomFilter: EditionCheckConclusionsCustomFilter;
}

export interface EditionCheckConclusionsCustomFilter {
  periodName: string[];
  conclusion: string[];
}

export type EditionCheckDataTableColumnsDisplayStatus = {
  [key in EditionCheckDataVirsTableField]: boolean;
};

export type EditionCheckDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: EditionCheckDataVirsTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: EditionCheckDataVirsTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: EditionCheckDataVirsTableField };

export const editionCheckDataTableColumns: EditionCheckDataTableHeaderType[] = [
  {
    id: 'checkDate',
    label: 'Data',
    type: 'date'
  },
  {
    id: 'docNr',
    label: 'Nr.'
  },
  {
    id: 'outletName',
    label: 'Susijusios VIP'
  },
  {
    id: 'periodName',
    label: 'Tiražo laikotarpis'
  },
  {
    id: 'conclusion',
    label: 'Tiražo patikrinimo išvadą'
  },
  {
    id: 'checkedBy',
    label: 'Tiražą patikrino'
  }
];
