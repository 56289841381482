import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  DELETE_ARCHIVED_DATA_REQUEST,
  FETCH_ARCHIVED_DATA_REQUEST,
  FETCH_AUDITS_DATA_REQUEST,
  FETCH_AUDIT_ACTION_TYPES_REQUEST,
  FETCH_AUDIT_OUTLET_TYPES_REQUEST,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST
} from './auditsActionTypes';
import {
  getAuditOutletTypes,
  getAuditsData,
  getAuditActionTypes,
  getVirsisSendsToSystem,
  getVirsisReceivesFromSystem,
  getIntegrationalSystemAuditsData,
  getArchivedData,
  deleteArchivedData
} from './auditsApi';
import {
  deleteArchivedDataError,
  deleteArchivedDataSuccess,
  fetchArchivedDataError,
  fetchArchivedDataRequest,
  fetchArchivedDataSuccess,
  fetchAuditActionTypesError,
  fetchAuditActionTypesSuccess,
  fetchAuditOutletTypesError,
  fetchAuditOutletTypesSuccess,
  fetchAuditsDataError,
  fetchAuditsDataRequest,
  fetchAuditsDataSuccess,
  fetchIntegratedSystemsAuditsDataError,
  fetchIntegratedSystemsAuditsDataRequest,
  fetchIntegratedSystemsAuditsDataSuccess,
  fetchVirsisReceivesFromSystemError,
  fetchVirsisReceivesFromSystemSuccess,
  fetchVirsisSendsToSystemError,
  fetchVirsisSendsToSystemSuccess
} from './auditsActions';

function* handleAuditsDataFetchSaga(action: ActionType<typeof fetchAuditsDataRequest>) {
  try {
    const { data } = yield call(getAuditsData, action.payload);
    yield put(fetchAuditsDataSuccess(data.auditData));
  } catch (err) {
    yield put(fetchAuditsDataError(err.response.data));
  }
}

function* handleIntegratedSystemsAuditsDataFetchSaga(
  action: ActionType<typeof fetchIntegratedSystemsAuditsDataRequest>
) {
  try {
    const { data } = yield call(getIntegrationalSystemAuditsData, action.payload);
    yield put(fetchIntegratedSystemsAuditsDataSuccess(data.integratedSystemsAuditData));
  } catch (err) {
    yield put(fetchIntegratedSystemsAuditsDataError(err.response.data));
  }
}

function* handleAuditOutletTypesFetchSaga() {
  try {
    const { data } = yield call(getAuditOutletTypes);
    yield put(fetchAuditOutletTypesSuccess(data.auditOutletTypes));
  } catch (err) {
    yield put(fetchAuditOutletTypesError(err));
    yield put(sendMessage('error', 'Klaida! Nepavyko gauti audito VIP rūšių'));
  }
}

function* handleAuditActionsFetchSaga() {
  try {
    const { data } = yield call(getAuditActionTypes);
    yield put(fetchAuditActionTypesSuccess(data.auditActionTypes));
  } catch (err) {
    yield put(fetchAuditActionTypesError(err));
    yield put(sendMessage('error', 'Klaida! Nepavyko gauti veiksmų tipų'));
  }
}
function* handleVirsisSendsToSystemFetchSaga() {
  try {
    const { data } = yield call(getVirsisSendsToSystem);
    yield put(fetchVirsisSendsToSystemSuccess(data.integratedSystemsVirsisSendsDataTo));
  } catch (err) {
    yield put(fetchVirsisSendsToSystemError(err));
    yield put(sendMessage('error', 'Klaida! Nepavyko gauti sistemų sąrašo'));
  }
}

function* handleVirsisReceivesFromSystemFetchSaga() {
  try {
    const { data } = yield call(getVirsisReceivesFromSystem);
    yield put(fetchVirsisReceivesFromSystemSuccess(data.integratedSystemsVirsisReceivesDataFrom));
  } catch (err) {
    yield put(fetchVirsisReceivesFromSystemError(err));
    yield put(sendMessage('error', 'Klaida! Nepavyko gauti sistemų sąrašo'));
  }
}

function* handleFetchArchivedDataSaga(action: ActionType<typeof fetchArchivedDataRequest>) {
  try {
    const {
      data: { archivedData }
    } = yield call(getArchivedData);
    const file = new Blob([archivedData], { type: 'application/xml' });
    saveAs(file, 'data.xml');
    yield put(fetchArchivedDataSuccess());
  } catch (err) {
    yield put(fetchArchivedDataError(err));
    yield put(
      sendMessage(
        'error',
        err.response?.data.message || 'Klaida! Nepavyko gauti archyvuotų duomenų'
      )
    );
  }
}

function* handleDeleteArchivedDataSaga() {
  try {
    yield call(deleteArchivedData);
    yield put(deleteArchivedDataSuccess());
    yield put(sendMessage('success', 'Archyvuoti duomenys sunaikinti'));
  } catch (err) {
    yield put(deleteArchivedDataError(err));
    yield put(
      sendMessage(
        'error',
        err.response?.data.message || 'Klaida! Nepavyko sunaikinti archyvuotų duomenų'
      )
    );
  }
}

function* AuditsDataSaga() {
  yield takeEvery(FETCH_AUDITS_DATA_REQUEST, handleAuditsDataFetchSaga);
  yield takeEvery(
    FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST,
    handleIntegratedSystemsAuditsDataFetchSaga
  );
  yield takeEvery(FETCH_AUDIT_OUTLET_TYPES_REQUEST, handleAuditOutletTypesFetchSaga);
  yield takeEvery(FETCH_AUDIT_ACTION_TYPES_REQUEST, handleAuditActionsFetchSaga);
  yield takeEvery(FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST, handleVirsisSendsToSystemFetchSaga);
  yield takeEvery(
    FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST,
    handleVirsisReceivesFromSystemFetchSaga
  );
  yield takeEvery(FETCH_ARCHIVED_DATA_REQUEST, handleFetchArchivedDataSaga);
  yield takeEvery(DELETE_ARCHIVED_DATA_REQUEST, handleDeleteArchivedDataSaga);
}

export default AuditsDataSaga;
