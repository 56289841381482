export interface RadioButtonValues {
  label: string;
  value: string | number;
  disabled?: boolean;
}

export const radioButtonTooltipText =
  'Keičiant dalies tipą, pokytis bus taikomas visiems tėvinio JA dalyviams.';

export const errorText = {
  foreignParentForm: 'Prašome pasirinkti bent vieną dalies tipą',
  parentForm: 'Prašome pasirinkti balsų dalies tipą'
};

export const radioGroupName = {
  shares: 'Akcijos/Įnašai:',
  votes: 'Balsai:'
};
