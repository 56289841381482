import React, { useReducer, useEffect } from 'react';

import { LicensesData } from '../../../store/externalSystemData/externalSystemDataTypes';
import {
  ColumnHeaderField,
  LicensesColumnHeader,
  RowsPerPageOptions
} from './tableState/tableTypes';
import { tableReducer } from './tableState/tableReducer';
import { initialState } from './tableState/tableInitialState';

import DataTable from './DataTable';
import { FileFormats } from '../../../utils/exporters/types';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import {
  getFilteredAndSortedData,
  getTableExportDefinition
} from './tableState/tableReducerFunctions';

interface Props {
  originalData: LicensesData[];
  columnNames: LicensesColumnHeader[];
  rowsPerPageOptions: RowsPerPageOptions;
}

const DataTableWrapper: React.FC<Props> = ({ originalData, columnNames, rowsPerPageOptions }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'SET_DATA', data: originalData });
    dispatch({ type: 'SYNC_TABLE_STATE' });
  }, [originalData]);

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' });
    dispatch({ type: 'SYNC_TABLE_STATE' });
  };

  const handleSetCustomFilter = (column: ColumnHeaderField, value: string | null) => {
    dispatch({ type: 'SET_CUSTOM_FILTER', filterBy: column, value });
    dispatch({ type: 'SYNC_TABLE_STATE' });
  };

  const handleSetSortByColumn = (column: ColumnHeaderField) => {
    dispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
    dispatch({ type: 'SYNC_TABLE_STATE' });
  };

  const toggleDisplayColumn = (column: ColumnHeaderField) => {
    dispatch({ type: 'TOGGLE_DISPLAY_COLUMN', column });
  };
  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
    dispatch({ type: 'SYNC_TABLE_STATE' });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
    dispatch({ type: 'SET_PAGE', page: 0 });
    dispatch({ type: 'SYNC_TABLE_STATE' });
  };

  const handleDownload = (format: FileFormats, navigationPath: string[]) => {
    exportTableData(
      format,
      getTableExportDefinition(state),
      getFilteredAndSortedData(
        state.customFilterOn,
        state.data,
        state.customFilter,
        state.order,
        state.sortBy
      ),
      navigationPath
    );
  };

  const handlePrint = (navigationPath: string[]) => {
    printTableData(
      getTableExportDefinition(state),
      getFilteredAndSortedData(
        state.customFilterOn,
        state.data,
        state.customFilter,
        state.order,
        state.sortBy
      ),
      navigationPath
    );
  };

  return (
    <DataTable
      tableState={state}
      columnNames={columnNames}
      rowsPerPageOptions={rowsPerPageOptions}
      onFilterTabToggle={toggleDisplayCustomFilter}
      onCustomFilterChange={handleSetCustomFilter}
      onSortByColumnClick={handleSetSortByColumn}
      onDisplayColumnChecked={toggleDisplayColumn}
      setPage={handleSetPage}
      setRowsPerPage={handleSetRowsPerPage}
      onDownload={handleDownload}
      onPrint={handlePrint}
    />
  );
};

export default DataTableWrapper;
