import React from 'react';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  ShareholdersProps,
  ShareholderPerson,
  ShareholdersData
} from '../../../../store/shareholders/shareholdersTypes';
import { groupRowColor, mainDarkColor } from '../../constants';
import GroupShareholderMemberName from '../../../../components/ShareholderComponents/GroupShareholderMemberName';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { filterErrorObjs } from '../../utilityFunctions/sharedFunctions';
import {
  useShareholdersSelectedDateTableState,
  useShareholdersSelectedDateTableDispatch
} from '../TableWithContext';
import { ShareholderRows } from './ShareholderRows';
import { checkIfRenderShareholderRow, getIsLastShareholder } from '../utilities/utilityFunctions';
import { EditShareholderRow } from './EditShareholderRow/EditShareholderRow';
import { SelectShareholderRow } from './SelectShareholderRow';
import {
  setExpandParents,
  setExpandShareholder,
  unsetUpdateShareholderId
} from '../../../../store/shareholders/shareholdersActions';
import { LevelBar } from '../../../../components/ShareholderComponents/TableButtons/LevelBar';
import { ApplicationState } from '../../../../store';

const useStyles = makeStyles({
  shareholdersColumn: {
    paddingLeft: '8px',
    verticalAlign: 'middle',
    position: 'relative'
  }
});

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  person: ShareholderPerson;
  previousDate?: string;
  followingDate?: string;
  selectedDate: string;
  parentShareholder: ShareholdersProps | ShareholdersData;
  virId: number;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  filteredShareholderLevel?: string;
}

export const ShareholderGroupRows: React.FC<Props> = ({
  index,
  shareholder,
  person,
  previousDate,
  followingDate,
  selectedDate,
  parentShareholder,
  virId,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  filteredShareholderLevel
}) => {
  const classes = useStyles();
  const { state: tableState } = useShareholdersSelectedDateTableState();
  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();
  const { expandedPersons } = useSelector((state: ApplicationState) => state.shareholdersData);
  const reduxDispatch = useDispatch();

  const { shareholderLevel } = shareholder;
  const { errors, personId } = person;
  const uniquePersonId = `${shareholderLevel}${personId}`;

  const showChildGroupShareholders = expandedPersons[uniquePersonId + '__' + index];

  const showAddShareholderButton =
    person.mayShareholderHaveChildren &&
    shareholder.closedWithDocument?.documentStatus !== 'PASIRASYTAS';

  const showSelectShareholderRow =
    tableState.showSelectShareholderRow &&
    tableState.parentPersonLevelIdToAddChild.level === shareholderLevel &&
    tableState.index === index &&
    tableState.parentPersonLevelIdToAddChild.id === personId;

  const showAddShareholderRow =
    tableState.showAddShareholderRow &&
    tableState.parentPersonLevelIdToAddChild.level === shareholderLevel &&
    tableState.parentPersonLevelIdToAddChild.id === personId;

  const handleShowGroupShareholders = () => {
    reduxDispatch(setExpandShareholder(undefined, uniquePersonId, index));

    if (filteredShareholderLevel) {
      tableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: true });
      tableDispatch({
        type: 'CUSTOM_FILTER_VALUE_CHANGED',
        filterBy: 'shareholderLevel',
        value: null
      });
    }
  };

  const handleSelectShareholderRowOpen = () => {
    tableDispatch({
      type: 'SET_PARENT_ID_TO_ADD_CHILD',
      id: shareholder.shareholderId,
      index: index
    });
    tableDispatch({
      type: 'SET_PARENT_PERSON_LEVEL_ID_TO_ADD_CHILD',
      id: personId,
      level: shareholderLevel
    });
    tableDispatch({
      type: 'SHOW_SELECT_SHAREHOLDER_ROW',
      showSelectShareholderRow: true
    });
    reduxDispatch(setExpandParents(undefined, [uniquePersonId]));
    reduxDispatch(unsetUpdateShareholderId());
  };

  const criticalErrorMessages = filterErrorObjs(errors, ErrorType.CRITICAL);
  const nonCriticalErrorMessages = filterErrorObjs(errors, ErrorType.NONCRITICAL);
  const infoNotifications = filterErrorObjs(errors, ErrorType.INFO);

  const areChildrenShareholdersToShow = (): boolean => {
    if (tableState.showNonParticipatingShareholders && person.shareholders.length >= 1) {
      return true;
    }
    if (!tableState.showNonParticipatingShareholders && person.shareholders.length >= 1) {
      return person.shareholders.some((child) => {
        return checkIfRenderShareholderRow(
          tableState.showNonParticipatingShareholders,
          child.shareholderStartDate,
          child.shareholderParentStartDate,
          child.shareholderStoppedParticipatingFromDate,
          selectedDate
        );
      });
    }
    return false;
  };

  const showTriangleButton = areChildrenShareholdersToShow();

  return (
    <>
      <TableRow style={{ backgroundColor: groupRowColor }}>
        <TableCell>
          <Typography variant="h5" style={{ color: mainDarkColor }}>
            {shareholder.shareholderLevel}
          </Typography>
        </TableCell>
        <TableCell className={classes.shareholdersColumn}>
          {showTriangleButton && (
            <LevelBar
              level={shareholderLevel}
              halfBottom
              showChildShareholder={showChildGroupShareholders}
              middle={false}
            />
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <GroupShareholderMemberName
              index={index}
              shareholder={shareholder}
              person={person}
              showTriangleButton={showTriangleButton}
              showChildShareholder={showChildGroupShareholders}
              handleShowGroupShareholders={handleShowGroupShareholders}
              showAddShareholderButton={showAddShareholderButton}
              criticalErrorMessages={criticalErrorMessages}
              nonCriticalErrorMessages={nonCriticalErrorMessages}
              infoNotifications={infoNotifications}
              handleAddShareholderButtonClicked={handleSelectShareholderRowOpen}
              selectedDate={selectedDate}
              marginLeft={getMarginLeft(shareholder.shareholderLevel)}
            />
          </div>
        </TableCell>

        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>

      {showSelectShareholderRow && (
        <SelectShareholderRow shareholderLevel={shareholder.shareholderLevel + 1} index={index} />
      )}

      {showAddShareholderRow && (
        <EditShareholderRow
          index={index}
          shareholderLevel={shareholderLevel + 1}
          parentLegalFormCode={person.personLegalFormCode}
          parentPersonId={personId}
          parentShareholder={person}
          mayShareholderHaveChildren={shareholder.mayShareholderHaveChildren}
        />
      )}

      {showChildGroupShareholders &&
        person.shareholders.map((childShareholder, newIndex) => (
          <ShareholderRows
            index={index + newIndex}
            parentShareholder={parentShareholder}
            groupMemberParentPerson={person}
            key={`${person.personId}${childShareholder.shareholderId}`}
            shareholder={childShareholder}
            previousDate={previousDate}
            followingDate={followingDate}
            selectedDate={selectedDate}
            virId={virId}
            isLastShareholder={getIsLastShareholder(newIndex, person.shareholders)}
            virsEndDate={virsEndDate}
            virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
            filteredShareholderLevel={filteredShareholderLevel}
          />
        ))}
    </>
  );
};
