import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';

import { loadData, setFormOpen, State } from './store';
import { LicencedEnterprise } from './types';
import { LicencedEnterpriseForm } from './LicencedEnterpriseForm';

export const LicencedEnterpriseTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const licencedEnterpriseTableDefinition: TableDefinition<Nullable<LicencedEnterprise>> =
    useMemo(() => {
      return {
        columnGroups: [
          {
            columns: [
              getNumericMappedColumn(
                'licencedEnterpriseId',
                'Kodas',
                (data) => data.licencedEnterpriseId
              ),
              getStringMappedColumn(
                'licenceTypeName',
                'LIS licencijuojamos veiklos pavadinimas',
                (data) => data.licenceTypeName,
                { cell: { width: undefined } },
                {
                  cell: { alignment: { horizontal: 'left' } },
                  column: { width: 45 }
                }
              ),
              getStringMappedColumn(
                'enterpriseTypeName',
                'VIRS veiklos rūšies pavadinimas',
                (data) => data.enterpriseTypeName,
                { cell: { width: undefined } },
                {
                  cell: { alignment: { horizontal: 'left' } },
                  column: { width: 45 }
                }
              )
            ]
          },
          {
            header: 'Galioja',
            columns: [
              getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
              getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
            ]
          }
        ],
        tableActions: {
          create: () => dispatch(setFormOpen(true)),
          edit: (data) => {
            dispatch(
              setFormOpen(true, {
                licencedEnterpriseId: data.licencedEnterpriseId,
                enterpriseTypeId: data.enterpriseTypeId,
                licenceTypeId: data.licenceTypeId,
                validFrom: (data.validFrom && new Date(data.validFrom)) || null,
                validTo: (data.validTo && new Date(data.validTo)) || null
              })
            );
          }
        },
        actionLabels: {
          create: 'Pridėti naują licencijuojamą veiklą'
        }
      };
    }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={licencedEnterpriseTableDefinition} {...dataState} />

      <LicencedEnterpriseForm />
    </>
  );
};
