import { FileType } from './types';

export const getFileName = (fileType: FileType | null) => {
  switch (fileType) {
    case 'NAUDOTOJO_VADOVAS_ADMIN':
      return 'VIRSIS administratoriaus vadovas';
    case 'NAUDOTOJO_VADOVAS_VIEA':
      return 'VIEA naudotojo vadovas';
    case 'NAUDOTOJO_VADOVAS_VIRS':
      return 'VIRS naudotojo vadovas';
    case 'NAUDOTOJO_VADOVAS_LRTK_ZEIT':
      return 'LRTK ir ŽEIT naudotojo vadovas';
    case 'NAUDOTOJO_VADOVAS_KM':
      return 'KM naudotojo vadovas';
    case 'NAUDOTOJO_VADOVAS_ISTAIGA':
      return 'Įstaigos naudotojo vadovas';
    case 'NAUDOTOJO_VADOVAS_VIRS_VIEW':
      return 'VIRS duomenų peržiūros vadovas';
    case 'NAUDOJIMO_SALYGOS':
    default:
      return 'Naudojimo salygos';
  }
};
