import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, Typography, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    goBackBtn: {
      color: '#637082',
      fontWeight: 800,
      padding: '15px'
    },
    actionArrow: {
      fontSize: '18px',
      marginLeft: '10px',
      fontWeight: 800
    }
  })
);

interface Props {
  onClick?: () => void;
  btnText: string;
  disabled?: boolean;
}

const GoToPageButton: React.FC<Props> = ({ onClick, btnText, disabled }) => {
  const classes = useStyles();
  return (
    <Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
      <Typography variant="h5">{btnText}</Typography>
      <ArrowForwardIcon className={classes.actionArrow} />
    </Button>
  );
};

export default GoToPageButton;
