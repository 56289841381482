import React from 'react';
import { useSelector } from 'react-redux';
import { FindByPersonOrLegalDialogContainer } from '../../../../../components/FindPersonDialog/FindByPersonOrLegalDialogContainer';
import { ApplicationState } from '../../../../../store';
import {
  useShareholdersSelectedDateTableState,
  useShareholdersSelectedDateTableDispatch
} from '../../TableWithContext';
import { getLegalFormType } from '../../utilities/utilityFunctions';

export const FindPersonDialog: React.FC = () => {
  const {
    virsis: { selectedPersonRepresentative, selectedLegalRepresentative },
    classifiers: { legalFormTypes },
    shareholdersData: { shareholderToEditId }
  } = useSelector((state: ApplicationState) => state);

  const {
    state: { isPersonSearchDialogOpen, index }
  } = useShareholdersSelectedDateTableState();

  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();

  const canLegalPersonBeAdded = (
    legalFormCode: number,
    personType: string,
    personTypeCode: number
  ) => {
    if (personType === 'UJA' || personTypeCode === 10) {
      return true;
    }
    if (legalFormTypes && personType === 'LJA') {
      const isLegalFormFoundInClassifier = getLegalFormType(legalFormCode, legalFormTypes);
      return !!isLegalFormFoundInClassifier;
    }
    return false;
  };

  const handleCloseFindPersonDialog = () => {
    tableDispatch({
      type: 'IS_FIND_PERSON_DIALOG_OPEN',
      isPersonSearchDialogOpen: false
    });
  };

  const handleFindPersonSubmit = () => {
    const personToAdd = selectedPersonRepresentative || selectedLegalRepresentative;

    if (personToAdd) {
      handleCloseFindPersonDialog();
      if (shareholderToEditId) {
        tableDispatch({
          type: 'SHOW_EDIT_SHAREHOLDER_ROW',
          showEditShareholderRow: true,
          shareholderToEditList: [],
          index,
          personToAdd
        });
      }
      if (!shareholderToEditId) {
        tableDispatch({
          type: 'SHOW_ADD_SHAREHOLDER_ROW',
          showAddShareholderRow: true,
          personToAdd
        });
      }
    }
  };

  return (
    <FindByPersonOrLegalDialogContainer
      dialogOpen={isPersonSearchDialogOpen}
      closeDialog={handleCloseFindPersonDialog}
      closeAndContinueAction={handleFindPersonSubmit}
      checkIfPersonCanBeSelected={(
        legalFormCode: number,
        personType: string,
        personTypeCode: number
      ) => canLegalPersonBeAdded(legalFormCode, personType, personTypeCode)}
      tooltipTextOnToggleButton="Šio juridinio asmens negalima sukurti kaip dalyvio"
    />
  );
};
