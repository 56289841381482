import React, { useEffect, useReducer, useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ApplicationState } from '../../../store';
import { VirsSearchData } from '../../../store/virsis/dataTypes';

import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import {
  initialMisconductEditRowState,
  ProfessionalMisconductOutletState,
  DeterminedMisconductState
} from './rowState/misconductInitialStateAndTypes';
import {
  ProfessionalMisconductDataInst,
  ProfessionalMisconductRecord,
  ProfessionalMisconductSanctionType,
  ProfessionalMisconductType
} from '../../../store/professionalMisconducts/professionalMisconductsTypes';
import {
  getProfessionalMisconductInstDataRequest,
  updateProfessionalMisconduct,
  resetProfessionalMisconductUpdatingState,
  resetProfessionalMisconductRemovingState,
  removeProfessionalMisconduct,
  resetAnnulProfessionalMisconductState,
  annulProfessionalMisconduct
} from '../../../store/professionalMisconducts/professionalMisconductsActions';
import {
  mapIntoProfessionalMisconductOutletsRecordObject,
  validateEditedMisconductRecordState
} from './rowState/misconductRowReducerFunctions';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { ProfessionalMisconductBasicInstRow } from './BasicRow';
import { editMisconductReducer } from './rowState/editMisconductReducer';
import { AnnulDocumentButton } from '../../../components/TableButtons/RowActionButtons/AnnulDocumentButton';
import { SignDocumentButton } from '../../../components/TableButtons/RowActionButtons/SignDocumentButton';
import { useProfessionalMisconductInstTableState } from '../Context';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import PreviewAndSignDocumentDialog from '../../document/PreviewAndSignDocumentDialog';
import { DocumentNumberInputCell } from '../../../components/TableInputs/DocumentNumberInput';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import { AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { getOutletOption } from '../../../components/TableInputs/Utilities';
import { ViewDocumentButton } from '../../../components/TableButtons/RowActionButtons/ViewDocumentButton';
import { AuthorisedEditAndRemoveActions } from '../../../components/TableRowActions/AuthorisedEditAndRemoveActions';

interface Props {
  record: ProfessionalMisconductDataInst;
}

export const MisconductInstRowContainer: React.FC<Props> = ({ record }) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleDialogClose = () => setOpenDialog(false);

  const [rowState, rowDispatch] = useReducer(editMisconductReducer, initialMisconductEditRowState);

  const { state: tableState } = useProfessionalMisconductInstTableState();

  const {
    professionalMisconductData: {
      professionalMisconductTypes,
      professionalMisconductSanctionTypes,
      isProfessionalMisconductUpdated,
      updatingProfessionalMisconductError,
      removingProfessionalMisconduct,
      isProfessionalMisconductRemoved,
      removingProfessionalMisconductError,
      annullingProfessionalMisconductRecord,
      annullingProfessionalMisconductError
    },
    classifiers: { enterpriseTypes, fictitiousOutlet, annulmentTypes }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  function startEditing() {
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      misconductTypes: professionalMisconductTypes || [],
      sanctionTypes: professionalMisconductSanctionTypes || [],
      fictitiousOutlet: fictitiousOutlet || []
    });
  }

  function stopEditing() {
    rowDispatch({
      type: 'EDITING_CANCELLED'
    });
  }

  function openFindVirsDialog() {
    rowDispatch({
      type: 'VIRS_INPUT_CLICKED'
    });
  }

  function useSelectedVirs(virs: VirsSearchData) {
    rowDispatch({
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
      virs
    });
  }

  function closeFindVirsDialog() {
    rowDispatch({
      type: 'FIND_VIRS_DIALOG_CLOSED'
    });
  }

  function setDecisionDate(decisionDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'DECISION_DATE_CHANGED', decisionDate });
  }

  function changeDocumentNumber(documentNumber: string) {
    rowDispatch({ type: 'DOCUMENT_NUMBER_CHANGED', documentNumber });
  }

  function addOutlet() {
    rowDispatch({ type: 'OUTLET_FIELD_ADDED' });
  }

  function changeOutlet(outletIndex: number) {
    return (selectedValue: OutletShortData | null) => {
      rowDispatch({
        type: 'OUTLET_CHANGED',
        outlet: selectedValue,
        outletIndex
      });
    };
  }

  function removeOutlet(outletIndex: number) {
    return () => rowDispatch({ type: 'OUTLET_FIELD_REMOVED', outletIndex });
  }

  function addMisconduct(outletIndex: number) {
    return () => {
      rowDispatch({ type: 'MISCONDUCT_FIELD_ADDED', outletIndex });
    };
  }

  function changeMisconduct(outletIndex: number, misconductIndex: number) {
    return (selectedValue: ProfessionalMisconductType | null) => {
      rowDispatch({
        type: 'MISCONDUCT_CHANGED',
        misconduct: selectedValue,
        outletIndex,
        misconductIndex
      });
    };
  }

  function removeMisconduct(outletIndex: number, misconductIndex: number) {
    return () =>
      rowDispatch({
        type: 'MISCONDUCT_FIELD_REMOVED',
        outletIndex,
        misconductIndex
      });
  }

  function addSanction(outletIndex: number, misconductIndex: number) {
    return () =>
      rowDispatch({
        type: 'SANCTION_FIELD_ADDED',
        outletIndex,
        misconductIndex
      });
  }

  function changeSanction(outletIndex: number, misconductIndex: number, sanctionIndex: number) {
    return (selectedValue: ProfessionalMisconductSanctionType | null) => {
      rowDispatch({
        type: 'SANCTION_CHANGED',
        sanction: selectedValue,
        outletIndex,
        misconductIndex,
        sanctionIndex
      });
    };
  }

  function removeSanction(outletIndex: number, misconductIndex: number, sanctionIndex: number) {
    return () =>
      rowDispatch({
        type: 'SANCTION_FIELD_REMOVED',
        outletIndex,
        misconductIndex,
        sanctionIndex
      });
  }

  function setValidFromDate(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_FROM_CHANGED', validFrom });
  }

  function setValidToDate(validTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_TO_CHANGED', validTo });
  }

  function updateMisconductRecord() {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateEditedMisconductRecordState(rowState).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const misconductRecord: ProfessionalMisconductRecord = {
        virsId: rowState.virs.value ? rowState.virs.value.virId : null,
        decisionDate: rowState.decisionDate.value
          ? rowState.decisionDate.value.format('L').toString()
          : '',
        documentNumber: rowState.documentNumber.value ? rowState.documentNumber.value : '',
        professionalMisconductOutlets: mapIntoProfessionalMisconductOutletsRecordObject(rowState),
        validFrom: rowState.validFrom.value ? rowState.validFrom.value.format('L').toString() : '',
        validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : '',
        roleNumber: null
      };
      reduxDispatch(
        updateProfessionalMisconduct(misconductRecord, record.professionalMisconductId)
      );
    }
  }

  function onRemoveRecordClicked() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function removeRecord() {
    reduxDispatch(removeProfessionalMisconduct(record.professionalMisconductId));
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  function closeOnRemoveError() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetProfessionalMisconductRemovingState());
  }

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || []
    });
  }

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulProfessionalMisconductState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    reduxDispatch(annulProfessionalMisconduct(record.documentStatusId, annulRecord));
    closeAnnulDocumentDialog();
  }

  useEffect(() => {
    if (isProfessionalMisconductUpdated) {
      rowDispatch({ type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
      reduxDispatch(resetProfessionalMisconductUpdatingState());
      reduxDispatch(getProfessionalMisconductInstDataRequest());
    }
  }, [reduxDispatch, isProfessionalMisconductUpdated]);

  useEffect(() => {
    if (updatingProfessionalMisconductError) {
      rowDispatch({ type: 'STOP_LOADING' });
      reduxDispatch(resetProfessionalMisconductUpdatingState());
    }
  }, [reduxDispatch, updatingProfessionalMisconductError]);

  const totalRowSpan = () => {
    let rowSpan = 0;
    rowState.outlets.forEach((outlet) => {
      outlet.misconducts.forEach((misconduct) => {
        rowSpan += misconduct.sanctions.length;
      });
    });
    return rowSpan;
  };

  const outletRowSpan = (outlet: ProfessionalMisconductOutletState) => {
    let rowSpan = 0;
    outlet.misconducts.forEach((misconduct: DeterminedMisconductState) => {
      rowSpan += misconduct.sanctions.length;
    });
    return rowSpan;
  };

  const rowsInEdit: JSX.Element[] = [];

  let rowIndex = 0;
  rowState.outlets.forEach((outlet, outletIndex) => {
    outlet.misconducts.forEach((misconduct, misconductIndex) => {
      misconduct.sanctions.forEach((_sanction, sanctionIndex) => {
        rowsInEdit.push(
          <TableRow key={`new-misconduct-${rowIndex}`}>
            {rowIndex === 0 && (
              <>
                {tableState.columnsDisplayStatus.virsName && (
                  <VirsInputCell
                    openSearchDialog={openFindVirsDialog}
                    state={rowState.virs}
                    rowSpan={totalRowSpan()}
                  />
                )}
                {tableState.columnsDisplayStatus.decisionDate && (
                  <DateInputImprovedCell
                    state={rowState.decisionDate}
                    setDate={setDecisionDate}
                    isRequired
                    rowSpan={totalRowSpan()}
                  />
                )}
                {tableState.columnsDisplayStatus.documentNumber && (
                  <DocumentNumberInputCell
                    setValue={changeDocumentNumber}
                    inputState={rowState.documentNumber}
                    rowSpan={totalRowSpan()}
                    isRequired
                  />
                )}
              </>
            )}
            {misconductIndex === 0 &&
              sanctionIndex === 0 &&
              tableState.columnsDisplayStatus.outletName && (
                <DropdownInputCell
                  selectValue={changeOutlet(outletIndex)}
                  dropdownState={rowState.outlets[outletIndex].outlet}
                  getOptionSelected={(option, value) =>
                    value ? option.outletId === value.outletId : false
                  }
                  getOptionLabel={(option) =>
                    `${option?.outletName} ${option?.outletClosureStatus ? '(Uždarytas)' : ''}`
                  }
                  addUnit={addOutlet}
                  removeUnit={removeOutlet(outletIndex)}
                  showRemoveButton={rowIndex !== 0}
                  rowSpan={outletRowSpan(outlet)}
                  renderOption={getOutletOption}
                />
              )}
            {sanctionIndex === 0 && tableState.columnsDisplayStatus.professionalMisconduct && (
              <DropdownInputCell
                selectValue={changeMisconduct(outletIndex, misconductIndex)}
                dropdownState={
                  rowState.outlets[outletIndex].misconducts[misconductIndex].misconduct
                }
                getOptionLabel={(misc) => misc.misconductTypeName}
                getOptionSelected={(option, value) =>
                  value ? option.misconductTypeId === value.misconductTypeId : false
                }
                addUnit={addMisconduct(outletIndex)}
                removeUnit={removeMisconduct(outletIndex, misconductIndex)}
                showRemoveButton={misconductIndex !== 0}
                rowSpan={misconduct.sanctions.length}
              />
            )}
            {tableState.columnsDisplayStatus.sanction && (
              <DropdownInputCell
                selectValue={changeSanction(outletIndex, misconductIndex, sanctionIndex)}
                dropdownState={
                  rowState.outlets[outletIndex].misconducts[misconductIndex].sanctions[
                    sanctionIndex
                  ].sanction
                }
                getOptionLabel={(sanct) => sanct.sanctionTypeName}
                getOptionSelected={(option, value) =>
                  value ? option.sanctionTypeId === value.sanctionTypeId : false
                }
                addUnit={addSanction(outletIndex, misconductIndex)}
                removeUnit={removeSanction(outletIndex, misconductIndex, sanctionIndex)}
                showRemoveButton={sanctionIndex !== 0}
                rowSpan={1}
              />
            )}
            {rowIndex === 0 && (
              <>
                {tableState.columnsDisplayStatus.validFrom && (
                  <DateInputImprovedCell
                    rowSpan={totalRowSpan()}
                    state={rowState.validFrom}
                    setDate={setValidFromDate}
                    isRequired
                  />
                )}
                {tableState.columnsDisplayStatus.validTo && (
                  <DateInputImprovedCell
                    rowSpan={totalRowSpan()}
                    state={rowState.validTo}
                    setDate={setValidToDate}
                  />
                )}
                <TableCell align="right" rowSpan={totalRowSpan()}>
                  <SaveAndCancelActions
                    handleCancelButtonClick={stopEditing}
                    handleConfirmationYes={updateMisconductRecord}
                    isProcessing={rowState.loading}
                  />
                </TableCell>
                <TableCell align="left" rowSpan={totalRowSpan()} />
              </>
            )}
          </TableRow>
        );
        rowIndex++;
      });
    });
  });

  return (
    <>
      {!rowState.editingOn && (
        <ProfessionalMisconductBasicInstRow
          key={record.professionalMisconductId}
          record={record}
          actions={
            record.documentStatusId &&
            record.removable && (
              <AuthorisedEditAndRemoveActions
                handleEditButtonClick={startEditing}
                handleRemoveButtonClick={onRemoveRecordClicked}
                confirmationOn={rowState.removeRecordConfirmationOn}
                handleConfirmationYes={removeRecord}
                handleConfirmationNo={closeRemoveConfirmation}
                isProcessing={removingProfessionalMisconduct}
                isSuccess={isProfessionalMisconductRemoved}
                error={removingProfessionalMisconductError}
                onErrorClose={closeOnRemoveError}
                recordCreatedByCode={record.determinedByCode}
                editingWarningMessage={record.editingWarningMessage}
              />
            )
          }
          eDocumentActions={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {record.documentStatus.id == 'PASIRASYTAS' && (
                <>
                  <ViewDocumentButton onClick={() => setOpenDialog(true)} />
                  <AnnulDocumentButton
                    annulmentDisablementReason={record.annulmentDisablementReason}
                    onClick={openAnnulDocumentDialog}
                  />
                </>
              )}
              {record.removable && <SignDocumentButton onClick={() => setOpenDialog(true)} />}
            </div>
          }
        />
      )}
      <PreviewAndSignDocumentDialog
        openDialog={openDialog}
        documentPath={`misconduct-data/documents/${record.documentStatusId}`}
        documentId={record.documentStatusId}
        documentStatus={record.documentStatus.id}
        onClose={handleDialogClose}
        documentErrors={[]}
        onSignSuccess={() => reduxDispatch(getProfessionalMisconductInstDataRequest())}
      />
      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingProfessionalMisconductError}
        isProcessing={annullingProfessionalMisconductRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingProfessionalMisconductError}
      />
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={closeFindVirsDialog}
          closeAndContinueWithVirs={useSelectedVirs}
        />
      )}
      {rowState.editingOn && rowsInEdit}
    </>
  );
};
