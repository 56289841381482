import moment from 'moment';
import {
  MAXIMAL_RECORD_DATE_FUTURE,
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  setDocumentNumberAndValidate,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../utils/InputValueFunctions';
import {
  initialMisconductEditRowState,
  MisconductEditRowAction,
  MisconductEditRowState
} from './misconductInitialStateAndTypes';
import {
  addOutletField,
  setAndValidateOutletAndUpdateOthersOptions,
  setAndValidateMisconductAndUpdateOthersOptions,
  setAndValidateSanctionAndUpdateOthersOptions,
  removeOutletAndUpdateOthersOptions,
  removeMisconductAndUpdateOthersOptions,
  removeSanctionAndUpdateOthersOptions,
  addMisconductField,
  addSanctionField,
  setMisconductVirsAndUpdateOptions,
  initRecordsOutletsToRowState,
  validateEditedMisconductRecordState,
  setDynamicValidationConstraints
} from './misconductRowReducerFunctions';

export const editMisconductReducer = (
  state: MisconductEditRowState,
  action: MisconductEditRowAction
): MisconductEditRowState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return setDynamicValidationConstraints({
        ...state,
        editingOn: true,
        showFindVirsDialog: false,
        misconductTypes: action.misconductTypes,
        sanctionTypes: action.sanctionTypes,
        fictitiousOutlet: action.fictitiousOutlet[0],
        virs: {
          ...state.virs,
          value: {
            ...state.virs.value,
            virId: action.record.virsId,
            personFullName: action.record.virsName,
            personCode: String(action.record.virsLegalCode),
            outlets: action.record.outlets,
            virsLegalRegistrationDate: '',
            virsLegalDeregistrationDate: ''
          },
          validated: true
        },
        decisionDate: {
          ...state.decisionDate,
          value: action.record.decisionDate ? moment(action.record.decisionDate) : null,
          validated: true,
          maxDates: [
            {
              id: 'validFrom',
              date: moment(action.record.validFrom),
              text: 'Data negali būti vėlesnė už įrašo galiojimo pradžios datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        documentNumber: {
          value: String(action.record.documentNumber),
          id: 'customName',
          error: false,
          helperText: 'Dokumento numeris',
          placeholder: '',
          validated: true
        },
        outlets: initRecordsOutletsToRowState(
          action.record,
          action.misconductTypes,
          action.sanctionTypes,
          action.fictitiousOutlet[0]
        ),
        validFrom: {
          ...state.validFrom,
          value: action.record.validFrom ? moment(action.record.validFrom) : null,
          validated: true,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'decisionDate',
              date: moment(action.record.decisionDate),
              text: 'Data negali būti ankstesnė už sprendimo datą'
            }
          ],
          maxDates: [
            {
              id: 'validTo',
              date: moment(action.record.validTo),
              text: 'Data negali būti vėlesnė už įrašo galiojimo pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          value: action.record.validTo ? moment(action.record.validTo) : null,
          validated: true,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'validFrom',
              date: moment(action.record.validFrom),
              text: 'Data negali būti ankstesnė už įrašo galiojimo pradžios datą'
            }
          ],
          maxDates: [MAXIMAL_RECORD_DATE_FUTURE]
        }
      });
    case 'EDITING_CANCELLED':
      return initialMisconductEditRowState;
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return setDynamicValidationConstraints({
        ...state,
        ...setMisconductVirsAndUpdateOptions(state, action.virs)
      });
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'DECISION_DATE_CHANGED':
      return {
        ...state,
        decisionDate: updateDateValue(action.decisionDate, state.decisionDate),
        validFrom: updateMinDates(action.decisionDate, 'decisionDate', state.validFrom)
      };
    case 'DOCUMENT_NUMBER_CHANGED':
      return {
        ...state,
        documentNumber: setDocumentNumberAndValidate(action.documentNumber, state.documentNumber)
      };
    case 'OUTLET_FIELD_ADDED':
      return setDynamicValidationConstraints({
        ...state,
        ...addOutletField(state)
      });
    case 'OUTLET_CHANGED':
      return setDynamicValidationConstraints({
        ...state,
        ...setAndValidateOutletAndUpdateOthersOptions(state, action.outlet, action.outletIndex)
      });
    case 'OUTLET_FIELD_REMOVED':
      return setDynamicValidationConstraints({
        ...state,
        ...removeOutletAndUpdateOthersOptions(state, action.outletIndex)
      });
    case 'MISCONDUCT_FIELD_ADDED':
      return { ...state, ...addMisconductField(state, action.outletIndex) };
    case 'MISCONDUCT_CHANGED':
      return {
        ...state,
        ...setAndValidateMisconductAndUpdateOthersOptions(
          state,
          action.misconduct,
          action.outletIndex,
          action.misconductIndex
        )
      };
    case 'MISCONDUCT_FIELD_REMOVED':
      return {
        ...state,
        ...removeMisconductAndUpdateOthersOptions(state, action.outletIndex, action.misconductIndex)
      };
    case 'SANCTION_FIELD_ADDED':
      return {
        ...state,
        ...addSanctionField(state, action.outletIndex, action.misconductIndex)
      };
    case 'SANCTION_CHANGED':
      return {
        ...state,
        ...setAndValidateSanctionAndUpdateOthersOptions(
          state,
          action.sanction,
          action.outletIndex,
          action.misconductIndex,
          action.sanctionIndex
        )
      };
    case 'SANCTION_FIELD_REMOVED':
      return {
        ...state,
        ...removeSanctionAndUpdateOthersOptions(
          state,
          action.outletIndex,
          action.misconductIndex,
          action.sanctionIndex
        )
      };
    case 'VALID_FROM_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        decisionDate: updateMaxDates(action.validFrom, 'validFrom', state.decisionDate),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'VALID_TO_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'UPDATE_RECORD_CLICKED':
      return validateEditedMisconductRecordState(state);
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return initialMisconductEditRowState;
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },
        annulRecordConfirmationOn: true
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        annulRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
