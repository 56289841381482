import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  OutletRepresentativesColumnHeaderField,
  OutletRepresentativesColumnHeader
} from '../tablesState/representativesTableTypes';
import { OutletRepresentative } from '../../../store/outletInfoData/outletInfoDataTypes';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { TableCustomFilterCellGeneric } from '../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  allData: OutletRepresentative[];
  columnNames: OutletRepresentativesColumnHeader[];
}

const FilterRow: React.FC<Props> = ({ columnNames, allData }) => {
  const {
    state: { representatives: representativesTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const handleSetCustomFilter = (
    column: OutletRepresentativesColumnHeaderField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'REPRESENTATIVES_SET_CUSTOM_FILTER',
      filterBy: column,
      value
    });
  };

  function getUniqueRepresentativestDataOptions(
    data: OutletRepresentative[],
    objectField: OutletRepresentativesColumnHeaderField
  ): string[] {
    const allValues = data
      .filter((record) => record[objectField] && record[objectField] !== '')
      .map((record) => String(record[objectField]));
    return Array.from(new Set(allValues));
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => representativesTableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueRepresentativestDataOptions(allData || [], column.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FilterRow;
