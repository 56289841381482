import React from 'react';
import { Dialog, DialogContent, DialogProps, Divider, Typography } from '@material-ui/core';
import { UnisignContainer } from './UnisignContainer';
import { CloseDialogButton } from '../../components/Dialogs/Dialog/CloseButton';
import { setUnisignStatus } from '../../store/unisign/unisignActions';
import { UnisignStatus } from '../../store/unisign/unisignTypes';
import { useDispatch } from 'react-redux';

type Props = DialogProps & {
  onClose: () => void;
  onSignSuccess: () => void;
  unsignedPdfPath: string;
  documentId: number;
};

export const UnisignDialog: React.FC<Props> = ({
  onClose,
  onSignSuccess,
  unsignedPdfPath,
  documentId,
  ...props
}) => {
  const dispatch = useDispatch();
  return (
    <Dialog {...props}>
      <div className="dialog-title">
        <Typography variant="h2">Dokumento Pasirašymas Unisign</Typography>
      </div>

      <Divider light style={{ margin: '0px 35px' }} />

      <DialogContent>
        <UnisignContainer
          onClose={onClose}
          unsignedPdfPath={unsignedPdfPath}
          documentId={documentId}
          onSignSuccess={onSignSuccess}
        />
      </DialogContent>

      <div className="dialog-actions">
        <CloseDialogButton
          label="Uždaryti"
          onClick={(event) => {
            if (onClose) {
              onClose(event, 'escapeKeyDown');
              dispatch(setUnisignStatus(UnisignStatus.LOADING));
            }
          }}
        />
      </div>
    </Dialog>
  );
};
