import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core/';

import { LegalEntityData } from '../../../store/externalSystemData/externalSystemDataTypes';
import { ColumnsDisplayStatus } from './tableState/tableTypes';
import { replaceDecimalPoint } from '../../../utils/tableDataFunctions';

interface Props {
  data: LegalEntityData[];
  columnsDisplayStatus: ColumnsDisplayStatus;
}
const DataTableBody: React.FC<Props> = ({ data, columnsDisplayStatus }) => {
  return (
    <TableBody>
      {data.map((record) => (
        <TableRow key={record.vdpId}>
          {columnsDisplayStatus.supportProviderName && (
            <TableCell>{record.supportProviderName}</TableCell>
          )}
          {columnsDisplayStatus.supportProviderCode && (
            <TableCell>{record.supportProviderCode}</TableCell>
          )}
          {columnsDisplayStatus.financialSupportAmount && (
            <TableCell>{replaceDecimalPoint(record.financialSupportAmount.toString())}</TableCell>
          )}
          {columnsDisplayStatus.supportReceivedYear && (
            <TableCell>{record.supportReceivedYear}</TableCell>
          )}
          {columnsDisplayStatus.dataCreatedDate && (
            <TableCell>
              {record.dataCreatedDate
                ? new Date(record.dataCreatedDate).toLocaleDateString('lt-LT')
                : null}
            </TableCell>
          )}
          {columnsDisplayStatus.dataProvidedDate && (
            <TableCell>
              {record.dataProvidedDate
                ? new Date(record.dataProvidedDate).toLocaleDateString('lt-LT')
                : null}
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default DataTableBody;
