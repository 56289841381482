import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  DatePickerState,
  VirsInputState,
  ValidationDate,
  DropdownMultipleStateGeneric,
  DropdownStateGeneric,
  InputStateGeneric
} from './tableTypes';

export interface ValidatorResult {
  isValid: boolean;
  message: string;
}
export type TextValidator = (text: string) => ValidatorResult;

export function validateInputValue(
  value: InputStateGeneric<string>,
  validators: TextValidator[],
  valueNullMessage?: string
): InputStateGeneric<string> {
  const result = { ...value };
  result.validated = false;
  result.error = true;

  const text = result.value;
  if (text === null && !!valueNullMessage) {
    return { ...result, helperText: valueNullMessage };
  }

  const failedValidations = validators
    .map((validator) => validator(text || ''))
    .filter((validatorResult) => !validatorResult.isValid);

  if (failedValidations.length > 0) {
    return { ...result, helperText: failedValidations[0].message };
  }

  result.validated = true;
  result.error = false;
  return { ...result, helperText: '' };
}

export function getIsNotEmptyValidator(failureMessage: string): TextValidator {
  return (text: string) => ({
    isValid: text.trim().length > 0,
    message: failureMessage
  });
}

export function getIsUnTrimmedValidator(failureMessage: string): TextValidator {
  return (text: string) => ({
    isValid: text.trim().length === text.length,
    message: failureMessage
  });
}

export function getIsShorterThanValidator(
  maxLength: number,
  failureMessage: string
): TextValidator {
  return (text: string) => ({
    isValid: text.length <= maxLength,
    message: failureMessage
  });
}

export const MINIMAL_RECORD_DATE_DEFAULT: ValidationDate = {
  id: 'sytemMinimum',
  date: moment('1883-01-01'),
  text: 'Data negali būti ankstesnė už leidžiamą datą'
};

export const MINIMAL_RECORD_YEAR_DEFAULT: ValidationDate = {
  id: 'sytemMinimumYear',
  date: moment('1883-01-01'),
  text: 'Metai negali būti ankstesni už leidžiamus'
};

export const MINIMAL_OUTLET_ESTABLISHED_DATE: ValidationDate = {
  id: 'sytemMinimum',
  date: moment('1883-01-01'),
  text: 'Data negali būti ankstesnė už leidžiamą datą'
};

export const MAXIMAL_RECORD_DATE_TODAY: ValidationDate = {
  id: 'todaysDate',
  date: moment(),
  text: 'Data negali būti vėlesnė už šiandienos datą'
};

export const MAXIMAL_RECORD_YEAR_TODAY: ValidationDate = {
  id: 'todaysDateYear',
  date: moment(),
  text: 'Metai negali būti vėlesni už einamus'
};

export const MAXIMAL_RECORD_DATE_FUTURE: ValidationDate = {
  id: 'futureDate',
  date: moment().add(100, 'year'),
  text: 'Data negali būti vėlesnė už leidžiamą datą'
};

function handleMinMaxDate(state: DatePickerState): DatePickerState {
  const minDateAllowed: ValidationDate | undefined = _.maxBy(state.minDates, 'date');
  const withinMinimumDate: boolean =
    state?.value?.isSameOrAfter(minDateAllowed?.date, 'day') || false;
  if (!withinMinimumDate) {
    return {
      ...state,
      error: true,
      helperText: `${minDateAllowed?.text} ${minDateAllowed?.date?.format('YYYY-MM-DD')}`,
      validated: false
    };
  }

  const maxDateAllowed: ValidationDate | undefined = _.minBy(state.maxDates, 'date');
  const withinMaximumDate: boolean =
    state?.value?.isSameOrBefore(maxDateAllowed?.date, 'day') || false;
  if (!withinMaximumDate && maxDateAllowed) {
    return {
      ...state,
      error: true,
      helperText: `${maxDateAllowed?.text} ${maxDateAllowed?.date?.format('YYYY-MM-DD')}`,
      validated: false
    };
  }
  return { ...state, error: false, helperText: '', validated: true };
}

function handleDateFormat(state: DatePickerState, format: string): DatePickerState {
  return {
    ...state,
    error: true,
    helperText: format,
    validated: false
  };
}

function handleNullDate(state: DatePickerState): DatePickerState {
  return {
    ...state,
    value: null,
    error: false,
    helperText: undefined,
    validated: false
  };
}

function validateDate(state: DatePickerState): DatePickerState {
  if (state.value && !state.value.isValid()) return handleDateFormat(state, 'mmmm-mm-dd');

  if (state.value === null) return handleNullDate(state);

  if (state.value && state.value.isValid()) return handleMinMaxDate(state);

  return {
    ...state,
    validated: false
  };
}

function validateMonth(state: DatePickerState): DatePickerState {
  if (state.value && !state.value.isValid()) return handleDateFormat(state, 'mmmm-mm');

  if (state.value === null) return handleNullDate(state);

  if (state.value && state.value.isValid()) return handleMinMaxDate(state);

  return {
    ...state,
    validated: false
  };
}

function validateYear(state: DatePickerState): DatePickerState {
  if (state.value && !state.value.isValid()) return handleDateFormat(state, 'mmmm');

  if (state.value === null) return handleNullDate(state);

  if (state.value && state.value.isValid()) return handleMinMaxDate(state);

  return {
    ...state,
    validated: false
  };
}

export function updateDateValue(
  newDate: MaterialUiPickersDate | null,
  state: DatePickerState
): DatePickerState {
  const updatedState = {
    ...state,
    value: newDate ? moment(newDate.toDate()) : null
  };
  return validateDate(updatedState);
}

function renewMinDates(
  newDate: MaterialUiPickersDate | null,
  id: string,
  state: DatePickerState
): DatePickerState {
  return {
    ...state,
    minDates: state.minDates.map((entry) =>
      entry.id === id
        ? {
            ...entry,
            date: newDate && newDate.isValid() ? moment(newDate.toDate()) : null
          }
        : entry
    )
  };
}

function renewMaxDates(
  newDate: MaterialUiPickersDate | null,
  id: string,
  state: DatePickerState
): DatePickerState {
  return {
    ...state,
    maxDates: state.maxDates.map((entry) =>
      entry.id === id
        ? {
            ...entry,
            date: newDate && newDate.isValid() ? moment(newDate.toDate()) : null
          }
        : entry
    )
  };
}

export function updateMinDates(
  newDate: MaterialUiPickersDate | null,
  id: string,
  state: DatePickerState
): DatePickerState {
  return validateDate(renewMinDates(newDate, id, state));
}

export function updateMaxDates(
  newDate: MaterialUiPickersDate | null,
  id: string,
  state: DatePickerState
): DatePickerState {
  return validateDate(renewMaxDates(newDate, id, state));
}

export function validateMandatoryDate(state: DatePickerState): DatePickerState {
  const dateValid: boolean = state.value ? state.value.isValid() : false;
  if (!dateValid) {
    return {
      ...state,
      error: true,
      validated: false,
      helperText: 'Privaloma data'
    };
  }
  return validateDate(state);
}

export function validateOptionalDateImproved(state: DatePickerState): DatePickerState {
  if (state.value === null) {
    return { ...state, validated: true, error: false };
  }
  return validateDate(state);
}

export function validateMonthDateInput(state: DatePickerState): DatePickerState {
  return validateMonth(state);
}

export function validatedSelectedVirs(state: VirsInputState): VirsInputState {
  if (!state.value) {
    return { ...state, error: true, validated: false };
  }
  return { ...state, error: false, validated: true };
}

export function validateMultipleChoices<T>(
  state: DropdownMultipleStateGeneric<T>,
  helperTextString: string
): DropdownMultipleStateGeneric<T> {
  return state.values.length === 0
    ? {
        ...state,
        error: true,
        helperText: helperTextString,
        validated: false
      }
    : {
        ...state,
        error: false,
        helperText: '',
        validated: true
      };
}

export function validateSingleChoice<T>(
  state: DropdownStateGeneric<T>,
  helperTextString: string
): DropdownStateGeneric<T> {
  return state.value === null
    ? {
        ...state,
        error: true,
        helperText: helperTextString,
        validated: false
      }
    : {
        ...state,
        error: false,
        helperText: '',
        validated: true
      };
}

export function validateMandatoryYear(state: DatePickerState): DatePickerState {
  const dateValid: boolean = state.value ? state.value.isValid() : false;
  if (!dateValid) {
    return {
      ...state,
      error: true,
      validated: false,
      helperText: 'Privalomi metai'
    };
  }
  return validateDate(state);
}

export function validateDateYear(state: DatePickerState): DatePickerState {
  return validateYear(state);
}

export function setDocumentNumberAndValidate(
  documentNumber: string | null,
  state: InputStateGeneric<string>
): InputStateGeneric<string> {
  if (!documentNumber) {
    return {
      ...state,
      value: documentNumber,
      error: true,
      helperText: 'Privaloma',
      placeholder: '',
      validated: false
    };
  }
  if (documentNumber.length === 0 || documentNumber.length > 10) {
    return {
      ...state,
      value: documentNumber,
      error: true,
      helperText: 'Ne daugiau 10 simbolių',
      placeholder: '',
      validated: false
    };
  }
  return {
    ...state,
    value: documentNumber,
    error: false,
    helperText: '',
    placeholder: '',
    validated: true
  };
}

export function validateDocumentNumber(
  documentNumberState: InputStateGeneric<string>
): InputStateGeneric<string> {
  if (!documentNumberState.value) {
    return {
      ...documentNumberState,
      value: null,
      error: true,
      helperText: 'Privaloma',
      placeholder: '',
      validated: false
    };
  }
  return documentNumberState;
}

export function clearFormikForm<T>(
  setFieldValue: (id: string, value: undefined) => void,
  values: Partial<T>
) {
  const keys = Object.keys(values);
  for (let i = 0; i < keys.length; i++) {
    setFieldValue(`${keys[i]}`, undefined);
  }
}

type Range = { start: Moment; end: Moment };
export function dateRangesOverlap(a: Range, b: Range): boolean {
  const [first, second] = a.start.valueOf() < b.start.valueOf() ? [a, b] : [b, a];
  if (!isFinite(first.end.valueOf())) {
    return true;
  }

  return second.start.valueOf() < first.end.valueOf();
}
