import {
  ColumnDisplayStatus,
  ColumnHeader,
  TableCustomFilter,
  TableGenericFilterOption
} from '../../../utils/tableTypes';
import { EthicalNonComplianceData } from '../../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { getTableExportDefinition } from './tableStateFunctions';
import { columnNames } from '../Table';

export const initialDisplayStatus = {
  virsName: true,
  decisionDate: true,
  documentNumber: true,
  outletList: true,
  decisionStatus: true,
  validFrom: true,
  validTo: true
};

export const initialEthicalNonComplianceTableState: EthicalNonComplianceTableState = {
  columnsDisplayStatus: initialDisplayStatus,
  customFilterOn: false,
  showNewRecord: false,
  // showDocumentDialog: false
  companyCode: null,
  tableExportDefinition: getTableExportDefinition(columnNames, initialDisplayStatus)
};

export type EthicalNonComplianceHeaderField =
  | 'virsName'
  | 'decisionDate'
  | 'documentNumber'
  | 'outletList'
  | 'decisionStatus'
  | 'validFrom'
  | 'validTo';

export type EthicalNonComplianceColumnsDisplayStatus =
  ColumnDisplayStatus<EthicalNonComplianceHeaderField>;

export type EthicalNonComplianceCustomFilter = TableCustomFilter<EthicalNonComplianceHeaderField>;

export type EthicalNonComplianceCustomFilterOptions =
  TableGenericFilterOption<EthicalNonComplianceHeaderField>;

export type EthicalNonComplianceColumnHeader = ColumnHeader<EthicalNonComplianceHeaderField>;

export interface EthicalNonComplianceTableState {
  columnsDisplayStatus: EthicalNonComplianceColumnsDisplayStatus;
  customFilterOn: boolean;
  showNewRecord: boolean;
  // showDocumentDialog: boolean;
  companyCode: string | null;
  tableExportDefinition: TableExportDefinition<EthicalNonComplianceData>;
}

export type EthicalNonComplianceTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: EthicalNonComplianceHeaderField }
  // | { type: 'DOCUMENT_DIALOG_OPENED', documentId: number }
  // | { type: 'DOCUMENT_DIALOG_CLOSED' }
  // | { type: 'HIDE_NEW_RECORD' }
  | { type: 'SHOW_NEW_RECORD_CLICKED'; companyCode: string | null }
  | { type: 'NEW_RECORD_CREATED' }
  | { type: 'RESET_COMPANY_CODE' };
