import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { TooltipOnTableActionButton } from '../../../../../components/Tooltips/TooltipOnTableActionButton';
import { getValueSelectedDate } from '../../utilities/UIFunctions';

interface Props {
  showVotes: boolean;
  showShares: boolean;
  votesOnRules: boolean | null;
  sharePercentage: number | null;
  votePercentage: number | null;
  sharePercentageLessThanPermitted: boolean | null;
  votePercentageLessThanPermitted: boolean | null;
}

const useStyles = makeStyles({
  flexContainer: { display: 'flex' },
  padding: {
    padding: '0 7px'
  }
});

export const ColorBarValue: React.FC<Props> = ({
  showVotes,
  showShares,
  votesOnRules,
  sharePercentage,
  votePercentage,
  sharePercentageLessThanPermitted,
  votePercentageLessThanPermitted
}) => {
  const classes = useStyles();
  return showVotes && votesOnRules ? (
    <TooltipOnTableActionButton text="Balsai pagal taisyklę">
      <Typography variant="h5" className={classes.padding}>
        T
      </Typography>
    </TooltipOnTableActionButton>
  ) : (
    <Typography variant="h5" className={`${classes.flexContainer} ${classes.padding}`}>
      {getValueSelectedDate(showShares, showVotes, sharePercentage, votePercentage, sharePercentageLessThanPermitted, votePercentageLessThanPermitted)}
    </Typography>
  );
};
