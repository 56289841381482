import React from 'react';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import { auditsTableColumns } from './tableState/tableInitialStateAndTypes';
import SortByTool from '../../components/TableCustomTools/SortByTool';
import {
  useIntegratedSystemsAuditsTableDispatch,
  useIntegratedSystemsAuditsTableState
} from './Context';

export const IntegratedSystemsAuditsTableCustomizationBar: React.FC = () => {
  const { dispatch: tableDispatch } = useIntegratedSystemsAuditsTableDispatch();
  const { state: tableState } = useIntegratedSystemsAuditsTableState();

  const handleSetSortByColumn = (column: any) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <SortByTool
            order={tableState.order}
            sortBy={tableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnNames={auditsTableColumns}
          />
        }
      />
    </div>
  );
};
