import moment from 'moment';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import {
  MINIMAL_RECORD_DATE_DEFAULT,
  validateDocumentNumber,
  validatedSelectedVirs,
  validateMandatoryDate
} from '../../../../utils/InputValueFunctions';
import { filterEmpty } from '../../../../utils/tableDataFunctions';
import { ValidationDate, VirsInputState } from '../../../../utils/tableTypes';
import {
  EthicalNonComplianceEditRecordState,
  EthicalNonComplianceNewRecordState,
  EthicalNonComplianceRecordState
} from './initialEthicalNonComplianceStateAndTypes';

export function setDynamicValidationConstraints<T extends EthicalNonComplianceRecordState>(
  state: T
): T {
  const minDates: (ValidationDate | undefined | null)[] = [
    MINIMAL_RECORD_DATE_DEFAULT,
    state.virs.value && {
      id: 'virsLegalRegistrationDate',
      date: moment(state.virs.value.virsLegalRegistrationDate),
      text: 'Data negali būti ankstesnė nei VIRS registracijos data'
    },
    ...filterEmpty(
      state.outlets.values
        .map((selectedOutlet) =>
          state.virs.value?.outlets.find(({ outletId }) => outletId === selectedOutlet?.outletId)
        )
        .map((value) => value?.controlledFrom)
    )
      .sort((a, b) => b.localeCompare(a))
      .slice(0, 1)
      .map((controlledFrom) => ({
        id: 'outletControlledFrom',
        date: moment(controlledFrom),
        text: 'Negali būti ankstestė nei vip valdymo pradžios data'
      }))
  ];

  return {
    ...state,
    decisionDate: {
      ...state.decisionDate,
      minDates
    }
  };
}

export const setEthicalNonComplianceVirsAndUpdateOptions = (
  state: EthicalNonComplianceRecordState,
  virs: VirsSearchData,
  fictitiousOutlet: OutletShortData[]
): EthicalNonComplianceRecordState => {
  return {
    ...state,
    virs: {
      ...state.virs,
      value: virs,
      error: false
    },
    outlets: {
      ...state.outlets,
      values: virs.outlets.length === 0 ? [fictitiousOutlet[0]] : [],
      options: [fictitiousOutlet[0], ...virs.outlets]
    }
  };
};

export const validateNewRowState = (
  state: EthicalNonComplianceNewRecordState
): EthicalNonComplianceNewRecordState => {
  const virsValidated: VirsInputState = validatedSelectedVirs(state.virs);
  const decisionDateValidated = validateMandatoryDate(state.decisionDate);
  const validFromValidated = validateMandatoryDate(state.validFrom);
  const validToValidated = validateMandatoryDate(state.validTo);
  const documentNumberValidated = validateDocumentNumber(state.documentNumber);
  const outletsValidated =
    state.outlets.values.length < 1
      ? {
          ...state.outlets,
          error: true,
          helperText: 'Būtina VIP(-ai)',
          validated: false
        }
      : { ...state.outlets, error: false, helperText: '', validated: true };
  const decisionStatusValidated = {
    ...state.decisionStatus,
    error: !state.decisionStatus.id
  };
  return {
    ...state,
    virs: virsValidated,
    decisionDate: decisionDateValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    documentNumber: documentNumberValidated,
    outlets: outletsValidated,
    decisionStatus: decisionStatusValidated,
    createRecordConfirmationOn:
      virsValidated.validated &&
      decisionDateValidated.validated &&
      validFromValidated.validated &&
      validToValidated.validated &&
      documentNumberValidated.validated &&
      outletsValidated.validated &&
      !decisionStatusValidated.error
  };
};

export const validateEditRowState = (
  state: EthicalNonComplianceEditRecordState
): EthicalNonComplianceEditRecordState => {
  const decisionDateValidated = validateMandatoryDate(state.decisionDate);
  const validFromValidated = validateMandatoryDate(state.validFrom);
  const validToValidated = validateMandatoryDate(state.validTo);
  const documentNumberValidated = validateDocumentNumber(state.documentNumber);
  const outletsValidated =
    state.outlets.values.length < 1
      ? {
          ...state.outlets,
          error: true,
          helperText: 'Būtina VIP(-ai)',
          validated: false
        }
      : { ...state.outlets, error: false, helperText: '', validated: true };
  const decisionStatusValidated = {
    ...state.decisionStatus,
    error: !state.decisionStatus.id
  };
  return {
    ...state,
    decisionDate: decisionDateValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    documentNumber: documentNumberValidated,
    outlets: outletsValidated,
    decisionStatus: decisionStatusValidated,
    updateRecordConfirmationOn:
      decisionDateValidated.validated &&
      validFromValidated.validated &&
      validToValidated.validated &&
      documentNumberValidated.validated &&
      outletsValidated.validated &&
      !decisionStatusValidated.error
  };
};
