import { action } from 'typesafe-actions';
import {
  FETCH_EXTERNAL_SYSTEM_DATA_REQUEST,
  FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_ERROR,
  FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_SUCCESS
} from './externalSystemDataActionTypes';
import { ExternalSystemData } from './externalSystemDataTypes';

export const fetchExternalSystemData = (virId: number) =>
  action(FETCH_EXTERNAL_SYSTEM_DATA_REQUEST, virId);
export const fetchExternalSystemDataSuccess = (externalSystemData: ExternalSystemData) =>
  action(FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_SUCCESS, externalSystemData);
export const fetchExternalSystemDataError = (error: Error) =>
  action(FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_ERROR, error);
