export interface EditionCheckConclusion {
  editionCheckConclusionId: number;
  editionCheckConclusionTypeName: string;
  editionCheckConclusionTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface EditionCheckConclusionInput {
  editionCheckConclusionId: number | null;
  editionCheckConclusionTypeName: string | null;
  editionCheckConclusionTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: EditionCheckConclusionInput = {
  editionCheckConclusionId: null,
  editionCheckConclusionTypeName: '',
  editionCheckConclusionTypeNameEn: '',
  validFrom: null,
  validTo: null
};
