import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntegrationalSytemUsageAuditSearchForm } from '../../components/IntegrationalSytemUsageAuditSearch/IntegrationalSytemUsageAuditSearchForm';
import { IntegratedSystemsAuditsTableWithContext } from '../../containers/IntegratedSystemAuditsTable/Context';
import { ApplicationState } from '../../store';
import { resetAuditsData } from '../../store/audits/auditsActions';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('xs')]: {
    auditContainer: {
      padding: 0
    }
  }
}));

const InstitutionIntegrationalSytemUsageAuditsPage = () => {
  const {
    loadingIntegratedSystemsAudits,
    integratedSystemsAuditsData,
    integratedSystemsAuditsDataError
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal.auditsData);
  const reduxDispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      reduxDispatch(resetAuditsData());
    };
  }, [reduxDispatch]);

  return (
    <div className="data-page">
      <Container style={{ minHeight: '100%' }} maxWidth="xl">
        <Container maxWidth="lg" className={classes.auditContainer}>
          <div className="data-page-header">
            <Typography variant="h1">Integracinių sistemų panaudojimo auditas</Typography>
          </div>
          <Divider />
          <IntegrationalSytemUsageAuditSearchForm />
        </Container>
        {loadingIntegratedSystemsAudits ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {integratedSystemsAuditsDataError ? (
              <>
                <Divider />
                <Typography variant="h3">{`Klaida. ${integratedSystemsAuditsDataError.message}.`}</Typography>
              </>
            ) : (
              <>
                <>
                  {integratedSystemsAuditsData ? (
                    <>
                      {integratedSystemsAuditsData.length === 0 ? (
                        <>
                          <Divider />
                          <Typography variant="h3">
                            Atsiprašome, pagal jūsų užklausą duomenų nerasta.
                          </Typography>
                        </>
                      ) : (
                        <IntegratedSystemsAuditsTableWithContext />
                      )}
                    </>
                  ) : null}
                </>
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default InstitutionIntegrationalSytemUsageAuditsPage;
