import { ProvidedDataVirs } from '../../../store/providedData/providedDataTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import {
  ColumnDisplayStatus,
  ColumnHeader,
  SortOrder,
  TableCustomFilter
} from '../../../utils/tableTypes';
import { getTableExportDefinition } from './tableStateFunctions';

export type ProvidedDataTableAction =
  | {
      type: 'TABLE_INITIALIZED';
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: ProvidedDataTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: ProvidedDataTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: ProvidedDataTableField };

export const providedDataTableColumns: ProvidedDataTableColumn[] = [
  {
    id: 'dataType',
    label: 'Duomenų tipas'
  },
  {
    id: 'documentStatus',
    label: 'Būsena'
  },
  {
    id: 'confirmationDate',
    label: 'Duomenų pateikimo data',
    type: 'date'
  },
  {
    id: 'confirmationPersonFullName',
    label: 'Duomenis pateikęs asmuo'
  },
  {
    id: 'annulmentDate',
    label: 'Anuliavimo data',
    type: 'date'
  },
  {
    id: 'annulmentReasonTypeName',
    label: 'Anuliavimo priežastis'
  }
];

export const initialColumnDisplay = {
  dataType: true,
  documentStatus: true,
  confirmationDate: true,
  confirmationPersonFullName: true,
  annulmentDate: true,
  annulmentReasonTypeName: true
};

export const initialProvidedDataTableState: ProvidedDataTableState = {
  customFilter: {
    dataType: [],
    documentStatus: [],
    confirmationDate: [],
    confirmationPersonFullName: [],
    annulmentDate: [],
    annulmentReasonTypeName: []
  },
  customFilterOn: false,
  order: 'desc',
  sortBy: 'documentStatus',
  page: 0,
  rowsPerPage: 20,
  columnsDisplayStatus: initialColumnDisplay,
  tableExportDefinition: getTableExportDefinition(providedDataTableColumns, initialColumnDisplay)
};

export type ProvidedDataTableField =
  | 'dataType'
  | 'documentStatus'
  | 'confirmationDate'
  | 'confirmationPersonFullName'
  | 'annulmentDate'
  | 'annulmentReasonTypeName';

export type ProvidedDataTableColumn = ColumnHeader<ProvidedDataTableField>;

export interface ProvidedDataTableState {
  customFilter: ProvidedDataTableCustomFilter;
  customFilterOn: boolean;
  order: SortOrder;
  sortBy: ProvidedDataTableField;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: ProvidedColumnsDisplay;
  tableExportDefinition: TableExportDefinition<ProvidedDataVirs>;
}

export type ProvidedColumnsDisplay = ColumnDisplayStatus<ProvidedDataTableField>;

export type ProvidedDataTableCustomFilter = TableCustomFilter<ProvidedDataTableField>;
