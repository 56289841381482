import { AnnulmentType } from '../../../store/classifiers/classifiersTypes';
import { DropdownStateGeneric, InputStateGeneric } from '../../../utils/tableTypes';
import { ProvidedDataVirs } from '../../../store/providedData/providedDataTypes';

export interface ProvidedDataRecordState {
  removeRecordConfirmationOn: boolean;
  annulRecordConfirmationOn: boolean;
  showPreviewDialog: boolean;
  selectedRecord?: ProvidedDataVirs;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
}

export const initialProvidedDataRecordState: ProvidedDataRecordState = {
  removeRecordConfirmationOn: false,
  showPreviewDialog: false,
  selectedRecord: undefined,
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  }
};

export type ProvidedDataRowAction =
  | {
      type: 'ANNUL_RECORD_CLICKED';
      annulmentTypes: AnnulmentType[];
      selectedRecord: ProvidedDataVirs;
    }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | {
      type: 'PREVIEW_RECORD';
      showPreviewDialog: boolean;
      selectedRecord?: ProvidedDataVirs;
    };
