import { makeStyles, Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ShareholdersHistory } from '../../../../store/shareholders/shareholdersTypes';
import { DateDetailsPopover } from './DateDetailsPopover';

interface Props {
  events: ShareholdersHistory[] | [];
  date: string;
  handleDateClick: (date: string) => void;
  open: boolean;
  children: ReactElement;
  parentHeight: number;
  parentShareTypeName: string | null;
  parentMayHaveSharePercentage: boolean;
  parentMayHaveVotePercentage: boolean;
}

const useStyles = makeStyles(() => ({
  detailsPopper: {
    flexDirection: 'column',
    display: 'flex',
    padding: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    borderTopLeftRadius: '0px',
    border: '1px solid #006fb2',
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    fontSize: '0.75rem'
  },
  tooltip: {
    padding: 0,
    minWidth: '330px'
  },
  popper: {
    zIndex: 1200
  },
  tooltipPlacementBottom: ({ parentHeight }: { parentHeight: number }) => ({
    margin: `${parentHeight / 2 - 2}px -20px`
  }),
  tooltipPlacementTop: ({ parentHeight }: { parentHeight: number }) => ({
    margin: `${parentHeight / 2 - 2}px -20px`
  })
}));

export const DateDetailsCellWrapper: React.FC<Props> = ({
  events,
  handleDateClick,
  open,
  children,
  parentHeight,
  parentShareTypeName,
  parentMayHaveSharePercentage,
  parentMayHaveVotePercentage
}) => {
  const classes = useStyles({ parentHeight });

  return (
    <Tooltip
      PopperProps={{
        disablePortal: false
      }}
      classes={{
        tooltip: classes.tooltip,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
        tooltipPlacementTop: classes.tooltipPlacementTop,
        popper: classes.popper
      }}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="bottom-start"
      title={
        !!events.length && (
          <DateDetailsPopover
            className={classes.detailsPopper}
            events={events}
            handleDateClick={handleDateClick}
            parentShareTypeName={parentShareTypeName}
            parentMayHaveSharePercentage={parentMayHaveSharePercentage}
            parentMayHaveVotePercentage={parentMayHaveVotePercentage}
          />
        )
      }
    >
      {children}
    </Tooltip>
  );
};
