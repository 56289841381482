import {
  currentDate,
  getHeaderDatesSortedUnique,
  splitShareholderPath
} from '../../containers/ShareholdersTable/utilityFunctions/sharedFunctions';
import { KeyStringValueBoolean } from '../virsis/dataTypes';
import {
  EndDateType,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps
} from './shareholdersTypes';

// dalyvių išskleidimo funkcijos
export const setExpandParentsPath = (prevState: KeyStringValueBoolean, parentIds: string) => {
  const parentShareholdersIds: KeyStringValueBoolean = {};
  const idsArr = splitShareholderPath(parentIds);

  for (let i = 0; i < idsArr.length + 1; i++) {
    const parentShareholderPath = idsArr.slice(0, i + 1).join('/');
    parentShareholdersIds[parentShareholderPath] = true;
  }

  return {
    ...prevState,
    ...parentShareholdersIds
  };
};

export const setExpandParentPersons = (
  prevState: KeyStringValueBoolean,
  parentPersonsPath: string[],
  index: number
) => {
  const updatedExpandedShareholders = { ...prevState };
  parentPersonsPath.forEach(
    (personId) => (updatedExpandedShareholders[personId + '__' + index] = true)
  );

  return updatedExpandedShareholders;
};

export const getAllSelectedDates = (data: ShareholdersData) => {
  const allDates = getHeaderDatesSortedUnique(false, data);
  const allDatesWithToday = allDates && [...allDates, currentDate].sort();
  return data.virsEndDate ? allDates : Array.from(new Set(allDatesWithToday));
};

export function isShareholderSigned(shareholder: ShareholdersProps): boolean {
  const shareholderSigned =
    shareholder.isShareholderFromJADIS ||
    shareholder.createdWithDocument?.documentStatus !== 'RUOSIAMAS';
  if (!shareholderSigned) {
    return false;
  }
  const sharesSigned = shareholder.shareholderHistory.every(
    (value) => value.documentStatus === 'RUOSIAMAS'
  );
  if (sharesSigned) {
    return false;
  }
  return shareholder.shareholderPersons
    .flatMap(({ shareholders }) => shareholders)
    .every(isShareholderSigned);
}

export const isNextShareholderEventSigned = (
  events: ShareholdersHistory[],
  selectedDate: string | undefined
): boolean => {
  return events
    .filter((event) => selectedDate && event.eventDate >= selectedDate)
    .some(
      (event) =>
        event.documentStatus === 'PASIRASYTAS' &&
        (event.votesOnRules || event.votePercentage !== null) &&
        event.sharePercentage !== null
    );
};

export const getEndDateLabel = (endDateType: EndDateType): string => {
  switch (endDateType) {
    case EndDateType.SHAREHOLDER:
      return 'JA turto/balsų perleidimo data:'; // shareholder pabaigos data
    case EndDateType.JADIS:
      return 'Gauta iš JADIS:'; // gauta iš jadis data
    case EndDateType.VIRS:
      return 'VIRS veiklų pabaigos data:'; // veiklos periodo pabaiga
    case EndDateType.JAR:
      return 'JA išregistravimas iš JAR:'; // išregistravimo iš jar data
    case EndDateType.GR:
      return 'Fizinio asmens išregistravimo data'; // dalyvio-fizinio asmens mirties datą
  }
};
