import { Action, createAction, createReducer } from 'typesafe-actions';

export interface LoaderState<TData> {
  loading: boolean;
  loaded: boolean;
  error?: string;
  data: TData;
}

export function getLoaderStore<TData, TPrefix extends string>(
  actionPrefix: TPrefix,
  initialData: TData
) {
  function prefixActionType<TAction extends string>(action: TAction) {
    return `${actionPrefix}/${action}` as `${TPrefix}/${TAction}`;
  }

  const setLoading = createAction(prefixActionType('SET_LOADING'), (loading: boolean) => ({
    loading
  }))();
  const setLoaded = createAction(prefixActionType('SET_LOADED'), (loaded: boolean) => ({
    loaded
  }))();
  const setData = createAction(prefixActionType('SET_DATA'), (data: TData) => ({
    data
  }))();
  const setError = createAction(prefixActionType('SET_ERROR'), (error?: string) => ({
    error
  }))();

  const load = createAction(prefixActionType('LOAD'))();

  const initialState: LoaderState<TData> = {
    loading: false,
    loaded: false,
    data: initialData
  };

  const reducer = createReducer<LoaderState<TData>, Action>(initialState)
    .handleAction(setLoading, (state, action) => ({
      ...state,
      loading: action.payload.loading
    }))
    .handleAction(setLoaded, (state, action) => ({
      ...state,
      loaded: action.payload.loaded
    }))
    .handleAction(setData, (state, action) => ({
      ...state,
      data: action.payload.data
    }))
    .handleAction(setError, (state, action) => ({
      ...state,
      error: action.payload.error
    }));

  return {
    reducer,
    initialState,
    actions: {
      setLoading,
      setLoaded,
      setData,
      setError,
      load
    }
  };
}
