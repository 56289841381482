import { useDispatch, useSelector } from 'react-redux';
import { AlertBox } from '../../../components/Alert/AlertBox';
import { closeUnsignedDataAlert } from '../../../store/virsis/actions';
import { ApplicationState } from '../../../store';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';

export const MissingActivityPeriodAlert = () => {
  const reduxDispatch = useDispatch();

  const {
    shareholdersData: { shareholdersData }
  } = useSelector((state: ApplicationState) => state);

  const activityPeriodCount = shareholdersData?.activityPeriods.length || 0;

  return (
    <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
      {activityPeriodCount === 0 ||
      shareholdersData?.activityPeriods[activityPeriodCount - 1].endDate !== null ? (
        <AlertBox
          notClosable
          handleClose={() => reduxDispatch(closeUnsignedDataAlert())}
          text={`Dalyvių duomenys galima teikti tik VIRS veiklų laikotarpiais. Įveskite ir pasirašykite veiklos rūšis, kad galėtumėte pildyti dalyvių duomenis.`}
        />
      ) : (
        <></>
      )}
    </AllowedTo>
  );
};
