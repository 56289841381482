import React from 'react';
import {
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Checkbox
} from '@material-ui/core/';
import { TableColumn, ColumnsDisplayStatus } from './TableCustomTools';
import SelectionButton from '../TableButtons/SelectionButton';

interface Props {
  columnNames: TableColumn[];
  columnsDisplayStatus: ColumnsDisplayStatus;
  onDisplayColumnChecked: (columnID: string) => void;
  disabled?: boolean;
}

const SelectionTool: React.FC<Props> = ({
  columnNames,
  columnsDisplayStatus,
  onDisplayColumnChecked,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleSelectionTab = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  function isColumnBeingShown(columnId: string): boolean {
    return columnsDisplayStatus[columnId] !== undefined && columnsDisplayStatus[columnId] === true;
  }

  return (
    <>
      <SelectionButton onToggle={toggleSelectionTab} anchorEl={anchorEl} disabled={disabled} />
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={toggleSelectionTab}
      >
        <Grid container alignItems="center" style={{ padding: 15 }}>
          <Grid item>
            <Typography>
              <i>Pasirinkite aktualias informacijos skiltis,</i>
            </Typography>
            <Typography>
              <i>kurios bus atvaizduojamos lentelėje:</i>
            </Typography>
          </Grid>
        </Grid>
        <Divider light />
        {columnNames.map((columnName) => {
          return (
            <MenuItem key={columnName.id} disableRipple>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isColumnBeingShown(columnName.id)}
                    onChange={() => onDisplayColumnChecked(columnName.id)}
                    value={columnName}
                  />
                }
                label={columnName.label}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default SelectionTool;
