import { Button, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

interface Props {
  mainTitle: string;
  url: string;
}

const useStyles = makeStyles({
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 24px 15px 30px'
  },
  button: {
    alignSelf: 'center',
    margin: '10px 0',
    textDecoration: 'none',
    flexGrow: 1
  },
  icon: {
    borderRadius: '50%',
    padding: 6,
    '&:hover': {
      backgroundColor: '#DBEDFC'
    }
  }
});

export const BottomDataWrapper: React.FC<Props> = ({ mainTitle, url, children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div style={{ height: '100%' }}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ color: '#5A5A5A', fontWeight: 800 }}>
            {mainTitle}
          </Typography>
          <Link to={url} className={`${classes.icon} link flex-container`}>
            <ChevronRightIcon />
          </Link>
        </div>
        <Divider style={{ margin: 0 }} />
        <div>{children}</div>
      </div>
      <Link className={classes.button} to={url} style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="primary">
          Peržiūrėti visus
        </Button>
      </Link>
    </Paper>
  );
};
