import React, { useMemo, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../ShareholdersTableWithContext';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorProps, ShareholdersProps } from '../../../../store/shareholders/shareholdersTypes';
import ShareholdersChart from './ShareholdersChart';
import {
  getParentPathAndStatus,
  getIsLastShareholder,
  showExtendedDetailsButton
} from '../../SelectedDateTable/utilities/utilityFunctions';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import { ShareholderFullNameWithButtons } from '../../../../components/ShareholderComponents/ShareholderFullNameWithButtons';
import {
  currentDate,
  filterErrorObjs,
  getSortedPersonsArray
} from '../../utilityFunctions/sharedFunctions';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import ExtendedDetailsRow from './ExtendedDetailsRow';
import { LastColumnColorBar } from '../Components/LastColumnColorBar';
import { LevelBar } from '../../../../components/ShareholderComponents/TableButtons/LevelBar';
import { ApplicationState } from '../../../../store';
import { setExpandShareholder } from '../../../../store/shareholders/shareholdersActions';
import { ShareholderGroupTree } from './ShareholderGroupTree';

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  allEventsDates: string[];
  parentShareTypeName: string | null;
  parentErrors: ErrorProps[];
  parentMayHaveVotesOnRules: boolean;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  isLastShareholder: boolean;
  parentMayHaveSharePercentage: boolean;
  parentMayHaveVotePercentage: boolean;
  filteredShareholderLevel?: string;
}

const ShareholdersTree: React.FC<Props> = ({
  index,
  shareholder,
  allEventsDates,
  parentShareTypeName,
  parentErrors,
  parentMayHaveVotesOnRules,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  isLastShareholder,
  parentMayHaveSharePercentage,
  parentMayHaveVotePercentage,
  filteredShareholderLevel
}) => {
  const {
    state: {
      lastColumnColors,
      datesInPerdiodWithEvents,
      tableId,
      customFilter: { shareholderPeriodDateTo, shareholderPeriodDateFrom }
    }
  } = useShareholdersTableState();

  const [periodFilterFromDate] = shareholderPeriodDateFrom;
  const [periodFilterToDate] = shareholderPeriodDateTo;

  const {
    virsis: { currentUser },
    shareholdersData: { shareholdersData, expandedShareholders }
  } = useSelector((state: ApplicationState) => state);
  const reduxDispatch = useDispatch();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  const [showExtension, setShowExtension] = useState<boolean>(false);

  const {
    shareholderId,
    shareholderGroupId,
    shareholderLevel,
    shareholderPersons,
    shareholderPath
  } = shareholder;

  const { errors } = shareholderPersons[0];

  const getParentPathStatus = useMemo(() => {
    if (shareholderLevel > 1 && !!shareholdersData) {
      return getParentPathAndStatus(shareholdersData.shareholders, shareholderPath);
    }
    return undefined;
  }, [shareholdersData, shareholderLevel, shareholderPath]);

  const handleShowChildShareholder = () => {
    reduxDispatch(setExpandShareholder(shareholderPath, undefined, index));

    if (filteredShareholderLevel) {
      // reset default filter level value (shows '-' in input)
      tableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: true });
      // reset filter level (kad galetume dar karta pasirinkti ta pati leveli ir vel isfiltruoti)
      tableDispatch({
        type: 'CUSTOM_FILTER_VALUE_CHANGED',
        filterBy: 'shareholderLevel',
        value: null
      });
    }
  };

  const showChildShareholder = expandedShareholders[shareholderPath + '__' + index];
  const showGroupMembers = shareholderGroupId && showChildShareholder;

  const criticalErrorMessages = filterErrorObjs(errors, ErrorType.CRITICAL);
  const nonCriticalErrorMessages = filterErrorObjs(errors, ErrorType.NONCRITICAL);
  const infoNotifications = filterErrorObjs(errors, ErrorType.INFO);

  const sortedPersons = getSortedPersonsArray(shareholderPersons);

  const setLastColumnColors =
    `${shareholderId}` in lastColumnColors ? lastColumnColors[shareholderId] : undefined;

  const isItGroupShareholder = !!shareholder.shareholderGroupId;

  const showTriangleButton =
    isItGroupShareholder ||
    shareholder.shareholderPersons.some((person) => person.shareholders.length);

  const hideLastColumnColorBar = !!(
    virsEndDate ||
    shareholder.shareholderStoppedParticipatingFromDate ||
    (periodFilterToDate ? !allEventsDates.includes(currentDate) : false) ||
    (periodFilterToDate
      ? !datesInPerdiodWithEvents.map((x) => x.date).includes(currentDate)
      : false) ||
    (tableId === 'day' && !(!!periodFilterFromDate && !!periodFilterToDate))
  );

  return (
    <>
      <TableRow className="shareholders-table-row">
        <TableCell className="sticky-col first-col">
          <div className="fake-cell level-cell">{shareholderLevel}</div>
        </TableCell>
        <TableCell className="sticky-col second-col">
          <div className="fake-cell shareholder-cell">
            <LevelBar
              level={shareholderLevel}
              showChildShareholder={showChildShareholder}
              halfBottom={showTriangleButton}
              isItGroupShareholder={!!shareholderGroupId}
              middle
              isLastShareholder={isLastShareholder}
              parentsLevelBarStatus={getParentPathStatus}
            />
            <ShareholderFullNameWithButtons
              index={index}
              shareholder={shareholder}
              shareholderPersons={shareholderGroupId ? sortedPersons : shareholderPersons}
              showExtendedDetailsButton={showExtendedDetailsButton(shareholder)}
              showRowExtension={showExtension}
              setShowRowExtention={() => setShowExtension(!showExtension)}
              showChildShareholder={showChildShareholder}
              handleShowChildShareholder={handleShowChildShareholder}
              mayShowErrorIcons={!shareholderGroupId}
              criticalErrorMessages={criticalErrorMessages}
              nonCriticalErrorMessages={nonCriticalErrorMessages}
              infoNotifications={infoNotifications}
              marginLeft={getMarginLeft(shareholderLevel)}
            />
          </div>
        </TableCell>

        {allEventsDates.map((date) => (
          <ShareholdersChart
            index={index}
            key={date + index}
            shareholder={shareholder}
            parentMayHaveVotesOnRules={parentMayHaveVotesOnRules}
            parentShareTypeName={parentShareTypeName}
            allEventsDates={allEventsDates}
            date={date}
            parentErrors={parentErrors}
            virsEndDate={virsEndDate}
            virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
            parentMayHaveSharePercentage={parentMayHaveSharePercentage}
            parentMayHaveVotePercentage={parentMayHaveVotePercentage}
          />
        ))}
        <TableCell className="last-column">
          <LastColumnColorBar
            hideLastColumnColorBar={hideLastColumnColorBar}
            colors={setLastColumnColors}
          />
        </TableCell>
      </TableRow>

      {shareholderId && showExtension && shareholder?.shareholderHistory?.length > 0 && (
        <ExtendedDetailsRow
          shareholder={shareholder}
          datesColCount={allEventsDates.length + 1}
          parentShareTypeName={parentShareTypeName}
          parentMayHaveSharePercentage={parentMayHaveSharePercentage}
          parentMayHaveVotePercentage={parentMayHaveVotePercentage}
          currentUser={currentUser}
        />
      )}

      {shareholderGroupId &&
        showGroupMembers &&
        sortedPersons.map((person, index) => {
          return (
            <ShareholderGroupTree
              index={index}
              key={person.personId + '__' + index}
              shareholder={shareholder}
              person={person}
              allEventsDates={allEventsDates}
              virsEndDate={virsEndDate}
              virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
              filteredShareholderLevel={filteredShareholderLevel}
            />
          );
        })}

      {!shareholderGroupId &&
        showChildShareholder &&
        shareholderPersons[0].shareholders.map((shareholderChild, index) => {
          return (
            <ShareholdersTree
              index={index}
              key={
                `${shareholder.shareholderId}` +
                shareholder.shareholderHistory[0]?.eventDate +
                index
              }
              shareholder={shareholderChild}
              allEventsDates={allEventsDates}
              parentShareTypeName={shareholderPersons[0].shareTypeName}
              parentErrors={shareholderPersons[0].errors}
              parentMayHaveVotesOnRules={shareholder.mayHaveVotesOnRules}
              virsEndDate={virsEndDate}
              virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
              isLastShareholder={getIsLastShareholder(index, shareholderPersons[0].shareholders)}
              parentMayHaveSharePercentage={shareholderPersons[0].mayHaveSharePercentage}
              parentMayHaveVotePercentage={shareholderPersons[0].mayHaveVotePercentage}
              filteredShareholderLevel={filteredShareholderLevel}
            />
          );
        })}
    </>
  );
};

export default ShareholdersTree;
