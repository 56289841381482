import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  addShareholderDateReset,
  addShareholderPercentageError,
  addShareholderPercentageRequest,
  addShareholderPercentageSuccess,
  clearShareholderEventError,
  clearShareholderEventRequest,
  clearShareholderEventSuccess,
  createShareholderError,
  createShareholderRequest,
  createShareholderStateReset,
  createShareholderSuccess,
  deleteShareholderDataByDateError,
  deleteShareholderDataByDateRequest,
  deleteShareholderDataByDateSuccess,
  deleteShareholderDataError,
  deleteShareholderDateError,
  deleteShareholderDateRequest,
  deleteShareholderDateSuccess,
  deleteShareholderRequest,
  deleteShareholderSuccess,
  fetchShareholdersSelectedDateDataError,
  fetchShareholdersSelectedDateDataRequest,
  fetchShareholdersSelectedDateDataSuccess,
  fetchVirsPersonsListError,
  fetchVirsPersonsListRequest,
  fetchVirsPersonsListSuccess,
  getShareholderDocumentRequest,
  getShareholdersError,
  getShareholdersRequest,
  getShareholdersSuccess,
  resetClearShareholderEventDialog,
  resetShareholderDataDeletionByDateDialog,
  resetShareholderDateDeletionDialog,
  resetShareholderDeletionDialog,
  synchroniseWithJADISError,
  synchroniseWithJADISRequest,
  synchroniseWithJADISSuccess,
  updateShareholderError,
  updateShareholderRequest,
  updateShareholderStateReset,
  updateShareholderSuccess
} from './shareholdersActions';
import {
  ADD_SHAREHOLDER_PERCENTAGE_REQUEST,
  CLEAR_SHAREHOLDER_EVENT_REQUEST,
  CREATE_SHAREHOLDER_REQUEST,
  DELETE_DATA_BY_DATE_REQUEST,
  DELETE_DATE_REQUEST,
  DELETE_SHAREHOLDERS_REQUEST,
  FETCH_VIRS_PERSONS_LIST_REQUEST,
  SHAREHOLDER_DOCUMENT_PDF_REQUEST,
  SHAREHOLDERS_DATA_REQUEST,
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST,
  SYNCHRONISE_WITH_JADIS_REQUEST,
  UPDATE_SHAREHOLDER_REQUEST
} from './shareholdersActionTypes';
import {
  deleteShareholder,
  deleteShareholderDataByDate,
  deleteShareholderDate,
  getFullVirsPersonsList,
  getShareholdersData,
  getShareholdersSelectedDateData,
  getVirsDocument,
  getVirsPersonsList,
  postAddShareholderPercentage,
  postClearShareholderEvent,
  postCreateShareholder,
  postSynchroniseWithJADIS,
  putUpdateShareholder
} from './shareholdersApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { ShareholderGroupPerson } from '../../containers/ShareholdersTable/SelectedDateTable/tableState/tableInitialStateAndTypes';
import { ShareholdersData } from './shareholdersTypes';
import { fetchVirsDataRequest } from '../virsis/actions';
import { shareholderTableSignedFilterState } from './selectors';

function* handleShareholdersSelectedDateDataFetchSaga(
  action: ActionType<typeof fetchShareholdersSelectedDateDataRequest>
) {
  try {
    const { virsId, date } = action.payload;
    const { data } = yield call(getShareholdersSelectedDateData, virsId, date);
    yield put(fetchShareholdersSelectedDateDataSuccess(data.shareholdersData));
  } catch (err) {
    yield put(fetchShareholdersSelectedDateDataError(err));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleShareholdersDataRequestSaga(action: ActionType<typeof getShareholdersRequest>) {
  try {
    const { virId, isShowUnsigned } = action.payload;
    const {
      data: { shareholdersData }
    }: { data: { shareholdersData: ShareholdersData } } = yield call(
      getShareholdersData,
      virId,
      !isShowUnsigned
    );
    yield put(getShareholdersSuccess(shareholdersData));
  } catch (err) {
    yield put(getShareholdersError(err));
    yield put(
      sendMessage(
        'error',
        err.response?.data.message || 'Klaida. Nepavyko gauti duomenų. Bandykite dar kartą vėliau'
      )
    );
  }
}

function* handleShareholderDocumentRequestSaga(
  action: ActionType<typeof getShareholderDocumentRequest>
) {
  try {
    const docId = action.payload;
    const { data } = yield call(getVirsDocument, docId);
    const ie = window.navigator && !!window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]));
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleSynchroniseWithJADISSaga(action: ActionType<typeof synchroniseWithJADISRequest>) {
  const { record, personName } = action.payload;
  try {
    yield call(postSynchroniseWithJADIS, record);
    yield put(synchroniseWithJADISSuccess(true));
    yield put(sendMessage('success', 'Sinchronizuota su JADIS '));
  } catch (err) {
    yield put(synchroniseWithJADISError(err.response));
    yield put(
      sendMessage(
        'warning',
        `Nepavyko sinchronizuoti JADIS duomenų. ${personName}, ${err.response.data.message}`
      )
    );
  }
}

function* handleAddShareholderPercentageSaga(
  action: ActionType<typeof addShareholderPercentageRequest>
) {
  try {
    const showUnsigned = yield select(shareholderTableSignedFilterState);
    const { percentageRecord, virsId, date } = action.payload;
    yield call(postAddShareholderPercentage, percentageRecord);
    yield put(addShareholderPercentageSuccess());
    yield put(fetchShareholdersSelectedDateDataRequest(virsId, date));
    yield put(getShareholdersRequest(virsId, !!showUnsigned));
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Įrašyta'));
  } catch (err) {
    yield put(addShareholderPercentageError(err.response));
    yield put(
      sendMessage('error', err.response?.data?.message || 'Klaida. Bandykite dar kartą vėliau')
    );
  }
}

function* handleFetchVirsPersonsListSaga(action: ActionType<typeof fetchVirsPersonsListRequest>) {
  try {
    const { virsId, date } = action.payload;
    if (date) {
      const { data } = yield call(getVirsPersonsList, virsId, date);
      yield put(fetchVirsPersonsListSuccess(data.virsPersonsList));
    } else {
      const { data } = yield call(getFullVirsPersonsList, virsId);
      yield put(fetchVirsPersonsListSuccess(data.virsPersonsList));
    }
  } catch (err) {
    yield put(fetchVirsPersonsListError(err));
    yield put(
      sendMessage('error', err.response?.data?.message || 'Klaida. Bandykite dar kartą vėliau')
    );
  }
}

function* syncWithJADISTask(personsFromJADIS: ShareholderGroupPerson[], virsId: number) {
  try {
    for (const person of personsFromJADIS) {
      yield postSynchroniseWithJADIS({ personId: person.personId, virsId });
    }
    yield put(synchroniseWithJADISSuccess(true));
    yield put(sendMessage('success', 'Sinchronizuota su JADIS '));
  } catch (err) {
    yield put(synchroniseWithJADISError(err.response));
    yield put(
      sendMessage('warning', `Nepavyko sinchronizuoti JADIS duomenų. ${err.response.data.message}`)
    );
  }
}

function* handleCreateShareholderSaga(action: ActionType<typeof createShareholderRequest>) {
  try {
    const { record, virsId, selectedDate, personsFromJADIS } = action.payload;
    const { data } = yield call(postCreateShareholder, record, virsId);
    const showUnsigned = yield select(shareholderTableSignedFilterState);
    yield put(
      sendMessage(
        'success',
        data.shareholderPersonInTree
          ? 'Dalyvis sėkmingai išsaugotas. Atkreipkite dėmesį, kad tas pats dalyvis jau yra nurodytas tarp kitų šio VIRS dalyvių.'
          : 'Dalyvis sėkmingai išsaugotas'
      )
    );
    yield put(createShareholderSuccess(data));
    if (personsFromJADIS.length > 0) {
      yield call(syncWithJADISTask, personsFromJADIS, virsId);
    }
    yield put(fetchShareholdersSelectedDateDataRequest(virsId, selectedDate));
    yield put(getShareholdersRequest(virsId, !!showUnsigned));
    yield put(fetchVirsDataRequest());
    yield put(createShareholderStateReset());
    yield put(addShareholderDateReset());
  } catch (err) {
    yield put(createShareholderError(err.response));
    yield put(
      sendMessage('error', err.response.data?.message || 'Klaida. Bandykite dar kartą vėliau')
    );
  }
}

function* handleUpdateShareholderSaga(action: ActionType<typeof updateShareholderRequest>) {
  try {
    const { record, shareholderId, virsId, selectedDate, personsFromJADIS } = action.payload;
    const { data } = yield call(putUpdateShareholder, record, shareholderId, virsId);

    yield put(sendMessage('success', 'Dalyvis atnaujintas'));
    if (personsFromJADIS.length > 0) {
      yield call(syncWithJADISTask, personsFromJADIS, virsId);
    }
    yield put(fetchShareholdersSelectedDateDataRequest(virsId, selectedDate));
    yield put(updateShareholderSuccess(data.shareholderId));
    yield put(fetchVirsDataRequest());
    yield put(updateShareholderStateReset());
  } catch (err) {
    yield put(updateShareholderError(err));
    yield put(sendMessage('error', err.response.data.message));
  }
}

/** CLEAR SHAREHOLDER EVENT */
function* handleClearShareholderEventRequest(
  action: ActionType<typeof clearShareholderEventRequest>
) {
  const { virId, date, tempPercentageRecord } = action.payload;
  try {
    yield call(postClearShareholderEvent, tempPercentageRecord);
    yield put(fetchShareholdersSelectedDateDataRequest(virId, date));
    yield put(fetchVirsDataRequest());
    yield put(clearShareholderEventSuccess());
    yield put(resetClearShareholderEventDialog());
    yield put(sendMessage('success', 'Išvalyti dalies duomenys'));
  } catch (err) {
    yield put(clearShareholderEventError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

/** DELETE SHAREHOLDER */
function* handleDeleteShareholderRequest(action: ActionType<typeof deleteShareholderRequest>) {
  const { virId, shareholderId, date, location } = action.payload;
  const showUnsigned = yield select(shareholderTableSignedFilterState);
  try {
    yield call(deleteShareholder, virId, shareholderId);
    if (location === 'SELECTED') {
      yield put(fetchShareholdersSelectedDateDataRequest(virId, date));
      yield put(getShareholdersRequest(virId, !!showUnsigned));
    } else {
      yield put(getShareholdersRequest(virId, !!showUnsigned));
    }
    yield put(deleteShareholderSuccess());
    yield put(resetShareholderDeletionDialog());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Dalyvis pašalintas'));
  } catch (err) {
    yield put(deleteShareholderDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

/** DELETE DATE  */
function* handleDateDeletion(action: ActionType<typeof deleteShareholderDateRequest>) {
  try {
    const showUnsigned = yield select(shareholderTableSignedFilterState);
    const { virId, date } = action.payload;
    yield call(deleteShareholderDate, virId, date);
    yield put(getShareholdersRequest(virId, !!showUnsigned));
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Data pašalinta'));
    yield put(deleteShareholderDateSuccess());
    yield put(resetShareholderDateDeletionDialog());
  } catch (err) {
    yield put(deleteShareholderDateError(err.response));
    yield put(
      sendMessage('error', err.response.data?.message || 'Klaida. Bandykite dar kartą vėliau')
    );
  }
}

/** DELETE DATA BY DATE */
function* handleDataDeletionByDate(action: ActionType<typeof deleteShareholderDataByDateRequest>) {
  try {
    const showUnsigned = yield select(shareholderTableSignedFilterState);
    const { virId, date, location } = action.payload;
    yield call(deleteShareholderDataByDate, virId, date);
    if (location === 'MAIN' || !date) {
      yield put(getShareholdersRequest(virId, !!showUnsigned));
    } else {
      yield put(fetchShareholdersSelectedDateDataRequest(virId, date));
    }
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Duomenys išvalyti'));
    yield put(deleteShareholderDataByDateSuccess());
    yield put(resetShareholderDataDeletionByDateDialog());
  } catch (err) {
    yield put(deleteShareholderDataByDateError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* shareholdersDataSaga() {
  yield takeLatest(
    SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST,
    handleShareholdersSelectedDateDataFetchSaga
  );
  yield takeEvery(CREATE_SHAREHOLDER_REQUEST, handleCreateShareholderSaga);
  yield takeEvery(UPDATE_SHAREHOLDER_REQUEST, handleUpdateShareholderSaga);
  yield takeLatest(SHAREHOLDERS_DATA_REQUEST, handleShareholdersDataRequestSaga);
  yield takeEvery(DELETE_SHAREHOLDERS_REQUEST, handleDeleteShareholderRequest);
  yield takeEvery(SHAREHOLDER_DOCUMENT_PDF_REQUEST, handleShareholderDocumentRequestSaga);
  yield takeEvery(ADD_SHAREHOLDER_PERCENTAGE_REQUEST, handleAddShareholderPercentageSaga);
  yield takeLatest(FETCH_VIRS_PERSONS_LIST_REQUEST, handleFetchVirsPersonsListSaga);
  yield takeEvery(SYNCHRONISE_WITH_JADIS_REQUEST, handleSynchroniseWithJADISSaga);
  yield takeEvery(DELETE_DATE_REQUEST, handleDateDeletion);
  yield takeEvery(DELETE_DATA_BY_DATE_REQUEST, handleDataDeletionByDate);
  yield takeEvery(CLEAR_SHAREHOLDER_EVENT_REQUEST, handleClearShareholderEventRequest);
}

export default shareholdersDataSaga;
