export interface SanctionType {
  sanctionTypeId: number;
  sanctionTypeName: string;
  sanctionTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface SanctionTypeInput {
  sanctionTypeId: number | null;
  sanctionTypeName: string | null;
  sanctionTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: SanctionTypeInput = {
  sanctionTypeId: null,
  sanctionTypeName: '',
  sanctionTypeNameEn: '',
  validFrom: null,
  validTo: null
};
