import React, { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EnterpriseDataWithOutlets } from '../../store/outlets/outletsTypes';
import { noOptionsText } from '../../components/TableCustomFilterCell/constants';

interface Props {
  options: EnterpriseDataWithOutlets[];
  setEnterprise: (enterprise: EnterpriseDataWithOutlets | undefined) => void;
  errorMessage: null | string;
}

export const OutletsTableNewRecordDialogAutocomplete: React.FC<Props> = ({
  options,
  setEnterprise,
  errorMessage
}) => {
  function handleOptionSelected(
    event: ChangeEvent<object>,
    value: EnterpriseDataWithOutlets | null
  ) {
    setEnterprise(value || undefined);
  }

  return (
    <Autocomplete
      size="small"
      autoComplete
      clearOnEscape
      key={0}
      noOptionsText={noOptionsText}
      options={options}
      getOptionLabel={(group) => {
        return `${group.enterpriseTypeName} (${group.validFrom} - ${
          group.validTo ? group.validTo : ''
        })`;
      }}
      getOptionSelected={(option, value) => option.enterpriseId === value.enterpriseId}
      onChange={handleOptionSelected}
      renderInput={(params) => (
        <TextField
          required
          label="Veiklos rūšis"
          error={!!errorMessage}
          helperText={errorMessage}
          {...params}
          variant="outlined"
        />
      )}
    />
  );
};
