import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
  TableSortLabel
} from '@material-ui/core/';
import { EnterpriseLicenceData } from '../../../../store/enterprises/enterprisesTypes';
import { SortOrder } from '../../../../utils/tableTypes';
import {
  EnterpriseDataLicenceDataField,
  enterpriseLicencesTableColumns
} from './innerTableReducerAndTypes';

interface Props {
  licences: EnterpriseLicenceData[];
  sortBy: EnterpriseDataLicenceDataField;
  order: SortOrder;
  setSorting: (column: EnterpriseDataLicenceDataField) => void;
}

export const InnerLicencesTable: React.FC<Props> = ({ licences, sortBy, order, setSorting }) => {
  const createSortHandler =
    (column: EnterpriseDataLicenceDataField) => (event: React.MouseEvent<unknown>) => {
      setSorting(column);
    };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {enterpriseLicencesTableColumns.map((header) => (
              <TableCell
                key={header.id}
                sortDirection={sortBy === header.id ? order : false}
                align="left"
                variant="head"
                className="TableCellSortable"
                onClick={createSortHandler(header.id)}
              >
                <TableSortLabel
                  active={sortBy === header.id}
                  direction={sortBy === header.id ? order : 'asc'}
                >
                  <Typography>{header.label}</Typography>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {licences.map((licence) => (
            <TableRow key={licence.licenceTypeCode}>
              <TableCell component="th" scope="row">
                {licence.licenceNumber}
              </TableCell>
              <TableCell align="left">{licence.licenceTypeCode}</TableCell>
              <TableCell align="left">{licence.licenceTypeName}</TableCell>
              <TableCell align="left">{licence.issuingDate}</TableCell>
              <TableCell align="left">{licence.licenceStatus}</TableCell>
              <TableCell align="left">{licence.validFrom}</TableCell>
              <TableCell align="left">{licence.validTo}</TableCell>
              <TableCell align="left">{licence.issuer}</TableCell>
              <TableCell align="left">{licence.licenceNotes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
