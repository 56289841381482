import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VirsDataSubmissionPageForm } from '../../containers/VirsDataSubmision/VirsDataSearch/VirsDataSearchForm';
import { ApplicationState } from '../../store';
import { VirsSearchData, VirsSearchQuery } from '../../store/virsis/dataTypes';
import { resetSearchVirsState, searchVirsActiveOnlyData } from '../../store/virsis/actions';
import { AuditVirsSearchResultsTable } from './AuditVirsSearchResultsTable';
import { CloseContinueButton } from '../Dialogs/Dialog/CloseContinueButton';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';

interface Props {
  dialogOpen: boolean;
  closeDialog: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  field: string;
}

const useStyles = makeStyles((theme) => ({
  dialogActionsButton: {
    margin: 8
  },
  [theme.breakpoints.down('xs')]: {
    dialogTitle: {
      padding: '20px 15px 10px 15px !important',
      justifyContent: 'space-between'
    },
    dialogTitleButton: {
      paddingLeft: 0,
      paddingRight: 0
    },
    dialogMainContent: {
      padding: '10px 15px 10px 15px'
    },
    dialogActionsButton: {
      margin: 0
    }
  }
}));

export const AuditVirsSearchDialog: React.FC<Props> = ({
  dialogOpen,
  closeDialog,
  setFieldValue,
  field
}) => {
  const reduxDispatch = useDispatch();
  const [querySize, setQuerySize] = useState<number>(0);
  const {
    virsis: { loadingVirsSearchResults, virsSearchResults, virsSearchResultsError }
  } = useSelector((state: ApplicationState) => state);
  const [selectedVirs, setSelectedVirs] = useState<VirsSearchData | undefined>(undefined);
  const classes = useStyles();

  function handleClickSelect(virs: VirsSearchData) {
    setSelectedVirs(virs);
  }

  function searchForVirsis(virsQuery: VirsSearchQuery) {
    reduxDispatch(resetSearchVirsState());
    reduxDispatch(searchVirsActiveOnlyData(virsQuery));
  }

  function handleClickContinue() {
    setFieldValue(field, selectedVirs?.personFullName || '');
    closeDialog();
  }

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} onExit={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className={'dialog-title ' + classes.dialogTitle}>
        <Typography variant="subtitle1">VIRS paieška</Typography>
        <CustomCloseIconButton className={classes.dialogTitleButton} onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <div className={'dialog-main-content ' + classes.dialogMainContent}>
          <Divider />
          <VirsDataSubmissionPageForm
            setQuerySize={setQuerySize}
            searchVirs={searchForVirsis}
            isSearching={loadingVirsSearchResults}
          />
          <>
            {virsSearchResultsError ? (
              <Typography>{`Klaida. ${virsSearchResultsError}`}</Typography>
            ) : (
              <>
                {virsSearchResults && virsSearchResults.length === 0 ? (
                  <>
                    <Typography variant="h5">Pagal Jūsų paiešką įrašų nebuvo rasta.</Typography>
                    <Typography>
                      {querySize === 1
                        ? 'Pagal nurodytą paieškos kriterijų duomenų nerasta. Galite patikslinti nurodytą kriterijų arba ieškoti pagal kitą kriterijų.'
                        : 'Pagal nurodytus paieškos kriterijus duomenų nerasta. Galite patikslinti nurodytus kriterijus arba ieškoti nurodant mažiau kriterijų'}
                    </Typography>
                  </>
                ) : (
                  <>
                    {virsSearchResults && virsSearchResults.length > 0 && (
                      <>
                        <Typography
                          style={{
                            paddingTop: '10px',
                            paddingBottom: '20px',
                            fontWeight: 'bolder'
                          }}
                        >
                          Paieškos rezultatai
                        </Typography>

                        <AuditVirsSearchResultsTable
                          selectedVirs={selectedVirs}
                          handleClickSelect={handleClickSelect}
                          virsSearchResults={virsSearchResults}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" className={classes.dialogActionsButton}>
          <Button variant="outlined" onClick={closeDialog}>
            <Typography>Atgal</Typography>
          </Button>
        </Box>
        <Box right={30} component="span" className={classes.dialogActionsButton}>
          <CloseContinueButton onClick={handleClickContinue} disabled={!selectedVirs} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
