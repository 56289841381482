import React, { useEffect, useReducer } from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  ProvidedDataTableType,
  ProvidedDataVirs
} from '../../../store/providedData/providedDataTypes';
import { ProvidedColumnsDisplay } from '../tableState/tableInitialStateAndTypes';
import downloadPdf from '../../../utils/PdfDownload';
import { ApplicationState } from '../../../store';
import { AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import { initialProvidedDataRecordState } from './providedDataRecordInitialStateAndTypes';
import { providedDataRowReducer } from './providedDataRecordReducer';
import {
  annulProvidedData,
  removeProvidedData,
  resetAnnulProvidedDataState,
  resetProvidedDataRemoveState
} from '../../../store/providedData/providedDataActions';
import { PreviewDocumentDialogMinimal } from '../../document/PreviewDocumentDialogMinimal';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { ViewDownloadDocButtons } from '../../../components/TableButtons/RowActionButtons/ViewDownloadDocButtons';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { ChooseActionsOnDifferentStatus } from '../../../components/TableRowActions/RowActionsOnDifferentStatus';
import { ConfirmRemoveDialog } from '../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import { resetVirsDataRequest, setSelectedPersonId } from '../../../store/virsis/actions';

interface ProvidedDataTableRowProps {
  record: ProvidedDataVirs;
  columnsToDisplay: ProvidedColumnsDisplay;
}

export const ProvidedDataTableRow: React.FC<ProvidedDataTableRowProps> = ({
  record,
  columnsToDisplay
}) => {
  const history = useHistory();
  const {
    classifiers: { annulmentTypes },
    providedData: { removingProvidedData, isProvidedDataRemoved, removingProvidedDataError },
    virsis: { selectedVirsId }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const [rowState, rowDispatch] = useReducer(
    providedDataRowReducer,
    initialProvidedDataRecordState
  );

  const handleClosePreview = () => {
    rowDispatch({
      type: 'PREVIEW_RECORD',
      showPreviewDialog: false,
      selectedRecord: undefined
    });
  };

  const {
    virsis: { virsData, virsDataError },
    providedData: { annullingProvidedDataRecord, annullingProvidedDataRecordError }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (virsDataError && virsData && virsData.personId) {
      reduxDispatch(setSelectedPersonId(virsData.personId));
      reduxDispatch(resetVirsDataRequest());
      history.push(`/virs-duomenys/kurimas/${virsData.personId}`);
    }
  }, [reduxDispatch, history, virsDataError]);

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulProvidedDataState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    if (rowState.selectedRecord && virsData) {
      reduxDispatch(
        annulProvidedData(rowState.selectedRecord.documentStatusId, annulRecord, virsData.virsId)
      );
      closeAnnulDocumentDialog();
    }
  }

  function openRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  const closeOnRemoveError = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
    reduxDispatch(resetProvidedDataRemoveState());
  };

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || [],
      selectedRecord: record
    });
  }

  const handleDownload = (documentStatusId: number) => {
    downloadPdf(`virs/legals/documents/${documentStatusId}`);
  };

  const handleShowPreview = (previewRecord: ProvidedDataVirs) => {
    rowDispatch({
      type: 'PREVIEW_RECORD',
      showPreviewDialog: true,
      selectedRecord: previewRecord
    });
  };

  const { data: virsProvidedDataStatus } = useSelector(
    (state: ApplicationState) => state.virsProvidedDataStatus
  );

  let linkPath = 'veiklos-rusys';

  if (virsProvidedDataStatus.enterprisesUnsigned) {
    linkPath = 'veiklos-rusys';
  } else if (virsProvidedDataStatus.outletsUnsigned) {
    linkPath = 'visuomenes-informavimo-priemones';
  } else if (virsProvidedDataStatus.shareholdersUnsigned) {
    linkPath = 'dalyviai';
  }

  return (
    <>
      {rowState.showPreviewDialog && rowState.selectedRecord && (
        <PreviewDocumentDialogMinimal
          maxWidth="lg"
          open={rowState.showPreviewDialog}
          onClose={handleClosePreview}
          documentPath={`virs/legals/documents/${rowState.selectedRecord.documentStatusId}`}
        />
      )}
      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingProvidedDataRecordError}
        isProcessing={annullingProvidedDataRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingProvidedDataRecordError}
      />
      <ConfirmRemoveDialog
        dialogTitle="Įrašo pašalinimas"
        dialogText="Ar tikrai norite pašalinti šį įrašą?"
        mainButtonText="Pašalinti įrašą"
        open={rowState.removeRecordConfirmationOn}
        onClose={closeRemoveConfirmation}
        onSubmit={() =>
          reduxDispatch(
            removeProvidedData(record.documentStatusId, ProvidedDataTableType.VIRS, selectedVirsId)
          )
        }
        isProcessing={removingProvidedData}
        isSuccess={isProvidedDataRemoved}
        isError={!!removingProvidedDataError}
        onErrorClose={closeOnRemoveError}
        errorMessage={removingProvidedDataError?.message}
      />
      <RowStyleOnDifferentStatus status={record.documentStatus.id}>
        {columnsToDisplay.dataType && (
          <TooltipOnRowRibbon status={record.documentStatus.id}>
            <TableCell align="left" component="th" scope="row">
              <Typography> {record.dataType}</Typography>
            </TableCell>
          </TooltipOnRowRibbon>
        )}
        {columnsToDisplay.documentStatus && (
          <TableCell align="left">
            <Typography>{record.documentStatus.label}</Typography>
          </TableCell>
        )}
        {columnsToDisplay.confirmationDate && (
          <TableCell align="left">
            <Typography>
              {record.confirmationDate
                ? moment(record.confirmationDate).format('YYYY-MM-DD')
                : undefined}
            </Typography>
          </TableCell>
        )}
        {columnsToDisplay.confirmationPersonFullName && (
          <TableCell align="left">
            <Typography>{record.confirmationPersonFullName}</Typography>
          </TableCell>
        )}
        {columnsToDisplay.annulmentDate && (
          <TableCell align="left">
            <Typography>
              {record.annulmentDate ? moment(record.annulmentDate).format('YYYY-MM-DD') : undefined}
            </Typography>
          </TableCell>
        )}
        {columnsToDisplay.annulmentReasonTypeName && (
          <TableCell align="left">
            <Typography>{record.annulmentReasonTypeName}</Typography>
          </TableCell>
        )}

        <TableCell align="left">
          <ViewDownloadDocButtons
            documentId={record.documentStatusId}
            documentStatus={record.documentStatus.id}
            openDocClicked={() => handleShowPreview(record)}
            downloadDocClicked={() => handleDownload(record.documentStatusId)}
          />
        </TableCell>
        <TableCell align="right">
          <ChooseActionsOnDifferentStatus
            status={record.documentStatus.id}
            linkPath={linkPath}
            annulmentDisablementReason={record.annulmentDisablementReason}
            onClickRemove={openRemoveConfirmation}
            onClickAnnul={openAnnulDocumentDialog}
            isRemovable={record.documentStatus.id === 'RUOSIAMAS'}
          />
        </TableCell>
      </RowStyleOnDifferentStatus>
    </>
  );
};
