import moment from 'moment';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  validateSingleChoice
} from '../../../../../utils/InputValueFunctions';
import {
  EditionNewRowState,
  EditionNewRowAction,
  initialEditionNewRecordRowState
} from './rowInitialStateAndTypes';
import {
  isValid,
  setCirculationAndValidate,
  validateNewEditionRecordState
} from './rowReducerFunctions';

export const newEditionReducer = (
  state: EditionNewRowState,
  action: EditionNewRowAction
): EditionNewRowState => {
  switch (action.type) {
    case 'NEW_RECORD_STATE_INITIALIZED':
      return {
        ...state,
        editionPeriod: {
          ...state.editionPeriod,
          options: action.missingPeriods
        },
        circulation: {
          ...state.circulation,
          helperText: action.periodic ? 'vidutinis tiražas' : 'tiražas'
        },
        periodic: action.periodic,
        editionDate: action.periodic
          ? state.editionDate
          : {
              ...state.editionDate,
              minDates: [
                MINIMAL_RECORD_DATE_DEFAULT,
                {
                  id: 'outletControlledFrom',
                  date: moment(action.controlledFrom),
                  text: 'Data negali būti ankstesnė už VIP valdymo pradžios datą'
                }
              ],
              maxDates: [
                {
                  id: 'outletControlledTo',
                  date: action.controlledTo !== null ? moment(action.controlledTo) : null,
                  text: 'Data negali būti vėlesnė už VIP valdymo pabaigos datą '
                },
                MAXIMAL_RECORD_DATE_TODAY
              ]
            }
      };
    case 'EDITION_PERIOD_CHANGED':
      return {
        ...state,
        editionPeriod: validateSingleChoice(
          { ...state.editionPeriod, value: action.editionPeriod },
          'Reikia pasirinkti iš sąrašo'
        )
      };
    case 'EDITION_DATE_CHANGED':
      return {
        ...state,
        editionDate: updateDateValue(action.editionDate, state.editionDate)
      };
    case 'CIRCULATION_CHANGED':
      return {
        ...state,
        circulation: setCirculationAndValidate(action.circulation)
      };
    case 'CREATE_RECORD_CLICKED': {
      const validated = validateNewEditionRecordState(state);
      if (isValid(validated) && action.onValid) {
        action.onValid(validated);
      }
      return validated;
    }
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return initialEditionNewRecordRowState;
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    default:
      return state;
  }
};
