import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Dialog, Divider, CircularProgress } from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { searchVirsData, resetSearchVirsState } from '../../store/virsis/actions';
import { FindVirsDialogForm } from './FindVirsDialogForm';
import { FindVirsDialogResults } from './FindVirsDialogResults';
import { VirsSearchQuery, VirsSearchData } from '../../store/virsis/dataTypes';
import { EnterpriseType } from '../../store/classifiers/classifiersTypes';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../Dialogs/Dialog/CloseButton';
import { CloseContinueButton } from '../Dialogs/Dialog/CloseContinueButton';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export interface Props {
  dialogOpen: boolean;
  closeDialog: () => void;
  enterpriseTypes: EnterpriseType[];
  selectedVirs: VirsSearchData | undefined;
  closeAndContinueWithVirs: (virs: VirsSearchData) => void;
  disableVirsWithoutOutlets?: boolean;
}

export const FindVirsDialog: React.FC<Props> = ({
  dialogOpen,
  closeDialog,
  enterpriseTypes,
  selectedVirs,
  closeAndContinueWithVirs,
  disableVirsWithoutOutlets
}) => {
  const [dialogSelection, setDialogSelection] = useState<VirsSearchData | undefined>(undefined);

  const { loadingVirsSearchResults, virsSearchResults, virsSearchResultsError } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  const reduxDispatch = useDispatch();

  const [querySize, setQuerySize] = useState<number>(0);

  function searchForVirsis(virsQuery: VirsSearchQuery) {
    reduxDispatch(resetSearchVirsState());
    reduxDispatch(searchVirsData(virsQuery));
  }

  function selectVirsToDialogState(virs: VirsSearchData) {
    if (dialogSelection && dialogSelection.virId === virs.virId) {
      setDialogSelection(undefined);
    } else {
      setDialogSelection(virs);
    }
  }
  function closeDialogAndGoBack() {
    reduxDispatch(resetSearchVirsState());
    closeDialog();
  }

  function closeDialogAndContinue() {
    reduxDispatch(resetSearchVirsState());
    if (dialogSelection) {
      closeAndContinueWithVirs(dialogSelection);
    }
  }

  useEffect(() => {
    if (selectedVirs) {
      setDialogSelection(selectedVirs);
    }
  }, [selectedVirs]);

  return (
    <Dialog open={dialogOpen} onClose={closeDialogAndGoBack} fullWidth maxWidth="md">
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="subtitle1">VIRS paieška</Typography>
        <CustomCloseIconButton onClick={closeDialogAndGoBack} />
      </DialogTitle>
      <DialogContent>
        <div className="dialog-main-content">
          <Divider style={{ margin: 0 }} />
          <FindVirsDialogForm
            setQuerySize={setQuerySize}
            enterpriseTypes={enterpriseTypes}
            searchVirs={searchForVirsis}
            isSearching={loadingVirsSearchResults}
          />
          <Divider />
          <Grid
            container
            item
            direction="column"
            justify="flex-end"
            alignItems="flex-start"
            style={{ padding: '50px 0' }}
          >
            {loadingVirsSearchResults && <CircularProgress />}
            {virsSearchResultsError && (
              <Typography>{`Klaida. ${virsSearchResultsError}`}</Typography>
            )}
            {virsSearchResults && virsSearchResults.length === 0 && (
              <>
                <Typography variant="h5">Pagal Jūsų paiešką įrašų nebuvo rasta.</Typography>
                <Typography>
                  {querySize === 0 && 'Nurodykite bent vieną paieškos kriterijų'}
                  {querySize === 1 &&
                    'Pagal nurodytą paieškos kriterijų duomenų nerasta. Galite patikslinti nurodytą kriterijų arba ieškoti pagal kitą kriterijų'}
                  {querySize > 1 &&
                    'Pagal nurodytus paieškos kriterijus duomenų nerasta. Galite patikslinti nurodytus kriterijus arba ieškoti nurodant mažiau kriterijų'}
                </Typography>
              </>
            )}
            {virsSearchResults && virsSearchResults.length > 0 && (
              <FindVirsDialogResults
                virsSearchResults={virsSearchResults}
                selectedVirs={dialogSelection}
                selectVirs={selectVirsToDialogState}
                disableVirsWithoutOutlets={!!disableVirsWithoutOutlets}
              />
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" m={1}>
          <CloseDialogButton label="Atgal" onClick={closeDialogAndGoBack} />
        </Box>
        <Box right={30} component="span" m={1}>
          <CloseContinueButton
            onClick={closeDialogAndContinue}
            disabled={!dialogSelection?.personCode.length}
            withIcon
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
