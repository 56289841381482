import React, { ChangeEvent, ReactNode } from 'react';
import { Box, Divider, TableCell, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import { DropdownStateGeneric } from '../../utils/tableTypes';
import { noOptionsText } from '../TableCustomFilterCell/constants';
import theme from '../../style/virsisTheme';
import { CloseIconButton } from '../TableButtons/RowActionButtons/CloseIconButton';
import { AddIconButton } from '../TableButtons/RowActionButtons/AddIconButton';

interface DropdownInputProps<T> {
  selectValue: (selectedValue: T | null) => void;
  dropdownState: DropdownStateGeneric<T>;
  getOptionLabel: (object: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
  renderOption?: (option: T, state: AutocompleteRenderOptionState) => ReactNode;
  getOptionDisabled?: (option: T) => boolean;
  disabled?: boolean;
}

export function DropdownInput<T>({
  selectValue,
  dropdownState,
  getOptionLabel,
  getOptionSelected,
  renderOption,
  getOptionDisabled,
  disabled
}: DropdownInputProps<T>) {
  function handleOptionSelected(event: ChangeEvent<object>, value: T | null) {
    selectValue(value);
  }

  return (
    <Autocomplete
      size="small"
      autoComplete
      clearOnEscape
      noOptionsText={noOptionsText}
      options={dropdownState.options}
      disabled={!dropdownState.options || disabled}
      value={dropdownState.value}
      getOptionLabel={getOptionLabel}
      onChange={handleOptionSelected}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
      renderInput={(params) => (
        <form noValidate autoComplete="off">
          <TextField
            required
            label={dropdownState.placeholder}
            error={dropdownState.error}
            helperText={dropdownState.helperText}
            {...params}
            variant="outlined"
          />
        </form>
      )}
    />
  );
}

interface CellProps {
  rowSpan?: number;
  addUnit?: () => void;
  removeUnit?: () => void;
  showRemoveButton?: boolean;
}

export function DropdownInputCell<T>({
  rowSpan,
  addUnit,
  removeUnit,
  showRemoveButton,
  ...props
}: DropdownInputProps<T> & CellProps) {
  const {
    dropdownState: { error }
  } = props;
  return (
    <TableCell
      style={{
        minWidth: '100px',
        backgroundColor: error ? theme.palette.error.light : undefined
      }}
      rowSpan={rowSpan}
    >
      <Box>
        <DropdownInput {...props} />
        {showRemoveButton && addUnit && removeUnit && <CloseIconButton onClick={removeUnit} />}
      </Box>
      {addUnit && removeUnit && (
        <>
          <Divider />
          <AddIconButton onClick={addUnit} />
        </>
      )}
    </TableCell>
  );
}
