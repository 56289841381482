import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmRemoveDialog } from '../../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import {
  clearShareholderEventRequest,
  resetClearShareholderEventDialog
} from '../../../../store/shareholders/shareholdersActions';
import { ApplicationState } from '../../../../store';
import { Strings } from '../../../../utils/strings/Strings';

const ClearShareholderEventDialog: React.FC = () => {
  const reduxDispatch = useDispatch();
  const {
    shareholdersData: {
      clearShareholderEventError,
      showClearShareholderEventDialog,
      loadingClearShareholderEvent,
      selectedDateForFunctions,
      selectedPercentageRecord
    },
    virsis: { virsData }
  } = useSelector((state: ApplicationState) => state);

  const close = () => {
    reduxDispatch(resetClearShareholderEventDialog());
  };

  const clearShareholderEvent = () => {
    if (virsData?.virsId && selectedDateForFunctions && selectedPercentageRecord) {
      reduxDispatch(
        clearShareholderEventRequest(
          selectedPercentageRecord,
          selectedDateForFunctions,
          virsData?.virsId
        )
      );
    }
  };

  return (
    <ConfirmRemoveDialog
      open={showClearShareholderEventDialog}
      dialogTitle={Strings.shareholderTooltip__clearShareholder}
      dialogText={Strings.shareholderTooltip__clearShareholderApproval}
      mainButtonText={Strings.shareholderTooltip__clearShareholder}
      onClose={close}
      onSubmit={clearShareholderEvent}
      isProcessing={loadingClearShareholderEvent}
      errorMessage={`Klaida. ${clearShareholderEventError?.message}` || ''}
      isError={!!clearShareholderEventError}
      onErrorClose={close}
    />
  );
};

export default ClearShareholderEventDialog;
