import { Handle, Position } from 'react-flow-renderer';
import { VirsComponentInformationIcons } from './TreeChartBoxes/VirsComponentInformationIcons';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import { VirsBoxTitle } from './TreeChartBoxes/VirsBoxTitle';

import { outletBoxColor, virsBoxColor } from './treeChartFunctions/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VirsComponent = ({ data: { chartData } }: any) => {
  const {
    shareholders,
    outlets,
    hasPoliticalAdRevenues,
    hasFinancialSupportRecevied,
    hasFundsReceivedFromTransactions,
    hasEthicalNonCompliances,
    hasProfessionalMisconducts,
    shareholderErrors
  } = chartData;

  const outletsNumber = outlets.length;

  const handleOpenDiagramCard = () => {};

  const handleOpenVirsCard = () => {};

  const handleOpenVirsCardByTab = (preopenVirsCardTab: string) => () => {};

  const hasErrors = !!shareholderErrors && shareholderErrors.length > 0;

  const minHeight = 95;
  const maxHeight = 110;

  return (
    <>
      <div>
        {outlets.length > 0 && (
          <Handle type="target" position={Position.Left} style={{ background: outletBoxColor }} />
        )}
        <TreeSingleBox
          boxTitle={<VirsBoxTitle title={chartData.virsLegalName} subtitle="VIRS" />}
          leftChildrenNumber={outletsNumber}
          rightChildrenNumber={shareholders.length}
          color={virsBoxColor}
          showLeftSideBox
          showRightSideBox
          minHeight={hasErrors ? minHeight + 15 : minHeight}
          maxHeight={hasErrors ? maxHeight + 15 : maxHeight}
          virsClickableIcons={
            <VirsComponentInformationIcons
              onAdRevenuesClick={
                hasPoliticalAdRevenues && handleOpenVirsCardByTab('politicalAdFunds')
              }
              onFinancialSupportReceivedClick={
                hasFinancialSupportRecevied && handleOpenVirsCardByTab('legalPersonSupport')
              }
              onFundsReceivedFromTransactionsClick={
                hasFundsReceivedFromTransactions && handleOpenVirsCardByTab('receivedFunds')
              }
              onEthicalNonCompliancesClick={
                hasEthicalNonCompliances && handleOpenVirsCardByTab('ethicalNonCompliance')
              }
              onProfessionalMisconductsClick={
                hasProfessionalMisconducts && handleOpenVirsCardByTab('professionalMisconducts')
              }
              hasErrors={hasErrors}
              areDiagrams={false}
              onClickDiagram={handleOpenDiagramCard}
              sideChartCardOpen={false}
            />
          }
          hasErrors={hasErrors}
          errorText={shareholderErrors}
          onClick={handleOpenVirsCard}
        />
        {shareholders.length > 0 && <Handle type="source" position={Position.Right} />}
      </div>
    </>
  );
};
