import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  OutletCategory,
  OutletCategoryRecord,
  OutletInfo
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { OutletCategoryBasicRow } from './RowBasic';
import { ApplicationState } from '../../../../store';
import {
  updateCategory,
  removeCategory,
  resetCategoryRemovingState
} from '../../../../store/outletInfoData/outletInfoDataActions';
import { categoryEditRowReducer } from './categoryRowState/editRowReducer';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { EditAndRemoveActions } from '../../../../components/TableRowActions/EditAndRemoveActions';
import { initialCategoryEditRowState } from './categoryRowState/categoryInitialStateAndTypes';
import AllowedTo from '../../../AllowedTo';
import { Roles } from '../../../../store/virsis/dataTypes';
import { DropdownInputCell } from '../../../../components/TableInputs/DropdownInput';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { OutletCategoryTypeShort } from '../../../../store/classifiers/classifiersTypes';
import { OutletCategoryColumnsDisplayStatus } from '../../tablesState/categoryTableTypes';
import { validateEditCategoryState } from './categoryRowState/categoryRowReducerFunctions';
import { determineRecordStatus } from '../../../../utils/tableDataFunctions';

interface Props {
  record: OutletCategory;
  outletInfo: OutletInfo;
  latestRecordId: number | null;
  columnsDisplay: OutletCategoryColumnsDisplayStatus;
}

export const OutletCategoryRowContainer: React.FC<Props> = ({
  record,
  outletInfo: { outletId, controlledFrom, controlledTo, availableCategories },
  columnsDisplay
}) => {
  const [rowState, rowDispatch] = useReducer(categoryEditRowReducer, initialCategoryEditRowState);

  const {
    outletInfoData: {
      updatingCategoryError,
      removingCategory,
      categoryRemoved,
      removingCategoryError,
      outletInfo
    },
    virsis: { virsData, currentUser }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  const turnOnEditing = () => {
    const previousCategoryValidFrom = outletInfo?.categories
      ?.filter((value) => value.validFrom.localeCompare(record.validFrom) === -1)
      .sort((a, b) => b.validFrom.localeCompare(a.validTo))[0]?.validFrom;

    const previousCategoryValidTo = outletInfo?.categories
      ?.filter((value) => value.validTo && value.validTo.localeCompare(record.validTo) === -1)
      .sort((a, b) => b.validTo.localeCompare(a.validTo))[0]?.validTo;

    const nextCategoryValidFrom = outletInfo?.categories
      ?.filter((value) => value.validFrom.localeCompare(record.validFrom) === 1)
      .sort((a, b) => a.validFrom.localeCompare(b.validTo))[0]?.validFrom;

    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      availableCategories: availableCategories || [],
      previousCategoryValidFrom: previousCategoryValidFrom || null,
      previousCategoryValidTo: previousCategoryValidTo || null,
      nextCategoryValidFrom: nextCategoryValidFrom || null,
      controlledFrom: controlledFrom || null,
      controlledTo: controlledTo || null
    });
  };

  const setCategoryType = (categoryType: OutletCategoryTypeShort | null) => {
    rowDispatch({ type: 'CATEGORY_TYPE_CHANGED', categoryType });
  };

  const setValidFromDate2 = (date: MaterialUiPickersDate | null) => {
    rowDispatch({ type: 'VALID_FROM_DATE_CHANGED', date });
  };

  const cancelEditing = () => {
    rowDispatch({ type: 'EDITING_CANCELLED' });
  };

  const updateCategoryRecord = () => {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateEditCategoryState(rowState).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const updatedCategory: OutletCategoryRecord = {
        categoryTypeId: rowState.categoryType.value?.categoryTypeId,
        validFrom: rowState.validFrom.value ? rowState.validFrom.value.format('L').toString() : null
      };
      if (virsData && currentUser && outletId) {
        reduxDispatch(updateCategory(outletId, record.outletCategoryId, updatedCategory));
      }
    }
  };

  const onRemoveRecordClicked = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  };

  const removeRecord = () => {
    if (rowState.removeRecordConfirmationOn && virsData && currentUser && outletId) {
      reduxDispatch(removeCategory(virsData.virsId, outletId, record.outletCategoryId));
    }
  };

  const closeRemoveConfirmation = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  };

  const closeOnRemoveError = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetCategoryRemovingState());
  };

  useEffect(() => {
    if (updatingCategoryError) rowDispatch({ type: 'STOP_LOADING' });
  }, [updatingCategoryError]);

  return (
    <>
      {record.confirmationStatus === 'P' && (
        <OutletCategoryBasicRow
          rowStatus={determineRecordStatus(record)}
          id={`${record.categoryTypeId}-${record.originalDocumentId}`}
          columnsToDisplay={columnsDisplay}
          categoryTypeName={record.categoryTypeName}
          validFrom={record.validFrom}
          validTo={<TableCell>{record.validTo}</TableCell>}
        />
      )}
      {record.confirmationStatus === 'U' && (
        <OutletCategoryBasicRow
          rowStatus={determineRecordStatus(record)}
          id={`${record.categoryTypeId}-${record.originalDocumentId}`}
          columnsToDisplay={columnsDisplay}
          categoryTypeName={record.categoryTypeName}
          validFrom={record.validFrom}
          validTo={
            <TableCell
              style={{
                boxShadow: record.validTo ? '8px 0 0 #50C9F3 inset' : ''
              }}
            >
              {record.validTo}
            </TableCell>
          }
        />
      )}
      {record.confirmationStatus === 'N' && (
        <OutletCategoryBasicRow
          id={`${record.categoryTypeId}-${record.originalDocumentId}`}
          rowStatus={determineRecordStatus(record)}
          columnsToDisplay={columnsDisplay}
          categoryTypeName={!rowState.editingOn && record.categoryTypeName}
          categoryTypeInput={
            rowState.editingOn && (
              <DropdownInputCell
                selectValue={setCategoryType}
                dropdownState={rowState.categoryType}
                getOptionLabel={(category) => category.categoryTypeName}
                getOptionSelected={(option, value) =>
                  option.categoryTypeId === value.categoryTypeId
                }
              />
            )
          }
          validFromInput={
            rowState.editingOn ? (
              <DateInputImprovedCell
                state={rowState.validFrom}
                setDate={setValidFromDate2}
                isRequired
              />
            ) : (
              <TableCell style={{ minWidth: '100px' }}>{record.validFrom}</TableCell>
            )
          }
          validTo={<TableCell style={{ minWidth: '100px' }}>{record.validTo}</TableCell>}
          actions={
            rowState.editingOn ? (
              <>
                {
                  <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                    <SaveAndCancelActions
                      handleConfirmationYes={updateCategoryRecord}
                      isProcessing={rowState.loading}
                      handleCancelButtonClick={cancelEditing}
                    />
                  </AllowedTo>
                }
              </>
            ) : (
              <>
                {
                  <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                    <EditAndRemoveActions
                      handleEditButtonClick={turnOnEditing}
                      handleRemoveButtonClick={onRemoveRecordClicked}
                      confirmationOn={rowState.removeRecordConfirmationOn}
                      handleConfirmationYes={removeRecord}
                      handleConfirmationNo={closeRemoveConfirmation}
                      isProcessing={removingCategory}
                      isSuccess={categoryRemoved}
                      error={removingCategoryError}
                      onErrorClose={closeOnRemoveError}
                    />
                  </AllowedTo>
                }
              </>
            )
          }
        />
      )}
    </>
  );
};
