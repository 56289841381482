export const PAYMENT_DATA_REQUEST = '@paymentData/PAYMENT_DATA_REQUEST';
export const PAYMENT_DATA_SUCCESS = '@paymentData/PAYMENT_DATA_SUCCESS';
export const PAYMENT_DATA_ERROR = '@paymentData/PAYMENT_DATA_ERROR';

export const PAYMENT_DOCUMENT_PDF_REQUEST = '@paymentData/PAYMENT_DOCUMENT_PDF_REQUEST';

export const CREATE_PAYMENT_REQUEST = '@paymentData/CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = '@paymentData/CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = '@paymentData/CREATE_PAYMENT_ERROR';
export const RESET_CREATE_PAYMENT_STATE = '@paymentData/RESET_CREATE_PAYMENT_STATE';

export const UPDATE_PAYMENT_REQUEST = '@paymentData/UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = '@paymentData/UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_ERROR = '@paymentData/UPDATE_PAYMENT_ERROR';
export const RESET_UPDATE_PAYMENT_STATE = '@paymentData/RESET_UPDATE_PAYMENT_STATE';

export const REMOVE_PAYMENT_REQUEST = '@paymentData/REMOVE_PAYMENT_REQUEST';
export const REMOVE_PAYMENT_SUCCESS = '@paymentData/REMOVE_PAYMENT_SUCCESS';
export const REMOVE_PAYMENT_ERROR = '@paymentData/REMOVE_PAYMENT_ERROR';
export const RESET_REMOVE_PAYMENT_STATE = '@paymentData/RESET_REMOVE_PAYMENT_STATE';
export const VIRS_PAYMENT_DATA_REQUEST = '@paymentData/VIRS_PAYMENT_DATA_REQUEST';
export const VIRS_PAYMENT_DATA_SUCCESS = '@paymentData/VIRS_PAYMENT_DATA_SUCCESS';
export const VIRS_PAYMENT_DATA_ERROR = '@paymentData/VIRS_PAYMENT_DATA_ERROR';

export const VIRS_PAYMENT_DOCUMENT_PDF_REQUEST = '@paymentData/VIRS_PAYMENT_DOCUMENT_PDF_REQUEST';

export const ANNUL_PAYMENT_DATA_REQUEST = '@paymentData/ANNUL_PAYMENT_DATA_REQUEST';
export const ANNUL_PAYMENT_DATA_SUCCESS = '@paymentData/ANNUL_PAYMENT_DATA_SUCCESS';
export const ANNUL_PAYMENT_DATA_ERROR = '@paymentData/ANNUL_PAYMENT_DATA_ERROR';
export const RESET_ANNUL_PAYMENT_DATA_STATE = '@paymentData/RESET_ANNUL_PAYMENT_DATA_STATE';

export const CLOSE_PAYMENT_REQUEST = '@paymentData/CLOSE_PAYMENT_REQUEST';
export const CLOSE_PAYMENT_SUCCESS = '@paymentData/CLOSE_PAYMENT_SUCCESS';
export const CLOSE_PAYMENT_ERROR = '@paymentData/CLOSE_PAYMENT_ERROR';
export const RESET_CLOSING_PAYMENT_STATE = '@paymentData/RESET_CLOSING_PAYMENT_STATE';

export const PAYMENT_DATA_BY_DOC_ID_REQUEST = '@paymentData/PAYMENT_DATA_BY_DOC_ID_REQUEST';
