import React, { useCallback } from 'react';
import { Button, CircularProgress, Container, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DownloadIcon from '@material-ui/icons/GetApp';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { fetchHelpFileRequest } from '../../store/helpPage/helpPageAction';
import { HelpPageTypes } from '../../store/helpPage/types';
import { ApplicationState } from '../../store';
import virsisTheme from '../../style/virsisTheme';
import { getSubsystem } from '../../utils/roleHelper';
import { applicationStorage } from '../../utils/axiosApi';

export const HelpPage: React.FC = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      margin: {
        margin: '15px 0px'
      },
      button: {
        padding: 0,
        borderColor: 'transparent',
        backgroundColor: virsisTheme.palette.secondary.light,
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
        color: '#637082'
      },
      themeButton: {
        '&:hover': {
          borderColor: virsisTheme.palette.primary.main,
          backgroundColor: virsisTheme.palette.secondary.light,
          color: virsisTheme.palette.primary.main
        },
        '&:active': {
          borderColor: virsisTheme.palette.primary.main,
          backgroundColor: '#DBEDFC',
          color: virsisTheme.palette.primary.main
        }
      },
      icon: {
        height: 30,
        padding: '5px 10px',
        borderRight: '1px solid #C4E2FC',
        color: 'inherit'
      },
      text: {
        color: '#637082',
        padding: '1px 15px'
      }
    })
  );

  const classes = useStyles();

  const {
    virsis: { currentUser },
    helpPageData: { loadingHtml, loadingFile }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  const getPageType = useCallback((): HelpPageTypes => {
    const selectedAccountType = applicationStorage.getItem('accountType');
    const subsystem = getSubsystem(currentUser);
    if (selectedAccountType === 'LEGAL') return 'INSTITUTION';
    if (selectedAccountType === 'VIRS') return 'VIRSIS';
    if (subsystem) {
      return subsystem;
    }
    return 'VIRSIS';
  }, [currentUser]);

  const getFileName = () => {
    switch (getPageType()) {
      case 'INSTITUTION':
        return 'Įstaigos naudotojo vadovas';
      case 'VIRSIS':
        return 'VIRS naudotojo vadovas';
      case 'RC':
        return 'VIRSIS administratoriaus vadovas';
      case 'LRTK':
        return 'LRTK naudotojo vadovas';
      case 'ZEIT':
        return 'ŽEIT naudotojo vadovas';
      case 'VIEA':
        return 'VIEA naudotojo vadovas';
      case 'KM':
        return 'KM naudotojo vadovas';
      case 'VIRS_VIEW':
        return 'VIRS duomenų peržiūra';
      default:
        return 'Naudotojo vadovas';
    }
  };

  const getFile = () => {
    reduxDispatch(fetchHelpFileRequest(getPageType(), getFileName()));
  };

  return (
    <Container
      style={{
        marginTop: '40px',
        maxWidth: 1590,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      <Typography variant="h1">Pagalba</Typography>
      <Typography style={{ marginTop: '8px' }} variant="h3">
        {getFileName()}
      </Typography>
      <Button
        disabled={loadingFile}
        variant="outlined"
        onClick={getFile}
        className={`${classes.button} ${classes.themeButton} ${classes.margin}`}
      >
        <DownloadIcon fontSize="small" className={classes.icon} />
        <Typography variant="h5" className={classes.text}>
          Atsisiųsti naudotojo vadovą
        </Typography>
      </Button>
      {(loadingHtml || loadingFile) && <CircularProgress />}
    </Container>
  );
};
