import { makeStyles } from '@material-ui/core';

export const useDialogFormStyles = makeStyles((theme) => ({
  textField: {
    flex: '1'
  },
  inputLabel: {
    margin: '0 8px 0 0',
    lineHeight: '25px',
    textAlign: 'right'
  },
  inputContainer: {
    margin: '4px',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: '4px'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '9px 6px',
      top: '100%'
    }
  },
  clearIcon: {
    margin: '0 0 0 4px',
    size: '12px',
    padding: '4px'
  },
  button: {
    height: '34px',
    width: '100px'
  },
  dateField: {
    fontSize: '12px',
    padding: '4px',
    minWidth: '220px'
  },
  selectInput: {
    flex: '1'
  },
  fileCard: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: 'em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px dashed #cbd5e0',
    backgroundColor: '#edf2f7',
    height: '300px',
    minWidth: '100%',
    minHeight: '100%'
  },
  [theme.breakpoints.down('xs')]: {
    dateField: {
      minWidth: 'unset'
    }
  }
}));
