import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberInputProps {
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string; name: string } }) => void;
}

export const FormattedNumberInput: React.FC<NumberInputProps> = ({
  inputRef,
  onChange,
  ...props
}) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      isNumericString
      decimalSeparator=","
      allowedDecimalSeparators={['.', ',']}
    />
  );
};
