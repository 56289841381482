import { Redirect, Switch, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from '../../components/Router/PrivateRoute';
import { ApplicationState } from '../../store';
import InstEditionCheckPage from './InstEditionCheckPage';
import InstitutionHeader from '../header/InstitutionHeader';
import HomePage from '../HomePage';
import { InstPaymentPage } from './InstPaymentPage';
import EthicalNonComplianceDataPage from './InstEthicalNonCompliancePage';
import InstitutionPage from './InstitutionPage';
import InstProfessionalMisconductPage from './InstProfessionalMisconductPage';
import AllowedTo from '../../containers/AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import AppFooter from '../../components/Footer/AppFooter';
import VirsDataCreationPage from '../../containers/VirsDataSubmision/VirsDataCreation/VirsDataCreationPage';
import VirsDataPage from '../../containers/VirsDataSubmision/VirsDataSubmision/VirsDataPage';
import InstProvidedDataPage from './InstProvidedDataPage';
import { LegalFundsReceivedPage } from '../legal/LegalFundsReceivedPage';
import UserMessagesPage from '../virs/UserMessagesPage';
import VirsDataSearchPage from '../../containers/VirsDataSubmision/VirsDataSearch/VirsDataSearchPage';
import { DataSubmission } from '../../components/InstitutionContextComponents/DataSubmission';
import { ClassifierManagementPage } from './ClassifierManagementPage';
import { ReportsPage } from './ReportsPage';
import GraphicalRepresentationPage from '../../containers/GraphicalRepresentationTools/GraphicalRepresentationPage';
import InstitutionDataReportPage from './InstitutionDataReportPage';
import InstitutionAuditsPage from './InstitutionAuditsPage';
import InstitutionIntegrationalSytemUsageAuditsPage from './InstitutionIntegrationalSytemUsageAuditsPage';
import { HelpPage } from '../../containers/HelpPage/HelpPage';
import { poolingVirsDataEditorInfoRequest } from '../../store/virsis/actions';
import { DeleteArchivedDataPage } from './DeleteArchivedDataPage';

const InstitutionRoute: React.FC = () => {
  const { authoriseError, logoutSuccess } = useSelector((state: ApplicationState) => state.virsis);

  const { currentUser, virsData } = useSelector((state: ApplicationState) => state.virsis);

  const location = useLocation();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (currentUser && virsData && virsData.virsId) {
      const roles = [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS];
      const startPoolingInfo =
        location.pathname.includes('/duomenu-perziura-ir-teikimas') &&
        roles.find((role: Roles) => currentUser.authorities.includes(Roles[role])) !== undefined;
      reduxDispatch(poolingVirsDataEditorInfoRequest(startPoolingInfo, Number(virsData.virsId)));
    }
  }, [reduxDispatch, location, currentUser, virsData]);

  if (authoriseError || logoutSuccess) {
    return <HomePage />;
  }

  return (
    <>
      <InstitutionHeader />
      <div style={{ flex: '1 0 auto' }}>
        <Switch>
          <PrivateRoute exact path="/">
            <InstitutionPage />
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas">
            <AllowedTo roles={[Roles.ROLE_VIRS_EDIT]}>
              <DataSubmission />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/metines-imokos-duomenys">
            <AllowedTo roles={[Roles.ROLE_KM_METINE_IMOKA_VIEW, Roles.ROLE_KM_METINE_IMOKA_EDIT]}>
              <InstPaymentPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/metines-imokos-duomenys/:documentStatusId">
            <AllowedTo roles={[Roles.ROLE_KM_METINE_IMOKA_VIEW, Roles.ROLE_KM_METINE_IMOKA_EDIT]}>
              <InstPaymentPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/metines-imokos-duomenys/virs/:companyCode">
            <AllowedTo roles={[Roles.ROLE_KM_METINE_IMOKA_VIEW, Roles.ROLE_KM_METINE_IMOKA_EDIT]}>
              <InstPaymentPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/profesiniai-pazeidimai">
            <AllowedTo
              roles={[
                Roles.ROLE_ZEIT_VIEW,
                Roles.ROLE_ZEIT_EDIT,
                Roles.ROLE_LRTK_VIEW,
                Roles.ROLE_LRTK_EDIT
              ]}
            >
              <InstProfessionalMisconductPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/profesiniai-pazeidimai/:documentStatusId">
            <AllowedTo
              roles={[
                Roles.ROLE_ZEIT_VIEW,
                Roles.ROLE_ZEIT_EDIT,
                Roles.ROLE_LRTK_VIEW,
                Roles.ROLE_LRTK_EDIT
              ]}
            >
              <InstProfessionalMisconductPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/profesiniai-pazeidimai/virs/:companyCode">
            <AllowedTo
              roles={[
                Roles.ROLE_ZEIT_VIEW,
                Roles.ROLE_ZEIT_EDIT,
                Roles.ROLE_LRTK_VIEW,
                Roles.ROLE_LRTK_EDIT
              ]}
            >
              <InstProfessionalMisconductPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/profesines-etikos-nesilaikymas">
            <AllowedTo roles={[Roles.ROLE_VIEA_EDIT]}>
              <EthicalNonComplianceDataPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute
            exact
            path="/duomenu-teikimas/profesines-etikos-nesilaikymas/:documentStatusId"
          >
            <AllowedTo roles={[Roles.ROLE_VIEA_EDIT]}>
              <EthicalNonComplianceDataPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute
            exact
            path="/duomenu-teikimas/profesines-etikos-nesilaikymas/virs/:companyCode"
          >
            <AllowedTo roles={[Roles.ROLE_VIEA_EDIT]}>
              <EthicalNonComplianceDataPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute
            exact
            path="/duomenu-teikimas/tirazo-patikrinimo-duomenys/:documentStatusId"
          >
            <AllowedTo roles={[Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT]}>
              <InstEditionCheckPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/tirazo-patikrinimo-duomenys">
            <AllowedTo roles={[Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT]}>
              <InstEditionCheckPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute
            exact
            path="/duomenu-teikimas/tirazo-patikrinimo-duomenys/virs/:companyCode"
          >
            <AllowedTo roles={[Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT]}>
              <InstEditionCheckPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/virs-gautos-lesos">
            <AllowedTo roles={[Roles.ROLE_ISTAIG_EDIT]}>
              <LegalFundsReceivedPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/virs-gautos-lesos/:documentStatusId">
            <AllowedTo roles={[Roles.ROLE_ISTAIG_EDIT]}>
              <LegalFundsReceivedPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/duomenu-teikimas/virs-gautos-lesos/virs/:companyCode">
            <AllowedTo roles={[Roles.ROLE_ISTAIG_EDIT]}>
              <LegalFundsReceivedPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/virs-duomenys">
            <AllowedTo roles={[Roles.ROLE_VIRS_VIEW, Roles.ROLE_VIRS]}>
              <VirsDataSearchPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/virs-duomenys/kurimas/:personId">
            <AllowedTo roles={[Roles.ROLE_VIRS_EDIT]}>
              <VirsDataCreationPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute path="/virs-duomenys/:virsId" component={VirsDataPage} />

          <PrivateRoute exact path="/duomenu-istorija">
            <AllowedTo
              roles={[
                Roles.ROLE_VIRS_EDIT,
                Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT,
                Roles.ROLE_KM_METINE_IMOKA_EDIT,
                Roles.ROLE_VIEA_EDIT,
                Roles.ROLE_ZEIT_EDIT,
                Roles.ROLE_LRTK_EDIT,
                Roles.ROLE_ISTAIG_EDIT,
                Roles.ROLE_KM_TIRAZO_TIKRINIMAS_VIEW,
                Roles.ROLE_KM_METINE_IMOKA_VIEW,
                Roles.ROLE_VIEA_VIEW,
                Roles.ROLE_ZEIT_VIEW,
                Roles.ROLE_LRTK_VIEW
              ]}
            >
              <InstProvidedDataPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/auditai">
            <AllowedTo roles={[Roles.ROLE_ADMIN_AUD]}>
              <Redirect to="/auditai/duomenys" />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/auditai/duomenys">
            <AllowedTo roles={[Roles.ROLE_ADMIN_AUD]}>
              <InstitutionAuditsPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/auditai/integraciniu-sistemu-panaudojimo-auditas">
            <AllowedTo roles={[Roles.ROLE_ADMIN_AUD]}>
              <InstitutionIntegrationalSytemUsageAuditsPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/auditai/archyvuotu-duomenu-naikinimas">
            <AllowedTo roles={[Roles.ROLE_ADMIN_ARCH]}>
              <DeleteArchivedDataPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/pagalba">
            <HelpPage />
          </PrivateRoute>

          <PrivateRoute exact path="/pranesimai">
            <UserMessagesPage />
          </PrivateRoute>

          <PrivateRoute exact path="/nustatymai/klasifikatoriu-tvarkymas">
            <AllowedTo roles={[Roles.ROLE_ADMIN_KLSF]}>
              <ClassifierManagementPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/nustatymai/grafinio-atvaizdavimo-įrankio-administravimas">
            <AllowedTo roles={[Roles.ROLE_ADMIN_KLSF]}>
              <GraphicalRepresentationPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/netikslus-duomenys">
            <AllowedTo roles={[Roles.ROLE_ADMIN_ALL]}>
              <InstitutionDataReportPage />
            </AllowedTo>
          </PrivateRoute>

          <PrivateRoute exact path="/ataskaitos">
            <AllowedTo
              roles={[
                Roles.ROLE_REPORT_A1,
                Roles.ROLE_REPORT_A2,
                Roles.ROLE_REPORT_A3,
                Roles.ROLE_REPORT_A4,
                Roles.ROLE_REPORT_A5,
                Roles.ROLE_REPORT_A6,
                Roles.ROLE_REPORT_A7,
                Roles.ROLE_REPORT_A8,
                Roles.ROLE_REPORT_A9,
                Roles.ROLE_REPORT_A10,
                Roles.ROLE_REPORT_A11,
                Roles.ROLE_REPORT_A12,
                Roles.ROLE_REPORT_A13,
                Roles.ROLE_REPORT_A15,
                Roles.ROLE_REPORT_A13ARCH
              ]}
            >
              <ReportsPage />
            </AllowedTo>
          </PrivateRoute>
        </Switch>
      </div>
      <AppFooter />
    </>
  );
};

export default InstitutionRoute;
