import React from 'react';
import { Field } from 'formik';
import { FormattedNumberInput } from './FormattedNumberInput';
import { CustomTextField } from './CustomFormikTextField';

interface Props {
  fieldName: string;
  disabled?: boolean;
}

export const CustomFormikField: React.FC<Props> = ({ fieldName, disabled }) => {
  return (
    <Field
      name={fieldName}
      component={CustomTextField}
      disabled={disabled}
      InputProps={{
        inputComponent: FormattedNumberInput
      }}
    />
  );
};
