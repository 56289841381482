import { action } from 'typesafe-actions';
import {
  AccountType,
  AgreementDateResponse,
  ContactEmailResponse,
  ContactRequest,
  EmailResponse,
  VirsDataResponse,
  VirsisUser,
  AgreementFile,
  VirsSubmitDataResponse,
  VirsSearchData,
  VirsSearchQuery,
  VirsDataEditorInfo
} from './dataTypes';
import {
  AUTHORISE_REQUEST,
  AUTHORISE_REQUEST_ERROR,
  AUTHORISE_REQUEST_SUCCESS,
  FETCH_USER_DATA_REQUEST,
  CLOSE_CONTACT_EMAIL_DIALOG,
  FETCH_AGREEMENT_DATE_ERROR,
  FETCH_AGREEMENT_DATE_REQUEST,
  FETCH_AGREEMENT_DATE_SUCCESS,
  FETCH_JAR_CONTACT_EMAIL_ERROR,
  FETCH_JAR_CONTACT_EMAIL_REQUEST,
  FETCH_JAR_CONTACT_EMAIL_SUCCESS,
  FETCH_USER_CONTACT_EMAIL_ERROR,
  FETCH_USER_CONTACT_EMAIL_REQUEST,
  FETCH_USER_CONTACT_EMAIL_SUCCESS,
  FETCH_VIRS_DATA_ERROR,
  FETCH_VIRS_DATA_REQUEST,
  FETCH_VIRS_DATA_SUCCESS,
  LOGOUT_REQUEST,
  OPEN_AGREEMENT_DIALOG,
  OPEN_CONTACT_EMAIL_DIALOG,
  OPEN_REPRESENTATION_TYPE_SELECT_MODAL,
  SAVE_CONTACT_EMAIL_ERROR,
  SAVE_CONTACT_EMAIL_REQUEST,
  SAVE_CONTACT_EMAIL_SUCCESS,
  SET_ACCOUNT_TYPE,
  LOGOUT_SUCCESS,
  ACCEPT_AGREEMENT_REQUEST,
  ACCEPT_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT_ERROR,
  FETCH_AGREEMENT_FILE_SUCCESS,
  FETCH_AGREEMENT_FILE_ERROR,
  START_VIRSIS_FLOW,
  START_VIRSIS_FLOW_ERROR,
  LOGOUT_ERROR,
  OPEN_EDIT_CONTACT_EMAIL_DIALOG,
  EXIT_EMAIL_DIALOG,
  EXIT_REPRESENTATION_TYPE_SELECT_DIALOG,
  EXIT_AGREEMENT_DIALOG,
  SUBMIT_VIRS_DATA_REQUEST,
  SUBMIT_VIRS_DATA_ERROR,
  SUBMIT_VIRS_DATA_SUCCESS,
  SUBMIT_VIRS_DATA_RESET,
  SEARCH_VIRS_REQUEST,
  SEARCH_VIRS_SUCCESS,
  SEARCH_VIRS_ERROR,
  RESET_SEARCH_VIRS_STATE,
  FETCH_USER_DATA_SUCCESS,
  APPLICATION_LOADING,
  APPLICATION_LOADING_STOP,
  RESET_VIRS_DATA_REQUEST,
  FETCH_JAR_COUNTRIES_REQUEST,
  FETCH_JAR_COUNTRIES_SUCCESS,
  SET_PERSON_ID,
  FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST,
  FETCH_VIRS_DATA_BY_VIRS_ID_SUCCESS,
  FETCH_VIRS_DATA_BY_VIRS_ID_ERROR,
  SET_VIRS_ID,
  CLOSE_UNSIGNED_ALERT,
  SEARCH_VIRS_ACTIVE_ONLY_REQUEST,
  REGISTER_ACCOUNT_TYPE_CHOSEN,
  REGISTER_ACCOUNT_TYPE_CHOSEN_SUCCESS,
  REGISTER_ACCOUNT_TYPE_CHOSEN_ERROR,
  CLOSE_DATA_EDITING_ALERT,
  FETCH_DATA_EDITOR_REQUEST,
  FETCH_DATA_EDITOR_SUCCESS,
  FETCH_DATA_EDITOR_ERROR,
  RESET_PERSON_ID,
  SYNCHRONIZE_VIRS,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_SUCCESS,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_ERROR
} from './actionTypes';
import { JarCountry } from '../classifiers/classifiersTypes';

export const logout = () => action(LOGOUT_REQUEST);
export const logoutSuccess = () => action(LOGOUT_SUCCESS);
export const logoutError = (error: Error) => action(LOGOUT_ERROR, error);
export const authoriseRequest = (enc?: string) => action(AUTHORISE_REQUEST, enc);
export const authoriseSuccess = (user: VirsisUser) => action(AUTHORISE_REQUEST_SUCCESS, user);
export const authoriseError = (error: Error) => action(AUTHORISE_REQUEST_ERROR, error);
export const fetchUser = () => action(FETCH_USER_DATA_REQUEST);
export const fetchUserDataSuccess = () => action(FETCH_USER_DATA_SUCCESS);
export const applicationLoading = () => action(APPLICATION_LOADING);
export const applicationLoadingStop = () => action(APPLICATION_LOADING_STOP);

export const openRepresentationTypeSelectModal = () =>
  action(OPEN_REPRESENTATION_TYPE_SELECT_MODAL);

export const openAgreementDialog = () => action(OPEN_AGREEMENT_DIALOG);

export const fetchAgreementDateRequest = () => action(FETCH_AGREEMENT_DATE_REQUEST);
export const fetchAgreementDateSuccess = (response: AgreementDateResponse) =>
  action(FETCH_AGREEMENT_DATE_SUCCESS, response);
export const fetchAgreementDateError = (error: Error) => action(FETCH_AGREEMENT_DATE_ERROR, error);

export const acceptAgreementRequest = (personId: number) =>
  action(ACCEPT_AGREEMENT_REQUEST, personId);
export const acceptAgreementSuccess = (agreementDate: string) =>
  action(ACCEPT_AGREEMENT_SUCCESS, agreementDate);
export const acceptAgreementError = (error: Error) => action(ACCEPT_AGREEMENT_ERROR, error);

export const openContactEmailDialog = () => action(OPEN_CONTACT_EMAIL_DIALOG);
export const closeContactEmailDialog = () => action(CLOSE_CONTACT_EMAIL_DIALOG);

export const openEditContactEmailDialog = (isVirsEmailBeingEdited: boolean) =>
  action(OPEN_EDIT_CONTACT_EMAIL_DIALOG, isVirsEmailBeingEdited);

export const fetchUserContactEmailRequest = (personId: number) =>
  action(FETCH_USER_CONTACT_EMAIL_REQUEST, personId);
export const fetchUserContactEmailSuccess = (response: EmailResponse) =>
  action(FETCH_USER_CONTACT_EMAIL_SUCCESS, response);
export const fetchUserContactEmailError = () => action(FETCH_USER_CONTACT_EMAIL_ERROR);

export const fetchSelectedVirsContactEmailRequest = (personId: number) =>
  action(FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST, personId);
export const fetchSelectedVirsContactEmailSuccess = (response: EmailResponse) =>
  action(FETCH_SELECTED_VIRS_CONTACT_EMAIL_SUCCESS, response);
export const fetchSelectedVirsContactEmailError = () =>
  action(FETCH_SELECTED_VIRS_CONTACT_EMAIL_ERROR);

export const saveVirsContactEmailRequest = (contact: ContactRequest) =>
  action(SAVE_CONTACT_EMAIL_REQUEST, contact);
export const saveVirsContactEmailSuccess = (response: ContactEmailResponse) =>
  action(SAVE_CONTACT_EMAIL_SUCCESS, response);
export const saveVirsContactEmailError = (error: Error) => action(SAVE_CONTACT_EMAIL_ERROR, error);

export const fetchJarContactEmailRequest = (personId: number) =>
  action(FETCH_JAR_CONTACT_EMAIL_REQUEST, personId);
export const fetchJarContactEmailSuccess = (response: EmailResponse) =>
  action(FETCH_JAR_CONTACT_EMAIL_SUCCESS, response);
export const fetchJarContactEmailError = (error: Error) =>
  action(FETCH_JAR_CONTACT_EMAIL_ERROR, error);

export const resetVirsDataRequest = () => action(RESET_VIRS_DATA_REQUEST);
export const fetchVirsDataRequest = () => action(FETCH_VIRS_DATA_REQUEST);
export const fetchVirsDataSuccess = (response: VirsDataResponse) =>
  action(FETCH_VIRS_DATA_SUCCESS, response);
export const fetchVirsDataError = (error: Error) => action(FETCH_VIRS_DATA_ERROR, error);

export const fetchVirsDataByVirsIdRequest = (personId: number) =>
  action(FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST, personId);
export const fetchVirsDataByVirsIdSuccess = (response: VirsDataResponse) =>
  action(FETCH_VIRS_DATA_BY_VIRS_ID_SUCCESS, response);
export const fetchVirsDataByVirsIdError = (error: Error) =>
  action(FETCH_VIRS_DATA_BY_VIRS_ID_ERROR, error);

export const setAccountType = (accountType: AccountType) => action(SET_ACCOUNT_TYPE, accountType);

export const fetchAgreementFileSuccess = (file: AgreementFile) =>
  action(FETCH_AGREEMENT_FILE_SUCCESS, file);
export const fetchAgreementFileError = (error: Error) => action(FETCH_AGREEMENT_FILE_ERROR, error);

export const startVirsisFlow = (personId: number) => action(START_VIRSIS_FLOW, personId);
export const startVirsisFlowError = (error: Error) => action(START_VIRSIS_FLOW_ERROR, error);

export const exitEmailDialog = () => action(EXIT_EMAIL_DIALOG);
export const exitRepresentationTypeSelectDialog = () =>
  action(EXIT_REPRESENTATION_TYPE_SELECT_DIALOG);
export const exitAgreementDialog = () => action(EXIT_AGREEMENT_DIALOG);

export const submitVirsDataRequest = (personId: number) =>
  action(SUBMIT_VIRS_DATA_REQUEST, personId);
export const submitVirsDataSuccess = (response: VirsSubmitDataResponse) =>
  action(SUBMIT_VIRS_DATA_SUCCESS, response);
export const submitVirsDataError = (error: Error) => action(SUBMIT_VIRS_DATA_ERROR, error);
export const submitVirsDataReset = () => action(SUBMIT_VIRS_DATA_RESET);

export const searchVirsData = (virsQuery: VirsSearchQuery) =>
  action(SEARCH_VIRS_REQUEST, virsQuery);
export const searchVirsActiveOnlyData = (virsQuery: VirsSearchQuery) =>
  action(SEARCH_VIRS_ACTIVE_ONLY_REQUEST, virsQuery);
export const searchVirsDataSuccess = (naturalPersonData: VirsSearchData) =>
  action(SEARCH_VIRS_SUCCESS, naturalPersonData);
export const searchVirsDataError = (error: string) => action(SEARCH_VIRS_ERROR, error);
export const resetSearchVirsState = () => action(RESET_SEARCH_VIRS_STATE);

export const fetchJarCountriesRequest = () => action(FETCH_JAR_COUNTRIES_REQUEST);
export const fetchJarCountriesSuccess = (jarCountries: JarCountry[]) =>
  action(FETCH_JAR_COUNTRIES_SUCCESS, jarCountries);

export const setSelectedPersonId = (personId: number) => action(SET_PERSON_ID, personId);
export const resetSelectedPersonId = () => action(RESET_PERSON_ID);

export const setSelectedVirsId = (virsId: number) => action(SET_VIRS_ID, virsId);

export const closeUnsignedDataAlert = () => action(CLOSE_UNSIGNED_ALERT);

export const closeDataEditingAlert = () => action(CLOSE_DATA_EDITING_ALERT);

export const poolingVirsDataEditorInfoRequest = (pooling: boolean, virsId: number) =>
  action(FETCH_DATA_EDITOR_REQUEST, { pooling, virsId });
export const poolingVirsDataEditorInfoSuccess = (data: VirsDataEditorInfo | undefined) =>
  action(FETCH_DATA_EDITOR_SUCCESS, data);
export const poolingVirsDataEditorInfoError = () => action(FETCH_DATA_EDITOR_ERROR);

export const registerAccountType = (accountName: string | null, virsId: number | null) =>
  action(REGISTER_ACCOUNT_TYPE_CHOSEN, { accountName, virsId });

export const registerAccountTypeSuccess = () => action(REGISTER_ACCOUNT_TYPE_CHOSEN_SUCCESS);

export const registerAccountTypeError = (error: Error) =>
  action(REGISTER_ACCOUNT_TYPE_CHOSEN_ERROR, error);

export const synchronizeVirs = () => action(SYNCHRONIZE_VIRS);
