import { TableCell } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  fetchPublicationOutlets,
  updateEditionCheck
} from '../../../store/editionChecks/editionChecksActions';
import {
  EditionCheckConclusionType,
  EditionCheckDataInst,
  EditionCheckRecord
} from '../../../store/editionChecks/editionChecksTypes';
import { EditionCheckDataTableStateContext } from '../Context';
import {
  joinEditionCheckIntoFormData,
  validateEditionCheckEditRecordState
} from './editionCheckRecordState/editionCheckRowReducerFunctions';
import {
  EditionCheckEditRecordAction,
  EditionCheckEditRecordState
} from './editionCheckRecordState/initialStateAndTypes';
import { EditionCheckFilesInput } from '../../../components/TableInputs/FilesUploadInput/EditionCheckFilesInput';
import { ApplicationState } from '../../../store';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { SignDocumentButton } from '../../../components/TableButtons/RowActionButtons/SignDocumentButton';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { DocumentNumberInput } from '../../../components/TableInputs/DocumentNumberInput';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { fetchMissingPeriods } from '../../../store/outletInfoData/outletInfoDataActions';
import { OutletEditionPeriod } from '../../../store/outletInfoData/outletInfoDataTypes';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { getOutletOption } from '../../../components/TableInputs/Utilities';
import { AlertDialog } from '../../../components/Dialogs/AlertDialog';

interface EditionCheckDataTableEditRecordProps {
  rowState: EditionCheckEditRecordState;
  totalRowSpan: number;
  record: EditionCheckDataInst;
  rowDispatch: React.Dispatch<EditionCheckEditRecordAction>;
}

export const EditionCheckDataTableEditRecord: React.FC<EditionCheckDataTableEditRecordProps> = ({
  rowState,
  totalRowSpan,
  record,
  rowDispatch
}) => {
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);
  const reduxDispatch = useDispatch();

  const {
    editionData: {
      updatingEditionError,
      editionCheckConclusionTypes,
      virsSearchDataWithPublicationOutlets
    },
    classifiers: { enterpriseTypes },
    outletInfoData: { missingPeriodsForOutlet, loadingMissingPeriods }
  } = useSelector((state: ApplicationState) => state);

  const [showNoPublicationOutletsDialog, setShowNoPublicationOutletsDialog] = useState(false);

  const cancelEditing = () => rowDispatch({ type: 'EDITING_CANCELLED' });

  const updateRecord = () => {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateEditionCheckEditRecordState(rowState).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const updatedEditionCheck: EditionCheckRecord = {
        editionCheckId: rowState.editionCheckId,
        virsId: rowState.virs.value?.virId,
        checkDate: rowState.checkDate.value
          ? rowState.checkDate.value.format('L').toString()
          : null,
        editionCheckDocuments: rowState.editionCheckDocs,
        editionCheckVips: rowState.editionCheckOutlets.map((vip) => ({
          vipId: vip.outlet.value?.outletId,
          conclusions: vip.editionCheckConclusions.map((conclusion) => ({
            conclusionId: conclusion.conclusion.value?.editionCheckConclusionId,
            periodId: conclusion.period.value?.periodId
          }))
        }))
      };
      const asFormData = joinEditionCheckIntoFormData(
        updatedEditionCheck,
        rowState.addedDocuments.files,
        rowState.customDocumentsName.value || ''
      );
      reduxDispatch(updateEditionCheck(asFormData, rowState.editionCheckId));
    }
  };

  function setCheckDate(checkDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'CHECK_DATE_CHANGED', checkDate });
  }

  function changeOutlet(outletIndex: number) {
    return (selectedValue: OutletShortData | null) => {
      if (selectedValue) {
        rowDispatch({
          type: 'EDITION_CHECK_OUTLETS_CHANGED',
          outlet: selectedValue,
          outletIndex
        });
        reduxDispatch(fetchMissingPeriods(selectedValue.outletId));
      }
    };
  }

  const addOutletHandler = () => {
    rowDispatch({ type: 'ADD_OUTLET_ROW' });
  };

  const cancelOutletHandler = (outletIndex: number) => {
    if (rowState.editionCheckOutlets.length > 1)
      rowDispatch({ type: 'CANCEL_OUTLET_ROW', outletIndex: outletIndex });
  };

  function changePeriod(outletIndex: number, conclusionIndex: number) {
    return (selectedValue: OutletEditionPeriod | null) => {
      rowDispatch({
        type: 'EDITION_CHECK_PERIOD_CHANGED',
        outletIndex,
        conclusionIndex,
        period: selectedValue
      });
    };
  }

  const addPeriodHandler = (outletIndex: number) => {
    rowDispatch({
      type: 'ADD_PERIOD_ROW',
      outletIndex: outletIndex
    });
  };

  const reducePeriodHandler = (outletIndex: number, conclusionIndex: number) => {
    if (rowState.editionCheckOutlets[outletIndex].editionCheckConclusions.length > 1) {
      rowDispatch({ type: 'REDUCE_PERIOD_ROW', outletIndex: outletIndex, conclusionIndex });
    }
  };

  function changeConclusion(outletIndex: number, conclusionIndex: number) {
    return (selectedValue: EditionCheckConclusionType | null) => {
      rowDispatch({
        type: 'EDITION_CHECK_CONCLUSION_CHANGED',
        outletIndex,
        conclusionIndex,
        conclusion: selectedValue
      });
    };
  }

  const addFile = (file: File) => {
    if (file) rowDispatch({ type: 'FILE_ADDED', file });
  };

  const removeFile = (fileId: number) => rowDispatch({ type: 'FILE_REMOVED', fileId });

  const changeFilesCustomName = (filesCustomName: string) =>
    rowDispatch({
      type: 'FILES_CUSTOM_NAME_CHANGED',
      filesCustomName
    });

  const handleFileWrongFormat = () => {
    rowDispatch({
      type: 'FILE_ADDING_WRONG_FORMAT',
      errorMessage: 'netinkamas dokumento formatas'
    });
  };

  const removeExistingDocument = (documentId: number) =>
    rowDispatch({ type: 'DOCUMENT_REMOVED', documentId });

  useEffect(() => {
    if (updatingEditionError) {
      rowDispatch({ type: 'STOP_LOADING' });
    }
  }, [rowDispatch, updatingEditionError]);

  useEffect(() => {
    if (!loadingMissingPeriods) rowDispatch({ type: 'PERIODS_LOADED', missingPeriodsForOutlet });
  }, [loadingMissingPeriods, missingPeriodsForOutlet, rowDispatch]);

  useEffect(() => {
    if (virsSearchDataWithPublicationOutlets?.id === record.editionCheckId) {
      if (virsSearchDataWithPublicationOutlets.publicationOutlets.length) {
        rowDispatch({
          type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
          virs: {
            ...virsSearchDataWithPublicationOutlets.virs,
            outlets: virsSearchDataWithPublicationOutlets.publicationOutlets
          },
          conclusionTypes: editionCheckConclusionTypes
        });
      } else {
        setShowNoPublicationOutletsDialog(true);
      }
    }
  }, [
    editionCheckConclusionTypes,
    record.editionCheckId,
    rowDispatch,
    virsSearchDataWithPublicationOutlets
  ]);

  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  rowState.editionCheckOutlets.forEach((outlet, outletIndex) => {
    outlet.editionCheckConclusions.forEach((conclusion, conclusionIndex) => {
      rows.push(
        <RowStyleOnDifferentStatus status={record.documentStatus} key={rowIndex}>
          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.virsName && (
                <VirsInputCell
                  openSearchDialog={() => rowDispatch({ type: 'VIRS_INPUT_CLICKED' })}
                  state={rowState.virs}
                  rowSpan={totalRowSpan}
                />
              )}
              {tableState.columnsDisplayStatus.checkDate && (
                <DateInputImprovedCell
                  state={rowState.checkDate}
                  setDate={setCheckDate}
                  rowSpan={totalRowSpan}
                />
              )}
              {tableState.columnsDisplayStatus.docNr && (
                <TableCell align="left" rowSpan={totalRowSpan}>
                  <DocumentNumberInput
                    setValue={changeFilesCustomName}
                    inputState={rowState.customDocumentsName}
                    isRequired
                  />
                  <EditionCheckFilesInput
                    editionCheckDocuments={rowState.editionCheckDocs}
                    removeEditionCheckDocument={removeExistingDocument}
                    state={rowState.addedDocuments}
                    customName={rowState.customDocumentsName}
                    addFile={addFile}
                    removeFile={removeFile}
                    changeCustomName={changeFilesCustomName}
                    setWrongFormatError={handleFileWrongFormat}
                  />
                </TableCell>
              )}
            </>
          )}
          {conclusionIndex === 0 && tableState.columnsDisplayStatus.outletName && (
            <DropdownInputCell
              selectValue={changeOutlet(outletIndex)}
              dropdownState={rowState.editionCheckOutlets[outletIndex].outlet}
              getOptionSelected={(option, value) =>
                value ? option.outletId === value.outletId : false
              }
              renderOption={getOutletOption}
              getOptionLabel={(option) =>
                `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
              }
              rowSpan={outlet.editionCheckConclusions.length}
              addUnit={addOutletHandler}
              removeUnit={() => cancelOutletHandler(outletIndex)}
              showRemoveButton={rowIndex !== 0}
            />
          )}
          {tableState.columnsDisplayStatus.periodName && (
            <DropdownInputCell
              selectValue={changePeriod(outletIndex, conclusionIndex)}
              dropdownState={
                rowState.editionCheckOutlets[outletIndex].editionCheckConclusions[conclusionIndex]
                  .period
              }
              getOptionLabel={(period) => period.periodName}
              getOptionSelected={(option, value) =>
                value ? option.periodId === value.periodId : false
              }
              addUnit={() => addPeriodHandler(outletIndex)}
              removeUnit={() => reducePeriodHandler(outletIndex, conclusionIndex)}
              showRemoveButton={conclusionIndex !== 0}
            />
          )}
          {tableState.columnsDisplayStatus.conclusion && (
            <DropdownInputCell
              selectValue={changeConclusion(outletIndex, conclusionIndex)}
              dropdownState={
                rowState.editionCheckOutlets[outletIndex].editionCheckConclusions[conclusionIndex]
                  .conclusion
              }
              getOptionLabel={(conclusion) => conclusion.editionCheckConclusionTypeName}
              getOptionSelected={(option, value) =>
                value ? option.editionCheckConclusionId === value.editionCheckConclusionId : false
              }
            />
          )}
          {rowIndex === 0 && (
            <>
              <TableCell align="left" rowSpan={totalRowSpan}>
                <SaveAndCancelActions
                  handleConfirmationYes={updateRecord}
                  isProcessing={rowState.loading}
                  handleCancelButtonClick={cancelEditing}
                />
              </TableCell>
              <TableCell align="left" rowSpan={totalRowSpan}>
                <SignDocumentButton />
              </TableCell>
            </>
          )}
        </RowStyleOnDifferentStatus>
      );
      rowIndex++;
    });
  });
  return (
    <>
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={() => rowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' })}
          closeAndContinueWithVirs={(virs: VirsSearchData) =>
            reduxDispatch(fetchPublicationOutlets(virs, record.editionCheckId))
          }
          disableVirsWithoutOutlets
        />
      )}
      <AlertDialog
        isOpen={showNoPublicationOutletsDialog}
        dialogText="Šis VIRS neturi VIP, kurioms reikėtų tiražo tikrinimo"
        onContinue={() => setShowNoPublicationOutletsDialog(false)}
        onClose={() => setShowNoPublicationOutletsDialog(false)}
        showCloseButton={false}
      />
      {rows}
    </>
  );
};
