import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  searchLegalPersonData,
  searchLegalPersonDataSuccess,
  searchLegalPersonDataError,
  createLegalForeignPerson,
  createLegalForeignPersonSuccess,
  createLegalForeignPersonError
} from './legalDataActions';
import {
  CREATE_LEGAL_FOREIGN_PERSON_REQUEST,
  SEARCH_LEGAL_PERSON_REQUEST
} from './legalDataActionTypes';
import { getFindLegalPerson, postCreateLegalForeignPerson } from './legalDataApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';

function* handleLegalPersonSearchSaga(action: ActionType<typeof searchLegalPersonData>) {
  try {
    const data = yield call(getFindLegalPerson, action.payload);
    yield put(searchLegalPersonDataSuccess(data.data.searchResults));
  } catch (err) {
    yield put(searchLegalPersonDataError(err.response));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* handleCreateLegalForeignPersonSaga(action: ActionType<typeof createLegalForeignPerson>) {
  try {
    yield call(postCreateLegalForeignPerson, action.payload);
    yield put(createLegalForeignPersonSuccess());
    yield put(sendMessage('success', 'Užsenio juridinis asmuo išsaugotas'));
  } catch (err) {
    yield put(createLegalForeignPersonError(err.response));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* legalDataSaga() {
  yield takeEvery(SEARCH_LEGAL_PERSON_REQUEST, handleLegalPersonSearchSaga);
  yield takeEvery(CREATE_LEGAL_FOREIGN_PERSON_REQUEST, handleCreateLegalForeignPersonSaga);
}

export default legalDataSaga;
