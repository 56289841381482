import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import {
  EnterpriseDataTableColumnsDisplayStatus,
  EnterpriseDataTableField,
  EnterpriseDataTableColumn
} from './tableState/initialStateAndTypes';
import { EnterpriseData } from '../../store/enterprises/enterprisesTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnParams: EnterpriseDataTableColumn[];
  columnsDisplayStatus: EnterpriseDataTableColumnsDisplayStatus;
  onCustomFilterChange: (column: EnterpriseDataTableField, values: string[]) => void;
  tableData: EnterpriseData[];
}

const EnterpriseTableFilterRow: React.FC<Props> = ({
  columnParams,
  columnsDisplayStatus,
  onCustomFilterChange,
  tableData
}) => {
  function setSingleValueForFilter(column: EnterpriseDataTableField, value: string | null) {
    onCustomFilterChange(column, value ? [value] : []);
  }

  function getUniqueOptions(
    data: EnterpriseData[],
    objectField: EnterpriseDataTableField
  ): string[] {
    const allValues = data
      .filter((record) => record[objectField] && record[objectField] !== '')
      .map((record) => record[objectField]);
    return Array.from(new Set(allValues));
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnParams
          .filter((header) => columnsDisplayStatus[header.id])
          .map((header) => (
            <TableCustomFilterCellGeneric
              key={header.id}
              id={header.id}
              type={header?.type}
              setFilter={
                header?.type === 'multiple' ? onCustomFilterChange : setSingleValueForFilter
              }
              uniqueOptions={getUniqueOptions(tableData, header.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default EnterpriseTableFilterRow;
