import React from 'react';
import { TableBody } from '@material-ui/core';
import { ActivityPeriod, ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import { useShareholdersTableState } from '../ShareholdersTableWithContext';
import { TableRowsContainer } from '../TableRows/TableRowsContainer';
import { getFilteredMixedDates, getYearsArray } from '../utilities/functionsWithDateFormat';
import FilteredViewTableHeader from './FilteredViewTableHeader';
import { YEAR_LABEL } from '../../constants';
import { DatesWithEvents } from '../tableState/tableInitialStateAndTypes';

interface Props {
  allEventsDates: string[];
  sortedShareholderData: ShareholdersData;
  openCalendar: () => void;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  loadingShareholdersData: boolean;
  periodFilterFromDate: string;
  periodFilterToDate: string;
  activityPeriods: ActivityPeriod[];
}

const MixeDatesTable: React.FC<Props> = ({
  allEventsDates,
  sortedShareholderData,
  openCalendar,
  noValidDates,
  allShareholdersFromJADIS,
  loadingShareholdersData,
  periodFilterFromDate,
  activityPeriods,
  periodFilterToDate
}) => {
  const {
    state: { datesInPerdiodWithEvents, periodFilter }
  } = useShareholdersTableState();
  const yearsArray = getYearsArray(sortedShareholderData.virsStartDate);

  const dateRange = periodFilter.label === YEAR_LABEL ? 'year' : 'month';

  const activityDays: DatesWithEvents[] = activityPeriods
    .flatMap((period) => Object.values(period))
    .filter((date) => date !== null)
    .map((date) => ({ date: date, hasEvent: true }));

  datesInPerdiodWithEvents.concat(activityDays);

  const filteredDates = getFilteredMixedDates(
    datesInPerdiodWithEvents,
    {
      periodFilterFromDate,
      periodFilterToDate
    },
    dateRange
  );

  const onlyDates = filteredDates.map((x) => x.date);

  return (
    <>
      <FilteredViewTableHeader
        openCalendar={openCalendar}
        allEventsDates={allEventsDates}
        yearsArray={yearsArray}
        shareholdersData={sortedShareholderData}
        noValidDates={noValidDates}
        allShareholdersFromJADIS={allShareholdersFromJADIS}
        loadingShareholdersData={loadingShareholdersData}
        datesInPerdiodWithEvents={filteredDates}
      />

      <TableBody>
        <TableRowsContainer data={sortedShareholderData} allEventsDates={onlyDates} />
      </TableBody>
    </>
  );
};

export default MixeDatesTable;
