import { PaginationParams, SortOrder } from '../../utils/tableTypes';
import { Order } from '../../components/TableTypes/TableTypes';
import {
  FundsReceivedLegalTableCustomFilter,
  FundsReceivedLegalTableField
} from '../../containers/FundsReceivedTableInstLegal/tableState/tableTypesAndActions';

export interface FundsReceivedDataPageRequest extends PaginationParams {
  filterVirsId: number | null;
  filterFundsReceivedYear: string | null;
  filterFundsReceivedSum: string | null;
  filterFundsSourceName: string | null;
  filterFundsSourceCode: string | null;
  filterOutletId: number | null;
  filterTransactionTypeId: number | null;
  sortingVirsName: SortOrder | null;
  sortingFundsReceivedYear: SortOrder | null;
  sortingFundsReceivedSum: SortOrder | null;
  sortingFundsSourceName: SortOrder | null;
  sortingFundsSourceCode: SortOrder | null;
  sortingOutletName: SortOrder | null;
  sortingTransactionTypeName: SortOrder | null;
}

export interface FundsReceivedTableDataState {
  sortBy: FundsReceivedLegalTableField;
  order: Order;
  page: number;
  rowsPerPage: number;
  customFilter: FundsReceivedLegalTableCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
}

export const initialFundsReceivedTableDataState: FundsReceivedTableDataState = {
  order: 'desc',
  sortBy: 'fundsReceivedYear',
  page: 0,
  rowsPerPage: 5,
  customFilter: {
    virsName: [],
    fundsReceivedYear: [],
    fundsReceivedSum: [],
    fundsSourceName: [],
    fundsSourceCode: [],
    transaction: [],
    fundsReceivedOutlets: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false
};
