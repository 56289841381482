import { action } from 'typesafe-actions';
import {
  FETCH_INSTITUTION_DATA_REPORTS_REQUEST,
  FETCH_INSTITUTION_DATA_REPORTS_ERROR,
  FETCH_INSTITUTION_DATA_REPORTS_SUCCESS,
  REPORT_INSTITUTION_DATA_REQUEST,
  REPORT_INSTITUTION_DATA_SUCCESS,
  REPORT_INSTITUTION_DATA_ERROR,
  RESET_INACCURATE_DATA_REPORTING_STATE,
  FETCH_INACCURATE_DATA_MESSAGE_REQUEST,
  FETCH_INACCURATE_DATA_MESSAGE_SUCCESS,
  FETCH_INACCURATE_DATA_MESSAGE_ERROR
} from './institutionDataReportsActionTypes';
import {
  InstitutionDataReport,
  ReportInaccurateDataMessage,
  ReportInstitutionDataRecord
} from './institutionDataReportsTypes';

export const fetchInsitutionsDataReports = () => action(FETCH_INSTITUTION_DATA_REPORTS_REQUEST);
export const fetchInsitutionsDataReportsSuccess = (
  institutionDataReportData: InstitutionDataReport[]
) => action(FETCH_INSTITUTION_DATA_REPORTS_SUCCESS, institutionDataReportData);
export const fetchInsitutionsDataReportsError = (error: Error) =>
  action(FETCH_INSTITUTION_DATA_REPORTS_ERROR, error);

export const reportInaccurateInstitutionData = (report: ReportInstitutionDataRecord) =>
  action(REPORT_INSTITUTION_DATA_REQUEST, report);
export const reportInaccurateInstitutionDataSuccess = () => action(REPORT_INSTITUTION_DATA_SUCCESS);
export const reportInaccurateInstitutionDataError = (error: Error) =>
  action(REPORT_INSTITUTION_DATA_ERROR, error);
export const resetReportInaccurateInstitutionDataState = () =>
  action(RESET_INACCURATE_DATA_REPORTING_STATE);

export const fetchInaccurateDataMessageRequest = (docId: number) =>
  action(FETCH_INACCURATE_DATA_MESSAGE_REQUEST, docId);
export const fetchInaccurateDataMessageSuccess = (data: ReportInaccurateDataMessage) =>
  action(FETCH_INACCURATE_DATA_MESSAGE_SUCCESS, data);
export const fetchInaccurateDataMessageError = (error: Error) =>
  action(FETCH_INACCURATE_DATA_MESSAGE_ERROR, error);
