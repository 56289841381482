import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Roles } from '../../../../store/virsis/dataTypes';
import AllowedTo from '../../../AllowedTo';
import { HeaderActions } from '../../MainTable/Components/HeaderActions';
import { AddShareholderButtonContainer } from './AddShareholderComponents/AddShareholderButtonContainer';
import { ApplicationState } from '../../../../store';
import { TableActionButton } from '../../../../components/TableButtons/TableActionButton';
import { FileFormats } from '../../../../utils/exporters/types';
import { applicationStorage } from '../../../../utils/axiosApi';
import { showShareholderDataDeletionByDateDialog } from '../../../../store/shareholders/shareholdersActions';

const useStyles = makeStyles((theme) => ({
  buttonWrapperInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      blockSize: 'fit-content',
    }
  },
  buttonWrapperOuter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  }
}));

interface Props {
  onDownload?: (format: FileFormats, navigationPath: string[]) => void;
  onPrint?: (navigationPath: string[]) => void;
  onVizualizeClick: () => void;
}

const ShareholderSelectedDateHeader: React.FC<Props> = ({
  onDownload,
  onPrint,
  onVizualizeClick
}) => {
  const history = useHistory();

  const goToPath = (path: string) => {
    history.push(path);
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    virsis: { selectedVirsId },
    shareholdersData: { selectedDate }
  } = useSelector((state: ApplicationState) => state);

  const context = applicationStorage.getItem('context');
  const path =
    context === 'VIRS'
      ? '/duomenu-perziura-ir-teikimas/dalyviai?syncJADIS=false'
      : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/dalyviai?syncJADIS=false`;

  const onClickDelete = () => {
    dispatch(showShareholderDataDeletionByDateDialog(selectedDate));
  };

  return (
    <>
      <Typography style={{ margin: 'auto 0' }} variant="h1">
        Dalyviai
      </Typography>

      <Box className={classes.buttonWrapperOuter}>
        <Box className={classes.buttonWrapperInner}>
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <AddShareholderButtonContainer />
          </AllowedTo>
          <TableActionButton
            onClick={() => goToPath(path)}
            IconComponent={ArrowBackIcon}
            text="Grįžti į visų datų lentelę"
          />
        </Box>
        <HeaderActions
          onDownload={onDownload}
          onPrint={onPrint}
          onVizualizeClick={onVizualizeClick}
          onClickDelete={onClickDelete}
        />
      </Box>
    </>
  );
};

export default ShareholderSelectedDateHeader;
