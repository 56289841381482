import { put, select, takeLatest } from '@redux-saga/core/effects';
import { call } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { get } from '../../utils/axiosApi';
import { getLoaderStore } from '../../utils/loaderStore';
import { VirsisState } from '../virsis/reducer';

export interface VirsProvidedDataStatus {
  enterprisesUnsigned: boolean;
  outletsUnsigned: boolean;
  shareholdersUnsigned: boolean;
}

const initialState = {
  enterprisesUnsigned: false,
  outletsUnsigned: false,
  shareholdersUnsigned: false
};

const {
  actions: { load, setLoaded, setError, setLoading, setData },
  reducer
} = getLoaderStore('@VirsProvidedDataStatus', initialState);

export const getVirsProvidedDataStatusRequest = load;
export const setVirsProvidedDataStatusLoaded = setLoaded;
export const setVirsProvidedDataStatusValue = setData;
export const setVirsProvidedDataStatusLoading = setLoading;
export const setVirsProvidedDataStatusError = setError;
export const virsProvidedDataStatusReducer = reducer;

function* loadTask() {
  yield put(setLoaded(false));
  yield put(setLoading(true));
  yield put(setVirsProvidedDataStatusValue(initialState));

  try {
    const { selectedVirsId, virsData }: VirsisState = yield select(
      (state: ApplicationState) => state.virsis
    );
    const personId = virsData?.personId;
    const path =
      selectedVirsId && personId
        ? `/virs/legals/${personId}/provided-data-status`
        : '/virs/legals/provided-data-status';

    const { data }: { data: VirsProvidedDataStatus } = yield call(get, path);
    yield put(setVirsProvidedDataStatusValue(data));
    yield put(setLoaded(true));
  } catch (err) {
    const message = err?.response?.data?.message;
    yield put(setError(message));
  }
  yield put(setLoading(false));
}

export function* virsProvidedDataStatusSaga() {
  yield takeLatest(load, loadTask);
}
