export const FETCH_PROVIDED_DATA_VIRS_REQUEST = '@providedDataVirs/FETCH_DATA_REQUEST';
export const FETCH_PROVIDED_DATA_VIRS_SUCCESS = '@providedDataVirs/FETCH_DATA_SUCCESS';
export const FETCH_PROVIDED_DATA_VIRS_ERROR = '@providedDataVirs/FETCH_DATA_ERROR';

export const FETCH_PROVIDED_DATA_BY_VIRS_ID_REQUEST =
  '@providedDataVirs/FETCH_DATA_BY_VIRS_ID_REQUEST';
export const ANNUL_PROVIDED_DATA_VIRS_REQUEST = '@providedDataVirs/ANNUL_DATA_VIRS_REQUEST';

// INST REQUEST TYPES --------------------------------------------
export const FETCH_PROVIDED_DATA_INST_REQUEST = '@providedDataInst/FETCH_DATA_REQUEST';
export const FETCH_PROVIDED_DATA_INST_SUCCESS = '@providedDataInst/FETCH_DATA_SUCCESS';
export const FETCH_PROVIDED_DATA_INST_ERROR = '@providedDataInst/FETCH_DATA_ERROR';
export const ANNUL_PROVIDED_DATA_INST_REQUEST = '@providedDataInst/ANNUL_DATA_INST_REQUEST';

// LEGAL REQUEST TYPES --------------------------------------------
export const FETCH_PROVIDED_DATA_LEGAL_REQUEST = '@providedDataLegal/FETCH_DATA_REQUEST';
export const FETCH_PROVIDED_DATA_LEGAL_SUCCESS = '@providedDataLegal/FETCH_DATA_SUCCESS';
export const FETCH_PROVIDED_DATA_LEGAL_ERROR = '@providedDataLegal/FETCH_DATA_ERROR';
export const ANNUL_PROVIDED_DATA_LEGAL_REQUEST = '@providedDataLegal/ANNUL_DATA_REQUEST';

// COMMON GETBACK TYPES --------------------------------------------
export const REMOVE_PROVIDED_DATA_REQUEST = '@providedData/REMOVE_DATA_REQUEST';
export const REMOVE_PROVIDED_DATA_SUCCESS = '@providedData/REMOVE_DATA_SUCCESS';
export const REMOVE_PROVIDED_DATA_ERROR = '@providedData/REMOVE_DATA_ERROR';
export const RESET_PROVIDED_DATA_REMOVE_STATE = '@providedData/RESET_PROVIDED_DATA_REMOVE_STATE';

export const ANNUL_PROVIDED_DATA_REQUEST = '@providedData/ANNUL_DATA_REQUEST';
export const ANNUL_PROVIDED_DATA_SUCCESS = '@providedData/ANNUL_DATA_SUCCESS';
export const ANNUL_PROVIDED_DATA_ERROR = '@providedData/ANNUL_DATA_ERROR';
export const RESET_PROVIDED_DATA_ANNUL_STATE = '@providedData/RESET_PROVIDED_DATA_ANNUL_STATE';
