import {
  ColumnDisplayStatus,
  ColumnHeader,
  TableCustomFilter,
  TableGenericFilterOption
} from '../../../utils/tableTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { PaymentDataInst } from '../../../store/payments/paymentsTypes';
import { getTableExportDefinition } from './paymentTableStateFunctions';
import { columnNames } from '../TableColumns';

export const initialColumnDisplay = {
  virsName: true,
  decisionDate: true,
  paymentDocuments: true,
  paymentOutlets: true,
  paymentAmount: true,
  validFrom: true,
  validTo: true
};

export const initialPaymentDataTableState: PaymentDataTableState = {
  columnsDisplayStatus: initialColumnDisplay,
  customFilterOn: false,
  showNewRecord: false,
  companyCode: null,
  tableExportDefinition: getTableExportDefinition(columnNames, initialColumnDisplay)
};

export type PaymentDataHeaderField =
  | 'virsName'
  | 'decisionDate'
  | 'paymentDocuments'
  | 'paymentOutlets'
  | 'paymentAmount'
  | 'validFrom'
  | 'validTo';

export type PaymentDataColumnsDisplayStatus = ColumnDisplayStatus<PaymentDataHeaderField>;

export type PaymentDataCustomFilter = TableCustomFilter<PaymentDataHeaderField>;

export type PaymentDataCustomFilterOptions = TableGenericFilterOption<PaymentDataHeaderField>;

export type PaymentDataColumnHeader = ColumnHeader<PaymentDataHeaderField>;

export interface PaymentDataTableState {
  columnsDisplayStatus: PaymentDataColumnsDisplayStatus;
  customFilterOn: boolean;
  showNewRecord: boolean;
  companyCode: string | null;
  tableExportDefinition: TableExportDefinition<PaymentDataInst>;
}

export type PaymentDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: PaymentDataHeaderField;
    }
  | { type: 'CREATE_RECORD_CLICKED'; companyCode: string | null }
  | { type: 'CLOSE_CREATING_RECORD_CLICKED' }
  | { type: 'RESET_COMPANY_CODE' };
