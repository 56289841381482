import React, { ReactNode } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../../style/virsisTheme';

const useStyles = makeStyles({
  // šituos stilius po refaktorinimo reikia ištrinti
  linkLikeRemoveButton: {
    color: 'red',
    textTransform: 'none'
  },
  // iki čia
  linkLikeButton: {
    padding: '8px',
    boxSizing: 'border-box',
    borderColor: '#E5E5E5',
    backgroundColor: theme.palette.secondary.light,
    color: '#637082',
    minWidth: '120px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.main
    },
    '&:active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#DBEDFC',
      color: theme.palette.primary.main
    }
  }
});

interface ButtonProps {
  id?: string;
  label: string;
  onButtonClicked: (event: React.MouseEvent) => void;
  removeButton?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  style?: {
    label?: React.CSSProperties;
  };
}

export const RowActionButtonGeneric: React.FC<ButtonProps> = ({
  id,
  label,
  onButtonClicked,
  removeButton,
  icon,
  disabled,
  style
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      id={id}
      // po refaktorinimo  removeButton ? classes.linkLikeRemoveButton reikėtų ištrinti
      className={removeButton ? classes.linkLikeRemoveButton : classes.linkLikeButton}
      onClick={onButtonClicked}
      startIcon={icon}
      disabled={disabled}
    >
      <Typography variant="h5" style={style?.label}>
        {label}
      </Typography>
    </Button>
  );
};
