import { get, getBlob, post, getWithQuery } from '../../utils/axiosApi';
import { ContactRequest, RegisterAccountTypeRecord, VirsSearchQuery } from './dataTypes';

export const getData = () => get('/data');
export const getCredentials = (enc?: string) => post(`/login?enc=${enc}`);
export const getUserData = () => get('/login/user');
export const logout = () => get('/logout');
export const fetchAgreementDateByVersion = () => get('/agreements/user-accepted-version');
export const acceptAgreementVersion = () => post('/agreements/accept');
export const fetchContactEmailByPersonId = (personId: number) =>
  get(`/contacts/${personId}/virs-email`);
export const fetchJarEmailByPersonId = (personId: number) => get(`/contacts/${personId}/jar-email`);
export const fetchVirsData = (personId: number) => get(`/virs/legals/${personId}`);
export const fetchVirsDataByVirsId = (virsId: number) => get(`/virs/legals/byVirsId/${virsId}`);
export const saveVirsisContactEmail = (contact: ContactRequest) =>
  post(`/contacts/${contact.personId}`, { email: contact.email });
export const downloadAgreementFile = () => getBlob('/files/agreement');
export const submitVirsData = (personId: number) => post(`/virs/legals/${personId}`);
export const getFindVirs = (virsQuery: VirsSearchQuery) => {
  const { personCode, personFullName, enterpriseTypeId, outletName, onlySigned } = virsQuery;

  return getWithQuery('/virs/legals/virs-lookup', {
    personCode,
    personFullName,
    enterpriseTypeId,
    outletName,
    tikPasirasyti: onlySigned ? 'T' : 'N'
  });
};

export const getFindActiveVirs = (virsQuery: VirsSearchQuery) => {
  const { personCode, personFullName, enterpriseTypeId, outletName, onlySigned } = virsQuery;

  return getWithQuery('/virs/legals/virs-lookup/active', {
    personCode,
    personFullName,
    enterpriseTypeId,
    outletName,
    tikPasirasyti: onlySigned ? 'T' : 'N'
  });
};

export const postRegisterAccountType = (accountTypeRegister: RegisterAccountTypeRecord) => {
  return post('/audits/account-type', accountTypeRegister);
};

export const getVirsDataEditor = (virsId: number) => {
  return get(`/virs/legals/editor/${virsId}`);
};

export const synchronizeVirs = (virsId: number) => {
  return post(`/integration/virs/${virsId}`);
};
