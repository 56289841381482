import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ErrorMessage } from 'formik';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: -8,
    marginBottom: 10,
    maxWidth: 240
  }
}));

interface Props {
  name: string;
}

export const CustomErrorField: React.FC<Props> = ({ name }) => {
  const classes = useStyles();

  return (
    <ErrorMessage name={name}>
      {(message) => (
        <Typography variant="h6" className={classes.errorMessage}>
          {message}
        </Typography>
      )}
    </ErrorMessage>
  );
};
