import { InstitutionDataReport } from '../../../store/InstitutionDataReports/institutionDataReportsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import {
  checkIfDateIsWithinPeriod,
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { Period } from '../../../utils/tableTypes';
import {
  InstitutionDataReportCustomFilter,
  InstitutionDataReportHeaderField,
  institutionDataReportTableColumns,
  InstitutionDataReportTableState
} from './initialTableStateAndTypes';

export function updateInstitutionDataReportCustomFilter(
  filter: InstitutionDataReportCustomFilter,
  filterBy: InstitutionDataReportHeaderField,
  value: string | null
): InstitutionDataReportCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export const getPeriodFilterInstitutionDataReportData = (
  data: InstitutionDataReport[],
  periodFilter: Period
): InstitutionDataReport[] => {
  switch (periodFilter.id) {
    case 'PASKUTINIS_MENUO':
      return data.filter((item) => {
        return checkIfDateIsWithinPeriod(item.reportedDateTime, 30);
      });
    case 'PASKUTINIS_KETVIRTIS':
      return data.filter((item) => checkIfDateIsWithinPeriod(item.reportedDateTime, 90));
    case 'PASKUTINIS_PUSMETIS':
      return data.filter((item) => checkIfDateIsWithinPeriod(item.reportedDateTime, 180));
    case 'PASKUTINIAI_METAI':
      return data.filter((item) => checkIfDateIsWithinPeriod(item.reportedDateTime, 365));
    case 'VISI':
      return data;
    default:
      return data;
  }
};

export function filterAndSortTableData(
  tableState: InstitutionDataReportTableState,
  data: InstitutionDataReport[]
): InstitutionDataReport[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;

  const filters: Filter<InstitutionDataReport>[] = [
    getStringMappedColumnFilter(columnsFilters.reportType, ({ reportType }) => [reportType]),
    getStringMappedColumnFilter(columnsFilters.reportedDateTime, ({ reportedDateTime }) => [
      reportedDateTime
    ]),
    getStringMappedColumnFilter(columnsFilters.institutionCode, ({ institutionCode }) => [
      institutionCode
    ]),
    getStringMappedColumnFilter(columnsFilters.institutionName, ({ institutionName }) => [
      institutionName
    ]),
    getStringMappedColumnFilter(columnsFilters.institutionEmail, ({ institutionEmail }) => [
      institutionEmail
    ]),
    getStringMappedColumnFilter(columnsFilters.virsCode, ({ virsCode }) => [virsCode]),
    getStringMappedColumnFilter(columnsFilters.virsName, ({ virsName }) => [virsName]),
    getStringMappedColumnFilter(columnsFilters.reportedBy, ({ reportedBy }) => [reportedBy]),
    getStringMappedColumnFilter(columnsFilters.reporterEmail, ({ reporterEmail }) => [
      reporterEmail
    ])
  ];

  const filtered = filterData(data, filters);

  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);

  return sorted;
}

export function getTableExportDefinition(
  tableState: InstitutionDataReportTableState
): TableExportDefinition<InstitutionDataReport> {
  const { stringValueColumn } = getColumnBuilder<
    InstitutionDataReport,
    InstitutionDataReportHeaderField
  >(institutionDataReportTableColumns, tableState.columnsDisplayStatus);

  return {
    title: 'Pranešimai apie netikslius duomenis',
    columnGroups: [
      {
        columns: [
          stringValueColumn('reportType', { width: 30 }),
          stringValueColumn('reportedDateTime', { width: 20 }),
          stringValueColumn('institutionCode', { width: 15 }),
          stringValueColumn('institutionName', { width: 45 }),
          stringValueColumn('institutionEmail', { width: 30 }),
          stringValueColumn('virsCode', { width: 15 }),
          stringValueColumn('virsName', { width: 45 }),
          stringValueColumn('reportedBy', { width: 30 }),
          stringValueColumn('reporterEmail', { width: 30 })
        ]
      }
    ]
  };
}
