import { Switch } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import PrivateRoute from '../../components/Router/PrivateRoute';
import { ApplicationState } from '../../store';
import HomePage from '../HomePage';
import LegalHeader from '../header/LegalHeader';
import LegalPage from './LegalPage';
import { LegalFundsReceivedPage } from './LegalFundsReceivedPage';
import AppFooter from '../../components/Footer/AppFooter';
import LegalProvidedDataPage from './LegalProvidedDataPage';
import UserMessagesPage from '../virs/UserMessagesPage';
import { HelpPage } from '../../containers/HelpPage/HelpPage';

const LegalRoute: React.FC = () => {
  const { authoriseError, logoutSuccess } = useSelector((state: ApplicationState) => state.virsis);

  if (authoriseError || logoutSuccess) {
    return <HomePage />;
  }

  return (
    <>
      <LegalHeader />
      <div style={{ flex: '1 0 auto' }}>
        <Switch>
          <PrivateRoute exact path="/">
            <LegalPage />
          </PrivateRoute>
          <PrivateRoute exact path="/duomenu-teikimas">
            <LegalFundsReceivedPage />
          </PrivateRoute>
          <PrivateRoute exact path="/duomenu-teikimas/:documentStatusId">
            <LegalFundsReceivedPage />
          </PrivateRoute>
          <PrivateRoute exact path="/duomenu-istorija">
            <LegalProvidedDataPage />
          </PrivateRoute>
          <PrivateRoute exact path="/pagalba">
            <HelpPage />
          </PrivateRoute>
          <PrivateRoute exact path="/pranesimai">
            <UserMessagesPage />
          </PrivateRoute>
        </Switch>
      </div>
      <AppFooter />
    </>
  );
};

export default LegalRoute;
