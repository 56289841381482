import * as React from 'react';
import { Formik, Form } from 'formik';
import { Button, LinearProgress, Typography, FormGroup } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';
import { useDialogFormStyles } from '../../../../components/FormikFields/FieldStyles';
import { FormikFormTextField } from '../../../../components/FormikFields/FormikFormTextField';

interface Props {
  searchLibis: (title?: string, internationalNumber?: string) => void;
  isSearching: boolean;
}
interface Values {
  title?: string;
  internationalNumber?: string;
}

export const SearchLibisDialogForm: React.FC<Props> = ({ searchLibis, isSearching }) => {
  const initValues: Partial<Values> = {
    title: '',
    internationalNumber: ''
  };

  const validate = (field: string) =>
    Yup.mixed().when(field, (value: string, schema: any) => {
      return value
        ? schema.nullable()
        : schema.required('Pavadinimas arba tarptautinis kodas privalomas');
    });

  const validationSchema = Yup.object().shape(
    {
      title: validate('internationalNumber'),
      internationalNumber: validate('title')
    },
    [['title', 'internationalNumber']]
  );
  const classes = useDialogFormStyles();

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        searchLibis(values.title, values.internationalNumber);
        setSubmitting(false);
      }}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Form>
            <FormGroup style={{ maxWidth: 'fit-content' }}>
              <FormikFormTextField
                styledClasses={classes}
                label="Pavadinimas"
                formikKey="title"
                values={values.title}
                error={errors.title}
                setFieldValue={setFieldValue}
                required={!values.internationalNumber}
              />
              <FormikFormTextField
                styledClasses={classes}
                label="ISBN / ISSN kodas"
                formikKey="internationalNumber"
                values={values.internationalNumber}
                error={errors.internationalNumber}
                setFieldValue={setFieldValue}
                required={!values.title}
              />
              <Box margin={1}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isValidating || isSearching}
                  onClick={submitForm}
                  className={classes.button}
                >
                  <Typography color="inherit">Ieškoti</Typography>
                </Button>
              </Box>
              {isSearching && <LinearProgress />}
            </FormGroup>
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
