import { useDispatch, useSelector } from 'react-redux';
import { AlertBox } from '../../../components/Alert/AlertBox';
import { closeUnsignedDataAlert } from '../../../store/virsis/actions';
import { ApplicationState } from '../../../store';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';

export const UpdatedDataAlert = () => {
  const reduxDispatch = useDispatch();

  const {
    shareholdersData: { shareholdersData }
  } = useSelector((state: ApplicationState) => state);

  return (
    <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
      {shareholdersData?.isUpdatedFromJADIS ? (
        <AlertBox
          notClosable
          handleClose={() => reduxDispatch(closeUnsignedDataAlert())}
          text={`Dalyvių duomenys atnaujinti/pakeisti dėl pasikeitimų JADIS. Prašome papildyti pateiktus duomenis ir juos pasirašyti.`}
        />
      ) : (
        <></>
      )}
    </AllowedTo>
  );
};
