import { FormikProps } from 'formik';
import { isNumber, isString } from 'lodash';
import React from 'react';
import { FormikFieldStyleProps } from '../FieldStyles';
import { FormikFormNumberInput } from '../FormikFormNumberInput';
import { useDialogFormStyles } from './DialogFormStyles';

interface Props<TData> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label: string;
  field: keyof TData;
  disabled?: boolean;
  clearable?: boolean;
  integer?: boolean;
}

export const GenericNumberField = <TData,>({
  field,
  label,
  formikProps,
  disabled,
  style,
  integer,
  clearable
}: Props<TData>) => {
  const classes = useDialogFormStyles();
  const value = formikProps.values[field];
  const error = formikProps.errors[field];

  const typedError = isString(error) ? error : undefined;
  const typedValue = isNumber(value) ? value : null;

  return (
    <FormikFormNumberInput
      isRequired={false}
      isInteger={integer || false}
      styledClasses={classes}
      formikKey={`${field}`}
      values={typedValue}
      error={typedError}
      label={label}
      clearValue={(clearable && (() => formikProps.setFieldValue(`${field}`, null))) || undefined}
      style={style}
      disabled={disabled}
    />
  );
};

GenericNumberField.defaultProps = {
  disabled: false,
  clearable: true,
  integer: false
};
