import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { TriangleButton } from './TableButtons/TriangleButton';
import { ShareholderFullName } from './ShareholderFullName';
import {
  ClearShareholderPercentageRecord,
  ErrorProps,
  ShareholderPerson,
  ShareholdersProps
} from '../../store/shareholders/shareholdersTypes';
import { ExpandDetailsButton } from '../TableExpandDetailsButton/ExpandDetailsButton';
import { ShareholderNameCellButtons } from './ShareholderNameCellButtons';

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  shareholderPersons: ShareholderPerson[];
  handleShowChildShareholder: () => void;
  showChildShareholder: boolean;
  showRowExtension: boolean;
  setShowRowExtention: (arg: boolean) => void;
  handleAddShareholderButtonClicked?: () => void;
  mayShowErrorIcons: boolean;
  criticalErrorMessages: ErrorProps[];
  nonCriticalErrorMessages: ErrorProps[];
  infoNotifications: ErrorProps[];
  showAddShareholderButton?: boolean;
  mayShowOptionsButton?: boolean;
  marginLeft: number;
  showExtendedDetailsButton: boolean;
  tempShareholder?: ClearShareholderPercentageRecord;
  selectedDate?: string;
}

const useStyles = makeStyles({
  shareholdersName: {
    display: 'block',
    position: 'relative',
    textAlign: 'left'
  },
  flexStartContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

export const ShareholderFullNameWithButtons: React.FC<Props> = ({
  index,
  shareholder,
  shareholderPersons,
  showChildShareholder,
  handleShowChildShareholder,
  showRowExtension,
  setShowRowExtention,
  handleAddShareholderButtonClicked,
  mayShowErrorIcons,
  criticalErrorMessages,
  nonCriticalErrorMessages,
  infoNotifications,
  showAddShareholderButton,
  mayShowOptionsButton,
  marginLeft,
  showExtendedDetailsButton,
  tempShareholder,
  selectedDate
}) => {
  const classes = useStyles();

  const isItGroupShareholder = !!shareholder.shareholderGroupId;

  const showTriangleButton =
    isItGroupShareholder ||
    shareholder.shareholderPersons.some((person) => person.shareholders.length);

  return (
    <div className="name-row">
      <div className={classes.flexStartContainer}>
        {showExtendedDetailsButton && (
          <ExpandDetailsButton
            showExtension={showRowExtension}
            onClick={() => setShowRowExtention(!showRowExtension)}
          />
        )}
        {!showExtendedDetailsButton && <div className="expand-details-button-placeholder" />}
        <div className="flex-container">
          <TriangleButton
            showTriangleButton={showTriangleButton}
            expandChild={showChildShareholder}
            onClicked={handleShowChildShareholder}
            marginLeft={marginLeft}
          />
          <Typography variant="h5" className={classes.shareholdersName}>
            {shareholderPersons.map((person) => (
              <ShareholderFullName
                key={`${person.personId}${person.personLegalCode}`}
                person={person}
                isItGroupShareholder={isItGroupShareholder}
              />
            ))}
          </Typography>
        </div>
      </div>
      <ShareholderNameCellButtons
        index={index}
        shareholder={shareholder}
        mayShowErrorIcons={mayShowErrorIcons}
        showAddShareholderButton={showAddShareholderButton}
        mayShowOptionsButton={mayShowOptionsButton}
        criticalErrorMessages={criticalErrorMessages}
        nonCriticalErrorMessages={nonCriticalErrorMessages}
        infoNotifications={infoNotifications}
        handleAddShareholderButtonClicked={handleAddShareholderButtonClicked}
        tempShareholder={tempShareholder}
        selectedDate={selectedDate}
        isItGroupShareholder={isItGroupShareholder}
      />
    </div>
  );
};
