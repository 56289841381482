import { get, post, getBlob, put, axiosDelete, getWithQuery } from '../../utils/axiosApi';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { EditionChecksPageRequest } from '../editionChecksTable/editionChecksTableTypes';

export const getEditionCheckData = () => get('/edition-check/inst');
export const getEditionCheckDataPage = (query: EditionChecksPageRequest) =>
  getWithQuery('/edition-check/inst', query);

export const getEditionCheckDataByVirs = (virId: number) => get(`/edition-check/inst/${virId}`);

export const getVirsEditionChecks = (virsId: number) =>
  get(`/virs/${virsId}/institutions-data/edition-checks/`);

export const postCreateEditionCheck = (newEditionCheck: FormData) =>
  post('/edition-check', newEditionCheck);

export const putUpdateEditionCheck = (newEditionCheck: FormData, id: number | undefined) =>
  put(`/edition-check/${id}`, newEditionCheck);

export const deleteRemoveEditionCheck = (editionCheckId: number) =>
  axiosDelete(`/edition-check/${editionCheckId}`);

export const getEditionCheckDataVirsisDocument = (docId: number) =>
  getBlob(`/payment-data/attachments/${docId}`);

export const annulEditionCheckRecord = (documentStatusId: number, annulRecord: AnnulRecord) =>
  put(`/provided-data/inst/${documentStatusId}`, annulRecord);

export const getPublicationOutlets = (virId: number) =>
  get(`/edition-check/publication-outlets/${virId}`);

export const getEditionCheckByDockIdRequest = (docId: number) => get(`/edition-check/${docId}`);
