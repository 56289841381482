import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import {
  PaymentDataHeaderField,
  PaymentDataTableState
} from './tableState/tableInitialStateAndTypes';
import { usePaymentDataTableState, usePaymentDataTableDispatch } from './Context';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';

const PaymentDataTableHead: React.FC = () => {
  const { state: tableState } = usePaymentDataTableState();
  const { dispatch } = usePaymentDataTableDispatch();

  const handleSetSortByColumn = (column: PaymentDataHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    PaymentDataTableState,
    typeof handleSetSortByColumn,
    PaymentDataHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.paymentDocuments) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.decisionDate,
              tableState.columnsDisplayStatus.paymentDocuments
            ])}
          >
            <Typography variant="h4">Sprendimo</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.paymentOutlets && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Susijusios VIP"
            columnName="paymentOutlets"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.paymentAmount && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Įmokos dydis (BSI)"
            columnName="paymentAmount"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.paymentDeterminedBy && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Įmoką nustatė"
            columnName="paymentDeterminedBy"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        <TableCell rowSpan={2} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Data"
            columnName="decisionDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.paymentDocuments && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nr."
            columnName="paymentDocuments"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default PaymentDataTableHead;
