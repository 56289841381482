import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { whiteColor } from '../../containers/ShareholdersTable/constants';
import SubmitVirsData from '../../containers/SubmitVirsData';
import { ApplicationState } from '../../store';
import { VirsDataWrapper } from './VirsDataWrapper';
import { DocumentValidationError } from '../../store/document/documentTypes';
import { ErrorType } from '../../store/virsis/dataTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: whiteColor,
    borderRadius: 10,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('xs')]: {
    overflow: 'auto'
    }
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 40,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 7,
      paddingRight: 0,
    }
  }
}));

export const VirsData = () => {
  const classes = useStyles();
  const {
    virsis: { virsData },
    documentData: { documentValidation }
  } = useSelector((state: ApplicationState) => state);

  if (!virsData) {
    return null;
  }

  const noDataError = {
    action: '',
    attributes: '',
    code: ErrorType.INFO,
    group: '',
    sivId: '',
    priority: 'E',
    text: 'Nėra nurodytų duomenų'
  };

  const filterErrors = (xs: string[]): DocumentValidationError[] =>
    documentValidation?.documentErrors?.filter((error) =>
      xs.some((x) => error?.attributes?.includes(x))
    ) || [];

  const enterpriseErrors =
    virsData?.documentStatus === null ? [noDataError] : filterErrors(['vvr']);
  const outletErrors = virsData?.documentStatus === null ? [noDataError] : filterErrors(['vip']);
  const shareholderErrors =
    virsData?.documentStatus === null ? [noDataError] : filterErrors(['dal', 'das']);

  return (
    <div className={classes.container}>
      <SubmitVirsData />
      <div className={classes.paper}>
        <VirsDataWrapper
          errors={enterpriseErrors}
          title="Veiklos rūšys"
          data={[
            {
              name: 'Vykdomos veiklos rūšys',
              count: virsData.enterpriseCount
            }
          ]}
          url="/duomenu-perziura-ir-teikimas/veiklos-rusys"
        />
        <VirsDataWrapper
          errors={outletErrors}
          title="Visuomenės informavimo priemonės (VIP)"
          data={[{ name: 'Valdomos VIP', count: virsData.outletCount }]}
          url="/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones"
        />
        <VirsDataWrapper
          errors={shareholderErrors}
          title="Dalyviai"
          data={[
            { name: 'Fiziniai asmenys', count: virsData.naturalPersonCount },
            { name: 'Juridiniai asmenys', count: virsData.legalPersonCount }
          ]}
          url="/duomenu-perziura-ir-teikimas/dalyviai"
        />
      </div>
    </div>
  );
};
