import moment from 'moment';
import {
  intialOutletFullEditRowState,
  OutletFullyEditRowState,
  OutletFullEditRowAction
} from './outletRowStateAndTypes';
import {
  findOutletTypeOrNull,
  findOutletGroupOrNull,
  validatedEditOutletState
} from './outletRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_OUTLET_ESTABLISHED_DATE,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  updateMaxDates,
  updateMinDates,
  validateSingleChoice
} from '../../../../utils/InputValueFunctions';
import { VirsisYesNoString } from '../../../../utils/tableTypes';

export const fullyEditOutletReducer = (
  state: OutletFullyEditRowState,
  action: OutletFullEditRowAction
): OutletFullyEditRowState => {
  switch (action.type) {
    case 'EDIT_BUTTON_CLICKED':
      return {
        ...state,
        editingOn: true,
        name: {
          ...state.name,
          value: action.outlet.outletName
        },
        outletType: {
          ...state.outletType,
          validated: action.enterprise.outletTypes.length === 1,
          value:
            action.enterprise.outletTypes.length === 1
              ? action.enterprise.outletTypes[0]
              : findOutletTypeOrNull(action.enterprise.outletTypes, action.outlet.outletTypeId),
          options: action.enterprise.outletTypes
        },
        outletGroup: findOutletGroupOrNull(action.outletGroups, action.outlet.outletGroupId),
        established: {
          ...state.established,
          value: moment(action.outlet.establishedDate),
          minDates: [MINIMAL_OUTLET_ESTABLISHED_DATE],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date: action.deregistrationDate !== null ? moment(action.deregistrationDate) : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo iš JAR datą'
            },
            {
              id: 'enterpriseValidTo',
              date: action.enterprise.validTo ? moment(action.enterprise.validTo) : null,
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            {
              id: 'controlledFrom',
              date: moment(action.outlet.controlledFrom),
              text: 'Data negali būti vėlesnė už valdymo pradžios datą'
            },
            {
              id: 'controlledTo',
              date: action.outlet.controlledTo ? moment(action.outlet.controlledTo || null) : null,
              text: 'Data negali būti vėlesnė už valdymo pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        controlledFrom: {
          ...state.controlledFrom,
          value: moment(action.outlet.controlledFrom),
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.registrationDate),
              text: 'Data negali būti ankstesnė už įsiregistravimo datą JAR'
            },
            {
              id: 'enterpriseValidFrom',
              date: moment(action.enterprise.validFrom),
              text: 'Data negali būti ankstesnė už veiklos pradžios datą'
            },
            {
              id: 'established',
              date: moment(action.outlet.establishedDate),
              text: 'Data negali būti ankstesnė už VIP įsteigimo datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date: action.deregistrationDate !== null ? moment(action.deregistrationDate) : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo iš JAR datą'
            },
            {
              id: 'enterpriseValidTo',
              date: action.enterprise.validTo ? moment(action.enterprise.validTo) : null,
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            {
              id: 'controlledTo',
              date: action.outlet.controlledTo ? moment(action.outlet.controlledTo || null) : null,
              text: 'Data negali būti vėlesnė už valdymo pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        controlledTo: {
          ...state.controlledTo,
          value: action.outlet.controlledTo ? moment(action.outlet.controlledTo) : null,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.registrationDate),
              text: 'Data negali būti ankstesnė už įsiregistravimo datą JAR'
            },
            {
              id: 'enterpriseValidFrom',
              date: moment(action.enterprise.validFrom),
              text: 'Data negali būti ankstesnė už veiklos pradžios datą'
            },
            {
              id: 'established',
              date: moment(action.outlet.establishedDate),
              text: 'Data negali būti ankstesnė už VIP įsteigimo datą'
            },
            {
              id: 'controlledFrom',
              date: moment(action.outlet.controlledFrom),
              text: 'Data negali būti vėlesnė už valdymo pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date: action.deregistrationDate !== null ? moment(action.deregistrationDate) : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo iš JAR datą'
            },
            {
              id: 'enterpriseValidTo',
              date: action.enterprise.validTo ? moment(action.enterprise.validTo) : null,
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        internationalNumber: {
          ...state.internationalNumber,
          value: action.outlet.internationalNumber
        },
        ISBN: action.outlet.isbn,
        ISSN: action.outlet.issn,
        outletGroups: action.outletGroups
      };
    case 'CANCEL_EDITING_BUTTON_CLICKED':
      return intialOutletFullEditRowState;
    case 'LIBIS_SEARCH_OPENED':
      return {
        ...state,
        showLibisSearch: true
      };
    case 'LIBIS_SEARCH_CLOSED':
      return {
        ...state,
        showLibisSearch: false
      };
    case 'LIBIS_OUTLET_CLICKED':
      return {
        ...state,
        selectedLibisOutlet:
          state.selectedLibisOutlet && state.selectedLibisOutlet.id === action.outlet.id
            ? undefined
            : action.outlet
      };
    case 'POPULATE_RECORD_WITH_LIBIS_DATA_CLICKED':
      return {
        ...state,
        showLibisSearch: false,
        name: {
          ...state.name,
          value: state.selectedLibisOutlet ? state.selectedLibisOutlet.title : null
        },
        ISBN: state.selectedLibisOutlet ? state.selectedLibisOutlet.isbn : null,
        ISSN: state.selectedLibisOutlet ? state.selectedLibisOutlet.issn : null,
        internationalNumber: {
          ...state.internationalNumber,
          value: state.selectedLibisOutlet
            ? state.selectedLibisOutlet.isbn || state.selectedLibisOutlet.issn
            : null
        }
      };
    case 'OUTLET_NAME_CHANGED':
      return {
        ...state,
        name: {
          ...state.name,
          value: action.name,
          validated: false,
          helperText: '',
          error: false
        }
      };
    case 'OUTLET_TYPE_CHANGED':
      const usesLibis = action.outletType?.usesLibis === VirsisYesNoString.YES;
      let defaultValues = {};
      if (state.usesLibis !== usesLibis) {
        defaultValues = {
          name: {
            value: null,
            id: 'outletName',
            error: false,
            helperText: '',
            placeholder: 'VIP pavadinimas',
            validated: false
          },
          internationalNumber: {
            value: null,
            id: 'internationalNumber',
            validated: false,
            helperText: '',
            error: false
          },
          ISBN: null,
          ISSN: null
        };
      }
      return {
        ...state,
        outletType: validateSingleChoice(
          { ...state.outletType, value: action.outletType },
          'Būtina pasirinkti iš sąrašo'
        ),
        outletGroup: action.outletType
          ? findOutletGroupOrNull(state.outletGroups, action.outletType?.outletGroupId)
          : null,
        usesLibis,
        ...defaultValues
      };
    case 'OUTLET_ESTABLISHED_DATE_CHANGED':
      return {
        ...state,
        established: updateDateValue(action.established, state.established),
        controlledFrom: updateMinDates(action.established, 'established', state.controlledFrom),
        controlledTo: updateMinDates(action.established, 'established', state.controlledTo)
      };
    case 'OUTLET_CONTROLLED_FROM_CHANGED':
      return {
        ...state,
        controlledFrom: updateDateValue(action.controlledFrom, state.controlledFrom),
        established: updateMaxDates(action.controlledFrom, 'controlledFrom', state.established),
        controlledTo: updateMinDates(action.controlledFrom, 'controlledFrom', state.controlledTo)
      };
    case 'OUTLET_CONTROLLED_TO_CHANGED':
      return {
        ...state,
        controlledTo: updateDateValue(action.controlledTo, state.controlledTo),
        controlledFrom: updateMaxDates(action.controlledTo, 'controlledTo', state.controlledFrom),
        established: updateMaxDates(action.controlledTo, 'controlledTo', state.established)
      };
    case 'OUTLET_INTERNATIONAL_NUMBER_CHANGED':
      return {
        ...state,
        internationalNumber: {
          ...state.internationalNumber,
          value: action.value,
          validated: false,
          helperText: '',
          error: false
        },
        ISBN: null,
        ISSN: null
      };
    case 'UPDATE_RECORD_CLICKED':
      return validatedEditOutletState(state);
    case 'CANCEL_UPDATING_RECORD_CLICKED':
      return {
        ...state,
        updateConfirmationOn: false
      };
    case 'UPDATE_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateConfirmationOn: false
      };
    case 'UPDATE_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeConfirmationOn: true
      };
    case 'CANCEL_REMOVING_RECORD_CLICKED':
      return {
        ...state,
        removeConfirmationOn: false
      };
    case 'REMOVE_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeConfirmationOn: false
      };
    case 'REMOVE_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeConfirmationOn: false
      };
    default:
      return state;
  }
};
