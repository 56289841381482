import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import React, { useReducer } from 'react';
import { ToggleButton } from '@material-ui/lab';
import { VirsSearchData } from '../../store/virsis/dataTypes';
import { pageTableData } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TablePagination } from '../TablePagination/TablePagination';
import {
  FindVirsResultsTableColumnHeader,
  findVirsResultsTableReducer,
  initialFindVirsResultsTableState,
  VirsSearchDataField
} from '../TableDialogFindVirs/findVirsDialogResultsReducerAndTypes';
import { getSortedVirsSearchData } from '../TableDialogFindVirs/FindVirsDialogResults';
import { useSearchStyles } from '../../style/searchStyles';

const columns: FindVirsResultsTableColumnHeader[] = [
  {
    id: 'personCode',
    label: 'Įmonės kodas'
  },
  {
    id: 'personFullName',
    label: 'Pavadinimas'
  },
  {
    id: 'enterpriseTypes',
    label: 'VIRS veiklos rūšis'
  },
  {
    id: 'outlets',
    label: 'VIP pavadinimas'
  }
];

interface Props {
  virsSearchResults: VirsSearchData[];
  selectedVirs?: VirsSearchData;
  handleClickSelect: (virs: VirsSearchData) => void;
}

export const AuditVirsSearchResultsTable: React.FC<Props> = ({
  virsSearchResults,
  selectedVirs,
  handleClickSelect
}) => {
  const classes = useSearchStyles();
  const [tableState, tableDispatch] = useReducer(
    findVirsResultsTableReducer,
    initialFindVirsResultsTableState
  );

  const createSortHandler = (column: VirsSearchDataField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  function setPage(page: number): void {
    tableDispatch({ type: 'PAGE_SET', page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    tableDispatch({ type: 'ROWS_PER_PAGE_SET', rowsPerPage });
  }

  const sortedData = getSortedVirsSearchData(
    virsSearchResults,
    tableState.order,
    tableState.sortBy
  );

  const { page: pagedData, pagesCount } = pageTableData(tableState, sortedData);

  return (
    <TableContainer
      className={classes.tableContainer}
      style={{
        overflowX: 'auto'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((header) => (
              <TableCell
                key={header.id}
                sortDirection={tableState.sortBy === header.id ? tableState.order : false}
                align="left"
                variant="head"
                className="TableCellSortable"
                onClick={() => {
                  createSortHandler(header.id);
                }}
              >
                <TableSortLabel
                  active
                  direction={tableState.sortBy === header.id ? tableState.order : 'asc'}
                  IconComponent={tableState.sortBy === header.id ? ActiveSortIcon : SortIcon}
                >
                  <Typography variant="h4">{header.label}</Typography>
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>
              <Typography variant="h4">Veiksmai</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedData.map((record) => (
            <TableRow key={record.personId}>
              <TableCell>{record.personCode}</TableCell>
              <TableCell>{record.personFullName}</TableCell>
              <TableCell align="left">
                {record.enterpriseTypes.map((type) => (
                  <div key={type.enterpriseTypeId}>{type.enterpriseTypeName}</div>
                ))}
              </TableCell>
              <TableCell align="left">
                {record.outlets.map((outlet) => (
                  <div key={outlet.outletId}>{outlet.outletName}</div>
                ))}
              </TableCell>
              <TableCell>
                <ToggleButton
                  classes={{
                    selected: classes.selected,
                    root: classes.root
                  }}
                  selected={selectedVirs ? record.personId === selectedVirs.personId : false}
                  value="select-person"
                  onClick={() => {
                    handleClickSelect(record);
                  }}
                >
                  {selectedVirs && record.personId === selectedVirs.personId
                    ? 'Pasirinktas'
                    : 'Pasirinkti'}
                </ToggleButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        recordsCount={virsSearchResults.length}
        pagesCount={pagesCount}
        rowsPerPage={tableState.rowsPerPage}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        page={tableState.page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        disabled={virsSearchResults.length === 0}
      />
    </TableContainer>
  );
};
