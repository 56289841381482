import React from 'react';
import {
  GroupShareholderType,
  LegalPerson,
  ShareholderPerson
} from '../../store/shareholders/shareholdersTypes';
import { mainDarkColor, naturalPersonColor } from '../../containers/ShareholdersTable/constants';

interface Props {
  person: ShareholderPerson;
  isItGroupShareholder: boolean;
}

export const ShareholderFullName: React.FC<Props> = ({ person, isItGroupShareholder }) => {
  const isLegalPerson =
    person.personType === LegalPerson.LJA || person.personType === LegalPerson.UJA;

  return (
    <>
      <span
        style={{
          color: isLegalPerson ? mainDarkColor : naturalPersonColor
        }}
      >
        {`${person.personName}  `}
        {person.personLegalFormAbbreviation && `(${person.personLegalFormAbbreviation})`}
      </span>
      {isLegalPerson && (
        <span className="sh-additional-info">
          {person.personLegalCode && `${person.personLegalCode} `}
        </span>
      )}

      {isItGroupShareholder && (
        <span className="sh-additional-info">
          {person.isAuthorized
            ? GroupShareholderType.AUTHORIZED
            : GroupShareholderType.UNAUTHORIZED}
        </span>
      )}
    </>
  );
};
