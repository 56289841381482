import { FormikProps } from 'formik';
import React from 'react';
import { FormikFieldStyleProps } from '../FieldStyles';
import { FormikFormCheckBoxField } from '../FormikFormCheckBoxField';
import { useDialogFormStyles } from './DialogFormStyles';

interface Props<TData> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label: string;
  field: keyof TData;
  isRequired?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
}

export const GenericBooleanField = <TData,>({
  isRequired,
  field,
  label,
  formikProps,
  disabled,
  onChange,
  style,
  className
}: Props<TData>) => {
  const classes = useDialogFormStyles();
  return (
    <FormikFormCheckBoxField
      styledClasses={classes}
      isRequired={!!isRequired}
      label={label}
      formikKey={`${field}`}
      errors={formikProps.errors[field]}
      touched={formikProps.touched[field]}
      setFieldValue={formikProps.setFieldValue}
      onChange={onChange}
      style={style}
      disabled={disabled}
      className={className}
    />
  );
};

GenericBooleanField.defaultProps = {
  isRequired: false,
  disabled: false,
  onChange: undefined
};
