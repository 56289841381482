import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { categoryNewRowReducer } from './categoryRowState/newRowReducer';

import { ApplicationState } from '../../../../store';
import {
  OutletCategoryRecord,
  OutletInfo
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { OutletCategoryBasicRow } from './RowBasic';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { initialCategoryNewRowState } from './categoryRowState/categoryInitialStateAndTypes';
import { createCategory } from '../../../../store/outletInfoData/outletInfoDataActions';
import { DropdownInputCell } from '../../../../components/TableInputs/DropdownInput';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { OutletCategoryTypeShort } from '../../../../store/classifiers/classifiersTypes';
import { OutletCategoryColumnsDisplayStatus } from '../../tablesState/categoryTableTypes';
import { validateNewCategoryState } from './categoryRowState/categoryRowReducerFunctions';
import { TableCell } from '@material-ui/core';

interface Props {
  outletInfo: OutletInfo;
  columnsDisplay: OutletCategoryColumnsDisplayStatus;
  closeNewRecordRow: () => void;
}

export const CategoryRowContainerNew: React.FC<Props> = ({
  outletInfo: {
    outletId,
    controlledFrom,
    controlledTo,
    fromDateForNewCategory,
    availableCategories
  },
  columnsDisplay,
  closeNewRecordRow
}) => {
  const [rowState, rowDispatch] = useReducer(categoryNewRowReducer, initialCategoryNewRowState);

  const {
    virsis: { virsData, currentUser },
    outletInfoData: { creatingCategory, creatingCategoryError }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    rowDispatch({
      type: 'NEW_RECORD_STATE_INITIALIZED',
      availableCategories: availableCategories || [],
      controlledFrom: controlledFrom || null,
      controlledTo: controlledTo || null,
      fromDateForNewCategory
    });
  }, [rowDispatch, availableCategories, controlledFrom, controlledTo, fromDateForNewCategory]);

  const setCategoryType = (categoryType: OutletCategoryTypeShort | null) => {
    rowDispatch({ type: 'CATEGORY_TYPE_CHANGED', categoryType });
  };

  const setValidFromDate = (date: MaterialUiPickersDate | null) => {
    rowDispatch({ type: 'VALID_FROM_DATE_CHANGED', date });
  };

  const createRecord = () => {
    rowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateNewCategoryState(rowState).createRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const newCategory: OutletCategoryRecord = {
        categoryTypeId: rowState.categoryType.value?.categoryTypeId,
        validFrom: rowState.validFrom.value ? rowState.validFrom.value.format('L').toString() : null
      };
      if (virsData && currentUser && outletId) {
        reduxDispatch(createCategory(outletId, newCategory));
      }
    }
  };

  useEffect(() => {
    if (creatingCategoryError) rowDispatch({ type: 'STOP_LOADING' });
  }, [creatingCategoryError]);

  return (
    <OutletCategoryBasicRow
      id="new-category-record"
      columnsToDisplay={columnsDisplay}
      categoryTypeInput={
        <DropdownInputCell
          selectValue={setCategoryType}
          dropdownState={rowState.categoryType}
          getOptionLabel={(category) => category.categoryTypeName}
          getOptionSelected={(option, value) => option.categoryTypeId === value.categoryTypeId}
        />
      }
      validFromInput={
        <DateInputImprovedCell state={rowState.validFrom} setDate={setValidFromDate} isRequired />
      }
      validTo={<TableCell />}
      actions={
        <SaveAndCancelActions
          handleConfirmationYes={createRecord}
          isProcessing={creatingCategory}
          handleCancelButtonClick={closeNewRecordRow}
        />
      }
    />
  );
};
