import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import {
  ColumnDisplayStatus,
  ColumnHeader,
  SortOrder,
  TableCustomFilter
} from '../../../utils/tableTypes';
import { getTableExportDefinition } from './tableFunctions';

export const initialDisplayStatus = {
  enterpriseTypeName: true,
  validFrom: true,
  validTo: true,
  authorName: true
};

export const enterpriseTableColumns: EnterpriseDataTableColumn[] = [
  {
    id: 'enterpriseTypeName',
    label: 'Veiklos rūšis',
    type: 'multiple'
  },
  {
    id: 'validFrom',
    label: 'Nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Iki',
    type: 'date'
  },
  {
    id: 'authorName',
    label: 'Duomenų teikėjas'
  }
];

export const initialEnterpriseTableState: EnterpriseDataTableState = {
  showAddNewRecord: false,
  customFilter: {
    enterpriseTypeName: [],
    validFrom: [],
    validTo: [],
    authorName: []
  },
  customFilterOn: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showStatusNotSigned: true,
  showStatusRelevant: true,
  showStatusOutdated: true,
  order: 'asc',
  sortBy: 'enterpriseTypeName',
  page: 0,
  rowsPerPage: 10,
  pageData: [],
  columnsDisplayStatus: initialDisplayStatus,
  extendedRows: [],
  tableExportDefinition: getTableExportDefinition(enterpriseTableColumns, initialDisplayStatus)
};

export type EnterpriseDataTableField =
  | 'enterpriseTypeName'
  | 'validFrom'
  | 'validTo'
  | 'authorName';

export type EnterpriseDataTableColumn = ColumnHeader<EnterpriseDataTableField>;

export interface EnterpriseDataTableState {
  showAddNewRecord: boolean;
  customFilter: EnterpriseDataTableCustomFilter;
  customFilterOn: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  order: SortOrder;
  sortBy: EnterpriseDataTableField;
  page: number;
  rowsPerPage: number;
  pageData: EnterpriseData[];
  columnsDisplayStatus: EnterpriseDataTableColumnsDisplayStatus;
  extendedRows: number[];
  tableExportDefinition: TableExportDefinition<EnterpriseData>;
}

export type EnterpriseDataTableCustomFilter = TableCustomFilter<EnterpriseDataTableField>;

export type EnterpriseDataTableColumnsDisplayStatus = ColumnDisplayStatus<EnterpriseDataTableField>;

export type EnterpriseDataTableAction =
  | {
      type: 'SET_STATUS_FILTERS';
      notSignedEnabled: boolean;
      relevantEnabled: boolean;
      outdatedEnabled: boolean;
    }
  | { type: 'TOGGLE_SHOW_ADD_NEW_RECORD' }
  | { type: 'TOGGLE_CUSTOM_FILTER_DISPLAY' }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: EnterpriseDataTableField;
      values: string[];
    }
  | { type: 'TOGGLE_SHOW_STATUS_UNSIGNED' }
  | { type: 'TOGGLE_SHOW_STATUS_RELEVANT' }
  | { type: 'TOGGLE_SHOW_STATUS_NOT_RELEVANT' }
  | { type: 'SET_SORTING'; sortBy: EnterpriseDataTableField }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_COLUMN_DISPLAY'; column: EnterpriseDataTableField }
  | { type: 'RESET_COLUMN_DISPLAY' }
  | { type: 'ROW_EXTENSION_CLICKED'; enterpriseId: number }
  | { type: 'INITIALIZE_WITH_NEW_RECORD_OPENED' };
