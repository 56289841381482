import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RadioGroup } from '@material-ui/core';

import { PersonDetails } from './PersonDetails';
import { CloseButton } from '../../../../../../../../components/TableButtons/CloseButton';
import { CustomRadioButton } from '../../../../../../../../components/TableButtons/CustomRadioButton';
import { ShareholderGroupPerson } from '../../../../../tableState/tableInitialStateAndTypes';
import { AddPersonButton } from './AddPersonIconButton';
import { Typography } from '@material-ui/core/';

const useStyles = makeStyles(() => ({
  shareholderGroupPersons: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export interface Props {
  onRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemove: (personId: number) => void;
  radioValue: number | null;
  shareholderPersonsList: ShareholderGroupPerson[];
  onAddPersonClick: () => void;
  isShareholderFromJadis: boolean;
  isSelectedPersonUnique: boolean;
  mayShareholderHaveChildren: boolean;
  index: number;
}

export const ShareholderGroupPersons: React.FC<Props> = ({
  onRadioChange,
  onRemove,
  radioValue,
  shareholderPersonsList,
  onAddPersonClick,
  isShareholderFromJadis,
  isSelectedPersonUnique,
  index,
  mayShareholderHaveChildren
}) => {
  const classes = useStyles();

  return (
    <>
      <RadioGroup
        aria-label="authorizedPerson"
        name="authorizedPerson"
        value={radioValue}
        onChange={onRadioChange}
      >
        {!isSelectedPersonUnique && (
          <Typography style={{ color: '#F11A1A' }}>
            Toks dalyvis jau pasirinktas, pridėkite asmenį kuris dar nėra pasirinktas.
          </Typography>
        )}
        {shareholderPersonsList.map(
          ({ personId, personName, legalPersonCode }: ShareholderGroupPerson, index) => (
            <div className={classes.shareholderGroupPersons} key={`${personId}-${index}`}>
              <PersonDetails personName={personName} legalPersonCode={legalPersonCode} />
              {(!isShareholderFromJadis || mayShareholderHaveChildren) && (
                <>
                  <CustomRadioButton label="Įgaliotas asmuo" value={personId} />
                  <CloseButton
                    onClose={() => {
                      onRemove(personId);
                    }}
                  />
                </>
              )}
            </div>
          )
        )}
      </RadioGroup>
      {(!isShareholderFromJadis || mayShareholderHaveChildren) && (
        <AddPersonButton onAddPersonClick={onAddPersonClick} text="Pridėti bendraturtį" />
      )}
    </>
  );
};
