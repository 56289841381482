import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customButton: {
      alignItems: 'center',
      borderColor: theme.palette.info.light,
      color: '#637082',
      display: 'flex',
      fontWeight: 400,
      minWidth: 120,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        background: '#fff'
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        background: '#fff'
      },
      '&:disabled': {
        borderColor: theme.palette.info.light,
        color: theme.palette.info.main,
        background: '#fff',
        '& $icon': {
          stroke: theme.palette.info.main
        }
      }
    },
    icon: {
      marginRight: 12,
      stroke: '#e60000'
    }
  })
);

interface Props {
  onClose: () => void;
  disabled?: boolean;
}

export const CancelIconButton: React.FC<Props> = ({ children, onClose, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={classes.customButton}
      onClick={onClose}
      disabled={disabled}
    >
      <svg width="11.759" height="11.759" viewBox="0 0 11.759 11.759" className={classes.icon}>
        <g id="Group_2804" data-name="Group 2804" transform="translate(2.121 2.121)">
          <path
            id="Path_1233"
            data-name="Path 1233"
            d="M-8846.208-20088.666l7.516,7.516"
            transform="translate(8846.208 20088.666)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="Path_1234"
            data-name="Path 1234"
            d="M0,0,7.516,7.516"
            transform="translate(7.517) rotate(90)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </svg>
      {children}
    </Button>
  );
};
