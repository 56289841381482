export const FETCH_OUTLET_DATA_REQUEST = '@outlet/FETCH_OUTLET_DATA_REQUEST';
export const FETCH_OUTLET_DATA_SUCCESS = '@outlet/FETCH_OUTLET_DATA_SUCCESS';
export const FETCH_OUTLET_DATA_ERROR = '@outlet/FETCH_OUTLET_DATA_ERROR';

export const CREATE_OUTLET_REQUEST = '@outlet/CREATE_OUTLET_REQUEST';
export const CREATE_OUTLET_SUCCESS = '@outlet/CREATE_OUTLET_SUCCESS';
export const CREATE_OUTLET_ERROR = '@outlet/CREATE_OUTLET_ERROR';
export const RESET_CREATE_OUTLET_STATE = '@outlet/RESET_CREATE_OUTLET_STATE';

export const RENEW_OUTLET_REQUEST = '@outlet/RENEW_OUTLET_REQUEST';
export const RENEW_OUTLET_SUCCESS = '@outlet/RENEW_OUTLET_SUCCESS';
export const RENEW_OUTLET_ERROR = '@outlet/RENEW_OUTLET_ERROR';
export const RESET_RENEW_OUTLET_STATE = '@outlet/RESET_RENEW_OUTLET_STATE';

export const UPDATE_OUTLET_REQUEST = '@outlet/UPDATE_OUTLET_REQUEST';
export const UPDATE_OUTLET_SUCCESS = '@outlet/UPDATE_OUTLET_SUCCESS';
export const UPDATE_OUTLET_ERROR = '@outlet/UPDATE_OUTLET_ERROR';
export const RESET_UPDATE_OUTLET_STATE = '@outlet/RESET_UPDATE_OUTLET_STATE';

export const REMOVE_OUTLET_REQUEST = '@outlet/REMOVE_OUTLET_REQUEST';
export const REMOVE_OUTLET_SUCCESS = '@outlet/REMOVE_OUTLET_SUCCESS';
export const REMOVE_OUTLET_ERROR = '@outlet/REMOVE_OUTLET_ERROR';
export const RESET_REMOVE_OUTLET_STATE = '@outlet/RESET_REMOVE_OUTLET_STATE';

export const REMOVE_OUTLETS_REQUEST = '@outlet/REMOVE_OUTLETS_REQUEST';
export const REMOVE_OUTLETS_SUCCESS = '@outlet/REMOVE_OUTLETS_SUCCESS';
export const REMOVE_OUTLETS_ERROR = '@outlet/REMOVE_OUTLETS_ERROR';

export const FIND_OUTLET_ON_LIBIS_REQUEST = '@outlet/FIND_OUTLET_ON_LIBIS_REQUEST';
export const FIND_OUTLET_ON_LIBIS_SUCCESS = '@outlet/FIND_OUTLET_ON_LIBIS_SUCCESS';
export const FIND_OUTLET_ON_LIBIS_ERROR = '@outlet/FIND_OUTLET_ON_LIBIS_ERROR';
export const RESET_FIND_OUTLET_ON_LIBIS_STATE = '@outlet/RESET_FIND_OUTLET_ON_LIBIS_STATE';
