import React, { ReactNode } from 'react';
import { TextField, TableCell, InputAdornment } from '@material-ui/core';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { InputStateGeneric } from '../../utils/tableTypes';
import theme from '../../style/virsisTheme';

interface MoneyInputProps {
  setValue: (value: string) => void;
  inputState: InputStateGeneric<string>;
  isRequired?: boolean;
  isDisabled?: boolean;
  endAdornment?: ReactNode;
  outlined?: boolean;
  noDecimals?: boolean;
}

export const MoneyInput: React.FC<MoneyInputProps> = ({
  setValue,
  inputState,
  isRequired,
  isDisabled,
  endAdornment,
  outlined,
  noDecimals
}) => {
  function setInputValue(values: NumberFormatValues) {
    setValue(values.value);
  }

  return (
    <form noValidate autoComplete="off">
      <NumberFormat
        customInput={TextField}
        disabled={isDisabled}
        variant={outlined ? 'outlined' : undefined}
        placeholder={inputState.placeholder}
        error={inputState.error}
        label={inputState.helperText}
        required={isRequired}
        value={inputState.value ? inputState.value : ''}
        isNumericString
        onValueChange={setInputValue}
        allowedDecimalSeparators={['.', ',']}
        decimalScale={2}
        decimalSeparator={noDecimals ? false : ','}
        thousandSeparator={' '}
        InputProps={{
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          )
        }}
      />
    </form>
  );
};

interface CellProps {
  rowSpan?: number;
}

export const MoneyInputCell: React.FC<MoneyInputProps & CellProps> = ({ rowSpan, ...props }) => {
  return (
    <TableCell
      style={{
        minWidth: '100px',
        backgroundColor: props.inputState.error ? theme.palette.error.light : undefined
      }}
      rowSpan={rowSpan}
    >
      <MoneyInput {...props} />
    </TableCell>
  );
};
