import { Reducer } from 'redux';
import { NaturalPersonInVirsis } from '../../components/FindNaturalPersonInVirsis/Utilities';
import {
  SELECT_NATURAL_PERSON_IN_VIRSIS,
  SELECT_NATURAL_PERSON_IN_VIRSIS_ERROR,
  SELECT_NATURAL_PERSON_IN_VIRSIS_REQUEST,
  SELECT_NATURAL_PERSON_IN_VIRSIS_SUCCESS
} from './actionTypes';

export interface findNaturalPersonInVirsisState {
  selectedVirsByNaturalPerson?: NaturalPersonInVirsis;
  selectedVirsByNaturalPersonLoading: boolean;
  selectedVirsByNaturalPersonError?: Error;
  selectedVirsByNaturalPersonResults?: NaturalPersonInVirsis[];
}

const initialState: findNaturalPersonInVirsisState = {
  selectedVirsByNaturalPerson: undefined,
  selectedVirsByNaturalPersonLoading: false,
  selectedVirsByNaturalPersonError: undefined,
  selectedVirsByNaturalPersonResults: undefined
};

export const findNaturalPersonInVirsisReducer: Reducer<findNaturalPersonInVirsisState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SELECT_NATURAL_PERSON_IN_VIRSIS: {
      return {
        ...state,
        selectedVirsByNaturalPerson: action.payload
      };
    }
    case SELECT_NATURAL_PERSON_IN_VIRSIS_REQUEST: {
      return {
        ...state,
        selectedVirsByNaturalPersonLoading: true
      };
    }
    case SELECT_NATURAL_PERSON_IN_VIRSIS_SUCCESS: {
      return {
        ...state,
        selectedVirsByNaturalPersonResults: action.payload,
        selectedVirsByNaturalPersonLoading: false
      };
    }
    case SELECT_NATURAL_PERSON_IN_VIRSIS_ERROR: {
      return {
        ...state,
        selectedVirsByNaturalPersonError: action.payload,
        selectedVirsByNaturalPersonLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
