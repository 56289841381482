import {
  put,
  takeEvery,
  takeLatest,
  call,
  select,
  take,
  fork,
  cancel,
  delay
} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { Task } from 'redux-saga';
import {
  acceptAgreementError,
  acceptAgreementSuccess,
  authoriseError,
  authoriseRequest,
  authoriseSuccess,
  fetchAgreementDateError,
  fetchAgreementDateSuccess,
  fetchAgreementFileError,
  fetchAgreementFileSuccess,
  fetchJarContactEmailError,
  fetchJarContactEmailRequest,
  fetchJarContactEmailSuccess,
  fetchUserContactEmailError,
  fetchUserContactEmailRequest,
  fetchUserContactEmailSuccess,
  fetchVirsDataError,
  fetchVirsDataRequest,
  fetchVirsDataSuccess,
  logoutSuccess,
  logoutError,
  openAgreementDialog,
  openContactEmailDialog,
  openRepresentationTypeSelectModal,
  saveVirsContactEmailError,
  saveVirsContactEmailRequest,
  saveVirsContactEmailSuccess,
  setAccountType,
  startVirsisFlow,
  startVirsisFlowError,
  submitVirsDataRequest,
  submitVirsDataSuccess,
  submitVirsDataError,
  searchVirsData,
  searchVirsDataSuccess,
  searchVirsDataError,
  fetchUserDataSuccess,
  applicationLoading,
  applicationLoadingStop,
  fetchJarCountriesSuccess,
  fetchVirsDataByVirsIdRequest,
  fetchVirsDataByVirsIdSuccess,
  fetchVirsDataByVirsIdError,
  searchVirsActiveOnlyData,
  registerAccountType,
  registerAccountTypeSuccess,
  registerAccountTypeError,
  poolingVirsDataEditorInfoSuccess,
  poolingVirsDataEditorInfoRequest,
  fetchSelectedVirsContactEmailRequest,
  fetchSelectedVirsContactEmailSuccess,
  fetchSelectedVirsContactEmailError
} from './actions';
import { Roles, VirsisUser, RegisterAccountTypeRecord, AccountType } from './dataTypes';
import {
  AUTHORISE_REQUEST,
  FETCH_USER_DATA_REQUEST,
  FETCH_AGREEMENT_DATE_REQUEST,
  FETCH_JAR_CONTACT_EMAIL_REQUEST,
  FETCH_VIRS_DATA_REQUEST,
  LOGOUT_REQUEST,
  SAVE_CONTACT_EMAIL_REQUEST,
  ACCEPT_AGREEMENT_REQUEST,
  FETCH_AGREEMENT_FILE,
  ACCEPT_AGREEMENT_SUCCESS,
  START_VIRSIS_FLOW,
  SET_ACCOUNT_TYPE,
  SAVE_CONTACT_EMAIL_SUCCESS,
  EXIT_EMAIL_DIALOG,
  EXIT_REPRESENTATION_TYPE_SELECT_DIALOG,
  EXIT_AGREEMENT_DIALOG,
  SUBMIT_VIRS_DATA_REQUEST,
  SEARCH_VIRS_REQUEST,
  FETCH_JAR_COUNTRIES_REQUEST,
  FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST,
  SEARCH_VIRS_ACTIVE_ONLY_REQUEST,
  REGISTER_ACCOUNT_TYPE_CHOSEN,
  FETCH_DATA_EDITOR_REQUEST,
  FETCH_USER_CONTACT_EMAIL_REQUEST,
  SYNCHRONIZE_VIRS,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST
} from './actionTypes';
import {
  fetchAgreementDateByVersion,
  getCredentials,
  fetchJarEmailByPersonId,
  getUserData,
  fetchVirsData,
  logout,
  saveVirsisContactEmail,
  acceptAgreementVersion,
  downloadAgreementFile,
  fetchContactEmailByPersonId,
  submitVirsData,
  getFindVirs,
  fetchVirsDataByVirsId,
  getFindActiveVirs,
  postRegisterAccountType,
  getVirsDataEditor,
  synchronizeVirs
} from './virsisApi';
import {
  accountType,
  agreementDate,
  agreementFile,
  authoriseData,
  contactEmail,
  getPersonId,
  isVirsEmailBeingEdited,
  user,
  virsData,
  virsisData
} from './selectors';
import { hasRole } from '../../utils/roleHelper';
import { DOCUMENT_APPROVE_SUCCESS } from '../document/documentTypes';
import { ApplicationState } from '../index';
import { getJarCountries } from '../legalPersonData/legalDataApi';
import { fetchUserMessages } from '../userMessages/userMessagesActions';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { VirsisState } from './reducer';
import { applicationStorage } from '../../utils/axiosApi';
import { documentValidationRequest } from '../document/documentActions';

type CONTEXT = 'INST' | 'VIRS';
//const devEnvironment = !!process.env.REACT_APP_DEV;
const devEnvironment = false;

function setContext(selectedUser: VirsisUser) {
  const context: CONTEXT =
    (selectedUser && hasRole(selectedUser, Roles.ROLE_VIRS)) ||
    hasRole(selectedUser, Roles.ROLE_LEGAL)
      ? 'VIRS'
      : 'INST';
  applicationStorage.setItem('context', context);
  if (context === 'INST') applicationStorage.removeItem('acountType');
}

function* authoriseSaga(action: ActionType<typeof authoriseRequest>) {
  try {
    applicationStorage.clear();
    const { data } = yield call(getCredentials, action.payload);
    yield put(authoriseSuccess(data));

    const selectedUser = yield select(user);
    yield setContext(selectedUser);
    if (selectedUser) {
      yield call(startVirsisFlowSaga, startVirsisFlow(selectedUser.personId));
    }
  } catch (err) {
    yield put(authoriseError(err));
  }
}

function* fetchUserDataSaga() {
  try {
    const authorise = yield select((state: ApplicationState) => state.virsis.authorise);
    const userExists = yield select(user);

    if (!authorise && !userExists) {
      const { data } = yield call(getUserData);
      yield put(authoriseSuccess(data));
      const selectedUser = yield select(user);
      yield setContext(selectedUser);
      yield call(startVirsisFlowSaga, startVirsisFlow(selectedUser.personId));
    }
    yield put(fetchUserDataSuccess());
  } catch (err) {
    yield put(authoriseError(err));
  }
}

function* redirect() {
  yield window.location.assign('/');
}

function clearStorage() {
  applicationStorage.clear();
}

function* logoutSaga() {
  try {
    yield clearStorage();
    yield call(logout);
    yield put(logoutSuccess());
  } catch (err) {
    yield clearStorage();
    yield put(logoutError(err));
  }
}

function* fetchJarEmailSaga(action: ActionType<typeof fetchJarContactEmailRequest>) {
  try {
    const { data } = yield call(fetchJarEmailByPersonId, action.payload);
    yield put(fetchJarContactEmailSuccess(data.email));
  } catch (err) {
    yield put(fetchJarContactEmailError(err));
  }
}

function* fetchJarCountriesSaga() {
  try {
    const { data } = yield call(getJarCountries);
    yield put(fetchJarCountriesSuccess(data));
  } catch (err) {
    yield put(
      sendMessage(
        'error',
        err.response.data?.message || 'Klaida. Nepavyko atsiųsti šalių sąrašo. Bandykite dar kartą'
      )
    );
  }
}

function* fetchVirsDataSaga(action: ActionType<typeof fetchVirsDataRequest>) {
  try {
    const currentUser = yield select(user);
    const personId = yield select(getPersonId);
    const id = personId || currentUser.personId;
    const { data } = yield call(fetchVirsData, id);
    yield put(fetchVirsDataSuccess(data.virsData));
    yield put(fetchSelectedVirsContactEmailRequest(data.virsData.personId));
    yield put(documentValidationRequest(data.virsData.documentStatusId));
  } catch (err) {
    yield put(fetchVirsDataError(err));
  }
}

function* synchronizeVirsDataSaga(action: ActionType<typeof fetchVirsDataRequest>) {
  try {
    const currentUser = yield select(user);
    const personId = yield select(getPersonId);
    const virs = yield select(virsData);
    const id = virs.virsId || personId || currentUser.personId;
    yield call(synchronizeVirs, id);
  } catch (err) {
    console.log('Synchronization ERROR');
  }
}

function* fetchVirsDataByVirsIdSaga(action: ActionType<typeof fetchVirsDataByVirsIdRequest>) {
  try {
    const { data } = yield call(fetchVirsDataByVirsId, action.payload);
    yield put(fetchVirsDataByVirsIdSuccess(data.virsData));
    yield put(fetchSelectedVirsContactEmailRequest(data.virsData.personId));
    yield put(documentValidationRequest(data.virsData.documentStatusId));
  } catch (err) {
    yield put(fetchVirsDataByVirsIdError(err));
  }
}

function* acceptAgreementSaga() {
  try {
    const { data } = yield call(acceptAgreementVersion);
    yield put(acceptAgreementSuccess(data.agreementDate));
  } catch (err) {
    yield put(acceptAgreementError(err));
  }
}

function* saveContactEmailSaga(action: ActionType<typeof saveVirsContactEmailRequest>) {
  try {
    const isVirsEmailEdited: boolean = yield select(isVirsEmailBeingEdited);
    const currentUser = yield select(user);
    const { data } = yield call(saveVirsisContactEmail, action.payload);
    yield put(saveVirsContactEmailSuccess(data));
    yield put(sendMessage('success', 'El. paštas išsaugotas'));
    if (isVirsEmailEdited) {
      yield put(fetchVirsDataRequest());
      if (currentUser?.personId === action.payload.personId) {
        yield put(fetchUserContactEmailRequest(action.payload.personId));
      } else {
        yield put(fetchSelectedVirsContactEmailRequest(action.payload.personId));
      }
    } else {
      yield put(fetchUserContactEmailRequest(action.payload.personId));
    }
  } catch (err) {
    yield put(saveVirsContactEmailError(err));
    yield put(
      sendMessage(
        'error',
        err.response?.data?.message || 'El. pašto išsaugoti nepavyko, bandykite dar kartą vėliau'
      )
    );
  }
}

function* fetchContactEmailSaga(action: ActionType<typeof fetchUserContactEmailRequest>) {
  try {
    const { data } = yield call(fetchContactEmailByPersonId, action.payload);
    yield put(fetchUserContactEmailSuccess(data.email));
  } catch (err) {
    yield put(fetchUserContactEmailError());
  }
}

function* fetchSelectedVirsEmailSaga(
  action: ActionType<typeof fetchSelectedVirsContactEmailRequest>
) {
  try {
    const { data } = yield call(fetchContactEmailByPersonId, action.payload);
    yield put(fetchSelectedVirsContactEmailSuccess(data.email));
  } catch (err) {
    yield put(fetchSelectedVirsContactEmailError());
  }
}

function* fetchAgreementFileSaga() {
  try {
    const { data } = yield call(downloadAgreementFile);
    yield put(
      fetchAgreementFileSuccess({
        content: data
      })
    );
  } catch (err) {
    yield put(fetchAgreementFileError(err));
  }
}

function* fetchAgreementDateSaga() {
  try {
    const { data } = yield call(fetchAgreementDateByVersion);
    yield put(fetchAgreementDateSuccess(data.agreementDate));
  } catch (err) {
    yield put(fetchAgreementDateError(err));
  }
}

function* logoutOn(type: string) {
  try {
    yield take(type);
    yield call(logoutSaga);
    yield put(fetchUserDataSuccess());
  } catch (err) {
    put(sendMessage('error', err.response.data?.message || 'Klaida. Bandykite dar kartą vėliau'));
  }
}

function* startVirsisFlowSaga(action: ActionType<typeof startVirsisFlow>) {
  try {
    yield call(fetchAgreementDateSaga);
    const selectedAgreementDate = yield select(agreementDate);
    if (!selectedAgreementDate) {
      yield call(fetchAgreementFileSaga);
      const selectedAgreementFile = yield select(agreementFile);
      if (selectedAgreementFile) {
        yield put(openAgreementDialog());
        const logoutOnExit = yield fork(logoutOn, EXIT_AGREEMENT_DIALOG);
        yield take(ACCEPT_AGREEMENT_SUCCESS);
        yield cancel(logoutOnExit);
      }
    }

    const selectedUser: VirsisUser = yield select(user);
    const savedAccountType = applicationStorage.getItem('accountType');
    if (hasRole(selectedUser, Roles.ROLE_VIRS) || hasRole(selectedUser, Roles.ROLE_LEGAL)) {
      if (!savedAccountType) {
        yield put(openRepresentationTypeSelectModal());
        const logoutOnExit = yield fork(logoutOn, EXIT_REPRESENTATION_TYPE_SELECT_DIALOG);
        yield take(SET_ACCOUNT_TYPE);
        yield cancel(logoutOnExit);
      }
    }

    const selectedAccountType = yield select(accountType);
    if ((savedAccountType || selectedAccountType) === AccountType.VIRS) {
      yield put(setAccountType(AccountType.VIRS));
      yield call(fetchVirsDataSaga, fetchVirsDataRequest());
    } else if ((savedAccountType || selectedAccountType) === AccountType.LEGAL) {
      yield put(setAccountType(AccountType.LEGAL));
    }

    yield call(fetchContactEmailSaga, fetchUserContactEmailRequest(action.payload));
    const selectedContactEmail = yield select(contactEmail);
    if (!selectedContactEmail) {
      yield put(fetchJarContactEmailRequest(action.payload));
      yield put(openContactEmailDialog());
      const logoutOnExit = yield fork(logoutOn, EXIT_EMAIL_DIALOG);
      yield take(SAVE_CONTACT_EMAIL_SUCCESS);
      yield cancel(logoutOnExit);
    }

    const virs = yield select(virsData);
    const accType = savedAccountType || selectedAccountType;
    if (accType === AccountType.VIRS || accType === AccountType.LEGAL) {
      yield put(registerAccountType(accType, virs ? virs.virsId : null));
    }

    yield put(fetchUserMessages());
    yield put(fetchUserDataSuccess());
  } catch (err) {
    yield put(startVirsisFlowError(err));
  }
}

function* submitVirsDataSaga(action: ActionType<typeof submitVirsDataRequest>) {
  try {
    const { data } = yield call(submitVirsData, action.payload);
    yield put(submitVirsDataSuccess(data.virsId));
    yield call(fetchVirsDataSaga, fetchVirsDataRequest());
  } catch (err) {
    yield put(submitVirsDataError(err));
  }
}

function* handleSearchVirsSaga(action: ActionType<typeof searchVirsData>) {
  try {
    const { data } = yield call(getFindVirs, action.payload);
    yield put(searchVirsDataSuccess(data));
  } catch (err) {
    yield put(
      searchVirsDataError(
        err?.response?.data?.message || 'Sistemos klaida, kreipkitės į sistemos administratorių'
      )
    );
  }
}

function* handleSearchVirsActiveOnlySaga(action: ActionType<typeof searchVirsActiveOnlyData>) {
  try {
    const { data } = yield call(getFindActiveVirs, action.payload);
    yield put(searchVirsDataSuccess(data));
  } catch (err) {
    yield put(searchVirsDataError(err.response));
  }
}

function* stopLoadingTask() {
  yield delay(500);
  yield put(applicationLoadingStop());
}

function* cancelOn(task: Task) {
  yield take([AUTHORISE_REQUEST, FETCH_USER_DATA_REQUEST]);
  yield cancel(task);
  const enc = yield select(authoriseData);
  if (enc) {
    yield call(authoriseSaga, authoriseRequest(enc));
  }
}

export function* startApplication() {
  yield put(applicationLoading());
  const task: Task = yield fork(stopLoadingTask);
  yield cancelOn(task);
}

function* handleRegisterAccountTypeSaga(action: ActionType<typeof registerAccountType>) {
  try {
    const { accountName, virsId } = action.payload;
    const regAccountTypeRecord: RegisterAccountTypeRecord = {
      accountName,
      virsId
    };
    yield call(postRegisterAccountType, regAccountTypeRecord);
    yield put(registerAccountTypeSuccess());
  } catch (err) {
    yield put(registerAccountTypeError(err.response));
  }
}

function* handleFetchVirsDataEditor(action: ActionType<typeof poolingVirsDataEditorInfoRequest>) {
  try {
    const pooling = yield fork(fetchVirsDataEditor, action);
    yield delay(300000);
    yield cancel(pooling);
  } catch (err) {
    yield put(registerAccountTypeError(err.response));
  }
}

function* fetchVirsDataEditor(action: ActionType<typeof poolingVirsDataEditorInfoRequest>) {
  const { virsId, pooling } = action.payload;
  try {
    if (virsId) {
      while (pooling) {
        const { data } = yield call(getVirsDataEditor, virsId);
        const virsisState: VirsisState = yield select(virsisData);
        if (
          (JSON.stringify(virsisState.dataEditorInfo) !== JSON.stringify(data) && data) ||
          (virsisState.dataEditorInfo === undefined && data)
        ) {
          yield put(poolingVirsDataEditorInfoSuccess(data || undefined));
        }
        yield delay(30000);
      }
    }
  } catch (err) {
    yield put(registerAccountTypeError(err.response));
  }
}

function* virsisSaga() {
  yield takeEvery(FETCH_USER_DATA_REQUEST, fetchUserDataSaga);
  if (!devEnvironment) {
    yield takeEvery(LOGOUT_REQUEST, redirect);
  }
  yield takeEvery(LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(FETCH_AGREEMENT_DATE_REQUEST, fetchAgreementDateSaga);
  yield takeEvery(FETCH_VIRS_DATA_REQUEST, fetchVirsDataSaga);
  yield takeEvery(FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST, fetchVirsDataByVirsIdSaga);
  yield takeEvery(FETCH_JAR_CONTACT_EMAIL_REQUEST, fetchJarEmailSaga);
  yield takeEvery(SAVE_CONTACT_EMAIL_REQUEST, saveContactEmailSaga);
  yield takeEvery(ACCEPT_AGREEMENT_REQUEST, acceptAgreementSaga);
  yield takeEvery(FETCH_AGREEMENT_FILE, fetchAgreementFileSaga);
  yield takeEvery(START_VIRSIS_FLOW, startVirsisFlowSaga);
  yield takeEvery(SUBMIT_VIRS_DATA_REQUEST, submitVirsDataSaga);
  yield takeEvery(DOCUMENT_APPROVE_SUCCESS, fetchVirsDataSaga);
  yield takeEvery(SEARCH_VIRS_REQUEST, handleSearchVirsSaga);
  yield takeEvery(SEARCH_VIRS_ACTIVE_ONLY_REQUEST, handleSearchVirsActiveOnlySaga);
  yield takeEvery(FETCH_JAR_COUNTRIES_REQUEST, fetchJarCountriesSaga);
  yield takeEvery(REGISTER_ACCOUNT_TYPE_CHOSEN, handleRegisterAccountTypeSaga);
  yield takeLatest(FETCH_DATA_EDITOR_REQUEST, handleFetchVirsDataEditor);
  yield takeLatest(FETCH_USER_CONTACT_EMAIL_REQUEST, fetchContactEmailSaga);
  yield takeLatest(FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST, fetchSelectedVirsEmailSaga);
  yield takeLatest(SYNCHRONIZE_VIRS, synchronizeVirsDataSaga);
}

export default virsisSaga;
