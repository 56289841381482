import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@material-ui/core';

interface Props {
  label: string;
  disabled?: boolean;
}

export const PercentageFieldContainer: React.FC<Props> = ({ label, children, disabled }) => {
  const classes = makeStyles((theme: Theme) => ({
    labelInputContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      maxWidth: 170
    },
    inputContainer: {
      alignItems: 'center',
      display: 'flex'
    },
    containerText: {
      color: disabled ? theme.palette.info.main : theme.palette.text.primary
    }
  }))();

  return (
    <Box className={classes.labelInputContainer}>
      <Typography variant="body2" className={classes.containerText}>
        {label}
      </Typography>
      <div className={classes.inputContainer}>
        {children}
        <Typography variant="body2" className={classes.containerText}>
          %
        </Typography>
      </div>
    </Box>
  );
};
