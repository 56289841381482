import {
  InputStateGeneric,
  DropdownStateGeneric,
  DatePickerState
} from '../../../../../utils/tableTypes';
import { EditionPeriodShort } from '../../../../../store/classifiers/classifiersTypes';
import { EditionEditRowState, EditionNewRowState } from './rowInitialStateAndTypes';
import {
  validateMandatoryDate,
  validateSingleChoice
} from '../../../../../utils/InputValueFunctions';

export function setCirculationAndValidate(
  circulationValue: string | null
): InputStateGeneric<string> {
  if (!circulationValue) {
    return {
      value: null,
      id: 'circulation',
      error: true,
      helperText: 'Tiražas privalomas',
      placeholder: '',
      validated: false
    };
  }
  const circulationParsedToNumber = Number(circulationValue);
  if (
    !circulationParsedToNumber ||
    circulationParsedToNumber < 1 ||
    circulationParsedToNumber > 1000000000
  ) {
    return {
      value: circulationValue,
      id: 'circulation',
      error: true,
      helperText: 'Tiražas turi būti skaičius tarp 1 ir 1 000 000 000',
      placeholder: '',
      validated: false
    };
  }
  return {
    value: circulationValue,
    id: 'circulation',
    error: false,
    helperText: '',
    placeholder: '',
    validated: true
  };
}

export function validateCirculiation(
  circulationState: InputStateGeneric<string>
): InputStateGeneric<string> {
  if (!circulationState.value) {
    return {
      value: null,
      id: 'circulation',
      error: true,
      helperText: 'tiražas privalomas',
      placeholder: '',
      validated: false
    };
  }
  return circulationState;
}

export function validateNewEditionRecordState(state: EditionNewRowState): EditionNewRowState {
  const circulationValidated = validateCirculiation(state.circulation);
  const periodValidated: DropdownStateGeneric<EditionPeriodShort> = validateSingleChoice(
    state.editionPeriod,
    'Reikia pasirinkti iš sąrašo'
  );
  const editionDateValidated: DatePickerState = validateMandatoryDate({
    ...state.editionDate
  });
  return {
    ...state,
    circulation: circulationValidated,
    editionPeriod: state.periodic ? periodValidated : state.editionPeriod,
    editionDate: !state.periodic ? editionDateValidated : state.editionDate,
    createRecordConfirmationOn: state.periodic
      ? periodValidated.validated && circulationValidated.validated
      : editionDateValidated.validated && circulationValidated.validated
  };
}

export function validateEditEditionRecordState(state: EditionEditRowState): EditionEditRowState {
  const circulationValidated = validateCirculiation(state.circulation);
  const periodValidated: DropdownStateGeneric<EditionPeriodShort> = validateSingleChoice(
    state.editionPeriod,
    'Reikia pasirinkti iš sąrašo'
  );

  const editionDateValidated: DatePickerState = validateMandatoryDate({
    ...state.editionDate
  });
  return {
    ...state,
    circulation: circulationValidated,
    editionPeriod: state.periodic ? periodValidated : state.editionPeriod,
    editionDate: !state.periodic ? editionDateValidated : state.editionDate,
    updateRecordConfirmationOn: state.periodic
      ? periodValidated.validated && circulationValidated.validated
      : editionDateValidated.validated && circulationValidated.validated
  };
}

export function isValid({
  circulation,
  editionDate,
  editionPeriod
}: EditionNewRowState | EditionEditRowState): boolean {
  return !circulation.error && !editionDate.error && !editionPeriod.error;
}
