import { Elements, FlowElement, isEdge, isNode } from 'react-flow-renderer';
import dagre from 'dagre';

import {
  createOutletEdges,
  createOutletsNodes,
  createShareholdersEdges,
  createShareholdersNodes,
  createVirsNode
} from './createNodes';

import { ShareholderNodeData } from './NodesTypes';
import {
  ChartShareholderPerson,
  ChartVirsData
} from '../../../../store/shareholdersVirsTree/virsTreeDataTypes';
import { StateShareholder, VirsTreeState } from '../../treeState/treeTypes';

export const createInitialFlowElements = (data: ChartVirsData, activeDate: string) => {
  const { outlets, shareholders, uniqueId, virsId } = data;

  const virsNode = createVirsNode(data, uniqueId);
  const outletsNodes = createOutletsNodes(outlets, virsId);
  const outletEdges = createOutletEdges(outlets, uniqueId);

  const shareholdersNodes = createShareholdersNodes(shareholders, virsId, activeDate);

  const shareholdersEdges = createShareholdersEdges(shareholders, uniqueId, virsId);

  const elements = [
    ...outletsNodes,
    ...outletEdges,
    virsNode,
    ...shareholdersNodes,
    ...shareholdersEdges
  ];

  return elements;
};

export const filterElementsByState = (
  data: ChartVirsData,
  elements: Elements,
  showingShareholders: StateShareholder[],
  elementsToRender: Elements = []
): Elements => {
  if (!elements) {
    return [];
  }
  const { virsId } = data;
  const outletsIds = data.outlets.map((outl) => outl.outletId);
  const ids = showingShareholders.map((shareholder) =>
    parseFloat(`${shareholder.shareholderLevel}${shareholder.shareholderId}`)
  );

  elements.forEach((el) => {
    if (parseInt(el.id, 10) === virsId) {
      elementsToRender.push(el);
    }
    if (outletsIds.includes(parseFloat(el.id))) {
      elementsToRender.push(el);
    }
    if (isEdge(el) && outletsIds.includes(parseFloat(el.source))) {
      elementsToRender.push(el);
    }
    if (ids.includes(parseFloat(el.id))) {
      elementsToRender.push(el);
    }
    if (isEdge(el) && ids.includes(parseFloat(el.target))) {
      elementsToRender.push(el);
    }
  });

  return elementsToRender;
};

const countBoxNumber = (persons: ChartShareholderPerson[]): number => {
  return persons.length + 1;
};

const calcNodeHeight = (
  node: FlowElement<ShareholderNodeData>,
  boxNumber: number,
  treeState: VirsTreeState
): number => {
  const defaultHeight = 95;

  if (node.type === 'shareholderGroupNode') {
    const groupId: number | undefined = node.data?.shareholder.shareholderGroupId;
    const groupOnScreen: StateShareholder | undefined = treeState.showingShareholders.find(
      (sh) => sh.shareholderGroupId === groupId
    );
    return groupOnScreen && groupOnScreen.showingGroupExtended
      ? 12 + 97 * boxNumber + 10 * (boxNumber - 1)
      : defaultHeight;
  }

  return defaultHeight;
};

const setNodeVerticalPosition = (y: number, boxNumber: number, nodeHeight: number): number => {
  const defaultHeight = 95;

  if (nodeHeight > defaultHeight) {
    return boxNumber > 3
      ? y - (nodeHeight / boxNumber + 55 * (boxNumber - 3))
      : y - nodeHeight / boxNumber;
  }
  return y;
};

export function getLayoutedElements(
  elements: Elements,
  treeState: VirsTreeState,
  direction = 'LR'
) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: direction, nodesep: 20 });

  elements.forEach((el) => {
    if (isNode(el)) {
      const boxNumber =
        el.data.shareholder && countBoxNumber(el.data.shareholder.shareholderPersons);

      dagreGraph.setNode(el.id, {
        width: 300,
        height: calcNodeHeight(el, boxNumber, treeState)
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const boxNumber =
        el.data.shareholder && countBoxNumber(el.data.shareholder.shareholderPersons);
      const nodeWithPosition = dagreGraph.node(el.id);
      // eslint-disable-next-line no-param-reassign
      el.position = {
        x: nodeWithPosition.x,
        y: setNodeVerticalPosition(
          nodeWithPosition.y,
          boxNumber,
          calcNodeHeight(el, boxNumber, treeState)
        )
      };
    }
    return el;
  });
}
