import React, { useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { FundsReceivedDataTableStateContext } from './Context';
import { FundsReceivedDataVirs } from '../../store/fundsReceived/fundsReceivedDataTypes';
import { ReportErrorAction } from '../../components/TableRowActions/ReportErrorAction';
import { OutletsAsLinksList } from '../../components/TableLinks/OutletsAsLinksList';
import { ExpandDetailsButton } from '../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { formatStringOfNumber } from '../../utils/tableDataFunctions';
import { ReportInstitutionDataDialog } from '../../components/ReportInstitutionDataDialog/ReportInstitutionDataDialog';
import { PreviewDocumentDialogMinimal } from '../document/PreviewDocumentDialogMinimal';
import useInstitutionTableRow from '../../utils/hooks/institutionTableRowHook';
import { TableRowExtension } from '../../components/institutionProvidedDataTable/TableRowExtension';
import { Roles } from '../../store/virsis/dataTypes';

interface FundsReceivedDataTableRowProps {
  record: FundsReceivedDataVirs;
  redirectToOutlet: (outletId: number) => void;
  openDocumentInNewTab?: () => void;
  virsId: number;
}

export const FundsReceivedDataTableRow: React.FC<FundsReceivedDataTableRowProps> = ({
  record,
  virsId
}) => {
  const { state: tableState } = useContext(FundsReceivedDataTableStateContext);

  const {
    rowState,
    toggleExtension,
    closeDocumentPreview,
    openDocumentPreview,
    closeReportDialog,
    openReportDialog
  } = useInstitutionTableRow();

  return (
    <>
      {rowState.showReport && rowState.documentStatusId && (
        <ReportInstitutionDataDialog
          documentStatusId={rowState.documentStatusId}
          close={closeReportDialog}
          openDocumentPreview={() => openDocumentPreview(record.documentStatusId)}
          isOpen
        />
      )}
      {rowState.showPreview && (
        <PreviewDocumentDialogMinimal
          maxWidth="lg"
          open
          onClose={closeDocumentPreview}
          documentPath={`virs/legals/documents/${rowState.documentStatusId}`}
        />
      )}

      <TableRow key={record.fundsReceivedId}>
        {tableState.columnsDisplayStatus.fundsReceivedYear && (
          <TableCell align="left">
            <ExpandDetailsButton onClick={toggleExtension} showExtension={rowState.showExtension} />
            {record.fundsReceivedYear}
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsReceivedSum && (
          <TableCell align="left">{formatStringOfNumber(record.fundsReceivedSum)}</TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceName && (
          <TableCell align="left">{record.fundsSourceName}</TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceCode && (
          <TableCell align="left">{record.fundsSourceCode}</TableCell>
        )}
        {tableState.columnsDisplayStatus.outletName && (
          <TableCell align="left">
            <OutletsAsLinksList outlets={record.fundsReceivedOutlets} virsId={virsId} />
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.transactionType && (
          <TableCell align="left">{record.transactionType}</TableCell>
        )}
        <TableCell align="right">
          <ReportErrorAction onClicked={openReportDialog(record.documentStatusId)} />
        </TableCell>
      </TableRow>
      {rowState.showExtension && (
        <TableRowExtension
          creationDate={record.creationDate}
          documentId={record.documentStatusId}
          documentStatus={record.documentStatus.id}
          openDocumentPreview={openDocumentPreview}
          signDate={record.signDate}
          signedByPerson={record.signedByPerson}
          authorizedRolesToViewDocumentRow={[Roles.ROLE_ISTAIG_EDIT, Roles.ROLE_VIRS]}
        />
      )}
    </>
  );
};
