import { action } from 'typesafe-actions';
import { OutletShortData } from '../outlets/outletsTypes';
import {
  FETCH_LEGAL_FORM_TYPES_REQUEST,
  FETCH_LEGAL_FORM_TYPES_REQUEST_SUCCESS,
  FETCH_LEGAL_FORM_TYPES_REQUEST_ERROR,
  FETCH_SHARE_TYPES_REQUEST,
  FETCH_SHARE_TYPES_REQUEST_SUCCESS,
  FETCH_SHARE_TYPES_REQUEST_ERROR,
  FETCH_ANNULMENT_TYPES_REQUEST,
  FETCH_ANNULMENT_TYPES_SUCCESS,
  FETCH_ANNULMENT_TYPES_ERROR,
  FETCH_ENTERPRISE_TYPES_REQUEST,
  FETCH_ENTERPRISE_TYPES_SUCCESS,
  FETCH_ENTERPRISE_TYPES_ERROR,
  FETCH_DATA_DELETION_PERIOD_REQUEST,
  FETCH_DATA_DELETION_PERIOD_SUCCESS,
  FETCH_DATA_DELETION_PERIOD_ERROR,
  FETCH_FICTITIOUS_OUTLET_ERROR,
  FETCH_FICTITIOUS_OUTLET_REQUEST,
  FETCH_FICTITIOUS_OUTLET_SUCCESS
} from './classifiersActionTypes';
import { AnnulmentType } from './classifiersTypes';

export const fetchLegalFormTypesRequest = () => action(FETCH_LEGAL_FORM_TYPES_REQUEST);
export const fetchLegalFormTypesSuccess = (data: string) =>
  action(FETCH_LEGAL_FORM_TYPES_REQUEST_SUCCESS, data);
export const fetchLegalFormTypesError = (error: Error) =>
  action(FETCH_LEGAL_FORM_TYPES_REQUEST_ERROR, error);

export const fetchShareTypesRequest = () => action(FETCH_SHARE_TYPES_REQUEST);
export const fetchShareTypesSuccess = (data: string) =>
  action(FETCH_SHARE_TYPES_REQUEST_SUCCESS, data);
export const fetchShareTypesError = (error: Error) =>
  action(FETCH_SHARE_TYPES_REQUEST_ERROR, error);

export const fetchAnnulmentTypesRequest = () => action(FETCH_ANNULMENT_TYPES_REQUEST);
export const fetchAnnulmentTypesSuccess = (annulmentTypes: AnnulmentType[]) =>
  action(FETCH_ANNULMENT_TYPES_SUCCESS, annulmentTypes);
export const fetchAnnulmentTypesError = (error: Error) =>
  action(FETCH_ANNULMENT_TYPES_ERROR, error);

export const fetchEnterpriseTypesRequest = () => action(FETCH_ENTERPRISE_TYPES_REQUEST);
export const fetchEnterpriseTypesSuccess = (data: string) =>
  action(FETCH_ENTERPRISE_TYPES_SUCCESS, data);
export const fetchEnterpriseTypesError = (error: Error) =>
  action(FETCH_ENTERPRISE_TYPES_ERROR, error);

export const fetchDataDeletionPeriodRequest = () => action(FETCH_DATA_DELETION_PERIOD_REQUEST);
export const fetchDataDeletionPeriodSuccess = (data: string) =>
  action(FETCH_DATA_DELETION_PERIOD_SUCCESS, data);
export const fetchDataDeletionPeriodError = (error: Error) =>
  action(FETCH_DATA_DELETION_PERIOD_ERROR, error);

export const fetchFictitiousOutletRequest = () => action(FETCH_FICTITIOUS_OUTLET_REQUEST);
export const fetchFictitiousOutletSuccess = (ficticiousOutlet: OutletShortData[]) =>
  action(FETCH_FICTITIOUS_OUTLET_SUCCESS, ficticiousOutlet);
export const fetchFictitiousOutletError = (error: Error) =>
  action(FETCH_FICTITIOUS_OUTLET_ERROR, error);
