import React, { useContext } from 'react';

import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import {
  FundsReceivedDataTableStateContext,
  FundsReceivedDataTableDispatchContext
} from './Context';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import {
  FundsReceivedDataTableHeaderType,
  FundsReceivedDataVirsTableField
} from './tableState/tableTypesAndActions';

interface Props {
  columnNames: FundsReceivedDataTableHeaderType[];
}

export const FundsReceivedDataTableCustomizationBar: React.FC<Props> = ({ columnNames }) => {
  const { dispatch: tableDispatch } = useContext(FundsReceivedDataTableDispatchContext);
  const { state: tableState } = useContext(FundsReceivedDataTableStateContext);

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: FundsReceivedDataVirsTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: FundsReceivedDataVirsTableField) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={tableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={tableState.order}
            sortBy={tableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={tableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
