import { createAction, EmptyActionCreator, PayloadAction } from 'typesafe-actions';
import { SortOrder } from '../../../../utils/tableTypes';
import { Pagination } from './reducer';

export type Actions<TData> = {
  toggleColumnVisibility: (
    field: keyof TData,
    visible: boolean
  ) => PayloadAction<
    'TOGGLE_COLUMN_VISIBILITY',
    {
      field: keyof TData;
      visible: boolean;
    }
  >;
  setFilterValue: (
    field: keyof TData,
    value?: string
  ) => PayloadAction<
    'SET_FILTER_VALUE',
    {
      field: keyof TData;
      value?: string;
    }
  >;
  resetFilter: EmptyActionCreator<'RESET_FILTER'>;
  setSort: (
    field: keyof TData,
    direction?: SortOrder
  ) => PayloadAction<
    'SET_SORT',
    {
      field?: keyof TData;
      direction?: SortOrder;
    }
  >;
  setShowFilters: (showFilters: boolean) => PayloadAction<
    'SET_SHOW_FILTERS',
    {
      showFilters: boolean;
    }
  >;
  setPagination: (pagination: Pagination) => PayloadAction<
    'SET_PAGINATION',
    {
      pagination: Pagination;
    }
  >;
  edit: (data: TData) => PayloadAction<
    'EDIT',
    {
      data: TData;
    }
  >;
  remove: (data: TData) => PayloadAction<
    'REMOVE',
    {
      data: TData;
    }
  >;
  create: EmptyActionCreator<'CREATE'>;
  download: (data: TData) => PayloadAction<
    'DOWNLOAD',
    {
      data: TData;
    }
  >;
};

export function getActions<TData>(): Actions<TData> {
  return {
    toggleColumnVisibility: createAction(
      'TOGGLE_COLUMN_VISIBILITY',
      (field: keyof TData, visible: boolean) => ({ field, visible })
    )(),
    setFilterValue: createAction('SET_FILTER_VALUE', (field: keyof TData, value?: string) => ({
      field,
      value
    }))(),
    setSort: createAction('SET_SORT', (field?: keyof TData, direction?: SortOrder) => ({
      field,
      direction
    }))(),
    setShowFilters: createAction('SET_SHOW_FILTERS', (showFilters: boolean) => ({ showFilters }))(),
    resetFilter: createAction('RESET_FILTER')(),
    setPagination: createAction('SET_PAGINATION', (pagination: Pagination) => ({ pagination }))(),
    edit: createAction('EDIT', (data: TData) => ({ data }))(),
    remove: createAction('REMOVE', (data: TData) => ({ data }))(),
    create: createAction('CREATE')(),
    download: createAction('DOWNLOAD', (data: TData) => ({ data }))()
  };
}
