import { Reducer } from 'redux';
import {
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST,
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_SUCCESS,
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_ERROR,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_SUCCESS,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_ERROR,
  RESET_REMOVE_FUNDS_RECEIVED_STATE,
  REMOVE_FUNDS_RECEIVED_ERROR,
  REMOVE_FUNDS_RECEIVED_SUCCESS,
  REMOVE_FUNDS_RECEIVED_REQUEST,
  UPDATE_FUNDS_RECEIVED_ERROR,
  UPDATE_FUNDS_RECEIVED_SUCCESS,
  UPDATE_FUNDS_RECEIVED_REQUEST,
  RESET_CREATE_FUNDS_RECEIVED_STATE,
  CREATE_FUNDS_RECEIVED_ERROR,
  CREATE_FUNDS_RECEIVED_SUCCESS,
  CREATE_FUNDS_RECEIVED_REQUEST,
  RESET_FUNDS_RECEIVED_UPDATE_STATE,
  RESET_ANNUL_FUNDS_RECEIVED_DATA_STATE,
  ANNUL_FUNDS_RECEIVED_DATA_ERROR,
  ANNUL_FUNDS_RECEIVED_DATA_REQUEST,
  ANNUL_FUNDS_RECEIVED_DATA_SUCCESS,
  FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST
} from './fundsReceivedActionTypes';
import {
  FundsReceivedDataLegal,
  FundsReceivedDataVirs,
  TransactionType
} from './fundsReceivedDataTypes';

export interface FundsReceivedDataState {
  fundsReceivedDataLegal?: FundsReceivedDataLegal[];
  loadingFundsReceivedDataLegal: boolean;
  fundsReceivedDataErrorLegal?: Error;
  transactionTypes?: TransactionType[];
  fundsReceivedDataVirs?: FundsReceivedDataVirs[];
  loadingFundsReceivedDataVirs: boolean;
  fundsReceivedDataErrorVirs?: Error;
  fundsReceivedDataVirsRecordCount?: number;
  creatingFundsReceived: boolean;
  isFundsReceivedCreated: boolean;
  creatingFundsReceivedError?: Error;
  updatingFundsReceived: boolean;
  isFundsReceivedUpdated: boolean;
  updatingFundsReceivedError?: Error;
  removingFundsReceived: boolean;
  isFundsReceivedRemoved: boolean;
  removingFundsReceivedError?: Error;
  annullingFundsReceivedRecord: boolean;
  annullingFundsReceivedError?: Error;
  annullingFundsReceivedSuccess: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  recordCount: number;
  instFilterOptions: undefined;
}

export const initialState: FundsReceivedDataState = {
  loadingFundsReceivedDataLegal: false,
  fundsReceivedDataLegal: undefined,
  fundsReceivedDataErrorLegal: undefined,
  transactionTypes: [],
  loadingFundsReceivedDataVirs: true,
  fundsReceivedDataVirs: undefined,
  fundsReceivedDataErrorVirs: undefined,
  fundsReceivedDataVirsRecordCount: 0,
  creatingFundsReceived: false,
  isFundsReceivedCreated: false,
  creatingFundsReceivedError: undefined,
  updatingFundsReceived: false,
  isFundsReceivedUpdated: false,
  updatingFundsReceivedError: undefined,
  removingFundsReceived: false,
  isFundsReceivedRemoved: false,
  removingFundsReceivedError: undefined,
  annullingFundsReceivedRecord: false,
  annullingFundsReceivedError: undefined,
  annullingFundsReceivedSuccess: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  recordCount: 0,
  instFilterOptions: undefined
};

export const fundsReceivedReducer: Reducer<FundsReceivedDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST:
      return {
        ...state,
        loadingFundsReceivedDataLegal: true
      };
    case FETCH_FUNDS_RECEIVED_DATA_LEGAL_SUCCESS:
      return {
        ...state,
        loadingFundsReceivedDataLegal: false,
        fundsReceivedDataLegal: action.payload.fundsReceivedData,
        transactionTypes: action.payload.transactionTypes,
        fundsReceivedDataErrorLegal: undefined,
        statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
        statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
        statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
        recordCount: action.payload.recordCount,
        instFilterOptions: action.payload.filterValues
      };
    case FETCH_FUNDS_RECEIVED_DATA_LEGAL_ERROR:
      return {
        ...state,
        loadingFundsReceivedDataLegal: false,
        fundsReceivedDataErrorLegal: action.payload
      };
    case FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST:
      return {
        ...state,
        loadingFundsReceivedDataVirs: true
      };
    case FETCH_FUNDS_RECEIVED_DATA_VIRS_SUCCESS:
      return {
        ...state,
        loadingFundsReceivedDataVirs: false,
        fundsReceivedDataVirs: action.payload.fundsReceived,
        fundsReceivedDataVirsRecordCount: action.payload.fundsReceived.length
      };
    case FETCH_FUNDS_RECEIVED_DATA_VIRS_ERROR:
      return {
        ...state,
        loadingFundsReceivedDataVirs: false,
        fundsReceivedDataErrorVirs: action.payload.data
      };
    case CREATE_FUNDS_RECEIVED_REQUEST:
      return {
        ...state,
        creatingFundsReceived: true
      };
    case CREATE_FUNDS_RECEIVED_SUCCESS:
      return {
        ...state,
        creatingFundsReceived: false,
        isFundsReceivedCreated: true
      };
    case CREATE_FUNDS_RECEIVED_ERROR:
      return {
        ...state,
        creatingFundsReceived: false,
        isFundsReceivedCreated: false,
        creatingFundsReceivedError: action?.payload?.data
      };
    case RESET_CREATE_FUNDS_RECEIVED_STATE:
      return {
        ...state,
        creatingFundsReceived: false,
        isFundsReceivedCreated: false,
        creatingFundsReceivedError: undefined
      };
    case UPDATE_FUNDS_RECEIVED_REQUEST:
      return {
        ...state,
        updatingFundsReceived: true
      };
    case UPDATE_FUNDS_RECEIVED_SUCCESS:
      return {
        ...state,
        updatingFundsReceived: false,
        isFundsReceivedUpdated: true
      };
    case UPDATE_FUNDS_RECEIVED_ERROR:
      return {
        ...state,
        updatingFundsReceived: false,
        isFundsReceivedUpdated: false,
        updatingFundsReceivedError: action?.payload?.data
      };
    case RESET_FUNDS_RECEIVED_UPDATE_STATE:
      return {
        ...state,
        updatingFundsReceived: false,
        isFundsReceivedUpdated: false,
        updatingFundsReceivedError: undefined
      };
    case REMOVE_FUNDS_RECEIVED_REQUEST:
      return {
        ...state,
        removingFundsReceived: true
      };
    case REMOVE_FUNDS_RECEIVED_SUCCESS:
      return {
        ...state,
        removingFundsReceived: false,
        isFundsReceivedRemoved: true
      };
    case REMOVE_FUNDS_RECEIVED_ERROR:
      return {
        ...state,
        removingFundsReceived: false,
        isFundsReceivedRemoved: false,
        removingFundsReceivedError: action?.payload?.data
      };
    case RESET_REMOVE_FUNDS_RECEIVED_STATE:
      return {
        ...state,
        removingFundsReceived: false,
        isFundsReceivedRemoved: false,
        removingFundsReceivedError: undefined
      };
    case ANNUL_FUNDS_RECEIVED_DATA_REQUEST:
      return {
        ...state,
        annullingFundsReceivedRecord: true
      };
    case ANNUL_FUNDS_RECEIVED_DATA_SUCCESS:
      return {
        ...state,
        annullingFundsReceivedRecord: false,
        annullingFundsReceivedSuccess: true
      };
    case ANNUL_FUNDS_RECEIVED_DATA_ERROR:
      return {
        ...state,
        annullingFundsReceivedRecord: false,
        annullingFundsReceivedSuccess: false,
        annullingFundsReceivedError: action?.payload?.data
      };
    case RESET_ANNUL_FUNDS_RECEIVED_DATA_STATE:
      return {
        ...state,
        annullingFundsReceivedRecord: false,
        annullingFundsReceivedSuccess: false,
        annullingFundsReceivedError: undefined
      };
    case FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST:
      return {
        ...state,
        loadingFundsReceivedDataLegal: true
      };

    default:
      return state;
  }
};
