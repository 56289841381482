import React from 'react';
import { useSelector } from 'react-redux';
import { EditionColumnHeader, EditionColumnHeaderField } from '../tablesState/editionTableTypes';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../../components/TableStatusFilters/TableStatusFilters';
import { ApplicationState } from '../../../store';
import { getSubsystem } from '../../../utils/roleHelper';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { filterEditionColumnsByPeriodicOrNotPeriodic } from '../tablesState/tablesStateFunctions';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';

interface Props {
  columnNames: EditionColumnHeader[];
  tableName: OutletTableName;
}

const EditionCustomizationBar: React.FC<Props> = ({ columnNames, tableName }) => {
  const {
    state: { editions: editionsTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();
  const { currentUser } = useSelector((appState: ApplicationState) => appState.virsis);

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED', table: tableName });
  };

  const handleSetSortByColumn = (column: EditionColumnHeaderField) => {
    tableDispatch({ type: 'EDITION_SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: EditionColumnHeaderField) => {
    tableDispatch({ type: 'EDITION_COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    tableDispatch({ type: 'SHOW_STATUS_UNSIGNED_TOGGLED', table: tableName });
  };

  const toggleRelevantFilter = () => {
    tableDispatch({ type: 'SHOW_STATUS_RELEVANT_TOGGLED', table: tableName });
  };

  const toggleOutdatedFilter = () => {
    tableDispatch({ type: 'SHOW_STATUS_OUTDATED_TOGGLED', table: tableName });
  };

  const subsystem = getSubsystem(currentUser);

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            notSignedFilterDisabled={!editionsTableState.statusNotSignedFilterEnabled}
            notSignedFilterOn={editionsTableState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            outdatedFilterDisabled={!editionsTableState.statusOutdatedFilterEnabled}
            outdatedFilterOn={editionsTableState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
            relevantFilterDisabled={!editionsTableState.statusRelevantFilterEnabled}
            relevantFilterOn={editionsTableState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            dontShowNotSigned={subsystem === 'LRTK' || subsystem === 'ZEIT'}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={filterEditionColumnsByPeriodicOrNotPeriodic(
              columnNames,
              editionsTableState.periodic
            )}
            showFilter={editionsTableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={editionsTableState.order}
            sortBy={editionsTableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={editionsTableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default EditionCustomizationBar;
