export const FETCH_USER_MESSAGES_REQUEST = '@userMessages/USER_MESSAGES_REQUEST';
export const FETCH_USER_MESSAGES_SUCCESS = '@userMessages/FETCH_USER_MESSAGES_SUCCESS';
export const FETCH_USER_MESSAGES_ERROR = '@userMessages/FETCH_USER_MESSAGES_ERROR';

export const REMOVE_MESSAGES_REQUEST = '@userMessages/REMOVE_MESSAGES_REQUEST';
export const REMOVE_MESSAGES_SUCCESS = '@userMessages/REMOVE_MESSAGES_SUCCESS';
export const REMOVE_MESSAGES_ERROR = '@userMessages/REMOVE_MESSAGES_ERROR';
export const RESET_REMOVE_MESSAGES_STATE = '@userMessages/RESET_REMOVE_MESSAGES_STATE';

export const MARK_MESSAGES_READ_REQUEST = '@userMessages/MARK_MESSAGES_READ_REQUEST';
export const MARK_MESSAGES_READ_SUCCESS = '@userMessages/MARK_MESSAGES_READ_SUCCESS';
export const MARK_MESSAGES_READ_ERROR = '@userMessages/MARK_MESSAGES_READ_ERROR';
export const RESET_MARK_MESSAGES_READ_STATE = '@userMessages/RESET_MARK_MESSAGES_READ_STATE';

export const MARK_MESSAGES_UNREAD_REQUEST = '@userMessages/MARK_MESSAGES_UNREAD_REQUEST';
export const MARK_MESSAGES_UNREAD_SUCCESS = '@userMessages/MARK_MESSAGES_UNREAD_SUCCESS';
export const MARK_MESSAGES_UNREAD_ERROR = '@userMessages/MARK_MESSAGES_UNREAD_ERROR';
export const RESET_MARK_MESSAGES_UNREAD_STATE = '@userMessages/RESET_MARK_MESSAGES_UNREAD_STATE';
