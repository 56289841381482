import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FindPersonPage } from './FindPerson/FindPersonPage';

import { ApplicationState } from '../../store';
import { fetchJarCountriesRequest } from '../../store/virsis/actions';
import { resetPersonRepresentative } from '../../store/persons/personsActions';
import {
  resetLegalRepresentative,
  resetLegalSearchQuery
} from '../../store/legalPersonData/legalDataActions';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../Dialogs/Dialog/CloseButton';
import { FindLegalPage } from './FindLegal/FindLegalPage';
import { CloseContinueButton } from '../Dialogs/Dialog/CloseContinueButton';

interface Props {
  dialogOpen: boolean;
  closeDialog: () => void;
  closeAndContinueAction: () => void;
  checkIfPersonCanBeSelected?: (
    legalFormCode: number,
    personType: string,
    personTypeCode: number
  ) => boolean;
  tooltipTextOnToggleButton?: string;
}

const useStyle = makeStyles((theme) => ({
  active: {
    fontWeight: 'bold'
  },
  inactive: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  marginTop: {
    marginTop: '10px'
  },
  center: {
    margin: 'auto 0'
  },
  buttonWidth: {
    width: '124px'
  },
  backgroundWhite: { background: '#F3F3F3' }
}));

export const FindByPersonOrLegalDialogContainer: React.FC<Props> = ({
  dialogOpen,
  closeDialog,
  closeAndContinueAction,
  checkIfPersonCanBeSelected,
  tooltipTextOnToggleButton
}) => {
  const reduxDispatch = useDispatch();
  const classes = useStyle();

  const [tabDisplayStatus, setTabDisplayStatus] = useState({
    natural: true,
    legal: false
  });

  const { jarCountries } = useSelector((state: ApplicationState) => state.virsis);

  const handleDisplayTab = (filterName: string) => {
    setTabDisplayStatus({
      ...tabDisplayStatus,
      natural: false,
      legal: false,
      [filterName]: true
    });
  };

  const { selectedPersonRepresentative, selectedLegalRepresentative } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  useEffect(() => {
    if (!jarCountries) {
      reduxDispatch(fetchJarCountriesRequest());
    }
  }, [jarCountries, reduxDispatch]);

  function closeNewRepDialogAndGoBack() {
    closeDialog();
    reduxDispatch(resetLegalSearchQuery());
    reduxDispatch(resetPersonRepresentative());
    reduxDispatch(resetLegalRepresentative());
  }

  function closeNewRepDialogAndContinue() {
    closeDialog();
    closeAndContinueAction();
    reduxDispatch(resetLegalSearchQuery());
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      onExit={closeDialog}
      fullWidth
      maxWidth="lg"
      disableBackdropClick
    >
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="subtitle1">Asmenų paieška</Typography>
        <CustomCloseIconButton onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <div className="dialog-main-content">
          <Divider />
          <Box display="flex">
            <Button
              className={!tabDisplayStatus.natural ? classes.active : classes.inactive}
              onClick={() => handleDisplayTab('natural')}
            >
              Fizinis asmuo
            </Button>
            <Button
              className={!tabDisplayStatus.legal ? classes.active : classes.inactive}
              onClick={() => handleDisplayTab('legal')}
            >
              Juridinis asmuo
            </Button>
          </Box>
          {tabDisplayStatus.natural && <FindPersonPage />}
          {tabDisplayStatus.legal && (
            <FindLegalPage
              checkIfPersonCanBeSelected={checkIfPersonCanBeSelected}
              tooltipTextOnToggleButton={tooltipTextOnToggleButton}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" m={1}>
          <CloseDialogButton label="Atgal" onClick={closeNewRepDialogAndGoBack} />
        </Box>
        <Box right={30} component="span" m={1}>
          <CloseContinueButton
            onClick={closeNewRepDialogAndContinue}
            disabled={!selectedPersonRepresentative && !selectedLegalRepresentative}
            withIcon={true}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
