import { Reducer } from 'redux';
import {
  VirsisUser,
  AccountType,
  VirsData,
  AgreementFile,
  LegalData,
  VirsSearchData,
  VirsDataEditorInfo
} from './dataTypes';
import {
  LOGIN,
  LOGOUT_REQUEST,
  AUTHORISE_REQUEST,
  AUTHORISE_REQUEST_SUCCESS,
  AUTHORISE_REQUEST_ERROR,
  FETCH_AGREEMENT_DATE_REQUEST,
  FETCH_AGREEMENT_DATE_SUCCESS,
  FETCH_AGREEMENT_DATE_ERROR,
  FETCH_USER_CONTACT_EMAIL_REQUEST,
  FETCH_USER_CONTACT_EMAIL_SUCCESS,
  FETCH_USER_CONTACT_EMAIL_ERROR,
  SET_ACCOUNT_TYPE,
  FETCH_VIRS_DATA_SUCCESS,
  FETCH_VIRS_DATA_REQUEST,
  FETCH_VIRS_DATA_ERROR,
  FETCH_JAR_CONTACT_EMAIL_REQUEST,
  FETCH_JAR_CONTACT_EMAIL_SUCCESS,
  FETCH_JAR_CONTACT_EMAIL_ERROR,
  LOAD_VIRS_CONTEXT_PAGE,
  SAVE_CONTACT_EMAIL_REQUEST,
  SAVE_CONTACT_EMAIL_SUCCESS,
  SAVE_CONTACT_EMAIL_ERROR,
  LOGOUT_SUCCESS,
  OPEN_REPRESENTATION_TYPE_SELECT_MODAL,
  SET_AGREEMENT_VERSION,
  ACCEPT_AGREEMENT_REQUEST,
  ACCEPT_AGREEMENT_SUCCESS,
  ACCEPT_AGREEMENT_ERROR,
  FETCH_AGREEMENT_FILE,
  FETCH_AGREEMENT_FILE_SUCCESS,
  FETCH_AGREEMENT_FILE_ERROR,
  OPEN_AGREEMENT_DIALOG,
  OPEN_CONTACT_EMAIL_DIALOG,
  START_VIRSIS_FLOW_ERROR,
  LOGOUT_ERROR,
  CLOSE_CONTACT_EMAIL_DIALOG,
  OPEN_EDIT_CONTACT_EMAIL_DIALOG,
  EXIT_EMAIL_DIALOG,
  EXIT_REPRESENTATION_TYPE_SELECT_DIALOG,
  EXIT_AGREEMENT_DIALOG,
  SUBMIT_VIRS_DATA_REQUEST,
  SUBMIT_VIRS_DATA_SUCCESS,
  SUBMIT_VIRS_DATA_ERROR,
  SUBMIT_VIRS_DATA_RESET,
  SEARCH_VIRS_SUCCESS,
  SEARCH_VIRS_REQUEST,
  SEARCH_VIRS_ERROR,
  RESET_SEARCH_VIRS_STATE,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  APPLICATION_LOADING,
  APPLICATION_LOADING_STOP,
  RESET_VIRS_DATA_REQUEST,
  FETCH_JAR_COUNTRIES_SUCCESS,
  SET_PERSON_ID,
  FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST,
  FETCH_VIRS_DATA_BY_VIRS_ID_SUCCESS,
  FETCH_VIRS_DATA_BY_VIRS_ID_ERROR,
  SET_VIRS_ID,
  CLOSE_UNSIGNED_ALERT,
  SEARCH_VIRS_ACTIVE_ONLY_REQUEST,
  REGISTER_ACCOUNT_TYPE_CHOSEN,
  REGISTER_ACCOUNT_TYPE_CHOSEN_SUCCESS,
  REGISTER_ACCOUNT_TYPE_CHOSEN_ERROR,
  CLOSE_DATA_EDITING_ALERT,
  FETCH_DATA_EDITOR_SUCCESS,
  RESET_PERSON_ID,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_SUCCESS,
  FETCH_SELECTED_VIRS_CONTACT_EMAIL_ERROR
} from './actionTypes';
import { NaturalPersonSearchData } from '../persons/personsTypes';
import { LegalPersonSearchData } from '../legalPersonData/legalDataTypes';
import { JarCountry } from '../classifiers/classifiersTypes';
import {
  RESET_LEGAL_REPRESENTATIVE,
  SELECT_LEGAL_REPRESENTATIVE
} from '../legalPersonData/legalDataActionTypes';
import {
  RESET_PERSON_REPRESENTATIVE,
  SELECT_PERSON_REPRESENTATIVE
} from '../persons/personsActionTypes';

export interface VirsisState {
  loading: boolean;
  data?: string;
  error?: Error;
  currentUser?: VirsisUser;
  authorise: boolean;
  authoriseData?: string;
  authoriseSuccess: boolean;
  authoriseError?: Error;
  fetchUserLoading: boolean;
  fetchUserError?: Error;
  representationSelectDialogOpen: boolean;
  agreementDialogOpen: boolean;
  getAgreementDateLoading: boolean;
  agreementDate?: string;
  getAgreementDateError?: Error;
  acceptAgreementLoading: boolean;
  acceptAgreementError?: Error;
  emailDialogOpen: boolean;
  emailEditDialogOpen: boolean;
  contactEmail?: string;
  getContactEmailLoading: boolean;
  getContactEmailError?: Error;
  selectedAccountType?: AccountType;
  virsDataLoading: boolean;
  virsDataError?: Error;
  virsData?: VirsData;
  getJarContactEmailLoading: boolean;
  getJarEmailError?: Error;
  saveContactLoading: boolean;
  contactId?: number;
  saveContactError?: Error;
  logoutLoading: boolean;
  logoutSuccess: boolean;
  logoutError?: Error;
  agreementVersion?: string;
  getAgreementFileLoading: boolean;
  agreementFile?: AgreementFile;
  getAgreementFileError?: Error;
  legalData?: LegalData;
  startVirsisFlowError?: Error;
  submitVirsDataLoading: boolean;
  submitVirsDataSuccess: boolean;
  virsId?: number;
  isVirsEmailBeingEdited: boolean;
  submitVirsDataError?: Error;
  loadingVirsSearchResults: boolean;
  virsSearchResults?: VirsSearchData[];
  virsSearchResultsError?: string;
  loadingLegalPersonSearchResults: boolean;
  legalPersonSearchResults?: LegalData[];
  legalPersonSearchResultsError?: Error;
  selectedPersonRepresentative?: NaturalPersonSearchData;
  selectedLegalRepresentative?: LegalPersonSearchData;
  jarCountries?: JarCountry[];
  selectedPersonId?: number;
  selectedVirsId?: number;
  showUnsignedDataAlert: boolean;
  registeringAccountType: boolean;
  registeringAccountTypeError?: Error;
  dataEditorInfo?: VirsDataEditorInfo;
  selectedVirsContactEmail?: string;
}

export const initialState: VirsisState = {
  loading: false,
  data: undefined,
  error: undefined,
  currentUser: undefined,
  authorise: false,
  authoriseData: undefined,
  authoriseSuccess: false,
  authoriseError: undefined,
  fetchUserLoading: false,
  fetchUserError: undefined,
  representationSelectDialogOpen: false,
  agreementDialogOpen: false,
  getAgreementDateLoading: false,
  agreementDate: undefined,
  getAgreementDateError: undefined,
  acceptAgreementLoading: false,
  acceptAgreementError: undefined,
  emailDialogOpen: false,
  emailEditDialogOpen: false,
  contactEmail: undefined,
  getContactEmailLoading: false,
  getContactEmailError: undefined,
  selectedAccountType: undefined,
  virsDataLoading: false,
  virsDataError: undefined,
  virsData: undefined,
  getJarContactEmailLoading: false,
  getJarEmailError: undefined,
  saveContactLoading: false,
  contactId: undefined,
  saveContactError: undefined,
  logoutLoading: false,
  logoutSuccess: false,
  logoutError: undefined,
  agreementVersion: undefined,
  getAgreementFileLoading: false,
  agreementFile: undefined,
  getAgreementFileError: undefined,
  legalData: undefined,
  startVirsisFlowError: undefined,
  submitVirsDataLoading: false,
  submitVirsDataSuccess: false,
  virsId: undefined,
  isVirsEmailBeingEdited: false,
  submitVirsDataError: undefined,
  loadingVirsSearchResults: false,
  virsSearchResults: undefined,
  virsSearchResultsError: undefined,
  loadingLegalPersonSearchResults: false,
  legalPersonSearchResults: undefined,
  legalPersonSearchResultsError: undefined,
  selectedPersonRepresentative: undefined,
  selectedLegalRepresentative: undefined,
  jarCountries: undefined,
  selectedPersonId: undefined,
  showUnsignedDataAlert: true,
  registeringAccountType: false,
  registeringAccountTypeError: undefined,
  dataEditorInfo: undefined,
  selectedVirsContactEmail: undefined
};

const reducer: Reducer<VirsisState> = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return { ...state, currentUser: undefined };
    }
    case LOGOUT_REQUEST: {
      return { ...state, logoutLoading: true };
    }
    case LOGOUT_SUCCESS: {
      return { ...state, logoutLoading: false, logoutSuccess: true };
    }
    case LOGOUT_ERROR: {
      return { ...state, logoutError: action.payload };
    }
    case AUTHORISE_REQUEST: {
      return { ...state, authorise: true, authoriseData: action.payload };
    }
    case AUTHORISE_REQUEST_SUCCESS: {
      return {
        ...state,
        authorise: false,
        authoriseSuccess: true,
        currentUser: action.payload
      };
    }
    case APPLICATION_LOADING:
    case FETCH_USER_DATA_REQUEST: {
      return { ...state, fetchUserLoading: true };
    }
    case APPLICATION_LOADING_STOP:
    case FETCH_USER_DATA_SUCCESS: {
      return {
        ...state,
        fetchUserLoading: false
      };
    }
    case OPEN_REPRESENTATION_TYPE_SELECT_MODAL: {
      return { ...state, representationSelectDialogOpen: true };
    }
    case AUTHORISE_REQUEST_ERROR: {
      return {
        ...state,
        authorise: false,
        fetchUserLoading: false,
        fetchUserError: action.payload,
        authoriseError: action.payload,
        logoutSuccess: true
      };
    }
    case FETCH_AGREEMENT_DATE_REQUEST: {
      return {
        ...state,
        getAgreementDateLoading: true
      };
    }
    case FETCH_AGREEMENT_DATE_SUCCESS: {
      return {
        ...state,
        getAgreementDateLoading: false,
        agreementDate: action.payload
      };
    }
    case FETCH_AGREEMENT_DATE_ERROR: {
      return {
        ...state,
        getAgreementDateLoading: false,
        getAgreementDateError: action.payload
      };
    }
    case ACCEPT_AGREEMENT_REQUEST: {
      return { ...state, acceptAgreementLoading: true };
    }
    case ACCEPT_AGREEMENT_SUCCESS: {
      return {
        ...state,
        acceptAgreementLoading: false,
        agreementDialogOpen: false,
        agreementDate: action.payload
      };
    }
    case ACCEPT_AGREEMENT_ERROR: {
      return {
        ...state,
        acceptAgreementLoading: false,
        acceptAgreementError: action.payload
      };
    }
    case FETCH_USER_CONTACT_EMAIL_REQUEST: {
      return {
        ...state,
        getContactEmailLoading: true,
        contactEmail: undefined
      };
    }
    case FETCH_USER_CONTACT_EMAIL_SUCCESS: {
      return {
        ...state,
        getContactEmailLoading: false,
        contactEmail: action.payload
      };
    }
    case FETCH_USER_CONTACT_EMAIL_ERROR: {
      return {
        ...state,
        getContactEmailLoading: false,
        contactEmail: action.payload
      };
    }

    case FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST: {
      return {
        ...state,
        getContactEmailLoading: true,
        selectedVirsContactEmail: undefined
      };
    }
    case FETCH_SELECTED_VIRS_CONTACT_EMAIL_SUCCESS: {
      return {
        ...state,
        getContactEmailLoading: false,
        selectedVirsContactEmail: action.payload
      };
    }
    case FETCH_SELECTED_VIRS_CONTACT_EMAIL_ERROR: {
      return {
        ...state,
        getContactEmailLoading: false,
        selectedVirsContactEmail: action.payload
      };
    }

    case SET_ACCOUNT_TYPE: {
      return {
        ...state,
        selectedAccountType: action.payload,
        representationSelectDialogOpen: false
      };
    }
    case RESET_VIRS_DATA_REQUEST: {
      return { ...state, virsData: undefined, virsDataError: undefined };
    }
    case FETCH_VIRS_DATA_REQUEST: {
      return { ...state, virsDataLoading: true };
    }
    case FETCH_VIRS_DATA_SUCCESS: {
      return { ...state, virsData: action.payload, virsDataLoading: false };
    }
    case FETCH_VIRS_DATA_ERROR: {
      return {
        ...state,
        virsDataLoading: false,
        virsDataError: action.payload
      };
    }
    case FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST: {
      return { ...state, virsDataLoading: true };
    }
    case FETCH_VIRS_DATA_BY_VIRS_ID_SUCCESS: {
      return { ...state, virsData: action.payload, virsDataLoading: false };
    }
    case FETCH_VIRS_DATA_BY_VIRS_ID_ERROR: {
      return {
        ...state,
        virsDataLoading: false,
        virsDataError: action.payload
      };
    }
    case FETCH_JAR_CONTACT_EMAIL_REQUEST: {
      return {
        ...state,
        getJarContactEmailLoading: true,
        contactEmail: undefined
      };
    }
    case FETCH_JAR_CONTACT_EMAIL_SUCCESS: {
      return {
        ...state,
        getJarContactEmailLoading: false,
        contactEmail: action.payload
      };
    }
    case FETCH_JAR_CONTACT_EMAIL_ERROR: {
      return {
        ...state,
        getJarContactEmailLoading: false,
        getJarEmailError: action.payload
      };
    }
    case LOAD_VIRS_CONTEXT_PAGE: {
      return { ...state, virsData: action.payload };
    }
    case SAVE_CONTACT_EMAIL_REQUEST: {
      return { ...state, saveContactLoading: true };
    }
    case SAVE_CONTACT_EMAIL_SUCCESS: {
      return {
        ...state,
        saveContactLoading: false,
        contactId: action.payload.contId,
        emailDialogOpen: false,
        emailEditDialogOpen: false,
        isVirsEmailBeingEdited: false
      };
    }
    case SAVE_CONTACT_EMAIL_ERROR: {
      return {
        ...state,
        saveContactLoading: false,
        saveContactError: action.payload
      };
    }
    case SET_AGREEMENT_VERSION: {
      return { ...state, agreementVersion: action.payload };
    }
    case FETCH_AGREEMENT_FILE: {
      return { ...state, getAgreementFileLoading: true };
    }
    case FETCH_AGREEMENT_FILE_SUCCESS: {
      return {
        ...state,
        getAgreementFileLoading: false,
        agreementFile: action.payload
      };
    }
    case FETCH_AGREEMENT_FILE_ERROR: {
      return {
        ...state,
        getAgreementFileLoading: false,
        getAgreementFileError: action.payload
      };
    }
    case OPEN_AGREEMENT_DIALOG: {
      return { ...state, agreementDialogOpen: true };
    }
    case OPEN_CONTACT_EMAIL_DIALOG: {
      return { ...state, emailDialogOpen: true };
    }
    case CLOSE_CONTACT_EMAIL_DIALOG: {
      return { ...state, emailEditDialogOpen: false, isVirsEmailBeingEdited: false };
    }
    case OPEN_EDIT_CONTACT_EMAIL_DIALOG: {
      return { ...state, emailEditDialogOpen: true, isVirsEmailBeingEdited: action.payload };
    }
    case START_VIRSIS_FLOW_ERROR: {
      return { ...state, startVirsisFlowError: action.payload };
    }
    case EXIT_EMAIL_DIALOG: {
      return { ...state, emailDialogOpen: false };
    }
    case EXIT_REPRESENTATION_TYPE_SELECT_DIALOG: {
      return { ...state, representationSelectDialogOpen: false };
    }
    case EXIT_AGREEMENT_DIALOG: {
      return { ...state, agreementDialogOpen: false };
    }
    case SUBMIT_VIRS_DATA_REQUEST: {
      return { ...state, submitVirsDataLoading: true };
    }
    case SUBMIT_VIRS_DATA_SUCCESS: {
      return {
        ...state,
        virsId: action.payload,
        submitVirsDataLoading: false,
        submitVirsDataSuccess: true
      };
    }
    case SUBMIT_VIRS_DATA_ERROR: {
      return {
        ...state,
        error: action.payload,
        submitVirsDataLoading: false,
        submitVirsDataSuccess: false
      };
    }
    case SUBMIT_VIRS_DATA_RESET: {
      return {
        ...state,
        error: undefined,
        submitVirsDataLoading: false,
        submitVirsDataSuccess: false
      };
    }
    case SEARCH_VIRS_REQUEST:
      return { ...state, loadingVirsSearchResults: true };
    case SEARCH_VIRS_ACTIVE_ONLY_REQUEST:
      return { ...state, loadingVirsSearchResults: true };
    case SEARCH_VIRS_SUCCESS:
      return {
        ...state,
        loadingVirsSearchResults: false,
        virsSearchResults: action.payload.virsSearchData
      };
    case SEARCH_VIRS_ERROR:
      return {
        ...state,
        loadingVirsSearchResults: false,
        virsSearchResultsError: action.payload
      };
    case RESET_SEARCH_VIRS_STATE:
      return {
        ...state,
        loadingVirsSearchResults: false,
        virsSearchResults: undefined,
        virsSearchResultsError: undefined
      };
    case SELECT_PERSON_REPRESENTATIVE:
      return {
        ...state,
        selectedPersonRepresentative: action.payload,
        selectedLegalRepresentative: undefined
      };
    case RESET_PERSON_REPRESENTATIVE:
      return {
        ...state,
        selectedPersonRepresentative: undefined
      };
    case SELECT_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        selectedLegalRepresentative: action.payload,
        selectedPersonRepresentative: undefined
      };
    case RESET_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        selectedLegalRepresentative: action.payload
      };
    case FETCH_JAR_COUNTRIES_SUCCESS:
      return {
        ...state,
        jarCountries: action.payload?.sort((optionA: JarCountry, optionB: JarCountry) =>
          new Intl.Collator('lt').compare(optionA.countryName, optionB.countryName)
        )
      };
    case SET_PERSON_ID:
      return {
        ...state,
        selectedPersonId: action.payload
      };
    case RESET_PERSON_ID:
      return {
        ...state,
        selectedPersonId: undefined
      };
    case SET_VIRS_ID:
      return {
        ...state,
        selectedVirsId: action.payload
      };
    case CLOSE_UNSIGNED_ALERT:
      return {
        ...state,
        showUnsignedDataAlert: false
      };
    case CLOSE_DATA_EDITING_ALERT:
      return {
        ...state,
        showDataEditingAlert: false
      };
    case FETCH_DATA_EDITOR_SUCCESS:
      return {
        ...state,
        dataEditorInfo: action.payload
      };
    case REGISTER_ACCOUNT_TYPE_CHOSEN: {
      return { ...state, registeringAccountType: true };
    }
    case REGISTER_ACCOUNT_TYPE_CHOSEN_SUCCESS: {
      return {
        ...state,
        registeringAccountType: false,
        registeringAccountTypeError: undefined
      };
    }
    case REGISTER_ACCOUNT_TYPE_CHOSEN_ERROR: {
      return {
        ...state,
        registeringAccountType: false,
        registeringAccountTypeError: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as virsisReducer };
