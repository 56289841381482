import { createStore } from 'redux';
import { TableDefinition } from '../types';
import { getActions } from './actions';
import { getReducer } from './reducer';

export function getStore<TData>(tableDefinition: TableDefinition<TData>) {
  const actions = getActions<TData>();
  const reducer = getReducer<TData>(tableDefinition, actions);

  const store = createStore(reducer);
  return { store, actions };
}
