import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ColumnHeader } from '../../../utils/tableTypes';
import { EditionColumnHeaderField } from '../tablesState/editionTableTypes';
import { OutletEdition } from '../../../store/outletInfoData/outletInfoDataTypes';
import { ApplicationState } from '../../../store';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { TableCustomFilterCellGeneric } from '../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: ColumnHeader<EditionColumnHeaderField>[];
}

const FilterRow: React.FC<Props> = ({ columnNames }) => {
  const {
    state: { editions: editionsTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const handleSetCustomFilter = (column: EditionColumnHeaderField, value: string | null) => {
    tableDispatch({
      type: 'EDITION_CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };

  const allData =
    useSelector((state: ApplicationState) => state.outletInfoData.outletInfo?.editions) || [];

  function getUniqueOptions(name: EditionColumnHeaderField, data: OutletEdition[]) {
    if (data) return Array.from(new Set(data.map((item) => String(item[name]))));
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => {
            if (editionsTableState.periodic) {
              return column.id !== 'editionDate';
            }
            return column.id !== 'periodName';
          })
          .filter((column) => editionsTableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FilterRow;
