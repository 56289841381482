import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { whiteColor } from '../../containers/ShareholdersTable/constants';

interface Props {
  onClick?: () => void;
  text: string;
  showIcon?: boolean;
  secondary?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      borderColor: 'transparent',
      backgroundColor: '#949494',
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)'
    },
    themeButton: {
      '&:hover': {
        backgroundColor: theme.palette.info.dark
      },
      '&:active': {
        backgroundColor: theme.palette.info.dark
      },
      '&:disabled': {
        backgroundColor: theme.palette.info.main
      }
    },
    text: {
      color: whiteColor
    }
  })
);

const SignDocumentButton: React.FC<Props> = ({ onClick, showIcon, text, secondary }) => {
  const classes = useStyles();

  const getButtonStyle = () => {
    if (secondary) {
      return `${classes.button} ${classes.themeButton}`;
    }
    return undefined;
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={onClick} className={getButtonStyle()}>
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
        {showIcon && <ChevronRightIcon />}
      </Button>
    </>
  );
};
export default SignDocumentButton;
