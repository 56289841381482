import React, { ReactNode } from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { FundsReceivedDataLegal } from '../../../store/fundsReceived/fundsReceivedDataTypes';
import { formatStringOfNumber } from '../../../utils/tableDataFunctions';
import { FundsReceivedLegalTableColumnsDisplayStatus } from '../tableState/tableTypesAndActions';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedToAllExcept from '../../AllowedToAllExcept';

interface FundsReceivedDataTableRowProps {
  record: FundsReceivedDataLegal;
  display: FundsReceivedLegalTableColumnsDisplayStatus;
  actions?: ReactNode;
  elDocument?: ReactNode;
  openDocumentInNewTab?: () => void;
}

export const FundsReceivedLegalRow: React.FC<FundsReceivedDataTableRowProps> = ({
  record,
  actions,
  display,
  elDocument
}) => {
  return (
    <RowStyleOnDifferentStatus status={record.documentStatus.id}>
      {display.virsName && (
        <TooltipOnRowRibbon status={record.documentStatus.id}>
          <TableCell align="left">
            <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
              <VirsNameLink
                virsName={record.virsName}
                virsLegalCode={Number(record.virsLegalCode)}
                virsId={record.virsId}
              />
            </AllowedTo>
            <AllowedToAllExcept roles={[Roles.ROLE_VIRS_VIEW]}>
              <Typography>{record.virsName}</Typography>
              <Typography style={{ paddingTop: '5px' }}>{record.virsLegalCode}</Typography>
            </AllowedToAllExcept>
          </TableCell>
        </TooltipOnRowRibbon>
      )}
      {display.fundsReceivedYear && <TableCell align="left">{record.fundsReceivedYear}</TableCell>}
      {display.fundsReceivedSum && (
        <TableCell align="left">{formatStringOfNumber(record.fundsReceivedSum)}</TableCell>
      )}
      {display.fundsSourceName && <TableCell align="left">{record.fundsSourceName}</TableCell>}
      {display.fundsSourceCode && <TableCell align="left">{record.fundsSourceCode}</TableCell>}
      {display.fundsReceivedOutlets && (
        <TableCell align="left">
          {record.fundsReceivedOutlets.map((out) => (
            <div key={out.outletId}>{out.outletName}</div>
          ))}
        </TableCell>
      )}
      {display.transaction && (
        <TableCell align="left">{record.transaction.transactionTypeName}</TableCell>
      )}
      <TableCell align="left">{actions}</TableCell>
      <TableCell align="left">{elDocument}</TableCell>
    </RowStyleOnDifferentStatus>
  );
};
