import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MisconductInstTableWithContext } from '../../containers/ProfessionalMisconductTableInst/Context';

import { getProfessionalMisconductInstDataRequest } from '../../store/professionalMisconducts/professionalMisconductsActions';
import { ApplicationState } from '../../store';
import {
  fetchAnnulmentTypesRequest,
  fetchEnterpriseTypesRequest,
  fetchFictitiousOutletRequest
} from '../../store/classifiers/classifiersActions';

const InstProfessionalMisconductPage: React.FC = () => {
  const { annulmentTypes, enterpriseTypes, fictitiousOutlet } = useSelector(
    (state: ApplicationState) => state.classifiers
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(getProfessionalMisconductInstDataRequest());
  }, [reduxDispatch]);

  useEffect(() => {
    if (!enterpriseTypes) {
      reduxDispatch(fetchEnterpriseTypesRequest());
    }
  }, [reduxDispatch, enterpriseTypes]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  useEffect(() => {
    if (!fictitiousOutlet) {
      reduxDispatch(fetchFictitiousOutletRequest());
    }
  }, [reduxDispatch, fictitiousOutlet]);

  return (
    <div className="data-page">
      <div className="data-page-content">
        <MisconductInstTableWithContext />
      </div>
    </div>
  );
};

export default InstProfessionalMisconductPage;
