import { Reducer } from 'redux';
import { ExternalSystemData } from './externalSystemDataTypes';
import {
  FETCH_EXTERNAL_SYSTEM_DATA_REQUEST,
  FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_ERROR,
  FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_SUCCESS
} from './externalSystemDataActionTypes';

export interface ExternalSystemDataReducerState {
  loadingExternalSystemData: boolean;
  externalSystemData?: ExternalSystemData;
  externalSystemDataError?: Error;
}

export const initialState: ExternalSystemDataReducerState = {
  loadingExternalSystemData: false,
  externalSystemData: {
    individualPersonData: [],
    legalEntityData: [],
    politicalAdData: [],
    licensesData: []
  }
};

export const externalSystemDataReducer: Reducer<ExternalSystemDataReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_EXTERNAL_SYSTEM_DATA_REQUEST: {
      return { ...state, loadingExternalSystemData: true };
    }
    case FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingExternalSystemData: false,
        externalSystemData: {
          individualPersonData: action.payload.receivedNaturalPersonSupportData,
          legalEntityData: action.payload.receivedLegalPersonSupportData,
          politicalAdData: action.payload.electoralCommissionData,
          licensesData: action.payload.licensesData
        }
      };
    }
    case FETCH_EXTERNAL_SYSTEM_DATA_REQUEST_ERROR:
      return {
        ...state,
        loadingExternalSystemData: false,
        externalSystemDataError: action.payload
      };
    default: {
      return state;
    }
  }
};
