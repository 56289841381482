import * as Yup from 'yup';

export const validationNotFromClassifier = Yup.object().shape({
  sharePercentage: Yup.mixed().when(['endOfParticipation', 'mayHaveSharePercentage'], {
    is: (endOfParticipation, mayHaveSharePercentage) =>
      mayHaveSharePercentage && !endOfParticipation,
    then: Yup.number()
      .typeError('Privaloma įvesti procentų dalį')
      .required('Privaloma įvesti procentų dalį')
      .min(0.01, 'Procento reikšmė gali būti nuo 0,01 iki 100')
      .max(100, 'Procento reikšmė gali būti nuo 0,01 iki 100')
  }),
  votePercentage: Yup.mixed().when(
    [
      'endOfParticipation',
      'endOfParticipationFromBefore',
      'mayHaveSharePercentage',
      'mayHaveVotePercentage',
      'mayHaveVotesOnRules',
      'votesValueSelected'
    ],
    {
      is: (
        endOfParticipation,
        endOfParticipationFromBefore,
        mayHaveSharePercentage,
        mayHaveVotePercentage,
        mayHaveVotesOnRules,
        votesValueSelected
      ) =>
        !endOfParticipation &&
        !endOfParticipationFromBefore &&
        !mayHaveSharePercentage &&
        mayHaveVotePercentage &&
        mayHaveVotesOnRules &&
        !votesValueSelected,
      then: Yup.number()
        .typeError('Privaloma įvesti procentų dalį arba pažymėti "Balsai pagal taisyklę"')
        .required('Privaloma įvesti procentų dalį arba pažymėti "Balsai pagal taisyklę"')
        .min(0, 'Procento reikšmė gali būti nuo 0 iki 100')
        .max(100, 'Procento reikšmė gali būti nuo 0 iki 100')
    }
  ),
  votesOnRules: Yup.mixed().when(
    [
      'endOfParticipation',
      'mayHaveSharePercentage',
      'mayHaveVotePercentage',
      'mayHaveVotesOnRules',
      'votesValueSelected'
    ],
    {
      is: (
        endOfParticipation,
        endOfParticipationFromBefore,
        mayHaveSharePercentage,
        mayHaveVotePercentage,
        mayHaveVotesOnRules,
        votesValueSelected
      ) =>
        !endOfParticipation &&
        !endOfParticipationFromBefore &&
        !mayHaveSharePercentage &&
        mayHaveVotesOnRules &&
        mayHaveVotePercentage &&
        !votesValueSelected,
      then: Yup.bool().oneOf(
        [true],
        'Privaloma įvesti procentų dalį arba pažymėti "Balsai pagal taisyklę"'
      )
    }
  )
});
