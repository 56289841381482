import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';

import { resetForm, State, submitForm } from './store';
import { GenericTextField } from '../../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import { defaultValues, EditionPeriodInput } from './types';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<EditionPeriodInput>> = (formikProps) => {
  const {
    values: { periodId }
  } = formikProps;
  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{periodId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Tiražo laikotarpio pavadinimas Lt" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="periodName"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Tiražo laikotarpio pavadinimas En" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="periodNameEn"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Pradžios diena" spacing="1em">
        <GenericDateField
          label=""
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Pabaigos diena">
        <GenericDateField
          label=""
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const EditionPeriodForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error, data } = useSelector((state: State) => ({
    ...state.form,
    data: state.data
  }));

  const validationSchema = Yup.object().shape({
    periodId: noUndefinedNumber(),
    periodName: Yup.string()
      .nullable()
      .required('Būtina įvesti reikšmę')
      .max(200, 'Negali būti ilgesnis nei 200 simbolių')
      .notOneOf(
        data
          .map(({ periodName }) => periodName)
          .filter((value) => initialValues?.periodName !== value),
        'Privalo būti unikalus'
      ),
    periodNameEn: Yup.string()
      .nullable()
      .required('Būtina įvesti reikšmę')
      .max(200, 'Negali būti ilgesnis nei 200 simbolių')
      .notOneOf(
        data
          .map(({ periodNameEn }) => periodNameEn)
          .filter((value) => initialValues?.periodNameEn !== value),
        'Privalo būti unikalus'
      ),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="Tiražo laikotarpio įvedimas"
    />
  );
};
