import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ConfirmRemoveDialog } from '../Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import { RemoveDocumentButton } from '../TableButtons/RowActionButtons/RemoveDocumentButton';
import { DocumentActionButton } from '../TableButtons/RowActionButtons/DocumentActionButton';

export interface EditAndRemoveActionsProps {
  viewButton?: ReactNode;
  handleEditButtonClick: () => void;
  handleRemoveButtonClick?: () => void;
  confirmationOn: boolean;
  handleConfirmationYes: () => void;
  handleConfirmationNo: () => void;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: Error;
  onErrorClose: () => void;
  onSuccessClose?: () => void;
  editButtonLabel?: string;
  confirmationMessage?: string;
  errorMessage?: string;
  editWarningReason?: string;
  disabled?: boolean;
}

export const EditAndRemoveActions: React.FC<EditAndRemoveActionsProps> = ({
  viewButton,
  handleEditButtonClick,
  confirmationOn,
  handleConfirmationYes,
  handleConfirmationNo,
  isProcessing,
  isSuccess,
  error,
  onErrorClose,
  onSuccessClose,
  handleRemoveButtonClick,
  editButtonLabel,
  errorMessage,
  confirmationMessage,
  editWarningReason,
  disabled
}) => {
  return (
    <Box display="inline-block" width="120px">
      <ConfirmRemoveDialog
        open={confirmationOn}
        dialogTitle="Įrašo pašalinimas"
        dialogText={confirmationMessage || 'Ar tikrai norite pašalinti šį įrašą?'}
        mainButtonText="Pašalinti įrašą"
        onClose={handleConfirmationNo}
        onSubmit={handleConfirmationYes}
        isProcessing={isProcessing}
        errorMessage={errorMessage || error?.message}
        isError={!!error}
        isSuccess={isSuccess}
        onErrorClose={onErrorClose}
        onSuccessClose={onSuccessClose}
      />
      {viewButton && viewButton}
      <DocumentActionButton
        onClick={handleEditButtonClick}
        text={editButtonLabel || 'Redaguoti'}
        IconComponent={EditIcon}
        tooltipProps={{
          buttonBoxProps: { component: 'span', display: 'inline-flex' },
          tooltipProps: editWarningReason ? { text: editWarningReason } : undefined
        }}
        disabled={disabled}
      />

      <RemoveDocumentButton
        onClick={handleRemoveButtonClick}
        tooltipProps={{
          buttonBoxProps: { component: 'span', display: 'inline-flex' }
        }}
        disabled={disabled}
      />
    </Box>
  );
};
