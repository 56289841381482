import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const useStyles = makeStyles(() => ({
  addPersonButton: {
    padding: '0 16px 0 8px',
    margin: '8px 0 8px -10px',
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    boxSizing: 'border-box',
    '&:hover': {
      background: 'rgba(0, 111, 178, 0.04)'
    }
  }
}));

export interface Props {
  onAddPersonClick: () => void;
  text: string;
}

export const AddPersonButton: React.FC<Props> = ({ onAddPersonClick, text }) => {
  const classes = useStyles();

  return (
    <Button className={classes.addPersonButton} onClick={onAddPersonClick}>
      <AddRoundedIcon color="primary" fontSize="small" />
      <Typography color="primary" variant="h6">
        {text}
      </Typography>
    </Button>
  );
};
