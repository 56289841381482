import { CircularProgress, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import { DocumentActionButton } from '../../components/TableButtons/RowActionButtons/DocumentActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteArchivedDataRequest,
  fetchArchivedDataRequest,
  resetDeleteArchivedData
} from '../../store/audits/auditsActions';
import { useState } from 'react';
import { ApplicationState } from '../../store';
import { ConfirmRemoveDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '20px'
  },
  flexContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20
  },
  buttonContainer: {
    display: 'flex'
  },
  [theme.breakpoints.down('xs')]: {
    buttonContainer: {
      flexWrap: 'wrap',
      width: '100%',
      '& div': {
        width: '100%'
      },
      '& button': {
        width: '100%'
      }
    }
  }
}));

export const DeleteArchivedDataPage = () => {
  const classes = useStyles();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const {
    loadingArchivedData,
    loadingDeleteArchivedData,
    deletedArchivedData,
    deleteArchivedDataError
  } = useSelector((state: ApplicationState) => state.auditsData);
  const reduxDispatch = useDispatch();

  const downloadButtonLabel = loadingArchivedData ? <CircularProgress size={25} /> : 'Atsisiųsti';

  const onDownloadClick = () => {
    reduxDispatch(fetchArchivedDataRequest());
  };

  const onDeleteDataClick = () => {
    setShowConfirmationDialog(true);
  };

  const onConfirm = () => {
    reduxDispatch(deleteArchivedDataRequest());
  };

  const onCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
    reduxDispatch(resetDeleteArchivedData());
  };

  return (
    <>
      {showConfirmationDialog && (
        <ConfirmRemoveDialog
          dialogTitle="Archyvuotų duomenų naikinimas"
          dialogText="Ar tikrai norite sunaikinti archyvuotus duomenis? Sunaikinti duomenys bus pašalinti iš duomenų bazės be galimybės atkurti."
          mainButtonText="Sunaikinti duomenis"
          open={showConfirmationDialog}
          onClose={onCloseConfirmationDialog}
          onSubmit={onConfirm}
          isProcessing={loadingDeleteArchivedData}
          isSuccess={deletedArchivedData}
          isError={!!deleteArchivedDataError}
          processingMessage="Naikinama"
          errorMessage={`Klaida. ${deleteArchivedDataError?.message}`}
          onErrorClose={onCloseConfirmationDialog}
          onSuccessClose={onCloseConfirmationDialog}
        />
      )}
      <div className="data-page">
        <Container style={{ minHeight: '100%' }} maxWidth="lg">
          <div className="data-page-header">
            <Typography variant="h1">Archyvuotų duomenų naikinimas</Typography>
            <Divider />
          </div>
          <div className={`${classes.marginTop} data-page-content`}>
            <div className={classes.flexContainer}>
              <Typography variant="h3">Naikinimui tinkamų duomenų rinkinys:</Typography>
              <div className={classes.buttonContainer}>
                <DocumentActionButton
                  onClick={onDownloadClick}
                  IconComponent={GetAppIcon}
                  text={downloadButtonLabel}
                />
                <DocumentActionButton
                  onClick={onDeleteDataClick}
                  IconComponent={DeleteIcon}
                  text="Naikinti archyvuotus duomenis"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
