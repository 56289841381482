import moment from 'moment';
import {
  CategoryEditRowState,
  CategoryEditRowAction,
  initialCategoryEditRowState
} from './categoryInitialStateAndTypes';
import { validateEditCategoryState } from './categoryRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  validateSingleChoice
} from '../../../../../utils/InputValueFunctions';

export const categoryEditRowReducer = (
  state: CategoryEditRowState,
  action: CategoryEditRowAction
): CategoryEditRowState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return {
        ...state,
        editingOn: true,
        categoryType: {
          ...state.categoryType,
          options: [
            ...action.availableCategories,
            {
              categoryTypeId: action.record.categoryTypeId,
              categoryTypeName: action.record.categoryTypeName
            }
          ],
          value: {
            categoryTypeId: action.record.categoryTypeId,
            categoryTypeName: action.record.categoryTypeName
          },
          validated: true
        },
        validFrom: {
          ...state.validFrom,
          value: moment(action.record.validFrom),
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.controlledFrom),
              text: 'Data negali būti ankstesnė už VIP valdymo pradžios datą'
            },
            {
              id: 'previousCategoryValidFrom',
              date: action.previousCategoryValidFrom
                ? moment(action.previousCategoryValidFrom).subtract(1, 'days')
                : null,
              text: 'Data negali būti ankstesnė nei kito ankstesnio įrašo nuo data'
            },
            {
              id: 'previousCategoryValidTo',
              date: action.previousCategoryValidTo
                ? moment(action.previousCategoryValidTo).add(1, 'days')
                : null,
              text: 'Data turi būti vėlesnė nei ankstesnio įrašo iki data'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.controlledTo ? moment(action.controlledTo) : null,
              text: 'Data negali būti vėlesnė už VIP valdymo pabaigos datą'
            },
            {
              id: 'nextCategoryValidFrom',
              date: action.nextCategoryValidFrom
                ? moment(action.nextCategoryValidFrom).subtract(1, 'days')
                : null,
              text: 'Data negali būti vėlesnė nei kito vėlesnio įrašo nuo data'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'CATEGORY_TYPE_CHANGED':
      return {
        ...state,
        categoryType: validateSingleChoice(
          { ...state.categoryType, value: action.categoryType },
          'Reikia pasirinkti iš sąrašo'
        )
      };
    case 'VALID_FROM_DATE_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.date, state.validFrom)
      };
    case 'EDITING_CANCELLED':
      return initialCategoryEditRowState;
    case 'UPDATE_RECORD_CLICKED':
      return validateEditCategoryState(state);
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
