import { action } from 'typesafe-actions';
import {
  INIT_SIGN,
  RESET, SET_CANCEL_SUCCESS,
  SET_ERROR,
  SET_LOADING,
  SET_UNISIGN_DATA,
  SET_UNISIGN_STATUS,
  SET_VERIFY_SUCCESS,
  UnisignData,
  UnisignStatus,
  VERIFY_SIGN
} from './unisignTypes';

export const initSign = (path: string) => action(INIT_SIGN, path);

export const verifySign = (documentId: number) => action(VERIFY_SIGN, documentId);

export const setLoading = (isLoading: boolean) => action(SET_LOADING, isLoading);

export const setUnisignData = (unisignData?: UnisignData) => action(SET_UNISIGN_DATA, unisignData);

export const setError = (error?: string) => action(SET_ERROR, error);

export const setVerifySuccess = (isVerifySuccess?: boolean) =>
  action(SET_VERIFY_SUCCESS, isVerifySuccess);

export const setCancelSuccess = (isCancelSuccess?: boolean) =>
  action(SET_CANCEL_SUCCESS, isCancelSuccess);

export const setUnisignStatus = (status: UnisignStatus) => action(SET_UNISIGN_STATUS, status);

export const resetUnisign = () => action(RESET);
