import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import {
  ColumnHeaderField,
  ColumnsDisplayStatus,
  LicensesColumnHeader
} from '../tableState/tableTypes';
import { LicensesData } from '../../../../store/externalSystemData/externalSystemDataTypes';
import { TableCustomFilterCellGeneric } from '../../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnsDisplayStatus: ColumnsDisplayStatus;
  onCustomFilterChange: (column: ColumnHeaderField, value: string | null) => void;
  columnNames: LicensesColumnHeader[];
  pageData: LicensesData[];
}

const CustomFilter: React.FC<Props> = ({
  columnsDisplayStatus,
  onCustomFilterChange,
  columnNames,
  pageData
}) => {
  function getUniqueOptions(name: ColumnHeaderField, data: LicensesData[]) {
    if (data) {
      return Array.from(
        new Set(data.filter((item) => item[name] !== null).map((item) => item[name]?.toString()))
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={onCustomFilterChange}
              uniqueOptions={getUniqueOptions(column.id, pageData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default CustomFilter;
