import React, { ChangeEvent, useRef } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import { InputStateGeneric, VirsisFilesState } from '../../../utils/tableTypes';
import { EditionCheckDocument } from '../../../store/editionChecks/editionChecksTypes';
import GoInputDataButton from '../../TableButtons/GoInputDataButton';
import { CloseIconButton } from '../../TableButtons/RowActionButtons/CloseIconButton';
import { FileUnit } from './FileUnit';
import { BasicTooltip } from '../../Tooltips/BasicTooltip';

interface Props {
  state: VirsisFilesState;
  customName: InputStateGeneric<string>;
  editionCheckDocuments?: EditionCheckDocument[];
  removeEditionCheckDocument?: (id: number) => void;
  addFile: (inputFile: File) => void;
  removeFile: (id: number) => void;
  changeCustomName: (name: string) => void;
  setWrongFormatError: () => void;
}

export const EditionCheckFilesInput: React.FC<Props> = ({
  state,
  customName,
  editionCheckDocuments,
  removeEditionCheckDocument,
  addFile,
  removeFile,
  setWrongFormatError
}) => {
  const addFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const acceptableFormat = 'application/pdf';

    if (event.currentTarget.files) {
      if (event.currentTarget.files[0].type === acceptableFormat) {
        addFile(event.currentTarget.files[0] as File);
      } else {
        setWrongFormatError();
      }
    }
  };

  const newInputRef = useRef<any>(null);
  const generateName = (indexNo: number, name: string) =>
    indexNo === 0 ? name : `${name}-${indexNo + 1}`;

  return (
    <>
      {editionCheckDocuments?.map((document, index) => (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          key={document.editionCheckDocId}
        >
          <Grid item>
            <GoInputDataButton
              disabled
              text={customName.value ? generateName(index, customName.value) : document.docNr}
              style={{
                label: {
                  maxWidth: '100px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }
              }}
            />
          </Grid>
          <Grid item>
            {removeEditionCheckDocument && (
              <CloseIconButton
                onClick={() => removeEditionCheckDocument(document.editionCheckDocId)}
              />
            )}
          </Grid>
        </Grid>
      ))}
      {state.files.map((file, index) => (
        <div key={file.id}>
          <FileUnit
            file={file}
            changeFile={addFile}
            removeFile={removeFile}
            displayName={
              customName.value
                ? generateName(
                    editionCheckDocuments ? index + editionCheckDocuments.length : index,
                    customName.value
                  )
                : ''
            }
            sizeLimit={state.sizeLimit}
          />
        </div>
      ))}
      <BasicTooltip text="Galimas tik PDF formatas">
        <div key="new">
          <input
            type="file"
            style={{ visibility: 'hidden', display: 'none' }}
            placeholder="Prisegti dokumentą"
            ref={newInputRef}
            onChange={addFileHandler}
            accept=".pdf"
          />

          <GoInputDataButton
            onClicked={() => newInputRef.current?.click()}
            text="+Prisegti dokumentą"
          />

          <FormHelperText error={state.error}>{state.errorMessage}</FormHelperText>
        </div>
      </BasicTooltip>
    </>
  );
};
