import React, { ReactNode } from 'react';
import { TableCell } from '@material-ui/core/';
import { OutletCategoryColumnsDisplayStatus } from '../../tablesState/categoryTableTypes';
import { RowStyleOnDifferentStatus } from '../../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../../components/Tooltips/TooltipOnRowRibbon';

interface Props {
  id: string;
  columnsToDisplay: OutletCategoryColumnsDisplayStatus;
  categoryTypeName?: ReactNode;
  categoryTypeInput?: ReactNode;
  validFrom?: ReactNode;
  validFromInput?: ReactNode;
  validTo?: ReactNode;
  actions?: ReactNode;
  rowStatus?: string;
}

export const OutletCategoryBasicRow: React.FC<Props> = ({
  id,
  columnsToDisplay,
  categoryTypeName,
  categoryTypeInput,
  validFrom,
  validFromInput,
  validTo,
  actions,
  rowStatus
}) => {
  return (
    <RowStyleOnDifferentStatus id={id} status={rowStatus}>
      {columnsToDisplay.categoryTypeName && !categoryTypeInput && (
        <TooltipOnRowRibbon status={rowStatus}>
          <TableCell>{categoryTypeName}</TableCell>
        </TooltipOnRowRibbon>
      )}
      {columnsToDisplay.categoryTypeName && !categoryTypeName && categoryTypeInput}
      {columnsToDisplay.validFrom && !validFromInput && <TableCell>{validFrom} </TableCell>}
      {columnsToDisplay.validFrom && validFromInput && validFromInput}
      {columnsToDisplay.validTo && validTo}
      <TableCell align="right" scope="row">
        {actions}
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
