import * as React from 'react';

import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Store } from 'redux';
import { ThemeProvider } from '@material-ui/core/styles';

import { ApplicationState } from './store';
import './App.css';
import virsisTheme from './style/virsisTheme';
import LandingPage from './pages/LandingPage';

interface Props {
  store: Store<ApplicationState>;
}

const App: React.FC<Props> = ({ store }) => (
  <Provider store={store}>
    <div className="App">
      <ThemeProvider theme={virsisTheme}>
        <SnackbarProvider autoHideDuration={9000} maxSnack={4}>
          <LandingPage />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  </Provider>
);

export default App;
