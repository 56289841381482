import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import BottomPageActionButtons from '../../components/PageButtons/BottomPageActionButtons';
import ShareholdersTableContainer from '../../containers/ShareholdersTable/ShareholdersTableContainer';
import { ApplicationState } from '../../store';
import { getSubsystem, hasRoles } from '../../utils/roleHelper';
import { ShareholdersTableWithContext } from '../../containers/ShareholdersTable/MainTable/ShareholdersTableWithContext';
import { Roles } from '../../store/virsis/dataTypes';

const ShareholdersMainTablePage: React.FC = () => {
  const history = useHistory();
  const { currentUser, selectedVirsId } = useSelector((state: ApplicationState) => state.virsis);
  const isVirsis = getSubsystem(currentUser) === 'VIRSIS';

  const userCantEditData =
    currentUser && !hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]);
  const handleGoBackClick = () => {
    const path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones'
      : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones`;

    history.push(path);
  };
  const handleGoToPageClick = () => {
    let path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/el-dokumentai'
      : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/el-dokumentai`;
    if (userCantEditData) {
      path = isVirsis
        ? '/duomenu-perziura-ir-teikimas/veiklos-rusys'
        : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`;
    }
    history.push(path);
  };

  return (
    <>
      <ShareholdersTableContainer>
        <ShareholdersTableWithContext />
      </ShareholdersTableContainer>
      <BottomPageActionButtons
        handleGoToPageClick={handleGoToPageClick}
        handleGoBackClick={handleGoBackClick}
        pageToGo={userCantEditData ? 'Veiklos rušys' : 'El. dokumentai'}
      />
    </>
  );
};

export default ShareholdersMainTablePage;
