import React from 'react';
import { Popover } from '@material-ui/core';
import {
  ShareholdersProps,
  ShareholdersData,
  ShareholdersHistory,
  ShareholderPerson
} from '../../../../../store/shareholders/shareholdersTypes';
import { AddPercentageFormContainer } from './AddPercentageForm/AddPercentageFormContainer';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson;
  popoverId: string;
  shareholder: ShareholdersProps;
  shareholderEvent: ShareholdersHistory;
}

export const AddPercentageDialog: React.FC<Props> = ({
  anchorEl,
  onClose,
  open,
  parentShareholder,
  popoverId,
  shareholder,
  shareholderEvent
}) => (
  <Popover
    id={popoverId}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
  >
    <AddPercentageFormContainer
      parentShareholder={parentShareholder}
      onClose={onClose}
      shareholder={shareholder}
      shareholderEvent={shareholderEvent}
    />
  </Popover>
);
