import React from 'react';
import { Typography } from '@material-ui/core/';
import { determineLithuanianNumberDefinitionCase } from '../../utils/tableDataFunctions';

interface PaginationInfoProps {
  recordsCount: number;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({ recordsCount }) => {
  return (
    <div className="pagination-item">
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        {determineLithuanianNumberDefinitionCase(recordsCount)}
      </Typography>
    </div>
  );
};
