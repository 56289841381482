import { Handle, Position } from 'react-flow-renderer';
import { BoxTitle } from './TreeChartBoxes/BoxTitle';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import { virsBoxColor } from './treeChartFunctions/constants';

export const OutletComponent = ({ data }: any) => {
  const { outlet } = data;

  const handleOpenOutletCard = () => {};

  return (
    <div>
      <Handle type="source" position={Position.Right} style={{ background: virsBoxColor }} />
      <TreeSingleBox
        boxTitle={<BoxTitle title={outlet.outletName} subtitle={outlet.outletTypeName} />}
        color="#B8581B"
        minHeight={60}
        maxHeight={95}
        showLeftSideBox={false}
        showRightSideBox
        onClick={handleOpenOutletCard}
      />
    </div>
  );
};
