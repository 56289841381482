import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DocumentActionButton } from './DocumentActionButton';

interface Props {
  documentId: number;
  onClick: (documentId: number) => void;
}
export const DownloadDocumentButton: React.FC<Props> = ({ documentId, onClick }) => {
  return (
    <DocumentActionButton
      onClick={() => onClick(documentId)}
      IconComponent={GetAppIcon}
      text="Atsisiųsti"
    />
  );
};
