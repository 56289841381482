import { makeStyles, TableCell } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { LinkRouter } from '../../../../components/Router/LinkRouter';
import ShareholderDateOptions from '../../../../components/ShareholderComponents/TableButtons/ShareholderDateOptions';
import { HeaderDateButton } from '../../../../components/ShareholderComponents/TableButtons/TableHeaderDateButton';
import { ApplicationState } from '../../../../store';
import { ShareholdersProps } from '../../../../store/shareholders/shareholdersTypes';
import { ErrorType, Roles } from '../../../../store/virsis/dataTypes';
import virsisTheme from '../../../../style/virsisTheme';
import { getSubsystem } from '../../../../utils/roleHelper';
import AllowedTo from '../../../AllowedTo';
import { cssVariables } from '../../constants';
import {
  checkAllShareholdersIfDateFromJADIS,
  currentDate
} from '../../utilityFunctions/sharedFunctions';
import { hasHeaderDateErrors, hasNoEvents, isDateUnsigned } from '../tableState/tableFunctions';
import { headerDateIsDay } from '../utilities/functionsWithDateFormat';

const useStyles = makeStyles({
  headerDateCell: {
    maxWidth: '100px',
    width: `${cssVariables.dateColumnWidthNarrow.width}px`,
    boxSizing: 'border-box',
    textAlign: 'center',
    lineHeight: '1.4',
    position: 'relative',
    '&:hover': {
      '& $dateOptionsButton': {
        visibility: 'visible'
      }
    }
  },
  dateOptionsButton: {
    position: 'absolute',
    top: '15px',
    right: '0px',
    visibility: 'hidden'
  },
  ribbonUnsigned: {
    boxShadow: `0 -8px 0 ${virsisTheme.palette.primary.light} inset`
  },
  ribbonWarning: {
    boxShadow: `0 -8px 0 ${virsisTheme.palette.warning.main} inset`
  },
  ribbonError: {
    boxShadow: `0 -8px 0 ${virsisTheme.palette.error.main} inset`
  }
});

interface Props {
  date: string;
  allShareholders: ShareholdersProps[];
  handleDateClick: (date: string) => void;
  isDisabled?: boolean;
}

export const TableHeadDateCell: React.FC<Props> = ({
  allShareholders,
  handleDateClick,
  date,
  isDisabled
}) => {
  const classes = useStyles();

  const { currentUser, selectedVirsId } = useSelector((state: ApplicationState) => state.virsis);

  const { shareholdersData } = useSelector((state: ApplicationState) => state.shareholdersData);

  const isDateFromJADIS = checkAllShareholdersIfDateFromJADIS(allShareholders, date);

  const hasDateNonCriticalError =
    shareholdersData && hasHeaderDateErrors(shareholdersData, ErrorType.NONCRITICAL, date);

  const hasDateCriticalError =
    shareholdersData && hasHeaderDateErrors(shareholdersData, ErrorType.CRITICAL, date);

  const hasDateUnsignedDoc = isDateUnsigned(
    allShareholders,
    shareholdersData?.activityPeriods || [],
    date
  );

  const noEventsOnDate = hasNoEvents(allShareholders || [], date);

  const checkDateMarkColor = () => {
    if (hasDateUnsignedDoc && hasDateCriticalError) {
      return classes.ribbonError;
    }

    if (hasDateNonCriticalError) {
      return classes.ribbonWarning;
    }

    if (hasDateUnsignedDoc) {
      return classes.ribbonUnsigned;
    }

    return undefined;
  };

  const formatedDateLabel = currentDate === date ? 'Šiandien' : date.replace(/-/g, '\n');

  const isVirsis = getSubsystem(currentUser) === 'VIRSIS';

  return (
    <>
      {headerDateIsDay(date) ? (
        <TableCell className={`${classes.headerDateCell} ${checkDateMarkColor()}`}>
          <LinkRouter
            path={
              isVirsis
                ? '/duomenu-perziura-ir-teikimas/dalyviai/konkreti-data'
                : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/dalyviai/konkreti-data`
            }
          >
            <HeaderDateButton
              label={formatedDateLabel}
              isDateFromJADIS={isDateFromJADIS}
              onClicked={() => handleDateClick(date)}
              isDisabled={isDisabled}
            />
          </LinkRouter>

          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <div className={classes.dateOptionsButton}>
              <ShareholderDateOptions
                selectedDate={date}
                isDateFromJADIS={isDateFromJADIS}
                hasDateUnsignedDoc={hasDateUnsignedDoc}
                noEventsOnDate={noEventsOnDate}
              />
            </div>
          </AllowedTo>
        </TableCell>
      ) : (
        <TableCell className={`${classes.headerDateCell} ${checkDateMarkColor()}`}>
          <HeaderDateButton
            label={formatedDateLabel}
            isDateFromJADIS={isDateFromJADIS}
            onClicked={() => handleDateClick(date)}
            isDisabled={isDisabled}
          />
        </TableCell>
      )}
    </>
  );
};
