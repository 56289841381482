import React, { useState } from 'react';
import { Menu, MenuItem, Typography, Button, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { VirsisUser } from '../../store/virsis/dataTypes';
import { logout, openEditContactEmailDialog } from '../../store/virsis/actions';
import { BasicTooltip } from '../../components/Tooltips/BasicTooltip';
import EditIcon from '@material-ui/icons/Edit';
import { ApplicationState } from '../../store';

interface Props {
  currentUser: VirsisUser;
  displayEmailEditing: boolean;
}

const useStyles = makeStyles((theme) => ({
  dropdownMenu: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '4px',
    minWidth: '200px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
    marginTop: '5px'
  },
  currentUserButton: {
    height: 'auto',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#258ECE'
    }
  },
  activeButton: {
    height: 'auto',
    backgroundColor: '#258ECE'
  },
  icon: { padding: '2px 10px 2px 5px' },
  progress: {
    height: '20px',
    width: '20px'
  }
}));

const CurrentUserMenu: React.FC<Props & RouteComponentProps> = ({
  currentUser,
  displayEmailEditing
}) => {
  const classes = useStyles();
  const [logOutbtnColor, setLogOutBtnColor] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { contactEmail, getContactEmailLoading, saveContactLoading } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  const dispatch = useDispatch();

  const handleMenuClose = () => {
    setAnchorEl(null);
    setLogOutBtnColor(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const openLogoutPopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!anchorEl) {
      setLogOutBtnColor(!logOutbtnColor);
    }
  };

  return (
    <>
      <BasicTooltip text={'Paskyra'}>
        <Button
          color="inherit"
          className={logOutbtnColor ? classes.activeButton : classes.currentUserButton}
          onClick={openLogoutPopup}
        >
          <div className="logout-button">
            <div>
              <Typography variant="h5">{currentUser.amnName}</Typography>
              <Typography variant="h6">{currentUser.name}</Typography>
            </div>
            <div className="triangle" />
          </div>
        </Button>
      </BasicTooltip>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        elevation={0}
        classes={{ paper: classes.dropdownMenu }}
        MenuListProps={{ disablePadding: true }}
      >
        {displayEmailEditing && (
          <MenuItem onClick={() => dispatch(openEditContactEmailDialog(false))}>
            <EditIcon className={classes.icon} />
            Kontaktinis el. paštas:{' '}
            <span style={{ marginLeft: '5px' }}>
              {(getContactEmailLoading || saveContactLoading) && !contactEmail && (
                <CircularProgress className={classes.progress} />
              )}
              {!getContactEmailLoading && contactEmail}
              {!getContactEmailLoading && !saveContactLoading && !contactEmail && '-'}
            </span>
          </MenuItem>
        )}
        <Divider style={{ margin: 0 }} />
        <MenuItem onClick={() => handleLogout()}>
          <ExitToAppIcon style={{ padding: '2px 10px 2px 5px' }} />
          Atsijungti
        </MenuItem>
      </Menu>
    </>
  );
};

export default withRouter(CurrentUserMenu);
