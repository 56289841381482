import React, { ReactNode } from 'react';
import { TableCell } from '@material-ui/core/';
import { OutletRepresentativesColumnsDisplayStatus } from '../../tablesState/representativesTableTypes';
import { RowStyleOnDifferentStatus } from '../../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../../components/Tooltips/TooltipOnRowRibbon';

interface Props {
  columnsToDisplay: OutletRepresentativesColumnsDisplayStatus;
  id?: string;
  rowStatus?: string;
  representativeName?: ReactNode;
  representativeNameInput?: ReactNode;
  validFrom?: ReactNode;
  validFromInput?: ReactNode;
  validTo?: ReactNode;
  validToInput?: ReactNode;
  representationNotes?: ReactNode;
  representationNotesInput?: ReactNode;
  actions?: ReactNode;
}

export const RepresentativeBasicRow: React.FC<Props> = ({
  id,
  columnsToDisplay,
  rowStatus,
  representativeName,
  representativeNameInput,
  validFrom,
  validFromInput,
  validTo,
  validToInput,
  representationNotes,
  representationNotesInput,
  actions
}) => {
  return (
    <RowStyleOnDifferentStatus id={id} status={rowStatus}>
      {columnsToDisplay.representativeName && !representativeNameInput && (
        <TooltipOnRowRibbon status={rowStatus}>
          <TableCell>{representativeName}</TableCell>
        </TooltipOnRowRibbon>
      )}
      {columnsToDisplay.representativeName && representativeNameInput && representativeNameInput}
      {columnsToDisplay.validFrom && !validFromInput && <TableCell>{validFrom}</TableCell>}
      {columnsToDisplay.validFrom && validFromInput && validFromInput}
      {columnsToDisplay.validTo && !validToInput && <TableCell> {validTo} </TableCell>}
      {columnsToDisplay.validTo && validToInput && validToInput}
      {columnsToDisplay.representationNotes && !representationNotesInput && (
        <TableCell>{representationNotes}</TableCell>
      )}
      {columnsToDisplay.representationNotes && representationNotesInput && representationNotesInput}
      <TableCell align="right">{actions}</TableCell>
    </RowStyleOnDifferentStatus>
  );
};
