import React from 'react';
import { Typography, TableHead, TableRow, TableCell, makeStyles } from '@material-ui/core/';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDispatch } from 'react-redux';
import {
  addShareholderDateReset,
  fetchShareholdersSelectedDateDataRequest,
  setSelectedDate
} from '../../../store/shareholders/shareholdersActions';
import { virsColor } from '../constants';
import { TableHeadPrevFollDateCell } from './Components/TableHead/TableHeadPrevFollDateCell';
import { TableHeadSelectedDateCell } from './Components/TableHead/TableHeadSelectedDateCell';
import { formatDateLT } from './tableState/tableStateFunctions';
import { ActivityPeriod } from '../../../store/shareholders/shareholdersTypes';

interface Props {
  previousDate?: string;
  followingDate?: string;
  selectedDate: string;
  isSelectedDateFromJADIS: boolean;
  openCalendar: () => void;
  noValidDates: boolean;
  loadingShareholders: boolean;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  virsId?: number;
  isDateUnsignedDoc: boolean;
  noEventsOnDate: boolean;
  virsStartDate: string | null;
  activityPeriods: ActivityPeriod[];
}

const useStyles = makeStyles({
  virsTextColor: {
    color: virsColor
  }
});

const ShareholdersSelectedDateTableHead: React.FC<Props> = ({
  previousDate,
  followingDate,
  selectedDate,
  isSelectedDateFromJADIS,
  openCalendar,
  noValidDates,
  loadingShareholders,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  virsId,
  noEventsOnDate,
  isDateUnsignedDoc,
  virsStartDate,
  activityPeriods
}) => {
  const { virsTextColor } = useStyles();
  const reduxDispatch = useDispatch();

  const handleDateClick = (date?: string) => {
    if (date && virsId) {
      reduxDispatch(setSelectedDate(date));
      reduxDispatch(addShareholderDateReset());
      reduxDispatch(fetchShareholdersSelectedDateDataRequest(virsId, date));
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography variant="h4">Lygis</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h4">Dalyvis</Typography>
        </TableCell>

        <TableHeadPrevFollDateCell
          alignment="left"
          isDate={!previousDate}
          onClick={() => handleDateClick(previousDate)}
        >
          <ArrowBackIcon />
          <Typography variant="h4" style={{ color: virsColor }}>
            {previousDate ? formatDateLT(previousDate) : null}
          </Typography>
        </TableHeadPrevFollDateCell>

        <TableHeadSelectedDateCell
          virsStartDate={virsStartDate}
          noValidDates={noValidDates}
          openCalendar={openCalendar}
          selectedDate={selectedDate}
          isDateFromJADIS={isSelectedDateFromJADIS}
          loadingShareholders={loadingShareholders}
          virsEndDate={virsEndDate}
          virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
          isDateUnsignedDoc={isDateUnsignedDoc}
          noEventsOnDate={noEventsOnDate}
          activityPeriods={activityPeriods}
        />

        <TableHeadPrevFollDateCell
          alignment="right"
          isDate={!followingDate}
          onClick={() => handleDateClick(followingDate)}
        >
          <Typography variant="h4" className={virsTextColor}>
            {followingDate ? formatDateLT(followingDate) : null}
          </Typography>
          <ArrowForwardIcon />
        </TableHeadPrevFollDateCell>
      </TableRow>
    </TableHead>
  );
};

export default ShareholdersSelectedDateTableHead;
