import { ShareholdersHistory } from '../../../../store/shareholders/shareholdersTypes';
import { NO_VALID_DATE_TEXT, NO_VALID_PARENT_TEXT } from '../../constants';
import { Period } from '../tableState/tableInitialStateAndTypes';

export const drawColorRow = (
  startDay: string | null,
  endDay: string | null,
  currentDate: string,
  datesArray: string[]
) => {
  if (startDay) {
    return (
      datesArray.indexOf(endDay === null ? currentDate : endDay) - datesArray.indexOf(startDay)
    );
  }
  return 0;
};

// dalyvio datų (pradžios, pabaigos ir įvykių) masyve randa sekančia data nuo konkretaus įvykio, naudojama juostelės piešimiui
export const findNextEventDate = (arrayOfEvents: (string | null)[], event: string) => {
  const currentIndex = arrayOfEvents.indexOf(event);

  const nextIndex = (currentIndex + 1) % arrayOfEvents.length;
  return arrayOfEvents[nextIndex];
};

export const checkHistory = (shareholderHistory: ShareholdersHistory[]) => {
  if (!Array.isArray(shareholderHistory) || !shareholderHistory.length) {
    return 'base-bg-color'; // grey
  }
  return '';
};

export const getValue = (
  showShares: boolean,
  sharePercentage: number | null,
  mayHaveSharePercentage: boolean,
  showVotes: boolean,
  sharePercentageLessThanPermitted: boolean | null,
  votePercentageLessThanPermitted: boolean | null,
  votesPercentage?: number | null,
  votesOnRules?: boolean | null
) => {
  if (showShares && sharePercentage) {
    return `${sharePercentageLessThanPermitted ? '< ' : ''}${sharePercentage}%`;
  }
  if (showVotes && votesOnRules) {
    return 'T';
  }
  if (
    showVotes &&
    votesPercentage !== null &&
    votesPercentage !== undefined &&
    votesPercentage >= 0
  ) {
    return `${votePercentageLessThanPermitted ? '< ' : ''}${votesPercentage}%`;
  }
  if (showShares && !mayHaveSharePercentage) {
    return '';
  }
  return '-';
};

export const getLeftBorderRadius = (
  index: number,
  startDate: string | null,
  date?: string,
  startWithTriangle?: boolean
) => {
  if (startWithTriangle === false && index === 0 && startDate === date) {
    return '5px';
  }
  return '0px';
};

export const getRightBorderRadius = (index: number, arrayOfDates?: string[]) => {
  const lastItem = arrayOfDates && arrayOfDates.length - 1;
  if (index === lastItem) {
    return '5px';
  }
  return '0px';
};

export const getShareholderDateTooltip = (
  noValidDates: boolean,
  allShareholderDataFromJADIS?: boolean
) => {
  if (noValidDates) return NO_VALID_DATE_TEXT;
  if (allShareholderDataFromJADIS) return NO_VALID_PARENT_TEXT;
  return 'Pridėti naują datą';
};

export const getWidthByDay = (
  endDate: string | null,
  date: string,
  isSelectedDateEventDate?: boolean,
  eventDate?: string
) => {
  if (endDate === date || (eventDate && eventDate < date && isSelectedDateEventDate)) {
    return '43%';
  }
  return undefined;
};

export const getVirsWidthByDay = (endDate: string | null, date: string, periodFilter: Period) => {
  if (endDate === date && periodFilter.id === 'changeDate') {
    return '43%';
  }
  return undefined;
};

export const getJustifyContentByDay = (
  startDate: string,
  date: string,
  periodFilterFromDate: string
) => {
  if (periodFilterFromDate === date && date !== startDate) {
    return 'flex-start';
  }
  return 'center';
};

export const getVirsLeftParameter = (
  startDate: string,
  date: string,
  periodFilterFromDate: string,
  periodFilter: Period
) => {
  if (periodFilterFromDate === date && date !== startDate) {
    return '0';
  }
  if (startDate === date && periodFilter.id === 'changeDate') {
    return '43%';
  }
  return '0';
};

export const getVirsRightParameter = (
  startDate: string,
  endDate: string | null,
  date: string,
  periodFilterFromDate: string
) => {
  if (endDate && endDate === date) {
    return undefined;
  }
  return '-1px';
};

export const getRightParameter = (
  endDate: string | null,
  date: string,
  isSelectedDateEventDate?: boolean,
  eventDate?: string
) => {
  if (endDate === date || (eventDate && eventDate < date && isSelectedDateEventDate)) {
    return undefined;
  }
  return '-1px';
};

export function getScaledFontSize(
  valueLength?: number,
  isOneDayShareholder = false,
  minFontSize = 0.6,
  maxFontSize = 0.75,
  minLength = 3,
  maxLength = 6
): string {
  if (isOneDayShareholder) minFontSize = 0.5;
  if (!valueLength) return maxFontSize + 'rem';
  if (valueLength <= minLength) {
    return maxFontSize + 'rem';
  }

  if (valueLength >= maxLength) {
    return minFontSize + 'rem';
  }

  const scaleFactor: number = (valueLength - minLength) / (maxLength - minLength);
  const fontSize: number = maxFontSize + scaleFactor * (minFontSize - maxFontSize);

  return fontSize + 'rem';
}
