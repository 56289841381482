import { axiosDelete, get, getBlob, post, put } from '../../utils/axiosApi';
import {
  RecordToSynchronise,
  AddPercentageRecord,
  ShareholderRecord,
  ClearShareholderPercentageRecord
} from './shareholdersTypes';

export const getShareholdersSelectedDateData = (virsId: number, date: string) =>
  get(`/shareholders/${virsId}?date=${date}`);

export const getShareholdersData = (virId: number, isShowOnlySigned: boolean) =>
  get(`/shareholders/${virId}?isShowOnlySigned=${isShowOnlySigned}`);

export const deleteShareholder = (virId: number | undefined, shareholderId: number) =>
  axiosDelete(`/shareholders/${virId}/${shareholderId}`);

export const postClearShareholderEvent = (percentageRecord: ClearShareholderPercentageRecord) =>
  post('/shareholders/clearShares', percentageRecord);

export const postAddShareholderPercentage = (percentageRecord: AddPercentageRecord) =>
  post('/shareholders/shares', percentageRecord);

export const getVirsPersonsList = (virsId: number, date: string) =>
  get(`/shareholders/${virsId}/persons/${date}`);

export const getFullVirsPersonsList = (virsId: number) => get(`/shareholders/${virsId}/persons`);

export const postSynchroniseWithJADIS = (record: RecordToSynchronise) =>
  post('/shareholders/synchronise', record);

export const postCreateShareholder = (record: ShareholderRecord, virsId: number) =>
  post(`/shareholders/${virsId}`, record);

export const putUpdateShareholder = (
  record: ShareholderRecord,
  shareholderId: number,
  virsId: number
) => put(`/shareholders/${virsId}/shareholder/${shareholderId}`, record);

export const deleteShareholderDate = (virsId: number, date: string) => {
  return axiosDelete(`/shareholders/date/${virsId}/${date}`);
};

export const deleteShareholderDataByDate = (virsId: number, date?: string) => {
  if (date) {
    return axiosDelete(`/shareholders/shareholder/${virsId}/${date}`);
  }
  return axiosDelete(`/shareholders/shareholder/${virsId}`);
};

export const getVirsDocument = (documentId: number) =>
  getBlob(`virs/legals/documents/${documentId}`);
