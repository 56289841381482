import React from 'react';
import { TableCell } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EnterpriseDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { EnterpriseTypeShort } from '../../../store/classifiers/classifiersTypes';
import { EnterpriseEditRecordState } from './rowState/rowInitialStateAndTypes';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';

interface Props {
  record: EnterpriseData;
  rowState: EnterpriseEditRecordState;
  columnsToDisplay: EnterpriseDataTableColumnsDisplayStatus;
  setEnterpriseType: (enterprise: EnterpriseTypeShort | null) => void;
  setValidFrom: (validFrom: MaterialUiPickersDate | null) => void;
  setValidTo: (validTo: MaterialUiPickersDate | null) => void;
  updateRecord: () => void;
  closeEditing: () => void;
  updatingEnterprise: boolean;
}

export const EnterpriseTableRowEditOn: React.FC<Props> = ({
  record,
  rowState,
  columnsToDisplay,
  setEnterpriseType,
  setValidFrom,
  setValidTo,
  updateRecord,
  closeEditing,
  updatingEnterprise
}) => {
  return (
    <RowStyleOnDifferentStatus status="RUOSIAMAS">
      {columnsToDisplay.enterpriseTypeName && (
        <DropdownInputCell
          selectValue={setEnterpriseType}
          dropdownState={rowState.enterpriseType}
          getOptionLabel={(ent) => ent.enterpriseTypeName}
          getOptionSelected={(option, value) => option.enterpriseTypeId === value.enterpriseTypeId}
        />
      )}
      {columnsToDisplay.validFrom && (
        <DateInputImprovedCell state={rowState.validFrom} setDate={setValidFrom} isRequired />
      )}
      {columnsToDisplay.validTo && (
        <DateInputImprovedCell state={rowState.validTo} setDate={setValidTo} />
      )}
      {columnsToDisplay.authorName && <TableCell>{record.authorName}</TableCell>}
      <TableCell>
        <SaveAndCancelActions
          handleConfirmationYes={updateRecord}
          isProcessing={updatingEnterprise}
          handleCancelButtonClick={closeEditing}
        />
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
