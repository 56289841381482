import * as React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Button, LinearProgress, Typography, FormGroup } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import * as Yup from 'yup';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { JarCountry } from '../../store/classifiers/classifiersTypes';
import { FormikFormTextField } from '../FormikFields/FormikFormTextField';
import { FormikFormDateField } from '../FormikFields/FormikFormDateField';
import { useDialogFormStyles } from '../FormikFields/FieldStyles';
import { todaysDateString } from '../../utils/tableDataFunctions';
import { ApplicationState } from '../../store';
import { NAME_CHECK_REGEX_LT } from '../../utils/tableTypes';
import { FindNaturalPersonInVirsisQuery } from './Utilities';

export interface Props {
  searchPerson: (personQuery: FindNaturalPersonInVirsisQuery) => void;
  isSearching: boolean;
  personCodeNotRequired?: boolean;
}
interface Values {
  firstName?: string;
  lastName?: string;
  birthDate?: Date | null;
}

export const SearchForm: React.FC<Props> = ({
  searchPerson,
  isSearching,
  personCodeNotRequired
}) => {
  const { selectedNaturalPersonSearchQuery } = useSelector(
    (state: ApplicationState) => state.personData
  );

  const classes = useDialogFormStyles();

  const [initValues, setInitialValues] = useState<Partial<Values>>({
    firstName: '',
    lastName: '',
    birthDate: null
  });

  const clearForm = (
    setFieldValue: (id: string, value: string | null | undefined | JarCountry) => void
  ) => {
    setFieldValue('firstName', '');
    setFieldValue('lastName', '');
    setFieldValue('birthDate', null);
  };

  const getBirthdate = (personBirthdate: Date | undefined | null): string | null => {
    return personBirthdate ? moment(personBirthdate).format('YYYY-MM-DD') : null;
  };

  const wrongSymbols = 'Įvesti netinkami simboliai';

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Vardas privalomas'),
    lastName: Yup.string()
      .trim()
      .matches(NAME_CHECK_REGEX_LT, wrongSymbols)
      .required('Pavardė privaloma'),
    birthDate: Yup.mixed().typeError('Data turi būti teisingo formato')
  });

  useEffect(() => {
    setInitialValues({
      firstName: selectedNaturalPersonSearchQuery?.naturalFirstname || '',
      lastName: selectedNaturalPersonSearchQuery?.naturalLastname || '',
      birthDate: selectedNaturalPersonSearchQuery?.personBirthdate
        ? new Date(selectedNaturalPersonSearchQuery.personBirthdate)
        : null
    });
  }, [selectedNaturalPersonSearchQuery]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery: FindNaturalPersonInVirsisQuery = {
          personFirstName: values.firstName ? values.firstName : null,
          personLastName: values.lastName ? values.lastName : null,
          personBirthdate: getBirthdate(values.birthDate)
        };
        searchPerson(searchQuery);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <FormikForm>
              <FormGroup style={{ maxWidth: 'fit-content' }}>
                <FormikFormTextField
                  styledClasses={classes}
                  label={`Vardas*`}
                  formikKey="firstName"
                  values={values.firstName}
                  error={errors.firstName}
                  setFieldValue={setFieldValue}
                />
                <FormikFormTextField
                  styledClasses={classes}
                  label="Pavardė*"
                  formikKey="lastName"
                  values={values.lastName}
                  error={errors.lastName}
                  setFieldValue={setFieldValue}
                />
                <FormikFormDateField
                  maxDate={todaysDateString()}
                  styledClasses={classes}
                  values={values.birthDate}
                  label={`Gimimo data`}
                  formikKey="birthDate"
                  date={values.birthDate}
                  errors={errors.birthDate}
                  setFieldValue={setFieldValue}
                  isRequired={false}
                />
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isValidating || isSearching}
                    onClick={submitForm}
                    className={classes.button}
                    style={{ marginRight: '45px' }}
                    startIcon={<SearchIcon />}
                  >
                    <Typography color="inherit">Ieškoti</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isValidating || isSearching}
                    onClick={() => clearForm(setFieldValue)}
                    className={classes.button}
                    style={{ marginRight: '4px' }}
                    startIcon={<ClearIcon />}
                  >
                    <Typography color="inherit">Išvalyti</Typography>
                  </Button>
                </Box>
                {isSearching && <LinearProgress />}
              </FormGroup>
            </FormikForm>
          </MuiPickersUtilsProvider>
        );
      }}
    </Formik>
  );
};
