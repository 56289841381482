import _ from 'lodash';
import {
  OutletDataTableState,
  OutletDataTableAction,
  initialOutletDataTableState,
  outletTableColumns
} from './initialStateAndTypes';
import {
  addNewOrRemoveExisting,
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../utils/tableDataFunctions';
import { filterTable, getTableExportDefinition } from './tableReducerFunctions';

export const outletsDataTableReducer = (
  state: OutletDataTableState,
  action: OutletDataTableAction
): OutletDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialOutletDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      const tempState = {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value),
        page: 0
      };
      const filteredData = filterTable(action.enterprisesToShow, tempState);
      return {
        ...tempState,
        extendedEnterprises: Object.entries(tempState.customFilter).some(
          (entry) => entry[1].length > 0
        )
          ? filteredData
              .filter((enterprise) => enterprise.outlets.length)
              .map((enterprise) => enterprise.enterpriseId)
          : [...tempState.extendedEnterprises]
      };
    case 'SHOW_STATUS_UNSIGNED_TOGGLED':
      return {
        ...state,
        showStatusNotSigned: !state.showStatusNotSigned,
        userPreferenceNotSignedOn: !state.userPreferenceNotSignedOn,
        page: 0
      };
    case 'SHOW_STATUS_RELEVANT_TOGGLED':
      return {
        ...state,
        showStatusRelevant: !state.showStatusRelevant,
        userPreferenceRelevantOn: !state.userPreferenceRelevantOn,
        page: 0
      };
    case 'SHOW_STATUS_NOT_RELEVANT_TOGGLED':
      return {
        ...state,
        showStatusOutdated: !state.showStatusOutdated,
        userPreferenceOutdatedOn: !state.userPreferenceOutdatedOn,
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(outletTableColumns, columnsDisplayStatus)
      };
    }
    case 'SET_NEWEST_RECORD_ID':
      return {
        ...state,
        extendedOutlets: [...state.extendedOutlets, action.virOutletControlId]
      };
    case 'RESET_COLUMN_DISPLAY':
      return {
        ...state,
        columnsDisplayStatus: initialOutletDataTableState.columnsDisplayStatus
      };
    case 'TABLE_INITIALIZED':
      return {
        ...state,
        statusNotSignedFilterEnabled: action.notSignedEnabled,
        statusRelevantFilterEnabled: action.relevantEnabled,
        statusOutdatedFilterEnabled: action.outdatedEnabled,
        showStatusNotSigned: action.notSignedEnabled
          ? action.notSignedEnabled && state.userPreferenceNotSignedOn
          : false,
        showStatusRelevant: action.relevantEnabled
          ? action.relevantEnabled && state.userPreferenceRelevantOn
          : false,
        showStatusOutdated: action.outdatedEnabled
          ? action.outdatedEnabled && state.userPreferenceOutdatedOn
          : false
      };
    case 'SHOW_NEW_RECORD_DIALOG_TOGGLED':
      return {
        ...state,
        showAddNewRecordDialog: !state.showAddNewRecordDialog
      };
    case 'ADD_OUTLET_FROM_DIALOG_CLICKED':
      return {
        ...state,
        showNewRecordInput: true,
        tableInputOn: true,
        showGroupOfSingleEnterprise: action.enterprise,
        extendedEnterprises: _.union([action.enterprise.enterpriseId], state.extendedEnterprises),
        statusNotSignedFilterEnabled: action.notSignedEnabled,
        statusRelevantFilterEnabled: action.relevantEnabled,
        statusOutdatedFilterEnabled: action.outdatedEnabled,
        showStatusNotSigned: action.notSignedEnabled
          ? state.showStatusNotSigned && state.userPreferenceNotSignedOn
          : false,
        showStatusRelevant: action.relevantEnabled
          ? state.showStatusRelevant && state.userPreferenceRelevantOn
          : false,
        showStatusOutdated: action.outdatedEnabled
          ? state.showStatusOutdated && state.userPreferenceOutdatedOn
          : false,
        customFilterOn: false,
        customFilter: initialOutletDataTableState.customFilter,
        columnsDisplayStatus: initialOutletDataTableState.columnsDisplayStatus,
        pagesCount: 1,
        recordsCountAfterFilters: 1,
        page: 0
      };
    case 'CANCEL_ADDING_OUTLET_CLICKED':
      return {
        ...state,
        showNewRecordInput: false,
        tableInputOn: false
      };
    case 'RETURN_TO_TABLE_CLICKED':
      return {
        ...state,
        showNewRecordInput: false,
        tableInputOn: false,
        showGroupOfSingleEnterprise: undefined,
        statusNotSignedFilterEnabled: action.notSignedEnabled,
        statusRelevantFilterEnabled: action.relevantEnabled,
        statusOutdatedFilterEnabled: action.outdatedEnabled,
        showStatusNotSigned: action.notSignedEnabled ? state.showStatusNotSigned : false,
        showStatusRelevant: action.relevantEnabled ? state.showStatusRelevant : false,
        showStatusOutdated: action.outdatedEnabled ? state.showStatusOutdated : false
      };
    case 'ENTERPRISE_EXTENSION_CLICKED':
      return {
        ...state,
        extendedEnterprises: addNewOrRemoveExisting(action.enterpriseId, state.extendedEnterprises)
      };
    case 'OUTLET_EXTENSION_CLICKED':
      return {
        ...state,
        extendedOutlets: addNewOrRemoveExisting(action.virOutletControlId, state.extendedOutlets)
      };
    case 'UPDATE_SINGLE_SHOWING_ENTERPRISE_WITH_NEW_DATA':
      return {
        ...state,
        showGroupOfSingleEnterprise: action.updatedEnterprise
      };
    case 'EXTEND_ENTERPRISES':
      return {
        ...state,
        extendedEnterprises: _.union(
          action.enterprisesToExtend.map((e) => e.enterpriseId),
          state.extendedEnterprises
        )
      };
    case 'EDITING_ON':
      return {
        ...state,
        tableInputOn: true
      };
    case 'EDITING_OFF':
      return {
        ...state,
        tableInputOn: false
      };
    default:
      return state;
  }
};
