import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import {
  initialParametersDataTableState,
  ParametersDataTableAction,
  ParametersDataTableState
} from './tableInitialStateAndTypes';
import { updateParameterDataCustomFilter } from './tableStateFunctions';

export const parametersTableReducer = (
  state: ParametersDataTableState,
  action: ParametersDataTableAction
): ParametersDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialParametersDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateParameterDataCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'PARAMETER_DIALOG_OPENED':
      return {
        ...state,
        parameter: action.parameterRecord
      };
    case 'PARAMETER_DIALOG_CLOSED':
      return {
        ...state,
        parameter: undefined
      };
    default:
      return state;
  }
};
