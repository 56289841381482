import React from 'react';
import { Box, Paper, Table, Typography } from '@material-ui/core';
import {
  RowsPerPageOptions,
  ColumnHeaderField,
  LicensesColumnHeader,
  DataTableState
} from './tableState/tableTypes';
import CustomizationBar from './CustomizationBar/CustomizationBar';
import DataTableHead from './DataTableHead';
import CustomFilter from './CustomFilter/CustomFilter';
import DataTableBody from './DataTableBody';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import PageActionButtons from '../../../components/PageButtons/PageActionButtons';
import { FileFormats } from '../../../utils/exporters/types';
import { ScrollXContainer } from '../../../components/ScrollXContainer/ScrollXContainer';

interface Props {
  tableState: DataTableState;
  columnNames: LicensesColumnHeader[];
  rowsPerPageOptions: RowsPerPageOptions;
  onFilterTabToggle: () => void;
  onCustomFilterChange: (column: ColumnHeaderField, value: string | null) => void;
  onSortByColumnClick: (column: ColumnHeaderField) => void;
  onDisplayColumnChecked: (column: ColumnHeaderField) => void;
  setPage: (value: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  onDownload: (format: FileFormats, navigationPath: string[]) => void;
  onPrint: (navigationPath: string[]) => void;
}

const DataTable: React.FC<Props> = ({
  tableState,
  columnNames,
  rowsPerPageOptions,
  onFilterTabToggle,
  onCustomFilterChange,
  onSortByColumnClick,
  onDisplayColumnChecked,
  setPage,
  setRowsPerPage,
  onDownload,
  onPrint
}) => {
  return (
    <>
      <div
        className="table-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          <Typography variant="subtitle1">Licencijos</Typography>

          <Typography variant="subtitle2">
            Duomenys gauti iš Licencijų informacinės sistemos (LIS)
          </Typography>
        </Box>

        <Box>
          <PageActionButtons dontShowDelete onDownload={onDownload} onPrint={onPrint} />
        </Box>
      </div>

      <div className="data-table">
        <Paper elevation={0}>
          <CustomizationBar
            order={tableState.order}
            sortBy={tableState.sortBy}
            onSortByColumnClick={onSortByColumnClick}
            columnNames={columnNames}
            onDisplayColumnChecked={onDisplayColumnChecked}
            columnsDisplayStatus={tableState.columnsDisplayStatus}
            onFilterTabToggle={onFilterTabToggle}
            customFilterOn={tableState.customFilterOn}
          />

          <ScrollXContainer>
            <Table>
              <DataTableHead
                sortBy={tableState.sortBy}
                order={tableState.order}
                onSortByColumnClick={onSortByColumnClick}
                columnsDisplayStatus={tableState.columnsDisplayStatus}
              />
              {tableState.customFilterOn && (
                <CustomFilter
                  columnsDisplayStatus={tableState.columnsDisplayStatus}
                  onCustomFilterChange={onCustomFilterChange}
                  columnNames={columnNames}
                  pageData={tableState.data}
                />
              )}
              <DataTableBody
                columnsDisplayStatus={tableState.columnsDisplayStatus}
                data={tableState.pageData}
              />
            </Table>
          </ScrollXContainer>

          <TablePagination
            recordsCount={tableState.recordsPassed}
            pagesCount={tableState.pagesCount}
            rowsPerPage={tableState.rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={tableState.page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            disabled={tableState.recordsPassed === 0}
          />
        </Paper>
      </div>
    </>
  );
};
export default DataTable;
