import React, { useContext, useState } from 'react';
import { Box, Paper, Table, TableBody, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import { ApplicationState } from '../../../store';
import { EditionDataRowContainer } from './TableRows/RowContainer';
import { pageTableData } from '../../../utils/tableDataFunctions';
import { AddNewRecordButton } from '../../../components/TableButtons/AddNewRecordButton';
import FilterRow from './FilterRow';
import { EditionDataRowContainerNew } from './TableRows/RowContainerNew';
import { editionTablecolumns } from '../tablesState/editionTableTypes';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import EditionCustomizationBar from './CustomizationBar';
import EditionDataTableHead from './TableHead';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { TableErrorRow } from '../../../components/TableErrorRow/TableErrorRow';
import { TableCircularProgressRow } from '../../../components/TableCircularProgressRow/TableCircularProgressRow';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { filterAndSortEditionTableData } from '../tablesState/tablesStateFunctions';
import PageActionButtons from '../../../components/PageButtons/PageActionButtons';
import { subsystemContext } from '../../../pages/virs/OutletInfoTablesPage';
import { ConfirmRemoveUnsignedDialog } from '../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { OutletDocumentType } from '../../../store/unsignedDataRemoval/unsignedDataTypes';
import { unsignedDataRemoveOutlet } from '../../../store/unsignedDataRemoval/unsignedDataActions';
import { ScrollXContainer } from '../../../components/ScrollXContainer/ScrollXContainer';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import { getTableExportDefinition } from './exportDefinition';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';
import { fetchVirsDataRequest } from '../../../store/virsis/actions';

interface Props {
  tableName: OutletTableName;
}

const EditionDataTable: React.FC<Props> = ({ tableName }) => {
  const reduxDispatch = useDispatch();

  const {
    state: { editions: editionsTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const { loadingOutletInfo, outletInfo, outletInfoError } = useSelector(
    (state: ApplicationState) => state.outletInfoData
  );

  function handleSetPage(value: number) {
    tableDispatch({ type: 'PAGE_SET', page: value, table: tableName });
  }

  function handleSetRowsPerPage(rowsPerPage: number) {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage,
      table: tableName
    });
  }

  function closeNewRecordRow() {
    tableDispatch({ type: 'NEW_RECORD_ROW_CLOSED', table: tableName });
  }

  function toggleNewRecordRow() {
    return editionsTableState.showNewRecord
      ? closeNewRecordRow()
      : tableDispatch({ type: 'NEW_RECORD_ROW_OPENED', table: tableName });
  }

  const data = filterAndSortEditionTableData(outletInfo?.editions || [], editionsTableState);
  const { page: dataPage, pagesCount } = pageTableData(editionsTableState, data);

  const subsystem = useContext(subsystemContext);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  return (
    <div className="named-data-table">
      <div className="table-title">
        {outletInfo?.active && <Typography variant="subtitle1">Tiražo duomenys</Typography>}
        {outletInfo && (
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {outletInfo?.active ? (
                <AddNewRecordButton text="Pridėti tiražo duomenis" onClicked={toggleNewRecordRow} />
              ) : (
                <Typography variant="subtitle1">Tiražo duomenys</Typography>
              )}

              <Box>
                <PageActionButtons
                  dontShowDelete={subsystem === 'LRTK' || subsystem === 'ZEIT'}
                  onDownload={(format, navigationPath) =>
                    exportTableData(
                      format,
                      getTableExportDefinition(editionsTableState),
                      data,
                      navigationPath
                    )
                  }
                  onPrint={(navigationPath) =>
                    printTableData(
                      getTableExportDefinition(editionsTableState),
                      data,
                      navigationPath
                    )
                  }
                  onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
                />
              </Box>
            </Box>

            <ConfirmRemoveUnsignedDialog
              onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
              onConfirm={() =>
                reduxDispatch(
                  unsignedDataRemoveOutlet(outletInfo.outletId, OutletDocumentType.EDITION)
                )
              }
              onRemoveSuccess={() => reduxDispatch(fetchVirsDataRequest())}
              open={confirmToDeleteUnsignedDialogOpen}
            />
          </AllowedTo>
        )}
      </div>
      <Paper elevation={0}>
        <EditionCustomizationBar columnNames={editionTablecolumns} tableName={tableName} />

        <ScrollXContainer>
          <Table>
            <EditionDataTableHead columns={editionTablecolumns} />

            {editionsTableState.customFilterOn && <FilterRow columnNames={editionTablecolumns} />}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingOutletInfo && !outletInfoError}
                colSpan={3}
              />

              <TableErrorRow
                error={outletInfoError && 'Klaida. Nepavyko gauti VIP duomenų'}
                colSpan={3}
              />

              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                editionsTableState.showNewRecord && (
                  <EditionDataRowContainerNew
                    outletInfo={outletInfo}
                    columnsDisplay={editionsTableState.columnsDisplayStatus}
                    closeNewRecordRow={closeNewRecordRow}
                  />
                )}
              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                dataPage.map((record) => (
                  <EditionDataRowContainer
                    columnsDisplay={editionsTableState.columnsDisplayStatus}
                    key={record.editionId}
                    record={record}
                    outletInfo={outletInfo}
                  />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={editionsTableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={editionsTableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </Paper>
    </div>
  );
};

export default EditionDataTable;
