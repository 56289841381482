import moment from 'moment';
import { currentDate } from '../../utilityFunctions/sharedFunctions';
import { DatesWithEvents } from '../tableState/tableInitialStateAndTypes';

export const headerDateIsDay = (date: string) => moment(date, 'YYYY-MM-DD', true).isValid();
export const headerDateIsMonth = (date: string) => moment(date, 'YYYY-MM', true).isValid();
export const headerDateIsYear = (date: string) => moment(date, 'YYYY', true).isValid();

export const checkDateFormat = (headerDate: string, dateToFormat: string) => {
  if (moment(headerDate, 'YYYY', true).isValid()) {
    return moment(dateToFormat).format('YYYY');
  }
  if (moment(headerDate, 'YYYY-MM', true).isValid()) {
    return moment(dateToFormat).format('YYYY-MM');
  }
  return dateToFormat;
};

export const getAllDaysInPeriod = (periodFilterFromDate: string, periodFilterToDate: string) => {
  const startDate = periodFilterFromDate ? moment(periodFilterFromDate) : null;
  const endDate = periodFilterToDate ? moment(periodFilterToDate) : null;

  const daysArray: string[] = [];
  if (startDate !== null && endDate !== null) {
    for (let day = startDate; day <= endDate; day.add(1, 'day')) {
      daysArray.push(day.format('YYYY-MM-DD'));
    }
  }

  return daysArray;
};

export const getYearsArray = (virsStartDate: string): string[] => {
  const endDate = moment().year();
  const startDate = moment(virsStartDate, 'YYYY').year();

  const yearsArray: string[] = [];
  for (let year = startDate; year <= endDate; year++) {
    yearsArray.push(`${year}`);
  }
  return yearsArray;
};

export const getAllMonthsInPeriod = (virsStartDate: string) => {
  const startDate = moment(virsStartDate, 'YYYY-MM');
  const allMonthsInPeriod: string[] = [];

  while (startDate.isBefore(currentDate)) {
    allMonthsInPeriod.push(startDate.format('YYYY-MM'));
    startDate.add(1, 'month');
  }

  return allMonthsInPeriod;
};

export const getChangeDatesInMonth = (date: string, allEventsDates: string[]) => {
  const days: string[] = [];
  const dateStart = moment(date).startOf('month').format('YYYY-MM-DD');
  const dateEnd = moment(date).endOf('month').format('YYYY-MM-DD');

  allEventsDates.forEach((x) => {
    if (x >= dateStart && x <= dateEnd) {
      days.push(x);
    }
  });

  return days;
};

export const getSelectedYearMonths = (date: string, virsStartDate: string) => {
  const months: string[] = [];

  const minDate = moment(virsStartDate, 'YYYY').isSame(moment(date))
    ? moment(virsStartDate, 'YYYY-MM')
    : moment(date).month(0);
  const maxDate = moment(date).month(11) < moment() ? moment(date).month(11) : moment();

  while (minDate.isSameOrBefore(maxDate)) {
    months.push(minDate.format('YYYY-MM'));
    minDate.add(1, 'month');
  }
  return months;
};

export const getFilteredMixedDates = (
  dates: DatesWithEvents[],
  perdiodDates: { periodFilterFromDate?: string; periodFilterToDate?: string },
  dateRange: any
) => {
  const { periodFilterFromDate, periodFilterToDate } = perdiodDates;

  if (periodFilterFromDate && periodFilterToDate) {
    return dates.filter(
      (dateObject) =>
        moment(dateObject.date).isSameOrAfter(periodFilterFromDate, dateRange) &&
        moment(dateObject.date).isSameOrBefore(periodFilterToDate, dateRange)
    );
  }
  if (periodFilterFromDate && !periodFilterToDate) {
    return dates.filter((dateObject) =>
      moment(dateObject.date).isSameOrAfter(periodFilterFromDate, dateRange)
    );
  }
  if (!periodFilterFromDate && periodFilterToDate) {
    return dates.filter((dateObject) =>
      moment(dateObject.date).isSameOrBefore(periodFilterToDate, dateRange)
    );
  }

  return dates;
};
