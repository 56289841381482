import { Reducer } from 'redux';
import { FETCH_FILE_ERROR, FETCH_FILE_REQUEST, FETCH_FILE_SUCCESS } from './helpPageActionTypes';

export interface HelpPageReducerState {
  pageHtml?: string;
  loadingHtml: boolean;
  loadingFile: boolean;
}

export const helpPageInitialState: HelpPageReducerState = {
  pageHtml: undefined,
  loadingHtml: false,
  loadingFile: false
};

export const helpPageReducer: Reducer<HelpPageReducerState> = (
  state = helpPageInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_FILE_REQUEST:
      return { ...state, loadingFile: true };
    case FETCH_FILE_SUCCESS:
      return { ...state, loadingFile: false };
    case FETCH_FILE_ERROR:
      return { ...state, loadingFile: false };
    default:
      return { ...state };
  }
};
