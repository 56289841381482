import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Formik } from 'formik';

import { AddPercentageForm } from './AddPercentageForm';
import {
  addShareholderDateReset,
  addShareholderPercentageRequest,
  resetAddShareholderPercentage
} from '../../../../../../store/shareholders/shareholdersActions';
import { ApplicationState } from '../../../../../../store';
import {
  ShareholderPercentages,
  ShareholderPerson,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../../../../store/shareholders/shareholdersTypes';
import {
  getFormedPercentageRecord,
  getInitialPercentageFormValues,
  getParentShareholderId
} from '../../../utilities/utilityFunctions';
import { validationFromClassifier } from './ValidationSchemas/validationFromClassifier';
import {
  getCurrentPeriod,
  getParentLegalFormCode
} from '../../../../utilityFunctions/sharedFunctions';
import { validationNotFromClassifier } from './ValidationSchemas/validationNotFromClassifier';
import { isNextShareholderEventSigned } from '../../../../../../store/shareholders/shareholdersUtils';

interface Props {
  onClose: () => void;
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson;
  shareholder: ShareholdersProps;
  shareholderEvent: ShareholdersHistory;
}

export const AddPercentageFormContainer: React.FC<Props> = ({
  onClose,
  parentShareholder,
  shareholder,
  shareholderEvent
}) => {
  const {
    virsis: { virsData },
    shareholdersData: { selectedDate, isShareholderPercentageAdded, shareholdersData }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (isShareholderPercentageAdded) {
      onClose();
      reduxDispatch(resetAddShareholderPercentage());
    }
  }, [isShareholderPercentageAdded, onClose, reduxDispatch]);

  const {
    shareholderStartDate,
    isShareholderFromJADIS,
    shareholderParentStartDate,
    shareholderId,
    shareholderPath,
    shareholderStoppedParticipatingFromDate
  } = shareholder;

  const formedInitialValues = getInitialPercentageFormValues(
    parentShareholder,
    shareholderEvent,
    shareholderStartDate,
    shareholderStoppedParticipatingFromDate,
    selectedDate
  );

  const parentId = getParentShareholderId(shareholderPath);
  const parentLegalFormCode = getParentLegalFormCode(parentShareholder);
  const validationSchema = parentLegalFormCode
    ? validationFromClassifier
    : validationNotFromClassifier;

  const getAllShareholders = (
    shareholders: ShareholdersProps[] | undefined
  ): ShareholdersProps[] => {
    let allShareholders: ShareholdersProps[] = [];
    if (shareholders) {
      shareholders.forEach((shareholder) => {
        allShareholders = allShareholders.concat(shareholder);
        shareholder.shareholderPersons.forEach((person) => {
          if (person.shareholders.length > 0) {
            allShareholders = allShareholders.concat(getAllShareholders(person.shareholders));
          }
        });
      });
    }
    return allShareholders;
  };

  const showParticipateBeforeCheckbox =
    !isShareholderFromJADIS && selectedDate === shareholderParentStartDate;

  const sortShareholders = (prev: ShareholdersProps, cur: ShareholdersProps) => {
    let prevDate = prev.shareholderStartDate;
    let curDate = cur.shareholderStartDate;
    if (prevDate === null) {
      prevDate = prev.shareholderHistory[0].eventDate;
    }
    if (curDate === null) {
      curDate = cur.shareholderHistory[0].eventDate;
    }

    const prevDateObj = new Date(prevDate);
    const curDateObj = new Date(curDate);

    if (prevDateObj < curDateObj) {
      return -1;
    } else if (prevDateObj > curDateObj) {
      return 1;
    } else {
      return 0;
    }
  };

  const showStoppedParticipatingBeforeCheckbox = (): boolean => {
    if (!selectedDate || isShareholderFromJADIS) return false;

    const selectedShareholder = shareholder;
    const selectedPersonId: number = shareholder.shareholderPersons[0].personId;
    const selectedGroupId: number | null = shareholder.shareholderGroupId;
    const allShareholders: ShareholdersProps[] = getAllShareholders(shareholdersData?.shareholders);

    const filteredList: ShareholdersProps[] = allShareholders
      .filter((shareholder) => {
        if (shareholder.shareholderGroupId === selectedGroupId) {
          return shareholder.shareholderPersons.some(
            (person) => person.personId === selectedPersonId
          );
        }
        return false;
      })
      .sort(sortShareholders);

    const selectedShareholderIndex = filteredList.findIndex((shareholder) => {
      return (
        shareholder.shareholderHistory[shareholder.shareholderHistory.length - 1].eventDate ===
        selectedShareholder.shareholderHistory[selectedShareholder.shareholderHistory.length - 1]
          .eventDate
      );
    });

    if (0 >= selectedShareholderIndex) {
      return false;
    }

    const prevShareholder = filteredList[selectedShareholderIndex - 1];
    const activityPeriod = getCurrentPeriod(selectedDate, shareholdersData?.activityPeriods || []);
    if (prevShareholder.shareholderStoppedParticipatingFromDate !== null) {
      const isTheFirstEvent =
        selectedShareholder.shareholderHistory.filter((e) => e.documentStatus !== null)[0]
          ?.eventDate === selectedDate;

      return (
        isTheFirstEvent &&
        activityPeriod.startDate > prevShareholder.shareholderStoppedParticipatingFromDate
      );
    }
    return false;
  };

  const showEndOfParticipationCheckbox =
    !isShareholderFromJADIS &&
    !isNextShareholderEventSigned(shareholder.shareholderHistory, selectedDate);

  const handleAddPercentageSubmit = (values: ShareholderPercentages) => {
    const areSubmitAndInitialValuesEqual = isEqual(formedInitialValues, values);

    if (areSubmitAndInitialValuesEqual) {
      onClose();
    } else if (virsData && selectedDate) {
      const formedPercentageRecord = getFormedPercentageRecord(
        values,
        virsData.virsId,
        parentId,
        shareholderId,
        selectedDate
      );
      reduxDispatch(
        addShareholderPercentageRequest(formedPercentageRecord, virsData.virsId, selectedDate)
      );
      reduxDispatch(addShareholderDateReset());
    }
  };

  return (
    <>
      <Formik
        initialValues={formedInitialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleAddPercentageSubmit}
      >
        {({ submitForm, values, setFieldValue }) => (
          <AddPercentageForm
            parentShareholder={parentShareholder}
            shareholderEvent={shareholderEvent}
            showParticipateBeforeCheckbox={showParticipateBeforeCheckbox}
            showStoppedParticipatingBeforeCheckbox={showStoppedParticipatingBeforeCheckbox()}
            formValues={values}
            showEndOfParticipationCheckbox={showEndOfParticipationCheckbox}
            submitForm={submitForm}
            onClose={onClose}
            setFieldValue={setFieldValue}
          />
        )}
      </Formik>
    </>
  );
};
