import moment from 'moment';
import {
  EditionCheckEditRecordState,
  EditionCheckEditRecordAction,
  initialEditionCheckEditRecordState,
  initialDocumentsState
} from './initialStateAndTypes';
import {
  validateEditionCheckEditRecordState,
  addFileAndValidateDocuments,
  removeFileAndValidateDocuments,
  setCustomNameByExistingOrNull,
  removeExistingDocumentAndValidateDocuments,
  getUpdatedOutletOptions
} from './editionCheckRowReducerFunctions';
import {
  setDocumentNumberAndValidate,
  updateDateValue
} from '../../../../utils/InputValueFunctions';
import produce from 'immer';

export const editEditionCheckRecordReducer = (
  state: EditionCheckEditRecordState,
  action: EditionCheckEditRecordAction
): EditionCheckEditRecordState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return {
        ...state,
        editingOn: true,
        editionCheckId: action.record.editionCheckId,
        virs: {
          ...state.virs,
          value: {
            ...state.virs.value,
            virId: action.record.virsId,
            personFullName: action.record.virsName,
            personCode: String(action.record.virsLegalCode),
            virsLegalRegistrationDate: '',
            virsLegalDeregistrationDate: '',
            outlets: action.record.outlets
          }
        },
        checkDate: {
          ...state.checkDate,
          value: action.record.checkDate ? moment(action.record.checkDate) : null,
          validated: true
        },
        customDocumentsName: setCustomNameByExistingOrNull(
          action.record.editionCheckDocs,
          state.customDocumentsName
        ),
        editionCheckDocs: action.record.editionCheckDocs,
        addedDocuments: initialDocumentsState,
        editionCheckOutlets: action.record.editionCheckOutlets.map((outlet) => ({
          outlet: {
            value: {
              outletId: outlet.outletId,
              outletName: outlet.outletName,
              outletClosureStatus: outlet.outletClosureStatus
            },
            error: false,
            helperText: '',
            placeholder: '',
            validated: false,
            options: action.record.outlets
          },
          loadingPeriods: false,
          editionCheckConclusions: outlet.editionCheckConclusions.map((conclusion) => ({
            period: {
              value: {
                periodId: conclusion.periodId,
                periodName: conclusion.periodName,
                periodCode: '',
                validFrom: '',
                validTo: ''
              },
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: []
            },
            samePeriodError: false,
            conclusion: {
              value: {
                editionCheckConclusionId: conclusion.conclusionId,
                editionCheckConclusionCode: '',
                editionCheckConclusionTypeName: conclusion.conclusion,
                validFrom: '',
                validTo: ''
              },
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: action.editionCheckConclusionTypes
            }
          }))
        })),
        outlets: action.record.outlets,
        conclusionTypes: action.editionCheckConclusionTypes
      };
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return {
        ...state,
        virs: {
          ...state.virs,
          value: action.virs,
          validated: true,
          error: false
        },
        editionCheckOutlets: [
          {
            outlet: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: action.virs.outlets
            },
            loadingPeriods: false,
            editionCheckConclusions: [
              {
                period: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: []
                },
                samePeriodError: false,
                conclusion: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: action.conclusionTypes
                }
              }
            ]
          }
        ],
        showFindVirsDialog: false,
        outlets: action.virs.outlets
      };
    case 'CHECK_DATE_CHANGED':
      return {
        ...state,
        checkDate: updateDateValue(action.checkDate, state.checkDate)
      };
    case 'FILE_ADDED':
      return {
        ...state,
        addedDocuments: addFileAndValidateDocuments(
          action.file,
          state.addedDocuments,
          state.editionCheckDocs.length
        )
      };
    case 'FILE_REMOVED':
      return {
        ...state,
        addedDocuments: removeFileAndValidateDocuments(
          action.fileId,
          state.addedDocuments,
          state.editionCheckDocs.length
        )
      };
    case 'FILE_ADDING_WRONG_FORMAT':
      return {
        ...state,
        addedDocuments: {
          ...state.addedDocuments,
          error: true,
          errorMessage: action.errorMessage
        }
      };
    case 'DOCUMENT_REMOVED':
      return removeExistingDocumentAndValidateDocuments(action.documentId, state);
    case 'FILES_CUSTOM_NAME_CHANGED':
      return {
        ...state,
        customDocumentsName: setDocumentNumberAndValidate(
          action.filesCustomName,
          state.customDocumentsName
        ),
        editionCheckDocs: state.editionCheckDocs.map((doc, docIndex) => ({
          ...doc,
          docNr:
            docIndex === 0 ? action.filesCustomName : `${action.filesCustomName}-${docIndex + 1}`
        }))
      };
    case 'EDITION_CHECK_OUTLETS_CHANGED':
      return produce(state, (draft) => {
        draft.editionCheckOutlets[action.outletIndex] = {
          ...draft.editionCheckOutlets[action.outletIndex],
          outlet: {
            ...draft.editionCheckOutlets[action.outletIndex].outlet,
            value: action.outlet
          },
          loadingPeriods: !!action.outlet
        };
        draft.editionCheckOutlets.forEach((outletInput) => {
          outletInput.outlet.options = getUpdatedOutletOptions(draft);
        });
      });
    case 'PERIODS_LOADED':
      return {
        ...state,
        editionCheckOutlets: state.editionCheckOutlets.map((outlet) => ({
          ...outlet,
          loadingPeriods: false,
          editionCheckConclusions: outlet.editionCheckConclusions.map((conclusion) => ({
            ...conclusion,
            period: {
              ...conclusion.period,
              options: outlet.outlet.value?.outletId
                ? action.missingPeriodsForOutlet.get(outlet.outlet.value.outletId) || []
                : []
            }
          }))
        }))
      };
    case 'EDITION_CHECK_PERIOD_CHANGED':
      return validateEditionCheckEditRecordState(
        produce(state, (draft) => {
          draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
            action.conclusionIndex
          ].period = {
            ...draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
              action.conclusionIndex
            ].period,
            value: action.period
          };
        })
      );
    case 'EDITION_CHECK_CONCLUSION_CHANGED':
      return validateEditionCheckEditRecordState(
        produce(state, (draft) => {
          draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
            action.conclusionIndex
          ].conclusion = {
            ...draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
              action.conclusionIndex
            ].conclusion,
            value: action.conclusion
          };
        })
      );
    case 'EDITING_CANCELLED':
      return initialEditionCheckEditRecordState;
    case 'UPDATE_RECORD_CLICKED':
      return validateEditionCheckEditRecordState(state);
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateRecordConfirmationOn: false,
        editingOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'CLEAR_RECORD_VALID_TO_DATE_CLICKED':
      return {
        ...state,
        updateRecordConfirmationOn: true
      };
    case 'ADD_OUTLET_ROW':
      const tempStateVip = validateEditionCheckEditRecordState(state);
      if (!tempStateVip.updateRecordConfirmationOn) {
        return tempStateVip;
      }
      return {
        ...state,
        editionCheckOutlets: [
          ...state.editionCheckOutlets,
          {
            outlet: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: getUpdatedOutletOptions(state)
            },
            loadingPeriods: false,
            editionCheckConclusions: [
              {
                period: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: []
                },
                samePeriodError: false,
                conclusion: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: state.conclusionTypes
                }
              }
            ]
          }
        ]
      };
    case 'CANCEL_OUTLET_ROW':
      return produce(state, (draft) => {
        draft.editionCheckOutlets.splice(action.outletIndex, 1);
        draft.editionCheckOutlets.forEach((outletInput) => {
          outletInput.outlet.options = getUpdatedOutletOptions(draft);
        });
      });
    case 'ADD_PERIOD_ROW':
      const tempStatePeriod = validateEditionCheckEditRecordState(state);
      if (!tempStatePeriod.updateRecordConfirmationOn) {
        return tempStatePeriod;
      }
      return produce(state, (draft) => {
        draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions = [
          ...draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions,
          {
            period: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options:
                draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[0].period
                  .options
            },
            samePeriodError: false,
            conclusion: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: state.conclusionTypes
            }
          }
        ];
      });
    case 'REDUCE_PERIOD_ROW':
      return produce(state, (draft) => {
        draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions.splice(
          action.conclusionIndex,
          1
        );
      });
    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },
        annulRecordConfirmationOn: true
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        annulRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
