import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  title: string | ReactNode;
  label?: string;
  date: string | null;
  linkToDocument?: ReactNode;
}

const ExtendedRowDetailsBlock: React.FC<Props> = ({ title, label, date, linkToDocument }) => {
  return (
    <div className="details-block">
      <Typography className="details-block-row" variant="h5">
        <span className="extended-row-label">{title}</span>
      </Typography>

      <Typography className="details-block-row" variant="h5">
        <span className="extended-row-label">{label}</span>

        <span className="extended-row-date">{date}</span>
        {linkToDocument}
      </Typography>
    </div>
  );
};

export default ExtendedRowDetailsBlock;
