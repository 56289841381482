import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Paper, Table, TableBody, TableHead, Typography, makeStyles } from '@material-ui/core';
import {
  providedDataTableColumns,
  ProvidedDataTableField
} from './tableState/tableInitialStateAndTypes';
import { filterAndSortProvidedVirsTableData } from './tableState/tableStateFunctions';
import { pageTableData } from '../../utils/tableDataFunctions';
import { ProvidedDataVirs } from '../../store/providedData/providedDataTypes';
import { ApplicationState } from '../../store';
import { ProvidedDataTableDispatchContext, ProvidedDataTableStateContext } from './Context';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { ProvidedDataTableHeaders } from './TableHeaders';
import ProvidedDataTableFilterRow from './FilterRow';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { ProvidedDataTableRow } from './TableRow/TableRow';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';

interface Props {
  loading: boolean;
  data: ProvidedDataVirs[] | undefined;
  dataError: Error | undefined;
}
const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  }
}));
export const ProvidedDataTable: React.FC<Props> = ({ loading, data, dataError }) => {
  const { state: tableState } = useContext(ProvidedDataTableStateContext);
  const { dispatch: tableDispatch } = useContext(ProvidedDataTableDispatchContext);

  const { currentUser } = useSelector((state: ApplicationState) => state.virsis);
  const classes = useStyles();

  const filteredAndSorted: ProvidedDataVirs[] = filterAndSortProvidedVirsTableData(
    tableState,
    data || []
  );
  const { page: dataPage, pagesCount } = pageTableData(tableState, filteredAndSorted);

  function setFilter(column: ProvidedDataTableField, value: string | null) {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  }

  function setSorting(column: ProvidedDataTableField): void {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  }

  function setPage(value: number): void {
    tableDispatch({ type: 'PAGE_SET', page: value });
  }

  function setRowsPerPage(rowsPerPage: number) {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  }

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: ProvidedDataTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: ProvidedDataTableField) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  useEffect(() => {
    if (data) {
      tableDispatch({
        type: 'TABLE_INITIALIZED'
      });
    }
  }, [tableDispatch, data, currentUser]);

  return (
    <Container maxWidth="lg">
      <div className="data-page">
        <div className="data-page-header">
          <Box display="flex" alignItems="center">
            <Typography variant="h1">El. dokumentai</Typography>
            <Box className={classes.buttonWrapper}>
              <PageActionButtons
                dontShowDelete
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    dataPage,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, dataPage, navigationPath)
                }
              />
            </Box>
          </Box>
        </div>
        <div>
          <div className="data-table">
            <Paper elevation={0}>
              <div className="CustomizationBar">
                <TableToolsFrame
                  rightSideItem={
                    <TableCustomTools
                      tableColumns={providedDataTableColumns}
                      showFilter={tableState.customFilterOn}
                      onFilterTabToggle={toggleDisplayCustomFilter}
                      sortOrder={tableState.order}
                      sortBy={tableState.sortBy}
                      onSortByColumnClick={handleSetSortByColumn}
                      columnsDisplayStatus={tableState.columnsDisplayStatus}
                      toggleDisplayColumn={toggleDisplayColumn}
                    />
                  }
                />
              </div>

              <ScrollXContainer>
                <Box style={{overflow: 'auto'}}>
                  <Table>
                    <TableHead>
                      <ProvidedDataTableHeaders
                        columnParams={providedDataTableColumns}
                        columnsDisplayStatus={tableState.columnsDisplayStatus}
                        order={tableState.order}
                        sortBy={tableState.sortBy}
                        setSorting={setSorting}
                      />
                    </TableHead>
                    {tableState.customFilterOn && (
                      <ProvidedDataTableFilterRow
                        columnParams={providedDataTableColumns}
                        columnsDisplayStatus={tableState.columnsDisplayStatus}
                        onCustomFilterChange={setFilter}
                        tableData={data || []}
                      />
                    )}
                    <TableBody>
                      <TableCircularProgressRow isLoading={loading} colSpan={9} />

                      <TableErrorRow
                        error={dataError && 'Klaida. Nepavyko gauti el. dokumentų duomenų'}
                        colSpan={9}
                      />
                      {!loading &&
                        dataPage.map((record) => {
                          return (
                            <ProvidedDataTableRow
                              key={record.documentStatusId}
                              record={record}
                              columnsToDisplay={tableState.columnsDisplayStatus}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </ScrollXContainer>

              <TablePagination
                recordsCount={filteredAndSorted.length}
                pagesCount={pagesCount}
                rowsPerPage={tableState.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                page={tableState.page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </Container>
  );
};
