export const PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST =
  '@professionalMisconductVirs/FETCH_VIRS_DATA_REQUEST';
export const PROFESSIONAL_MISCONDUCT_VIRS_DATA_SUCCESS =
  '@professionalMisconductVirs/FETCH_DATA_SUCCESS';
export const PROFESSIONAL_MISCONDUCT_VIRS_DATA_ERROR =
  '@professionalMisconductVirs/FETCH_DATA_ERROR';
export const PROFESSIONAL_MISCONDUCT_VIRS_DOCUMENT_PDF_REQUEST =
  '@professionalMisconductVirs/DOCUMENT_PDF_REQUEST';

export const PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST =
  '@professionalMisconductInst/FETCH_DATA_REQUEST';
export const PROFESSIONAL_MISCONDUCT_INST_DATA_SUCCESS =
  '@professionalMisconductInst/FETCH_DATA_SUCCESS';
export const PROFESSIONAL_MISCONDUCT_INST_DATA_ERROR =
  '@professionalMisconductInst/FETCH_DATA_ERROR';

export const CREATE_PROFESSIONAL_MISCONDUCT_REQUEST = '@professionalMisconductInst/CREATE_REQUEST';
export const CREATE_PROFESSIONAL_MISCONDUCT_SUCCESS = '@professionalMisconductInst/CREATE_SUCCESS';
export const CREATE_PROFESSIONAL_MISCONDUCT_ERROR = '@professionalMisconductInst/CREATE_ERROR';
export const RESET_CREATE_PROFESSIONAL_MISCONDUCT_STATE =
  '@professionalMisconductInst/RESET_CREATE_PROFESSIONAL_MISCONDUCT_STATE';

export const UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST = '@professionalMisconductInst/UPDATE_REQUEST';
export const UPDATE_PROFESSIONAL_MISCONDUCT_SUCCESS = '@professionalMisconductInst/UPDATE_SUCCESS';
export const UPDATE_PROFESSIONAL_MISCONDUCT_ERROR = '@professionalMisconductInst/UPDATE_ERROR';
export const RESET_UPDATE_PROFESSIONAL_MISCONDUCT_STATE =
  '@professionalMisconductInst/RESET_UPDATE_PROFESSIONAL_MISCONDUCT_STATE';

export const REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST = '@professionalMisconductInst/REMOVE_REQUEST';
export const REMOVE_PROFESSIONAL_MISCONDUCT_SUCCESS = '@professionalMisconductInst/REMOVE_SUCCESS';
export const REMOVE_PROFESSIONAL_MISCONDUCT_ERROR = '@professionalMisconductInst/REMOVE_ERROR';
export const RESET_REMOVE_PROFESSIONAL_MISCONDUCT_STATE =
  '@professionalMisconductInst/RESET_REMOVE_STATE';

export const ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST = '@ethicalNonCompliance/ANNUL_REQUEST';
export const ANNUL_PROFESSIONAL_MISCONDUCT_SUCCESS = '@ethicalNonCompliance/ANNUL_SUCCESS';
export const ANNUL_PROFESSIONAL_MISCONDUCT_ERROR = '@ethicalNonCompliance/ANNUL_ERROR';
export const RESET_ANNUL_PROFESSIONAL_MISCONDUCT_STATE = '@ethicalNonCompliance/RESET_ANNUL_STATE';

export const PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST =
  '@ethicalNonCompliance/PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST';
