import React from 'react';

import {
  Order,
  ColumnHeaderField,
  ColumnsDisplayStatus,
  PoliticalAdColumnHeader
} from '../tableState/tableTypes';

import { TableToolsFrame } from '../../../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../../../components/TableCustomTools/TableCustomTools';

interface Props {
  columnNames: PoliticalAdColumnHeader[];
  order: Order;
  sortBy: ColumnHeaderField;
  onSortByColumnClick: (column: ColumnHeaderField) => void;
  onDisplayColumnChecked: (column: ColumnHeaderField) => void;
  columnsDisplayStatus: ColumnsDisplayStatus;
  onFilterTabToggle: () => void;
  customFilterOn: boolean;
}

const CustomizationBar: React.FC<Props> = ({
  columnNames,
  order,
  sortBy,
  onSortByColumnClick,
  onDisplayColumnChecked,
  columnsDisplayStatus,
  onFilterTabToggle,
  customFilterOn
}) => {
  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={customFilterOn}
            onFilterTabToggle={onFilterTabToggle}
            sortOrder={order}
            sortBy={sortBy}
            onSortByColumnClick={onSortByColumnClick}
            columnsDisplayStatus={columnsDisplayStatus}
            toggleDisplayColumn={onDisplayColumnChecked}
          />
        }
      />
    </div>
  );
};
export default CustomizationBar;
