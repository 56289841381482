import React from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { noOptionsText } from './constants';
import { Option } from './TableCustomFilterForOptionCellGeneric';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Props {
  uniqueOptions: Option[];
  name: string;
  setFilter(column: string, value: string[]): void;
}

export const TableCustomFilterForMultipleOptionAutocomplete: React.FC<Props> = ({
  uniqueOptions,
  name,
  setFilter
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function handleChange(event: React.ChangeEvent<unknown>, value: Option[]) {
    setFilter(
      name,
      value.map((option) => option?.value?.toString() || '')
    );
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      multiple
      clearOnEscape
      id={`filter-${name}`}
      options={uniqueOptions}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      getOptionLabel={(option) => (option.value ? option.value.toString() : '')}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            color="primary"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </>
      )}
      disableCloseOnSelect
      limitTags={2}
    />
  );
};
