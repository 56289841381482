import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShareholderFullNameWithButtons } from '../../../../components/ShareholderComponents/ShareholderFullNameWithButtons';
import {
  ShareholdersProps,
  ShareholdersData,
  LegalPerson,
  ShareholderPerson,
  ClearShareholderPercentageRecord
} from '../../../../store/shareholders/shareholdersTypes';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { mainDarkColor } from '../../constants';
import { filterErrorObjs, getSortedPersonsArray } from '../../utilityFunctions/sharedFunctions';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import { ExtendedRowShareholderSelectedDate } from '../Components/ExtendedDetailsRow/ExtendedRow';
import {
  useShareholdersSelectedDateTableState,
  useShareholdersSelectedDateTableDispatch
} from '../TableWithContext';
import {
  getParentPathAndStatus,
  hasShareholderUnsignedDocs,
  getIsLastShareholder,
  showExtendedDetailsButton
} from '../utilities/utilityFunctions';
import { ShareholderGroupRows } from './ShareholderGroupRows';
import { ShareholdersChartSelectedDate } from './ShareholdersChartSelectedDate';
import { EditShareholderRowContainer } from './EditShareholderRow/EditShareholderRowContainer';
import { ApplicationState } from '../../../../store';
import { SelectShareholderRow } from './SelectShareholderRow';
import {
  setExpandParents,
  setExpandShareholder,
  unsetUpdateShareholderId
} from '../../../../store/shareholders/shareholdersActions';
import { TooltipOnRowRibbon } from '../../../../components/Tooltips/TooltipOnRowRibbon';
import { LevelBar } from '../../../../components/ShareholderComponents/TableButtons/LevelBar';

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  previousDate?: string;
  followingDate?: string;
  selectedDate: string;
  parentShareholder: ShareholdersProps | ShareholdersData;
  virId: number;
  groupMemberParentPerson?: ShareholderPerson;
  isLastShareholder: boolean;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  filteredShareholderLevel?: string;
}

const useStyles = makeStyles({
  shareholdersColumn: {
    paddingLeft: '8px',
    verticalAlign: 'middle',
    position: 'relative'
  },
  rowRibbonUnsigned: {
    boxShadow: '8px 0 0 #50C9F3 inset'
  },
  levelNum: {
    border: '1px solid #006FB2',
    padding: '2px',
    borderRadius: '100%',
    backgroundColor: '#006FB2',
    width: 'fit-content',
    verticalAlign: 'middle'
  },
  darkTextColor: {
    color: mainDarkColor
  }
});

export const ShareholderRows: React.FC<Props> = ({
  index,
  shareholder,
  previousDate,
  followingDate,
  selectedDate,
  parentShareholder,
  virId,
  groupMemberParentPerson,
  isLastShareholder,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  filteredShareholderLevel
}) => {
  const classes = useStyles();

  const { state: tableState } = useShareholdersSelectedDateTableState();
  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();
  const {
    shareholdersData: { shareholderToEditId, shareholdersSelectedDateData, expandedShareholders },
    virsis: { currentUser }
  } = useSelector((state: ApplicationState) => state);
  const reduxDispatch = useDispatch();
  const [showRowExtension, setShowRowExtention] = useState(false);

  const {
    shareholderId,
    shareholderGroupId,
    shareholderPath,
    shareholderLevel,
    shareholderStoppedParticipatingFromDate,
    shareholderPersons,
    shareholderHistory,
    mayShareholderHaveChildren,
    closedWithDocument
  } = shareholder;

  const { errors } = shareholderPersons[0];

  const handleShowChildShareholder = () => {
    reduxDispatch(setExpandShareholder(shareholderPath, undefined, index));
    if (filteredShareholderLevel) {
      tableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: true });
      tableDispatch({
        type: 'CUSTOM_FILTER_VALUE_CHANGED',
        filterBy: 'shareholderLevel',
        value: null
      });
    }
  };

  const showSelectRow =
    tableState.showSelectShareholderRow &&
    !shareholderGroupId &&
    tableState.parentIdToAddChild &&
    tableState.index === index &&
    tableState.parentIdToAddChild === shareholderId;

  const showAddShareholderButton =
    mayShareholderHaveChildren && closedWithDocument?.documentStatus !== 'PASIRASYTAS';

  const showChildShareholder = expandedShareholders[shareholderPath + '__' + index];

  const showGroupMembers = shareholderGroupId && showChildShareholder;

  const handleAddShareholderButtonClicked = () => {
    if (!showChildShareholder) {
      handleShowChildShareholder();
    }
    tableDispatch({
      type: 'SET_PARENT_ID_TO_ADD_CHILD',
      id: shareholderId,
      index: index
    });
    tableDispatch({
      type: 'SET_PARENT_PERSON_LEVEL_ID_TO_ADD_CHILD'
    });
    tableDispatch({
      type: 'SHOW_SELECT_SHAREHOLDER_ROW',
      showSelectShareholderRow: true
    });
    reduxDispatch(setExpandParents(shareholderPath));
    reduxDispatch(unsetUpdateShareholderId());
  };

  const isLegalPerson = shareholderPersons.some(
    (person) => person.personType === LegalPerson.LJA || person.personType === LegalPerson.UJA
  );

  const isSelectedDateEventDate = shareholderHistory.some(
    (event) => event.eventDate === selectedDate
  );

  const criticalErrorMessages = filterErrorObjs(errors, ErrorType.CRITICAL);
  const nonCriticalErrorMessages = filterErrorObjs(errors, ErrorType.NONCRITICAL);
  const infoNotifications = filterErrorObjs(errors, ErrorType.INFO);

  const notEditableShareholder = shareholderToEditId !== shareholderId;

  const tempShareholder: ClearShareholderPercentageRecord = {
    virsId: virId,
    shareholderId: shareholder.shareholderId,
    eventDate: selectedDate
  };

  const sortedPersons = getSortedPersonsArray(shareholderPersons);

  const hasUnsignedDocs = hasShareholderUnsignedDocs(
    shareholderHistory,
    shareholderStoppedParticipatingFromDate,
    closedWithDocument,
    selectedDate
  );

  const isItGroupShareholder = !!shareholder.shareholderGroupId;

  const showTriangleButton =
    isItGroupShareholder ||
    shareholder.shareholderPersons.some((person) => person.shareholders.length);

  const parentsLevelBarStatus =
    shareholderLevel > 1 && !!shareholdersSelectedDateData
      ? getParentPathAndStatus(shareholdersSelectedDateData.shareholders, shareholderPath)
      : undefined;

  return (
    <React.Fragment key={shareholderId}>
      {notEditableShareholder && (
        <TableRow>
          <TableCell>
            <Typography variant="h5" className={classes.darkTextColor}>
              {shareholderLevel}
            </Typography>
          </TableCell>
          <TooltipOnRowRibbon status={hasUnsignedDocs ? 'RUOSIAMAS' : undefined}>
            <TableCell
              className={`${classes.shareholdersColumn} ${
                hasUnsignedDocs ? classes.rowRibbonUnsigned : undefined
              }`}
            >
              <LevelBar
                level={shareholderLevel}
                showChildShareholder={showChildShareholder}
                halfBottom={showTriangleButton}
                isItGroupShareholder={!!shareholderGroupId}
                middle
                isLastShareholder={isLastShareholder}
                parentsLevelBarStatus={parentsLevelBarStatus}
              />

              <ShareholderFullNameWithButtons
                index={index}
                shareholder={shareholder}
                shareholderPersons={shareholderGroupId ? sortedPersons : shareholderPersons}
                showChildShareholder={showChildShareholder}
                handleShowChildShareholder={handleShowChildShareholder}
                handleAddShareholderButtonClicked={handleAddShareholderButtonClicked}
                showRowExtension={showRowExtension}
                setShowRowExtention={() => setShowRowExtention(!showRowExtension)}
                mayShowErrorIcons={!shareholderGroupId}
                criticalErrorMessages={criticalErrorMessages}
                nonCriticalErrorMessages={nonCriticalErrorMessages}
                infoNotifications={infoNotifications}
                showAddShareholderButton={showAddShareholderButton}
                mayShowOptionsButton
                marginLeft={getMarginLeft(shareholderLevel)}
                showExtendedDetailsButton={showExtendedDetailsButton(shareholder, selectedDate)}
                tempShareholder={tempShareholder}
                selectedDate={selectedDate}
              />
            </TableCell>
          </TooltipOnRowRibbon>
          <ShareholdersChartSelectedDate
            parentShareholder={groupMemberParentPerson || parentShareholder}
            shareholder={shareholder}
            previousDate={previousDate}
            followingDate={followingDate}
            selectedDate={selectedDate}
            isLegalPerson={isLegalPerson}
            isSelectedDateEventDate={isSelectedDateEventDate}
            virsEndDate={virsEndDate}
            virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
          />
        </TableRow>
      )}

      {showRowExtension && notEditableShareholder && (
        <ExtendedRowShareholderSelectedDate
          parentShareholder={parentShareholder}
          shareholder={shareholder}
          isSelectedDateEventDate={isSelectedDateEventDate}
          selectedDate={selectedDate}
          currentUser={currentUser}
        />
      )}

      {showSelectRow && (
        <SelectShareholderRow shareholderLevel={shareholderLevel + 1} index={index} />
      )}

      <EditShareholderRowContainer
        index={index}
        shareholder={shareholder}
        parentShareholder={parentShareholder}
        groupMemberParentPerson={groupMemberParentPerson}
      />

      {showGroupMembers &&
        notEditableShareholder &&
        sortedPersons.map((person) => (
          <ShareholderGroupRows
            index={index}
            key={person.personId + selectedDate}
            shareholder={shareholder}
            person={person}
            previousDate={previousDate}
            followingDate={followingDate}
            selectedDate={selectedDate}
            parentShareholder={parentShareholder}
            virId={virId}
            virsEndDate={virsEndDate}
            virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
            filteredShareholderLevel={filteredShareholderLevel}
          />
        ))}

      {!shareholderGroupId &&
        notEditableShareholder &&
        showChildShareholder &&
        shareholderPersons.map((person) =>
          person.shareholders.map((childShareholder, newIndex) => (
            <ShareholderRows
              index={index + newIndex}
              parentShareholder={shareholder}
              key={`${person.personId}${childShareholder.shareholderId}`}
              shareholder={childShareholder}
              previousDate={previousDate}
              followingDate={followingDate}
              selectedDate={selectedDate}
              virId={virId}
              isLastShareholder={getIsLastShareholder(newIndex, person.shareholders)}
              virsEndDate={virsEndDate}
              virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
              filteredShareholderLevel={filteredShareholderLevel}
            />
          ))
        )}
    </React.Fragment>
  );
};
