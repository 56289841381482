import moment from 'moment';
import {
  ActivityPeriod,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../../store/shareholders/shareholdersTypes';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { ColumnDefinition, TableExportDefinition } from '../../../../utils/exporters/types';
import {
  dateCalendarFormat,
  DateTypes,
  monthCalendarFormat,
  yearCalendarFormat
} from '../../constants';
import {
  currentDate,
  getCurrentPeriod,
  getHeaderDatesSortedUnique
} from '../../utilityFunctions/sharedFunctions';
import {
  buildMappedEventColumn,
  getFill,
  getValueAfterDate,
  mapCellStyle,
  ShareholderExportRowData,
  ShareholderExportSettings
} from '../../utilityFunctions/shareholderExportMapping';
import {
  CustomDatePickerProps,
  DatesWithEvents,
  KeyStringValueNumType,
  Period
} from './tableInitialStateAndTypes';
import { todaysDateString } from '../../../../utils/tableDataFunctions';

const checkIfDateErrorInVirs = (data: ShareholdersData, errorType: ErrorType, date: string) => {
  return data.errors.some((error) => {
    return (
      error.errorType === errorType &&
      error.errorMessages.some((errorMesage) => errorMesage.eventDate === date)
    );
  });
};

const checkIfDateErrorInShareholder = (
  shareholders: ShareholdersProps[],
  errorType: ErrorType,
  date: string
): boolean => {
  return shareholders.some((shareholder) =>
    shareholder.shareholderPersons.some((person) =>
      person.errors.some((error) => {
        const hasErrorOndate =
          error.errorType === errorType &&
          error.errorMessages.some((errorMessage) => errorMessage.eventDate === date);
        return (
          hasErrorOndate || checkIfDateErrorInShareholder(person.shareholders, errorType, date)
        );
      })
    )
  );
};

export const hasHeaderDateErrors = (data: ShareholdersData, errorType: ErrorType, date: string) => {
  const hasVirsError = checkIfDateErrorInVirs(data, errorType, date);
  if (hasVirsError) {
    return true;
  }
  return checkIfDateErrorInShareholder(data.shareholders, errorType, date);
};

// check if shareholder has any events on date
export const hasNoEvents = (shareholders: ShareholdersProps[], date: string): boolean => {
  const noEvents = !getAllEvents(shareholders).some(
    (event) => event.eventDate === date && event.documentDate !== null
  );
  const shareholderHasStoppedParticipating = !shareholders.some(
    (shareholder) => shareholder.shareholderStoppedParticipatingFromDate === date
  );
  return noEvents && shareholderHasStoppedParticipating;
};

export const getAllEvents = (shareholders: ShareholdersProps[]): ShareholdersHistory[] => {
  let allEvents: ShareholdersHistory[] = [];
  shareholders.forEach((shareholder) => {
    allEvents = allEvents.concat(shareholder.shareholderHistory);
    shareholder.shareholderPersons.forEach((person) => {
      if (person.shareholders.length > 0) {
        allEvents = allEvents.concat(getAllEvents(person.shareholders));
      }
    });
  });
  return allEvents;
};

// check if shareholder event date is unsigned
export const isDateUnsigned = (
  shareholders: ShareholdersProps[],
  activityPeriods: ActivityPeriod[],
  date: string
): boolean => {
  const allEvents = getAllEvents(shareholders);
  const currentActivePeriod = getCurrentPeriod(date, activityPeriods);
  const selectedDateEvents = allEvents.filter((event) => event.eventDate === date);
  if (
    allEvents.some((event) => event.eventDate === date && event.documentStatus === 'PASIRASYTAS')
  ) {
    return false;
  }
  if (
    allEvents.some(
      (event) =>
        event.eventDate === date &&
        event.documentStatus !== 'PASIRASYTAS' &&
        !event.isEventFromJADIS
    )
  ) {
    return true;
  }
  if (todaysDateString() === date) {
    return selectedDateEvents.some(
      (event) =>
        event.documentStatus === 'RUOSIAMAS' && event.documentId !== null && !event.isEventFromJADIS
    );
  }
  if (currentActivePeriod?.endDate === date) {
    return selectedDateEvents.some(
      (event) =>
        event.documentStatus === 'RUOSIAMAS' && event.documentId !== null && !event.isEventFromJADIS
    );
  }
  if (
    shareholders.some((shareholder) => shareholder.shareholderStoppedParticipatingFromDate === date)
  ) {
    const isStoppedParticipatingDateNotClosedWithSignedDocument = shareholders
      .filter((shareholder) => shareholder.shareholderStoppedParticipatingFromDate === date)
      .some((shareholder) => shareholder?.closedWithDocument?.documentStatus !== 'PASIRASYTAS');
    if (isStoppedParticipatingDateNotClosedWithSignedDocument) {
      return true;
    }
    return allEvents
      .filter((event) => event.eventDate <= date)
      .some((event) => event.documentStatus === 'RUOSIAMAS');
  }
  return !selectedDateEvents.some((event) => event.documentStatus === 'PASIRASYTAS');
};

export const setShowCellDetailsPopover = (
  prevState: KeyStringValueNumType,
  date?: string,
  id?: string
) => {
  if ((date && prevState[date] === id) || !date || !id) {
    return {};
  }
  return { [date]: id };
};

export const getEventsHistoryWithChanges = (
  shareholderHistory: ShareholdersHistory[],
  date: string,
  periodFilter: DateTypes
) => {
  return shareholderHistory.filter((event, index) => {
    let previousEvents = event.eventDate <= date;
    if (periodFilter === 'year') {
      previousEvents = moment(event.eventDate, 'YYYY').year().toString() <= date;
    }
    if (periodFilter === 'month') {
      previousEvents = moment(event.eventDate).format('YYYY-MM') <= date;
    }
    if (periodFilter === 'mixed') {
      if (moment(date, 'YYYY', true).isValid()) {
        previousEvents = moment(event.eventDate).format('YYYY') <= date;
      }
      if (moment(date, 'YYYY-MM', true).isValid()) {
        previousEvents = moment(event.eventDate).format('YYYY-MM') <= date;
      }
    }

    const eventsWithChangeOrFalsy =
      index === 0 ||
      (!event.sharePercentage && !event.votePercentage) || // if both values null - include
      event.sharePercentage !== shareholderHistory[index - 1]?.sharePercentage ||
      event.votePercentage !== shareholderHistory[index - 1]?.votePercentage;
    return previousEvents && eventsWithChangeOrFalsy;
  });
};

export const checkIfCurrentDateIsEvent = (data: ShareholdersData) => {
  const allDates = getHeaderDatesSortedUnique(false, data);
  return allDates?.some((date) => date === currentDate);
};

export function getTableExportDefinition(
  settings: ShareholderExportSettings
): TableExportDefinition<ShareholderExportRowData> {
  const columns: ColumnDefinition<ShareholderExportRowData>[] = buildMappedEventColumn(
    settings
  ).map((column, i) => ({
    ...column(
      settings.selectedDates[i],
      settings.selectedDates[i],
      true,
      ({ shareholderEvent }) => {
        if (settings.showShares) {
          return shareholderEvent?.sharePercentage?.toString();
        }
        if (settings.showVotes) {
          return shareholderEvent?.votePercentage?.toString();
        }
        return undefined;
      },
      getValueAfterDate
    ),
    style: { width: 12 }
  }));

  return {
    title: 'Dalyviai',
    columnGroups: [
      {
        columns: [
          {
            header: 'Lygis',
            cellMapper: (row) => [{ values: [`${row.level}`], style: mapCellStyle(row) }],
            identifier: 'level',
            visible: true
          },
          {
            header: 'Pavadinimas',
            cellMapper: ({ name, groupMember }) => [
              {
                values: name,
                style: {
                  alignment: { horizontal: 'left' },
                  fill: getFill(groupMember)
                }
              }
            ],
            identifier: 'name',
            visible: true,
            style: { width: 60 }
          },
          ...columns
        ]
      }
    ]
  };
}

export const findVirsStartDateToDrawColorBar = (
  periodFilter: string,
  virsStartDate: string,
  date: string
) => {
  if (periodFilter === 'changeDate') {
    return virsStartDate;
  }
  if (periodFilter === 'year') {
    return moment(virsStartDate, 'YYYY').year().toString();
  }
  if (periodFilter === 'month') {
    return moment(virsStartDate).format('YYYY-MM');
  }
  if (periodFilter === 'mixed' && moment(date, 'YYYY', true).isValid()) {
    return moment(virsStartDate).format('YYYY');
  }
  if (periodFilter === 'mixed' && moment(date, 'YYYY-MM', true).isValid()) {
    return moment(virsStartDate).format('YYYY-MM');
  }
  if (periodFilter === 'mixed' && moment(date, 'YYYY-MM-DD', true).isValid()) {
    return virsStartDate;
  }
  return virsStartDate;
};

export const setYearsPeriodWithEvents = (
  years: string[],
  allEventsDates: string[]
): DatesWithEvents[] => {
  const allEventsYears = allEventsDates.map((date) => moment(date).format('YYYY'));
  return years.map((el) => {
    return {
      date: el,
      hasEvent: allEventsYears.includes(moment(el).format('YYYY'))
    };
  });
};

export const getDatesWithEventsForHeader = (
  prevState: DatesWithEvents[],
  allEventsDates: string[],
  months: string[],
  clickedDate?: string,
  days?: string[]
): DatesWithEvents[] => {
  const allEventsMonths = allEventsDates.map((date) => moment(date).format('YYYY-MM'));

  const monthsInPeriodWithEvents = months.map((el) => {
    return {
      date: el,
      hasEvent: allEventsMonths.includes(moment(el).format('YYYY-MM'))
    };
  });

  const daysInPeriodWithEvents = days?.map((x) => {
    return {
      date: x,
      hasEvent: allEventsDates.includes(x)
    };
  });

  if (clickedDate && daysInPeriodWithEvents) {
    const allDates = [...daysInPeriodWithEvents, ...prevState];

    const finalSetOfDates = allDates
      .map((x) => {
        if (
          moment(x.date, 'YYYY-MM', true).isValid() &&
          days &&
          moment(days[0]).format('YYYY-MM') === x.date
        ) {
          return { date: '', hasEvent: false };
        }
        if (
          clickedDate !== moment(x.date).format('YYYY-MM') &&
          !moment(x.date, 'YYYY', true).isValid()
        ) {
          return { date: moment(x.date).format('YYYY-MM'), hasEvent: x.hasEvent };
        }
        return x;
      })
      .filter((x) => x.date !== '');

    const uniqueFinalSetOfDates: DatesWithEvents[] = Object.values(
      finalSetOfDates.reduce((acc, cur) => Object.assign(acc, { [cur.date]: cur }), {})
    );

    return uniqueFinalSetOfDates.sort(
      (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix()
    );
  }

  return monthsInPeriodWithEvents;
};

export const getDatesWithEventsForDays = (allEventsDates: string[], days: string[]) => {
  return days.map((el) => {
    return {
      date: el,
      hasEvent: allEventsDates.includes(el)
    };
  });
};

export const setInitialDatesToMonthsHeader = (
  _prevState: DatesWithEvents[],
  allEventsDates: string[],
  initialDates: string[]
): DatesWithEvents[] => {
  const allEventsMonths = allEventsDates.map((date) => moment(date).format('YYYY-MM'));
  return initialDates.map((el) => {
    return {
      date: el,
      hasEvent: allEventsMonths.includes(moment(el).format('YYYY-MM'))
    };
  });
};

export const getDatesWithEventsForYears = (
  _prevState: DatesWithEvents[],
  allEventsDates: string[],
  monthsArray: string[],
  yearsWithEvents: DatesWithEvents[],
  clickedDate?: string
): DatesWithEvents[] => {
  const allEventsMonths = allEventsDates.map((date) => moment(date).format('YYYY-MM'));

  const monthsInPeriodWithEvents = monthsArray.map((el) => {
    return {
      date: el,
      hasEvent: allEventsMonths.includes(moment(el).format('YYYY-MM'))
    };
  });

  if (clickedDate && monthsInPeriodWithEvents) {
    const allDates = [...monthsInPeriodWithEvents, ...yearsWithEvents];

    const finalSetOfDates = allDates
      .map((x) => {
        if (
          moment(x.date, 'YYYY', true).isValid() &&
          monthsArray &&
          moment(monthsArray[0]).format('YYYY') === x.date
        ) {
          return { date: '', hasEvent: false };
        }
        return x;
      })
      .filter((x) => x.date !== '');

    return finalSetOfDates.sort((a: any, b: any) => moment(a.date).unix() - moment(b.date).unix());
  }

  return yearsWithEvents;
};

// grazina atributus kalendoriukui pagal tai koks yra perziuros filtras
export const getCalenderDisplayProps = (periodFilter: Period): CustomDatePickerProps => {
  if (periodFilter.id === 'year') {
    return yearCalendarFormat;
  }
  if (periodFilter.id === 'month') {
    return monthCalendarFormat;
  }

  return dateCalendarFormat;
};
