import { Reducer } from 'redux';
import {
  FETCH_USER_MESSAGES_REQUEST,
  FETCH_USER_MESSAGES_SUCCESS,
  FETCH_USER_MESSAGES_ERROR,
  MARK_MESSAGES_READ_REQUEST,
  MARK_MESSAGES_READ_SUCCESS,
  MARK_MESSAGES_READ_ERROR,
  RESET_MARK_MESSAGES_READ_STATE,
  MARK_MESSAGES_UNREAD_REQUEST,
  MARK_MESSAGES_UNREAD_SUCCESS,
  MARK_MESSAGES_UNREAD_ERROR,
  RESET_MARK_MESSAGES_UNREAD_STATE,
  REMOVE_MESSAGES_REQUEST,
  REMOVE_MESSAGES_SUCCESS,
  REMOVE_MESSAGES_ERROR,
  RESET_REMOVE_MESSAGES_STATE
} from './userMessagesActionTypes';

import { UserMessage } from './userMessageTypes';

export interface UserMessagesState {
  userMessages?: UserMessage[];
  loadingUserMessages: boolean;
  userMessagesError?: Error;
  removingUserMessages: boolean;
  isUserMessagesRemoved: boolean;
  removingUserMessagesError?: Error;
  markingUserMessagesRead: boolean;
  isUserMessagesMarkedRead: boolean;
  markingUserMessagesReadError?: Error;
  markingUserMessagesUnread: boolean;
  isUserMessagesMarkedUnread: boolean;
  markingUserMessagesUnreadError?: Error;
}

export const initialUserMessagesState: UserMessagesState = {
  loadingUserMessages: false,
  userMessages: undefined,
  userMessagesError: undefined,
  removingUserMessages: false,
  isUserMessagesRemoved: false,
  removingUserMessagesError: undefined,
  markingUserMessagesRead: false,
  isUserMessagesMarkedRead: false,
  markingUserMessagesReadError: undefined,
  markingUserMessagesUnread: false,
  isUserMessagesMarkedUnread: false,
  markingUserMessagesUnreadError: undefined
};

export const userMessagesReducer: Reducer<UserMessagesState> = (
  state = initialUserMessagesState,
  action
) => {
  switch (action.type) {
    case FETCH_USER_MESSAGES_REQUEST:
      return {
        ...state,
        loadingUserMessages: true
      };
    case FETCH_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        loadingUserMessages: false,
        userMessages: action.payload.userMessages,
        userMessagesError: undefined
      };
    case FETCH_USER_MESSAGES_ERROR:
      return {
        ...state,
        loadingUserMessages: false,
        userMessages: [],
        userMessagesError: action?.payload?.data
      };
    case REMOVE_MESSAGES_REQUEST:
      return {
        ...state,
        removingUserMessages: true
      };
    case REMOVE_MESSAGES_SUCCESS:
      return {
        ...state,
        removingUserMessages: false,
        isUserMessagesRemoved: true
      };
    case REMOVE_MESSAGES_ERROR:
      return {
        ...state,
        removingUserMessages: false,
        isUserMessagesRemoved: false,
        removingUserMessagesError: action?.payload?.data
      };
    case RESET_REMOVE_MESSAGES_STATE:
      return {
        ...state,
        removingUserMessages: false,
        isUserMessagesRemoved: false,
        removingUserMessagesError: undefined
      };
    case MARK_MESSAGES_UNREAD_REQUEST:
      return {
        ...state,
        markingUserMessagesUnread: true
      };
    case MARK_MESSAGES_UNREAD_SUCCESS:
      return {
        ...state,
        markingUserMessagesUnread: false,
        isUserMessagesMarkedUnread: true
      };
    case MARK_MESSAGES_UNREAD_ERROR:
      return {
        ...state,
        markingUserMessagesUnread: false,
        isUserMessagesMarkedUnread: false,
        markingUserMessagesUnreadError: action?.payload?.data
      };
    case RESET_MARK_MESSAGES_UNREAD_STATE:
      return {
        ...state,
        markingUserMessagesUnread: false,
        isUserMessagesMarkedUnread: false,
        markingUserMessagesUnreadError: undefined
      };
    case MARK_MESSAGES_READ_REQUEST:
      return {
        ...state,
        markingUserMessagesRead: true
      };
    case MARK_MESSAGES_READ_SUCCESS:
      return {
        ...state,
        markingUserMessagesRead: false,
        isUserMessagesMarkedRead: true
      };
    case MARK_MESSAGES_READ_ERROR:
      return {
        ...state,
        markingUserMessagesRead: false,
        isUserMessagesMarkedRead: false,
        markingUserMessagesReadError: action?.payload?.data
      };
    case RESET_MARK_MESSAGES_READ_STATE:
      return {
        ...state,
        markingUserMessagesRead: false,
        isUserMessagesMarkedRead: false,
        markingUserMessagesReadError: undefined
      };
    default:
      return state;
  }
};
