import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Paper,
  Table,
  TableBody,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';

import { RouteComponentProps, useParams, withRouter } from 'react-router';
import Box from '@material-ui/core/Box/Box';
import { EthicalNonComplianceTableCustomizationBar } from './CustomizationBar';
import EthicalNonComplianceTableHead from './TableHead';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import {
  ROWS_PER_PAGE_OPTIONS,
  DatePickerState,
  DropdownMultipleStateGeneric,
  InputStateGeneric
} from '../../utils/tableTypes';
import { EthicalNonComplianceColumnHeader } from './tableState/tableInitialStateAndTypes';
import { ApplicationState } from '../../store';
import { useEthicalNonComplianceTableState, useEthicalNonComplianceTableDispatch } from './Context';
import EthicalNonComplianceTableFilterRow from './FilterRow';
import AllowedTo from '../AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import {
  fetchEthicalNonComplianceByDocId,
  fetchEthicalNonComplianceData
} from '../../store/ethicalNonCompliances/ethicalNonCompliancesActions';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import { EthicalNonComplianceRowContainerNew } from './TableRows/RowContainerNew';
import { EthicalNonComplianceRowContainer } from './TableRows/RowContainer';
import { OutletShortData } from '../../store/outlets/outletsTypes';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import {
  initializeEthicalNonComplianceDataTableState,
  setPage,
  setRowsPerPage
} from '../../store/ethicalNonComplianceTable/ethicalNonComplianceTableAction';
import { TableLinearProgressRow } from '../../components/TableLinearProgressRow/TableLinearProgressRow';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { unsignedDataRemoveInst } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { InstDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { AlertBox } from '../../components/Alert/AlertBox';

export const columnNames: EthicalNonComplianceColumnHeader[] = [
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'decisionDate',
    label: 'Sprendimo data',
    type: 'date'
  },
  {
    id: 'documentNumber',
    label: 'Sprendimo Nr.'
  },
  {
    id: 'outletList',
    label: 'VIP'
  },
  {
    id: 'decisionStatus',
    label: 'Būsena'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      tableBox: {
        flexWrap: 'wrap'
      },
      addButton: {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0,
        paddingLeft: 0
      },
      pageActionButtonBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  })
);

export const newOrUpdatedEthicalNonCompliance = (
  id: number | undefined,
  virId: number | null,
  decisionDate: DatePickerState,
  documentNumber: InputStateGeneric<string>,
  outlets: DropdownMultipleStateGeneric<OutletShortData>,
  decisionStatusId: number | undefined,
  validFrom: DatePickerState,
  validTo: DatePickerState
) => ({
  ethicalNonComplianceId: id,
  virsId: virId,
  decisionDate: decisionDate.value?.format('L').toString(),
  documentNumber: documentNumber.value,
  outletIdList: outlets.values.map((outlet) => ({
    outletId: outlet.outletId
  })),
  decisionStatusId,
  validFrom: validFrom.value?.format('L').toString(),
  validTo: validTo.value?.format('L').toString()
});

const EthicalNonComplianceTable: React.FC<RouteComponentProps> = () => {
  const { documentStatusId, companyCode } = useParams<{
    documentStatusId?: string;
    companyCode?: string;
  }>();

  const { state: tableState } = useEthicalNonComplianceTableState();
  const { dispatch: tableDispatch } = useEthicalNonComplianceTableDispatch();
  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);
  const reduxDispatch = useDispatch();
  const classes = useStyles();

  const {
    ethicalNonComplianceData: {
      loadingEthicalNonComplianceData,
      ethicalNonComplianceData,
      ethicalNonComplianceTypes,
      ethicalNonComplianceDataError,
      statusNotSignedFilterEnabled,
      statusOutdatedFilterEnabled,
      statusRelevantFilterEnabled,
      recordCount,
      annullingEthicalNonComplianceSuccess
    },
    ethicalNonComplianceTableData: { tableDataState, tempTableDataState },
    virsis: { currentUser },
    classifiers: { enterpriseTypes }
  } = useSelector((state: ApplicationState) => state);

  const [showDocumentAnulledAlert, setShowDocumentAnulledAlert] = useState(false);

  const handleSetPage = (value: number) => {
    reduxDispatch(setPage(value));
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    reduxDispatch(setRowsPerPage(rowsPerPage));
  };

  const showNewRecordRow = () => {
    tableDispatch({ type: 'SHOW_NEW_RECORD_CLICKED', companyCode: null });
  };

  let filteredData = ethicalNonComplianceData || [];
  if (documentStatusId) {
    filteredData = filteredData.filter(
      (record) => record.documentStatusId === Number(documentStatusId)
    );
  }

  useEffect(() => {
    if (currentUser?.authorities.includes('ROLE_VIEA_EDIT')) {
      reduxDispatch(
        initializeEthicalNonComplianceDataTableState(
          documentStatusId ? false : statusNotSignedFilterEnabled,
          documentStatusId ? false : statusRelevantFilterEnabled,
          documentStatusId ? false : statusOutdatedFilterEnabled,
          documentStatusId ? false : statusNotSignedFilterEnabled,
          documentStatusId ? false : statusRelevantFilterEnabled,
          !statusNotSignedFilterEnabled &&
            !statusRelevantFilterEnabled &&
            statusOutdatedFilterEnabled
        )
      );
    }
    if (!currentUser?.authorities.includes('ROLE_VIEA_EDIT')) {
      reduxDispatch(
        initializeEthicalNonComplianceDataTableState(
          false,
          documentStatusId ? false : statusRelevantFilterEnabled,
          documentStatusId ? false : statusOutdatedFilterEnabled,
          false,
          documentStatusId ? false : statusRelevantFilterEnabled,
          !statusNotSignedFilterEnabled &&
            !statusRelevantFilterEnabled &&
            statusOutdatedFilterEnabled
        )
      );
    }
  }, [
    reduxDispatch,
    currentUser,
    statusNotSignedFilterEnabled,
    statusRelevantFilterEnabled,
    statusOutdatedFilterEnabled,
    documentStatusId
  ]);

  useEffect(() => {
    if (documentStatusId) {
      reduxDispatch(fetchEthicalNonComplianceByDocId(Number(documentStatusId)));
    }
    if (
      JSON.stringify(tableDataState) !== JSON.stringify(tempTableDataState) &&
      !documentStatusId
    ) {
      reduxDispatch(fetchEthicalNonComplianceData());
    }
  }, [
    documentStatusId,
    reduxDispatch,
    tableDataState,
    tempTableDataState,
    tableState.customFilterOn
  ]);

  useEffect(() => {
    reduxDispatch(fetchEthicalNonComplianceData());
  }, [reduxDispatch]);

  useEffect(() => {
    if (companyCode) {
      tableDispatch({
        type: 'SHOW_NEW_RECORD_CLICKED',
        companyCode
      });
    }
  }, [companyCode, tableDispatch]);

  useEffect(() => {
    if (annullingEthicalNonComplianceSuccess) {
      setShowDocumentAnulledAlert(true);
    }
  }, [annullingEthicalNonComplianceSuccess]);

  return (
    <>
      <Container maxWidth="xl">
        <AllowedTo roles={[Roles.ROLE_VIEA_EDIT]}>
          <div className="data-page-header">
            <Typography variant="h1">Duomenų teikimas</Typography>
            <Typography style={{ margin: '10px 0' }} variant="subtitle1">
              Profesinės etikos nesilaikymo duomenys
            </Typography>
          </div>
          <Box display="flex" flexWrap="wrap" className={classes.tableBox}>
            <AddNewRecordButton
              text="Pridėti profesinės etikos nesilaikymo įrašą"
              onClicked={showNewRecordRow}
              disabled={
                !!ethicalNonComplianceDataError || tableState.showNewRecord || !!documentStatusId
              }
              className={classes.addButton}
            />
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              className={classes.pageActionButtonBox}
            >
              <PageActionButtons
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    filteredData,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, filteredData, navigationPath)
                }
                onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
              />
            </Box>
          </Box>
          <ConfirmRemoveUnsignedDialog
            onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
            onConfirm={() =>
              reduxDispatch(unsignedDataRemoveInst(InstDocumentType.ETHICAL_NONCOMPLIANCE))
            }
            onRemoveSuccess={() => reduxDispatch(fetchEthicalNonComplianceData())}
            open={confirmToDeleteUnsignedDialogOpen}
          />
        </AllowedTo>
      </Container>

      {showDocumentAnulledAlert && (
        <AlertBox
          handleClose={() => setShowDocumentAnulledAlert(false)}
          text={
            'Įrašas sėkmingai anuliuotas. Anuliuotus dokumentus galite peržiūrėti pasirinkę meniu „Duomenų istorija“.'
          }
        />
      )}

      <Container maxWidth="xl" style={{ marginTop: '1rem' }}>
        <Paper elevation={0}>
          <EthicalNonComplianceTableCustomizationBar
            columnNames={columnNames}
            disabled={tableState.showNewRecord || !!documentStatusId}
          />

          <ScrollXContainer>
            <Table>
              <EthicalNonComplianceTableHead />
              {tableState.customFilterOn && (
                <EthicalNonComplianceTableFilterRow
                  columnNames={columnNames}
                  dataToDisplay={filteredData}
                />
              )}
              <TableBody>
                <TableLinearProgressRow isLoading={loadingEthicalNonComplianceData} colSpan={9} />
                <TableErrorRow
                  error={
                    ethicalNonComplianceDataError &&
                    'Klaida. Nepavyko gauti etikos nesilaikymo duomenų'
                  }
                  colSpan={9}
                />
                {!loadingEthicalNonComplianceData &&
                  !ethicalNonComplianceDataError &&
                  tableState.showNewRecord && (
                    <EthicalNonComplianceRowContainerNew
                      enterpriseTypes={enterpriseTypes}
                      ethicalNonComplianceTypes={ethicalNonComplianceTypes}
                    />
                  )}
                {filteredData.map((record) => {
                  return (
                    <EthicalNonComplianceRowContainer
                      record={record}
                      key={`${record.ethicalNonComplianceId}`}
                      enterpriseTypes={enterpriseTypes}
                      ethicalNonComplianceTypes={ethicalNonComplianceTypes}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </ScrollXContainer>

          <TablePagination
            recordsCount={recordCount}
            pagesCount={Math.ceil(recordCount / tableDataState.rowsPerPage || 0)}
            rowsPerPage={tableDataState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableDataState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={filteredData.length === 0}
          />
        </Paper>
      </Container>
    </>
  );
};

export default withRouter(EthicalNonComplianceTable);
