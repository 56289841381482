import {
  ProvidedDataHistoryTableAction,
  ProvidedDataHistoryTableState,
  initialProvidedDataHistoryTableState,
  providedDataInstColumns
} from './tableInitialStateAndTypes';
import { getTableExportDefinition } from './tableStateFunctions';

export const providedDataHistoryTableReducer = (
  state: ProvidedDataHistoryTableState,
  action: ProvidedDataHistoryTableAction
): ProvidedDataHistoryTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialProvidedDataHistoryTableState.customFilter
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(
          providedDataInstColumns,
          columnsDisplayStatus
        )
      };
    }
    case 'DOCUMENT_DIALOG_OPENED':
      return {
        ...state,
        showDocumentDialog: true
      };
    case 'DOCUMENT_DIALOG_CLOSED':
      return {
        ...state,
        showDocumentDialog: false
      };
    default:
      return state;
  }
};
