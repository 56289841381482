import React, { useState } from 'react';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import 'moment/locale/lt';
import _ from 'lodash';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import { DatePickerState, ValidationDate } from '../../utils/tableTypes';
import theme from '../../style/virsisTheme';
import { Strings } from '../../utils/strings/Strings';

interface Props {
  state: DatePickerState;
  setDate: (date: MaterialUiPickersDate | null) => void;
  isRequired?: boolean;
  withoutCalendar?: boolean;
}

export const DateInputOnlyYear: React.FC<Props> = ({
  state,
  setDate,
  isRequired,
  withoutCalendar
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openCaledar = () => setIsOpen(true);
  const closeCaledar = () => setIsOpen(false);

  const minDateAllowed: ValidationDate | undefined = _.maxBy(state.minDates, 'date');
  const maxDateAllowed: ValidationDate | undefined = _.minBy(state.maxDates, 'date');

  const handleDateChange = (value: MaterialUiPickersDate | null) => {
    if (
      (minDateAllowed?.date &&
        maxDateAllowed?.date &&
        value?.isValid() &&
        value?.year() >= minDateAllowed?.date?.year() &&
        value?.year() <= maxDateAllowed?.date?.year()) ||
      value === null
    ) {
      setDate(value || null);
    }
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <div
        style={{
          minWidth: '170px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center'
        }}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            views={['year']}
            autoOk
            disableFuture
            value={state.value}
            onChange={handleDateChange}
            minDate={minDateAllowed?.date}
            maxDate={maxDateAllowed?.date}
            error={state.error}
            helperText={state.helperText}
            placeholder={state.placeholder || 'mmmm'}
            label={state.label || undefined}
            required={isRequired}
            inputVariant="outlined"
            format="YYYY"
            invalidDateMessage="mmmm"
            style={{ width: '90px' }}
            InputAdornmentProps={{
              style: {
                display: 'none'
              }
            }}
            open={isOpen}
            onOpen={openCaledar}
            onClose={closeCaledar}
            okLabel="Pasirinkti"
            cancelLabel={Strings.button__cancel}
            clearable
            clearLabel="Išvalyti"
            id={state.id}
          />
        </MuiPickersUtilsProvider>
        {!withoutCalendar && (
          <IconButton
            id={`${state.id}-open-calendar`}
            onClick={() => setIsOpen(true)}
            style={{
              marginLeft: '4px',
              padding: '0.5rem'
            }}
          >
            <DateRangeRoundedIcon fontSize="small" color="primary" />
          </IconButton>
        )}
      </div>
    </Grid>
  );
};

interface CellProps {
  rowSpan?: number;
}
export const DateInputCellOnlyYear: React.FC<Props & CellProps> = ({
  rowSpan,
  state,
  ...props
}) => {
  return (
    <TableCell
      style={{
        backgroundColor: state.error ? theme.palette.error.light : undefined
      }}
      rowSpan={rowSpan}
    >
      <form noValidate autoComplete="off">
        <DateInputOnlyYear {...{ ...props, state }} />
      </form>
    </TableCell>
  );
};
