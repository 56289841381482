import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import { providedDataHistoryTableReducer } from './tableState/tableReducer';
import {
  ProvidedDataHistoryTableState,
  ProvidedDataHistoryTableAction,
  initialProvidedDataHistoryTableState
} from './tableState/tableInitialStateAndTypes';
import ProvidedDataHistoryTable, { ProvidedDataInstTableProps } from './Table';

const ProvidedDataHistoryTableStateContext = createContext<{
  state: ProvidedDataHistoryTableState;
}>({
  state: initialProvidedDataHistoryTableState
});

const ProvidedDataHistoryTableDispatchContext = createContext<{
  dispatch: Dispatch<ProvidedDataHistoryTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const ProvidedDataHistoryTableLegalWithContext: React.FC<ProvidedDataInstTableProps> = ({
  ...props
}) => {
  const [state, dispatch] = useReducer(
    providedDataHistoryTableReducer,
    initialProvidedDataHistoryTableState
  );
  return (
    <ProvidedDataHistoryTableStateContext.Provider value={{ state }}>
      <ProvidedDataHistoryTableDispatchContext.Provider value={{ dispatch }}>
        <ProvidedDataHistoryTable {...props} />
      </ProvidedDataHistoryTableDispatchContext.Provider>
    </ProvidedDataHistoryTableStateContext.Provider>
  );
};

export function useProvidedDataHistoryTableState() {
  return useContext(ProvidedDataHistoryTableStateContext);
}
export function useProvidedDataHistoryTableDispatch() {
  return useContext(ProvidedDataHistoryTableDispatchContext);
}
