import { Reducer } from 'redux';
import {
  FETCH_EDITION_CHECK_DATA_REQUEST,
  FETCH_EDITION_CHECK_DATA_SUCCESS,
  FETCH_EDITION_CHECK_DATA_ERROR,
  CREATE_EDITION_REQUEST,
  CREATE_EDITION_SUCCESS,
  CREATE_EDITION_ERROR,
  RESET_CREATE_EDITION_STATE,
  UPDATE_EDITION_REQUEST,
  UPDATE_EDITION_SUCCESS,
  UPDATE_EDITION_ERROR,
  RESET_UPDATE_EDITION_STATE,
  CONTINUE_ADDING_EDITION_CLICKED,
  CANCEL_ADDING_EDITION_CLICKED,
  RESET_REMOVE_EDITION_STATE,
  REMOVE_EDITION_REQUEST,
  REMOVE_EDITION_SUCCESS,
  REMOVE_EDITION_ERROR,
  FETCH_EDITION_CHECK_DATA_VIRS_REQUEST,
  FETCH_EDITION_CHECK_DATA_VIRS_SUCCESS,
  FETCH_EDITION_CHECK_DATA_VIRS_ERROR,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_ERROR,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_SUCCESS,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST,
  ANNUL_EDITION_CHECK_DATA_SUCCESS,
  ANNUL_EDITION_CHECK_DATA_REQUEST,
  ANNUL_EDITION_CHECK_DATA_ERROR,
  RESET_ANNUL_EDITION_CHECK_DATA_STATE,
  FETCH_PUBLICATION_OUTLETS_ERROR,
  FETCH_PUBLICATION_OUTLETS_REQUEST,
  FETCH_PUBLICATION_OUTLETS_SUCCESS,
  FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST,
  HIDE_POST_SAVE_WARNING,
  RESET_PUBLICATION_OUTLETS
} from './editionChecksActionTypes';
import {
  EditionCheckConclusionType,
  EditionCheckDataInst,
  EditionCheckDataVirs,
  VirsSearchDataWithPublicationOutlets
} from './editionChecksTypes';
import { EditionCheckCustomFilterOptions } from '../../containers/EditionCheckTableInst/tableState/tableTypesAndActions';

export interface EditionCheckDataState {
  editionCheckData?: EditionCheckDataInst[];
  editionCheckConclusionTypes: EditionCheckConclusionType[];
  loadingEditionCheckData: boolean;
  editionChecksDataError?: Error;
  editionCheckNewRecordRowOn: boolean;
  creatingEdition: boolean;
  editionCreated: boolean;
  creatingEditionError: undefined;
  updatingEdition: boolean;
  editionUpdated: boolean;
  updatingEditionError?: Error;
  removingEdition: boolean;
  editionRemoved: boolean;
  removingEditionError?: Error;
  editionCheckDataVirs?: EditionCheckDataVirs[];
  loadingEditionCheckDataVirs: boolean;
  editionChecksDataErrorVirs?: Error;
  editionCheckDataVirsRecordCount: number;
  annullingEditionCheckRecord: boolean;
  annullingEditionCheckError?: Error;
  annullingEditionCheckSuccess: boolean;
  virsSearchDataWithPublicationOutlets?: VirsSearchDataWithPublicationOutlets;
  loadingPublicationOutlets: boolean;
  publicationOutletsError?: Error;
  postSaveWarningOpen: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  recordCount: number;
  instTableFilters?: EditionCheckCustomFilterOptions;
}

export const initialState: EditionCheckDataState = {
  loadingEditionCheckData: false,
  editionCheckConclusionTypes: [],
  editionCheckNewRecordRowOn: false,
  creatingEdition: false,
  editionCreated: false,
  creatingEditionError: undefined,
  updatingEdition: false,
  editionUpdated: false,
  removingEdition: false,
  editionRemoved: false,
  editionCheckData: undefined,
  editionChecksDataError: undefined,
  loadingEditionCheckDataVirs: true,
  editionCheckDataVirs: undefined,
  editionChecksDataErrorVirs: undefined,
  editionCheckDataVirsRecordCount: 0,
  annullingEditionCheckRecord: false,
  annullingEditionCheckError: undefined,
  annullingEditionCheckSuccess: false,
  loadingPublicationOutlets: false,
  postSaveWarningOpen: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  recordCount: 0,
  instTableFilters: undefined
};

export const editionChecksReducer: Reducer<EditionCheckDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_EDITION_CHECK_DATA_REQUEST:
      return {
        ...state,
        loadingEditionCheckData: true
      };
    case FETCH_EDITION_CHECK_DATA_SUCCESS:
      return {
        ...state,
        loadingEditionCheckData: false,
        editionCheckData: action.payload.editionCheckData,
        editionCheckConclusionTypes: action.payload.editionCheckConclusionTypes,
        editionChecksDataError: undefined,
        statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
        statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
        statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
        recordCount: action.payload.recordCount,
        instTableFilters: action.payload.filterValues
      };
    case FETCH_EDITION_CHECK_DATA_ERROR:
      return {
        ...state,
        loadingEditionCheckData: false,
        editionChecksDataError: action?.payload?.data
      };
    case CREATE_EDITION_REQUEST:
      return {
        ...state,
        creatingEdition: true
      };
    case CREATE_EDITION_SUCCESS:
      return {
        ...state,
        postSaveWarningOpen: action.payload,
        creatingEdition: false,
        editionCreated: true
      };
    case CREATE_EDITION_ERROR:
      return {
        ...state,
        creatingEdition: false,
        creatingEditionError: action?.payload?.data
      };
    case RESET_CREATE_EDITION_STATE:
      return {
        ...state,
        creatingEdition: false,
        editionCreated: false,
        creatingEditionError: undefined
      };
    case UPDATE_EDITION_REQUEST:
      return {
        ...state,
        updatingEdition: true
      };
    case UPDATE_EDITION_SUCCESS:
      return {
        ...state,
        postSaveWarningOpen: action.payload,
        updatingEdition: false,
        editionUpdated: true
      };
    case UPDATE_EDITION_ERROR:
      return {
        ...state,
        updatingEdition: false,
        editionUpdated: false,
        updatingEditionError: action?.payload?.data
      };
    case RESET_UPDATE_EDITION_STATE:
      return {
        ...state,
        updatingEdition: false,
        editionUpdated: false,
        updatingEditionError: undefined
      };
    case CONTINUE_ADDING_EDITION_CLICKED:
      return {
        ...state,
        editionCheckNewRecordRowOn: true
      };
    case CANCEL_ADDING_EDITION_CLICKED:
      return {
        ...state,
        editionNewRecordRowOn: false
      };
    case RESET_REMOVE_EDITION_STATE:
      return {
        ...state,
        removingEdition: false,
        editionRemoved: false,
        removingEditionError: undefined
      };
    case REMOVE_EDITION_REQUEST:
      return {
        ...state,
        removingEdition: true
      };
    case REMOVE_EDITION_SUCCESS:
      return {
        ...state,
        removingEdition: false,
        editionRemoved: true
      };
    case REMOVE_EDITION_ERROR:
      return {
        ...state,
        removingEdition: false,
        removingEditionError: action?.payload?.data
      };
    case FETCH_EDITION_CHECK_DATA_VIRS_REQUEST:
      return {
        ...state,
        loadingEditionCheckDataVirs: true
      };
    case FETCH_EDITION_CHECK_DATA_VIRS_SUCCESS:
      return {
        ...state,
        loadingEditionCheckDataVirs: false,
        editionCheckDataVirs: action.payload.editionCheckData,
        editionCheckDataVirsRecordCount: action.payload.editionCheckData.length
      };
    case FETCH_EDITION_CHECK_DATA_VIRS_ERROR:
      return {
        ...state,
        loadingEditionCheckDataVirs: false,
        editionChecksDataErrorVirs: action.payload.data
      };
    case FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST:
      return {
        ...state,
        loadingEditionCheckData: true
      };
    case FETCH_EDITION_CHECK_DATA_BY_VIRID_SUCCESS:
      return {
        ...state,
        loadingEditionCheckData: false,
        editionCheckData: action.payload.editionCheckData,
        editionCheckDataRecordCount: action.payload.editionCheckData.length
      };
    case FETCH_EDITION_CHECK_DATA_BY_VIRID_ERROR:
      return {
        ...state,
        loadingEditionCheckData: false,
        editionChecksDataError: action?.payload?.data
      };
    case ANNUL_EDITION_CHECK_DATA_REQUEST:
      return {
        ...state,
        annullingEditionCheckRecord: true
      };
    case ANNUL_EDITION_CHECK_DATA_SUCCESS:
      return {
        ...state,
        annullingEditionCheckRecord: false,
        annullingEditionCheckSuccess: true
      };
    case ANNUL_EDITION_CHECK_DATA_ERROR:
      return {
        ...state,
        annullingEditionCheckRecord: false,
        annullingEditionCheckSuccess: false,
        annullingEditionCheckError: action?.payload?.data
      };
    case RESET_ANNUL_EDITION_CHECK_DATA_STATE:
      return {
        ...state,
        annullingEditionCheckRecord: false,
        annullingEditionCheckSuccess: false,
        annullingEditionCheckError: undefined
      };
    case FETCH_PUBLICATION_OUTLETS_REQUEST:
      return {
        ...state,
        loadingPublicationOutlets: true
      };
    case FETCH_PUBLICATION_OUTLETS_SUCCESS:
      return {
        ...state,
        loadingPublicationOutlets: false,
        virsSearchDataWithPublicationOutlets: {
          id: action.payload.id,
          virs: action.payload.virs,
          publicationOutlets: action.payload.data.publicationOutlets
        },
        editionChecksDataError: undefined
      };
    case FETCH_PUBLICATION_OUTLETS_ERROR:
      return {
        ...state,
        loadingPublicationOutlets: false,
        publicationOutletsError: action?.payload?.data
      };
    case RESET_PUBLICATION_OUTLETS:
      return {
        ...state,
        loadingPublicationOutlets: false,
        virsSearchDataWithPublicationOutlets: undefined,
        editionChecksDataError: undefined
      };
    case HIDE_POST_SAVE_WARNING:
      return {
        ...state,
        postSaveWarningOpen: false
      };
    case FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST:
      return {
        ...state,
        loadingEditionCheckData: true
      };
    default:
      return state;
  }
};
