import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { loadData, setFormOpen, State } from './store';
import { TransactionTypeForm } from './TransactionTypeForm';
import { TransactionType } from './types';

export const TransactionTypeTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const tableDefinition: TableDefinition<Nullable<TransactionType>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('transactionTypeId', 'Kodas', (data) => data.transactionTypeId),
            getStringMappedColumn(
              'transactionTypeName',
              'Sandorio arba administracinio akto rūšies pavadinimas Lt',
              (data) => data.transactionTypeName,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getStringMappedColumn(
              'transactionTypeNameEn',
              'Sandorio arba administracinio akto rūšies pavadinimas En',
              (data) => data.transactionTypeNameEn,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            )
          ]
        },
        {
          header: 'Galioja',
          columns: [
            getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              transactionTypeId: data.transactionTypeId,
              transactionTypeName: data.transactionTypeName,
              transactionTypeNameEn: data.transactionTypeNameEn,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              validTo: (data.validTo && new Date(data.validTo)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują sandorio arba administracinio akto rūšį'
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={tableDefinition} {...dataState} />

      <TransactionTypeForm />
    </>
  );
};
