import React from 'react';
import { IconButton } from '@material-ui/core';

interface Props {
  onClose: () => void;
}
export const CloseButton: React.FC<Props> = ({ onClose }) => (
  <IconButton onClick={onClose}>
    <svg xmlns="http://www.w3.org/2000/svg" width="11.759" height="11.759">
      <g data-name="Group 2804" fill="none" stroke="#e60000" strokeLinecap="round" strokeWidth="3">
        <path data-name="Path 1233" d="M2.121 2.121l7.516 7.516" />
        <path data-name="Path 1234" d="M9.638 2.121L2.122 9.637" />
      </g>
    </svg>
  </IconButton>
);
