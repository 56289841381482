import React, { useContext } from 'react';
import {
  EditionCheckDataTableHeaderType,
  EditionCheckDataVirsTableField
} from './tableState/tableTypesAndActions';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import { EditionCheckDataTableStateContext, EditionCheckDataTableDispatchContext } from './Context';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';

interface Props {
  columnNames: EditionCheckDataTableHeaderType[];
}

export const EditionCheckDataTableCustomizationBar: React.FC<Props> = ({ columnNames }) => {
  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: EditionCheckDataVirsTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: EditionCheckDataVirsTableField) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={tableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={tableState.order}
            sortBy={tableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={tableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
