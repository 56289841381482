import {
  EnterpriseDataTableColumn,
  EnterpriseDataTableColumnsDisplayStatus,
  EnterpriseDataTableField,
  EnterpriseDataTableState
} from './initialStateAndTypes';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import {
  Filter,
  filterData,
  getConfirmationAndRelevancyStatusFilter,
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function filterByStatus(
  records: EnterpriseData[],
  showUnconfirmed: boolean,
  showRelevant: boolean,
  showNotRelevant: boolean
): EnterpriseData[] {
  return getConfirmationAndRelevancyStatusFilter<EnterpriseData>(
    showUnconfirmed,
    showRelevant,
    showNotRelevant
  )(records);
}

export function filterAndSortEnterpriseTableData(
  tableState: EnterpriseDataTableState,
  data: EnterpriseData[]
): EnterpriseData[] {
  if (data.length === 0) {
    return [];
  }
  const statusFiltered = filterByStatus(
    data,
    tableState.showStatusNotSigned,
    tableState.showStatusRelevant,
    tableState.showStatusOutdated
  );

  const columnsFilters = tableState.customFilter;

  const filters: Filter<EnterpriseData>[] = [
    getStringMappedColumnFilter(columnsFilters.enterpriseTypeName, ({ enterpriseTypeName }) => [
      enterpriseTypeName
    ]),
    getStringCompareMoreFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(columnsFilters.authorName, ({ authorName }) => [authorName])
  ];

  const filtered = filterData(statusFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}

export function getTableExportDefinition(
  columnHeaders: EnterpriseDataTableColumn[],
  displayStatus: EnterpriseDataTableColumnsDisplayStatus
): TableExportDefinition<EnterpriseData> {
  const { stringValueColumn } = getColumnBuilder<EnterpriseData, EnterpriseDataTableField>(
    columnHeaders,
    displayStatus
  );
  return {
    title: 'Veiklos rūšys',
    columnGroups: [
      {
        columns: [stringValueColumn('enterpriseTypeName', { width: 45 })]
      },
      {
        header: 'Vykdoma',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      },
      {
        columns: [stringValueColumn('authorName', { width: 45 })]
      }
    ]
  };
}
