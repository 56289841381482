import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { personIsAuthorized, personNotAuthorized } from '../treeChartFunctions/constants';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';
import { useTooltip } from '../../../../utils/hooks/tooltipHook';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontStyle: 'italic',
    fontSize: '0.7rem',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden'
  },
  subtitle2: {
    fontSize: '0.7rem',
    fontStyle: 'italic'
  },
  title: {
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    overflow: 'hidden',
    maxHeight: '40px',
    fontSize: '0.73rem'
  }
}));

export interface Props {
  title: string;
  subtitle: string;
  isPersonFromGroup?: boolean;
  authorized?: boolean;
}

export const BoxTitle: React.FC<Props> = ({ title, subtitle, isPersonFromGroup, authorized }) => {
  const classes = useStyles();
  const { textRef, showTooltip } = useTooltip(title, 184);

  return (
    <>
      {title && showTooltip ? (
        <VirsTreeCustomTooltip text={title}>
          <Typography variant="h5" className={classes.title} ref={textRef}>
            {title}
          </Typography>
        </VirsTreeCustomTooltip>
      ) : (
        <Typography variant="h5" className={classes.title} ref={textRef}>
          {title}
        </Typography>
      )}
      <Typography variant="h6" className={classes.subtitle}>
        {subtitle}
      </Typography>

      {isPersonFromGroup && (
        <Typography variant="h6" className={classes.subtitle2}>
          {authorized ? personIsAuthorized : personNotAuthorized}
        </Typography>
      )}
    </>
  );
};
