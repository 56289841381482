import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  personDetails: {
    flexGrow: 1
  },
  personName: {
    color: theme.palette.primary.main
  },
  personCode: {
    color: theme.palette.info.main
  }
}));

export interface Props {
  personName: string;
  legalPersonCode?: number | string | null;
}

export const PersonDetails: React.FC<Props> = ({ personName, legalPersonCode }) => {
  const classes = useStyles();

  return (
    <div className={classes.personDetails}>
      <Typography variant="h5" className={classes.personName}>
        {personName}
      </Typography>

      {legalPersonCode && (
        <Typography variant="h6" className={classes.personCode}>
          {legalPersonCode}
        </Typography>
      )}
    </div>
  );
};
