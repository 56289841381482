import React, { createContext, useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import { fetchOutletInfoData } from '../../store/outletInfoData/outletInfoDataActions';
import { ApplicationState } from '../../store';
import BottomPageActionButtons from '../../components/PageButtons/BottomPageActionButtons';
import { getSubsystem } from '../../utils/roleHelper';
import { OutletInfoTablesWithContext } from '../../containers/OutletInfoTables/OutletInfoTablesContext';
import { Subsystem } from '../../containers/VirsDataSubmision/VirsDataSubmision/VirsDataPage';
import {
  getOutletTab,
  OutletTabName
} from '../../containers/OutletInfoTables/tablesState/tablesTypesAndActions';

const useStyles = makeStyles({
  alignBottomRow: {
    flex: 1
  },
  title: {
    padding: '20px 35px 10px 35px'
  },
  divider: {
    margin: '10px 35px'
  },
  mainContainer: {
    padding: '10px 35px'
  },
  actions: {
    minWidth: 250,
    padding: '10px 34px',
    height: 50,
    backgroundColor: '#F3F3F3'
  },
  inputContainer: {
    display: 'flex',
    margin: 0,
    '& > button': {
      padding: 0,
      marginLeft: 4,
      width: 36,
      height: 36
    },
    '& > div > div > div > button': {
      padding: 0
    }
  },
  errorMessage: {
    margin: '0 35px',
    fontWeight: 'normal',
    '& span': {
      color: '#F11A1A',
      fontWeight: 600
    }
  }
});
export const subsystemContext = createContext<Subsystem>(undefined);

interface Param {
  outletId: string;
  tablePath?: string;
}

const OutletInfoTablesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { outletId, tablePath } = useParams<Param>();

  const tab: OutletTabName = getOutletTab(tablePath);

  const {
    outletInfoData,
    virsis: { virsData, currentUser, selectedVirsId },
    outletData: { isOutletRemoved }
  } = useSelector((state: ApplicationState) => state);

  const subsystem = getSubsystem(currentUser);
  const isVirsis = subsystem !== 'VIRSIS';
  const { loadingOutletInfo, outletInfo, outletInfoError } = outletInfoData;

  useEffect(() => {
    if (isOutletRemoved) {
      const path = isVirsis
        ? `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones`
        : '/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones';
      history.push(path);
    }
  }, [dispatch, isOutletRemoved]);

  useEffect(() => {
    if (virsData && currentUser) {
      dispatch(fetchOutletInfoData(virsData.virsId, Number(outletId)));
    }
  }, [dispatch, virsData, currentUser, outletId]);

  const handleGoBackClick = () => {
    const path = isVirsis
      ? `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`
      : '/duomenu-perziura-ir-teikimas/veiklos-rusys';
    history.push(path);
  };

  const handleGoToPageClick = () => {
    const path = isVirsis
      ? `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/dalyviai`
      : '/duomenu-perziura-ir-teikimas/dalyviai';
    history.push(path);
  };

  return (
    <subsystemContext.Provider value={subsystem}>
      <div className="contentWrapper">
        <Container maxWidth="lg" className={classes.alignBottomRow}>
          <div className="data-page">
            <div className="data-page-header">
              <Box display="flex">
                <Typography variant="h1">Visuomenės informavimo priemonės (VIP)</Typography>
              </Box>
            </div>
            {loadingOutletInfo && <CircularProgress />}
            {!loadingOutletInfo && outletInfo && !outletInfoError && (
              <>
                <Typography variant="subtitle1">
                  {`${outletInfo.outletName} ${outletInfo.controlledFrom} - ${
                    outletInfo.controlledTo ? `${outletInfo.controlledTo}` : ''
                  }`}
                </Typography>
                <Divider light />
              </>
            )}
            <OutletInfoTablesWithContext
              data={outletInfo}
              openedTab={tab}
              currentUser={currentUser}
            />
          </div>
        </Container>
      </div>
      <BottomPageActionButtons
        pageToGo="Dalyviai"
        handleGoToPageClick={handleGoToPageClick}
        handleGoBackClick={handleGoBackClick}
      />
    </subsystemContext.Provider>
  );
};

export default withRouter(OutletInfoTablesPage);
