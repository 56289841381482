import React, { ReactNode, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { FundsReceivedLegalTableStateContext } from '../Context';

interface FundsReceivedDataTableRowProps {
  virsInput?: ReactNode;
  fundsReceivedYearInput?: ReactNode;
  fundsReceivedSumInput?: ReactNode;
  fundsSourceNameInput?: ReactNode;
  fundsSourceCode?: string;
  fundsSourceCodeInput?: ReactNode;
  outletsInput?: ReactNode;
  transactionTypeName?: ReactNode;
  transactionInput?: ReactNode;
  actions?: ReactNode;
  elDocument?: ReactNode;
}

export const FundsReceivedLegalTableInputs: React.FC<FundsReceivedDataTableRowProps> = ({
  virsInput,
  fundsReceivedYearInput,
  fundsReceivedSumInput,
  fundsSourceNameInput,
  fundsSourceCodeInput,
  outletsInput,
  transactionInput,
  actions,
  elDocument
}) => {
  const { state: tableState } = useContext(FundsReceivedLegalTableStateContext);

  return (
    <>
      <TableRow>
        {tableState.columnsDisplayStatus.virsName && virsInput}
        {tableState.columnsDisplayStatus.fundsReceivedYear && fundsReceivedYearInput}
        {tableState.columnsDisplayStatus.fundsReceivedSum && fundsReceivedSumInput}
        {tableState.columnsDisplayStatus.fundsSourceName && (
          <TableCell align="left">{fundsSourceNameInput}</TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceCode && (
          <TableCell align="left">{fundsSourceCodeInput}</TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsReceivedOutlets && outletsInput}
        {tableState.columnsDisplayStatus.transaction && transactionInput}
        <TableCell align="left">{actions}</TableCell>
        <TableCell align="left">{elDocument}</TableCell>
      </TableRow>
    </>
  );
};
