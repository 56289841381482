import { action } from 'typesafe-actions';
import {
  FETCH_AUDITS_DATA_ERROR,
  FETCH_AUDITS_DATA_REQUEST,
  FETCH_AUDITS_DATA_SUCCESS,
  FETCH_AUDIT_ACTION_TYPES_ERROR,
  FETCH_AUDIT_ACTION_TYPES_REQUEST,
  FETCH_AUDIT_ACTION_TYPES_SUCCESS,
  FETCH_AUDIT_OUTLET_TYPES_ERROR,
  FETCH_AUDIT_OUTLET_TYPES_REQUEST,
  FETCH_AUDIT_OUTLET_TYPES_SUCCESS,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_SUCCESS,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_ERROR,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_SUCCESS,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_ERROR,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_SUCCESS,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_ERROR,
  FETCH_ARCHIVED_DATA_REQUEST,
  FETCH_ARCHIVED_DATA_SUCCESS,
  FETCH_ARCHIVED_DATA_ERROR,
  DELETE_ARCHIVED_DATA_ERROR,
  DELETE_ARCHIVED_DATA_REQUEST,
  DELETE_ARCHIVED_DATA_SUCCESS,
  RESET_DELETE_ARCHIVED_DATA,
  RESET_AUDITS_DATA_STATE,
  RESET_INTEGRATED_SYSTEMS_AUDITS_DATA_STATE
} from './auditsActionTypes';
import {
  AuditActionType,
  AuditData,
  IntegrationalSystemAuditQuery,
  AuditOutletType,
  AuditQuery,
  ExternalSytemInfoData,
  IntegratedSystemsAuditData
} from './auditsTypes';

export const fetchAuditsDataRequest = (q: AuditQuery) => action(FETCH_AUDITS_DATA_REQUEST, q);
export const fetchAuditsDataError = (error: Error) => action(FETCH_AUDITS_DATA_ERROR, error);
export const fetchAuditsDataSuccess = (auditsData: AuditData[]) =>
  action(FETCH_AUDITS_DATA_SUCCESS, auditsData);
export const resetAuditsData = () => action(RESET_AUDITS_DATA_STATE);

export const fetchIntegratedSystemsAuditsDataRequest = (q: IntegrationalSystemAuditQuery) =>
  action(FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST, q);
export const fetchIntegratedSystemsAuditsDataError = (error: Error) =>
  action(FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_ERROR, error);
export const fetchIntegratedSystemsAuditsDataSuccess = (auditsData: IntegratedSystemsAuditData[]) =>
  action(FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_SUCCESS, auditsData);
export const resetIntegratedSystemsAuditsData = () =>
  action(RESET_INTEGRATED_SYSTEMS_AUDITS_DATA_STATE);

export const fetchAuditOutletTypesRequest = () => action(FETCH_AUDIT_OUTLET_TYPES_REQUEST);
export const fetchAuditOutletTypesError = (error: Error) =>
  action(FETCH_AUDIT_OUTLET_TYPES_ERROR, error);
export const fetchAuditOutletTypesSuccess = (auditOutletTypes: AuditOutletType[]) =>
  action(FETCH_AUDIT_OUTLET_TYPES_SUCCESS, auditOutletTypes);

export const fetchAuditActionTypesRequest = () => action(FETCH_AUDIT_ACTION_TYPES_REQUEST);
export const fetchAuditActionTypesSuccess = (auditActionTypes: AuditActionType[]) =>
  action(FETCH_AUDIT_ACTION_TYPES_SUCCESS, auditActionTypes);
export const fetchAuditActionTypesError = (error: Error) =>
  action(FETCH_AUDIT_ACTION_TYPES_ERROR, error);

export const fetchVirsisReceivesFromSystemRequest = () =>
  action(FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST);
export const fetchVirsisReceivesFromSystemSuccess = (auditActionTypes: ExternalSytemInfoData[]) =>
  action(FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_SUCCESS, auditActionTypes);
export const fetchVirsisReceivesFromSystemError = (error: Error) =>
  action(FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_ERROR, error);

export const fetchVirsisSendsToSystemRequest = () => action(FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST);
export const fetchVirsisSendsToSystemSuccess = (auditActionTypes: ExternalSytemInfoData[]) =>
  action(FETCH_VIRSIS_SENDS_TO_SYSTEM_SUCCESS, auditActionTypes);
export const fetchVirsisSendsToSystemError = (error: Error) =>
  action(FETCH_VIRSIS_SENDS_TO_SYSTEM_ERROR, error);

export const fetchArchivedDataRequest = () => action(FETCH_ARCHIVED_DATA_REQUEST);
export const fetchArchivedDataSuccess = () => action(FETCH_ARCHIVED_DATA_SUCCESS);
export const fetchArchivedDataError = (error: Error) => action(FETCH_ARCHIVED_DATA_ERROR, error);

export const deleteArchivedDataRequest = () => action(DELETE_ARCHIVED_DATA_REQUEST);
export const deleteArchivedDataSuccess = () => action(DELETE_ARCHIVED_DATA_SUCCESS);
export const deleteArchivedDataError = (error: Error) => action(DELETE_ARCHIVED_DATA_ERROR, error);
export const resetDeleteArchivedData = () => action(RESET_DELETE_ARCHIVED_DATA);
