export const SEARCH_LEGAL_PERSON_REQUEST = '@legalData/SEARCH_LEGAL_PERSON_REQUEST';
export const SEARCH_LEGAL_PERSON_SUCCESS = '@legalData/SEARCH_LEGAL_PERSON_SUCCESS';
export const SEARCH_NATURAL_PERSON_ERROR = '@legalData/SEARCH_LEGAL_PERSON_ERROR';
export const RESET_SEARCH_NATURAL_PERSON_STATE = '@legalData/RESET_SEARCH_LEGAL_PERSON_STATE';

export const SELECT_LEGAL_REPRESENTATIVE = '@legal/SELECT_LEGAL_REPRESENTATIVE';
export const RESET_LEGAL_REPRESENTATIVE = '@legal/RESET_LEGAL_REPRESENTATIVE';

export const CREATE_LEGAL_FOREIGN_PERSON_REQUEST = '@legal/CREATE_LEGAL_FOREIGN_PERSON_REQUEST';
export const CREATE_LEGAL_FOREIGN_PERSON_SUCCESS = '@legal/CREATE_LEGAL_FOREIGN_PERSON_SUCCESS';
export const CREATE_LEGAL_FOREIGN_PERSON_ERROR = '@legal/CREATE_LEGAL_FOREIGN_PERSON_ERROR';
export const RESET_CREATE_LEGAL_FOREIGN_PERSON_STATE =
  '@legal/RESET_CREATE_LEGAL_FOREIGN_PERSON_STATE';

export const SELECT_LEGAL_SEARCH_QUERY = '@legal/SELECT_LEGAL_SEARCH_QUERY';
export const RESET_LEGAL_SEARCH_QUERY = '@legal/RESET_LEGAL_SEARCH_QUERY';
