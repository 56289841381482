export const FETCH_OUTLET_INFO_DATA_REQUEST = '@outletInfoData/FETCH_OUTLET_INFO_DATA_REQUEST';
export const FETCH_OUTLET_INFO_DATA_SUCCESS = '@outletInfoData/FETCH_OUTLET_INFO_DATA_SUCCESS';
export const FETCH_OUTLET_INFO_DATA_ERROR = '@outletInfoData/FETCH_OUTLET_INFO_DATA_ERROR';

export const CREATE_EDITION_REQUEST = '@outletInfoData/CREATE_EDITION_REQUEST';
export const CREATE_EDITION_SUCCESS = '@outletInfoData/CREATE_EDITION_SUCCESS';
export const CREATE_EDITION_ERROR = '@outletInfoData/CREATE_EDITION_ERROR';
export const RESET_CREATE_EDITION_STATE = '@outletInfoData/RESET_CREATE_EDITION_STATE';

export const UPDATE_EDITION_REQUEST = '@outletInfoData/UPDATE_EDITION_REQUEST';
export const UPDATE_EDITION_SUCCESS = '@outletInfoData/UPDATE_EDITION_SUCCESS';
export const UPDATE_EDITION_ERROR = '@outletInfoData/UPDATE_EDITION_ERROR';
export const RESET_UPDATE_EDITION_STATE = '@outletInfoData/RESET_UPDATE_EDITION_STATE';

export const REMOVE_EDITION_REQUEST = '@outletInfoData/REMOVE_EDITION_REQUEST';
export const REMOVE_EDITION_SUCCESS = '@outletInfoData/REMOVE_EDITION_SUCCESS';
export const REMOVE_EDITION_ERROR = '@outletInfoData/REMOVE_EDITION_ERROR';
export const RESET_REMOVE_EDITION_STATE = '@outletInfoData/RESET_REMOVE_EDITION_STATE';

export const FETCH_MISSING_PERIODS_REQUEST = '@editionCheck/FETCH_MISSING_PERIODS_REQUEST';
export const FETCH_MISSING_PERIODS_SUCCESS = '@editionCheck/FETCH_MISSING_PERIODS_SUCCESS';
export const FETCH_MISSING_PERIODS_ERROR = '@editionCheck/FETCH_MISSING_PERIODS_ERROR';
export const RESET_MISSING_PERIODS = '@editionCheck/RESET_MISSING_PERIODS';

export const CREATE_CATEGORY_REQUEST = '@outletInfoData/CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = '@outletInfoData/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = '@outletInfoData/CREATE_CATEGORY_ERROR';
export const RESET_CREATE_CATEGORY_STATE = '@outletInfoData/RESET_CREATE_CATEGORY_STATE';

export const UPDATE_CATEGORY_REQUEST = '@outletInfoData/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = '@outletInfoData/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = '@outletInfoData/UPDATE_CATEGORY_ERROR';
export const RESET_UPDATE_CATEGORY_STATE = '@outletInfoData/RESET_UPDATE_CATEGORY_STATE';

export const REMOVE_CATEGORY_REQUEST = '@outletInfoData/REMOVE_CATEGORY_REQUEST';
export const REMOVE_CATEGORY_SUCCESS = '@outletInfoData/REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_ERROR = '@outletInfoData/REMOVE_CATEGORY_ERROR';
export const RESET_REMOVE_CATEGORY_STATE = '@outletInfoData/RESET_REMOVE_CATEGORY_STATE';

export const SET_REPRESENTATIVE_FOR_NEW_RECORD =
  '@outletInfoData/SET_REPRESENTATIVE_FOR_NEW_RECORD';
export const RESET_REPRESENTATIVE_FOR_NEW_RECORD =
  '@outletInfoData/RESET_REPRESENTATIVE_FOR_NEW_RECORD';
export const CONTINUE_ADDING_REPRESENTATIVE_CLICKED =
  '@outletInfoData/CONTINUE_ADDING_REPRESENTATIVE_CLICKED';
export const CANCEL_ADDING_REPRESENTATIVE_CLICKED =
  '@outletInfoData/CANCEL_ADDING_REPRESENTATIVE_CLICKED';

export const CREATE_REPRESENTATIVE_REQUEST = '@outletInfoData/CREATE_REPRESENTATIVE_REQUEST';
export const CREATE_REPRESENTATIVE_SUCCESS = '@outletInfoData/CREATE_REPRESENTATIVE_SUCCESS';
export const CREATE_REPRESENTATIVE_ERROR = '@outletInfoData/CREATE_REPRESENTATIVE_ERROR';
export const RESET_CREATE_REPRESENTATIVE_STATE =
  '@outletInfoData/RESET_CREATE_REPRESENTATIVE_STATE';

export const UPDATE_REPRESENTATIVE_REQUEST = '@outletInfoData/UPDATE_REPRESENTATIVE_REQUEST';
export const UPDATE_REPRESENTATIVE_SUCCESS = '@outletInfoData/UPDATE_REPRESENTATIVE_SUCCESS';
export const UPDATE_REPRESENTATIVE_ERROR = '@outletInfoData/UPDATE_REPRESENTATIVE_ERROR';
export const RESET_UPDATE_REPRESENTATIVE_STATE =
  '@outletInfoData/RESET_UPDATE_REPRESENTATIVE_STATE';

export const REMOVE_REPRESENTATIVE_REQUEST = '@outletInfoData/REMOVE_REPRESENTATIVE_REQUEST';
export const REMOVE_REPRESENTATIVE_SUCCESS = '@outletInfoData/REMOVE_REPRESENTATIVE_SUCCESS';
export const REMOVE_REPRESENTATIVE_ERROR = '@outletInfoData/REMOVE_REPRESENTATIVE_ERROR';
export const RESET_REMOVE_REPRESENTATIVE_STATE =
  '@outletInfoData/RESET_REMOVE_REPRESENTATIVE_STATE';

export const CREATE_ADVERTISEMENT_REQUEST = '@outletInfoData/CREATE_ADVERTISEMENT_REQUEST';
export const CREATE_ADVERTISEMENT_SUCCESS = '@outletInfoData/CREATE_ADVERTISEMENT_SUCCESS';
export const CREATE_ADVERTISEMENT_ERROR = '@outletInfoData/CREATE_ADVERTISEMENT_ERROR';
export const RESET_CREATE_ADVERTISEMENT_STATE = '@outletInfoData/RESET_CREATE_ADVERTISEMENT_STATE';

export const UPDATE_ADVERTISEMENT_REQUEST = '@outletInfoData/UPDATE_ADVERTISEMENT_REQUEST';
export const UPDATE_ADVERTISEMENT_SUCCESS = '@outletInfoData/UPDATE_ADVERTISEMENT_SUCCESS';
export const UPDATE_ADVERTISEMENT_ERROR = '@outletInfoData/UPDATE_ADVERTISEMENT_ERROR';
export const RESET_UPDATE_ADVERTISEMENT_STATE = '@outletInfoData/RESET_UPDATE_ADVERTISEMENT_STATE';
export const RESET_REMOVE_ADVERTISEMENT_STATE = '@outletInfoData/RESET_REMOVE_ADVERTISEMENT_STATE';
export const REMOVE_ADVERTISEMENT_REQUEST = '@outletInfoData/REMOVE_ADVERTISEMENT_REQUEST';
export const REMOVE_ADVERTISEMENT_SUCCESS = '@outletInfoData/REMOVE_ADVERTISEMENT_SUCCESS';
export const REMOVE_ADVERTISEMENT_ERROR = '@outletInfoData/REMOVE_ADVERTISEMENT_ERROR';
