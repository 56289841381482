import { axiosDelete, get, getWithQuery, put } from '../../utils/axiosApi';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { ProvidedDataPageRequest } from '../providedDataTable/providedDataTableTypes';

export const getProvidedDataVirs = () => get('/provided-data/virs');

export const getProvidedDataByVirsId = (virsId: number | null) =>
  get(`/provided-data/virs/${virsId}`);

export const getProvidedDataInst = (query: ProvidedDataPageRequest) =>
  getWithQuery('/provided-data/inst', query);

export const getProvidedDataLegal = () => get('/provided-data/legal');

export const deleteProvidedRecord = (documentStatusId: number) =>
  axiosDelete(`/provided-data/inst/${documentStatusId}`);

export const annulProvidedRecord = (documentStatusId: number, annulRecord: AnnulRecord) =>
  put(`/provided-data/inst/${documentStatusId}`, annulRecord);
