import { PaymentDataTableAction, PaymentDataTableState } from './paymentTableInitialStateAndTypes';
import { getTableExportDefinition } from './paymentTableStateFunctions';
import { columnNames } from '../TableColumns';

export const paymentTableReducer = (
  state: PaymentDataTableState,
  action: PaymentDataTableAction
): PaymentDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(columnNames, columnsDisplayStatus)
      };
    }
    case 'CREATE_RECORD_CLICKED':
      return {
        ...state,
        showNewRecord: !state.showNewRecord,
        companyCode: action.companyCode
      };
    case 'CLOSE_CREATING_RECORD_CLICKED':
      return {
        ...state,
        showNewRecord: false
      };
    case 'RESET_COMPANY_CODE':
      return {
        ...state,
        companyCode: null
      };
    default:
      return state;
  }
};
