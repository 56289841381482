export interface ExternalSystemData {
  individualPersonData: IndividualPersonData[];
  legalEntityData: LegalEntityData[];
  politicalAdData: PoliticalAdData[];
  licensesData: LicensesData[];
}

export interface LicensesData {
  // licenseId: number;
  licenseNumber: number;
  licenseEnterpriseId: number;
  // enterpriseTypeId: number;
  enterpriseName: string;
  licenseStatus: string;
  licenseIssueDate: Date;
  validFrom: Date;
  validTo: Date;
  // issuingInstitutionPersonId: number;
  issuingInstitutionName: string;
  licenseNotes: string;
  // dataProvidedDate: Date;
  // dataUpdatedDate: Date;
  licenseTypeName: string;
}

export interface PoliticalAdData {
  vprId: number;
  // distributorPersonId: number;
  distributorName: string;
  // shareholderId: number;
  interestedPartyName: string;
  campaignName: string;
  politicalAdDateFrom: Date;
  politicalAdDateTo: Date;
  financialSupportAmount: number;
  // dataSubmittedDate: Date;
  // dataUpdatedDate: Date;
  distributorCode: number;
}

export interface LegalEntityData {
  vdpId: number;
  // supportProviderPersonId: number;
  supportProviderName: string;
  supportProviderCode: string;
  supportReceivedYear: string;
  financialSupportAmount: number;
  // currency: string;
  dataCreatedDate: Date;
  dataProvidedDate: Date;
  // dataUpdatedDate: Date;
}

export interface IndividualPersonData {
  vdpId: number;
  // supportProviderPersonId: number;
  supportProviderName: string;
  // supportProviderCode: string;
  supportReceivedYear: string;
  financialSupportAmount: number;
  // currency: string;
  dataCreatedDate: Date;
  dataProvidedDate: Date;
  // dataUpdatedDate: Date;
}

export interface ExternalSystemDataFiltersProps {
  value: string;
  label: string;
  pathname: string;
  roles?: string[];
}
export const externalSystemDataFilters: ExternalSystemDataFiltersProps[] = [
  { value: 'all', label: 'Visi', pathname: 'visi' },
  { value: 'licenses', label: 'Licencijos', pathname: 'licencijos' },
  {
    value: 'politicalAdFinancialSupport',
    label: 'Pajamos iš politinės reklamos',
    pathname: 'pajamos-iš-politinės-reklamos'
  },
  {
    value: 'legalEntityFinancialSupport',
    label: 'Juridinių asmenų parama',
    pathname: 'juridinių-asmenų-parama'
  },
  {
    value: 'individualPersonFinancialSupport',
    label: 'Fizinių asmenų parama',
    pathname: 'fizinių-asmenų-parama'
  }
];
