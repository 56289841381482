import { makeStyles, IconButton } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles({
  removePadding: {
    padding: '0px',
    position: 'relative'
  }
});

interface Props {
  showExtension?: boolean;
  onClick?: () => void;
  extraStyles?: React.CSSProperties;
}

export const ExpandDetailsButton: React.FC<Props> = ({ showExtension, onClick, extraStyles }) => {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="Išskleisti daugiau veiksmų"
      className={classes.removePadding}
      color="primary"
      onClick={onClick}
      style={extraStyles}
    >
      {showExtension && <ExpandLessIcon />}
      {!showExtension && <ExpandMoreIcon />}
    </IconButton>
  );
};
