import React, { useContext, createContext, Dispatch, useReducer } from 'react';
import { IntegratedSystemsAuditsTable } from './Table';
import {
  initialIntegratedSystemsAuditsTableState,
  IntegratedSystemsAuditsTableAction,
  IntegratedSystemsAuditsTableState
} from './tableState/tableInitialStateAndTypes';
import { integratedSystemsAuditsTableReducer } from './tableState/tableReducer';

const IntegratedSystemsAuditsTableStateContext = createContext<{
  state: IntegratedSystemsAuditsTableState;
}>({
  state: initialIntegratedSystemsAuditsTableState
});

const IntegratedSystemsAuditsTableDispatchContext = createContext<{
  dispatch: Dispatch<IntegratedSystemsAuditsTableAction>;
}>({
  dispatch: (action) => action
});

export const IntegratedSystemsAuditsTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(
    integratedSystemsAuditsTableReducer,
    initialIntegratedSystemsAuditsTableState
  );
  return (
    <IntegratedSystemsAuditsTableStateContext.Provider value={{ state }}>
      <IntegratedSystemsAuditsTableDispatchContext.Provider value={{ dispatch }}>
        <IntegratedSystemsAuditsTable />
      </IntegratedSystemsAuditsTableDispatchContext.Provider>
    </IntegratedSystemsAuditsTableStateContext.Provider>
  );
};

export function useIntegratedSystemsAuditsTableState() {
  return useContext(IntegratedSystemsAuditsTableStateContext);
}
export function useIntegratedSystemsAuditsTableDispatch() {
  return useContext(IntegratedSystemsAuditsTableDispatchContext);
}
