import axios from 'axios';
import { store } from '../index';
import { authoriseError, logout } from '../store/virsis/actions';
import { sendMessage } from '../store/errorOrSuccessMessage/errorOrSuccessMessageAction';
import { FileType } from '../containers/classifiers/FilesTable/types';

const hostname = window && window.location && window.location.hostname;
//const devEnvironment = !!process.env.REACT_APP_DEV;
const devEnvironment = false;
const baseURL = devEnvironment ? `http://${hostname}:8080/virsis-api` : '/virsis-api';

let cancelSessionTimeout: (() => void) | undefined;

export const applicationStorage = localStorage;

export const api = axios.create({
  baseURL,
  responseType: 'json',
  withCredentials: devEnvironment
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      applicationStorage.clear();
      store.dispatch(authoriseError(error));
      const data = error.response.data;
      if (data && data.redirectUrl) {
        window.location.assign(data.redirectUrl);
      }
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((request) => {
  if (cancelSessionTimeout) {
    cancelSessionTimeout();
  }

  if (applicationStorage.length > 0) {
    const sessionMaxInactive = 15 * 60 * 1000;

    const timeout = setTimeout(() => {
      store.dispatch(logout());
      store.dispatch(sendMessage('warning', 'Baigėsi vartotojo sesijos galiojimo laikas'));
    }, sessionMaxInactive);

    cancelSessionTimeout = () => {
      clearTimeout(timeout);
      cancelSessionTimeout = undefined;
    };
  }
  return request;
});

export const get = (url: string) => api.get(url);

export const getWithQuery = (url: string, queryParams: Record<string, any>) =>
  api.get(url, { params: queryParams });

export const getBlob = (url: string) => api.get(url, { responseType: 'blob' });

export const post = (url: string, data?: any) => api.post(url, data);

export const postFile = (url: string, fileContent: Blob, fileName: string, hasUrl: boolean) => {
  const file = new File([fileContent], fileName);
  const formData = new FormData();
  formData.append('file', file, file.name);
  if (hasUrl) {
    formData.append('url', window.location.href);
  }
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export interface FileProps {
  url: string;
  fileContents: Blob;
  fileType: FileType;
}

export const postPdfFile = (fileInput: FileProps) => {
  const { url, fileContents, fileType } = fileInput;
  const formData = new FormData();
  if (fileContents) {
    formData.append('file', fileContents);
  }
  formData.append('fileType', fileType);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const axiosDelete = (url: string, data?: any, headers?: any) =>
  api.delete(`${url}`, { data, headers });

export const put = (url: string, data?: any) => api.put(`${url}`, data);
