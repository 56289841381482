import { IndividualPersonFinancialSupportColumnHeader } from '../IndividualPersonFinancialSupportTable';

export const columnNames: IndividualPersonFinancialSupportColumnHeader[] = [
  {
    id: 'supportProviderName',
    label: 'Paramos teikėjo vardas, pavardė'
  },
  {
    id: 'financialSupportAmount',
    label: 'Paramos suma (EUR)'
  },
  {
    id: 'supportReceivedYear',
    label: 'Metai'
  },
  {
    id: 'dataCreatedDate',
    label: 'Duomenų sukūrimo data',
    type: 'date'
  },
  {
    id: 'dataProvidedDate',
    label: 'Duomenų gavimo data',
    type: 'date'
  }
];
