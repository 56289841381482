import {
  professionalMisconductTableColumns,
  ProfessionalMisconductTableField,
  ProfessionalMisconductTableState
} from './tableInitialStateAndTypes';
import {
  ProfessionalMisconductDataVirs,
  ProfessionalMisconductOutlet
} from '../../../store/professionalMisconducts/professionalMisconductsTypes';
import {
  Filter,
  filterData,
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

const getSortBy = (field: ProfessionalMisconductTableField) => {
  switch (field) {
    case 'outletName':
      return 'professionalMisconductOutlets.outletName';
    case 'misconductDeterminedBy':
      return 'determinedBy';
    case 'professionalMisconduct':
      return 'professionalMisconductOutlets.determinedMisconducts.misconductTypeName';
    case 'sanction':
      return 'professionalMisconductOutlets.determinedMisconducts.sanctions.sanctionTypeName';
    default:
      return field;
  }
};
export function filterAndSortTableData(
  tableState: ProfessionalMisconductTableState,
  data: ProfessionalMisconductDataVirs[]
): ProfessionalMisconductDataVirs[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ProfessionalMisconductDataVirs>[] = [
    getStringMappedColumnFilter(columnsFilters.decisionDate, ({ decisionDate }) => [decisionDate]),
    getStringMappedColumnFilter(columnsFilters.documentNumber, ({ documentNumber }) => [
      documentNumber
    ]),
    getStringMappedColumnFilter(columnsFilters.outletName, ({ professionalMisconductOutlets }) =>
      professionalMisconductOutlets.map((value) => value.outletName)
    ),
    getStringMappedColumnFilter(
      columnsFilters.professionalMisconduct,
      ({ professionalMisconductOutlets }) =>
        professionalMisconductOutlets.flatMap(
          ({ determinedMisconducts: detectedProfessionalMisconducts }) =>
            detectedProfessionalMisconducts.map((value) => value.misconductTypeName)
        )
    ),
    getStringMappedColumnFilter(columnsFilters.sanction, ({ professionalMisconductOutlets }) =>
      professionalMisconductOutlets.flatMap(
        ({ determinedMisconducts: detectedProfessionalMisconducts }) =>
          detectedProfessionalMisconducts.flatMap(({ sanctions }) =>
            sanctions.map((value) => value.sanctionTypeName)
          )
      )
    ),
    getStringCompareMoreFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(
      columnsFilters.misconductDeterminedBy,
      ({ determinedBy: misconductDeterminedBy }) => [misconductDeterminedBy]
    )
  ];

  const filtered = filterData(data, filters);
  const sortBy = getSortBy(tableState.sortBy);
  return stableSortWrapped(filtered, tableState.order, sortBy);
}

export function getTableExportDefinition(
  tableState: ProfessionalMisconductTableState
): TableExportDefinition<ProfessionalMisconductDataVirs> {
  const { mappedValueColumn } = getColumnBuilder<
    ProfessionalMisconductDataVirs,
    ProfessionalMisconductTableField
  >(professionalMisconductTableColumns, tableState.columnsDisplayStatus);

  const getRowGroupHeight = (outlets: ProfessionalMisconductOutlet[]) =>
    outlets
      .flatMap(({ determinedMisconducts }) => determinedMisconducts)
      .flatMap(({ sanctions }) => sanctions).length || 1;

  return {
    title: 'Rimti profesiniai (VIĮ) pažeidimai',
    columnGroups: [
      {
        header: 'Sprendimo',
        columns: [
          mappedValueColumn(
            'decisionDate',
            ({ decisionDate, professionalMisconductOutlets }) => [
              {
                values: [decisionDate],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          ),
          mappedValueColumn(
            'documentNumber',
            ({ documentNumber, professionalMisconductOutlets }) => [
              {
                values: [documentNumber],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'outletName',
            ({ professionalMisconductOutlets }) =>
              professionalMisconductOutlets.map(({ outletName, determinedMisconducts }) => ({
                values: [outletName],
                style: {
                  rowSpan: determinedMisconducts.flatMap(({ sanctions }) => sanctions).length || 1
                }
              })),
            { width: 45 }
          ),
          mappedValueColumn(
            'professionalMisconduct',
            ({ professionalMisconductOutlets }) =>
              professionalMisconductOutlets
                .flatMap(({ determinedMisconducts }) => determinedMisconducts)
                .map(({ misconductTypeName, sanctions }) => ({
                  values: [misconductTypeName],
                  style: { rowSpan: sanctions.length || 1 }
                })),
            { width: 45 }
          ),
          mappedValueColumn(
            'sanction',
            ({ professionalMisconductOutlets }) =>
              professionalMisconductOutlets
                .flatMap(({ determinedMisconducts }) => determinedMisconducts)
                .flatMap(({ sanctions }) => sanctions)
                .map(({ sanctionTypeName }) => ({
                  values: [sanctionTypeName]
                })),
            { width: 45 }
          )
        ]
      },
      {
        header: 'Pažeidimas galioja',
        columns: [
          mappedValueColumn(
            'validFrom',
            ({ validFrom, professionalMisconductOutlets }) => [
              {
                values: [validFrom],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          ),
          mappedValueColumn(
            'validTo',
            ({ validTo, professionalMisconductOutlets }) => [
              {
                values: [validTo],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'misconductDeterminedBy',
            ({ determinedBy, professionalMisconductOutlets }) => [
              {
                values: [determinedBy],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 30 }
          )
        ]
      }
    ]
  };
}
