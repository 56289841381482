import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  getFindNaturalPerson,
  getFindNaturalPersonForeign,
  postCreateNaturalForeignPerson
} from './personsApi';
import {
  searchNaturalPersonData,
  searchNaturalPersonDataSuccess,
  searchNaturalPersonDataError,
  createNaturalForeignPerson,
  createNaturalForeignPersonSuccess,
  createNaturalForeignPersonError,
  searchNaturalPersonForeignData,
  searchNaturalPersonForeignDataSuccess,
  searchNaturalPersonForeignDataError
} from './personsActions';
import {
  SEARCH_NATURAL_PERSON_REQUEST,
  CREATE_NATURAL_FOREIGN_PERSON_REQUEST,
  SEARCH_NATURAL_PERSON_FOREIGN_REQUEST
} from './personsActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';

function* handleNaturalPersonSearchSaga(action: ActionType<typeof searchNaturalPersonData>) {
  try {
    const data = yield call(getFindNaturalPerson, action.payload);
    yield put(searchNaturalPersonDataSuccess(data.data.searchResults));
  } catch (err) {
    yield put(searchNaturalPersonDataError(err.response));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* handleNaturalPersonForeignSearchSaga(
  action: ActionType<typeof searchNaturalPersonForeignData>
) {
  try {
    const data = yield call(getFindNaturalPersonForeign, action.payload);
    yield put(searchNaturalPersonForeignDataSuccess(data.data.searchResults));
  } catch (err) {
    yield put(searchNaturalPersonForeignDataError(err.response));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* handleCreateNaturalForeignPersonSaga(
  action: ActionType<typeof createNaturalForeignPerson>
) {
  try {
    yield call(postCreateNaturalForeignPerson, action.payload);
    yield put(createNaturalForeignPersonSuccess());
    yield put(sendMessage('success', 'Užsenio fizinis asmuo išsaugotas'));
  } catch (err) {
    yield put(createNaturalForeignPersonError(err.response));
    yield put(sendMessage('error', err.response?.data?.message || 'Įvyko klaida'));
  }
}

function* personsSaga() {
  yield takeEvery(SEARCH_NATURAL_PERSON_REQUEST, handleNaturalPersonSearchSaga);
  yield takeEvery(SEARCH_NATURAL_PERSON_FOREIGN_REQUEST, handleNaturalPersonForeignSearchSaga);
  yield takeEvery(CREATE_NATURAL_FOREIGN_PERSON_REQUEST, handleCreateNaturalForeignPersonSaga);
}

export default personsSaga;
