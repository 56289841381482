import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { DatePicker, TimePicker } from 'formik-material-ui-pickers';
import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import moment, { Moment } from 'moment';
import { Strings } from '../../utils/strings/Strings';

interface Props {
  labelText: string;
  dateFromFieldName: string;
  timeFromFieldName: string;
  dateToFieldName: string;
  timeToFieldName: string;
  setFieldValue: (id: string, value: any, shouldValidate?: boolean) => void;
  dateFrom?: string | null;
  timeFrom?: string | null;
  dateTo?: string | null;
  timeTo?: string | null;
}

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    margin: 'auto',
    width: 330,
    lineHeight: '25px',
    textAlign: 'right'
  },
  inputContainerTime: {
    margin: 4,
    display: 'grid',
    gridTemplateColumns: '330px auto',
    gap: '12px'
  },
  clearIcon: {
    size: '12px',
    padding: '4px',
    margin: '0 auto auto auto'
  },
  dateField: {
    fontSize: '12px',
    padding: '4px'
  },
  dateInput: {
    width: 135,
    '& div': {
      paddingRight: 4
    },
    '& button': {
      padding: 0,
      '& svg': {
        transition: 'fill 0.1s'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          fill: '#006FB2'
        }
      }
    }
  },
  timeInput: {
    width: 92
  },
  clearButtonWrap: {
    display: 'flex',
    width: 25,
    marginRight: 4
  },
  clearButton: {
    padding: 0,
    width: 20,
    height: 20,
    margin: 'auto',
    '& svg': {
      height: 18,
      width: 18
    }
  },
  inputContainerTimeInputWrapper: {
    display: 'flex'
  },
  inputContainerTimeWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  inputTypeDateToLabel: {
    margin: 'auto 10px auto 20px'
  },
  [theme.breakpoints.down('md')]: {
    inputContainerTimeWrapper: {
      flexWrap: 'wrap',
      gap: 10
    },
    inputTypeDateToLabel: {
      margin: 'auto 22px auto 0px'
    },
    inputLabel: {
      width: '100%',
      textAlign: 'left'
    }
  },
  [theme.breakpoints.down('sm')]: {
    inputContainerTimeInputWrapper: {
      gap: 5,
      width: '100%'
    },
    inputContainerTime: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    clearButtonWrap: {
      width: 'unset',
      margin: 0
    },
    dateInput: {
      width: '100%'
    },
    timeInput: {
      width: '100%'
    }
  }
}));

export const IntegrationalSystemUsageAuditDateAndTimeFields: React.FC<Props> = ({
  labelText,
  dateFromFieldName,
  timeFromFieldName,
  dateToFieldName,
  timeToFieldName,
  setFieldValue,
  dateFrom,
  timeFrom,
  dateTo,
  timeTo
}) => {
  const classes = useStyles();

  function setDateFromWithDefaultTime(newValue: string) {
    if (!timeFrom) {
      setFieldValue(timeFromFieldName, moment(newValue).startOf('day'), true);
    }
    setFieldValue(dateFromFieldName, moment(newValue), true);
  }

  function setDateToWithDefaultTime(newValue: string) {
    if (!timeTo) {
      setFieldValue(timeToFieldName, moment(), true);
    }
    setFieldValue(dateToFieldName, moment(newValue), true);
  }

  return (
    <Box className={classes.inputContainerTime}>
      <Typography variant="h4" className={classes.inputLabel}>
        {labelText}
      </Typography>
      <div className={classes.inputContainerTimeWrapper}>
        <div className={classes.inputContainerTimeInputWrapper}>
          <Typography style={{ margin: 'auto 10px auto 0' }}>nuo</Typography>

          <Field
            component={DatePicker}
            format="YYYY-MM-DD"
            name={dateFromFieldName}
            disabled={false}
            inputVariant="outlined"
            classes={{ root: classes.dateInput }}
            placeholder="mmmm-mm-dd"
            inputProps={{
              value: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : ''
            }}
            onChange={setDateFromWithDefaultTime}
            cancelLabel={Strings.button__cancel}
            disableFuture
            okLabel="Pasirinkti"
            maxDate={dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined}
            maxDateMessage={`Data negali būti velesnė už ${moment(dateTo).format('YYYY-MM-DD')}`}
          />

          <div className={classes.clearButtonWrap}>
            {dateFrom && (
              <IconButton
                className={classes.clearButton}
                onClick={() => {
                  setFieldValue(dateFromFieldName, null);
                  setFieldValue(timeFromFieldName, null);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Field
            component={TimePicker}
            ampm={false}
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            format="HH:mm:ss"
            name={timeFromFieldName}
            disabled={!dateFrom}
            placeholder="hh:mm:ss"
            inputVariant="outlined"
            classes={{ root: classes.timeInput }}
            inputProps={{
              value: timeFrom ? moment(timeFrom).format('HH:mm:ss') : ''
            }}
            onAccept={(time: Moment) => {
              if (
                time.isAfter(moment()) &&
                moment().startOf('day').isSame(moment(dateFrom).startOf('day'))
              ) {
                setFieldValue(timeFromFieldName, moment());
              }
            }}
            cancelLabel={Strings.button__cancel}
            okLabel="Pasirinkti"
          />

          <div className={classes.clearButtonWrap}>
            {timeFrom && (
              <IconButton
                className={classes.clearButton}
                onClick={() => {
                  setFieldValue(timeFromFieldName, null);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.inputContainerTimeInputWrapper}>
          <Typography className={classes.inputTypeDateToLabel}>iki</Typography>
          <Field
            component={DatePicker}
            format="YYYY-MM-DD"
            name={dateToFieldName}
            placeholder="mmmm-mm-dd"
            disabled={false}
            inputVariant="outlined"
            classes={{ root: classes.dateInput }}
            disableFuture
            inputProps={{
              value: dateTo ? moment(dateTo).format('YYYY-MM-DD') : ''
            }}
            cancelLabel={Strings.button__cancel}
            okLabel="Pasirinkti"
            onChange={setDateToWithDefaultTime}
            minDate={dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined}
            minDateMessage={`Data negali būti ankstesnė už ${moment(dateFrom).format(
              'YYYY-MM-DD'
            )}`}
          />
          <div className={classes.clearButtonWrap}>
            {dateTo && (
              <IconButton
                className={classes.clearButton}
                onClick={() => {
                  setFieldValue(dateToFieldName, null);
                  setFieldValue(timeToFieldName, null);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Field
            component={TimePicker}
            ampm={false}
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            placeholder="hh:mm:ss"
            name={timeToFieldName}
            disabled={!dateTo}
            inputVariant="outlined"
            classes={{ root: classes.timeInput }}
            inputProps={{
              value: timeTo ? moment(timeTo).format('HH:mm:ss') : ''
            }}
            onAccept={(time: Moment) => {
              if (
                time.isAfter(moment()) &&
                moment().startOf('day').isSame(moment(dateTo).startOf('day'))
              ) {
                setFieldValue(timeToFieldName, moment());
              }
            }}
            cancelLabel={Strings.button__cancel}
            okLabel="Pasirinkti"
          />
          <div className={classes.clearButtonWrap}>
            {timeTo && (
              <IconButton
                className={classes.clearButton}
                onClick={() => {
                  setFieldValue(timeToFieldName, null);
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
