import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../style/virsisTheme';

export const useLinkButtonStyles = makeStyles({
  linkButton: {
    display: 'inline',
    fontFamily: 'Open Sans',
    fontSize: '0.85rem',
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: '0px',
    margin: '0px',
    height: 'auto',
    marginRight: '5px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    }
  }
});

interface ButtonProps {
  text: string;
  onClicked?: () => void;
  disabled?: boolean;
}

const BtnOpenNewDoc: React.FC<ButtonProps> = ({ text, onClicked, disabled }) => {
  const classes = useLinkButtonStyles();

  return (
    <Button disabled={disabled} className={classes.linkButton} onClick={onClicked}>
      {text}
    </Button>
  );
};
export default BtnOpenNewDoc;
