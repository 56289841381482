import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Paper, Table, TableBody, Typography } from '@material-ui/core';

import { ApplicationState } from '../../store';

import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { pageTableData } from '../../utils/tableDataFunctions';

import { filterAndSortTableData, getTableExportDefinition } from './tableState/tableFunctions';
import { ProfMisconductCustomizationBar } from './TableCustomization';
import ProfessionalMiconductTableCustomFilter from './FilterRow';
import {
  useProfessionalMisconductTableState,
  useProfessionalMisconductTableDispatch
} from './Context';
import { ProfessionalMisconductBasicRow } from './TableRow';
import MisconductTableHeader from './TableHeader';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { professionalMisconductTableColumns } from './tableState/tableInitialStateAndTypes';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { getVirsDocument } from '../../store/shareholders/shareholdersApi';

const ProfesionalMisconductTable: React.FC = () => {
  const { state: tableState } = useProfessionalMisconductTableState();
  const { dispatch: tableDispatch } = useProfessionalMisconductTableDispatch();

  const reduxDispatch = useDispatch();
  const { customFilterOn, page, rowsPerPage } = tableState;

  const {
    virsis: { virsData },
    professionalMisconductData: {
      professionalMisconductVirsData,
      loadingProfessionalMisconductVirsData,
      professionalMisconductVirsDataError
    }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    if (professionalMisconductVirsData) {
      tableDispatch({
        type: 'TABLE_INITIALIZED'
      });
    }
  }, [tableDispatch, professionalMisconductVirsData]);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPageNumber: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage: rowsPerPageNumber
    });
  };

  const openDocumentInNewTab = (documentId: number) => {
    reduxDispatch(getVirsDocument(documentId));
  };

  const data = filterAndSortTableData(tableState, professionalMisconductVirsData || []);
  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <div className="named-data-table">
      <div
        className="table-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">Rimti profesiniai (VIĮ) pažeidimai</Typography>

        <Box>
          <PageActionButtons
            dontShowDelete
            onDownload={(format, navigationPath) =>
              exportTableData(format, getTableExportDefinition(tableState), data, navigationPath)
            }
            onPrint={(navigationPath) =>
              printTableData(getTableExportDefinition(tableState), data, navigationPath)
            }
          />
        </Box>
      </div>

      <Paper elevation={0}>
        <ProfMisconductCustomizationBar columnNames={professionalMisconductTableColumns} />

        <ScrollXContainer>
          <Table>
            <MisconductTableHeader />
            {customFilterOn && (
              <ProfessionalMiconductTableCustomFilter
                columnNames={professionalMisconductTableColumns}
              />
            )}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingProfessionalMisconductVirsData}
                colSpan={9}
              />

              <TableErrorRow
                error={
                  professionalMisconductVirsDataError &&
                  'Klaida. Nepavyko gauti profesinių pažeidimų duomenų'
                }
                colSpan={9}
              />

              {!loadingProfessionalMisconductVirsData &&
                !professionalMisconductVirsDataError &&
                virsData &&
                dataPage.map((record) => (
                  <ProfessionalMisconductBasicRow
                    key={record.professionalMisconductId}
                    record={record}
                    openDocumentInNewTab={openDocumentInNewTab}
                    virsId={virsData.virsId}
                  />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </Paper>
    </div>
  );
};

export default withRouter(ProfesionalMisconductTable);
