import { PaginationParams, SortOrder } from '../../utils/tableTypes';
import { Order } from '../../components/TableTypes/TableTypes';
import {
  EthicalNonComplianceCustomFilter,
  EthicalNonComplianceHeaderField
} from '../../containers/EthicalNonComplianceTableInst/tableState/tableInitialStateAndTypes';

export interface EthicalNonCompliancePageRequest extends PaginationParams {
  filterVirsId: number | null;
  filterDecisionDate: string | null;
  filterDocumentNumber: string | null;
  filterOutletId: number | null;
  filterDecisionStatusId: number | null;
  filterValidFrom: string | null;
  filterValidTo: string | null;
  sortingVirsName: SortOrder | null;
  sortingDecisionDate: SortOrder | null;
  sortingDocumentNumber: SortOrder | null;
  sortingOutletName: SortOrder | null;
  sortingDecisionStatus: SortOrder | null;
  sortingValidFrom: SortOrder | null;
  sortingValidTo: SortOrder | null;
}

export interface EthicalNonComplianceTableDataState {
  sortBy: EthicalNonComplianceHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  customFilter: EthicalNonComplianceCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
}

export const initialEthicalNonComplianceTableDataState: EthicalNonComplianceTableDataState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 10,
  customFilter: {
    virsName: [],
    decisionDate: [],
    documentNumber: [],
    outletList: [],
    decisionStatus: [],
    validFrom: [],
    validTo: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false
};
