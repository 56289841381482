import React from 'react';
import { makeStyles, Menu } from '@material-ui/core';
import MainTableFilterItems from '../../MainTable/Components/MainTableFilterItems';
import SelectedTableFilterItems from '../../SelectedDateTable/Components/SelectedTableFilterItems';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../../MainTable/ShareholdersTableWithContext';
import FilterButtonWithRef from './FilterButtonWithRef';

const useStyles = makeStyles(() => ({
  menu: {
    '& ul': {
      width: '100% !important'
    }
  },
  padding: {
    padding: '14px 20px'
  }
}));

interface Props {
  allEventsDates?: string[];
  disabled?: boolean;
  mainTable?: boolean;
}

const ShareholderFilterTool: React.FC<Props> = ({ allEventsDates, disabled, mainTable }) => {
  const classes = useStyles();

  const { state: tableState } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  const toggleFilterButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_DISPLAY_TOGGLED',
      filterButtonAnchor: tableState.filterButtonAnchor === null ? event.currentTarget : null
    });
  };

  return (
    <>
      <FilterButtonWithRef
        onFilterTabToggle={toggleFilterButton}
        showFilter={!!tableState.filterButtonAnchor}
        disabled={disabled}
      />
      <Menu
        anchorEl={tableState.filterButtonAnchor}
        keepMounted
        open={!!tableState.filterButtonAnchor}
        onClose={toggleFilterButton}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.menu}
      >
        <div className={classes.padding}>
          {mainTable ? (
            <MainTableFilterItems allEventsDates={allEventsDates} />
          ) : (
            <SelectedTableFilterItems />
          )}
        </div>
      </Menu>
    </>
  );
};
export default ShareholderFilterTool;
