import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, Radio, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  radioIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    height: 15,
    width: 15,
    'input:disabled ~ &': {
      border: `1px solid ${theme.palette.info.main}`
    }
  },
  checkedRadioIcon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      backgroundImage: `radial-gradient(14px circle at center, ${theme.palette.primary.main} 28%, transparent 35%)`,
      content: '""',
      display: 'block',
      height: 15,
      width: 15
    }
  },
  radioButton: {
    padding: 12,
    '&:hover': {
      backgroundColor: 'rgba(0, 111, 178, 0.04)',
      borderRadius: '50%'
    }
  },
  radioButtonLabel: {
    whiteSpace: 'nowrap'
  }
}));

interface Props {
  value: string | number;
  label?: string;
  disabled?: boolean;
}

export const CustomRadioButton: React.FC<Props> = ({ value, label, disabled }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      control={
        <Radio
          checkedIcon={<span className={classes.checkedRadioIcon} />}
          icon={<span className={classes.radioIcon} />}
          className={classes.radioButton}
        />
      }
      label={
        label && (
          <Typography variant="h6" className={classes.radioButtonLabel}>
            {label}
          </Typography>
        )
      }
    />
  );
};
