import React, { useState } from 'react';
import { Typography, Grid, Divider, CircularProgress } from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { resetSearchNaturalPersonData } from '../../store/persons/personsActions';
import { SearchResults } from './SearchResults';
import { SearchForm } from './SearchForm';
import { FindNaturalPersonInVirsisQuery } from './Utilities';
import { searchNaturalPersonInVirsisRequest } from '../../store/findnaturalpersoninvirsis/actions';

interface FindRepresentativeState {
  searchClicked: boolean;
  showingAddNaturalForeign: boolean;
}

const initialFindRepresentativeState: FindRepresentativeState = {
  searchClicked: false,
  showingAddNaturalForeign: false
};

export const MainPage: React.FC = () => {
  const [dialogState, setDialogState] = useState<FindRepresentativeState>(
    initialFindRepresentativeState
  );

  const {
    selectedVirsByNaturalPersonLoading,
    selectedVirsByNaturalPersonResults,
    selectedVirsByNaturalPersonError
  } = useSelector((state: ApplicationState) => state.findNaturalPersonInVirsis);

  const reduxDispatch = useDispatch();

  function searchForNaturalPersonFormik(personQuery: FindNaturalPersonInVirsisQuery) {
    reduxDispatch(resetSearchNaturalPersonData());
    setDialogState({
      ...dialogState,
      searchClicked: true
    });
    reduxDispatch(searchNaturalPersonInVirsisRequest(personQuery));
  }

  return (
    <>
      <Divider />
      <SearchForm
        searchPerson={searchForNaturalPersonFormik}
        isSearching={selectedVirsByNaturalPersonLoading}
      />
      <Divider />
      <Grid
        container
        item
        direction="column"
        justify="flex-end"
        alignItems="flex-start"
        style={{ paddingBottom: '50px' }}
      >
        {selectedVirsByNaturalPersonLoading && <CircularProgress />}
        {!dialogState.showingAddNaturalForeign && selectedVirsByNaturalPersonError && (
          <Typography>{selectedVirsByNaturalPersonError.message}</Typography>
        )}
        {!selectedVirsByNaturalPersonLoading &&
          dialogState.searchClicked &&
          selectedVirsByNaturalPersonResults &&
          selectedVirsByNaturalPersonResults.length === 0 && (
            <>
              <Typography variant="h5">{`Pagal Jūsų paiešką nebuvo rasta įrašų`}</Typography>
            </>
          )}
        {dialogState.searchClicked &&
          !selectedVirsByNaturalPersonLoading &&
          selectedVirsByNaturalPersonResults &&
          selectedVirsByNaturalPersonResults.length > 0 && (
            <SearchResults personSearchResults={selectedVirsByNaturalPersonResults} />
          )}
      </Grid>
    </>
  );
};
