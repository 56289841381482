import { ApplicationState } from '../index';

export const contactEmail = (state: ApplicationState) => state.virsis.contactEmail;
export const agreementDate = (state: ApplicationState) => state.virsis.agreementDate;
export const agreementFile = (state: ApplicationState) => state.virsis.agreementFile;
export const virsData = (state: ApplicationState) => state.virsis.virsData;
export const virsisData = (state: ApplicationState) => state.virsis;
export const accountType = (state: ApplicationState) => state.virsis.selectedAccountType;
export const user = (state: ApplicationState) => state.virsis.currentUser;
export const getPersonId = (state: ApplicationState) => state.virsis.selectedPersonId;
export const authoriseData = (state: ApplicationState) => state.virsis.authoriseData;
export const isVirsEmailBeingEdited = (state: ApplicationState) =>
  state.virsis.isVirsEmailBeingEdited;
