import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ShareholderGroupPersons } from './components/ShareholderGroupPersons';
import { ShareholderPerson } from './components/ShareholderPerson';
import { getMarginLeft } from '../../../../../utilityFunctions/sharedUIFunctions';
import { whiteColor } from '../../../../../constants';
import { ShareholderGroupPerson } from '../../../../tableState/tableInitialStateAndTypes';

export interface Props {
  authorizedPersonId: number | null;
  isGroup: boolean;
  onAddPersonClick: () => void;
  onIconClick: () => void;
  onRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemove: (personId: number) => void;
  shareholderLevel: number;
  index: number;
  shareholderPersonsList: ShareholderGroupPerson[];
  isShareholderFromJadis: boolean;
  isSelectedPersonUnique: boolean;
  mayShareholderHaveChildren: boolean;
}

export const ShareholderDetails: React.FC<Props> = ({
  authorizedPersonId,
  isGroup,
  onAddPersonClick,
  index,
  onIconClick,
  onRadioChange,
  onRemove,
  shareholderLevel,
  shareholderPersonsList,
  isShareholderFromJadis,
  isSelectedPersonUnique,
  mayShareholderHaveChildren
}) => {
  const classes = makeStyles({
    shareholderDetailsContainer: {
      backgroundColor: whiteColor,
      marginLeft: getMarginLeft(shareholderLevel) + 40
    }
  })();

  const [shareholderPerson] = shareholderPersonsList;

  return (
    <div className={classes.shareholderDetailsContainer}>
      {isGroup ? (
        <ShareholderGroupPersons
          shareholderPersonsList={shareholderPersonsList}
          index={index}
          onRemove={onRemove}
          onRadioChange={onRadioChange}
          radioValue={authorizedPersonId || null}
          onAddPersonClick={onAddPersonClick}
          isShareholderFromJadis={isShareholderFromJadis}
          isSelectedPersonUnique={isSelectedPersonUnique}
          mayShareholderHaveChildren={mayShareholderHaveChildren}
        />
      ) : (
        shareholderPerson && (
          <ShareholderPerson
            shareholderPerson={shareholderPerson}
            onIconClick={onIconClick}
            isShareholderFromJadis={isShareholderFromJadis}
          />
        )
      )}
    </div>
  );
};
