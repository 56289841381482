import React, { useReducer } from 'react';
import {
  innerTableReducer,
  initialInnerTableState,
  EnterpriseDataLicenceDataField
} from './innerTableReducerAndTypes';
import { InnerLicencesTable } from './InnerTable';
import { EnterpriseLicenceData } from '../../../../store/enterprises/enterprisesTypes';
import { getSortedDataGeneric } from '../../../../utils/tableDataFunctions';

interface Props {
  data: EnterpriseLicenceData[];
}

export const InnerTableContainer: React.FC<Props> = ({ data }) => {
  const [state, dispatch] = useReducer(innerTableReducer, initialInnerTableState);

  function sortingChanged(column: EnterpriseDataLicenceDataField): void {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  }

  return (
    <InnerLicencesTable
      licences={getSortedDataGeneric(data, state.order, state.sortBy)}
      sortBy={state.sortBy}
      order={state.order}
      setSorting={sortingChanged}
    />
  );
};
