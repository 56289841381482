import { getBorder } from '../../utilityFunctions/sharedUIFunctions';
import { getEventWidth, getLeftParameter } from './filteredViewStylesUtils';

const getVerticalBorder = (
  startDate: string,
  date: string,
  index: number,
  startWithTriangle?: boolean,
  borderColor?: string
) => {
  if (borderColor) {
    if (startWithTriangle === false && startDate === date && index === 0) {
      return `1px solid ${borderColor}`;
    }
  }
  return undefined;
};

const getLeftBorderRadius = (
  startDate: string,
  date: string,
  allEventsNumber: number,
  index: number,
  startWithTriangle?: boolean
) => {
  if (!startWithTriangle && startDate === date && index === 0) {
    return '5px';
  }
  return '0px';
};

const drawRightBorder = (
  endDate: string | null,
  allEventsNumber: number,
  index: number,
  date: string,
  eventDate?: string,
  borderColor?: string
) => {
  if (endDate === date && index === allEventsNumber - 1) {
    return `1px solid ${borderColor}`;
  }

  return undefined;
};

const getRightBorderRadius = (
  endDate: string | null,
  allEventsNumber: number,
  index: number,
  date: string
) => {
  if (endDate === date && index === allEventsNumber - 1) {
    return '5px';
  }
  return '0px';
};

export const monthsRowStyles = (
  index: number,
  allEventsNumber: number,
  isShareholderFromJADIS: boolean,
  startDate: string,
  endDate: string | null,
  virsEndDate: string | null,
  date: string,
  fillWithColor: string,
  startWithTriangle: boolean,
  borderColor: string,
  textColor: string,
  eventDate?: string,
  isSelectedDateEventDate?: boolean
) => {
  return {
    width: getEventWidth(
      endDate,
      startDate,
      date,
      allEventsNumber,
      startWithTriangle,
      index,
      eventDate
    ),
    backgroundColor: fillWithColor,
    left: getLeftParameter(startDate, date, allEventsNumber, index, startWithTriangle, eventDate),
    borderTopLeftRadius: getLeftBorderRadius(
      startDate,
      date,
      allEventsNumber,
      index,
      startWithTriangle
    ),
    borderBottomLeftRadius: getLeftBorderRadius(
      startDate,
      date,
      allEventsNumber,
      index,
      startWithTriangle
    ),
    borderTopRightRadius: getRightBorderRadius(endDate, allEventsNumber, index, date),
    borderBottomRightRadius: getRightBorderRadius(endDate, allEventsNumber, index, date),
    borderTop: getBorder(borderColor),
    borderBottom: getBorder(borderColor),
    borderRight: drawRightBorder(endDate, allEventsNumber, index, date, eventDate, borderColor),
    borderLeft: getVerticalBorder(startDate, date, index, startWithTriangle, borderColor),
    color: textColor,
    padding: '0px',
    minWidth: '1px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  };
};
