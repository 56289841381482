import React from 'react';

import { AddNewRecordButton } from '../../../../../components/TableButtons/AddNewRecordButton';
import { NO_ADDING_TEXT, VIRS_UNREGISTERED_TEXT } from '../../../constants';
import {
  ActivityPeriod,
  ShareholdersProps
} from '../../../../../store/shareholders/shareholdersTypes';
import moment, { Moment } from 'moment';
import { Strings } from '../../../../../utils/strings/Strings';
import { isDateUnsigned } from '../../../MainTable/tableState/tableFunctions';

interface Props {
  noValidShareholderParent: boolean;
  handleAddShareholder: () => void;
  loadingShareholdersSelectedDateData: boolean;
  selectedDate: string;
  virsHasShareholders: boolean;
  tableStartDate: string;
  virsStoppedParticipatingFromDate: string | null;
  activityPeriods: ActivityPeriod[];
  shareholders: ShareholdersProps[];
  lastSignedDate: Moment | undefined;
}
export const AddShareholderButtonWithTooltip: React.FC<Props> = ({
  noValidShareholderParent,
  handleAddShareholder,
  loadingShareholdersSelectedDateData,
  selectedDate,
  virsHasShareholders,
  tableStartDate,
  virsStoppedParticipatingFromDate,
  shareholders,
  activityPeriods,
  lastSignedDate
}) => {
  const addButtonDisable =
    activityPeriods.length === 0 ||
    noValidShareholderParent ||
    loadingShareholdersSelectedDateData ||
    virsStoppedParticipatingFromDate === selectedDate ||
    (lastSignedDate !== undefined && moment(selectedDate).isSameOrBefore(lastSignedDate)) ||
    !isDateUnsigned(shareholders, activityPeriods, selectedDate);

  const participateBeforeTooltip =
    !virsHasShareholders && selectedDate > tableStartDate && !addButtonDisable;

  const showTooltip = addButtonDisable || participateBeforeTooltip;

  const getTooltipText = () => {
    if (participateBeforeTooltip) {
      return `Jei dalyvis, kurį norite pridėti, dalyvauja anksčiau nei nuo ${tableStartDate}, pasirinkite ${tableStartDate} datą ir nurodykite požymį, jog dalyvis dalyvauja nuo anksčiau.`;
    }
    if (noValidShareholderParent) {
      return NO_ADDING_TEXT;
    }
    if (virsStoppedParticipatingFromDate === selectedDate) {
      return VIRS_UNREGISTERED_TEXT;
    }
    return '';
  };
  return (
    <AddNewRecordButton
      text={Strings.shareholder__addShareholder}
      onClicked={handleAddShareholder}
      disabled={addButtonDisable}
      tooltipText={getTooltipText()}
      showTooltip={showTooltip}
    />
  );
};
