import React, { useContext } from 'react';

import DownloadButton from './DownloadButton';
import PrintButton from './PrintButton';
import DeleteButton from './DeleteButton';
import { FileFormats } from '../../utils/exporters/types';
import { BreadcrumbsContext } from '../Header/HeaderBreadCrumbs';

interface Props {
  onDownload?: (format: FileFormats, navigationPath: string[]) => void;
  onPrint?: (navigationPath: string[]) => void;
  onClickDelete?: () => void;
  dontShowDelete?: boolean;
}

const PageActionButtons: React.FC<Props> = ({
  onDownload,
  onClickDelete,
  dontShowDelete,
  onPrint
}) => {
  const navigationPath = useContext(BreadcrumbsContext).breadcrumbs.map(({ label }) => label);

  return (
    <>
      <DownloadButton onClick={(format) => onDownload?.(format, navigationPath)} />
      <PrintButton onClick={() => onPrint?.(navigationPath)} />
      {!dontShowDelete && <DeleteButton onClick={onClickDelete} />}
    </>
  );
};

export default PageActionButtons;
