import React from 'react';
import Box from '@material-ui/core/Box';
import { Field } from 'formik';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import { IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FormikFieldStyleProps } from './FieldStyles';
import { noOptionsText } from '../TableCustomFilterCell/constants';

interface Props extends FormikFieldStyleProps {
  styledClasses: Record<string, string>;
  isRequired: boolean;
  options: any[];
  errors: any;
  formikKey: string;
  setFieldValue: (id: string, value: any) => void;
  value: any;
  defaultValue?: any;
  label?: string;
  isClearable: boolean;
  getOptionLabel: any;
  withoutLabel?: boolean;
  disabled?: boolean;
  onChange?: (value: any) => void;
  className?: string;
}

type AutocompleteInnerProps = JSX.IntrinsicAttributes &
  AutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined>;

export const FormikFormSelectField: React.FC<Props> = ({
  styledClasses,
  isRequired,
  disabled,
  options,
  errors,
  formikKey,
  setFieldValue,
  value,
  onChange,
  defaultValue,
  label,
  isClearable,
  getOptionLabel,
  withoutLabel,
  style,
  className
}) => {
  return (
    <Box
      margin={1}
      className={styledClasses.inputContainer + ' ' + className}
      style={style?.container}
    >
      {!withoutLabel && (
        <Typography variant="h4" className={styledClasses.inputLabel}>
          {label}
        </Typography>
      )}
      <Field
        name={formikKey}
        component={(props: AutocompleteInnerProps) => (
          <Autocomplete
            {...props}
            size="small"
            onChange={(_event, fieldValue) => {
              if (onChange) {
                onChange(fieldValue);
              }
              setFieldValue(formikKey, fieldValue);
            }}
          />
        )}
        options={options}
        closeIcon=""
        getOptionLabel={getOptionLabel}
        noOptionsText={noOptionsText}
        disabled={disabled || options.length === 0}
        className={styledClasses.selectInput}
        clearOnEscape
        renderInput={(params: AutocompleteRenderInputParams) => (
          <MuiTextField
            {...params}
            size="small"
            error={!!errors}
            helperText={errors}
            FormHelperTextProps={{ style: { ...style?.helper } }}
            required={isRequired}
            title=""
            variant="outlined"
          />
        )}
      />
      {value && isClearable && (
        <IconButton
          className={styledClasses.clearIcon}
          disabled={disabled}
          onClick={() => {
            setFieldValue(formikKey, defaultValue);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};
