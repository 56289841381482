import React from 'react';
import { Container } from '@material-ui/core';
import { DataEditingAlert } from '../../components/Alert/DataEditingAlert';
import { UpdatedDataAlert } from './SharedComponents/UpdatedDataAlert';
import { MissingActivityPeriodAlert } from './SharedComponents/MissingActivityPeriodsAllert';

const ShareholdersTableContainer: React.FC = ({ children }) => {
  return (
    <Container maxWidth="xl" style={{ flex: '1 0 auto' }}>
      <DataEditingAlert />
      <UpdatedDataAlert />
      <MissingActivityPeriodAlert />
      <div className="data-page">
        <div className="data-page-header" />
        {children}
      </div>
    </Container>
  );
};

export default ShareholdersTableContainer;
