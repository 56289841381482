import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  fetchOutletData,
  fetchOutletDataError,
  fetchOutletDataSuccess,
  createOutlet,
  createOutletSuccess,
  createOutletError,
  updateOutlet,
  updateOutletSuccess,
  updateOutletError,
  removeOutlet,
  removeOutletSuccess,
  removeOutletError,
  renewOutlet,
  renewOutletSuccess,
  renewOutletError,
  removeOutletsRequest,
  removeOutletsSuccess,
  removeOutletsError,
  findOutletOnLibis,
  findOutletOnLibisSuccess,
  findOutletOnLibisError,
  resetOutletRemovingState,
  resetOutletRenewingState
} from './outletsActions';
import {
  getOutletData,
  postCreateOutlet,
  putUpdateOutlet,
  deleteRemoveOutlet,
  postRenewOutlet,
  deleteOutlets,
  getFindOutletOnLibisData
} from './outletsApi';
import {
  FETCH_OUTLET_DATA_REQUEST,
  CREATE_OUTLET_REQUEST,
  UPDATE_OUTLET_REQUEST,
  REMOVE_OUTLET_REQUEST,
  RENEW_OUTLET_REQUEST,
  REMOVE_OUTLETS_REQUEST,
  FIND_OUTLET_ON_LIBIS_REQUEST
} from './outletsActionsTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { fetchVirsDataRequest } from '../virsis/actions';
import { LibisSearchData } from './outletsTypes';
import { getSearchTermDistance } from '../../utils/textSearch';

function* handleOutletDataFetchSaga(action: ActionType<typeof fetchOutletData>) {
  try {
    const { virId } = action.payload;
    const { data } = yield call(getOutletData, virId);
    yield put(fetchOutletDataSuccess(data));
  } catch (err) {
    yield put(fetchOutletDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message || 'Atsiprašome. Klaida'));
  }
}

function* handleCreateOutletSaga(action: ActionType<typeof createOutlet>) {
  try {
    const { virId, record, message } = action.payload;
    const { data } = yield call(postCreateOutlet, virId, record);
    yield put(createOutletSuccess(data));
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', message));
  } catch (err) {
    yield put(createOutletError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleRenewOutletSaga(action: ActionType<typeof renewOutlet>) {
  try {
    const { virId, virOutletControllId, record, message } = action.payload;
    const { data } = yield call(postRenewOutlet, virId, virOutletControllId, record);
    yield put(renewOutletSuccess(data));
    yield put(fetchVirsDataRequest());
    yield put(fetchOutletData(virId));
    yield put(resetOutletRenewingState());
    yield put(sendMessage('success', message));
  } catch (err) {
    yield put(renewOutletError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateOutletSaga(action: ActionType<typeof updateOutlet>) {
  try {
    const { virId, virOutletControllId, record, message } = action.payload;
    yield call(putUpdateOutlet, virId, virOutletControllId, record);
    yield put(updateOutletSuccess());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', message));
  } catch (err) {
    yield put(updateOutletError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveOutletSaga(action: ActionType<typeof removeOutlet>) {
  try {
    const { virId, virOutletControllId } = action.payload;
    yield call(deleteRemoveOutlet, virId, virOutletControllId);
    yield put(removeOutletSuccess());
    yield put(resetOutletRemovingState());
    yield put(fetchOutletData(virId));
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'VIP įrašas pašalintas'));
  } catch (err) {
    yield put(removeOutletError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveOutletsSaga(action: ActionType<typeof removeOutletsRequest>) {
  try {
    yield call(deleteOutlets, action.payload);
    yield put(removeOutletsSuccess());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'VIP įrašas pašalintas'));
  } catch (err) {
    yield put(removeOutletsError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleFindOutletOnLibisSaga(action: ActionType<typeof findOutletOnLibis>) {
  try {
    const { title, internationalNumber } = action.payload;
    const {
      data: { libisResults }
    }: { data: { libisResults: LibisSearchData[] } } = yield call(
      getFindOutletOnLibisData,
      title,
      internationalNumber
    );

    const maxTitleLength = libisResults.reduce(
      (prev, current) =>
        !!current.title && current.title.length > prev ? current.title.length : prev,
      0
    );

    const results = libisResults.map((value) => {
      return {
        ...value,
        searchTermDistance:
          value.title && title
            ? getSearchTermDistance(title, value.title, maxTitleLength)
            : Infinity
      };
    });
    yield put(findOutletOnLibisSuccess(results));
  } catch (err) {
    yield put(findOutletOnLibisError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* outletsSaga() {
  yield takeEvery(FETCH_OUTLET_DATA_REQUEST, handleOutletDataFetchSaga);
  yield takeEvery(CREATE_OUTLET_REQUEST, handleCreateOutletSaga);
  yield takeEvery(RENEW_OUTLET_REQUEST, handleRenewOutletSaga);
  yield takeEvery(UPDATE_OUTLET_REQUEST, handleUpdateOutletSaga);
  yield takeEvery(REMOVE_OUTLET_REQUEST, handleRemoveOutletSaga);
  yield takeEvery(REMOVE_OUTLETS_REQUEST, handleRemoveOutletsSaga);
  yield takeEvery(FIND_OUTLET_ON_LIBIS_REQUEST, handleFindOutletOnLibisSaga);
}

export default outletsSaga;
