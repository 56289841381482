import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  documents: {
    documentId: number;
    documentNumber: string;
  }[];
  onClickedOpenInDialog: (documentId: number, documentNumber: string) => void;
  className?: string;
  prefix?: string;
}

const useStyles = makeStyles({
  default: {
    display: 'block'
  }
});

export const ElDocumentLinks: React.FC<Props> = ({
  documents,
  className,
  prefix,
  onClickedOpenInDialog
}) => {
  const classes = useStyles();

  return (
    <>
      {documents.map((document) => (
        <Link
          className={className || classes.default}
          to="#"
          onClick={() => onClickedOpenInDialog(document.documentId, document.documentNumber)}
          key={`${document.documentId}${document.documentNumber}`}
        >
          {prefix && `${prefix} ${document.documentNumber}`}
          {!prefix && document.documentNumber}
        </Link>
      ))}
    </>
  );
};
