import {
  EthicalNonComplianceCustomFilter,
  EthicalNonComplianceHeaderField,
  ethicalNonComplianceTableColumns,
  EthicalNonComplianceTableState
} from './tableInitialStateAndTypes';
import {
  Filter,
  filterData,
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { VirsEthicalNonComplianceData } from '../../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export const updateEthicalNonComplianceCustomFilter = (
  filter: EthicalNonComplianceCustomFilter,
  filterBy: EthicalNonComplianceHeaderField,
  value: string | null
): EthicalNonComplianceCustomFilter => {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
};

const getSortBy = (sortBy: EthicalNonComplianceHeaderField): string => {
  if (sortBy === 'outletList') {
    return 'outletList.outletName';
  } else {
    return sortBy;
  }
};

export function filterAndSortTableData(
  tableState: EthicalNonComplianceTableState,
  data: VirsEthicalNonComplianceData[]
): VirsEthicalNonComplianceData[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;

  const filters: Filter<VirsEthicalNonComplianceData>[] = [
    getStringMappedColumnFilter(columnsFilters.decisionDate, ({ decisionDate }) => [decisionDate]),
    getStringMappedColumnFilter(columnsFilters.documentNumber, ({ documentNumber }) => [
      documentNumber
    ]),
    getStringMappedColumnFilter(columnsFilters.decisionStatus, ({ decisionStatus }) => [
      decisionStatus
    ]),
    getStringCompareMoreFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(columnsFilters.reportingInstitution, ({ reportingInstitution }) => [
      reportingInstitution
    ]),
    getStringMappedColumnFilter(columnsFilters.outletList, ({ outletList }) =>
      outletList.map((value) => value.outletName)
    )
  ];
  const filtered = filterData(data, filters);
  const sortBy: string = getSortBy(tableState.sortBy);
  return stableSortWrapped(filtered, tableState.order, sortBy);
}

export function getTableExportDefinition(
  tableState: EthicalNonComplianceTableState
): TableExportDefinition<VirsEthicalNonComplianceData> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    VirsEthicalNonComplianceData,
    EthicalNonComplianceHeaderField
  >(ethicalNonComplianceTableColumns, tableState.columnsDisplayStatus);
  return {
    title: 'Profesinės etikos nesilaikymas',
    columnGroups: [
      {
        header: 'Sprendimo',
        columns: [
          stringValueColumn('decisionDate', { width: 15 }),
          stringValueColumn('documentNumber', { width: 15 })
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'outletList',
            ({ outletList }) => [{ values: outletList.map(({ outletName }) => outletName) }],
            { width: 45 }
          ),
          stringValueColumn('decisionStatus', { width: 45 })
        ]
      },
      {
        header: 'Galioja',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      },
      { columns: [stringValueColumn('reportingInstitution', { width: 45 })] }
    ]
  };
}
