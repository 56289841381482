import { axiosDelete, get, post } from '../../utils/axiosApi';
import { AuditQuery, IntegrationalSystemAuditQuery } from './auditsTypes';

export const getAuditsData = (q: AuditQuery) => post('/admin/audits', q);

export const getIntegrationalSystemAuditsData = (q: IntegrationalSystemAuditQuery) =>
  post('/admin/integrated-systems-audits', q);

export const getAuditOutletTypes = () => get('/admin/audit-outlet-types');

export const getAuditActionTypes = () => get('/admin/audit-action-types');

export const getVirsisReceivesFromSystem = () => get('admin/systems-virsis-receives-data-from');

export const getVirsisSendsToSystem = () => get('admin/systems-virsis-sends-data-to');

export const getArchivedData = () => get('/admin/archived-data');
export const deleteArchivedData = () => axiosDelete('/admin/archived-data');
