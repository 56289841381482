import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box, makeStyles, createStyles } from '@material-ui/core';

import { ApplicationState } from '../../store';
import { getStatus } from '../../store/document/documentTypes';
import PreviewAndSignDocumentDialog from './PreviewAndSignDocumentDialog';
import { VirsisUser } from '../../store/virsis/dataTypes';
import DocumentIcon from '../../components/Icons/Icons/DocumentIcon';
import virsisTheme from '../../style/virsisTheme';
import { fetchVirsDataRequest } from '../../store/virsis/actions';
import {
  fetchVirsPersonsListRequest,
  getShareholdersRequest
} from '../../store/shareholders/shareholdersActions';

const useStyles = makeStyles((theme) =>
createStyles({
  viewButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content',
      paddingLeft: '5px'
    }
  },
  documentStatusBanner: {
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down('xs')]: {
    display: 'block',
    blockSize: 'fit-content',
    paddingLeft: '5px'
  }
  }
})
);

interface Props {
  personId?: number;
  flexWrap: 'wrap' | 'nowrap';
}

export const PreviewOrSignDocument: React.FC<Props> = ({ flexWrap, personId }) => {
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const handlePreviewDialogClose = () => setOpenPreviewDialog(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  
  const {
    shareholdersData: { showUnsigned },
    virsis: { virsData, currentUser, virsDataLoading },
    documentData: { documentValidation, loadingDocumentValidation }
  } = useSelector((state: ApplicationState) => state);

  const handleDocumentSignSuccess = (user: VirsisUser) => {
    dispatch(fetchVirsDataRequest());
    if (virsData && virsData.virsId !== 0) {
      dispatch(getShareholdersRequest(virsData.virsId, !!showUnsigned));
      dispatch(fetchVirsPersonsListRequest(virsData.virsId, null));
    }
  };

  if (!virsData || virsData.documentStatus === null) {
    return null;
  }

  const getIconColor = () => {
    if (!virsData.documentStatus) {
      return virsisTheme.palette.info.main;
    }
    if (virsData.documentStatus === 'RUOSIAMAS') {
      return virsisTheme.palette.warning.main;
    }
    if (virsData.documentStatus === 'PASIRASYTAS') {
      return virsisTheme.palette.success.main;
    }
    return '';
  };

  return (
    <>
      {currentUser && (
        <Box
          display="flex"
          flexWrap={flexWrap}
          alignItems="center"
          justifyContent="flex-end"
          paddingTop={2}
          paddingBottom={2}
        >
          <Box className={classes.documentStatusBanner}>
            <Box mr={1}>
              <DocumentIcon iconColor={getIconColor()} />
            </Box>
            <Typography variant="caption">El. dokumento būsena:</Typography>
            <Box ml={1} mr={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {getStatus(virsData.documentStatus)}
              </Typography>
            </Box>
          </Box>
          <Button
            disabled={loadingDocumentValidation || virsDataLoading}
            variant="outlined"
            color="primary"
            onClick={() => setOpenPreviewDialog(true)}
            className={classes.viewButton}
          >
            <Typography variant="h5" color="primary">
              {virsData.documentStatus === 'RUOSIAMAS' && 'Peržiūrėti ir pasirašyti'}
              {virsData.documentStatus !== 'RUOSIAMAS' && 'Peržiūrėti'}
            </Typography>
          </Button>

          <PreviewAndSignDocumentDialog
            openDialog={openPreviewDialog}
            documentPath={`virs/legals/documents/${virsData.documentStatusId}`}
            documentId={virsData.documentStatusId}
            documentStatus={virsData.documentStatus}
            onClose={handlePreviewDialogClose}
            documentErrors={documentValidation && documentValidation?.documentErrors}
            onSignSuccess={() => handleDocumentSignSuccess(currentUser)}
          />
        </Box>
      )}
    </>
  );
};
