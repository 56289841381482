import React from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PageActionButtons from '../../../../components/PageButtons/PageActionButtons';
import { ApplicationState } from '../../../../store';
import { Roles } from '../../../../store/virsis/dataTypes';
import { hasRoles } from '../../../../utils/roleHelper';
import { FileFormats } from '../../../../utils/exporters/types';

interface Props {
  onDownload?: (format: FileFormats, navigationPath: string[]) => void;
  onPrint?: (navigationPath: string[]) => void;
  onClickDelete?: () => void;
  onVizualizeClick: () => void;
}

const useStyles = makeStyles({
  margin: {
    margin: '15px 0 15px 25px'
  }
});

export const HeaderActions: React.FC<Props> = ({
  onDownload,
  onPrint,
  onVizualizeClick,
  onClickDelete
}) => {
  const { margin } = useStyles();
  const {
    virsis: { currentUser }
  } = useSelector((state: ApplicationState) => state);

  const userCanEdit = currentUser && hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]);

  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <PageActionButtons
        onDownload={onDownload}
        onPrint={onPrint}
        dontShowDelete={!userCanEdit}
        onClickDelete={onClickDelete}
      />
      <Button className={margin} variant="contained" color="primary" onClick={onVizualizeClick}>
        <Typography variant="h5">Vizualizuoti</Typography>
      </Button>
    </Box>
  );
};
