import { Reducer } from 'redux';
import moment from 'moment';
import {
  SET_TIMELINE_ACTIVE_DATE,
  SET_TIMELINE_ERROR,
  SET_TIMELINE_EVENTS,
  SET_TIMELINE_LOADING,
  VIRS_TREE_ERROR,
  VIRS_TREE_REQUEST,
  VIRS_TREE_REQUEST_OF_DATE,
  VIRS_TREE_SUCCESS
} from './virsTreeActionTypes';
import { ApiVirsTree, TimelineState } from './virsTreeDataTypes';
import { ActivityPeriod } from '../shareholders/shareholdersTypes';

export interface VirsTreeState {
  loadingVirsTreeData: boolean;
  virsTreeData?: ApiVirsTree;
  virsTreeError?: Error;
  timeline: TimelineState;
  activityPeriods: ActivityPeriod[];
}

export const initialState: VirsTreeState = {
  loadingVirsTreeData: false,
  virsTreeData: undefined,
  virsTreeError: undefined,
  activityPeriods: [],
  timeline: {
    activeDate: moment().startOf('day'),
    isLoading: false,
    periodName: 'month',
    timelineEvents: [],
    error: undefined
  }
};

export const virsTreeReducer: Reducer<VirsTreeState> = (state = initialState, action) => {
  switch (action.type) {
    case VIRS_TREE_REQUEST:
    case VIRS_TREE_REQUEST_OF_DATE: {
      return {
        ...state,
        loadingVirsTreeData: true
      };
    }
    case VIRS_TREE_SUCCESS: {
      return {
        ...state,
        loadingVirsTreeData: false,
        virsTreeData: {
          ...action.payload.virs,
          uniqueId: action.payload.virs.virsId + Math.random()
        },
        virsTreeError: undefined
      };
    }
    case VIRS_TREE_ERROR: {
      return {
        ...state,
        loadingVirsTreeData: false,
        virsTreeData: undefined,
        virsTreeError: action.payload
      };
    }

    case SET_TIMELINE_ACTIVE_DATE:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          activeDate: action.payload
        }
      };

    case SET_TIMELINE_LOADING:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          isLoading: action.payload
        }
      };

    case SET_TIMELINE_EVENTS:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          timelineEvents: action.payload.timelineEvents,
          isLoading: false,
          error: undefined
        },
        activityPeriods: action.payload.activityPeriods
      };

    case SET_TIMELINE_ERROR:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          error: action.payload,
          isLoading: false,
          timelineEvents: []
        }
      };

    default: {
      return state;
    }
  }
};
