import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, makeStyles } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import { Form, Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import {
  createNaturalForeignPerson,
  resetCreateNaturalForeignPersonState,
  selectPersonSearchQuery
} from '../../../store/persons/personsActions';
import {
  NaturalForeignPersonRecord,
  NaturalPersonSearchQuery
} from '../../../store/persons/personsTypes';
import { ApplicationState } from '../../../store';
import { FormikFormTextField } from '../../FormikFields/FormikFormTextField';
import { FormikFormDateField } from '../../FormikFields/FormikFormDateField';
import { FormikFormSelectField } from '../../FormikFields/FormikFormSelectField';
import { todaysDateString } from '../../../utils/tableDataFunctions';
import { useDialogPersonCreationFormStyles } from '../styles';
import { filterOutLithuaniaFromJarCountries } from '../Utilities';
import { DOCUMENT_OPTIONS, PersonDocumentType, RowState, Values } from './types';
import { NAME_CHECK_REGEX_LT } from '../../../utils/tableTypes';
import { BasicTooltip } from '../../Tooltips/BasicTooltip';
import { Strings } from '../../../utils/strings/Strings';

interface Props {
  close: () => void;
  searchPerson: (personQuery: NaturalPersonSearchQuery, searchedCountry?: JarCountry) => void;
}

const initialRowState: RowState = {
  naturalForeignPerson: undefined,
  showConfirmation: false,
  confirmationAnchor: null
};

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    personCodeTooltip: {
      flex: 1
    },
    boxContainer: {
      width: '100%'
    }
  }
}));

const isDocInfoRequired = (values: Partial<Values>) =>
  !!values.documentType ||
  !!values.documentNr ||
  !!values.documentIssueDate ||
  !!values.documentExpirationDate;

export const FindPersonAddNaturalForeignForm: React.FC<Props> = ({ close, searchPerson }) => {
  const classes = useDialogPersonCreationFormStyles();
  const styledClasses = useStyles();

  const { jarCountries } = useSelector((state: ApplicationState) => state.virsis);

  const [rowState, setRowState] = useState<RowState>(initialRowState);

  const todayDate = new Date().toISOString().slice(0, 10);

  const tomorrowDate = new Date(moment().add(1, 'days').format('YYYY-MM-DD'));

  const {
    creatingNaturalForeignPerson,
    naturalForeignPersonCreated,
    selectedNaturalPersonSearchQuery
  } = useSelector((state: ApplicationState) => state.personData);

  const reduxDispatch = useDispatch();

  const initValues: Partial<Values> = {
    firstName: selectedNaturalPersonSearchQuery?.naturalFirstname || '',
    lastName: selectedNaturalPersonSearchQuery?.naturalLastname || '',
    personCode: selectedNaturalPersonSearchQuery?.naturalPersonCode || '',
    birthDate: selectedNaturalPersonSearchQuery?.personBirthdate
      ? new Date(selectedNaturalPersonSearchQuery?.personBirthdate)
      : null,
    documentNr: '',
    documentType: null,
    documentIssueDate: null,
    documentCountry:
      jarCountries?.find(
        (country) => country.countryCode === selectedNaturalPersonSearchQuery?.countryCode
      ) || null,
    documentExpirationDate: null,
    documentInfoRequired: false
  };

  const dateFormat = 'Data turi būti teisingo formato';
  const dateEarlierThanToday = 'Dokumento išdavimo data negali būti vėlesnė negu šiandien';
  const docExpirationDateRequired = 'Dokumento galiojimo data privaloma';
  const docIssueDateRequired = 'Dokumento išdavimo data privaloma';
  const docDateCantMatch =
    'Dokumento galiojimo data negali būti ankstesnė arba tokia pati kaip dokumento išdavimo data';

  const getDocumentInfoFieldSchema = <T,>(
    baseSchema: T,
    required?: (baseSchema: T) => T,
    notRequired?: (baseSchema: T) => T
  ): Yup.MixedSchema => {
    return Yup.mixed().when('documentInfoRequired', {
      is: (documentInfoRequired) => documentInfoRequired,
      then: required?.(baseSchema) || baseSchema,
      otherwise: notRequired?.(baseSchema) || baseSchema
    });
  };

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .trim()
        .matches(NAME_CHECK_REGEX_LT, 'Įvesti netinkami simboliai')
        .required('Vardas privalomas'),
      lastName: Yup.string()
        .max(40, 'Maksimalus simboliu skaičius 40')
        .trim()
        .matches(NAME_CHECK_REGEX_LT, 'Įvesti netinkami simboliai')
        .required('Pavardė privaloma'),
      personCode: Yup.string().nullable().max(40, 'Maksimalus simboliu skaičius 40'),
      birthDate: Yup.date()
        .required('Gimimo data privaloma')
        .max(tomorrowDate, 'Gimimo data negali būti vėlesnė nei šiandienos')
        .typeError('Data turi būti teisingo formato')
        .nullable(),
      documentCountry: Yup.string().required('Dokumentą išdavusi valstybė').nullable(),
      documentType: getDocumentInfoFieldSchema(Yup.string().nullable(), (a) =>
        a.required('Dokumento tipas privalomas')
      ),
      documentNr: getDocumentInfoFieldSchema(
        Yup.string().nullable().max(40, 'Maksimalus simboliu skaičius 40'),
        (a) => a.required('Nr privalomas')
      ),
      documentIssueDate: getDocumentInfoFieldSchema(
        Yup.date()
          .max(tomorrowDate, dateEarlierThanToday)
          .test(
            'DocumentIssueDateLaterThanOrEqualToBirthDate',
            'Dokumento išdavimo data negali būti ankstesnė nei gimimo data',
            function () {
              const { birthDate, documentIssueDate }: Values = this.parent;
              if (birthDate && documentIssueDate) {
                return moment(documentIssueDate)
                  .startOf('day')
                  .isSameOrAfter(moment(birthDate).startOf('day').valueOf());
              }
              return true;
            }
          )
          .typeError(dateFormat)
          .nullable(),
        (a) => a.required(docIssueDateRequired)
      ),
      documentExpirationDate: getDocumentInfoFieldSchema(
        Yup.mixed().when('documentIssueDate', {
          is: (issueDate) => !!issueDate,
          then: Yup.mixed().when(['documentExpirationDate', 'documentIssueDate'], {
            is: (documentExpirationDate: Date, documentIssueDate: Date) =>
              moment(documentExpirationDate).format('YYYY-MM-DD') ===
              moment(documentIssueDate).format('YYYY-MM-DD'),
            then: Yup.date().test('documentExpirationDate', docDateCantMatch, () => false),
            otherwise: Yup.date()
              .typeError(dateFormat)
              .min(Yup.ref('documentIssueDate'), docDateCantMatch)
              .nullable()
          }),
          otherwise: Yup.date()
            .typeError(dateFormat)
            .min(Yup.ref('documentIssueDate'), docDateCantMatch)
            .nullable()
        }),
        (a) => a.required(docExpirationDateRequired)
      )
    },
    [
      ['birthDate', 'personCode'],
      ['documentExpirationDate', 'documentIssueDate']
    ]
  );

  const dayAfterDate = (d: Date) => {
    const date = new Date(d);
    date.setDate(date.getDate() + 1);
    return date.toDateString().slice(0, 10);
  };

  const setSelectedPersonSearchQuery = (person: NaturalForeignPersonRecord) => {
    const query = {
      countryCode: person.documentCountry,
      naturalFirstname: person.naturalFirstname,
      naturalLastname: person.naturalLastname,
      naturalPersonCode: person.personCode,
      personBirthdate: person.personBirthdate
    };
    reduxDispatch(selectPersonSearchQuery(query));
  };

  useEffect(() => {
    if (naturalForeignPersonCreated) {
      setRowState({ ...rowState, showConfirmation: false });
      reduxDispatch(resetCreateNaturalForeignPersonState());
      close();
      if (selectedNaturalPersonSearchQuery) {
        searchPerson(
          selectedNaturalPersonSearchQuery,
          jarCountries?.find(
            (country) => country.countryCode === selectedNaturalPersonSearchQuery.countryCode
          )
        );
      }
    }
  }, [
    reduxDispatch,
    naturalForeignPersonCreated,
    rowState,
    close,
    selectedNaturalPersonSearchQuery,
    searchPerson,
    jarCountries
  ]);

  return (
    <>
      {jarCountries && (
        <Box style={{ paddingBottom: '50px' }} className={styledClasses.boxContainer}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initValues}
            onSubmit={(values, { setSubmitting }) => {
              const naturalForeignPerson: NaturalForeignPersonRecord = {
                documentCountry: values.documentCountry ? values.documentCountry.countryCode : null,
                naturalFirstname: values.firstName ? values.firstName : null,
                naturalLastname: values.lastName ? values.lastName : null,
                personBirthdate: values.birthDate
                  ? moment(values.birthDate).format('YYYY-MM-DD')
                  : null,
                personCode: values.personCode ? values.personCode : null,
                documentType: values?.documentType?.value ? values.documentType.value : null,
                documentNr: values.documentNr ? values.documentNr : null,
                documentIssueDate: values.documentIssueDate
                  ? moment(values.documentIssueDate).format('YYYY-MM-DD')
                  : null,
                documentExpirationDate: values.documentExpirationDate
                  ? moment(values.documentExpirationDate).format('YYYY-MM-DD')
                  : null
              };
              setRowState({
                ...rowState,
                showConfirmation: true
              });
              reduxDispatch(createNaturalForeignPerson(naturalForeignPerson));
              setSelectedPersonSearchQuery(naturalForeignPerson);
              setSubmitting(false);
            }}
          >
            {({ setValues, errors, values, isValidating, submitForm }) => {
              const docInfoRequired = isDocInfoRequired(values);

              const setFieldValueSkipValidation = (id: string, value: any) => {
                setValues(
                  {
                    ...values,
                    [id]: value,
                    documentInfoRequired: isDocInfoRequired({ ...values, [id]: value })
                  },
                  false
                );
              };

              const getDocFieldLabelText = (text: string) => (docInfoRequired ? `${text}*` : text);

              return (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Form>
                    <Typography
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '20px',
                        fontWeight: 'bolder'
                      }}
                    >
                      Pridėti užsienio fizinį asmenį
                    </Typography>
                    <BasicTooltip
                      text="Asmens kodas privalomas. Jeigu pagal užsienio valstybės teisės aktus nesuteikiamas asmens kodas, nurodoma tik gimimo data"
                      className={styledClasses.personCodeTooltip}
                    >
                      <FormikFormTextField
                        styledClasses={classes}
                        maxLength={30}
                        label="Asmens kodas"
                        formikKey="personCode"
                        values={values.personCode}
                        error={errors.personCode}
                        setFieldValue={setFieldValueSkipValidation}
                      />
                    </BasicTooltip>
                    <FormikFormDateField
                      maxDate={todaysDateString()}
                      styledClasses={classes}
                      values={values.birthDate}
                      label="Gimimo data*"
                      formikKey="birthDate"
                      date={values.birthDate}
                      errors={errors.birthDate}
                      setFieldValue={setFieldValueSkipValidation}
                      isRequired
                    />
                    <FormikFormTextField
                      styledClasses={classes}
                      label="Vardas*"
                      formikKey="firstName"
                      values={values.firstName}
                      error={errors.firstName}
                      setFieldValue={setFieldValueSkipValidation}
                    />
                    <FormikFormTextField
                      styledClasses={classes}
                      label="Pavardė*"
                      formikKey="lastName"
                      values={values.lastName}
                      error={errors.lastName}
                      setFieldValue={setFieldValueSkipValidation}
                    />
                    <FormikFormTextField
                      styledClasses={classes}
                      label={getDocFieldLabelText('Dokumento Nr')}
                      formikKey="documentNr"
                      values={values.documentNr}
                      error={errors.documentNr}
                      setFieldValue={setFieldValueSkipValidation}
                      maxLength={30}
                    />
                    <FormikFormSelectField
                      styledClasses={classes}
                      getOptionLabel={(option?: PersonDocumentType) => (option ? option.title : '')}
                      isClearable
                      label={getDocFieldLabelText('Dokumento tipas')}
                      formikKey="documentType"
                      value={values.documentType}
                      options={DOCUMENT_OPTIONS}
                      errors={errors.documentType}
                      setFieldValue={setFieldValueSkipValidation}
                      isRequired={docInfoRequired}
                    />
                    <FormikFormDateField
                      maxDate={todayDate}
                      styledClasses={classes}
                      values={values.documentIssueDate}
                      label={getDocFieldLabelText('Dokumento išdavimo data')}
                      formikKey="documentIssueDate"
                      date={values.documentIssueDate}
                      errors={errors.documentIssueDate}
                      setFieldValue={setFieldValueSkipValidation}
                      isRequired={docInfoRequired}
                    />
                    <FormikFormDateField
                      minDate={
                        values.documentIssueDate
                          ? dayAfterDate(values.documentIssueDate)
                          : undefined
                      }
                      styledClasses={classes}
                      values={values.documentExpirationDate}
                      label={getDocFieldLabelText('Dokumento galiojimo data')}
                      formikKey="documentExpirationDate"
                      date={values.documentExpirationDate}
                      errors={errors.documentExpirationDate}
                      setFieldValue={setFieldValueSkipValidation}
                      isRequired={docInfoRequired}
                    />
                    <FormikFormSelectField
                      styledClasses={classes}
                      getOptionLabel={(option?: JarCountry) => (option ? option.countryName : '')}
                      isClearable={
                        values.documentCountry
                          ? values.documentCountry.countryCode !== 'LTU'
                          : false
                      }
                      isRequired
                      label="Dokumentą išdavusi valstybė*"
                      formikKey="documentCountry"
                      value={values.documentCountry}
                      options={filterOutLithuaniaFromJarCountries(jarCountries)}
                      errors={errors.documentCountry}
                      setFieldValue={setFieldValueSkipValidation}
                      defaultValue={null}
                    />
                    <Box>
                      <Button
                        style={{
                          marginRight: '45px'
                        }}
                        variant="outlined"
                        disabled={isValidating || creatingNaturalForeignPerson}
                        onClick={submitForm}
                        className={classes.button}
                        startIcon={
                          creatingNaturalForeignPerson ? (
                            <CircularProgress style={{ height: '20px', width: '20px' }} />
                          ) : (
                            <CheckIcon style={{ color: '#03B079' }} />
                          )
                        }
                      >
                        <Typography color="inherit">Išsaugoti</Typography>
                      </Button>
                      <Button
                        style={{
                          marginRight: '4px'
                        }}
                        variant="outlined"
                        disabled={isValidating}
                        onClick={() => close()}
                        className={classes.button}
                        startIcon={<ClearIcon style={{ color: '#f44336' }} />}
                      >
                        <Typography color="inherit">{Strings.button__cancel}</Typography>
                      </Button>
                    </Box>
                  </Form>
                </MuiPickersUtilsProvider>
              );
            }}
          </Formik>
        </Box>
      )}
    </>
  );
};
