import React from 'react';
import {
  Typography,
  Grid,
  Box,
  Dialog,
  Divider,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { SearchLibisDialogResultsTable } from './SearchLibisDialogResultsTable';
import { ApplicationState } from '../../../../store';
import { SearchLibisDialogForm } from './SearchLibisDialogForm';
import { LibisSearchData } from '../../../../store/outlets/outletsTypes';
import {
  resetFindOutletOnLibisState,
  findOutletOnLibis
} from '../../../../store/outlets/outletsActions';
import { CustomCloseIconButton } from '../../../../components/Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../../../../components/Dialogs/Dialog/CloseButton';
import { CloseContinueButton } from '../../../../components/Dialogs/Dialog/CloseContinueButton';

interface Props {
  dialogOpen: boolean;
  selectedOutlet?: LibisSearchData;
  closeDialog: () => void;
  toggleLibisOutletSelection: (outlet: LibisSearchData) => void;
  populateNewRecordWithLibisData: () => void;
}

export const SearchLibisDialog: React.FC<Props> = ({
  dialogOpen,
  selectedOutlet,
  closeDialog,
  toggleLibisOutletSelection,
  populateNewRecordWithLibisData
}) => {
  const { searchingOutletOnLibis, outletOnLibisResults, outletOnLibisError } = useSelector(
    (state: ApplicationState) => state.outletData
  );

  const reduxDispatch = useDispatch();

  function findOnLibis(title?: string, internationalNumber?: string) {
    reduxDispatch(resetFindOutletOnLibisState());
    reduxDispatch(
      findOutletOnLibis(
        title === '' ? undefined : title,
        internationalNumber === '' ? undefined : internationalNumber
      )
    );
  }

  function closeLibisDialogAndGoBack() {
    reduxDispatch(resetFindOutletOnLibisState());
    closeDialog();
  }

  function closeLibisDialogAndContinue() {
    populateNewRecordWithLibisData();
    closeDialog();
  }

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} onExit={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="subtitle1">Leidinio paieška LIBIS</Typography>
        <CustomCloseIconButton onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <div className="dialog-main-content">
          <Divider />
          <SearchLibisDialogForm searchLibis={findOnLibis} isSearching={searchingOutletOnLibis} />
          <Divider />
          <Grid
            container
            item
            direction="column"
            justify="flex-end"
            alignItems="flex-start"
            style={{ padding: '50px 0' }}
          >
            {searchingOutletOnLibis && <CircularProgress />}
            {outletOnLibisError && <Typography>{outletOnLibisError.message}</Typography>}
            {outletOnLibisResults && outletOnLibisResults.length === 0 && (
              <Typography variant="h5">
                VIP nerasta pagal paieškos duomenis. Patikslinkite įvestus duomenis arba
                užregistruokite leidinį LIBIS ir pabaikite pildyti duomenis VIRSIS
              </Typography>
            )}
            {outletOnLibisResults && outletOnLibisResults.length > 0 && (
              <SearchLibisDialogResultsTable
                selectedOutlet={selectedOutlet}
                selectLibisOutlet={toggleLibisOutletSelection}
                outletOnLibisResults={outletOnLibisResults}
              />
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" m={1}>
          <CloseDialogButton label="Atgal" onClick={closeLibisDialogAndGoBack} />
        </Box>
        <Box right={30} component="span" m={1}>
          <CloseContinueButton
            onClick={closeLibisDialogAndContinue}
            disabled={!selectedOutlet}
            withIcon
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
