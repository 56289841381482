import React, { useReducer, useState } from 'react';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import {
  Typography,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead
} from '@material-ui/core/';

import ToggleButton from '@material-ui/lab/ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
  initialPersonResultsTableState,
  PersonSearchDataField,
  PersonSearchResultsTableColumnHeader,
  PersonSearchResultsTableReducer
} from './state/Reducer';
import { NaturalPersonInVirsis } from './Utilities';
import { useSearchStyles } from '../../style/searchStyles';
import { ApplicationState } from '../../store';
import {
  getComparator,
  getDataSlicedToPageGeneric,
  getPagesCountGeneric,
  stableSort
} from '../../utils/tableDataFunctions';
import { TablePagination } from '../TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { selectNaturalPersonInVirsis } from '../../store/findnaturalpersoninvirsis/actions';

interface Props {
  personSearchResults: NaturalPersonInVirsis[];
}

const columns: PersonSearchResultsTableColumnHeader[] = [
  {
    id: 'personCode',
    label: 'Asmens kodas'
  },
  {
    id: 'personBirthDate',
    label: 'Gimimo data'
  },
  {
    id: 'personFirstName',
    label: 'Vardas Pavardė'
  }
];

export const SearchResults: React.FC<Props> = ({ personSearchResults }) => {
  const classes = useSearchStyles();
  const [resultsPagination, setResultsPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({
    page: 0,
    rowsPerPage: 20
  });

  const [tableState, tableDispatch] = useReducer(
    PersonSearchResultsTableReducer,
    initialPersonResultsTableState
  );

  const { selectedVirsByNaturalPerson } = useSelector(
    (state: ApplicationState) => state.findNaturalPersonInVirsis
  );

  const reduxDispatch = useDispatch();

  function selectNewRepresentative(newRep: NaturalPersonInVirsis) {
    return () => reduxDispatch(selectNaturalPersonInVirsis(newRep));
  }

  function setPage(page: number): void {
    setResultsPagination({ ...resultsPagination, page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    setResultsPagination({ rowsPerPage, page: 0 });
  }

  const pagesCount = getPagesCountGeneric(
    personSearchResults.length,
    resultsPagination.rowsPerPage
  );

  const sortedData =
    (stableSort(
      personSearchResults,
      getComparator(tableState.order, tableState.sortBy)
    ) as unknown as NaturalPersonInVirsis[]) || [];

  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    resultsPagination.page,
    resultsPagination.rowsPerPage
  ) as unknown as NaturalPersonInVirsis[];

  const createSortHandler = (column: PersonSearchDataField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  return (
    <>
      <Typography
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          fontWeight: 'bolder'
        }}
      >
        Paieškos rezultatai
      </Typography>
      <Paper style={{ width: '100%' }}>
        <TableContainer className={classes.tableContainer} style={{ overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow style={{ border: 'none' }}>
                {columns.map((column, index) => (
                  <TableCell
                    style={{
                      minWidth: '80px',
                      borderLeft: 'none',
                      borderTopLeftRadius: index === 0 ? '10px' : 0
                    }}
                    key={column.id}
                    variant="head"
                    align="left"
                    className="TableCellSortable"
                    onClick={() => {
                      createSortHandler(column.id);
                    }}
                  >
                    <TableSortLabel
                      active
                      direction={tableState.sortBy === column.id ? tableState.order : 'asc'}
                      IconComponent={tableState.sortBy === column.id ? ActiveSortIcon : SortIcon}
                    >
                      <Typography variant="h4">{column.label}</Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  variant="head"
                  style={{
                    width: '100px',
                    borderRight: 'none',
                    borderTopRightRadius: '10px'
                  }}
                  align="center"
                >
                  <Typography>Veiksmai</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedData.map((person) => (
                <TableRow key={person.personId}>
                  <TableCell align="left">{person.personCode}</TableCell>
                  <TableCell align="left">{person.personBirthDate}</TableCell>
                  <TableCell align="left">{`${person.personFirstName} ${person.personLastName}`}</TableCell>
                  <TableCell align="center">
                    <ToggleButton
                      classes={{
                        selected: classes.selected,
                        root: classes.root
                      }}
                      selected={
                        selectedVirsByNaturalPerson
                          ? person.personId === selectedVirsByNaturalPerson.personId
                          : false
                      }
                      value="select-person"
                      onClick={selectNewRepresentative(person)}
                    >
                      {selectedVirsByNaturalPerson &&
                      person.personId === selectedVirsByNaturalPerson.personId
                        ? 'Pasirinktas'
                        : 'Pasirinkti'}
                    </ToggleButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            recordsCount={personSearchResults.length}
            pagesCount={pagesCount}
            rowsPerPage={resultsPagination.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={resultsPagination.page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            disabled={!personSearchResults.length}
          />
        </TableContainer>
      </Paper>
    </>
  );
};
