import React from 'react';
import { IconButton } from '@material-ui/core';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';

interface Props {
  id?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const CalendarButton: React.FC<Props> = ({ id, disabled, onClick }) => (
  <IconButton
    id={`${id}-open-calendar`}
    onClick={onClick}
    style={{ padding: '0.5rem' }}
    disabled={disabled}
  >
    <DateRangeRoundedIcon fontSize="small" color={disabled ? 'secondary' : 'primary'} />
  </IconButton>
);
