import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { ColumnHeader } from '../../../utils/tableTypes';
import { AdColumnHeaderField } from '../tablesState/adPrintingTableTypes';
import { OutletAdPrinting } from '../../../store/outletInfoData/outletInfoDataTypes';
import { ApplicationState } from '../../../store';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { TableCustomFilterCellGeneric } from '../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columns: ColumnHeader<AdColumnHeaderField>[];
}

const FilterRow: React.FC<Props> = ({ columns }) => {
  const {
    state: { adprinting: adPrintingTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tablesDispatch } = useOutletInfoTablesDispatch();

  const allData =
    useSelector((state: ApplicationState) => state.outletInfoData.outletInfo?.advertisements) || [];

  const handleSetCustomFilter = (column: AdColumnHeaderField, value: string | null) => {
    tablesDispatch({
      type: 'AD_PRINTING_CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };

  function getUniqueOptions(name: AdColumnHeaderField, data: OutletAdPrinting[]) {
    if (data) {
      return Array.from(new Set(data.map((item) => String(item[name]))));
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columns
          .filter((column) => adPrintingTableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FilterRow;
