import { Roles, VirsisUser } from '../store/virsis/dataTypes';
import { Subsystem } from '../containers/VirsDataSubmision/VirsDataSubmision/VirsDataPage';

export const hasRole = (virsisUser: VirsisUser, role: Roles) => {
  return virsisUser.authorities.includes(Roles[role]);
};

export const hasRoles = (virsisUser: VirsisUser, roles: Roles[]) => {
  return roles.find((role: Roles) => virsisUser.authorities.includes(Roles[role])) !== undefined;
};

export const getSubsystem = (currentUser: VirsisUser | undefined): Subsystem => {
  let currentSubsystem: Subsystem;
  if (currentUser) {
    if (hasRole(currentUser, Roles.ROLE_VIRS_VIEW)) {
      currentSubsystem = 'VIRS_VIEW';
    }
    if (hasRoles(currentUser, [Roles.ROLE_VIRS, Roles.ROLE_VIRS_EDIT])) {
      currentSubsystem = 'VIRSIS';
    }
    if (hasRole(currentUser, Roles.ROLE_ZEIT_EDIT)) {
      currentSubsystem = 'ZEIT';
    }
    if (hasRole(currentUser, Roles.ROLE_LRTK_EDIT)) {
      currentSubsystem = 'LRTK';
    }
    if (hasRole(currentUser, Roles.ROLE_VIEA_EDIT)) {
      currentSubsystem = 'VIEA';
    }
    if (
      hasRoles(currentUser, [Roles.ROLE_KM_METINE_IMOKA_EDIT, Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT])
    ) {
      currentSubsystem = 'KM';
    }
    if (hasRoles(currentUser, [Roles.ROLE_ADMIN_AUD, Roles.ROLE_ADMIN_GRF, Roles.ROLE_ADMIN_ALL])) {
      currentSubsystem = 'RC';
    }

    return currentSubsystem;
  }

  return undefined;
};
