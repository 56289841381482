import React from 'react';
import { useSelector } from 'react-redux';
import { VirsisUser } from '../../store/virsis/dataTypes';
import { ApplicationState } from '../../store';

interface Props {
  currentUser: VirsisUser;
}

export const withLoadedUser = (Component: React.ComponentType<Props>) => {
  const ComposedComponent: React.FC = (): JSX.Element | null => {
    const { currentUser } = useSelector((state: ApplicationState) => state.virsis);
    if (currentUser) {
      return <Component currentUser={currentUser} />;
    }
    return null;
  };

  return ComposedComponent;
};
