import { Moment } from 'moment';
import { createAction } from 'typesafe-actions';
import { TimelinePeriod } from './types';

export const changeVisiblePeriod = createAction('CHANGE_VISIBLE_PERIOD', (amount: number) => ({
  amount
}))();

export const setVisiblePeriod = createAction(
  'SET_VISIBLE_PERIOD',
  (visiblePeriod?: TimelinePeriod) => ({ visiblePeriod })
)();

export const changeActiveDate = createAction('CHANGE_ACTIVE_DATE', (activeDate: Moment) => ({
  activeDate
}))();

export const setActiveDate = createAction('SET_ACTIVE_DATE', (activeDate: Moment) => ({
  activeDate
}))();

export const changeContainerWidth = createAction(
  'CHANGE_CONTAINER_WIDTH',
  (containerWidth?: number) => ({ containerWidth })
)();

export const setContainerWidth = createAction('SET_CONTAINER_WIDTH', (containerWidth?: number) => ({
  containerWidth
}))();

export const setGroupingDistance = createAction('SET_GROUPING_DISTANCE', (distance: number) => ({
  distance
}))();

export const setCursorDate = createAction('SET_CURSOR_DATE', (cursorDate?: Moment) => ({
  cursorDate
}))();

export const setPeriods = createAction('SET_PERIODS', (periods: TimelinePeriod[]) => ({
  periods
}))();
