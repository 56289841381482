import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import VirsDataSearch from './VirsDataSearch';
import { ApplicationState } from '../../../store';
import { resetVirsDataRequest } from '../../../store/virsis/actions';
import NamedBreadcrumbs from '../../../components/VirsNamedBreadcrumbs/VirsNamedBreadcrumbs';
import { getSubsystem } from '../../../utils/roleHelper';
import { fetchEnterpriseTypesRequest } from '../../../store/classifiers/classifiersActions';

const VirsDataSearchPage: React.FC = () => {
  const reduxDispatch = useDispatch();
  const {
    virsis: { currentUser }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    reduxDispatch(resetVirsDataRequest());
    reduxDispatch(fetchEnterpriseTypesRequest());
  }, [reduxDispatch]);

  return (
    <div className="data-page">
      <Container maxWidth="lg">
        <NamedBreadcrumbs subsystem={getSubsystem(currentUser)} />
        <Typography variant="subtitle1" style={{ margin: '20px 0' }}>
          {getSubsystem(currentUser) === 'KM' ? 'VIRS duomenų teikimas' : 'VIRS duomenų peržiūra'}
        </Typography>
        <VirsDataSearch />
      </Container>
    </div>
  );
};

export default VirsDataSearchPage;
