import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AnnulmentType } from '../../../../store/classifiers/classifiersTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import {
  ProfessionalMisconductType,
  ProfessionalMisconductSanctionType,
  ProfessionalMisconductDataInst
} from '../../../../store/professionalMisconducts/professionalMisconductsTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import {
  DatePickerState,
  DropdownStateGeneric,
  InputStateGeneric,
  VirsInputState
} from '../../../../utils/tableTypes';

export interface ProfessionalMisconductOutletState {
  outlet: DropdownStateGeneric<OutletShortData>;
  misconducts: DeterminedMisconductState[];
}

export interface DeterminedMisconductState {
  misconduct: DropdownStateGeneric<ProfessionalMisconductType>;
  sanctions: DeterminedMisconductSanctionState[];
}

export interface DeterminedMisconductSanctionState {
  sanction: DropdownStateGeneric<ProfessionalMisconductSanctionType>;
}

const initialProfessionalMisconductOutletState: ProfessionalMisconductOutletState[] = [
  {
    outlet: {
      value: null,
      error: false,
      helperText: '',
      placeholder: '',
      validated: false,
      options: []
    },
    misconducts: [
      {
        misconduct: {
          value: null,
          error: false,
          helperText: '',
          placeholder: '',
          validated: false,
          options: []
        },
        sanctions: [
          {
            sanction: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: []
            }
          }
        ]
      }
    ]
  }
];

export const initialMisconductRecordState: ProfessionalMisconductRecordState = {
  virs: {
    value: null,
    error: false,
    validated: false,
    helperText: 'Privaloma pasirinkti VIRS',
    placeholder: 'VIRS pavadinimas'
  },
  decisionDate: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  documentNumber: {
    value: null,
    id: 'customName',
    error: false,
    helperText: 'Dokumento numeris',
    placeholder: '',
    validated: false
  },
  outlets: initialProfessionalMisconductOutletState,
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  validTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: []
  },
  misconductTypes: [],
  sanctionTypes: [],
  showFindVirsDialog: false,
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  },
  loading: false,
  fictitiousOutlet: {} as OutletShortData
};

export interface ProfessionalMisconductRecordState {
  virs: VirsInputState;
  decisionDate: DatePickerState;
  documentNumber: InputStateGeneric<string>;
  outlets: ProfessionalMisconductOutletState[];
  validFrom: DatePickerState;
  validTo: DatePickerState;
  misconductTypes: ProfessionalMisconductType[];
  sanctionTypes: ProfessionalMisconductSanctionType[];
  showFindVirsDialog: boolean;
  annulRecordConfirmationOn: boolean;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
  loading: boolean;
  fictitiousOutlet: OutletShortData;
}

export const initialMisconductNewRowState: MisconductNewRowState = {
  ...initialMisconductRecordState,
  showFindVirsDialog: true,
  showRoleAssignmentDialog: false,
  createRecordConfirmationOn: false
};

export interface MisconductNewRowState extends ProfessionalMisconductRecordState {
  createRecordConfirmationOn: boolean;
  showRoleAssignmentDialog: boolean;
}

export const initialMisconductEditRowState: MisconductEditRowState = {
  editingOn: false,
  ...initialMisconductRecordState,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false
};

export interface MisconductEditRowState extends ProfessionalMisconductRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

type MisconductRecordRowAction =
  | { type: 'VIRS_INPUT_CLICKED' }
  | { type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED'; virs: VirsSearchData }
  | { type: 'FIND_VIRS_DIALOG_CLOSED' }
  | {
      type: 'DECISION_DATE_CHANGED';
      decisionDate: MaterialUiPickersDate | null;
    }
  | { type: 'DOCUMENT_NUMBER_CHANGED'; documentNumber: string }
  | {
      type: 'OUTLET_FIELD_ADDED';
    }
  | {
      type: 'OUTLET_CHANGED';
      outlet: OutletShortData | null;
      outletIndex: number;
    }
  | { type: 'OUTLET_FIELD_REMOVED'; outletIndex: number }
  | { type: 'MISCONDUCT_FIELD_ADDED'; outletIndex: number }
  | {
      type: 'MISCONDUCT_CHANGED';
      misconduct: ProfessionalMisconductType | null;
      outletIndex: number;
      misconductIndex: number;
    }
  | {
      type: 'MISCONDUCT_FIELD_REMOVED';
      outletIndex: number;
      misconductIndex: number;
    }
  | {
      type: 'SANCTION_FIELD_ADDED';
      outletIndex: number;
      misconductIndex: number;
    }
  | {
      type: 'SANCTION_CHANGED';
      sanction: ProfessionalMisconductSanctionType | null;
      outletIndex: number;
      misconductIndex: number;
      sanctionIndex: number;
    }
  | {
      type: 'SANCTION_FIELD_REMOVED';
      sanctionIndex: number;
      misconductIndex: number;
      outletIndex: number;
    }
  | { type: 'VALID_FROM_CHANGED'; validFrom: MaterialUiPickersDate | null }
  | { type: 'VALID_TO_CHANGED'; validTo: MaterialUiPickersDate | null };

type MisconductNewAction =
  | {
      type: 'NEW_RECORD_STATE_INITIALIZED';
      misconductTypes: ProfessionalMisconductType[];
      sanctionTypes: ProfessionalMisconductSanctionType[];
      fictitiousOutlet: OutletShortData[];
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };
type MisconductEditAction =
  | {
      type: 'EDITING_INITIALIZED';
      record: ProfessionalMisconductDataInst;
      misconductTypes: ProfessionalMisconductType[];
      sanctionTypes: ProfessionalMisconductSanctionType[];
      fictitiousOutlet: OutletShortData[];
    }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'ANNUL_RECORD_CLICKED'; annulmentTypes: AnnulmentType[] }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type MisconductNewRowAction = MisconductRecordRowAction | MisconductNewAction;
export type MisconductEditRowAction = MisconductEditAction | MisconductRecordRowAction;
