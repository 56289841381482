export const FETCH_LEGAL_FORM_TYPES_REQUEST = '@classifiers/FETCH_LEGAL_FORM_TYPES_REQUEST';
export const FETCH_LEGAL_FORM_TYPES_REQUEST_SUCCESS =
  '@classifiers/FETCH_LEGAL_FORM_TYPES_REQUEST_SUCCESS';
export const FETCH_LEGAL_FORM_TYPES_REQUEST_ERROR =
  '@classifiers/FETCH_LEGAL_FORM_TYPES_REQUEST_ERROR';

export const FETCH_SHARE_TYPES_REQUEST = '@classifiers/FETCH_SHARE_TYPES_REQUEST';
export const FETCH_SHARE_TYPES_REQUEST_SUCCESS = '@classifiers/FETCH_SHARE_TYPES_REQUEST_SUCCESS';
export const FETCH_SHARE_TYPES_REQUEST_ERROR = '@classifiers/FETCH_SHARE_TYPES_REQUEST_ERROR';

export const FETCH_ANNULMENT_TYPES_REQUEST = '@classifiers/FETCH_ANNULMENT_TYPES_REQUEST';
export const FETCH_ANNULMENT_TYPES_SUCCESS = '@classifiers/FETCH_ANNULMENT_TYPES_SUCCESS';
export const FETCH_ANNULMENT_TYPES_ERROR = '@classifiers/FETCH_ANNULMENT_TYPES_ERROR';

export const FETCH_ENTERPRISE_TYPES_REQUEST = '@enterprise/FETCH_ENTERPRISE_TYPES_REQUEST';
export const FETCH_ENTERPRISE_TYPES_SUCCESS = '@enterprise/FETCH_ENTERPRISE_TYPES_SUCCESS';
export const FETCH_ENTERPRISE_TYPES_ERROR = '@enterprise/FETCH_ENTERPRISE_TYPES_ERROR';

export const FETCH_DATA_DELETION_PERIOD_REQUEST = '@classifiers/FETCH_DATA_DELETION_PERIOD_REQUEST';
export const FETCH_DATA_DELETION_PERIOD_SUCCESS = '@classifiers/FETCH_DATA_DELETION_PERIOD_SUCCESS';
export const FETCH_DATA_DELETION_PERIOD_ERROR = '@classifiers/FETCH_DATA_DELETION_PERIOD_ERROR';

export const FETCH_FICTITIOUS_OUTLET_REQUEST = '@classifiers/FETCH_FICTITIOUS_OUTLET_REQUEST';
export const FETCH_FICTITIOUS_OUTLET_SUCCESS = '@classifiers/FETCH_FICTITIOUS_OUTLET_SUCCESS';
export const FETCH_FICTITIOUS_OUTLET_ERROR = '@classifiers/FETCH_FICTITIOUS_OUTLET_ERROR';
