import { ProvidedDataHistoryDataState, ProvidedDataPageRequest } from './providedDataTableTypes';
import { ProvidedDataHistoryHeaderField } from '../../containers/ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';
import { SortOrder } from '../../utils/tableTypes';
import { ApplicationState } from '../index';
import { ProvidedDataTableState } from './providedDataTableReducer';

const getFilterString = (
  state: ProvidedDataHistoryDataState,
  value: ProvidedDataHistoryHeaderField
): string | null => state.customFilter[value][0]?.trim() || null;

const getFilterNumber = (
  state: ProvidedDataHistoryDataState,
  value: ProvidedDataHistoryHeaderField
): number | null => Number(state.customFilter[value][0]) || null;

const getSorting = (
  state: ProvidedDataHistoryDataState,
  value: ProvidedDataHistoryHeaderField
): SortOrder | null => (state.sortBy === value ? state.order : null);

export const getProvidedDataHistoryPageRequest = (
  state: ProvidedDataHistoryDataState
): ProvidedDataPageRequest => {
  return {
    docStatusAnnulled: state.showStatusAnnuled,
    docStatusSigned: state.showStatusSigned,
    docStatusUnsigned: state.showStatusNotSigned,
    filterDataTypeId: getFilterNumber(state, 'dataType'),
    filterDocumentStatus: getFilterString(state, 'documentStatus'),
    filterLastEditedById: getFilterString(state, 'lastEditedBy'),
    filterLastEditedDate: getFilterString(state, 'lastEditedDate'),
    filterOutletId: getFilterNumber(state, 'outletList'),
    filterVirsId: getFilterNumber(state, 'virsName'),
    pageNr: state.rowsPerPage * state.page + 1,
    pageSize: state.rowsPerPage,
    shownPeriod: state.periodFilter.id,
    sortingDataType: getSorting(state, 'dataType'),
    sortingDocumentStatus: getSorting(state, 'documentStatus'),
    sortingLastEditedBy: getSorting(state, 'lastEditedBy'),
    sortingLastEditedDate: getSorting(state, 'lastEditedDate'),
    sortingOutletName: getSorting(state, 'outletList'),
    sortingVirsName: getSorting(state, 'virsName')
  };
};

export const selectProvidedDataTableState = (state: ApplicationState): ProvidedDataTableState =>
  state.providedDataTable;
