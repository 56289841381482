import React, { useCallback, useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import { OutletInfo } from '../../store/outletInfoData/outletInfoDataTypes';
import PageFilterButton from '../../components/PageButtons/PageFilterButton';
import { useOutletInfoTablesDispatch } from './OutletInfoTablesContext';
import { outletTabs, getOutletTabPath, OutletTabName } from './tablesState/tablesTypesAndActions';
import OutletMainDataTable from './OutletMainTable/Table';
import AdPrintingTable from './OutletAdPrintingTable/Table';
import OutletCategoryTable from './OutletCategoryTable/Table';
import EditionDataTable from './OutletEditionTable/Table';
import { OutletRepresentativesTable } from './OutletRepresentativesTable/Table';
import { VirsisUser } from '../../store/virsis/dataTypes';
import { useHistory } from 'react-router';

export interface OutletInfoTablesProps {
  data?: OutletInfo;
  openedTab?: OutletTabName;
  currentUser: VirsisUser | undefined;
}

export const OutletInfoTables: React.FC<OutletInfoTablesProps> = ({
  openedTab,
  data,
  currentUser
}) => {
  const history = useHistory();
  const { dispatch: tablesDispatch } = useOutletInfoTablesDispatch();

  const setTab = useCallback(
    (tab: OutletTabName) => {
      history.push(getOutletTabPath(tab));
    },
    [history]
  );

  function redirectToEditions() {
    history.push(getOutletTabPath('editions'));
  }

  useEffect(() => {
    if (data) {
      tablesDispatch({
        type: 'OUTLET_INFO_INITIALIZED',
        data,
        currentUser
      });
    }
  }, [tablesDispatch, data, currentUser]);

  const showAllTabs = openedTab === 'all' || openedTab === undefined;
  const showMain = openedTab === 'main' || showAllTabs;
  const showRepresentatives = openedTab === 'representatives' || showAllTabs;
  const showEditions = (openedTab === 'editions' || showAllTabs) && data?.hasEditions;
  const showAdPrinting =
    (openedTab === 'adprinting' || showAllTabs) &&
    (data?.mandatoryPeriodicEditions || data?.outletIsPeriodic || data?.outletIsNonPeriodic);
  const showCategories = openedTab === 'categories' || showAllTabs;

  return (
    <>
      <div className="data-page-filters">
        <Box>
          {outletTabs
            .filter((displayTab) =>
              data && !data.hasEditions ? displayTab.tab !== 'editions' : displayTab
            )
            .filter((displayTab) =>
              data &&
              !(
                data?.mandatoryPeriodicEditions ||
                data?.outletIsPeriodic ||
                data?.outletIsNonPeriodic
              )
                ? displayTab.tab !== 'adprinting'
                : displayTab
            )
            .map((displayTab) => (
              <PageFilterButton
                key={displayTab.tab}
                active={openedTab ? displayTab.tab === openedTab : displayTab.tab === 'all'}
                onClick={() => setTab(displayTab.tab)}
                label={displayTab.label}
              />
            ))}
        </Box>
      </div>
      <Divider light />
      <div className="data-page-content">
        {showMain && (
          <OutletMainDataTable redirectToEditions={redirectToEditions} tableName="main" />
        )}
        {showRepresentatives && <OutletRepresentativesTable tableName="representatives" />}
        {showEditions && <EditionDataTable tableName="editions" />}
        {showAdPrinting && <AdPrintingTable tableName="adprinting" />}
        {showCategories && <OutletCategoryTable tableName="categories" />}
      </div>
    </>
  );
};
