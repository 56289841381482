import React, { useEffect, useRef } from 'react';
import { Typography, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import { useShareholdersTableDispatch } from '../../MainTable/ShareholdersTableWithContext';

interface Props {
  onFilterTabToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showFilter: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: {
    border: '1px solid transparent',
    borderLeftColor: '#E5E5E5',
    borderRadius: 0,
    margin: 0,
    height: 48,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  activeButton: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  icon: {
    paddingLeft: 5,
    fontSize: 15,
    color: theme.palette.primary.main
  },
  borlderText: {
    color: '#637082',
    fontWeight: 600
  }
}));

const FilterButtonWithRef: React.FC<Props> = ({ onFilterTabToggle, showFilter, disabled }) => {
  const classes = useStyles();
  const elementRef = useRef(null);

  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  useEffect(() => {
    tableDispatch({
      type: 'SAVE_FILTER_BUTTON_REFERENCE',
      filterButtonRef: elementRef.current
    });
  }, [tableDispatch]);

  return (
    <Button
      ref={elementRef}
      value="filter"
      className={showFilter ? `${classes.button} ${classes.activeButton}` : `${classes.button}`}
      onClick={onFilterTabToggle}
      disabled={disabled}
    >
      <Typography variant="h6" className={classes.borlderText}>
        Filtravimas
      </Typography>
      <FilterListIcon className={classes.icon} />
    </Button>
  );
};
export default FilterButtonWithRef;
