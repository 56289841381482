import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EditionRowBasic } from './RowBasic';
import { ApplicationState } from '../../../../store';
import { createEdition } from '../../../../store/outletInfoData/outletInfoDataActions';
import {
  OutletEditionRecord,
  OutletInfo
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import {
  EditionNewRowState,
  initialEditionNewRecordRowState
} from './editionRowState/rowInitialStateAndTypes';
import { newEditionReducer } from './editionRowState/newRecordReducer';
import AllowedTo from '../../../AllowedTo';
import { Roles } from '../../../../store/virsis/dataTypes';
import { DropdownInputCell } from '../../../../components/TableInputs/DropdownInput';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { MoneyInputCell } from '../../../../components/TableInputs/MoneyInput';
import { EditionPeriodShort } from '../../../../store/classifiers/classifiersTypes';
import { EditionColumnsDisplayStatus } from '../../tablesState/editionTableTypes';

interface Props {
  outletInfo: OutletInfo;
  columnsDisplay: EditionColumnsDisplayStatus;
  closeNewRecordRow: () => void;
}

export const EditionDataRowContainerNew: React.FC<Props> = ({
  outletInfo: { outletId, controlledFrom, controlledTo, outletIsPeriodic, missingPeriods },
  columnsDisplay,
  closeNewRecordRow
}) => {
  const [rowState, rowDispatch] = useReducer(newEditionReducer, initialEditionNewRecordRowState);
  const {
    outletInfoData: { creatingEdition },
    virsis: { virsData, currentUser }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  function setPeriod(editionPeriod: EditionPeriodShort | null) {
    rowDispatch({ type: 'EDITION_PERIOD_CHANGED', editionPeriod });
  }

  function setDate(editionDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'EDITION_DATE_CHANGED', editionDate });
  }

  function setCirculation(circulation: string) {
    rowDispatch({ type: 'CIRCULATION_CHANGED', circulation });
  }

  function createRecord(state: EditionNewRowState) {
    if (virsData && currentUser && outletId) {
      const newEdition: OutletEditionRecord = {
        circulation: state.circulation.value ? Number(state.circulation.value) : null,
        editionDate: state.editionDate.value
          ? state.editionDate.value.format('L').toString()
          : null,
        editionPeriodId: outletIsPeriodic
          ? Number(state.editionPeriod.value?.periodId) || null
          : null
      };
      const { virsId } = virsData;
      reduxDispatch(createEdition(virsId, outletId, newEdition));
    }
  }

  useEffect(() => {
    rowDispatch({
      type: 'NEW_RECORD_STATE_INITIALIZED',
      missingPeriods: missingPeriods || [],
      periodic: outletIsPeriodic,
      controlledFrom: controlledFrom || null,
      controlledTo: controlledTo || null
    });
  }, [controlledFrom, controlledTo, missingPeriods, outletIsPeriodic]);

  return (
    <>
      <EditionRowBasic
        id="new-record"
        periodic={outletIsPeriodic}
        columnsToDisplay={columnsDisplay}
        firstInput={
          <DropdownInputCell
            selectValue={setPeriod}
            dropdownState={rowState.editionPeriod}
            getOptionLabel={(period) => period.periodName}
            getOptionSelected={(option, value) => option.periodId === value.periodId}
          />
        }
        secondInput={
          <DateInputImprovedCell state={rowState.editionDate} setDate={setDate} isRequired />
        }
        thirdInput={
          <MoneyInputCell
            setValue={setCirculation}
            inputState={rowState.circulation}
            outlined
            noDecimals
            isRequired
          />
        }
        actions={
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <SaveAndCancelActions
              handleConfirmationYes={() =>
                rowDispatch({ type: 'CREATE_RECORD_CLICKED', onValid: createRecord })
              }
              isProcessing={creatingEdition}
              handleCancelButtonClick={closeNewRecordRow}
            />
          </AllowedTo>
        }
      />
    </>
  );
};
