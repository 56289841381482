import React, { ReactNode } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core/';
import { VirsisParameter } from '../../../store/parameters/parametersDataTypes';

interface Props {
  record: VirsisParameter;
  parameterInput: ReactNode;
}

const useStyles = makeStyles({
  paramWrapper: {
    '& > div': {
      marginBottom: 10,
      flexWrap: 'nowrap',
      '&> p:first-child': {
        flexShrink: 0,
        width: 200,
        marginRight: 30,
        textAlign: 'end',
        fontWeight: 600
      }
    }
  }
});

export const ParameterDialogBody: React.FC<Props> = ({ record, parameterInput }) => {
  const {
    parameterCode,
    parameterName,
    parameterType,
    parameterScope,
    minValue,
    maxValue,
    isUrl,
    isRequired,
    measurementUnit,
    validFrom,
    validTo
  } = record;
  const classes = useStyles();

  return (
    <Grid
      className={classes.paramWrapper}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid container item>
        <Typography>Kodas</Typography>
        <Typography>{parameterCode}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Parametro pavadinimas</Typography>
        <Typography>{parameterName}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Parametro tipas</Typography>
        <Typography>{parameterType}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Parametro sritis</Typography>
        <Typography>{parameterScope}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Minimali reikšmė</Typography>
        <Typography>{minValue}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Maksimali reikšmė</Typography>
        <Typography>{maxValue}</Typography>
      </Grid>
      <Grid container item>
        <Typography>URL požymis</Typography>
        <Typography>{isUrl}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Privalomas</Typography>
        <Typography>{isRequired}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Matavimo vienetas</Typography>
        <Typography>{measurementUnit}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Parametro reikšmė</Typography>
        <Grid item style={{ flexGrow: 1 }}>
          {parameterInput}
        </Grid>
      </Grid>
      <Grid container item>
        <Typography>Galioja nuo</Typography>
        <Typography>{validFrom}</Typography>
      </Grid>
      <Grid container item>
        <Typography>Galioja iki</Typography>
        <Typography>{validTo}</Typography>
      </Grid>
    </Grid>
  );
};
