import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import {
  IntegratedSystemsAuditsTableAction,
  IntegratedSystemsAuditsTableState
} from './tableInitialStateAndTypes';

export const integratedSystemsAuditsTableReducer = (
  state: IntegratedSystemsAuditsTableState,
  action: IntegratedSystemsAuditsTableAction
): IntegratedSystemsAuditsTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'CONTENT_OPENED':
      return {
        ...state,
        contentToView: action.contentToView
      };
    case 'CONTENT_CLOSED':
      return {
        ...state,
        contentToView: undefined
      };
    default:
      return state;
  }
};
