import React from 'react';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import { useParametersDataTableDispatch, useParametersDataTableState } from './Context';
import {
  ParametersDataColumnHeader,
  ParametersDataHeaderField
} from './tableState/tableInitialStateAndTypes';

interface Props {
  columnNames: ParametersDataColumnHeader[];
}

export const ParametersDataTableCustomizationBar: React.FC<Props> = ({ columnNames }) => {
  const { state } = useParametersDataTableState();
  const { dispatch } = useParametersDataTableDispatch();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: ParametersDataHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: ParametersDataHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
