import React from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { ShareholderGroupPerson } from '../../../../../tableState/tableInitialStateAndTypes';
import { PersonDetails } from './PersonDetails';

const useStyles = makeStyles((theme) => ({
  shareholderPerson: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
  },
  editIcon: {
    color: theme.palette.info.main
  }
}));

export interface Props {
  onIconClick: () => void;
  shareholderPerson: ShareholderGroupPerson;
  isShareholderFromJadis: boolean;
}

export const ShareholderPerson: React.FC<Props> = ({
  onIconClick,
  shareholderPerson,
  isShareholderFromJadis
}) => {
  const classes = useStyles();
  const { personName, legalPersonCode } = shareholderPerson;

  return (
    <div className={classes.shareholderPerson}>
      <PersonDetails personName={personName} legalPersonCode={legalPersonCode} />
      {!isShareholderFromJadis && (
        <IconButton onClick={onIconClick}>
          <EditIcon fontSize="small" className={classes.editIcon} />
        </IconButton>
      )}
    </div>
  );
};
