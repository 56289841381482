import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { OutletDataTableHeader } from '../tablesState/mainOutletTableTypes';
import { OutletDataTableField } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import { ApplicationState } from '../../../store';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { getUniqueOutletDataOptions } from '../tablesState/tablesStateFunctions';
import { TableCustomFilterCellGeneric } from '../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: OutletDataTableHeader[];
}

const FilterRow: React.FC<Props> = ({ columnNames }) => {
  const { outletInfo } = useSelector((state: ApplicationState) => state.outletInfoData);

  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();
  const {
    state: { main: mainTableState }
  } = useOutletInfoTablesState();

  function setCustomFilter(column: OutletDataTableField, value: string | null) {
    tableDispatch({
      type: 'MAIN_OUTLET_CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => mainTableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={setCustomFilter}
              uniqueOptions={getUniqueOutletDataOptions(outletInfo?.outletData || [], column.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FilterRow;
