import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  fetchVirsisParametersError,
  fetchVirsisParametersRequest,
  fetchVirsisParametersSuccess,
  updateVirsisParameter,
  updateVirsisParameterError,
  updateVirsisParameterSuccess
} from './parametersActions';
import { FETCH_PARAMETERS_REQUEST, UPDATE_PARAMETER_REQUEST } from './parametersActionTypes';
import { getVirsisParameters, putVirsisParameterValue } from './parametersApi';

function* handleVirsisParametersFetchSaga() {
  try {
    const { data } = yield call(getVirsisParameters);
    yield put(fetchVirsisParametersSuccess(data));
  } catch (err) {
    yield put(fetchVirsisParametersError(err));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleUpdateVirsisParameterSaga(action: ActionType<typeof updateVirsisParameter>) {
  const { parameterId, parameterValue, parameterCode } = action.payload;
  try {
    yield call(putVirsisParameterValue, parameterId, parameterValue);
    yield put(updateVirsisParameterSuccess());
    yield put(sendMessage('success', `Parametras ${parameterCode} atnaujintas`));
    yield put(fetchVirsisParametersRequest());
  } catch (err) {
    yield put(updateVirsisParameterError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* parametersSaga() {
  yield takeEvery(FETCH_PARAMETERS_REQUEST, handleVirsisParametersFetchSaga);
  yield takeEvery(UPDATE_PARAMETER_REQUEST, handleUpdateVirsisParameterSaga);
}

export default parametersSaga;
