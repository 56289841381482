import React, { useRef, useState } from 'react';
import { Badge, IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsNone';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import MessagesMenu from './MessagesMenu/MessagesMenu';
import { BasicTooltip } from '../../components/Tooltips/BasicTooltip';

const useStyles = makeStyles((theme) => ({
  iconButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    height: 'inherit'
  },
  iconButton: {
    backgroundColor: theme.palette.secondary.light,
    height: 40,
    width: 40,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  iconBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const UserNotificationsBadge: React.FC = () => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const badgeRef = useRef(null);

  const { userMessages, loadingUserMessages, userMessagesError } = useSelector(
    (stateGlobal: ApplicationState) => stateGlobal.userMessages
  );

  const dataUnavailable = !userMessages || loadingUserMessages || !!userMessagesError;

  const unreadMessagesLength: number =
    userMessages && !dataUnavailable ? userMessages.filter((msg) => !msg.read).length : 0;

  return (
    <div className={classes.iconButtonContainer} ref={badgeRef}>
      <BasicTooltip text={'Pranešimai'}>
        <Badge
          badgeContent={unreadMessagesLength}
          overlap="circle"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{ root: classes.iconBadge }}
        >
          <IconButton
            aria-label="Pranešimai"
            disabled={dataUnavailable}
            className={classes.iconButton}
            size="medium"
            onClick={() => setOpen((prevOpen) => !prevOpen)}
          >
            <NotificationsIcon />
          </IconButton>
        </Badge>
      </BasicTooltip>
      {open && (
        <MessagesMenu isOpen={open} anchorRef={badgeRef.current} closeList={() => setOpen(false)} />
      )}
    </div>
  );
};

export default UserNotificationsBadge;
