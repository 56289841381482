import { action } from 'typesafe-actions';
import {
  FETCH_OUTLET_INFO_DATA_REQUEST,
  FETCH_OUTLET_INFO_DATA_SUCCESS,
  FETCH_OUTLET_INFO_DATA_ERROR,
  CREATE_EDITION_REQUEST,
  CREATE_EDITION_SUCCESS,
  CREATE_EDITION_ERROR,
  RESET_CREATE_EDITION_STATE,
  UPDATE_EDITION_REQUEST,
  UPDATE_EDITION_SUCCESS,
  UPDATE_EDITION_ERROR,
  RESET_UPDATE_EDITION_STATE,
  REMOVE_EDITION_REQUEST,
  REMOVE_EDITION_SUCCESS,
  RESET_REMOVE_EDITION_STATE,
  REMOVE_EDITION_ERROR,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  RESET_CREATE_CATEGORY_STATE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_ERROR,
  RESET_UPDATE_CATEGORY_STATE,
  REMOVE_CATEGORY_REQUEST,
  REMOVE_CATEGORY_ERROR,
  RESET_REMOVE_CATEGORY_STATE,
  REMOVE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  CREATE_REPRESENTATIVE_REQUEST,
  CREATE_REPRESENTATIVE_SUCCESS,
  CREATE_REPRESENTATIVE_ERROR,
  RESET_CREATE_REPRESENTATIVE_STATE,
  UPDATE_REPRESENTATIVE_SUCCESS,
  UPDATE_REPRESENTATIVE_REQUEST,
  UPDATE_REPRESENTATIVE_ERROR,
  RESET_UPDATE_REPRESENTATIVE_STATE,
  REMOVE_REPRESENTATIVE_SUCCESS,
  REMOVE_REPRESENTATIVE_REQUEST,
  REMOVE_REPRESENTATIVE_ERROR,
  RESET_REMOVE_REPRESENTATIVE_STATE,
  RESET_REPRESENTATIVE_FOR_NEW_RECORD,
  SET_REPRESENTATIVE_FOR_NEW_RECORD,
  CONTINUE_ADDING_REPRESENTATIVE_CLICKED,
  CANCEL_ADDING_REPRESENTATIVE_CLICKED,
  CREATE_ADVERTISEMENT_REQUEST,
  CREATE_ADVERTISEMENT_SUCCESS,
  CREATE_ADVERTISEMENT_ERROR,
  RESET_CREATE_ADVERTISEMENT_STATE,
  UPDATE_ADVERTISEMENT_REQUEST,
  UPDATE_ADVERTISEMENT_SUCCESS,
  UPDATE_ADVERTISEMENT_ERROR,
  RESET_UPDATE_ADVERTISEMENT_STATE,
  RESET_REMOVE_ADVERTISEMENT_STATE,
  REMOVE_ADVERTISEMENT_REQUEST,
  REMOVE_ADVERTISEMENT_SUCCESS,
  REMOVE_ADVERTISEMENT_ERROR,
  RESET_MISSING_PERIODS,
  FETCH_MISSING_PERIODS_REQUEST,
  FETCH_MISSING_PERIODS_SUCCESS,
  FETCH_MISSING_PERIODS_ERROR
} from './outletInfoDataActionTypes';

import {
  OutletInfo,
  OutletRepresentativeRecord,
  OutletEditionRecord,
  OutletCategoryRecord,
  OutletAdRecord
} from './outletInfoDataTypes';

import { NaturalPersonSearchData } from '../persons/personsTypes';
import { LegalPersonSearchData } from '../legalPersonData/legalDataTypes';

export const fetchOutletInfoData = (virId: number, outletId: number) =>
  action(FETCH_OUTLET_INFO_DATA_REQUEST, {
    virId,
    outletId
  });

export const fetchOutletInfoDataSuccess = (outletInfoData: OutletInfo) =>
  action(FETCH_OUTLET_INFO_DATA_SUCCESS, outletInfoData);

export const fetchOutletInfoDataError = (error: Error) =>
  action(FETCH_OUTLET_INFO_DATA_ERROR, error);

export const createEdition = (virId: number, outletId: number, record: OutletEditionRecord) =>
  action(CREATE_EDITION_REQUEST, { virId, outletId, record });

export const createEditionSuccess = () => action(CREATE_EDITION_SUCCESS);

export const createEditionError = (error: Error) => action(CREATE_EDITION_ERROR, error);

export const resetEditionCreatingState = () => action(RESET_CREATE_EDITION_STATE);

export const updateEdition = (
  virId: number,
  outletId: number,
  editionId: number,
  record: OutletEditionRecord
) =>
  action(UPDATE_EDITION_REQUEST, {
    virId,
    outletId,
    editionId,
    record
  });

export const updateEditionSuccess = () => action(UPDATE_EDITION_SUCCESS);

export const updateEditionError = (error: Error) => action(UPDATE_EDITION_ERROR, error);

export const resetEditionUpdatingState = () => action(RESET_UPDATE_EDITION_STATE);

export const removeEdition = (virId: number, outletId: number, editionId: number) =>
  action(REMOVE_EDITION_REQUEST, { virId, outletId, editionId });

export const removeEditionSuccess = () => action(REMOVE_EDITION_SUCCESS);

export const removeEditionError = (error: Error) => action(REMOVE_EDITION_ERROR, error);

export const resetEditionRemovingState = () => action(RESET_REMOVE_EDITION_STATE);

export const resetMissingPeriods = () => action(RESET_MISSING_PERIODS);
export const fetchMissingPeriods = (outletId?: number) =>
  action(FETCH_MISSING_PERIODS_REQUEST, { outletId });
export const fetchMissingPeriodsSuccess = (data: string, outletId?: number) =>
  action(FETCH_MISSING_PERIODS_SUCCESS, { data, outletId });
export const fetchMissingPeriodsError = (error: Error) =>
  action(FETCH_MISSING_PERIODS_ERROR, error);

export const createCategory = (outletId: number, newCategory: OutletCategoryRecord) =>
  action(CREATE_CATEGORY_REQUEST, { outletId, newCategory });

export const createCategorySuccess = () => action(CREATE_CATEGORY_SUCCESS);

export const createCategoryError = (error: Error) => action(CREATE_CATEGORY_ERROR, error);

export const resetCategoryCreatingState = () => action(RESET_CREATE_CATEGORY_STATE);

export const updateCategory = (
  outletId: number,
  outletCategoryId: number,
  record: OutletCategoryRecord
) =>
  action(UPDATE_CATEGORY_REQUEST, {
    outletId,
    outletCategoryId,
    record
  });

export const updateCategorySuccess = () => action(UPDATE_CATEGORY_SUCCESS);

export const updateCategoryError = (error: Error) => action(UPDATE_CATEGORY_ERROR, error);

export const resetCategoryUpdatingState = () => action(RESET_UPDATE_CATEGORY_STATE);

export const removeCategory = (virId: number, outletId: number, outletCategoryId: number) =>
  action(REMOVE_CATEGORY_REQUEST, {
    virId,
    outletId,
    outletCategoryId
  });

export const removeCategorySuccess = () => action(REMOVE_CATEGORY_SUCCESS);

export const removeCategoryError = (error: Error) => action(REMOVE_CATEGORY_ERROR, error);

export const resetCategoryRemovingState = () => action(RESET_REMOVE_CATEGORY_STATE);

export const setRepresentativeForNewRecord = (
  newRepresentative: NaturalPersonSearchData | LegalPersonSearchData
) => action(SET_REPRESENTATIVE_FOR_NEW_RECORD, newRepresentative);
export const resetRepresentativeForNewRecord = () => action(RESET_REPRESENTATIVE_FOR_NEW_RECORD);
export const showAddRepresentativeRow = () => action(CONTINUE_ADDING_REPRESENTATIVE_CLICKED);
export const hideAddRepresentativeRow = () => action(CANCEL_ADDING_REPRESENTATIVE_CLICKED);
export const createRepresentative = (
  virId: number,
  outletId: number,
  record: OutletRepresentativeRecord
) => action(CREATE_REPRESENTATIVE_REQUEST, { virId, outletId, record });
export const createRepresentativeSuccess = () => action(CREATE_REPRESENTATIVE_SUCCESS);
export const createRepresentativeError = (error: Error) =>
  action(CREATE_REPRESENTATIVE_ERROR, error);
export const resetRepresentativeCreatingState = () => action(RESET_CREATE_REPRESENTATIVE_STATE);

export const updateRepresentative = (
  virId: number,
  outletId: number,
  representativeId: number,
  record: OutletRepresentativeRecord,
  message: string
) =>
  action(UPDATE_REPRESENTATIVE_REQUEST, {
    virId,
    outletId,
    representativeId,
    record,
    message
  });
export const updateRepresentativeSuccess = () => action(UPDATE_REPRESENTATIVE_SUCCESS);
export const updateRepresentativeError = (error: Error) =>
  action(UPDATE_REPRESENTATIVE_ERROR, error);
export const resetRepresentativeUpdatingState = () => action(RESET_UPDATE_REPRESENTATIVE_STATE);

export const removeRepresentative = (virId: number, outletId: number, representativeId: number) =>
  action(REMOVE_REPRESENTATIVE_REQUEST, {
    virId,
    outletId,
    representativeId
  });
export const removeRepresentativeSuccess = () => action(REMOVE_REPRESENTATIVE_SUCCESS);
export const removeRepresentativeError = (error: Error) =>
  action(REMOVE_REPRESENTATIVE_ERROR, error);
export const resetRepresentativeRemovingState = () => action(RESET_REMOVE_REPRESENTATIVE_STATE);

export const createAdvertisement = (outletId: number, newAdvertisement: OutletAdRecord) =>
  action(CREATE_ADVERTISEMENT_REQUEST, {
    outletId,
    newAdvertisement
  });

export const createAdvertisementSuccess = () => action(CREATE_ADVERTISEMENT_SUCCESS);

export const createAdvertisementError = (error: Error) => action(CREATE_ADVERTISEMENT_ERROR, error);

export const updateAdvertisement = (
  outletId: number,
  outletAdPrintingId: number,
  record: OutletAdRecord
) =>
  action(UPDATE_ADVERTISEMENT_REQUEST, {
    outletId,
    outletAdPrintingId,
    record
  });

export const updateAdvertisementSuccess = () => action(UPDATE_ADVERTISEMENT_SUCCESS);

export const updateAdvertisementError = (error: Error) => action(UPDATE_ADVERTISEMENT_ERROR, error);

export const resetAdvertisementCreatingState = () => action(RESET_CREATE_ADVERTISEMENT_STATE);

export const resetAdvertisementUpdatingState = () => action(RESET_UPDATE_ADVERTISEMENT_STATE);

export const resetAdvertisementRemovingState = () => action(RESET_REMOVE_ADVERTISEMENT_STATE);

export const removeAdvertisement = (outletId: number, advertisementId: number, virId: number) =>
  action(REMOVE_ADVERTISEMENT_REQUEST, {
    outletId,
    advertisementId,
    virId
  });

export const removeAdvertisementSuccess = () => action(REMOVE_ADVERTISEMENT_SUCCESS);

export const removeAdvertisementError = (error: Error) => action(REMOVE_ADVERTISEMENT_ERROR, error);
