import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterByPeriodCell } from '../../components/TableCustomFilterCell/FilterByPeriodCell';
import { periodFilterChanged } from '../../store/providedDataTable/providedDataTableActions';
import { ApplicationState } from '../../store';
import { Period } from '../../utils/tableTypes';

export const ProvidedDataHistoryTablePeriodFilter: React.FC = () => {
  const reduxDispatch = useDispatch();
  const state = useSelector(
    (applicationState: ApplicationState) => applicationState.providedDataTable.tableDataState
  );

  const handlePeriodFilterChange = (value: Period) => {
    reduxDispatch(periodFilterChanged(value));
  };

  return (
    <FilterByPeriodCell
      setPeriodFilter={handlePeriodFilterChange}
      periodFilter={state.periodFilter}
    />
  );
};
