import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { AddNewRecordButton } from '../../../../components/TableButtons/AddNewRecordButton';
import { Roles, VirsData } from '../../../../store/virsis/dataTypes';
import { FileFormats } from '../../../../utils/exporters/types';
import AllowedTo from '../../../AllowedTo';
import AllowedToAllExcept from '../../../AllowedToAllExcept';
import { getShareholderDateTooltip } from '../utilities/UIfunctions';
import { HeaderActions } from './HeaderActions';
import SubmitVirsData from '../../../SubmitVirsData';
import { Strings } from '../../../../utils/strings/Strings';

interface Props {
  openCalendar: () => void;
  loadingShareholdersData: boolean;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  onDownload?: (format: FileFormats, navigationPath: string[]) => void;
  onClickDelete?: () => void;
  onPrint?: (navigationPath: string[]) => void;
  virsData?: VirsData;
  onVizualizeClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 'auto 0'
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  },
  dateButtonWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  }
}));

const ShareholderHeader: React.FC<Props> = ({
  openCalendar,
  loadingShareholdersData,
  noValidDates,
  allShareholdersFromJADIS,
  onDownload,
  onPrint,
  onClickDelete,
  virsData,
  onVizualizeClick
}) => {
  const { margin, buttonWrapper, dateButtonWrapper } = useStyles();
  return (
    <>
      <Box display="flex">
        <Typography className={margin} variant="h1">
          Dalyviai
        </Typography>
        <AllowedToAllExcept roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
          <HeaderActions
            onDownload={onDownload}
            onPrint={onPrint}
            onVizualizeClick={onVizualizeClick}
            onClickDelete={onClickDelete}
          />
        </AllowedToAllExcept>
      </Box>
      <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
        {virsData && virsData.virsId ? (
          <Box className={dateButtonWrapper}>
              <AddNewRecordButton
                text={Strings.addDate}
                onClicked={openCalendar}
                tooltipText={getShareholderDateTooltip(noValidDates, allShareholdersFromJADIS)}
                disabled={noValidDates || allShareholdersFromJADIS || loadingShareholdersData}
                showTooltip
              />
              <Box className={buttonWrapper}>
                <HeaderActions
                  onDownload={onDownload}
                  onPrint={onPrint}
                  onVizualizeClick={onVizualizeClick}
                  onClickDelete={onClickDelete}
                />
              </Box>
            </Box>
        ) : (
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <SubmitVirsData enterpriseTable />
          </AllowedTo>
        )}
      </AllowedTo>
    </>
  );
};
export default ShareholderHeader;
