import { Handle, Position } from 'react-flow-renderer';
import { BoxTitle } from './TreeChartBoxes/BoxTitle';
import { PercentageInfo } from './TreeChartBoxes/PercentageInfo';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import {
  boxColor,
  childrenNumber,
  isLegalPerson,
  isNaturalPerson
} from './treeChartFunctions/uiFunctions';
import { useVirsTreeDispatch, useVirsTreeState } from '../VirsTreeContext';
import { removeConcatenatedLevelFromParentIdNumber } from '../treeState/treeFunctions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ShareholderComponent = ({ data }: any) => {
  const { treeDispatch } = useVirsTreeDispatch();
  const {
    treeState: { shareType }
  } = useVirsTreeState();

  const { shareholder, activeDate } = data;
  const {
    shareholderPersons,
    sharePercentage,
    votePercentage,
    votesOnRules,
    shareholderLevel,
    shareholderId,
    parentId,
    shareholderEndDate,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  } = shareholder;
  const firstPerson = shareholderPersons[0];

  const subtitle = () => {
    if (isNaturalPerson(shareholderPersons[0].personType)) {
      return 'Fizinis asmuo';
    }
    return 'Juridinis asmuo';
  };

  const handleOpenShareholderCard = () => {};

  const showChildrenShareholders = () => {
    treeDispatch({
      type: 'SHOW_SHAREHOLDERS_CHILDREN',
      shareholder: {
        shareholderId,
        shareholderLevel
      }
    });
  };

  const closeShareholder = () => {
    treeDispatch({
      type: 'HIDE_SHAREHOLDER',
      shareholder: {
        shareholderId,
        parentId: removeConcatenatedLevelFromParentIdNumber(parentId, shareholderLevel),
        shareholderLevel
      }
    });
  };

  const hasErrors = !!firstPerson.shareholderErrors && firstPerson.shareholderErrors.length > 0;

  return (
    <div>
      <Handle type="target" position={Position.Left} />
      <TreeSingleBox
        boxTitle={<BoxTitle title={firstPerson.personName} subtitle={subtitle()} />}
        showLeftSideBox
        showRightSideBox={isLegalPerson(firstPerson.personType)}
        rightChildrenNumber={childrenNumber(firstPerson.shareholders)}
        minHeight={95}
        maxHeight={95}
        color={boxColor(firstPerson.personType)}
        additionalInfo={
          <PercentageInfo
            shares={sharePercentage}
            votes={votePercentage}
            shareType={shareType}
            votesOnRule={votesOnRules}
            sharePercentageLessThanPermitted={sharePercentageLessThanPermitted}
            votePercentageLessThanPermitted={votePercentageLessThanPermitted}
          />
        }
        hasErrors={hasErrors}
        errorText={firstPerson.shareholderErrors}
        onClick={handleOpenShareholderCard}
        handleRightSideBoxClick={showChildrenShareholders}
        handleLeftSideBoxClick={closeShareholder}
        leftSideButtonDisabled={shareholderLevel === 1}
        foregroundGrid={shareholderEndDate === activeDate}
      />
      {firstPerson.shareholderLinks.length > 0 && (
        <Handle type="source" position={Position.Right} />
      )}
    </div>
  );
};
