export type HelpPageTypes =
  | 'KM'
  | 'LRTK'
  | 'ZEIT'
  | 'INSTITUTION'
  | 'RC'
  | 'VIRSIS'
  | 'VIEA'
  | 'VIRS_VIEW';

export const helpPageTypes = {
  KM: 'km',
  LRTK: 'lrtk-zeit',
  ZEIT: 'lrtk-zeit',
  INSTITUTION: 'institution',
  RC: 'rc',
  VIRSIS: 'virs',
  VIEA: 'viea',
  VIRS_VIEW: 'virs-view'
};
