import { Reducer } from 'redux';
import {
  FETCH_OUTLET_DATA_REQUEST,
  FETCH_OUTLET_DATA_SUCCESS,
  FETCH_OUTLET_DATA_ERROR,
  CREATE_OUTLET_REQUEST,
  CREATE_OUTLET_SUCCESS,
  CREATE_OUTLET_ERROR,
  RESET_CREATE_OUTLET_STATE,
  UPDATE_OUTLET_REQUEST,
  UPDATE_OUTLET_SUCCESS,
  UPDATE_OUTLET_ERROR,
  RESET_UPDATE_OUTLET_STATE,
  RENEW_OUTLET_REQUEST,
  RENEW_OUTLET_SUCCESS,
  RENEW_OUTLET_ERROR,
  RESET_RENEW_OUTLET_STATE,
  REMOVE_OUTLET_REQUEST,
  REMOVE_OUTLET_SUCCESS,
  REMOVE_OUTLET_ERROR,
  RESET_REMOVE_OUTLET_STATE,
  REMOVE_OUTLETS_REQUEST,
  REMOVE_OUTLETS_ERROR,
  FIND_OUTLET_ON_LIBIS_ERROR,
  FIND_OUTLET_ON_LIBIS_REQUEST,
  RESET_FIND_OUTLET_ON_LIBIS_STATE,
  FIND_OUTLET_ON_LIBIS_SUCCESS
} from './outletsActionsTypes';
import {
  EnterpriseDataWithOutlets,
  LibisSearchData,
  OutletData,
  OutletGroup
} from './outletsTypes';

export interface OutletDataState {
  loadingOutletData: boolean;
  allEnterprises?: EnterpriseDataWithOutlets[];
  outlets: OutletData[];
  outletGroups?: OutletGroup[];
  outletDataError?: Error;
  creatingOutlet: boolean;
  isOutletCreated: boolean;
  createdOutletId: number | undefined;
  creatingOutletError?: Error;
  renewingOutlet: boolean;
  isOutletRenewed: boolean;
  renewedOutletId: number | undefined;
  renewingOutletError?: Error;
  updatingOutlet: boolean;
  isOutletUpdated: boolean;
  updatingOutletError?: Error;
  removingOutlet: boolean;
  isOutletRemoved: boolean;
  removingOutletError?: Error;
  removeOutletsProgress: boolean;
  removeOutletsError?: Error;
  searchingOutletOnLibis: boolean;
  outletOnLibisResults?: LibisSearchData[];
  outletOnLibisError?: Error;
}
export const initialState: OutletDataState = {
  loadingOutletData: false,
  allEnterprises: [],
  outlets: [],
  outletGroups: [],
  outletDataError: undefined,
  creatingOutlet: false,
  isOutletCreated: false,
  createdOutletId: undefined,
  creatingOutletError: undefined,
  renewingOutlet: false,
  isOutletRenewed: false,
  renewedOutletId: undefined,
  renewingOutletError: undefined,
  updatingOutlet: false,
  isOutletUpdated: false,
  updatingOutletError: undefined,
  removingOutlet: false,
  isOutletRemoved: false,
  removingOutletError: undefined,
  removeOutletsProgress: false,
  removeOutletsError: undefined,
  searchingOutletOnLibis: false,
  outletOnLibisResults: undefined,
  outletOnLibisError: undefined
};

export const outletsReducer: Reducer<OutletDataState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OUTLET_DATA_REQUEST:
      return {
        ...state,
        loadingOutletData: true
      };
    case FETCH_OUTLET_DATA_SUCCESS:
      return {
        ...state,
        loadingOutletData: false,
        allEnterprises: action.payload.allEnterprises,
        outlets: action.payload.outlets,
        outletGroups: action.payload.outletGroups ? action.payload.outletGroups : []
      };
    case FETCH_OUTLET_DATA_ERROR:
      return {
        ...state,
        loadingOutletData: false,
        outletDataError: action?.payload?.data
      };
    case CREATE_OUTLET_REQUEST:
      return {
        ...state,
        creatingOutlet: true
      };
    case CREATE_OUTLET_SUCCESS:
      return {
        ...state,
        creatingOutlet: false,
        createdOutletId: action.payload,
        isOutletCreated: true
      };
    case CREATE_OUTLET_ERROR:
      return {
        ...state,
        creatingOutlet: false,
        creatingOutletError: action?.payload?.data
      };
    case RESET_CREATE_OUTLET_STATE:
      return {
        ...state,
        creatingOutlet: false,
        isOutletCreated: false,
        createdOutletId: undefined,
        creatingOutletError: undefined
      };
    case RENEW_OUTLET_REQUEST:
      return {
        ...state,
        renewingOutlet: true
      };
    case RENEW_OUTLET_SUCCESS:
      return {
        ...state,
        renewingOutlet: false,
        isOutletRenewed: true,
        renewedOutletId: action.payload
      };
    case RENEW_OUTLET_ERROR:
      return {
        ...state,
        renewingOutlet: false,
        renewingOutletError: action?.payload?.data
      };
    case RESET_RENEW_OUTLET_STATE:
      return {
        ...state,
        renewingOutlet: false,
        isOutletRenewed: false,
        renewedOutletId: undefined,
        renewingOutletError: undefined
      };
    case UPDATE_OUTLET_REQUEST:
      return {
        ...state,
        updatingOutlet: true
      };
    case UPDATE_OUTLET_SUCCESS:
      return {
        ...state,
        updatingOutlet: false,
        isOutletUpdated: true
      };
    case UPDATE_OUTLET_ERROR:
      return {
        ...state,
        updatingOutlet: false,
        updatingOutletError: action?.payload?.data
      };
    case RESET_UPDATE_OUTLET_STATE:
      return {
        ...state,
        updatingOutlet: false,
        isOutletUpdated: false,
        updatingOutletError: undefined
      };
    case REMOVE_OUTLET_REQUEST:
      return {
        ...state,
        removingOutlet: true
      };
    case REMOVE_OUTLET_SUCCESS:
      return {
        ...state,
        removingOutlet: false,
        isOutletRemoved: true
      };
    case REMOVE_OUTLET_ERROR:
      return {
        ...state,
        removingOutlet: false,
        removingOutletError: action?.payload?.data
      };
    case RESET_REMOVE_OUTLET_STATE:
      return {
        ...state,
        removingOutlet: false,
        isOutletRemoved: false,
        removingOutletError: undefined
      };
    case REMOVE_OUTLETS_REQUEST:
      return {
        ...state,
        removeOutletsProgress: true
      };
    case REMOVE_OUTLETS_ERROR:
      return {
        ...state,
        removeOutletsError: action.payload
      };
    case FIND_OUTLET_ON_LIBIS_REQUEST:
      return {
        ...state,
        searchingOutletOnLibis: true
      };
    case FIND_OUTLET_ON_LIBIS_SUCCESS:
      return {
        ...state,
        searchingOutletOnLibis: false,
        outletOnLibisResults: action.payload
      };
    case FIND_OUTLET_ON_LIBIS_ERROR:
      return {
        ...state,
        searchingOutletOnLibis: false,
        outletOnLibisError: action?.payload?.data
      };
    case RESET_FIND_OUTLET_ON_LIBIS_STATE:
      return {
        ...state,
        searchingOutletOnLibis: false,
        outletOnLibisResults: undefined,
        outletOnLibisError: undefined
      };
    default: {
      return state;
    }
  }
};
