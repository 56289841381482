import React from 'react';
import { Typography, TableCell, makeStyles } from '@material-ui/core/';

import { useSelector } from 'react-redux';
import { formatDateLT } from '../../tableState/tableStateFunctions';
import { ShareholderAddButton } from '../../../../../components/ShareholderComponents/TableButtons/ShareholderAddButton';
import ShareholderDateOptions from '../../../../../components/ShareholderComponents/TableButtons/ShareholderDateOptions';
import { TooltipOnTableActionButton } from '../../../../../components/Tooltips/TooltipOnTableActionButton';
import { mainDarkColor } from '../../../constants';
import { ConditionalWrapper } from '../../../../../components/ConditionalWrapper/ConditionalWrapper';
import { Roles } from '../../../../../store/virsis/dataTypes';
import { ApplicationState } from '../../../../../store';
import { hasRoles } from '../../../../../utils/roleHelper';
import AllowedTo from '../../../../AllowedTo';
import { getShareholderDateTooltip } from '../../../MainTable/utilities/UIfunctions';
import { ActivityPeriod } from '../../../../../store/shareholders/shareholdersTypes';
import { Strings } from '../../../../../utils/strings/Strings';

interface Props {
  selectedDate: string;
  isDateFromJADIS: boolean;
  openCalendar: () => void;
  noValidDates: boolean;
  loadingShareholders: boolean;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  virsStartDate: string | null;
  isDateUnsignedDoc: boolean;
  noEventsOnDate: boolean;
  activityPeriods: ActivityPeriod[];
}

const useStyles = makeStyles((theme) => ({
  selectedCell: {
    boxSizing: 'border-box',
    width: '380px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '270px'
    }
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&:hover': {
      '& $dateOptionsButton': {
        visibility: 'visible'
      }
    }
  },
  dateOptionsButton: {
    visibility: 'hidden',
    display: 'inline'
  }
}));

export const TableHeadSelectedDateCell: React.FC<Props> = ({
  selectedDate,
  isDateFromJADIS,
  openCalendar,
  noValidDates,
  noEventsOnDate,
  loadingShareholders,
  isDateUnsignedDoc
}) => {
  const classes = useStyles();
  const {
    virsis: { currentUser },
    shareholdersData: { shareholdersData }
  } = useSelector((state: ApplicationState) => state);

  const userCanEdit = currentUser && hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]);

  const showTooltip = !!(userCanEdit && isDateFromJADIS);

  const disableAddDate: boolean =
    shareholdersData?.activityPeriods.length === 0 || noValidDates || loadingShareholders;

  return (
    <TableCell className={classes.selectedCell}>
      <div className={classes.cellContainer}>
        <ConditionalWrapper
          condition={showTooltip}
          wrapper={(children) => (
            <TooltipOnTableActionButton text={Strings.shareholder__dateFromJADIS}>
              <span>{children}</span>
            </TooltipOnTableActionButton>
          )}
        >
          <Typography variant="h4" style={{ color: mainDarkColor }}>
            {formatDateLT(selectedDate)}
          </Typography>
        </ConditionalWrapper>

        <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
          <div style={{ position: 'absolute', right: 0 }}>
            <div className={classes.dateOptionsButton}>
              <ShareholderDateOptions
                selectedDate={selectedDate}
                isDateFromJADIS={isDateFromJADIS}
                hasDateUnsignedDoc={isDateUnsignedDoc}
                noEventsOnDate={noEventsOnDate}
              />
            </div>
            <ShareholderAddButton
              disabled={disableAddDate}
              outlined
              onClick={openCalendar}
              tooltipMessage={getShareholderDateTooltip(noValidDates)}
            />
          </div>
        </AllowedTo>
      </div>
    </TableCell>
  );
};
