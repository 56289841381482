import {
  ProvidedDataHistoryCustomFilter,
  ProvidedDataHistoryHeaderField,
  Period
} from './tableInitialStateAndTypes';
import { SortOrder, VirsisData } from '../../../utils/tableTypes';
import {
  stableSort,
  getComparator,
  checkIfDateIsWithinPeriod
} from '../../../utils/tableDataFunctions';
import { ProvidedDataInst } from '../../../store/providedData/providedDataTypes';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { getStatus } from '../../../store/document/documentTypes';

export const updateProvidedDataHistoryCustomFilter = (
  filter: ProvidedDataHistoryCustomFilter,
  filterBy: ProvidedDataHistoryHeaderField,
  value: string | null
): ProvidedDataHistoryCustomFilter => {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
};

const getCustomFilteredProvidedDataHistory = (
  data: ProvidedDataInst[],
  filter: ProvidedDataHistoryCustomFilter
): ProvidedDataInst[] => {
  function createCustomFilter(field: ProvidedDataHistoryHeaderField) {
    return (record: ProvidedDataInst): boolean => {
      const [valueToFilterBy] = filter[field];
      if (valueToFilterBy) {
        if (field === 'outletList') {
          const recordsMatchingOutlets = record.outletList.filter((outlet) =>
            outlet.outletName
              .toString()
              .toLowerCase()
              .includes(valueToFilterBy.toString().toLowerCase())
          );
          return recordsMatchingOutlets !== undefined && recordsMatchingOutlets.length > 0;
        }
        if (field === 'documentStatus') {
          return getStatus(record?.documentStatus?.id).includes(filter.documentStatus.toString());
        }

        const recordFieldToTest = record[field];
        if (recordFieldToTest) {
          return recordFieldToTest
            .toString()
            .toLowerCase()
            .includes(valueToFilterBy.toString().toLowerCase());
        }
        return false;
      }
      return true;
    };
  }

  const allTableFilters = [
    createCustomFilter('dataType'),
    createCustomFilter('virsName'),
    createCustomFilter('outletList'),
    createCustomFilter('documentStatus'),
    createCustomFilter('lastEditedDate'),
    createCustomFilter('lastEditedBy')
  ];

  const filteredData = data.filter((record) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });
  return filteredData;
};

const getStatusFilteredProvidedDataHistory = (
  data: ProvidedDataInst[],
  showStatusNotSigned: boolean,
  showStatusSigned: boolean,
  showStatusAnnuled: boolean
): ProvidedDataInst[] => {
  let filtered;
  const unsigned = data.filter((record) => record.documentStatus.id === 'RUOSIAMAS');
  const signed = data.filter((record) => record.documentStatus.id === 'PASIRASYTAS');
  const annuled = data.filter((record) => record.documentStatus.id === 'ANULIUOTAS');

  if (showStatusNotSigned && !showStatusSigned && !showStatusAnnuled) {
    filtered = unsigned || [];
  }
  if (!showStatusNotSigned && showStatusSigned && !showStatusAnnuled) {
    filtered = signed;
  }
  if (!showStatusNotSigned && !showStatusSigned && showStatusAnnuled) {
    filtered = annuled;
  }
  if (showStatusNotSigned && showStatusSigned && showStatusAnnuled) {
    filtered = data;
  }
  if (!showStatusNotSigned && showStatusSigned && showStatusAnnuled) {
    filtered = [...signed, ...annuled];
  }
  if (showStatusNotSigned && !showStatusSigned && showStatusAnnuled) {
    filtered = [...unsigned, ...annuled];
  }
  if (showStatusNotSigned && showStatusSigned && !showStatusAnnuled) {
    filtered = [...unsigned, ...signed];
  }
  return filtered || [];
};

export const getPeriodFilterProvidedDataHistory = (
  data: ProvidedDataInst[],
  periodFilter: Period
): ProvidedDataInst[] => {
  switch (periodFilter.id) {
    case 'PASKUTINIS_MENUO':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 30));
    case 'PASKUTINIS_KETVIRTIS':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 90));
    case 'PASKUTINIS_PUSMETIS':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 180));
    case 'PASKUTINIAI_METAI':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 365));
    case 'VISI':
      return data;
    default:
      return data;
  }
};

export const getStatusFilteredCustomFilteredProvidedDataHistory = (
  data: ProvidedDataInst[],
  filter: ProvidedDataHistoryCustomFilter,
  showStatusNotSigned: boolean,
  showStatusSigned: boolean,
  showStatusAnnuled: boolean
): ProvidedDataInst[] => {
  const statusFilteredData = getStatusFilteredProvidedDataHistory(
    data,
    showStatusNotSigned,
    showStatusSigned,
    showStatusAnnuled
  );
  // eslint-disable-next-line
  return getCustomFilteredProvidedDataHistory(statusFilteredData as ProvidedDataInst[], filter);
};

export const getSortedProvidedDataHistory = (
  data: ProvidedDataInst[],
  order: SortOrder,
  sortBy: string
): ProvidedDataInst[] => {
  if (sortBy === 'outletList') {
    const innerSort = data.map((record) => {
      if (record.outletList) {
        const sortedOutlets = stableSort<OutletShortData>(
          record.outletList,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'outletName')
        );
        return { ...record, outletList: sortedOutlets };
      }
      return record;
    });
    return innerSort.sort((x, y) => {
      if (order === 'desc')
        return y.outletList[0].outletName.localeCompare(x.outletList[0].outletName, 'lt');
      return x.outletList[0].outletName.localeCompare(y.outletList[0].outletName, 'lt');
    });
  }
  if (sortBy === 'documentStatus') {
    return data.sort((x, y) => {
      if (order === 'desc')
        return y.documentStatus.label.localeCompare(x.documentStatus.label, 'lt');
      return x.documentStatus.label.localeCompare(y.documentStatus.label, 'lt');
    });
  }
  return (
    (stableSort(
      data as unknown as VirsisData[],
      getComparator(order, sortBy)
    ) as unknown as ProvidedDataInst[]) || []
  );
};
