import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Paper, TableBody, Box, makeStyles } from '@material-ui/core';

import { ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { TableCircularProgressRow } from '../../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../../components/TableErrorRow/TableErrorRow';

import { getFilteredData, getTableExportDefinition } from './utility/tableDataUtility';
import { Actions } from './state/actions';
import { State } from './state/reducer';

import { ClassifierHeader } from './ClassifierHeader';
import { ClassifierRow } from './ClassifierRow';
import { TableToolWrapper } from './TableToolWrapper';
import { ClassifierFilters } from './ClassifierFilters';
import { AddNewRecordButton } from '../../../components/TableButtons/AddNewRecordButton';
import PageActionButtons from '../../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import { tableTitleContext } from '../../../pages/institution/ClassifierManagementPage';
import { ScrollXContainer } from '../../../components/ScrollXContainer/ScrollXContainer';

interface Props<TData> {
  actions: Actions<TData>;
  isLoading: boolean;
  error: boolean;
  data: TData[];
}

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    display: 'flex'
  },
  buttonBoxInner: {
    marginLeft: 'auto'
  },
  [theme.breakpoints.down('xs')]: {
    buttonBox: {
      flexWrap: 'wrap',
      gap: 10,

      '& div': {
        margin: 0
      },

      '& button': {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0
      }
    },
    buttonBoxInner: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 10,
      marginBottom: '10px !important',
      width: '100%'
    }
  }
}));

export const ClassifierTableInner = <TData,>({ actions, isLoading, error, data }: Props<TData>) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    columns,
    columnFilters,
    columnSort,
    showFilters,
    tableDefinition,
    columnVisibility,
    pagination: { activePage, rowsInPage }
  } = useSelector((state: State<TData>) => state);

  const filtered = getFilteredData(columnFilters, columns, data);
  const sorted =
    columns
      .find(({ field }) => field === columnSort.field)
      ?.sortFn(filtered, columnSort.direction) || filtered;

  const pageCount = Math.ceil(filtered.length / rowsInPage);
  const paged = sorted.slice(activePage * rowsInPage, activePage * rowsInPage + rowsInPage);

  const createActionText = tableDefinition.actionLabels?.create;

  const tableTitle = useContext(tableTitleContext);

  return (
    <div className="named-data-table">
      <Box className={classes.buttonBox}>
        {tableDefinition.tableActions.create && (
          <AddNewRecordButton
            text={createActionText || 'Pridėti naują įrašą'}
            onClicked={() => dispatch(actions.create())}
          />
        )}

        <Box className={classes.buttonBoxInner}>
          <PageActionButtons
            dontShowDelete
            onDownload={(format, navigationPath) =>
              exportTableData(
                format,
                getTableExportDefinition(tableDefinition, columnVisibility, tableTitle),
                sorted,
                navigationPath
              )
            }
            onPrint={(navigationPath) =>
              printTableData(
                getTableExportDefinition(tableDefinition, columnVisibility, tableTitle),
                sorted,
                navigationPath
              )
            }
          />
        </Box>
      </Box>

      <Paper elevation={0}>
        <div className="CustomizationBar">
          <TableToolsFrame rightSideItem={<TableToolWrapper actions={actions} />} />
        </div>

        <ScrollXContainer>
          <Table aria-label="simple table">
            <ClassifierHeader actions={actions} />

            <TableBody>
              <TableCircularProgressRow isLoading={isLoading} colSpan={columns.length + 1} />

              {error && (
                <TableErrorRow
                  error="Klaida. Nepavyko gauti duomenų"
                  colSpan={columns.length + 1}
                />
              )}

              {!isLoading && !error && showFilters && (
                <ClassifierFilters actions={actions} data={data} />
              )}

              {!isLoading &&
                !error &&
                paged.map((value) => (
                  <ClassifierRow key={Math.random()} data={value} actions={actions} />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={filtered.length}
          pagesCount={pageCount}
          rowsPerPage={rowsInPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={activePage}
          setPage={(value) => dispatch(actions.setPagination({ rowsInPage, activePage: value }))}
          setRowsPerPage={(value) =>
            dispatch(actions.setPagination({ rowsInPage: value, activePage }))
          }
          disabled={filtered.length === 0}
        />
      </Paper>
    </div>
  );
};
