import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import {
  OutletRepresentativesColumnHeaderField,
  OutletRepresentativesDataTableState
} from '../tablesState/representativesTableTypes';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';

export const OutletRepresentativesTableHead: React.FC = () => {
  const {
    state: { representatives: representativesTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const handleSetSortByColumn = (column: OutletRepresentativesColumnHeaderField) => {
    tableDispatch({
      type: 'REPRESENTATIVES_SET_SORT_BY_COLUMN',
      sortBy: column
    });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletRepresentativesDataTableState,
    typeof handleSetSortByColumn,
    OutletRepresentativesColumnHeaderField
  >(handleSetSortByColumn, representativesTableState);

  return (
    <TableHead>
      <TableRow>
        {representativesTableState.columnsDisplayStatus.representativeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Vardas Pavardė"
            columnName="representativeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={representativesTableState}
          />
        )}

        {(representativesTableState.columnsDisplayStatus.validFrom ||
          representativesTableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              representativesTableState.columnsDisplayStatus.validFrom,
              representativesTableState.columnsDisplayStatus.validTo
            ])}
            key="valid"
          >
            <Typography variant="h4">Atsakingas</Typography>
          </TableCell>
        )}

        {representativesTableState.columnsDisplayStatus.representationNotes && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Atsakomybės apimtis ir kitos pastabos"
            columnName="representationNotes"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={representativesTableState}
          />
        )}

        <TableCell rowSpan={2} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {representativesTableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={representativesTableState}
          />
        )}

        {representativesTableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={representativesTableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
