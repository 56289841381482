export interface AnnulmentType {
  annulmentTypeId: number;
  annulmentTypeName: string;
  annulmentTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface AnnulmentTypeInput {
  annulmentTypeId: number | null;
  annulmentTypeName: string | null;
  annulmentTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: AnnulmentTypeInput = {
  annulmentTypeId: null,
  annulmentTypeName: '',
  annulmentTypeNameEn: '',
  validFrom: null,
  validTo: null
};
