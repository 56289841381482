import { useReducer, useContext, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { newEditionCheckReducer } from './editionCheckRecordState/newEditionCheckRecordReducer';
import { initialEditionCheckNewRecordState } from './editionCheckRecordState/initialStateAndTypes';
import {
  EditionCheckDataTableDispatchContext,
  EditionCheckDataTableStateContext
} from '../Context';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { ApplicationState } from '../../../store';
import {
  hideAddEditionCheckRow,
  createEditionCheck,
  resetEditionCheckCreatingState,
  fetchPublicationOutlets,
  resetPublicationOutlets
} from '../../../store/editionChecks/editionChecksActions';
import {
  EditionCheckConclusionType,
  EditionCheckRecord
} from '../../../store/editionChecks/editionChecksTypes';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { EditionCheckFilesInput } from '../../../components/TableInputs/FilesUploadInput/EditionCheckFilesInput';
import {
  joinEditionCheckIntoFormData,
  validateEditionCheckNewRecordState
} from './editionCheckRecordState/editionCheckRowReducerFunctions';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { DocumentNumberInput } from '../../../components/TableInputs/DocumentNumberInput';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { resetSearchVirsState, searchVirsData } from '../../../store/virsis/actions';
import { getOutletOption } from '../../../components/TableInputs/Utilities';
import { fetchMissingPeriods } from '../../../store/outletInfoData/outletInfoDataActions';
import theme from '../../../style/virsisTheme';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { OutletEditionPeriod } from '../../../store/outletInfoData/outletInfoDataTypes';
import { AlertDialog } from '../../../components/Dialogs/AlertDialog';

const useStyles = makeStyles({
  newColumn: {
    paddingLeft: '5px',
    paddingRight: '5px'
  }
});

export const EditionCheckDataTableNewRecord = () => {
  const classes = useStyles();

  const {
    virsis: { virsSearchResults },
    editionData: {
      editionCheckConclusionTypes,
      editionCreated,
      creatingEditionError,
      virsSearchDataWithPublicationOutlets
    },
    classifiers: { enterpriseTypes },
    outletInfoData: { missingPeriodsForOutlet, loadingMissingPeriods }
  } = useSelector((state: ApplicationState) => state);

  const [showNoPublicationOutletsDialog, setShowNoPublicationOutletsDialog] = useState(false);

  const [newRowState, newRowDispatch] = useReducer(
    newEditionCheckReducer,
    initialEditionCheckNewRecordState
  );

  const { state: tableState } = useContext(EditionCheckDataTableStateContext);
  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);

  const reduxDispatch = useDispatch();

  let selectedVirs: VirsSearchData | undefined;
  if (virsSearchResults) {
    [selectedVirs] = virsSearchResults;
  }

  const addFile = (file: File) => {
    if (file) newRowDispatch({ type: 'FILE_ADDED', file });
  };

  const changeFilesCustomName = (filesCustomName: string) =>
    newRowDispatch({
      type: 'FILES_CUSTOM_NAME_CHANGED',
      filesCustomName
    });

  const cancelCreating = useCallback(() => {
    tableDispatch({ type: 'CANCEL_CREATION' });
  }, [tableDispatch]);

  const onCreateRecordConfirmed = () => {
    newRowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateEditionCheckNewRecordState(newRowState).createRecordConfirmationOn) {
      newRowDispatch({ type: 'START_LOADING' });
      const newEditionCheck: EditionCheckRecord = {
        virsId: newRowState.virs.value?.virId,
        checkDate: newRowState.checkDate.value
          ? newRowState.checkDate.value.format('L').toString()
          : null,
        editionCheckDocuments: newRowState.editionCheckDocs,
        editionCheckVips: newRowState.editionCheckOutlets.map((outlet) => ({
          vipId: outlet.outlet.value?.outletId,
          conclusions: outlet.editionCheckConclusions.map((conclusion) => ({
            conclusionId: conclusion.conclusion.value?.editionCheckConclusionId,
            periodId: conclusion.period.value?.periodId
          }))
        }))
      };
      const asFormData = joinEditionCheckIntoFormData(
        newEditionCheck,
        newRowState.addedDocuments.files,
        newRowState.customDocumentsName.value || ''
      );
      reduxDispatch(createEditionCheck(asFormData));
    }
  };

  const addPeriodHandler = (outletIndex: number) => {
    newRowDispatch({
      type: 'ADD_PERIOD_ROW',
      outletIndex
    });
  };

  const reducePeriodHandler = (outletIndex: number, conclusionIndex: number) => {
    if (newRowState.editionCheckOutlets[outletIndex].editionCheckConclusions.length > 1) {
      newRowDispatch({ type: 'REDUCE_PERIOD_ROW', outletIndex, conclusionIndex });
    }
  };

  const addOutletHandler = () => {
    newRowDispatch({ type: 'ADD_OUTLET_ROW' });
  };

  const cancelOutletHandler = (outletIndex: number) => {
    if (newRowState.editionCheckOutlets.length > 1)
      newRowDispatch({ type: 'CANCEL_OUTLET_ROW', outletIndex: outletIndex });
  };

  const totalRowSpan = () => {
    let rowSpan = 0;
    newRowState.editionCheckOutlets.forEach((vip) => {
      rowSpan += vip.editionCheckConclusions.length;
    });
    return rowSpan;
  };

  const rowSpan = totalRowSpan();

  function changeOutlet(outletIndex: number) {
    return (selectedValue: OutletShortData | null) => {
      if (selectedValue) {
        newRowDispatch({
          type: 'EDITION_CHECK_OUTLETS_CHANGED',
          outlet: selectedValue,
          outletIndex
        });
        reduxDispatch(fetchMissingPeriods(selectedValue.outletId));
      }
    };
  }

  function changePeriod(outletIndex: number, conclusionIndex: number) {
    return (selectedValue: OutletEditionPeriod | null) => {
      newRowDispatch({
        type: 'EDITION_CHECK_PERIOD_CHANGED',
        outletIndex,
        conclusionIndex,
        period: selectedValue
      });
    };
  }

  function changeConclusion(outletIndex: number, conclusionIndex: number) {
    return (selectedValue: EditionCheckConclusionType | null) => {
      newRowDispatch({
        type: 'EDITION_CHECK_CONCLUSION_CHANGED',
        outletIndex,
        conclusionIndex,
        conclusion: selectedValue
      });
    };
  }

  const handleFileWrongFormat = () => {
    newRowDispatch({
      type: 'FILE_ADDING_WRONG_FORMAT',
      errorMessage: 'netinkamas dokumento formatas'
    });
  };

  const openSearchDialogHandler = () => {
    newRowDispatch({ type: 'VIRS_INPUT_CLICKED' });
    tableDispatch({ type: 'RESET_COMPANY_CODE' });
  };

  const onAlertClose = () => {
    cancelCreating();
    reduxDispatch(hideAddEditionCheckRow());
    reduxDispatch(resetEditionCheckCreatingState());
    tableDispatch({ type: 'CANCEL_CREATION' });
    reduxDispatch(resetPublicationOutlets());
    reduxDispatch(resetSearchVirsState());
    tableDispatch({ type: 'RESET_COMPANY_CODE' });
    setShowNoPublicationOutletsDialog(false);
  };

  useEffect(() => {
    if (creatingEditionError) newRowDispatch({ type: 'STOP_LOADING' });
  }, [creatingEditionError]);

  useEffect(() => {
    if (!tableState.companyCode) {
      newRowDispatch({ type: 'VIRS_INPUT_CLICKED' });
    }
  }, [tableState.companyCode, newRowDispatch]);

  useEffect(() => {
    if (!loadingMissingPeriods) newRowDispatch({ type: 'PERIODS_LOADED', missingPeriodsForOutlet });
  }, [loadingMissingPeriods, missingPeriodsForOutlet, newRowDispatch]);

  useEffect(() => {
    if (!selectedVirs && tableState.companyCode)
      reduxDispatch(searchVirsData({ personCode: tableState.companyCode, onlySigned: true }));

    if (selectedVirs && tableState.companyCode)
      reduxDispatch(fetchPublicationOutlets(selectedVirs, -1));
  }, [
    virsSearchResults,
    newRowDispatch,
    reduxDispatch,
    selectedVirs,
    tableState.companyCode,
    editionCheckConclusionTypes
  ]);

  useEffect(() => {
    if (virsSearchDataWithPublicationOutlets)
      newRowDispatch({
        type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
        virs: {
          ...virsSearchDataWithPublicationOutlets.virs,
          outlets: virsSearchDataWithPublicationOutlets.publicationOutlets
        },
        conclusionTypes: editionCheckConclusionTypes
      });
  }, [virsSearchDataWithPublicationOutlets, editionCheckConclusionTypes]);

  useEffect(() => {
    if (editionCreated) {
      cancelCreating();
      reduxDispatch(hideAddEditionCheckRow());
      reduxDispatch(resetEditionCheckCreatingState());
      tableDispatch({ type: 'CANCEL_CREATION' });
    }
  }, [reduxDispatch, tableDispatch, editionCreated, cancelCreating]);

  useEffect(() => {
    if (virsSearchDataWithPublicationOutlets?.id === -1) {
      if (virsSearchDataWithPublicationOutlets.publicationOutlets.length) {
        newRowDispatch({
          type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
          virs: {
            ...virsSearchDataWithPublicationOutlets.virs,
            outlets: virsSearchDataWithPublicationOutlets.publicationOutlets
          },
          conclusionTypes: editionCheckConclusionTypes
        });
      } else {
        setShowNoPublicationOutletsDialog(true);
      }
    }
  }, [editionCheckConclusionTypes, virsSearchDataWithPublicationOutlets]);

  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  newRowState.editionCheckOutlets.forEach((outlet, outletIndex) => {
    outlet.editionCheckConclusions.forEach((conclusion, conclusionIndex) => {
      rows.push(
        <TableRow key={rowIndex}>
          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.virsName && (
                <VirsInputCell
                  openSearchDialog={openSearchDialogHandler}
                  state={newRowState.virs}
                  rowSpan={rowSpan}
                />
              )}
              {tableState.columnsDisplayStatus.checkDate && (
                <DateInputImprovedCell
                  state={newRowState.checkDate}
                  setDate={(checkDate: MaterialUiPickersDate | null) =>
                    newRowDispatch({ type: 'CHECK_DATE_CHANGED', checkDate })
                  }
                  rowSpan={rowSpan}
                />
              )}
              {tableState.columnsDisplayStatus.docNr && (
                <TableCell
                  align="left"
                  rowSpan={rowSpan}
                  className={classes.newColumn}
                  style={{
                    backgroundColor:
                      newRowState.customDocumentsName.error || newRowState.addedDocuments.error
                        ? theme.palette.error.light
                        : undefined
                  }}
                >
                  <DocumentNumberInput
                    setValue={changeFilesCustomName}
                    inputState={newRowState.customDocumentsName}
                    isRequired
                  />
                  <EditionCheckFilesInput
                    state={newRowState.addedDocuments}
                    customName={newRowState.customDocumentsName}
                    addFile={addFile}
                    removeFile={(fileId: number) =>
                      newRowDispatch({ type: 'FILE_REMOVED', fileId })
                    }
                    changeCustomName={changeFilesCustomName}
                    setWrongFormatError={handleFileWrongFormat}
                  />
                </TableCell>
              )}
            </>
          )}
          {conclusionIndex === 0 && tableState.columnsDisplayStatus.outletName && (
            <DropdownInputCell
              selectValue={changeOutlet(outletIndex)}
              dropdownState={newRowState.editionCheckOutlets[outletIndex].outlet}
              getOptionSelected={(option, value) =>
                value ? option.outletId === value.outletId : false
              }
              renderOption={getOutletOption}
              getOptionLabel={(option) =>
                `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
              }
              rowSpan={outlet.editionCheckConclusions.length}
              addUnit={addOutletHandler}
              removeUnit={() => cancelOutletHandler(outletIndex)}
              showRemoveButton={rowIndex !== 0}
            />
          )}
          {tableState.columnsDisplayStatus.periodName && (
            <DropdownInputCell
              selectValue={changePeriod(outletIndex, conclusionIndex)}
              dropdownState={
                newRowState.editionCheckOutlets[outletIndex].editionCheckConclusions[
                  conclusionIndex
                ].period
              }
              getOptionLabel={(period) => period.periodName}
              getOptionSelected={(option, value) =>
                value ? option.periodId === value.periodId : false
              }
              addUnit={() => addPeriodHandler(outletIndex)}
              removeUnit={() => reducePeriodHandler(outletIndex, conclusionIndex)}
              showRemoveButton={rowIndex !== 0}
            />
          )}
          {tableState.columnsDisplayStatus.conclusion && (
            <DropdownInputCell
              selectValue={changeConclusion(outletIndex, conclusionIndex)}
              dropdownState={
                newRowState.editionCheckOutlets[outletIndex].editionCheckConclusions[
                  conclusionIndex
                ].conclusion
              }
              getOptionLabel={(conclusion) => conclusion.editionCheckConclusionTypeName}
              getOptionSelected={(option, value) =>
                value ? option.editionCheckConclusionId === value.editionCheckConclusionId : false
              }
            />
          )}
          {rowIndex === 0 && (
            <>
              <TableCell align="left" rowSpan={rowSpan} className={classes.newColumn}>
                <SaveAndCancelActions
                  handleConfirmationYes={onCreateRecordConfirmed}
                  isProcessing={newRowState.loading}
                  handleCancelButtonClick={() => {
                    cancelCreating();
                    reduxDispatch(hideAddEditionCheckRow());
                    newRowDispatch({ type: 'CREATING_CANCELED' });
                    reduxDispatch(resetPublicationOutlets());
                  }}
                />
              </TableCell>
              <TableCell align="left" rowSpan={rowSpan} className={classes.newColumn} />
            </>
          )}
        </TableRow>
      );
      rowIndex++;
    });
  });

  return (
    <>
      {newRowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(newRowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={newRowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={() => newRowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' })}
          closeAndContinueWithVirs={(virs: VirsSearchData) =>
            reduxDispatch(fetchPublicationOutlets(virs, -1))
          }
          disableVirsWithoutOutlets
        />
      )}
      <AlertDialog
        isOpen={showNoPublicationOutletsDialog}
        dialogText="Šis VIRS neturi VIP, kurioms reikėtų tiražo tikrinimo"
        onContinue={onAlertClose}
        onClose={onAlertClose}
        showCloseButton={false}
      />
      {rows}
    </>
  );
};
