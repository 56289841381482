import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow } from '@material-ui/core';
import {
  useInstitutionDataReportTableDispatch,
  useInstitutionDataReportTableState
} from './Context';
import {
  InstitutionDataReportHeaderField,
  institutionDataReportTableColumns
} from './tableState/initialTableStateAndTypes';
import { ApplicationState } from '../../store';
import { InstitutionDataReport } from '../../store/InstitutionDataReports/institutionDataReportsTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

const InstitutionDataReportTableCustomFilter: React.FC = () => {
  const { dispatch: tableDispatch } = useInstitutionDataReportTableDispatch();
  const { state: tableState } = useInstitutionDataReportTableState();

  const handleSetCustomFilter = (
    column: InstitutionDataReportHeaderField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };

  const allData =
    useSelector(
      (state: ApplicationState) => state.institutionDataReportData.institutionDataReportData
    ) || [];

  function getUniqueOptions(name: InstitutionDataReportHeaderField, data: InstitutionDataReport[]) {
    if (data) {
      return Array.from(
        new Set(data.filter((item) => item[name] !== null).map((item) => item[name]))
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {institutionDataReportTableColumns
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
      </TableRow>
    </TableBody>
  );
};
export default InstitutionDataReportTableCustomFilter;
