import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useParametersDataTableDispatch, useParametersDataTableState } from './Context';
import {
  ParametersDataHeaderField,
  ParametersDataTableState
} from './tableState/tableInitialStateAndTypes';

export const ParametersDataTableHead: React.FC = () => {
  const {
    state: { columnsDisplayStatus: display }
  } = useParametersDataTableState();
  const { state: tableState } = useParametersDataTableState();

  const { dispatch } = useParametersDataTableDispatch();

  const handleSetSortByColumn = (column: ParametersDataHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    ParametersDataTableState,
    typeof handleSetSortByColumn,
    ParametersDataHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {display.parameterCode && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Kodas"
            columnName="parameterCode"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.parameterName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Parametro pavadinimas"
            columnName="parameterName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.parameterType && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Parametro tipas"
            columnName="parameterType"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.parameterScope && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Parametro sritis"
            columnName="parameterScope"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.minValue && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Minimali reikšmė"
            columnName="minValue"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {display.maxValue && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Maksimali reikšmė"
            columnName="maxValue"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.isUrl && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="URL požymis"
            columnName="isUrl"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.isRequired && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Privalomas"
            columnName="isRequired"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.measurementUnit && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Matavimo vienetas"
            columnName="measurementUnit"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {display.parameterValue && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Parametro reiškmė"
            columnName="parameterValue"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(display.validFrom || display.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([display.validFrom, display.validTo])}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}

        <TableCell rowSpan={2} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
