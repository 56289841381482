import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import { submitVirsDataRequest, submitVirsDataReset } from '../store/virsis/actions';
import { ApplicationState } from '../store';
import PreviewToSignDocument from './document/PreviewToSignDocument';
import virsisTheme from '../style/virsisTheme';
import DocumentIcon from '../components/Icons/Icons/DocumentIcon';
import { BasicTooltip } from '../components/Tooltips/BasicTooltip';
import { whiteColor } from './ShareholdersTable/constants';
import SignDocumentButton from '../components/PageButtons/SignDocumentButton';
import { getVirsProvidedDataStatusRequest } from '../store/virsProvidedDataStatus/store';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitVirsDataContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 25px',
      borderRadius: '10px 10px 0 0',
      borderBottom: `1px solid ${theme.palette.info.light}`,
      marginTop: 30,
      backgroundColor: whiteColor,
      overflow: 'auto'
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    header: {
      marginLeft: 14
    },
    headerText: {
      color: '#5A5A5A',
      fontWeight: 800
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: virsisTheme.palette.secondary.light
    },
    icon: {
      color: '#9E9E9E',
      width: 15,
      height: 15,
      marginLeft: 7
    }
  })
);

interface Props {
  enterpriseTable?: boolean;
}

const SubmitVirsData: React.FC<Props> = ({ enterpriseTable }) => {
  const dispatch = useDispatch();
  const {
    virsis: { virsData, currentUser, submitVirsDataSuccess },
    documentData: { loadingDocumentValidation, documentValidation },
    virsProvidedDataStatus
  } = useSelector((state: ApplicationState) => state);

  const classes = useStyles();

  const enterpriseTableStyle = enterpriseTable
    ? {
        borderRadius: 10,
        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 30
      }
    : undefined;

  useEffect(() => {
    if (submitVirsDataSuccess) {
      dispatch(getVirsProvidedDataStatusRequest());
    }
  }, [submitVirsDataSuccess, dispatch]);

  const history = useHistory();

  const redirectToTab = () => {
    if (virsProvidedDataStatus.data.enterprisesUnsigned) {
      history.push('/duomenu-perziura-ir-teikimas/veiklos-rusys');
    } else if (virsProvidedDataStatus.data.outletsUnsigned) {
      history.push('/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones');
    } else if (virsProvidedDataStatus.data.shareholdersUnsigned) {
      history.push('/duomenu-perziura-ir-teikimas/dalyviai');
    } else {
      history.push('/duomenu-perziura-ir-teikimas/veiklos-rusys');
    }
  };

  useEffect(() => {
    if (virsProvidedDataStatus.loaded && submitVirsDataSuccess) {
      redirectToTab();
      dispatch(submitVirsDataReset());
    }
  }, [
    virsProvidedDataStatus.data,
    virsProvidedDataStatus.loaded,
    submitVirsDataSuccess,
    history,
    dispatch
  ]);

  if (virsData && currentUser) {
    const handleSubmitDataClick = () => {
      if (virsData && currentUser && !virsData.documentStatus) {
        dispatch(submitVirsDataRequest(currentUser.personId));
      } else {
        redirectToTab();
      }
    };

    const hasCiticalError = documentValidation?.documentErrors.some(
      ({ priority }) => priority === 'E'
    );

    let headerText;
    let subText;

    let submitButtonText = '';
    let submitButtonSecondaryStyle;

    let previewSignDocumentButtonText = '';
    let previewSignDocumentButtonSecondaryStyle;

    let iconColor;

    if (!virsData.documentStatus || virsData.documentStatus === 'ANULIUOTAS') {
      headerText = 'Jūs nesate pradėję pildyti duomenų';
      subText = 'Pradėjus pildyti duomenis bus automatiškai sukuriamas naujas dokumentas';
      submitButtonText = 'Pateikti duomenis';
      iconColor = virsisTheme.palette.info.main;
    } else if (virsData.documentStatus === 'RUOSIAMAS') {
      headerText = 'Jūs turite nepasirašytą el. dokumentą';
      subText = 'Pabaikite pildyti dokumentą ir jį pasirašykite';
      submitButtonText = 'Tęsti duomenų pildymą';
      iconColor = virsisTheme.palette.warning.main;

      if (hasCiticalError) {
        previewSignDocumentButtonText = 'Peržiūrėti';
        previewSignDocumentButtonSecondaryStyle = true;
      } else {
        previewSignDocumentButtonText = 'Peržiūrėti ir pasirašyti';
        submitButtonSecondaryStyle = true;
      }
    } else if (virsData.documentStatus === 'PASIRASYTAS') {
      headerText = 'Visi el. dokumentai pasirašyti';
      subText = 'Naujas dokumentas susikuria pradėjus pildyti duomenis';
      submitButtonText = 'Pateikti naujus duomenis';
      previewSignDocumentButtonText = 'Peržiūrėti duomenis ';
      previewSignDocumentButtonSecondaryStyle = true;
      iconColor = virsisTheme.palette.success.main;
    }

    return (
      <div className={classes.submitVirsDataContainer} style={enterpriseTableStyle}>
        <div className={classes.headerContainer}>
          <DocumentIcon iconColor={iconColor} />
          <div className={classes.header}>
            <Typography variant="h3" className={classes.headerText}>
              {headerText}
            </Typography>
          </div>
          {subText && (
            <BasicTooltip text={subText}>
              <InfoIcon className={classes.icon} />
            </BasicTooltip>
          )}
        </div>
        {loadingDocumentValidation ? (
          <CircularProgress />
        ) : (
          <div style={{ display: 'flex' }}>
            <Box p={1}>
              {previewSignDocumentButtonText && (
                <PreviewToSignDocument
                  text={previewSignDocumentButtonText}
                  secondary={previewSignDocumentButtonSecondaryStyle}
                />
              )}
            </Box>
            <Box p={1}>
              <SignDocumentButton
                text={submitButtonText}
                onClick={handleSubmitDataClick}
                secondary={submitButtonSecondaryStyle}
                showIcon
              />
            </Box>
          </div>
        )}
        <Backdrop className={classes.backdrop} open={virsProvidedDataStatus.loading}>
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    );
  }
  return null;
};

export default SubmitVirsData;
