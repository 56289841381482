import React, { useReducer } from 'react';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
  TableSortLabel
} from '@material-ui/core/';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { VirsSearchData, VirsSearchDataEnterpriseType } from '../../store/virsis/dataTypes';
import {
  FindVirsResultsTableColumnHeader,
  findVirsResultsTableReducer,
  initialFindVirsResultsTableState,
  VirsSearchDataField
} from './findVirsDialogResultsReducerAndTypes';
import {
  getPagesCountGeneric,
  getDataSlicedToPageGeneric,
  getComparator,
  stableSort
} from '../../utils/tableDataFunctions';
import { VirsisData, ROWS_PER_PAGE_OPTIONS, SortOrder } from '../../utils/tableTypes';
import { TablePagination } from '../TablePagination/TablePagination';
import { OutletShortData } from '../../store/outlets/outletsTypes';
import { useSearchStyles } from '../../style/searchStyles';

const columns: FindVirsResultsTableColumnHeader[] = [
  {
    id: 'personCode',
    label: 'Įmonės kodas'
  },
  {
    id: 'personFullName',
    label: 'Pavadinimas'
  },
  {
    id: 'enterpriseTypes',
    label: 'VIRS veiklos rūšis'
  },
  {
    id: 'outlets',
    label: 'VIP pavadinimas'
  }
];

interface Props {
  virsSearchResults: VirsSearchData[];
  selectedVirs?: VirsSearchData;
  selectVirs: (virs: VirsSearchData) => void;
  disableVirsWithoutOutlets: boolean;
}

export function getSortedVirsSearchData(
  data: VirsSearchData[],
  order: SortOrder,
  sortBy: string
): VirsSearchData[] {
  if (sortBy === 'outlets') {
    return data.map((record) => {
      if (record.outlets) {
        const sortedOutlets = stableSort<OutletShortData>(
          record.outlets,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'outletName')
        );
        return { ...record, outlets: sortedOutlets };
      }
      return record;
    });
  }
  if (sortBy === 'enterpriseTypes') {
    return data.map((record) => {
      if (record.enterpriseTypes) {
        const sortedEnterpriseTypes = stableSort<VirsSearchDataEnterpriseType>(
          record.enterpriseTypes,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'enterpriseTypeName')
        );
        return { ...record, enterpriseTypes: sortedEnterpriseTypes };
      }
      return record;
    });
  }
  return (
    (stableSort(
      data as unknown as VirsisData[],
      getComparator(order, sortBy)
    ) as unknown as VirsSearchData[]) || []
  );
}

export const FindVirsDialogResults: React.FC<Props> = ({
  virsSearchResults,
  selectedVirs,
  selectVirs,
  disableVirsWithoutOutlets
}) => {
  const [tableState, tableDispatch] = useReducer(
    findVirsResultsTableReducer,
    initialFindVirsResultsTableState
  );
  const classes = useSearchStyles();

  const createSortHandler = (column: VirsSearchDataField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  function setPage(page: number): void {
    tableDispatch({ type: 'PAGE_SET', page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    tableDispatch({ type: 'ROWS_PER_PAGE_SET', rowsPerPage });
  }

  const pagesCount = getPagesCountGeneric(virsSearchResults.length, tableState.rowsPerPage);
  const sortedData = getSortedVirsSearchData(
    virsSearchResults,
    tableState.order,
    tableState.sortBy
  );
  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    tableState.page,
    tableState.rowsPerPage
  ) as unknown as VirsSearchData[];

  return (
    <>
      <Typography
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          fontWeight: 'bolder'
        }}
      >
        Paieškos rezultatai
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((header) => (
                <TableCell
                  key={header.id}
                  sortDirection={tableState.sortBy === header.id ? tableState.order : false}
                  align="left"
                  variant="head"
                  className="TableCellSortable"
                  onClick={() => {
                    createSortHandler(header.id);
                  }}
                >
                  <TableSortLabel
                    active
                    direction={tableState.sortBy === header.id ? tableState.order : 'asc'}
                    IconComponent={tableState.sortBy === header.id ? ActiveSortIcon : SortIcon}
                  >
                    <Typography variant="h4">{header.label}</Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedData.map((virs) => {
              const punishable =
                virs.hasActiveData &&
                (disableVirsWithoutOutlets ? virs.outlets.length !== 0 : true);
              return (
                <TableRow
                  key={`${virs.virId}${virs.personCode}`}
                  style={{
                    backgroundColor: punishable ? undefined : 'lightgrey',
                    fontStyle: punishable ? undefined : 'italic'
                  }}
                >
                  <TableCell align="left">{virs.personCode}</TableCell>
                  <TableCell align="left">
                    {virs.hasActiveData ? (
                      virs.personFullName
                    ) : (
                      <>
                        <div>{virs.personFullName}</div>
                        <Typography>nesukurtas</Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {virs.enterpriseTypes.map((type) => (
                      <div key={type.enterpriseTypeId}>{type.enterpriseTypeName}</div>
                    ))}
                  </TableCell>
                  <TableCell align="left">
                    {virs.outlets.map((outlet) => (
                      <div key={outlet.outletId}>{outlet.outletName}</div>
                    ))}
                  </TableCell>
                  <TableCell align="left">
                    {punishable && (
                      <ToggleButton
                        classes={{
                          selected: classes.selected,
                          root: classes.root
                        }}
                        selected={selectedVirs ? selectedVirs.virId === virs.virId : false}
                        value="select-virs"
                        onClick={() => selectVirs(virs)}
                      >
                        {selectedVirs?.virId === virs.virId ? 'Pasirinktas' : 'Pasirinkti'}
                      </ToggleButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          recordsCount={virsSearchResults.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          disabled={virsSearchResults.length === 0}
        />
      </TableContainer>
    </>
  );
};
