import { OptionedFormState } from '../../components/FormikFields/GenericFormikWrappers/types';
import { VirsSearchData } from '../../store/virsis/dataTypes';
import { NaturalPersonInVirsis } from '../../components/FindNaturalPersonInVirsis/Utilities';

export interface ReportInput {
  report: string | null;
  virsName: string | null | undefined;
  virsCode: string | null | undefined;
  fromDate: Date | null;
  todayReport: boolean;
  toDate: Date | null;
  dataBlock: string | null;
  sortByColumn: string | null;
  columnFilter: string | null;
  includeActive: boolean;
  includeInactive: boolean;
  includeAnnulled: boolean;
  detailedReport: boolean;
  summarizedReport: boolean;
  fileFormat: string | null;
  personCodeFilter: string | null | undefined;
  personFirstNameFilter: string | null | undefined;
  personLastNameFilter: string | null | undefined;
  personBirthDateFilter: Date | null;
  filterEnabled: boolean;
}

export interface FormOption {
  value: string;
  label: string;
}

export interface ReportSettings {
  timeFilter: boolean;
  virsFilter: boolean;
  virsStateFilter: boolean;
  dataBlockFilter: boolean;
  columnFilter: boolean;
  dataStateFilter: boolean;
  personCodeFilter: boolean;
  personFirstNameFilter: boolean;
  personLastNameFilter: boolean;
  personBirthDateFilter: boolean;
  reportType: boolean;
  sorting: boolean;
}

export const initialValues: ReportInput = {
  report: null,
  virsName: null,
  virsCode: null,
  dataBlock: null,
  sortByColumn: null,
  columnFilter: null,
  fileFormat: null,
  fromDate: null,
  toDate: null,
  todayReport: true,
  detailedReport: true,
  includeActive: true,
  includeAnnulled: false,
  includeInactive: false,
  summarizedReport: false,
  personCodeFilter: null,
  personFirstNameFilter: null,
  personLastNameFilter: null,
  personBirthDateFilter: null,
  filterEnabled: false
};

export const initialReportSettings: ReportSettings = {
  timeFilter: false,
  virsFilter: false,
  virsStateFilter: false,
  dataBlockFilter: false,
  columnFilter: false,
  dataStateFilter: false,
  personCodeFilter: false,
  personFirstNameFilter: false,
  personLastNameFilter: false,
  personBirthDateFilter: false,
  reportType: false,
  sorting: false
};

export interface FormOptions {
  reportSettings: ReportSettings;
  reportOptions: FormOption[];
  dataBlockOptions: FormOption[];
  sortOptions: FormOption[];
  columnOptions: FormOption[];
  fileOptions: FormOption[];
}

export type ReportFormState = OptionedFormState<ReportInput, FormOptions> & {
  reportFileUrl?: string;
  selectedVirs?: VirsSearchData;
  selectedPerson?: NaturalPersonInVirsis;
  showVirsSearch: boolean;
  showPersonSearch: boolean;
};
