import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { Roles } from '../store/virsis/dataTypes';

interface Props {
  roles: Roles[];
}
const AllowedTo: React.FC<Props> = ({ children, roles }: PropsWithChildren<any>) => {
  const { currentUser } = useSelector((state: ApplicationState) => state.virsis);
  if (currentUser && roles.some((role: Roles) => currentUser.authorities.includes(Roles[role]))) {
    return children;
  }
  return null;
};

export default AllowedTo;
