import React, { useEffect, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { ShareholdersTableFilterFields } from '../../../MainTable/tableState/tableInitialStateAndTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { currentDate } from '../../../utilityFunctions/sharedFunctions';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useShareholdersTableState } from '../../../MainTable/ShareholdersTableWithContext';
import moment from 'moment';
import { DateInput, FilterDatePickerState } from './DateInput';
import {
  MAXIMAL_RECORD_DATE_FUTURE,
  MINIMAL_OUTLET_ESTABLISHED_DATE,
  validateMonthDateInput,
  validateOptionalDateImproved,
  validateDateYear
} from '../../../../../utils/InputValueFunctions';

const useStyles = makeStyles((theme) => ({
  itemLabel: {
    width: 100,
    textAlign: 'right'
  },
  itemDateInput: {
    flexGrow: 1,
    marginLeft: 12,
    textAlign: 'left'
  },
  disabledLabel: {
    color: theme.palette.info.main
  }
}));

interface Props {
  text: string;
  filterName: ShareholdersTableFilterFields;
  setFilter: (column: ShareholdersTableFilterFields, value: string | null) => void;
  calendarDisplayProps: any;
  disabled?: boolean;
  enabledDays?: string[];
  marginBottom?: boolean;
}

const DateInputContainer: React.FC<Props> = ({
  text,
  filterName,
  setFilter,
  disabled,
  enabledDays,
  marginBottom,
  calendarDisplayProps
}) => {
  const classes = useStyles();
  const {
    shareholdersData: { shareholdersData }
  } = useSelector((state: ApplicationState) => state);
  const {
    state: {
      tableId,
      resetFilter,
      customFilter: { shareholderPeriodDateFrom, shareholderPeriodDateTo }
    }
  } = useShareholdersTableState();

  const [periodFilterFromDate] = shareholderPeriodDateFrom;
  const [periodFilterToDate] = shareholderPeriodDateTo;

  const shouldDisableDate = (date: MaterialUiPickersDate) => {
    if (enabledDays) {
      return date ? !enabledDays.includes(date.format('YYYY-MM-DD')) : false;
    } else {
      return false;
    }
  };

  const maxDate = periodFilterFromDate
    ? moment(periodFilterFromDate).add(1, 'M').format('YYYY-MM-DD')
    : currentDate;
  const minDate = periodFilterToDate
    ? moment(periodFilterToDate).subtract(1, 'M').format('YYYY-MM-DD')
    : shareholdersData?.virsStartDate;

  const setCustomMaxDate = () => {
    if (tableId === 'day') {
      return maxDate <= currentDate ? maxDate : currentDate;
    }
    return shareholdersData?.virsEndDate || currentDate;
  };

  const setCustomMinDate = () => {
    if (tableId === 'day') {
      return minDate;
    }
    return shareholdersData?.virsStartDate;
  };

  const [inputState, setInputState] = useState<FilterDatePickerState>({
    value: null,
    error: false,
    validated: false
  });

  useEffect(() => {
    if (resetFilter) {
      setInputState({
        ...inputState,
        value: null
      });
    }
  }, [resetFilter]);

  const setDate = (newDate: MaterialUiPickersDate | null) => {
    let dateState = {
      maxDates: [MAXIMAL_RECORD_DATE_FUTURE],
      minDates: [MINIMAL_OUTLET_ESTABLISHED_DATE],
      value: newDate,
      error: false,
      validated: false
    };
    if (calendarDisplayProps.format === 'YYYY-MM-DD') {
      dateState = validateOptionalDateImproved(dateState);
      setInputState(dateState);
    }
    if (calendarDisplayProps.format === 'YYYY-MM') {
      dateState = validateMonthDateInput(dateState);
      setInputState(dateState);
    }
    if (calendarDisplayProps.format === 'YYYY') {
      dateState = validateDateYear(dateState);
      setInputState(dateState);
    }
    if (!dateState.error) setFilter(filterName, newDate ? newDate.format('YYYY-MM-DD') : null);
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={marginBottom ? 1 : undefined}>
        <Typography
          variant="body2"
          className={`${classes.itemLabel} ${disabled ? classes.disabledLabel : undefined}`}
        >
          {text}
        </Typography>
        <div className={classes.itemDateInput}>
          <DateInput
            state={inputState}
            disabled={disabled}
            shouldDisableDate={shouldDisableDate}
            customMaxDate={setCustomMaxDate()}
            customMinDate={setCustomMinDate()}
            setDate={setDate}
            calendarDisplayProps={calendarDisplayProps}
          />
        </div>
      </Box>
    </>
  );
};
export default DateInputContainer;
