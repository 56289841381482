import { PaginationParams, SortOrder } from '../../utils/tableTypes';
import { Order } from '../../components/TableTypes/TableTypes';
import {
  PaymentDataCustomFilter,
  PaymentDataHeaderField
} from '../../containers/PaymentTableInst/tableState/paymentTableInitialStateAndTypes';

export interface PaymentsTablePageRequest extends PaginationParams {
  filterVirsId: null | number;
  filterDecisionDate: null | string;
  filterDocumentNumber: null | string;
  filterOutletId: null | number;
  filterPaymentAmount: null | number;
  filterValidFrom: null | string;
  filterValidTo: null | string;
  sortingVirsName: SortOrder | null;
  sortingDecisionDate: SortOrder | null;
  sortingDocumentNumber: SortOrder | null;
  sortingOutletName: SortOrder | null;
  sortingPaymentAmount: SortOrder | null;
  sortingValidFrom: SortOrder | null;
  sortingValidTo: SortOrder | null;
}

export interface PaymentsTableDataState {
  sortBy: PaymentDataHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  customFilter: PaymentDataCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
}

export const initialPaymentsTableDataState: PaymentsTableDataState = {
  customFilter: {
    virsName: [],
    decisionDate: [],
    paymentDocuments: [],
    paymentOutlets: [],
    paymentAmount: [],
    validFrom: [],
    validTo: []
  },
  order: 'desc',
  page: 0,
  rowsPerPage: 10,
  showStatusNotSigned: false,
  showStatusOutdated: false,
  showStatusRelevant: false,
  sortBy: 'decisionDate',
  statusNotSignedFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  statusRelevantFilterEnabled: false
};
