import React from 'react';
import { useSelector } from 'react-redux';
import { AdColumnHeader, AdColumnHeaderField } from '../tablesState/adPrintingTableTypes';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../../components/TableStatusFilters/TableStatusFilters';
import { getSubsystem } from '../../../utils/roleHelper';
import { ApplicationState } from '../../../store';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';

interface Props {
  columnNames: AdColumnHeader[];
  tableName: OutletTableName;
}

const AdTableCustomizationBar: React.FC<Props> = ({ columnNames, tableName }) => {
  const {
    state: { adprinting: adPrintingTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tablesDispatch } = useOutletInfoTablesDispatch();

  const { currentUser } = useSelector((appState: ApplicationState) => appState.virsis);

  const toggleDisplayCustomFilter = () => {
    tablesDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED', table: tableName });
  };

  const handleSetSortByColumn = (column: AdColumnHeaderField) => {
    tablesDispatch({ type: 'AD_PRINTING_SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: AdColumnHeaderField) => {
    tablesDispatch({ type: 'AD_PRINTING_COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    tablesDispatch({ type: 'SHOW_STATUS_UNSIGNED_TOGGLED', table: tableName });
  };

  const toggleRelevantFilter = () => {
    tablesDispatch({ type: 'SHOW_STATUS_RELEVANT_TOGGLED', table: tableName });
  };

  const toggleOutdatedFilter = () => {
    tablesDispatch({ type: 'SHOW_STATUS_OUTDATED_TOGGLED', table: tableName });
  };

  const subsystem = getSubsystem(currentUser);

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            dontShowNotSigned={subsystem === 'LRTK' || subsystem === 'ZEIT'}
            notSignedFilterDisabled={!adPrintingTableState.statusNotSignedFilterEnabled}
            notSignedFilterOn={adPrintingTableState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterDisabled={!adPrintingTableState.statusRelevantFilterEnabled}
            relevantFilterOn={adPrintingTableState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterDisabled={!adPrintingTableState.statusOutdatedFilterEnabled}
            outdatedFilterOn={adPrintingTableState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={adPrintingTableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={adPrintingTableState.order}
            sortBy={adPrintingTableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={adPrintingTableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default AdTableCustomizationBar;
