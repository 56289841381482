import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';

export const initialOutletRepresentativesTableState: OutletRepresentativesDataTableState = {
  order: 'desc',
  sortBy: 'validFrom',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    representativeName: true,
    validFrom: true,
    validTo: true,
    representationNotes: true
  },
  customFilterOn: false,
  customFilter: {
    representativeName: [],
    validFrom: [],
    validTo: [],
    representationNotes: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: true,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showNewRepDialog: false
};

export interface OutletRepresentativesDataTableState {
  sortBy: OutletRepresentativesColumnHeaderField;
  order: SortOrder;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: OutletRepresentativesColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: OutletRepresentativesTableCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showNewRepDialog: boolean;
}

export type OutletRepresentativesColumnHeader =
  ColumnHeader<OutletRepresentativesColumnHeaderField>;

export type OutletRepresentativesColumnHeaderField =
  | 'representativeName'
  | 'validFrom'
  | 'validTo'
  | 'representationNotes';

export type OutletRepresentativesColumnsDisplayStatus = {
  representativeName: boolean;
  validFrom: boolean;
  validTo: boolean;
  representationNotes: boolean;
};

export type OutletRepresentativesTableCustomFilter = {
  representativeName: string[];
  validFrom: string[];
  validTo: string[];
  representationNotes: string[];
};

export type OutletRepresentativesTableAction =
  | {
      type: 'REPRESENTATIVES_SET_SORT_BY_COLUMN';
      sortBy: OutletRepresentativesColumnHeaderField;
    }
  | {
      type: 'REPRESENTATIVES_SET_CUSTOM_FILTER';
      filterBy: OutletRepresentativesColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'REPRESENTATIVES_TOGGLE_DISPLAY_COLUMN';
      column: OutletRepresentativesColumnHeaderField;
    }
  | { type: 'REPRESENTATIVES_NEW_REPRESENTATIVE_DIALOG_OPENED' }
  | { type: 'REPRESENTATIVES_NEW_REPRESENTATIVE_DIALOG_CLOSED' };

export const outletRepresentativesTableColumns: OutletRepresentativesColumnHeader[] = [
  {
    id: 'representativeName',
    label: 'Vardas, Pavardė'
  },
  {
    id: 'validFrom',
    label: 'Atsakingas nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Atsakingas iki',
    type: 'date'
  },
  {
    id: 'representationNotes',
    label: 'Atsakomybės apimtis ir kitos pastabos'
  }
];
