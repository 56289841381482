import React from 'react';
import BlockIcon from '@material-ui/icons/Block';
import { DocumentActionButton } from './DocumentActionButton';
import { TooltipOnTableActionButton } from '../../Tooltips/TooltipOnTableActionButton';
import { makeStyles } from '@material-ui/core';
import { ErrorType } from '../../../store/virsis/dataTypes';

interface Props {
  annulmentDisablementReason: string | null;
  onClick?: () => void;
}

const useStyles = makeStyles({
  inlineFlex: {
    display: 'inline-flex'
  }
});

export const AnnulDocumentButton: React.FC<Props> = ({ annulmentDisablementReason, onClick }) => {
  const { inlineFlex } = useStyles();
  return (
    <TooltipOnTableActionButton
      text={
        annulmentDisablementReason
          ? annulmentDisablementReason
          : 'Įrašo anuliavimas. Patvirtinus įrašo anuliavimą - įrašas anuliuojamas'
      }
      errorType={ErrorType.CRITICAL}
    >
      <span className={inlineFlex}>
        <DocumentActionButton
          onClick={onClick}
          IconComponent={BlockIcon}
          text="Anuliuoti"
          disabled={!!annulmentDisablementReason}
          dangerButton
        />
      </span>
    </TooltipOnTableActionButton>
  );
};
