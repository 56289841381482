import {
  editionCheckDataTableColumns,
  EditionCheckDataTableColumnsDisplayStatus,
  EditionCheckDataTableCustomFilter,
  EditionCheckDataTableState,
  EditionCheckDataVirsTableField
} from './tableTypesAndActions';
import {
  EditionCheckConclusions,
  EditionCheckDataVirs,
  EditionCheckOutlets
} from '../../../store/editionChecks/editionChecksTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function updateEditCheckDataCustomFilter(
  filter: EditionCheckDataTableCustomFilter,
  filterBy: EditionCheckDataVirsTableField,
  value: string | null
): EditionCheckDataTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function calculateEditionCheckColSpan(
  displayStatus: EditionCheckDataTableColumnsDisplayStatus
): number {
  const displayingColumns = [displayStatus.checkDate, displayStatus.docNr];
  return displayingColumns.filter((value) => value).length;
}

export function filterAndSortTableData(
  data: EditionCheckDataVirs[],
  tableState: EditionCheckDataTableState
): EditionCheckDataVirs[] {
  if (data.length === 0) {
    return [];
  }
  const filters: Filter<EditionCheckDataVirs>[] = [
    getStringMappedColumnFilter(tableState.customFilter.checkDate, ({ checkDate }) => [checkDate]),
    getStringMappedColumnFilter(tableState.customFilter.checkedBy, ({ checkedBy }) => [checkedBy]),
    getStringMappedColumnFilter(tableState.customFilter.docNr, ({ editionCheckDocs }) =>
      editionCheckDocs.map(({ docNr }) => docNr)
    ),
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ editionCheckOutlets }) =>
      editionCheckOutlets.map(({ outletName }) => outletName)
    ),
    getStringMappedColumnFilter(tableState.customFilter.periodName, ({ editionCheckOutlets }) =>
      editionCheckOutlets.flatMap(({ editionCheckConclusions }) =>
        editionCheckConclusions.map(({ periodName }) => periodName)
      )
    ),
    getStringMappedColumnFilter(tableState.customFilter.conclusion, ({ editionCheckOutlets }) =>
      editionCheckOutlets.flatMap(({ editionCheckConclusions }) =>
        editionCheckConclusions.map(({ conclusion }) => conclusion)
      )
    )
  ];

  const nestedVipFilters: Filter<EditionCheckOutlets>[] = [
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ outletName: vipName }) => [
      vipName
    ])
  ];

  const nestedConclusionFilters: Filter<EditionCheckConclusions>[] = [
    getStringMappedColumnFilter(tableState.customFilter.conclusion, ({ conclusion }) => [
      conclusion
    ]),
    getStringMappedColumnFilter(tableState.customFilter.periodName, ({ periodName }) => [
      periodName
    ])
  ];

  const filtered = filterData(data, filters).map((entry) => ({
    ...entry,
    editionCheckVips: filterData(entry.editionCheckOutlets, nestedVipFilters).map((outlet) => ({
      ...outlet,
      editionCheckConclusions: filterData(outlet.editionCheckConclusions, nestedConclusionFilters)
    }))
  }));
  const getSortBy = (sortBy: EditionCheckDataVirsTableField): string => {
    switch (sortBy) {
      case 'outletName':
        return 'editionCheckOutlets.outletName';
      case 'docNr':
        return 'editionCheckDocs.docNr';
      case 'conclusion':
        return 'editionCheckOutlets.editionCheckConclusions.conclusion';
      case 'periodName':
        return 'editionCheckOutlets.editionCheckConclusions.periodName';
      default:
        return sortBy;
    }
  };

  const sortBy = getSortBy(tableState.sortBy);

  return stableSortWrapped(filtered, tableState.order, sortBy);
}

export function getTableExportDefinition(
  tableState: EditionCheckDataTableState
): TableExportDefinition<EditionCheckDataVirs> {
  const { mappedValueColumn } = getColumnBuilder<
    EditionCheckDataVirs,
    EditionCheckDataVirsTableField
  >(editionCheckDataTableColumns, tableState.columnsDisplayStatus);

  return {
    title: 'Tiražo patikrinimas',
    columnGroups: [
      {
        header: 'Tiražo tikrinimo',
        columns: [
          mappedValueColumn(
            'checkDate',
            ({ checkDate, editionCheckOutlets }) => [
              {
                values: [checkDate],
                style: {
                  rowSpan: editionCheckOutlets.flatMap(
                    ({ editionCheckConclusions }) => editionCheckConclusions
                  ).length
                }
              }
            ],
            { width: 20 }
          ),
          mappedValueColumn(
            'docNr',
            ({ editionCheckDocs, editionCheckOutlets }) => [
              {
                values: editionCheckDocs.map(({ docNr }) => docNr),
                style: {
                  rowSpan: editionCheckOutlets.flatMap(
                    ({ editionCheckConclusions }) => editionCheckConclusions
                  ).length
                }
              }
            ],
            { width: 20 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'outletName',
            ({ editionCheckOutlets }) =>
              editionCheckOutlets.map(({ outletName, editionCheckConclusions }) => ({
                values: [outletName],
                style: { rowSpan: editionCheckConclusions.length }
              })),
            { width: 45 }
          ),
          mappedValueColumn(
            'periodName',
            ({ editionCheckOutlets }) =>
              editionCheckOutlets
                .flatMap(({ editionCheckConclusions }) => editionCheckConclusions)
                .map(({ periodName }) => ({ values: [periodName] })),
            { width: 30 }
          ),
          mappedValueColumn(
            'conclusion',
            ({ editionCheckOutlets }) =>
              editionCheckOutlets
                .flatMap(({ editionCheckConclusions }) => editionCheckConclusions)
                .map(({ conclusion }) => ({ values: [conclusion] })),
            { width: 30 }
          ),
          mappedValueColumn(
            'checkedBy',
            ({ checkedBy, editionCheckOutlets }) => [
              {
                values: [checkedBy],
                style: {
                  rowSpan: editionCheckOutlets.flatMap(
                    ({ editionCheckConclusions }) => editionCheckConclusions
                  ).length
                }
              }
            ],
            { width: 30 }
          )
        ]
      }
    ]
  };
}
