import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as virsisActions from '../../store/virsis/actions';
import { ApplicationState } from '../../store';

const LoginPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser, authoriseSuccess } = useSelector((state: ApplicationState) => state.virsis);
  useEffect(() => {
    //const devEnvironment = false;
    const devEnvironment = process.env.REACT_APP_DEV;
    if (devEnvironment) {
      const params = new URLSearchParams(location.search);
      const enc = params.get('enc');
      if (!currentUser && enc) {
        dispatch(virsisActions.authoriseRequest(enc));
      }
    }
  }, [authoriseSuccess, currentUser, dispatch, location.search]);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default LoginPage;
