import React, { useEffect } from 'react';
import { Paper, Table, Typography, TableBody } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { MessagesTableCustomizationBar } from './TableCustomization';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { pageTableData } from '../../utils/tableDataFunctions';
import { filterAndSortMessages } from './tableState/tableStateFunctions';
import { useMessagesTableState, useMessagesTableDispatch } from './Context';
import MessagesTableFilterRow from './FilterRow';
import { MessageTableRow } from './TableRow';

import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { UserMessage, UserMessageRecord } from '../../store/userMessages/userMessageTypes';
import { MESSAGE_TABLE_COLUMN_HEADERS } from './tableState/tableInitialStateAndTypes';
import { UserMessageDialog } from '../../components/UserMessageDialog/UserMessageDialog';
import {
  markMessagesRead,
  removeUserMessages,
  resetUserMessageRemovingState
} from '../../store/userMessages/userMessagesActions';
import { ApplicationState } from '../../store';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';

export const MessagesTable: React.FC = () => {
  const { state: tableState } = useMessagesTableState();
  const { dispatch: tableDispatch } = useMessagesTableDispatch();

  const {
    virsis: { currentUser },
    userMessages: {
      userMessages,
      loadingUserMessages,
      userMessagesError,
      removingUserMessages,
      isUserMessagesRemoved,
      removingUserMessagesError,
      isUserMessagesMarkedRead,
      isUserMessagesMarkedUnread
    }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const toggleMessageCheck = (message: UserMessage) => {
    return tableState.selectedMessages.find((msg) => msg.messageId === message.messageId)
      ? () =>
          tableDispatch({
            type: 'MESSAGE_UNSELECTED',
            message
          })
      : () =>
          tableDispatch({
            type: 'MESSAGE_SELECTED',
            message
          });
  };

  function viewInDialog(message: UserMessage) {
    return () =>
      tableDispatch({
        type: 'MESSAGE_DIALOG_OPENED',
        message
      });
  }

  function closeDialog(message: UserMessage) {
    tableDispatch({
      type: 'MESSAGE_DIALOG_CLOSED'
    });

    if (!message.read) {
      reduxDispatch(
        markMessagesRead([
          {
            messageId: message.messageId,
            messageStatusId: message.messageStatusId
          }
        ])
      );
    }
  }

  const filteredData = filterAndSortMessages(tableState, userMessages || []);
  const { page: dataPage, pagesCount } = pageTableData(tableState, filteredData);

  const isMessageChecked = (message: UserMessage): boolean => {
    return !!tableState.selectedMessages.find((msg) => msg.messageId === message.messageId);
  };

  function removeSelectedMessage() {
    if (tableState.messageToView) {
      const { messageId, messageStatusId } = tableState.messageToView;
      const messages: UserMessageRecord[] = [
        {
          messageId,
          messageStatusId
        }
      ];
      reduxDispatch(removeUserMessages(messages));
    }
  }

  function closeRemoveConfirmationOnError() {
    reduxDispatch(resetUserMessageRemovingState());
  }

  useEffect(() => {
    tableDispatch({
      type: 'RESET_AFTER_ACTION_WITH_MESSAGES_SUCCESSFUL'
    });
  }, [tableDispatch, isUserMessagesRemoved, isUserMessagesMarkedRead, isUserMessagesMarkedUnread]);

  return (
    <>
      <div className="table-title">
        <Typography variant="h2">Pranešimai</Typography>
      </div>
      <Paper elevation={0}>
        {tableState.messageToView && (
          <UserMessageDialog
            message={tableState.messageToView}
            closeDialog={closeDialog}
            removeMessage={removeSelectedMessage}
            isProcessing={removingUserMessages}
            error={removingUserMessagesError}
            onRemoveError={closeRemoveConfirmationOnError}
            currentUser={currentUser}
          />
        )}
        <>
          <MessagesTableCustomizationBar
            columnNames={MESSAGE_TABLE_COLUMN_HEADERS}
            allPageMessages={dataPage}
            hasMessages={!!userMessages?.length}
          />

          <ScrollXContainer>
            <Table>
              {tableState.customFilterOn && (
                <MessagesTableFilterRow
                  columnNames={MESSAGE_TABLE_COLUMN_HEADERS}
                  allData={userMessages || []}
                />
              )}
              <TableBody>
                <TableCircularProgressRow
                  isLoading={loadingUserMessages && !userMessagesError}
                  colSpan={8}
                />

                <TableErrorRow
                  error={userMessagesError && 'Klaida. Nepavyko gauti žinučių'}
                  colSpan={8}
                />

                {!loadingUserMessages &&
                  !userMessagesError &&
                  dataPage.map((message) => (
                    <MessageTableRow
                      key={`${message.messageId}${message.messageStatusId}`}
                      record={message}
                      viewInDialog={viewInDialog(message)}
                      checked={isMessageChecked(message)}
                      checkMessage={toggleMessageCheck(message)}
                    />
                  ))}
              </TableBody>
            </Table>
          </ScrollXContainer>

          <TablePagination
            recordsCount={filteredData.length}
            pagesCount={pagesCount}
            rowsPerPage={tableState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={filteredData.length === 0}
          />
        </>
      </Paper>
    </>
  );
};
