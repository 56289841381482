import {
  mapIntoStateShareholders,
  getFilteredByLevel,
  removeShareholderWithChildrenRecursively,
  getFirstLevelShareholdersWithPreextended,
  setGroupAsExtended,
  addChildrenOfShareholderToShowingList,
  addChildrenOfShareholdersToShowingList,
  setGroupAsClosedAndRemoveShareholdersRecursively
} from './treeFunctions';
import { VirsTreeAction, VirsTreeState } from './treeTypes';

export const virsTreeReducer = (state: VirsTreeState, action: VirsTreeAction): VirsTreeState => {
  switch (action.type) {
    case 'DATA_RECEIVED': {
      const shareholders = mapIntoStateShareholders(action.data.shareholders);
      const firstLevelOrPreextended = getFirstLevelShareholdersWithPreextended(
        shareholders,
        undefined
      );

      return {
        ...state,
        allShareholders: shareholders,
        showingShareholders: firstLevelOrPreextended,
        shareType: action.data.shareTypeName || 'Akcijų / įnašų dalis'
      };
    }

    case 'EXPAND_TREE': {
      return {
        ...state,
        showingShareholders: state.allShareholders.map((sh) => {
          if (sh.shareholderGroupId) {
            return { ...sh, showingGroupExtended: true };
          }
          return sh;
        })
      };
    }

    case 'COLLAPSE_TREE':
      return {
        ...state,
        showingShareholders: getFilteredByLevel(state.allShareholders, 1)
      };

    case 'SHOW_UP_TO_LEVEL': {
      return {
        ...state,
        showingShareholders: getFilteredByLevel(state.allShareholders, action.level)
      };
    }

    case 'SHOW_SHAREHOLDERS_CHILDREN': {
      return {
        ...state,
        showingShareholders: addChildrenOfShareholderToShowingList(
          state.allShareholders,
          action.shareholder,
          state.showingShareholders
        )
      };
    }

    case 'SHOW_GROUP_PERSONS_CHILDREN': {
      return {
        ...state,
        showingShareholders: addChildrenOfShareholdersToShowingList(
          state.allShareholders,
          action.shareholders,
          state.showingShareholders
        )
      };
    }

    case 'HIDE_SHAREHOLDER': {
      return {
        ...state,
        showingShareholders:
          action.shareholder.shareholderLevel > 1
            ? removeShareholderWithChildrenRecursively(
                state.showingShareholders,
                action.shareholder
              )
            : state.showingShareholders
      };
    }

    case 'SHOW_GROUP_LIST': {
      return {
        ...state,
        showingShareholders: setGroupAsExtended(state.showingShareholders, action.groupId)
      };
    }

    case 'HIDE_GROUP_LIST': {
      return {
        ...state,
        showingShareholders: setGroupAsClosedAndRemoveShareholdersRecursively(
          state.showingShareholders,
          action.group
        )
      };
    }

    default:
      return state;
  }
};
