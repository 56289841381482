import { CircularProgress, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

type Props = {
  scale?: number;
  documentPath: string;
  page?: number;
  onPageLoad?: (img: string) => void;
  onDocumentLoad?: (documentInfo: { pageCount: number }) => void;
} & React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>;

export const PdfPreview: React.FC<Props> = ({
  page,
  onDocumentLoad,
  documentPath,
  onPageLoad,
  scale,
  ...props
}) => {
  //const devEnvironment = !!process.env.REACT_APP_DEV;
  const devEnvironment = false;
  const hostname = window && window.location && window.location.hostname;
  const filePath = devEnvironment ? `http://${hostname}:8080` : '';
  const uri = devEnvironment ? '' : '/web';

  const [error, setError] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  usePdf({
    canvasRef,
    file: `${filePath}/virsis-api/${documentPath}`,
    page: page && page > 0 ? page : 1,
    onDocumentLoadSuccess: (doc) => {
      if (onDocumentLoad) {
        onDocumentLoad({ pageCount: doc._pdfInfo.numPages });
      }
      setLoading(false);
    },
    onDocumentLoadFail: () => {
      setLoading(false);
      setError('Įvyko klaida');
    },
    onPageRenderSuccess: () => {
      const img = canvasRef.current?.toDataURL('image/png');
      if (img && onPageLoad) {
        onPageLoad(img);
      }
    },
    withCredentials: true,
    scale: scale || 1,
    workerSrc: `${window.location.origin}${uri}/pdf.worker.js`
  });

  return (
    <>
      {isLoading && <CircularProgress />}
      {!error && !isLoading && <canvas ref={canvasRef} {...props} />}
      {error && <Typography>{error}</Typography>}
    </>
  );
};

type PdfPreviewScrollableProps = {
  onDocumentLoad: () => void;
} & React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>;

export const PdfPreviewScrollable: React.FC<PdfPreviewScrollableProps> = ({ onDocumentLoad }) => {
  const [state, setState] = useState<{ page: number; pageCount: number; pageData: string[] }>({
    page: 0,
    pageCount: 0,
    pageData: []
  });
  const { page, pageCount, pageData } = state;

  return (
    <>
      <PdfPreview
        scale={1.5}
        page={page + 1}
        onDocumentLoad={({ pageCount: count }) => {
          setState({ ...state, pageCount: count });
          onDocumentLoad();
        }}
        onPageLoad={(img) => {
          if (page < pageCount) {
            setState({ ...state, page: page + 1, pageData: [...pageData, img] });
          }
        }}
        documentPath="files/agreement"
        style={{ visibility: 'hidden', position: 'fixed' }}
      />
      {pageData.map((page) => (
        <img key={Math.random()} src={page} style={{ width: '100%' }} alt="" />
      ))}
    </>
  );
};
