import React from 'react';

import {
  ProvidedDataHistoryColumnHeader,
  ProvidedDataHistoryHeaderField
} from './tableState/tableInitialStateAndTypes';
import { useProvidedDataHistoryTableState, useProvidedDataHistoryTableDispatch } from './Context';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { ProvidedDataHistoryTablePeriodFilter } from './FilterByPeriod';
import TableStatusFiltersWithAnnuled from '../../components/TableStatusFilters/TableStatusFiltersWithAnnuled';

interface Props {
  columnNames: ProvidedDataHistoryColumnHeader[];
}

export const ProvidedDataHistoryTableCustomizationBar: React.FC<Props> = ({ columnNames }) => {
  const { state } = useProvidedDataHistoryTableState();
  const { dispatch } = useProvidedDataHistoryTableDispatch();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: ProvidedDataHistoryHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: ProvidedDataHistoryHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    dispatch({ type: 'SHOW_STATUS_UNSIGNED_TOGGLED' });
  };

  const toggleSignedFilter = () => {
    dispatch({ type: 'SHOW_STATUS_SIGNED_TOGGLED' });
  };

  const toggleAnnuledFilter = () => {
    dispatch({ type: 'SHOW_STATUS_ANNULED_TOGGLED' });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFiltersWithAnnuled
            notSignedFilterDisabled={!state.statusNotSignedFilterEnabled}
            notSignedFilterOn={state.showStatusNotSigned}
            onNotSignedFilterToggle={toggleNotSignedFilter}
            signedFilterDisabled={!state.statusSignedFilterEnabled}
            signedFilterOn={state.showStatusSigned}
            onSignedFilterToggle={toggleSignedFilter}
            annuledFilterDisabled={!state.statusAnnuledFilterEnabled}
            annuledFilterOn={state.showStatusAnnuled}
            onAnnuledFilterToggle={toggleAnnuledFilter}
          />
        }
        rightSideItem={
          <>
            <ProvidedDataHistoryTablePeriodFilter />
            <TableCustomTools
              tableColumns={columnNames}
              showFilter={state.customFilterOn}
              onFilterTabToggle={toggleDisplayCustomFilter}
              sortOrder={state.order}
              sortBy={state.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnsDisplayStatus={state.columnsDisplayStatus}
              toggleDisplayColumn={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
