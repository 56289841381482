import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, TextFieldProps } from 'formik-material-ui';

export const CustomTextField: React.FC<TextFieldProps> = (props) => {
  const helperTextClasses = makeStyles({
    root: {
      display: 'none'
    },
    error: {
      '&.MuiFormHelperText-root.Mui-error': {
        display: 'none'
      }
    }
  })();

  const textFieldClasses = makeStyles(() => ({
    textField: {
      width: '62px',
      margin: '0 8px',
      display: 'block'
    }
  }))();

  return (
    <TextField
      {...props}
      variant="outlined"
      className={textFieldClasses.textField}
      FormHelperTextProps={{ classes: helperTextClasses }}
    />
  );
};
