import React, { ReactNode, useState } from 'react';
import { Grid, makeStyles, TableCell, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import 'moment/locale/lt';
import _ from 'lodash';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import { DatePickerState, ValidationDate } from '../../utils/tableTypes';
import {
  MINIMAL_RECORD_DATE_DEFAULT,
  MAXIMAL_RECORD_DATE_FUTURE,
  updateDateValue
} from '../../utils/InputValueFunctions';
import theme from '../../style/virsisTheme';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { Strings } from '../../utils/strings/Strings';

interface DatePickerCoreProps {
  isRequired?: boolean;
  withoutCalendar?: boolean;
  allowFutureDate?: boolean;
  helperItem?: ReactNode;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  customMaxDate?: string;
  disabled?: boolean;
  TextFieldComponent?: React.ComponentType<TextFieldProps>;
}

interface DatePickerImprovedProps extends DatePickerCoreProps {
  state: DatePickerState;
  setDate: (date: MaterialUiPickersDate | null) => void;
}

const useStyles = makeStyles({
  keyboardDatePicker: {
    '& .MuiIconButton-root': {
      padding: '0'
    }
  }
});

export const DateInput: React.FC<DatePickerImprovedProps> = ({
  state,
  setDate,
  isRequired,
  withoutCalendar,
  allowFutureDate,
  helperItem,
  shouldDisableDate,
  customMaxDate,
  disabled,
  TextFieldComponent = TextField
}) => {
  const classes = useStyles();

  const minDateAllowed: ValidationDate | undefined = _.maxBy(state.minDates, 'date');
  const maxDateAllowed: ValidationDate | undefined = _.minBy(state.maxDates, 'date');

  return (
    <Grid container justify="space-between" alignItems="center">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={classes.keyboardDatePicker}
          autoOk
          value={state.value}
          onChange={(value: MaterialUiPickersDate | null) => setDate(value || null)}
          minDate={minDateAllowed?.date}
          maxDate={customMaxDate || (!allowFutureDate ? maxDateAllowed?.date : undefined)}
          error={state.error}
          helperText={state.helperText}
          placeholder={state.placeholder || 'mmmm-mm-dd'}
          label={state.label || undefined}
          required={isRequired}
          inputVariant="outlined"
          format="YYYY-MM-DD"
          invalidDateMessage="mmmm-mm-dd"
          okLabel="Pasirinkti"
          cancelLabel={Strings.button__cancel}
          clearable
          clearLabel="Išvalyti"
          id={state.id}
          style={{ width: '130px' }}
          shouldDisableDate={shouldDisableDate}
          keyboardIcon={
            <DateRangeRoundedIcon
              style={{ display: withoutCalendar ? 'none' : 'inline-flex' }}
              fontSize="small"
              color={disabled ? 'disabled' : 'primary'}
            />
          }
          TextFieldComponent={TextFieldComponent}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
      {helperItem}
    </Grid>
  );
};

interface CellProps {
  rowSpan?: number;
  style?: React.CSSProperties;
}

export const DateInputImprovedCell: React.FC<DatePickerImprovedProps & CellProps> = ({
  rowSpan,
  style,
  state,
  ...props
}) => {
  return (
    <TableCell
      style={{
        minWidth: '100px',
        backgroundColor: state.error ? theme.palette.error.light : undefined,
        ...style
      }}
      rowSpan={rowSpan}
    >
      <form noValidate autoComplete="off">
        <DateInput {...{ ...props, state }} />
      </form>
    </TableCell>
  );
};

interface DateFilterProps extends DatePickerCoreProps {
  name: string;
  setFilter(column: string, value: string | null): void;
}

export const DateInputFilter: React.FC<DateFilterProps> = ({ name, setFilter, ...props }) => {
  const [state, setState] = useState<DatePickerState>({
    value: null,
    error: false,
    validated: false,
    minDates: [MINIMAL_RECORD_DATE_DEFAULT],
    maxDates: [MAXIMAL_RECORD_DATE_FUTURE]
  });

  const setDate = (newDate: MaterialUiPickersDate | null) => {
    const updatedState = updateDateValue(newDate, state);
    setState(updatedState);
    if (!updatedState.error) setFilter(name, newDate ? newDate.format('YYYY-MM-DD') : null);
  };

  return <DateInput {...{ ...props, state, setDate }} />;
};
