import _ from 'lodash';
import {
  EthicalNonComplianceColumnHeader,
  EthicalNonComplianceColumnsDisplayStatus,
  EthicalNonComplianceCustomFilter,
  EthicalNonComplianceHeaderField
} from './tableInitialStateAndTypes';
import { EthicalNonComplianceData } from '../../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export const updateEthicalNonComplianceCustomFilter = (
  filter: EthicalNonComplianceCustomFilter,
  filterBy: EthicalNonComplianceHeaderField,
  value: string | null
): EthicalNonComplianceCustomFilter => {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
};

export function getTableExportDefinition(
  columnHeaders: EthicalNonComplianceColumnHeader[],
  displayStatus: EthicalNonComplianceColumnsDisplayStatus
): TableExportDefinition<EthicalNonComplianceData> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    EthicalNonComplianceData,
    EthicalNonComplianceHeaderField
  >(columnHeaders, displayStatus);
  return {
    title: 'Profesinės etikos nesilaikymo duomenys',
    columnGroups: [
      {
        columns: [
          mappedValueColumn(
            'virsName',
            ({ virsName, virsLegalCode }) => [
              {
                values: [virsName, `${virsLegalCode}`],
                style: {
                  alignment: { horizontal: 'left' }
                }
              }
            ],
            { width: 45 }
          )
        ]
      },
      {
        header: 'Sprendimo',
        columns: [
          stringValueColumn('decisionDate', { width: 15 }),
          stringValueColumn('documentNumber', { width: 15 })
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'outletList',
            ({ outletList }) => [{ values: outletList.map(({ outletName }) => outletName) }],
            { width: 45 }
          ),
          stringValueColumn('decisionStatus', { width: 45 })
        ]
      },
      {
        header: 'Galioja',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      }
    ]
  };
}
