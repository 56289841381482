import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import AllowedTo from '../../containers/AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import { EthicalNonComplianceTableWithContext } from '../../containers/EthicalNonComplianceTableInst/Context';
import { ApplicationState } from '../../store';
import {
  fetchAnnulmentTypesRequest,
  fetchEnterpriseTypesRequest,
  fetchFictitiousOutletRequest
} from '../../store/classifiers/classifiersActions';

const EthicalNonComplianceDataPage: React.FC = () => {
  const reduxDispatch = useDispatch();

  const { annulmentTypes, enterpriseTypes, fictitiousOutlet } = useSelector(
    (state: ApplicationState) => state.classifiers
  );

  useEffect(() => {
    if (!enterpriseTypes) {
      reduxDispatch(fetchEnterpriseTypesRequest());
    }
  }, [reduxDispatch, enterpriseTypes]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  useEffect(() => {
    if (!fictitiousOutlet) {
      reduxDispatch(fetchFictitiousOutletRequest());
    }
  }, [reduxDispatch, fictitiousOutlet]);

  return (
    <AllowedTo roles={[Roles.ROLE_VIEA_VIEW]}>
      <div className="data-page">
        <div className="data-page-content">
          <EthicalNonComplianceTableWithContext />
        </div>
      </div>
    </AllowedTo>
  );
};

export default withRouter(EthicalNonComplianceDataPage);
