import React from 'react';
import { FormGroup, FormControlLabel, Typography, makeStyles } from '@material-ui/core';
import { VirsisSwitch } from '../../../../components/VirsisSwitch/VirsisSwitch';

interface Props {
  filterOn: boolean;
  onFilterToggle: () => void;
  filterDisabled?: boolean;
}

const useStyles = makeStyles({
  boldText: {
    color: '#637082',
    fontWeight: 600,
    marginRight: 15
  }
});

export const NonParticipatingShareholdersFilters: React.FC<Props> = ({
  filterOn,
  onFilterToggle,
  filterDisabled
}) => {
  const classes = useStyles();
  return (
    <div className="TableStatusFilters">
      <FormGroup row>
        <Typography variant="h6" className={classes.boldText}>
          Duomenų rodymas:
        </Typography>
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={filterOn}
              onChange={onFilterToggle}
              name="non-participating-shareholders"
              disabled={filterDisabled}
            />
          }
          label="Nedalyvaujantys dalyviai"
        />
      </FormGroup>
    </div>
  );
};
