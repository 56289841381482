import moment from 'moment';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  validateSingleChoice
} from '../../../../../utils/InputValueFunctions';
import {
  EditionEditRowState,
  EditionEditRowAction,
  initialEditionEditRowState
} from './rowInitialStateAndTypes';
import {
  isValid,
  setCirculationAndValidate,
  validateEditEditionRecordState
} from './rowReducerFunctions';

export const editionEditRowReducer = (
  state: EditionEditRowState,
  action: EditionEditRowAction
): EditionEditRowState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return {
        ...state,
        editingOn: true,
        periodic: action.periodic,
        editionPeriod: {
          ...state.editionPeriod,
          value: {
            periodId: action.record.periodId,
            periodName: action.record.periodName
          },
          validated: true,
          options: [
            ...action.missingPeriods,
            {
              periodId: action.record.periodId,
              periodName: action.record.periodName
            }
          ]
        },
        circulation: {
          ...state.circulation,
          value: String(action.record.circulation) || null,
          validated: true,
          helperText: action.periodic ? 'vidutinis tiražas' : 'tiražas'
        },
        editionDate: action.periodic
          ? state.editionDate
          : {
              ...state.editionDate,
              value: moment(action.record.editionDate),
              minDates: [
                MINIMAL_RECORD_DATE_DEFAULT,
                {
                  id: 'outletControlledFrom',
                  date: moment(action.controlledFrom),
                  text: 'Data negali būti ankstesnė už VIP valdymo pradžios datą'
                }
              ],
              maxDates: [
                {
                  id: 'outletControlledTo',
                  date: action.controlledTo !== null ? moment(action.controlledTo) : null,
                  text: 'Data negali būti vėlesnė už VIP valdymo pabaigos datą '
                },
                MAXIMAL_RECORD_DATE_TODAY
              ]
            }
      };
    case 'EDITING_CANCELLED':
      return initialEditionEditRowState;
    case 'EDITION_PERIOD_CHANGED':
      return {
        ...state,
        editionPeriod: validateSingleChoice(
          { ...state.editionPeriod, value: action.editionPeriod },
          'Reikia pasirinkti iš sąrašo'
        )
      };
    case 'EDITION_DATE_CHANGED':
      return {
        ...state,
        editionDate: updateDateValue(action.editionDate, state.editionDate)
      };
    case 'CIRCULATION_CHANGED':
      return {
        ...state,
        circulation: setCirculationAndValidate(action.circulation)
      };
    case 'UPDATE_RECORD_CLICKED': {
      const validated = validateEditEditionRecordState(state);
      if (isValid(validated) && action.onValid) {
        action.onValid(validated);
      }
      return validateEditEditionRecordState(state);
    }
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    default:
      return state;
  }
};
