import { get, post, put, axiosDelete, getWithQuery } from '../../utils/axiosApi';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { ProfessionalMisconductRecord } from './professionalMisconductsTypes';
import { ProfessionalMisconductsTablePageRequest } from '../professionalMisconductTable/professionalMisconductsTableTypes';

export const getProfessionalMisconductVirsData = (virsId: number) =>
  get(`/virs/${virsId}/institutions-data/professional-misconducts/`);

export const getProfessionalMisconductInstData = () => get('/misconduct-data/inst');

export const getProfessionalMisconductDataByDocId = (docId: number) =>
  get(`/misconduct-data/inst/${docId}`);

export const getProfessionalMisconductInstDataPage = (
  query: ProfessionalMisconductsTablePageRequest
) => getWithQuery('/misconduct-data/inst', query);

export const postCreateProfessionalMisconduct = (record: ProfessionalMisconductRecord) => {
  return post('/misconduct-data/', record);
};

export const putUpdateProfessionalMisconduct = (
  record: ProfessionalMisconductRecord,
  professionalMisconductId: number
) => {
  return put(`/misconduct-data/${professionalMisconductId}`, record);
};

export const deleteRemoveProfessionalMisconduct = (professionalMisconductId: number) => {
  return axiosDelete(`/misconduct-data/${professionalMisconductId}`);
};

export const annulProfessionalMisconductRecord = (
  documentStatusId: number,
  annulRecord: AnnulRecord
) => put(`/provided-data/inst/${documentStatusId}`, annulRecord);
