import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { UnisignProps } from './UnisignContainer';
import {
  initSign,
  setCancelSuccess,
  setUnisignStatus,
  verifySign
} from '../../store/unisign/unisignActions';
import { UnisignStatus } from '../../store/unisign/unisignTypes';
import { fetchVirsDataRequest } from '../../store/virsis/actions';
import { JADISSynchronise } from '../../store/jadisSync/store';
import { ApplicationState } from '../../store';
import { applicationStorage } from '../../utils/axiosApi';

export const UnisignContainerBase: React.FC<UnisignProps> = ({
  onClose,
  documentId,
  unsignedPdfPath,
  onSignSuccess
}) => {
  const { isLoading, error, unisignData, verifySuccess, cancelSuccess, unisignStatus } = useSelector(
    (state: ApplicationState) => state.unsignData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initSign(unsignedPdfPath));
  }, [dispatch, unsignedPdfPath]);

  useEffect(() => {
    if (verifySuccess) {
      if (applicationStorage.getItem('accountType') !== 'LEGAL') {
        dispatch(JADISSynchronise());
        dispatch(fetchVirsDataRequest());
      }
      onSignSuccess();
    }
  }, [onSignSuccess, verifySuccess]);

  useEffect(() => {
    if (cancelSuccess) {
      dispatch(setCancelSuccess(false));
      onClose();
    }
  }, [cancelSuccess]);

  const onFrameLoad = () => {
    switch (unisignStatus) {
      case UnisignStatus.LOADING:
        dispatch(setUnisignStatus(UnisignStatus.SIGNING));
        break;
      case UnisignStatus.SIGNING:
        dispatch(setUnisignStatus(UnisignStatus.COMPLETE));
        dispatch(verifySign(documentId));
        if (cancelSuccess) {
          onClose();
          dispatch(setCancelSuccess(false));
        }
        break;
      default:
        dispatch(setUnisignStatus(UnisignStatus.LOADING));
    }
  };

  const showUnisign: boolean =
    !isLoading &&
    !!unisignData &&
    !error &&
    (unisignStatus === UnisignStatus.LOADING || unisignStatus === UnisignStatus.SIGNING);

  const showSignSuccess: boolean =
    !isLoading &&
    !!unisignData &&
    !error &&
    !!verifySuccess &&
    unisignStatus === UnisignStatus.COMPLETE;

  return (
    <div style={{ display: 'flex', height: '1000px', width: '800px' }}>
      {isLoading && <CircularProgress />}

      {showUnisign && (
        <iframe
          title="unisign"
          height="100%"
          width="100%"
          style={{ border: 'none' }}
          src={unisignData && unisignData.signUrl}
          onLoad={onFrameLoad}
        />
      )}

      {showSignSuccess && <Typography>Dokumentas pasirašytas sėkmingai</Typography>}

      {!isLoading && !!error && <Typography>Įvyko klaida</Typography>}
    </div>
  );
};
