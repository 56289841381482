import React, { useReducer, useEffect } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSelector, useDispatch } from 'react-redux';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { DropdownMultipleInputCell } from '../../../components/TableInputs/DropdownMultipleInput';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { DateInputCellOnlyYear } from '../../../components/TableInputs/DateInputOnlyYear';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { ApplicationState } from '../../../store';
import {
  createFundsReceived,
  resetFundsReceivedCreatingState
} from '../../../store/fundsReceived/fundsReceivedActions';
import {
  FundsReceivedRecord,
  TransactionType
} from '../../../store/fundsReceived/fundsReceivedDataTypes';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { useFundsReceivedLegalTableDispatch } from '../Context';
import { FundsReceivedLegalTableInputs } from './RowInputs';
import { initialFundsReceivedNewRowState } from './rowState/rowInitialStateAndTypes';
import { newFundsReceivedReducer } from './rowState/newReducer';
import { MoneyInputCell } from '../../../components/TableInputs/MoneyInput';
import { validateNewFundsReceivedState } from './rowState/reducerFunctions';
import { searchVirsData } from '../../../store/virsis/actions';
import { FundsReceivedLegalTableState } from '../tableState/tableTypesAndActions';
import {
  disableOthersIfFictitiousSelected,
  getOutletMultiOption
} from '../../../components/TableInputs/Utilities';

interface Props {
  tableState: FundsReceivedLegalTableState;
}

export const FundsReceivedLegalRowContainerNew: React.FC<Props> = ({ tableState }) => {
  const [rowState, rowDispatch] = useReducer(
    newFundsReceivedReducer,
    initialFundsReceivedNewRowState
  );

  const {
    fundsReceivedData: {
      transactionTypes,
      isFundsReceivedCreated,
      creatingFundsReceived,
      creatingFundsReceivedError
    },
    classifiers: { enterpriseTypes, fictitiousOutlet },
    virsis: { currentUser, virsSearchResults }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const { dispatch: tableDispatch } = useFundsReceivedLegalTableDispatch();

  let selectedVirs: VirsSearchData | undefined;
  if (virsSearchResults) {
    [selectedVirs] = virsSearchResults;
  }

  function openFindVirsDialog() {
    rowDispatch({ type: 'VIRS_INPUT_CLICKED' });
    tableDispatch({ type: 'RESET_COMPANY_CODE' });
  }

  function closeFindVirsDialog() {
    rowDispatch({
      type: 'FIND_VIRS_DIALOG_CLOSED'
    });
  }

  function selectVirs(virs: VirsSearchData) {
    rowDispatch({
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
      virs
    });
  }

  function setFundsReceivedYear(fundsReceivedYear: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'FUNDS_RECEIVED_YEAR_CHANGED', fundsReceivedYear });
  }

  function setFundsReceivedSum(fundsReceivedSum: string | null) {
    rowDispatch({ type: 'FUNDS_RECEIVED_SUM_CHANGED', fundsReceivedSum });
  }

  function setOutlets(outlets: OutletShortData[]) {
    rowDispatch({ type: 'OUTLETS_CHANGED', outlets });
  }

  function setTransactionType(transaction: TransactionType | null) {
    rowDispatch({ type: 'TRANSACTION_CHANGED', transaction });
  }

  function cancelCreating() {
    tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
  }

  function createRecord() {
    rowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateNewFundsReceivedState(rowState).createRecordConfirmationOn) {
      const fundsReceivedRecord: FundsReceivedRecord = {
        virsId: rowState.virs.value ? rowState.virs.value.virId : null,
        fundsReceivedYear: rowState.fundsReceivedYear.value
          ? rowState.fundsReceivedYear.value.format('L').toString()
          : null,
        fundsReceivedSum: rowState.fundsReceivedSum.value ? rowState.fundsReceivedSum.value : null,
        fundsSourceCode: currentUser ? String(currentUser.personId) : null,

        fundsReceivedOutlets: rowState.fundsReceivedOutlets.values.map((outlet) => outlet.outletId),
        transactionTypeId: rowState.transaction.value
          ? rowState.transaction.value.transactionTypeId
          : null
      };
      reduxDispatch(createFundsReceived(fundsReceivedRecord));
    }
  }

  useEffect(() => {
    if (isFundsReceivedCreated) {
      rowDispatch({ type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
      reduxDispatch(resetFundsReceivedCreatingState());
      tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
    }
  }, [reduxDispatch, rowDispatch, tableDispatch, isFundsReceivedCreated]);

  useEffect(() => {
    rowDispatch({
      type: 'NEW_RECORD_STATE_INITIALIZED',
      transactionTypes: transactionTypes || [],
      fictitiousOutlet: fictitiousOutlet || []
    });
  }, [transactionTypes, fictitiousOutlet]);

  useEffect(() => {
    if (creatingFundsReceivedError) rowDispatch({ type: 'STOP_LOADING' });
  }, [creatingFundsReceivedError]);

  useEffect(() => {
    if (!tableState.companyCode) rowDispatch({ type: 'VIRS_INPUT_CLICKED' });
  }, [tableState.companyCode, rowDispatch]);

  useEffect(() => {
    if (!selectedVirs && tableState.companyCode) {
      reduxDispatch(searchVirsData({ personCode: tableState.companyCode, onlySigned: true }));
    }
    if (selectedVirs && tableState.companyCode) {
      rowDispatch({
        type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
        virs: selectedVirs
      });
    }
  }, [reduxDispatch, selectedVirs, tableState.companyCode, virsSearchResults]);

  return (
    <>
      {rowState.showFindVirsDialog && !tableState.companyCode && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={closeFindVirsDialog}
          closeAndContinueWithVirs={selectVirs}
        />
      )}
      <FundsReceivedLegalTableInputs
        virsInput={<VirsInputCell openSearchDialog={openFindVirsDialog} state={rowState.virs} />}
        fundsReceivedYearInput={
          <DateInputCellOnlyYear
            state={rowState.fundsReceivedYear}
            setDate={setFundsReceivedYear}
            isRequired
          />
        }
        fundsReceivedSumInput={
          <MoneyInputCell
            setValue={setFundsReceivedSum}
            inputState={rowState.fundsReceivedSum}
            isRequired
          />
        }
        fundsSourceNameInput={currentUser?.amnName}
        fundsSourceCodeInput={currentUser?.amnCode}
        outletsInput={
          <DropdownMultipleInputCell
            selectValues={setOutlets}
            state={rowState.fundsReceivedOutlets}
            getOptionLabel={(option) =>
              `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
            }
            getOptionSelected={(option, value) =>
              value ? option.outletId === value.outletId : false
            }
            renderOption={getOutletMultiOption}
            getOptionDisabled={disableOthersIfFictitiousSelected(
              fictitiousOutlet && fictitiousOutlet[0]
            )}
          />
        }
        transactionInput={
          <DropdownInputCell
            selectValue={setTransactionType}
            dropdownState={rowState.transaction}
            getOptionLabel={(transaction) => transaction.transactionTypeName}
            getOptionSelected={(option, value) =>
              option.transactionTypeId === value.transactionTypeId
            }
          />
        }
        actions={
          <SaveAndCancelActions
            handleConfirmationYes={createRecord}
            isProcessing={creatingFundsReceived}
            handleCancelButtonClick={cancelCreating}
          />
        }
      />
    </>
  );
};
