import { ColumnDisplayStatus, ColumnHeader } from '../tableTypes';

export type CellAlignmentHorizontal = 'left' | 'center' | 'right';
export type CellAlignmentVertical = 'bottom' | 'middle' | 'top';
export type CellBorderThickness = 'hair' | 'thin' | 'medium' | 'thick';

export enum FileFormats {
  XLSX,
  HTML,
  PDF
}

export type CellAlignment = {
  horizontal?: CellAlignmentHorizontal;
  vertical?: CellAlignmentVertical;
};

export type Style = {
  alignment?: CellAlignment;
  border?: CellBorderThickness;
  fill?: string;
};

export type CellStyle = Style & {
  colSpan?: number;
  rowSpan?: number;
};

export type ColumnStyle = Style & {
  width?: number;
};

export type CellDefinition = {
  values: string[];
  style?: CellStyle;
};

export type ColumnDefinition<TData> = {
  header?: string;
  style?: ColumnStyle;
  cellMapper: (data: TData) => CellDefinition[];
  identifier: keyof TData | string;
  visible: boolean;
};

export type ColumnGroup<TData> = {
  header?: string;
  columns: ColumnDefinition<TData>[];
};

export type TableExportDefinition<TData> = {
  title?: string;
  navigationPath?: string[];
  user?: string;
  header?: string;
  columnGroups: ColumnGroup<TData>[];
  onBlob?: (fileData: Blob) => void;
};

export const defaultCellStyle: CellStyle = {
  alignment: { vertical: 'middle', horizontal: 'center' },
  border: 'thin'
};

export type ColumnsDisplayStatus<TData> = {
  [key in keyof TData]?: boolean;
};

export type MatchingKey<T, K> = keyof T & keyof K;

export function getColumnBuilder<TData, TKey extends string>(
  headers: ColumnHeader<TKey>[],
  display: ColumnDisplayStatus<TKey>
): {
  stringValueColumn: (
    key: keyof TData & string & TKey,
    columnStyle?: ColumnStyle,
    cellStyle?: CellStyle
  ) => ColumnDefinition<TData>;
  mappedValueColumn: (
    key: TKey,
    cellMapper: (value: TData) => CellDefinition[],
    columnStyle?: ColumnStyle
  ) => ColumnDefinition<TData>;
} {
  return {
    stringValueColumn: (key, columnStyle, style) => ({
      identifier: key,
      cellMapper: (data) => [
        {
          values: [`${data[key] !== null ? data[key] : ''}`],
          style
        }
      ],
      header: headers?.find(({ id }) => id === key)?.label,
      visible: !!display[key],
      style: columnStyle
    }),
    mappedValueColumn: (key, cellMapper, columnStyle) => ({
      identifier: key,
      cellMapper: (data) => cellMapper(data),
      header: headers?.find(({ id }) => id === key)?.label,
      visible: !!display[key],
      style: columnStyle
    })
  };
}
