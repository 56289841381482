import React from 'react';
import { Container } from '@material-ui/core';
import { HeaderTab } from '../../../components/Tabs/HeaderTab';

interface Props {
  activeTab: any;
  setActiveTab: any;
}

const GraphicalRepresentationHeaderTabs: React.FC<Props> = ({ activeTab, setActiveTab }) => (
  <Container disableGutters style={{ marginTop: '1em', display: 'flex' }}>
    <HeaderTab
      active={activeTab === 'VIRS'}
      label="VIRS"
      value="VIRS"
      setActiveTab={setActiveTab}
    />
    <HeaderTab active={activeTab === 'VIP'} label="VIP" value="VIP" setActiveTab={setActiveTab} />
    <HeaderTab
      active={activeTab === 'DJA'}
      label="Dalyvio juridinio asmens"
      value="DJA"
      setActiveTab={setActiveTab}
    />
    <HeaderTab
      active={activeTab === 'DFA'}
      label="Dalyvio fizinio asmens"
      value="DFA"
      setActiveTab={setActiveTab}
    />
  </Container>
);

export default GraphicalRepresentationHeaderTabs;
