import {
  EditionCheckDataInstTableHeaderType,
  EditionCheckDataTableColumnsDisplayStatus,
  EditionCheckDataTableField
} from './tableTypesAndActions';
import {
  EditionCheckDataInst,
  EditionCheckDocs,
  EditionCheckOutlets
} from '../../../store/editionChecks/editionChecksTypes';
import {
  getComparator,
  getSortedDataGeneric,
  stableSort,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { SortOrder } from '../../../utils/tableTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function calculateEditionCheckColSpan(
  displayStatus: EditionCheckDataTableColumnsDisplayStatus
): number {
  const displayingColumns = [displayStatus.checkDate, displayStatus.docNr];
  return displayingColumns.filter((value) => value === true).length;
}

export function getSortedEditionCheckData(
  data: EditionCheckDataInst[],
  order: SortOrder,
  sortBy: string
): EditionCheckDataInst[] {
  if (sortBy === 'vipName') {
    return data.map((record) => {
      if (record.editionCheckOutlets) {
        const sortedOutlets = stableSort<EditionCheckOutlets>(
          record.editionCheckOutlets,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'outletName')
        );
        return { ...record, editionCheckOutlets: sortedOutlets };
      }
      return record;
    });
  }
  if (sortBy === 'docNr') {
    return data.map((record) => {
      if (record.editionCheckDocs) {
        const sortedDocs = stableSort<EditionCheckDocs>(
          record.editionCheckDocs,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'docNr')
        );
        return { ...record, editionCheckDocs: sortedDocs };
      }
      return record;
    });
  }
  if (sortBy === 'periodName' || sortBy === 'conclusion') {
    const sortedData = JSON.parse(JSON.stringify(data));
    data.forEach((editionCheck, editionCheckIndex) =>
      editionCheck.editionCheckOutlets.forEach((vip, vipIndex) => {
        sortedData[editionCheckIndex].editionCheckOutlets[vipIndex].editionCheckConclusions =
          getSortedDataGeneric(vip.editionCheckConclusions, order, sortBy);
      })
    );
    return sortedData;
  }

  return stableSortWrapped(data, order, sortBy);
}

export function getTableExportDefinition(
  columnHeaders: EditionCheckDataInstTableHeaderType[],
  displayStatus: EditionCheckDataTableColumnsDisplayStatus
): TableExportDefinition<EditionCheckDataInst> {
  const { mappedValueColumn } = getColumnBuilder<EditionCheckDataInst, EditionCheckDataTableField>(
    columnHeaders,
    displayStatus
  );

  return {
    title: 'Tiražo patikrinimo duomenys',
    columnGroups: [
      {
        columns: [
          mappedValueColumn(
            'virsName',
            ({ virsName, virsLegalCode, editionCheckOutlets }) => [
              {
                values: [virsName, `${virsLegalCode}`],
                style: {
                  alignment: { horizontal: 'left' },
                  rowSpan: editionCheckOutlets.flatMap(
                    ({ editionCheckConclusions }) => editionCheckConclusions
                  ).length
                }
              }
            ],
            { width: 45 }
          )
        ]
      },
      {
        header: 'Tiražo tikrinimo',
        columns: [
          mappedValueColumn(
            'checkDate',
            ({ checkDate, editionCheckOutlets }) => [
              {
                values: [checkDate],
                style: {
                  rowSpan: editionCheckOutlets.flatMap(
                    ({ editionCheckConclusions }) => editionCheckConclusions
                  ).length
                }
              }
            ],
            { width: 20 }
          ),
          mappedValueColumn(
            'docNr',
            ({ editionCheckDocs, editionCheckOutlets }) => [
              {
                values: editionCheckDocs.map(({ docNr }) => docNr),
                style: {
                  rowSpan: editionCheckOutlets.flatMap(
                    ({ editionCheckConclusions }) => editionCheckConclusions
                  ).length
                }
              }
            ],
            { width: 20 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'outletName',
            ({ editionCheckOutlets }) =>
              editionCheckOutlets.map(({ outletName, editionCheckConclusions }) => ({
                values: [outletName],
                style: { rowSpan: editionCheckConclusions.length }
              })),
            { width: 45 }
          ),
          mappedValueColumn(
            'periodName',
            ({ editionCheckOutlets }) =>
              editionCheckOutlets
                .flatMap(({ editionCheckConclusions }) => editionCheckConclusions)
                .map(({ periodName }) => ({ values: [periodName] })),
            { width: 30 }
          ),
          mappedValueColumn(
            'conclusion',
            ({ editionCheckOutlets }) =>
              editionCheckOutlets
                .flatMap(({ editionCheckConclusions }) => editionCheckConclusions)
                .map(({ conclusion }) => ({ values: [conclusion] })),
            { width: 30 }
          )
        ]
      }
    ]
  };
}
