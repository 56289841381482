import React from 'react';
import { makeStyles, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { ShareholderAddButton } from '../../../../components/ShareholderComponents/TableButtons/ShareholderAddButton';
import { ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import { Roles } from '../../../../store/virsis/dataTypes';
import AllowedTo from '../../../AllowedTo';
import { getShareholderDateTooltip } from '../utilities/UIfunctions';
import { TableHeadDateCell } from './HeaderDateCell';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../ShareholdersTableWithContext';
import moment from 'moment';
import {
  getChangeDatesInMonth,
  getSelectedYearMonths,
  headerDateIsDay
} from '../utilities/functionsWithDateFormat';
import { useDispatch } from 'react-redux';
import {
  addShareholderDateReset,
  setSelectedDate
} from '../../../../store/shareholders/shareholdersActions';
import { setYearsPeriodWithEvents } from '../tableState/tableFunctions';
import { MONTH_LABEL, YEAR_LABEL } from '../../constants';
import { DatesWithEvents } from '../tableState/tableInitialStateAndTypes';

const useStyles = makeStyles({
  headerRow: {
    borderLeft: 'none',
    borderRight: 'none',
    height: '88px'
  }
});

interface Props {
  allEventsDates: string[];
  yearsArray: string[];
  shareholdersData: ShareholdersData;
  openCalendar: () => void;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  loadingShareholdersData: boolean;
  datesInPerdiodWithEvents: DatesWithEvents[];
}

const FilteredViewTableHeader: React.FC<Props> = ({
  allEventsDates,
  yearsArray,
  shareholdersData,
  openCalendar,
  noValidDates,
  allShareholdersFromJADIS,
  loadingShareholdersData,
  datesInPerdiodWithEvents
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  const {
    state: { periodFilter }
  } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  const allShareholders = shareholdersData.shareholders;

  const handleDateClick = (date: string) => {
    const selectedYearMonths = getSelectedYearMonths(date, shareholdersData.virsStartDate);

    if (moment(date, 'YYYY', true).isValid()) {
      tableDispatch({
        type: 'EXTEND_HEADER_DATES',
        clickedDate: date,
        allEventsDates: allEventsDates,
        months: selectedYearMonths,
        yearsWithEvents: setYearsPeriodWithEvents(yearsArray, allEventsDates),
        value: { id: 'year', label: YEAR_LABEL },
        tableId: 'mixed'
      });
    }

    if (moment(date, 'YYYY-MM', true).isValid()) {
      const changeDatesInMonth: string[] = getChangeDatesInMonth(date, allEventsDates);
      const id = periodFilter.label === YEAR_LABEL ? 'year' : 'month';
      const label = periodFilter.label === YEAR_LABEL ? YEAR_LABEL : MONTH_LABEL;

      tableDispatch({
        type: 'EXTEND_MONTHS_HEADER_DATES',
        allEventsDates: allEventsDates,
        clickedDate: date,
        months: selectedYearMonths,
        days: changeDatesInMonth,
        value: { id, label },
        tableId: 'mixed'
      });
    }
    if (headerDateIsDay(date)) {
      reduxDispatch(addShareholderDateReset());
      reduxDispatch(setSelectedDate(date));
    }
  };

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        <TableCell className="header-level-column header-sticky-col first-col">
          <div className="fake-header-cell">
            <Typography variant="h4">Lygis</Typography>
          </div>
        </TableCell>
        <TableCell className="header-share-column header-sticky-col second-col">
          <div className="fake-header-cell shareholder-cell">
            <Typography variant="h4">Dalyvis</Typography>
          </div>
        </TableCell>
        {datesInPerdiodWithEvents.map((x) => (
          <TableHeadDateCell
            key={x.date}
            date={x.date}
            allShareholders={allShareholders}
            handleDateClick={handleDateClick}
            isDisabled={!x.hasEvent}
          />
        ))}
        <TableCell className="last-column">
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <ShareholderAddButton
              onClick={openCalendar}
              tooltipMessage={getShareholderDateTooltip(noValidDates, allShareholdersFromJADIS)}
              disabled={noValidDates || allShareholdersFromJADIS || loadingShareholdersData}
            />
          </AllowedTo>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FilteredViewTableHeader;
