import { action } from 'typesafe-actions';
import {
  CUSTOM_FILTER_VALUE_CHANGED,
  INITIALIZE_TABLE_STATE,
  PAGE_SET,
  ROWS_PER_PAGE_SET,
  SHOW_STATUS_NOT_SIGNED_TOGGLED,
  SHOW_STATUS_OUTDATED_TOGGLED,
  SHOW_STATUS_RELEVANT_TOGGLED,
  SORTING_CHANGED,
  UPDATE_TEMP_DATA_STATE,
  CUSTOM_FILTER_VALUE_RESET
} from './paymentsTableActionTypes';
import { PaymentDataHeaderField } from '../../containers/PaymentTableInst/tableState/paymentTableInitialStateAndTypes';
import { PaymentsTableDataState } from './paymentsTableTypes';

export const initializePaymentsDataTableState = (
  statusNotSignedFilterEnabled: boolean,
  statusRelevantFilterEnabled: boolean,
  statusOutdatedFilterEnabled: boolean,
  showStatusNotSigned: boolean,
  showStatusRelevant: boolean,
  showStatusOutdated: boolean
) =>
  action(INITIALIZE_TABLE_STATE, {
    statusNotSignedFilterEnabled,
    statusRelevantFilterEnabled,
    statusOutdatedFilterEnabled,
    showStatusNotSigned,
    showStatusRelevant,
    showStatusOutdated
  });
export const showStatusUnsignedToggled = () => action(SHOW_STATUS_NOT_SIGNED_TOGGLED);
export const showStatusRelevantToggle = () => action(SHOW_STATUS_RELEVANT_TOGGLED);
export const showStatusOutdatedToggle = () => action(SHOW_STATUS_OUTDATED_TOGGLED);
export const customFilterValueChanged = (filterBy: PaymentDataHeaderField, value: string | null) =>
  action(CUSTOM_FILTER_VALUE_CHANGED, { filterBy, value });
export const sortingChanged = (sortBy: PaymentDataHeaderField) => action(SORTING_CHANGED, sortBy);
export const customFilterReset = () => action(CUSTOM_FILTER_VALUE_RESET);
export const setPage = (pageNr: number) => action(PAGE_SET, pageNr);
export const setRowsPerPage = (rowsPerPage: number) => action(ROWS_PER_PAGE_SET, rowsPerPage);
export const updateTempDataState = (tableState: PaymentsTableDataState) =>
  action(UPDATE_TEMP_DATA_STATE, tableState);
