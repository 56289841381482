import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { noOptionsText } from './constants';

interface Props {
  uniqueOptions: string[];
  name: string;
  setFilter(column: string, value: string | null): void;
}

export const TableCustomFilterAutocomplete: React.FC<Props> = ({
  uniqueOptions,
  name,
  setFilter
}) => {
  function handleChange(event: React.ChangeEvent<unknown>, value: string | null) {
    setFilter(name, value);
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      clearOnEscape
      id={`filter-${name}`}
      options={uniqueOptions}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};
