import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetExpandShareholders,
  resetShareholdersData
} from '../../store/shareholders/shareholdersActions';

interface Props {
  children: ReactNode;
}

export const ShareholdersPages = ({ children }: Props) => {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    return () => {
      reduxDispatch(resetExpandShareholders());
      reduxDispatch(resetShareholdersData());
    };
  }, [reduxDispatch]);

  return <div>{children}</div>;
};
