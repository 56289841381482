import React, { useEffect, useState } from 'react';

import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { noOptionsText } from '../../../../components/TableCustomFilterCell/constants';

const useStyles = makeStyles(() => ({
  itemLabel: {
    width: 100,
    textAlign: 'right'
  },
  itemEndLabel: {
    marginLeft: 8
  },
  itemDateInput: {
    flexGrow: 1,
    marginLeft: 12,
    textAlign: 'left'
  }
}));

interface Props {
  shareholdersLevelList: string[];
  onLevelChange: (selectedLevel: string) => void;
  defaultValue: boolean;
}

const LevelFilter: React.FC<Props> = ({ shareholdersLevelList, onLevelChange, defaultValue }) => {
  const classes = useStyles();

  const [levelState, setLevelState] = useState<string>('-');

  useEffect(() => {
    if (defaultValue) {
      setLevelState('-');
    }
  }, [defaultValue]);

  const setLevel = (_: any, selectedLevel: string) => {
    setLevelState(selectedLevel);
    onLevelChange(selectedLevel);
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" className={classes.itemLabel}>
        Filtruoti iki
      </Typography>
      <div className={classes.itemDateInput}>
        <Autocomplete
          id="filter-selectedDate"
          disableClearable
          noOptionsText={noOptionsText}
          size="small"
          value={levelState}
          options={['-', ...shareholdersLevelList]}
          onChange={setLevel}
          style={{ width: 130 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          renderOption={(option) => {
            return <span style={{ width: '80px', textAlign: 'center' }}>{option}</span>;
          }}
        />
      </div>
      <Typography variant="body2" className={classes.itemEndLabel}>
        eilės
      </Typography>
    </Box>
  );
};

export default LevelFilter;
