import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, FormGroup, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import { FormikFormTextField } from '../../FormikFields/FormikFormTextField';
import { FormikFormSelectField } from '../../FormikFields/FormikFormSelectField';
import { useDialogFormStyles } from '../../FormikFields/FieldStyles';
import { LegalPersonSearchQuery } from '../../../store/legalPersonData/legalDataTypes';
import { selectLegalSearchQuery } from '../../../store/legalPersonData/legalDataActions';
import { ApplicationState } from '../../../store';
import { FIELD_CHECK_REGEX, FIELD_CHECK_REGEX_LT } from '../../../utils/tableTypes';

export interface Props {
  jarCountries: JarCountry[];
  searchLegal: (legalQuery: LegalPersonSearchQuery, searchedCountry?: JarCountry) => void;
  isSearching: boolean;
}

interface Values {
  country?: JarCountry;
  legalName?: string;
  legalCode?: string;
}

const useStyles = makeStyles((theme) => ({
  formGroup: {
    maxWidth: 'fit-content'
  },
  [theme.breakpoints.down('sm')]: {
    formGroup: {
      maxWidth: 'unset'
    },
    searchButtonWrapper: {
      display: 'contents'
    },
    button: {
      marginLeft: 4
    }
  }
}));

export const FindLegalForm: React.FC<Props> = ({ jarCountries, searchLegal, isSearching }) => {
  const reduxDispatch = useDispatch();
  const classes = useDialogFormStyles();
  const styledClasses = useStyles();

  const [initValues, setInitialValues] = useState<Partial<Values>>({
    country: jarCountries.find((country) => country.countryCode === 'LTU'),
    legalName: '',
    legalCode: ''
  });

  const { selectedLegalPersonSearchQuery } = useSelector(
    (state: ApplicationState) => state.legalData
  );

  const clearForm = (
    setFieldValue: (id: string, value: string | null | undefined | JarCountry) => void
  ) => {
    setFieldValue(
      'country',
      jarCountries.find((country) => country.countryCode === 'LTU')
    );
    setFieldValue('legalName', '');
    setFieldValue('legalCode', '');
  };

  const atLeastNineSymbolsLong = 'Kodas privalo būti 9 simbolių ilgio';
  const atLeastOneFieldRequired = 'Bent vienas iš laukų privalo būti užpildytas';
  const symbolMismatch = 'Įvesti netinkami simboliai';

  const validationSchema = Yup.object().shape(
    {
      country: Yup.object().required('Privaloma šalis').nullable(),
      legalName: Yup.string().when('country', {
        is: (country: JarCountry | null) => country?.countryCode === 'LTU',
        then: Yup.string().when(['legalCode'], {
          is: (legalCode) => !legalCode,
          then: Yup.string()
            .trim()
            .required(atLeastOneFieldRequired)
            .matches(FIELD_CHECK_REGEX_LT, symbolMismatch),
          otherwise: Yup.string().trim().matches(FIELD_CHECK_REGEX_LT, symbolMismatch)
        }),
        otherwise: Yup.string().when(['legalCode'], {
          is: (legalCode) => !legalCode,
          then: Yup.string()
            .trim()
            .required(atLeastOneFieldRequired)
            .matches(FIELD_CHECK_REGEX, symbolMismatch),
          otherwise: Yup.string().trim().matches(FIELD_CHECK_REGEX, symbolMismatch)
        })
      }),
      legalCode: Yup.string().when('country', {
        is: (country: JarCountry | null) => country?.countryCode === 'LTU',
        then: Yup.string().when(['legalName'], {
          is: (legalName) => !legalName,
          then: Yup.string()
            .length(9, atLeastNineSymbolsLong)
            .required(atLeastOneFieldRequired)
            .matches(FIELD_CHECK_REGEX_LT, symbolMismatch),
          otherwise: Yup.string()
            .length(9, atLeastNineSymbolsLong)
            .matches(FIELD_CHECK_REGEX_LT, symbolMismatch)
        }),
        otherwise: Yup.string().when(['legalName'], {
          is: (legalName) => !legalName,
          then: Yup.string()
            .required(atLeastOneFieldRequired)
            .max(40, 'Maksimalus simboliu skaičius 40')
            .matches(FIELD_CHECK_REGEX, symbolMismatch),
          otherwise: Yup.string()
            .max(40, 'Maksimalus simboliu skaičius 40')
            .matches(FIELD_CHECK_REGEX, symbolMismatch)
        })
      })
    },
    [['legalCode', 'legalName']]
  );

  useEffect(() => {
    setInitialValues({
      country:
        jarCountries.find(
          (country) => country.countryCode === selectedLegalPersonSearchQuery?.countryCode
        ) || jarCountries.find((country) => country.countryCode === 'LTU'),
      legalName: selectedLegalPersonSearchQuery?.legalName || '',
      legalCode: selectedLegalPersonSearchQuery?.legalCode || ''
    });
  }, [jarCountries, selectedLegalPersonSearchQuery]);

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery = {
          countryCode: values.country ? values.country.countryCode : null,
          legalName: values.legalName ? values.legalName : null,
          legalCode: values.legalCode ? values.legalCode : null
        };
        searchLegal(searchQuery, values.country);
        setSubmitting(false);
        reduxDispatch(selectLegalSearchQuery(searchQuery));
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        const isLithuanian = values.country ? values.country.countryCode !== 'LTU' : false;
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Form>
              <FormGroup className={styledClasses.formGroup}>
                <FormikFormTextField
                  styledClasses={classes}
                  label="Įmonės kodas"
                  formikKey="legalCode"
                  values={values.legalCode}
                  error={errors.legalCode}
                  setFieldValue={setFieldValue}
                />
                <FormikFormTextField
                  styledClasses={classes}
                  label="Įmonės pavadinimas"
                  formikKey="legalName"
                  values={values.legalName}
                  error={errors.legalName}
                  setFieldValue={setFieldValue}
                />
                <FormikFormSelectField
                  styledClasses={classes}
                  getOptionLabel={(option?: JarCountry) => (option ? option.countryName : '')}
                  isClearable={isLithuanian}
                  isRequired
                  label="Šalis"
                  formikKey="country"
                  value={values.country}
                  options={jarCountries}
                  errors={errors.country}
                  setFieldValue={setFieldValue}
                  defaultValue={jarCountries.find((country) => country.countryCode === 'LTU')}
                />

                <Box className={styledClasses.searchButtonWrapper}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isValidating || isSearching}
                    onClick={submitForm}
                    className={classes.button + ' ' + styledClasses.button}
                    startIcon={<SearchIcon />}
                  >
                    <Typography color="inherit">Ieškoti</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isValidating || isSearching}
                    onClick={() => clearForm(setFieldValue)}
                    className={classes.button + ' ' + styledClasses.button}
                    style={{ marginRight: '4px' }}
                    startIcon={<ClearIcon />}
                  >
                    <Typography color="inherit">Išvalyti</Typography>
                  </Button>
                </Box>
                {isSearching && <LinearProgress />}
              </FormGroup>
            </Form>
          </MuiPickersUtilsProvider>
        );
      }}
    </Formik>
  );
};
