import {
  FundsReceivedLegalTableState,
  FundsReceivedLegalTableAction,
  initialColumnDisplay,
  fundsReceivedLegalColumnNames
} from './tableTypesAndActions';
import { getTableExportDefinition } from './tableStateFunctions';

export const initialFundsReceivedLegalTableState: FundsReceivedLegalTableState = {
  columnsDisplayStatus: initialColumnDisplay,
  customFilterOn: false,
  showNewRecord: false,
  companyCode: undefined,
  tableExportDefinition: getTableExportDefinition(
    fundsReceivedLegalColumnNames,
    initialColumnDisplay
  )
};

export const fundsReceivedLegalTableReducer = (
  state: FundsReceivedLegalTableState,
  action: FundsReceivedLegalTableAction
): FundsReceivedLegalTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(
          fundsReceivedLegalColumnNames,
          columnsDisplayStatus
        )
      };
    }
    case 'CREATE_RECORD_CLICKED':
      return {
        ...state,
        showNewRecord: !state.showNewRecord,
        companyCode: action.companyCode
      };
    case 'CLOSE_CREATING_RECORD_CLICKED':
      return {
        ...state,
        showNewRecord: false
      };
    case 'RESET_COMPANY_CODE':
      return {
        ...state,
        companyCode: undefined
      };
    default:
      return state;
  }
};
