import React from 'react';

import { ColumnHeaderField, RowsPerPageOptions } from './tableState/tableTypes';
import { IndividualPersonData } from '../../../store/externalSystemData/externalSystemDataTypes';

import DataTableWrapper from './DataTableWrapper';
import { ColumnHeader } from '../../../utils/tableTypes';
import { columnNames } from './tableState/tableColumns';

export type IndividualPersonFinancialSupportColumnHeader = ColumnHeader<ColumnHeaderField>;

interface Props {
  data: IndividualPersonData[];
  rowsPerPageOptions: RowsPerPageOptions;
}

const IndividualPersonFinancialSupportTable: React.FC<Props> = ({ data, rowsPerPageOptions }) => {
  const originalData = data;

  return (
    <div className="named-data-table">
      <DataTableWrapper
        originalData={originalData}
        columnNames={columnNames}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};

export default IndividualPersonFinancialSupportTable;
