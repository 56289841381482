export const INITIALIZE_TABLE_STATE = '@payments/INITIALIZE_TABLE_STATE';
export const SHOW_STATUS_OUTDATED_TOGGLED = '@payments/SHOW_STATUS_OUTDATED_TOGGLED';
export const SHOW_STATUS_NOT_SIGNED_TOGGLED = '@payments/SHOW_STATUS_NOT_SIGNED_TOGGLED';
export const SHOW_STATUS_RELEVANT_TOGGLED = '@payments/SHOW_STATUS_RELEVANT_TOGGLED';
export const CUSTOM_FILTER_VALUE_CHANGED = '@payments/CUSTOM_FILTER_VALUE_CHANGED';
export const CUSTOM_FILTER_VALUE_RESET = '@payments/CUSTOM_FILTER_VALUE_RESET';
export const SORTING_CHANGED = '@payments/SORTING_CHANGED';
export const PAGE_SET = '@payments/PAGE_SET';
export const ROWS_PER_PAGE_SET = '@payments/ROWS_PER_PAGE_SET';
export const UPDATE_TEMP_DATA_STATE = '@payments/UPDATE_TEMP_DATA_STATE';
