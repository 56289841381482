import React from 'react';
import { FormikProps } from 'formik';
import { FormikFieldStyleProps } from '../FieldStyles';
import { FormikFormSelectField } from '../FormikFormSelectField';
import { useDialogFormStyles } from './DialogFormStyles';

interface Props<TData, TOption> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label?: string;
  field: keyof TData;
  isRequired?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  onChange?: (value: TOption) => void;
  options: { value: TOption; label: string }[];
  propClasses?: string;
}

export const GenericSelectField = <TData, TOption>({
  isRequired,
  clearable,
  disabled,
  field,
  label,
  formikProps,
  options,
  onChange,
  style,
  propClasses
}: Props<TData, TOption>) => {
  const classes = useDialogFormStyles();
  return (
    <FormikFormSelectField
      withoutLabel={label === undefined}
      styledClasses={classes}
      className={propClasses}
      getOptionLabel={(option: TOption) =>
        options.find(({ value }) => value === option)?.label || ''
      }
      isClearable={!!clearable}
      isRequired={!!isRequired}
      label={label}
      disabled={disabled}
      formikKey={`${field}`}
      value={formikProps.values[field]}
      options={options.map((option) => option.value)}
      errors={formikProps.errors[field]}
      setFieldValue={formikProps.setFieldValue}
      defaultValue={null}
      onChange={onChange}
      style={style}
    />
  );
};

GenericSelectField.defaultProps = {
  isRequired: false,
  disabled: false,
  clearable: true,
  onChange: undefined,
  label: undefined
};
