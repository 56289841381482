import { ColumnHeader } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';

export type FundsReceivedDataTableHeaderType = ColumnHeader<FundsReceivedDataVirsTableField>;

export type FundsReceivedDataVirsTableField =
  | 'fundsReceivedYear'
  | 'fundsReceivedSum'
  | 'fundsSourceName'
  | 'fundsSourceCode'
  | 'transactionType'
  | 'outletName';

export interface FundsReceivedDataTableState {
  customFilter: FundsReceivedDataTableCustomFilter;
  customFilterOn: boolean;
  order: Order;
  sortBy: FundsReceivedDataVirsTableField;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: FundsReceivedDataTableColumnsDisplayStatus;
}

export interface FundsReceivedDataTableCustomFilter {
  fundsReceivedYear: string[];
  fundsReceivedSum: string[];
  fundsSourceName: string[];
  fundsSourceCode: string[];
  transactionType: string[];
  outletName: string[];
}

export type FundsReceivedDataTableColumnsDisplayStatus = {
  [key in FundsReceivedDataVirsTableField]: boolean;
};

export type FundsReceivedDataTableAction =
  | {
      type: 'TABLE_INITIALIZED';
      notSignedEnabled: boolean;
      relevantEnabled: boolean;
      outdatedEnabled: boolean;
      statusCanceledEnabled: boolean;
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: FundsReceivedDataVirsTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: FundsReceivedDataVirsTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: FundsReceivedDataVirsTableField };

export const fundsReceivedTableVirsColumns: FundsReceivedDataTableHeaderType[] = [
  {
    id: 'fundsReceivedYear',
    label: 'Metai'
  },
  {
    id: 'fundsReceivedSum',
    label: 'Suma(EUR)'
  },
  {
    id: 'fundsSourceName',
    label: 'Lėšų šaltinio pavadinimas'
  },
  {
    id: 'fundsSourceCode',
    label: 'Lešų šaltinio kodas'
  },
  {
    id: 'outletName',
    label: 'Susijusios VIP'
  },
  {
    id: 'transactionType',
    label: 'Sandorio rūšis'
  }
];
