import React, { useReducer, useState } from 'react';
import {
  Typography,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead
} from '@material-ui/core/';

import ToggleButton from '@material-ui/lab/ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import SortIcon from '@material-ui/icons/UnfoldMore';
import { TablePagination } from '../../TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import { ApplicationState } from '../../../store';
import {
  getPagesCountGeneric,
  getDataSlicedToPageGeneric,
  stableSort,
  getComparator
} from '../../../utils/tableDataFunctions';
import { LegalPersonSearchData } from '../../../store/legalPersonData/legalDataTypes';
import { useSearchStyles } from '../../../style/searchStyles';
import {
  initialLegalResultsTableState,
  LegalSearchDataField,
  LegalSearchResultsTableColumnHeader,
  LegalSearchResultsTableReducer
} from './state/FindLegalReducer';
import { ConditionalWrapper } from '../../ConditionalWrapper/ConditionalWrapper';
import { TooltipOnTableActionButton } from '../../Tooltips/TooltipOnTableActionButton';
import { selectLegalRepresentative } from '../../../store/legalPersonData/legalDataActions';

const columns: LegalSearchResultsTableColumnHeader[] = [
  {
    id: 'personFullName',
    label: 'Įmonės pavadinimas'
  },
  {
    id: 'personCode',
    label: 'Įmonės kodas'
  },
  {
    id: 'personCountryName',
    label: 'Šalis'
  }
];

interface Props {
  legalSearchResults: LegalPersonSearchData[];
  checkIfPersonCanBeSelected?: (
    legalFormCode: number,
    personType: string,
    personTypeCode: number
  ) => boolean;
  tooltipTextOnToggleButton?: string;
}

export const FindLegalResults: React.FC<Props> = ({
  legalSearchResults,
  checkIfPersonCanBeSelected,
  tooltipTextOnToggleButton
}) => {
  const classes = useSearchStyles();
  const [resultsPagination, setResultsPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({
    page: 0,
    rowsPerPage: 20
  });

  const [tableState, tableDispatch] = useReducer(
    LegalSearchResultsTableReducer,
    initialLegalResultsTableState
  );

  const reduxDispatch = useDispatch();

  const { selectedLegalRepresentative } = useSelector((state: ApplicationState) => state.virsis);

  function selectNewRepresentative(newRep: LegalPersonSearchData) {
    return () => reduxDispatch(selectLegalRepresentative(newRep));
  }

  function setPage(page: number): void {
    setResultsPagination({ ...resultsPagination, page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    setResultsPagination({ rowsPerPage, page: 0 });
  }

  const pagesCount = getPagesCountGeneric(legalSearchResults.length, resultsPagination.rowsPerPage);

  const sortedData = stableSort(
    legalSearchResults,
    getComparator(tableState.order, tableState.sortBy)
  );

  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    resultsPagination.page,
    resultsPagination.rowsPerPage
  );

  const createSortHandler = (column: LegalSearchDataField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const canRepresentativeBeSelected = (
    legalFormCode: number,
    personType: string,
    personTypeCode: number
  ) => {
    if (checkIfPersonCanBeSelected === undefined) {
      return true;
    }
    return checkIfPersonCanBeSelected(legalFormCode, personType, personTypeCode);
  };

  return (
    <>
      <Typography
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          fontWeight: 'bolder'
        }}
      >
        Paieškos rezultatai
      </Typography>
      <Paper style={{ width: '100%' }}>
        <TableContainer className={classes.tableContainer} style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow style={{ border: 'none' }}>
                {columns.map((column, index) => (
                  <TableCell
                    style={{
                      borderLeft: 'none',
                      borderTopLeftRadius: index === 0 ? '10px' : 0
                    }}
                    key={column.id}
                    variant="head"
                    align="left"
                    className="TableCellSortable"
                    onClick={() => {
                      createSortHandler(column.id);
                    }}
                  >
                    <TableSortLabel
                      active
                      direction={tableState.sortBy === column.id ? tableState.order : 'asc'}
                      IconComponent={tableState.sortBy === column.id ? ActiveSortIcon : SortIcon}
                    >
                      <Typography variant="h4">{column.label}</Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  variant="head"
                  style={{
                    width: '100px',
                    borderRight: 'none',
                    borderTopRightRadius: '10px'
                  }}
                  align="center"
                >
                  <Typography>Veiksmai</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedData.map((person) => (
                <TableRow key={person.personId}>
                  <TableCell align="left">{person.personFullName}</TableCell>
                  <TableCell align="left">{person.personCode}</TableCell>
                  <TableCell align="left">{person.personCountryName}</TableCell>
                  <TableCell align="center">
                    <ConditionalWrapper
                      condition={
                        !canRepresentativeBeSelected(
                          person.legalFormCode,
                          person.personType,
                          person.personTypeCode
                        )
                      }
                      wrapper={(children) => (
                        <TooltipOnTableActionButton
                          text={
                            tooltipTextOnToggleButton || 'Šio juridinio asmens pasirinkti negalima'
                          }
                        >
                          <span>{children}</span>
                        </TooltipOnTableActionButton>
                      )}
                    >
                      <ToggleButton
                        classes={{
                          selected: classes.selected,
                          root: classes.root
                        }}
                        selected={
                          selectedLegalRepresentative
                            ? person.personId === selectedLegalRepresentative.personId
                            : false
                        }
                        value="select-person"
                        onClick={selectNewRepresentative(person)}
                        disabled={
                          !canRepresentativeBeSelected(
                            person.legalFormCode,
                            person.personType,
                            person.personTypeCode
                          )
                        }
                      >
                        {selectedLegalRepresentative &&
                        person.personId === selectedLegalRepresentative.personId
                          ? 'Pasirinktas'
                          : 'Pasirinkti'}
                      </ToggleButton>
                    </ConditionalWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            recordsCount={legalSearchResults.length}
            pagesCount={pagesCount}
            rowsPerPage={resultsPagination.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={resultsPagination.page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            disabled={!legalSearchResults.length}
          />
        </TableContainer>
      </Paper>
    </>
  );
};
