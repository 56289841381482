import React, { ReactNode } from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { VirsisParameter } from '../../store/parameters/parametersDataTypes';
import { ParametersTableColumnsDisplayStatus } from './tableState/tableInitialStateAndTypes';
import { convertVirsisYesNoToLabel } from '../../utils/tableDataFunctions';
import { VirsisParameterType, VirsisYesNoString } from '../../utils/tableTypes';

interface Props {
  columnsToDisplay: ParametersTableColumnsDisplayStatus;
  record: VirsisParameter;
  actions: ReactNode;
}

export const ParameterRow: React.FC<Props> = ({ columnsToDisplay, record, actions }) => {
  const {
    parameterCode,
    parameterName,
    parameterType,
    parameterScope,
    minValue,
    maxValue,
    isUrl,
    isRequired,
    measurementUnit,
    parameterValue,
    validFrom,
    validTo
  } = record;

  return (
    <>
      <TableRow>
        {columnsToDisplay.parameterCode && <TableCell>{parameterCode}</TableCell>}

        {columnsToDisplay.parameterName && <TableCell>{parameterName}</TableCell>}

        {columnsToDisplay.parameterType && <TableCell>{parameterType}</TableCell>}

        {columnsToDisplay.parameterScope && <TableCell>{parameterScope}</TableCell>}
        {columnsToDisplay.minValue && <TableCell>{minValue}</TableCell>}

        {columnsToDisplay.maxValue && <TableCell>{maxValue}</TableCell>}

        {columnsToDisplay.isUrl && <TableCell>{convertVirsisYesNoToLabel(isUrl)}</TableCell>}

        {columnsToDisplay.isRequired && (
          <TableCell>{convertVirsisYesNoToLabel(isRequired)}</TableCell>
        )}

        {columnsToDisplay.measurementUnit && <TableCell>{measurementUnit}</TableCell>}

        {columnsToDisplay.parameterValue && (
          <TableCell>
            {parameterType === VirsisParameterType.YesNo &&
            (parameterValue === VirsisYesNoString.YES || parameterValue === VirsisYesNoString.NO)
              ? convertVirsisYesNoToLabel(parameterValue)
              : parameterValue}
          </TableCell>
        )}

        {columnsToDisplay.validFrom && <TableCell>{validFrom}</TableCell>}

        {columnsToDisplay.validTo && <TableCell>{validTo}</TableCell>}

        <TableCell align="right">{actions}</TableCell>
      </TableRow>
    </>
  );
};
