import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { ApplicationState } from '../../store';
import { FundsReceivedDataTableCustomizationBar } from './TableCustomization';
import { FundsReceivedDataTableHeader } from './TableHeader';
import {
  FundsReceivedDataTableStateContext,
  FundsReceivedDataTableDispatchContext
} from './Context';
import { getPagesCountGeneric, getDataSlicedToPageGeneric } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import FundsReceivedDataTableCustomFilter from './FilterRow';
import {
  getFilterAndSortTableData,
  getTableExportDefinition
} from './tableState/tableStateFunctions';
import { FundsReceivedDataVirs } from '../../store/fundsReceived/fundsReceivedDataTypes';
import { FundsReceivedDataTableRow } from './TableRow';
import { fundsReceivedTableVirsColumns } from './tableState/tableTypesAndActions';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';

const FundsReceivedDataTable: React.FC = () => {
  const {
    virsis: { virsData },
    fundsReceivedData: {
      fundsReceivedDataVirs,
      loadingFundsReceivedDataVirs,
      fundsReceivedDataErrorVirs
    }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const { dispatch: tableDispatch } = useContext(FundsReceivedDataTableDispatchContext);
  const { state: tableState } = useContext(FundsReceivedDataTableStateContext);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };
  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const redirectToOutletInfoPage = (outletId: number) => {
    const win = window.open(
      `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}`,
      '_blank'
    );
    if (win) {
      win.focus();
    }
  };

  const filterAndSortTableData = getFilterAndSortTableData(fundsReceivedDataVirs || [], tableState);

  const pagesCount = getPagesCountGeneric(filterAndSortTableData.length, tableState.rowsPerPage);

  const pagedData: FundsReceivedDataVirs[] = getDataSlicedToPageGeneric(
    filterAndSortTableData,
    tableState.page,
    tableState.rowsPerPage
  );

  return (
    <div className="named-data-table">
      <div
        className="table-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">VIRS gautos lėšos</Typography>

        <Box>
          <PageActionButtons
            dontShowDelete
            onDownload={(format, navigationPath) =>
              exportTableData(
                format,
                getTableExportDefinition(tableState),
                filterAndSortTableData,
                navigationPath
              )
            }
            onPrint={(navigationPath) =>
              printTableData(
                getTableExportDefinition(tableState),
                filterAndSortTableData,
                navigationPath
              )
            }
          />
        </Box>
      </div>

      <Paper elevation={0}>
        <FundsReceivedDataTableCustomizationBar columnNames={fundsReceivedTableVirsColumns} />

        <Box style={{overflow: 'auto'}}>
          <Table aria-label="simple table" >
            <FundsReceivedDataTableHeader />

            {tableState.customFilterOn && (
              <FundsReceivedDataTableCustomFilter columnNames={fundsReceivedTableVirsColumns} />
            )}

            <TableBody>
              <TableCircularProgressRow isLoading={loadingFundsReceivedDataVirs} colSpan={7} />

              <TableErrorRow
                error={fundsReceivedDataErrorVirs && 'Klaida. Nepavyko gauti gautų lėšų duomenų'}
                colSpan={7}
              />

              {!loadingFundsReceivedDataVirs &&
                !fundsReceivedDataErrorVirs &&
                virsData &&
                pagedData.map((record) => {
                  return (
                    <FundsReceivedDataTableRow
                      key={record.fundsReceivedId}
                      record={record as unknown as FundsReceivedDataVirs}
                      redirectToOutlet={redirectToOutletInfoPage}
                      virsId={virsData.virsId}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Box>

        <TablePagination
          recordsCount={filterAndSortTableData.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={filterAndSortTableData.length === 0}
        />
      </Paper>
    </div>
  );
};

export default withRouter(FundsReceivedDataTable);
