import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core/';
import { customStylesColorBar } from '../../customStyles/customStylesColorBar';
import { ActivityPeriod } from '../../../../../store/shareholders/shareholdersTypes';

interface Props {
  selectedDate: string;
  startDate: string;
  endDate: string | null;
  fillWithColor: string;
  borderColor: string;
  textColor: string;
  value: string | ReactNode;
  virsEndDate: string | null;
  currentActivityPeriod: ActivityPeriod;
}

export const ColorBarVirs: React.FC<Props> = ({
  selectedDate,
  startDate,
  endDate,
  fillWithColor,
  borderColor,
  textColor,
  value,
  virsEndDate,
  currentActivityPeriod
}) => {
  const customStyles = customStylesColorBar(
    false,
    startDate,
    endDate,
    virsEndDate,
    virsEndDate,
    selectedDate,
    fillWithColor,
    false,
    borderColor,
    textColor
  );

  const getStyles = () => {
    if (currentActivityPeriod?.startDate === selectedDate) {
      return {
        left: '47%',
        width: '53%',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px',
        borderLeft: `1px solid ${borderColor}`
      };
    }
    if (currentActivityPeriod?.endDate === selectedDate) {
      return {
        left: '0%',
        width: '53%',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        borderRight: `1px solid ${borderColor}`
      };
    }
    return {};
  };

  return (
    <>
      <div className="color-bar-selected-date" style={{ ...customStyles, ...getStyles() }}>
        <Typography
          variant="h5"
          style={{
            display: 'flex',
            padding: '0 7px'
          }}
        >
          {value}
        </Typography>
      </div>
    </>
  );
};
