import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exitEmailDialog } from '../../store/virsis/actions';
import { ApplicationState } from '../../store';
import EmailDialog from './Dialog/EmailDialog';
import { CloseDialogButton } from './Dialog/CloseButton';

const FlowEmailDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser, contactEmail, emailDialogOpen } = useSelector(
    (state: ApplicationState) => state.virsis
  );
  return (
    <EmailDialog
      personId={currentUser?.personId || 0}
      contactEmail={contactEmail}
      emailDialogOpen={emailDialogOpen}
      disableBackdropClick
    >
      <CloseDialogButton label="Atsijungti" onClick={() => dispatch(exitEmailDialog())} />
    </EmailDialog>
  );
};

export default FlowEmailDialog;
