import React, { useReducer } from 'react';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  createStyles,
  makeStyles,
  Box
} from '@material-ui/core/';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import {
  getDataSlicedToPageGeneric,
  getPagesCountGeneric
} from '../../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import {
  FindVirsResultsTableColumnHeader,
  findVirsResultsTableReducer,
  initialFindVirsResultsTableState,
  VirsSearchDataField
} from '../../../components/TableDialogFindVirs/findVirsDialogResultsReducerAndTypes';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { getSortedVirsSearchData } from '../../../components/TableDialogFindVirs/FindVirsDialogResults';
import { OutletsAsLinksList } from '../../../components/TableLinks/OutletsAsLinksList';
import VirsDataSearchResultsButtons from './VirsDataSearchButtons';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';

const useStyles = makeStyles((theme) =>
createStyles({
  tableBox: {
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    addButton: {
      width: '100%',
      justifyContent: 'flex-start',
      margin: 0,
      paddingLeft: 0,
    },
    pageActionButtonBox: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap'
    }
  }
})
);

const columns: FindVirsResultsTableColumnHeader[] = [
  {
    id: 'personCode',
    label: 'Įmonės kodas'
  },
  {
    id: 'personFullName',
    label: 'Pavadinimas'
  },
  {
    id: 'enterpriseTypes',
    label: 'VIRS veiklos rūšis'
  },
  {
    id: 'outlets',
    label: 'VIP pavadinimas'
  }
];

interface Props {
  virsSearchResults: VirsSearchData[];
  authorities: string[] | undefined;
}

export const VirsDataSubmissionSearchResults: React.FC<Props> = ({
  virsSearchResults,
  authorities
}) => {
  const [tableState, tableDispatch] = useReducer(
    findVirsResultsTableReducer,
    initialFindVirsResultsTableState
  );

  const createSortHandler = (column: VirsSearchDataField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  function setPage(page: number): void {
    tableDispatch({ type: 'PAGE_SET', page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    tableDispatch({ type: 'ROWS_PER_PAGE_SET', rowsPerPage });
  }
  const classes = useStyles();
  const pagesCount = getPagesCountGeneric(virsSearchResults.length, tableState.rowsPerPage);
  const sortedData = getSortedVirsSearchData(
    virsSearchResults,
    tableState.order,
    tableState.sortBy
  );
  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    tableState.page,
    tableState.rowsPerPage
  ) as unknown as VirsSearchData[];

  return (
    <>
      <Typography
        variant="h3"
        style={{
          paddingTop: '10px',
          paddingBottom: '20px'
        }}
      >
        Paieškos rezultatai
      </Typography>
      <Container disableGutters maxWidth="lg">
        <Paper>
          <Box className={classes.tableBox}>
            <Table aria-label="simple table" style={{ borderTop: ' 1px solid #dddddd' }}>
              <TableHead>
                <TableRow>
                  {columns.map((header) => (
                    <TableCell
                      key={header.id}
                      sortDirection={tableState.sortBy === header.id ? tableState.order : false}
                      align="left"
                      variant="head"
                      className="TableCellSortable"
                      onClick={() => {
                        createSortHandler(header.id);
                      }}
                    >
                      <TableSortLabel
                        active
                        direction={tableState.sortBy === header.id ? tableState.order : 'asc'}
                        IconComponent={tableState.sortBy === header.id ? ActiveSortIcon : SortIcon}
                      >
                        <Typography variant="h4">{header.label}</Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedData.map((virs) => (
                  <TableRow
                    key={`${virs.virId}${virs.personCode}`}
                    style={{
                      backgroundColor: virs.virId ? undefined : 'lightgrey',
                      fontStyle: virs.virId ? undefined : 'italic'
                    }}
                  >
                    <TableCell align="left">{virs.personCode}</TableCell>

                    <TableCell align="left">
                      {virs.virId &&
                      (authorities?.includes('ROLE_VIRS_EDIT') ||
                        authorities?.includes('ROLE_VIRS_VIEW')) ? (
                        <VirsNameLink virsName={virs.personFullName} virsId={virs.virId} />
                      ) : (
                        <Typography>{virs.personFullName}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {virs.enterpriseTypes.map((type) => (
                        <div key={type.enterpriseTypeId}>{type.enterpriseTypeName}</div>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      <OutletsAsLinksList outlets={virs.outlets} virsId={virs.virId} />
                    </TableCell>
                    {virs.personId && (
                      <VirsDataSearchResultsButtons
                        personId={virs.personId}
                        virId={virs.virId}
                        authorities={authorities}
                        canConnectAsVIRS={virs.canConnectAsVIRS}
                        deregistrationDate={virs.virsLegalDeregistrationDate}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            recordsCount={virsSearchResults.length}
            pagesCount={pagesCount}
            rowsPerPage={tableState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableState.page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            disabled={virsSearchResults.length === 0}
          />
        </Paper>
      </Container>
    </>
  );
};
