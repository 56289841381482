import { EditionCheckDataTableState, EditionCheckDataTableAction } from './tableTypesAndActions';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import { updateEditCheckDataCustomFilter } from './tableStateFunctions';

export const initialEditionCheckDataTableState: EditionCheckDataTableState = {
  order: 'desc',
  sortBy: 'checkDate',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    checkDate: true,
    docNr: true,
    outletName: true,
    periodName: true,
    conclusion: true,
    checkedBy: true
  },
  customFilterOn: false,
  customFilter: {
    checkDate: [],
    docNr: [],
    outletName: [],
    periodName: [],
    conclusion: [],
    checkedBy: []
  }
};

export const editionCheckDataTableReducer = (
  state: EditionCheckDataTableState,
  action: EditionCheckDataTableAction
): EditionCheckDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialEditionCheckDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateEditCheckDataCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    default:
      return state;
  }
};
