export const VIRS_TREE_REQUEST = '@virsTree/VIRS_DATA_REQUEST';
export const VIRS_TREE_REQUEST_OF_DATE = '@virsTree/VIRS_DATA_REQUEST_OF_DATE';
export const VIRS_TREE_SUCCESS = '@virsTree/VIRS_DATA_SUCCESS';
export const VIRS_TREE_ERROR = '@virsTree/VIRS_DATA_ERROR';

export const SET_TIMELINE_ACTIVE_DATE = '@@virsTree/SET_TIMELINE_ACTIVE_DATE';
export const SET_TIMELINE_LOADING = '@@virsTree/SET_TIMELINE_LOADING';
export const SET_TIMELINE_ERROR = '@@virsTree/SET_TIMELINE_ERROR';
export const SET_TIMELINE_EVENTS = '@@virsTree/SET_TIMELINE_EVENTS';
export const LOAD_TIMELINE_EVENTS = '@@virsTree/LOAD_TIMELINE_EVENTS';
