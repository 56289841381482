import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import ExtendedRowDetailsBlock from '../../../../../components/ShareholderComponents/ExtendedRowDetailsBlock';
import BtnOpenNewDoc from '../../../../../components/TableButtons/BtnOpenNewDoc';
import { getShareholderDocumentRequest } from '../../../../../store/shareholders/shareholdersActions';
import {
  ShareholdersProps,
  ShareholdersData,
  ShareholdersHistory
} from '../../../../../store/shareholders/shareholdersTypes';
import { getPercentageValue } from '../../../utilityFunctions/sharedFunctions';
import { getEndDateLabel } from '../../../../../store/shareholders/shareholdersUtils';
import { hasRoles } from '../../../../../utils/roleHelper';
import { Roles, VirsisUser } from '../../../../../store/virsis/dataTypes';

interface Props {
  shareholder: ShareholdersProps;
  isSelectedDateEventDate: boolean;
  selectedDate: string;
  parentShareholder: ShareholdersProps | ShareholdersData;
  currentUser?: VirsisUser;
}

export const ExtendedRowShareholderSelectedDate: React.FC<Props> = ({
  shareholder,
  isSelectedDateEventDate,
  selectedDate,
  currentUser,
  parentShareholder
}) => {
  const reduxDispatch = useDispatch();

  const {
    shareholderStartDate,
    shareholderParentStartDate,
    shareholderStoppedParticipatingFromDate,
    endDateType,
    isShareholderFromJADIS,
    shareholderHistory,
    createdWithDocument,
    closedWithDocument,
    JADISStartDate,
    JADISUpdateDate
  } = shareholder;

  const previousAndSelectedDateEvents = shareholderHistory?.filter(
    (event) => event.eventDate <= selectedDate
  );

  function openDocumentInNewTab(documentId: number) {
    return reduxDispatch(getShareholderDocumentRequest(documentId));
  }

  return (
    <TableRow>
      <TableCell colSpan={5}>
        <div className="shareholder-expanded-row-container">
          {isShareholderFromJADIS &&
            JADISUpdateDate !== null &&
            isSelectedDateEventDate &&
            shareholderHistory.length > 1 && (
              <ExtendedRowDetailsBlock
                title="Dalies pasikeitimas"
                label="Gauta iš JADIS:"
                date={JADISUpdateDate}
              />
            )}

          {previousAndSelectedDateEvents.map((event: ShareholdersHistory) => {
            const eventDocumentId = event.documentId;
            const { mayHaveSharePercentage, mayHaveVotePercentage, shareTypeName } =
              parentShareholder;

            if (
              event.documentDate &&
              eventDocumentId &&
              event.documentStatus === 'PASIRASYTAS' &&
              currentUser &&
              hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS, Roles.ROLE_ADMIN_ALL])
            ) {
              return (
                <ExtendedRowDetailsBlock
                  key={`${event.eventId}${event.eventDate}`}
                  title={getPercentageValue(
                    event.votesOnRules,
                    event.sharePercentage,
                    event.votePercentage,
                    mayHaveSharePercentage,
                    mayHaveVotePercentage,
                    shareTypeName
                  )}
                  label="Pateikta:"
                  date={event.documentDate}
                  linkToDocument={
                    hasRoles(currentUser, [
                      Roles.ROLE_VIRS_EDIT,
                      Roles.ROLE_VIRS,
                      Roles.ROLE_ADMIN_ALL
                    ]) && (
                      <BtnOpenNewDoc
                        text="peržiūrėti el. dokumentą"
                        onClicked={() => openDocumentInNewTab(eventDocumentId)}
                      />
                    )
                  }
                />
              );
            }
            return null;
          })}

          {isShareholderFromJADIS && (
            <ExtendedRowDetailsBlock
              title="Dalyvavimo pradžia"
              label="Gauta iš JADIS:"
              date={JADISStartDate}
            />
          )}

          {!isShareholderFromJADIS &&
            currentUser &&
            hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS, Roles.ROLE_ADMIN_ALL]) &&
            createdWithDocument &&
            createdWithDocument.documentId &&
            createdWithDocument.documentStatus === 'PASIRASYTAS' && (
              <ExtendedRowDetailsBlock
                title="Dalyvavimo pradžia"
                label="Pateikta:"
                date={shareholderStartDate || shareholderParentStartDate}
                linkToDocument={
                  <BtnOpenNewDoc
                    text="peržiūrėti el. dokumentą"
                    onClicked={() => openDocumentInNewTab(createdWithDocument.documentId)}
                  />
                }
              />
            )}

          {shareholderStoppedParticipatingFromDate && (
            <ExtendedRowDetailsBlock
              title="Dalyvavimo pabaiga"
              label={getEndDateLabel(endDateType)}
              date={shareholderStoppedParticipatingFromDate}
            />
          )}

          {!!shareholderStoppedParticipatingFromDate &&
            closedWithDocument &&
            currentUser &&
            hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS, Roles.ROLE_ADMIN_ALL]) &&
            closedWithDocument.documentStatus === 'PASIRASYTAS' && (
              <ExtendedRowDetailsBlock
                title="Dalyvavimo pabaiga"
                label="Pateikta:"
                date={closedWithDocument.documentDate}
                linkToDocument={
                  <BtnOpenNewDoc
                    text="peržiūrėti el. dokumentą"
                    onClicked={() => openDocumentInNewTab(closedWithDocument.documentId)}
                  />
                }
              />
            )}
        </div>
      </TableCell>
    </TableRow>
  );
};
