import moment from 'moment';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  setDocumentNumberAndValidate,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../utils/InputValueFunctions';
import {
  EthicalNonComplianceEditRecordState,
  EthicalNonComplianceEditRecordAction,
  EthicalNonComplianceRecordState,
  initialEthicalNonComplianceEditRecordState
} from './initialEthicalNonComplianceStateAndTypes';

import {
  setEthicalNonComplianceVirsAndUpdateOptions,
  validateEditRowState,
  setDynamicValidationConstraints
} from './rowReducerFunctions';

export const editEthicalNonComplianceRowReducer = (
  state: EthicalNonComplianceEditRecordState,
  action: EthicalNonComplianceEditRecordAction
): EthicalNonComplianceEditRecordState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return setDynamicValidationConstraints({
        ...state,
        editingOn: true,
        recordId: action.record.ethicalNonComplianceId,
        virs: {
          ...state.virs,
          value: {
            ...state.virs.value,
            virId: action.record.virsId,
            personFullName: action.record.virsName,
            personCode: String(action.record.virsLegalCode),
            outlets: action.record.outlets,
            virsLegalRegistrationDate: '',
            virsLegalDeregistrationDate: ''
          },
          validated: true
        },
        decisionDate: {
          ...state.decisionDate,
          value: moment(action.record.decisionDate),
          validated: true,
          maxDates: [
            {
              id: 'validFrom',
              date: moment(action.record.validFrom),
              text: 'Data negali būti vėlesnė už įrašo galiojimo pradžios datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        documentNumber: {
          ...state.documentNumber,
          value: action.record.documentNumber,
          validated: true
        },
        outlets: {
          ...state.outlets,
          values:
            action.record.outlets?.length === 1
              ? [action.record.outlets[0]]
              : action.record.outletList,
          validated: true,
          options: action.record.outlets
        },
        decisionStatus: {
          ...state.decisionStatus,
          id: action.ethicalNonComplianceTypes.find(
            (decision) => decision.ethicalNonComplianceTypeName === action.record.decisionStatus
          )?.ethicalNonComplianceId
        },
        validFrom: {
          ...state.validFrom,
          value: moment(action.record.validFrom),
          validated: true,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'decisionDate',
              date: moment(action.record.decisionDate),
              text: 'Data negali būti ankstesnė už sprendimo datą'
            }
          ],
          maxDates: [
            {
              id: 'validTo',
              date: moment(action.record.validTo).subtract(1, 'day'),
              text: 'Data negali būti lygi arba vėlesnė už įrašo galiojimo pabaigos datą'
            }
          ]
        },
        validTo: {
          ...state.validTo,
          value: moment(action.record.validTo),
          validated: true,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'validFrom',
              date: moment(action.record.validFrom).add(1, 'day'),
              text: 'Data negali būti lygi arba ankstesnė už įrašo galiojimo pradžios datą'
            }
          ]
        }
      });
    case 'VIRS_SELECTED':
      return setDynamicValidationConstraints({
        ...state,
        ...setEthicalNonComplianceVirsAndUpdateOptions(
          state as unknown as EthicalNonComplianceRecordState,
          action.virs,
          action.fictitiousOutlet
        ),
        showFindVirsDialog: false
      });
    case 'DECISION_DATE_CHANGED':
      return {
        ...state,
        decisionDate: updateDateValue(action.decisionDate, state.decisionDate),
        validFrom: updateMinDates(action.decisionDate, 'decisionDate', state.validFrom)
      };
    case 'DOCUMENT_NUMBER_CHANGED':
      return {
        ...state,
        documentNumber: setDocumentNumberAndValidate(action.documentNumber, state.documentNumber)
      };
    case 'OUTLET_LIST_CHANGED':
      return setDynamicValidationConstraints({
        ...state,
        outlets: {
          ...state.outlets,
          values: action.outlets,
          validated: action.outlets.length > 0,
          error: !(action.outlets.length > 0),
          helperText: action.outlets.length > 0 ? '' : 'Būtina VIP(-ai)'
        }
      });
    case 'DECISION_STATUS_CHANGED':
      return {
        ...state,
        decisionStatus: {
          ...state.decisionStatus,
          id: action.decisionStatus.ethicalNonComplianceId,
          error: !action.decisionStatus
        }
      };
    case 'VALID_FROM_DATE_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        decisionDate: updateMaxDates(action.validFrom, 'validFrom', state.decisionDate),
        validTo: updateMinDates(
          action.validFrom ? action.validFrom.add(1, 'day') : null,
          'validFrom',
          state.validTo
        )
      };
    case 'VALID_TO_DATE_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(
          action.validTo ? action.validTo.subtract(1, 'day') : null,
          'validTo',
          state.validFrom
        )
      };
    case 'EDITING_CANCELLED':
      return initialEthicalNonComplianceEditRecordState;
    case 'UPDATE_RECORD_CLICKED':
      return validateEditRowState(state);
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateRecordConfirmationOn: false,
        editingOn: false,
        loading: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'FIND_VIRS_DIALOG_OPENED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };

    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },

        annulRecordConfirmationOn: true
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        annulRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
