import { Reducer } from 'redux';
import {
  ProfessionalMisconductDataInst,
  ProfessionalMisconductSanctionType,
  ProfessionalMisconductType,
  ProfessionalMisconductDataVirs
} from './professionalMisconductsTypes';
import {
  PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST,
  PROFESSIONAL_MISCONDUCT_INST_DATA_SUCCESS,
  PROFESSIONAL_MISCONDUCT_INST_DATA_ERROR,
  CREATE_PROFESSIONAL_MISCONDUCT_REQUEST,
  CREATE_PROFESSIONAL_MISCONDUCT_SUCCESS,
  CREATE_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_CREATE_PROFESSIONAL_MISCONDUCT_STATE,
  UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST,
  UPDATE_PROFESSIONAL_MISCONDUCT_SUCCESS,
  RESET_UPDATE_PROFESSIONAL_MISCONDUCT_STATE,
  UPDATE_PROFESSIONAL_MISCONDUCT_ERROR,
  REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST,
  REMOVE_PROFESSIONAL_MISCONDUCT_SUCCESS,
  REMOVE_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_REMOVE_PROFESSIONAL_MISCONDUCT_STATE,
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST,
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_SUCCESS,
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_ERROR,
  ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST,
  ANNUL_PROFESSIONAL_MISCONDUCT_SUCCESS,
  ANNUL_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_ANNUL_PROFESSIONAL_MISCONDUCT_STATE,
  PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST
} from './professionalMisconductsActionTypes';
import { ProfessionalMisconductFilterOptions } from '../../containers/ProfessionalMisconductTableInst/tableState/misconductTableInitialStateAndTypes';

export interface ProfessionalMisconductDataState {
  professionalMisconductVirsData?: ProfessionalMisconductDataVirs[];
  loadingProfessionalMisconductVirsData: boolean;
  professionalMisconductVirsDataError?: Error;
  professionalMisconductRecordCount: number;
  professionalMisconductInstData?: ProfessionalMisconductDataInst[];
  professionalMisconductTypes?: ProfessionalMisconductType[];
  professionalMisconductSanctionTypes?: ProfessionalMisconductSanctionType[];
  loadingProfessionalMisconductInstData: boolean;
  professionalMisconductInstDataError?: Error;
  creatingProfessionalMisconduct: boolean;
  isProfessionalMisconductCreated: boolean;
  creatingProfessionalMisconductError?: Error;
  updatingProfessionalMisconduct: boolean;
  isProfessionalMisconductUpdated: boolean;
  updatingProfessionalMisconductError?: Error;
  removingProfessionalMisconduct: boolean;
  isProfessionalMisconductRemoved: boolean;
  removingProfessionalMisconductError?: Error;
  annullingProfessionalMisconductRecord: boolean;
  annullingProfessionalMisconductError?: Error;
  annullingProfessionalMisconductSuccess: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  recordCount: number;
  instTableFilters?: ProfessionalMisconductFilterOptions;
}

const initialState: ProfessionalMisconductDataState = {
  loadingProfessionalMisconductVirsData: true,
  professionalMisconductVirsData: undefined,
  professionalMisconductVirsDataError: undefined,
  professionalMisconductRecordCount: 0,
  loadingProfessionalMisconductInstData: false,
  professionalMisconductInstData: undefined,
  professionalMisconductTypes: undefined,
  professionalMisconductSanctionTypes: undefined,
  professionalMisconductInstDataError: undefined,
  creatingProfessionalMisconduct: false,
  isProfessionalMisconductCreated: false,
  creatingProfessionalMisconductError: undefined,
  updatingProfessionalMisconduct: false,
  isProfessionalMisconductUpdated: false,
  updatingProfessionalMisconductError: undefined,
  removingProfessionalMisconduct: false,
  isProfessionalMisconductRemoved: false,
  removingProfessionalMisconductError: undefined,
  annullingProfessionalMisconductRecord: false,
  annullingProfessionalMisconductError: undefined,
  annullingProfessionalMisconductSuccess: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  recordCount: 0,
  instTableFilters: undefined
};

export const professionalMisconductsReducer: Reducer<ProfessionalMisconductDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST:
      return {
        ...state,
        loadingProfessionalMisconductVirsData: true,
        virsId: action.payload.virsId
      };
    case PROFESSIONAL_MISCONDUCT_VIRS_DATA_SUCCESS:
      return {
        ...state,
        loadingProfessionalMisconductVirsData: false,
        professionalMisconductVirsData: action.payload.misconducts,
        professionalMisconductRecordCount: action.payload.misconducts.length || 0,
        professionalMisconductInstDataError: undefined
      };
    case PROFESSIONAL_MISCONDUCT_VIRS_DATA_ERROR:
      return {
        ...state,
        loadingProfessionalMisconductVirsData: false,
        professionalMisconductVirsDataError: action?.payload?.data
      };
    case PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST:
      return { ...state, loadingProfessionalMisconductInstData: true };
    case PROFESSIONAL_MISCONDUCT_INST_DATA_SUCCESS:
      return {
        ...state,
        loadingProfessionalMisconductInstData: false,
        professionalMisconductInstData: action.payload.misconductData,
        professionalMisconductTypes: action.payload.misconductTypes,
        professionalMisconductSanctionTypes: action.payload.sanctionTypes,
        statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
        statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
        statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
        recordCount: action.payload.recordCount,
        instTableFilters: action.payload.filterValues
      };
    case PROFESSIONAL_MISCONDUCT_INST_DATA_ERROR:
      return {
        ...state,
        loadingProfessionalMisconductInstData: false,
        professionalMisconductInstDataError: action?.payload?.data
      };
    case CREATE_PROFESSIONAL_MISCONDUCT_REQUEST:
      return {
        ...state,
        creatingProfessionalMisconduct: true
      };
    case CREATE_PROFESSIONAL_MISCONDUCT_SUCCESS:
      return {
        ...state,
        creatingProfessionalMisconduct: false,
        isProfessionalMisconductCreated: true
      };
    case CREATE_PROFESSIONAL_MISCONDUCT_ERROR:
      return {
        ...state,
        creatingProfessionalMisconduct: false,
        isProfessionalMisconductCreated: false,
        creatingProfessionalMisconductError: action?.payload?.data
      };
    case RESET_CREATE_PROFESSIONAL_MISCONDUCT_STATE:
      return {
        ...state,
        creatingProfessionalMisconduct: false,
        isProfessionalMisconductCreated: false,
        creatingProfessionalMisconductError: undefined
      };
    case UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST:
      return {
        ...state,
        updatingProfessionalMisconduct: true
      };
    case UPDATE_PROFESSIONAL_MISCONDUCT_SUCCESS:
      return {
        ...state,
        updatingProfessionalMisconduct: false,
        isProfessionalMisconductUpdated: true
      };
    case UPDATE_PROFESSIONAL_MISCONDUCT_ERROR:
      return {
        ...state,
        updatingProfessionalMisconduct: false,
        isProfessionalMisconductUpdated: false,
        updatingProfessionalMisconductError: action?.payload?.data
      };
    case RESET_UPDATE_PROFESSIONAL_MISCONDUCT_STATE:
      return {
        ...state,
        updatingProfessionalMisconduct: false,
        isProfessionalMisconductUpdated: false,
        updatingProfessionalMisconductError: undefined
      };
    case REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST:
      return {
        ...state,
        removingProfessionalMisconduct: true
      };
    case REMOVE_PROFESSIONAL_MISCONDUCT_SUCCESS:
      return {
        ...state,
        isProfessionalMisconductRemoved: true
      };
    case REMOVE_PROFESSIONAL_MISCONDUCT_ERROR:
      return {
        ...state,
        removingProfessionalMisconduct: false,
        isProfessionalMisconductRemoved: false,
        removingProfessionalMisconductError: action?.payload?.data
      };
    case RESET_REMOVE_PROFESSIONAL_MISCONDUCT_STATE:
      return {
        ...state,
        removingProfessionalMisconduct: false,
        isProfessionalMisconductRemoved: false,
        removingProfessionalMisconductError: undefined
      };
    case ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST:
      return {
        ...state,
        annullingProfessionalMisconductRecord: true
      };
    case ANNUL_PROFESSIONAL_MISCONDUCT_SUCCESS:
      return {
        ...state,
        annullingProfessionalMisconductRecord: false,
        annullingProfessionalMisconductSuccess: true
      };
    case ANNUL_PROFESSIONAL_MISCONDUCT_ERROR:
      return {
        ...state,
        annullingProfessionalMisconductRecord: false,
        annullingProfessionalMisconductSuccess: false,
        annullingProfessionalMisconductError: action?.payload?.data
      };
    case RESET_ANNUL_PROFESSIONAL_MISCONDUCT_STATE:
      return {
        ...state,
        annullingProfessionalMisconductRecord: false,
        annullingProfessionalMisconductSuccess: false,
        annullingProfessionalMisconductError: undefined
      };
    case PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST:
      return {
        ...state,
        loadingProfessionalMisconductInstData: false
      };

    default:
      return state;
  }
};
