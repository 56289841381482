import {
  ColumnDisplayStatus,
  ColumnHeader,
  DatePeriod,
  TableCustomFilter,
  TableGenericFilterOption
} from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { ProvidedDataInst } from '../../../store/providedData/providedDataTypes';
import { getTableExportDefinition } from './tableStateFunctions';

export type ProvidedDataHistoryHeaderField =
  | 'dataType'
  | 'virsName'
  | 'outletList'
  | 'documentStatus'
  | 'lastEditedDate'
  | 'lastEditedBy';

export type ProvidedDataHistoryColumnsDisplayStatus =
  ColumnDisplayStatus<ProvidedDataHistoryHeaderField>;

export type ProvidedDataHistoryCustomFilter = TableCustomFilter<ProvidedDataHistoryHeaderField>;

export type ProvidedDataFilterOptions = TableGenericFilterOption<ProvidedDataHistoryHeaderField>;

export type ProvidedDataHistoryColumnHeader = ColumnHeader<ProvidedDataHistoryHeaderField>;

export interface Period {
  id: DatePeriod;
  label: string;
}

export interface ProvidedDataHistoryTableState {
  sortBy: ProvidedDataHistoryHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: ProvidedDataHistoryColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: ProvidedDataHistoryCustomFilter;
  periodFilter: Period;
  showStatusNotSigned: boolean;
  showStatusSigned: boolean;
  showStatusAnnuled: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusSignedFilterEnabled: boolean;
  statusAnnuledFilterEnabled: boolean;
  showNewRecord: boolean;
  showDocumentDialog: boolean;
  tableExportDefinition: TableExportDefinition<ProvidedDataInst>;
}

export const initialDisplayStatus: ProvidedDataHistoryColumnsDisplayStatus = {
  dataType: true,
  documentStatus: true,
  lastEditedBy: true,
  lastEditedDate: true,
  outletList: true,
  virsName: true
};

export const providedDataInstColumns: ProvidedDataHistoryColumnHeader[] = [
  {
    id: 'dataType',
    label: 'Duomenų tipas'
  },
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'outletList',
    label: 'VIP'
  },
  {
    id: 'documentStatus',
    label: 'Būsena'
  },
  {
    id: 'lastEditedDate',
    label: 'Paskutinio įvedimo / redagavimo data',
    type: 'nonFutureDate'
  },
  {
    id: 'lastEditedBy',
    label: 'Paskutinis įvedęs / redagavęs asmuo'
  }
];

export const initialProvidedDataHistoryTableState: ProvidedDataHistoryTableState = {
  order: 'desc',
  sortBy: 'documentStatus',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: initialDisplayStatus,
  customFilterOn: false,
  customFilter: {
    dataType: [],
    virsName: [],
    outletList: [],
    documentStatus: [],
    lastEditedDate: [],
    lastEditedBy: []
  },
  periodFilter: {
    id: 'PASKUTINIS_MENUO',
    label: 'Paskutinis mėnuo'
  },
  showStatusNotSigned: true,
  showStatusSigned: true,
  showStatusAnnuled: true,
  statusNotSignedFilterEnabled: true,
  statusSignedFilterEnabled: true,
  statusAnnuledFilterEnabled: true,
  showNewRecord: false,
  showDocumentDialog: false,
  tableExportDefinition: getTableExportDefinition(providedDataInstColumns, initialDisplayStatus)
};

export type ProvidedDataHistoryTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: ProvidedDataHistoryHeaderField }
  | { type: 'DOCUMENT_DIALOG_OPENED'; documentId: number }
  | { type: 'DOCUMENT_DIALOG_CLOSED' };
