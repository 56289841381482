import React, { ReactNode } from 'react';
import { TableCell } from '@material-ui/core/';
import { PaymentDataColumnsDisplayStatus } from '../tableState/paymentTableInitialStateAndTypes';
import { WordStatus } from '../../../utils/tableTypes';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { makeStyles } from '@material-ui/core';

interface Props {
  columnsToDisplay: PaymentDataColumnsDisplayStatus;
  virs?: ReactNode;
  decisionDate?: ReactNode;
  paymentDocuments?: ReactNode;
  paymentDocumentsInput?: ReactNode;
  paymentOutlets?: ReactNode;
  paymentOutletsInput?: ReactNode;
  paymentAmount?: ReactNode;
  validFrom?: ReactNode;
  validTo?: ReactNode;
  actions?: ReactNode;
  elDocument?: ReactNode;
  recordStatus?: WordStatus;
  editOn?: boolean;
}

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

export const PaymentBasicRow: React.FC<Props> = ({
  columnsToDisplay,
  virs,
  decisionDate,
  paymentDocuments,
  paymentDocumentsInput,
  paymentOutlets,
  paymentOutletsInput,
  paymentAmount,
  validFrom,
  validTo,
  actions,
  elDocument,
  recordStatus,
  editOn
}) => {
  const classes = useStyles();

  return (
    <>
      {editOn && (
        <RowStyleOnDifferentStatus status={recordStatus}>
          {columnsToDisplay.virsName && virs}
          {columnsToDisplay.decisionDate && decisionDate}
          {columnsToDisplay.paymentDocuments && !paymentDocumentsInput && (
            <TableCell>{paymentDocuments}</TableCell>
          )}
          {columnsToDisplay.paymentDocuments && !paymentDocuments && paymentDocumentsInput}
          {columnsToDisplay.paymentOutlets && !paymentOutletsInput && (
            <TableCell>{paymentOutlets}</TableCell>
          )}
          {columnsToDisplay.paymentOutlets && paymentOutletsInput}
          {columnsToDisplay.paymentAmount && paymentAmount}
          {columnsToDisplay.validFrom && validFrom}
          {columnsToDisplay.validTo && validTo}
          <TableCell>{actions}</TableCell>
          <TableCell>{elDocument}</TableCell>
        </RowStyleOnDifferentStatus>
      )}
      {!editOn && (
        <RowStyleOnDifferentStatus status={recordStatus}>
          {columnsToDisplay.virsName && (
            <TooltipOnRowRibbon status={recordStatus}>
              <TableCell>{virs}</TableCell>
            </TooltipOnRowRibbon>
          )}
          {columnsToDisplay.decisionDate && (
            <TableCell className={classes.dateColumn}>{decisionDate}</TableCell>
          )}
          {columnsToDisplay.paymentDocuments && <TableCell>{paymentDocuments}</TableCell>}
          {columnsToDisplay.paymentOutlets && <TableCell>{paymentOutlets}</TableCell>}
          {columnsToDisplay.paymentAmount && <TableCell>{paymentAmount}</TableCell>}
          {columnsToDisplay.validFrom && (
            <TableCell className={classes.dateColumn}>{validFrom}</TableCell>
          )}
          {columnsToDisplay.validTo && validTo}
          <TableCell>{actions}</TableCell>
          <TableCell>{elDocument}</TableCell>
        </RowStyleOnDifferentStatus>
      )}
    </>
  );
};
