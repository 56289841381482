import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Popover,
  Typography
} from '@material-ui/core';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';

interface Props {
  markingUserMessagesRead: boolean;
  markUnreadMessagesAsRead: () => void;
  disabled?: boolean;
}

const MarkAllReadButton: React.FC<Props> = ({
  markingUserMessagesRead,
  markUnreadMessagesAsRead,
  disabled
}) => {
  const classes = makeStyles((theme) =>
    createStyles({
      moreIcon: {
        color: theme.palette.info.main
      },
      moreIconButton: {
        borderRadius: 5,
        '&:focus': {
          backgroundColor: '#C4E2FC'
        }
      },
      markReadButton: {
        margin: 0,
        padding: '12px 16px',
        '&:hover': {
          textDecoration: 'underline',
          textDecorationColor: '#637082'
        },
        '&:disabled': {
          backgroundColor: 'F8F8F8',
          '& h4': { color: theme.palette.info.main }
        }
      }
    })
  )();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handlePopoverClick} className={classes.moreIconButton}>
        <MoreHorizRoundedIcon fontSize="small" className={classes.moreIcon} />
        {markingUserMessagesRead && (
          <CircularProgress style={{ width: '20px', height: '20px' }} color="secondary" />
        )}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Button
          onClick={markUnreadMessagesAsRead}
          className={classes.markReadButton}
          disabled={disabled}
        >
          <Typography variant="h4">Pažymėti visus kaip perskaitytus</Typography>
        </Button>
      </Popover>
    </>
  );
};
export default MarkAllReadButton;
