import React from 'react';
import { Box } from '@material-ui/core';
import { WordStatus } from '../../../utils/tableTypes';
import { DownloadDocumentButton } from './DownloadDocumentButton';
import { ViewDocumentButton } from './ViewDocumentButton';

interface Props {
  documentId: number;
  documentStatus: WordStatus;
  dataType?: string;
  openDocClicked: (documentId: number, documentStatus: WordStatus, dataType?: string) => void;
  downloadDocClicked: (documentId: number) => void;
}

export const ViewDownloadDocButtons: React.FC<Props> = ({
  documentId,
  documentStatus,
  dataType,
  openDocClicked,
  downloadDocClicked
}) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <ViewDocumentButton onClick={() => openDocClicked(documentId, documentStatus, dataType)} />
      <DownloadDocumentButton
        documentId={documentId}
        onClick={() => downloadDocClicked(documentId)}
      />
    </Box>
  );
};
