import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import {
  ProfessionalMisconductInstTableState,
  initialProfessionalMisconductInstTableState,
  ProfessionalMisconductInstTableAction
} from './tableState/misconductTableInitialStateAndTypes';
import { ProfessionalMisconductTableReducer } from './tableState/misconductTableReducer';
import ProfessionalMisconductTable from './Table';

const ProfessionalMisconductTableStateContext = createContext<{
  state: ProfessionalMisconductInstTableState;
}>({
  state: initialProfessionalMisconductInstTableState
});

const ProfessionalMisconductTableDispatchContext = createContext<{
  dispatch: Dispatch<ProfessionalMisconductInstTableAction>;
}>({
  dispatch: () => {}
});

export const MisconductInstTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(
    ProfessionalMisconductTableReducer,
    initialProfessionalMisconductInstTableState
  );

  return (
    <ProfessionalMisconductTableStateContext.Provider value={{ state }}>
      <ProfessionalMisconductTableDispatchContext.Provider value={{ dispatch }}>
        <ProfessionalMisconductTable />
      </ProfessionalMisconductTableDispatchContext.Provider>
    </ProfessionalMisconductTableStateContext.Provider>
  );
};

export function useProfessionalMisconductInstTableState() {
  return useContext(ProfessionalMisconductTableStateContext);
}
export function useProfessionalMisconductInstTableDispatch() {
  return useContext(ProfessionalMisconductTableDispatchContext);
}
