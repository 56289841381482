import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';
import { VirsisParameter } from '../../../store/parameters/parametersDataTypes';

export const initialParametersDataTableState: ParametersDataTableState = {
  order: 'desc',
  sortBy: 'validFrom',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    parameterCode: true,
    parameterName: true,
    parameterType: true,
    parameterScope: true,
    minValue: true,
    maxValue: true,
    isUrl: true,
    isRequired: true,
    measurementUnit: true,
    parameterValue: true,
    validFrom: true,
    validTo: true
  },
  customFilterOn: false,
  customFilter: {
    parameterCode: [],
    parameterName: [],
    parameterType: [],
    parameterScope: [],
    minValue: [],
    maxValue: [],
    isUrl: [],
    isRequired: [],
    measurementUnit: [],
    parameterValue: [],
    validFrom: [],
    validTo: []
  },
  parameter: undefined
};

export type ParametersDataHeaderField =
  | 'parameterCode'
  | 'parameterName'
  | 'parameterType'
  | 'parameterScope'
  | 'minValue'
  | 'maxValue'
  | 'isUrl'
  | 'isRequired'
  | 'measurementUnit'
  | 'parameterValue'
  | 'validFrom'
  | 'validTo';

export type ParametersTableColumnsDisplayStatus = {
  [key in ParametersDataHeaderField]: boolean;
};

export type ParametersDataCustomFilter = {
  [key in ParametersDataHeaderField]: string[];
};

export type ParametersDataColumnHeader = ColumnHeader<ParametersDataHeaderField>;

export interface ParametersDataTableState {
  sortBy: ParametersDataHeaderField;
  order: SortOrder;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: ParametersTableColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: ParametersDataCustomFilter;
  parameter: VirsisParameter | undefined;
}

export type ParametersDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: ParametersDataHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: ParametersDataHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: ParametersDataHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'PARAMETER_DIALOG_OPENED'; parameterRecord: VirsisParameter }
  | { type: 'PARAMETER_DIALOG_CLOSED' };

export const parametersTableColumns: ParametersDataColumnHeader[] = [
  {
    id: 'parameterCode',
    label: 'Kodas'
  },
  {
    id: 'parameterName',
    label: 'Parametro pavadinimas'
  },
  {
    id: 'parameterType',
    label: 'Parametro tipas'
  },
  {
    id: 'parameterScope',
    label: 'Parametro sritis'
  },
  {
    id: 'minValue',
    label: 'Minimali reikšmė'
  },
  {
    id: 'maxValue',
    label: 'Maksimali reikšmė'
  },
  {
    id: 'isUrl',
    label: 'URL požymis'
  },
  {
    id: 'isRequired',
    label: 'Privalomas'
  },
  {
    id: 'measurementUnit',
    label: 'Matavimo vienetas'
  },
  {
    id: 'parameterValue',
    label: 'Parametro reikšmė'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  }
];
