import { action } from 'typesafe-actions';
import {
  DOCUMENT_APPROVE_ERROR,
  DOCUMENT_APPROVE_REQUEST,
  DOCUMENT_APPROVE_SUCCESS,
  DOCUMENT_VALIDATE_ERROR,
  DOCUMENT_VALIDATE_REQUEST,
  DOCUMENT_VALIDATE_SUCCESS,
  DocumentValidationResponse,
  RESET_DOCUMENT_VALIDATION
} from './documentTypes';

export const documentValidationRequest = (providedDocumentId: number) =>
  action(DOCUMENT_VALIDATE_REQUEST, providedDocumentId);
export const documentValidationSuccess = (response: DocumentValidationResponse) =>
  action(DOCUMENT_VALIDATE_SUCCESS, response);
export const documentValidationError = (error: Error) => action(DOCUMENT_VALIDATE_ERROR, error);

export const documentApproveRequest = (providedDocumentId: number) =>
  action(DOCUMENT_APPROVE_REQUEST, providedDocumentId);
export const documentApproveSuccess = () => action(DOCUMENT_APPROVE_SUCCESS);
export const documentApproveError = (error: Error) => action(DOCUMENT_APPROVE_ERROR, error);

export const resetDocumentValidation = () => action(RESET_DOCUMENT_VALIDATION);
