export interface TransactionType {
  transactionTypeId: number;
  transactionTypeName: string;
  transactionTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface TransactionTypeInput {
  transactionTypeId: number | null;
  transactionTypeName: string | null;
  transactionTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: TransactionTypeInput = {
  transactionTypeId: null,
  transactionTypeName: '',
  transactionTypeNameEn: '',
  validFrom: null,
  validTo: null
};
