import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';

import { Actions } from './state/actions';
import { State } from './state/reducer';

import { getVisibleColumns } from './utility/tableDataUtility';

interface Props<TData> {
  actions: Actions<TData>;
  data: TData;
}

const useStyles = makeStyles({
  cellItem: {
    marginBottom: '1em',
    '&:last-child': {
      marginBottom: 0
    }
  }
});

export const ClassifierRow = <TData,>({ actions, data }: Props<TData>) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { columns, columnVisibility, tableDefinition } = useSelector(
    (state: State<TData>) => state
  );

  const mappers = getVisibleColumns(columnVisibility, columns).map(({ valueMapper, style }) => ({
    valueMapper,
    style
  }));

  const mapper = (value: TData) =>
    mappers.map(({ valueMapper, style }) => ({
      value: valueMapper(value),
      style
    }));

  return (
    <TableRow>
      {mapper(data).map(({ value, style }) => (
        <TableCell key={Math.random()} style={style?.cell}>
          {value.map((valueInner) => (
            <Typography className={classes.cellItem} key={Math.random()}>
              {valueInner}
            </Typography>
          ))}
        </TableCell>
      ))}
      <TableCell style={tableDefinition?.style?.actionsColumn?.cell} align="right">
        {tableDefinition.tableActions.download && (
          <Button onClick={() => dispatch(actions.download(data))} variant="outlined">
            Atsisiųsti
          </Button>
        )}
        <Button onClick={() => dispatch(actions.edit(data))} variant="outlined">
          Redaguoti
        </Button>

        {tableDefinition.tableActions.remove && (
          <Button onClick={() => dispatch(actions.remove(data))} variant="outlined">
            Šalinti
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
