import { ChartVirsData } from '../../../store/shareholdersVirsTree/virsTreeDataTypes';

export interface VirsTreeState {
  shareType: string;
  allShareholders: StateShareholder[];
  showingShareholders: StateShareholder[];
}

export const initialVirsTreeState: VirsTreeState = {
  shareType: '',
  allShareholders: [],
  showingShareholders: []
};

export interface PieChartData {
  key: number;
  x: string;
  fill: string;
  y: number;
}

export interface StateShareholder {
  shareholderId: number;
  parentId: number;
  shareholderLevel: number;
  shareholderGroupId?: number;
  showingGroupExtended?: boolean;
}

export interface KeyNumberValueBoolean {
  [key: number]: boolean;
}

export interface ShareholderIdAndLevel {
  shareholderId: number;
  shareholderLevel: number;
}

export type VirsTreeAction =
  | {
      type: 'DATA_RECEIVED';
      data: ChartVirsData;
    }
  | { type: 'EXPAND_TREE' }
  | { type: 'COLLAPSE_TREE' }
  | { type: 'SHOW_UP_TO_LEVEL'; level: number }
  | {
      type: 'HIDE_GROUP_LIST';
      group: StateShareholder;
    }
  | {
      type: 'SHOW_GROUP_LIST';
      groupId: number;
    }
  | { type: 'SHOW_SHAREHOLDERS_CHILDREN'; shareholder: ShareholderIdAndLevel }
  | {
      type: 'SHOW_GROUP_PERSONS_CHILDREN';
      shareholders: ShareholderIdAndLevel[];
    }
  | { type: 'HIDE_SHAREHOLDER'; shareholder: StateShareholder };
