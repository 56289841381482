import React, { useReducer, createContext, Dispatch } from 'react';

import FundsReceivedDataTable from './Table';
import {
  FundsReceivedDataTableAction,
  FundsReceivedDataTableState
} from './tableState/tableTypesAndActions';
import {
  fundsReceivedDataTableReducer,
  initialFundsReceivedDataTableState
} from './tableState/tableReducer';

export const FundsReceivedDataTableStateContext = createContext<{
  state: FundsReceivedDataTableState;
}>({
  state: initialFundsReceivedDataTableState
});

export const FundsReceivedDataTableDispatchContext = createContext<{
  dispatch: Dispatch<FundsReceivedDataTableAction>;
}>({
  dispatch: () => {}
});

export const FundsReceivedDataTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(
    fundsReceivedDataTableReducer,
    initialFundsReceivedDataTableState
  );
  return (
    <FundsReceivedDataTableStateContext.Provider value={{ state }}>
      <FundsReceivedDataTableDispatchContext.Provider value={{ dispatch }}>
        <FundsReceivedDataTable />
      </FundsReceivedDataTableDispatchContext.Provider>
    </FundsReceivedDataTableStateContext.Provider>
  );
};
