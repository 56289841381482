import {
  PaymentDataColumnHeader,
  PaymentDataColumnsDisplayStatus,
  PaymentDataCustomFilter,
  PaymentDataHeaderField
} from './paymentTableInitialStateAndTypes';
import { PaymentDataInst } from '../../../store/payments/paymentsTypes';
import { SortOrder } from '../../../utils/tableTypes';
import { stableSort, getComparator, stableSortWrapped } from '../../../utils/tableDataFunctions';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function updatePaymentDataCustomFilter(
  filter: PaymentDataCustomFilter,
  filterBy: PaymentDataHeaderField,
  value: string | null
): PaymentDataCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function getSortedPaymentData(
  data: PaymentDataInst[],
  order: SortOrder,
  sortBy: string
): PaymentDataInst[] {
  if (sortBy === 'paymentOutlets') {
    return data.map((record) => {
      if (record.paymentOutlets) {
        const sortedOutlets = stableSort<OutletShortData>(
          record.paymentOutlets,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'outletName')
        );
        return { ...record, paymentOutlets: sortedOutlets };
      }
      return record;
    });
  }

  return stableSortWrapped(data, order, sortBy);
}

export function getTableExportDefinition(
  columnHeaders: PaymentDataColumnHeader[],
  displayStatus: PaymentDataColumnsDisplayStatus
): TableExportDefinition<PaymentDataInst> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    PaymentDataInst,
    PaymentDataHeaderField
  >(columnHeaders, displayStatus);

  return {
    title: 'Metinės įmokos duomenys',
    columnGroups: [
      {
        columns: [
          mappedValueColumn(
            'virsName',
            ({ virsName, virsLegalCode }) => [
              {
                values: [virsName, `${virsLegalCode}`],
                style: {
                  alignment: { horizontal: 'left' }
                }
              }
            ],
            { width: 45 }
          )
        ]
      },
      {
        header: 'Sprendimo',
        columns: [
          stringValueColumn('decisionDate', { width: 15 }),
          mappedValueColumn(
            'paymentDocuments',
            ({ paymentDocuments }) => [
              {
                values: paymentDocuments.map(({ documentNumber }) => documentNumber)
              }
            ],
            { width: 25 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'paymentOutlets',
            ({ paymentOutlets }) => [
              {
                values: paymentOutlets.map(({ outletName }) => outletName)
              }
            ],
            { width: 45 }
          ),
          stringValueColumn('paymentAmount', { width: 15 })
        ]
      },
      {
        header: 'Galioja',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      }
    ]
  };
}
