import React, { ReactNode } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { BasicDarkTooltip } from '../../components/Tooltips/BasicDarkTooltip';
import { ConditionalWrapper } from '../../components/ConditionalWrapper/ConditionalWrapper';

interface Props {
  onClicked: () => void;
  isProcessing: boolean;
  showToolTip: boolean;
  tooltipText: string;
  icon: ReactNode;
  buttonStyle: string;
}

export const ChangeSelectedMessagesStatusButton: React.FC<Props> = ({
  onClicked,
  isProcessing,
  showToolTip,
  tooltipText,
  icon,
  buttonStyle
}) => {
  return (
    <ConditionalWrapper
      condition={showToolTip}
      wrapper={(children) => <BasicDarkTooltip text={tooltipText}>{children}</BasicDarkTooltip>}
    >
      <Button
        variant="outlined"
        onClick={onClicked}
        disabled={!showToolTip}
        className={buttonStyle}
      >
        <>
          {isProcessing ? (
            <CircularProgress style={{ width: '20px', height: '20px' }} color="secondary" />
          ) : (
            <>{icon}</>
          )}
        </>
      </Button>
    </ConditionalWrapper>
  );
};
