import { OutletEdition } from '../../../store/outletInfoData/outletInfoDataTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import {
  EditionColumnHeaderField,
  EditionDataTableState,
  editionTablecolumns
} from '../tablesState/editionTableTypes';

export function getTableExportDefinition(
  tableState: EditionDataTableState
): TableExportDefinition<OutletEdition> {
  const { stringValueColumn } = getColumnBuilder<OutletEdition, EditionColumnHeaderField>(
    editionTablecolumns,
    tableState.columnsDisplayStatus
  );

  return {
    title: 'Tiražo duomenys',
    columnGroups: [
      {
        header: 'Spausdinama',
        columns: [
          stringValueColumn('periodName', { width: 30 }),
          stringValueColumn('circulation', { width: 20 })
        ]
      }
    ]
  };
}
