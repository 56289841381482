import React, { useContext } from 'react';
import { TableRow, TableCell, Typography, TableHead, TableSortLabel } from '@material-ui/core';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import {
  FundsReceivedDataTableStateContext,
  FundsReceivedDataTableDispatchContext
} from './Context';
import { FundsReceivedDataVirsTableField } from './tableState/tableTypesAndActions';

export const FundsReceivedDataTableHeader: React.FC = () => {
  const { dispatch: tableDispatch } = useContext(FundsReceivedDataTableDispatchContext);
  const { state: tableState } = useContext(FundsReceivedDataTableStateContext);

  const handleSetSortByColumn = (column: FundsReceivedDataVirsTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.fundsReceivedYear && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            key="fundsReceivedYear"
            sortDirection={tableState.sortBy === 'fundsReceivedYear' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsReceivedYear')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'fundsReceivedYear' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'fundsReceivedYear' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Metai</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsReceivedSum && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            key="fundsReceivedSum"
            sortDirection={tableState.sortBy === 'fundsReceivedSum' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsReceivedSum')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'fundsReceivedSum' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'fundsReceivedSum' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Suma (EUR)</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceName && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={tableState.sortBy === 'fundsSourceName' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsSourceName')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'fundsSourceName' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'fundsSourceName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Lėšų šaltinio pavadinimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceCode && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={tableState.sortBy === 'fundsSourceCode' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsSourceCode')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'fundsSourceCode' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'fundsSourceCode' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Lėšų šaltinio kodas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.outletName && (
          <TableCell
            align="left"
            variant="head"
            sortDirection={tableState.sortBy === 'outletName' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('outletName')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'outletName' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'outletName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Susijusios VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.transactionType && (
          <TableCell
            align="left"
            variant="head"
            sortDirection={tableState.sortBy === 'transactionType' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('transactionType')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'transactionType' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'transactionType' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Sandorio rūšis</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell align="right" variant="head">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
