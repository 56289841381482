import React, { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  OutletInfo,
  OutletRepresentativeRecord
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { ApplicationState } from '../../../../store';
import { RepresentativeBasicRow } from './BasicRow';
import { StringInputCell } from '../../../../components/TableInputs/StringInput';
import {
  createRepresentative,
  hideAddRepresentativeRow
} from '../../../../store/outletInfoData/outletInfoDataActions';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { newRepresentativeReducer } from './rowState/newRecordReducer';
import { initialRepresentativeNewRowState } from './rowState/rowStateAndTypes';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { OutletRepresentativesColumnsDisplayStatus } from '../../tablesState/representativesTableTypes';
import { validateRepresentativeNewRecordState } from './rowState/rowReducerFunctions';

interface Props {
  outletInfo: OutletInfo;
  columnsDisplay: OutletRepresentativesColumnsDisplayStatus;
}

export const RepresentativeRowContainerNew: React.FC<Props> = ({
  outletInfo: { outletId, controlledFrom, controlledTo },
  columnsDisplay
}) => {
  const [newRowState, newRowDispatch] = useReducer(
    newRepresentativeReducer,
    initialRepresentativeNewRowState
  );

  const {
    outletInfoData: { creatingRepresentativeError, representativeNewRecordRowOn },
    virsis: { virsData, currentUser, selectedPersonRepresentative }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  function setRepValidFrom(validFrom: MaterialUiPickersDate | null) {
    newRowDispatch({
      type: 'REPRESENTATION_FROM_DATE_CHANGED',
      validFrom
    });
  }

  function setRepValidTo(validTo: MaterialUiPickersDate | null) {
    newRowDispatch({
      type: 'REPRESENTATION_TO_DATE_CHANGED',
      validTo
    });
  }

  function setRepNotes(representationNotes: string | null) {
    newRowDispatch({
      type: 'REPRESENTATION_NOTES_CHANGED',
      representationNotes
    });
  }

  function cancelCreating() {
    newRowDispatch({ type: 'CREATING_CANCELLED' });
  }

  function onCreateRecordConfirmed() {
    newRowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (
      validateRepresentativeNewRecordState(newRowState).createRecordConfirmationOn &&
      virsData &&
      currentUser &&
      outletId &&
      selectedPersonRepresentative
    ) {
      newRowDispatch({ type: 'START_LOADING' });
      const newRepRecord: OutletRepresentativeRecord = {
        personId: selectedPersonRepresentative.personId,
        validFrom: newRowState.validFrom.value
          ? newRowState.validFrom.value.format('L').toString()
          : null,
        validTo: newRowState.validTo.value
          ? newRowState.validTo.value.format('L').toString()
          : null,
        representationNotes: newRowState.representationNotes.value
      };
      reduxDispatch(createRepresentative(virsData.virsId, outletId, newRepRecord));
    }
  }

  useEffect(() => {
    if (creatingRepresentativeError) newRowDispatch({ type: 'STOP_LOADING' });
  }, [creatingRepresentativeError]);

  useEffect(() => {
    newRowDispatch({
      type: 'NEW_RECORD_INITIALIZED',
      outletControlledFrom: controlledFrom || null,
      outletControlledTo: controlledTo || null
    });
  }, [controlledFrom, controlledTo, representativeNewRecordRowOn]);

  return (
    <RepresentativeBasicRow
      id={selectedPersonRepresentative ? `${selectedPersonRepresentative.personId}` : '0'}
      columnsToDisplay={columnsDisplay}
      representativeName={
        selectedPersonRepresentative ? selectedPersonRepresentative.personFullNameToDisplay : '---'
      }
      validFromInput={
        <DateInputImprovedCell state={newRowState.validFrom} setDate={setRepValidFrom} isRequired />
      }
      validToInput={<DateInputImprovedCell state={newRowState.validTo} setDate={setRepValidTo} />}
      representationNotesInput={
        <StringInputCell
          setValue={setRepNotes}
          inputState={newRowState.representationNotes}
          multiline
          outlined
          fullWidth
          rows={2}
        />
      }
      actions={
        <SaveAndCancelActions
          handleConfirmationYes={onCreateRecordConfirmed}
          isProcessing={newRowState.loading}
          handleCancelButtonClick={() => {
            cancelCreating();
            reduxDispatch(hideAddRepresentativeRow());
          }}
        />
      }
    />
  );
};
