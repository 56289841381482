import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  PaymentDataHeaderField,
  PaymentDataColumnHeader,
  PaymentDataCustomFilterOptions
} from './tableState/paymentTableInitialStateAndTypes';
import { usePaymentTableState } from './Context';
import { ApplicationState } from '../../store';
import { customFilterValueChanged } from '../../store/paymentsTable/paymentsTableActions';
import { getUniqueOptions } from '../../utils/tableDataFunctions';
import { TableCustomFilterForOptionCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterForOptionCellGeneric';

interface Props {
  columnNames: PaymentDataColumnHeader[];
}

const PaymentTableFilterRow: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = usePaymentTableState();
  const reduxDispatch = useDispatch();

  const handleCustomFilterChange = (column: PaymentDataHeaderField, value: string | null) => {
    reduxDispatch(customFilterValueChanged(column, value));
  };

  const { instTableFilters } = useSelector((state: ApplicationState) => state.paymentData) || [];

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterForOptionCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions<
                PaymentDataCustomFilterOptions,
                PaymentDataHeaderField
              >(instTableFilters, column.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default PaymentTableFilterRow;
