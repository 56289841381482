import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { LabelLinkField } from '../TextField/LabelLinkField';
import { BottomDataWrapper } from './BottomDataWrapper';

const useStyles = makeStyles({
  bottomData: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30
  },
  divider: {
    margin: '0 30px',
    height: 0.5,
    color: '#e5e5e5'
  }
});

export const InstitutionData = () => {
  const classes = useStyles();
  const { virsData } = useSelector((state: ApplicationState) => state.virsis);
  if (!virsData) {
    return null;
  }
  return (
    <div className={classes.bottomData}>
      <div style={{ width: '49.5%' }}>
        <BottomDataWrapper
          mainTitle="Duomenys iš institucijų / įstaigų"
          url="/duomenys-is-instituciju/instituciju-istaigu-duomenys/visi"
        >
          <LabelLinkField
            label="Nustatyta metinė įmoka"
            value={virsData.paymentAmount}
            url="/duomenys-is-instituciju/instituciju-istaigu-duomenys/metine-imoka"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Atlikta tiražo patikrinimų"
            value={virsData.editionCheckCount}
            url="/duomenys-is-instituciju/instituciju-istaigu-duomenys/tirazo-patikrinimas"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Nustatyta profesinių pažeidimų"
            value={virsData.misconductCount}
            url="/duomenys-is-instituciju/instituciju-istaigu-duomenys/pazeidimai"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Profesinės etikos nesilaikymo pripažinimas"
            value={virsData.ethicalNonComplianceStatus}
            url="/duomenys-is-instituciju/instituciju-istaigu-duomenys/etikos-nesilaikymas"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Gauta lėšų sandorio / administracinio akto pagrindu"
            value={virsData.fundsReceivedSum}
            url="/duomenys-is-instituciju/instituciju-istaigu-duomenys/gautos-lesos"
          />
          <Divider light className={classes.divider} />
        </BottomDataWrapper>
      </div>
      <div style={{ width: '49.5%' }}>
        <BottomDataWrapper
          mainTitle="Duomenys iš išorinių sistemų"
          url="/duomenys-is-instituciju/isoriniu-sistemu-duomenys/visi"
        >
          <LabelLinkField
            label="Galiojančios licencijos"
            value={virsData.licenseCount}
            url="/duomenys-is-instituciju/isoriniu-sistemu-duomenys/licencijos"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Fizinių asmenų suteikta parama"
            value={virsData.receivedNaturalPersonSupport}
            url="/duomenys-is-instituciju/isoriniu-sistemu-duomenys/fiziniu-asmenu-parama"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Juridinių asmenų suteikta parama"
            value={virsData.receivedLegalPersonSupport}
            url="/duomenys-is-instituciju/isoriniu-sistemu-duomenys/juridiniu-asmenu-parama"
          />
          <Divider light className={classes.divider} />
          <LabelLinkField
            label="Pajamos už skleistą politinę reklamą"
            value={virsData.politicalFinancialSupport}
            url="/duomenys-is-instituciju/isoriniu-sistemu-duomenys/pajamos-is-politines-reklamos"
          />
          <Divider light className={classes.divider} />
        </BottomDataWrapper>
      </div>
    </div>
  );
};
