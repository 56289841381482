import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePickerState } from '../../../../../utils/tableTypes';
import { OutletAdPrinting } from '../../../../../store/outletInfoData/outletInfoDataTypes';

export interface AdRecordState {
  validFrom: DatePickerState;
  validTo: DatePickerState;
}

export const initialAdPrintingRecordState: AdRecordState = {
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  validTo: {
    value: null,
    error: false,
    validated: true,
    minDates: [],
    maxDates: [],
    helperText: ''
  }
};

export interface AdPrintingRowState extends AdRecordState {
  createStateValidated: boolean;
  editingOn: boolean;
  editStateValidated: boolean;
  removeConfirmationOn: boolean;
}

export const initialAdPrintingRowState: AdPrintingRowState = {
  ...initialAdPrintingRecordState,
  createStateValidated: false,
  editingOn: false,
  editStateValidated: false,
  removeConfirmationOn: false
};

export type AdPrintingRowAction =
  | {
      type: 'AD_PRINTING_FROM_DATE_CHANGED';
      validFrom: MaterialUiPickersDate | null;
    }
  | {
      type: 'AD_PRINTING_TO_DATE_CHANGED';
      validTo: MaterialUiPickersDate | null;
    }
  | {
      type: 'NEW_RECORD_INITIALIZED';
      outletControlledFrom: string | null;
      outletControlledTo: string | null;
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | {
      type: 'EDITING_INITIALIZED';
      record: OutletAdPrinting;
      outletControlledFrom: string | null;
      outletControlledTo: string | null;
    }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'CANCEL_DATE_VALID_TO_EDITING' }
  | {
      type: 'INITIALIZE_DATE_VALID_TO_EDITING';
      record: OutletAdPrinting;
      outletControlledFrom: string | null;
      outletControlledTo: string | null;
    }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CANCELLED' };
