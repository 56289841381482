import React, { useEffect } from 'react';
import { Box, Button, Container, createStyles, Divider, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ApplicationState } from '../../../store';
import {
  useAcordianStyles,
  VirsDataContainer
} from '../../../components/VirsDataContainer/VirsDataContainer';
import {
  fetchVirsDataRequest,
  setSelectedPersonId,
  submitVirsDataRequest
} from '../../../store/virsis/actions';
import NamedBreadcrumbs from '../../../components/VirsNamedBreadcrumbs/VirsNamedBreadcrumbs';
import { getSubsystem } from '../../../utils/roleHelper';
import theme from '../../../style/virsisTheme';

interface Param {
  personId: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    backButton: {
      margin: 0,
      padding: 0,
      marginBottom: '10px'
    },
    title: {
      marginTop: '20px'
    },
    header: {
      lineHeight: '70px'
    },
    divider: {
      marginTop: 0
    },
    buttonContainer: {
      float: 'right'
    }
  })
);

const VirsDataCreationPage: React.FC = () => {
  const history = useHistory();
  const classes = useAcordianStyles();
  const { backButton, title, header, divider, buttonContainer } = useStyles();
  const { personId } = useParams<Param>();

  const reduxDispatch = useDispatch();

  const { virsData, currentUser } = useSelector((state: ApplicationState) => state.virsis);

  useEffect(() => {
    reduxDispatch(setSelectedPersonId(Number(personId)));
    reduxDispatch(fetchVirsDataRequest());
  }, [personId, reduxDispatch]);

  const { virsId } = virsData || {};

  useEffect(() => {
    if (virsId) history.push(`/virs-duomenys/${virsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`);
  }, [history, virsId]);

  if (!virsData || !currentUser) {
    return null;
  }

  return (
    <div className="data-page">
      <Container maxWidth="lg">
        <Button
          color="primary"
          className={backButton}
          variant="text"
          onClick={() => history.push('/virs-duomenys')}
        >
          <Typography color="inherit">Atgal</Typography>
        </Button>
        <NamedBreadcrumbs subsystem={getSubsystem(currentUser)} />
        <Typography className={title} variant="h2">
          Sukurti VIRS
        </Typography>
        <div className="named-data-table">
          <Container
            style={{ backgroundColor: theme.palette.secondary.light }}
            className={classes.accordionBox}
          >
            <Typography className={header} variant="subtitle2">
              Viešosios informacijos rengėjo ir (ar) skleidėjo (VIRS) duomenys
            </Typography>
            <Divider className={divider} />
            <Container disableGutters>
              <VirsDataContainer virsData={virsData} />
            </Container>
          </Container>
        </div>
        <Box className={buttonContainer}>
          <Button variant="outlined" onClick={() => history.push('/virs-duomenys')}>
            <Typography>Atgal</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => reduxDispatch(submitVirsDataRequest(Number(personId)))}
          >
            <Typography color="inherit">Sukurti VIRS</Typography>
            <ChevronRightIcon />
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default VirsDataCreationPage;
