import { Action, createAction, createReducer } from 'typesafe-actions';
import { FormOptions, ReportInput, ReportFormState, initialReportSettings } from './types';
import { VirsSearchData } from '../../store/virsis/dataTypes';
import { NaturalPersonInVirsis } from '../../components/FindNaturalPersonInVirsis/Utilities';

export const loadReportOptions = createAction('@report/LOAD_REPORT_OPTIONS', (report?: string) => ({
  report
}))();

export const setReportOptions = createAction(
  '@report/SET_REPORT_OPTIONS',
  (options: FormOptions) => ({ options })
)();

export const generateReportFile = createAction(
  '@report/GENERATE_REPORT_FILE',
  (input: ReportInput) => ({ input })
)();

export const setReportFileUrl = createAction(
  '@report/SET_REPORT_FILE_URL',
  (reportFileUrl?: string) => ({ reportFileUrl })
)();

export const showVirsSearchDialog = createAction(
  '@report/SHOW_VIRS_SEARCH_DIALOG',
  (showVirsSearchDialog: boolean) => ({ showVirsSearchDialog })
)();

export const showPersonSearchDialog = createAction(
  '@report/SHOW_PERSON_SEARCH_DIALOG',
  (showPersonSearchDialog: boolean) => ({ showPersonSearchDialog })
)();

export const selectVirs = createAction('@report/SELECT_VIRS', (selectedVirs: VirsSearchData) => ({
  selectedVirs
}))();

export const selectPerson = createAction(
  '@report/SELECT_PERSON',
  (selectedPerson?: NaturalPersonInVirsis) => ({
    selectedPerson
  })
)();

export const setLoading = createAction('@report/SET_LOADING', (loading: boolean) => ({
  loading
}))();

export const setError = createAction('@report/SET_ERROR', (error: boolean) => ({
  error
}))();

export const reportReducer = createReducer<ReportFormState, Action>({
  error: false,
  isLoading: false,
  open: true,
  selectedVirs: undefined,
  showVirsSearch: false,
  showPersonSearch: false,
  selectedPerson: undefined,
  options: {
    dataBlockOptions: [],
    fileOptions: [],
    reportSettings: initialReportSettings,
    reportOptions: [],
    sortOptions: [],
    columnOptions: []
  }
})
  .handleAction(setReportOptions, (state, action) => ({
    ...state,
    options: action.payload.options
  }))
  .handleAction(setReportFileUrl, (state, action) => ({
    ...state,
    reportFileUrl: action.payload.reportFileUrl
  }))
  .handleAction(setLoading, (state, action) => ({
    ...state,
    isLoading: action.payload.loading
  }))
  .handleAction(setError, (state, action) => ({
    ...state,
    error: action.payload.error
  }))
  .handleAction(showVirsSearchDialog, (state, action) => {
    return {
      ...state,
      showVirsSearch: action.payload.showVirsSearchDialog
    };
  })
  .handleAction(selectVirs, (state, action) => {
    return {
      ...state,
      selectedVirs: action.payload.selectedVirs
    };
  })
  .handleAction(selectPerson, (state, action) => {
    return {
      ...state,
      selectedPerson: action.payload.selectedPerson
    };
  })
  .handleAction(showPersonSearchDialog, (state, action) => {
    return {
      ...state,
      showPersonSearch: action.payload.showPersonSearchDialog
    };
  });
