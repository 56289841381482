import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import ShareHoldersMainTable from './Table';
import {
  initialShareholdersTableState,
  ShareholdersTableAction,
  ShareholdersTableState
} from './tableState/tableInitialStateAndTypes';
import { ShareholdersTableReducer } from './tableState/tableReducer';

const ShareholdersTableStateContext = createContext<{
  state: ShareholdersTableState;
}>({
  state: initialShareholdersTableState
});

const ShareholdersTableDispatchContext = createContext<{
  dispatch: Dispatch<ShareholdersTableAction>;
}>({
  dispatch: () => {}
});

export const ShareholdersTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(ShareholdersTableReducer, initialShareholdersTableState);
  return (
    <ShareholdersTableStateContext.Provider value={{ state }}>
      <ShareholdersTableDispatchContext.Provider value={{ dispatch }}>
        <ShareHoldersMainTable />
      </ShareholdersTableDispatchContext.Provider>
    </ShareholdersTableStateContext.Provider>
  );
};

export function useShareholdersTableState() {
  return useContext(ShareholdersTableStateContext);
}
export function useShareholdersTableDispatch() {
  return useContext(ShareholdersTableDispatchContext);
}
