import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PreviewAndSignDocumentDialog from './PreviewAndSignDocumentDialog';
import { ApplicationState } from '../../store';
import SignDocumentButton from '../../components/PageButtons/SignDocumentButton';
import { fetchVirsDataRequest } from '../../store/virsis/actions';

interface Props {
  text: string;
  secondary?: boolean;
}

const PreviewToSignDocument: React.FC<Props> = ({ text, secondary }) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleDialogClose = () => setOpenDialog(false);
  const dispatch = useDispatch();
  const {
    virsis: { virsData },
    documentData: { documentValidation }
  } = useSelector((state: ApplicationState) => state);

  return (
    <>
      {virsData && (
        <div>
          <SignDocumentButton
            text={text}
            onClick={() => setOpenDialog(true)}
            secondary={secondary}
          />
          <PreviewAndSignDocumentDialog
            openDialog={openDialog}
            documentPath={`virs/legals/documents/${virsData.documentStatusId}`}
            documentId={virsData.documentStatusId}
            documentStatus={virsData.documentStatus}
            onClose={handleDialogClose}
            documentErrors={documentValidation && documentValidation.documentErrors}
            onSignSuccess={() => dispatch(fetchVirsDataRequest())}
          />
        </div>
      )}
    </>
  );
};

export default PreviewToSignDocument;
