import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  OutletCategoryHeaderField,
  OutletCategoryColumnHeader
} from '../tablesState/categoryTableTypes';
import { OutletCategory } from '../../../store/outletInfoData/outletInfoDataTypes';
import { ApplicationState } from '../../../store';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { TableCustomFilterCellGeneric } from '../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: OutletCategoryColumnHeader[];
}

const FilterRow: React.FC<Props> = ({ columnNames }) => {
  const {
    state: { categories: categoryTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const handleSetCustomFilter = (column: OutletCategoryHeaderField, value: string | null) => {
    tableDispatch({
      type: 'CATEGORY_CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };

  const allData =
    useSelector((state: ApplicationState) => state.outletInfoData.outletInfo?.categories) || [];

  function getUniqueOptions(name: OutletCategoryHeaderField, data: OutletCategory[]) {
    if (data) {
      return Array.from(new Set(data.map((item) => String(item[name]))));
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => categoryTableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FilterRow;
