export interface MessageTypeInput {
  messageTypeId: number | null;
  messageTypeName: string | null;
  enabled: boolean;
  subject: string | null;
  body: string | null;
  validFrom: Date | null;
}

export const defaultValues: MessageTypeInput = {
  messageTypeId: null,
  messageTypeName: '',
  enabled: true,
  subject: '',
  body: '',
  validFrom: null
};

export interface MessageType {
  messageTypeId: number;
  messageTypeCode: string;
  messageTypeName: string;
  enabled: boolean;
  subject: string;
  body: string;
  validFrom: string;
}
