import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import React, { ReactNode } from 'react';
import { noOptionsText } from '../TableCustomFilterCell/constants';

interface DropdownMultipleInputProps<T> {
  formikKey: string;
  options: any[];
  value: any[];
  getOptionLabel: (object: T) => string;
  setFieldValue: (id: string, value: T[]) => void;
  label?: string;
  styledClasses: Record<string, string>;
  style?: React.CSSProperties;
  renderOption?: (option: T, state: AutocompleteRenderOptionState) => ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: { borderRadius: 5 },
  inputRoot: {
    backgroundColor: '#fff',
    borderRadius: 5,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    paddingBottom: '0 !important',
    '&:hover': { border: '1px solid #949494' },
    '&::before': { display: 'none' },
    '&:after': { display: 'none' }
  },
  input: {
    padding: '10px 14px !important'
  },
  [theme.breakpoints.down('sm')]: {
    autoComplete: {
      width: '100%'
    }
  }
}));

export function AuditActionTypesDropdown<T>({
  formikKey,
  options,
  label,
  getOptionLabel,
  styledClasses,
  setFieldValue,
  style,
  value,
  renderOption
}: DropdownMultipleInputProps<T>) {
  const classes = useStyles();

  return (
    <Box className={styledClasses.inputContainer} style={style}>
      {label && (
        <Typography variant="h4" className={styledClasses.inputLabel}>
          {label}
        </Typography>
      )}
      <Autocomplete
        size="small"
        id={formikKey}
        multiple
        value={value}
        noOptionsText={noOptionsText}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          setFieldValue(formikKey, newValue);
        }}
        className={classes.autoComplete}
        classes={{
          root: classes.root,
          inputRoot: classes.inputRoot,
          input: classes.input
        }}
        renderInput={(params) => (
          <TextField {...params} className={styledClasses.textField} name={formikKey} />
        )}
        renderOption={renderOption}
        disableCloseOnSelect
        limitTags={2}
      />
      <Typography
        variant="h6"
        style={{
          fontStyle: 'italic',
          color: '#637082',
          margin: 'auto 0'
        }}
      >
        galima pasirinkti kelis
      </Typography>
    </Box>
  );
}
