import React from 'react';
import Button from '@material-ui/core/Button';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Roles } from '../../../store/virsis/dataTypes';
import { setSelectedPersonId } from '../../../store/virsis/actions';
import AllowedTo from '../../AllowedTo';
import AllowedToAllExcept from '../../AllowedToAllExcept';
import { TooltipOnTableActionButton } from '../../../components/Tooltips/TooltipOnTableActionButton';

interface Props {
  personId: number;
  virId: number | undefined;
  authorities: string[] | undefined;
  canConnectAsVIRS: boolean;
  deregistrationDate: string | null;
}

const VirsDataSearchResultsButtons: React.FC<Props> = ({
  personId,
  virId,
  authorities,
  canConnectAsVIRS,
  deregistrationDate
}) => {
  const useStyles = makeStyles(() => ({
    button: {
      margin: 0,
      padding: '6px 20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%'
    }
  }));

  const classes = useStyles();
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const readOrEdit = () => {
    history.push(`/virs-duomenys/${virId}/duomenu-perziura-ir-teikimas/veiklos-rusys`);
  };

  const createVirs = () => {
    reduxDispatch(setSelectedPersonId(personId));
    history.push(`/virs-duomenys/kurimas/${personId}`);
  };

  if (!authorities) {
    return null;
  }

  let tooltipText = '';

  if (!canConnectAsVIRS) {
    tooltipText =
      'Šiam juridiniam asmeniui, jo filialui ar atstovybei viešosios informacijos rengėjo ir (ar) skleidėjo duomenų teikimas pagal Visuomenės informavimo įstatymą nenumatytas.';
  }

  if (deregistrationDate) {
    tooltipText = 'Šis juridinis asmuo išregistruotas. VIRS sukūrimas negalimas';
  }

  return (
    <TableCell align="right">
      {virId && (
        <AllowedTo roles={[Roles.ROLE_VIRS_EDIT]}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={readOrEdit}
          >
            Peržiurėti ir/ar teikti VIRS duomenis
          </Button>
        </AllowedTo>
      )}
      {virId && (
        <AllowedToAllExcept roles={[Roles.ROLE_VIRS_EDIT]}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={readOrEdit}
          >
            Peržiurėti
          </Button>
        </AllowedToAllExcept>
      )}
      {!virId && authorities?.includes('ROLE_VIRS_EDIT') && (
        <AllowedTo roles={[Roles.ROLE_VIRS_EDIT]}>
          <TooltipOnTableActionButton text={tooltipText}>
            <div>
              <Button
                disabled={!canConnectAsVIRS || Boolean(deregistrationDate)}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={createVirs}
              >
                Kurti VIRS
              </Button>
            </div>
          </TooltipOnTableActionButton>
        </AllowedTo>
      )}
    </TableCell>
  );
};

export default VirsDataSearchResultsButtons;
