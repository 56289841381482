import { makeStyles } from '@material-ui/core/styles';

export const useSearchStyles = makeStyles((theme) => ({
  selected: {
    '&.Mui-selected': {
      color: theme.palette.background.paper,
      textTransform: 'none',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  root: {
    textTransform: 'none',
    height: '36px',
    width: '100px',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default
    }
  },
  tableCell: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.secondary.light,
    padding: '0 10px'
  },
  tableContainer: {
    border: 'none',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px'
  },
  [theme.breakpoints.down('xs')]: {
    tableContainer: {
      display: 'grid'
    }
  }
}));
