import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Table, Typography, TableBody, Box } from '@material-ui/core';

import { RouteComponentProps, withRouter } from 'react-router';
import { PaymentDataTableCustomizationBar } from './TableCustomization';
import PaymentDataTableHead from './TableHead';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { ApplicationState } from '../../store';
import { formatStringOfNumber, pageTableData } from '../../utils/tableDataFunctions';
import {
  filterAndSortPaymentDataVirs,
  getTableExportDefinition
} from './tableState/tableStateFunctions';
import { usePaymentDataTableState, usePaymentDataTableDispatch } from './Context';
import PaymentDataTableCustomFilter from './FilterRow';
import { getVirsPaymentDocumentInNewTabRequest } from '../../store/payments/paymentsActions';
import { PaymentBasicRow } from './TableRow';
import { OutletsAsLinksList } from '../../components/TableLinks/OutletsAsLinksList';
import { InlineElDocumentLinks } from '../../components/TableElDocumentLinks/InlineElDocumentLinks';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { ElDocumentLinks } from '../../components/TableElDocumentLinks/ElDocumentLinks';
import { paymentTableColumns } from './tableState/tableColumns';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';

const PaymentDataTable: React.FC<RouteComponentProps> = () => {
  const { state: tableState } = usePaymentDataTableState();
  const { dispatch: tableDispatch } = usePaymentDataTableDispatch();
  const reduxDispatch = useDispatch();

  const {
    virsis: { virsData },
    paymentData: { virsLoadingPaymentData, virsPaymentData, virsPaymentDataError }
  } = useSelector((state: ApplicationState) => state);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  function openDocument(paymentDocumentId: number, documentNumber: string) {
    reduxDispatch(getVirsPaymentDocumentInNewTabRequest(paymentDocumentId, documentNumber));
  }

  const filteredData = filterAndSortPaymentDataVirs(tableState, virsPaymentData || []);
  const { page: dataPage, pagesCount } = pageTableData(tableState, filteredData);

  return (
    <div className="named-data-table">
      <div
        className="table-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">Metinė įmoka</Typography>

        <Box>
          <PageActionButtons
            dontShowDelete
            onDownload={(format, navigationPath) =>
              exportTableData(
                format,
                getTableExportDefinition(tableState),
                filteredData,
                navigationPath
              )
            }
            onPrint={(navigationPath) =>
              printTableData(getTableExportDefinition(tableState), filteredData, navigationPath)
            }
          />
        </Box>
      </div>

      <Paper elevation={0}>
        <PaymentDataTableCustomizationBar columnNames={paymentTableColumns} />

        <ScrollXContainer>
          <Table>
            <PaymentDataTableHead />

            {tableState.customFilterOn && (
              <PaymentDataTableCustomFilter columnNames={paymentTableColumns} />
            )}

            <TableBody>
              <TableCircularProgressRow
                isLoading={virsLoadingPaymentData && !virsPaymentDataError}
                colSpan={8}
              />

              <TableErrorRow
                error={virsPaymentDataError && 'Klaida. Nepavyko gauti įmokos duomenų'}
                colSpan={8}
              />

              {!virsLoadingPaymentData &&
                !virsPaymentDataError &&
                virsData &&
                dataPage.map((record) => (
                  <PaymentBasicRow
                    key={record.paymentId}
                    columnsToDisplay={tableState.columnsDisplayStatus}
                    decisionDate={record.decisionDate}
                    paymentDocuments={
                      <ElDocumentLinks
                        documents={record.paymentDocuments}
                        onClickedOpenInDialog={openDocument}
                      />
                    }
                    paymentOutlets={
                      <OutletsAsLinksList
                        outlets={record.paymentOutlets}
                        virsId={virsData.virsId}
                      />
                    }
                    paymentAmount={formatStringOfNumber(record.paymentAmount)}
                    validFrom={record.validFrom}
                    validTo={record.validTo}
                    paymentDeterminedBy={record.paymentDeterminedBy}
                    originalDocumentDate={record.originalDocumentDate}
                    documentStatus={record.documentStatus.id}
                    elDocument={
                      <InlineElDocumentLinks
                        documents={record.paymentDocuments}
                        onClickedOpenInDialog={openDocument}
                      />
                    }
                    confirmedByPersonFullName={record.confirmedByPersonFullName}
                    documentStatusId={record.documentStatusId}
                  />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={filteredData.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={filteredData.length === 0}
        />
      </Paper>
    </div>
  );
};
export default withRouter(PaymentDataTable);
