import React from 'react';
import { ReportErrorButton } from '../TableButtons/RowActionButtons/ReportErrorButton';
import AllowedTo from '../../containers/AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import { Box } from '@material-ui/core';

interface Props {
  reportErrorButtonLabel?: string;
  onClicked: () => void;
}

export const ReportErrorAction: React.FC<Props> = ({ reportErrorButtonLabel, onClicked }) => {
  return (
    <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
      <Box width={150}>
        <ReportErrorButton
          label={reportErrorButtonLabel || 'Pranešti apie klaidą'}
          onButtonClicked={onClicked}
        />
      </Box>
    </AllowedTo>
  );
};
