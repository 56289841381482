import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditionCheckDataVirs } from '../../store/editionChecks/editionChecksTypes';
import { EditionCheckDataTableStateContext } from './Context';
import { getEditionCheckDataVirsDocRequest } from '../../store/editionChecks/editionChecksActions';
import GoInputDataButton from '../../components/TableButtons/GoInputDataButton';
import { ReportErrorAction } from '../../components/TableRowActions/ReportErrorAction';
import { ExpandDetailsButton } from '../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { ReportInstitutionDataDialog } from '../../components/ReportInstitutionDataDialog/ReportInstitutionDataDialog';
import { PreviewDocumentDialogMinimal } from '../document/PreviewDocumentDialogMinimal';
import useInstitutionTableRow from '../../utils/hooks/institutionTableRowHook';
import { TableRowExtension } from '../../components/institutionProvidedDataTable/TableRowExtension';
import { OutletsAsLinksList } from '../../components/TableLinks/OutletsAsLinksList';
import { Roles } from '../../store/virsis/dataTypes';

interface EditionCheckDataTableRowProps {
  virsId: number;
  record: EditionCheckDataVirs;
  openDocumentInNewTab?: () => void;
}

const useStyles = makeStyles({
  removePadding: {
    padding: '5px',
    position: 'relative',
    left: '-5px'
  },
  dateColumn: {
    whiteSpace: 'nowrap'
  },
  outletColumnWidth: {
    width: '120px'
  },
  actionColumnWidth: {
    width: '160px',
    boxSizing: 'border-box'
  }
});

export const EditionCheckDataTableRow: React.FC<EditionCheckDataTableRowProps> = ({
  record,
  virsId
}) => {
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const openDocumentInNewTab = (docId: number) => {
    dispatch(getEditionCheckDataVirsDocRequest(docId));
  };

  const {
    rowState,
    toggleExtension,
    closeDocumentPreview,
    openDocumentPreview,
    closeReportDialog,
    openReportDialog
  } = useInstitutionTableRow();

  const totalRowSpan = () => {
    let rowSpan = 0;
    record.editionCheckOutlets.forEach((outlet) => {
      rowSpan += outlet.editionCheckConclusions.length;
    });
    return rowSpan;
  };

  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  record.editionCheckOutlets.forEach((outlet) => {
    outlet.editionCheckConclusions.forEach((conclusion, conclusionIndex) => {
      rows.push(
        <TableRow key={rowIndex}>
          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.checkDate && (
                <TableCell align="left" rowSpan={totalRowSpan()} className={classes.dateColumn}>
                  <ExpandDetailsButton
                    onClick={toggleExtension}
                    showExtension={rowState.showExtension}
                  />
                  {record.checkDate}
                </TableCell>
              )}

              {tableState.columnsDisplayStatus.docNr && (
                <TableCell align="left" rowSpan={totalRowSpan()}>
                  {record.editionCheckDocs.map((doc) => (
                    <GoInputDataButton
                      key={doc.docId}
                      onClicked={() => openDocumentInNewTab(doc.docId)}
                      text={doc.docNr}
                    />
                  ))}
                </TableCell>
              )}
            </>
          )}

          {conclusionIndex === 0 && tableState.columnsDisplayStatus.outletName && (
            <TableCell align="left" rowSpan={outlet.editionCheckConclusions.length}>
              <OutletsAsLinksList outlets={[outlet]} virsId={virsId} />
            </TableCell>
          )}

          {tableState.columnsDisplayStatus.periodName && (
            <TableCell align="left">{conclusion.periodName}</TableCell>
          )}

          {tableState.columnsDisplayStatus.conclusion && (
            <TableCell align="left">{conclusion.conclusion}</TableCell>
          )}

          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.checkedBy && (
                <TableCell align="left" rowSpan={totalRowSpan()}>
                  {record.checkedBy}
                </TableCell>
              )}

              <TableCell
                align="right"
                rowSpan={totalRowSpan()}
                className={classes.actionColumnWidth}
              >
                <ReportErrorAction onClicked={openReportDialog(record.documentStatusId)} />
              </TableCell>
            </>
          )}
        </TableRow>
      );
      rowIndex++;
    });
  });

  return (
    <>
      {rowState.documentStatusId && rowState.showReport && (
        <ReportInstitutionDataDialog
          documentStatusId={rowState.documentStatusId}
          close={closeReportDialog}
          openDocumentPreview={() => openDocumentPreview(record.documentStatusId)}
          isOpen
        />
      )}
      {rowState.showPreview && (
        <PreviewDocumentDialogMinimal
          maxWidth="lg"
          open={true}
          onClose={closeDocumentPreview}
          documentPath={`virs/legals/documents/${rowState.documentStatusId}`}
        />
      )}

      {rows}
      {rowState.showExtension && (
        <TableRowExtension
          creationDate={record.creationDate}
          documentId={record.documentStatusId}
          documentStatus={record.documentStatus}
          openDocumentPreview={openDocumentPreview}
          signDate={record.signDate}
          signedByPerson={record.signedByPerson}
          authorizedRolesToViewDocumentRow={[
            Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT,
            Roles.ROLE_KM_TIRAZO_TIKRINIMAS_VIEW,
            Roles.ROLE_VIRS
          ]}
        />
      )}
    </>
  );
};
