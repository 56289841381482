import React from 'react';

import {
  EthicalNonComplianceColumnHeader,
  EthicalNonComplianceHeaderField
} from './tableState/tableInitialStateAndTypes';
import { useEthicalNonComplianceTableState, useEthicalNonComplianceTableDispatch } from './Context';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../components/TableStatusFilters/TableStatusFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
  customFilterReset,
  showStatusOutdatedToggle,
  showStatusRelevantToggle,
  showStatusUnsignedToggled,
  sortingChanged
} from '../../store/ethicalNonComplianceTable/ethicalNonComplianceTableAction';
import { ApplicationState } from '../../store';

interface Props {
  columnNames: EthicalNonComplianceColumnHeader[];
  disabled?: boolean;
}

export const EthicalNonComplianceTableCustomizationBar: React.FC<Props> = ({
  columnNames,
  disabled
}) => {
  const { state } = useEthicalNonComplianceTableState();
  const { dispatch } = useEthicalNonComplianceTableDispatch();

  const reduxDispatch = useDispatch();

  const { tableDataState } = useSelector(
    (state: ApplicationState) => state.ethicalNonComplianceTableData
  );

  const toggleDisplayCustomFilter = () => {
    reduxDispatch(customFilterReset());
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: EthicalNonComplianceHeaderField) => {
    reduxDispatch(sortingChanged(column));
  };

  const toggleDisplayColumn = (column: EthicalNonComplianceHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    reduxDispatch(showStatusUnsignedToggled());
  };

  const toggleRelevantFilter = () => {
    reduxDispatch(showStatusRelevantToggle());
  };

  const toggleOutdatedFilter = () => {
    reduxDispatch(showStatusOutdatedToggle());
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            allFiltersDisabled={disabled}
            notSignedFilterDisabled={!tableDataState.statusNotSignedFilterEnabled}
            notSignedFilterOn={tableDataState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterDisabled={!tableDataState.statusRelevantFilterEnabled}
            relevantFilterOn={tableDataState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterDisabled={!tableDataState.statusOutdatedFilterEnabled}
            outdatedFilterOn={tableDataState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
          />
        }
        rightSideItem={
          <>
            <TableCustomTools
              allToolsDisabled={disabled}
              tableColumns={columnNames}
              showFilter={state.customFilterOn}
              onFilterTabToggle={toggleDisplayCustomFilter}
              sortOrder={tableDataState.order}
              sortBy={tableDataState.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnsDisplayStatus={state.columnsDisplayStatus}
              toggleDisplayColumn={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
