import { Order } from '../../../../components/TableTypes/TableTypes';
import { LegalPersonSearchData } from '../../../../store/legalPersonData/legalDataTypes';
import { NaturalPersonSearchData } from '../../../../store/persons/personsTypes';
import { ColumnHeader } from '../../../../utils/tableTypes';

export const initialShareholdersSelectedDateTableState: ShareholdersSelectedDateTableState = {
  index: 0,
  order: 'asc',
  sortBy: 'shareholderName',
  columnsDisplayStatus: {
    shareholderName: true,
    shareholderStartDate: true,
    shareholderPercentage: true
  },
  showNonParticipatingShareholders: true,
  participationFilterDisabled: true,
  showShares: false,
  showVotes: true,
  virsStartDate: null,
  lastDate: '',
  isPersonSearchDialogOpen: false,
  parentIdToAddChild: undefined,
  parentPersonLevelIdToAddChild: {
    level: undefined,
    id: undefined
  },
  showEditShareholderRow: false,
  showAddShareholderRow: false,
  isShareholderOpen: false,
  isParentOpen: false,
  isSelectParentDialogOpen: false,
  showSelectShareholderRow: false,
  isGroup: false,
  shareholderPerson: undefined,
  shareholderPersonsList: [],
  personId: undefined,
  authorizedPersonId: null,
  shouldScroll: false,
  addingNewDate: false,
  newDate: undefined,
  personToAdd: undefined,
  shareholderToEditList: [],
  shareholdersLevelList: [],
  customFilter: {
    shareholderLevel: []
  },
  defaultFilterLevelValue: false
};

export type ShareholdersSelectedDateHeaderField =
  | 'shareholderStartDate'
  | 'shareholderName'
  | 'shareholderPercentage';

export type ShareholdersSelectedDateColumnsDisplayStatus = {
  shareholderStartDate: boolean;
  shareholderName: boolean;
  shareholderPercentage: boolean;
};

export type ShareholdersSelectedDateColumnHeader =
  ColumnHeader<ShareholdersSelectedDateHeaderField>;

export type SelectedDateTableFilterFields = 'shareholderLevel';

export interface SelectedDateTableCustomFilter {
  shareholderLevel: string[];
}

export interface ShareholderLevelId {
  level?: number;
  id?: number;
}

export interface ShareholderPathValues {
  shareholderLevel: number;
  shareholderId: number;
  parentLevel: number;
  parentId: number;
  isParentLastChild?: boolean;
}

export type ShareholderGroupPerson = {
  personId: number;
  personType: string;
  personName: string;
  legalPersonCode?: number | string | null;
  shouldSynchronize?: boolean;
};

type ShareholderParentInfo = {
  parentLevel: number;
  parentId: string;
  parentPersonId: number;
  isParentLastChild: boolean;
};

export type ShareholderChildParents = {
  [key: string]: ShareholderParentInfo;
};

type ShareholderParentPersonsInfo = {
  parentLevel: number;
  parentId: string;
  parentPersonId: number;
};

export type ShareholderChildParentPersons = {
  [key: number]: ShareholderParentPersonsInfo;
};

export interface ShareholdersSelectedDateTableState {
  sortBy: ShareholdersSelectedDateHeaderField;
  order: Order;
  columnsDisplayStatus: ShareholdersSelectedDateColumnsDisplayStatus;
  showNonParticipatingShareholders: boolean;
  participationFilterDisabled: boolean;
  showShares: boolean;
  showVotes: boolean;
  virsStartDate?: string | null;
  lastDate?: string;
  isPersonSearchDialogOpen: boolean;
  parentIdToAddChild?: number;
  parentPersonLevelIdToAddChild: ShareholderLevelId;
  showEditShareholderRow: boolean;
  showAddShareholderRow: boolean;
  isShareholderOpen: boolean;
  isParentOpen: boolean;
  isSelectParentDialogOpen: boolean;
  showSelectShareholderRow: boolean;
  isGroup: boolean;
  shareholderPerson?: ShareholderGroupPerson[];
  shareholderPersonsList: ShareholderGroupPerson[];
  personId?: number;
  authorizedPersonId?: number | null;
  shouldScroll: boolean;
  addingNewDate: boolean;
  newDate?: string;
  personToAdd?: NaturalPersonSearchData | LegalPersonSearchData;
  shareholderToEditList?: ShareholderGroupPerson[];
  shareholdersLevelList: string[];
  customFilter: SelectedDateTableCustomFilter;
  defaultFilterLevelValue: boolean;
  index: number;
}

export type ShareholdersSelectedDateTableAction =
  | {
      type: 'NON_PARTICIPATING_SHAREHOLDERS_DISABLED';
      participationFilterDisabled: boolean;
    }
  | { type: 'NON_PARTICIPATING_SHAREHOLDERS_TOGGLED' }
  | { type: 'SET_SHOW_SHARES_VOTES'; showShares: boolean; showVotes: boolean }
  | { type: 'SET_SELECTED_DATE'; selectedDate: string }
  | { type: 'IS_FIND_PERSON_DIALOG_OPEN'; isPersonSearchDialogOpen: boolean; index?: number }
  | {
      type: 'SET_PARENT_ID_TO_ADD_CHILD';
      id: number;
      index: number;
    }
  | {
      type: 'SET_PARENT_PERSON_LEVEL_ID_TO_ADD_CHILD';
      level?: number;
      id?: number;
    }
  | {
      type: 'SHOW_EDIT_SHAREHOLDER_ROW';
      showEditShareholderRow: boolean;
      index: number;
      shareholderToEditList: ShareholderGroupPerson[];
      authorizedPersonId?: number | null;
      personToAdd?: NaturalPersonSearchData | LegalPersonSearchData;
    }
  | {
      type: 'SHOW_ADD_SHAREHOLDER_ROW';
      showAddShareholderRow: boolean;
      personToAdd: NaturalPersonSearchData | LegalPersonSearchData;
    }
  | {
      type: 'IS_SELECT_PARENT_DIALOG_OPEN';
      isOpen: boolean;
    }
  | {
      type: 'SHOW_SELECT_SHAREHOLDER_ROW';
      showSelectShareholderRow: boolean;
    }
  | { type: 'IS_SELECTED_SHAREHOLDER_GROUP'; isGroup: boolean }
  | {
      type: 'SET_SHAREHOLDER_PERSONS_LIST';
      shareholderPerson: ShareholderGroupPerson[];
    }
  | { type: 'UPDATE_SHAREHOLDER_PERSONS_LIST' }
  | { type: 'EDIT_SHAREHOLDER_ROW_CLOSE' }
  | { type: 'REMOVE_SHAREHOLDER_PERSON'; personId: number }
  | { type: 'SET_AUTHORIZED_PERSON_ID'; authorizedPersonId: number | null }
  | { type: 'SET_SHOULD_SCROLL_TO_SHAREHOLDER'; shouldScroll: boolean }
  | { type: 'SORTING_CHANGED'; sortBy: ShareholdersSelectedDateHeaderField }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: SelectedDateTableFilterFields;
      value: string | null;
    }
  | { type: 'SET_SHAREHOLDERS_LEVEL_LIST'; shareholdersLevelList: string[] }
  | { type: 'SET_DEFAULT_LEVEL_VALUE'; defaultFilterLevelValue: boolean };
