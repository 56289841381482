import { action } from 'typesafe-actions';
import { ShareholderGroupPerson } from '../../containers/ShareholdersTable/SelectedDateTable/tableState/tableInitialStateAndTypes';
import {
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST,
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_SUCCESS,
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_ERROR,
  SHAREHOLDERS_DATA_REQUEST,
  SHAREHOLDERS_DATA_REQUEST_SUCCESS,
  SHAREHOLDERS_DATA_REQUEST_ERROR,
  SET_SELECTED_DATE,
  SHAREHOLDER_DOCUMENT_PDF_REQUEST,
  DELETE_SHAREHOLDERS_REQUEST,
  DELETE_SHAREHOLDERS_REQUEST_SUCCESS,
  DELETE_SHAREHOLDERS_REQUEST_ERROR,
  ADD_SHAREHOLDER_PERCENTAGE_REQUEST,
  ADD_SHAREHOLDER_PERCENTAGE_SUCCESS,
  ADD_SHAREHOLDER_PERCENTAGE_ERROR,
  SYNCHRONISE_WITH_JADIS_REQUEST,
  SYNCHRONISE_WITH_JADIS_SUCCESS,
  SYNCHRONISE_WITH_JADIS_ERROR,
  FETCH_VIRS_PERSONS_LIST_REQUEST,
  FETCH_VIRS_PERSONS_LIST_SUCCESS,
  FETCH_VIRS_PERSONS_LIST_ERROR,
  CREATE_SHAREHOLDER_REQUEST,
  CREATE_SHAREHOLDER_SUCCESS,
  CREATE_SHAREHOLDER_ERROR,
  CREATE_SHAREHOLDER_STATE_RESET,
  UPDATE_SHAREHOLDER_REQUEST,
  UPDATE_SHAREHOLDER_SUCCESS,
  UPDATE_SHAREHOLDER_ERROR,
  ADDING_SHAREHOLDER_DATE,
  ADDING_SHAREHOLDER_DATE_RESET,
  DELETE_DATE_SUCCESS,
  DELETE_DATE_ERROR,
  DELETE_DATA_BY_DATE_SUCCESS,
  DELETE_DATA_BY_DATE_ERROR,
  SHOW_DATE_DELETION_DIALOG,
  RESET_DATE_DELETION_DIALOG,
  DELETE_DATE_REQUEST,
  DELETE_DATA_BY_DATE_REQUEST,
  SHOW_DATA_DELETION_BY_DATE_DIALOG,
  RESET_DATA_DELETION_BY_DATE_DIALOG,
  SHOW_SHAREHOLDER_DELETION_DIALOG,
  RESET_SHAREHOLDER_DELETION_DIALOG,
  SHOW_CLEAR_SHAREHOLDER_EVENT_DIALOG,
  RESET_CLEAR_SHAREHOLDER_EVENT_DIALOG,
  CLEAR_SHAREHOLDER_EVENT_REQUEST,
  CLEAR_SHAREHOLDER_EVENT_SUCCESS,
  CLEAR_SHAREHOLDER_EVENT_ERROR,
  ADD_SHAREHOLDER_PERCENTAGE_RESET,
  SET_UPDATE_SHAREHOLDER_ID,
  UNSET_UPDATE_SHAREHOLDER_ID,
  UPDATE_SHAREHOLDER_STATE_RESET,
  SET_EXPAND_SHAREHOLDER,
  SET_EXPAND_PARENTS,
  SET_ALL_SHAREHOLDERS,
  SET_EXPAND_FILTERED_PARENTS,
  RESET_EXPAND_SHAREHOLDERS,
  RESET_SHAREHOLDERS_DATA,
  FILTER_UNSIGNED_SHAREHOLDERS_TOGGLED,
  SET_LAST_SIGNED_DATE
} from './shareholdersActionTypes';
import {
  AddPercentageRecord,
  AddPersonDialogElement,
  ClearShareholderPercentageRecord,
  CreatedShareholderData,
  RecordToSynchronise,
  ShareholderRecord,
  ShareholdersData,
  ShareholdersProps,
  ShareholderTableLocation
} from './shareholdersTypes';
import { Moment } from 'moment';

export const fetchShareholdersSelectedDateDataRequest = (virsId: number, date: string) =>
  action(SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST, { virsId, date });
export const fetchShareholdersSelectedDateDataSuccess = (data: ShareholdersData) =>
  action(SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_SUCCESS, data);
export const fetchShareholdersSelectedDateDataError = (error: Error) =>
  action(SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_ERROR, error);

export const getShareholdersRequest = (virId: number, isShowUnsigned: boolean) =>
  action(SHAREHOLDERS_DATA_REQUEST, { virId, isShowUnsigned });
export const getShareholdersSuccess = (data: ShareholdersData) =>
  action(SHAREHOLDERS_DATA_REQUEST_SUCCESS, data);
export const getShareholdersError = (error: Error) =>
  action(SHAREHOLDERS_DATA_REQUEST_ERROR, error);

export const setSelectedDate = (date?: string) => action(SET_SELECTED_DATE, date);

export const getShareholderDocumentRequest = (documentId: number) =>
  action(SHAREHOLDER_DOCUMENT_PDF_REQUEST, documentId);

export const addShareholderPercentageRequest = (
  percentageRecord: AddPercentageRecord,
  virsId: number,
  date: string
) =>
  action(ADD_SHAREHOLDER_PERCENTAGE_REQUEST, {
    percentageRecord,
    virsId,
    date
  });
export const addShareholderPercentageSuccess = () => action(ADD_SHAREHOLDER_PERCENTAGE_SUCCESS);
export const addShareholderPercentageError = (error: Error) =>
  action(ADD_SHAREHOLDER_PERCENTAGE_ERROR, error);
export const resetAddShareholderPercentage = () => action(ADD_SHAREHOLDER_PERCENTAGE_RESET);

export const synchroniseWithJADISRequest = (record: RecordToSynchronise, personName: string) =>
  action(SYNCHRONISE_WITH_JADIS_REQUEST, { record, personName });
export const synchroniseWithJADISSuccess = (response: boolean) =>
  action(SYNCHRONISE_WITH_JADIS_SUCCESS, response);
export const synchroniseWithJADISError = (error: Error) =>
  action(SYNCHRONISE_WITH_JADIS_ERROR, error);

export const fetchVirsPersonsListRequest = (virsId: number, date: string | null) =>
  action(FETCH_VIRS_PERSONS_LIST_REQUEST, { virsId, date });
export const fetchVirsPersonsListSuccess = (data: AddPersonDialogElement[]) =>
  action(FETCH_VIRS_PERSONS_LIST_SUCCESS, data);
export const fetchVirsPersonsListError = (error: Error) =>
  action(FETCH_VIRS_PERSONS_LIST_ERROR, error);

export const createShareholderRequest = (
  record: ShareholderRecord,
  virsId: number,
  selectedDate: string,
  personsFromJADIS: ShareholderGroupPerson[]
) =>
  action(CREATE_SHAREHOLDER_REQUEST, {
    record,
    virsId,
    selectedDate,
    personsFromJADIS
  });
export const createShareholderSuccess = (data: CreatedShareholderData) =>
  action(CREATE_SHAREHOLDER_SUCCESS, data);
export const createShareholderError = (error: Error) => action(CREATE_SHAREHOLDER_ERROR, error);
export const createShareholderStateReset = () => action(CREATE_SHAREHOLDER_STATE_RESET);

export const updateShareholderRequest = (
  record: ShareholderRecord,
  shareholderId: number,
  virsId: number,
  selectedDate: string,
  personsFromJADIS: ShareholderGroupPerson[]
) =>
  action(UPDATE_SHAREHOLDER_REQUEST, {
    record,
    shareholderId,
    virsId,
    selectedDate,
    personsFromJADIS
  });
export const updateShareholderSuccess = (data: CreatedShareholderData) =>
  action(UPDATE_SHAREHOLDER_SUCCESS, data);
export const updateShareholderError = (error: Error) => action(UPDATE_SHAREHOLDER_ERROR, error);
export const setUpdateShareholderId = (id?: number) => action(SET_UPDATE_SHAREHOLDER_ID, id);
export const unsetUpdateShareholderId = () => action(UNSET_UPDATE_SHAREHOLDER_ID);
export const updateShareholderStateReset = () => action(UPDATE_SHAREHOLDER_STATE_RESET);

/** ADD NEW DATE */
export const addShareholderDate = (date: string) => action(ADDING_SHAREHOLDER_DATE, date);
export const addShareholderDateReset = () => action(ADDING_SHAREHOLDER_DATE_RESET);

/** CLEAR SHAREHOLDER EVENT */
export const showClearShareholderEventDialog = (
  tempPercentageRecord: ClearShareholderPercentageRecord,
  date: string
) => action(SHOW_CLEAR_SHAREHOLDER_EVENT_DIALOG, { tempPercentageRecord, date });
export const resetClearShareholderEventDialog = () => action(RESET_CLEAR_SHAREHOLDER_EVENT_DIALOG);
export const clearShareholderEventRequest = (
  tempPercentageRecord: ClearShareholderPercentageRecord,
  date: string,
  virId: number
) =>
  action(CLEAR_SHAREHOLDER_EVENT_REQUEST, {
    tempPercentageRecord,
    date,
    virId
  });
export const clearShareholderEventSuccess = () => action(CLEAR_SHAREHOLDER_EVENT_SUCCESS);
export const clearShareholderEventError = (error: Error) =>
  action(CLEAR_SHAREHOLDER_EVENT_ERROR, error);

/** DELETE DATA BY DATE */
export const showShareholderDataDeletionByDateDialog = (date?: string) =>
  action(SHOW_DATA_DELETION_BY_DATE_DIALOG, date);
export const resetShareholderDataDeletionByDateDialog = () =>
  action(RESET_DATA_DELETION_BY_DATE_DIALOG);
export const deleteShareholderDataByDateRequest = (
  virId: number,
  location: ShareholderTableLocation,
  date?: string
) => action(DELETE_DATA_BY_DATE_REQUEST, { virId, date, location });
export const deleteShareholderDataByDateSuccess = () => action(DELETE_DATA_BY_DATE_SUCCESS);
export const deleteShareholderDataByDateError = (error: Error) =>
  action(DELETE_DATA_BY_DATE_ERROR, error);

/** DELETE DATE */
export const deleteShareholderDateRequest = (virId: number, date: string) =>
  action(DELETE_DATE_REQUEST, { virId, date });
export const deleteShareholderDateSuccess = () => action(DELETE_DATE_SUCCESS);
export const deleteShareholderDateError = (error: Error) => action(DELETE_DATE_ERROR, error);
export const showShareholderDateDeletionDialog = (date: string) =>
  action(SHOW_DATE_DELETION_DIALOG, date);
export const resetShareholderDateDeletionDialog = () => action(RESET_DATE_DELETION_DIALOG);

/** DELETE SHAREHOLDER */
export const deleteShareholderRequest = (
  virId: number,
  shareholderId: number,
  date: string,
  location: ShareholderTableLocation
) => action(DELETE_SHAREHOLDERS_REQUEST, { virId, shareholderId, date, location });
export const deleteShareholderSuccess = () => action(DELETE_SHAREHOLDERS_REQUEST_SUCCESS);
export const deleteShareholderDataError = (err: Error) =>
  action(DELETE_SHAREHOLDERS_REQUEST_ERROR, err);
export const showShareholderDeletionDialog = (date: string, shareholderId: number) =>
  action(SHOW_SHAREHOLDER_DELETION_DIALOG, { date, shareholderId });
export const resetShareholderDeletionDialog = () => action(RESET_SHAREHOLDER_DELETION_DIALOG);

/** EXPANDING SHAREHOLDER */
export const setExpandShareholder = (
  uniqueShareholderId?: string,
  uniquePersonId?: string,
  index?: number
) => action(SET_EXPAND_SHAREHOLDER, { uniqueShareholderId, uniquePersonId, index });
export const setExpandParents = (
  shareholderPath?: string,
  personIdPath?: string[],
  index?: number
) => action(SET_EXPAND_PARENTS, { shareholderPath, personIdPath, index });
export const setExpandFilteredParents = (
  filteredShareholderPaths: string[],
  filteredPersonIds: any
) => action(SET_EXPAND_FILTERED_PARENTS, { filteredShareholderPaths, filteredPersonIds });
export const resetExpandShareholders = () => action(RESET_EXPAND_SHAREHOLDERS);

export const resetShareholdersData = () => action(RESET_SHAREHOLDERS_DATA);

export const setAllShareholdersFlat = (shareholders: ShareholdersProps[]) =>
  action(SET_ALL_SHAREHOLDERS, shareholders);

export const filterUnsignedToggled = (isShowUnsigned: boolean) =>
  action(FILTER_UNSIGNED_SHAREHOLDERS_TOGGLED, isShowUnsigned);

/** LAST SIGNED DATE **/
export const setLastSignedDate = (lastSignedDate: Moment | undefined) =>
  action(SET_LAST_SIGNED_DATE, lastSignedDate);
