import { get, post, put, axiosDelete } from '../../utils/axiosApi';
import {
  OutletEditionRecord,
  OutletCategoryRecord,
  OutletRepresentativeRecord,
  OutletAdRecord
} from './outletInfoDataTypes';

export const getOutletInfoData = (virId: number, outletId: number) =>
  get(`/outlet-info/${virId}/${outletId}`);

export const postCreateEdition = (
  virId: number | undefined,
  outletId: number | undefined,
  record: OutletEditionRecord
) => {
  return post(`/outlet-info/${virId}/${outletId}/edition`, record);
};

export const putUpdateEdition = (
  virId: number | undefined,
  outletId: number | null,
  editionId: number | null,
  record: OutletEditionRecord
) => {
  return put(`/outlet-info/${virId}/${outletId}/edition/${editionId}`, record);
};

export const deleteRemoveEdition = (
  virId: number | undefined,
  outletId: number | null,
  editionId: number | null
) => {
  return axiosDelete(`/outlet-info/${virId}/${outletId}/edition/${editionId}`);
};

export const getOutletEditionPeriods = (outletId?: number) =>
  get(`/outlet-info/${outletId}/edition-periods`);

export const postCreateRepresentative = (
  virId: number | undefined,
  outletId: number | undefined,
  record: OutletRepresentativeRecord
) => {
  return post(`/outlet-info/${virId}/${outletId}/representative`, record);
};

export const putUpdateRepresentative = (
  virId: number | undefined,
  outletId: number | null,
  representativeId: number | null,
  record: OutletRepresentativeRecord
) => {
  return put(`/outlet-info/${virId}/${outletId}/representative/${representativeId}`, record);
};

export const deleteRemoveRepresentative = (
  virId: number | undefined,
  outletId: number | null,
  representativeId: number | null
) => {
  return axiosDelete(`/outlet-info/${virId}/${outletId}/representative/${representativeId}`);
};

export const postCreateCategory = (
  outletId: number | undefined,
  newCategory: OutletCategoryRecord
) => {
  return post(`/outlet-info/${outletId}/category`, newCategory);
};

export const putUpdateCategory = (
  outletId: number | null,
  outletCategoryId: number | null,
  record: OutletCategoryRecord
) => {
  return put(`/outlet-info/${outletId}/category/${outletCategoryId}`, record);
};

export const deleteRemoveCategory = (outletId: number | null, outletCategoryId: number | null) => {
  return axiosDelete(`/outlet-info/${outletId}/category/${outletCategoryId}`);
};

export const postCreateAdvertisement = (
  outletId: number | undefined,
  newAdvertisement: OutletAdRecord
) => {
  return post(`/outlet-info/${outletId}/ad`, newAdvertisement);
};

export const putUpdateAdvertisement = (
  outletId: number | null,
  outletAdPrintingId: number | null,
  record: OutletAdRecord
) => {
  return put(`/outlet-info/${outletId}/ad/${outletAdPrintingId}`, record);
};

export const deleteRemoveAdvertisement = (
  outletId: number | null,
  advertisementId: number | null
) => {
  return axiosDelete(`/outlet-info/${outletId}/ad/${advertisementId}`);
};
