import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EnterpriseDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { EnterpriseType, EnterpriseTypeShort } from '../../../store/classifiers/classifiersTypes';
import { EnterpriseTableRowEditOff } from './RowEditOff';
import { editRnterpriseRecordReducer } from './rowState/editRecordReducer';
import { intialEnterpriseEditRecordState } from './rowState/rowInitialStateAndTypes';
import { EnterpriseDataApiFunctions } from '../Table';
import { ApplicationState } from '../../../store';
import { EnterpriseTableRowEditOn } from './RowEditOn';
import { EnterpriseTableRowEditOnValidToOnly } from './RowEditOnValidToOnly';
import { resetRemoveEnterpriseState } from '../../../store/enterprises/enterprisesActions';
import {
  validateEditedEnterpriseRecord,
  validateEditedValidTo
} from './rowState/rowReducerFunctions';

interface Props {
  record: EnterpriseData;
  columnsToDisplay: EnterpriseDataTableColumnsDisplayStatus;
  enterpriseTypes: EnterpriseType[];
  apiFunctions: EnterpriseDataApiFunctions;
  resetColumnDisplay: () => void;
  toggleRowExtension: (enterpriseId: number) => void;
  isExtended: boolean;
  legalRegistrationDate: string;
  legalDeregistrationDate: string | null;
}

export const EnterpriseTableRowContainer: React.FC<Props> = ({
  record,
  enterpriseTypes,
  apiFunctions,
  columnsToDisplay,
  resetColumnDisplay,
  toggleRowExtension,
  isExtended,
  legalRegistrationDate,
  legalDeregistrationDate
}) => {
  const [rowState, rowDispatch] = useReducer(
    editRnterpriseRecordReducer,
    intialEnterpriseEditRecordState
  );

  const {
    removingEnterprise,
    removingEnterpriseId,
    isEnterpriseRemoved,
    removingEnterpriseError,
    updatingEnterprise,
    updatingEnterpriseId
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal.enterpriseData);
  const reduxDispatch = useDispatch();

  function toggleExtension() {
    toggleRowExtension(record.enterpriseId);
  }

  function toggleEditing() {
    if (rowState.editingOn) {
      rowDispatch({ type: 'RESET_RECORD_STATE' });
    } else {
      rowDispatch({
        type: 'INITIALIZE_RECORD_STATE',
        record,
        enterpriseTypes,
        legalRegistrationDate,
        legalDeregistrationDate
      });
    }
    resetColumnDisplay();
    rowDispatch({ type: 'TOGGLE_EDITING_RECORD' });
  }

  function setRecordStateEnterpriseType(enterpriseType: EnterpriseTypeShort | null) {
    rowDispatch({
      type: 'ENTERPRISE_TYPE_CHANGED',
      enterpriseType
    });
  }

  function setRecordStateValidFrom(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'VALID_FROM_CHANGED',
      validFrom
    });
  }

  function setRecordStateValidTo(validTo: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'VALID_TO_CHANGED',
      validTo
    });
  }

  function updateEnterprise() {
    rowDispatch({ type: 'SHOW_UPDATE_RECORD_CONFIRMATION_OR_ERRORS' });
    if (validateEditedEnterpriseRecord(rowState).updateRecordConfirmationOn) {
      apiFunctions.updateEnterpriseRecord(
        record.enterpriseId,
        record.enterpriseTypeId,
        {
          enterpriseTypeId: rowState.enterpriseType.value
            ? rowState.enterpriseType.value.enterpriseTypeId
            : 0,
          validFrom: rowState.validFrom.value
            ? rowState.validFrom.value.format('L').toString()
            : null,
          validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null
        },
        'Atnaujintas veiklos rūšių įrašas'
      );
    }
  }

  function updateEnterpriseValidToDate() {
    rowDispatch({ type: 'SHOW_UPDATE_DATE_CONFIRMATION_OR_ERRORS' });
    if (validateEditedValidTo(rowState).updateDateConfirmationOn) {
      apiFunctions.updateEnterpriseRecord(
        record.enterpriseId,
        record.enterpriseTypeId,
        {
          enterpriseTypeId: record.enterpriseTypeId,
          validFrom: record.validFrom,
          validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null
        },
        'Atnaujinta pabaigos data'
      );
    }
  }

  function unsetEnterpriseValidToDate() {
    apiFunctions.updateEnterpriseRecord(
      record.enterpriseId,
      record.enterpriseTypeId,
      {
        enterpriseTypeId: record.enterpriseTypeId,
        validFrom: record.validFrom,
        validTo: null
      },
      'Atšaukta galiojimo pabaigos data'
    );
  }

  function handleRemoveRecordButtonClick() {
    rowDispatch({ type: 'DISPLAY_REMOVE_RECORD_CONFIRMATION', status: true });
  }

  function closeRemoveRecordConfirmation() {
    rowDispatch({ type: 'DISPLAY_REMOVE_RECORD_CONFIRMATION', status: false });
  }

  function removeEnterprise() {
    apiFunctions.removeEnterpriseRecord(record.enterpriseId);
  }

  function onRemoveError() {
    rowDispatch({ type: 'DISPLAY_REMOVE_RECORD_CONFIRMATION', status: false });
    reduxDispatch(resetRemoveEnterpriseState());
  }

  const colSpanForExtensionRow = () => {
    return (
      [
        columnsToDisplay.enterpriseTypeName,
        columnsToDisplay.validFrom,
        columnsToDisplay.validTo,
        columnsToDisplay.authorName
      ].filter((col) => col).length + 1
    );
  };

  return (
    <>
      {record.editable && rowState.editingOn && (
        <EnterpriseTableRowEditOn
          record={record}
          rowState={rowState}
          columnsToDisplay={columnsToDisplay}
          setEnterpriseType={setRecordStateEnterpriseType}
          setValidFrom={setRecordStateValidFrom}
          setValidTo={setRecordStateValidTo}
          updateRecord={updateEnterprise}
          closeEditing={toggleEditing}
          updatingEnterprise={updatingEnterpriseId === record.enterpriseId && updatingEnterprise}
        />
      )}
      {record.editableValidToOnly && rowState.editingOn && (
        <EnterpriseTableRowEditOnValidToOnly
          record={record}
          rowState={rowState}
          columnsToDisplay={columnsToDisplay}
          colSpanForExtensionRow={colSpanForExtensionRow()}
          toggleExtension={toggleExtension}
          setValidTo={setRecordStateValidTo}
          updateValidToDate={updateEnterpriseValidToDate}
          closeEditing={toggleEditing}
          isExtended={isExtended}
          updatingEnterprise={updatingEnterpriseId === record.enterpriseId && updatingEnterprise}
        />
      )}
      {!rowState.editingOn && (
        <EnterpriseTableRowEditOff
          record={record}
          rowState={rowState}
          columnsToDisplay={columnsToDisplay}
          colSpanForExtensionRow={colSpanForExtensionRow()}
          toggleExtension={toggleExtension}
          toggleEditing={toggleEditing}
          handleRemoveRecordButtonClick={handleRemoveRecordButtonClick}
          removeEnterprise={removeEnterprise}
          closeRemoveRecordConfirmation={closeRemoveRecordConfirmation}
          removingEnterprise={removingEnterpriseId === record.enterpriseId && removingEnterprise}
          isEnterpriseRemoved={isEnterpriseRemoved}
          removingEnterpriseError={removingEnterpriseError}
          onRemoveError={onRemoveError}
          unsetValidToDate={unsetEnterpriseValidToDate}
          isExtended={isExtended}
          updatingEnterprise={updatingEnterpriseId === record.enterpriseId && updatingEnterprise}
        />
      )}
    </>
  );
};
