import { makeStyles, Button } from '@material-ui/core';
import React from 'react';

interface Props {
  showTriangleButton: boolean;
  onClicked?: () => void;
  expandChild: boolean;
  marginLeft: number;
}

const useStyles = makeStyles({
  button: {
    margin: '0',
    width: '25px',
    minWidth: '25px',
    height: '35px',
    boxSizing: 'border-box',
    position: 'relative'
  },
  rotateDown: {
    transform: 'rotate(180deg)'
  },
  buttonPlaceholder: ({ marginLeft }: { marginLeft: number }) => ({
    width: '25px',
    minWidth: '25px',
    marginLeft
  })
});

export const TriangleButton: React.FC<Props> = ({
  showTriangleButton,
  onClicked,
  expandChild,
  marginLeft
}) => {
  const classes = useStyles({ marginLeft });

  return (
    <>
      {showTriangleButton ? (
        <Button
          style={{
            marginLeft
          }}
          className={classes.button}
          onClick={onClicked}
        >
          <span className={expandChild ? classes.rotateDown : undefined}>⏷</span>
        </Button>
      ) : (
        <div className={classes.buttonPlaceholder} />
      )}
    </>
  );
};
