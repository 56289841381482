import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import { parametersTableReducer } from './tableState/tableReducer';
import {
  ParametersDataTableState,
  ParametersDataTableAction,
  initialParametersDataTableState
} from './tableState/tableInitialStateAndTypes';
import ParametersDataTable from './Table';

const ParametersDataTableStateContext = createContext<{
  state: ParametersDataTableState;
}>({
  state: initialParametersDataTableState
});

const ParametersDataTableDispatchContext = createContext<{
  dispatch: Dispatch<ParametersDataTableAction>;
}>({
  dispatch: () => {}
});

export const ParametersDataVirsTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(parametersTableReducer, initialParametersDataTableState);
  return (
    <ParametersDataTableStateContext.Provider value={{ state }}>
      <ParametersDataTableDispatchContext.Provider value={{ dispatch }}>
        <ParametersDataTable />
      </ParametersDataTableDispatchContext.Provider>
    </ParametersDataTableStateContext.Provider>
  );
};

export function useParametersDataTableState() {
  return useContext(ParametersDataTableStateContext);
}
export function useParametersDataTableDispatch() {
  return useContext(ParametersDataTableDispatchContext);
}
