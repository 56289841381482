import React from 'react';
import { IconButton, Paper, Typography, makeStyles } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';
import theme from '../../../style/virsisTheme';
import { useDialogFormStyles } from '../../../components/FormikFields/FieldStyles';
import { GraphicalRecord } from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';
import { FormikArrayCheckBox } from './formFields/ArrayCheckBox';
import { ApplicationState } from '../../../store';
import { Box } from '@material-ui/core/';

interface Props {
  id: string;
  index: number;
  graphicalRecord: GraphicalRecord;
}

const useStyles = makeStyles((theme) => ({
  cardTitleLabel: {
    width: 185,
    margin: 'auto'
  },
  icon: {
    float: 'right',
    width: 30,
    height: 30
  },
  [theme.breakpoints.down('sm')]: {
    cardTitleLabel: {
      marginLeft: 0
    },
    icon: {
      marginBottom: 5
    }
  },
  [theme.breakpoints.down('xs')]: {
    cardTitleContainer: {
      marginBottom: 10
    },
    cardTitleLabel: {
      width: '100%'
    }
  }
}));

const GraphicalRepresentationStaticRecordRow: React.FC<Props> = ({
  index,
  id,
  graphicalRecord
}) => {
  const styledClasses = useDialogFormStyles();
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const { showActiveRecords } = useSelector(
    (state: ApplicationState) => state.graphicalRepresentation
  );

  if (showActiveRecords && !graphicalRecord.isActive) {
    return <></>;
  }

  return (
    <>
      <Paper
        style={{
          margin: '1rem 0',
          padding: '1rem',
          background: theme.palette.background.default,
          transform: CSS.Transform.toString(transform),
          transition: transition || 'inherit'
        }}
      >
        <IconButton ref={setNodeRef} {...attributes} {...listeners} className={classes.icon}>
          <DragIndicator />
        </IconButton>
        <Box
          className={styledClasses.inputContainer + ' ' + classes.cardTitleContainer}
          style={{ gridTemplateColumns: '185px 320px 30px' }}
        >
          <Typography
            className={styledClasses.inputLabel + ' ' + classes.cardTitleLabel}
            variant="h5"
          >
            Kortelės pavadinimas LT:
          </Typography>
          <Typography style={{ margin: 'auto 0 auto 0' }} variant="h4">
            {graphicalRecord.tableNameLt}
          </Typography>
        </Box>
        <Box
          className={styledClasses.inputContainer}
          style={{ gridTemplateColumns: '185px 320px 30px' }}
        >
          <Typography
            className={styledClasses.inputLabel + ' ' + classes.cardTitleLabel}
            variant="h5"
          >
            Kortelės pavadinimas ENG:
          </Typography>
          <Typography style={{ margin: 'auto 0 auto 0' }} variant="h4">
            {graphicalRecord.tableNameEn}
          </Typography>
        </Box>
        <FormikArrayCheckBox
          label="Aktyvi"
          arrayName="records"
          field="isActive"
          index={index}
          styledClasses={styledClasses}
        />
      </Paper>
    </>
  );
};

export default GraphicalRepresentationStaticRecordRow;
