import React, { useState } from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import {
  ShareholdersProps,
  ShareholdersData,
  ShareholdersHistory,
  ShareholderPerson
} from '../../../../../store/shareholders/shareholdersTypes';
import { AddPercentageDialog } from './AddPercentageDialog';

const useStyles = makeStyles((theme) => ({
  button: ({ textColor, barWidth }: { textColor: string; barWidth: string }) => ({
    alignSelf: 'center',
    background: 'none',
    border: 'none',
    borderRadius: 0,
    color: textColor,
    display: 'flex',
    justifyContent: barWidth === '100%' ? 'center' : 'start',
    margin: 0,
    maxHeight: 24,
    width: '100%'
  }),
  editIcon: ({ textColor }: { textColor: string }) => ({
    color:
      textColor === theme.palette.secondary.light
        ? theme.palette.info.light
        : theme.palette.info.dark,
    fontSize: 15,
    margin: 0,
    padding: '0 7px 0 0'
  })
}));

export interface Props {
  barWidth: string;
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson;
  popoverId: string;
  shareholder: ShareholdersProps;
  shareholderEvent: ShareholdersHistory;
  textColor: string;
}

export const AddPercentageContainer: React.FC<Props> = ({
  barWidth,
  children,
  parentShareholder,
  popoverId,
  shareholder,
  shareholderEvent,
  textColor
}) => {
  const classes = useStyles({ textColor, barWidth });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton id={popoverId} onClick={(event) => handleOpen(event)} className={classes.button}>
        {children}
        <EditRoundedIcon className={classes.editIcon} />
      </IconButton>
      <AddPercentageDialog
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        parentShareholder={parentShareholder}
        popoverId={popoverId}
        shareholder={shareholder}
        shareholderEvent={shareholderEvent}
      />
    </>
  );
};
