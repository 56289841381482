import { action } from 'typesafe-actions';
import { Moment } from 'moment';
import {
  LOAD_TIMELINE_EVENTS,
  SET_TIMELINE_ACTIVE_DATE,
  SET_TIMELINE_ERROR,
  SET_TIMELINE_EVENTS,
  SET_TIMELINE_LOADING,
  VIRS_TREE_ERROR,
  VIRS_TREE_REQUEST,
  VIRS_TREE_REQUEST_OF_DATE,
  VIRS_TREE_SUCCESS
} from './virsTreeActionTypes';
import { ApiVirsTree, TimelineEvent } from './virsTreeDataTypes';
import { ActivityPeriod } from '../shareholders/shareholdersTypes';

export const fetchVirsTreeData = (date: string, virsId: number) =>
  action(VIRS_TREE_REQUEST, { date, virsId });

export const fetchVirsDataOfDate = (date: string) => action(VIRS_TREE_REQUEST_OF_DATE, date);

export const fetchVirsDataSuccess = (data: ApiVirsTree) => action(VIRS_TREE_SUCCESS, data);

export const fetchVirsDataError = (error: Error) => action(VIRS_TREE_ERROR, error);

export const setTimelineActiveDate = (activeDate: Moment) =>
  action(SET_TIMELINE_ACTIVE_DATE, activeDate);

export const setTimelineLoading = (isLoading: boolean) => action(SET_TIMELINE_LOADING, isLoading);

export const setTimelineError = (message?: string) => action(SET_TIMELINE_ERROR, message);

export const setTimelineEvents = (
  timelineEvents: TimelineEvent[],
  activityPeriods: ActivityPeriod[]
) => action(SET_TIMELINE_EVENTS, { timelineEvents, activityPeriods });

export const loadTimelineEvents = (virsId: number) => action(LOAD_TIMELINE_EVENTS, virsId);
