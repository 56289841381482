import { get, post } from '../../utils/axiosApi';
import { GraphicalRecordCreationInput } from './graphicalRepresentationToolsTypes';

export const getGraphicalRepresentationVersion = () => get('/graphical-representation');

export const getGraphicalRepresentationData = (graphicalVersionId: number) =>
  get(`/graphical-representation/${graphicalVersionId}`);

export const createGraphicalRepresentationRecord = (input: GraphicalRecordCreationInput) =>
  post('/graphical-representation', input);

export const publishGraphicalRepresentationVersion = (graphicalVersionId: number) =>
  post(`/graphical-representation/${graphicalVersionId}`);

export const getAvailableVirsId = () => get('/graphical-representation/virs');
