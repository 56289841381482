import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { VirsSearchShortData } from '../store/virsis/dataTypes';
import { MINIMAL_RECORD_DATE_DEFAULT, MAXIMAL_RECORD_DATE_FUTURE } from './InputValueFunctions';

export type SortOrder = 'asc' | 'desc';

export type RowsPerPageOptions = number[];

export const ROWS_PER_PAGE_OPTIONS: RowsPerPageOptions = [5, 10, 20, 30];

export const REPORT_EXTERNAL_DATA_COMMENT_MAX_LENGTH = 1600;

export interface ColumnHeader<T> {
  id: T;
  label: string;
  type?: HeaderType;
}

export type HeaderType = 'date' | 'multiple' | 'year' | 'nonFutureDate';

export type TableCustomFilter<T extends string> = {
  [key in T]: string[];
};

export type ColumnDisplayStatus<TKey extends string> = {
  [key in TKey]: boolean;
};

export type VirsisData = { [key: string]: number | string };

export interface DataWithStatuses {
  confirmationStatus: string;
  relevancyStatus: string | null;
}

export type RelevancyStatus = 'A' | 'I' | null;
export type ConfirmationStatus = 'P' | 'N' | 'U';

export interface DataWithDocumentStatus {
  documentStatus: DocumentStatus;
}

export interface DocumentStatus {
  id: WordStatus;
  label: WordStatusLabel;
}

export type WordStatusLabel = 'Nepasirašyta' | 'Pasirašyta' | 'Anuliuota';

export type WordStatus = 'RUOSIAMAS' | 'PASIRASYTAS' | 'ANULIUOTAS';

export interface DataWithDocumentStatusProvidedData {
  documentStatus: DocumentStatus;
}

export interface DocumentStatus {
  id: WordStatus;
  label: WordStatusLabel;
}

export interface FilterOption {
  label: string;
  value: string | number;
}
export type TableGenericFilterOption<T extends string> = {
  [key in T]: FilterOption[];
};
export interface ValidationDate {
  id: string;
  date?: MaterialUiPickersDate;
  text: string;
}

export interface DatePickerState {
  value: MaterialUiPickersDate | null;
  error: boolean;
  validated: boolean;
  minDates: ValidationDate[];
  maxDates: ValidationDate[];
  helperText?: string;
  placeholder?: string;
  label?: string;
  id?: string;
}

export const genericDatePickerState = {
  value: null,
  error: false,
  validated: false,
  minDates: [MINIMAL_RECORD_DATE_DEFAULT],
  maxDates: [MAXIMAL_RECORD_DATE_FUTURE]
};

export interface DropdownStateGeneric<T> {
  value: T | null;
  id?: string;
  error: boolean;
  helperText: string;
  placeholder: string;
  validated: boolean;
  options: T[];
}

export interface DropdownMultipleStateGeneric<T> {
  values: T[];
  id?: string;
  error: boolean;
  helperText: string;
  placeholder: string;
  validated: boolean;
  options: T[];
}

export interface InputStateGeneric<T> {
  value: T | null;
  id: string;
  error: boolean;
  helperText: string;
  placeholder: string;
  validated: boolean;
}

export interface VirsisFile {
  id: number;
  file: File;
  title: string;
  size: number;
}

export interface VirsInputState {
  value: VirsSearchShortData | null;
  error: boolean;
  validated: boolean;
  helperText: string;
  placeholder: string;
}

export interface VirsisFilesState {
  files: VirsisFile[];
  sizeLimit: number;
  filesLimit: number;
  error: boolean;
  validated: boolean;
  errorMessage: string;
}

export type RowStatusClassName =
  | 'none'
  | 'rowUnsigned'
  | 'rowSigned'
  | 'rowAnnuled'
  | 'rowHistoric';

export enum VirsisYesNoString {
  YES = 'T',
  NO = 'N'
}

export enum VirsisParameterType {
  NUMBER = 'SKAICIUS',
  STRING = 'TEKSTAS',
  DATE = 'DATA',
  YesNo = 'LOGINIS'
}

export type ValueType<T, K extends keyof T> = T[K];

export interface InstitutionDataRowState {
  documentStatusId: number | undefined;
  showReport: boolean;
  showPreview: boolean;
  showExtension: boolean;
}

export const NAME_CHECK_REGEX_LT = /^[a-zA-ZąčęėįšųūĄČĘĖĮŠŲŪžŽ(),.'\-/\s]+$/u;

export const FILE_TYPE_CHECK = /(.pdf)$/u;

export const NAME_CHECK_REGEX = /^[a-zA-Z(),.'-/\s]+$/u;

export const FIELD_CHECK_REGEX_LT = /^[0-9a-zA-ZąčęėįšųūĄČĘĖĮŠŲŪžŽ()"?,.'\-/\s]+$/u;

export const FIELD_CHECK_REGEX = /^[0-9a-zA-Z()?",.'\-/\s]+$/u;

export const getDockStatus = (status: string): WordStatus => {
  if (status === 'Nepasirašyta') return 'RUOSIAMAS';
  if (status === 'Pasirašyta') return 'PASIRASYTAS';
  return 'ANULIUOTAS';
};
export type DatePeriod =
  | 'PASKUTINIS_MENUO'
  | 'PASKUTINIS_KETVIRTIS'
  | 'PASKUTINIS_PUSMETIS'
  | 'PASKUTINIAI_METAI'
  | 'VISI';

export type ProvidedDataTypes =
  | 'Profesinės etikos nesilaikymas'
  | 'Rimti profesiniai pažeidimai'
  | 'VIRS gautos lėšos'
  | 'Tiražo patikrinimas'
  | 'Metinė įmoka'
  | 'VIRS duomenys';

export type Period =
  | {
      id: 'PASKUTINIS_MENUO';
      label: 'Paskutinis mėnuo';
    }
  | {
      id: 'PASKUTINIS_KETVIRTIS';
      label: 'Paskutinis ketvirtis';
    }
  | {
      id: 'PASKUTINIS_PUSMETIS';
      label: 'Paskutinis pusmetis';
    }
  | {
      id: 'PASKUTINIAI_METAI';
      label: 'Paskutiniai metai';
    }
  | {
      id: 'VISI';
      label: 'Visi duomenys';
    };

export interface PaginationParams {
  docStatusUnsigned?: boolean;
  docStatusSigned?: boolean;
  docStatusAnnulled?: boolean;
  docStatusNotSigned?: boolean;
  docStatusRelevant?: boolean;
  docStatusOutdated?: boolean;
  pageSize?: number;
  pageNr?: number;
}

export enum ROLE_CODE {
  LRTK_EDIT = 1414,
  ZEIT_EDIT = 1411
}

// eslint-disable-next-line no-control-regex
export const EMAIL_VALIDATION_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
