import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  label: {
    color: '#637082',
    margin: '0 10px',
    fontWeight: 600
  },
  button: {
    border: '1px solid #a8b1bd',
    backgroundColor: 'white',
    color: '#637082',
    boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.1)',
    fontWeight: 500,
    '&:hover': {
      border: '1px solid #006FB2',
      backgroundColor: 'white',
      color: '#006FB2'
    },
    '&:disabled': {
      backgroundColor: '#DBEDFC',
      color: '#006FB2',
      border: '1px solid #DBEDFC'
    }
  }
}));

interface Props {
  onShowSharesClicked: () => void;
  onShowVotesClicked: () => void;
  sharesButtonDisabled: boolean;
  votesButtonDisabled: boolean;
}

export const TableFilterBySharesAndVotes: React.FC<Props> = ({
  onShowSharesClicked,
  onShowVotesClicked,
  sharesButtonDisabled,
  votesButtonDisabled
}) => {
  const classes = useStyles();

  return (
    <div className="flex-container">
      <Typography variant="h6" className={classes.label}>
        Rodyti dalyvių
      </Typography>
      <Button
        className={classes.button}
        onClick={onShowVotesClicked}
        disabled={votesButtonDisabled}
      >
        <Typography variant="h6">Balsus</Typography>
      </Button>
      <Button
        className={classes.button}
        onClick={onShowSharesClicked}
        disabled={sharesButtonDisabled}
      >
        <Typography variant="h6">Įnašą / akcijas</Typography>
      </Button>
    </div>
  );
};
