import { DataTableState } from './tableTypes';

export const initialState: DataTableState = {
  data: [],
  order: 'asc',
  sortBy: 'distributorName',
  pageData: [],
  page: 0,
  recordsPassed: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    distributorName: true,
    distributorCode: true,
    interestedPartyName: true,
    campaignName: true,
    politicalAdDateFrom: true,
    politicalAdDateTo: true,
    financialSupportAmount: true
  },
  customFilterOn: false,
  customFilter: {
    distributorName: [],
    distributorCode: [],
    interestedPartyName: [],
    campaignName: [],
    politicalAdDateFrom: [],
    politicalAdDateTo: [],
    financialSupportAmount: []
  }
};
