import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableRow, TableCell } from '@material-ui/core';
import { getVisibilityValue } from './utility/tableDataUtility';
import { State } from './state/reducer';
import { Actions } from './state/actions';
import { TableCustomFilterCellGeneric } from '../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { Column } from './types';

interface Props<TData> {
  actions: Actions<TData>;
  data: TData[];
}

export const ClassifierFilters = <TData,>({ actions, data }: Props<TData>) => {
  const dispatch = useDispatch();
  const { columns, columnVisibility } = useSelector((state: State<TData>) => state);

  function handleSetCustomFilter(column: string, value: string | null) {
    dispatch(actions.setFilterValue(column as keyof TData, value || undefined));
  }

  function getUniqueOptions(data: TData[], column: Column<TData>) {
    return data
      .map((value) => column.valueMapper(value))
      .flatMap((values) => values)
      .reduce(
        (prev, current) => (prev.includes(current) ? prev : [...prev, current]),
        [] as string[]
      );
  }

  return (
    <TableRow key="filter" className="CustomFilter">
      {columns
        .filter(({ field }) => getVisibilityValue(field, columnVisibility))
        .map((column) => (
          <TableCustomFilterCellGeneric
            key={`${column.field}`}
            id={`${column.field}`}
            type={column?.type}
            setFilter={handleSetCustomFilter}
            uniqueOptions={getUniqueOptions(data, column)}
          />
        ))}

      <TableCell
        style={{
          borderColor: '#C4E2FC',
          borderTop: 'none',
          borderBottom: 'none'
        }}
      />
    </TableRow>
  );
};
