import React from 'react';
import { TableCell } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EnterpriseDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { EnterpriseTableRowExtension } from './RowExtension';
import { ExpandDetailsButton } from '../../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { EnterpriseEditRecordState } from './rowState/rowInitialStateAndTypes';
import { SaveAndCancelIcons } from '../../../components/TableRowActions/SaveAndCancelIcons';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';

interface Props {
  record: EnterpriseData;
  rowState: EnterpriseEditRecordState;
  columnsToDisplay: EnterpriseDataTableColumnsDisplayStatus;
  colSpanForExtensionRow: number;
  toggleExtension: () => void;
  isExtended: boolean;
  setValidTo: (validTo: MaterialUiPickersDate | null) => void;
  updateValidToDate: () => void;
  closeEditing: () => void;
  updatingEnterprise: boolean;
}

export const EnterpriseTableRowEditOnValidToOnly: React.FC<Props> = ({
  record,
  rowState,
  columnsToDisplay,
  colSpanForExtensionRow,
  toggleExtension,
  isExtended,
  setValidTo,
  updateValidToDate,
  closeEditing,
  updatingEnterprise
}) => {
  return (
    <>
      <RowStyleOnDifferentStatus status="PASIRASYTAS">
        {columnsToDisplay.enterpriseTypeName && (
          <TableCell>
            <ExpandDetailsButton onClick={toggleExtension} showExtension={isExtended} />
            {record.enterpriseTypeName}
          </TableCell>
        )}
        {columnsToDisplay.validFrom && <TableCell>{record.validFrom}</TableCell>}
        {columnsToDisplay.validTo && (
          <DateInputImprovedCell
            state={rowState.validTo}
            setDate={setValidTo}
            helperItem={
              <SaveAndCancelIcons
                handleConfirmationYes={updateValidToDate}
                isProcessing={updatingEnterprise}
                handleCancelButtonClick={closeEditing}
              />
            }
          />
        )}
        {columnsToDisplay.authorName && <TableCell>{record.authorName}</TableCell>}
        <TableCell />
      </RowStyleOnDifferentStatus>
      {isExtended && (
        <EnterpriseTableRowExtension
          colSpan={colSpanForExtensionRow}
          recordStatus="PASIRASYTAS"
          record={record}
        />
      )}
    </>
  );
};
