import { WordStatus } from '../../utils/tableTypes';
import { ErrorType } from '../virsis/dataTypes';

export type ShareholdersData = {
  virsName: string;
  virsShareholderId: number;
  virsLegalCode: number;
  virsLegalFormCode: number;
  virsStartDate: string;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  shareholders: ShareholdersProps[];
  shareTypeName: string;
  mayHaveSharePercentage: boolean;
  mayHaveVotePercentage: boolean;
  mayHaveVotesOnRules: boolean;
  isUpdatedFromJADIS: boolean;
  mayShareholderHaveChildren: boolean;
  isUnsigned: { isUnsigned: boolean };
  errors: ErrorProps[];
  minimumDate: string;
  activityPeriods: ActivityPeriod[];
};

export type ShareholdersProps = {
  shareholderId: number;
  shareholderGroupId: number | null;
  shareholderPath: string;
  shareholderLevel: number;
  shareholderPersons: ShareholderPerson[];
  shareholderStartDate: string | null;
  shareholderParentStartDate: string;
  shareholderStoppedParticipatingFromDate: string | null;
  createdWithDocument: DocumentProps | null;
  closedWithDocument: DocumentProps | null;
  mayHaveVotePercentage: boolean;
  mayHaveVotesOnRules: boolean;
  mayHaveSharePercentage: boolean;
  shareTypeName: string;
  isShareholderFromJADIS: boolean;
  JADISStartDate: string | null;
  JADISUpdateDate: string | null;
  mayShareholderHaveChildren: boolean;
  endDateType: EndDateType;
  shareholderHistory: ShareholdersHistory[];
};

export type ShareholderPerson = {
  personId: number;
  personName: string;
  personLegalCode: number | string | null;
  personLegalFormAbbreviation: string | null;
  personLegalFormName: string | null;
  personLegalFormCode: number | null;
  personType: LegalPerson | NaturalPerson;
  isAuthorized: boolean;
  errors: ErrorProps[];
  shareTypeName: string | null;
  mayHaveSharePercentage: boolean;
  mayHaveVotePercentage: boolean;
  mayHaveVotesOnRules: boolean;
  mayShareholderHaveChildren: boolean;
  shareholders: ShareholdersProps[];
};

export interface ActivityPeriod {
  startDate: string;
  endDate: string | null;
}

export type DocumentProps = {
  documentId: number;
  documentDate: string;
  documentStatus: WordStatus;
};

export interface ErrorProps {
  errorGroup: string;
  errorType: ErrorType;
  errorMessages: ErrorMessage[];
  errorWithShares?: boolean;
  errorWithVotes?: boolean;
}

export interface ErrorMessage {
  errorText: string;
  eventDate: string;
}

export type ShareholdersHistory = {
  eventId: number;
  eventDate: string;
  sharePercentage: number | null;
  votePercentage: number | null;
  votesOnRules: boolean | null;
  documentId: number | null;
  documentDate: string | null;
  documentStatus: WordStatus | null;
  isEventFromJADIS: boolean | null;
  sharePercentageLessThanPermitted: boolean | null;
  votePercentageLessThanPermitted: boolean | null;
};

export interface ShareholderRecord {
  virsId: number;
  shareholderPersonsIds: ShareholderPersonsIds[];
  parentPersonId: number;
  shareTypeId: number | null;
  hasVotePercentage: boolean;
  hasVotesOnRules: boolean;
  selectedDate: string | null;
}

export interface ShareholderPersonsIds {
  personId: number;
}

export interface RecordToSynchronise {
  virsId: number;
  personId: number;
}

export interface ShareholderPercentages {
  sharePercentage: string | null;
  votePercentage: string | null;
  votesOnRules: boolean;
  participateBeforeSelectedDate: boolean;
  endOfParticipationFromBefore: boolean;
  isEventFromJadis: boolean;
  endOfParticipation: boolean;
  mayHaveSharePercentage?: boolean;
  mayHaveVotePercentage: boolean;
  votesValueSelected: boolean;
  sharePercentageLessThanPermitted: boolean;
  votePercentageLessThanPermitted: boolean;
}

export interface ShareholderPercentagesRecord {
  sharePercentage: number | null;
  votePercentage: number | null;
  votesOnRules: boolean;
  participateBeforeSelectedDate: boolean;
  endOfParticipation: boolean;
  endOfParticipationFromBefore: boolean;
  sharePercentageLessThanPermitted: boolean;
  votePercentageLessThanPermitted: boolean;
}

export interface ShareholderIds {
  virsId: number;
  parentId: number;
  shareholderId: number;
  eventDate: string;
}

export type AddPercentageRecord = ShareholderPercentagesRecord & ShareholderIds;

export type ClearShareholderPercentageRecord = {
  virsId: number;
  shareholderId: number;
  eventDate: string;
};

export interface AddPersonDialogElement {
  personId: number;
  shareholderId: number;
  nameToDisplay?: string;
  personLegalFormAbbreviation?: string | null;
  shareholderLevel: number;
  shareholderPath: string;
  index: number;
}

export interface CreatedShareholderData {
  shareholderId: number;
  shareholderPersonInTree: boolean;
}

export interface ShareholderDateInput {
  virsId: number;
  shareholderId: number;
  startDate: string;
  sharePercentage: string;
  votesPercentage: string;
  hasVotesOnRules: boolean;
  participateBeforeSelectedDate: boolean;
  endOfParticipation: boolean;
}

export enum LegalPerson {
  LJA = 'LJA',
  UJA = 'UJA'
}

export enum NaturalPerson {
  LFA = 'LFA',
  UFA = 'UFA'
}

export enum GroupShareholderType {
  AUTHORIZED = 'Bendraturčių įgaliotas asmuo',
  UNAUTHORIZED = 'Bendraturtis'
}

export enum ShareTypeNames {
  PART_OF_SHARES = 'Akcijų dalis',
  PART_OF_CONTRIBUTIONS = 'Įnašų dalis'
}

export enum VoteTypeNames {
  PART_OF_VOTES = 'Balsų dalis',
  VOTES_ON_RULES = 'Balsai pagal taisyklę'
}

export type ShareholderTableLocation = 'SELECTED' | 'MAIN';

export enum EndDateType {
  SHAREHOLDER = 'SHAREHOLDER',
  JADIS = 'JADIS',
  VIRS = 'VIRS',
  JAR = 'JAR',
  GR = 'GR'
}
