import { getToggledSortOrderGeneric } from '../../utils/tableDataFunctions';
import { SortOrder, ColumnHeader } from '../../utils/tableTypes';

export type FindVirsResultsTableAction =
  | {
      type: 'SORTING_CHANGED';
      sortBy: VirsSearchDataField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export const initialFindVirsResultsTableState: FindVirsResultsTableState = {
  order: 'desc',
  sortBy: 'personFullName',
  page: 0,
  rowsPerPage: 10
};

export type VirsSearchDataField = 'personCode' | 'personFullName' | 'enterpriseTypes' | 'outlets';

export type FindVirsResultsTableColumnHeader = ColumnHeader<VirsSearchDataField>;

export interface FindVirsResultsTableState {
  order: SortOrder;
  sortBy: VirsSearchDataField;
  page: number;
  rowsPerPage: number;
}

export const findVirsResultsTableReducer = (
  state: FindVirsResultsTableState,
  action: FindVirsResultsTableAction
): FindVirsResultsTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    default:
      return state;
  }
};
