import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  InputStateGeneric,
  DatePickerState,
  VirsInputState,
  DropdownStateGeneric,
  DropdownMultipleStateGeneric
} from '../../../../utils/tableTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import {
  FundsReceivedDataLegal,
  TransactionType
} from '../../../../store/fundsReceived/fundsReceivedDataTypes';
import { AnnulmentType } from '../../../../store/classifiers/classifiersTypes';

export interface FundsReceivedRecordState {
  loading: boolean;
  virs: VirsInputState;
  fundsReceivedYear: DatePickerState;
  fundsReceivedSum: InputStateGeneric<string>;
  fundsReceivedOutlets: DropdownMultipleStateGeneric<OutletShortData>;
  transaction: DropdownStateGeneric<TransactionType>;
  showFindVirsDialog: boolean;
  annulRecordConfirmationOn: boolean;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
}

export const initialFundsReceivedRecordState: FundsReceivedRecordState = {
  loading: false,
  virs: {
    value: null,
    error: false,
    validated: false,
    helperText: 'Privaloma pasirinkti VIRS',
    placeholder: 'VIRS pavadinimas'
  },
  fundsReceivedYear: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    placeholder: 'mmmm*',
    helperText: ''
  },
  fundsReceivedSum: {
    value: null,
    id: 'fundsReceivedSum',
    error: false,
    helperText: 'Suma eurais',
    placeholder: 'Suma eurais',
    validated: false
  },
  fundsReceivedOutlets: {
    values: [],
    id: '',
    error: false,
    helperText: '',
    placeholder: 'Pasirinkti',
    validated: false,
    options: []
  },
  transaction: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: 'Pasirinkti',
    validated: false,
    options: []
  },
  showFindVirsDialog: false,
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  }
};

export interface FundsReceivedEditRowState extends FundsReceivedRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

export const initialFundsReceivedEditRowState: FundsReceivedEditRowState = {
  editingOn: false,
  ...initialFundsReceivedRecordState,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false
};

export interface FundsReceivedNewRowState extends FundsReceivedRecordState {
  createRecordConfirmationOn: boolean;
}

export const initialFundsReceivedNewRowState: FundsReceivedNewRowState = {
  ...initialFundsReceivedRecordState,
  createRecordConfirmationOn: false
};

type FundsReceivedRowAction =
  | { type: 'VIRS_INPUT_CLICKED' }
  | { type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED'; virs: VirsSearchData }
  | { type: 'FIND_VIRS_DIALOG_CLOSED' }
  | {
      type: 'FUNDS_RECEIVED_YEAR_CHANGED';
      fundsReceivedYear: MaterialUiPickersDate | null;
    }
  | { type: 'FUNDS_RECEIVED_SUM_CHANGED'; fundsReceivedSum: string | null }
  | { type: 'OUTLETS_CHANGED'; outlets: OutletShortData[] }
  | { type: 'TRANSACTION_CHANGED'; transaction: TransactionType | null }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type FundsReceivedNewRowAction =
  | FundsReceivedRowAction
  | {
      type: 'NEW_RECORD_STATE_INITIALIZED';
      transactionTypes: TransactionType[];
      fictitiousOutlet: OutletShortData[];
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };

export type FundsReceivedEditRowAction =
  | FundsReceivedRowAction
  | {
      type: 'EDITING_INITIALIZED';
      record: FundsReceivedDataLegal;
      transactionTypes: TransactionType[];
      fictitiousOutlet: OutletShortData[];
    }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'DOCUMENT_REMOVED'; documentId: number }
  | { type: 'ANNUL_RECORD_CLICKED'; annulmentTypes: AnnulmentType[] }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' };
