import {
  PaymentDataCustomFilter,
  PaymentDataHeaderField,
  PaymentDataTableState
} from './tableInitialStateAndTypes';
import { PaymentDataVirs, PaymentDocumentData } from '../../../store/payments/paymentsTypes';
import { SortOrder } from '../../../utils/tableTypes';
import {
  Filter,
  filterData,
  getComparator,
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  getStringMappedColumnFilter,
  replaceDecimalPoint,
  stableSort,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import { paymentTableColumns } from './tableColumns';

export function updatePaymentDataCustomFilter(
  filter: PaymentDataCustomFilter,
  filterBy: PaymentDataHeaderField,
  value: string | null
): PaymentDataCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

function getSortedPaymentData(
  data: PaymentDataVirs[],
  order: SortOrder,
  sortBy: string
): PaymentDataVirs[] {
  if (sortBy === 'paymentDocuments') {
    return data.map((record) => {
      if (record.paymentDocuments) {
        const sortedDocs = stableSort<PaymentDocumentData>(
          record.paymentDocuments,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'documentNumber')
        );
        return { ...record, paymentDocuments: sortedDocs };
      }
      return record;
    });
  }

  if (sortBy === 'paymentOutlets') {
    return data.map((record) => {
      if (record.paymentOutlets) {
        const sortedOutlets = stableSort<OutletShortData>(
          record.paymentOutlets,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'outletName')
        );
        return { ...record, paymentOutlets: sortedOutlets };
      }
      return record;
    });
  }

  return stableSortWrapped(data, order, sortBy);
}

export function filterAndSortPaymentDataVirs(
  tableState: PaymentDataTableState,
  data: PaymentDataVirs[]
): PaymentDataVirs[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;

  const filters: Filter<PaymentDataVirs>[] = [
    getStringMappedColumnFilter(columnsFilters.decisionDate, ({ decisionDate }) => [decisionDate]),
    getStringMappedColumnFilter(columnsFilters.paymentDocuments, ({ paymentDocuments }) =>
      paymentDocuments.map(({ documentNumber }) => documentNumber)
    ),
    getStringMappedColumnFilter(columnsFilters.paymentOutlets, ({ paymentOutlets }) =>
      paymentOutlets.map(({ outletName }) => outletName)
    ),
    getStringCompareMoreFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(columnsFilters.paymentDeterminedBy, ({ paymentDeterminedBy }) => [
      paymentDeterminedBy
    ]),
    getStringMappedColumnFilter(columnsFilters.paymentAmount, ({ paymentAmount }) => [
      replaceDecimalPoint(`${paymentAmount}`)
    ])
  ];
  const filtered = filterData(data, filters);

  const getSortBy = (sortBy: PaymentDataHeaderField): string => {
    switch (sortBy) {
      case 'paymentDocuments':
        return 'paymentDocuments.documentNumber';
      case 'paymentOutlets':
        return 'paymentOutlets.outletName';
      default:
        return sortBy;
    }
  };

  const sortBy: string = getSortBy(tableState.sortBy);
  return getSortedPaymentData(filtered, tableState.order, sortBy);
}

export function getTableExportDefinition(
  tableState: PaymentDataTableState
): TableExportDefinition<PaymentDataVirs> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    PaymentDataVirs,
    PaymentDataHeaderField
  >(paymentTableColumns, tableState.columnsDisplayStatus);

  return {
    title: 'Metinė įmoka',
    columnGroups: [
      {
        header: 'Sprendimo',
        columns: [
          stringValueColumn('decisionDate', { width: 15 }),
          mappedValueColumn(
            'paymentDocuments',
            ({ paymentDocuments }) => [
              {
                values: paymentDocuments.map(({ documentNumber }) => documentNumber)
              }
            ],
            { width: 15 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'paymentOutlets',
            ({ paymentOutlets }) => [
              { values: paymentOutlets.map(({ outletName }) => outletName) }
            ],
            { width: 45 }
          ),
          stringValueColumn('paymentAmount', { width: 15 })
        ]
      },
      {
        header: 'Galioja',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      },
      { columns: [stringValueColumn('paymentDeterminedBy', { width: 45 })] }
    ]
  };
}
