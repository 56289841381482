import {
  ColumnHeader,
  ColumnDisplayStatus,
  TableCustomFilter,
  TableGenericFilterOption
} from '../../../utils/tableTypes';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { EditionCheckDataInst } from '../../../store/editionChecks/editionChecksTypes';

export type EditionCheckDataTableField =
  | 'virsName'
  | 'checkDate'
  | 'docNr'
  | 'outletName'
  | 'periodName'
  | 'conclusion';

export type EditionCheckDataInstTableHeaderType = ColumnHeader<EditionCheckDataTableField>;

export type EditionCheckDataTableColumnsDisplayStatus =
  ColumnDisplayStatus<EditionCheckDataTableField>;

export type EditionCheckCustomFilterOptions = TableGenericFilterOption<EditionCheckDataTableField>;

export type EditionCheckDataTableCustomFilter = TableCustomFilter<EditionCheckDataTableField>;

export interface EditionCheckDataTableState {
  customFilterOn: boolean;
  columnsDisplayStatus: EditionCheckDataTableColumnsDisplayStatus;
  showNewRecordFields: boolean;
  companyCode?: string;
  tableExportDefinition: TableExportDefinition<EditionCheckDataInst>;
}

export type EditionCheckDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: EditionCheckDataTableField }
  | { type: 'VIRS_SELECTED'; virs: VirsSearchData }
  | { type: 'CANCEL_CREATION' }
  | { type: 'CREATE_RECORD_CLICKED'; companyCode?: string }
  | { type: 'RESET_COMPANY_CODE' };

export const initialColumnDisplay: EditionCheckDataTableColumnsDisplayStatus = {
  virsName: true,
  checkDate: true,
  docNr: true,
  outletName: true,
  periodName: true,
  conclusion: true
};
