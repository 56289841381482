import {
  DataWithStatuses,
  DataWithDocumentStatusProvidedData,
  ProvidedDataTypes
} from '../../utils/tableTypes';
import { OutletShortData } from '../outlets/outletsTypes';

interface ProvidedData extends DataWithStatuses, DataWithDocumentStatusProvidedData {
  annulmentDate: string;
  annulmentDisablementReason: string | null;
  annulmentNotes: string;
  annulmentPersonFullName: string;
  annulmentPersonId: number;
  annulmentReasonTypeId: number;
  annulmentReasonTypeName: string;
  confirmationDate: string;
  confirmationPersonFullName: string;
  confirmationPersonId: number;
  dataType: ProvidedDataTypes;
  dataTypeId: number;
  documentId: number;
  documentSaperionId: number;
  documentStatusId: number;
  recordCount: number;
}

export type ProvidedDataVirs = ProvidedData;

export interface ProvidedDataInst extends ProvidedData {
  virsName: string;
  virsLegalCode: number;
  virsId: number;
  lastEditedDate: string;
  lastEditedBy: string;
  lastEditedById: number;
  outletList: OutletShortData[];
  authorPersonId: number;
  authorPersonFullName: string;
  authorId: number;
  recordId: number;
  personId: number;
  isRemovable: boolean;
  recordCount: number;
  documentClosureId: number;
  editingWarningMessage: string;
}

export enum ProvidedDataTableType {
  VIRS,
  INST,
  LEGAL
}
