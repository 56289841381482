import React, { useEffect } from 'react';
import { TooltipOnTableActionButton } from '../../../../components/Tooltips/TooltipOnTableActionButton';
import {
  ActivityPeriod,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../../store/shareholders/shareholdersTypes';
import { customStylesTriangle } from '../../SelectedDateTable/customStyles/customStylesColorBar';
import {
  useShareholdersTableState,
  useShareholdersTableDispatch
} from '../ShareholdersTableWithContext';
import { rowStyles } from '../CustomStyles/rowStyles';
import {
  getCurrentPeriod,
  getFirstNoNParticipationDay,
  isOneDayShareholder
} from '../../utilityFunctions/sharedFunctions';
import { replaceDecimalPoint } from '../../../../utils/tableDataFunctions';

interface Props {
  borderColor: string;
  event: ShareholdersHistory;
  fillWithColor: string;
  isSelectedDateEventDate?: boolean;
  date: string;
  shareholder: ShareholdersProps;
  textColor: string;
  value: string;
  allEventsDates: string[];
  virsEndDate: string | null;
  activityPeriod: ActivityPeriod[];
  virsStoppedParticipatingFromDate: string | null;
  isOneDayShareholder?: boolean;
}

export const ColorBarByDay: React.FC<Props> = ({
  borderColor,
  event,
  fillWithColor,
  isSelectedDateEventDate,
  date,
  shareholder,
  textColor,
  value,
  allEventsDates,
  virsEndDate,
  activityPeriod,
  virsStoppedParticipatingFromDate
}) => {
  const {
    state: { showShares, showVotes }
  } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  useEffect(() => {
    if (date === allEventsDates[allEventsDates.length - 1]) {
      tableDispatch({
        type: 'SET_LAST_COLUMN_BAR_COLOR',
        lastColumnColor: fillWithColor,
        lastColumnBorderColor: borderColor,
        shareholderId: shareholder.shareholderId
      });
    }
  }, [
    borderColor,
    date,
    fillWithColor,
    shareholder.shareholderId,
    showShares,
    showVotes,
    tableDispatch
  ]);

  const [firstDateOfFilteredDates] = allEventsDates;

  const {
    shareholderParentStartDate,
    shareholderStartDate,
    shareholderStoppedParticipatingFromDate,
    isShareholderFromJADIS
  } = shareholder;

  const { eventDate, votesOnRules } = event;

  const startDate = shareholderStartDate || shareholderParentStartDate;

  const startWithTriangle = !shareholderStartDate;

  const firstNoNParticipationDay = getFirstNoNParticipationDay(
    shareholderStoppedParticipatingFromDate,
    virsStoppedParticipatingFromDate
  );

  const currentActivityPeriod = getCurrentPeriod(date, activityPeriod);
  const isActivityPeriodEndDate = currentActivityPeriod?.endDate === date;

  const formattedValue = replaceDecimalPoint(value);

  const oneDayShareholder: boolean = isOneDayShareholder(
    currentActivityPeriod,
    shareholder.shareholderHistory,
    event,
    shareholderStoppedParticipatingFromDate
  );

  const customStyles = rowStyles(
    isShareholderFromJADIS,
    startDate,
    firstNoNParticipationDay,
    virsEndDate,
    date,
    fillWithColor,
    startWithTriangle,
    borderColor,
    textColor,
    isActivityPeriodEndDate,
    eventDate,
    isSelectedDateEventDate,
    formattedValue.length,
    oneDayShareholder
  );

  if (
    event.isEventFromJADIS === false &&
    event.documentId === null &&
    currentActivityPeriod?.endDate === event.eventDate
  ) {
    return <></>;
  }

  return (
    <>
      {startWithTriangle && startDate === date ? (
        <div
          className="color-row-triangle triangle-main-table"
          style={customStylesTriangle(borderColor, fillWithColor)}
        />
      ) : null}
      <div
        className="color-row-bar"
        style={{ ...customStyles, padding: value === '100%' ? '2px 3px' : '2px 10px' }}
      >
        {(date === eventDate || firstDateOfFilteredDates === date) && (
          <>
            {showVotes && votesOnRules ? (
              <TooltipOnTableActionButton text="Balsai pagal taisyklę">
                <span>{formattedValue}</span>
              </TooltipOnTableActionButton>
            ) : (
              formattedValue
            )}
          </>
        )}
      </div>
    </>
  );
};
