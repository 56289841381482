import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmRemoveDialog } from '../../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import {
  deleteShareholderDataByDateRequest,
  resetShareholderDataDeletionByDateDialog
} from '../../../../store/shareholders/shareholdersActions';
import { ApplicationState } from '../../../../store';
import { ShareholderTableLocation } from '../../../../store/shareholders/shareholdersTypes';

interface Props {
  location: ShareholderTableLocation;
}

const DataDeletionByDateDialog: React.FC<Props> = ({ location }) => {
  const reduxDispatch = useDispatch();
  const {
    shareholdersData: {
      dataDeletionByDateError,
      showDataDeletionByDateDialog,
      selectedDateForFunctions,
      loadingDataDeletionByDate
    },
    virsis: { virsData }
  } = useSelector((state: ApplicationState) => state);

  const close = () => {
    reduxDispatch(resetShareholderDataDeletionByDateDialog());
  };

  const deleteShareholderDataByDate = () => {
    if (virsData?.virsId) {
      reduxDispatch(
        deleteShareholderDataByDateRequest(virsData.virsId, location, selectedDateForFunctions)
      );
    }
  };

  const confirmText = selectedDateForFunctions
    ? 'Ar tikrai norite pašalinti nepasirašytus dalyvių duomenis pažymėtai datai?'
    : 'Ar tikrai norite pašalinti visus nepasirašytus dalyvių duomenis?';

  return (
    <ConfirmRemoveDialog
      open={showDataDeletionByDateDialog}
      dialogTitle="Išvalyti duomenis"
      dialogText={confirmText}
      mainButtonText="Išvalyti duomenis"
      onClose={close}
      onSubmit={deleteShareholderDataByDate}
      isProcessing={loadingDataDeletionByDate}
      errorMessage={`Klaida. ${dataDeletionByDateError?.message}` || ''}
      isError={!!dataDeletionByDateError}
      onErrorClose={close}
    />
  );
};

export default DataDeletionByDateDialog;
