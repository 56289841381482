import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableCustomTools, {
  ColumnsDisplayStatus
} from '../../../components/TableCustomTools/TableCustomTools';
import { SortOrder } from '../../../utils/tableTypes';
import { Actions } from './state/actions';
import { State } from './state/reducer';
import { getVisibilityValue } from './utility/tableDataUtility';

interface Props<TData> {
  actions: Actions<TData>;
}

export const TableToolWrapper = <TData,>({ actions }: Props<TData>) => {
  const dispatch = useDispatch();
  const { columnVisibility, columns, columnSort, showFilters } = useSelector(
    (state: State<TData>) => state
  );

  function toggleColumnVisibility(field: keyof TData) {
    const visible = getVisibilityValue<TData>(field, columnVisibility);
    dispatch(actions.toggleColumnVisibility(field, !visible));
  }

  function toggleColumnSort(field: keyof TData) {
    let direction: SortOrder = 'asc';
    if (columnSort.field === field) {
      direction = columnSort.direction === 'asc' ? 'desc' : 'asc';
    }
    dispatch(actions.setSort(field, direction));
  }

  const columnsDisplayStatus: ColumnsDisplayStatus = {};
  columnVisibility.forEach(({ field, visible }) => {
    columnsDisplayStatus[`${field}`] = visible;
  });

  return (
    <TableCustomTools
      tableColumns={columns.map(({ header, field }) => ({
        id: `${field}`,
        label: header,
        numeric: false
      }))}
      showFilter={showFilters}
      onFilterTabToggle={() => {
        dispatch(actions.setShowFilters(!showFilters));
        dispatch(actions.resetFilter());
      }}
      sortOrder={columnSort.direction || 'asc'}
      sortBy={`${columnSort.field}`}
      onSortByColumnClick={(key) => toggleColumnSort(key as keyof TData)}
      columnsDisplayStatus={columnsDisplayStatus}
      toggleDisplayColumn={(key) => toggleColumnVisibility(key as keyof TData)}
    />
  );
};
