import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  InputStateGeneric,
  DatePickerState,
  VirsisFilesState,
  DropdownStateGeneric,
  VirsInputState
} from '../../../../utils/tableTypes';
import {
  EditionCheckConclusionType,
  EditionCheckDataInst,
  EditionCheckDocs
} from '../../../../store/editionChecks/editionChecksTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT
} from '../../../../utils/InputValueFunctions';
import { AnnulmentType } from '../../../../store/classifiers/classifiersTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import { OutletEditionPeriod } from '../../../../store/outletInfoData/outletInfoDataTypes';

export interface EditionCheckRecordState {
  virs: VirsInputState;
  checkDate: DatePickerState;
  customDocumentsName: InputStateGeneric<string>;
  editionCheckDocs: EditionCheckDocs[];
  addedDocuments: VirsisFilesState;
  editionCheckOutlets: EditionCheckRecordOutlets[];
  showFindVirsDialog: boolean;
  annulRecordConfirmationOn: boolean;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
  loading: boolean;
  outlets: OutletShortData[];
  conclusionTypes: EditionCheckConclusionType[];
}

export interface EditionCheckRecordOutlets {
  outlet: DropdownStateGeneric<OutletShortData>;
  loadingPeriods: boolean;
  editionCheckConclusions: EditionCheckRecordConclusions[];
}

export interface EditionCheckRecordConclusions {
  period: DropdownStateGeneric<OutletEditionPeriod>;
  samePeriodError: boolean;
  conclusion: DropdownStateGeneric<EditionCheckConclusionType>;
}

export const initialDocumentsState: VirsisFilesState = {
  files: [],
  sizeLimit: 5242880,
  filesLimit: 5,
  error: false,
  validated: true,
  errorMessage: ''
};

export const initialEditionCheckRecordState: EditionCheckRecordState = {
  virs: {
    value: null,
    error: false,
    validated: false,
    helperText: 'Privaloma pasirinkti VIRS',
    placeholder: 'VIRS pavadinimas'
  },
  checkDate: {
    value: null,
    error: false,
    validated: false,
    minDates: [MINIMAL_RECORD_DATE_DEFAULT],
    maxDates: [MAXIMAL_RECORD_DATE_TODAY],
    helperText: ''
  },
  customDocumentsName: {
    value: null,
    id: 'customName',
    error: false,
    helperText: 'Dokumento numeris',
    placeholder: '',
    validated: false
  },
  editionCheckDocs: [],
  addedDocuments: initialDocumentsState,
  editionCheckOutlets: [
    {
      outlet: {
        value: null,
        error: false,
        helperText: '',
        placeholder: '',
        validated: false,
        options: []
      },
      loadingPeriods: false,
      editionCheckConclusions: [
        {
          period: {
            value: null,
            error: false,
            helperText: '',
            placeholder: '',
            validated: false,
            options: []
          },
          samePeriodError: false,
          conclusion: {
            value: null,
            error: false,
            helperText: '',
            placeholder: '',
            validated: false,
            options: []
          }
        }
      ]
    }
  ],
  showFindVirsDialog: false,
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  },
  loading: false,
  outlets: [],
  conclusionTypes: []
};

export interface EditionCheckNewRecordState extends EditionCheckRecordState {
  createRecordConfirmationOn: boolean;
}

export const initialEditionCheckNewRecordState: EditionCheckNewRecordState = {
  ...initialEditionCheckRecordState,
  createRecordConfirmationOn: false
};

export interface EditionCheckEditRecordState extends EditionCheckRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
  editionCheckId?: number;
  loading: boolean;
}

export const initialEditionCheckEditRecordState: EditionCheckEditRecordState = {
  ...initialEditionCheckRecordState,
  editingOn: false,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false,
  loading: false
};

type EditionCheckRecordAction =
  | { type: 'FIND_VIRS_DIALOG_CLOSED' }
  | { type: 'VIRS_INPUT_CLICKED' }
  | {
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED';
      virs: VirsSearchData;
      conclusionTypes: EditionCheckConclusionType[];
    }
  | { type: 'CHECK_DATE_CHANGED'; checkDate: MaterialUiPickersDate | null }
  | { type: 'FILE_ADDED'; file: File }
  | { type: 'FILE_REMOVED'; fileId: number }
  | { type: 'FILE_ADDING_WRONG_FORMAT'; errorMessage: string }
  | { type: 'FILES_CUSTOM_NAME_CHANGED'; filesCustomName: string }
  | {
      type: 'EDITION_CHECK_PERIOD_CHANGED';
      outletIndex: number;
      conclusionIndex: number;
      period: OutletEditionPeriod | null;
    }
  | {
      type: 'EDITION_CHECK_CONCLUSION_CHANGED';
      outletIndex: number;
      conclusionIndex: number;
      conclusion: EditionCheckConclusionType | null;
    }
  | { type: 'ADD_PERIOD_ROW'; outletIndex: number }
  | { type: 'PERIODS_LOADED'; missingPeriodsForOutlet: Map<number, OutletEditionPeriod[]> }
  | { type: 'REDUCE_PERIOD_ROW'; outletIndex: number; conclusionIndex: number }
  | { type: 'ADD_OUTLET_ROW' }
  | { type: 'CANCEL_OUTLET_ROW'; outletIndex: number };

export type EditionCheckNewRecordAction =
  | EditionCheckRecordAction
  | { type: 'CREATING_CANCELED' }
  | {
      type: 'EDITION_CHECK_OUTLETS_CHANGED';
      outletIndex: number;
      outlet: OutletShortData | null;
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type EditionCheckEditRecordAction =
  | EditionCheckRecordAction
  | {
      type: 'EDITING_INITIALIZED';
      record: EditionCheckDataInst;
      editionCheckConclusionTypes: EditionCheckConclusionType[];
    }
  | { type: 'EDITING_CANCELLED' }
  | {
      type: 'EDITION_CHECK_OUTLETS_CHANGED';
      outletIndex: number;
      outlet: OutletShortData | null;
    }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'CLEAR_RECORD_VALID_TO_DATE_CLICKED' }
  | { type: 'RESET_PERIOD_OPTIONS' }
  | { type: 'DOCUMENT_REMOVED'; documentId: number }
  | { type: 'ANNUL_RECORD_CLICKED'; annulmentTypes: AnnulmentType[] }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };
