import { ColumnDisplayStatus, ColumnHeader } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { IntegratedSystemsAuditData } from '../../../store/audits/auditsTypes';
import { getTableExportDefinition } from './tableStateFunctions';

const initialColumnDisplay: ColumnDisplayStatus<IntegratedSystemsAuditsHeaderField> = {
  systemDataIsReceivedFrom: true,
  systemDataIsSentTo: true,
  serviceTypeName: true,
  service: true,
  resultCode: true,
  requestDate: true,
  requestContent: true,
  responseDate: true,
  responseContent: true
};

export const auditsTableColumns: IntegratedSystemsAuditsColumnHeader[] = [
  {
    id: 'systemDataIsReceivedFrom',
    label: 'Sistema, iš kurios gaunami duomenys'
  },
  {
    id: 'systemDataIsSentTo',
    label: 'Sistema, į kurią siunčiami duomenys'
  },
  {
    id: 'serviceTypeName',
    label: 'Sąsajos tipas'
  },
  {
    id: 'service',
    label: 'Sąsaja'
  },
  {
    id: 'resultCode',
    label: 'Rezultato kodas'
  },
  {
    id: 'requestDate',
    label: 'Užklausos data ir laikas'
  },
  {
    id: 'requestContent',
    label: 'Užklausos turinys'
  },
  {
    id: 'responseDate',
    label: 'Atsakymo data ir laikas'
  },
  {
    id: 'responseContent',
    label: 'Atsakymo turinys'
  }
];

export const initialIntegratedSystemsAuditsTableState: IntegratedSystemsAuditsTableState = {
  order: 'desc',
  sortBy: 'requestDate',
  page: 0,
  rowsPerPage: 10,
  contentToView: undefined,
  columnsDisplayStatus: initialColumnDisplay,
  tableExportDefinition: getTableExportDefinition(auditsTableColumns, initialColumnDisplay)
};

export type IntegratedSystemsAuditsHeaderField =
  | 'systemDataIsReceivedFrom'
  | 'systemDataIsSentTo'
  | 'serviceTypeName'
  | 'service'
  | 'resultCode'
  | 'requestDate'
  | 'requestContent'
  | 'responseDate'
  | 'responseContent';

export type IntegratedSystemsAuditsColumnHeader = ColumnHeader<IntegratedSystemsAuditsHeaderField>;

export interface IntegratedSystemsAuditsTableState {
  sortBy: IntegratedSystemsAuditsHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  contentToView: AuditContentToView | undefined;
  columnsDisplayStatus: ColumnDisplayStatus<IntegratedSystemsAuditsHeaderField>;
  tableExportDefinition: TableExportDefinition<IntegratedSystemsAuditData>;
}

export interface AuditContentToView {
  content: string;
  title: string;
}

export type IntegratedSystemsAuditsTableAction =
  | { type: 'SORTING_CHANGED'; sortBy: IntegratedSystemsAuditsHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'CONTENT_OPENED'; contentToView: AuditContentToView }
  | { type: 'CONTENT_CLOSED' };
