import React, { ReactNode } from 'react';
import { TableCell, makeStyles } from '@material-ui/core';
import {
  ProfessionalMisconductDataInst,
  ProfessionalMisconductOutlet,
  ProfessionalMisconductOutletMisconduct
} from '../../../store/professionalMisconducts/professionalMisconductsTypes';
import { useProfessionalMisconductInstTableState } from '../Context';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { OutletsAsLinksList } from '../../../components/TableLinks/OutletsAsLinksList';

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

interface Props {
  record: ProfessionalMisconductDataInst;
  actions: ReactNode;
  eDocumentActions: ReactNode;
}

export const ProfessionalMisconductBasicInstRow: React.FC<Props> = ({
  record,
  actions,
  eDocumentActions
}) => {
  const classes = useStyles();
  const { state: tableState } = useProfessionalMisconductInstTableState();

  const totalRowSpan = () => {
    let rowSpan = 0;
    record.professionalMisconductOutlets.forEach((outlet) => {
      outlet.determinedMisconducts.forEach((misconduct) => {
        rowSpan += misconduct.sanctions.length;
      });
    });
    return rowSpan;
  };

  const outletRowSpan = (outlet: ProfessionalMisconductOutlet) => {
    let rowSpan = 0;
    outlet.determinedMisconducts.forEach((misconduct: ProfessionalMisconductOutletMisconduct) => {
      rowSpan += misconduct.sanctions.length;
    });
    return rowSpan;
  };

  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  record.professionalMisconductOutlets.forEach((outlet) => {
    outlet.determinedMisconducts.forEach((misconduct, misconductIndex) => {
      misconduct.sanctions.forEach((sanction, sanctionIndex) => {
        rows.push(
          <RowStyleOnDifferentStatus key={rowIndex} status={record.documentStatus.id}>
            {rowIndex === 0 && (
              <>
                {tableState.columnsDisplayStatus.virsName && (
                  <TooltipOnRowRibbon status={record.documentStatus.id}>
                    <TableCell align="left" rowSpan={totalRowSpan()}>
                      <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
                        <VirsNameLink
                          virsName={record.virsName}
                          virsLegalCode={Number(record.virsLegalCode)}
                          virsId={record.virsId}
                        />
                      </AllowedTo>
                    </TableCell>
                  </TooltipOnRowRibbon>
                )}
                {tableState.columnsDisplayStatus.decisionDate && (
                  <TableCell align="left" rowSpan={totalRowSpan()} className={classes.dateColumn}>
                    {record.decisionDate}
                  </TableCell>
                )}
                {tableState.columnsDisplayStatus.documentNumber && (
                  <TableCell align="left" rowSpan={totalRowSpan()}>
                    {record.documentNumber}
                  </TableCell>
                )}
              </>
            )}
            {misconductIndex === 0 &&
              sanctionIndex === 0 &&
              tableState.columnsDisplayStatus.outletName && (
                <TableCell align="left" rowSpan={outletRowSpan(outlet)}>
                  <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
                    <OutletsAsLinksList outlets={[outlet]} virsId={record.virsId} />
                  </AllowedTo>
                </TableCell>
              )}
            {sanctionIndex === 0 && tableState.columnsDisplayStatus.professionalMisconduct && (
              <TableCell align="left" rowSpan={misconduct.sanctions.length}>
                {misconduct.misconductTypeName}
              </TableCell>
            )}
            {tableState.columnsDisplayStatus.sanction && (
              <TableCell align="left">{sanction.sanctionTypeName}</TableCell>
            )}
            {rowIndex === 0 && (
              <>
                {tableState.columnsDisplayStatus.validFrom && (
                  <TableCell align="left" rowSpan={totalRowSpan()} className={classes.dateColumn}>
                    {record.validFrom}
                  </TableCell>
                )}
                {tableState.columnsDisplayStatus.validTo && (
                  <TableCell align="left" rowSpan={totalRowSpan()} className={classes.dateColumn}>
                    {record.validTo}
                  </TableCell>
                )}
                <TableCell align="left" rowSpan={totalRowSpan()}>
                  {actions}
                </TableCell>

                <TableCell align="left" rowSpan={totalRowSpan()}>
                  {eDocumentActions}
                </TableCell>
              </>
            )}
          </RowStyleOnDifferentStatus>
        );
        rowIndex++;
      });
    });
  });

  return <>{rows}</>;
};
