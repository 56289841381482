import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { PaymentDataHeaderField } from './tableState/paymentTableInitialStateAndTypes';
import { usePaymentTableState } from './Context';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../utils/tableDataFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { sortingChanged } from '../../store/paymentsTable/paymentsTableActions';
import { ApplicationState } from '../../store';
import { PaymentsTableDataState } from '../../store/paymentsTable/paymentsTableTypes';

const PaymentTableHead: React.FC = () => {
  const { state: tableState } = usePaymentTableState();
  const reduxDispatch = useDispatch();

  const handleSetSortByColumn = (column: PaymentDataHeaderField) => {
    reduxDispatch(sortingChanged(column));
  };

  const { tableDataState } = useSelector((state: ApplicationState) => state.paymentTableData);

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    PaymentsTableDataState,
    typeof handleSetSortByColumn,
    PaymentDataHeaderField
  >(handleSetSortByColumn, tableDataState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.virsName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="VIRS"
            columnName="virsName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}

        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.paymentDocuments) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.decisionDate,
              tableState.columnsDisplayStatus.paymentDocuments
            ])}
          >
            <Typography variant="h4">Sprendimo</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.paymentOutlets && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Susijusios VIP"
            columnName="paymentOutlets"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}

        {tableState.columnsDisplayStatus.paymentAmount && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Įmokos dydis (BSI)"
            columnName="paymentAmount"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}

        <TableCell
          style={{
            width: '80px'
          }}
          rowSpan={2}
        >
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>

        <TableCell rowSpan={2} className="column-of-buttons">
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Data"
            columnName="decisionDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}

        {tableState.columnsDisplayStatus.paymentDocuments && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nr."
            columnName="paymentDocuments"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}

        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableDataState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default PaymentTableHead;
