import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableHead, TableBody, Typography, Box, Container, Paper, makeStyles } from '@material-ui/core/';
import { ApplicationState } from '../../store';
import { OutletDataTableField, outletTableColumns } from './tableState/initialStateAndTypes';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import { OutletsTableHeaders } from './TableHeaders';
import OutletsTableNewRecordDialog from './TableNewRecordDialog';
import { OutletEnterpriseRow } from './TableRows/EnterpriseRow';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../components/TableStatusFilters/TableStatusFilters';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import FilterRow from './FilterRow';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import AllowedTo from '../AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import {
  enterprisesOutletsOrOnlySingleEnterpriseOutlets,
  filterAndSortTableData,
  outletsToEnterprisesWithOutlets
} from './tableState/tableReducerFunctions';
import {
  dataContainsAnyNotSignedRecords,
  dataContainsAnyOutdatedRecords,
  dataContainsAnyRelevantRecords,
  pageTableData
} from '../../utils/tableDataFunctions';
import { useOutletsTableDispatch, useOutletsTableState } from './Context';
import { EnterpriseDataWithOutlets } from '../../store/outlets/outletsTypes';
import { getSubsystem } from '../../utils/roleHelper';
import AllowedToAllExcept from '../AllowedToAllExcept';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { unsignedDataRemoveVirs } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { fetchOutletData } from '../../store/outlets/outletsActions';
import { VirsDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';
import SubmitVirsData from '../SubmitVirsData';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { RowActionButtonGeneric } from '../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';

export interface OutletRedirectFunctions {
  redirectToEnterpriseNewRecord: () => void;
  redirectToOutletInfoPage: (outletId: number) => void;
  redirectToOutletRepresentativesPage: (outletId: number) => void;
  redirectToOutletEditionsPage: (outletId: number) => void;
  redirectToOutletAdPrintingPage: (outletId: number) => void;
  redirectToOutletCategoriesPage: (outletId: number) => void;
}

export interface OutletTableProps {
  outletRedirectFunctions: OutletRedirectFunctions;
}
const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  }
}));
export const OutletsTable: React.FC<OutletTableProps> = ({ outletRedirectFunctions }) => {
  const reduxDispatch = useDispatch();
  const classes = useStyles();

  const { state: tableState } = useOutletsTableState();
  const { dispatch: tableDispatch } = useOutletsTableDispatch();

  const {
    virsis: { currentUser, virsData },
    outletData: { allEnterprises, outlets, loadingOutletData, outletDataError }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const allOutlets = useMemo(() => outlets || [], [outlets]);

  const activeEnterprises = allEnterprises?.filter((ent) => ent.active) || [];

  const generatedEnterpriseOutletsData = useCallback(
    () => outletsToEnterprisesWithOutlets(allEnterprises || [], allOutlets),
    [allEnterprises, allOutlets]
  );

  const enterprisesToShow: EnterpriseDataWithOutlets[] =
    enterprisesOutletsOrOnlySingleEnterpriseOutlets(
      generatedEnterpriseOutletsData(),
      tableState.showGroupOfSingleEnterprise
    );

  const data = filterAndSortTableData(enterprisesToShow, tableState);

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  const outletsToShow = enterprisesToShow.flatMap((ent) => ent.outlets);

  const initTable = useCallback(() => {
    const hasNotSigned = dataContainsAnyNotSignedRecords(allOutlets);
    const hasRelevant = dataContainsAnyRelevantRecords(allOutlets);
    const hasOutdated = dataContainsAnyOutdatedRecords(allOutlets);
    tableDispatch({
      type: 'TABLE_INITIALIZED',
      notSignedEnabled: hasNotSigned,
      relevantEnabled: hasRelevant,
      outdatedEnabled: hasOutdated
    });
  }, [allOutlets, tableDispatch]);

  const subsystem = getSubsystem(currentUser);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  function toggleCustomFilter() {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  }

  function setCustomFilter(column: OutletDataTableField, value: string | null) {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value,
      enterprisesToShow
    });
  }

  function toggleUnconfirmedFilter() {
    tableDispatch({ type: 'SHOW_STATUS_UNSIGNED_TOGGLED' });
  }

  function toggleRelevantFilter() {
    tableDispatch({ type: 'SHOW_STATUS_RELEVANT_TOGGLED' });
  }

  function togglePassedFilter() {
    tableDispatch({ type: 'SHOW_STATUS_NOT_RELEVANT_TOGGLED' });
  }

  function setSorting(column: OutletDataTableField): void {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  }

  function setPage(value: number): void {
    tableDispatch({ type: 'PAGE_SET', page: value });
  }

  function setRowsPerPage(rowsPerPage: number) {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  }

  function toggleColumnDisplay(column: OutletDataTableField): void {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  }

  function toggleNewRecordDialog() {
    tableDispatch({ type: 'SHOW_NEW_RECORD_DIALOG_TOGGLED' });
  }

  function toggleEnterprise(enterpriseId: number) {
    return () => tableDispatch({ type: 'ENTERPRISE_EXTENSION_CLICKED', enterpriseId });
  }

  const openNewRecordInputRowInEnterprise = (enterprise: EnterpriseDataWithOutlets) => {
    const hasNotSigned = dataContainsAnyNotSignedRecords(enterprise.outlets);
    const hasRelevant = dataContainsAnyRelevantRecords(enterprise.outlets);
    const hasOutdated = dataContainsAnyOutdatedRecords(enterprise.outlets);
    return () =>
      tableDispatch({
        type: 'ADD_OUTLET_FROM_DIALOG_CLICKED',
        enterprise,
        notSignedEnabled: hasNotSigned,
        relevantEnabled: hasRelevant,
        outdatedEnabled: hasOutdated
      });
  };

  function openNewRecordFromDialog(enterprisePickedFromDialog: EnterpriseDataWithOutlets) {
    const existingEnterpriseWithOutlets = generatedEnterpriseOutletsData().find(
      (ent) => ent.enterpriseId === enterprisePickedFromDialog.enterpriseId
    );

    const enterpriseToShow = existingEnterpriseWithOutlets || enterprisePickedFromDialog;

    const hasNotSigned = dataContainsAnyNotSignedRecords(enterpriseToShow.outlets || []);
    const hasRelevant = dataContainsAnyRelevantRecords(enterpriseToShow.outlets || []);
    const hasOutdated = dataContainsAnyOutdatedRecords(enterpriseToShow.outlets || []);

    tableDispatch({
      type: 'ADD_OUTLET_FROM_DIALOG_CLICKED',
      enterprise: enterpriseToShow,
      notSignedEnabled: hasNotSigned,
      relevantEnabled: hasRelevant,
      outdatedEnabled: hasOutdated
    });
  }

  function returnToTable() {
    const hasNotSigned = dataContainsAnyNotSignedRecords(allOutlets);
    const hasRelevant = dataContainsAnyRelevantRecords(allOutlets);
    const hasOutdated = dataContainsAnyOutdatedRecords(allOutlets);
    tableDispatch({
      type: 'RETURN_TO_TABLE_CLICKED',
      notSignedEnabled: hasNotSigned,
      relevantEnabled: hasRelevant,
      outdatedEnabled: hasOutdated
    });
  }

  function extendVisibleEnterprises() {
    tableDispatch({
      type: 'EXTEND_ENTERPRISES',
      enterprisesToExtend: dataPage
    });
  }

  useEffect(() => {
    initTable();

    const generatedEnterprises = generatedEnterpriseOutletsData().find(
      (ent) => ent.enterpriseId === tableState.showGroupOfSingleEnterprise?.enterpriseId
    );

    if (generatedEnterprises && !tableState.showGroupOfSingleEnterprise) {
      tableDispatch({
        type: 'UPDATE_SINGLE_SHOWING_ENTERPRISE_WITH_NEW_DATA',
        updatedEnterprise: generatedEnterprises
      });
    }
  }, [
    tableDispatch,
    initTable,
    generatedEnterpriseOutletsData,
    tableState.showGroupOfSingleEnterprise
  ]);

  const HeaderActions: React.FC = () => (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <PageActionButtons
        dontShowDelete={subsystem === 'LRTK' || subsystem === 'ZEIT'}
        onDownload={(format, navigationPath) =>
          exportTableData(
            format,
            tableState.tableExportDefinition,
            data.flatMap((entry) => entry.outlets),
            navigationPath
          )
        }
        onPrint={(navigationPath) =>
          printTableData(
            tableState.tableExportDefinition,
            data.flatMap((entry) => entry.outlets),
            navigationPath
          )
        }
        onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
      />

      {virsData && (
        <ConfirmRemoveUnsignedDialog
          onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
          onConfirm={() =>
            reduxDispatch(unsignedDataRemoveVirs(virsData.virsId, VirsDocumentType.OUTLET))
          }
          onRemoveSuccess={() => reduxDispatch(fetchOutletData(virsData.virsId))}
          open={confirmToDeleteUnsignedDialogOpen}
        />
      )}
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <div className="data-page">
        <div className="data-page-header">
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ margin: 'auto 0' }} variant="h1">
              Visuomenės informavimo priemonės (VIP)
            </Typography>
            <AllowedToAllExcept roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
              <HeaderActions />
            </AllowedToAllExcept>
          </Box>
        </div>
        {virsData && virsData.virsId !== 0 ? (
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <Box className={classes.buttonWrapper}>
              <AddNewRecordButton
                text="Pridėti naują VIP"
                onClicked={toggleNewRecordDialog}
                disabled={
                  tableState.showGroupOfSingleEnterprise !== null && tableState.tableInputOn
                }
              />
              <HeaderActions />
            </Box>
          </AllowedTo>
        ) : (
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <SubmitVirsData enterpriseTable />
          </AllowedTo>
        )}

        <OutletsTableNewRecordDialog
          isOpen={tableState.showAddNewRecordDialog}
          closeDialog={toggleNewRecordDialog}
          options={activeEnterprises || []}
          openNewRecord={openNewRecordFromDialog}
          redirectToEnterpriseNewRecord={outletRedirectFunctions.redirectToEnterpriseNewRecord}
        />

        <div className="data-table">
          <Paper elevation={0}>
            <div className="CustomizationBar">
              <TableToolsFrame
                numberOfGridsLeft={8}
                numberOfGridsRight={4}
                leftSideItem={
                  <>
                    <TableStatusFilters
                      dontShowNotSigned={subsystem === 'LRTK' || subsystem === 'ZEIT'}
                      notSignedFilterDisabled={!tableState.statusNotSignedFilterEnabled}
                      notSignedFilterOn={tableState.showStatusNotSigned}
                      notSignedFilterToggler={toggleUnconfirmedFilter}
                      relevantFilterDisabled={!tableState.statusRelevantFilterEnabled}
                      relevantFilterOn={tableState.showStatusRelevant}
                      relevantFilterToggler={toggleRelevantFilter}
                      outdatedFilterDisabled={!tableState.statusOutdatedFilterEnabled}
                      outdatedFilterOn={tableState.showStatusOutdated}
                      outdatedFilterToggler={togglePassedFilter}
                    />
                    <RowActionButtonGeneric
                      label="Išskleisti visas VIP"
                      onButtonClicked={extendVisibleEnterprises}
                    />
                  </>
                }
                rightSideItem={
                  <TableCustomTools
                    tableColumns={outletTableColumns}
                    showFilter={tableState.customFilterOn}
                    onFilterTabToggle={toggleCustomFilter}
                    sortOrder={tableState.order}
                    sortBy={tableState.sortBy}
                    onSortByColumnClick={setSorting}
                    columnsDisplayStatus={tableState.columnsDisplayStatus}
                    toggleDisplayColumn={toggleColumnDisplay}
                    selectingDisabled={
                      tableState.showGroupOfSingleEnterprise !== null &&
                      tableState.showNewRecordInput
                    }
                  />
                }
              />
            </div>

            <ScrollXContainer>
              <Table>
                <TableHead>
                  <OutletsTableHeaders
                    columnsDisplayStatus={tableState.columnsDisplayStatus}
                    order={tableState.order}
                    sortBy={tableState.sortBy}
                    setSorting={setSorting}
                    tableData={outletsToShow}
                    setFilter={setCustomFilter}
                    customFilterOn={tableState.customFilterOn}
                  />
                </TableHead>

                {tableState.customFilterOn && (
                  <FilterRow
                    columnParams={outletTableColumns}
                    columnsDisplayStatus={tableState.columnsDisplayStatus}
                    onCustomFilterChange={setCustomFilter}
                    tableData={outletsToShow}
                  />
                )}

                <TableBody>
                  {loadingOutletData && (
                    <TableCircularProgressRow isLoading={loadingOutletData} colSpan={8} />
                  )}
                  {!loadingOutletData && outletDataError && (
                    <TableErrorRow
                      error={outletDataError && 'Klaida, nepavyko gauti VIP duomenų'}
                      colSpan={8}
                    />
                  )}
                  {!loadingOutletData &&
                    !outletDataError &&
                    virsData &&
                    dataPage.map((enterpriseWitOutlets) => (
                      <OutletEnterpriseRow
                        key={enterpriseWitOutlets.enterpriseId}
                        groupingEnterprise={enterpriseWitOutlets}
                        toggleEnterpriseRow={toggleEnterprise(enterpriseWitOutlets.enterpriseId)}
                        returnToTable={returnToTable}
                        isExtended={tableState.extendedEnterprises.includes(
                          enterpriseWitOutlets.enterpriseId
                        )}
                        outletRedirectFunctions={outletRedirectFunctions}
                        virsRegistrationDate={virsData.legalRegistrationDate}
                        virsDeregistrationDate={virsData.legalDeregistrationDate}
                        openNewRecordInputRowInEnterprise={openNewRecordInputRowInEnterprise(
                          enterpriseWitOutlets
                        )}
                      />
                    ))}
                </TableBody>
              </Table>
            </ScrollXContainer>

            <TablePagination
              recordsCount={outletsToShow.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              disabled={
                tableState.showGroupOfSingleEnterprise !== null && tableState.showNewRecordInput
              }
            />
          </Paper>
        </div>
      </div>
    </Container>
  );
};
