import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import {
  InputStateGeneric,
  DropdownMultipleStateGeneric,
  DatePickerState,
  VirsInputState,
  DropdownStateGeneric,
  ValidationDate
} from '../../../../utils/tableTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import {
  MAXIMAL_RECORD_YEAR_TODAY,
  MINIMAL_RECORD_YEAR_DEFAULT,
  validatedSelectedVirs,
  validateMandatoryYear,
  validateMultipleChoices,
  validateSingleChoice
} from '../../../../utils/InputValueFunctions';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import {
  FundsReceivedEditRowState,
  FundsReceivedNewRowState,
  FundsReceivedRecordState
} from './rowInitialStateAndTypes';
import { TransactionType } from '../../../../store/fundsReceived/fundsReceivedDataTypes';

export function setFundsReceivedVirsAndUpdateOptions(
  state: FundsReceivedRecordState,
  virs: VirsSearchData
): FundsReceivedRecordState {
  return {
    ...state,
    virs: {
      ...state.virs,
      value: virs,
      error: false
    },
    fundsReceivedYear: {
      ...state.fundsReceivedYear,
      minDates: [
        MINIMAL_RECORD_YEAR_DEFAULT,
        {
          id: 'virsLegalRegistrationDate',
          date: virs.virsLegalRegistrationDate
            ? moment(virs.virsLegalRegistrationDate).startOf('year')
            : undefined,
          text: 'Metai negali būti ankstesni už VIRS įsiregistravimo JAR metus'
        }
      ],
      maxDates: [
        MAXIMAL_RECORD_YEAR_TODAY,
        {
          id: 'virsLegalDeregistrationDate',
          date: virs.virsLegalDeregistrationDate
            ? moment(virs.virsLegalDeregistrationDate).endOf('year')
            : undefined,
          text: 'Metai negali būti vėlesni už VIRS išsiregistravimo iš JAR metus'
        }
      ]
    },
    fundsReceivedOutlets: {
      ...state.fundsReceivedOutlets,
      values: virs.outlets.length === 0 ? [state.fundsReceivedOutlets.options[0]] : [],
      options: [state.fundsReceivedOutlets.options[0], ...virs.outlets]
    }
  };
}

export function setFundsReceivedSumAndValidate(
  fundsReceivedSum: string | null
): InputStateGeneric<string> {
  if (!fundsReceivedSum) {
    return {
      value: fundsReceivedSum,
      id: 'fundsReceivedSum',
      error: true,
      helperText: 'Privaloma suma',
      placeholder: '',
      validated: false
    };
  }
  const fundsReceivedSumParsedToNumber = Number(fundsReceivedSum);

  if (fundsReceivedSumParsedToNumber > 0 && fundsReceivedSumParsedToNumber <= 100000000.0) {
    return {
      value: fundsReceivedSum,
      id: 'fundsReceivedSum',
      error: false,
      helperText: '',
      placeholder: '',
      validated: true
    };
  }
  return {
    value: fundsReceivedSum,
    id: 'fundsReceivedSum',
    error: true,
    helperText: 'Suma tarp 0,01 ir 100 000 000,00',
    placeholder: '',
    validated: false
  };
}

export function setTransactionAndValidate(
  state: DropdownStateGeneric<TransactionType>,
  transaction: TransactionType | null
): DropdownStateGeneric<TransactionType> {
  if (!transaction) {
    return {
      ...state,
      value: transaction,
      validated: false,
      error: true,
      helperText: 'Būtinas sandoris'
    };
  }
  return {
    ...state,
    value: transaction,
    validated: true,
    error: false,
    helperText: ''
  };
}

export function validateNewFundsReceivedState(
  state: FundsReceivedNewRowState
): FundsReceivedNewRowState {
  const virsValidated: VirsInputState = validatedSelectedVirs(state.virs);

  const fundsReceivedYearValidated: DatePickerState = validateMandatoryYear(
    state.fundsReceivedYear
  );
  const validatedfundsReceivedSum: InputStateGeneric<string> = setFundsReceivedSumAndValidate(
    state.fundsReceivedSum.value
  );
  const fundsReceivedOutletsValidated: DropdownMultipleStateGeneric<OutletShortData> =
    validateMultipleChoices(state.fundsReceivedOutlets, 'Būtina (-os) VIP');
  const transactionValidated: DropdownStateGeneric<TransactionType> = validateSingleChoice(
    state.transaction,
    'Būtinas sandoris'
  );

  return {
    ...state,
    virs: virsValidated,
    fundsReceivedYear: fundsReceivedYearValidated,
    fundsReceivedSum: validatedfundsReceivedSum,
    fundsReceivedOutlets: fundsReceivedOutletsValidated,
    transaction: transactionValidated,
    createRecordConfirmationOn:
      virsValidated.validated &&
      fundsReceivedYearValidated.validated &&
      validatedfundsReceivedSum.validated &&
      fundsReceivedOutletsValidated.validated &&
      transactionValidated.validated
  };
}

export function validateEditFundsReceivedRecordState(
  state: FundsReceivedEditRowState
): FundsReceivedEditRowState {
  const virsValidated: VirsInputState = validatedSelectedVirs(state.virs);

  const fundsReceivedYearValidated: DatePickerState = validateMandatoryYear(
    state.fundsReceivedYear
  );
  const validatedfundsReceivedSum: InputStateGeneric<string> = setFundsReceivedSumAndValidate(
    state.fundsReceivedSum.value
  );
  const fundsReceivedOutletsValidated: DropdownMultipleStateGeneric<OutletShortData> =
    validateMultipleChoices(state.fundsReceivedOutlets, 'Būtina (-os) VIP');
  const transactionValidated: DropdownStateGeneric<TransactionType> = validateSingleChoice(
    state.transaction,
    'Būtinas sandoris'
  );

  return {
    ...state,
    virs: virsValidated,
    fundsReceivedYear: fundsReceivedYearValidated,
    fundsReceivedSum: validatedfundsReceivedSum,
    fundsReceivedOutlets: fundsReceivedOutletsValidated,
    transaction: transactionValidated,
    updateRecordConfirmationOn:
      virsValidated.validated &&
      fundsReceivedYearValidated.validated &&
      validatedfundsReceivedSum.validated &&
      fundsReceivedOutletsValidated.validated &&
      transactionValidated.validated
  };
}

function validateYearOnlyDate(state: DatePickerState): DatePickerState {
  if (state.value && !state.value.isValid()) {
    return {
      ...state,
      error: true,
      helperText: 'mmmm',
      validated: false
    };
  }
  if (state.value && state.value.isValid()) {
    const minDateAllowed: ValidationDate | undefined = _.maxBy(state.minDates, 'date');
    const withinMinimumDate: boolean = state.value.isSameOrAfter(minDateAllowed?.date, 'day');
    if (!withinMinimumDate) {
      return {
        ...state,
        error: true,
        helperText: `${minDateAllowed?.text} ${minDateAllowed?.date?.format('YYYY')}`,
        validated: false
      };
    }

    const maxDateAllowed: ValidationDate | undefined = _.minBy(state.maxDates, 'date');
    const withinMaximumDate: boolean = state.value.isSameOrBefore(maxDateAllowed?.date, 'day');
    if (!withinMaximumDate && maxDateAllowed) {
      return {
        ...state,
        error: true,
        helperText: `${maxDateAllowed?.text} ${maxDateAllowed?.date?.format('YYYY')}`,
        validated: false
      };
    }
    return { ...state, error: false, helperText: '', validated: true };
  }

  return {
    ...state,
    validated: false
  };
}

export function updateYearOnlyDateValue(
  newDate: MaterialUiPickersDate | null,
  state: DatePickerState
): DatePickerState {
  const updatedState = {
    ...state,
    value: newDate ? moment(newDate.toDate()) : null
  };
  return validateYearOnlyDate(updatedState);
}
