import {
  EditionCheckDataTableState,
  EditionCheckDataTableAction,
  initialColumnDisplay
} from './tableTypesAndActions';
import { getTableExportDefinition } from './tableStateFunctions';
import { editionCheckTableColumns } from './tableColumns';

export const initialEditionCheckDataTableState: EditionCheckDataTableState = {
  customFilterOn: false,
  columnsDisplayStatus: initialColumnDisplay,
  showNewRecordFields: false,
  tableExportDefinition: getTableExportDefinition(editionCheckTableColumns, initialColumnDisplay)
};

export const editionCheckDataTableReducer = (
  state: EditionCheckDataTableState,
  action: EditionCheckDataTableAction
): EditionCheckDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn
      };
    case 'CREATE_RECORD_CLICKED':
      return {
        ...state,
        showNewRecordFields: true,
        companyCode: action.companyCode
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(
          editionCheckTableColumns,
          columnsDisplayStatus
        )
      };
    }
    case 'CANCEL_CREATION':
      return {
        ...state,
        showNewRecordFields: false
      };
    case 'RESET_COMPANY_CODE':
      return {
        ...state,
        companyCode: undefined
      };
    default:
      return state;
  }
};
