import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { ConditionalWrapper } from '../../ConditionalWrapper/ConditionalWrapper';
import { TooltipOnTableActionButton } from '../../Tooltips/TooltipOnTableActionButton';

const useStyles = makeStyles((theme) => ({
  buttonStyles: {
    fontFamily: 'Open Sans',
    color: theme.palette.primary.main,
    textTransform: 'none',
    width: '35px',
    margin: '0',
    padding: '0',
    textAlign: 'center',
    lineHeight: '1.4',
    fontWeight: 600,
    height: 'auto',
    '&:disabled': {
      color: theme.palette.info.main
    },
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  }
}));

interface Props {
  label: string;
  isDateFromJADIS: boolean;
  onClicked: (date: string) => void;
  isDisabled?: boolean;
}

export const HeaderDateButton: React.FC<Props> = ({
  label,
  isDateFromJADIS,
  onClicked,
  isDisabled
}) => {
  const classes = useStyles();

  return (
    <ConditionalWrapper
      condition={isDateFromJADIS}
      wrapper={(children: React.ReactElement) => (
        <TooltipOnTableActionButton text="Data gauta iš JADIS, jos redagavimas negalimas">
          {children}
        </TooltipOnTableActionButton>
      )}
    >
      <Button
        className={classes.buttonStyles}
        onClick={() => onClicked(label)}
        disabled={isDisabled}
      >
        {label}
      </Button>
    </ConditionalWrapper>
  );
};
