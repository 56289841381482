import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertDialog } from '../../components/Dialogs/AlertDialog';
import { ApplicationState } from '../../store';
import { cancelEditEnterpriseAlert } from '../../store/enterprises/enterprisesActions';

interface Props {
  contentText: string;
}

const OutletsHaveToBeUpdatedAlert: React.FC<Props> = ({ contentText }) => {
  const { editEnterpriseAlertOpen, outletIds } = useSelector(
    (state: ApplicationState) => state.enterpriseData
  );
  const dispatch = useDispatch();

  if (!outletIds) {
    return null;
  }

  function closeAlert() {
    dispatch(cancelEditEnterpriseAlert());
  }

  return (
    <AlertDialog
      isOpen={editEnterpriseAlertOpen}
      dialogText={contentText}
      onContinue={closeAlert}
      onClose={closeAlert}
    />
  );
};

export default OutletsHaveToBeUpdatedAlert;
