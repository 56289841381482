import { Reducer } from 'redux';
import { OutletShortData } from '../outlets/outletsTypes';
import {
  FETCH_LEGAL_FORM_TYPES_REQUEST,
  FETCH_LEGAL_FORM_TYPES_REQUEST_SUCCESS,
  FETCH_LEGAL_FORM_TYPES_REQUEST_ERROR,
  FETCH_SHARE_TYPES_REQUEST,
  FETCH_SHARE_TYPES_REQUEST_ERROR,
  FETCH_SHARE_TYPES_REQUEST_SUCCESS,
  FETCH_ANNULMENT_TYPES_ERROR,
  FETCH_ANNULMENT_TYPES_REQUEST,
  FETCH_ANNULMENT_TYPES_SUCCESS,
  FETCH_ENTERPRISE_TYPES_ERROR,
  FETCH_ENTERPRISE_TYPES_REQUEST,
  FETCH_ENTERPRISE_TYPES_SUCCESS,
  FETCH_DATA_DELETION_PERIOD_REQUEST,
  FETCH_DATA_DELETION_PERIOD_SUCCESS,
  FETCH_DATA_DELETION_PERIOD_ERROR,
  FETCH_FICTITIOUS_OUTLET_ERROR,
  FETCH_FICTITIOUS_OUTLET_REQUEST,
  FETCH_FICTITIOUS_OUTLET_SUCCESS
} from './classifiersActionTypes';
import { AnnulmentType, EnterpriseType, LegalFormType, ShareTypes } from './classifiersTypes';

export interface ClassifiersState {
  loadingLegalFormTypes: boolean;
  legalFormTypes?: LegalFormType[];
  legalFormTypesError?: Error;
  loadingShareTypes: boolean;
  shareTypes?: ShareTypes[];
  shareTypesError?: Error;
  loadingAnnulmentTypes: boolean;
  annulmentTypes?: AnnulmentType[];
  annulmentTypesError?: Error;
  loadingEnterpriseTypes: boolean;
  enterpriseTypes?: EnterpriseType[];
  enterpriseTypesError?: Error;
  loadingDataDeletionPeriod: boolean;
  dataDeletionPeriod?: string;
  dataDeletionPeriodError?: Error;
  loadingFictitiousOutlet: boolean;
  fictitiousOutlet?: OutletShortData[];
  fictitiousOutletError?: Error;
}

export const initialState: ClassifiersState = {
  loadingLegalFormTypes: false,
  legalFormTypes: undefined,
  legalFormTypesError: undefined,
  loadingShareTypes: false,
  shareTypes: undefined,
  shareTypesError: undefined,
  loadingAnnulmentTypes: false,
  annulmentTypes: undefined,
  annulmentTypesError: undefined,
  loadingEnterpriseTypes: false,
  enterpriseTypes: undefined,
  enterpriseTypesError: undefined,
  loadingDataDeletionPeriod: false,
  dataDeletionPeriod: undefined,
  dataDeletionPeriodError: undefined,
  loadingFictitiousOutlet: false,
  fictitiousOutlet: undefined,
  fictitiousOutletError: undefined
};

export const classifiersReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEGAL_FORM_TYPES_REQUEST: {
      return { ...state, loadingLegalFormTypes: true };
    }
    case FETCH_LEGAL_FORM_TYPES_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingLegalFormTypes: false,
        legalFormTypes: action.payload
      };
    }
    case FETCH_LEGAL_FORM_TYPES_REQUEST_ERROR: {
      return {
        ...state,
        loadingLegalFormTypes: false,
        legalFormTypesError: action.payload
      };
    }
    case FETCH_SHARE_TYPES_REQUEST: {
      return { ...state, loadingShareTypes: true };
    }
    case FETCH_SHARE_TYPES_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingShareTypes: false,
        shareTypes: action.payload
      };
    }
    case FETCH_SHARE_TYPES_REQUEST_ERROR: {
      return {
        ...state,
        loadingShareTypes: false,
        shareTypesError: action.payload
      };
    }
    case FETCH_ANNULMENT_TYPES_REQUEST:
      return {
        ...state,
        loadingAnnulmentTypes: true
      };
    case FETCH_ANNULMENT_TYPES_SUCCESS:
      return {
        ...state,
        loadingAnnulmentTypes: false,
        annulmentTypes: action.payload
      };
    case FETCH_ANNULMENT_TYPES_ERROR:
      return {
        ...state,
        loadingAnnulmentTypes: false,
        annulmentTypesError: action?.payload?.data
      };
    case FETCH_ENTERPRISE_TYPES_REQUEST:
      return {
        ...state,
        loadingEnterpriseTypes: true
      };
    case FETCH_ENTERPRISE_TYPES_SUCCESS:
      return {
        ...state,
        loadingEnterpriseTypes: false,
        enterpriseTypes: action.payload
      };
    case FETCH_ENTERPRISE_TYPES_ERROR:
      return {
        ...state,
        loadingEnterpriseTypes: false,
        enterpriseTypesError: action?.payload?.data
      };
    case FETCH_DATA_DELETION_PERIOD_REQUEST:
      return {
        ...state,
        loadingDataDeletionPeriod: true,
        dataDeletionPeriodError: undefined
      };
    case FETCH_DATA_DELETION_PERIOD_SUCCESS:
      return {
        ...state,
        loadingDataDeletionPeriod: false,
        dataDeletionPeriod: action?.payload
      };
    case FETCH_DATA_DELETION_PERIOD_ERROR:
      return {
        ...state,
        loadingDataDeletionPeriod: false,
        dataDeletionPeriodError: action?.payload?.data
      };
    case FETCH_FICTITIOUS_OUTLET_REQUEST:
      return {
        ...state,
        loadingFictitiousOutlet: true
      };
    case FETCH_FICTITIOUS_OUTLET_SUCCESS:
      return {
        ...state,
        loadingFictitiousOutlet: false,
        fictitiousOutlet: action.payload
      };
    case FETCH_FICTITIOUS_OUTLET_ERROR:
      return {
        ...state,
        loadingFictitiousOutlet: false,
        fictitiousOutletError: action?.payload?.data
      };
    default:
      return state;
  }
};
