import { useState } from 'react';
import { InstitutionDataRowState } from '../tableTypes';

const useInstitutionTableRow = () => {
  const [rowState, setRowState] = useState<InstitutionDataRowState>({
    documentStatusId: undefined,
    showPreview: false,
    showReport: false,
    showExtension: false
  });

  const openReportDialog = (documentStatusId: number) => () =>
    setRowState({ ...rowState, showReport: true, documentStatusId });

  function closeReportDialog() {
    setRowState({
      ...rowState,
      documentStatusId: undefined,
      showReport: false
    });
  }

  function openDocumentPreview(documentStatusId: number) {
    setRowState({ ...rowState, showPreview: true, documentStatusId });
  }

  function closeDocumentPreview() {
    setRowState({ ...rowState, showPreview: false });
  }

  const toggleExtension = () =>
    setRowState({ ...rowState, showExtension: !rowState.showExtension });

  return {
    rowState,
    toggleExtension,
    closeDocumentPreview,
    openDocumentPreview,
    openReportDialog,
    closeReportDialog
  };
};

export default useInstitutionTableRow;
