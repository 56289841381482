import React, { useState, useEffect } from 'react';
import { Button, Divider, Grid, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { UserMessage, UserMessageRecord } from '../../../store/userMessages/userMessageTypes';
import { UserMessageDialog } from '../../../components/UserMessageDialog/UserMessageDialog';
import {
  markMessagesRead,
  removeUserMessages,
  resetUserMessageRemovingState
} from '../../../store/userMessages/userMessagesActions';
import { LinkRouter } from '../../../components/Router/LinkRouter';
import MessagesMenuItem from './Components/MessageMenuItem';
import MarkAllReadButton from './Components/MarkAllReadButton';

interface Props {
  isOpen: boolean;
  anchorRef: null | HTMLElement;
  closeList: () => void;
}

const MessagesMenu: React.FC<Props> = ({ isOpen, anchorRef, closeList }) => {
  const classes = makeStyles({
    viewAllButton: {
      border: '1px solid #a8b1bd',
      backgroundColor: 'white',
      color: '#637082',
      boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.1)',
      fontWeight: 500,
      '&:hover': {
        border: '1px solid #006FB2',
        backgroundColor: 'white',
        color: '#006FB2'
      },
      '&:disabled': {
        backgroundColor: '#DBEDFC',
        color: '#006FB2',
        border: '1px solid #DBEDFC'
      }
    },
    markReadButton: {
      margin: 0,
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#637082'
      }
    },
    menu: {
      '& ul': {
        width: '100% !important'
      }
    }
  })();

  const {
    virsis: { currentUser },
    userMessages: {
      userMessages,
      loadingUserMessages,
      userMessagesError,
      removingUserMessages,
      isUserMessagesRemoved,
      removingUserMessagesError,
      markingUserMessagesRead,
      isUserMessagesMarkedRead,
      isUserMessagesMarkedUnread
    }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const [state, setState] = useState<{
    selectedMessage: UserMessage | undefined;
  }>({ selectedMessage: undefined });

  useEffect(() => {
    setState({
      selectedMessage: undefined
    });
  }, [isUserMessagesRemoved, isUserMessagesMarkedRead, isUserMessagesMarkedUnread]);

  const readMessagesCount: number =
    userMessages && !loadingUserMessages ? userMessages.filter((msg) => msg.read).length : 0;

  const viewAllMessagesText = readMessagesCount
    ? `(+${readMessagesCount})`
    : `(${userMessages ? userMessages.length : 0})`;

  const unreadMessages: UserMessage[] = userMessages ? userMessages.filter((msg) => !msg.read) : [];

  function markUnreadMessagesAsRead() {
    if (unreadMessages.length) {
      const messages: UserMessageRecord[] = unreadMessages.map((msg) => ({
        messageId: msg.messageId,
        messageStatusId: msg.messageStatusId
      }));
      reduxDispatch(markMessagesRead(messages));
    }
  }

  function openMessageDialog(message: UserMessage) {
    setState({ ...state, selectedMessage: message });
  }

  function closeMessageDialog(message: UserMessage) {
    setState({ ...state, selectedMessage: undefined });

    if (!message.read) {
      reduxDispatch(
        markMessagesRead([
          {
            messageId: message.messageId,
            messageStatusId: message.messageStatusId
          }
        ])
      );
    }
  }

  function removeSelectedMessage() {
    if (state.selectedMessage) {
      const messages: UserMessageRecord[] = [
        {
          messageId: state.selectedMessage?.messageId,
          messageStatusId: state.selectedMessage.messageStatusId
        }
      ];
      reduxDispatch(removeUserMessages(messages));
    }
  }

  function closeRemoveConfirmationOnError() {
    reduxDispatch(resetUserMessageRemovingState());
  }

  return (
    <>
      {!loadingUserMessages && !userMessagesError && (
        <Menu
          anchorEl={anchorRef}
          keepMounted
          open={isOpen}
          onClose={closeList}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.menu}
        >
          <div style={{ padding: ' 0 14px 14px 14px' }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h3">Pranešimai</Typography>
              </Grid>
              <Grid item>
                <MarkAllReadButton
                  markingUserMessagesRead={markingUserMessagesRead}
                  markUnreadMessagesAsRead={markUnreadMessagesAsRead}
                  disabled={!unreadMessages.length}
                />
              </Grid>
            </Grid>
            <Divider light style={{ margin: '0 0 8px 0' }} />

            {unreadMessages.length ? (
              unreadMessages.map((msg) => (
                <MessagesMenuItem
                  message={msg}
                  openMessageDialog={openMessageDialog}
                  key={`${msg.messageId}${msg.messageStatusId}`}
                />
              ))
            ) : (
              <MenuItem disabled style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Typography>Neperskaitytų pranešimų nėra</Typography>
              </MenuItem>
            )}

            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <LinkRouter path="/pranesimai" noHoverUnderline>
                  <Button onClick={closeList} className={classes.viewAllButton}>
                    <Typography variant="h6">{`Peržiūrėti visus ${viewAllMessagesText}`}</Typography>
                  </Button>
                </LinkRouter>
              </Grid>
            </Grid>
          </div>
        </Menu>
      )}

      {state.selectedMessage && (
        <UserMessageDialog
          message={state.selectedMessage}
          closeDialog={closeMessageDialog}
          removeMessage={removeSelectedMessage}
          isProcessing={removingUserMessages}
          error={removingUserMessagesError}
          onRemoveError={closeRemoveConfirmationOnError}
          currentUser={currentUser}
          closeList={closeList}
        />
      )}
    </>
  );
};

export default MessagesMenu;
