import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCell } from '@material-ui/core';
import {
  OutletEdition,
  OutletEditionRecord,
  OutletInfo
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { EditionRowBasic } from './RowBasic';
import { editionEditRowReducer } from './editionRowState/editRecordReducer';
import { ApplicationState } from '../../../../store';
import {
  EditionEditRowState,
  initialEditionEditRowState
} from './editionRowState/rowInitialStateAndTypes';
import {
  updateEdition,
  removeEdition,
  resetEditionRemovingState
} from '../../../../store/outletInfoData/outletInfoDataActions';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { EditAndRemoveActions } from '../../../../components/TableRowActions/EditAndRemoveActions';
import AllowedTo from '../../../AllowedTo';
import { Roles } from '../../../../store/virsis/dataTypes';
import { DropdownInputCell } from '../../../../components/TableInputs/DropdownInput';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { MoneyInputCell } from '../../../../components/TableInputs/MoneyInput';
import { EditionPeriodShort } from '../../../../store/classifiers/classifiersTypes';
import { EditionColumnsDisplayStatus } from '../../tablesState/editionTableTypes';

interface Props {
  record: OutletEdition;
  outletInfo: OutletInfo;
  columnsDisplay: EditionColumnsDisplayStatus;
}

export const EditionDataRowContainer: React.FC<Props> = ({
  record,
  outletInfo: { outletId, controlledFrom, controlledTo, outletIsPeriodic, missingPeriods },
  columnsDisplay
}) => {
  const [rowState, rowDispatch] = useReducer(editionEditRowReducer, initialEditionEditRowState);

  const {
    outletInfoData: {
      updatingEdition,

      removingEdition,
      editionRemoved,
      removingEditionError
    },
    virsis: { virsData, currentUser }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  function turnOnEditing() {
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      periodic: outletIsPeriodic,
      missingPeriods: missingPeriods || [],
      controlledFrom: controlledFrom || null,
      controlledTo: controlledTo || null
    });
  }

  function setPeriod(editionPeriod: EditionPeriodShort | null) {
    rowDispatch({ type: 'EDITION_PERIOD_CHANGED', editionPeriod });
  }

  function setDate(editionDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'EDITION_DATE_CHANGED', editionDate });
  }

  function setCirculation(circulation: string) {
    rowDispatch({ type: 'CIRCULATION_CHANGED', circulation });
  }

  function cancelEditing() {
    rowDispatch({ type: 'EDITING_CANCELLED' });
  }

  function updateEditionRecord(state: EditionEditRowState) {
    if (virsData && currentUser && outletId) {
      const updatedEdition: OutletEditionRecord = {
        circulation: Number(state.circulation.value),
        editionDate: state.editionDate.value
          ? state.editionDate.value.format('L').toString()
          : null,
        editionPeriodId: outletIsPeriodic
          ? Number(state.editionPeriod.value?.periodId) || null
          : null
      };
      reduxDispatch(updateEdition(virsData.virsId, outletId, record.editionId, updatedEdition));
    }
  }

  function onRemoveRecordClicked() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function removeRecord() {
    if (virsData && currentUser && outletId) {
      reduxDispatch(removeEdition(virsData.virsId, outletId, record.editionId));
    }
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  function closeOnRemoveError() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetEditionRemovingState());
  }

  return (
    <>
      {record.confirmationStatus === 'P' && (
        <EditionRowBasic
          id={`${record.editionId}`}
          periodic={outletIsPeriodic}
          columnsToDisplay={columnsDisplay}
          first={record.periodName}
          second={record.editionDate}
          third={record.circulation}
        />
      )}
      {record.confirmationStatus !== 'P' && (
        <EditionRowBasic
          id={`${record.editionId}`}
          periodic={outletIsPeriodic}
          rowStatus="RUOSIAMAS"
          columnsToDisplay={columnsDisplay}
          firstInput={
            rowState.editingOn && (
              <DropdownInputCell
                selectValue={setPeriod}
                dropdownState={rowState.editionPeriod}
                getOptionLabel={(period) => period.periodName}
                getOptionSelected={(option, value) => option.periodId === value.periodId}
              />
            )
          }
          first={!rowState.editingOn && record.periodName}
          secondInput={
            rowState.editingOn ? (
              <DateInputImprovedCell state={rowState.editionDate} setDate={setDate} isRequired />
            ) : (
              <TableCell>{record.editionDate}</TableCell>
            )
          }
          thirdInput={
            rowState.editingOn ? (
              <MoneyInputCell
                setValue={setCirculation}
                inputState={rowState.circulation}
                outlined
                noDecimals
                isRequired
              />
            ) : (
              <TableCell>{record.circulation}</TableCell>
            )
          }
          actions={
            rowState.editingOn ? (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <SaveAndCancelActions
                  handleConfirmationYes={() =>
                    rowDispatch({
                      type: 'UPDATE_RECORD_CLICKED',
                      onValid: updateEditionRecord
                    })
                  }
                  isProcessing={updatingEdition}
                  handleCancelButtonClick={cancelEditing}
                />
              </AllowedTo>
            ) : (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <EditAndRemoveActions
                  handleEditButtonClick={turnOnEditing}
                  handleRemoveButtonClick={onRemoveRecordClicked}
                  confirmationOn={rowState.removeRecordConfirmationOn}
                  handleConfirmationYes={removeRecord}
                  handleConfirmationNo={closeRemoveConfirmation}
                  isProcessing={removingEdition}
                  isSuccess={editionRemoved}
                  error={removingEditionError}
                  onErrorClose={closeOnRemoveError}
                />
              </AllowedTo>
            )
          }
        />
      )}
    </>
  );
};
