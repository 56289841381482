import React, { createContext, Dispatch, useReducer } from 'react';
import { ProvidedDataTable } from './Table';
import { ProvidedDataVirs } from '../../store/providedData/providedDataTypes';
import { providedDataTableReducer } from './tableState/tableReducer';
import {
  initialProvidedDataTableState,
  ProvidedDataTableAction,
  ProvidedDataTableState
} from './tableState/tableInitialStateAndTypes';

export const ProvidedDataTableStateContext = createContext<{
  state: ProvidedDataTableState;
}>({
  state: initialProvidedDataTableState
});

export const ProvidedDataTableDispatchContext = createContext<{
  dispatch: Dispatch<ProvidedDataTableAction>;
}>({
  dispatch: () => {}
});

interface Props {
  loading: boolean;
  data?: ProvidedDataVirs[];
  dataError?: Error;
}

export const ProvidedDataTableWithContext: React.FC<Props> = ({ loading, data, dataError }) => {
  const [state, dispatch] = useReducer(providedDataTableReducer, initialProvidedDataTableState);
  return (
    <ProvidedDataTableStateContext.Provider value={{ state }}>
      <ProvidedDataTableDispatchContext.Provider value={{ dispatch }}>
        <ProvidedDataTable loading={loading} data={data} dataError={dataError} />
      </ProvidedDataTableDispatchContext.Provider>
    </ProvidedDataTableStateContext.Provider>
  );
};
