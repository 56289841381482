import moment from 'moment';
import {
  intialRenewOutletState,
  OutletRenewRowAction,
  OutletRenewRowState
} from './outletRowStateAndTypes';
import { validatedRenewState } from './outletRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../utils/InputValueFunctions';

export const renewOutletReducer = (
  state: OutletRenewRowState,
  action: OutletRenewRowAction
): OutletRenewRowState => {
  switch (action.type) {
    case 'START_RENEWING_CLICKED':
      return {
        ...state,
        controlledFrom: {
          ...state.controlledFrom,
          minDates: [
            {
              id: 'enterpriseValidFrom',
              date: moment(action.enterpriseValidFrom),
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            {
              id: 'previousControlledTo',
              date: moment(action.outlet.controlledTo).add(1, 'days'),
              text: 'Anksčiausia galima pratęsimo data'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date: action.deregistrationDate !== null ? moment(action.deregistrationDate) : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo iš JAR datą'
            },
            {
              id: 'enterpriseValidTo',
              date: action.enterpriseValidTo ? moment(action.enterpriseValidTo) : null,
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        controlledTo: {
          ...state.controlledFrom,
          minDates: [
            {
              id: 'enterpriseValidFrom',
              date: moment(action.enterpriseValidFrom),
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            {
              id: 'previousControlledTo',
              date: moment(action.outlet.controlledTo),
              text: 'Data negali būti vėlesnė už ankstesnio valdymo pabaigos datą'
            },
            {
              id: 'controlledFrom',
              date: null,
              text: 'Data negali būti vėlesnė už valdymo pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date: action.deregistrationDate !== null ? moment(action.deregistrationDate) : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo iš JAR datą'
            },
            {
              id: 'enterpriseValidTo',
              date: action.enterpriseValidTo ? moment(action.enterpriseValidTo) : null,
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        showRenewRow: true
      };
    case 'CANCEL_RENEWING_CLICKED':
      return intialRenewOutletState;
    case 'RENEW_FROM_CHANGED':
      return {
        ...state,
        controlledFrom: updateDateValue(action.controlledFrom, state.controlledFrom),
        controlledTo: updateMinDates(action.controlledFrom, 'controlledFrom', state.controlledTo)
      };
    case 'RENEW_TO_CHANGED':
      return {
        ...state,
        controlledTo: updateDateValue(action.controlledTo, state.controlledTo),
        controlledFrom: updateMaxDates(action.controlledTo, 'controlledTo', state.controlledFrom)
      };
    case 'RENEW_RECORD_CLICKED':
      return validatedRenewState(state);
    case 'STOP_RENEWING_RECORD_CLICKED':
      return {
        ...state,
        renewConfirmationOn: false
      };
    case 'RENEW_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        renewConfirmationOn: false
      };
    case 'RENEW_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        renewConfirmationOn: false
      };
    default:
      return state;
  }
};
