import React from 'react';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { DocumentActionButton } from './DocumentActionButton';

interface Props {
  onClick?: () => void;
}

export const SignDocumentButton: React.FC<Props> = ({ onClick }) => (
  <DocumentActionButton onClick={onClick} IconComponent={BorderColorIcon} text="Pasirašyti" />
);
