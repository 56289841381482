import React, { useEffect } from 'react';
import { RouteChildrenProps, Switch } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import { fetchProvidedDataByVirsId } from '../../../../store/providedData/providedDataActions';
import { ApplicationState } from '../../../../store';
import { fetchEnterpriseData } from '../../../../store/enterprises/enterprisesActions';
import {
  fetchAnnulmentTypesRequest,
  fetchEnterpriseTypesRequest
} from '../../../../store/classifiers/classifiersActions';
import ShareholdersMainTablePage from '../../../../pages/virs/ShareholdersMainTablePage';
import SecondaryVirsDataEditingTabMenuHeader from '../navigation/VirsDataSubmissionSecondaryHeader';
import { VirsDataSubmissionNavigation } from '../navigation/VirsDataSubmissionNavigation';
import OutletInfoTablesPage from '../../../../pages/virs/OutletInfoTablesPage';
import EnterpriseDataPage from '../../../../pages/virs/EnterpriseDataPage';
import OutletsDataPage from '../../../../pages/virs/OutletsDataPage';
import ShareholdersSelectedDatePage from '../../../../pages/virs/ShareholdersSelectedDateDataPage';
import { DataEditingAlert } from '../../../../components/Alert/DataEditingAlert';
import ProvidedDataPage from '../../../../pages/virs/ProvidedDataPage';
import { ShareholdersPages } from '../../../../pages/virs/ShareholdersPages';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletData } from '../../../../store/outlets/outletsActions';

const VirsDataSubmissionTablesContainer: React.FC<RouteChildrenProps> = ({ match }) => {
  const reduxDispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;
  const {
    virsis: { virsData, currentUser, selectedPersonId },
    classifiers: { annulmentTypes }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  useEffect(() => {
    if (currentUser && virsData && selectedPersonId) {
      reduxDispatch(fetchEnterpriseData(virsData.virsId, selectedPersonId));
    }
  }, [reduxDispatch, currentUser, virsData, selectedPersonId]);

  const pathNameOutlet = pathname.includes('visuomenes-informavimo-priemones');
  useEffect(() => {
    if (virsData && pathNameOutlet) {
      reduxDispatch(fetchOutletData(virsData.virsId));
      reduxDispatch(fetchEnterpriseTypesRequest());
    }
  }, [reduxDispatch, virsData, pathNameOutlet]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  const pathNameDocuments = pathname.includes('el-dokumentai');
  useEffect(() => {
    if (virsData && pathNameDocuments) {
      reduxDispatch(fetchProvidedDataByVirsId(virsData.virsId));
    }
  }, [reduxDispatch, virsData, pathNameDocuments]);

  return (
    <>
      <SecondaryVirsDataEditingTabMenuHeader />
      <VirsDataSubmissionNavigation />
      <Switch>
        <Route exact path={`${match?.url}/el-dokumentai`}>
          <DataEditingAlert />
          <ProvidedDataPage />
        </Route>

        <Route path={`${match?.url}/veiklos-rusys`}>
          <EnterpriseDataPage institutionContext />
        </Route>

        <Route exact path={`${match?.url}/visuomenes-informavimo-priemones/`}>
          <OutletsDataPage />
        </Route>

        <Route exact path={`${match?.url}/visuomenes-informavimo-priemones/:outletId/:tablePath`}>
          <OutletInfoTablesPage />
        </Route>

        <Route exact path={`${match?.url}/visuomenes-informavimo-priemones/:outletId`}>
          <OutletInfoTablesPage />
        </Route>

        <ShareholdersPages>
          <Route exact path={`${match?.url}/dalyviai/konkreti-data`}>
            <ShareholdersSelectedDatePage />
          </Route>

          <Route exact path={`${match?.url}/dalyviai`}>
            <ShareholdersMainTablePage />
          </Route>
        </ShareholdersPages>
      </Switch>
    </>
  );
};

export default VirsDataSubmissionTablesContainer;
