import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow } from '@material-ui/core';

import { ApplicationState } from '../../store';
import {
  ProfessionalMisconductDataVirs,
  ProfessionalMisconductOutlet
} from '../../store/professionalMisconducts/professionalMisconductsTypes';
import { getUniqueOptionsGeneric } from '../../utils/tableDataFunctions';

import {
  ProfessionalMisconductColumnHeader,
  ProfessionalMisconductTableField
} from './tableState/tableInitialStateAndTypes';
import {
  useProfessionalMisconductTableState,
  useProfessionalMisconductTableDispatch
} from './Context';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: ProfessionalMisconductColumnHeader[];
}

const ProfessionalMiconductTableCustomFilter: React.FC<Props> = ({
  columnNames: [
    decisionDate,
    documentNumber,
    outletName,
    professionalMisconduct,
    sanction,
    validFrom,
    validTo,
    misconductDeterminedBy
  ]
}) => {
  const { state: tableState } = useProfessionalMisconductTableState();
  const { dispatch: tableDispatch } = useProfessionalMisconductTableDispatch();

  const handleSetCustomFilter = (
    column: ProfessionalMisconductTableField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector((state: ApplicationState) => {
      return state.professionalMisconductData.professionalMisconductVirsData;
    }) || [];

  const getOutlet = (item: ProfessionalMisconductDataVirs) => item.professionalMisconductOutlets;
  const getMisconduct = (item: ProfessionalMisconductOutlet) => item.determinedMisconducts;

  const mapableHeaders = [
    {
      header: decisionDate,
      mapper: (data: ProfessionalMisconductDataVirs[]) =>
        data.map((item) => String(item.decisionDate))
    },
    {
      header: documentNumber,
      mapper: (data: ProfessionalMisconductDataVirs[]) => data.map((item) => item.documentNumber)
    },
    {
      header: outletName,
      mapper: (data: ProfessionalMisconductDataVirs[]) =>
        data.flatMap(getOutlet).map((item) => item.outletName)
    },
    {
      header: professionalMisconduct,
      mapper: (data: ProfessionalMisconductDataVirs[]) =>
        data
          .flatMap(getOutlet)
          .flatMap(getMisconduct)
          .map((item) => item.misconductTypeName)
    },
    {
      header: sanction,
      mapper: (data: ProfessionalMisconductDataVirs[]) =>
        data
          .flatMap(getOutlet)
          .flatMap(getMisconduct)
          .flatMap((item) => item.sanctions)
          .map((item) => item.sanctionTypeName)
    },
    {
      header: validFrom,
      mapper: (data: ProfessionalMisconductDataVirs[]) => data.map((item) => item.validFrom)
    },
    {
      header: validTo,
      mapper: (data: ProfessionalMisconductDataVirs[]) => data.map((item) => item.validTo)
    },
    {
      header: misconductDeterminedBy,
      mapper: (data: ProfessionalMisconductDataVirs[]) => data.map((item) => item.determinedBy)
    }
  ];

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {mapableHeaders
          .filter(({ header }) => tableState.columnsDisplayStatus[header.id])
          .map(({ header, mapper }) => (
            <TableCustomFilterCellGeneric
              key={header.id}
              id={header.id}
              type={header.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptionsGeneric(allData, mapper)}
            />
          ))}
      </TableRow>
    </TableBody>
  );
};

export default ProfessionalMiconductTableCustomFilter;
