import React, { ReactNode } from 'react';
import { createStyles, makeStyles, TableRow } from '@material-ui/core/';
import { RowStatusClassName } from '../../utils/tableTypes';
import virsisTheme from '../../style/virsisTheme';

interface Props {
  status?: string;
  id?: string | number;
  dataHistoryTable?: boolean;
  children: ReactNode;
}

const setRowStyleOnDifferentStatus = (status?: string): RowStatusClassName => {
  switch (status) {
    case 'RUOSIAMAS':
      return 'rowUnsigned';
    case 'PASIRASYTAS':
      return 'rowSigned';
    case 'ANULIUOTAS':
      return 'rowAnnuled';
    case 'ISTORINIS':
      return 'rowHistoric';
    default:
      return 'none';
  }
};

export const RowStyleOnDifferentStatus: React.FC<Props> = ({
  status,
  dataHistoryTable,
  id,
  children
}) => {
  const classes = makeStyles(() =>
    createStyles({
      none: {},
      rowUnsigned: {
        boxShadow: `8px 0 0 ${virsisTheme.palette.primary.light} inset`,
        backgroundColor: dataHistoryTable ? '#F5F8FF' : 'inherit'
      },
      rowSigned: {
        borderLeft: '1px solid inherit',
        boxShadow: '8px 0 0 #ffffff inset'
      },
      rowAnnuled: {
        boxShadow: `8px 0 0 ${virsisTheme.palette.error.main} inset`,
        backgroundColor: dataHistoryTable ? '#ffefef' : 'inherit'
      },
      rowHistoric: { boxShadow: '8px 0 0  #949494 inset' }
    })
  )();

  return (
    <TableRow key={id} className={classes[setRowStyleOnDifferentStatus(status)]}>
      {children}
    </TableRow>
  );
};
