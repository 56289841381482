import React from 'react';
import { useSelector } from 'react-redux';
import {
  OutletRepresentativesColumnHeader,
  OutletRepresentativesColumnHeaderField
} from '../tablesState/representativesTableTypes';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../../components/TableStatusFilters/TableStatusFilters';
import { ApplicationState } from '../../../store';
import { getSubsystem } from '../../../utils/roleHelper';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';

interface Props {
  columnNames: OutletRepresentativesColumnHeader[];
  tableName: OutletTableName;
}

const OutletRepresentativesCustomizationBar: React.FC<Props> = ({ columnNames, tableName }) => {
  const {
    state: { representatives: representativesTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();
  const { currentUser } = useSelector((appState: ApplicationState) => appState.virsis);

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED', table: tableName });
  };

  const handleSetSortByColumn = (column: OutletRepresentativesColumnHeaderField) => {
    tableDispatch({
      type: 'REPRESENTATIVES_SET_SORT_BY_COLUMN',
      sortBy: column
    });
  };

  const toggleDisplayColumn = (column: OutletRepresentativesColumnHeaderField) => {
    tableDispatch({ type: 'REPRESENTATIVES_TOGGLE_DISPLAY_COLUMN', column });
  };

  const toggleNotSignedFilter = () => {
    tableDispatch({ type: 'SHOW_STATUS_UNSIGNED_TOGGLED', table: tableName });
  };

  const toggleRelevantFilter = () => {
    tableDispatch({ type: 'SHOW_STATUS_RELEVANT_TOGGLED', table: tableName });
  };

  const toggleOutdatedFilter = () => {
    tableDispatch({ type: 'SHOW_STATUS_OUTDATED_TOGGLED', table: tableName });
  };

  const subsystem = getSubsystem(currentUser);

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            dontShowNotSigned={subsystem === 'LRTK' || subsystem === 'ZEIT'}
            notSignedFilterOn={representativesTableState.showStatusNotSigned}
            relevantFilterOn={representativesTableState.showStatusRelevant}
            outdatedFilterOn={representativesTableState.showStatusOutdated}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterToggler={toggleOutdatedFilter}
            notSignedFilterDisabled={!representativesTableState.statusNotSignedFilterEnabled}
            relevantFilterDisabled={!representativesTableState.statusRelevantFilterEnabled}
            outdatedFilterDisabled={!representativesTableState.statusOutdatedFilterEnabled}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={representativesTableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={representativesTableState.order}
            sortBy={representativesTableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={representativesTableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default OutletRepresentativesCustomizationBar;
