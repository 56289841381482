import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditionCheckDataInstTableHeaderType,
  EditionCheckDataTableField
} from './tableState/tableTypesAndActions';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableStatusFilters from '../../components/TableStatusFilters/TableStatusFilters';
import { EditionCheckDataTableStateContext, EditionCheckDataTableDispatchContext } from './Context';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { ApplicationState } from '../../store';
import {
  customFilterReset,
  showStatusOutdatedToggle,
  showStatusRelevantToggle,
  showStatusUnsignedToggled,
  sortingChanged
} from '../../store/editionChecksTable/editionChecksTableActions';

interface Props {
  columnNames: EditionCheckDataInstTableHeaderType[];
  disabled?: boolean;
}

export const EditionCheckDataTableCustomizationBar: React.FC<Props> = ({
  columnNames,
  disabled
}) => {
  const reduxDispatch = useDispatch();
  const { tableDataState } = useSelector((state: ApplicationState) => state.editionDataTable);
  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const toggleDisplayCustomFilter = () => {
    reduxDispatch(customFilterReset());
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: EditionCheckDataTableField) => {
    reduxDispatch(sortingChanged(column));
  };

  const toggleDisplayColumn = (column: EditionCheckDataTableField) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    reduxDispatch(showStatusUnsignedToggled());
  };

  const toggleRelevantFilter = () => {
    reduxDispatch(showStatusRelevantToggle());
  };

  const toggleOutdatedFilter = () => {
    reduxDispatch(showStatusOutdatedToggle());
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            allFiltersDisabled={disabled}
            notSignedFilterDisabled={!tableDataState.statusNotSignedFilterEnabled}
            notSignedFilterOn={tableDataState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterDisabled={!tableDataState.statusRelevantFilterEnabled}
            relevantFilterOn={tableDataState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterDisabled={!tableDataState.statusOutdatedFilterEnabled}
            outdatedFilterOn={tableDataState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            allToolsDisabled={disabled}
            tableColumns={columnNames}
            showFilter={tableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={tableDataState.order}
            sortBy={tableDataState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={tableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
