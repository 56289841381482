import { Reducer } from 'redux';

import {
  Action,
  RESET,
  SET_CANCEL_SUCCESS,
  SET_ERROR,
  SET_LOADING,
  SET_UNISIGN_DATA,
  SET_UNISIGN_STATUS,
  SET_VERIFY_SUCCESS,
  UnisignDataState,
  UnisignStatus
} from './unisignTypes';

export const UnisignInitialState: UnisignDataState = {
  unisignData: undefined,
  error: undefined,
  isLoading: true,
  unisignStatus: UnisignStatus.LOADING,
  verifySuccess: undefined,
  cancelSuccess: undefined
};

export const unisignReducer: Reducer<UnisignDataState, Action> = (
  state = UnisignInitialState,
  action: Action
) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_VERIFY_SUCCESS:
      return { ...state, verifySuccess: action.payload };
    case SET_CANCEL_SUCCESS:
      return { ...state, cancelSuccess: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_UNISIGN_DATA:
      return { ...state, unisignData: action.payload };
    case SET_UNISIGN_STATUS:
      return { ...state, unisignStatus: action.payload };
    case RESET:
      return UnisignInitialState;
    default:
      return { ...state };
  }
};
