import React, { useState } from 'react';

import { DocumentValidationError } from '../../store/document/documentTypes';
import { PreviewDocumentDialog } from './PreviewDocumentDialog';
import { UnisignDialog } from '../UnisignContainers/UnisignDialog';
import { WordStatus } from '../../utils/tableTypes';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../../store/errorOrSuccessMessage/errorOrSuccessMessageAction';
import { setUnisignStatus } from '../../store/unisign/unisignActions';
import { UnisignStatus } from '../../store/unisign/unisignTypes';

interface Props {
  openDialog: boolean;
  onClose: () => void;
  documentErrors?: DocumentValidationError[];
  documentPath: string;
  documentId: number;
  documentStatus: WordStatus;
  onSignSuccess: () => void;
  hasUnsignedValidTo?: boolean;
  validate?: boolean;
}

enum DisplayState {
  SHOW_UNISIGN,
  SHOW_PREVIEW,
  SHOW_NONE
}

const PreviewAndSignDocumentDialog: React.FC<Props> = ({
  openDialog,
  onClose,
  documentErrors,
  documentPath,
  documentId,
  documentStatus,
  onSignSuccess,
  hasUnsignedValidTo,
  validate
}) => {
  const dispatch = useDispatch();
  const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.SHOW_PREVIEW);

  const handleClose = () => {
    onClose();
    setDisplayState(DisplayState.SHOW_PREVIEW);
    dispatch(setUnisignStatus(UnisignStatus.LOADING));
  };

  const showPreview = openDialog && displayState === DisplayState.SHOW_PREVIEW;
  const showUnisign = openDialog && displayState === DisplayState.SHOW_UNISIGN;
  const disableSign =
    (documentStatus !== 'RUOSIAMAS' && !hasUnsignedValidTo) ||
    (!!documentErrors && documentErrors.some(({ priority }) => priority === 'E'));

  return (
    <>
      <PreviewDocumentDialog
        maxWidth="lg"
        open={showPreview}
        onClose={handleClose}
        documentPath={documentPath}
        documentErrors={documentErrors}
        disableSign={disableSign}
        onSignButtonClicked={() => setDisplayState(DisplayState.SHOW_UNISIGN)}
        documentStatus={documentStatus}
      />
      {showUnisign && (
        <UnisignDialog
          maxWidth="lg"
          open={showUnisign}
          onClose={handleClose}
          unsignedPdfPath={documentPath}
          documentId={documentId}
          onSignSuccess={() => {
            onSignSuccess();
            dispatch(sendMessage('success', 'Dokumentas sėkmingai pasirašytas'));
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default PreviewAndSignDocumentDialog;
