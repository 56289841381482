import { LegalEntityData } from '../../../../store/externalSystemData/externalSystemDataTypes';
import { Order, ColumnHeaderField, TableCustomFilter, DataTableState } from './tableTypes';
import {
  getComparator,
  replaceDecimalPoint,
  stableSort
} from '../../../../utils/tableDataFunctions';
import { getColumnBuilder, TableExportDefinition } from '../../../../utils/exporters/types';
import { columnNames } from './tableColumns';

export function sortByColumn(
  actionSortBy: ColumnHeaderField,
  stateSortBy: ColumnHeaderField,
  stateOrder: Order
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export function updateCustomFilter(
  filter: TableCustomFilter,
  filterBy: ColumnHeaderField,
  value: string | null
): TableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function getCustomFilteredData(
  data: LegalEntityData[],
  filter: TableCustomFilter
): LegalEntityData[] {
  if (data.length === 0) {
    return [];
  }
  function createCustomFilter(field: ColumnHeaderField) {
    return (record: LegalEntityData): boolean => {
      const [valueToFilterBy] = filter[field];
      if (valueToFilterBy) {
        const recordField = record[field];
        if (recordField) {
          return replaceDecimalPoint(recordField?.toString())
            .toLowerCase()
            .includes(replaceDecimalPoint(valueToFilterBy?.toString()).toLowerCase());
        }
        return false;
      }
      return true;
    };
  }

  const allTableFilters = [
    createCustomFilter('supportProviderName'),
    createCustomFilter('supportProviderCode'),
    createCustomFilter('supportReceivedYear'),
    createCustomFilter('financialSupportAmount'),
    createCustomFilter('dataCreatedDate'),
    createCustomFilter('dataProvidedDate')
  ];

  const filteredData = data.filter((record) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });

  return filteredData;
}

function getSortedData(
  data: LegalEntityData[],
  order: Order,
  sortBy: ColumnHeaderField
): LegalEntityData[] {
  if (data) {
    return stableSort(data, getComparator(order, sortBy));
  }
  return [];
}

export function getFilteredAndSortedData(
  isFilterOn: boolean,
  data: LegalEntityData[],
  filter: TableCustomFilter,
  order: Order,
  sortBy: ColumnHeaderField
): LegalEntityData[] {
  const dataPassedCustomFilter = isFilterOn ? getCustomFilteredData(data, filter) : data;

  return getSortedData(dataPassedCustomFilter, order, sortBy);
}

function getDataWithPagination(
  data: LegalEntityData[],
  page: number,
  rowsPerPage: number
): LegalEntityData[] {
  return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export function getUpdatedPageData(
  page: number,
  rowsPerPage: number,
  isCustomFilterOn: boolean,
  data: LegalEntityData[],
  filter: TableCustomFilter,
  order: Order,
  sortBy: ColumnHeaderField
): LegalEntityData[] {
  const customFilteredAndSortedData = getFilteredAndSortedData(
    isCustomFilterOn,
    data,
    filter,
    order,
    sortBy
  );

  return getDataWithPagination(customFilteredAndSortedData, page, rowsPerPage);
}

export function getPagesCount(numOfRecords: number, rowsPerPage: number) {
  return Math.ceil(numOfRecords / rowsPerPage);
}

export function getTableExportDefinition(
  tableState: DataTableState
): TableExportDefinition<LegalEntityData> {
  const { stringValueColumn } = getColumnBuilder<LegalEntityData, ColumnHeaderField>(
    columnNames,
    tableState.columnsDisplayStatus
  );
  return {
    title: 'Juridinių asmenų parama',
    columnGroups: [
      {
        columns: [
          stringValueColumn('supportProviderName', { width: 45 }),
          stringValueColumn('supportProviderCode', { width: 25 }),
          stringValueColumn('financialSupportAmount', { width: 15 }),
          stringValueColumn('supportReceivedYear', { width: 15 }),
          stringValueColumn('dataCreatedDate', { width: 15 }),
          stringValueColumn('dataProvidedDate', { width: 15 })
        ]
      }
    ]
  };
}
