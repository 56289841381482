import { Reducer } from 'redux';

import { getToggledSortOrderGeneric } from '../../utils/tableDataFunctions';
import {
  CUSTOM_FILTER_VALUE_CHANGED,
  INITIALIZE_TABLE_STATE,
  PAGE_SET,
  ROWS_PER_PAGE_SET,
  SHOW_STATUS_RELEVANT_TOGGLED,
  SHOW_STATUS_NOT_SIGNED_TOGGLED,
  SHOW_STATUS_OUTDATED_TOGGLED,
  SORTING_CHANGED,
  UPDATE_TEMP_DATA_STATE,
  CUSTOM_FILTER_VALUE_RESET
} from './paymentsTableActionTypes';
import { initialPaymentsTableDataState, PaymentsTableDataState } from './paymentsTableTypes';
import { updatePaymentDataCustomFilter } from '../../containers/PaymentTableInst/tableState/paymentTableStateFunctions';

export interface PaymentsDataTableState {
  tempTableDataState?: PaymentsTableDataState;
  tableDataState: PaymentsTableDataState;
}

export const initialState: PaymentsDataTableState = {
  tempTableDataState: undefined,
  tableDataState: initialPaymentsTableDataState
};

export const paymentsTableDataReducer: Reducer<PaymentsDataTableState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INITIALIZE_TABLE_STATE:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
          statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
          statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
          showStatusNotSigned: action.payload.showStatusNotSigned,
          showStatusRelevant: action.payload.showStatusRelevant,
          showStatusOutdated: action.payload.showStatusOutdated
        }
      };
    case SHOW_STATUS_OUTDATED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusOutdated: !state.tableDataState.showStatusOutdated,
          page: 0
        }
      };
    case SHOW_STATUS_NOT_SIGNED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusNotSigned: !state.tableDataState.showStatusNotSigned,
          page: 0
        }
      };
    case SHOW_STATUS_RELEVANT_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusRelevant: !state.tableDataState.showStatusRelevant,
          page: 0
        }
      };
    case CUSTOM_FILTER_VALUE_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          customFilter: updatePaymentDataCustomFilter(
            state.tableDataState.customFilter,
            action.payload.filterBy,
            action.payload.value
          ),
          page: 0
        }
      };
    case SORTING_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          sortBy:
            action.payload === state.tableDataState.sortBy
              ? state.tableDataState.sortBy
              : action.payload,
          order: getToggledSortOrderGeneric(
            action.payload,
            state.tableDataState.sortBy,
            state.tableDataState.order
          )
        }
      };
    case PAGE_SET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          page: action.payload
        }
      };
    case ROWS_PER_PAGE_SET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          rowsPerPage: action.payload
        }
      };
    case UPDATE_TEMP_DATA_STATE:
      return {
        ...state,
        tempTableDataState: action.payload
      };
    case CUSTOM_FILTER_VALUE_RESET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          customFilter: initialPaymentsTableDataState.customFilter
        }
      };
    default:
      return state;
  }
};
