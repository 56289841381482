import { action } from 'typesafe-actions';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { VirsSearchData } from '../virsis/dataTypes';
import {
  FETCH_EDITION_CHECK_DATA_REQUEST,
  FETCH_EDITION_CHECK_DATA_SUCCESS,
  FETCH_EDITION_CHECK_DATA_ERROR,
  CREATE_EDITION_REQUEST,
  CREATE_EDITION_SUCCESS,
  UPDATE_EDITION_SUCCESS,
  UPDATE_EDITION_ERROR,
  CONTINUE_ADDING_EDITION_CLICKED,
  CANCEL_ADDING_EDITION_CLICKED,
  RESET_CREATE_EDITION_STATE,
  RESET_UPDATE_EDITION_STATE,
  REMOVE_EDITION_REQUEST,
  REMOVE_EDITION_SUCCESS,
  REMOVE_EDITION_ERROR,
  RESET_REMOVE_EDITION_STATE,
  FETCH_EDITION_CHECK_DATA_VIRS_REQUEST,
  FETCH_EDITION_CHECK_DATA_VIRS_SUCCESS,
  FETCH_EDITION_CHECK_DATA_VIRS_ERROR,
  EDITION_CHECK_DATA_VIRS_PDF_REQUEST,
  CREATE_EDITION_ERROR,
  UPDATE_EDITION_REQUEST,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_SUCCESS,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_ERROR,
  ANNUL_EDITION_CHECK_DATA_REQUEST,
  ANNUL_EDITION_CHECK_DATA_SUCCESS,
  ANNUL_EDITION_CHECK_DATA_ERROR,
  RESET_ANNUL_EDITION_CHECK_DATA_STATE,
  FETCH_PUBLICATION_OUTLETS_REQUEST,
  FETCH_PUBLICATION_OUTLETS_SUCCESS,
  FETCH_PUBLICATION_OUTLETS_ERROR,
  FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST,
  HIDE_POST_SAVE_WARNING,
  RESET_PUBLICATION_OUTLETS
} from './editionChecksActionTypes';

export const fetchEditionCheckData = () => action(FETCH_EDITION_CHECK_DATA_REQUEST);
export const fetchEditionCheckDataSuccess = (data: string) =>
  action(FETCH_EDITION_CHECK_DATA_SUCCESS, data);
export const fetchEditionCheckDataError = (error: Error) =>
  action(FETCH_EDITION_CHECK_DATA_ERROR, error);

export const createEditionCheck = (newEditionCheck: FormData) =>
  action(CREATE_EDITION_REQUEST, {
    newEditionCheck
  });

export const createEditionCheckSuccess = (postSaveWarning?: boolean) =>
  action(CREATE_EDITION_SUCCESS, postSaveWarning);

export const createEditionCheckError = (error: Error) => action(CREATE_EDITION_ERROR, error);

export const updateEditionCheck = (updatedEditionCheck: FormData, id: number | undefined) =>
  action(UPDATE_EDITION_REQUEST, {
    updatedEditionCheck,
    id
  });

export const updateEditionCheckSuccess = (showPostSaveWarning?: boolean) =>
  action(UPDATE_EDITION_SUCCESS, showPostSaveWarning);

export const updateEditionCheckError = (error: Error) => action(UPDATE_EDITION_ERROR, error);

export const hideAddEditionCheckRow = () => action(CANCEL_ADDING_EDITION_CLICKED);

export const showAddEditionCheckRow = () => action(CONTINUE_ADDING_EDITION_CLICKED);

export const resetEditionCheckCreatingState = () => action(RESET_CREATE_EDITION_STATE);

export const resetEditionCheckUpdatingState = () => action(RESET_UPDATE_EDITION_STATE);

export const resetEditionCheckRemovingState = () => action(RESET_REMOVE_EDITION_STATE);

export const removeEditionCheck = (editionCheckId: number) =>
  action(REMOVE_EDITION_REQUEST, {
    editionCheckId
  });

export const removeEditionCheckSuccess = () => action(REMOVE_EDITION_SUCCESS);

export const removeEditionCheckError = (error: Error) => action(REMOVE_EDITION_ERROR, error);

export const fetchEditionCheckDataByVirId = (virsId: number) =>
  action(FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST, virsId);
export const fetchEditionCheckDataByVirIdSuccess = (data: string) =>
  action(FETCH_EDITION_CHECK_DATA_BY_VIRID_SUCCESS, data);
export const fetchEditionCheckDataByVirIdError = (error: Error) =>
  action(FETCH_EDITION_CHECK_DATA_BY_VIRID_ERROR, error);

export const fetchEditionCheckDataVirs = (virsId: number) =>
  action(FETCH_EDITION_CHECK_DATA_VIRS_REQUEST, virsId);
export const fetchEditionCheckDataVirsSuccess = (data: string) =>
  action(FETCH_EDITION_CHECK_DATA_VIRS_SUCCESS, data);
export const fetchEditionCheckDataVirsError = (error: Error) =>
  action(FETCH_EDITION_CHECK_DATA_VIRS_ERROR, error);

export const getEditionCheckDataVirsDocRequest = (docId: number) =>
  action(EDITION_CHECK_DATA_VIRS_PDF_REQUEST, docId);

export const annulEditionCheck = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_EDITION_CHECK_DATA_REQUEST, {
    documentStatusId,
    annulRecord
  });
export const annulEditionCheckSuccess = () => action(ANNUL_EDITION_CHECK_DATA_SUCCESS);
export const annulEditionCheckError = (error: Error) =>
  action(ANNUL_EDITION_CHECK_DATA_ERROR, error);
export const resetAnnulEditionCheckState = () => action(RESET_ANNUL_EDITION_CHECK_DATA_STATE);

export const fetchPublicationOutlets = (virs: VirsSearchData, id: number) =>
  action(FETCH_PUBLICATION_OUTLETS_REQUEST, { virs, id });
export const fetchPublicationOutletsSuccess = (data: string, virs: VirsSearchData, id: number) =>
  action(FETCH_PUBLICATION_OUTLETS_SUCCESS, { data, virs, id });
export const fetchPublicationOutletsError = (error: Error) =>
  action(FETCH_PUBLICATION_OUTLETS_ERROR, error);
export const resetPublicationOutlets = () => action(RESET_PUBLICATION_OUTLETS);

export const fetchEditionCheckByDockIdRequest = (docId: number) =>
  action(FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST, docId);

export const hideEditionCheckPostSaveWarning = () => action(HIDE_POST_SAVE_WARNING);
