import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Formik, Form } from 'formik';
import {
  LinearProgress,
  Typography,
  FormGroup,
  Dialog,
  DialogTitle,
  makeStyles,
  createStyles,
  Divider,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { VirsisParameter } from '../../../store/parameters/parametersDataTypes';
import {
  resetVirsisParameterUpdatingState,
  updateVirsisParameter
} from '../../../store/parameters/parametersActions';
import { ApplicationState } from '../../../store';
import { ParameterDialogBody } from './ParameterDialogBody';
import { FormikFormTextField } from '../../../components/FormikFields/FormikFormTextField';
import { FormikFormDateField } from '../../../components/FormikFields/FormikFormDateField';
import { FormikFormSelectField } from '../../../components/FormikFields/FormikFormSelectField';
import { FormikFormNumberInput } from '../../../components/FormikFields/FormikFormNumberInput';
import { VirsisParameterType, VirsisYesNoString } from '../../../utils/tableTypes';
import { convertVirsisYesNoToLabel } from '../../../utils/tableDataFunctions';
import { CloseDialogButton } from '../../../components/Dialogs/Dialog/CloseButton';
import { CloseContinueButton } from '../../../components/Dialogs/Dialog/CloseContinueButton';
import { Strings } from '../../../utils/strings/Strings';

export interface Props {
  record: VirsisParameter;
  close: () => void;
  isOpen: boolean;
  updateSucess?: boolean;
}
export interface VirsisParameterValues {
  stringValue?: string;
  numberValue?: string;
  dateValue?: MaterialUiPickersDate;
  logicalValue?: VirsisYesNoString | null;
}
const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '20px 35px 10px 35px'
    },
    divider: {
      margin: '10px 35px'
    },
    mainContainer: {
      padding: '10px 35px'
    },
    actions: {
      minWidth: 250,
      padding: '10px 34px',
      height: 50,
      backgroundColor: '#F3F3F3'
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      maxWidth: '400px',
      margin: 0,
      '& > button': {
        padding: 0,
        marginLeft: 4,
        width: 36,
        height: 36
      },
      '& > div > div > div > button': {
        padding: 0
      }
    },
    errorMessage: {
      margin: '0 35px',
      fontWeight: 'normal',
      '& span': {
        color: '#F11A1A',
        fontWeight: 600
      }
    }
  })
);

function getStringTypeSchema(
  paramRequired: boolean,
  minValue: number | null,
  maxValue: number | null
) {
  let schema = Yup.string();
  if (minValue) {
    schema = schema.min(minValue, `Mažiausia galima reikšmė ${maxValue}`);
  }
  if (maxValue) {
    schema = schema.max(maxValue, `Didžiausia galima reikšmė ${maxValue}`);
  }
  if (paramRequired) {
    schema = schema.required('Reikšmė privaloma');
  }
  return schema.nullable();
}

export const ParameterDialogContainer: React.FC<Props> = ({
  record,
  close,
  isOpen,
  updateSucess
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (updateSucess) {
      close();
      reduxDispatch(resetVirsisParameterUpdatingState());
    }
  }, [close, reduxDispatch, updateSucess]);

  const { parameterType, minValue, maxValue, isRequired, isInteger, parameterValue } = record;

  const isNumberType = parameterType === VirsisParameterType.NUMBER;
  const isStringType = parameterType === VirsisParameterType.STRING;
  const isDateType = parameterType === VirsisParameterType.DATE;
  const isLogicalType = parameterType === VirsisParameterType.YesNo;

  const paramRequired = isRequired === 'T';

  const initValues: VirsisParameterValues = {
    numberValue: isNumberType ? parameterValue : undefined,
    stringValue: isStringType ? parameterValue : undefined,
    dateValue: isDateType && parameterValue ? moment(parameterValue) : undefined,
    logicalValue:
      isLogicalType &&
      (parameterValue === VirsisYesNoString.YES || parameterValue === VirsisYesNoString.NO)
        ? parameterValue
        : undefined
  };

  const validationSchema = Yup.object({
    numberValue:
      isNumberType && paramRequired
        ? Yup.number()
            .min(minValue, `Mažiausia galima reikšmė ${minValue}`)
            .max(maxValue, `Didžiausia galima reikšmė ${maxValue}`)
            .nullable()
            .required('Reikšmė privaloma')
        : Yup.number()
            .min(minValue, `Mažiausia galima reikšmė ${minValue}`)
            .max(maxValue, `Didžiausia galima reikšmė ${maxValue}`)
            .nullable(),
    stringValue: isStringType
      ? getStringTypeSchema(paramRequired, minValue, maxValue)
      : Yup.string(),
    dateValue:
      isDateType && paramRequired
        ? Yup.date()
            .typeError('Data turi būti teisingo formato')
            .nullable()
            .required('Reikšmė privaloma')
        : Yup.date().typeError('Data turi būti teisingo formato').nullable(),
    logicalValue:
      isLogicalType && paramRequired
        ? Yup.string().nullable().required('Reikšmė privaloma')
        : Yup.string().nullable()
  });

  const { updatingVirsisParameter, updatingVirsisParameterError } = useSelector(
    (state: ApplicationState) => state.virsisParameters
  );

  function updateParameter(val: string | null) {
    reduxDispatch(updateVirsisParameter(record.parameterId, val, record.parameterCode));
  }

  function closeOrCloseAndResetError() {
    if (updatingVirsisParameterError) {
      reduxDispatch(resetVirsisParameterUpdatingState());
    }
    close();
  }

  function getParameterValueAsString(
    paramType: string,
    values: VirsisParameterValues
  ): string | null {
    switch (paramType) {
      case 'SKAICIUS':
        return values.numberValue || null;
      case 'TEKSTAS':
        return values.stringValue || null;
      case 'DATA':
        return values.dateValue
          ? moment(values.dateValue.toISOString()).format('YYYY-MM-DD')
          : null;
      case 'LOGINIS':
        return values.logicalValue || null;
      default:
        return null;
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const parameValue = getParameterValueAsString(parameterType, values);
        updateParameter(parameValue);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        return (
          <Dialog
            open={isOpen}
            onClose={closeOrCloseAndResetError}
            onExit={closeOrCloseAndResetError}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle disableTypography className={classes.title}>
              <Typography variant="h2">Sistemos parametrai</Typography>
            </DialogTitle>

            <Divider className={classes.divider} />

            <DialogContent className={classes.mainContainer}>
              <ParameterDialogBody
                record={record}
                parameterInput={
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Form>
                      <FormGroup row>
                        {isNumberType && (
                          <FormikFormNumberInput
                            formikKey="numberValue"
                            values={values.numberValue}
                            isInteger={isInteger === VirsisYesNoString.YES}
                            clearValue={() => setFieldValue('numberValue', '')}
                            isRequired={paramRequired}
                            styledClasses={classes}
                          />
                        )}
                        {isStringType && (
                          <FormikFormTextField
                            formikKey="stringValue"
                            values={values.stringValue}
                            error={errors.stringValue}
                            setFieldValue={setFieldValue}
                            maxLength={maxValue}
                            required={paramRequired}
                            withoutLabel
                            styledClasses={classes}
                            isMultiline
                            rowsCount={6}
                            fullWidth
                          />
                        )}
                        {isDateType && (
                          <FormikFormDateField
                            formikKey="dateValue"
                            values={values.dateValue}
                            date={values.dateValue}
                            errors={errors.dateValue}
                            setFieldValue={setFieldValue}
                            isRequired={paramRequired}
                            styledClasses={classes}
                          />
                        )}
                        {isLogicalType && (
                          <FormikFormSelectField
                            formikKey="logicalValue"
                            getOptionLabel={(option: VirsisYesNoString) =>
                              convertVirsisYesNoToLabel(option)
                            }
                            isClearable={!paramRequired}
                            isRequired={paramRequired}
                            value={values.logicalValue}
                            options={[VirsisYesNoString.YES, VirsisYesNoString.NO]}
                            defaultValue={null}
                            errors={errors.logicalValue}
                            setFieldValue={(key: string, value: string) =>
                              setFieldValue(key, value)
                            }
                            withoutLabel
                            styledClasses={classes}
                          />
                        )}
                      </FormGroup>
                    </Form>
                  </MuiPickersUtilsProvider>
                }
              />

              {updatingVirsisParameterError && (
                <>
                  <Divider className={classes.divider} />
                  <Typography className={classes.errorMessage} variant="body2">
                    <span>Klaida. </span>
                    {updatingVirsisParameterError.message}
                  </Typography>
                  <Divider className={classes.divider} />
                </>
              )}
            </DialogContent>
            {updatingVirsisParameter && <LinearProgress />}
            <DialogActions className={classes.actions}>
              <CloseDialogButton
                label={Strings.button__cancel}
                onClick={closeOrCloseAndResetError}
              />
              <CloseContinueButton
                onClick={submitForm}
                disabled={isValidating || updatingVirsisParameter}
                label="Išsaugoti"
              />
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};
