import {
  EditionCheckNewRecordState,
  EditionCheckNewRecordAction,
  initialEditionCheckNewRecordState
} from './initialStateAndTypes';
import {
  validateEditionCheckNewRecordState,
  addFileAndValidateDocuments,
  removeFileAndValidateDocuments,
  getUpdatedOutletOptions
} from './editionCheckRowReducerFunctions';
import {
  setDocumentNumberAndValidate,
  updateDateValue
} from '../../../../utils/InputValueFunctions';
import produce from 'immer';

export const newEditionCheckReducer = (
  state: EditionCheckNewRecordState,
  action: EditionCheckNewRecordAction
): EditionCheckNewRecordState => {
  switch (action.type) {
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return {
        ...state,
        virs: {
          ...state.virs,
          value: action.virs,
          validated: true,
          error: false
        },
        editionCheckOutlets: [
          {
            outlet: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: action.virs.outlets
            },
            loadingPeriods: false,
            editionCheckConclusions: [
              {
                period: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: []
                },
                samePeriodError: false,
                conclusion: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: action.conclusionTypes
                }
              }
            ]
          }
        ],
        showFindVirsDialog: false,
        outlets: action.virs.outlets,
        conclusionTypes: action.conclusionTypes
      };
    case 'CREATING_CANCELED':
      return {
        ...initialEditionCheckNewRecordState
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'CHECK_DATE_CHANGED':
      return {
        ...state,
        checkDate: updateDateValue(action.checkDate, state.checkDate)
      };
    case 'FILE_ADDED':
      return {
        ...state,
        addedDocuments: addFileAndValidateDocuments(
          action.file,
          state.addedDocuments,
          state.editionCheckDocs.length
        )
      };
    case 'FILE_REMOVED':
      return {
        ...state,
        addedDocuments: removeFileAndValidateDocuments(
          action.fileId,
          state.addedDocuments,
          state.editionCheckDocs.length
        )
      };
    case 'FILES_CUSTOM_NAME_CHANGED':
      return {
        ...state,
        customDocumentsName: setDocumentNumberAndValidate(
          action.filesCustomName,
          state.customDocumentsName
        )
      };
    case 'FILE_ADDING_WRONG_FORMAT':
      return {
        ...state,
        addedDocuments: {
          ...state.addedDocuments,
          error: true,
          errorMessage: action.errorMessage
        }
      };
    case 'EDITION_CHECK_OUTLETS_CHANGED':
      return produce(state, (draft) => {
        draft.editionCheckOutlets[action.outletIndex] = {
          ...draft.editionCheckOutlets[action.outletIndex],
          outlet: {
            ...draft.editionCheckOutlets[action.outletIndex].outlet,
            value: action.outlet
          },
          loadingPeriods: !!action.outlet
        };
        draft.editionCheckOutlets.forEach((outletInput) => {
          outletInput.outlet.options = getUpdatedOutletOptions(draft);
        });
      });
    case 'PERIODS_LOADED':
      return {
        ...state,
        editionCheckOutlets: state.editionCheckOutlets.map((outlet) => ({
          ...outlet,
          loadingPeriods: false,
          editionCheckConclusions: outlet.editionCheckConclusions.map((conclusion) => ({
            ...conclusion,
            period: {
              ...conclusion.period,
              options: outlet.outlet.value?.outletId
                ? action.missingPeriodsForOutlet.get(outlet.outlet.value.outletId) || []
                : []
            }
          }))
        }))
      };
    case 'EDITION_CHECK_PERIOD_CHANGED':
      return validateEditionCheckNewRecordState(
        produce(state, (draft) => {
          draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
            action.conclusionIndex
          ].period = {
            ...draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
              action.conclusionIndex
            ].period,
            value: action.period
          };
        })
      );
    case 'EDITION_CHECK_CONCLUSION_CHANGED':
      return validateEditionCheckNewRecordState(
        produce(state, (draft) => {
          draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
            action.conclusionIndex
          ].conclusion = {
            ...draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[
              action.conclusionIndex
            ].conclusion,
            value: action.conclusion
          };
        })
      );
    case 'CREATE_RECORD_CLICKED':
      return validateEditionCheckNewRecordState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'ADD_OUTLET_ROW':
      const tempStateOutlet = validateEditionCheckNewRecordState(state);
      if (!tempStateOutlet.createRecordConfirmationOn) {
        return tempStateOutlet;
      }
      return {
        ...state,
        editionCheckOutlets: [
          ...state.editionCheckOutlets,
          {
            outlet: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: getUpdatedOutletOptions(state)
            },
            loadingPeriods: false,
            editionCheckConclusions: [
              {
                period: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: []
                },
                samePeriodError: false,
                conclusion: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: state.conclusionTypes
                }
              }
            ]
          }
        ]
      };
    case 'CANCEL_OUTLET_ROW':
      return produce(state, (draft) => {
        draft.editionCheckOutlets.splice(action.outletIndex, 1);
        draft.editionCheckOutlets.forEach((outletInput) => {
          outletInput.outlet.options = getUpdatedOutletOptions(draft);
        });
      });
    case 'ADD_PERIOD_ROW':
      const tempStatePeriod = validateEditionCheckNewRecordState(state);
      if (!tempStatePeriod.createRecordConfirmationOn) {
        return tempStatePeriod;
      }
      return produce(state, (draft) => {
        draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions = [
          ...draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions,
          {
            period: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options:
                draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions[0].period
                  .options
            },
            samePeriodError: false,
            conclusion: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: state.conclusionTypes
            }
          }
        ];
      });
    case 'REDUCE_PERIOD_ROW':
      return produce(state, (draft) => {
        draft.editionCheckOutlets[action.outletIndex].editionCheckConclusions.splice(
          action.conclusionIndex,
          1
        );
      });
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
