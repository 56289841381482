import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCell } from '@material-ui/core';
import {
  OutletRepresentativeRecord,
  OutletRepresentative,
  OutletInfo
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { editRepresentativeReducer } from './rowState/editRecordReducer';
import { ApplicationState } from '../../../../store';
import { RepresentativeBasicRow } from './BasicRow';
import { StringInputCell } from '../../../../components/TableInputs/StringInput';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { EditAndRemoveActions } from '../../../../components/TableRowActions/EditAndRemoveActions';
import { RowActionButtonGeneric } from '../../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { initialRepresentativeEditRowState } from './rowState/rowStateAndTypes';
import { SaveAndCancelIcons } from '../../../../components/TableRowActions/SaveAndCancelIcons';
import { Roles } from '../../../../store/virsis/dataTypes';
import AllowedTo from '../../../AllowedTo';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { OutletRepresentativesColumnsDisplayStatus } from '../../tablesState/representativesTableTypes';
import {
  removeRepresentative,
  resetRepresentativeRemovingState,
  updateRepresentative
} from '../../../../store/outletInfoData/outletInfoDataActions';
import { RowActionButton } from '../../../../components/TableRowActions/RowActionButton';
import { validateRepresentativeEditRecordState } from './rowState/rowReducerFunctions';
import { CustomEditIconButton } from '../../../../components/Icons/IconButtons/CustomEditIconButton';

interface Props {
  record: OutletRepresentative;
  outletInfo: OutletInfo;
  columnsDisplay: OutletRepresentativesColumnsDisplayStatus;
}

export const RepresentativeRowContainer: React.FC<Props> = ({
  record,
  outletInfo: { outletId, controlledFrom, controlledTo },
  columnsDisplay
}) => {
  const [rowState, rowDispatch] = useReducer(
    editRepresentativeReducer,
    initialRepresentativeEditRowState
  );

  const {
    outletInfoData: {
      updatingRepresentative,
      updatingRepresentativeError,
      removingRepresentative,
      representativeRemoved,
      removingRepresentativeError
    },
    virsis: { virsData, currentUser }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  function turnOnEditing() {
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      outletControlledFrom: controlledFrom || null,
      outletControlledTo: controlledTo || null
    });
  }

  function setRepValidFrom(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'REPRESENTATION_FROM_DATE_CHANGED',
      validFrom
    });
  }

  function setRepValidTo(validTo: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'REPRESENTATION_TO_DATE_CHANGED',
      validTo
    });
  }

  function setRepNotes(representationNotes: string | null) {
    rowDispatch({
      type: 'REPRESENTATION_NOTES_CHANGED',
      representationNotes
    });
  }

  function cancelEditing() {
    rowDispatch({ type: 'EDITING_CANCELLED' });
  }

  function updateRepresentativeRecord() {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateRepresentativeEditRecordState(rowState).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const message = 'Už turinį atsakingo asmens įrašas atnaujintas';
      const newRepRecord: OutletRepresentativeRecord = {
        personId: record.personId,
        validFrom: rowState.validFrom.value
          ? rowState.validFrom.value.format('L').toString()
          : null,
        validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null,
        representationNotes: rowState.representationNotes.value
      };
      if (virsData && currentUser && outletId)
        reduxDispatch(
          updateRepresentative(
            virsData.virsId,
            outletId,
            record.representativeId,
            newRepRecord,
            message
          )
        );
    }
  }

  function onRemoveRecordClicked() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function removeRecord() {
    if (virsData && currentUser && outletId) {
      reduxDispatch(removeRepresentative(virsData.virsId, outletId, record.representativeId));
    }
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  function closeOnRemoveError() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetRepresentativeRemovingState());
  }

  function unsetRecordDate() {
    rowDispatch({ type: 'START_LOADING' });
    const message = 'Atsakingo asmens įgaliojimo pabaigos data išvalyta.';
    const newRepRecord: OutletRepresentativeRecord = {
      personId: record.personId,
      validFrom: record.validFrom,
      validTo: null,
      representationNotes: record.representationNotes
    };
    if (virsData && currentUser && outletId) {
      reduxDispatch(
        updateRepresentative(
          virsData.virsId,
          outletId,
          record.representativeId,
          newRepRecord,
          message
        )
      );
    }
  }

  useEffect(() => {
    if (updatingRepresentativeError) {
      rowDispatch({ type: 'STOP_LOADING' });
    }
  }, [updatingRepresentativeError]);

  return (
    <>
      {record.editableFully && (
        <RepresentativeBasicRow
          id={`${record.representativeId}`}
          rowStatus="RUOSIAMAS"
          columnsToDisplay={columnsDisplay}
          representativeName={record.representativeName}
          validFromInput={
            rowState.editingOn && (
              <DateInputImprovedCell
                state={rowState.validFrom}
                setDate={setRepValidFrom}
                isRequired
              />
            )
          }
          validFrom={!rowState.editingOn && record.validFrom}
          validToInput={
            rowState.editingOn ? (
              <DateInputImprovedCell state={rowState.validTo} setDate={setRepValidTo} />
            ) : (
              <TableCell>
                {record.validTo && record.validTo}
                <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                  {!record.validTo && <CustomEditIconButton onClick={turnOnEditing} />}
                </AllowedTo>
              </TableCell>
            )
          }
          representationNotesInput={
            rowState.editingOn ? (
              <StringInputCell
                setValue={setRepNotes}
                inputState={rowState.representationNotes}
                multiline
                outlined
                fullWidth
                rows={2}
              />
            ) : (
              <TableCell>{record.representationNotes} </TableCell>
            )
          }
          actions={
            rowState.editingOn ? (
              <SaveAndCancelActions
                handleConfirmationYes={updateRepresentativeRecord}
                isProcessing={updatingRepresentative}
                handleCancelButtonClick={cancelEditing}
              />
            ) : (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <EditAndRemoveActions
                  handleEditButtonClick={turnOnEditing}
                  handleRemoveButtonClick={onRemoveRecordClicked}
                  confirmationOn={rowState.removeRecordConfirmationOn}
                  handleConfirmationYes={removeRecord}
                  handleConfirmationNo={closeRemoveConfirmation}
                  isProcessing={removingRepresentative}
                  isSuccess={representativeRemoved}
                  error={removingRepresentativeError}
                  onErrorClose={closeOnRemoveError}
                />
              </AllowedTo>
            )
          }
        />
      )}
      {record.editableValidToOnly && (
        <RepresentativeBasicRow
          columnsToDisplay={columnsDisplay}
          rowStatus="PASIRASYTAS"
          id={`${record.representativeId}`}
          representativeName={record.representativeName}
          validFrom={record.validFrom}
          validToInput={
            rowState.editingOn ? (
              <DateInputImprovedCell
                state={rowState.validTo}
                setDate={setRepValidTo}
                isRequired
                helperItem={
                  <SaveAndCancelIcons
                    handleConfirmationYes={updateRepresentativeRecord}
                    isProcessing={updatingRepresentative}
                    handleCancelButtonClick={cancelEditing}
                  />
                }
              />
            ) : (
              <TableCell
                style={{
                  boxShadow: record.validTo ? '8px 0 0 #50C9F3 inset' : ''
                }}
              >
                {record.validTo && record.validTo}
                <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                  {!record.validTo && <CustomEditIconButton onClick={turnOnEditing} />}
                </AllowedTo>
              </TableCell>
            )
          }
          representationNotes={record.representationNotes}
          actions={
            !rowState.editingOn && (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                {!record.validTo && (
                  <RowActionButtonGeneric
                    id={`add-record-date-button-${record.representativeId}`}
                    label="Įvesti pabaigos datą"
                    onButtonClicked={turnOnEditing}
                  />
                )}
                {record.validTo && (
                  <>
                    <RowActionButton
                      label="Išvalyti"
                      onButtonClick={unsetRecordDate}
                      isProcessing={rowState.loading}
                    />
                  </>
                )}
              </AllowedTo>
            )
          }
        />
      )}
      {!record.editableFully && !record.editableValidToOnly && (
        <RepresentativeBasicRow
          rowStatus="ISTORINIS"
          columnsToDisplay={columnsDisplay}
          id={`${record.representativeId}`}
          representativeName={record.representativeName}
          validFrom={record.validFrom}
          validTo={record.validTo}
          representationNotes={record.representationNotes}
        />
      )}
    </>
  );
};
