import { action } from 'typesafe-actions';
import {
  FETCH_GRAPHICAL_REPRESENTATION_DATA_ERROR,
  FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
  FETCH_GRAPHICAL_REPRESENTATION_DATA_SUCCESS,
  FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_ERROR,
  FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
  FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_SUCCESS,
  SET_GRAPHICAL_REPRESENTATION_VERSION,
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_ERROR,
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST,
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_SUCCESS,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_SUCCESS,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_ERROR,
  FETCH_AVAILABLE_VIRS_ID_REQUEST,
  FETCH_AVAILABLE_VIRS_ID_SUCCESS,
  FETCH_AVAILABLE_VIRS_ID_ERROR
} from './graphicalRepresentationToolsActionTypes';
import {
  GraphicalRecord,
  GraphicalRecordCreationInput,
  GraphicalVersion
} from './graphicalRepresentationToolsTypes';

export const fetchGraphicalRepresentationDataError = (error: Error) =>
  action(FETCH_GRAPHICAL_REPRESENTATION_DATA_ERROR, error);
export const fetchGraphicalRepresentationDataRequest = (graphicalVersionId: number) =>
  action(FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST, {
    graphicalVersionId
  });
export const fetchGraphicalRepresentationDataSuccess = (records: GraphicalRecord[]) =>
  action(FETCH_GRAPHICAL_REPRESENTATION_DATA_SUCCESS, records);

export const fetchGraphicalRepresentationVersionsError = (error: Error) =>
  action(FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_ERROR, error);
export const fetchGraphicalRepresentationVersionRequest = () =>
  action(FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST);
export const fetchGraphicalRepresentationVersionsSuccess = (data: GraphicalVersion[]) =>
  action(FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_SUCCESS, data);

export const setShownGraphicalRepresentationRecord = (
  graphicalVersionId: number,
  showActive: boolean
) =>
  action(SET_GRAPHICAL_REPRESENTATION_VERSION, {
    graphicalVersionId,
    showActive
  });

export const createGraphicalRepresentationRecordError = (error: Error) =>
  action(CREATE_GRAPHICAL_REPRESENTATION_RECORD_ERROR, error);
export const createGraphicalRepresentationRecordRequest = (input: GraphicalRecordCreationInput) =>
  action(CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST, input);
export const createGraphicalRepresentationRecordSuccess = (graphicalVersionId: number) =>
  action(CREATE_GRAPHICAL_REPRESENTATION_RECORD_SUCCESS, graphicalVersionId);

export const publishGraphicalRepresentationVersionRequest = (graphicalVersionId: number) =>
  action(PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST, graphicalVersionId);
export const publishGraphicalRepresentationVersionSuccess = (graphicalVersionId: number) =>
  action(PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_SUCCESS, graphicalVersionId);
export const publishGraphicalRepresentationVersionError = (error: Error) =>
  action(PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_ERROR, error);

export const fetchAvailableVirsIdRequest = () => action(FETCH_AVAILABLE_VIRS_ID_REQUEST);
export const fetchAvailableVirsIdSuccess = (virsId: number) =>
  action(FETCH_AVAILABLE_VIRS_ID_SUCCESS, virsId);
export const fetchAvailableVirsIdError = (error: Error) =>
  action(FETCH_AVAILABLE_VIRS_ID_ERROR, error);
