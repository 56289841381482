import React from 'react';
import { Order } from '../../../components/TableTypes/TableTypes';
import ShareholderFilterTool from './FilterTool/ShareholderFilterTool';
import SortByTool from './SortByTool';

export interface TableColumn {
  id: string;
  label: string;
}

interface Props {
  allToolsDisabled?: boolean;
  filterDisabled?: boolean;
  sortOrder: Order;
  sortBy: string;
  onSortByColumnClick: (columnId: any) => void;
  tableColumns: TableColumn[];
  sortingDisabled?: boolean;
  allEventsDates?: string[];
  mainTable?: boolean;
}

const ShareholdersCustomTools: React.FC<Props> = ({
  allToolsDisabled,
  filterDisabled,
  sortOrder,
  sortBy,
  onSortByColumnClick,
  tableColumns,
  sortingDisabled,
  allEventsDates,
  mainTable
}) => {
  return (
    <>
      <ShareholderFilterTool
        disabled={allToolsDisabled || filterDisabled}
        allEventsDates={allEventsDates}
        mainTable={mainTable}
      />

      <SortByTool
        order={sortOrder}
        sortBy={sortBy}
        onSortByColumnClick={onSortByColumnClick}
        columnNames={tableColumns}
        disabled={allToolsDisabled || sortingDisabled}
      />
    </>
  );
};

export default ShareholdersCustomTools;
