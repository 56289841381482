import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DocumentActionButton } from '../../components/TableButtons/RowActionButtons/DocumentActionButton';
import { IntegratedSystemsAuditData } from '../../store/audits/auditsTypes';

interface Props {
  record: IntegratedSystemsAuditData;
  viewRequestInDialog: () => void;
  viewResponseInDialog: () => void;
}

export const IntegratedSystemsAuditsTableRow: React.FC<Props> = ({
  record,
  viewRequestInDialog,
  viewResponseInDialog
}) => {
  return (
    <TableRow>
      <TableCell>{record.systemDataIsReceivedFrom}</TableCell>
      <TableCell>{record.systemDataIsSentTo}</TableCell>
      <TableCell>{record.serviceTypeName}</TableCell>
      <TableCell>{record.service}</TableCell>
      <TableCell>{record.resultCode}</TableCell>
      <TableCell>{record.requestDate}</TableCell>
      <TableCell align="left" variant="head">
        <DocumentActionButton
          IconComponent={VisibilityIcon}
          text="Peržiūrėti"
          onClick={viewRequestInDialog}
        />
      </TableCell>
      <TableCell>{record.responseDate}</TableCell>
      <TableCell align="left" variant="head">
        <DocumentActionButton
          IconComponent={VisibilityIcon}
          text="Peržiūrėti"
          onClick={viewResponseInDialog}
        />
      </TableCell>
    </TableRow>
  );
};
