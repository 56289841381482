import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Field, useField } from 'formik';

import ClearIcon from '@material-ui/icons/Clear';
import { FormikFieldStyleProps } from './FieldStyles';

interface Props extends FormikFieldStyleProps {
  styledClasses: any;
  options: { value: any; label: string }[];
  errors: any;
  touched: any;
  formikKey: string;
  setFieldValue: (id: string, value: any) => void;
  onChange?: (value: any) => void;
  isRequired?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  defaultValue?: any;
}

interface InnerProps {
  formikKey: string;
  disabled?: boolean;
  options: { value: any; label: string }[];
  setFieldValue: (id: string, value: any) => void;
  onChange?: (value: any) => void;
}

const FormikRadioGroup: React.FC<InnerProps> = ({
  formikKey,
  options,
  disabled,
  setFieldValue,
  onChange
}) => {
  const [field] = useField(formikKey);
  return (
    <RadioGroup
      {...field}
      value={options.findIndex(({ value }) => value === field.value)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(options[parseInt(event.target.value, 10)].value);
        }

        setFieldValue(formikKey, options[parseInt(event.target.value, 10)].value);
      }}
      style={{ flexDirection: 'row' }}
    >
      {options.map(({ value, label }, index) => (
        <FormControlLabel
          key={value}
          value={index}
          control={
            <Radio color="primary" disabled={disabled} style={{ padding: 3, marginRight: 3 }} />
          }
          label={label}
        />
      ))}
    </RadioGroup>
  );
};

export const FormikFormRadioGroup: React.FC<Props> = ({
  formikKey,
  options,
  errors,
  setFieldValue,
  onChange,
  isRequired,
  disabled,
  styledClasses,
  isClearable,
  defaultValue,
  style
}) => {
  const [field] = useField(formikKey);

  return (
    <Box margin={1} className={styledClasses.inputContainer} style={style?.container}>
      <FormGroup>
        <FormControl required={isRequired} error={!!errors} component="fieldset">
          <Field
            formikKey={formikKey}
            disabled={disabled}
            component={FormikRadioGroup}
            options={options}
            setFieldValue={setFieldValue}
            onChange={onChange}
            errors={errors}
            styledClasses={styledClasses}
          />
          {errors && <FormHelperText style={style?.helper}>{errors}</FormHelperText>}
        </FormControl>
      </FormGroup>
      {field.value && isClearable && (
        <IconButton
          className={styledClasses.clearIcon}
          disabled={disabled}
          onClick={() => {
            setFieldValue(formikKey, defaultValue);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};
