import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FieldArray, Form, FormikHelpers, FormikState } from 'formik';
import {
  GraphicalRecord,
  GraphicalRepresentationToolsTypes
} from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';
import { AddNewRecordButton } from '../../../components/TableButtons/AddNewRecordButton';
import { ApplicationState } from '../../../store';
import GraphicalRepresentationSortablePanelContext from './GraphicalRepresentationSortablePanelContext';

interface Props {
  activeTab: GraphicalRepresentationToolsTypes;
  setTabRecords: React.Dispatch<React.SetStateAction<GraphicalRecord[]>>;
  formikProps: FormikHelpers<any> & FormikState<{ records: GraphicalRecord[] }>;
}

const GraphicalRepresentationTabPanelContents: React.FC<Props> = ({
  activeTab,
  setTabRecords,
  formikProps
}) => {
  const { loadingGraphicalRepresentationRecord } = useSelector(
    (state: ApplicationState) => state.graphicalRepresentation
  );

  const initialRecord: GraphicalRecord = {
    type: activeTab,
    urlLt: '',
    urlEn: '',
    tableNameLt: '',
    tableNameEn: '',
    styleFileName: '',
    isActive: true,
    rowNr: String(formikProps.values.records.length + 1),
    staticId: null
  };

  return (
    <>
      <Form
        onBlur={() => setTabRecords(formikProps.values.records)}
        onMouseOut={() => setTabRecords(formikProps.values.records)}
      >
        <FieldArray name="records">
          {({ ...fieldArrayProps }) => (
            <>
              <AddNewRecordButton
                text="Pridėti naują skirtuką"
                onClicked={() => fieldArrayProps.push(initialRecord)}
              />
              {loadingGraphicalRepresentationRecord ? (
                <Box>
                  <CircularProgress style={{ height: '40px' }} />
                </Box>
              ) : (
                <GraphicalRepresentationSortablePanelContext
                  formikProps={formikProps}
                  fieldArrayProps={fieldArrayProps}
                  setTabRecords={setTabRecords}
                />
              )}
            </>
          )}
        </FieldArray>
      </Form>
    </>
  );
};

export default GraphicalRepresentationTabPanelContents;
