import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import {
  ShareholdersProps,
  ShareholderPerson
} from '../../../../store/shareholders/shareholdersTypes';
import { groupRowColor } from '../../constants';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import GroupShareholderMemberName from '../../../../components/ShareholderComponents/GroupShareholderMemberName';
import ShareholdersTree from './ShareholdersTree';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { filterErrorObjs } from '../../utilityFunctions/sharedFunctions';
import { getIsLastShareholder } from '../../SelectedDateTable/utilities/utilityFunctions';
import { LevelBar } from '../../../../components/ShareholderComponents/TableButtons/LevelBar';
import { setExpandShareholder } from '../../../../store/shareholders/shareholdersActions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { useShareholdersTableDispatch } from '../ShareholdersTableWithContext';

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  allEventsDates: string[];
  person: ShareholderPerson;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  filteredShareholderLevel?: string;
}

export const ShareholderGroupTree: React.FC<Props> = ({
  index,
  shareholder,
  person,
  allEventsDates,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  filteredShareholderLevel
}) => {
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const { expandedPersons } = useSelector((state: ApplicationState) => state.shareholdersData);
  const reduxDispatch = useDispatch();

  const { mayHaveVotesOnRules } = shareholder;
  const { errors, personId } = person;
  const { shareholderLevel } = shareholder;
  const uniquePersonId = `${shareholderLevel}${personId}`;

  const showTriangleButton = person.shareholders.length >= 1;
  const showChildGroupShareholders = expandedPersons[uniquePersonId + '__' + index];

  const handleShowGroupShareholders = () => {
    reduxDispatch(setExpandShareholder(undefined, uniquePersonId, index));

    if (filteredShareholderLevel) {
      tableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: true });
      tableDispatch({
        type: 'CUSTOM_FILTER_VALUE_CHANGED',
        filterBy: 'shareholderLevel',
        value: null
      });
    }
  };

  const marginLeftButton = getMarginLeft(shareholderLevel);

  const criticalErrorMessages = filterErrorObjs(errors, ErrorType.CRITICAL);
  const nonCriticalErrorMessages = filterErrorObjs(errors, ErrorType.NONCRITICAL);
  const infoNotifications = filterErrorObjs(errors, ErrorType.INFO);

  return (
    <>
      <TableRow className="shareholders-table-row" style={{ backgroundColor: groupRowColor }}>
        <TableCell className="sticky-col first-col" style={{ backgroundColor: groupRowColor }}>
          <div className="fake-cell level-cell">{shareholderLevel}</div>
        </TableCell>
        <TableCell className="sticky-col second-col" style={{ backgroundColor: groupRowColor }}>
          <div className="fake-cell shareholder-cell">
            {showTriangleButton && (
              <LevelBar
                level={shareholderLevel}
                halfBottom
                showChildShareholder={showChildGroupShareholders}
                middle={false}
              />
            )}
            <GroupShareholderMemberName
              index={index}
              shareholder={shareholder}
              person={person}
              showTriangleButton={showTriangleButton}
              showChildShareholder={showChildGroupShareholders}
              handleShowGroupShareholders={handleShowGroupShareholders}
              criticalErrorMessages={criticalErrorMessages}
              nonCriticalErrorMessages={nonCriticalErrorMessages}
              infoNotifications={infoNotifications}
              marginLeft={marginLeftButton}
            />
          </div>
        </TableCell>
        {allEventsDates.map((date) => (
          <TableCell key={date + index} />
        ))}
        <TableCell className="last-column" />
      </TableRow>

      {showChildGroupShareholders &&
        person.shareholders.map((shareholderChild, index) => (
          <ShareholdersTree
            index={index}
            key={
              `${shareholder.shareholderId}` + shareholder.shareholderHistory[0]?.eventDate + index
            }
            shareholder={shareholderChild}
            allEventsDates={allEventsDates}
            parentShareTypeName={person.shareTypeName}
            parentErrors={errors}
            parentMayHaveVotesOnRules={mayHaveVotesOnRules}
            virsEndDate={virsEndDate}
            virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
            isLastShareholder={getIsLastShareholder(index, person.shareholders)}
            parentMayHaveSharePercentage={person.mayHaveSharePercentage}
            parentMayHaveVotePercentage={person.mayHaveVotePercentage}
            filteredShareholderLevel={filteredShareholderLevel}
          />
        ))}
    </>
  );
};
