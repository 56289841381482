export const INITIALIZE_TABLE_STATE = '@editionCheck/INITIALIZE_TABLE_STATE';
export const SHOW_STATUS_UNSIGNED_TOGGLED = '@editionCheck/SHOW_STATUS_UNSIGNED_TOGGLED';
export const SHOW_STATUS_RELEVANT_TOGGLED = '@editionCheck/SHOW_STATUS_RELEVANT_TOGGLED';
export const SHOW_STATUS_OUTDATED_TOGGLED = '@editionCheck/SHOW_STATUS_OUTDATED_TOGGLED';
export const CUSTOM_FILTER_VALUE_CHANGED = '@editionCheck/CUSTOM_FILTER_VALUE_CHANGED';
export const CUSTOM_FILTER_VALUE_RESET = '@editionCheck/CUSTOM_FILTER_VALUE_RESET';
export const SORTING_CHANGED = '@editionCheck/SORTING_CHANGED';
export const PAGE_SET = '@editionCheck/PAGE_SET';
export const ROWS_PER_PAGE_SET = '@editionCheck/ROWS_PER_PAGE_SET';
export const UPDATE_TEMP_DATA_STATE = '@editionCheck/UPDATE_TEMP_DATA_STATE';
