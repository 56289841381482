import React from 'react';
import {
  Typography,
  Divider,
  Grid,
  Menu,
  MenuItem,
  TableSortLabel,
  makeStyles
} from '@material-ui/core/';
import ArrowIcon from '@material-ui/icons/ExpandLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { SortOrder } from '../../../utils/tableTypes';
import { TableColumn } from './ShareholdersCustomTools';
import SortByButton from './SortByButton';

const useStyles = makeStyles({
  padding: { padding: 15 },
  marginRight: { marginRight: 15 }
});
interface Props {
  columnNames: TableColumn[];
  order: SortOrder;
  sortBy: string;
  onSortByColumnClick: (column: string) => void;
  disabled?: boolean;
}

const SortByTool = ({ columnNames, order, sortBy, onSortByColumnClick, disabled }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleSortByButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  return (
    <>
      <SortByButton onToggle={toggleSortByButton} anchorEl={anchorEl} disabled={disabled} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={toggleSortByButton}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Grid container justify="space-between" alignItems="center" className={classes.padding}>
          <Grid item>
            <Typography className={classes.marginRight}>
              <i>Rūšiuoti pagal:</i>
            </Typography>
          </Grid>
        </Grid>
        <Divider light />
        {columnNames.map((column) => {
          if (column.id !== 'representationNotes') {
            return (
              <MenuItem key={column.id}>
                <TableSortLabel
                  active={sortBy === column.id}
                  direction={sortBy === column.id ? order : 'asc'}
                  onClick={() => onSortByColumnClick(column.id)}
                  IconComponent={sortBy === column.id ? ArrowIcon : UnfoldMoreIcon}
                >
                  <Typography>{column.label}</Typography>
                </TableSortLabel>
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </>
  );
};
export default SortByTool;
