import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { Box, Paper, Table, TableBody, Typography } from '@material-ui/core';
import { ApplicationState } from '../../store';
import { EditionCheckDataTableCustomizationBar } from './TableCustomization';
import { EditionCheckDataTableHeader } from './TableHeader';
import { EditionCheckDataTableRow } from './TableRow';
import { EditionCheckDataTableDispatchContext, EditionCheckDataTableStateContext } from './Context';
import { pageTableData } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { filterAndSortTableData, getTableExportDefinition } from './tableState/tableStateFunctions';
import EditionCheckDataTableCustomFilter from './FilterRow';
import { editionCheckDataTableColumns } from './tableState/tableTypesAndActions';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';

const EditionCheckDataTable: React.FC = () => {
  const {
    virsis: { virsData },
    editionData: { editionCheckDataVirs, loadingEditionCheckDataVirs, editionChecksDataErrorVirs }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };
  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const filteredData = filterAndSortTableData(editionCheckDataVirs || [], tableState);

  const { page: pagedData, pagesCount } = pageTableData(tableState, filteredData);

  return (
    <div className="named-data-table">
      <div
        className="table-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">Tiražo patikrinimas</Typography>

        <Box>
          <PageActionButtons
            dontShowDelete
            onDownload={(format, navigationPath) =>
              exportTableData(
                format,
                getTableExportDefinition(tableState),
                filteredData,
                navigationPath
              )
            }
            onPrint={(navigationPath) =>
              printTableData(getTableExportDefinition(tableState), filteredData, navigationPath)
            }
          />
        </Box>
      </div>

      <Paper elevation={0}>
        <EditionCheckDataTableCustomizationBar columnNames={editionCheckDataTableColumns} />
        <Box style={{overflow: 'auto'}}>
          <Table aria-label="simple table">
            <EditionCheckDataTableHeader />

            {tableState.customFilterOn && (
              <EditionCheckDataTableCustomFilter columnNames={editionCheckDataTableColumns} />
            )}

            <TableBody>
              <TableCircularProgressRow isLoading={loadingEditionCheckDataVirs} colSpan={7} />

              <TableErrorRow
                error={
                  editionChecksDataErrorVirs && 'Klaida. Nepavyko gauti tiražo tikrinimo duomenų'
                }
                colSpan={7}
              />

              {!loadingEditionCheckDataVirs &&
                !editionChecksDataErrorVirs &&
                virsData &&
                pagedData.map((record) => {
                  return (
                    <EditionCheckDataTableRow
                      key={record.editionCheckId}
                      record={record}
                      virsId={virsData.virsId}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          recordsCount={filteredData.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={filteredData.length === 0}
        />
      </Paper>
    </div>
  );
};

export default EditionCheckDataTable;
