import React from 'react';
import { FormGroup, FormControlLabel, Typography } from '@material-ui/core';
import { VirsisSwitch } from '../VirsisSwitch/VirsisSwitch';

interface Props {
  notSignedFilterDisabled: boolean;
  notSignedFilterOn: boolean;
  notSignedFilterToggler: () => void;
  outdatedFilterDisabled: boolean;
  outdatedFilterOn: boolean;
  outdatedFilterToggler: () => void;
  relevantFilterDisabled: boolean;
  relevantFilterOn: boolean;
  relevantFilterToggler: () => void;
  allFiltersDisabled?: boolean;
  dontShowNotSigned?: boolean;
}

const TableStatusFilters: React.FC<Props> = ({
  notSignedFilterDisabled,
  notSignedFilterOn,
  notSignedFilterToggler,
  outdatedFilterDisabled,
  outdatedFilterOn,
  outdatedFilterToggler,
  relevantFilterDisabled,
  relevantFilterOn,
  relevantFilterToggler,
  allFiltersDisabled,
  dontShowNotSigned
}) => {
  return (
    <div className="TableStatusFilters">
      <FormGroup row>
        <Typography variant="h6" style={{ color: '#637082', fontWeight: 600, marginRight: 15 }}>
          Duomenų rodymas:
        </Typography>
        {!dontShowNotSigned && (
          <FormControlLabel
            control={
              <VirsisSwitch
                checked={notSignedFilterOn}
                onChange={notSignedFilterToggler}
                name="not-signed"
                disabled={allFiltersDisabled || notSignedFilterDisabled}
              />
            }
            label="Nepasirašyti"
          />
        )}
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={relevantFilterOn}
              onChange={relevantFilterToggler}
              name="relevant"
              disabled={allFiltersDisabled || relevantFilterDisabled}
            />
          }
          label="Aktualūs"
        />
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={outdatedFilterOn}
              onChange={outdatedFilterToggler}
              name="outdated"
              disabled={allFiltersDisabled || outdatedFilterDisabled}
            />
          }
          label="Istoriniai"
        />
      </FormGroup>
    </div>
  );
};

export default TableStatusFilters;
