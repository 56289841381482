import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { get, post } from '../../../utils/axiosApi';

import {
  FormSagaContext,
  getFormSagaContext
} from '../../../components/FormikFields/GenericFormikWrappers/types';

import {
  loadData,
  setData,
  setError,
  setLoading,
  submitForm,
  setFormLoading,
  setFormOpen,
  setFormError
} from './store';

import { ValueType } from '../../../utils/tableTypes';
import { getDateString } from '../../../components/FormikFields/FormikFormDateField';

function* loadDataTask() {
  yield put(setLoading(true));

  try {
    const { data } = yield call(get, 'classifiers/admin/transaction-types');

    yield put(setData(data));
  } catch (error) {
    yield put(setError(true));
  }

  yield put(setLoading(false));
}

function* submitFormTask(action: ActionType<typeof submitForm>) {
  const sendSnackbar: ValueType<FormSagaContext, 'formSubmitSnackbar'> = yield getFormSagaContext(
    'formSubmitSnackbar'
  );

  yield put(setFormLoading(true));

  try {
    const postBody = action.payload.data;
    yield call(post, 'classifiers/admin/transaction-types', {
      ...postBody,
      validFrom: getDateString(postBody.validFrom),
      validTo: getDateString(postBody.validTo)
    });

    yield put(setFormOpen(false));
    yield put(setFormError(false));

    yield put(loadData());
    yield call(sendSnackbar, true);
  } catch (error) {
    yield call(sendSnackbar, false);
  }

  yield put(setFormLoading(false));
}

export function* saga() {
  yield takeLatest(loadData, loadDataTask);
  yield takeLatest(submitForm, submitFormTask);
}
