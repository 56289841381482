import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { loadData, setFormOpen, State } from './store';
import { EditionPeriodForm } from './EditionPeriodForm';
import { EditionPeriod } from './types';

export const EditionPeriodTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const tableDefinition: TableDefinition<Nullable<EditionPeriod>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('periodId', 'Kodas', (data) => data.periodId),
            getStringMappedColumn(
              'periodName',
              'Tiražo laikotarpio pavadinimas LT',
              (data) => data.periodName,
              {
                cell: { width: undefined }
              },
              {
                column: { width: 30 }
              }
            ),
            getStringMappedColumn(
              'periodNameEn',
              'Tiražo laikotarpio pavadinimas En',
              (data) => data.periodNameEn,
              {
                cell: { width: undefined }
              },
              {
                column: { width: 30 }
              }
            ),
            getDateFromMappedColumn('validFrom', 'Pradžios diena', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Pabaigos diena', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              periodId: data.periodId,
              periodName: data.periodName,
              periodNameEn: data.periodNameEn,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              validTo: (data.validTo && new Date(data.validTo)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują tiražo laikotarpį'
      },
      style: { actionsColumn: { cell: { width: '80px' } } }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={tableDefinition} {...dataState} />

      <EditionPeriodForm />
    </>
  );
};
