import { action } from 'typesafe-actions';
import {
  SEARCH_LEGAL_PERSON_REQUEST,
  SEARCH_LEGAL_PERSON_SUCCESS,
  SEARCH_NATURAL_PERSON_ERROR,
  RESET_SEARCH_NATURAL_PERSON_STATE,
  SELECT_LEGAL_REPRESENTATIVE,
  RESET_LEGAL_REPRESENTATIVE,
  CREATE_LEGAL_FOREIGN_PERSON_REQUEST,
  CREATE_LEGAL_FOREIGN_PERSON_SUCCESS,
  CREATE_LEGAL_FOREIGN_PERSON_ERROR,
  RESET_CREATE_LEGAL_FOREIGN_PERSON_STATE,
  SELECT_LEGAL_SEARCH_QUERY,
  RESET_LEGAL_SEARCH_QUERY
} from './legalDataActionTypes';
import { LegalPersonSearchQuery, LegalPersonSearchData, LegalPersonRecord } from './legalDataTypes';

export const searchLegalPersonData = (LegalPersonQuery: LegalPersonSearchQuery) =>
  action(SEARCH_LEGAL_PERSON_REQUEST, LegalPersonQuery);
export const searchLegalPersonDataSuccess = (legalPersonData: LegalPersonSearchData[]) =>
  action(SEARCH_LEGAL_PERSON_SUCCESS, legalPersonData);
export const searchLegalPersonDataError = (error: Error) =>
  action(SEARCH_NATURAL_PERSON_ERROR, error);
export const resetSearchLegalPersonData = () => action(RESET_SEARCH_NATURAL_PERSON_STATE);

export const selectLegalRepresentative = (representative: LegalPersonSearchData | undefined) =>
  action(SELECT_LEGAL_REPRESENTATIVE, representative);
export const resetLegalRepresentative = () => action(RESET_LEGAL_REPRESENTATIVE);

export const createLegalForeignPerson = (legalForeignPersonRecord: LegalPersonRecord) =>
  action(CREATE_LEGAL_FOREIGN_PERSON_REQUEST, legalForeignPersonRecord);
export const createLegalForeignPersonSuccess = () => action(CREATE_LEGAL_FOREIGN_PERSON_SUCCESS);
export const createLegalForeignPersonError = (error: Error) =>
  action(CREATE_LEGAL_FOREIGN_PERSON_ERROR, error);
export const resetCreateLegalForeignPersonState = () =>
  action(RESET_CREATE_LEGAL_FOREIGN_PERSON_STATE);

export const selectLegalSearchQuery = (query: LegalPersonSearchQuery) =>
  action(SELECT_LEGAL_SEARCH_QUERY, query);
export const resetLegalSearchQuery = () => action(RESET_LEGAL_SEARCH_QUERY);
