import { WordStatus, WordStatusLabel } from '../../utils/tableTypes';

export const DOCUMENT_VALIDATE_REQUEST = '@virsis/DOCUMENT_VALIDATE_REQUEST';
export const DOCUMENT_VALIDATE_SUCCESS = '@virsis/DOCUMENT_VALIDATE_SUCCESS';
export const DOCUMENT_VALIDATE_ERROR = '@virsis/DOCUMENT_VALIDATE_ERROR';

export const DOCUMENT_APPROVE_REQUEST = '@virsis/DOCUMENT_APPROVE_REQUEST';
export const DOCUMENT_APPROVE_SUCCESS = '@virsis/DOCUMENT_APPROVE_SUCCESS';
export const DOCUMENT_APPROVE_ERROR = '@virsis/DOCUMENT_APPROVE_ERROR';

export const RESET_DOCUMENT_VALIDATION = '@virsis/RESET_DOCUMENT_VALIDATION';

export interface DocumentValidationError {
  code: string;
  group: string;
  priority: string;
  text: string;
  action: string;
  sivId: string;
  attributes: string;
}

export interface DocumentValidationResponse {
  documentErrors: DocumentValidationError[];
}

export const documentStatusObject = {
  RUOSIAMAS: 'Nepasirašyta',
  PASIRASYTAS: 'Pasirašyta',
  ANULIUOTAS: 'Anuliuota'
};

function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export const getStatus = (type: WordStatus): WordStatusLabel =>
  <WordStatusLabel>prop(documentStatusObject, type);

export enum ErrorType {
  CRITICAL,
  NONCRITICAL
}
