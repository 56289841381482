import React, { ReactNode } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useLinkButtonStyles } from '../TableButtons/BtnOpenNewDoc';

interface Prop {
  label: string;
  value: string;
  linkName?: ReactNode;
  className?: string;
  customGrid?: any;
  customGrid2?: any;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const LabelFieldLink: React.FC<Prop> = ({
  label,
  value,
  linkName,
  className,
  customGrid,
  customGrid2,
  onClick
}) => {
  const classes = useLinkButtonStyles();

  return (
    <Grid container spacing={0} className={className}>
      <Grid key={0} item xs={customGrid}>
        <Typography variant="caption" display="block" gutterBottom>
          <Box mr={2} textAlign="right">
            {label}
          </Box>
        </Typography>
      </Grid>
      <Grid style={{ display: 'flex', alignItems: 'center' }} key={1} item xs={customGrid2}>
        <Grid container spacing={0}>
          <Grid key={0} xs={12} item>
            <Box display="flex">
              <Typography style={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                {value}
                <Button
                  aria-label="Pakeisti kontaktinį VIRS elektroninio pašto adresą"
                  style={{
                    display: 'flex',
                    marginLeft: 10,
                    minWidth: 'initial'
                  }}
                  className={classes.linkButton}
                  onClick={onClick}
                >
                  {linkName}
                </Button>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LabelFieldLink;
