import React, { ReactNode } from 'react';
import { TextField, InputAdornment, TableCell, makeStyles } from '@material-ui/core';
import { InputStateGeneric } from '../../utils/tableTypes';
import theme from '../../style/virsisTheme';

interface StringInputProps {
  setValue?: (value: string) => void;
  inputState: InputStateGeneric<string>;
  isRequired?: boolean;
  isDisabled?: boolean;
  endAdornment?: ReactNode;
  multiline?: boolean;
  outlined?: boolean;
  fullWidth?: boolean;
  rows?: number;
  helperItem?: ReactNode;
}

export const StringInput: React.FC<StringInputProps> = ({
  setValue,
  inputState,
  isRequired,
  isDisabled,
  endAdornment,
  multiline,
  outlined,
  fullWidth,
  rows,
  helperItem
}) => {
  function setInputValue(event: any) {
    setValue?.(event.target.value);
  }

  const classes = makeStyles({
    input: {
      color: isDisabled ? '#686868 !important' : 'inherit'
    }
  })();

  return (
    <>
      <TextField
        disabled={isDisabled}
        placeholder={inputState.placeholder}
        onChange={setInputValue}
        error={inputState.error}
        value={inputState.value ? inputState.value : ''}
        required={isRequired}
        variant={outlined ? 'outlined' : 'standard'}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows || 1}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : undefined,
          className: classes.input
        }}
        helperText={
          <>
            {helperItem}
            <span style={{ display: 'block' }}>{inputState.helperText}</span>
          </>
        }
      />
    </>
  );
};

interface CellProps {
  rowSpan?: number;
}

export const StringInputCell: React.FC<StringInputProps & CellProps> = ({ rowSpan, ...props }) => {
  return (
    <TableCell
      style={{
        minWidth: '100px',
        backgroundColor: props.inputState.error ? theme.palette.error.light : undefined
      }}
      rowSpan={rowSpan}
    >
      <StringInput {...props} />
    </TableCell>
  );
};
