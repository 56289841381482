import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import {
  InstitutionDataReportTableAction,
  InstitutionDataReportTableState,
  initialInstitutionDataReportTableState
} from './initialTableStateAndTypes';
import { updateInstitutionDataReportCustomFilter } from './tableStateFunctions';

export const institutionDataReportTableReducer = (
  state: InstitutionDataReportTableState,
  action: InstitutionDataReportTableAction
): InstitutionDataReportTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialInstitutionDataReportTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateInstitutionDataReportCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'PERIOD_FILTER_VALUE_CHANGED':
      return {
        ...state,
        periodFilter: action.value ? action.value : state.periodFilter,
        page: 0
      };

    default:
      return state;
  }
};
