import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import virsisTheme from '../../../style/virsisTheme';
import { ShareholderDateOptionsPopover } from './ShareholderDateOptionsPopover';
import {
  showShareholderDataDeletionByDateDialog,
  showShareholderDateDeletionDialog
} from '../../../store/shareholders/shareholdersActions';
import { todaysDateString } from '../../../utils/tableDataFunctions';
import {
  getCurrentPeriod,
  getMinimumDate,
  getTooltipTextOnClearButton,
  getTooltipTextOnDeleteButton
} from '../../../containers/ShareholdersTable/utilityFunctions/sharedFunctions';
import { ApplicationState } from '../../../store';
import moment from 'moment';

interface Props {
  selectedDate: string;
  isDateFromJADIS: boolean;
  hasDateUnsignedDoc: boolean;
  noEventsOnDate: boolean;
}

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: '18px',
    padding: '0 12px 0 0',
    height: 16,
    width: 16,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:hover svg': {
      fill: virsisTheme.palette.primary.main
    }
  }
}));

const ShareholderDateOptions: React.FC<Props> = ({
  selectedDate,
  hasDateUnsignedDoc,
  isDateFromJADIS,
  noEventsOnDate
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const {
    shareholdersData: { shareholdersData, lastSignedDate }
  } = useSelector((state: ApplicationState) => state);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const enterpriseActivityStartDate: boolean = shareholdersData?.activityPeriods
    ? shareholdersData.activityPeriods.filter(
        (activePeriod) => activePeriod.startDate === selectedDate
      ).length > 0
    : false;
  const enterpriseActivityEndDate: boolean = shareholdersData?.activityPeriods
    ? shareholdersData.activityPeriods.filter(
        (activePeriod) => activePeriod.endDate === selectedDate
      ).length > 0
    : false;

  const currentActivityPeriod = getCurrentPeriod(
    selectedDate,
    shareholdersData?.activityPeriods || []
  );

  const clearDataButtonDisable =
    (currentActivityPeriod?.endDate !== selectedDate &&
      (shareholdersData?.virsStoppedParticipatingFromDate === selectedDate ||
        !hasDateUnsignedDoc ||
        noEventsOnDate)) ||
    (lastSignedDate !== undefined && moment(selectedDate).isSameOrBefore(lastSignedDate));

  const deleteButtonTooltipText = shareholdersData
    ? getTooltipTextOnDeleteButton(
        shareholdersData.virsEndDate,
        shareholdersData.virsStoppedParticipatingFromDate,
        shareholdersData.activityPeriods,
        selectedDate,
        shareholdersData.virsStartDate,
        isDateFromJADIS,
        !hasDateUnsignedDoc,
        noEventsOnDate,
        shareholdersData
      )
    : '';

  const clearDataTooltipText = shareholdersData
    ? getTooltipTextOnClearButton(!hasDateUnsignedDoc, noEventsOnDate)
    : '';

  const deleteButtonDisable =
    (shareholdersData && shareholdersData.virsStartDate === selectedDate) ||
    selectedDate === getMinimumDate(shareholdersData) ||
    enterpriseActivityStartDate ||
    enterpriseActivityEndDate ||
    isDateFromJADIS ||
    shareholdersData?.virsEndDate === selectedDate ||
    shareholdersData?.virsStoppedParticipatingFromDate === selectedDate ||
    selectedDate === todaysDateString() ||
    !hasDateUnsignedDoc;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDateDeletionDialog = () => {
    reduxDispatch(showShareholderDateDeletionDialog(selectedDate));
    handleClose();
  };

  const handleOpenDataDeletionByDateDialog = () => {
    reduxDispatch(showShareholderDataDeletionByDateDialog(selectedDate));
    handleClose();
  };

  return (
    <>
      <IconButton className={classes.button} onClick={handleClick}>
        <MoreVertIcon
          fontSize="small"
          style={
            anchorEl
              ? {
                  fill: virsisTheme.palette.primary.main
                }
              : {}
          }
        />
      </IconButton>
      <ShareholderDateOptionsPopover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        clearShareData={handleOpenDataDeletionByDateDialog}
        deleteDate={handleOpenDateDeletionDialog}
        deleteButtonDisable={deleteButtonDisable}
        deleteButtonTooltopText={deleteButtonTooltipText}
        clearDataButtonDisable={clearDataButtonDisable}
        clearDataTooltipText={clearDataTooltipText}
      />
    </>
  );
};

export default ShareholderDateOptions;
