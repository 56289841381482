import React from 'react';
import { TableCell, TableRow, TableHead, Typography } from '@material-ui/core/';
import { OutletMainDataTableState } from '../tablesState/mainOutletTableTypes';
import { OutletDataTableField } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import { TableSortingHeaderCell } from '../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../utils/tableDataFunctions';
import { useOutletInfoTablesDispatch, useOutletInfoTablesState } from '../OutletInfoTablesContext';

export const OutletMainDataTableHeaders: React.FC = () => {
  const {
    state: { main: mainTableState }
  } = useOutletInfoTablesState();

  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  function setSorting(column: OutletDataTableField): void {
    tableDispatch({ type: 'MAIN_OUTLET_SORTING_CHANGED', sortBy: column });
  }

  const handleSetSortByColumn = (column: OutletDataTableField) => {
    setSorting(column);
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletMainDataTableState,
    typeof setSorting,
    OutletDataTableField
  >(handleSetSortByColumn, mainTableState);

  return (
    <TableHead>
      <TableRow>
        {mainTableState.columnsDisplayStatus.outletTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Rūšis"
            columnName="outletTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
        {mainTableState.columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="VIP pavadinimas"
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
        {mainTableState.columnsDisplayStatus.outletGroupName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Grupė"
            columnName="outletGroupName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
        {mainTableState.columnsDisplayStatus.establishedDate && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Steigimo data"
            columnName="establishedDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
        {(mainTableState.columnsDisplayStatus.controlledFrom ||
          mainTableState.columnsDisplayStatus.controlledTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              mainTableState.columnsDisplayStatus.controlledFrom,
              mainTableState.columnsDisplayStatus.controlledTo
            ])}
          >
            <Typography variant="h4">VIP valdoma</Typography>
          </TableCell>
        )}
        {mainTableState.columnsDisplayStatus.internationalNumber && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Tarptautinis Nr."
            columnName="internationalNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
        <TableCell rowSpan={2} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {mainTableState.columnsDisplayStatus.controlledFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="controlledFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
        {mainTableState.columnsDisplayStatus.controlledTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="controlledTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={mainTableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
