import { SortOrder } from '../../../../utils/tableTypes';
import { Column, ColumnStyle, ExportedColumnStyles } from '../types';
import {
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  ValueMapper
} from '../../../../utils/tableDataFunctions';

const defaultExportedColumnStyle: ExportedColumnStyles = {
  column: { width: 15 }
};

function makeNumericSort<TData>(mapper: (data: TData) => number | null) {
  return (data: TData[], direction?: SortOrder) =>
    (direction &&
      data.sort((a, b) => {
        const valueA = mapper(a);
        const valueB = mapper(b);
        if (valueA === null || valueB === null) {
          return direction === 'asc'
            ? `${valueB}`.localeCompare(`${valueA}`)
            : `${valueA}`.localeCompare(`${valueB}`);
        }
        return direction === 'asc' ? valueB - valueA : valueA - valueB;
      })) ||
    data;
}

function makeStringSort<TData>(mapper: (data: TData) => string | null) {
  return (data: TData[], direction?: SortOrder) =>
    (direction &&
      data.sort((a, b) =>
        direction === 'asc'
          ? `${mapper(b)}`.localeCompare(`${mapper(a)}`)
          : `${mapper(a)}`.localeCompare(`${mapper(b)}`)
      )) ||
    data;
}

function makeStringArraySort<TData>(mapper: (data: TData) => string[]) {
  const stringSorter = (a: string, b: string, direction: SortOrder) =>
    direction === 'asc' ? b?.localeCompare(a) : a?.localeCompare(b);

  return (data: TData[], direction?: SortOrder) =>
    (direction &&
      data.sort((a, b) => {
        const maxA = mapper(a).sort((a1, b1) => stringSorter(a1, b1, direction))[0];
        const maxB = mapper(b).sort((a1, b1) => stringSorter(a1, b1, direction))[0];

        return stringSorter(maxA, maxB, direction);
      })) ||
    data;
}

function makeIncludesFilter<TData>(mapper: ValueMapper<TData, string[]>) {
  return (data: TData[], value?: string) =>
    data.filter((entry) => (value ? mapper(entry).includes(value) : true));
}

function makeMoreFilter<TData>(mapper: ValueMapper<TData, string[]>) {
  return (data: TData[], value?: string) =>
    getStringCompareMoreFilter(value ? [value] : [], mapper)(data);
}

function makeLessFilter<TData>(mapper: ValueMapper<TData, string[]>) {
  return (data: TData[], value?: string) =>
    getStringCompareLessFilter(value ? [value] : [], mapper)(data);
}

function mapBoolean(value: boolean | null): string {
  if (value === null) {
    return `${value}`;
  }
  return value ? 'Taip' : 'Ne';
}

function applyExportSyle(exportedStyle?: ExportedColumnStyles): ExportedColumnStyles {
  return {
    cell: {
      ...defaultExportedColumnStyle.cell,
      ...exportedStyle?.cell,
      alignment: {
        ...defaultExportedColumnStyle.cell?.alignment,
        ...exportedStyle?.cell?.alignment
      }
    },
    column: {
      ...defaultExportedColumnStyle.column,
      ...exportedStyle?.column,
      alignment: {
        ...defaultExportedColumnStyle.column?.alignment,
        ...exportedStyle?.column?.alignment
      }
    }
  };
}

export function getNumericMappedColumn<TData>(
  field: keyof TData,
  header: string,
  mapper: (data: TData) => number | null,
  style?: ColumnStyle,
  exportedStyle?: ExportedColumnStyles
): Column<TData> {
  return {
    field,
    header,
    filterFn: makeIncludesFilter((data) => [`${mapper(data)}`]),
    sortFn: makeNumericSort((data) => mapper(data)),
    valueMapper: (data) => [`${mapper(data)}`],
    style: {
      cell: { width: '80px', ...style?.cell },
      ...style
    },
    exportedStyle: applyExportSyle(exportedStyle)
  };
}

export function getStringMappedColumn<TData>(
  field: keyof TData,
  header: string,
  mapper: (data: TData) => string | null,
  style?: ColumnStyle,
  exportedStyle?: ExportedColumnStyles
): Column<TData> {
  return {
    field,
    header,
    filterFn: makeIncludesFilter((data) => [`${mapper(data)}`]),
    sortFn: makeStringSort((data) => mapper(data)),
    valueMapper: (data) => [`${mapper(data)}`],
    style: {
      cell: { width: '80px', ...style?.cell },
      ...style
    },
    exportedStyle: applyExportSyle(exportedStyle)
  };
}

export function getStringArrayMappedColumn<TData>(
  field: keyof TData,
  header: string,
  mapper: (data: TData) => string[],
  style?: ColumnStyle,
  exportedStyle?: ExportedColumnStyles
): Column<TData> {
  return {
    field,
    header,
    filterFn: makeIncludesFilter((data) => mapper(data)),
    sortFn: makeStringArraySort((data) => mapper(data)),
    valueMapper: (data) => mapper(data),
    style: {
      cell: { width: '80px', ...style?.cell },
      ...style
    },
    exportedStyle: applyExportSyle(exportedStyle)
  };
}

export function getBooleanMappedColumn<TData>(
  field: keyof TData,
  header: string,
  mapper: (data: TData) => boolean | null,
  style?: ColumnStyle,
  exportedStyle?: ExportedColumnStyles
): Column<TData> {
  return {
    field,
    header,
    filterFn: makeIncludesFilter((data) => [mapBoolean(mapper(data))]),
    sortFn: makeStringSort((data) => mapBoolean(mapper(data))),
    valueMapper: (data) => [mapBoolean(mapper(data))],
    style: {
      cell: { width: '80px', ...style?.cell },
      ...style
    },
    exportedStyle: applyExportSyle(exportedStyle)
  };
}

export function getDateFromMappedColumn<TData>(
  field: keyof TData,
  header: string,
  mapper: (data: TData) => string | null,
  style?: ColumnStyle,
  exportedStyle?: ExportedColumnStyles
): Column<TData> {
  return {
    field,
    header,
    filterFn: makeMoreFilter((data) => [`${mapper(data)}`]),
    sortFn: makeStringSort((data) => mapper(data)),
    valueMapper: (data) => [`${mapper(data) || '-'}`],
    style: {
      cell: { width: '80px', ...style?.cell },
      ...style
    },
    exportedStyle: applyExportSyle(exportedStyle),
    type: 'date'
  };
}

export function getDateToMappedColumn<TData>(
  field: keyof TData,
  header: string,
  mapper: (data: TData) => string | null,
  style?: ColumnStyle,
  exportedStyle?: ExportedColumnStyles
): Column<TData> {
  return {
    field,
    header,
    filterFn: makeLessFilter((data) => [`${mapper(data)}`]),
    sortFn: makeStringSort((data) => mapper(data)),
    valueMapper: (data) => [`${mapper(data) || '-'}`],
    style: {
      cell: { width: '80px', ...style?.cell },
      ...style
    },
    exportedStyle: applyExportSyle(exportedStyle),
    type: 'date'
  };
}
