import React from 'react';
import { Typography, TableSortLabel, TableHead, TableRow, TableCell } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import { Order, ColumnsDisplayStatus, ColumnHeaderField } from './tableState/tableTypes';

interface Props {
  order: Order;
  sortBy: ColumnHeaderField;
  onSortByColumnClick: (column: ColumnHeaderField) => void;
  columnsDisplayStatus: ColumnsDisplayStatus;
}

const DataTableHead: React.FC<Props> = ({
  sortBy,
  order,
  onSortByColumnClick,
  columnsDisplayStatus
}) => {
  const calculateSharedCellColSpan = (displayingColumns: boolean[]): number =>
    displayingColumns.filter((value) => value === true).length;

  return (
    <TableHead>
      <TableRow>
        {(columnsDisplayStatus.licenseIssueDate || columnsDisplayStatus.licenseNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedCellColSpan([
              columnsDisplayStatus.licenseIssueDate,
              columnsDisplayStatus.licenseNumber
            ])}
          >
            <Typography variant="h4">Licencijos</Typography>
          </TableCell>
        )}
        {columnsDisplayStatus.licenseTypeName && (
          <TableCell
            rowSpan={2}
            key="licenseTypeName"
            sortDirection={sortBy === 'licenseTypeName' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('licenseTypeName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'licenseTypeName' ? order : 'asc'}
              IconComponent={sortBy === 'licenseTypeName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Licencijos tipas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.enterpriseName && (
          <TableCell
            rowSpan={2}
            key="enterpriseName"
            sortDirection={sortBy === 'enterpriseName' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('enterpriseName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'enterpriseName' ? order : 'asc'}
              IconComponent={sortBy === 'enterpriseName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Licencijuojamos veiklos pavadinimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.licenseStatus && (
          <TableCell
            rowSpan={2}
            key="licenseStatus"
            sortDirection={sortBy === 'licenseStatus' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('licenseStatus')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'licenseStatus' ? order : 'asc'}
              IconComponent={sortBy === 'licenseStatus' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Licencijos būsena</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(columnsDisplayStatus.validFrom || columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedCellColSpan([
              columnsDisplayStatus.licenseIssueDate,
              columnsDisplayStatus.licenseNumber
            ])}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}
        {columnsDisplayStatus.issuingInstitutionName && (
          <TableCell
            rowSpan={2}
            key="issuingInstitutionName"
            sortDirection={sortBy === 'issuingInstitutionName' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('issuingInstitutionName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'issuingInstitutionName' ? order : 'asc'}
              IconComponent={sortBy === 'issuingInstitutionName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Licenciją išdavė</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.licenseNotes && (
          <TableCell rowSpan={2} key="licenseNotes">
            <Typography variant="h4">Pastaba / Komentaras</Typography>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {columnsDisplayStatus.licenseIssueDate && (
          <TableCell
            rowSpan={1}
            key="licenseIssueDate"
            sortDirection={sortBy === 'licenseIssueDate' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('licenseIssueDate')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'licenseIssueDate' ? order : 'asc'}
              IconComponent={sortBy === 'licenseIssueDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Išdavimo data</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.licenseNumber && (
          <TableCell
            rowSpan={1}
            key="licenseNumber"
            sortDirection={sortBy === 'licenseNumber' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('licenseNumber')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'licenseNumber' ? order : 'asc'}
              IconComponent={sortBy === 'licenseNumber' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nr.</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.validFrom && (
          <TableCell
            rowSpan={1}
            key="validFrom"
            sortDirection={sortBy === 'validFrom' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('validFrom')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'validFrom' ? order : 'asc'}
              IconComponent={sortBy === 'validFrom' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.validTo && (
          <TableCell
            rowSpan={1}
            key="validTo"
            sortDirection={sortBy === 'validTo' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('validTo')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'validTo' ? order : 'asc'}
              IconComponent={sortBy === 'validTo' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Iki</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
export default DataTableHead;
