import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  ProvidedDataHistoryHeaderField,
  ProvidedDataHistoryColumnHeader
} from './tableState/tableInitialStateAndTypes';
import { useProvidedDataHistoryTableState, useProvidedDataHistoryTableDispatch } from './Context';
import { ApplicationState } from '../../store';
import { ProvidedDataInst } from '../../store/providedData/providedDataTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { getStatus } from '../../store/document/documentTypes';

interface Props {
  columnNames: ProvidedDataHistoryColumnHeader[];
}

const ProvidedDataHistoryTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = useProvidedDataHistoryTableState();
  const { dispatch } = useProvidedDataHistoryTableDispatch();

  const handleCustomFilterChange = (
    column: ProvidedDataHistoryHeaderField,
    value: string | null
  ) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData =
    useSelector((state: ApplicationState) => state.providedData.providedDataLegal) || [];

  function getUniqueOptions(name: ProvidedDataHistoryHeaderField, data: ProvidedDataInst[]) {
    const editedData = data?.map((record) => ({
      ...record,
      documentStatus: getStatus(record.documentStatus.id)
    }));
    if (name === 'documentStatus') {
      const documentStatus = editedData.map((status) => status?.documentStatus);
      return Array.from(new Set(documentStatus));
    }
    if (name === 'outletList') {
      const outletNames: string[] = editedData.flatMap((record) =>
        record.outletList.map((outlet) => outlet.outletName)
      );
      return Array.from(new Set(outletNames));
    }
    if (editedData) {
      return Array.from(
        new Set(editedData.filter((item) => item[name] !== null).map((item) => item[name]))
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default ProvidedDataHistoryTableCustomFilter;
