import React from 'react';

import {
  PaymentDataColumnHeader,
  PaymentDataHeaderField
} from './tableState/paymentTableInitialStateAndTypes';
import { usePaymentTableState, usePaymentTableDispatch } from './Context';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../components/TableStatusFilters/TableStatusFilters';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
  showStatusOutdatedToggle,
  showStatusRelevantToggle,
  showStatusUnsignedToggled,
  sortingChanged
} from '../../store/paymentsTable/paymentsTableActions';

interface Props {
  columnNames: PaymentDataColumnHeader[];
  disabled?: boolean;
}

export const PaymentTableCustomizationBar: React.FC<Props> = ({ columnNames, disabled }) => {
  const { state } = usePaymentTableState();
  const { dispatch } = usePaymentTableDispatch();
  const { tableDataState } = useSelector((state: ApplicationState) => state.paymentTableData);
  const reduxDispatch = useDispatch();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: PaymentDataHeaderField) => {
    reduxDispatch(sortingChanged(column));
  };

  const toggleDisplayColumn = (column: PaymentDataHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    reduxDispatch(showStatusUnsignedToggled());
  };

  const toggleRelevantFilter = () => {
    reduxDispatch(showStatusRelevantToggle());
  };

  const toggleOutdatedFilter = () => {
    reduxDispatch(showStatusOutdatedToggle());
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            allFiltersDisabled={disabled}
            notSignedFilterDisabled={!tableDataState.statusNotSignedFilterEnabled}
            notSignedFilterOn={tableDataState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterDisabled={!tableDataState.statusRelevantFilterEnabled}
            relevantFilterOn={tableDataState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterDisabled={!tableDataState.statusOutdatedFilterEnabled}
            outdatedFilterOn={tableDataState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            allToolsDisabled={disabled}
            tableColumns={columnNames}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={tableDataState.order}
            sortBy={tableDataState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
