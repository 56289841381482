import React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IconButton, Box, Typography } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import { FormikFieldStyleProps } from './FieldStyles';

interface NumberInputProps {
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string; name: string } }) => void;
  decimalSeparator: boolean | string;
}

const NumberInputMoney: React.FC<NumberInputProps> = ({ inputRef, onChange, ...props }) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      isNumericString
      decimalSeparator=","
      allowedDecimalSeparators={[',', '.']}
    />
  );
};

const NumberInputInteger: React.FC<NumberInputProps> = ({ inputRef, onChange, ...props }) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      isNumericString
      decimalSeparator={false}
    />
  );
};

interface Props extends FormikFieldStyleProps {
  styledClasses: any;
  values: string | undefined | null | number;
  formikKey: string;
  isInteger: boolean;
  clearValue?: () => void;
  isRequired: boolean;
  disabled?: boolean;
  label?: string;
  error?: string;
}

export const FormikFormNumberInput: React.FC<Props> = ({
  styledClasses,
  values,
  formikKey,
  isInteger,
  clearValue,
  isRequired,
  disabled,
  label,
  error,
  style
}) => {
  return (
    <Box margin={1} className={styledClasses.inputContainer} style={style?.container}>
      {!label && (
        <Typography variant="h4" className={styledClasses.inputLabel}>
          {label}
        </Typography>
      )}

      <Field
        name={formikKey}
        component={TextField}
        variant="outlined"
        required={isRequired}
        fullWidth
        style={{ display: 'block' }}
        FormHelperTextProps={{ style: { ...style?.helper } }}
        disabled={disabled}
        error={error}
        InputProps={{
          inputComponent: isInteger ? NumberInputInteger : NumberInputMoney
        }}
      />
      {values && clearValue && (
        <IconButton onClick={clearValue}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};
