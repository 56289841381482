import { Reducer } from 'redux';
import {
  UNSIGNED_DATA_REMOVE_ERROR,
  UNSIGNED_DATA_REMOVE_REQUEST_INST,
  UNSIGNED_DATA_REMOVE_REQUEST_LEGAL,
  UNSIGNED_DATA_REMOVE_REQUEST_OUTLET,
  UNSIGNED_DATA_REMOVE_REQUEST_VIRS,
  UNSIGNED_DATA_REMOVE_RESET_STATE,
  UNSIGNED_DATA_REMOVE_SUCCESS
} from './unsignedDataTypes';

export interface UnsignedDataState {
  removing: boolean;
  removed: boolean;
  removeError?: Error;
}

const initialState: UnsignedDataState = {
  removing: false,
  removed: false
};

export const unsignedDataReducer: Reducer<UnsignedDataState> = (state = initialState, action) => {
  switch (action.type) {
    case UNSIGNED_DATA_REMOVE_REQUEST_VIRS:
    case UNSIGNED_DATA_REMOVE_REQUEST_INST:
    case UNSIGNED_DATA_REMOVE_REQUEST_OUTLET:
    case UNSIGNED_DATA_REMOVE_REQUEST_LEGAL:
      return {
        ...state,
        removing: true
      };
    case UNSIGNED_DATA_REMOVE_SUCCESS:
      return {
        ...state,
        removing: false,
        removed: true
      };
    case UNSIGNED_DATA_REMOVE_ERROR:
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action?.payload?.data
      };
    case UNSIGNED_DATA_REMOVE_RESET_STATE:
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: undefined
      };
    default: {
      return state;
    }
  }
};
