import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { ProvidedDataHistoryColumnsDisplayStatus } from '../tableState/tableInitialStateAndTypes';
import { ViewDownloadDocButtons } from '../../../components/TableButtons/RowActionButtons/ViewDownloadDocButtons';
import { ProvidedDataInst } from '../../../store/providedData/providedDataTypes';
import { OutletsAsLinksList } from '../../../components/TableLinks/OutletsAsLinksList';
import { ConfirmRemoveDialog } from '../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import PreviewAndSignDocumentDialog from '../../document/PreviewAndSignDocumentDialog';
import { ChooseActionsOnDifferentStatus } from '../../../components/TableRowActions/RowActionsOnDifferentStatus';
import downloadPdf from '../../../utils/PdfDownload';
import { providedDataRecordReducer } from './providedDataRecordReducer';
import { initialProvidedDataTableRecordState } from './providedDataRecordInitialStateAndTypes';
import { ApplicationState } from '../../../store';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import {
  fetchProvidedDataInst,
  resetAnnulProvidedDataState,
  resetProvidedDataRemoveState
} from '../../../store/providedData/providedDataActions';
import { AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { ExpandDetailsButton } from '../../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { LineWithTitle } from '../../../components/TableExtendedRow/LinesForExtendedRow';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { Roles, VirsisUser } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import AllowedToAllExcept from '../../AllowedToAllExcept';
import { documentValidationRequest } from '../../../store/document/documentActions';
import { documentStatusObject } from '../../../store/document/documentTypes';
import {
  ErrorDescriptorsContextDefault,
  errorDescriptorsInst
} from '../../document/PreviewErrorsPanel';

const useStyles = makeStyles({
  removePadding: {
    padding: '5px',
    position: 'relative',
    left: '-5px',
    display: 'inline'
  },
  positionCenterHorizontaly: {
    display: 'flex',
    alignItems: 'center'
  }
});

interface Props {
  columnsToDisplay: ProvidedDataHistoryColumnsDisplayStatus;
  record: ProvidedDataInst;
  remove: () => void;
  annul: (documentStatusId: number, annulRecord: AnnulRecord) => void;
  currentUser: VirsisUser;
}

export const ProvidedDataHistoryRowContainer: React.FC<Props> = ({
  columnsToDisplay,
  record,
  remove,
  annul,
  currentUser
}) => {
  const [showExtension, setShowExtension] = useState<boolean>(false);

  const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false);

  const toggleExtension = () => setShowExtension(!showExtension);

  const [rowState, rowDispatch] = useReducer(
    providedDataRecordReducer,
    initialProvidedDataTableRecordState
  );

  const {
    classifiers: { annulmentTypes },
    documentData: { documentValidation, loadingDocumentValidation },
    providedData: {
      removingProvidedData,
      isProvidedDataRemoved,
      removingProvidedDataError,
      annullingProvidedDataRecord,
      annullingProvidedDataRecordError
    }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  const documentId = record.documentClosureId ? record.documentClosureId : record.documentStatusId;

  function openRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  const closeOnRemoveError = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
    reduxDispatch(resetProvidedDataRemoveState());
  };

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || []
    });
  }

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulProvidedDataState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    annul(documentId, annulRecord);
    closeAnnulDocumentDialog();
  }

  const generateDocumentPath = () => {
    switch (record.dataType) {
      case 'Profesinės etikos nesilaikymas':
        return `ethical-non-compliance-data/documents/${documentId}`;
      case 'Rimti profesiniai pažeidimai':
        return `misconduct-data/documents/${documentId}`;
      case 'VIRS duomenys':
        return `virs/legals/documents/${documentId}`;
      case 'VIRS gautos lėšos':
        return `funds-received/${documentId}`;
      case 'Tiražo patikrinimas':
        return `edition-check/document/${documentId}`;
      case 'Metinė įmoka':
        return `payment-data/${documentId}`;
      default:
        return '';
    }
  };

  const documentPath = generateDocumentPath();

  const generateLinkPath = () => {
    switch (record.dataType) {
      case 'Profesinės etikos nesilaikymas':
        return `duomenu-teikimas/profesines-etikos-nesilaikymas/${documentId}`;
      case 'Rimti profesiniai pažeidimai':
        return `duomenu-teikimas/profesiniai-pazeidimai/${documentId}`;
      case 'VIRS duomenys':
        return `virs-duomenys/${record.virsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`;
      case 'VIRS gautos lėšos':
        return `duomenu-teikimas/virs-gautos-lesos/${documentId}`;
      case 'Tiražo patikrinimas':
        return `duomenu-teikimas/tirazo-patikrinimo-duomenys/${documentId}`;
      case 'Metinė įmoka':
        return `duomenu-teikimas/metines-imokos-duomenys/${documentId}`;
      default:
        return '';
    }
  };

  const linkPath = generateLinkPath();

  const isVirsData =
    record.dataType === 'VIRS duomenys' && record.documentStatus.id === 'RUOSIAMAS';

  const openDocument = () => {
    if (isVirsData) {
      reduxDispatch(documentValidationRequest(documentId));
    }
    setShowPreviewDialog(true);
  };

  const closeDocument = () => {
    reduxDispatch(fetchProvidedDataInst());
    setShowPreviewDialog(false);
  };

  const classes = useStyles();

  return (
    <>
      <ErrorDescriptorsContextDefault.Provider value={errorDescriptorsInst(record.virsId)}>
        <PreviewAndSignDocumentDialog
          openDialog={showPreviewDialog}
          onClose={closeDocument}
          onSignSuccess={closeDocument}
          documentPath={documentPath}
          documentId={record.documentStatusId}
          documentStatus={record.documentStatus.id}
          documentErrors={
            isVirsData && !loadingDocumentValidation ? documentValidation?.documentErrors : []
          }
        />
      </ErrorDescriptorsContextDefault.Provider>
      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingProvidedDataRecordError}
        isProcessing={annullingProvidedDataRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingProvidedDataRecordError}
      />
      <ConfirmRemoveDialog
        dialogTitle="Įrašo pašalinimas"
        dialogText="Ar tikrai norite pašalinti šį įrašą?"
        mainButtonText="Pašalinti įrašą"
        open={rowState.removeRecordConfirmationOn}
        onClose={closeRemoveConfirmation}
        onSubmit={remove}
        isProcessing={removingProvidedData}
        isSuccess={isProvidedDataRemoved}
        isError={!!removingProvidedDataError}
        onErrorClose={closeOnRemoveError}
        errorMessage={removingProvidedDataError?.message}
      />
      <>
        <RowStyleOnDifferentStatus status={record.documentStatus.id} dataHistoryTable>
          {columnsToDisplay.dataType && (
            <TooltipOnRowRibbon status={record.documentStatus.id}>
              <TableCell>{record.dataType}</TableCell>
            </TooltipOnRowRibbon>
          )}
          {columnsToDisplay.virsName && (
            <TableCell>
              <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
                <VirsNameLink
                  virsName={record.virsName}
                  virsLegalCode={record.virsLegalCode}
                  virsId={record.virsId}
                />
              </AllowedTo>
              <AllowedToAllExcept roles={[Roles.ROLE_VIRS_VIEW]}>
                {`${record.virsName} ${record.virsLegalCode}`}
              </AllowedToAllExcept>
            </TableCell>
          )}
          {columnsToDisplay.outletList && (
            <TableCell>
              {record.outletList && (
                <>
                  <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
                    <OutletsAsLinksList outlets={record.outletList} virsId={record.virsId} />
                  </AllowedTo>
                  <AllowedToAllExcept roles={[Roles.ROLE_VIRS_VIEW]}>
                    {record.outletList.map((outlet) => (
                      <div key={outlet.outletId}>{outlet.outletName}</div>
                    ))}
                  </AllowedToAllExcept>
                </>
              )}
            </TableCell>
          )}
          {columnsToDisplay.documentStatus && (
            <TableCell>
              <div className={classes.positionCenterHorizontaly}>
                {documentStatusObject[record.documentStatus.id]}
                {record.documentStatus.id === 'ANULIUOTAS' && (
                  <ExpandDetailsButton onClick={toggleExtension} showExtension={showExtension} />
                )}
              </div>
            </TableCell>
          )}
          {columnsToDisplay.lastEditedDate && <TableCell>{record.lastEditedDate}</TableCell>}
          {columnsToDisplay.lastEditedBy && <TableCell>{record.lastEditedBy}</TableCell>}
          <TableCell>
            <ViewDownloadDocButtons
              documentId={record.documentStatusId}
              documentStatus={record.documentStatus.id}
              openDocClicked={openDocument}
              downloadDocClicked={() => {
                downloadPdf(documentPath);
              }}
            />
          </TableCell>
          <TableCell align="right">
            <ChooseActionsOnDifferentStatus
              status={record.documentStatus.id}
              linkPath={linkPath}
              annulmentDisablementReason={record.annulmentDisablementReason}
              onClickRemove={openRemoveConfirmation}
              onClickAnnul={openAnnulDocumentDialog}
              isRemovable={record.isRemovable}
              editingWarningMessage={record.editingWarningMessage}
            />
          </TableCell>
        </RowStyleOnDifferentStatus>
        {showExtension && (
          <TableRow>
            <TableCell colSpan={8}>
              <LineWithTitle title="Anuliavimo data:" value={record.annulmentDate} />
              <LineWithTitle
                title="Anuliavimo priežastis:"
                value={record.annulmentReasonTypeName}
              />
            </TableCell>
          </TableRow>
        )}
      </>
    </>
  );
};
