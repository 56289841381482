import { getWithQuery } from '../../utils/axiosApi';
import { FindNaturalPersonInVirsisQuery } from '../../components/FindNaturalPersonInVirsis/Utilities';

export const getNaturalPersonInVirsis = (virsQuery: FindNaturalPersonInVirsisQuery) => {
  const { personFirstName, personLastName, personBirthdate } = virsQuery;

  return getWithQuery('virs/legals/natural-person', {
    personFirstName,
    personLastName,
    personBirthdate
  });
};
