import { OutletAdPrinting } from '../../../store/outletInfoData/outletInfoDataTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import {
  AdColumnHeaderField,
  AdDataTableState,
  adPrintingTablecolumns
} from '../tablesState/adPrintingTableTypes';

export function getTableExportDefinition(
  tableState: AdDataTableState
): TableExportDefinition<OutletAdPrinting> {
  const { stringValueColumn } = getColumnBuilder<OutletAdPrinting, AdColumnHeaderField>(
    adPrintingTablecolumns,
    tableState.columnsDisplayStatus
  );

  return {
    title: 'Reklamos spausdinimas',
    columnGroups: [
      {
        header: 'Spausdinama',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      }
    ]
  };
}
