import { Order } from '../../../components/TableTypes/TableTypes';
import { ColumnHeader } from '../../../utils/tableTypes';

export const initialProfessionalMisconductTableState: ProfessionalMisconductTableState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    decisionDate: true,
    documentNumber: true,
    outletName: true,
    professionalMisconduct: true,
    sanction: true,
    validFrom: true,
    validTo: true,
    misconductDeterminedBy: true
  },
  customFilterOn: false,
  customFilter: {
    decisionDate: [],
    documentNumber: [],
    outletName: [],
    professionalMisconduct: [],
    sanction: [],
    validFrom: [],
    validTo: [],
    misconductDeterminedBy: []
  }
};

export type ProfessionalMisconductTableField =
  | 'decisionDate'
  | 'documentNumber'
  | 'outletName'
  | 'professionalMisconduct'
  | 'sanction'
  | 'validFrom'
  | 'validTo'
  | 'misconductDeterminedBy';

export type ProfessionalMisconductColumnsDisplayStatus = {
  decisionDate: boolean;
  documentNumber: boolean;
  outletName: boolean;
  professionalMisconduct: boolean;
  sanction: boolean;
  validFrom: boolean;
  validTo: boolean;
  misconductDeterminedBy: boolean;
};

export type ProfessionalMisconductCustomFilter = {
  decisionDate: string[];
  documentNumber: string[];
  outletName: string[];
  professionalMisconduct: string[];
  sanction: string[];
  validFrom: string[];
  validTo: string[];
  misconductDeterminedBy: string[];
};

export type ProfessionalMisconductColumnHeader = ColumnHeader<ProfessionalMisconductTableField>;

export interface ProfessionalMisconductTableState {
  sortBy: ProfessionalMisconductTableField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: ProfessionalMisconductColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: ProfessionalMisconductCustomFilter;
}

export type ProfessionalMisconductTableAction =
  | { type: 'TABLE_INITIALIZED' }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: ProfessionalMisconductTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: ProfessionalMisconductTableField }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: ProfessionalMisconductTableField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export const professionalMisconductTableColumns: ProfessionalMisconductColumnHeader[] = [
  {
    id: 'decisionDate',
    label: 'Sprendimo data',
    type: 'date'
  },
  {
    id: 'documentNumber',
    label: 'Sprendimo nr.'
  },
  {
    id: 'outletName',
    label: 'Susijusios VIP'
  },
  {
    id: 'professionalMisconduct',
    label: 'Nustatytas pažeidimas'
  },
  {
    id: 'sanction',
    label: 'Poveikio priemonė(-s)'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  },
  {
    id: 'misconductDeterminedBy',
    label: 'Pažeidimą nustatė'
  }
];
