import React, { ReactElement } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import theme from '../../style/virsisTheme';

const useStyles = makeStyles({
  tooltip: {
    maxWidth: 160,
    width: 'auto',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    marginLeft: '-7px',
    marginBottom: '8px',
    fontSize: '12px'
  },
  arrow: {
    color: theme.palette.secondary.light,
    left: '0 !important'
  }
});

interface Props {
  children: ReactElement;
  status?: string;
}

const setTooltipOnDifferentStatus = (status?: string) => {
  switch (status) {
    case 'RUOSIAMAS':
      return 'Įrašas nėra pasirašytas';
    case 'ISTORINIS':
      return 'Įrašas yra istorinis';
    case 'ANULIUOTAS':
      return 'Įrašas yra anuliuotas';
    default:
      return '';
  }
};

export const TooltipOnRowRibbon: React.FC<Props> = ({ children, status }) => {
  const classes = useStyles();

  const tooltipText = setTooltipOnDifferentStatus(status);

  return (
    <Tooltip
      title={tooltipText}
      placement="top-start"
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      disableHoverListener={!tooltipText}
    >
      {children}
    </Tooltip>
  );
};
