import React from 'react';
import { FormikProps } from 'formik';
import { isNumber, isString } from 'lodash';
import { FormikFieldStyleProps } from '../FieldStyles';
import { FormikFormTextField } from '../FormikFormTextField';
import { useDialogFormStyles } from './DialogFormStyles';

interface Props<TData> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label?: string;
  field: keyof TData;
  disabled?: boolean;
  rowsCount?: number;
  clearable?: boolean;
  placeholder?: string;
  multiline?: boolean;
  maxLength?: number;
  propClasses?: string;
}

export const GenericTextField = <TData,>({
  field,
  label,
  formikProps,
  disabled,
  style,
  clearable,
  placeholder,
  rowsCount,
  multiline,
  maxLength,
  propClasses
}: Props<TData>) => {
  const classes = useDialogFormStyles();
  const value = formikProps.values[field];
  const error = formikProps.errors[field];

  const typedError = isString(error) ? error : undefined;
  const typedValue = isNumber(value) || isString(value) ? value : null;

  return (
    <FormikFormTextField
      withoutLabel={label === undefined}
      isMultiline={multiline}
      placeholder={placeholder}
      isClearable={clearable}
      rowsCount={rowsCount}
      styledClasses={classes}
      label={label}
      formikKey={`${field}`}
      values={typedValue}
      error={typedError}
      maxLength={maxLength}
      setFieldValue={formikProps.setFieldValue}
      style={style}
      disabled={disabled}
      className={propClasses}
    />
  );
};

GenericTextField.defaultProps = {
  disabled: false,
  clearable: true,
  placeholder: null,
  multiline: false,
  rowsCount: 1,
  maxLength: 255
};
