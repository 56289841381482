import React from 'react';
import { Container, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useHistory } from 'react-router';
import { PreviewOrSignDocument } from '../../containers/document/PreviewOrSignDocument';
import { HeaderMenuField } from '../../pages/pages';
import virsisTheme from '../../style/virsisTheme';

interface Props {
  navigation1?: HeaderMenuField;
  activeSubMenu?: HeaderMenuField;
}

const useStyles = makeStyles({
  secondaryHeaderWrapper: {
    backgroundColor: virsisTheme.palette.secondary.light,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)'
  },
  secondaryHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    maxWidth: 1590
  },
  root: {
    marginLeft: 0
  },
  indicator: {
    backgroundColor: virsisTheme.palette.primary.main,
    height: 3
  },
  tab: {
    fontFamily: 'open sans',
    color: virsisTheme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 800,
    maxWidth: '190px',
    '&:nth-child(1)': {
      marginLeft: 0
    }
  }
});

const SecondaryHeader: React.FC<Props> = ({ navigation1, activeSubMenu }) => {
  const classes = useStyles();
  const history = useHistory();

  const onTabClick = (path: any) => {
    history.push(`${navigation1?.path}${path}`);
  };

  return (
    <div className={classes.secondaryHeaderWrapper}>
      <Container maxWidth={false} className={classes.secondaryHeaderContainer}>
        {navigation1?.subMenu && navigation1.subMenu?.length > 1 && (
          <Tabs
            classes={{
              indicator: classes.indicator,
              root: classes.root
            }}
            variant="scrollable"
            value={activeSubMenu?.path ? activeSubMenu.path : false}
          >
            {navigation1?.subMenu?.map((field) => (
              <Tab
                className={classes.tab}
                label={field.label}
                value={field.path}
                key={field.id}
                onClick={() => onTabClick(field.path)}
              />
            ))}
          </Tabs>
        )}
        {(navigation1?.path === '/duomenu-perziura-ir-teikimas' ||
          navigation1?.path === '/duomenys-is-instituciju') && (
          <PreviewOrSignDocument flexWrap="nowrap" />
        )}
      </Container>
    </div>
  );
};

export default SecondaryHeader;
