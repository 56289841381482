import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { FieldAttributes, useField } from 'formik';

const useStyles = makeStyles((theme) => ({
  thinLabel: {
    fontWeight: 400
  },
  icon: ({
    disabled,
    checkboxError
  }: {
    disabled: boolean | undefined;
    checkboxError: string | undefined;
  }) => ({
    margin: '5px 5px 4px 4px',
    width: 13,
    height: 13,
    borderRadius: 2,
    border: `1px solid ${
      disabled
        ? theme.palette.info.main
        : checkboxError
        ? theme.palette.error.main
        : theme.palette.info.main
    }`
  })
}));

interface Props {
  currentDate?: string | null;
  value: string;
  disabled?: boolean;
  onControlChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxField: React.FC<FieldAttributes<object> & Props> = ({
  currentDate,
  value,
  disabled,
  onControlChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const checkboxError = meta.error;
  const classes = useStyles({ disabled, checkboxError });

  return (
    <div>
      <FormControlLabel
        {...field}
        value={value}
        disabled={disabled}
        control={
          <Checkbox
            color="primary"
            onChange={(e) => {
              if (onControlChange) {
                onControlChange(e);
              }
              field.onChange(e);
            }}
            icon={<span className={classes.icon} />}
          />
        }
        label={
          <Typography variant="h5">
            <span className={classes.thinLabel}>{value}</span>
            {currentDate && ` ${currentDate}`}
          </Typography>
        }
        labelPlacement="end"
      />
    </div>
  );
};
