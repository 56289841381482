import theme from '../../../../style/virsisTheme';

export const outletBoxColor = '#B8581B';
export const virsBoxColor = theme.palette.primary.main;

export const legalPersonColor = '#923C8C';
export const naturalPersonColor = '#2C7837';

export const groupBoxColor = '#A5A5A5';

export const personIsAuthorized = 'Bendraturčių įgaliotas asmuo';
export const personNotAuthorized = 'Bendraturtis';
