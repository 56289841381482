import React from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  makeStyles,
  createStyles,
  Divider,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { CloseDialogButton } from '../Dialogs/Dialog/CloseButton';

export interface Props {
  title: string;
  content: string;
  close: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      padding: '20px 35px 10px 35px'
    },
    divider: {
      margin: '10px 35px'
    },
    mainContainer: {
      padding: '10px 35px'
    },
    actions: {
      minWidth: 250,
      padding: '10px 34px',
      height: 50,
      backgroundColor: '#F3F3F3'
    },
    inputContainer: {
      display: 'flex',
      margin: 0,
      '& > button': {
        padding: 0,
        marginLeft: 4,
        width: 36,
        height: 36
      },
      '& > div > div > div > button': {
        padding: 0
      }
    },
    errorMessage: {
      margin: '0 35px',
      fontWeight: 'normal',
      '& span': {
        color: '#F11A1A',
        fontWeight: 600
      }
    },
    [theme.breakpoints.down('xs')]: {
      actions: {
        minWidth: 'unset'
      }
    }
  })
);

export const AuditsContentDialog: React.FC<Props> = ({ content, title, close }) => {
  const classes = useStyles();

  return (
    <Dialog open onClose={close} onExit={close} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h2">{title}</Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.mainContainer}>
        <pre>{content}</pre>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CloseDialogButton label="Uždaryti" onClick={close} />
      </DialogActions>
    </Dialog>
  );
};
