import { Box, Container, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InstitutionDataReportTableWithContext } from '../../containers/InstitutionDataReportTable/Context';
import { fetchInsitutionsDataReports } from '../../store/InstitutionDataReports/institutionDataReportsActions';

const InstitutionDataReportPage = () => {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(fetchInsitutionsDataReports());
  }, [reduxDispatch]);

  return (
    <div className="data-page">
      <Container style={{ minHeight: '100%' }} maxWidth="xl">
        <div className="data-page-header">
          <Typography variant="h1">Pranešimai</Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">Pranešimai apie netikslius duomenis</Typography>
          </Box>
        </div>
        <div className="data-page-content">
          <InstitutionDataReportTableWithContext />
        </div>
      </Container>
    </div>
  );
};

export default InstitutionDataReportPage;
