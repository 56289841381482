import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../Dialogs/Dialog/CloseButton';
import { CloseContinueButton } from '../Dialogs/Dialog/CloseContinueButton';
import { MainPage } from './MainPage';
import { selectNaturalPersonInVirsis } from '../../store/findnaturalpersoninvirsis/actions';

interface FindNaturalPersonInVirsisDialog {
  dialogOpen: boolean;
  closeDialog: () => void;
  closeAndContinueAction: () => void;
}

export const FindNaturalPersonInVirsis: React.FC<FindNaturalPersonInVirsisDialog> = ({
  dialogOpen,
  closeDialog,
  closeAndContinueAction
}) => {
  const { selectedVirsByNaturalPerson } = useSelector(
    (state: ApplicationState) => state.findNaturalPersonInVirsis
  );

  const reduxDispatch = useDispatch();

  function closeNewRepDialogAndGoBack() {
    closeDialog();
    reduxDispatch(selectNaturalPersonInVirsis(undefined));
  }

  function closeNewRepDialogAndContinue() {
    closeDialog();
    closeAndContinueAction();
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      onExit={closeDialog}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="subtitle1">Asmenų paieška</Typography>
        <CustomCloseIconButton onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <div className="dialog-main-content">
          <MainPage />
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" m={1}>
          <CloseDialogButton label="Atgal" onClick={closeNewRepDialogAndGoBack} />
        </Box>
        <Box right={30} component="span" m={1}>
          <CloseContinueButton
            onClick={closeNewRepDialogAndContinue}
            disabled={!selectedVirsByNaturalPerson}
            withIcon={true}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
