import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmRemoveDialog } from '../../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import {
  deleteShareholderRequest,
  resetShareholderDeletionDialog
} from '../../../../store/shareholders/shareholdersActions';
import { ApplicationState } from '../../../../store';
import { ShareholderTableLocation } from '../../../../store/shareholders/shareholdersTypes';
import { Strings } from '../../../../utils/strings/Strings';

interface Props {
  location: ShareholderTableLocation;
}

const DeleteShareholderDialog: React.FC<Props> = ({ location }) => {
  const reduxDispatch = useDispatch();
  const {
    shareholdersData: {
      shareholderDeletionError,
      showShareholderDeletionDialog,
      selectedDateForFunctions,
      selectedShareholderId,
      loadingShareholderDeletion
    },
    virsis: { virsData }
  } = useSelector((state: ApplicationState) => state);

  const close = () => {
    reduxDispatch(resetShareholderDeletionDialog());
  };

  const deleteShareholder = () => {
    if (virsData?.virsId && selectedDateForFunctions && selectedShareholderId) {
      reduxDispatch(
        deleteShareholderRequest(
          virsData.virsId,
          selectedShareholderId,
          selectedDateForFunctions,
          location
        )
      );
    }
  };

  return (
    <ConfirmRemoveDialog
      open={showShareholderDeletionDialog}
      dialogTitle={Strings.shareholderTooltip__removeShareholderTitle}
      dialogText={Strings.shareholderTooltip__removeShareholderApproval}
      mainButtonText={Strings.shareholderTooltip__removeShareholder}
      onClose={close}
      onSubmit={deleteShareholder}
      isProcessing={loadingShareholderDeletion}
      errorMessage={`Klaida. ${shareholderDeletionError?.message}` || ''}
      isError={!!shareholderDeletionError}
      onErrorClose={close}
    />
  );
};

export default DeleteShareholderDialog;
