import React, { ReactNode } from 'react';
import { TextField, InputAdornment, TableCell, makeStyles } from '@material-ui/core';
import { InputStateGeneric } from '../../utils/tableTypes';
import theme from '../../style/virsisTheme';

interface StringInputProps {
  setValue: (value: string) => void;
  inputState: InputStateGeneric<string>;
  isRequired?: boolean;
  isDisabled?: boolean;
  endAdornment?: ReactNode;
  multiline?: boolean;
  outlined?: boolean;
  fullWidth?: boolean;
  rows?: number;
}

export const DocumentNumberInput: React.FC<StringInputProps> = ({
  setValue,
  inputState,
  isRequired,
  isDisabled,
  endAdornment,
  multiline,
  outlined,
  fullWidth,
  rows
}) => {
  function setInputValue(event: any) {
    setValue(event.target.value);
  }

  const classes = makeStyles({
    labelRoot: {
      whiteSpace: 'nowrap',
      fontSize: '0.875rem'
    },
    labelShrink: {
      fontSize: '1rem'
    }
  })();

  return (
    <form noValidate autoComplete="off">
      <TextField
        disabled={isDisabled}
        placeholder={inputState.placeholder}
        onChange={setInputValue}
        error={inputState.error}
        label={inputState.helperText}
        value={inputState.value ? inputState.value : ''}
        required={isRequired}
        variant={outlined ? 'outlined' : 'standard'}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows || 1}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : undefined
        }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            shrink: classes.labelShrink
          }
        }}
        style={{ minWidth: '160px' }}
      />
    </form>
  );
};

interface CellProps {
  rowSpan?: number;
}

export const DocumentNumberInputCell: React.FC<StringInputProps & CellProps> = ({
  rowSpan,
  ...props
}) => {
  return (
    <TableCell
      style={{
        minWidth: '160px',
        backgroundColor: props.inputState.error ? theme.palette.error.light : undefined
      }}
      rowSpan={rowSpan}
    >
      <DocumentNumberInput {...props} />
    </TableCell>
  );
};
