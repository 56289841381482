import moment, { Moment } from 'moment';
import { StateShareholder, KeyNumberValueBoolean, ShareholderIdAndLevel } from './treeTypes';
import { stableSort } from '../../../utils/tableDataFunctions';
import {
  ApiShareholder,
  ApiShareholderPerson,
  ApiVirsTree,
  ChartShareholder,
  ChartShareholderPerson,
  ChartVirsData,
  ShareholderLink,
  ShortVirsWithRole
} from '../../../store/shareholdersVirsTree/virsTreeDataTypes';

export function removeConcatenatedLevelFromParentIdNumber(parentId: number, level: number): number {
  const parentIdString = String(parentId);
  const replaced = parentIdString.replace(String(level - 1), '');
  return Number(replaced);
}

function unmapConcatenatedLevelFromParentId(list: StateShareholder[]): StateShareholder[] {
  return list.map((sh) => {
    return {
      ...sh,
      parentId: removeConcatenatedLevelFromParentIdNumber(sh.parentId, sh.shareholderLevel)
    };
  });
}

function getShareholdersWhoHaveThisParentRecursive(
  originalList: StateShareholder[],
  parentId: number,
  shareholderLevel: number
): StateShareholder[] {
  const children: StateShareholder[] = originalList.filter(
    (r) => r.parentId === parentId && r.shareholderLevel === shareholderLevel
  );
  if (children.length !== 0) {
    const childrensChildren: StateShareholder[] = children.flatMap((ch) =>
      getShareholdersWhoHaveThisParentRecursive(
        originalList,
        ch.shareholderId,
        ch.shareholderLevel + 1
      )
    );

    return [...children, ...childrensChildren];
  }
  return [];
}

function getListWithChildrenOfWhatToRemove(
  originalList: StateShareholder[],
  shareholderToRemove: StateShareholder
): StateShareholder[] {
  const toRemove = originalList.filter(
    (r) =>
      r.shareholderId === shareholderToRemove.shareholderId &&
      r.parentId === shareholderToRemove.parentId &&
      r.shareholderLevel === shareholderToRemove.shareholderLevel
  );

  if (toRemove.length !== 0) {
    const children: StateShareholder[] = toRemove.flatMap((shToRemove) =>
      getShareholdersWhoHaveThisParentRecursive(
        originalList,
        shToRemove.shareholderId,
        shToRemove.shareholderLevel + 1
      )
    );
    return [...toRemove, ...children];
  }
  return toRemove;
}

function removeListFromOriginalList(
  original: StateShareholder[],
  listToRemove: StateShareholder[]
): StateShareholder[] {
  return original.filter((shareholder) => {
    const matchesRecord = (sh: StateShareholder): boolean =>
      sh.shareholderLevel === shareholder.shareholderLevel &&
      sh.shareholderId === shareholder.shareholderId &&
      sh.parentId === shareholder.parentId;
    return !listToRemove.some(matchesRecord);
  });
}

export function setGroupAsClosedAndRemoveShareholdersRecursively(
  displayList: StateShareholder[],
  groupToClose: StateShareholder
): StateShareholder[] {
  const allChildren = displayList.filter(
    (sh) =>
      sh.parentId === groupToClose.shareholderId &&
      sh.shareholderLevel === groupToClose.shareholderLevel + 1
  );

  const withChildrenOut = removeListFromOriginalList(displayList, allChildren);

  return withChildrenOut.map((sh) => {
    if (sh.shareholderGroupId === groupToClose.shareholderGroupId) {
      return { ...sh, showingGroupExtended: false };
    }
    return sh;
  });
}

export function removeShareholderWithChildrenRecursively(
  displayList: StateShareholder[],
  shareholderToRemove: StateShareholder
): StateShareholder[] {
  const allChildrenToRemove = getListWithChildrenOfWhatToRemove(displayList, shareholderToRemove);
  return removeListFromOriginalList(displayList, [shareholderToRemove, ...allChildrenToRemove]);
}

export function addChildrenOfShareholderToShowingList(
  all: StateShareholder[],
  shareholder: ShareholderIdAndLevel,
  showing: StateShareholder[]
): StateShareholder[] {
  const addedToShowingList = all.filter(
    (sh) =>
      sh.parentId === shareholder.shareholderId &&
      sh.shareholderLevel === shareholder.shareholderLevel + 1
  );

  const showingShareholders = removeListFromOriginalList(showing, addedToShowingList);

  return [...showingShareholders, ...addedToShowingList];
}

export function addChildrenOfShareholdersToShowingList(
  all: StateShareholder[],
  shareholders: ShareholderIdAndLevel[],
  showing: StateShareholder[]
): StateShareholder[] {
  const addedToShowingList = all.filter((sh) =>
    shareholders.some((s) => s.shareholderId === sh.shareholderId)
  );

  const showingShareholders = removeListFromOriginalList(showing, addedToShowingList);

  return [...showingShareholders, ...addedToShowingList];
}

export function mapToStateShareholder(shareholder: ChartShareholder): StateShareholder {
  return {
    shareholderId: shareholder.shareholderId,
    parentId: shareholder.parentId,
    shareholderLevel: shareholder.shareholderLevel,
    shareholderGroupId: shareholder.shareholderGroupId,
    showingGroupExtended: false
  };
}

export function mapToStateShareholders(shareholders: ChartShareholder[]): StateShareholder[] {
  return shareholders.map((sh) => mapToStateShareholder(sh));
}

function flattenPersonsShareholders(sh: ChartShareholder): ChartShareholder[] {
  const flattenedShareholders: ChartShareholder[] = sh.shareholderPersons.flatMap(
    (pers) => pers.shareholders
  );
  const innerShareholders = flattenedShareholders.flatMap((shareholder) =>
    flattenPersonsShareholders(shareholder)
  );
  return [...flattenedShareholders, ...innerShareholders];
}

export function getGroupIdsFromAllShareholders(shareholders: ChartShareholder[]): number[] {
  const allSh = shareholders.flatMap((sh) => flattenPersonsShareholders(sh));
  return [...allSh, ...shareholders]
    .filter((sh) => sh.shareholderGroupId !== null)
    .map((sh) => sh.shareholderGroupId);
}

export function mapIntoStateShareholders(shareholders: ChartShareholder[]): StateShareholder[] {
  const allSh = shareholders.flatMap((sh) => flattenPersonsShareholders(sh));
  const allPlus = mapToStateShareholders([...allSh, ...shareholders]);
  return unmapConcatenatedLevelFromParentId(allPlus);
}

export function getFilteredByLevel(
  shareholders: StateShareholder[],
  level: number
): StateShareholder[] {
  return shareholders
    .filter((sh) => sh.shareholderLevel <= level)
    .map((sh) => ({
      ...sh,
      showingGroupExtended:
        sh.shareholderGroupId && sh.shareholderLevel < level ? true : sh.showingGroupExtended
    }));
}

const setParentIdAndLevel = (
  shareholders: ApiShareholder[],
  parentShareholder: ApiShareholder,
  level: number,
  isShareholderFromGroup: boolean,
  personAsParentId: number
): ChartShareholder[] => {
  return shareholders.map((shareholder) => ({
    ...shareholder,
    parentId: parseInt(`${level - 1}${parentShareholder.shareholderId}`, 10),
    shareholderLevel: level,
    shareholderFromGroup: isShareholderFromGroup,
    personAsParentId: isShareholderFromGroup ? personAsParentId : undefined,
    parentType: parentShareholder.shareholderPersons[0].personType,
    shareholderPersons: shareholder.shareholderPersons.map((person) => ({
      ...person,
      personFromGroup: !!shareholder.shareholderGroupId,
      shareholders: setParentIdAndLevel(
        person.shareholders,
        shareholder,
        level + 1,
        !!shareholder.shareholderGroupId,
        person.personId
      )
    }))
  }));
};

const setShareholderLinks = (shareholders: ChartShareholder[]): ChartShareholder[] => {
  return shareholders.map((shareholder) => ({
    ...shareholder,
    shareholderPersons: shareholder.shareholderPersons.map((person) => ({
      ...person,
      shareholderLinks: person.shareholders.map((childShareholder) => ({
        shareholderId: childShareholder.shareholderId,
        personType: childShareholder.shareholderPersons[0].personType
      })),
      shareholders: setShareholderLinks(person.shareholders)
    }))
  }));
};

export const transformApiDataToChartData = (virsData: ApiVirsTree): ChartVirsData => {
  const withParentIdsAndLevels = {
    ...virsData,
    shareholders: virsData.shareholders.map((shareholder: ApiShareholder) => ({
      ...shareholder,
      parentId: virsData.virsId,
      shareholderLevel: 1,
      shareholderPersons: shareholder.shareholderPersons.map((person) => ({
        ...person,
        personFromGroup: !!shareholder.shareholderGroupId,
        shareholders: setParentIdAndLevel(
          person.shareholders,
          shareholder,
          2,
          !!shareholder.shareholderGroupId,
          person.personId
        )
      }))
    }))
  };
  const withShareholderLinks = {
    ...withParentIdsAndLevels,
    shareholders: withParentIdsAndLevels.shareholders.map((shareholder) => ({
      ...shareholder,
      shareholderPersons: shareholder.shareholderPersons.map((person) => ({
        ...person,
        shareholderLinks: person.shareholders.map((childShareholder) => ({
          shareholderId: childShareholder.shareholderId,
          personType: childShareholder.shareholderPersons[0].personType
        })),
        shareholders: setShareholderLinks(person.shareholders)
      }))
    }))
  };
  return withShareholderLinks;
};

export function maximumShareholderLevel(shareholders: StateShareholder[]): number {
  const searchResults: number[] = [];
  JSON.stringify(shareholders, (key, value) => {
    if (key === 'shareholderLevel' && value !== undefined && value !== null)
      searchResults.push(Number(value));
    return value;
  });
  return Math.max(...searchResults);
}

export function objectOfLevelsFullyShown(
  allShareholders: StateShareholder[],
  showingShareholders: StateShareholder[]
): KeyNumberValueBoolean {
  const maxLevel = maximumShareholderLevel(allShareholders);

  const levelArray = Array.from({ length: maxLevel }, (unknown, i) => i + 1);

  const a = levelArray.map((level) => {
    if (level === 1) {
      return [level, true];
    }
    const allFiltered = getFilteredByLevel(allShareholders, level);
    const displayingFiltered = getFilteredByLevel(showingShareholders, level);
    const showingAllShareholdersOfSameLevel = allFiltered.length === displayingFiltered.length;
    return [level, showingAllShareholdersOfSameLevel];
  });

  return Object.fromEntries(a);
}

export function getShareholderIdAndLevelFromLinks(
  shLinks: ShareholderLink[] | undefined,
  level: number
): ShareholderIdAndLevel[] {
  if (shLinks) {
    const nextLevel = level + 1;
    return shLinks.map((l) => ({
      shareholderId: l.shareholderId,
      shareholderLevel: nextLevel
    }));
  }
  return [];
}

export const isConsideredAsLegalPerson = (shareholder: ApiShareholder): boolean => {
  const isPersonLegal = (person?: ApiShareholderPerson): boolean =>
    person ? person.personType === 'LJA' || person.personType === 'UJA' : false;

  if (shareholder.shareholderGroupId) {
    const authorizedPerson: ApiShareholderPerson | undefined = shareholder.shareholderPersons.find(
      (p: ApiShareholderPerson) => p.authorized === true
    );
    return isPersonLegal(authorizedPerson);
  }

  return isPersonLegal(shareholder.shareholderPersons[0]);
};

const getShareholderName = (shareholder: ApiShareholder): string => {
  if (shareholder.shareholderGroupId) {
    const authorisedPerson: ApiShareholderPerson | undefined = shareholder.shareholderPersons.find(
      (p: ApiShareholderPerson) => p.authorized === true
    );
    return authorisedPerson?.personName || '';
  }
  return shareholder.shareholderPersons[0].personName;
};

type VirsDataComparator<T> = (first: T, second: T) => number;

const personNameByLocale =
  (): VirsDataComparator<ApiShareholderPerson> =>
  (first: ApiShareholderPerson, second: ApiShareholderPerson) =>
    first.personName.localeCompare(second.personName, 'lt');

const isLegalPerson =
  (): VirsDataComparator<ApiShareholderPerson> =>
  (first: ApiShareholderPerson, second: ApiShareholderPerson) =>
    Number(second.personType === 'LJA' || second.personType === 'UJA') -
    Number(first.personType === 'LJA' || first.personType === 'UJA');

const shareholderNameByLocale =
  (): VirsDataComparator<ApiShareholder> => (first: ApiShareholder, second: ApiShareholder) =>
    getShareholderName(first).localeCompare(getShareholderName(second), 'lt');

const isShareholderLegalPerson =
  (): VirsDataComparator<ApiShareholder> => (first: ApiShareholder, second: ApiShareholder) =>
    Number(isConsideredAsLegalPerson(second)) - Number(isConsideredAsLegalPerson(first));

const hasVotesOnRules =
  (): VirsDataComparator<ApiShareholder> => (first: ApiShareholder, second: ApiShareholder) =>
    Number(second.votesOnRules) - Number(first.votesOnRules);

const byVotePercentage =
  (): VirsDataComparator<ApiShareholder> => (first: ApiShareholder, second: ApiShareholder) =>
    second.votePercentage - first.votePercentage;

const bySharePercentage =
  (): VirsDataComparator<ApiShareholder> => (first: ApiShareholder, second: ApiShareholder) =>
    second.sharePercentage - first.sharePercentage;

/*eslint-disable */
function getComparatorByImportance<T>(
  ...comparators: VirsDataComparator<T>[]
): VirsDataComparator<T> {
  return (first: T, second: T) => {
    let order = 0;
    let i = 0;

    while (!order && comparators[i]) {
      order = comparators[i++](first, second);
    }

    return order;
  };
}

function getSortedShareholders(
  shareholders: ApiShareholder[]
): ApiShareholder[] {
  const sortedInnerShareholders = shareholders.map(sh => {
    return {
      ...sh,
      shareholderPersons: getSortedPersonsAndInnerShareholders(
        sh.shareholderPersons
      )
    };
  });
  return stableSort(sortedInnerShareholders, getComparatorByImportance(bySharePercentage(), byVotePercentage(), hasVotesOnRules(), isShareholderLegalPerson(), shareholderNameByLocale()));
}

function getSortedPersonsAndInnerShareholders(
  persons: ApiShareholderPerson[]
): ApiShareholderPerson[] {

  const innerShareholdersSorted = persons.map(p => {
    return {
      ...p,
      shareholders: getSortedShareholders(p.shareholders)
    };
  });
  return stableSort(innerShareholdersSorted, getComparatorByImportance(isLegalPerson(), personNameByLocale()));
}
/* eslint-enable */

export function getSortedAndTransformedVirsData(
  virsData: ApiVirsTree | undefined
): ChartVirsData | undefined {
  if (!virsData) {
    return undefined;
  }
  const sorted = {
    ...virsData,
    shareholders: getSortedShareholders(virsData.shareholders)
  };
  return transformApiDataToChartData(sorted);
}

export function getParentObj(
  shareholders: ApiShareholder[],
  parentId?: number,
  parent: any[] = []
) {
  shareholders.forEach((x) => {
    if (x.shareholderGroupId) {
      x.shareholderPersons.forEach((p) => {
        if (p.personId === parentId) parent.push(p);
      });
    }
    if (x.shareholderId === parentId && !x.shareholderGroupId) {
      parent.push(x);
    }

    x.shareholderPersons[0].shareholders.forEach((y) => {
      if (y.shareholderGroupId) {
        y.shareholderPersons.forEach((b) => {
          if (b.personId === parentId) parent.push(b);
        });
      }
      if (y.shareholderId === parentId && !y.shareholderGroupId) {
        parent.push(y);
      }

      y.shareholderPersons[0].shareholders.forEach((z) => {
        if (z.shareholderGroupId) {
          z.shareholderPersons.forEach((c) => {
            if (c.personId === parentId) parent.push(c);
          });
        }
        if (z.shareholderId === parentId && !z.shareholderGroupId) {
          parent.push(z);
        }

        return getParentObj(z.shareholderPersons[0].shareholders, parentId, parent);
      });
    });
  });

  return parent;
}

export const setProperParentId = (
  shareholderLevel: number,
  parentId: number,
  shareholderFromGroup?: boolean,
  personAsParentId?: number
) => {
  if (shareholderLevel > 1 && !shareholderFromGroup) {
    return removeConcatenatedLevelFromParentIdNumber(parentId, shareholderLevel);
  }
  if (shareholderFromGroup) {
    return personAsParentId;
  }
  return parentId;
};

export const findShareholderSiblings = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent: any,
  shareholderLevel: number,
  shareholderFromGroup?: boolean
): ApiShareholder[] => {
  if (shareholderFromGroup) {
    return parent.shareholders;
  }
  if (shareholderLevel === 1) {
    return parent.shareholders;
  }
  return parent.shareholderPersons[0].shareholders;
};

export const mayHaveSharePercentages = (
  shareholderLevel: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent: any,
  shareholderFromGroup?: boolean
): boolean => {
  if (shareholderLevel !== 1 && !shareholderFromGroup) {
    return parent.shareholderPersons[0].mayHaveSharePercentage;
  }
  return parent.mayHaveSharePercentage;
};

export const mayHaveVotePercentages = (
  shareholderLevel: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent: any,
  shareholderFromGroup?: boolean
): boolean => {
  if (shareholderLevel !== 1 && !shareholderFromGroup) {
    return parent.shareholderPersons[0].mayHaveVotePercentage;
  }
  return parent.mayHaveVotePercentage;
};

export const setParentNameInPersonCard = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent: any,
  shareholderLevel: number,
  shareholderFromGroup?: boolean
) => {
  if (shareholderFromGroup) {
    return parent.personName;
  }
  if (shareholderLevel === 1) {
    return parent.virsLegalName;
  }
  return parent.shareholderPersons[0].personName;
};
/* eslint-disable */
export function getFirstLevelShareholdersWithPreextended(
  all: StateShareholder[],
  shareholderId?: number
): StateShareholder[] {
  const shareholdersToShow = getFilteredByLevel(all, 1);

  if (shareholderId) {
    let parentId: number | undefined;

    const shareholderToExtend = all.find(
      sh => sh.shareholderId === shareholderId
    );

    if (shareholderToExtend && shareholderToExtend.shareholderLevel > 1) {
      shareholdersToShow.push({ ...shareholderToExtend, showingGroupExtended: shareholderToExtend.shareholderGroupId ? true : false });
      parentId = shareholderToExtend.parentId;
      do {
        const parentShareholder = all.find(
          sh => sh.shareholderId === parentId
        );
        if (parentShareholder && parentShareholder.shareholderLevel > 1) {
          shareholdersToShow.push({ ...parentShareholder, showingGroupExtended: parentShareholder.shareholderGroupId ? true : false });
          parentId = parentShareholder.parentId;
        } else {
          parentId = undefined;
        }
      } while (parentId);
    }
  }

  return shareholdersToShow;
}
/* eslint-enable */

export function setGroupAsExtended(
  shareholders: StateShareholder[],
  groupId: number
): StateShareholder[] {
  return shareholders.map((sh) => {
    if (sh.shareholderGroupId === groupId) {
      return { ...sh, showingGroupExtended: true };
    }
    return sh;
  });
}

export function getTheLastDateOrDefault(
  locationState: { fromResult: ShortVirsWithRole } | undefined
): Moment {
  return locationState && locationState.fromResult && locationState.fromResult.virsRoleValidToDate
    ? moment(locationState.fromResult.virsRoleValidToDate).startOf('day')
    : moment().startOf('day');
}

export function getTheRedirectShareholderIdOrDefault(
  locationState: { fromResult: ShortVirsWithRole } | undefined
) {
  return locationState &&
    locationState.fromResult &&
    locationState.fromResult.virsRole.startsWith('VIRS dalyvio dalyvis')
    ? locationState.fromResult.shareholderIdForRole
    : undefined;
}

export function getTheRedirectOutletIdOrDefault(
  locationState: { fromResult: ShortVirsWithRole } | undefined
) {
  return locationState &&
    locationState.fromResult &&
    locationState.fromResult.virsRole === 'atsakingas už VIP turinį'
    ? locationState.fromResult.outletIdForRole
    : undefined;
}

export function getTheRedirectToVirsInfoTabOrdefault(
  locationState:
    | {
        fromResult: ShortVirsWithRole;
        resultType: 'naturalPerson' | 'legalPerson';
      }
    | undefined
): string | undefined {
  if (
    locationState &&
    locationState.fromResult &&
    locationState.fromResult.virsRole === 'politinės reklamos gavėjas'
  ) {
    return 'politicalAdFunds';
  }
  if (
    locationState &&
    locationState.fromResult &&
    locationState.fromResult.virsRole === 'lėšų iš sandorio su VIRS teikėjas'
  ) {
    return 'receivedFunds';
  }
  if (
    locationState &&
    locationState.fromResult &&
    locationState.resultType === 'legalPerson' &&
    (locationState.fromResult.virsRole === 'paramos turtu teikėjas' ||
      locationState.fromResult.virsRole === 'paramos teikėjas')
  ) {
    return 'legalPersonSupport';
  }
  if (
    locationState &&
    locationState.fromResult &&
    locationState.resultType === 'naturalPerson' &&
    (locationState.fromResult.virsRole === 'paramos turtu teikėjas' ||
      locationState.fromResult.virsRole === 'GPM paramos teikėjas')
  ) {
    return 'naturalPersonSupport';
  }
  return undefined;
}

export function virsIdOfshareholderOrAuthorizedShareholder(
  shareholderPersons: ChartShareholderPerson[]
): number | undefined {
  if (shareholderPersons.length === 1) {
    return shareholderPersons[0].relatedVirsId || undefined;
  }
  const authorisedPerson: ChartShareholderPerson | undefined = shareholderPersons.find(
    (x: ChartShareholderPerson) => x.authorized === true
  );
  return authorisedPerson?.relatedVirsId || undefined;
}

export function truncate(str: string) {
  return str.length > 70 ? `${str.substr(0, 70)}...` : str;
}

// funkcijos naudojamos atvaizduoti pavadinimus pie chart diagramose
export const splitLabelToLines = (str: string) => {
  const calcString = truncate(str);

  const splitLength = 2;
  const firstWord = calcString.split(' ')[0];
  const percentages = parseInt(firstWord.slice(0, -1), 10);
  if (percentages >= 25) {
    const a = calcString.split(' ');
    const b = [];
    while (a.length) b.push(a.splice(0, splitLength).join(' '));
    return b;
  }
  return firstWord;
};

export const splitTooltipLabelToLines = (str: string) => {
  const a = str.split(' ');
  const b = [];
  while (a.length) b.push(a.splice(0, 4).join(' '));
  return b;
};

export function arrayfromOneToTheNumber(lastNumber: number): number[] {
  return Array.from({ length: lastNumber }, (_, i) => i + 1);
}
