import { ColumnHeader } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';

export const initialEthicalNonComplianceTableState: EthicalNonComplianceTableState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    decisionDate: true,
    documentNumber: true,
    outletList: true,
    decisionStatus: true,
    validFrom: true,
    validTo: true,
    reportingInstitution: true
  },
  customFilterOn: false,
  customFilter: {
    virsName: [],
    decisionDate: [],
    documentNumber: [],
    outletList: [],
    decisionStatus: [],
    validFrom: [],
    validTo: [],
    reportingInstitution: []
  },
  showDocumentDialog: false
};

export type EthicalNonComplianceHeaderField =
  | 'decisionDate'
  | 'documentNumber'
  | 'outletList'
  | 'decisionStatus'
  | 'validFrom'
  | 'validTo'
  | 'reportingInstitution';

export type EthicalNonComplianceColumnsDisplayStatus = {
  reportingInstitution: boolean;
  decisionDate: boolean;
  documentNumber: boolean;
  outletList: boolean;
  decisionStatus: boolean;
  validFrom: boolean;
  validTo: boolean;
};

export type EthicalNonComplianceCustomFilter = {
  virsName: string[];
  decisionDate: string[];
  outletList: string[];
  documentNumber: string[];
  decisionStatus: string[];
  validFrom: string[];
  validTo: string[];
  reportingInstitution: string[];
};

export type EthicalNonComplianceColumnHeader = ColumnHeader<EthicalNonComplianceHeaderField>;

export interface EthicalNonComplianceTableState {
  sortBy: EthicalNonComplianceHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: EthicalNonComplianceColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: EthicalNonComplianceCustomFilter;
  showDocumentDialog: boolean;
}

export type EthicalNonComplianceTableAction =
  | {
      type: 'TABLE_INITIALIZED';
    }
  | { type: 'SHOW_STATUS_UNSIGNED_TOGGLED' }
  | { type: 'SHOW_STATUS_RELEVANT_TOGGLED' }
  | { type: 'SHOW_STATUS_OUTDATED_TOGGLED' }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: EthicalNonComplianceHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: EthicalNonComplianceHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: EthicalNonComplianceHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'DOCUMENT_DIALOG_OPENED'; documentId: number }
  | { type: 'DOCUMENT_DIALOG_CLOSED' };

export const ethicalNonComplianceTableColumns: EthicalNonComplianceColumnHeader[] = [
  {
    id: 'decisionDate',
    label: 'Sprendimo data',
    type: 'date'
  },
  {
    id: 'documentNumber',
    label: 'Sprendimo Nr.'
  },
  {
    id: 'outletList',
    label: 'VIP'
  },
  {
    id: 'decisionStatus',
    label: 'Būsena'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  },
  {
    id: 'reportingInstitution',
    label: 'Pripažino'
  }
];
