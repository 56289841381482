import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import { getStringMappedColumn } from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { download, loadData, setFormOpen, State } from './store';
import { FileForm } from './FileForm';
import { FileOutput } from './types';
import { getFileName } from './utilities';

export const FileTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const tableDefinition: TableDefinition<Nullable<FileOutput>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getStringMappedColumn(
              'fileType',
              'Failo tipas',
              (data) => getFileName(data.fileType),
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            )
          ]
        }
      ],
      tableActions: {
        download: (fileType) => {
          dispatch(download(fileType.fileType));
        },
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              fileType: data.fileType,
              fileContents: data.fileContents
            })
          );
        }
      },
      style: {
        actionsColumn: {
          cell: {
            width: '210px'
          }
        }
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={tableDefinition} {...dataState} />

      <FileForm />
    </>
  );
};
