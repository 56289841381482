import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { saga } from './saga';

import { getOptionedTableReducer } from '../GenericClassifierTable/genericReducers/optionedTableReducer';
import { LegalFormInput, LegalFormOptions, LegalForm } from './types';
import { FormSagaContext } from '../../../components/FormikFields/GenericFormikWrappers/types';

export const {
  loadData,
  resetForm,
  setData,
  submitForm,
  setLoading,
  setFormOpen,
  setFormLoading,
  setFormError,
  setError,
  setFormOptions,
  initialState,
  reducer
} = getOptionedTableReducer<LegalForm, LegalFormInput, LegalFormOptions>({
  legalForms: [],
  shareTypes: []
});

export function getLegalFormStore(context: FormSagaContext) {
  const middleware = createSagaMiddleware({
    context
  });
  const store = createStore(reducer, applyMiddleware(middleware));
  middleware.run(saga);
  return store;
}

export type State = typeof initialState;
