import {
  FundsReceivedDataPageRequest,
  FundsReceivedTableDataState
} from './fundsReceivedDataTableTypes';
import { ApplicationState } from '../index';
import { FundsReceivedDataTableState } from './fundsReceivedTableReducer';
import { FundsReceivedLegalTableField } from '../../containers/FundsReceivedTableInstLegal/tableState/tableTypesAndActions';
import { SortOrder } from '../../utils/tableTypes';

const getFilterString = (
  state: FundsReceivedTableDataState,
  value: FundsReceivedLegalTableField
): string | null => state.customFilter[value][0]?.trim() || null;

const getFilterNumber = (
  state: FundsReceivedTableDataState,
  value: FundsReceivedLegalTableField
): number | null => Number(state.customFilter[value][0]) || null;

const getSorting = (
  state: FundsReceivedTableDataState,
  value: FundsReceivedLegalTableField
): SortOrder | null => (state.sortBy === value ? state.order : null);

export const getFundsReceivedPageRequest = (
  state: FundsReceivedTableDataState
): FundsReceivedDataPageRequest => {
  return {
    filterFundsReceivedSum: getFilterString(state, 'fundsReceivedSum'),
    filterFundsReceivedYear: getFilterString(state, 'fundsReceivedYear'),
    filterFundsSourceCode: getFilterString(state, 'fundsSourceCode'),
    filterFundsSourceName: getFilterString(state, 'fundsSourceName'),
    filterOutletId: getFilterNumber(state, 'fundsReceivedOutlets'),
    filterTransactionTypeId: getFilterNumber(state, 'transaction'),
    filterVirsId: getFilterNumber(state, 'virsName'),
    pageNr: state.rowsPerPage * state.page + 1,
    pageSize: state.rowsPerPage,
    sortingFundsReceivedSum: getSorting(state, 'fundsReceivedSum'),
    sortingFundsReceivedYear: getSorting(state, 'fundsReceivedYear'),
    sortingFundsSourceCode: getSorting(state, 'fundsSourceCode'),
    sortingFundsSourceName: getSorting(state, 'fundsSourceName'),
    sortingOutletName: getSorting(state, 'fundsReceivedOutlets'),
    sortingTransactionTypeName: getSorting(state, 'transaction'),
    sortingVirsName: getSorting(state, 'virsName'),
    docStatusNotSigned: state.showStatusNotSigned,
    docStatusRelevant: state.showStatusRelevant,
    docStatusOutdated: state.showStatusOutdated
  };
};

export const selectFundsReceivedTableDataState = (
  state: ApplicationState
): FundsReceivedDataTableState => state.fundsReceivedTableData;
