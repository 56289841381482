import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchAnnulmentTypesError,
  fetchAnnulmentTypesSuccess,
  fetchDataDeletionPeriodError,
  fetchDataDeletionPeriodSuccess,
  fetchEnterpriseTypesError,
  fetchEnterpriseTypesSuccess,
  fetchFictitiousOutletError,
  fetchFictitiousOutletSuccess,
  fetchLegalFormTypesError,
  fetchLegalFormTypesSuccess,
  fetchShareTypesError,
  fetchShareTypesSuccess
} from './classifiersActions';
import {
  FETCH_ANNULMENT_TYPES_REQUEST,
  FETCH_DATA_DELETION_PERIOD_REQUEST,
  FETCH_ENTERPRISE_TYPES_REQUEST,
  FETCH_FICTITIOUS_OUTLET_REQUEST,
  FETCH_LEGAL_FORM_TYPES_REQUEST,
  FETCH_SHARE_TYPES_REQUEST
} from './classifiersActionTypes';
import {
  getAnnulmentTypes,
  getDataDeletionPeriod,
  getEnterpriseTypes,
  getFicticiousOutletData,
  getLegalFormTypes,
  getShareTypes
} from './classifiersApi';

function* handleLegalFormTypesFetchSaga() {
  try {
    const { data } = yield call(getLegalFormTypes);
    yield put(fetchLegalFormTypesSuccess(data));
  } catch (err) {
    yield put(fetchLegalFormTypesError(err));
  }
}

function* handleShareTypesFetchSaga() {
  try {
    const { data } = yield call(getShareTypes);
    yield put(fetchShareTypesSuccess(data));
  } catch (err) {
    yield put(fetchShareTypesError(err));
  }
}

function* fetchAnnulmentTypes() {
  try {
    const { data } = yield call(getAnnulmentTypes);
    yield put(fetchAnnulmentTypesSuccess(data));
  } catch (err) {
    yield put(fetchAnnulmentTypesError(err));
  }
}

function* handleEnterpriseTypesFetchSaga() {
  try {
    const { data } = yield call(getEnterpriseTypes);
    yield put(fetchEnterpriseTypesSuccess(data));
  } catch (err) {
    yield put(fetchEnterpriseTypesError(err.response));
  }
}

function* handleDataDeletionPeriodFetchSaga() {
  try {
    const { data } = yield call(getDataDeletionPeriod);
    if (Number.isInteger(data)) {
      yield put(fetchDataDeletionPeriodSuccess(data));
    }
  } catch (err) {
    yield put(fetchDataDeletionPeriodError(err.response));
  }
}

function* fetchFictitiousOutletDataSaga() {
  try {
    const { data } = yield call(getFicticiousOutletData);
    yield put(fetchFictitiousOutletSuccess(data));
  } catch (err) {
    yield put(fetchFictitiousOutletError(err));
  }
}

function* classifiersSaga() {
  yield takeEvery(FETCH_LEGAL_FORM_TYPES_REQUEST, handleLegalFormTypesFetchSaga);
  yield takeLatest(FETCH_SHARE_TYPES_REQUEST, handleShareTypesFetchSaga);
  yield takeEvery(FETCH_ANNULMENT_TYPES_REQUEST, fetchAnnulmentTypes);
  yield takeEvery(FETCH_ENTERPRISE_TYPES_REQUEST, handleEnterpriseTypesFetchSaga);
  yield takeEvery(FETCH_DATA_DELETION_PERIOD_REQUEST, handleDataDeletionPeriodFetchSaga);
  yield takeEvery(FETCH_FICTITIOUS_OUTLET_REQUEST, fetchFictitiousOutletDataSaga);
}

export default classifiersSaga;
