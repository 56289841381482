import {
  MessageTableAction,
  MessageTableState,
  initialMessageTableState
} from './tableInitialStateAndTypes';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import { updateMessagesCustomFilter } from './tableStateFunctions';

export const messagesTableReducer = (
  state: MessageTableState,
  action: MessageTableAction
): MessageTableState => {
  switch (action.type) {
    case 'RESET_AFTER_ACTION_WITH_MESSAGES_SUCCESSFUL':
      return {
        ...state,
        selectedMessages: [],
        selectAllChecked: false,
        showRemoveConfirmation: false,
        messageToView: undefined
      };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialMessageTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateMessagesCustomFilter(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'MESSAGE_SELECTED':
      return {
        ...state,
        selectedMessages: [...state.selectedMessages, action.message]
      };
    case 'MESSAGE_UNSELECTED':
      return {
        ...state,
        selectedMessages: state.selectedMessages.filter(
          (msg) => msg.messageId !== action.message.messageId
        )
      };
    case 'ALL_MESSAGES_SELECTED':
      return {
        ...state,
        selectedMessages: action.messages,
        selectAllChecked: true
      };
    case 'ALL_MESSAGES_UNSELECTED':
      return {
        ...state,
        selectedMessages: [],
        selectAllChecked: false
      };
    case 'MESSAGE_DIALOG_OPENED':
      return {
        ...state,
        messageToView: action.message
      };
    case 'MESSAGE_DIALOG_CLOSED':
      return {
        ...state,
        messageToView: undefined
      };
    case 'REMOVE_SELECTED_CLICKED':
      return {
        ...state,
        showRemoveConfirmation: !state.showRemoveConfirmation
      };
    default:
      return state;
  }
};
