import React from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';

import { Classifiers, ClassifierLabel } from './types';

const menuLabels: ClassifierLabel[] = [
  { value: Classifiers.LEGAL_FORMS, label: 'Teisinės formos' },
  { value: Classifiers.OUTLET_GROUPS, label: 'VIP grupės' },
  { value: Classifiers.ENTERPRISE_TYPES, label: 'VIRS rūšis' },
  { value: Classifiers.LICENCED_ENTERPRISES, label: 'Licencijuojama veikla' },
  { value: Classifiers.OUTLET_TYPES, label: 'VIP rūšis' },
  { value: Classifiers.SANCTION_TYPES, label: 'Poveikio priemonės' },
  { value: Classifiers.OUTLET_CATEGORIES, label: 'VIP kategorijos' },
  { value: Classifiers.ANNULMENT_TYPES, label: 'Anuliavimo priežastys' },
  { value: Classifiers.EDITION_CHECK_CONCLUSIONS, label: 'Tiražo atitiktis' },
  {
    value: Classifiers.TRANSACTION_TYPES,
    label: 'Sandorių ir administracinių aktų rūšys'
  },
  { value: Classifiers.PAYMENT_AMOUNTS, label: 'BSI dydžiai' },
  {
    value: Classifiers.ETHICAL_NON_COMPLIANCE_TYPES,
    label: 'Profesinės etikos nesilaikymo pripažinimas'
  },
  { value: Classifiers.MESSAGE_TYPES, label: 'Siunčiami pranešimai' },
  { value: Classifiers.EDITION_PERIODS, label: 'Tiražo laikotarpiai' },
  { value: Classifiers.FILES, label: 'Naudotojų vadovai ir naudojimo salygos' },
  { value: Classifiers.SYSTEM_PARAMETER, label: 'Sistemos parametrai' },
  { value: Classifiers.MISCONDUCT_TYPES, label: 'Pažeidimai' }
];

interface Props {
  activeClassifier: Classifiers;
  onChange: (value: Classifiers) => void;
}

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('xs')]: {
    box: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    button: {
      display: 'block',
      width: '100%',
      textAlign: 'left'
    }
  }
}));

export function getClassifierTitle(classifier: Classifiers) {
  return menuLabels.find(({ value }) => classifier === value)?.label;
}

export const ClassifierMenu: React.FC<Props> = ({ activeClassifier, onChange }) => {
  const classes = useStyles();
  return (
    <Box
      style={{
        borderTop: 'solid 2px #dddddd',
        borderBottom: 'solid 2px #dddddd'
      }}
      className={classes.box}
    >
      {menuLabels.map(({ label, value }) => (
        <Button
          key={value}
          onClick={() => onChange(value)}
          color={(activeClassifier === value && 'primary') || undefined}
          style={{ fontWeight: 'bold' }}
          className={classes.button}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};
