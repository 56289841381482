import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import { ethicalNonComplianceTableReducer } from './tableState/tableReducer';
import {
  EthicalNonComplianceTableState,
  EthicalNonComplianceTableAction,
  initialEthicalNonComplianceTableState
} from './tableState/tableInitialStateAndTypes';
import EthicalNonComplianceTable from './Table';

export const EthicalNonComplianceTableStateContext = createContext<{
  state: EthicalNonComplianceTableState;
}>({
  state: initialEthicalNonComplianceTableState
});

export const EthicalNonComplianceTableDispatchContext = createContext<{
  dispatch: Dispatch<EthicalNonComplianceTableAction>;
}>({
  dispatch: () => {}
});

export const EthicalNonComplianceTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(
    ethicalNonComplianceTableReducer,
    initialEthicalNonComplianceTableState
  );
  return (
    <EthicalNonComplianceTableStateContext.Provider value={{ state }}>
      <EthicalNonComplianceTableDispatchContext.Provider value={{ dispatch }}>
        <EthicalNonComplianceTable />
      </EthicalNonComplianceTableDispatchContext.Provider>
    </EthicalNonComplianceTableStateContext.Provider>
  );
};

export function useEthicalNonComplianceTableState() {
  return useContext(EthicalNonComplianceTableStateContext);
}
export function useEthicalNonComplianceTableDispatch() {
  return useContext(EthicalNonComplianceTableDispatchContext);
}
