import { action } from 'typesafe-actions';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import {
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST,
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_SUCCESS,
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_ERROR,
  PROFESSIONAL_MISCONDUCT_VIRS_DOCUMENT_PDF_REQUEST,
  PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST,
  PROFESSIONAL_MISCONDUCT_INST_DATA_SUCCESS,
  PROFESSIONAL_MISCONDUCT_INST_DATA_ERROR,
  CREATE_PROFESSIONAL_MISCONDUCT_REQUEST,
  CREATE_PROFESSIONAL_MISCONDUCT_SUCCESS,
  CREATE_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_CREATE_PROFESSIONAL_MISCONDUCT_STATE,
  UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST,
  UPDATE_PROFESSIONAL_MISCONDUCT_SUCCESS,
  UPDATE_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_UPDATE_PROFESSIONAL_MISCONDUCT_STATE,
  REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST,
  REMOVE_PROFESSIONAL_MISCONDUCT_SUCCESS,
  REMOVE_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_REMOVE_PROFESSIONAL_MISCONDUCT_STATE,
  ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST,
  ANNUL_PROFESSIONAL_MISCONDUCT_SUCCESS,
  ANNUL_PROFESSIONAL_MISCONDUCT_ERROR,
  RESET_ANNUL_PROFESSIONAL_MISCONDUCT_STATE,
  PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST
} from './professionalMisconductsActionTypes';
import {
  ProfessionalMisconductDataInst,
  ProfessionalMisconductRecord,
  ProfessionalMisconductDataVirs
} from './professionalMisconductsTypes';

export const getProfessionalMisconductVirsDataRequest = (virsId: number) =>
  action(PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST, virsId);
export const getProfessionalMisconductVirsDataSuccess = (
  professionalMisconductVirsData: ProfessionalMisconductDataVirs
) => action(PROFESSIONAL_MISCONDUCT_VIRS_DATA_SUCCESS, professionalMisconductVirsData);
export const getProfessionalMisconductVirsDataError = (error: Error) =>
  action(PROFESSIONAL_MISCONDUCT_VIRS_DATA_ERROR, error);
export const getProfessionalMisconductVirsDocumentRequest = (documentId: number) =>
  action(PROFESSIONAL_MISCONDUCT_VIRS_DOCUMENT_PDF_REQUEST, documentId);

export const getProfessionalMisconductInstDataRequest = () =>
  action(PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST);
export const getProfessionalMisconductInstDataSuccess = (
  professionalMisconductData: ProfessionalMisconductDataInst
) => action(PROFESSIONAL_MISCONDUCT_INST_DATA_SUCCESS, professionalMisconductData);
export const getProfessionalMisconductInstDataError = (error: Error) =>
  action(PROFESSIONAL_MISCONDUCT_INST_DATA_ERROR, error);

export const createProfessionalMisconduct = (record: ProfessionalMisconductRecord) =>
  action(CREATE_PROFESSIONAL_MISCONDUCT_REQUEST, { record });
export const createProfessionalMisconductSuccess = () =>
  action(CREATE_PROFESSIONAL_MISCONDUCT_SUCCESS);
export const createProfessionalMisconductError = (error: Error) =>
  action(CREATE_PROFESSIONAL_MISCONDUCT_ERROR, error);
export const resetProfessionalMisconductCreatingState = () =>
  action(RESET_CREATE_PROFESSIONAL_MISCONDUCT_STATE);

export const updateProfessionalMisconduct = (
  recordUpdated: ProfessionalMisconductRecord,
  professionalMisconductId: number
) =>
  action(UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST, {
    recordUpdated,
    professionalMisconductId
  });
export const updateProfessionalMisconductSuccess = () =>
  action(UPDATE_PROFESSIONAL_MISCONDUCT_SUCCESS);
export const updateProfessionalMisconductError = (error: Error) =>
  action(UPDATE_PROFESSIONAL_MISCONDUCT_ERROR, error);
export const resetProfessionalMisconductUpdatingState = () =>
  action(RESET_UPDATE_PROFESSIONAL_MISCONDUCT_STATE);

export const removeProfessionalMisconduct = (professionalMisconductId: number) =>
  action(REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST, { professionalMisconductId });
export const removeProfessionalMisconductSuccess = () =>
  action(REMOVE_PROFESSIONAL_MISCONDUCT_SUCCESS);
export const removeProfessionalMisconductError = (error: Error) =>
  action(REMOVE_PROFESSIONAL_MISCONDUCT_ERROR, error);
export const resetProfessionalMisconductRemovingState = () =>
  action(RESET_REMOVE_PROFESSIONAL_MISCONDUCT_STATE);

export const annulProfessionalMisconduct = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST, {
    documentStatusId,
    annulRecord
  });
export const annulProfessionalMisconductSuccess = () =>
  action(ANNUL_PROFESSIONAL_MISCONDUCT_SUCCESS);
export const annulProfessionalMisconductError = (error: Error) =>
  action(ANNUL_PROFESSIONAL_MISCONDUCT_ERROR, error);
export const resetAnnulProfessionalMisconductState = () =>
  action(RESET_ANNUL_PROFESSIONAL_MISCONDUCT_STATE);

export const getProfessionalMisconductByDocId = (docId: number) =>
  action(PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST, docId);
