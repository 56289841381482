export const FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST =
  '@graphicalRepresentationTools/FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST';
export const FETCH_GRAPHICAL_REPRESENTATION_DATA_SUCCESS =
  '@graphicalRepresentationTools/FETCH_GRAPHICAL_REPRESENTATION_DATA_SUCCESS';
export const FETCH_GRAPHICAL_REPRESENTATION_DATA_ERROR =
  '@graphicalRepresentationTools/FETCH_GRAPHICAL_REPRESENTATION_DATA_ERROR';

export const FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST =
  '@graphicalRepresentationTools/FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_REQUEST';
export const FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_SUCCESS =
  '@graphicalRepresentationTools/FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_SUCCESS';
export const FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_ERROR =
  '@graphicalRepresentationTools/FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_ERROR';

export const SET_GRAPHICAL_REPRESENTATION_VERSION =
  '@graphicalRepresentationTools/SET_GRAPHICAL_REPRESENTATION_VERSIONS';

export const PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST =
  '@graphicalRepresentationTools/PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST';
export const PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_SUCCESS =
  '@graphicalRepresentationTools/PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_SUCCESS';
export const PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_ERROR =
  '@graphicalRepresentationTools/PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_ERROR';

export const CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST =
  '@graphicalRepresentationTools/CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST';
export const CREATE_GRAPHICAL_REPRESENTATION_RECORD_SUCCESS =
  '@graphicalRepresentationTools/CREATE_GRAPHICAL_REPRESENTATION_RECORD_SUCCESS';
export const CREATE_GRAPHICAL_REPRESENTATION_RECORD_ERROR =
  '@graphicalRepresentationTools/CREATE_GRAPHICAL_REPRESENTATION_RECORD_ERROR';

export const FETCH_AVAILABLE_VIRS_ID_REQUEST =
  '@graphicalRepresentationTools/FETCH_AVAILABLE_VIRS_ID_REQUEST';
export const FETCH_AVAILABLE_VIRS_ID_SUCCESS =
  '@graphicalRepresentationTools/FETCH_AVAILABLE_VIRS_ID_SUCCESS';
export const FETCH_AVAILABLE_VIRS_ID_ERROR =
  '@graphicalRepresentationTools/FETCH_AVAILABLE_VIRS_ID_ERROR';
