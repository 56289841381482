import { DocumentValidationError } from '../../store/document/documentTypes';

export type LinkedError = DocumentValidationError & {
  label?: string;
  url?: string;
};

export type ErrorDescriptor = {
  label: string;
  key: string;
  getLinkByAttributeValue: (attributeValue: string, error?: DocumentValidationError) => string;
};

type ErrorAttribute = {
  key: string;
  value: string;
};

export type GroupedError = {
  text: string;
  count: number;
  priority: string;
  url: string;
  label: string;
  sivId?: string;
};

export function getLinkedError(
  error: DocumentValidationError,
  errorDescriptors: ErrorDescriptor[]
): LinkedError {
  const attributes: ErrorAttribute[] = error.attributes
    .split(',')
    .map((attributeString) => attributeString.split('='))
    .map(([key, value]) => ({ key, value }));

  const descriptor = errorDescriptors.find(({ key }) =>
    attributes.map((attribute) => attribute.key).includes(key)
  );

  if (!descriptor) {
    return error;
  }

  const { label, getLinkByAttributeValue } = descriptor;

  const attribute = attributes.find(({ key }) => descriptor.key === key);

  if (!attribute) {
    return { ...error, label };
  }

  const url = getLinkByAttributeValue(attribute.value, error);

  return {
    ...error,
    label,
    url
  };
}

function formGroupErrorObject(
  textGroupArray: string[],
  errorDescriptors: ErrorDescriptor[],
  priority: string
) {
  const [shareholderErrorDescriptor] = errorDescriptors.filter(
    (error) => error.key === 'dal_id' || error.key === 'das_id_tevo'
  );
  return Array.from(new Set(textGroupArray)).map((errorText) => ({
    text: errorText,
    count: textGroupArray.filter((error: string) => error === errorText).length,
    priority,
    label: 'Dalyviai',
    url: shareholderErrorDescriptor.getLinkByAttributeValue(shareholderErrorDescriptor.key)
  }));
}

export function mapGroupedErrors(
  errors: DocumentValidationError[],
  warnings: DocumentValidationError[],
  errorDescriptors: ErrorDescriptor[]
) {
  const mainErrors: DocumentValidationError[] = [];
  const mainWarnings: DocumentValidationError[] = [];
  const shareholderErrors: DocumentValidationError[] = [];
  const shareholderWarnings: DocumentValidationError[] = [];

  errors.forEach((error) => {
    if (error.attributes.includes('dal') || error.attributes.includes('das')) {
      shareholderErrors.push(error);
    } else {
      mainErrors.push(error);
    }
  });

  warnings.forEach((warning) => {
    if (warning.attributes.includes('dal') || warning.attributes.includes('das')) {
      shareholderWarnings.push(warning);
    } else {
      mainWarnings.push(warning);
    }
  });

  const getErrorText = (documentValidationErrors: DocumentValidationError[]): string[] => {
    return documentValidationErrors.map(({ text }) => text);
  };

  const shareholderGroupErrors = formGroupErrorObject(
    getErrorText(shareholderErrors),
    errorDescriptors,
    'E'
  );
  const shareholderGroupWarnings = formGroupErrorObject(
    getErrorText(shareholderWarnings),
    errorDescriptors,
    'W'
  );

  return {
    mainErrors,
    mainWarnings,
    shareholderGroupErrors,
    shareholderGroupWarnings
  };
}
