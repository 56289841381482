import React, { useReducer, createContext, Dispatch } from 'react';

import {
  editionCheckDataTableReducer,
  initialEditionCheckDataTableState
} from './tableState/tableReducer';
import {
  EditionCheckDataTableState,
  EditionCheckDataTableAction
} from './tableState/tableTypesAndActions';
import EditionCheckDataTable from './Table';

export const EditionCheckDataTableStateContext = createContext<{
  state: EditionCheckDataTableState;
}>({
  state: initialEditionCheckDataTableState
});

export const EditionCheckDataTableDispatchContext = createContext<{
  dispatch: Dispatch<EditionCheckDataTableAction>;
}>({
  dispatch: () => {}
});

export const EditionCheckDataTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(
    editionCheckDataTableReducer,
    initialEditionCheckDataTableState
  );
  return (
    <EditionCheckDataTableStateContext.Provider value={{ state }}>
      <EditionCheckDataTableDispatchContext.Provider value={{ dispatch }}>
        <EditionCheckDataTable />
      </EditionCheckDataTableDispatchContext.Provider>
    </EditionCheckDataTableStateContext.Provider>
  );
};
