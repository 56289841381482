import React, { useEffect } from 'react';
import { Box, Button, Container, Divider, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import { VirsisUser } from '../../store/virsis/dataTypes';
import { AnnulRecord } from '../../store/classifiers/classifiersTypes';
import { withLoadedUser } from '../../containers/hoc/withLoadedUser';
import { LinkRouter } from '../../components/Router/LinkRouter';
import { ApplicationState } from '../../store';
import {
  annulProvidedDataLegal,
  fetchProvidedDataLegal,
  removeProvidedData
} from '../../store/providedData/providedDataActions';
import { fetchAnnulmentTypesRequest } from '../../store/classifiers/classifiersActions';
import { unsignedDataRemoveLegal } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { LegalDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';
import { ProvidedDataHistoryTableLegalWithContext } from '../../containers/ProvidedDataTableLegal/Context';
import { ProvidedDataTableType } from '../../store/providedData/providedDataTypes';

interface Props {
  currentUser: VirsisUser;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    lgWidth: {
      margin: 'auto',
      maxWidth: 1210
    },
    button: {
      padding: '0px 20px',
      marginRight: '10px',
      marginLeft: '0px'
    },
    buttonBox: {
      width: '100%',
      display: 'flex'
    },
    icon: {
      height: 30,
      padding: '0px 10px',
      borderRight: '1px solid white'
    },
    text: {
      color: theme.palette.secondary.light,
      padding: '1px 15px'
    },
    titleContainer: {
      marginTop: '20px'
    },
    buttonContainer: {
      padding: '10px',
      marginTop: '20px',
      marginBottom: '30px'
    }
  })
);

const LegalPage: React.FC<Props> = ({ currentUser }) => {
  const classes = useStyles();

  const reduxDispatch = useDispatch();

  const {
    providedData: { loadingProvidedDataLegal, providedDataLegal, providedDataLegalError },
    unsignData: { verifySuccess }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    reduxDispatch(fetchProvidedDataLegal());
  }, [reduxDispatch, verifySuccess]);

  const { annulmentTypes } = useSelector(
    (stateGlobal: ApplicationState) => stateGlobal.classifiers
  );

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [annulmentTypes, reduxDispatch]);

  return (
    <>
      <div className="data-page">
        <Container maxWidth="lg">
          <div className="table-title">
            <Box>
              <Typography variant="h2" className="text-black" gutterBottom>
                {`Jūs esate prisijungę kaip: ${currentUser.name}, ${currentUser.amnName}`}
              </Typography>
              <Typography className="text-grey">
                Jūsų paskutinis apsilankymas: {currentUser.recentActivity}
              </Typography>
            </Box>
          </div>
        </Container>
        <Divider className={classes.lgWidth} />
        <Container maxWidth="lg">
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle1" gutterBottom>
              Duomenų teikimas
            </Typography>
          </Box>
          <Paper className={classes.buttonContainer}>
            <LinkRouter path="/duomenu-teikimas">
              <Button variant="contained" color="primary" className={classes.button}>
                <Typography variant="h5" className={classes.text}>
                  VIRS gautos lėšos
                </Typography>
                <ChevronRightIcon />
              </Button>
            </LinkRouter>
          </Paper>
        </Container>
        <Divider className={classes.lgWidth} />
        <ProvidedDataHistoryTableLegalWithContext
          loading={loadingProvidedDataLegal}
          data={providedDataLegal}
          error={providedDataLegalError}
          annul={(documentStatusId: number, annulRecord: AnnulRecord) => {
            reduxDispatch(annulProvidedDataLegal(documentStatusId, annulRecord));
          }}
          remove={(documentStatusId: number) => {
            reduxDispatch(removeProvidedData(documentStatusId, ProvidedDataTableType.LEGAL));
          }}
          onRemoveUnsignedSuccess={() => reduxDispatch(fetchProvidedDataLegal())}
          removeUnsigned={() => {
            reduxDispatch(unsignedDataRemoveLegal(LegalDocumentType.FUNDS_RECEIVED));
          }}
        />
      </div>
    </>
  );
};

export default withLoadedUser(LegalPage);
