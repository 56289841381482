export interface FileOutput {
  fileType: FileType | null;
  fileContents: File | null;
}

export interface FileInput {
  fileType: FileType | null;
  fileContents: File | null;
}

export const defaultValues: FileInput = {
  fileType: null,
  fileContents: null
};

export type FileType =
  | 'NAUDOTOJO_VADOVAS_ADMIN'
  | 'NAUDOTOJO_VADOVAS_VIEA'
  | 'NAUDOTOJO_VADOVAS_VIRS'
  | 'NAUDOTOJO_VADOVAS_LRTK_ZEIT'
  | 'NAUDOTOJO_VADOVAS_KM'
  | 'NAUDOTOJO_VADOVAS_ISTAIGA'
  | 'NAUDOTOJO_VADOVAS_VIRS_VIEW'
  | 'NAUDOJIMO_SALYGOS';

export const FormState: FileOutput[] = [
  {
    fileType: 'NAUDOTOJO_VADOVAS_ADMIN',
    fileContents: null
  },
  {
    fileType: 'NAUDOTOJO_VADOVAS_VIEA',
    fileContents: null
  },
  {
    fileType: 'NAUDOTOJO_VADOVAS_VIRS',
    fileContents: null
  },
  {
    fileType: 'NAUDOTOJO_VADOVAS_LRTK_ZEIT',
    fileContents: null
  },
  {
    fileType: 'NAUDOTOJO_VADOVAS_KM',
    fileContents: null
  },
  {
    fileType: 'NAUDOTOJO_VADOVAS_ISTAIGA',
    fileContents: null
  },
  {
    fileType: 'NAUDOTOJO_VADOVAS_VIRS_VIEW',
    fileContents: null
  },
  {
    fileType: 'NAUDOJIMO_SALYGOS',
    fileContents: null
  }
];
