import { OutletRepresentative } from '../../../store/outletInfoData/outletInfoDataTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import {
  OutletRepresentativesColumnHeaderField,
  OutletRepresentativesDataTableState,
  outletRepresentativesTableColumns
} from '../tablesState/representativesTableTypes';

export function getTableExportDefinition(
  tableState: OutletRepresentativesDataTableState
): TableExportDefinition<OutletRepresentative> {
  const { stringValueColumn } = getColumnBuilder<
    OutletRepresentative,
    OutletRepresentativesColumnHeaderField
  >(outletRepresentativesTableColumns, tableState.columnsDisplayStatus);
  return {
    title: 'Už VIP turinį atsakingi asmenys',
    columnGroups: [
      {
        columns: [stringValueColumn('representativeName', { width: 45 })]
      },
      {
        header: 'Atsakingas',
        columns: [
          stringValueColumn('validFrom', { width: 15 }),
          stringValueColumn('validTo', { width: 15 })
        ]
      },
      {
        columns: [stringValueColumn('representationNotes', { width: 45 })]
      }
    ]
  };
}
