import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EnterpriseData } from '../../../../store/enterprises/enterprisesTypes';
import {
  LibisSearchData,
  OutletData,
  OutletGroup,
  OutletType
} from '../../../../store/outlets/outletsTypes';
import {
  DatePickerState,
  DropdownStateGeneric,
  InputStateGeneric
} from '../../../../utils/tableTypes';

interface OutletRecordState {
  name: InputStateGeneric<string>;
  outletType: DropdownStateGeneric<OutletType>;
  outletGroup: OutletGroup | null;
  established: DatePickerState;
  controlledFrom: DatePickerState;
  controlledTo: DatePickerState;
  internationalNumber: InputStateGeneric<string>;
  ISBN: string | null;
  ISSN: string | null;
  outletGroups: OutletGroup[];
  showLibisSearch: boolean;
  selectedLibisOutlet?: LibisSearchData;
  usesLibis: boolean;
}

const initialOutletRecordState: OutletRecordState = {
  name: {
    value: null,
    id: 'outletName',
    error: false,
    helperText: '',
    placeholder: 'VIP pavadinimas',
    validated: false
  },
  outletType: {
    value: null,
    error: false,
    helperText: '',
    placeholder: 'VIP rūšis',
    validated: false,
    options: []
  },
  outletGroup: null,
  established: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  controlledFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  controlledTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  internationalNumber: {
    value: null,
    id: 'internationalNumber',
    error: false,
    helperText: '',
    placeholder: 'Tarptautinis nr.',
    validated: false
  },
  ISBN: null,
  ISSN: null,
  outletGroups: [],
  showLibisSearch: false,
  selectedLibisOutlet: undefined,
  usesLibis: false
};

export interface OutletFullyEditRowState extends OutletRecordState {
  editingOn: boolean;
  updateConfirmationOn: boolean;
  removeConfirmationOn: boolean;
}

export const intialOutletFullEditRowState: OutletFullyEditRowState = {
  ...initialOutletRecordState,
  editingOn: false,
  updateConfirmationOn: false,
  removeConfirmationOn: false
};

export interface OutletNewRowState extends OutletRecordState {
  createRecordConfirmationOn: boolean;
}
export const intialNewOutletRowState: OutletNewRowState = {
  ...initialOutletRecordState,
  createRecordConfirmationOn: false
};

export interface OutletSemiEditRowState {
  name: InputStateGeneric<string>;
  controlledTo: DatePickerState;
  editingOn: boolean;
  updateDateConfirmationOn: boolean;
}

export const intialSemiEditRowState: OutletSemiEditRowState = {
  name: {
    value: null,
    id: 'outletName',
    error: false,
    helperText: '',
    placeholder: 'VIP pavadinimas',
    validated: false
  },
  controlledTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  editingOn: false,
  updateDateConfirmationOn: false
};

export interface OutletRenewRowState {
  controlledFrom: DatePickerState;
  controlledTo: DatePickerState;
  isOutletExtended?: string;
  showRenewRow: boolean;
  renewConfirmationOn: boolean;
}

export const intialRenewOutletState: OutletRenewRowState = {
  controlledFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  controlledTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  showRenewRow: false,
  renewConfirmationOn: false
};

type OutletAction =
  | { type: 'OUTLET_NAME_CHANGED'; name: string | null }
  | { type: 'OUTLET_TYPE_CHANGED'; outletType: OutletType | null }
  | {
      type: 'OUTLET_ESTABLISHED_DATE_CHANGED';
      established: MaterialUiPickersDate | null;
    }
  | {
      type: 'OUTLET_CONTROLLED_FROM_CHANGED';
      controlledFrom: MaterialUiPickersDate | null;
    }
  | {
      type: 'OUTLET_CONTROLLED_TO_CHANGED';
      controlledTo: MaterialUiPickersDate | null;
    }
  | {
      type: 'OUTLET_INTERNATIONAL_NUMBER_CHANGED';
      value: string | null;
    }
  | { type: 'LIBIS_SEARCH_OPENED' }
  | { type: 'LIBIS_SEARCH_CLOSED' }
  | { type: 'LIBIS_OUTLET_CLICKED'; outlet: LibisSearchData }
  | { type: 'POPULATE_RECORD_WITH_LIBIS_DATA_CLICKED' };

export type NewOutletAction =
  | OutletAction
  | {
      type: 'NEW_OUTLET_ROW_INITIALIZED';
      enterprise: EnterpriseData;
      outletGroups: OutletGroup[];
      registrationDate: string;
      deregistrationDate: string | null;
    }
  | { type: 'CLOSE_NEW_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CANCEL_CREATING_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMED' }
  | { type: 'CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' }
  | { type: 'CONFIRMATION_CLOSED_ON_ERROR' };

export type OutletFullEditRowAction =
  | OutletAction
  | {
      type: 'EDIT_BUTTON_CLICKED';
      outlet: OutletData;
      enterprise: EnterpriseData;
      outletGroups: OutletGroup[];
      registrationDate: string;
      deregistrationDate: string | null;
    }
  | { type: 'CANCEL_EDITING_BUTTON_CLICKED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'CANCEL_UPDATING_RECORD_CLICKED' }
  | { type: 'UPDATE_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'CANCEL_REMOVING_RECORD_CLICKED' }
  | { type: 'REMOVE_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' }
  | { type: 'REMOVE_CONFIRMATION_CLOSED_ON_ERROR' };

export type OutletSemiEditRowAction =
  | { type: 'OUTLET_NAME_CHANGED'; name: string | null }
  | {
      type: 'OUTLET_CONTROLLED_TO_CHANGED';
      controlledTo: MaterialUiPickersDate | null;
    }
  | {
      type: 'SEMI_EDIT_BUTTON_CLICKED';
      outlet: OutletData;
      enterpriseValidFrom: string;
      enterpriseValidTo: string | null;
      registrationDate: string;
      deregistrationDate: string | null;
    }
  | { type: 'CANCEL_EDITING_BUTTON_CLICKED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'CANCEL_UPDATING_RECORD_CLICKED' }
  | { type: 'UPDATE_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'ADD_END_DATE_CLICKED' }
  | { type: 'CANCEL_ADDING_END_DATE_CLICKED' }
  | { type: 'REMOVE_END_DATE_CLICKED' }
  | { type: 'CANCEL_REMOVING_END_DATE_CLICKED' }
  | { type: 'UPDATE_END_DATE_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_END_DATE_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'CLOSED_ON_CIRCULATION_MISSING' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type OutletRenewRowAction =
  | {
      type: 'START_RENEWING_CLICKED';
      outlet: OutletData;
      registrationDate: string | null;
      deregistrationDate: string | null;
      enterpriseValidFrom: string;
      enterpriseValidTo: string | null;
    }
  | { type: 'CANCEL_RENEWING_CLICKED' }
  | { type: 'RENEW_FROM_CHANGED'; controlledFrom: MaterialUiPickersDate | null }
  | { type: 'RENEW_TO_CHANGED'; controlledTo: MaterialUiPickersDate | null }
  | { type: 'RENEW_RECORD_CLICKED' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' }
  | { type: 'STOP_RENEWING_RECORD_CLICKED' }
  | { type: 'RENEW_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'RENEW_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };
