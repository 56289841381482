import { InputStateGeneric, DatePickerState } from '../../../../../utils/tableTypes';
import { RepresentativeNewRowState, RepresentativeEditRowState } from './rowStateAndTypes';
import {
  validateMandatoryDate,
  validateOptionalDateImproved
} from '../../../../../utils/InputValueFunctions';

export function setRepresentativeNotesAndValidate(
  notes: string | null,
  notesState: InputStateGeneric<string>
) {
  if (notes !== null && notes.length >= 1300) {
    return {
      ...notesState,
      value: notes,
      validated: false,
      error: true,
      helperText: 'Pastabos negali viršyti 1300 simbolių'
    };
  }
  return {
    ...notesState,
    value: notes,
    validated: true,
    error: false,
    helperText: ''
  };
}

export function validateRepresentativeEditRecordState(
  state: RepresentativeEditRowState
): RepresentativeEditRowState {
  const validFromValidated: DatePickerState = validateMandatoryDate({
    ...state.validFrom
  });
  const validToValidated: DatePickerState = validateOptionalDateImproved({
    ...state.validTo
  });

  return {
    ...state,
    validFrom: validFromValidated,
    validTo: validToValidated,
    updateRecordConfirmationOn:
      validFromValidated.validated &&
      validToValidated.validated &&
      state.representationNotes.validated
  };
}

export function validateRepresentativeNewRecordState(
  state: RepresentativeNewRowState
): RepresentativeNewRowState {
  const validFromValidated: DatePickerState = validateMandatoryDate({
    ...state.validFrom
  });
  const validToValidated: DatePickerState = validateOptionalDateImproved({
    ...state.validTo
  });

  return {
    ...state,
    validFrom: validFromValidated,
    validTo: validToValidated,
    createRecordConfirmationOn:
      validFromValidated.validated &&
      validToValidated.validated &&
      state.representationNotes.validated
  };
}
