import React, { useEffect } from 'react';
import { TableBody } from '@material-ui/core';
import { ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../ShareholdersTableWithContext';
import { TableRowsContainer } from '../TableRows/TableRowsContainer';
import { getFilteredMixedDates, getYearsArray } from '../utilities/functionsWithDateFormat';
import YearsTableHeader from './FilteredViewTableHeader';
import { setYearsPeriodWithEvents } from '../tableState/tableFunctions';

interface Props {
  allEventsDates: string[];
  sortedShareholderData: ShareholdersData;
  openCalendar: () => void;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  loadingShareholdersData: boolean;
  periodFilterFromDate: string;
  periodFilterToDate: string;
}

const TableByYear: React.FC<Props> = ({
  allEventsDates,
  sortedShareholderData,
  openCalendar,
  noValidDates,
  allShareholdersFromJADIS,
  loadingShareholdersData,
  periodFilterFromDate,
  periodFilterToDate
}) => {
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const {
    state: { datesInPerdiodWithEvents }
  } = useShareholdersTableState();

  const yearsArray = getYearsArray(sortedShareholderData.virsStartDate);

  useEffect(() => {
    tableDispatch({
      type: 'INITIAL_HEADER_DATES',
      allEventsDates: allEventsDates,
      initialDates: setYearsPeriodWithEvents(yearsArray, allEventsDates)
    });
  }, []);

  const filteredDates = getFilteredMixedDates(
    datesInPerdiodWithEvents,
    {
      periodFilterFromDate,
      periodFilterToDate
    },
    'year'
  );
  const onlyDates = filteredDates.map((x) => x.date);

  return (
    <>
      <YearsTableHeader
        openCalendar={openCalendar}
        allEventsDates={allEventsDates}
        yearsArray={yearsArray}
        shareholdersData={sortedShareholderData}
        noValidDates={noValidDates}
        allShareholdersFromJADIS={allShareholdersFromJADIS}
        loadingShareholdersData={loadingShareholdersData}
        datesInPerdiodWithEvents={filteredDates}
      />

      <TableBody>
        <TableRowsContainer data={sortedShareholderData} allEventsDates={onlyDates} />
      </TableBody>
    </>
  );
};

export default TableByYear;
