import React from 'react';
import {
  AccordionSummary,
  Box,
  Button,
  Container,
  LinearProgress,
  Typography
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  useAcordianStyles,
  VirsDataContainer
} from '../../../components/VirsDataContainer/VirsDataContainer';
import NamedBreadcrumbs from '../../../components/VirsNamedBreadcrumbs/VirsNamedBreadcrumbs';
import { Roles, VirsData } from '../../../store/virsis/dataTypes';
import { AddNewRecordButtonLink } from '../../../components/TableButtons/AddNewRecordButtonLink';
import { Subsystem } from './VirsDataPage';
import AllowedTo from '../../AllowedTo';

const useStyles = makeStyles((theme) => ({
  backButton: {
    margin: '0 0 10px 0',
    padding: 0
  },
  marginLeft10: {
    marginLeft: 10,
    display: 'inline'
  },
  center: {
    margin: 'auto 0'
  },
  breadCrumbs: {
    marginLeft: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: 'fit-content',
    blockSize: 'fit-content'
  },
  titleWrapper: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    }
  },
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      overflow: 'auto'
    }
  }
}));

interface Props {
  virsData?: VirsData;
  loadingData: boolean;
  subsystem?: Subsystem;
}

const VirsDataPageHeader: React.FC<Props> = ({ virsData, subsystem, loadingData }) => {
  const classes = useAcordianStyles();
  const { backButton, marginLeft10, center, breadCrumbs, titleWrapper, buttonWrapper } = useStyles();
  const history = useHistory();

  return (
    <div className="data-page">
      <Container maxWidth="lg">
        <Button
          color="primary"
          variant="text"
          className={backButton}
          onClick={() => history.push('/virs-duomenys')}
        >
          <Typography color="inherit">Atgal</Typography>
        </Button>
        {virsData && (
          <>
            <div className={buttonWrapper}>
              <NamedBreadcrumbs virsName={virsData.name} subsystem={subsystem} />
              <div className="table-title">
                <Box style={{ display: 'flex' }}>
                  <Typography className={center} color="textPrimary" variant="h2" gutterBottom>
                    <div className={titleWrapper}>
                      {`${virsData.name} (${virsData.companyCode})`}
                    </div>
                  </Typography>
                  <div className={buttonWrapper}>
                    <Box className={breadCrumbs}>
                      <AllowedTo roles={[Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT]}>
                      <Box className={marginLeft10}>
                        <AddNewRecordButtonLink
                          helperText={'Mygtukas neaktyvus, nes VIRS neturi pasirašytų VIP'}
                          disabled={virsData.outletCount === 0}
                          text="Tiražo patikrinimo duomenys"
                          to={`/duomenu-teikimas/tirazo-patikrinimo-duomenys/virs/${virsData.companyCode}`}
                        />
                        </Box>
                      </AllowedTo>
                      <AllowedTo roles={[Roles.ROLE_KM_METINE_IMOKA_EDIT]}>
                        <Box className={marginLeft10}>
                          <AddNewRecordButtonLink
                            helperText={'Mygtukas neaktyvus, nes VIRS neturi pasirašytų veiklos rūšių'}
                            disabled={virsData.enterpriseCount === 0}
                            text="Metinės įmokos duomenys"
                            to={`/duomenu-teikimas/metines-imokos-duomenys/virs/${virsData.companyCode}`}
                          />
                        </Box>
                      </AllowedTo>
                      <AllowedTo roles={[Roles.ROLE_ISTAIG_EDIT]}>
                        <Box className={marginLeft10}>
                          <AddNewRecordButtonLink
                            helperText={'Mygtukas neaktyvus, nes VIRS neturi pasirašytų veiklos rūšių'}
                            disabled={virsData.enterpriseCount === 0}
                            text="VIRS gautos lėšos"
                            to={`/duomenu-teikimas/virs-gautos-lesos/virs/${virsData.companyCode}`}
                          />
                        </Box>
                      </AllowedTo>
                      <AllowedTo roles={[Roles.ROLE_ZEIT_EDIT, Roles.ROLE_LRTK_EDIT]}>
                        <Box className={marginLeft10}>
                          <AddNewRecordButtonLink
                            helperText={'Mygtukas neaktyvus, nes VIRS neturi pasirašytų veiklos rūšių'}
                            disabled={virsData.enterpriseCount === 0}
                            text="Rimti profesiniai pažeidimai"
                            to={`/duomenu-teikimas/profesiniai-pazeidimai/virs/${virsData.companyCode}`}
                          />
                        </Box>
                      </AllowedTo>
                      <AllowedTo roles={[Roles.ROLE_VIEA_EDIT]}>
                        <Box className={marginLeft10}>
                          <AddNewRecordButtonLink
                            helperText={'Mygtukas neaktyvus, nes VIRS neturi pasirašytų veiklos rūšių'}
                            disabled={virsData.enterpriseCount === 0}
                            text="Profesnės etikos nesilaikymas"
                            to={`/duomenu-teikimas/profesines-etikos-nesilaikymas/virs/${virsData.companyCode}`}
                          />
                        </Box>
                      </AllowedTo>
                    </Box>
                  </div>
                </Box>
              </div>
            </div>
          </>
        )}
        <Accordion defaultExpanded className={classes.accordionBox}>
          <AccordionSummary
            className={classes.accordionTitleWrapper}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" className={classes.accordionTitle}>
              Viešosios informacijos rengėjo ir (ar) skleidėjo (VIRS) duomenys
            </Typography>
          </AccordionSummary>
          {loadingData && <LinearProgress />}
          {virsData && <VirsDataContainer virsData={virsData} />}
        </Accordion>
      </Container>
    </div>
  );
};

export default VirsDataPageHeader;
