import React, { useContext } from 'react';

import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import {
  FundsReceivedLegalTableDispatchContext,
  FundsReceivedLegalTableStateContext
} from './Context';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import {
  FundsReceivedLegalTableField,
  FundsReceivedLegalTableHeader
} from './tableState/tableTypesAndActions';
import TableStatusFilters from '../../components/TableStatusFilters/TableStatusFilters';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
  customFilterReset,
  showStatusOutdatedToggle,
  showStatusRelevantToggle,
  showStatusUnsignedToggled,
  sortingChanged
} from '../../store/fundsReceivedTable/fundsReceivedTableActions';

interface Props {
  columnNames: FundsReceivedLegalTableHeader[];
  disabled?: boolean;
}

export const FundsReceivedLegalTableCustomizationBar: React.FC<Props> = ({
  columnNames,
  disabled
}) => {
  const { dispatch: tableDispatch } = useContext(FundsReceivedLegalTableDispatchContext);
  const reduxDispatch = useDispatch();
  const { tableDataState } = useSelector((state: ApplicationState) => state.fundsReceivedTableData);
  const { state: tableState } = useContext(FundsReceivedLegalTableStateContext);

  const toggleDisplayCustomFilter = () => {
    reduxDispatch(customFilterReset());
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: FundsReceivedLegalTableField) => {
    reduxDispatch(sortingChanged(column));
  };

  const toggleDisplayColumn = (column: FundsReceivedLegalTableField) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    reduxDispatch(showStatusUnsignedToggled());
  };

  const toggleRelevantFilter = () => {
    reduxDispatch(showStatusRelevantToggle());
  };

  const toggleOutdatedFilter = () => {
    reduxDispatch(showStatusOutdatedToggle());
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            allFiltersDisabled={disabled}
            notSignedFilterDisabled={!tableDataState.statusNotSignedFilterEnabled}
            notSignedFilterOn={tableDataState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterDisabled={!tableDataState.statusRelevantFilterEnabled}
            relevantFilterOn={tableDataState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterDisabled={!tableDataState.statusOutdatedFilterEnabled}
            outdatedFilterOn={tableDataState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            allToolsDisabled={disabled}
            tableColumns={columnNames}
            showFilter={tableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={tableDataState.order}
            sortBy={tableDataState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={tableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
