import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { fetchUser } from '../../store/virsis/actions';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { currentUser } = useSelector((state: ApplicationState) => state.virsis);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      dispatch(fetchUser());
    }
  }, [currentUser, dispatch]);

  return (
    <>
      <Route {...rest} render={() => children} />
    </>
  );
};

export default PrivateRoute;
