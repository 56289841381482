import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  EthicalNonComplianceHeaderField,
  EthicalNonComplianceColumnHeader
} from './tableState/tableInitialStateAndTypes';
import { useEthicalNonComplianceTableState, useEthicalNonComplianceTableDispatch } from './Context';
import { VirsEthicalNonComplianceData } from '../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: EthicalNonComplianceColumnHeader[];
  dataToDisplay: VirsEthicalNonComplianceData[];
}

const EthicalNonComplianceTableCustomFilter: React.FC<Props> = ({ columnNames, dataToDisplay }) => {
  const { state: tableState } = useEthicalNonComplianceTableState();
  const { dispatch } = useEthicalNonComplianceTableDispatch();

  const handleCustomFilterChange = (
    column: EthicalNonComplianceHeaderField,
    value: string | null
  ) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  function getUniqueOptions(
    name: EthicalNonComplianceHeaderField,
    data: VirsEthicalNonComplianceData[]
  ) {
    if (name === 'outletList') {
      const outletNames: string[] = data.flatMap((record) =>
        record.outletList.map((outlet) => outlet.outletName)
      );
      return Array.from(new Set(outletNames));
    }

    if (data) {
      return Array.from(
        new Set(data.filter((item) => item[name] !== null).map((item) => item[name]))
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions(column.id, dataToDisplay)}
            />
          ))}

        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default EthicalNonComplianceTableCustomFilter;
