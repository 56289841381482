import { Column, ColumnGroup, TableDefinition } from '../types';
import { Filter, Visibility } from '../state/reducer';
import {
  TableExportDefinition,
  ColumnDefinition as ExportedColumn,
  ColumnGroup as ExportedColumnGroup
} from '../../../../utils/exporters/types';

export function getVisibilityValue<TData>(
  key: keyof TData,
  visibility: Visibility<TData>[]
): boolean {
  return visibility.find(({ field }) => key === field)?.visible || false;
}

export function getVisibleColumns<TData>(
  columnVisibility: Visibility<TData>[],
  columns: Column<TData>[]
) {
  return columns.filter(({ field }) => getVisibilityValue(field, columnVisibility));
}

export function getFilteredData<TData>(
  filters: Filter<TData>[],
  columns: Column<TData>[],
  data: TData[]
) {
  const filterFunctions = columns
    .map(({ filterFn, field }) => ({
      value: filters.find(({ field: filterField }) => filterField === field)?.value,
      filterFn
    }))
    .map(
      ({ filterFn, value }) =>
        (values: TData[]) =>
          filterFn(values, value)
    );
  return filterFunctions.reduce((prev, filterFn) => filterFn(prev), [...data] as TData[]);
}

export function getInitialFilters<TData>(tableDefinition: TableDefinition<TData>): Filter<TData>[] {
  return tableDefinition.columnGroups
    .map(({ columns }) => columns)
    .flatMap((columns) => columns)
    .map(({ field }) => ({ field }));
}

function getTableExportColumn<TData>(column: Column<TData>): ExportedColumn<TData> {
  return {
    identifier: column.field,
    visible: true,
    header: column.header,
    cellMapper: (data) => [{ values: column.valueMapper(data), style: column.exportedStyle?.cell }],
    style: column.exportedStyle?.column
  };
}

function getTableExportColumnGroup<TData>(
  columnGroup: ColumnGroup<TData>
): ExportedColumnGroup<TData> {
  return {
    columns: columnGroup.columns.map(getTableExportColumn),
    header: columnGroup.header
  };
}

export function getTableExportDefinition<TData>(
  tableDefinition: TableDefinition<TData>,
  columnVisibility: Visibility<TData>[],
  tableTitle?: string
): TableExportDefinition<TData> {
  const mapExportColumnGroup = (group: ColumnGroup<TData>): ExportedColumnGroup<TData> => {
    const { columns, ...exportGroup } = getTableExportColumnGroup(group);
    return {
      ...exportGroup,
      columns: columns.map((column) => ({
        ...column,
        visible: columnVisibility.find(({ field }) => field === column.identifier)?.visible || false
      }))
    };
  };
  return {
    title: tableTitle,
    columnGroups: tableDefinition.columnGroups.map(mapExportColumnGroup)
  };
}
