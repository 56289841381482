import React, { ReactElement } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import theme from '../../style/virsisTheme';

const useStyles = makeStyles({
  tooltip: {
    margin: '0.8em 0',
    padding: 12,
    borderColor: theme.palette.text.primary,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.secondary.light,
    fontFamily: 'Open Sans',
    fontSize: '0.75rem',
    fontWeight: 600,
    '& span': {
      color: theme.palette.text.primary
    }
  }
});

interface Props {
  children: ReactElement;
  text: string;
  disableHoverListener?: any;
}

export const BasicDarkTooltip: React.FC<Props> = ({ children, text, disableHoverListener }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={text}
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip }}
      disableHoverListener={disableHoverListener}
    >
      {children}
    </Tooltip>
  );
};
