import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getBooleanMappedColumn,
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringArrayMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { OutletTypeForm } from './OutletTypeForm';

import { loadData, setFormOpen, State } from './store';
import { OutletType } from './types';

export const OutletTypeTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const outletTypeTableDefinition: TableDefinition<Nullable<OutletType>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('outletTypeId', 'Kodas', (data) => data.outletTypeId),
            getStringMappedColumn(
              'outletTypeName',
              'VIP rūšies pavadinimas Lt',
              (data) => data.outletTypeName,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getStringMappedColumn(
              'outletTypeNameEn',
              'VIP rūšies pavadinimas En',
              (data) => data.outletTypeNameEn,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getStringMappedColumn(
              'outletGroup',
              'VIP grupė',
              (data) => data.outletGroup?.outletGroupName || null,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getBooleanMappedColumn(
              'requiresIsbn',
              'Ar VIP rūšiai privalomas ISSN/ISBN',
              (data) => data.requiresIsbn
            ),
            getBooleanMappedColumn(
              'requiresEditions',
              'Ar VIP rūšiai privalomas periodinis tiražo duomenų teikimas',
              (data) => data.requiresEditions
            ),
            getBooleanMappedColumn(
              'usesLibis',
              'Ar VIP rūšiai privalomas duomenų iš LIBIS gavimas',
              (data) => data.usesLibis
            ),
            getStringArrayMappedColumn(
              'enterpriseTypes',
              'VIRS rūšis(-ys)',
              (data) =>
                data.enterpriseTypes?.map(({ enterpriseTypeName }) => enterpriseTypeName) || [],
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            )
          ]
        },
        {
          header: 'Galioja',
          columns: [
            getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              outletTypeId: data.outletTypeId,
              outletTypeName: data.outletTypeName || null,
              outletTypeNameEn: data.outletTypeNameEn || null,
              requiresEditions: data.requiresEditions || false,
              requiresIsbn: data.requiresIsbn || false,
              usesLibis: data.usesLibis || false,
              outletGroupId: data.outletGroup?.outletGroupId || null,
              enterpriseTypeIds:
                data.enterpriseTypes?.map(({ enterpriseTypeId, enterpriseTypeName }) => ({
                  value: enterpriseTypeId,
                  label: enterpriseTypeName
                })) || [],
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              validTo: (data.validTo && new Date(data.validTo)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują VIP rūšį'
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={outletTypeTableDefinition} {...dataState} />

      <OutletTypeForm />
    </>
  );
};
