// bendros funkcijos naudojamos pagrindinėje ir konkrečois datos lentelėse
import moment, { Moment } from 'moment';
import {
  ActivityPeriod,
  ErrorProps,
  LegalPerson,
  NaturalPerson,
  ShareholderPerson,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../store/shareholders/shareholdersTypes';
import { ErrorType } from '../../../store/virsis/dataTypes';
import {
  getDaysArray,
  replaceDecimalPoint,
  todaysDateString
} from '../../../utils/tableDataFunctions';
import { SortOrder } from '../../../utils/tableTypes';
import {
  DATE_FROM_JADIS_TEXT,
  DateTypes,
  ENTERPRISE_END_DATE_TEXT,
  ENTERPRISE_START_DATE_TEXT,
  ENTERPRISE_UPDATE_DATE_TEXT,
  NO_EVENTS_TEXT,
  SIGNED_DATE_TEXT,
  TODAYS_DATE_TEXT,
  VIRS_END_DATE_TEXT,
  VIRS_UNREGISTERED_TEXT
} from '../constants';
import { LastColumnData } from '../MainTable/tableState/tableInitialStateAndTypes';
import { getAllShareholders } from '../SelectedDateTable/utilities/utilityFunctions';
import { getAllEvents, isDateUnsigned } from '../MainTable/tableState/tableFunctions';

export const findKeyValue = (searchData?: any, findKey?: string) => {
  const searchResults: string[] = [];
  JSON.stringify(searchData, (key, value) => {
    if (key === findKey && value !== undefined && value !== null) searchResults.push(value);
    return value;
  });
  return searchResults;
};

export const currentDate = moment().format('YYYY-MM-DD');

export const getHeaderDatesSortedUnique = (
  withCurrentDate: boolean,
  shareholdersData?: ShareholdersData
) => {
  if (shareholdersData) {
    const { virsStartDate, virsEndDate, virsStoppedParticipatingFromDate } = shareholdersData;

    const shareholdersStartDate = findKeyValue(shareholdersData, 'shareholderStartDate');
    const shareholdersEndDate = findKeyValue(
      shareholdersData,
      'shareholderStoppedParticipatingFromDate'
    );
    const eventDate = findKeyValue(shareholdersData, 'eventDate');

    const allEventDates = [
      virsStartDate,
      ...shareholdersStartDate,
      ...shareholdersEndDate,
      ...eventDate,
      withCurrentDate ? currentDate : 'null'
    ];

    if (virsEndDate && virsStoppedParticipatingFromDate) {
      allEventDates.push(virsEndDate, virsStoppedParticipatingFromDate);
    }

    const uniqueDates = Array.from(new Set(allEventDates));

    return uniqueDates
      .filter(
        (item) =>
          item !== 'null' &&
          (virsStoppedParticipatingFromDate ? item <= virsStoppedParticipatingFromDate : true)
      )
      .sort();
  }
  return undefined;
};

export const filterErrorObjs = (errorData: ErrorProps[], errorType: ErrorType) => {
  const filteredErrors = errorData?.filter((error) => error.errorType === errorType);

  return getFormattedErrors(filteredErrors);
};

const getFormattedErrors = (errors: ErrorProps[]): ErrorProps[] => {
  return errors.map((error) => ({
    ...error,
    errorMessages: error.errorMessages.map((message) => ({
      ...message,
      errorText: replaceDecimalPoint(message.errorText)
    }))
  }));
};

export const splitShareholderPath = (path: string) => path.split('/');

const filterPersons = (shareholderPersons: ShareholderPerson[], personType: string) => {
  return shareholderPersons.filter((person) => person.personType === personType);
};

export const getSortedPersonsArray = (shareholderPersons: ShareholderPerson[]) => {
  const naturalPersonsLT = filterPersons(shareholderPersons, NaturalPerson.LFA);
  const naturalPersonsForeign = filterPersons(shareholderPersons, NaturalPerson.UFA);
  const legalPersonsLT = filterPersons(shareholderPersons, LegalPerson.LJA);
  const legalPersonsForeign = filterPersons(shareholderPersons, LegalPerson.UJA);
  return [...naturalPersonsLT, ...naturalPersonsForeign, ...legalPersonsLT, ...legalPersonsForeign];
};

export const getPercentageValue = (
  votesOnRules: boolean | null,
  sharePercentage: number | null,
  votePercentage: number | null,
  parentMayHaveSharePercentage: boolean,
  parentMayHaveVotePercentage: boolean,
  parentShareTypeName: string | null
) => {
  const votes = () => {
    if (votesOnRules) {
      return `balsai pagal taisyklę`;
    }
    return `${votePercentage === null ? '-' : votePercentage}% balsų`;
  };

  if (parentMayHaveSharePercentage && parentMayHaveVotePercentage) {
    return `${sharePercentage || '-'}% ${parentShareTypeName?.toLowerCase()} /
      ${votes()}`;
  } else if (parentMayHaveSharePercentage) {
    return `${sharePercentage || '-'}% ${parentShareTypeName?.toLowerCase()}`;
  } else if (parentMayHaveVotePercentage) {
    return votes();
  }
  return undefined;
};

// TableHeader bendros funkcijos
const checkIfEventDateFromJADIS = (shareholder: ShareholdersProps, selectedDate: string) => {
  return shareholder.shareholderHistory.some((event) => {
    return (
      event.eventDate === selectedDate &&
      event.isEventFromJADIS &&
      shareholder.shareholderParentStartDate !== selectedDate
    );
  });
};

export const checkAllShareholdersIfDateFromJADIS = (
  shareholders: ShareholdersProps[],
  date: string
): boolean => {
  return shareholders.some((shareholder) => {
    if (shareholder.shareholderStartDate === date) {
      return false;
    }
    const isEventDateFromJADIS = checkIfEventDateFromJADIS(shareholder, date);

    if (isEventDateFromJADIS) {
      return true;
    }

    return shareholder.shareholderPersons.some((person) => {
      if (person.shareholders.length) {
        return checkAllShareholdersIfDateFromJADIS(person.shareholders, date);
      }
      return false;
    });
  });
};

export const checkIfDateIsAfterOrEqualStartDate = (
  shareholderStartDate: string | null,
  shareholderParentStartDate: string,
  date: string
) => {
  return (
    (shareholderStartDate && shareholderStartDate <= date) ||
    (!shareholderStartDate && shareholderParentStartDate && shareholderParentStartDate <= date)
  );
};

const comparator = (firstValue: string, secondValue: string, direction: string): number =>
  direction === 'desc'
    ? secondValue.localeCompare(firstValue)
    : firstValue.localeCompare(secondValue);

const getSortedByStartDate = (data: ShareholdersData, direction: SortOrder) => {
  return data.shareholders.sort((a, b) => {
    if (a.shareholderStartDate && b.shareholderStartDate) {
      return comparator(a.shareholderStartDate, b.shareholderStartDate, direction);
    }
    if (!a.shareholderStartDate && b.shareholderStartDate) {
      return direction === 'desc' ? 1 : -1;
    }
    if (a.shareholderStartDate && !b.shareholderStartDate) {
      return direction === 'desc' ? -1 : 1;
    }
    if (!a.shareholderStartDate && !b.shareholderStartDate) {
      return comparator(a.shareholderParentStartDate, b.shareholderParentStartDate, direction);
    }
    return 0;
  });
};

const getSortedByPercentage = (
  data: ShareholdersData,
  direction: SortOrder,
  percentageData: {
    showShares: boolean;
    showVotes: boolean;
    selectedDate?: string;
  }
) => {
  const { showShares, showVotes, selectedDate } = percentageData;

  const percentageMapper = (shareholder: ShareholdersProps) => {
    const selectedEvent = shareholder.shareholderHistory.find(
      (event) => event.eventDate === selectedDate
    );

    if (selectedEvent) {
      if (showShares) {
        return selectedEvent.sharePercentage || 0;
      }
      if (showVotes && !data.mayHaveVotesOnRules) {
        return selectedEvent.votePercentage || 0;
      }
      return 0;
    }
    return -1;
  };

  return data.shareholders.sort((a, b) =>
    direction === 'desc'
      ? percentageMapper(a) - percentageMapper(b)
      : percentageMapper(b) - percentageMapper(a)
  );
};

const getSortedByName = (data: ShareholdersData, direction: SortOrder) => {
  const mapper = (shareholder: ShareholdersProps) =>
    shareholder.shareholderPersons[0].personName.toUpperCase();

  return data.shareholders.sort((a, b) => {
    if (a.shareholderGroupId && b.shareholderGroupId) {
      return comparator(mapper(a), mapper(b), direction);
    }
    if (a.shareholderGroupId && !b.shareholderGroupId) {
      return direction === 'desc' ? -1 : 1;
    }
    if (!a.shareholderGroupId && b.shareholderGroupId) {
      return direction === 'desc' ? 1 : -1;
    }
    if (!a.shareholderGroupId && !b.shareholderGroupId) {
      return comparator(mapper(a), mapper(b), direction);
    }
    return 0;
  });
};

export const getSortedShareholdersData = (
  shareholdersData: ShareholdersData,
  direction: SortOrder,
  sortBy: string,
  percentageData?: {
    showShares: boolean;
    showVotes: boolean;
    selectedDate?: string;
  }
): ShareholdersData => {
  const data: ShareholdersData = JSON.parse(JSON.stringify(shareholdersData));

  if (sortBy === 'shareholderStartDate') {
    return { ...data, shareholders: getSortedByStartDate(data, direction) };
  }
  if (sortBy === 'shareholderName') {
    return { ...data, shareholders: getSortedByName(data, direction) };
  }
  if (sortBy === 'shareholderPercentage' && percentageData) {
    return {
      ...data,
      shareholders: getSortedByPercentage(data, direction, percentageData)
    };
  }
  return data;
};

export const getTableStartDate = (virsStartDate: string, minStartDate: string) =>
  moment(minStartDate) > moment(virsStartDate) ? minStartDate : virsStartDate;

export const getValidDates = (
  virsStartDate: string,
  minStartDate: string,
  virsEndDate: string | null
) => {
  return getDaysArray(
    getTableStartDate(virsStartDate, minStartDate),
    virsEndDate || todaysDateString()
  );
};

export const isOneDayShareholder = (
  activityPeriod: ActivityPeriod,
  shareholderHistory: ShareholdersHistory[],
  event: ShareholdersHistory,
  shareholderStoppedParticipatingFromDate: string | null
) => {
  const filteredEvents = shareholderHistory.filter(
    (_event) => _event.documentId !== null || _event.isEventFromJADIS
  );
  if (!event.isEventFromJADIS && event.documentId === null) {
    return false;
  }
  if (event.isEventFromJADIS && filteredEvents.length > 2) {
    return false;
  }
  if (event.eventDate !== activityPeriod?.endDate && filteredEvents.length === 1) {
    return shareholderStoppedParticipatingFromDate === event.eventDate;
  }
  return (
    event.eventDate === activityPeriod?.endDate &&
    filteredEvents.length === 1 &&
    filteredEvents.some(
      (e) =>
        e.eventDate === shareholderStoppedParticipatingFromDate ||
        e.eventDate === activityPeriod?.endDate
    )
  );
};

export const getEventsForSelectedDate = (
  shareholderHistory: ShareholdersHistory[],
  dateFromHeader: string,
  virsEndDate: string | null,
  periodFilter: string,
  filteredEventDate: string,
  shareholderStoppedParticipatingFromDate: string | null,
  shareholderStartParticipatingFromDate: string | null,
  activityPeriods: ActivityPeriod[]
): ShareholdersHistory[] => {
  const format = (date: string | null) => {
    if (date === null) {
      return '';
    }
    if (periodFilter === 'changeDate' || filteredEventDate) {
      return date;
    }
    if (periodFilter === 'year') {
      return moment(date, 'YYYY').year().toString();
    }
    if (periodFilter === 'month') {
      return moment(date).format('YYYY-MM');
    }
    if (periodFilter === 'mixed') {
      if (moment(dateFromHeader, 'YYYY', true).isValid()) {
        return moment(date).format('YYYY');
      }
      if (moment(dateFromHeader, 'YYYY-MM', true).isValid()) {
        return moment(date).format('YYYY-MM');
      }
      return date;
    }
    return date;
  };

  const allPreviousAndSelectedDateEvents = shareholderHistory.filter((event) => {
    const eventDate = event.eventDate;
    const activityPeriod = getCurrentPeriod(eventDate, activityPeriods);

    const result =
      (format(eventDate) !== format(shareholderStoppedParticipatingFromDate) ||
        shareholderHistory.length === 1 ||
        event.documentId !== null ||
        format(eventDate) !== format(activityPeriod?.endDate)) &&
      (format(eventDate) !== format(activityPeriod?.endDate) ||
        format(eventDate) === format(shareholderStartParticipatingFromDate)) &&
      (virsEndDate !== null ? format(eventDate) <= format(virsEndDate) : true) &&
      format(eventDate) <= format(dateFromHeader);

    if (eventDate === shareholderStoppedParticipatingFromDate) {
      return isOneDayShareholder(
        activityPeriod,
        shareholderHistory,
        event,
        shareholderStoppedParticipatingFromDate
      );
    }
    return result;
  });

  const selectedDateIndex = allPreviousAndSelectedDateEvents.findIndex(
    (event: ShareholdersHistory) => {
      return format(event.eventDate) === dateFromHeader;
    }
  );

  return selectedDateIndex === -1
    ? allPreviousAndSelectedDateEvents.slice(-1)
    : allPreviousAndSelectedDateEvents.filter((event, index) => {
        if (format(event.eventDate) === dateFromHeader) {
          return true;
        }
        return format(event.eventDate) < dateFromHeader && index === selectedDateIndex - 1;
      });
};

export const getLastColumnColorList = (
  lastColumnColorsObject: LastColumnData,
  color: string,
  borderColor: string,
  id: number
) => {
  const colorsObject = { ...lastColumnColorsObject };
  colorsObject[id] = { color, borderColor };

  return colorsObject;
};

export const getTooltipTextOnDeleteButton = (
  virsEndDate: string | null,
  virsStoppedParticipatingFromDate: string | null,
  enterpriseActivityPeriods: ActivityPeriod[],
  selectedDate: string,
  virsStartDate?: string | null,
  isDateFromJADIS?: boolean,
  isDateSigned?: boolean,
  noEventsOnDate?: boolean,
  shareholdersData?: ShareholdersData
) => {
  const DATE_DELETE_TEXT = ', jos pašalinti negalima';
  if (virsStoppedParticipatingFromDate === selectedDate) {
    return VIRS_UNREGISTERED_TEXT;
  }
  if (virsStartDate === selectedDate || selectedDate === getMinimumDate(shareholdersData)) {
    return ENTERPRISE_START_DATE_TEXT + DATE_DELETE_TEXT;
  }
  if (
    enterpriseActivityPeriods.filter((activityPeriod) => activityPeriod?.endDate === selectedDate)
      .length > 0
  ) {
    return ENTERPRISE_END_DATE_TEXT + DATE_DELETE_TEXT;
  }
  if (
    enterpriseActivityPeriods.filter((activityPeriod) => activityPeriod?.startDate === selectedDate)
      .length > 0
  ) {
    return ENTERPRISE_UPDATE_DATE_TEXT + DATE_DELETE_TEXT;
  }
  if (virsEndDate === selectedDate) {
    return VIRS_END_DATE_TEXT;
  }
  if (isDateFromJADIS) {
    return DATE_FROM_JADIS_TEXT + DATE_DELETE_TEXT;
  }
  if (selectedDate === todaysDateString()) {
    return TODAYS_DATE_TEXT + DATE_DELETE_TEXT;
  }
  if (isDateSigned) {
    return SIGNED_DATE_TEXT + DATE_DELETE_TEXT;
  }
  if (noEventsOnDate) {
    return NO_EVENTS_TEXT;
  }

  return '';
};

export const getTooltipTextOnClearButton = (isDateSigned?: boolean, noEventsOnDate?: boolean) => {
  const DATE_CLEAR_TEXT = ', jos išvalyti negalima';
  if (noEventsOnDate) {
    return NO_EVENTS_TEXT;
  }
  if (isDateSigned) {
    return SIGNED_DATE_TEXT + DATE_CLEAR_TEXT;
  }
  return '';
};

export const getFirstNoNParticipationDay = (
  shareholderStoppedParticipatingFromDate: string | null,
  virsStoppedParticipatingFromDate: string | null
) => {
  if (shareholderStoppedParticipatingFromDate && virsStoppedParticipatingFromDate) {
    return shareholderStoppedParticipatingFromDate < virsStoppedParticipatingFromDate
      ? shareholderStoppedParticipatingFromDate
      : virsStoppedParticipatingFromDate;
  }
  return shareholderStoppedParticipatingFromDate || virsStoppedParticipatingFromDate;
};

const filterEventDatesByShareholderParentStart = (
  date: string,
  shareholderData: ShareholdersProps[]
): boolean => {
  if (
    shareholderData.some((shareholder) =>
      shareholder.shareholderHistory.some(
        (event) =>
          (event.eventDate === date && shareholder.shareholderParentStartDate <= date) ||
          shareholder.shareholderStoppedParticipatingFromDate === date
      )
    )
  ) {
    return true;
  }
  return shareholderData.some((shareholder) =>
    shareholder.shareholderPersons.some((person) =>
      filterEventDatesByShareholderParentStart(date, person.shareholders)
    )
  );
};

export const getFilteredDatesForMainTable = (
  allEventsDates: string[],
  periodFilterFromDate: string,
  periodFilterToDate: string,
  filteredEventDate: string,
  activityPeriods: ActivityPeriod[],
  minimumDate: string,
  shareholderData: ShareholdersData | undefined
) => {
  const events = allEventsDates.filter((eventDate: string) => {
    if (filteredEventDate) {
      return eventDate === filteredEventDate;
    }
    if (periodFilterFromDate && periodFilterToDate) {
      return eventDate >= periodFilterFromDate && eventDate <= periodFilterToDate;
    }
    if (periodFilterFromDate) {
      return eventDate >= periodFilterFromDate;
    }
    if (periodFilterToDate) {
      return eventDate <= periodFilterToDate;
    }
    return true;
  });
  return getFilteredDatesForSelectedDateTable(
    events,
    activityPeriods,
    minimumDate,
    shareholderData
  );
};

export const isEventAfterShareholderEndDate = (
  shareholderData: ShareholdersProps[],
  eventDate: string
): boolean => {
  const shareholders = getAllShareholders(shareholderData, []);
  const validShareholderEvents: string[] = shareholders
    .flatMap((shareholder) => {
      return shareholder.shareholderHistory.filter(
        (event) =>
          shareholder.shareholderStoppedParticipatingFromDate === null ||
          event.eventDate <= shareholder.shareholderStoppedParticipatingFromDate
      );
    })
    .map((event) => event.eventDate);

  return (
    validShareholderEvents.some((date) => date === eventDate) ||
    shareholders.some(
      (shareholder) => shareholder.shareholderStoppedParticipatingFromDate === eventDate
    )
  );
};

export const getFilteredDatesForSelectedDateTable = (
  allEventsDates: string[],
  activityPeriods: ActivityPeriod[],
  minimumDate: string,
  shareholderData: ShareholdersData | undefined
) => {
  return allEventsDates.filter((eventDate: string) => {
    const activityPeriod = getCurrentPeriod(eventDate, activityPeriods);
    if (activityPeriod?.startDate === eventDate) {
      return true;
    }
    if (activityPeriod?.endDate === eventDate) {
      return true;
    }
    if (eventDate === minimumDate) {
      return true;
    }
    if (eventDate < minimumDate) {
      return false;
    }
    if (!activityPeriod) {
      return false;
    }
    if (activityPeriod?.startDate > eventDate) {
      return false;
    }
    if (todaysDateString() === eventDate) {
      return true;
    }
    if (!isEventAfterShareholderEndDate(shareholderData?.shareholders || [], eventDate)) {
      return false;
    }
    return filterEventDatesByShareholderParentStart(eventDate, shareholderData?.shareholders || []);
  });
};

export const getShareholderLevelList = (shareholdersData: ShareholdersData) =>
  Array.from(new Set(findKeyValue(shareholdersData, 'shareholderLevel')))
    .sort()
    .map((level) => `${level}`);

const convertArrayToObject = (array: string[], value: boolean) => {
  const initialValue = {};
  return array.reduce((obj: any, item: string) => {
    return {
      ...obj,
      [item]: value
    };
  }, initialValue);
};

const getAllShareholderPathsWithIndexes = (shareholders: ShareholdersProps[]): string[] => {
  let allPaths: string[] = [];
  shareholders.forEach((shareholder, index) => {
    allPaths = allPaths.concat(shareholder.shareholderPath + '__' + index);
    shareholder.shareholderPersons.forEach((person) => {
      if (person.shareholders.length > 0) {
        allPaths = allPaths.concat(getAllShareholderPathsWithIndexes(person.shareholders));
      }
    });
  });
  return allPaths;
};

export const getFilteredPathsAndIds = (
  filteredShareholderLevel: string,
  virsShareholderId: number,
  allShareholders: ShareholdersProps[],
  shareholdersData: ShareholdersData
) => {
  const allPaths = getAllShareholderPathsWithIndexes(shareholdersData.shareholders);

  const filteredPathsArray = allPaths.filter((path: string) => {
    const pathLength = splitShareholderPath(path).length;

    if (pathLength <= Number(filteredShareholderLevel)) {
      return true;
    } else {
      return false;
    }
  });

  const filteredShareholdersByPath = allShareholders.filter((shareholder) =>
    filteredPathsArray.some((path) => {
      const [shareholderPath] = path.split('__');
      return shareholderPath === shareholder.shareholderPath;
    })
  );

  const filteredUniquePersonIds = filteredShareholdersByPath
    .map((shareholder) => {
      const shareholdersPersons: string[] = [];
      shareholder.shareholderPersons.forEach((person) =>
        shareholdersPersons.push(`${shareholder.shareholderLevel}${person.personId}`)
      );
      return shareholdersPersons;
    })
    .flat();

  return {
    filteredShareholderPaths: {
      [virsShareholderId]: true,
      ...convertArrayToObject(filteredPathsArray, true)
    },
    filteredPersonIds: convertArrayToObject(filteredUniquePersonIds, true)
  };
};

export const getParentLegalFormCode = (
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson
) => {
  if ('virsLegalCode' in parentShareholder) {
    return parentShareholder.virsLegalFormCode;
  } else if ('shareholderId' in parentShareholder) {
    return parentShareholder.shareholderPersons[0].personLegalFormCode;
  } else if ('personId' in parentShareholder) {
    return parentShareholder.personLegalFormCode;
  }

  return null;
};

export const formatDateString = (date: string, dateType: DateTypes): string => {
  switch (dateType) {
    case 'day':
    case 'changeDate':
    case 'mixed':
      date = moment(date).format('yyyy-MM-dd');
      break;
    case 'month':
      date = moment(date).format('yyyy-MM');
      break;
    case 'year':
      date = moment(date).format('yyyy');
      break;
  }
  return date;
};

export const getMinimumDate = (shareholders?: ShareholdersData): string => {
  if (!shareholders) return '';
  return shareholders.virsStartDate > shareholders.minimumDate
    ? shareholders.virsStartDate
    : shareholders.minimumDate;
};

export const getCurrentPeriod = (
  date: string,
  activityPeriods: ActivityPeriod[]
): ActivityPeriod => {
  const [currentActivePeriod] = activityPeriods.filter(
    (activityPeriod) =>
      activityPeriod?.startDate <= date &&
      (activityPeriod?.endDate === null || activityPeriod?.endDate >= date)
  );
  return currentActivePeriod;
};

export const getActivityPeriod = (
  selectedDate: string,
  activityPeriods: ActivityPeriod[],
  virsStartDate: string,
  dateType: DateTypes
) => {
  switch (dateType) {
    case 'year':
      activityPeriods = activityPeriods.map((period) => ({
        startDate: formatDateString(period.startDate, 'year'),
        endDate: period.endDate !== null ? formatDateString(period.endDate, 'year') : null
      }));
      break;
    case 'month':
      activityPeriods = activityPeriods.map((period) => ({
        startDate: formatDateString(period.startDate, 'month'),
        endDate: period.endDate !== null ? formatDateString(period.endDate, 'month') : null
      }));
      break;
  }

  const currentActivityPeriod = getCurrentPeriod(selectedDate, activityPeriods);
  const [firstActivityPeriod] = activityPeriods;

  const startDate: string | null =
    firstActivityPeriod &&
    currentActivityPeriod &&
    currentActivityPeriod?.startDate !== firstActivityPeriod?.startDate
      ? currentActivityPeriod?.startDate
      : currentActivityPeriod &&
        currentActivityPeriod?.startDate !== null &&
        virsStartDate < currentActivityPeriod?.startDate
      ? virsStartDate
      : currentActivityPeriod?.startDate;

  return { startDate, currentActivityPeriod };
};

export const getLastSignedDate = (
  shareholdersProps?: ShareholdersProps[],
  activityPeriods?: ActivityPeriod[]
): Moment | undefined => {
  if (!shareholdersProps || !activityPeriods) {
    return undefined;
  }
  const allEvents: ShareholdersHistory[] = getAllEvents(shareholdersProps);
  const [lastSignedEvent] = allEvents
    .filter((event) => !isDateUnsigned(shareholdersProps, activityPeriods, event.eventDate))
    .map((event) => moment(event.eventDate))
    .sort((a, b) => (a.isSameOrBefore(b) ? 1 : -1));
  return lastSignedEvent;
};
