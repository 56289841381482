// Bendros funkcijos naudojamos juostelių piešimui ir spalvinimui

import theme from '../../../style/virsisTheme';
import { mainDarkColor } from '../constants';

export const getFillColor = (percentage: number, isLegalPerson: boolean) => {
  const alpha = percentage / 100;
  if (isLegalPerson) {
    return `rgba(120, 50, 133, ${alpha})`; // purple
  }

  return `rgba(44, 120, 55, ${alpha})`; // green
};

export const getColor = (
  showShares: boolean,
  sharePercentage: number | null,
  showVotes: boolean,
  votesPercentage: number | null,
  isLegalPerson: boolean,
  votesOnRules: boolean | null,
  eventDate?: string,
  selectedDate?: string
) => {
  if (eventDate && selectedDate && eventDate > selectedDate) {
    return '#E5E5E5';
  }
  if (showShares && sharePercentage) {
    return getFillColor(sharePercentage, isLegalPerson);
  }
  if (showVotes && votesOnRules) {
    return getFillColor(50, isLegalPerson);
  }
  if (showVotes && votesPercentage) {
    return getFillColor(votesPercentage, isLegalPerson);
  }
  return '#E5E5E5';
};

export const getBorderColor = (
  showShares: boolean,
  sharePercentage: number | null,
  showVotes: boolean,
  votesPercentage: number | null,
  isLegalPerson: boolean,
  votesOnRules: boolean | null,
  eventDate?: string,
  selectedDate?: string
) => {
  if (eventDate && selectedDate && eventDate > selectedDate) {
    return '#707070';
  }
  if (showShares && sharePercentage) {
    return getFillColor(100, isLegalPerson);
  }
  if (showVotes && (votesPercentage || votesOnRules)) {
    return getFillColor(100, isLegalPerson);
  }
  return '#707070';
};

export const getBorder = (borderColor?: string) => {
  return `1px solid ${borderColor}`;
};

export const getTextColor = (
  showShares: boolean,
  showVotes: boolean,
  sharePercentage: number | null,
  votesPercentage: number | null
) => {
  if (
    (showShares && sharePercentage && sharePercentage > 50) ||
    (showVotes && votesPercentage && votesPercentage > 50)
  ) {
    return theme.palette.secondary.light;
  }
  return mainDarkColor;
};

export const getMarginLeft = (level: number) => {
  if (level >= 1 && level <= 10) {
    return level * 30;
  }
  if (level > 10) {
    return 300;
  }
  return 15;
};
