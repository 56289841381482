import React from 'react';
import { useSelector } from 'react-redux';
import { CustomRadioButton } from '../../../../../../../../components/TableButtons/CustomRadioButton';
import { TooltipOnTableActionButton } from '../../../../../../../../components/Tooltips/TooltipOnTableActionButton';
import { ConditionalWrapper } from '../../../../../../../../components/ConditionalWrapper/ConditionalWrapper';
import { radioButtonTooltipText, RadioButtonValues } from './formTypes';
import { ApplicationState } from '../../../../../../../../store';

interface Props {
  radioButton: RadioButtonValues;
  radioGroupValue: string | number | null;
  siblingCount: number;
}

export const FormRadioButtonWithTooltip: React.FC<Props> = ({
  radioButton,
  radioGroupValue,
  siblingCount
}) => {
  const {
    shareholdersData: { shareholderToEditId }
  } = useSelector((state: ApplicationState) => state);

  const showTooltip =
    radioButton.value !== radioGroupValue &&
    !radioButton.disabled &&
    (shareholderToEditId ? siblingCount > 1 : siblingCount >= 1);

  return (
    <ConditionalWrapper
      condition={showTooltip}
      wrapper={(children) => (
        <TooltipOnTableActionButton text={radioButtonTooltipText} placement="right">
          <span>{children}</span>
        </TooltipOnTableActionButton>
      )}
    >
      <CustomRadioButton
        label={radioButton.label}
        value={radioButton.value}
        disabled={radioButton.disabled}
      />
    </ConditionalWrapper>
  );
};
