import React from 'react';
import { Typography, Button, Menu, MenuItem } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import virsisTheme from '../../style/virsisTheme';
import { FileFormats } from '../../utils/exporters/types';

interface Props {
  onClick?: (file: FileFormats) => void;
  additionalStyles?: React.CSSProperties;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    margin: {
      margin: '15px 0px',
      marginLeft: '5px'
    },
    button: {
      padding: 0,
      borderColor: 'transparent',
      backgroundColor: virsisTheme.palette.secondary.light,
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      color: '#637082',
      [theme.breakpoints.down('xs')]: {
        // flex: 1,
        justifyContent: 'flex-start',
        marginTop: 15,
        wordWrap: 'break-word',
        hyphens: 'auto',
        display: 'flex'
      }
    },
    themeButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.primary.main
      },
      '&:active': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: '#DBEDFC',
        color: virsisTheme.palette.primary.main
      }
    },
    icon: {
      height: 30,
      padding: '5px 10px',
      borderRight: '1px solid #C4E2FC',
      color: 'inherit'
    },
    text: {
      color: '#637082',
      padding: '1px 15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 0,
        display: 'none',
      }
    },
  })
);

const DownloadButton: React.FC<Props> = ({ onClick, additionalStyles }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionButtonToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const computedStyles = anchorEl
    ? {
        backgroundColor: '#DBEDFC',
        borderColor: virsisTheme.palette.primary.main,
        ...additionalStyles
      }
    : { ...additionalStyles };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleActionButtonToggle}
        className={`${classes.button} ${classes.themeButton} ${classes.margin}`}
        style={computedStyles}
      >
        <DownloadIcon fontSize="small" className={classes.icon} />

        <Typography variant="h5" className={classes.text}>
          Atsisiųsti
        </Typography>
      </Button>

      <Menu
        id="download-action-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          style={{ width: 134 }}
          onClick={() => {
            if (onClick) {
              onClick(FileFormats.XLSX);
            }
          }}
        >
          .xlsx
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (onClick) {
              onClick(FileFormats.HTML);
            }
          }}
        >
          .html
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (onClick) {
              onClick(FileFormats.PDF);
            }
          }}
        >
          .pdf
        </MenuItem>
      </Menu>
    </>
  );
};

DownloadButton.defaultProps = {
  additionalStyles: undefined,
  onClick: undefined
};

export default DownloadButton;
