import { getContext } from 'redux-saga/effects';

export type BasicFormState<TInput> = {
  open: boolean;
  error: boolean;
  initialValues?: TInput;
  isLoading: boolean;
};

export type OptionedFormState<TInput, TFormOptions> = BasicFormState<TInput> & {
  options: TFormOptions;
};

export interface FormSagaContext {
  formSubmitSnackbar: (success: boolean) => void;
}

export function getFormSagaContext(key: keyof FormSagaContext) {
  return getContext(key);
}
