import { GraphicalRepresentationToolsTypes } from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';

export interface GraphicalPerceptionTableState {
  activeTab: GraphicalRepresentationToolsTypes;
  isActive: boolean;
}

export const initialGraphicalPerceptionTableState: GraphicalPerceptionTableState = {
  activeTab: 'VIRS',
  isActive: false
};

export type GraphicalPerceptionTableActions =
  | { type: 'SET_ACTIVE_FILTER'; isActive: boolean }
  | { type: 'SET_TYPE'; recordType: GraphicalRepresentationToolsTypes };
