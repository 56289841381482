import {
  ProvidedDataHistoryTableAction,
  ProvidedDataHistoryTableState,
  initialProvidedDataHistoryTableState
} from './tableInitialStateAndTypes';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import { updateProvidedDataHistoryCustomFilter } from './tableStateFunctions';
import { getTableExportDefinition } from '../../ProvidedDataTableInstLegal/tableState/tableStateFunctions';
import { providedDataInstColumns } from '../../ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';

export const providedDataHistoryTableReducer = (
  state: ProvidedDataHistoryTableState,
  action: ProvidedDataHistoryTableAction
): ProvidedDataHistoryTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return {
        ...state,
        statusNotSignedFilterEnabled: action.notSignedEnabled,
        statusSignedFilterEnabled: action.signedEnabled,
        statusAnnuledFilterEnabled: action.annuledEnabled,
        showStatusNotSigned: action.notSignedEnabled,
        showStatusSigned: action.signedEnabled,
        showStatusAnnuled: false
      };
    case 'SHOW_STATUS_UNSIGNED_TOGGLED':
      return {
        ...state,
        showStatusNotSigned: !state.showStatusNotSigned,
        page: 0
      };
    case 'SHOW_STATUS_SIGNED_TOGGLED':
      return {
        ...state,
        showStatusSigned: !state.showStatusSigned,
        page: 0
      };
    case 'SHOW_STATUS_ANNULED_TOGGLED':
      return {
        ...state,
        showStatusAnnuled: !state.showStatusAnnuled,
        page: 0
      };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialProvidedDataHistoryTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateProvidedDataHistoryCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'PERIOD_FILTER_VALUE_CHANGED':
      return {
        ...state,
        periodFilter: action.value ? action.value : state.periodFilter,
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(
          providedDataInstColumns,
          columnsDisplayStatus
        )
      };
    }
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'DOCUMENT_DIALOG_OPENED':
      return {
        ...state,
        showDocumentDialog: true
      };
    case 'DOCUMENT_DIALOG_CLOSED':
      return {
        ...state,
        showDocumentDialog: false
      };
    default:
      return state;
  }
};
