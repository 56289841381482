import {
  ProvidedDataRecordState,
  ProvidedDataRowAction
} from './providedDataRecordInitialStateAndTypes';

export const providedDataRowReducer = (
  state: ProvidedDataRecordState,
  action: ProvidedDataRowAction
): ProvidedDataRecordState => {
  switch (action.type) {
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'PREVIEW_RECORD':
      return {
        ...state,
        showPreviewDialog: action.showPreviewDialog,
        selectedRecord: action.selectedRecord
      };
    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },
        annulRecordConfirmationOn: true,
        selectedRecord: action.selectedRecord
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        selectedRecord: undefined,
        annulRecordConfirmationOn: false
      };

    default:
      return state;
  }
};
