import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import virsisTheme from '../../style/virsisTheme';
import { TooltipOnTableActionButton } from '../Tooltips/TooltipOnTableActionButton';
import { ErrorType } from '../../store/virsis/dataTypes';

interface Props {
  outlined?: boolean;
  errorType: ErrorType;
  text: string | ReactElement;
  large?: boolean;
  noWrap?: boolean;
}

const useStyles = makeStyles({
  errorColor: {
    color: `${virsisTheme.palette.error.main}`
  },
  warningColor: {
    color: `${virsisTheme.palette.warning.main}`
  },
  infoColor: {
    color: `${virsisTheme.palette.info.main}`
  },
  icon: {
    verticalAlign: 'middle',
    margin: '5px'
  }
});

export const ErrorIconWithTooltip: React.FC<Props> = ({
  text,
  outlined,
  errorType,
  large,
  noWrap
}) => {
  const classes = useStyles();

  let colorClass = classes.infoColor;

  switch (errorType) {
    case ErrorType.CRITICAL:
      colorClass = classes.errorColor;
      break;
    case ErrorType.NONCRITICAL:
      colorClass = classes.warningColor;
      break;
    case ErrorType.INFO:
      colorClass = classes.infoColor;
      break;
  }

  return (
    <TooltipOnTableActionButton errorType={errorType} text={text} noWrap={noWrap}>
      {outlined ? (
        <ErrorOutlineIcon
          className={`${classes.icon} ${colorClass}`}
          fontSize={large ? 'large' : 'default'}
        />
      ) : (
        <ErrorIcon
          className={`${classes.icon} ${colorClass}`}
          fontSize={large ? 'large' : 'default'}
        />
      )}
    </TooltipOnTableActionButton>
  );
};
