import { action } from 'typesafe-actions';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import {
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS,
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DOCUMENT_PDF_REQUEST,
  CANCEL_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED,
  CONTINUE_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED,
  CREATE_ETHICAL_NON_COMPLIANCE_ERROR,
  CREATE_ETHICAL_NON_COMPLIANCE_REQUEST,
  CREATE_ETHICAL_NON_COMPLIANCE_SUCCESS,
  REMOVE_ETHICAL_NON_COMPLIANCE_ERROR,
  REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST,
  REMOVE_ETHICAL_NON_COMPLIANCE_SUCCESS,
  RESET_CREATE_ETHICAL_NON_COMPLIANCE_STATE,
  RESET_REMOVE_ETHICAL_NON_COMPLIANCE_STATE,
  RESET_UPDATE_ETHICAL_NON_COMPLIANCE_STATE,
  UPDATE_ETHICAL_NON_COMPLIANCE_ERROR,
  UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST,
  UPDATE_ETHICAL_NON_COMPLIANCE_SUCCESS,
  ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST,
  ANNUL_ETHICAL_NON_COMPLIANCE_SUCCESS,
  ANNUL_ETHICAL_NON_COMPLIANCE_ERROR,
  RESET_ANNUL_ETHICAL_NON_COMPLIANCE_STATE,
  FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID
} from './ethicalNonCompliancesActionTypes';
import {
  EthicalNonComplianceRecord,
  VirsEthicalNonComplianceData
} from './ethicalNonCompliancesTypes';

export const fetchEthicalNonComplianceData = () =>
  action(FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST);
export const fetchEthicalNonComplianceDataSuccess = (data: string) =>
  action(FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS, data);
export const fetchEthicalNonComplianceDataError = (error: Error) =>
  action(FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR, error);

export const fetchVirsEthicalNonComplianceData = (virsId: number) =>
  action(FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST, virsId);
export const fetchVirsEthicalNonComplianceDataSuccess = (data: VirsEthicalNonComplianceData[]) =>
  action(FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS, data);
export const fetchVirsEthicalNonComplianceDataError = (error: Error) =>
  action(FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR, error);

export const getVirsVirsEthicalNonComplianceDocumentInNewTabRequest = (
  documentId: number,
  documentNumber: string
) =>
  action(FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DOCUMENT_PDF_REQUEST, {
    documentId,
    documentNumber
  });

export const createEthicalNonCompliance = (newEthicalNonCompliance: EthicalNonComplianceRecord) =>
  action(CREATE_ETHICAL_NON_COMPLIANCE_REQUEST, {
    newEthicalNonCompliance
  });

export const createEthicalNonComplianceSuccess = () =>
  action(CREATE_ETHICAL_NON_COMPLIANCE_SUCCESS);

export const createEthicalNonComplianceError = (error: Error) =>
  action(CREATE_ETHICAL_NON_COMPLIANCE_ERROR, error);

export const updateEthicalNonCompliance = (
  updatedEthicalNonCompliance: EthicalNonComplianceRecord,
  id: number | undefined
) =>
  action(UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST, {
    updatedEthicalNonCompliance,
    id
  });

export const updateEthicalNonComplianceSuccess = () =>
  action(UPDATE_ETHICAL_NON_COMPLIANCE_SUCCESS);

export const updateEthicalNonComplianceError = (error: Error) =>
  action(UPDATE_ETHICAL_NON_COMPLIANCE_ERROR, error);

export const hideAddEthicalNonCompliancekRow = () =>
  action(CANCEL_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED);

export const showAddEthicalNonComplianceRow = () =>
  action(CONTINUE_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED);

export const resetEthicalNonComplianceCreatingState = () =>
  action(RESET_CREATE_ETHICAL_NON_COMPLIANCE_STATE);

export const resetEthicalNonComplianceUpdatingState = () =>
  action(RESET_UPDATE_ETHICAL_NON_COMPLIANCE_STATE);

export const resetEthicalNonComplianceRemovingState = () =>
  action(RESET_REMOVE_ETHICAL_NON_COMPLIANCE_STATE);

export const removeEthicalNonCompliance = (ethicalNonComplianceId: number) =>
  action(REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST, {
    ethicalNonComplianceId
  });

export const removeEthicalNonComplianceSuccess = () =>
  action(REMOVE_ETHICAL_NON_COMPLIANCE_SUCCESS);

export const removeEthicalNonComplianceError = (error: Error) =>
  action(REMOVE_ETHICAL_NON_COMPLIANCE_ERROR, error);

export const annulEthicalNonCompliance = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST, {
    documentStatusId,
    annulRecord
  });

export const annulEthicalNonComplianceSuccess = () => action(ANNUL_ETHICAL_NON_COMPLIANCE_SUCCESS);

export const annulEthicalNonComplianceError = (error: Error) =>
  action(ANNUL_ETHICAL_NON_COMPLIANCE_ERROR, error);

export const resetAnnulEthicalNonComplianceState = () =>
  action(RESET_ANNUL_ETHICAL_NON_COMPLIANCE_STATE);

export const fetchEthicalNonComplianceByDocId = (docId: number) =>
  action(FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID, docId);
