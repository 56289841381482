import React from 'react';
import { TableCell, TableRow, TableSortLabel, Typography } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  ProvidedColumnsDisplay,
  ProvidedDataTableColumn,
  ProvidedDataTableField
} from './tableState/tableInitialStateAndTypes';
import { SortOrder } from '../../utils/tableTypes';

interface TableHeadersProps {
  columnParams: ProvidedDataTableColumn[];
  order: SortOrder;
  sortBy: ProvidedDataTableField;
  columnsDisplayStatus: ProvidedColumnsDisplay;
  setSorting: (column: ProvidedDataTableField) => void;
}

export const ProvidedDataTableHeaders: React.FC<TableHeadersProps> = ({
  columnParams,
  columnsDisplayStatus,
  order,
  sortBy,
  setSorting
}) => {
  const createSortHandler = (column: ProvidedDataTableField) => () => {
    setSorting(column);
  };

  return (
    <TableRow>
      {columnParams
        .filter((header) => columnsDisplayStatus[header.id])
        .map((header) => (
          <TableCell
            key={header.id}
            sortDirection={sortBy === header.id ? order : false}
            align="left"
            variant="head"
            className="TableCellSortable"
            onClick={createSortHandler(header.id)}
          >
            <TableSortLabel
              active
              direction={sortBy === header.id ? order : 'asc'}
              IconComponent={sortBy === header.id ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">{header.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      <TableCell key="dataProvider" align="left" variant="head">
        <Typography variant="h4">El. dokumentas</Typography>
      </TableCell>
      <TableCell key="actions" align="right" variant="head">
        <Typography variant="h4">Veiksmai</Typography>
      </TableCell>
    </TableRow>
  );
};
