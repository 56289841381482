import React, { ReactNode } from 'react';
import { TableCell, makeStyles, TableRow } from '@material-ui/core/';
import { EthicalNonComplianceColumnsDisplayStatus } from './tableState/tableInitialStateAndTypes';
import { WordStatus } from '../../utils/tableTypes';
import { ExpandDetailsButton } from '../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { ReportInstitutionDataDialog } from '../../components/ReportInstitutionDataDialog/ReportInstitutionDataDialog';
import { ReportErrorAction } from '../../components/TableRowActions/ReportErrorAction';
import { PreviewDocumentDialogMinimal } from '../document/PreviewDocumentDialogMinimal';
import useInstitutionTableRow from '../../utils/hooks/institutionTableRowHook';
import { TableRowExtension } from '../../components/institutionProvidedDataTable/TableRowExtension';
import { Roles } from '../../store/virsis/dataTypes';

interface Props {
  columnsToDisplay: EthicalNonComplianceColumnsDisplayStatus;
  decisionDate?: string;
  documentNumber?: string;
  outletList?: ReactNode;
  decisionStatus?: string;
  validFrom?: string;
  validTo?: string;
  reportingInstitution: string;
  elDocument?: ReactNode;
  signatureDate: string;
  signingPersonName: string;
  documentStatus: WordStatus;
  documentStatusId: number;
}

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

export const EthicalNonComplianceBasicRow: React.FC<Props> = ({
  columnsToDisplay,
  outletList,
  decisionDate,
  documentNumber,
  decisionStatus,
  validFrom,
  validTo,
  reportingInstitution,
  signatureDate,
  signingPersonName,
  documentStatus,
  documentStatusId
}) => {
  const classes = useStyles();

  const {
    rowState,
    toggleExtension,
    closeDocumentPreview,
    openDocumentPreview,
    closeReportDialog,
    openReportDialog
  } = useInstitutionTableRow();

  return (
    <>
      {rowState.documentStatusId && rowState.showReport && (
        <ReportInstitutionDataDialog
          documentStatusId={rowState.documentStatusId}
          close={closeReportDialog}
          openDocumentPreview={() => openDocumentPreview(documentStatusId)}
          isOpen
        />
      )}
      {rowState.documentStatusId && rowState.showPreview && (
        <PreviewDocumentDialogMinimal
          maxWidth="lg"
          open={true}
          onClose={closeDocumentPreview}
          documentPath={`virs/legals/documents/${rowState.documentStatusId}`}
        />
      )}
      <TableRow>
        {columnsToDisplay.decisionDate && (
          <TableCell className={classes.dateColumn}>
            <ExpandDetailsButton showExtension={rowState.showExtension} onClick={toggleExtension} />
            {decisionDate}
          </TableCell>
        )}

        {columnsToDisplay.documentNumber && <TableCell>{documentNumber}</TableCell>}

        {columnsToDisplay.outletList && <TableCell>{outletList}</TableCell>}

        {columnsToDisplay.decisionStatus && <TableCell>{decisionStatus}</TableCell>}

        {columnsToDisplay.validFrom && (
          <TableCell className={classes.dateColumn}>{validFrom}</TableCell>
        )}

        {columnsToDisplay.validTo && (
          <TableCell className={classes.dateColumn}>{validTo}</TableCell>
        )}

        {columnsToDisplay.reportingInstitution && <TableCell>{reportingInstitution}</TableCell>}

        <TableCell align="right">
          {documentStatusId && <ReportErrorAction onClicked={openReportDialog(documentStatusId)} />}
        </TableCell>
      </TableRow>

      {rowState.showExtension && (
        <TableRowExtension
          creationDate={decisionDate}
          documentId={documentStatusId}
          documentStatus={documentStatus}
          openDocumentPreview={openDocumentPreview}
          signDate={signatureDate}
          signedByPerson={signingPersonName}
          authorizedRolesToViewDocumentRow={[
            Roles.ROLE_VIEA_VIEW,
            Roles.ROLE_VIEA_EDIT,
            Roles.ROLE_VIRS
          ]}
        />
      )}
    </>
  );
};
