import { Reducer } from 'redux';
import {
  INITIALIZE_TABLE_STATE,
  SHOW_STATUS_UNSIGNED_TOGGLED,
  SHOW_STATUS_SIGNED_TOGGLED,
  SHOW_STATUS_ANNULLED_TOGGLED,
  CUSTOM_FILTER_VALUE_CHANGED,
  PERIOD_FILTER_VALUE_CHANGED,
  SORTING_CHANGED,
  PAGE_SET,
  ROWS_PER_PAGE_SET,
  UPDATE_TEMP_DATA_STATE,
  CUSTOM_FILTER_RESET
} from './providedDataTableActionTypes';
import { initialProvidedDataHistoryTableState } from '../../containers/ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';
import { updateProvidedDataHistoryCustomFilter } from '../../containers/ProvidedDataTableInstLegal/tableState/tableStateFunctions';
import { getToggledSortOrderGeneric } from '../../utils/tableDataFunctions';
import { ProvidedDataHistoryDataState } from './providedDataTableTypes';

export interface ProvidedDataTableState {
  tableDataState: ProvidedDataHistoryDataState;
  tempDataTableState?: ProvidedDataHistoryDataState;
}

export const initialState: ProvidedDataTableState = {
  tableDataState: initialProvidedDataHistoryTableState,
  tempDataTableState: undefined
};

export const providedDataTableReducer: Reducer<ProvidedDataTableState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INITIALIZE_TABLE_STATE:
      return {
        ...state,
        tableDataState: {
          ...initialProvidedDataHistoryTableState,
          statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
          statusSignedFilterEnabled: action.payload.statusSigneFilterEnabled,
          statusAnnuledFilterEnabled: action.payload.statusAnnuledFilterEnabled,
          showStatusNotSigned: action.payload.showStatusNotSigned,
          showStatusSigned: action.payload.showStatusSigned,
          showStatusAnnuled: action.payload.showStatusAnnuled,
          periodFilter: action.payload.periodFilter
        }
      };
    case SHOW_STATUS_UNSIGNED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusNotSigned: !state.tableDataState.showStatusNotSigned,
          page: 0
        }
      };
    case SHOW_STATUS_SIGNED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusSigned: !state.tableDataState.showStatusSigned,
          page: 0
        }
      };
    case SHOW_STATUS_ANNULLED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusAnnuled: !state.tableDataState.showStatusAnnuled,
          page: 0
        }
      };
    case CUSTOM_FILTER_VALUE_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          customFilter: updateProvidedDataHistoryCustomFilter(
            state.tableDataState.customFilter,
            action.payload.filterBy,
            action.payload.value
          ),
          page: 0
        }
      };
    case PERIOD_FILTER_VALUE_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          periodFilter: action.payload ? action.payload : state.tableDataState.periodFilter,
          page: 0
        }
      };
    case SORTING_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          sortBy:
            action.payload === state.tableDataState.sortBy
              ? state.tableDataState.sortBy
              : action.payload,
          order: getToggledSortOrderGeneric(
            action.payload,
            state.tableDataState.sortBy,
            state.tableDataState.order
          )
        }
      };
    case PAGE_SET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          page: action.payload
        }
      };
    case ROWS_PER_PAGE_SET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          rowsPerPage: action.payload
        }
      };
    case UPDATE_TEMP_DATA_STATE:
      return {
        ...state,
        tempDataTableState: action.payload
      };
    case CUSTOM_FILTER_RESET: {
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          customFilter: initialProvidedDataHistoryTableState.customFilter
        }
      };
    }
    default: {
      return state;
    }
  }
};
