import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Container, Box, Typography, Divider, CircularProgress } from '@material-ui/core';
import { EditionCheckDataTableWithContext } from '../../containers/EditionCheckTable/Context';
import { PaymentDataVirsTableWithContext } from '../../containers/PaymentTable/Context';
import { EthicalNonComplianceTableWithContext } from '../../containers/EthicalNonComplianceTable/Context';
import { FundsReceivedDataTableWithContext } from '../../containers/FundsReceivedTable/Context';
import { ProfessionalMisconductTableWithContext } from '../../containers/ProfessionalMisconductTable/Context';

import { ApplicationState } from '../../store';
import PageFilterButton from '../../components/PageButtons/PageFilterButton';
import { fetchEditionCheckDataVirs } from '../../store/editionChecks/editionChecksActions';
import { fetchVirsEthicalNonComplianceData } from '../../store/ethicalNonCompliances/ethicalNonCompliancesActions';
import { fetchFundsReceivedDataVirs } from '../../store/fundsReceived/fundsReceivedActions';
import { getVirsPaymentDataRequest } from '../../store/payments/paymentsActions';
import { getProfessionalMisconductVirsDataRequest } from '../../store/professionalMisconducts/professionalMisconductsActions';
import { fetchFictitiousOutletRequest } from '../../store/classifiers/classifiersActions';
import { useParams } from 'react-router-dom';

const InstitutionProvidedDataPage: React.FC<RouteComponentProps> = () => {
  const { displayedTable } = useParams<{ displayedTable: string }>();

  const {
    editionData: { editionCheckDataVirsRecordCount, loadingEditionCheckDataVirs },
    ethicalNonComplianceData: {
      virsEthicalNonComplianceDataRecordCount,
      loadingEthicalNonComplianceData
    },
    fundsReceivedData: { fundsReceivedDataVirsRecordCount, loadingFundsReceivedDataVirs },
    paymentData: { virsPaymentDataRecordCount, virsLoadingPaymentData },
    professionalMisconductData: {
      professionalMisconductRecordCount,
      loadingProfessionalMisconductVirsData
    },
    virsis: { virsData },
    classifiers: { fictitiousOutlet }
  } = useSelector((state: ApplicationState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (virsData) {
      dispatch(getProfessionalMisconductVirsDataRequest(virsData.virsId));
      dispatch(fetchVirsEthicalNonComplianceData(virsData.virsId));
      dispatch(fetchFundsReceivedDataVirs(virsData.virsId));
      dispatch(fetchEditionCheckDataVirs(virsData.virsId));
      dispatch(getVirsPaymentDataRequest(virsData.virsId));
    }
  }, [dispatch, virsData]);

  const loadingInstitutionProvidedData =
    loadingEditionCheckDataVirs ||
    loadingEthicalNonComplianceData ||
    loadingFundsReceivedDataVirs ||
    virsLoadingPaymentData ||
    loadingProfessionalMisconductVirsData;

  interface PageDataFiltersProps {
    value: string;
    label: string;
    recordCount?: number;
  }

  const institutionProvidedDataFilters: PageDataFiltersProps[] = [
    {
      value: 'all',
      label: 'Visi'
    },
    {
      value: 'professionalMisconduct',
      label: 'VIĮ pažeidimai',
      recordCount: professionalMisconductRecordCount
    },
    {
      value: 'ethicalNonCompliance',
      label: 'Profesinės etikos nesilaikymas',
      recordCount: virsEthicalNonComplianceDataRecordCount
    },
    {
      value: 'fundsReceived',
      label: 'VIRS gautos lėšos',
      recordCount: fundsReceivedDataVirsRecordCount
    },
    {
      value: 'editionCheck',
      label: 'Tiražo patikrinimas',
      recordCount: editionCheckDataVirsRecordCount
    },
    {
      value: 'payment',
      label: 'Metinė įmoka',
      recordCount: virsPaymentDataRecordCount
    }
  ];

  const [activeFilterValue, setActiveFilterValue] = useState<string>('all');
  const [tableDisplayStatus, setTableDisplayStatus] = useState({
    professionalMisconduct: true,
    ethicalNonCompliance: true,
    fundsReceived: true,
    editionCheck: true,
    payment: true
  });

  const handleDisplayFilteredTables = (filterName: string) => {
    setActiveFilterValue(filterName);
    setTableDisplayStatus({
      professionalMisconduct: filterName === 'all',
      ethicalNonCompliance: filterName === 'all',
      fundsReceived: filterName === 'all',
      editionCheck: filterName === 'all',
      payment: filterName === 'all',
      [filterName]: true
    });
  };

  useEffect(() => {
    switch (displayedTable) {
      case 'visi':
        handleDisplayFilteredTables('all');
        break;
      case 'pazeidimai':
        handleDisplayFilteredTables('professionalMisconduct');
        break;
      case 'etikos-nesilaikymas':
        handleDisplayFilteredTables('ethicalNonCompliance');
        break;
      case 'gautos-lesos':
        handleDisplayFilteredTables('fundsReceived');
        break;
      case 'tirazo-patikrinimas':
        handleDisplayFilteredTables('editionCheck');
        break;
      case 'metine-imoka':
        handleDisplayFilteredTables('payment');
        break;
      default:
        handleDisplayFilteredTables('all');
        break;
    }
  }, [displayedTable]);

  useEffect(() => {
    if (!fictitiousOutlet) {
      dispatch(fetchFictitiousOutletRequest());
    }
  }, [dispatch, fictitiousOutlet]);

  return (
    <Container maxWidth="lg">
      <div className="data-page">
        <div className="data-page-header">
          <Box display="flex">
            <Typography variant="h1">Institucijų / įstaigų pateikti duomenys</Typography>
          </Box>
        </div>
        <Divider light />
        {loadingInstitutionProvidedData ? (
          <CircularProgress />
        ) : (
          <>
            <div className="data-page-filters">
              <Box>
                {institutionProvidedDataFilters.map((filter) => (
                  <PageFilterButton
                    key={filter.value}
                    active={filter.value === activeFilterValue}
                    onClick={() => handleDisplayFilteredTables(filter.value)}
                    label={
                      filter.value === 'all'
                        ? `${filter.label}`
                        : `${filter.label} (${filter.recordCount})`
                    }
                  />
                ))}
              </Box>
            </div>
            <Divider light />
            <div className="data-page-content">
              {tableDisplayStatus.professionalMisconduct && (
                <ProfessionalMisconductTableWithContext />
              )}
              {tableDisplayStatus.ethicalNonCompliance && <EthicalNonComplianceTableWithContext />}
              {tableDisplayStatus.fundsReceived && <FundsReceivedDataTableWithContext />}
              {tableDisplayStatus.editionCheck && <EditionCheckDataTableWithContext />}
              {tableDisplayStatus.payment && <PaymentDataVirsTableWithContext />}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default withRouter(InstitutionProvidedDataPage);
