import React from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { RowActionButtonGeneric } from '../TableButtons/RowActionButtons/RowActionButtonGeneric';
import { Strings } from '../../utils/strings/Strings';

interface Props {
  handleConfirmationYes: () => void;
  isProcessing: boolean;
  handleCancelButtonClick: () => void;
  saveButtonLabel?: string;
  cancelButtonLabel?: string;
}

export const SaveAndCancelActions: React.FC<Props> = ({
  handleConfirmationYes,
  isProcessing,
  handleCancelButtonClick,
  saveButtonLabel,
  cancelButtonLabel
}) => {
  return (
    <Box>
      <RowActionButtonGeneric
        label={saveButtonLabel || 'Išsaugoti'}
        onButtonClicked={handleConfirmationYes}
        icon={
          isProcessing ? (
            <CircularProgress style={{ width: '20px', height: '20px' }} color="secondary" />
          ) : (
            <DoneIcon style={{ color: '#03B079' }} />
          )
        }
      />
      <RowActionButtonGeneric
        label={cancelButtonLabel || Strings.button__cancel}
        onButtonClicked={handleCancelButtonClick}
        icon={<CloseIcon color="error" />}
      />
    </Box>
  );
};
