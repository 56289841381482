import React, { ReactNode } from 'react';
import { Button, Typography } from '@material-ui/core';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../../style/virsisTheme';

const useStyles = makeStyles({
  reportErrorButton: {
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: 5,
    color: '#637082',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    }
  },
  textColor: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit'
    }
  }
});

interface ButtonProps {
  id?: string;
  label: string;
  onButtonClicked: (event: React.MouseEvent) => void;
  icon?: ReactNode;
}

export const ReportErrorButton: React.FC<ButtonProps> = ({ id, label, onButtonClicked }) => {
  const classes = useStyles();

  return (
    <Button
      variant="text"
      id={id}
      className={classes.reportErrorButton}
      onClick={onButtonClicked}
      startIcon={<BugReportOutlinedIcon style={{ color: 'inherit' }} />}
    >
      <Typography variant="h5" className={classes.textColor}>
        {label}
      </Typography>
    </Button>
  );
};
