import React from 'react';

import { Box, Divider, makeStyles } from '@material-ui/core';
import { ShareholdersTableFilterFields } from '../../MainTable/tableState/tableInitialStateAndTypes';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../../MainTable/ShareholdersTableWithContext';
import DateFilterComponent from '../../TableFilters/FilterTool/DateFilterComponents/DateInputContainer';
import LevelFilter from '../../TableFilters/FilterTool/LevelFilter';
import { BasicTooltip } from '../../../../components/Tooltips/BasicTooltip';
import { dateCalendarFormat } from '../../constants';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    flexDirection: 'column'
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: 10,
    width: '100%'
  }
}));

interface Props {
  allEventsDates?: string[];
}

const MainTableFilterItems: React.FC<Props> = ({ allEventsDates }) => {
  const classes = useStyles();
  const { dispatch: mainTableDispatch } = useShareholdersTableDispatch();
  const {
    state: {
      calendarDisplayProps,
      shareholdersLevelList,
      defaultFilterLevelValue,
      customFilter: { shareholderPeriodDateFrom, shareholderPeriodDateTo, shareholderEventDate }
    }
  } = useShareholdersTableState();

  const [filteredEventDate] = shareholderEventDate;
  const [periodFilterFromDate] = shareholderPeriodDateFrom;
  const [periodFilterToDate] = shareholderPeriodDateTo;

  const disableEventDateFilterOption = !!(periodFilterFromDate || periodFilterToDate);
  const disablePeriodFilterOption = !!filteredEventDate;

  const setCustomFilter = (column: ShareholdersTableFilterFields, value: string | null) => {
    mainTableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };

  const setLevel = (selectedLevel: string) => {
    setCustomFilter('shareholderLevel', selectedLevel);
    mainTableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: false });
  };

  const dateTooltipText = disableEventDateFilterOption
    ? 'Prašome išvalyti filtrus "Periodas nuo" ir "iki"'
    : '';
  const periodTooltipText = disablePeriodFilterOption ? 'Prašome išvalyti filtrą "Data"' : '';
  const errorMessage = 'Šiame laikotarpyje pasikeitimų datų nėra';

  return (
    <>
      <Box className={classes.menuItem}>
        <LevelFilter
          shareholdersLevelList={shareholdersLevelList}
          onLevelChange={setLevel}
          defaultValue={defaultFilterLevelValue}
        />
      </Box>
      <Divider light />
      <BasicTooltip text={dateTooltipText}>
        <div>
          <Box className={classes.menuItem}>
            <DateFilterComponent
              filterName="shareholderEventDate"
              text="Data"
              disabled={disableEventDateFilterOption}
              setFilter={setCustomFilter}
              enabledDays={allEventsDates}
              calendarDisplayProps={dateCalendarFormat}
            />
          </Box>
        </div>
      </BasicTooltip>
      <Divider light />
      <BasicTooltip text={periodTooltipText}>
        <div>
          <Box className={classes.menuItem}>
            <DateFilterComponent
              filterName="shareholderPeriodDateFrom"
              text="Periodas nuo"
              disabled={disablePeriodFilterOption}
              setFilter={setCustomFilter}
              marginBottom
              calendarDisplayProps={calendarDisplayProps}
            />
            <DateFilterComponent
              filterName="shareholderPeriodDateTo"
              text="iki"
              disabled={disablePeriodFilterOption}
              setFilter={setCustomFilter}
              calendarDisplayProps={calendarDisplayProps}
            />
            {allEventsDates?.length === 0 && (
              <Box className={classes.errorText}>{errorMessage}</Box>
            )}
          </Box>
        </div>
      </BasicTooltip>
    </>
  );
};

export default MainTableFilterItems;
