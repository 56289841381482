import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCell } from '@material-ui/core';
import { PaymentBasicRow } from './PaymentBasicRow';
import { ApplicationState } from '../../../store';
import { newPaymentReducer } from './paymentRowState/newPaymentReducer';
import { initialPaymentNewRowState } from './paymentRowState/paymentInitialStateAndTypes';
import { createPayment, resetPaymentCreatingState } from '../../../store/payments/paymentsActions';
import { PaymentRecordWithoutFiles } from '../../../store/payments/paymentsTypes';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { usePaymentTableState, usePaymentTableDispatch } from '../Context';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import {
  joinPaymentIntoFormData,
  validateNewPaymentRecordState
} from './paymentRowState/paymentRowReducerFunctions';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { DropdownMultipleInputCell } from '../../../components/TableInputs/DropdownMultipleInput';
import { MoneyInputCell } from '../../../components/TableInputs/MoneyInput';
import { searchVirsData } from '../../../store/virsis/actions';
import { DocumentNumberInput } from '../../../components/TableInputs/DocumentNumberInput';
import { PaymentFilesInput } from '../../../components/TableInputs/FilesUploadInput/PaymentFilesInput';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import {
  disableOthersIfFictitiousSelected,
  getOutletMultiOption
} from '../../../components/TableInputs/Utilities';
import theme from '../../../style/virsisTheme';
import { AlertDialog } from '../../../components/Dialogs/AlertDialog';

export const PaymentRowContainerNew: React.FC = () => {
  const [rowState, rowDispatch] = useReducer(newPaymentReducer, initialPaymentNewRowState);

  const {
    paymentData: { isPaymentCreated, creatingPaymentError },
    classifiers: { enterpriseTypes, fictitiousOutlet },
    virsis: { virsSearchResults }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const { state: tableState } = usePaymentTableState();
  const { dispatch: tableDispatch } = usePaymentTableDispatch();

  let selectedVirs: VirsSearchData | undefined;
  if (virsSearchResults) {
    [selectedVirs] = virsSearchResults;
  }

  function openFindVirsDialog() {
    rowDispatch({ type: 'VIRS_INPUT_CLICKED' });
    tableDispatch({ type: 'RESET_COMPANY_CODE' });
  }

  function closeFindVirsDialog() {
    rowDispatch({
      type: 'FIND_VIRS_DIALOG_CLOSED'
    });
  }

  function selectVirs(virs: VirsSearchData) {
    rowDispatch({
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
      virs
    });
  }

  function setDecisionDate(decisionDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'DECISION_DATE_CHANGED', decisionDate });
  }

  function addPaymentFile(file?: File) {
    if (file) {
      rowDispatch({ type: 'PAYMENT_FILE_ADDED', file });
    }
  }

  function removePaymentFile(fileId: number) {
    rowDispatch({ type: 'PAYMENT_FILE_REMOVED', fileId });
  }

  function changeCustomName(filesCustomName: string) {
    rowDispatch({ type: 'PAYMENT_FILES_CUSTOM_NAME_CHANGED', filesCustomName });
  }

  function setOutlets(outlets: OutletShortData[]) {
    rowDispatch({ type: 'OUTLETS_CHANGED', outlets });
  }

  function setPaymentAmount(paymentAmount: string | null) {
    rowDispatch({ type: 'PAYMENT_AMOUNT_CHANGED', paymentAmount });
  }

  function setValidFromDate(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_FROM_CHANGED', validFrom });
  }

  function setValidToDate(validTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_TO_CHANGED', validTo });
  }

  function cancelCreating() {
    tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
  }

  function createRecord() {
    rowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateNewPaymentRecordState(rowState).createRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const paymentRecord: PaymentRecordWithoutFiles = {
        virId: rowState.virs.value ? rowState.virs.value.virId : null,
        decisionDate: rowState.decisionDate.value
          ? rowState.decisionDate.value.format('L').toString()
          : null,
        paymentDocuments: rowState.paymentDocuments,
        paymentOutlets: rowState.paymentOutlets.values,
        paymentAmount: rowState.paymentAmount.value ? Number(rowState.paymentAmount.value) : null,
        validFrom: rowState.validFrom.value
          ? rowState.validFrom.value.format('L').toString()
          : null,
        validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null,
        customDocumentsName: rowState.customDocumentsName.value
      };
      const asFormData = joinPaymentIntoFormData(paymentRecord, rowState.paymentFiles.files);
      reduxDispatch(createPayment(asFormData));
    }
  }

  function handleFileWrongFormat() {
    rowDispatch({
      type: 'FILE_ADDING_WRONG_FORMAT',
      errorMessage: 'netinkamas dokumento formatas'
    });
  }

  const closeUnclosedPaymentDialog = () => {
    rowDispatch({ type: 'CLOSE_UNCLOSED_PAYMENT_DIALOG' });
    tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
  };

  useEffect(() => {
    rowDispatch({
      type: 'NEW_RECORD_STATE_INITIALIZED',
      fictitiousOutlet: fictitiousOutlet || []
    });
  }, [fictitiousOutlet]);

  useEffect(() => {
    if (creatingPaymentError) rowDispatch({ type: 'STOP_LOADING' });
  }, [creatingPaymentError]);

  useEffect(() => {
    if (isPaymentCreated) {
      tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
      reduxDispatch(resetPaymentCreatingState());
    }
  }, [tableDispatch, reduxDispatch, isPaymentCreated]);

  useEffect(() => {
    if (!selectedVirs && tableState.companyCode) {
      rowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' });
      reduxDispatch(searchVirsData({ personCode: tableState.companyCode, onlySigned: true }));
    }
    if (selectedVirs && tableState.companyCode && !rowState.showFindVirsDialog) {
      rowDispatch({ type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED', virs: selectedVirs });
    }
  }, [
    reduxDispatch,
    virsSearchResults,
    selectedVirs,
    rowState.showFindVirsDialog,
    tableState.companyCode
  ]);

  return (
    <>
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={closeFindVirsDialog}
          closeAndContinueWithVirs={selectVirs}
        />
      )}

      <AlertDialog
        isOpen={rowState.showUnclosedPaymentDialog}
        dialogText="Prašome įvesti šio VIRS galiojančios metinės įmokos pabaigos datą."
        onContinue={closeUnclosedPaymentDialog}
        onClose={closeUnclosedPaymentDialog}
      />

      <PaymentBasicRow
        editOn
        columnsToDisplay={tableState.columnsDisplayStatus}
        virs={<VirsInputCell openSearchDialog={openFindVirsDialog} state={rowState.virs} />}
        decisionDate={
          <DateInputImprovedCell
            state={rowState.decisionDate}
            setDate={setDecisionDate}
            isRequired
          />
        }
        paymentDocumentsInput={
          <TableCell
            style={{
              backgroundColor:
                rowState.customDocumentsName.error || rowState.paymentFiles.error
                  ? theme.palette.error.light
                  : undefined
            }}
          >
            <DocumentNumberInput
              setValue={changeCustomName}
              inputState={rowState.customDocumentsName}
              isRequired
            />
            <PaymentFilesInput
              state={rowState.paymentFiles}
              customName={rowState.customDocumentsName}
              addFile={addPaymentFile}
              removeFile={removePaymentFile}
              changeCustomName={changeCustomName}
              setWrongFormatError={handleFileWrongFormat}
            />
          </TableCell>
        }
        paymentOutletsInput={
          <DropdownMultipleInputCell
            selectValues={setOutlets}
            state={rowState.paymentOutlets}
            getOptionLabel={(outlet) => outlet.outletName}
            getOptionSelected={(option, value) =>
              value ? option.outletId === value.outletId : false
            }
            getOptionDisabled={disableOthersIfFictitiousSelected(
              fictitiousOutlet && fictitiousOutlet[0]
            )}
            renderOption={getOutletMultiOption}
          />
        }
        paymentAmount={
          <MoneyInputCell setValue={setPaymentAmount} inputState={rowState.paymentAmount} />
        }
        validFrom={
          <DateInputImprovedCell state={rowState.validFrom} setDate={setValidFromDate} isRequired />
        }
        validTo={<DateInputImprovedCell state={rowState.validTo} setDate={setValidToDate} />}
        actions={
          <>
            <SaveAndCancelActions
              handleConfirmationYes={createRecord}
              isProcessing={rowState.loading}
              handleCancelButtonClick={cancelCreating}
            />
          </>
        }
      />
    </>
  );
};
