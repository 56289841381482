import { Action, createReducer } from 'typesafe-actions';
import { OptionedFormState } from '../../../../components/FormikFields/GenericFormikWrappers/types';
import { getInitialBasicTableState } from './basicTableReducer';
import { getOptionedTableActions } from './genericActions';
import { GenericTableState } from './types';

export function getInitialOptionedTableState<TData, TInput, TOptions>(
  options: TOptions
): GenericTableState<TData, OptionedFormState<TInput, TOptions>> {
  const state = getInitialBasicTableState<TData, TInput>();
  return { ...state, form: { ...state.form, options } };
}

export function getOptionedTableReducer<TData, TInput, TOptions>(options: TOptions) {
  const initialState = getInitialOptionedTableState<TData, TInput, TOptions>(options);

  const actions = getOptionedTableActions<TData, TInput, TOptions>();

  const reducer = createReducer<typeof initialState, Action>(initialState)
    .handleAction(actions.setLoading, (state, action) => ({
      ...state,
      isLoading: action.payload.isLoading
    }))
    .handleAction(actions.setError, (state, action) => ({
      ...state,
      error: action.payload.error
    }))
    .handleAction(actions.setData, (state, action) => ({
      ...state,
      data: action.payload.data
    }))
    .handleAction(actions.setFormOptions, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        options: action.payload.options
      }
    }))
    .handleAction(actions.setFormOpen, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        initialValues: action.payload.initialValues,
        open: action.payload.open
      }
    }))
    .handleAction(actions.setFormError, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        error: action.payload.error
      }
    }))
    .handleAction(actions.setFormLoading, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        isLoading: action.payload.loading
      }
    }))
    .handleAction(actions.resetForm, (state) => ({
      ...state,
      form: {
        error: false,
        open: false,
        options: state.form.options,
        isLoading: false
      }
    }))
    .handleAction(actions.setRemoveOpen, (state, action) => ({
      ...state,
      remove: {
        ...state.remove,
        id: action.payload.id,
        open: action.payload.open
      }
    }))
    .handleAction(actions.setRemoveLoading, (state, action) => ({
      ...state,
      remove: {
        ...state.remove,
        isLoading: action.payload.loading
      }
    }))
    .handleAction(actions.setRemoveError, (state, action) => ({
      ...state,
      remove: {
        ...state.remove,
        error: action.payload.error
      }
    }))
    .handleAction(actions.resetRemove, (state) => ({
      ...state,
      remove: {
        error: false,
        open: false,
        isLoading: false
      }
    }));

  return {
    ...actions,
    initialState,
    reducer
  };
}
