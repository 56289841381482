import React from 'react';

import { MessagesColumnHeader, MessagesTableColumn } from './tableState/tableInitialStateAndTypes';
import { useMessagesTableState, useMessagesTableDispatch } from './Context';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import FilterTool from '../../components/TableCustomTools/FilterTool';
import SortByTool from '../../components/TableCustomTools/SortByTool';
import { MessagesTableCustomizationBarActions } from './TableCustomizationActions';
import { UserMessage } from '../../store/userMessages/userMessageTypes';

interface Props {
  columnNames: MessagesColumnHeader[];
  allPageMessages: UserMessage[];
  hasMessages: boolean;
}

export const MessagesTableCustomizationBar: React.FC<Props> = ({
  columnNames,
  allPageMessages,
  hasMessages
}) => {
  const { state: tableState } = useMessagesTableState();
  const { dispatch: tableDispatch } = useMessagesTableDispatch();

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: string) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column as unknown as MessagesTableColumn });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <MessagesTableCustomizationBarActions
            allPageMessages={allPageMessages}
            hasMessages={hasMessages}
          />
        }
        rightSideItem={
          <>
            <FilterTool
              onFilterTabToggle={toggleDisplayCustomFilter}
              showFilter={tableState.customFilterOn}
            />
            <SortByTool
              order={tableState.order}
              sortBy={tableState.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnNames={columnNames}
            />
          </>
        }
      />
    </div>
  );
};
