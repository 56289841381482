import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationState } from '../../store';
import {
  fetchProvidedDataByVirsId,
  fetchProvidedDataVirs
} from '../../store/providedData/providedDataActions';
import { ProvidedDataTableWithContext } from '../../containers/ProvidedDataTable/Context';
import {
  fetchAnnulmentTypesRequest,
  fetchDataDeletionPeriodRequest
} from '../../store/classifiers/classifiersActions';
import BottomPageActionButtons from '../../components/PageButtons/BottomPageActionButtons';
import { getSubsystem } from '../../utils/roleHelper';
import { getVirsProvidedDataStatusRequest } from '../../store/virsProvidedDataStatus/store';
import { AlertBox } from '../../components/Alert/AlertBox';
import { closeUnsignedDataAlert } from '../../store/virsis/actions';

const ProvidedDataPage: React.FC = () => {
  const {
    virsis: { virsData, currentUser, selectedVirsId, showUnsignedDataAlert },
    providedData: { loadingProvidedData, providedData, providedDataError },
    classifiers: { annulmentTypes, dataDeletionPeriod, loadingDataDeletionPeriod }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (virsData && selectedVirsId) {
      reduxDispatch(fetchProvidedDataByVirsId(selectedVirsId));
    } else if (virsData && !selectedVirsId) {
      reduxDispatch(fetchProvidedDataVirs());
    }
  }, [reduxDispatch, virsData, selectedVirsId]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  const isVirsis = getSubsystem(currentUser) === 'VIRSIS';

  const handleGoToPageClick = () => {
    const path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/veiklos-rusys'
      : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`;
    history.push(path);
  };

  const handleGoBackClick = () => {
    const path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/dalyviai'
      : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/dalyviai`;
    history.push(path);
  };

  useEffect(() => {
    reduxDispatch(getVirsProvidedDataStatusRequest());
  }, [reduxDispatch]);

  useEffect(() => {
    if (!dataDeletionPeriod) {
      reduxDispatch(fetchDataDeletionPeriodRequest());
    }
  }, [reduxDispatch, dataDeletionPeriod]);

  return (
    <>
      {showUnsignedDataAlert && dataDeletionPeriod && !loadingDataDeletionPeriod && (
        <AlertBox
          handleClose={() => reduxDispatch(closeUnsignedDataAlert())}
          text={`Dėmesio! Nepasirašyti duomenys bus automatiškai pašalinami po ${dataDeletionPeriod} d. nuo jų paskutinio redagavimo.`}
        />
      )}

      <ProvidedDataTableWithContext
        loading={loadingProvidedData}
        data={providedData}
        dataError={providedDataError}
      />
      <BottomPageActionButtons
        handleGoToPageClick={handleGoToPageClick}
        handleGoBackClick={handleGoBackClick}
        pageToGo="Veiklos rūšys"
      />
    </>
  );
};

export default ProvidedDataPage;
