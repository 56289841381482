import { Typography, SvgIconTypeMap } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { CSSProperties } from '@material-ui/styles';
import { TooltipButton, TooltipButtonProps } from '../../Tooltips/TooltipButton';

interface Props {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  text: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  dangerButton?: boolean;
  customStyles?: CSSProperties;
  tooltipProps?: TooltipButtonProps;
}

const useStyles = makeStyles((theme) => ({
  signButton: {
    padding: '0 10px',
    borderColor: '#E5E5E5',
    backgroundColor: theme.palette.secondary.light,
    minWidth: 120,
    color: '#637082',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.main
    },
    '&:active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#DBEDFC',
      color: theme.palette.primary.main
    }
  },
  dangerButton: {
    '&:hover': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main
    }
  },
  icon: {
    height: 30,
    padding: '5px 10px 5px 0',
    color: 'inherit'
  },
  text: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit'
    }
  }
}));

export const DocumentActionButton: React.FC<Props> = ({
  onClick,
  text,
  IconComponent,
  disabled,
  dangerButton,
  customStyles,
  tooltipProps
}) => {
  const classes = useStyles();
  return (
    <TooltipButton
      variant="outlined"
      onClick={onClick}
      className={`${classes.signButton} ${dangerButton ? classes.dangerButton : undefined}`}
      disabled={disabled}
      style={customStyles}
      {...tooltipProps}
    >
      <IconComponent fontSize="small" className={classes.icon} />
      <Typography variant="h5" className={classes.text}>
        {text}
      </Typography>
    </TooltipButton>
  );
};
