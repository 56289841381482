import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertDialog } from '../../components/Dialogs/AlertDialog';
import { ApplicationState } from '../../store';
import { cancelDeleteEnterpriseAlert } from '../../store/enterprises/enterprisesActions';
import { removeOutletsRequest } from '../../store/outlets/outletsActions';

interface Props {
  contentText: string;
}

const OutletsWillBeDeletedAlert: React.FC<Props> = ({ contentText }) => {
  const { deleteEnterpriseAlertOpen, outletIds } = useSelector(
    (state: ApplicationState) => state.enterpriseData
  );
  const dispatch = useDispatch();

  if (!outletIds) {
    return null;
  }

  return (
    <AlertDialog
      isOpen={deleteEnterpriseAlertOpen}
      dialogText={contentText}
      onContinue={() => dispatch(removeOutletsRequest(outletIds))}
      onClose={() => dispatch(cancelDeleteEnterpriseAlert())}
    />
  );
};

export default OutletsWillBeDeletedAlert;
