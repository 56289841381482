import React from 'react';
import { useSelector } from 'react-redux';
import { ConditionalWrapper } from '../../../../../components/ConditionalWrapper/ConditionalWrapper';
import { ApplicationState } from '../../../../../store';
import {
  ActivityPeriod,
  ShareholderPerson,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../../../store/shareholders/shareholdersTypes';
import { Roles } from '../../../../../store/virsis/dataTypes';
import { hasRoles } from '../../../../../utils/roleHelper';
import {
  getFirstNoNParticipationDay,
  isOneDayShareholder
} from '../../../utilityFunctions/sharedFunctions';
import {
  customStylesColorBar,
  customStylesTriangle
} from '../../customStyles/customStylesColorBar';
import { useShareholdersSelectedDateTableState } from '../../TableWithContext';
import { AddPercentageContainer } from '../AddShareholderPercentage/AddPercentageContainer';
import { ColorBarValue } from './ColorBarValue';
import { EndOfParticipationContainer } from '../AddShareholderPercentage/EndOfParticipationContainer';
import { hasNoEvents } from '../../../MainTable/tableState/tableFunctions';
import moment from 'moment';
import { todaysDateString } from '../../../../../utils/tableDataFunctions';
import { hasShareholderUnsignedDocs } from '../../utilities/utilityFunctions';

interface Props {
  selectedDate: string;
  shareholder: ShareholdersProps;
  event: ShareholdersHistory;
  fillWithColor: string;
  borderColor: string;
  textColor: string;
  isSelectedDateEventDate?: boolean;
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson;
  newDate?: string;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  loadingShareholdersSelectedDateData: boolean;
  currentActivityPeriod: ActivityPeriod;
  isDateSigned: boolean;
}

export const ColorBarSelectedDate: React.FC<Props> = ({
  selectedDate,
  shareholder,
  event,
  fillWithColor,
  borderColor,
  textColor,
  isSelectedDateEventDate,
  parentShareholder,
  newDate,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  loadingShareholdersSelectedDateData,
  currentActivityPeriod,
  isDateSigned
}) => {
  const {
    virsis: { currentUser },
    shareholdersData: { lastSignedDate, shareholdersData }
  } = useSelector((state: ApplicationState) => state);
  const {
    state: { showShares, showVotes }
  } = useShareholdersSelectedDateTableState();

  const {
    isShareholderFromJADIS,
    shareholderId,
    shareholderParentStartDate,
    shareholderStartDate,
    shareholderStoppedParticipatingFromDate
  } = shareholder;

  const { eventDate, sharePercentage, votePercentage, votesOnRules, sharePercentageLessThanPermitted, votePercentageLessThanPermitted } = event;

  const startDate = shareholderStartDate || shareholderParentStartDate;

  const startWithTriangle = !shareholderStartDate;

  const firstNoNParticipationDay = getFirstNoNParticipationDay(
    shareholderStoppedParticipatingFromDate,
    virsStoppedParticipatingFromDate
  );

  const customStyles = customStylesColorBar(
    isShareholderFromJADIS,
    startDate,
    firstNoNParticipationDay,
    shareholderStoppedParticipatingFromDate,
    virsEndDate,
    selectedDate,
    fillWithColor,
    startWithTriangle,
    borderColor,
    textColor,
    eventDate,
    isSelectedDateEventDate,
    newDate,
    event,
    currentActivityPeriod
  );

  const userCanEdit: boolean =
    currentUser !== undefined && hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]);
  const oneDayShareholder: boolean = isOneDayShareholder(
    currentActivityPeriod,
    shareholder.shareholderHistory,
    event,
    shareholderStoppedParticipatingFromDate
  );
  const eventsWithoutActivityDates: ShareholdersHistory[] = shareholder.shareholderHistory.filter(
    (_event) => _event.isEventFromJADIS || _event.documentId !== null
  );

  const showEditIcon = () => {
    if (!userCanEdit) {
      return false;
    }
    if (event.documentStatus === null && !isShareholderFromJADIS) {
      return false;
    }
    if (shareholdersData?.virsStartDate && event.eventDate < shareholdersData?.virsStartDate) {
      return false;
    }
    if (lastSignedDate && event.eventDate < lastSignedDate?.format('YYYY-MM-DD')) {
      return false;
    }
    if (shareholder.shareholderStoppedParticipatingFromDate === selectedDate) {
      return oneDayShareholder;
    }
    if (!event.isEventFromJADIS && event.documentId === null) {
      return (
        currentActivityPeriod?.startDate !== selectedDate &&
        currentActivityPeriod?.endDate !== selectedDate
      );
    }
    if (todaysDateString() === selectedDate) {
      return true;
    }
    if (isDateSigned) {
      return false;
    }
    if (selectedDate !== eventDate && event.isEventFromJADIS) {
      return false;
    }
    if (oneDayShareholder && !isDateSigned) {
      return true;
    }

    const oneDayShareholderOnActivityPeriodEnd =
      oneDayShareholder &&
      (shareholderStartDate === selectedDate ||
        (shareholderStartDate === null &&
          eventsWithoutActivityDates.length === 1 &&
          eventsWithoutActivityDates[0].eventDate === selectedDate)) &&
      shareholderStoppedParticipatingFromDate === selectedDate;
    if (currentActivityPeriod?.endDate === selectedDate) {
      return oneDayShareholderOnActivityPeriodEnd;
    }

    if (isShareholderFromJADIS) {
      return !isDateSigned;
    }

    return (
      (hasNoEvents([shareholder], selectedDate) ||
        selectedDate !== currentActivityPeriod?.endDate ||
        (selectedDate === shareholderStoppedParticipatingFromDate &&
          selectedDate === shareholderStartDate)) &&
      (eventDate === selectedDate || (eventDate < selectedDate && !isSelectedDateEventDate)) &&
      (!(
        selectedDate === virsStoppedParticipatingFromDate ||
        selectedDate === shareholderStoppedParticipatingFromDate
      ) ||
        selectedDate === shareholderStartDate)
    );
  };

  const showEndOfParticipation = () => {
    if (!userCanEdit) {
      return false;
    }
    if (isDateSigned) {
      return false;
    }
    if (isShareholderFromJADIS) {
      return false;
    }
    if (
      shareholderStoppedParticipatingFromDate === selectedDate &&
      currentActivityPeriod.endDate === selectedDate
    ) {
      return !oneDayShareholder && shareholder.closedWithDocument?.documentStatus !== 'PASIRASYTAS';
    }
    if (shareholderStoppedParticipatingFromDate !== selectedDate) {
      return false;
    }
    if (lastSignedDate !== undefined && moment(selectedDate).isSameOrBefore(lastSignedDate)) {
      return false;
    }
    const result =
      !isDateSigned &&
      shareholderStartDate !== selectedDate &&
      currentActivityPeriod?.startDate !== selectedDate &&
      virsStoppedParticipatingFromDate !== shareholderStoppedParticipatingFromDate;

    if (shareholderStartDate === selectedDate || shareholderStartDate === null) {
      if (eventsWithoutActivityDates.length > 1) {
        return !isShareholderFromJADIS;
      } else {
        return selectedDate !== shareholderStartDate && shareholderStartDate !== null;
      }
    }
    if (
      hasShareholderUnsignedDocs(
        shareholder.shareholderHistory,
        shareholderStoppedParticipatingFromDate,
        shareholder.closedWithDocument,
        selectedDate
      )
    ) {
      return true;
    }
    return result;
  };

  return (
    <>
      {startWithTriangle && startDate === selectedDate ? (
        <div
          className="color-row-triangle"
          style={customStylesTriangle(borderColor, fillWithColor)}
        />
      ) : null}
      <div className="color-bar-selected-date" style={customStyles}>
        <ConditionalWrapper
          condition={showEditIcon()}
          wrapper={(children) => (
            <AddPercentageContainer
              parentShareholder={parentShareholder}
              popoverId={`${shareholderId}${event.eventId}`}
              shareholder={shareholder}
              shareholderEvent={event}
              barWidth={customStyles.width}
              textColor={customStyles.color}
            >
              {children}
            </AddPercentageContainer>
          )}
        >
          <>
            {!loadingShareholdersSelectedDateData && (
              <ColorBarValue
                showVotes={showVotes}
                showShares={showShares}
                votesOnRules={votesOnRules}
                sharePercentage={sharePercentage}
                votePercentage={votePercentage}
                sharePercentageLessThanPermitted={sharePercentageLessThanPermitted}
                votePercentageLessThanPermitted={votePercentageLessThanPermitted}
              />
            )}
          </>
        </ConditionalWrapper>
      </div>
      {showEndOfParticipation() && (
        <EndOfParticipationContainer
          selectedDate={selectedDate}
          shareholder={shareholder}
          endOfparticipation={shareholderStoppedParticipatingFromDate === selectedDate}
        />
      )}
    </>
  );
};
