import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerState, ValidationDate } from '../../../../utils/tableTypes';
import {
  addShareholderDate,
  fetchShareholdersSelectedDateDataRequest
} from '../../../../store/shareholders/shareholdersActions';
import { getSubsystem } from '../../../../utils/roleHelper';
import { ApplicationState } from '../../../../store';
import { ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import { shouldDisableDate } from '../../../ShareholdersVirsTree/Timeline/dateUtil';
import { VirsTreeCustomTooltip } from '../../../ShareholdersVirsTree/VirsTreeCustomTooltip';
import { getCurrentPeriod } from '../../utilityFunctions/sharedFunctions';
import { Strings } from '../../../../utils/strings/Strings';

interface DatePickerImprovedProps {
  isOpen: boolean;
  withoutCalendar?: boolean;
  allowFutureDate?: boolean;
  helperItem?: ReactNode;
  openCalendar: () => void;
  closeCalendar: () => void;
  daysToDisable?: string[];
  virsStartDate: string;
  virsEndDate?: string | null;
  selectedDateTable?: boolean;
  shareholdersData: ShareholdersData;
}

const DateInputDialog: React.FC<DatePickerImprovedProps> = ({
  isOpen,
  closeCalendar,
  allowFutureDate,
  daysToDisable,
  virsStartDate,
  virsEndDate,
  selectedDateTable,
  shareholdersData
}) => {
  const now = moment();
  const history = useHistory();
  const virsMinDate = moment(virsStartDate);
  const virsMaxDate = virsEndDate ? moment(virsEndDate) : undefined;
  const reduxDispatch = useDispatch();

  const {
    virsis: { currentUser, virsData, selectedVirsId },
    shareholdersData: { lastSignedDate }
  } = useSelector((state: ApplicationState) => state);

  const { activityPeriods } = shareholdersData;

  const subsystem = getSubsystem(currentUser);

  const state: DatePickerState = {
    value: null,
    error: false,
    validated: false,
    minDates: [
      {
        id: 'minDate',
        date: virsMinDate,
        text: 'minDate'
      }
    ],
    maxDates: [
      {
        id: 'maxDate',
        date: virsMaxDate || now,
        text: 'maxDate'
      }
    ]
  };

  const getTooltipText = (date: MaterialUiPickersDate): string => {
    if (date === null) {
      return '';
    }

    const selectedDate: string = date.format('YYYY-MM-DD');
    const currentActivityPeriod = getCurrentPeriod(selectedDate, activityPeriods);

    if (!(currentActivityPeriod && selectedDate >= currentActivityPeriod?.startDate)) {
      return Strings.shareholderTooltip__virsNonActive;
    }
    if (lastSignedDate !== undefined && moment(date).isSameOrBefore(lastSignedDate)) {
      return Strings.shareholderTooltip__dateSigned;
    }
    if (daysToDisable?.includes(date.format('YYYY-MM-DD'))) {
      return Strings.shareholderTooltip__dateTaken;
    }
    return '';
  };

  const minDateAllowed: ValidationDate | undefined = _.maxBy(state.minDates, 'date');
  const maxDateAllowed: ValidationDate | undefined = _.minBy(state.maxDates, 'date');

  const disabledDays = (date: MaterialUiPickersDate): boolean => {
    if (date !== null && daysToDisable?.includes(date.format('YYYY-MM-DD'))) {
      return true;
    }
    return shouldDisableDate(activityPeriods, date, shareholdersData.shareholders, lastSignedDate);
  };

  const tooltipWrapper = (
    day: MaterialUiPickersDate,
    date: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    return (
      <VirsTreeCustomTooltip
        className={'MuiButtonBase-root MuiIconButton-root MuiPickersDay-day'}
        children={<div>{dayComponent}</div>}
        text={getTooltipText(day)}
      />
    );
  };

  const addDate = (value: MaterialUiPickersDate) => {
    if (value) {
      reduxDispatch(addShareholderDate(value.format('YYYY-MM-DD')));

      if (selectedDateTable && virsData) {
        reduxDispatch(
          fetchShareholdersSelectedDateDataRequest(virsData?.virsId, value.format('YYYY-MM-DD'))
        );
      }

      if (subsystem === 'VIRSIS') {
        history.push('/duomenu-perziura-ir-teikimas/dalyviai/konkreti-data');
      } else if (selectedVirsId) {
        history.push(
          `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/dalyviai/konkreti-data`
        );
      }
    }
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          renderDay={(
            day: MaterialUiPickersDate,
            selectedDate: MaterialUiPickersDate,
            dayInCurrentMonth: boolean,
            dayComponent: JSX.Element
          ) => {
            return tooltipWrapper(day, selectedDate, dayInCurrentMonth, dayComponent);
          }}
          value={state.value}
          onChange={addDate}
          minDate={minDateAllowed?.date}
          maxDate={!allowFutureDate ? maxDateAllowed?.date : undefined}
          format="YYYY-MM-DD"
          shouldDisableDate={disabledDays}
          InputProps={{
            style: {
              display: 'none'
            }
          }}
          InputAdornmentProps={{
            style: {
              display: 'none'
            }
          }}
          open={isOpen}
          onClose={closeCalendar}
          okLabel={Strings.addDate}
          cancelLabel={Strings.button__cancel}
          id={state.id}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

export default DateInputDialog;
