import React from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { Link, LinkProps } from 'react-router-dom';
import { Breadcrumbs, Link as MaterialLink, Typography } from '@material-ui/core';
import { Subsystem } from '../../containers/VirsDataSubmision/VirsDataSubmision/VirsDataPage';

interface BreadcrumbLinkProps extends LinkProps {
  to: string;
  label: string;
  virsName?: string;
  lastPath?: boolean;
  subsystem?: Subsystem;
}

interface BreadcrumbsProps extends RouteComponentProps {
  virsName?: string;
  subsystem?: Subsystem;
}

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({
  to,
  label,
  virsName,
  lastPath,
  subsystem
}) => {
  const breadcrumbNameMap: { [key: string]: string | undefined } = {
    '/': 'Pradžia',
    'virs-duomenys': `VIRS duomenų ${subsystem !== 'KM' ? 'peržiūra' : 'teikimas'}`,
    kurimas: 'Sukurti Virs',
    perziura: virsName
  };

  const key = label.replace(/[0-9]/g, '');
  const title = breadcrumbNameMap[key];

  return lastPath ? (
    <Typography>{title}</Typography>
  ) : (
    <MaterialLink to={to} component={Link}>
      {title}
    </MaterialLink>
  );
};

const NamedBreadcrumbs: React.FC<BreadcrumbsProps> = ({ virsName, subsystem }) => {
  const { pathname } = useLocation();
  const pathnames = pathname
    .split('/')
    .filter((x) => x.includes('perziura') || x.includes('kurimas') || x.includes('virs-duomenys'));

  return (
    <Breadcrumbs>
      <BreadcrumbLink to="/" label="/" />
      {pathnames.map((label, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const lastPath = index === pathnames.length - 1;
        return (
          <BreadcrumbLink
            style={{ marginBottom: '6px' }}
            key={routeTo}
            to={routeTo}
            label={label}
            virsName={virsName}
            lastPath={lastPath}
            subsystem={subsystem}
          />
        );
      })}
    </Breadcrumbs>
  );
};

export default withRouter(NamedBreadcrumbs);
