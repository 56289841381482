import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography
} from '@material-ui/core';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import virsisTheme from '../../style/virsisTheme';
import theme from '../../style/virsisTheme';
import { CloseContinueButton } from './Dialog/CloseContinueButton';

interface Props {
  open: boolean;
  onClose: () => void;
  onLrtkSubmit: () => void;
  onZeitSubmit: () => void;
  isProcessing: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 35px 10px 35px'
    },
    divider: {
      marginRight: 35,
      marginLeft: 35
    },
    contentWrap: {
      padding: '6px 35px 24px 35px'
    },
    contentText: {
      color: virsisTheme.palette.text.primary,
      fontSize: '1rem'
    },
    form: {
      width: '100%'
    },
    select: {
      width: '100%'
    },
    iconS: {
      marginLeft: 14,
      width: 12,
      height: 12
    },
    actions: {
      minWidth: 250,
      padding: '10px 34px',
      height: 50,
      backgroundColor: '#F3F3F3'
    },
    delete: {
      backgroundColor: 'transparent',
      color: virsisTheme.palette.error.main,
      borderColor: virsisTheme.palette.error.main,
      minWidth: '124px'
    },
    success: {
      backgroundColor: 'transparent',
      color: virsisTheme.palette.success.main,
      borderColor: virsisTheme.palette.success.main
    },
    button: {
      color: '#637082',
      border: '1px solid #637082',
      backgroundColor: 'transparent',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      width: '124px',
      '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary
      }
    }
  })
);

export const InstitutionSelectionDialog: React.FC<Props> = ({
  open,
  onClose,
  onZeitSubmit,
  onLrtkSubmit,
  isProcessing
}) => {
  const classes = useStyles();

  const contentText = (
    <DialogContentText className={classes.contentText}>
      Pasirinkite instituciją, kuriai norite priskirti šį įrašą
    </DialogContentText>
  );

  return (
    <Dialog maxWidth="md" open={open} onClose={isProcessing ? undefined : onClose}>
      {open && (
        <>
          <div className={classes.title}>
            <Typography variant="h2">Institucijos pasirinkimas</Typography>
            {isProcessing ? null : <CustomCloseIconButton onClick={onClose} />}
          </div>

          <Divider className={classes.divider} />

          <DialogContent
            className={classes.contentWrap}
            style={isProcessing ? { display: 'flex', justifyContent: 'center' } : undefined}
          >
            {isProcessing ? <CircularProgress /> : contentText}
          </DialogContent>

          <DialogActions className={classes.actions}>
            <CloseContinueButton onClick={onLrtkSubmit} label={'LRTK'} />
            <CloseContinueButton onClick={onZeitSubmit} label={'ŽEIT'} />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
