import { Action, createReducer } from 'typesafe-actions';
import { getBasicTableActions } from './genericActions';
import { BasicFormState } from '../../../../components/FormikFields/GenericFormikWrappers/types';
import { GenericTableState } from './types';

export function getInitialBasicTableState<TData, TInput>(): GenericTableState<
  TData,
  BasicFormState<TInput>
> {
  return {
    isLoading: true,
    error: false,
    data: [],
    form: {
      error: false,
      open: false,
      isLoading: false
    },
    remove: {
      error: false,
      open: false,
      isLoading: false
    }
  };
}

export function getBasicTableReducer<TData, TInput>() {
  const initialState = getInitialBasicTableState<TData, TInput>();
  const actions = getBasicTableActions<TData, TInput>();

  const reducer = createReducer<typeof initialState, Action>(initialState)
    .handleAction(actions.setLoading, (state, action) => ({
      ...state,
      isLoading: action.payload.isLoading
    }))
    .handleAction(actions.setError, (state, action) => ({
      ...state,
      error: action.payload.error
    }))
    .handleAction(actions.setData, (state, action) => ({
      ...state,
      data: action.payload.data
    }))
    .handleAction(actions.setFormOpen, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        initialValues: action.payload.initialValues,
        open: action.payload.open
      }
    }))
    .handleAction(actions.setFormError, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        error: action.payload.error
      }
    }))
    .handleAction(actions.setFormLoading, (state, action) => ({
      ...state,
      form: {
        ...state.form,
        isLoading: action.payload.loading
      }
    }))
    .handleAction(actions.resetForm, (state) => ({
      ...state,
      form: {
        error: false,
        open: false,
        isLoading: false
      }
    }))
    .handleAction(actions.setRemoveOpen, (state, action) => ({
      ...state,
      remove: {
        ...state.remove,
        id: action.payload.id,
        open: action.payload.open
      }
    }))
    .handleAction(actions.setRemoveLoading, (state, action) => ({
      ...state,
      remove: {
        ...state.remove,
        isLoading: action.payload.loading
      }
    }))
    .handleAction(actions.setRemoveError, (state, action) => ({
      ...state,
      remove: {
        ...state.remove,
        error: action.payload.error
      }
    }))
    .handleAction(actions.resetRemove, (state) => ({
      ...state,
      remove: {
        error: false,
        open: false,
        isLoading: false
      }
    }));

  return {
    ...actions,
    initialState,
    reducer
  };
}
