import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  Box,
  Typography,
  Container,
  makeStyles,
  createStyles
} from '@material-ui/core';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import MisconductTableHeader from './TableHeader';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import { ProfessionalMisconductColumnHeader } from './tableState/misconductTableInitialStateAndTypes';
import { ProfMisconductCustomizationBar } from './CustomizationBar';

import { ApplicationState } from '../../store';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import MisconductTableCustomFilterRow from './FilterRow';
import {
  useProfessionalMisconductInstTableState,
  useProfessionalMisconductInstTableDispatch
} from './Context';
import AllowedTo from '../AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import { MisconductInstRowContainerNew } from './TableRows/RowContainerNew';
import { MisconductInstRowContainer } from './TableRows/RowContainer';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import {
  initializeProfessionalMisconductDataTableState,
  setPage,
  setRowsPerPage
} from '../../store/professionalMisconductTable/professionalMisconductsActions';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { unsignedDataRemoveInst } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { InstDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import {
  getProfessionalMisconductByDocId,
  getProfessionalMisconductInstDataRequest
} from '../../store/professionalMisconducts/professionalMisconductsActions';
import { TableLinearProgressRow } from '../../components/TableLinearProgressRow/TableLinearProgressRow';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { AlertBox } from '../../components/Alert/AlertBox';

export const columnNames: ProfessionalMisconductColumnHeader[] = [
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'decisionDate',
    label: 'Sprendimo data',
    type: 'date'
  },
  {
    id: 'documentNumber',
    label: 'Sprendimo nr.'
  },
  {
    id: 'outletName',
    label: 'Susijusios VIP'
  },
  {
    id: 'professionalMisconduct',
    label: 'Nustatytas pažeidimas'
  },
  {
    id: 'sanction',
    label: 'Poveikio priemonė(-s)'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      tableBox: {
        flexWrap: 'wrap'
      },
      addButton: {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0,
        paddingLeft: 0
      },
      pageActionButtonBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  })
);

const MisconductTable: React.FC<RouteComponentProps> = () => {
  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);
  const { companyCode, documentStatusId } = useParams<{
    companyCode?: string;
    documentStatusId?: string;
  }>();
  const { state: tableState } = useProfessionalMisconductInstTableState();
  const { dispatch: tableDispatch } = useProfessionalMisconductInstTableDispatch();

  const reduxDispatch = useDispatch();
  const classes = useStyles();

  const {
    professionalMisconductData: {
      professionalMisconductInstData,
      loadingProfessionalMisconductInstData,
      professionalMisconductInstDataError,
      statusNotSignedFilterEnabled,
      statusRelevantFilterEnabled,
      statusOutdatedFilterEnabled,
      recordCount,
      annullingProfessionalMisconductSuccess
    },
    professionalMisconductTableData: { tableDataState, tempTableDataState }
  } = useSelector((state: ApplicationState) => state);

  const [showDocumentAnulledAlert, setShowDocumentAnulledAlert] = useState(false);

  const filteredData = professionalMisconductInstData || [];

  function openNewRecordRow() {
    tableDispatch({
      type: 'CREATE_RECORD_CLICKED',
      companyCode: null
    });
  }

  const handleSetPage = (value: number) => {
    reduxDispatch(setPage(value));
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    reduxDispatch(setRowsPerPage(rowsPerPage));
  };

  useEffect(() => {
    reduxDispatch(
      initializeProfessionalMisconductDataTableState(
        documentStatusId ? false : statusNotSignedFilterEnabled,
        documentStatusId ? false : statusRelevantFilterEnabled,
        documentStatusId ? false : statusOutdatedFilterEnabled,
        documentStatusId ? false : statusNotSignedFilterEnabled,
        documentStatusId ? false : statusRelevantFilterEnabled,
        !statusNotSignedFilterEnabled && !statusRelevantFilterEnabled && statusOutdatedFilterEnabled
      )
    );
  }, [
    reduxDispatch,
    statusNotSignedFilterEnabled,
    statusRelevantFilterEnabled,
    statusOutdatedFilterEnabled,
    documentStatusId
  ]);

  useEffect(() => {
    if (documentStatusId) {
      reduxDispatch(getProfessionalMisconductByDocId(Number(documentStatusId)));
      return;
    }
    if (
      JSON.stringify(tempTableDataState) !== JSON.stringify(tableDataState) &&
      !documentStatusId
    ) {
      reduxDispatch(getProfessionalMisconductInstDataRequest());
    }
  }, [tempTableDataState, reduxDispatch, tableDataState, documentStatusId]);

  useEffect(() => {
    if (companyCode) {
      tableDispatch({
        type: 'CREATE_RECORD_CLICKED',
        companyCode
      });
    }
  }, [companyCode, tableDispatch]);

  useEffect(() => {
    if (annullingProfessionalMisconductSuccess) {
      setShowDocumentAnulledAlert(true);
    }
  }, [annullingProfessionalMisconductSuccess]);

  return (
    <>
      <Container maxWidth="xl">
        <AllowedTo roles={[Roles.ROLE_ZEIT_EDIT, Roles.ROLE_LRTK_EDIT]}>
          <div className="data-page-header">
            <Typography variant="h1">Duomenų teikimas</Typography>
            <Typography style={{ margin: '10px 0' }} variant="subtitle1">
              Rimtų profesinių pažeidimų duomenys
            </Typography>
          </div>
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="space-between"
            flexWrap="wrap"
            className={classes.tableBox}
          >
            <AddNewRecordButton
              text="Pridėti rimto(-ų) profesinio(-ių) pažeidimo(-ų) įrašą"
              onClicked={openNewRecordRow}
              disabled={
                tableState.showNewRecord ||
                !!professionalMisconductInstDataError ||
                !!documentStatusId
              }
              className={classes.addButton}
            />
            <Box className={classes.pageActionButtonBox}>
              <PageActionButtons
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    filteredData,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, filteredData, navigationPath)
                }
                onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
              />
            </Box>
          </Box>
          <ConfirmRemoveUnsignedDialog
            onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
            onConfirm={() => reduxDispatch(unsignedDataRemoveInst(InstDocumentType.MISCONDUCT))}
            onRemoveSuccess={() => reduxDispatch(getProfessionalMisconductInstDataRequest())}
            open={confirmToDeleteUnsignedDialogOpen}
          />
        </AllowedTo>
      </Container>

      {showDocumentAnulledAlert && (
        <AlertBox
          handleClose={() => setShowDocumentAnulledAlert(false)}
          text={
            'Įrašas sėkmingai anuliuotas. Anuliuotus dokumentus galite peržiūrėti pasirinkę meniu „Duomenų istorija“.'
          }
        />
      )}

      <Container maxWidth="xl" style={{ marginTop: '1rem' }}>
        <Paper elevation={0}>
          <ProfMisconductCustomizationBar
            columnNames={columnNames}
            disabled={tableState.showNewRecord || !!documentStatusId}
          />

          <ScrollXContainer>
            <Table>
              <MisconductTableHeader />
              {tableState.customFilterOn && (
                <MisconductTableCustomFilterRow columnNames={columnNames} />
              )}
              <TableBody>
                <TableLinearProgressRow
                  isLoading={
                    loadingProfessionalMisconductInstData && !professionalMisconductInstDataError
                  }
                  colSpan={10}
                />
                <TableErrorRow
                  error={
                    professionalMisconductInstDataError &&
                    'Klaida. Nepavyko gauti profesinių pažeidimų duomenų'
                  }
                  colSpan={10}
                />
                {!professionalMisconductInstDataError && tableState.showNewRecord && (
                  <MisconductInstRowContainerNew />
                )}
                {!professionalMisconductInstDataError &&
                  !loadingProfessionalMisconductInstData &&
                  filteredData &&
                  filteredData.map((record) => (
                    <MisconductInstRowContainer
                      key={record.professionalMisconductId}
                      record={record}
                    />
                  ))}
              </TableBody>
            </Table>
          </ScrollXContainer>

          <TablePagination
            recordsCount={recordCount}
            pagesCount={Math.ceil(recordCount / tableDataState.rowsPerPage || 0)}
            rowsPerPage={tableDataState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableDataState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={filteredData.length === 0 || recordCount === 0}
          />
        </Paper>
      </Container>
    </>
  );
};

export default withRouter(MisconductTable);
