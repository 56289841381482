import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

export interface Props {
  virsName: string;
  closeTree: () => void;
}

export const VirsTreeHeader: React.FC<Props> = ({ virsName, closeTree }) => {
  return (
    <Box>
      <Typography style={{ margin: 'auto 0' }} variant="h1">
        Būsima dalyvių vizualizacija viešojoje srityje
      </Typography>
      <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{virsName}</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '15px 0 15px 25px' }}
          onClick={closeTree}
        >
          <Typography variant="h5">Grįžti</Typography>
        </Button>
      </Box>
    </Box>
  );
};
