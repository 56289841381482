import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import {
  useShareholdersSelectedDateTableDispatch,
  useShareholdersSelectedDateTableState
} from '../../SelectedDateTable/TableWithContext';
import LevelFilter from '../../TableFilters/FilterTool/LevelFilter';

const useStyles = makeStyles(() => ({
  menuItem: {
    flexDirection: 'column'
  }
}));

const SelectedTableFilterItems: React.FC = () => {
  const classes = useStyles();

  const { dispatch: selectedDateTableDispatch } = useShareholdersSelectedDateTableDispatch();
  const {
    state: { shareholdersLevelList, defaultFilterLevelValue }
  } = useShareholdersSelectedDateTableState();

  const setLevel = (selectedLevel: string) => {
    selectedDateTableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: 'shareholderLevel',
      value: selectedLevel
    });
    selectedDateTableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: false });
  };

  return (
    <>
      <Box className={classes.menuItem}>
        <LevelFilter
          shareholdersLevelList={shareholdersLevelList}
          onLevelChange={setLevel}
          defaultValue={defaultFilterLevelValue}
        />
      </Box>
    </>
  );
};
export default SelectedTableFilterItems;
