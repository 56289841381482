import React from 'react';
import ReactFlow, { Elements, ReactFlowProvider } from 'react-flow-renderer';
import { Moment } from 'moment';
import { VirsComponent } from './VirsComponent';
import { OutletComponent } from './OutletComponent';
import { ShareholderComponent } from './ShareholderComponent';
import { GroupComponent } from './GroupComponent';
import HorizontalFlowChartControls from './TreeChartControls';
import {
  createInitialFlowElements,
  filterElementsByState,
  getLayoutedElements
} from './treeChartFunctions/treeFlowFunctions';
import { ChartVirsData } from '../../../store/shareholdersVirsTree/virsTreeDataTypes';
import { useVirsTreeDispatch, useVirsTreeState } from '../VirsTreeContext';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  flow: {
    width: '100%',
    height: '100%',
    position: 'relative',
    cursor: 'grab',
    '& .react-flow__handle': {
      width: '8px',
      height: '8px'
    },
    '& .react-flow__controls': {
      bottom: 'auto',
      top: '150px',
      boxShadow: 'none'
    }
  }
});

const nodeTypes = {
  virsNode: VirsComponent,
  outletNode: OutletComponent,
  shareholderNode: ShareholderComponent,
  shareholderGroupNode: GroupComponent
};

interface Props {
  chartData: ChartVirsData | undefined;
  activeDate: Moment;
}
const TreeChart: React.FC<Props> = ({ chartData, activeDate }) => {
  const { treeState } = useVirsTreeState();
  const { treeDispatch } = useVirsTreeDispatch();
  const classes = useStyles();

  const expandTree = () => {
    treeDispatch({
      type: 'EXPAND_TREE'
    });
  };

  const collapseTree = () => {
    treeDispatch({
      type: 'COLLAPSE_TREE'
    });
  };

  const setLevel = (level: number) => {
    treeDispatch({
      type: 'SHOW_UP_TO_LEVEL',
      level
    });
  };

  const initialFlowElements: Elements = chartData
    ? createInitialFlowElements(chartData, activeDate.format('L'))
    : [];

  const elementsByState: Elements = chartData
    ? filterElementsByState(chartData, initialFlowElements, treeState.showingShareholders)
    : [];

  const layoutedElements = getLayoutedElements(elementsByState, treeState);

  return (
    <ReactFlowProvider>
      <div className={classes.flow}>
        <ReactFlow
          elements={layoutedElements}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          zoomOnScroll={false}
          nodesConnectable={false}
          minZoom={0.1}
          onLoad={(instance) => setTimeout(() => instance.fitView(), 0)}
        />
        <HorizontalFlowChartControls
          expandTree={expandTree}
          collapseTree={collapseTree}
          setLevel={setLevel}
          showingShareholders={treeState.showingShareholders}
          allShareholders={treeState.allShareholders}
        />
      </div>
    </ReactFlowProvider>
  );
};
export default TreeChart;
