import React from 'react';
import { Button, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUpdateShareholderId,
  showClearShareholderEventDialog,
  showShareholderDeletionDialog
} from '../../../store/shareholders/shareholdersActions';
import { todaysDateString } from '../../../utils/tableDataFunctions';
import {
  ClearShareholderPercentageRecord,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../store/shareholders/shareholdersTypes';
import { useShareholdersSelectedDateTableDispatch } from '../../../containers/ShareholdersTable/SelectedDateTable/TableWithContext';
import {
  formEditShareholderPerson,
  getCurrentDateSigned
} from '../../../containers/ShareholdersTable/SelectedDateTable/utilities/utilityFunctions';
import { ApplicationState } from '../../../store';
import moment from 'moment';
import { Strings } from '../../../utils/strings/Strings';

interface Props {
  index: number;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLButtonElement | null;
  percentageRecord: ClearShareholderPercentageRecord;
  selectedDate?: string;
  isItGroupShareholder?: boolean;
  shareholder: ShareholdersProps;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'right',
    padding: 0,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    border: 'solid 1px #C4E2FC'
  },
  button: {
    justifyContent: 'start',
    height: 'auto',
    margin: '0px',
    padding: '12px 24px 12px 19px',
    borderBottom: 'solid 1px #E5E5E5',
    color: '#637082',
    fill: '#637082',
    '&:hover': {
      backgroundColor: '#F1F1F3'
    },
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  delete: {
    '&:hover': {
      fill: theme.palette.error.main,
      color: theme.palette.error.main
    }
  }
}));

export const ShareholderOptionsPopover: React.FC<Props> = ({
  index,
  open,
  anchorEl,
  onClose,
  percentageRecord,
  selectedDate,
  isItGroupShareholder,
  shareholder
}) => {
  const classes = useStyles();

  const {
    shareholdersData: { addingShareholderDate, allShareholders, lastSignedDate },
    classifiers: { legalFormTypes }
  } = useSelector((state: ApplicationState) => state);
  const reduxDispatch = useDispatch();

  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();

  const {
    closedWithDocument,
    isShareholderFromJADIS,
    shareholderHistory,
    shareholderId,
    shareholderPersons
  } = shareholder;

  const shareholderWithAllEvents =
    allShareholders && allShareholders.find((s) => s.shareholderId === shareholderId);

  const hasAnyDateSigned = shareholderWithAllEvents
    ? shareholderWithAllEvents.shareholderHistory.some(
        (event: ShareholdersHistory) => event.documentStatus === 'PASIRASYTAS'
      )
    : false;

  const currentDateNotAdded = !shareholderHistory.some(
    (event: ShareholdersHistory) => selectedDate === event.eventDate
  );

  const currentDateSigned = getCurrentDateSigned(shareholderHistory, selectedDate);

  const disableClearShareholderData =
    currentDateSigned ||
    currentDateNotAdded ||
    closedWithDocument?.documentStatus === 'PASIRASYTAS' ||
    addingShareholderDate ||
    (lastSignedDate !== undefined && moment(selectedDate).isSameOrBefore(lastSignedDate));

  const disableDeleteShareholder =
    isShareholderFromJADIS ||
    closedWithDocument?.documentStatus === 'PASIRASYTAS' ||
    hasAnyDateSigned;

  const disableEditShareholder =
    closedWithDocument?.documentStatus === 'PASIRASYTAS' || hasAnyDateSigned;

  const handleClearShareholderData = () => {
    reduxDispatch(showClearShareholderEventDialog(percentageRecord, percentageRecord.eventDate));
    onClose();
  };

  const handleShowShareholderDeletionDialog = () => {
    const date = selectedDate || todaysDateString();
    reduxDispatch(showShareholderDeletionDialog(date, shareholderId));
    onClose();
  };

  const handleEditShareholder = () => {
    if (legalFormTypes) {
      reduxDispatch(setUpdateShareholderId(shareholderId));
      tableDispatch({
        type: 'SET_PARENT_ID_TO_ADD_CHILD',
        id: shareholderId,
        index
      });
      tableDispatch({
        type: 'SHOW_EDIT_SHAREHOLDER_ROW',
        showEditShareholderRow: true,
        shareholderToEditList: formEditShareholderPerson(shareholder, legalFormTypes),
        authorizedPersonId:
          shareholderPersons.find((person) => person.isAuthorized)?.personId || null,
        index: index
      });
      if (isItGroupShareholder) {
        tableDispatch({
          type: 'IS_SELECTED_SHAREHOLDER_GROUP',
          isGroup: true
        });
      } else {
        tableDispatch({
          type: 'IS_SELECTED_SHAREHOLDER_GROUP',
          isGroup: false
        });
      }
    }

    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ classes: { root: classes.paper } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Button
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={handleEditShareholder}
        disabled={disableEditShareholder}
      >
        <Typography variant="h5">{Strings.shareholderTooltip__editShareholder}</Typography>
      </Button>
      <Button
        className={`${classes.button} ${classes.delete}`}
        startIcon={<DeleteIcon />}
        onClick={handleClearShareholderData}
        disabled={disableClearShareholderData}
      >
        <Typography variant="h5">{Strings.shareholderTooltip__clearShareholder}</Typography>
      </Button>
      <Button
        className={`${classes.button} ${classes.delete}`}
        startIcon={<DeleteIcon />}
        onClick={handleShowShareholderDeletionDialog}
        disabled={disableDeleteShareholder}
      >
        <Typography variant="h5">{Strings.shareholderTooltip__removeShareholder}</Typography>
      </Button>
    </Popover>
  );
};
