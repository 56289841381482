import React, { useEffect } from 'react';
import { Route, RouteChildrenProps, Switch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VirsDataPageHeader from './VirsDataPageHeader';
import {
  fetchSelectedVirsContactEmailRequest,
  fetchVirsDataByVirsIdRequest,
  resetSelectedPersonId,
  resetVirsDataRequest,
  setSelectedPersonId,
  setSelectedVirsId
} from '../../../store/virsis/actions';
import { ApplicationState } from '../../../store';
import VirsDataSubmissionTablesContainer from './tableContainers/VirsDataSubmissionTablesContainer';
import { getSubsystem } from '../../../utils/roleHelper';
import VirsDataProvidedContainer from './tableContainers/VirsDataProvidedContainer';

export interface TableDisplayStatus {
  enterprise: boolean;
  outlet: boolean;
  shareholders: boolean;
  provided: boolean;
  institutionProvidedPage: boolean;
  externalSystemPage: boolean;
}

export interface VirsDataSubmissionProps {
  virsId?: number;
  handleOutletTabChange?: (id: number | undefined, filterValue: string) => void;
  handleDisplayFilteredTables?: (filterValue: string) => void;
}

interface Param {
  virsId: string | undefined;
  vipId: string | undefined;
}

export const institutionProvidedTables = [
  'professionalMisconductTable',
  'ethicalNonCompliance',
  'externalSystem',
  'fundsReceived',
  'institutionProvidedAll'
];

export const externalSystemTables = [
  'licensesData',
  'politicalAdData',
  'legalEntityData',
  'individualPersonData',
  'externalSystemAll'
];

export type Subsystem = 'KM' | 'LRTK' | 'ZEIT' | 'VIEA' | 'VIRSIS' | 'RC' | 'VIRS_VIEW' | undefined;

const VirsDataPage: React.FC<RouteChildrenProps> = ({ match }) => {
  const { virsId } = useParams<Param>();

  const reduxDispatch = useDispatch();

  const {
    virsis: { virsData, virsDataLoading, currentUser, selectedAccountType }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    if (virsId) reduxDispatch(fetchVirsDataByVirsIdRequest(Number(virsId)));
    return () => {
      reduxDispatch(resetSelectedPersonId());
    };
  }, [reduxDispatch, virsId, currentUser]);

  useEffect(() => {
    if (virsData) {
      reduxDispatch(fetchSelectedVirsContactEmailRequest(Number(virsData.personId)));
      reduxDispatch(setSelectedPersonId(Number(virsData.personId)));
      reduxDispatch(setSelectedVirsId(Number(virsId)));
    }
  }, [reduxDispatch, virsData, virsData?.personId, virsId]);

  useEffect(() => {
    if (!selectedAccountType) {
      return () => {
        reduxDispatch(resetVirsDataRequest());
      };
    }
  }, [reduxDispatch, selectedAccountType]);

  return (
    <>
      <VirsDataPageHeader
        virsData={virsData}
        subsystem={getSubsystem(currentUser)}
        loadingData={virsDataLoading}
      />
      {virsId && virsData && (
        <Switch>
          <Route
            path={`${match?.url}/duomenu-perziura-ir-teikimas`}
            component={VirsDataSubmissionTablesContainer}
          />
          <Route path={`${match?.url}/pateikti-duomenys`} component={VirsDataProvidedContainer} />
        </Switch>
      )}
    </>
  );
};

export default VirsDataPage;
