import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ElDocumentLinks } from './ElDocumentLinks';

const useStyle = makeStyles({
  listItem: {
    paddingLeft: '2em',
    '&:first-child': {
      paddingLeft: 'initial'
    }
  }
});

interface Props {
  documents: {
    documentId: number;
    documentNumber: string;
  }[];
  onClickedOpenInDialog: (documentId: number, documentNumber: string) => void;
}

export const InlineElDocumentLinks: React.FC<Props> = ({ documents, onClickedOpenInDialog }) => {
  const classes = useStyle();

  return (
    <span>
      <ElDocumentLinks
        documents={documents}
        onClickedOpenInDialog={onClickedOpenInDialog}
        className={classes.listItem}
      />
    </span>
  );
};
