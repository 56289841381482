import React from 'react';
import { Container } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import PageFilterButton from '../../../../components/PageButtons/PageFilterButton';
import { ExternalSystemDataFiltersProps } from '../../../../store/externalSystemData/externalSystemDataTypes';
import { ApplicationState } from '../../../../store';

export const dataSubmission: ExternalSystemDataFiltersProps[] = [
  { value: 'enterprise', label: 'Veiklos rūšys', pathname: 'veiklos-rusys' },
  {
    value: 'outlet',
    label: 'Visuomenės informavimo priemonės',
    pathname: 'visuomenes-informavimo-priemones'
  },
  {
    value: 'shareholders',
    label: 'Dalyviai',
    pathname: 'dalyviai'
  },
  {
    value: 'provided',
    label: 'El. dokumentai',
    pathname: 'el-dokumentai',
    roles: ['ROLE_VIRS_EDIT']
  }
];

export const VirsDataSubmissionNavigation: React.FC = () => {
  const history = useHistory();

  const goToPath = (path: string) => {
    history.push(path);
  };
  const location = useLocation();
  const { pathname } = location;
  const { selectedVirsId } = useSelector((state: ApplicationState) => state.virsis);

  const authorities: string[] | undefined = useSelector(
    (state: ApplicationState) => state.virsis.currentUser?.authorities
  );
  const filteredDataSubmission: ExternalSystemDataFiltersProps[] = dataSubmission.filter((header) =>
    header.roles ? header.roles.some((role) => authorities?.includes(role)) : true
  );

  return (
    <Container style={{ marginTop: '10px' }} maxWidth="lg">
      {filteredDataSubmission.map((filter) => {
        return (
          <PageFilterButton
            key={filter.value}
            active={pathname.includes(filter.pathname)}
            onClick={() =>
              goToPath(
                `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/${filter.pathname}`
              )
            }
            label={filter.label}
          />
        );
      })}
    </Container>
  );
};
