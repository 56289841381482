import { Reducer } from 'redux';
import {
  FETCH_AUDITS_DATA_ERROR,
  FETCH_AUDITS_DATA_REQUEST,
  FETCH_AUDITS_DATA_SUCCESS,
  FETCH_AUDIT_ACTION_TYPES_ERROR,
  FETCH_AUDIT_ACTION_TYPES_REQUEST,
  FETCH_AUDIT_ACTION_TYPES_SUCCESS,
  FETCH_AUDIT_OUTLET_TYPES_ERROR,
  FETCH_AUDIT_OUTLET_TYPES_REQUEST,
  FETCH_AUDIT_OUTLET_TYPES_SUCCESS,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_ERROR,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST,
  FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_SUCCESS,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_ERROR,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST,
  FETCH_VIRSIS_SENDS_TO_SYSTEM_SUCCESS,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_ERROR,
  FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_SUCCESS,
  FETCH_ARCHIVED_DATA_REQUEST,
  FETCH_ARCHIVED_DATA_SUCCESS,
  FETCH_ARCHIVED_DATA_ERROR,
  DELETE_ARCHIVED_DATA_REQUEST,
  DELETE_ARCHIVED_DATA_SUCCESS,
  DELETE_ARCHIVED_DATA_ERROR,
  RESET_DELETE_ARCHIVED_DATA,
  RESET_AUDITS_DATA_STATE,
  RESET_INTEGRATED_SYSTEMS_AUDITS_DATA_STATE
} from './auditsActionTypes';
import {
  AuditActionType,
  AuditData,
  AuditOutletType,
  ExternalSytemInfoData,
  IntegratedSystemsAuditData
} from './auditsTypes';

export interface AuditsDataState {
  loadingAuditsData: boolean;
  auditsDataError?: Error;
  auditsData?: AuditData[];
  loadingAuditOutletTypes: boolean;
  auditOutletTypesError?: Error;
  auditOutletTypes?: AuditOutletType[];
  loadingAuditActionTypes: boolean;
  auditActionTypes?: AuditActionType[];
  auditActionTypesError?: Error;
  loadingSendsToSystemData: boolean;
  sendsToSystemData?: ExternalSytemInfoData[];
  sendsToSystemDataError?: Error;
  loadingReceivesFromsSystemData: boolean;
  receivesFromsSystemData?: ExternalSytemInfoData[];
  receivesFromsSystemDataError?: Error;
  loadingIntegratedSystemsAudits: boolean;
  integratedSystemsAuditsData?: IntegratedSystemsAuditData[];
  integratedSystemsAuditsDataError?: Error;
  loadingArchivedData: boolean;
  archivedData?: string;
  archivedDataError?: Error;
  loadingDeleteArchivedData: boolean;
  deletedArchivedData: boolean;
  deleteArchivedDataError?: Error;
}

export const initialState: AuditsDataState = {
  loadingAuditsData: false,
  auditsDataError: undefined,
  auditsData: undefined,
  loadingAuditOutletTypes: false,
  auditOutletTypesError: undefined,
  auditOutletTypes: undefined,
  loadingAuditActionTypes: false,
  auditActionTypes: undefined,
  auditActionTypesError: undefined,
  loadingSendsToSystemData: false,
  sendsToSystemData: undefined,
  sendsToSystemDataError: undefined,
  loadingReceivesFromsSystemData: false,
  receivesFromsSystemData: undefined,
  receivesFromsSystemDataError: undefined,
  loadingIntegratedSystemsAudits: false,
  integratedSystemsAuditsData: undefined,
  integratedSystemsAuditsDataError: undefined,
  loadingArchivedData: false,
  archivedData: undefined,
  archivedDataError: undefined,
  loadingDeleteArchivedData: false,
  deletedArchivedData: false,
  deleteArchivedDataError: undefined
};

export const auditsReducer: Reducer<AuditsDataState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUDITS_DATA_REQUEST: {
      return { ...state, loadingAuditsData: true, auditsDataError: undefined };
    }
    case FETCH_AUDITS_DATA_SUCCESS: {
      return { ...state, loadingAuditsData: false, auditsData: action.payload };
    }
    case FETCH_AUDITS_DATA_ERROR: {
      return {
        ...state,
        loadingAuditsData: false,
        auditsDataError: action.payload
      };
    }
    case RESET_AUDITS_DATA_STATE: {
      return {
        ...state,
        loadingAuditsData: false,
        auditsDataError: undefined,
        auditsData: undefined
      };
    }
    case FETCH_AUDIT_OUTLET_TYPES_REQUEST: {
      return { ...state, loadingAuditOutletTypes: true };
    }
    case FETCH_AUDIT_OUTLET_TYPES_SUCCESS: {
      return {
        ...state,
        loadingAuditOutletTypes: false,
        auditOutletTypes: action.payload
      };
    }
    case FETCH_AUDIT_OUTLET_TYPES_ERROR: {
      return {
        ...state,
        loadingAuditOutletTypes: false,
        auditOutletTypesError: action?.payload?.data
      };
    }
    case RESET_INTEGRATED_SYSTEMS_AUDITS_DATA_STATE: {
      return {
        ...state,
        loadingIntegratedSystemsAudits: false,
        integratedSystemsAuditsData: undefined,
        integratedSystemsAuditsDataError: undefined
      };
    }
    case FETCH_AUDIT_ACTION_TYPES_REQUEST:
      return {
        ...state,
        loadingAuditActionTypes: true
      };
    case FETCH_AUDIT_ACTION_TYPES_SUCCESS:
      return {
        ...state,
        loadingAuditActionTypes: false,
        auditActionTypes: action.payload
      };
    case FETCH_AUDIT_ACTION_TYPES_ERROR:
      return {
        ...state,
        loadingAuditActionTypes: false,
        auditActionTypesError: action.payload
      };
    case FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST:
      return {
        ...state,
        loadingReceivesFromsSystemData: true
      };
    case FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_SUCCESS:
      return {
        ...state,
        loadingReceivesFromsSystemData: false,
        receivesFromsSystemData: action.payload
      };
    case FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_ERROR:
      return {
        ...state,
        loadingReceivesFromsSystemData: false,
        receivesFromsSystemDataError: action.payload
      };
    case FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST:
      return {
        ...state,
        loadingSendsToSystemData: true
      };
    case FETCH_VIRSIS_SENDS_TO_SYSTEM_SUCCESS:
      return {
        ...state,
        loadingSendsToSystemData: false,
        sendsToSystemData: action.payload
      };
    case FETCH_VIRSIS_SENDS_TO_SYSTEM_ERROR:
      return {
        ...state,
        loadingSendsToSystemData: false,
        sendsToSystemDataError: action.payload
      };

    case FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST: {
      return {
        ...state,
        loadingIntegratedSystemsAudits: true,
        integratedSystemsAuditsDataError: undefined
      };
    }
    case FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_SUCCESS: {
      return {
        ...state,
        loadingIntegratedSystemsAudits: false,
        integratedSystemsAuditsData: action.payload
      };
    }
    case FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_ERROR: {
      return {
        ...state,
        loadingIntegratedSystemsAudits: false,
        integratedSystemsAuditsDataError: action.payload
      };
    }
    case FETCH_ARCHIVED_DATA_REQUEST: {
      return {
        ...state,
        loadingArchivedData: true,
        archivedDataError: undefined
      };
    }
    case FETCH_ARCHIVED_DATA_SUCCESS: {
      return {
        ...state,
        loadingArchivedData: false,
        archivedDataError: undefined
      };
    }
    case FETCH_ARCHIVED_DATA_ERROR: {
      return {
        ...state,
        loadingArchivedData: false,
        archivedDataError: action.payload
      };
    }
    case DELETE_ARCHIVED_DATA_REQUEST: {
      return {
        ...state,
        deleteArchivedDataError: undefined,
        loadingDeleteArchivedData: true
      };
    }
    case DELETE_ARCHIVED_DATA_SUCCESS: {
      return {
        ...state,
        loadingDeleteArchivedData: false,
        deletedArchivedData: true,
        deleteArchivedDataError: undefined
      };
    }
    case DELETE_ARCHIVED_DATA_ERROR: {
      return {
        ...state,
        loadingDeleteArchivedData: false,
        deleteArchivedDataError: action.payload
      };
    }
    case RESET_DELETE_ARCHIVED_DATA: {
      return {
        ...state,
        deletedArchivedData: false,
        deleteArchivedDataError: undefined
      };
    }
    default: {
      return state;
    }
  }
};
