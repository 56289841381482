import moment from 'moment';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  fetchVirsTreeData,
  fetchVirsDataError,
  fetchVirsDataOfDate,
  fetchVirsDataSuccess,
  loadTimelineEvents,
  setTimelineActiveDate,
  setTimelineError,
  setTimelineEvents,
  setTimelineLoading
} from './virsTreeActions';
import {
  LOAD_TIMELINE_EVENTS,
  VIRS_TREE_REQUEST,
  VIRS_TREE_REQUEST_OF_DATE
} from './virsTreeActionTypes';
import { getVirsTreeData, getVirsTreeTimelineEvents } from './virsTreeApi';
import { TimelineEvent } from './virsTreeDataTypes';
import { virsData } from '../virsis/selectors';
import { ApplicationState } from '..';

function* handleVirsTreeDataRequest(action: ActionType<typeof fetchVirsTreeData>) {
  try {
    // eslint-disable-next-line
    const { date, virsId } = action.payload;

    const showUnsigned: boolean = yield select(
      (state: ApplicationState) => state.shareholdersData.showUnsigned
    );

    const { data } = yield call(getVirsTreeData, date, virsId, showUnsigned);
    yield put(fetchVirsDataSuccess(data));
    yield put(setTimelineActiveDate(moment(data.date)));
  } catch (err) {
    yield put(fetchVirsDataError(err.response));
  }
}

function* handleVirsTreeDataOfDateRequest(action: ActionType<typeof fetchVirsDataOfDate>) {
  try {
    const date = action.payload;

    const showUnsigned: boolean = yield select(
      (state: ApplicationState) => state.shareholdersData.showUnsigned
    );

    const { virsId: currentVirsId } = yield select(virsData);
    const { data } = yield call(getVirsTreeData, date, currentVirsId, showUnsigned);
    yield put(fetchVirsDataSuccess(data));
    yield put(setTimelineActiveDate(moment(data.date)));
  } catch (err) {
    yield put(fetchVirsDataError(err.response));
  }
}

export function* loadTimelineEventsTask(action: ActionType<typeof loadTimelineEvents>) {
  yield put(setTimelineLoading(true));

  try {
    const showUnsigned: boolean = yield select(
      (state: ApplicationState) => state.shareholdersData.showUnsigned
    );

    const { data } = yield call(getVirsTreeTimelineEvents, action.payload, showUnsigned);
    const virsEvents: TimelineEvent[] = data.virsEvents.map((value: TimelineEvent) => ({
      ...value,
      date: moment(value.date)
    }));

    yield put(setTimelineEvents(virsEvents, data.activityPeriods));
  } catch (error) {
    yield put(setTimelineError('Nepavyko gauti laiko juostos duomenų'));
  }
}

function* virsTreeSaga() {
  yield takeEvery(VIRS_TREE_REQUEST, handleVirsTreeDataRequest);
  yield takeEvery(VIRS_TREE_REQUEST_OF_DATE, handleVirsTreeDataOfDateRequest);
  yield takeEvery(LOAD_TIMELINE_EVENTS, loadTimelineEventsTask);
}

export default virsTreeSaga;
