import React, { createContext, Dispatch, useContext, useReducer } from 'react';

import FundsReceivedLegalTable from './Table';
import {
  FundsReceivedLegalTableAction,
  FundsReceivedLegalTableState
} from './tableState/tableTypesAndActions';
import {
  fundsReceivedLegalTableReducer,
  initialFundsReceivedLegalTableState
} from './tableState/tableReducer';

export const FundsReceivedLegalTableStateContext = createContext<{
  state: FundsReceivedLegalTableState;
}>({
  state: initialFundsReceivedLegalTableState
});

export const FundsReceivedLegalTableDispatchContext = createContext<{
  dispatch: Dispatch<FundsReceivedLegalTableAction>;
}>({
  dispatch: () => {}
});

export const FundsReceivedInstDataTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(
    fundsReceivedLegalTableReducer,
    initialFundsReceivedLegalTableState
  );
  return (
    <FundsReceivedLegalTableStateContext.Provider value={{ state }}>
      <FundsReceivedLegalTableDispatchContext.Provider value={{ dispatch }}>
        <FundsReceivedLegalTable />
      </FundsReceivedLegalTableDispatchContext.Provider>
    </FundsReceivedLegalTableStateContext.Provider>
  );
};

const withFundsReceivedInstTableContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC =>
  (props) =>
    (
      <FundsReceivedInstDataTableWithContext>
        <Component {...(props as P)} />
      </FundsReceivedInstDataTableWithContext>
    );

const FundsReceivedDataTableLegal = withFundsReceivedInstTableContext(FundsReceivedLegalTable);

function useFundsReceivedLegalTableState() {
  return useContext(FundsReceivedLegalTableStateContext);
}
function useFundsReceivedLegalTableDispatch() {
  return useContext(FundsReceivedLegalTableDispatchContext);
}

export {
  FundsReceivedDataTableLegal,
  useFundsReceivedLegalTableState,
  useFundsReceivedLegalTableDispatch
};
