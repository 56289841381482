import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableHead, TableRow, TableCell, Typography } from '@material-ui/core';

import { SortOrder } from '../../../utils/tableTypes';

import { ClassifierSortingLabel } from './ClassifierSortingLabel';

import { getVisibleColumns } from './utility/tableDataUtility';
import { Actions } from './state/actions';
import { Column } from './types';
import { State } from './state/reducer';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import SortIcon from '@material-ui/icons/UnfoldMore';

interface Props<TData> {
  actions: Actions<TData>;
}

interface Header<TData> {
  colSpan: number;
  rowSpan: number;
  header: string;
  column?: Column<TData> & { order?: SortOrder };
}

export const ClassifierHeader = <TData,>({ actions }: Props<TData>) => {
  const {
    tableDefinition: { columnGroups },
    columnVisibility
  } = useSelector((state: State<TData>) => state);

  const headersRow1: Header<TData>[] = [];
  const headersRow2: Header<TData>[] = [];

  columnGroups.forEach(({ header, columns }) => {
    const visibleColumns = getVisibleColumns(columnVisibility, columns);
    if (header && visibleColumns.length > 0) {
      headersRow1.push({
        header,
        rowSpan: 1,
        colSpan: visibleColumns.length
      });

      headersRow2.push(
        ...visibleColumns.map((column) => ({
          colSpan: 1,
          rowSpan: 1,
          header: column.header,
          column
        }))
      );
    } else {
      headersRow1.push(
        ...visibleColumns.map((column) => ({
          header: column.header,
          rowSpan: 2,
          colSpan: 1,
          column
        }))
      );
    }
  });

  const dispatch = useDispatch();
  const { field, direction } = useSelector((state: State<TData>) => state.columnSort);

  function getSortLabelProps(tableColumn: Column<TData>) {
    return {
      active: true,
      direction,
      IconComponent: field === tableColumn.field ? ActiveSortIcon : SortIcon
    };
  }

  function getSortFn(tableColumn: Column<TData>) {
    return () => {
      if (field !== tableColumn.field) {
        dispatch(actions.setSort(tableColumn.field, 'asc'));
      } else {
        dispatch(actions.setSort(tableColumn.field, direction === 'asc' ? 'desc' : 'asc'));
      }
    };
  }

  return (
    <TableHead>
      <TableRow>
        {headersRow1.map(({ rowSpan, header, colSpan, column }) => {
          const sortLabelProps = column ? getSortLabelProps(column) : null;
          const sortFn = column ? getSortFn(column) : undefined;

          return (
            <TableCell
              key={Math.random()}
              rowSpan={rowSpan}
              colSpan={colSpan}
              className={column ? 'TableCellSortable' : ''}
              onClick={sortFn}
              sortDirection={field === column?.field ? direction : false}
            >
              {!column && <Typography variant="h4">{header}</Typography>}

              {column && (
                <ClassifierSortingLabel column={column} actions={actions} {...sortLabelProps} />
              )}
            </TableCell>
          );
        })}

        <TableCell rowSpan={2} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {headersRow2.map(({ rowSpan, header, colSpan, column }) => {
          const sortLabelProps = column ? getSortLabelProps(column) : null;
          const sortFn = column ? getSortFn(column) : undefined;

          return (
            <TableCell
              key={Math.random()}
              rowSpan={rowSpan}
              colSpan={colSpan}
              className={column ? 'TableCellSortable' : ''}
              onClick={sortFn}
              sortDirection={field === column?.field ? direction : false}
            >
              {!column && <Typography variant="h4">{header}</Typography>}

              {column && (
                <ClassifierSortingLabel column={column} actions={actions} {...sortLabelProps} />
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
