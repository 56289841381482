import React, { useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikFormSelectField } from '../../../components/FormikFields/FormikFormSelectField';
import { GraphicalVersion } from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';
import { setShownGraphicalRepresentationRecord } from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsActions';
import { ApplicationState } from '../../../store';
import { GenericBooleanField } from '../../../components/FormikFields/GenericFormikWrappers/GenericBooleanField';

const FormStyles = makeStyles((theme) => ({
  inputLabel: {
    margin: '4px auto auto auto',
    width: '185px',
    lineHeight: '25px',
    textAlign: 'left'
  },
  inputContainer: {
    margin: '4px',
    display: 'grid',
    gridTemplateColumns: '185px 320px 10px',
    '& button': {
      padding: '4px'
    }
  },
  selectInput: {
    width: '320px'
  },
  form: {
    display: 'flex'
  },
  [theme.breakpoints.down('sm')]: {
    form: {
      flexWrap: 'wrap'
    }
  },
  [theme.breakpoints.down('xs')]: {
    selectInput: {
      width: '100%'
    },
    inputContainer: {
      flexWrap: 'wrap',
      display: 'flex',
      gap: 10,
      width: '100%'
    },
    inputLabel: {
      margin: 0,
      width: 'unset'
    }
  }
}));

interface AutoSubmitOnChangeProps<TData> {
  formikProps: FormikProps<TData>;
}

interface Props {
  graphicalRepresentationVersion: GraphicalVersion[];
}

const AutoSubmitOnChange: React.FC<AutoSubmitOnChangeProps<any>> = ({
  formikProps: { values, handleSubmit }
}) => {
  useEffect(() => {
    handleSubmit();
  }, [values, handleSubmit]);

  return null;
};

const GraphicalRepresentationVersionSelector: React.FC<Props> = ({
  graphicalRepresentationVersion
}) => {
  const classes = FormStyles();
  const reduxDispatch = useDispatch();
  const { selectedGraphicalVersionId } = useSelector(
    (state: ApplicationState) => state.graphicalRepresentation
  );

  const handleValidation = Yup.object().shape({
    active: Yup.boolean(),
    version: Yup.object()
      .shape({
        graphicalVersionId: Yup.number(),
        validFrom: Yup.string()
      })
      .typeError('')
      .required('')
  });

  const sortedOptions = graphicalRepresentationVersion?.sort(
    (one, two) => new Date(two.validFrom).getTime() - new Date(one.validFrom).getTime()
  );

  const [isActive] = sortedOptions;

  useEffect(() => {
    if (!selectedGraphicalVersionId && isActive?.graphicalVersionId)
      reduxDispatch(setShownGraphicalRepresentationRecord(isActive.graphicalVersionId, false));
  }, [isActive?.graphicalVersionId, reduxDispatch, selectedGraphicalVersionId]);

  const getOptionLabel = (option: GraphicalVersion) => {
    const isActiveVersion = option.isActive ? 'Aktyvus' : 'Neaktyvus';

    return `${option.validFrom} (${option.isPublished ? isActiveVersion : 'Ruošiamas'})`;
  };

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={handleValidation}
        initialValues={{ version: isActive, active: false }}
        onSubmit={(record) => {
          if (record.version && record.version.graphicalVersionId !== null)
            reduxDispatch(
              setShownGraphicalRepresentationRecord(
                record.version.graphicalVersionId,
                record.active
              )
            );
        }}
      >
        {(formikProps) => {
          const { values, errors, setFieldValue } = formikProps;
          return (
            <Form className={classes.form}>
              <FormikFormSelectField
                isClearable={false}
                styledClasses={classes}
                getOptionLabel={(option?: GraphicalVersion) =>
                  option ? getOptionLabel(option) : ''
                }
                isRequired
                label="Publikuota versija"
                formikKey="version"
                value={values.version}
                options={sortedOptions}
                errors={errors.version}
                setFieldValue={setFieldValue}
                defaultValue={isActive}
              />
              <GenericBooleanField
                label="rodyti tik aktyvias korteles"
                field="active"
                formikProps={formikProps}
              />
              <AutoSubmitOnChange formikProps={formikProps} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default GraphicalRepresentationVersionSelector;
