import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DocumentActionButton } from '../../components/TableButtons/RowActionButtons/DocumentActionButton';
import { AuditData } from '../../store/audits/auditsTypes';
import { useAuditsTableState } from './Context';

interface Props {
  record: AuditData;
  viewInDialog: () => void;
}

export const AuditsTableRow: React.FC<Props> = ({ record, viewInDialog }) => {
  const {
    state: { columnsDisplayStatus }
  } = useAuditsTableState();

  return (
    <TableRow>
      {columnsDisplayStatus.auditActionTypeName && (
        <TableCell>{record.auditActionTypeName}</TableCell>
      )}

      {columnsDisplayStatus.actionUserFullName && (
        <TableCell>{record.actionUserFullName}</TableCell>
      )}
      {columnsDisplayStatus.actionLegalPersonFullName && (
        <TableCell>{record.actionLegalPersonFullName}</TableCell>
      )}
      {columnsDisplayStatus.actionDateTime && <TableCell>{record.actionDateTime}</TableCell>}
      {columnsDisplayStatus.relatedVirs && <TableCell>{record.relatedVirs}</TableCell>}
      {columnsDisplayStatus.relatedOutlet && <TableCell>{record.relatedOutlet}</TableCell>}
      {columnsDisplayStatus.relatedOutletTypeName && (
        <TableCell>{record.relatedOutletTypeName}</TableCell>
      )}
      {columnsDisplayStatus.relatedPersonFullName && (
        <TableCell>{record.relatedPersonFullName}</TableCell>
      )}
      {columnsDisplayStatus.ipAddress && <TableCell>{record.ipAddress}</TableCell>}
      {columnsDisplayStatus.auditDetails && <TableCell>{record.auditDetails}</TableCell>}
      <TableCell align="right" variant="head">
        <DocumentActionButton
          IconComponent={VisibilityIcon}
          text="Peržiūrėti"
          onClick={viewInDialog}
        />
      </TableCell>
    </TableRow>
  );
};
