import React from 'react';
import { FilterByPeriodCell } from '../../components/TableCustomFilterCell/FilterByPeriodCell';
import { Period } from './tableState/tableInitialStateAndTypes';
import { useProvidedDataHistoryTableDispatch, useProvidedDataHistoryTableState } from './Context';

export const ProvidedDataHistoryTablePeriodFilter: React.FC = () => {
  const { state } = useProvidedDataHistoryTableState();
  const { dispatch } = useProvidedDataHistoryTableDispatch();

  const handlePeriodFilterChange = (value: Period) => {
    dispatch({ type: 'PERIOD_FILTER_VALUE_CHANGED', value });
  };

  return (
    <FilterByPeriodCell
      setPeriodFilter={handlePeriodFilterChange}
      periodFilter={state.periodFilter}
    />
  );
};
