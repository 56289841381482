import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  EditionCheckDataInstTableHeaderType,
  EditionCheckDataTableField
} from './tableState/tableTypesAndActions';
import { EditionCheckDataTableStateContext } from './Context';
import { ApplicationState } from '../../store';
import { customFilterValueChanged } from '../../store/editionChecksTable/editionChecksTableActions';
import { getUniqueOptions } from '../../utils/tableDataFunctions';
import { EditionCheckCustomFilterOptions } from './tableState/tableTypesAndActions';
import { TableCustomFilterForOptionCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterForOptionCellGeneric';

interface Props {
  columnNames: EditionCheckDataInstTableHeaderType[];
}

const EditionCheckDataTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const reduxDispatch = useDispatch();
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const handleSetCustomFilter = (column: EditionCheckDataTableField, value: string | null) => {
    reduxDispatch(customFilterValueChanged(column, value));
  };
  const { instTableFilters } = useSelector((state: ApplicationState) => state.editionData) || [];

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterForOptionCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions<
                EditionCheckCustomFilterOptions,
                EditionCheckDataTableField
              >(instTableFilters, column.id)}
            />
          ))}
        {[...Array(2)].map((e, i) => (
          <TableCell
            style={{
              borderColor: '#C4E2FC',
              borderTop: 'none',
              borderBottom: 'none'
            }}
            key={i}
          />
        ))}
      </TableRow>
    </TableBody>
  );
};
export default EditionCheckDataTableCustomFilter;
