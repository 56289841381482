import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import EmailDialog from './Dialog/EmailDialog';

const EditEmailDialog: React.FC = () => {
  const {
    currentUser,
    contactEmail,
    selectedVirsContactEmail,
    emailEditDialogOpen,
    virsData,
    isVirsEmailBeingEdited
  } = useSelector((state: ApplicationState) => state.virsis);

  return (
    <EmailDialog
      personId={isVirsEmailBeingEdited && virsData ? virsData.personId : currentUser?.personId || 0}
      contactEmail={isVirsEmailBeingEdited ? selectedVirsContactEmail : contactEmail}
      emailDialogOpen={emailEditDialogOpen}
      disableBackdropClick={false}
    />
  );
};

export default EditEmailDialog;
