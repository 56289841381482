import _ from 'lodash';
import {
  FundsReceivedLegalTableColumnsDisplayStatus,
  FundsReceivedLegalTableCustomFilter,
  FundsReceivedLegalTableField,
  FundsReceivedLegalTableHeader
} from './tableTypesAndActions';
import { FundsReceivedDataLegal } from '../../../store/fundsReceived/fundsReceivedDataTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function updateFundsReceivedLegalTableCustomFilter(
  filter: FundsReceivedLegalTableCustomFilter,
  filterBy: FundsReceivedLegalTableField,
  value: string | null
): FundsReceivedLegalTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function getTableExportDefinition(
  columnHeaders: FundsReceivedLegalTableHeader[],
  displayStatus: FundsReceivedLegalTableColumnsDisplayStatus
): TableExportDefinition<FundsReceivedDataLegal> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    FundsReceivedDataLegal,
    FundsReceivedLegalTableField
  >(columnHeaders, displayStatus);

  return {
    title: 'VIRS gautos lėšos',
    columnGroups: [
      {
        columns: [
          mappedValueColumn(
            'virsName',
            ({ virsName, virsLegalCode }) => [
              {
                values: [virsName, `${virsLegalCode}`],
                style: {
                  alignment: { horizontal: 'left' }
                }
              }
            ],
            { width: 45 }
          ),
          stringValueColumn('fundsReceivedYear', { width: 10 }),
          stringValueColumn('fundsReceivedSum', { width: 15 }),
          stringValueColumn('fundsSourceName', { width: 45 }),
          stringValueColumn('fundsSourceCode', { width: 25 }),
          mappedValueColumn(
            'fundsReceivedOutlets',
            ({ fundsReceivedOutlets }) => [
              {
                values: fundsReceivedOutlets.map(({ outletName }) => outletName)
              }
            ],
            { width: 45 }
          ),
          mappedValueColumn(
            'transaction',
            ({ transaction }) => [{ values: [transaction.transactionTypeName] }],
            { width: 25 }
          )
        ]
      }
    ]
  };
}
