import React from 'react';

import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  useProfessionalMisconductTableState,
  useProfessionalMisconductTableDispatch
} from './Context';
import { ProfessionalMisconductTableField } from './tableState/tableInitialStateAndTypes';

const MisconductTableHeader = () => {
  const {
    state: {
      columnsDisplayStatus: {
        decisionDate,
        documentNumber,
        misconductDeterminedBy,
        outletName,
        professionalMisconduct,
        sanction,
        validFrom,
        validTo
      },
      sortBy,
      order
    }
  } = useProfessionalMisconductTableState();
  const { dispatch: tableDispatch } = useProfessionalMisconductTableDispatch();

  const handleSetSortByColumn = (column: ProfessionalMisconductTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const calculateSharedCellColSpan = (displayingColumns: boolean[]): number =>
    displayingColumns.filter((value) => value).length;

  return (
    <TableHead>
      <TableRow>
        {(decisionDate || documentNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedCellColSpan([decisionDate, documentNumber])}
          >
            <Typography variant="h4">Sprendimo</Typography>
          </TableCell>
        )}
        {outletName && (
          <TableCell
            rowSpan={2}
            key="outletName"
            sortDirection={sortBy === 'outletName' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('outletName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'outletName' ? order : 'asc'}
              IconComponent={sortBy === 'outletName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Susijusios VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {professionalMisconduct && (
          <TableCell
            rowSpan={2}
            key="professionalMisconduct"
            sortDirection={sortBy === 'professionalMisconduct' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('professionalMisconduct')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'professionalMisconduct' ? order : 'asc'}
              IconComponent={sortBy === 'professionalMisconduct' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nustatytas pažeidimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {sanction && (
          <TableCell
            rowSpan={2}
            key="sanction"
            sortDirection={sortBy === 'sanction' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('sanction')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'sanction' ? order : 'asc'}
              IconComponent={sortBy === 'sanction' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Poveikio priemonė(-s)</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(validFrom || validTo) && (
          <TableCell rowSpan={1} colSpan={calculateSharedCellColSpan([validFrom, validTo])}>
            <Typography variant="h4">Pažeidimas galioja</Typography>
          </TableCell>
        )}
        {misconductDeterminedBy && (
          <TableCell
            rowSpan={2}
            key="misconductDeterminedBy"
            sortDirection={sortBy === 'misconductDeterminedBy' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('misconductDeterminedBy')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'misconductDeterminedBy' ? order : 'asc'}
              IconComponent={sortBy === 'misconductDeterminedBy' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Pažeidimą nustatė</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell rowSpan={2} className="column-of-buttons" align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {decisionDate && (
          <TableCell
            rowSpan={1}
            key="decisionDate"
            sortDirection={sortBy === 'decisionDate' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('decisionDate')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'decisionDate' ? order : 'asc'}
              IconComponent={sortBy === 'decisionDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Data</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {documentNumber && (
          <TableCell
            rowSpan={1}
            key="documentNumber"
            sortDirection={sortBy === 'documentNumber' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('documentNumber')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'documentNumber' ? order : 'asc'}
              IconComponent={sortBy === 'documentNumber' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nr.</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {validFrom && (
          <TableCell
            rowSpan={1}
            key="validFrom"
            sortDirection={sortBy === 'validFrom' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('validFrom')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'validFrom' ? order : 'asc'}
              IconComponent={sortBy === 'validFrom' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {validTo && (
          <TableCell
            rowSpan={1}
            key="validTo"
            sortDirection={sortBy === 'validTo' ? order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('validTo')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'validTo' ? order : 'asc'}
              IconComponent={sortBy === 'validTo' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Iki</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default MisconductTableHeader;
