import React, { ReactNode, useCallback, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCell, Box, TextField } from '@material-ui/core';
import {
  OutletRecord,
  OutletType,
  OutletData,
  LibisSearchData
} from '../../../store/outlets/outletsTypes';
import { ApplicationState } from '../../../store';
import {
  resetOutletUpdatingState,
  resetOutletRemovingState,
  removeOutlet,
  updateOutlet
} from '../../../store/outlets/outletsActions';
import { intialOutletFullEditRowState } from './rowState/outletRowStateAndTypes';
import { SearchLibisDialog } from './SearchLibisDialog/SearchLibisDialog';
import { OutletBasicRow } from './RowBasic';
import { RowActionButtonGeneric } from '../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { fullyEditOutletReducer } from './rowState/outletReducerFull';
import { StringInputCell } from '../../../components/TableInputs/StringInput';
import { EditAndRemoveActions } from '../../../components/TableRowActions/EditAndRemoveActions';
import BtnOpenNewDoc from '../../../components/TableButtons/BtnOpenNewDoc';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { determineRecordStatus } from '../../../utils/tableDataFunctions';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { OutletDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { validatedEditOutletState } from './rowState/outletRowReducerFunctions';
import { CustomEditIconButton } from '../../../components/Icons/IconButtons/CustomEditIconButton';
import { VirsisYesNoString } from '../../../utils/tableTypes';
import { useOutletsTableDispatch, useOutletsTableState } from '../Context';

interface RowContainerProps {
  enterprise: EnterpriseData;
  record: OutletData;
  virsLegalRegistrationDate: string;
  virsLegalDeregistrationDate: string | null;
  redirectButtonLabel?: string;
  toggleRowExtension?: () => void;
  isExtended?: boolean;
  rowExtensionComponent?: ReactNode;
  redirectToOutletInfo?: () => void;
  display: OutletDataTableColumnsDisplayStatus;
  resetColumnDisplay?: () => void;
}

const OutletRowContainerFullyEditable: React.FC<RowContainerProps> = ({
  enterprise,
  record,
  toggleRowExtension,
  isExtended,
  virsLegalRegistrationDate,
  virsLegalDeregistrationDate,
  redirectButtonLabel,
  redirectToOutletInfo,
  rowExtensionComponent,
  display,
  resetColumnDisplay
}) => {
  const [rowState, rowDispatch] = useReducer(fullyEditOutletReducer, intialOutletFullEditRowState);
  const { dispatch: tableDispatch } = useOutletsTableDispatch();

  const { currentUser, virsData } = useSelector(
    (stateGlobal: ApplicationState) => stateGlobal.virsis
  );

  const {
    updatingOutlet,
    isOutletUpdated,
    removingOutlet,
    isOutletRemoved,
    removingOutletError,
    outletGroups
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal.outletData);

  const reduxDispatch = useDispatch();

  const { state: tableState } = useOutletsTableState();

  const recordStatus = determineRecordStatus(record);

  const outletUsesLibis: boolean = rowState?.outletType.value?.usesLibis === VirsisYesNoString.YES;

  function activateEditing() {
    rowDispatch({
      type: 'EDIT_BUTTON_CLICKED',
      outlet: record,
      enterprise,
      outletGroups: outletGroups || [],
      registrationDate: virsLegalRegistrationDate,
      deregistrationDate: virsLegalDeregistrationDate
    });
    tableDispatch({ type: 'EDITING_ON' });
    if (resetColumnDisplay) {
      resetColumnDisplay();
    }
  }

  function openLibisDialog() {
    rowDispatch({ type: 'LIBIS_SEARCH_OPENED' });
  }

  function closeLibisDialog() {
    rowDispatch({ type: 'LIBIS_SEARCH_CLOSED' });
  }

  function toggleLibisOutletSelection(outlet: LibisSearchData) {
    rowDispatch({ type: 'LIBIS_OUTLET_CLICKED', outlet });
  }

  function populateNewRecordWithLibisOutletData() {
    rowDispatch({ type: 'POPULATE_RECORD_WITH_LIBIS_DATA_CLICKED' });
  }

  function setOutletName(name: string | null) {
    rowDispatch({ type: 'OUTLET_NAME_CHANGED', name });
  }

  function setOutletType(outletType: OutletType | null) {
    rowDispatch({ type: 'OUTLET_TYPE_CHANGED', outletType });
  }

  function setOutletEstablishedDate(established: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_ESTABLISHED_DATE_CHANGED', established });
  }

  function setOutletControlledFrom(controlledFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_CONTROLLED_FROM_CHANGED', controlledFrom });
  }

  function setOutletControlledTo(controlledTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_CONTROLLED_TO_CHANGED', controlledTo });
  }

  function setOutletInternationalNumber(value: string | null) {
    rowDispatch({ type: 'OUTLET_INTERNATIONAL_NUMBER_CHANGED', value });
  }

  function updateOutletRecord() {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validatedEditOutletState(rowState).updateConfirmationOn) {
      const message = 'VIP įrašas atnaujintas';
      const updated: OutletRecord = {
        outletName: rowState.name.value,
        outletTypeId: rowState.outletType.value
          ? rowState.outletType.value.outletTypeId
          : record.outletTypeId,
        establishedDate: rowState.established.value ? rowState.established.value.format('L') : null,
        controlledFrom: rowState.controlledFrom.value
          ? rowState.controlledFrom.value.format('L')
          : null,
        controlledTo: rowState.controlledTo.value ? rowState.controlledTo.value.format('L') : null,
        isbn: rowState.ISBN || rowState.internationalNumber.value,
        issn: rowState.ISSN,
        enterpriseId: record.enterpriseId
      };
      reduxDispatch(updateOutlet(virsData?.virsId, record.virOutletControlId, updated, message));
    }
  }

  function onRemoveRecordClick() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'CANCEL_REMOVING_RECORD_CLICKED' });
  }

  function removeOutletRecord() {
    if (virsData && currentUser) {
      reduxDispatch(removeOutlet(virsData.virsId, record.virOutletControlId));
    }
  }

  function onOutletRemoveError() {
    rowDispatch({ type: 'REMOVE_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetOutletRemovingState());
  }

  const deactivateEditing = useCallback(() => {
    rowDispatch({ type: 'CANCEL_EDITING_BUTTON_CLICKED' });
    tableDispatch({ type: 'EDITING_OFF' });
  }, [tableDispatch]);

  useEffect(() => {
    if (isOutletUpdated) {
      reduxDispatch(resetOutletUpdatingState());
      deactivateEditing();
    }
  }, [updatingOutlet, reduxDispatch, isOutletUpdated, deactivateEditing]);

  return (
    <>
      {rowState.showLibisSearch && outletUsesLibis && (
        <SearchLibisDialog
          dialogOpen={rowState.showLibisSearch}
          closeDialog={closeLibisDialog}
          selectedOutlet={rowState.selectedLibisOutlet}
          toggleLibisOutletSelection={toggleLibisOutletSelection}
          populateNewRecordWithLibisData={populateNewRecordWithLibisOutletData}
        />
      )}
      <>
        {!rowState.editingOn && (
          <OutletBasicRow
            rowStatus={recordStatus}
            toggleExtension={toggleRowExtension}
            isExtended={isExtended}
            rowExtensionComponent={rowExtensionComponent}
            record={record}
            outletControlledToInput={
              <TableCell>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={record.controlledTo ? 'space-between' : 'flex-end'}
                  alignItems="center"
                >
                  {record.controlledTo && record.controlledTo}
                  {!tableState.tableInputOn && (
                    <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                      <CustomEditIconButton onClick={activateEditing} />
                    </AllowedTo>
                  )}
                </Box>
              </TableCell>
            }
            display={display}
            outletRowActions={
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <EditAndRemoveActions
                  viewButton={
                    redirectToOutletInfo && (
                      <RowActionButtonGeneric
                        id={`view-record-button-${record.outletId}`}
                        label={redirectButtonLabel || ''}
                        onButtonClicked={redirectToOutletInfo}
                      />
                    )
                  }
                  handleEditButtonClick={activateEditing}
                  handleRemoveButtonClick={onRemoveRecordClick}
                  confirmationOn={rowState.removeConfirmationOn}
                  handleConfirmationYes={removeOutletRecord}
                  handleConfirmationNo={closeRemoveConfirmation}
                  isProcessing={removingOutlet}
                  isSuccess={isOutletRemoved}
                  error={removingOutletError}
                  onErrorClose={onOutletRemoveError}
                  confirmationMessage={`Ar tikrai norite ištrintį šį įrašą?${
                    record.representativesCount > 0
                      ? ` Šis įrašas turi ${record.representativesCount} už turinį atsakingų asmenų, kurie taip pat bus pašalinti`
                      : ''
                  }`}
                  disabled={tableState.tableInputOn}
                />
              </AllowedTo>
            }
          />
        )}
        {rowState.editingOn && (
          <OutletBasicRow
            rowStatus="RUOSIAMAS"
            display={display}
            toggleExtension={toggleRowExtension}
            rowExtensionComponent={rowExtensionComponent}
            isExtended={isExtended}
            record={record}
            outletNameInput={
              <>
                {outletUsesLibis && (
                  <StringInputCell
                    outlined
                    isDisabled={record.signedByAnotherVirs}
                    setValue={setOutletName}
                    inputState={rowState.name}
                    helperItem={
                      <BtnOpenNewDoc
                        onClicked={openLibisDialog}
                        text="Ieškoti LIBIS"
                        disabled={record.signedByAnotherVirs}
                      />
                    }
                  />
                )}
                {!outletUsesLibis && (
                  <StringInputCell
                    outlined
                    setValue={setOutletName}
                    inputState={rowState.name}
                    isDisabled={record.signedByAnotherVirs}
                  />
                )}
              </>
            }
            outletTypeInput={
              <DropdownInputCell
                disabled={record.signedByAnotherVirs}
                selectValue={setOutletType}
                dropdownState={rowState.outletType}
                getOptionLabel={(outl) => outl.outletTypeName}
                getOptionSelected={(option, value) => option.outletTypeId === value.outletTypeId}
              />
            }
            outletGroupNameInput={
              <TextField
                disabled={record.signedByAnotherVirs}
                variant="outlined"
                value={rowState.outletGroup ? rowState.outletGroup.outletGroupName : ''}
              />
            }
            outletEstablishedInput={
              <DateInputImprovedCell
                disabled={record.signedByAnotherVirs}
                state={rowState.established}
                setDate={setOutletEstablishedDate}
                isRequired
              />
            }
            outletControlledFromInput={
              <DateInputImprovedCell
                state={rowState.controlledFrom}
                setDate={setOutletControlledFrom}
                isRequired
              />
            }
            outletControlledToInput={
              <DateInputImprovedCell
                state={rowState.controlledTo}
                setDate={setOutletControlledTo}
              />
            }
            outletInternationalNumberInput={
              <StringInputCell
                outlined
                isDisabled={!outletUsesLibis || record.signedByAnotherVirs}
                inputState={rowState.internationalNumber}
                setValue={setOutletInternationalNumber}
              />
            }
            outletRowActions={
              <SaveAndCancelActions
                handleConfirmationYes={updateOutletRecord}
                isProcessing={updatingOutlet}
                handleCancelButtonClick={deactivateEditing}
              />
            }
          />
        )}
      </>
    </>
  );
};

export default OutletRowContainerFullyEditable;
