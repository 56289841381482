import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { TableDefinition } from './types';
import { ClassifierTableInner } from './ClassifierTableInner';
import { getStore } from './state/store';

interface Props<TData> {
  tableDefinition: TableDefinition<TData>;
  data: TData[];
  isLoading: boolean;
  error: boolean;
}

export const ClassifierTable = <TData,>({
  tableDefinition,
  data,
  isLoading,
  error
}: Props<TData>) => {
  const { actions, store } = useMemo(() => {
    return getStore({
      style: {
        actionsColumn: {
          cell: { width: '80px', ...tableDefinition.style?.actionsColumn?.cell },
          ...tableDefinition.style?.actionsColumn
        },
        ...tableDefinition.style
      },
      ...tableDefinition
    });
  }, [tableDefinition]);
  return (
    <Provider store={store}>
      <ClassifierTableInner actions={actions} data={data} isLoading={isLoading} error={error} />
    </Provider>
  );
};
