import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import { ColumnHeader } from '../../../utils/tableTypes';
import { EditionColumnHeaderField, EditionDataTableState } from '../tablesState/editionTableTypes';
import { getSortLabelPropsFactory } from '../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';

interface Props {
  columns: ColumnHeader<EditionColumnHeaderField>[];
}

const EditionDataTableHead: React.FC<Props> = ({ columns }) => {
  const {
    state: { editions: editionsTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const handleSetSortByColumn = (column: EditionColumnHeaderField) => {
    tableDispatch({ type: 'EDITION_SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    EditionDataTableState,
    typeof handleSetSortByColumn,
    EditionColumnHeaderField
  >(handleSetSortByColumn, editionsTableState);

  return (
    <TableHead>
      <TableRow>
        {columns
          .filter((column) => {
            if (editionsTableState.periodic) {
              return column.id !== 'editionDate';
            }
            return column.id !== 'periodName';
          })
          .filter((column) => editionsTableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableSortingHeaderCell
              key={column.id}
              rowSpan={1}
              label={column.label}
              columnName={column.id}
              sortLabelPropsFactory={sortLabelPropsFactory}
              tableSettings={editionsTableState}
            />
          ))}

        <TableCell align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default EditionDataTableHead;
