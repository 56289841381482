import React, { useContext, useState } from 'react';
import { Typography, Paper, Table, Box, TableBody } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { pageTableData, determineRecordStatus } from '../../../utils/tableDataFunctions';
import { RelevancyStatus, ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { OutletMainDataTableCustomizationBar } from './CustomizationBar';
import { OutletMainDataTableHeaders } from './TableHeaders';
import FilterRow from './FilterRow';
import { outletTableColumns } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import virsisTheme from '../../../style/virsisTheme';
import { TableErrorRow } from '../../../components/TableErrorRow/TableErrorRow';
import { TableCircularProgressRow } from '../../../components/TableCircularProgressRow/TableCircularProgressRow';
import { OutletBasicRow } from '../../OutletsDataTable/TableRows/RowBasic';
import OutletRowContainerRenewable from '../../OutletsDataTable/TableRows/RowContainerRenewable';
import OutletRowContainerFullyEditable from '../../OutletsDataTable/TableRows/RowContainerFullyEditable';
import OutletRowContainerSemiEditable from '../../OutletsDataTable/TableRows/RowContainerSemiEditable';
import { getRedirectButtonLabelsByRoles } from '../../OutletsDataTable/TableRows/rowState/outletRowReducerFunctions';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { filterAndSortMainOutletTableData } from '../tablesState/tablesStateFunctions';
import PageActionButtons from '../../../components/PageButtons/PageActionButtons';
import { subsystemContext } from '../../../pages/virs/OutletInfoTablesPage';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import { getTableExportDefinition } from '../exportDefinitions/outletMainTableExportDefinition';
import { ConfirmRemoveUnsignedDialog } from '../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { fetchVirsDataRequest } from '../../../store/virsis/actions';
import { unsignedDataRemoveOutlet } from '../../../store/unsignedDataRemoval/unsignedDataActions';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';

export interface MainOutletTableProps {
  redirectToEditions: () => void;
  tableName: OutletTableName;
}

const OutletMainDataTable: React.FC<MainOutletTableProps> = ({ redirectToEditions, tableName }) => {
  const reduxDispatch = useDispatch();

  const { loadingOutletInfo, outletInfo, outletInfoError } = useSelector(
    (state: ApplicationState) => state.outletInfoData
  );

  const { currentUser, virsData } = useSelector((state: ApplicationState) => state.virsis);

  const {
    state: { main: mainTableState }
  } = useOutletInfoTablesState();

  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  function handleSetPage(page: number) {
    tableDispatch({ type: 'PAGE_SET', page, table: tableName });
  }

  function handleSetRowsPerPage(rowsPerPage: number) {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage,
      table: tableName
    });
  }

  const outlets = outletInfo?.outletData || [];

  const data = filterAndSortMainOutletTableData(outlets, mainTableState);
  const { page: dataPage, pagesCount } = pageTableData(mainTableState, data);

  const redirectButtonLabelByRoles = (relevancyStatus: RelevancyStatus) =>
    getRedirectButtonLabelsByRoles(currentUser, relevancyStatus);

  const subsystem = useContext(subsystemContext);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  return (
    <div className="named-data-table">
      <div className="table-title">
        <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Pagrindiniai VIP duomenys</Typography>

          <Box>
            <PageActionButtons
              dontShowDelete={subsystem === 'LRTK' || subsystem === 'ZEIT'}
              onDownload={(format, navigationPath) =>
                exportTableData(
                  format,
                  getTableExportDefinition(mainTableState),
                  data,
                  navigationPath
                )
              }
              onPrint={(navigationPath) =>
                printTableData(getTableExportDefinition(mainTableState), data, navigationPath)
              }
              onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
            />
          </Box>

          {outletInfo && (
            <ConfirmRemoveUnsignedDialog
              onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
              onConfirm={() => reduxDispatch(unsignedDataRemoveOutlet(outletInfo.outletId))}
              onRemoveSuccess={() => reduxDispatch(fetchVirsDataRequest())}
              open={confirmToDeleteUnsignedDialogOpen}
            />
          )}
        </Box>
      </div>

      <Paper elevation={0}>
        {outletInfo?.outletGroupName && !loadingOutletInfo && (
          <Box
            style={{
              backgroundColor: '#006FB2',
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15
            }}
          >
            <Typography
              variant="h5"
              style={{
                padding: '15px 20px',
                color: virsisTheme.palette.secondary.light
              }}
            >
              {`${outletInfo.outletGroupName} ${outletInfo.controlledFrom} - ${
                outletInfo.controlledTo ? outletInfo.controlledTo : ''
              }`}
            </Typography>
          </Box>
        )}

        <OutletMainDataTableCustomizationBar
          columnNames={outletTableColumns}
          tableName={tableName}
        />
        <Box style={{overflow: 'auto'}}>
          <Table aria-label="simple table">
            <OutletMainDataTableHeaders />

            {mainTableState.customFilterOn && <FilterRow columnNames={outletTableColumns} />}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingOutletInfo && !outletInfoError}
                colSpan={8}
              />

              <TableErrorRow
                error={outletInfoError && 'Klaida. Nepavyko gauti VIP duomenų'}
                colSpan={8}
              />

              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                virsData &&
                dataPage.map((record) => {
                  const recordStatus = determineRecordStatus(record);
                  if (record.enterpriseActive && record.editableFully) {
                    return (
                      <OutletRowContainerFullyEditable
                        redirectButtonLabel={redirectButtonLabelByRoles(record.relevancyStatus)}
                        enterprise={outletInfo.parentEnterprise}
                        key={record.virOutletControlId}
                        record={record}
                        virsLegalRegistrationDate={virsData.legalRegistrationDate}
                        virsLegalDeregistrationDate={virsData.legalDeregistrationDate}
                        display={mainTableState.columnsDisplayStatus}
                      />
                    );
                  }
                  if (record.enterpriseActive && record.editableValidToOnly) {
                    return (
                      <OutletRowContainerSemiEditable
                        redirectButtonLabel={redirectButtonLabelByRoles(record.relevancyStatus)}
                        redirectToEditions={redirectToEditions}
                        enterprise={outletInfo.parentEnterprise}
                        key={record.virOutletControlId}
                        record={record}
                        virsLegalRegistrationDate={virsData.legalRegistrationDate}
                        virsLegalDeregistrationDate={virsData.legalDeregistrationDate}
                        display={mainTableState.columnsDisplayStatus}
                        showStatusNotSigned={mainTableState.showStatusNotSigned}
                      />
                    );
                  }
                  if (
                    record.enterpriseActive &&
                    !record.editableFully &&
                    !record.editableValidToOnly
                  ) {
                    return (
                      <OutletRowContainerRenewable
                        redirectButtonLabel={redirectButtonLabelByRoles(record.relevancyStatus)}
                        key={record.virOutletControlId}
                        enterprise={outletInfo.parentEnterprise}
                        record={record}
                        virsLegalRegistrationDate={virsData.legalRegistrationDate}
                        virsLegalDeregistrationDate={virsData.legalDeregistrationDate}
                        display={mainTableState.columnsDisplayStatus}
                        showStatusNotSigned={mainTableState.showStatusNotSigned}
                      />
                    );
                  }
                  return (
                    <OutletBasicRow
                      key={record.virOutletControlId}
                      record={record}
                      rowStatus={recordStatus}
                      display={mainTableState.columnsDisplayStatus}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={mainTableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={mainTableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}  
        />
      </Paper>
    </div>
  );
};

export default OutletMainDataTable;
