import React from 'react';
import { Button, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import virsisTheme from '../../../style/virsisTheme';
import { BasicTooltip } from '../../Tooltips/BasicTooltip';
import { ConditionalWrapper } from '../../ConditionalWrapper/ConditionalWrapper';

interface Props {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLButtonElement | null;
  clearShareData: () => void;
  deleteDate: (date: string) => void;
  deleteButtonDisable?: boolean;
  deleteButtonTooltopText: string;
  clearDataButtonDisable?: boolean;
  clearDataTooltipText: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'right',
    padding: 0,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    border: 'solid 1px #C4E2FC'
  },
  button: {
    justifyContent: 'start',
    height: 'auto',
    margin: '0px',
    padding: '12px 24px 12px 19px',
    borderBottom: 'solid 1px #E5E5E5',
    color: '#637082',
    fill: '#637082',
    '&:hover': {
      backgroundColor: '#F1F1F3'
    },
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  delete: {
    '&:hover': {
      fill: virsisTheme.palette.error.main,
      color: virsisTheme.palette.error.main
    }
  }
}));

export const ShareholderDateOptionsPopover: React.FC<Props> = ({
  open,
  anchorEl,
  onClose,
  clearShareData,
  deleteDate,
  deleteButtonDisable,
  deleteButtonTooltopText,
  clearDataButtonDisable,
  clearDataTooltipText
}) => {
  const classes = useStyles();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ classes: { root: classes.paper } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <ConditionalWrapper
        condition={!!clearDataButtonDisable}
        wrapper={(children) => (
          <BasicTooltip text={clearDataTooltipText}>
            <span>{children}</span>
          </BasicTooltip>
        )}
      >
        <Button
          className={`${classes.button} ${classes.delete}`}
          startIcon={<DeleteIcon />}
          onClick={clearShareData}
          disabled={!!clearDataButtonDisable}
        >
          <Typography variant="h5">Išvalyti duomenis</Typography>
        </Button>
      </ConditionalWrapper>

      <ConditionalWrapper
        condition={!!deleteButtonDisable}
        wrapper={(children) => (
          <BasicTooltip text={deleteButtonTooltopText}>
            <span>{children}</span>
          </BasicTooltip>
        )}
      >
        <Button
          className={`${classes.button} ${classes.delete}`}
          startIcon={<DeleteIcon />}
          onClick={() => deleteDate('anchorEl?.value')}
          disabled={!!deleteButtonDisable}
        >
          <Typography variant="h5">Pašalinti datą</Typography>
        </Button>
      </ConditionalWrapper>
    </Popover>
  );
};
