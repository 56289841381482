import { EnterpriseDataWithOutlets, OutletData } from '../../../store/outlets/outletsTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import {
  ColumnDisplayStatus,
  ColumnHeader,
  SortOrder,
  TableCustomFilter
} from '../../../utils/tableTypes';
import { getTableExportDefinition } from './tableReducerFunctions';

export const outletTableColumns: OutletDataTableColumn[] = [
  {
    id: 'outletTypeName',
    label: 'VIP rūšis'
  },
  {
    id: 'outletName',
    label: 'VIP pavadinimas'
  },
  {
    id: 'outletGroupName',
    label: 'VIP grupė'
  },
  {
    id: 'establishedDate',
    label: 'VIP steigimo data',
    type: 'date'
  },
  {
    id: 'controlledFrom',
    label: 'Nuo',
    type: 'date'
  },
  {
    id: 'controlledTo',
    label: 'Iki',
    type: 'date'
  },
  {
    id: 'internationalNumber',
    label: 'Tarptautinis Nr.'
  }
];

export interface OutletDataTableState {
  extendedEnterprises: number[];
  extendedOutlets: number[];
  showAddNewRecordDialog: boolean;
  showGroupOfSingleEnterprise: EnterpriseDataWithOutlets | undefined;
  showNewRecordInput: boolean;
  tableInputOn: boolean;
  customFilter: OutletDataTableCustomFilter;
  customFilterOn: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  userPreferenceNotSignedOn: boolean;
  userPreferenceRelevantOn: boolean;
  userPreferenceOutdatedOn: boolean;
  order: SortOrder;
  sortBy: OutletDataTableField;
  page: number;
  rowsPerPage: number;
  pagesCount: number;
  recordsCountAfterFilters: number;
  columnsDisplayStatus: OutletDataTableColumnsDisplayStatus;
  tableExportDefinition: TableExportDefinition<OutletData>;
}

export const initialDisplayStatus = {
  outletName: true,
  outletTypeName: true,
  outletGroupName: true,
  establishedDate: true,
  controlledFrom: true,
  controlledTo: true,
  internationalNumber: true
};

export const initialOutletDataTableState: OutletDataTableState = {
  extendedEnterprises: [],
  extendedOutlets: [],
  showAddNewRecordDialog: false,
  showGroupOfSingleEnterprise: undefined,
  showNewRecordInput: false,
  tableInputOn: false,
  customFilter: {
    outletName: [],
    outletTypeName: [],
    outletGroupName: [],
    establishedDate: [],
    controlledFrom: [],
    controlledTo: [],
    internationalNumber: []
  },
  customFilterOn: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: true,
  userPreferenceNotSignedOn: true,
  userPreferenceRelevantOn: true,
  userPreferenceOutdatedOn: true,
  order: 'asc',
  sortBy: 'outletName',
  page: 0,
  rowsPerPage: 20,
  pagesCount: 0,
  recordsCountAfterFilters: 0,
  columnsDisplayStatus: initialDisplayStatus,
  tableExportDefinition: getTableExportDefinition(outletTableColumns, initialDisplayStatus)
};

export type OutletDataTableField =
  | 'outletName'
  | 'outletTypeName'
  | 'outletGroupName'
  | 'establishedDate'
  | 'controlledFrom'
  | 'controlledTo'
  | 'internationalNumber';

export type OutletDataTableColumn = ColumnHeader<OutletDataTableField>;

export type OutletDataTableCustomFilter = TableCustomFilter<OutletDataTableField>;

export type OutletDataTableColumnsDisplayStatus = ColumnDisplayStatus<OutletDataTableField>;

export type OutletDataTableAction =
  | {
      type: 'TABLE_INITIALIZED';
      notSignedEnabled: boolean;
      relevantEnabled: boolean;
      outdatedEnabled: boolean;
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: OutletDataTableField;
      value: string | null;
      enterprisesToShow: EnterpriseDataWithOutlets[];
    }
  | { type: 'SHOW_STATUS_UNSIGNED_TOGGLED' }
  | { type: 'SHOW_STATUS_RELEVANT_TOGGLED' }
  | { type: 'SHOW_STATUS_NOT_RELEVANT_TOGGLED' }
  | { type: 'SORTING_CHANGED'; sortBy: OutletDataTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: OutletDataTableField }
  | { type: 'SHOW_NEW_RECORD_DIALOG_TOGGLED' }
  | {
      type: 'ADD_OUTLET_FROM_DIALOG_CLICKED';
      enterprise: EnterpriseDataWithOutlets;
      notSignedEnabled: boolean;
      relevantEnabled: boolean;
      outdatedEnabled: boolean;
    }
  | { type: 'CANCEL_ADDING_OUTLET_CLICKED' }
  | { type: 'SET_NEWEST_RECORD_ID'; virOutletControlId: number }
  | {
      type: 'RETURN_TO_TABLE_CLICKED';
      notSignedEnabled: boolean;
      relevantEnabled: boolean;
      outdatedEnabled: boolean;
    }
  | { type: 'RESET_COLUMN_DISPLAY' }
  | { type: 'ENTERPRISE_EXTENSION_CLICKED'; enterpriseId: number }
  | { type: 'OUTLET_EXTENSION_CLICKED'; virOutletControlId: number }
  | {
      type: 'UPDATE_SINGLE_SHOWING_ENTERPRISE_WITH_NEW_DATA';
      updatedEnterprise: EnterpriseDataWithOutlets | undefined;
    }
  | {
      type: 'EXTEND_ENTERPRISES';
      enterprisesToExtend: EnterpriseDataWithOutlets[];
    }
  | { type: 'EDITING_ON' }
  | { type: 'EDITING_OFF' };
