import { Box, Paper, Table, TableBody, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../../store';
import { getVirsVirsEthicalNonComplianceDocumentInNewTabRequest } from '../../store/ethicalNonCompliances/ethicalNonCompliancesActions';
import { pageTableData } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { OutletsAsLinksList } from '../../components/TableLinks/OutletsAsLinksList';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import EthicalNonComplianceTableCustomFilter from './FilterRow';
import { EthicalNonComplianceTableCustomizationBar } from './TableCustomization';
import { useEthicalNonComplianceTableDispatch, useEthicalNonComplianceTableState } from './Context';
import { ethicalNonComplianceTableColumns } from './tableState/tableInitialStateAndTypes';
import EthicalNonComplianceTableHead from './TableHead';
import { EthicalNonComplianceBasicRow } from './TableRow';
import { filterAndSortTableData, getTableExportDefinition } from './tableState/tableStateFunctions';
import { InlineElDocumentLinks } from '../../components/TableElDocumentLinks/InlineElDocumentLinks';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';

const EthicalNonComplianceTable: React.FC<RouteComponentProps> = () => {
  const { state: tableState } = useEthicalNonComplianceTableState();
  const { dispatch: tableDispatch } = useEthicalNonComplianceTableDispatch();
  const reduxDispatch = useDispatch();

  const {
    virsis: { currentUser, virsData },
    ethicalNonComplianceData: {
      loadingEthicalNonComplianceData,
      virsEthicalNonComplianceData,
      ethicalNonComplianceDataError
    }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    if (
      virsEthicalNonComplianceData &&
      currentUser &&
      !currentUser.authorities.includes('ROLE_VIRS')
    ) {
      tableDispatch({ type: 'TABLE_INITIALIZED' });
    }
  }, [tableDispatch, virsEthicalNonComplianceData, currentUser]);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  function openDocument(documentId: number, documentNumber: string) {
    reduxDispatch(
      getVirsVirsEthicalNonComplianceDocumentInNewTabRequest(documentId, documentNumber)
    );
  }

  const validData = virsEthicalNonComplianceData.filter(({ ethicalNonComplianceId }) =>
    Boolean(ethicalNonComplianceId)
  );
  const filteredData = filterAndSortTableData(tableState, validData);
  const { page: dataPage, pagesCount } = pageTableData(tableState, filteredData);

  return (
    <div className="named-data-table">
      <div
        className="table-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">Profesinės etikos nesilaikymas</Typography>

        <Box>
          <PageActionButtons
            dontShowDelete
            onDownload={(format, navigationPath) =>
              exportTableData(
                format,
                getTableExportDefinition(tableState),
                filteredData,
                navigationPath
              )
            }
            onPrint={(navigationPath) =>
              printTableData(getTableExportDefinition(tableState), filteredData, navigationPath)
            }
          />
        </Box>
      </div>

      <Paper elevation={0}>
        <EthicalNonComplianceTableCustomizationBar columnNames={ethicalNonComplianceTableColumns} />

        <ScrollXContainer>
          <Table>
            <EthicalNonComplianceTableHead />

            {tableState.customFilterOn && (
              <EthicalNonComplianceTableCustomFilter
                columnNames={ethicalNonComplianceTableColumns}
                dataToDisplay={filteredData}
              />
            )}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingEthicalNonComplianceData && !ethicalNonComplianceDataError}
                colSpan={9}
              />

              <TableErrorRow
                error={ethicalNonComplianceDataError && 'Klaida. Nepavyko gauti duomenų'}
                colSpan={9}
              />

              {!loadingEthicalNonComplianceData &&
                !ethicalNonComplianceDataError &&
                virsData &&
                dataPage.map((record) => {
                  return (
                    record.ethicalNonComplianceId && (
                      <EthicalNonComplianceBasicRow
                        key={record.ethicalNonComplianceId}
                        columnsToDisplay={tableState.columnsDisplayStatus}
                        outletList={
                          <OutletsAsLinksList
                            outlets={record.outletList}
                            virsId={virsData.virsId}
                          />
                        }
                        decisionStatus={record.decisionStatus}
                        decisionDate={record.decisionDate}
                        documentNumber={record.documentNumber}
                        documentStatus={record.documentStatus.id}
                        validFrom={record.validFrom}
                        validTo={record.validTo}
                        reportingInstitution={record.reportingInstitution}
                        signatureDate={record.signatureDate}
                        signingPersonName={record.signingPersonName}
                        elDocument={
                          <InlineElDocumentLinks
                            documents={[
                              {
                                documentId: record.documentStatusId,
                                documentNumber: record.documentNumber
                              }
                            ]}
                            onClickedOpenInDialog={openDocument}
                          />
                        }
                        documentStatusId={record.documentStatusId}
                      />
                    )
                  );
                })}
            </TableBody>
          </Table>
        </ScrollXContainer>
        <TablePagination
          recordsCount={filteredData.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={filteredData.length === 0}
        />
      </Paper>
    </div>
  );
};

export default withRouter(EthicalNonComplianceTable);
