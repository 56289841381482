import React from 'react';
import { TableCell, makeStyles, Button } from '@material-ui/core/';
import theme from '../../../../../style/virsisTheme';

interface Props {
  alignment: string;
  isDate: boolean;
  onClick: () => void;
}

const useStyles = makeStyles({
  button: {
    margin: 0,
    padding: 0,
    height: 'auto',
    color: theme.palette.primary.main
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
});

export const TableHeadPrevFollDateCell: React.FC<Props> = ({
  alignment,
  isDate,
  onClick,
  children
}) => {
  const classes = useStyles();

  return (
    <TableCell style={{ alignItems: alignment, width: '130px', boxSizing: 'border-box' }}>
      <div className={classes.cellContainer}>
        <Button className={classes.button} disabled={isDate} onClick={onClick}>
          {children}
        </Button>
      </div>
    </TableCell>
  );
};
