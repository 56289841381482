import React, { ReactNode } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { SideBox } from './SideBox';
import { groupBoxColor } from '../treeChartFunctions/constants';

const useStyles = makeStyles(() => ({
  boxContainer: {
    maxWidth: '430px',
    minHeight: '40px',
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    border: '1px solid',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  mainBox: {
    color: 'white',
    width: '200px',
    boxSizing: 'border-box',
    padding: '10px',
    cursor: 'pointer',
    margin: '0',
    height: '100%',
    borderRadius: '0',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  boxButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'initial',
    justifyContent: 'initial',
    height: '100%'
  },
  boxTitle: {
    flexGrow: 1
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  groupBox: {
    width: '100%',
    height: '20px',
    minHeight: '15px',
    borderRadius: '0',
    borderTop: `1px solid ${groupBoxColor}`,
    background: 'white',
    fontSize: '0.6rem'
  },
  title: {
    fontSize: '0.73rem',
    marginBottom: '5px'
  },
  foregroundGrid: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '500%',
      height: '500%',
      top: '-250%',
      left: '-250%',
      transform: 'rotate(45deg)',
      backgroundImage:
        'linear-gradient(rgba(255,255,255,0.5) 1px, transparent 1px), linear-gradient(90deg, rgba(255,255,255,0.5) 1px, transparent 1px)',
      backgroundSize: '10px 10px'
    }
  }
}));

export interface Props {
  boxTitle: ReactNode;
  additionalInfo?: ReactNode;
  leftChildrenNumber?: number;
  rightChildrenNumber: number;
  color: string;
  onClick?: () => void;
  handleLeftSideButtonBoxClick?: () => void;
  height: number;
  handleOpenGroupPersons: () => void;
  leftSideButtonDisabled?: boolean;
  foregroundGrid?: boolean;
}

export const GroupBox: React.FC<Props> = ({
  boxTitle,
  additionalInfo,
  leftChildrenNumber,
  rightChildrenNumber,
  color,
  onClick,
  height,
  handleOpenGroupPersons,
  handleLeftSideButtonBoxClick,
  leftSideButtonDisabled,
  foregroundGrid
}) => {
  const classes = useStyles();
  const containerCustomStyle = {
    borderColor: color,
    height: `${height}px`
  };
  const mainBoxCustomStyle = {
    backgroundColor: color
  };
  return (
    <div className={classes.boxContainer} style={containerCustomStyle}>
      <div style={{ display: 'inline-flex', height: '100%' }}>
        <SideBox
          childrenNumber={leftChildrenNumber}
          handleSideBoxClick={handleLeftSideButtonBoxClick}
          isDisabled={leftSideButtonDisabled}
        />
        <div className={classes.centerBox}>
          <Button
            variant="contained"
            className={`${classes.mainBox} ${foregroundGrid ? classes.foregroundGrid : ''}`}
            classes={{ label: classes.boxButtonLabel }}
            style={mainBoxCustomStyle}
            onClick={onClick}
          >
            <Typography variant="h5" className={classes.title}>
              Bendraturčių grupė
            </Typography>
            <div className={classes.boxTitle}>{boxTitle}</div>
            {additionalInfo}
          </Button>
        </div>
        <SideBox handleSideBoxClick={handleOpenGroupPersons} childrenNumber={rightChildrenNumber} />
      </div>
    </div>
  );
};
