import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import virsisTheme from '../../../style/virsisTheme';

export interface FieldGroupStyleOverrides {
  container?: React.CSSProperties;
  labelBox?: React.CSSProperties;
}

interface Props {
  label?: string;
  rowSpacing?: string;
  style?: FieldGroupStyleOverrides;
  disabled?: boolean;
  className?: string;
  childrenClassName?: string;
  labelClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    labelBox: {
      flex: 'unset !important',
      justifyContent: 'flex-start !important'
    }
  }
}));

export type FieldGroupProps = PropsWithChildren<Props>;

export const FieldGroup: React.FC<FieldGroupProps> = ({
  label,
  disabled,
  children,
  style,
  className,
  childrenClassName,
  labelClassName
}) => {
  const color = disabled ? virsisTheme.palette.text.disabled : undefined;
  const classes = useStyles();

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style?.container
      }}
      className={className}
    >
      <Box
        style={{
          display: 'flex',
          flex: '0 0 200px',
          justifyContent: 'flex-end',
          marginRight: '20px',
          ...style?.labelBox
        }}
        className={classes.labelBox + ' ' + labelClassName}
      >
        {label && (
          <Typography variant="h4" style={{ color }}>
            {label}
          </Typography>
        )}
      </Box>
      <Box style={{ display: 'flex' }} className={childrenClassName}>
        {children}
      </Box>
    </Box>
  );
};
