import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  initSign,
  resetUnisign, setCancelSuccess,
  setError,
  setLoading,
  setUnisignData,
  setVerifySuccess,
  verifySign
} from './unisignActions';
import { get, getBlob, postFile } from '../../utils/axiosApi';
import { ApplicationState } from '../index';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { INIT_SIGN, VERIFY_SIGN } from './unisignTypes';
import { JADISSynchronise } from '../jadisSync/store';
import { fetchVirsDataRequest } from '../virsis/actions';

function* initSignSaga(action: ActionType<typeof initSign>) {
  yield put(setLoading(true));
  try {
    const { data: fileContent } = yield call(getBlob, action.payload);
    const { data } = yield call(postFile, 'documents/init-sign', fileContent, 'document.pdf', true);
    yield put(setUnisignData(data));
  } catch {
    yield put(setError('Klaida'));
  }
  yield put(setLoading(false));
}

function* verifySignSaga(action: ActionType<typeof verifySign>) {
  yield put(setLoading(true));
  try {
    const transactionId = yield select(
      (state: ApplicationState) => state.unsignData?.unisignData?.transactionId
    );

    const {
      data: { signingStatus }
    } = yield call(get, `documents/signed/${transactionId}/status`);
    const canceled = signingStatus === 'CANCELED';
    yield put(setCancelSuccess(canceled));
    if (!canceled) {
      const { data: fileContent } = yield call(getBlob, `documents/signed/${transactionId}`);
      const {
        data: { verified }
      } = yield call(
        postFile,
        `documents/${action.payload}/approve`,
        fileContent,
        'document.pdf',
        false
      );
      yield put(setVerifySuccess(verified));
      yield put(resetUnisign());
    }
  } catch (err) {
    yield put(JADISSynchronise());
    yield put(fetchVirsDataRequest());
    yield put(setError('Klaida'));
    yield put(setVerifySuccess(undefined));
    yield put(setCancelSuccess(undefined));
    yield put(sendMessage('error', err));
  }
  yield put(setLoading(false));
}

function* unisignSaga() {
  yield takeLatest(INIT_SIGN, initSignSaga);
  yield takeLatest(VERIFY_SIGN, verifySignSaga);
}

export default unisignSaga;
