import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 'lg',
    paddingBottom: 100,
    paddingTop: 90,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 70,
      paddingTop: 60
    }
  },
  footerRight: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: 50
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      gap: 30,
      '& a': {
        width: '100%',
        textAlign: 'center',
        '& img': {
          margin: 0
        }
      }
    }
  }
}));

const AppFooter: React.FC = () => {
  const classes = useStyles();

  interface FooterNavItem {
    label: string;
    path: string;
  }

  const devEnvironment = false;
  //const devEnvironment = process.env.REACT_APP_DEV;


  let url = window.location.origin;

  if (process.env.REACT_APP_DEV_PUBLIC_ADDRESS) url = process.env.REACT_APP_DEV_PUBLIC_ADDRESS;
  else if (devEnvironment) url = url.concat(':81');

  const footerLogoSrc = devEnvironment ? '/images/footerLogos' : '/bylos/dokumentai/logo';

  const [firstColumn, secondColumn]: FooterNavItem[][] = [
    [
      {
        label: 'Apie sistemą',
        path: `${url}/apie`
      },
      {
        label: 'Naujienos',
        path: `${url}/naujienos`
      },
      {
        label: 'Kontaktai',
        path: `${url}/kontaktai`
      }
    ],
    [
      {
        label: 'DUK',
        path: `${url}/duk`
      },
      {
        label: 'Asmens duomenų apsauga',
        path: `${url}/Pagalba/AsmensDuomenuApsauga`
      },
      {
        label: 'Naudotojų vadovai',
        path: `${url}/Pagalba/NaudotojuVadovai`
      }
    ]
  ];

  return (
    <footer className="app-footer">
      <Container className={classes.container}>
        <div className="footer-left">
          <div className="footer-first-column">
            {firstColumn.map((item) => (
              <Link className="link" key={item.label} target={'_blank'} href={item.path}>
                {item.label}
              </Link>
            ))}
          </div>
          <div>
            {secondColumn.map((item) => (
              <Link key={item.label} href={item.path} target={'_blank'} className="link">
                {item.label}
              </Link>
            ))}
          </div>
        </div>
        <div>
          <div className={classes.footerRight}>
            <a target="_blank" rel="noreferrer noopener" href=" https://lrkm.lrv.lt/">
              <img
                src={`${footerLogoSrc}/KM-logo.svg`}
                alt="Registrų Centro logo"
                className="logo-KM"
              />
            </a>
            <a target="_blank" rel="noreferrer noopener" href="https://www.registrucentras.lt/">
              <img src={`${footerLogoSrc}/RC-logo.svg`} alt="Kulturos Ministerijos logo" />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default AppFooter;
