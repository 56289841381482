import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { loadData, setFormOpen, State } from './store';
import { PaymentAmountForm } from './PaymentAmountForm';
import { PaymentAmount } from './types';

export const PaymentAmountTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const tableDefinition: TableDefinition<Nullable<PaymentAmount>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('paymentAmountId', 'Kodas', (data) => data.paymentAmountId),
            getNumericMappedColumn('amount', 'BSI dydis', (data) => data.amount, {
              cell: { width: undefined }
            }),
            getStringMappedColumn('start', 'Pradžios data', (data) => data.start),
            getStringMappedColumn('end', 'Pabaigos data', (data) => data.end)
          ]
        },
        {
          header: 'Galioja',
          columns: [
            getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              paymentAmountId: data.paymentAmountId,
              amount: data.amount,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              validTo: (data.validTo && new Date(data.validTo)) || null,
              start: (data.start && new Date(data.start)) || null,
              end: (data.end && new Date(data.end)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują BSI dydį'
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={tableDefinition} {...dataState} />

      <PaymentAmountForm />
    </>
  );
};
