import { Order } from '../../../components/TableTypes/TableTypes';
import { ColumnHeader, Period } from '../../../utils/tableTypes';

export const initialInstitutionDataReportTableState: InstitutionDataReportTableState = {
  order: 'desc',
  sortBy: 'reportedDateTime',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    reportType: true,
    reportedDateTime: true,
    institutionCode: true,
    institutionName: true,
    institutionEmail: true,
    virsCode: true,
    virsName: true,
    reportedBy: true,
    reporterEmail: true
  },
  customFilterOn: false,
  customFilter: {
    reportType: [],
    reportedDateTime: [],
    institutionCode: [],
    institutionName: [],
    institutionEmail: [],
    virsCode: [],
    virsName: [],
    reportedBy: [],
    reporterEmail: []
  },
  periodFilter: {
    id: 'PASKUTINIS_MENUO',
    label: 'Paskutinis mėnuo'
  }
};

export type InstitutionDataReportHeaderField =
  | 'reportType'
  | 'reportedDateTime'
  | 'institutionCode'
  | 'institutionName'
  | 'institutionEmail'
  | 'virsCode'
  | 'virsName'
  | 'reportedBy'
  | 'reporterEmail';

export type InstitutionDataReportColumnsDisplayStatus = {
  reportType: boolean;
  reportedDateTime: boolean;
  institutionCode: boolean;
  institutionName: boolean;
  institutionEmail: boolean;
  virsCode: boolean;
  virsName: boolean;
  reportedBy: boolean;
  reporterEmail: boolean;
};

export type InstitutionDataReportCustomFilter = {
  reportType: string[];
  reportedDateTime: string[];
  institutionCode: string[];
  institutionName: string[];
  institutionEmail: string[];
  virsCode: string[];
  virsName: string[];
  reportedBy: string[];
  reporterEmail: string[];
};

export type InstitutionDataReportColumnHeader = ColumnHeader<InstitutionDataReportHeaderField>;

export interface InstitutionDataReportTableState {
  sortBy: InstitutionDataReportHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: InstitutionDataReportColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: InstitutionDataReportCustomFilter;
  periodFilter: Period;
}

export type InstitutionDataReportTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: InstitutionDataReportHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: InstitutionDataReportHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: InstitutionDataReportHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | {
      type: 'PERIOD_FILTER_VALUE_CHANGED';
      value: Period;
    };
export const institutionDataReportTableColumns: InstitutionDataReportColumnHeader[] = [
  {
    id: 'reportType',
    label: 'Pranešimo tipas'
  },
  {
    id: 'reportedDateTime',
    label: 'Data ir laikas',
    type: 'date'
  },
  {
    id: 'institutionCode',
    label: 'Institucijos kodas'
  },
  {
    id: 'institutionName',
    label: 'Institucijos pavadinimas'
  },
  {
    id: 'institutionEmail',
    label: 'Institucijos el. paštas'
  },
  {
    id: 'virsCode',
    label: 'VIRS kodas'
  },
  {
    id: 'virsName',
    label: 'VIRS pavadinimas'
  },
  {
    id: 'reportedBy',
    label: 'Pranšimą pateikė'
  },
  {
    id: 'reporterEmail',
    label: 'El. paštas'
  }
];
