import React, { createContext, useContext, useEffect } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HeaderMenuField } from '../../pages/pages';

interface Props {
  navigation: HeaderMenuField[];
}

export const BreadcrumbsContext = createContext<{
  setBreadcrumbs: (navigation: HeaderMenuField[]) => void;
  breadcrumbs: HeaderMenuField[];
}>({ setBreadcrumbs: () => {}, breadcrumbs: [] });

export const HeaderBreadCrumbs: React.FC<Props> = ({ navigation }) => {
  let path = '';
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);

  useEffect(() => {
    if (navigation.some(({ id }, i) => id !== breadcrumbs.slice(1)[i]?.id)) {
      setBreadcrumbs([
        {
          label: 'Pradžia',
          path: '/',
          id: 'start'
        },
        ...navigation
      ]);
    }
  }, [setBreadcrumbs, breadcrumbs, navigation]);

  return (
    <Container maxWidth={false} style={{ paddingTop: '30px', lineHeight: '10px', maxWidth: 1590 }}>
      <Breadcrumbs>
        <Link to="/" className="link">
          Pradžia
        </Link>
        {navigation.map((menu, index) => {
          path += menu.path;
          return (
            <div key={menu.id}>
              {index !== navigation.length - 1 ? (
                <Link to={path} className="link">
                  {menu.label}
                </Link>
              ) : (
                <Typography>{menu.label}</Typography>
              )}
            </div>
          );
        })}
      </Breadcrumbs>
    </Container>
  );
};
