import { TableCell, TableRow, Typography } from '@material-ui/core';
import { getStatus } from '../../store/document/documentTypes';
import BtnOpenNewDoc from '../TableButtons/BtnOpenNewDoc';
import React from 'react';
import { WordStatus } from '../../utils/tableTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Roles } from '../../store/virsis/dataTypes';
import { hasRoles } from '../../utils/roleHelper';

interface Props {
  documentStatus: WordStatus;
  creationDate?: string;
  signDate: string;
  signedByPerson: string;
  documentId: number;
  openDocumentPreview: (documentId: number) => void;
  authorizedRolesToViewDocumentRow?: Roles[];
}

export const TableRowExtension: React.FC<Props> = ({
  documentId,
  openDocumentPreview,
  documentStatus,
  creationDate,
  signDate,
  signedByPerson,
  authorizedRolesToViewDocumentRow
}) => {
  const {
    virsis: { currentUser }
  } = useSelector((appState: ApplicationState) => appState);
  return (
    <TableRow>
      <TableCell colSpan={9}>
        <Typography>
          <span className="align-extended-row-data">Būsena:</span>
          {getStatus(documentStatus)}
        </Typography>
        <Typography>
          <span className="align-extended-row-data">Sukūrimo data:</span>
          {creationDate}
        </Typography>
        <Typography>
          <span className="align-extended-row-data">Pasirašymo data:</span>
          {signDate}
        </Typography>
        <Typography>
          <span className="align-extended-row-data">Pasirašęs asmuo:</span>
          <span>{signedByPerson}</span>
        </Typography>
        {!authorizedRolesToViewDocumentRow ||
          (currentUser &&
            hasRoles(currentUser, authorizedRolesToViewDocumentRow) &&
            documentId !== null &&
            documentId && (
              <Typography>
                <span className="align-extended-row-data">El. dokumentas:</span>
                <BtnOpenNewDoc
                  key={documentId}
                  onClicked={() => openDocumentPreview(documentId)}
                  text={`${documentId}`}
                />
              </Typography>
            ))}
      </TableCell>
    </TableRow>
  );
};
