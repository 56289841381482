import { ColumnDisplayStatus, ColumnHeader } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';
import { AuditData } from '../../../store/audits/auditsTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { getTableExportDefinition } from './tableStateFunctions';

export const auditsTableColumns: AuditsColumnHeader[] = [
  {
    id: 'auditActionTypeName',
    label: 'Veiksmo tipas'
  },
  {
    id: 'actionUserFullName',
    label: 'Naudotojas, kuris atliko veiksmą'
  },
  {
    id: 'actionLegalPersonFullName',
    label: 'Atstovaujamas juridinis asmuo'
  },
  {
    id: 'actionDateTime',
    label: 'Veiksmo atlikimo laikas'
  },
  {
    id: 'relatedVirs',
    label: 'Susijęs VIRS'
  },
  {
    id: 'relatedOutlet',
    label: 'Susijusi VIP'
  },
  {
    id: 'relatedOutletTypeName',
    label: 'VIP rūšis'
  },
  {
    id: 'relatedPersonFullName',
    label: 'Susijęs asmuo'
  },
  {
    id: 'ipAddress',
    label: 'IP adresas'
  },
  {
    id: 'auditDetails',
    label: 'Papildomi duomenys'
  }
];

const initialColumnDisplay: ColumnDisplayStatus<AuditsHeaderField> = {
  auditActionTypeName: true,
  actionUserFullName: true,
  actionLegalPersonFullName: true,
  actionDateTime: true,
  relatedVirs: true,
  relatedOutlet: true,
  relatedOutletTypeName: true,
  relatedPersonFullName: true,
  ipAddress: true,
  auditDetails: true
};

export const initialAuditsTableState: AuditsTableState = {
  order: 'desc',
  sortBy: 'actionDateTime',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: initialColumnDisplay,
  contentToView: undefined,
  tableExportDefinition: getTableExportDefinition(auditsTableColumns, initialColumnDisplay)
};

export type AuditsHeaderField =
  | 'auditActionTypeName'
  | 'actionUserFullName'
  | 'actionLegalPersonFullName'
  | 'actionDateTime'
  | 'relatedVirs'
  | 'relatedOutlet'
  | 'relatedOutletTypeName'
  | 'relatedPersonFullName'
  | 'ipAddress'
  | 'auditDetails';

export type AuditsColumnsDisplayStatus = {
  auditActionTypeName: boolean;
  actionUserFullName: boolean;
  actionLegalPersonFullName: boolean;
  actionDateTime: boolean;
  relatedVirs: boolean;
  relatedOutlet: boolean;
  relatedOutletTypeName: boolean;
  relatedPersonFullName: boolean;
  ipAddress: boolean;
  auditDetails: boolean;
};

export type AuditsColumnHeader = ColumnHeader<AuditsHeaderField>;

export interface AuditsTableState {
  sortBy: AuditsHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: ColumnDisplayStatus<AuditsHeaderField>;
  contentToView: string | undefined;
  tableExportDefinition: TableExportDefinition<AuditData>;
}

export type AuditsTableAction =
  | { type: 'SORTING_CHANGED'; sortBy: AuditsHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: AuditsHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'CONTENT_OPENED'; content: string }
  | { type: 'CONTENT_CLOSED' };
