import { Reducer } from 'redux';
import {
  FETCH_PARAMETERS_REQUEST,
  FETCH_PARAMETERS_SUCCESS,
  FETCH_PARAMETERS_ERROR,
  UPDATE_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_REQUEST,
  UPDATE_PARAMETER_ERROR,
  RESET_UPDATE_PARAMETER_STATE
} from './parametersActionTypes';
import { VirsisParameter } from './parametersDataTypes';

export interface VirsParametersState {
  loadingVirsisParameters: boolean;
  virsisParameters?: VirsisParameter[];
  virsisParametersError?: Error;
  updatingVirsisParameter: boolean;
  updatingVirsisParameterError?: Error;
  updatingVirsisParameterSuccess?: boolean;
}

export const initialState: VirsParametersState = {
  loadingVirsisParameters: false,
  virsisParameters: undefined,
  virsisParametersError: undefined,
  updatingVirsisParameter: false,
  updatingVirsisParameterError: undefined,
  updatingVirsisParameterSuccess: undefined
};

export const parametersReducer: Reducer<VirsParametersState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARAMETERS_REQUEST: {
      return { ...state, loadingVirsisParameters: true };
    }
    case FETCH_PARAMETERS_SUCCESS: {
      return {
        ...state,
        loadingVirsisParameters: false,
        virsisParameters: action.payload.parameters
      };
    }
    case FETCH_PARAMETERS_ERROR: {
      return {
        ...state,
        loadingVirsisParameters: false,
        virsisParameters: undefined,
        virsisParametersError: action.payload
      };
    }
    case UPDATE_PARAMETER_REQUEST: {
      return { ...state, updatingVirsisParameter: true };
    }
    case UPDATE_PARAMETER_SUCCESS: {
      return {
        ...state,
        updatingVirsisParameter: false,
        updatingVirsisParameterSuccess: true
      };
    }
    case UPDATE_PARAMETER_ERROR: {
      return {
        ...state,
        updatingVirsisParameter: false,
        updatingVirsisParameterError: action?.payload?.data
      };
    }
    case RESET_UPDATE_PARAMETER_STATE:
      return {
        ...state,
        updatingVirsisParameter: false,
        updatingVirsisParameterSuccess: undefined,
        updatingVirsisParameterError: undefined
      };
    default:
      return state;
  }
};
