import React from 'react';
import { useLocation } from 'react-router';
import { AppBar, Toolbar, Box, Tabs, Container } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { VirsisUser } from '../../store/virsis/dataTypes';
import TabMenu from './TabMenu';
import CurrentUserMenu from '../../pages/header/CurrentUserMenu';
import SecondaryHeader from './SecondaryHeader';
import { HeaderMenuField } from '../../pages/pages';
import { HeaderBreadCrumbs } from './HeaderBreadCrumbs';
import UserNotificationsBadge from '../../containers/UserNotifications/UserNotificationsBadge';
import virsisLogo from '../../assets/virsis_logo.png';

interface Props {
  currentUser: VirsisUser;
  headerFields: HeaderMenuField[];
  displayEmailEditing?: boolean;
}

const useStyles = makeStyles((theme) => ({
  tabMenu: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    margin: '0 12px'
  },
  fixedWidthMenu: {
    [theme.breakpoints.between('xs', 1500)]: {
      maxWidth: '125px',
      padding: '10px 0'
    }
  },
  currentUserMenuContainer: {
    maxWidth: '360px'
  },
  maxWidth: {
    maxWidth: 1590
  },
  navbarSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      maxWidth: '100%'
    }
  }
}));

const AppHeader: React.FC<Props> = ({ currentUser, headerFields, displayEmailEditing }) => {
  const location = useLocation();
  const classes = useStyles();

  const userHasRole = (role: string) => currentUser?.authorities.includes(role);
  const headerRolesFilter = ({ roles }: HeaderMenuField) =>
    roles ? roles.some(userHasRole) : true;

  const filteredMenus: HeaderMenuField[] = headerFields
    .filter(headerRolesFilter)
    .map(({ subMenu, ...menu }) => ({
      ...menu,
      subMenu: subMenu?.filter(headerRolesFilter)
    }))
    .filter((headerField) => headerField.subMenu === undefined || headerField.subMenu.length);

  const paths = filteredMenus.slice(1).map((f) => f.path);

  const activeTabPath = paths.find((p) => location.pathname.includes(p)) || '/';

  const navigation: HeaderMenuField[] = [];

  const navigation1 = filteredMenus.find((menu) => menu.path === activeTabPath);
  if (navigation1) navigation.push(navigation1);
  const navigation2 = navigation1?.subMenu
    ? navigation1.subMenu.find((p) => location.pathname.includes(p.path))
    : undefined;
  if (navigation2) navigation.push(navigation2);
  const navigation3 = navigation2?.subMenu
    ? navigation2.subMenu.find((p) => location.pathname.includes(p.path))
    : undefined;
  if (navigation3) navigation.push(navigation3);

  if (navigation1?.subMenu) {
    navigation1.subMenu.map((item) => item.path).find((p) => location.pathname.includes(p));
  }

  const excludedPaths = () => {
    switch (navigation1?.id) {
      case 'start':
        return false;
      case 'virsDataSubmission':
        return false;
      case 'virsDataReview':
        return false;
      default:
        return true;
    }
  };

  const customStyles = headerFields.length > 6 ? `${classes.fixedWidthMenu}` : undefined;

  return (
    <>
      <AppBar position="static">
        <Container maxWidth={false} className={classes.maxWidth}>
          <Toolbar className={classes.navbarSection}>
            <div className="logo">
              <img src={virsisLogo} />
            </div>
            <Tabs value={activeTabPath} variant="scrollable" scrollButtons="on">
              {filteredMenus.map((field) => (
                <TabMenu
                  className={`${classes.tabMenu} ${customStyles}`}
                  label={field.label}
                  value={field.path}
                  key={field.id}
                  subMenu={field.subMenu}
                />
              ))}
            </Tabs>
            <Box
              className={headerFields.length > 6 ? classes.currentUserMenuContainer : undefined}
              display="flex"
              flexGrow={1}
              flexWrap="nowrap"
              justifyContent="flex-end"
            >
              <UserNotificationsBadge />
              {currentUser && (
                <CurrentUserMenu
                  currentUser={currentUser}
                  displayEmailEditing={displayEmailEditing ? true : false}
                />
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SecondaryHeader navigation1={navigation1} activeSubMenu={navigation2} />
      {excludedPaths() && <HeaderBreadCrumbs navigation={navigation} />}
    </>
  );
};

export default AppHeader;
