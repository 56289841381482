import { checkIfDateIsAfterOrEqualStartDate } from '../../utilityFunctions/sharedFunctions';
import { replaceDecimalPoint } from '../../../../utils/tableDataFunctions';

export const checkIfColorBarNeeded = (
  startDate: string | null,
  parentStartDate: string,
  endDate: string | null,
  date: string
) => {
  const isDateAfterOrEqualStartDate = checkIfDateIsAfterOrEqualStartDate(
    startDate,
    parentStartDate,
    date
  );
  if (isDateAfterOrEqualStartDate && (endDate === null || endDate >= date)) {
    return true;
  }
  return false;
};

export const getLeftParameter = (
  startDate: string,
  date: string,
  virsEndDate: string | null,
  eventDate?: string
) => {
  if (virsEndDate && virsEndDate === date && virsEndDate !== startDate) {
    return '0';
  }
  if (startDate === date) {
    return '43%';
  }

  if (eventDate && eventDate === date) {
    return '43%';
  }
  return '0';
};

export const getWidth = (
  isShareholderFromJADIS: boolean,
  startDate: string,
  endDate: string | null,
  shareholderStoppedParticipatingFromDate: string | null,
  virsEndDate: string | null,
  date: string,
  isSelectedDateEventDate?: boolean,
  eventDate?: string,
  newDate?: string
) => {
  if (
    virsEndDate &&
    virsEndDate === date &&
    virsEndDate !== startDate &&
    virsEndDate !== shareholderStoppedParticipatingFromDate
  ) {
    return '100%';
  }
  if (startDate === date || (date === newDate && newDate === eventDate)) {
    return '57%';
  }

  if (date === newDate) {
    return '100%';
  }

  if (eventDate && eventDate === date && isSelectedDateEventDate) {
    return '57%';
  }
  if (
    endDate === date ||
    (eventDate && eventDate < date && isSelectedDateEventDate) ||
    (eventDate && newDate && eventDate < newDate && !isShareholderFromJADIS)
  ) {
    return '43%';
  }
  return '100%';
};

export const getLeftBorderRadius = (
  startDate: string,
  date: string,
  startWithTriangle?: boolean
) => {
  if (!startWithTriangle && startDate === date) {
    return '5px';
  }
  return '0px';
};

export const getRightBorderRadius = (endDate: string | null, date: string, event?: string) => {
  if (endDate === date) {
    return '5px';
  }
  return '0px';
};

export const getJustifyContent = (
  isShareholderFromJADIS: boolean,
  startDate: string,
  endDate: string | null,
  virsEndDate: string | null,
  date: string,
  isSelectedDateEventDate?: boolean,
  eventDate?: string,
  newDate?: string
) => {
  if ((virsEndDate && virsEndDate === date && virsEndDate !== startDate) || date === newDate) {
    return 'center';
  }
  if (
    startDate === date ||
    (eventDate && eventDate <= date && isSelectedDateEventDate) ||
    (endDate && endDate === date) ||
    (eventDate && newDate && eventDate < newDate && !isShareholderFromJADIS)
  ) {
    return 'flex-start';
  }
  return 'center';
};

export const getValueSelectedDate = (
  showShares: boolean,
  showVotes: boolean,
  sharePercentage: number | null,
  votesPercentage: number | null,
  sharePercentageLessThanPermitted: boolean | null,
  votePercentageLessThanPermitted: boolean | null
) => {
  if (showShares && sharePercentage) {
    return replaceDecimalPoint(
      `${sharePercentageLessThanPermitted ? '< ' : ''}${sharePercentage}%`
    );
  }
  if (
    showVotes &&
    votesPercentage !== undefined &&
    votesPercentage !== null &&
    votesPercentage >= 0
  ) {
    return replaceDecimalPoint(`${votePercentageLessThanPermitted ? '< ' : ''}${votesPercentage}%`);
  }
  return false;
};

export const getVerticalBorder = (
  startDate: string,
  endDate: string | null,
  date: string,
  startWithTriangle?: boolean,
  borderColor?: string,
  eventDate?: string,
  isActivityPeriodEndDate?: boolean
) => {
  const border = `1px solid ${borderColor}`;
  if (!borderColor) return undefined;
  if (isActivityPeriodEndDate) return border;
  if (endDate === eventDate) return border;
  if (startWithTriangle === false && startDate === date) return border;
  if (startWithTriangle === false && endDate === date) return border;
  if (eventDate && endDate !== null && eventDate < endDate) return border;
  return undefined;
};

export const getJustifyContentOnFilter = (
  startDate: string,
  endDate: string | null,
  date: string,
  periodFilterFromDate: string
) => {
  if (periodFilterFromDate === date && date !== startDate) {
    return 'flex-start';
  }
  return 'center';
};

export const getVirsWidthOnFilter = (
  startDate: string,
  endDate: string | null,
  date: string,
  periodFilterFromDate: string
) => {
  if (periodFilterFromDate === date && date !== startDate) {
    return '101%';
  }
  if (startDate === date) {
    return '58%';
  }
  if (endDate === date) {
    return '43%';
  }
  return '101%';
};

export const getWidthOnFilter = (
  startDate: string,
  endDate: string | null,
  date: string,
  isSelectedDateEventDate?: boolean,
  eventDate?: string
) => {
  if (startDate === date) {
    return '58%';
  }
  if (eventDate && eventDate === date && isSelectedDateEventDate) {
    return '58%';
  }
  if (endDate === date || (eventDate && eventDate < date && isSelectedDateEventDate)) {
    return '43%';
  }
  return '101%';
};

export const getLeftParameterOnFilter = (
  startDate: string,
  date: string,
  periodFilterFromDate: string
) => {
  if (periodFilterFromDate === date && date !== startDate) {
    return '0';
  }
  if (startDate === date) {
    return '43%';
  }
  return '0';
};

export const getWidthTest = (
  startDate: string,
  endDate: string | null,
  date: string,
  isSelectedDateEventDate?: boolean,
  eventDate?: string
) => {
  if (startDate === date) {
    return '57%';
  }
  if (eventDate && eventDate === date && isSelectedDateEventDate) {
    return '57%';
  }
  if (endDate === date || (eventDate && eventDate < date && isSelectedDateEventDate)) {
    return '43%';
  }
  return '100%';
};
