import React from 'react';
import { Typography, Button, Popover } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import virsisTheme from '../../style/virsisTheme';

interface Props {
  onClick?: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      backgroundColor: 'transparent',
      boxShadow: 'unset'
    },
    margin: {
      margin: '15px 0px',
      marginLeft: '5px'
    },
    button: {
      padding: 0,
      borderColor: 'transparent',
      backgroundColor: virsisTheme.palette.secondary.light,
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      color: '#637082',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        marginTop: 15,
        display: 'flex'
      }
    },
    themeButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.primary.main
      },
      '&:active': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: '#DBEDFC',
        color: virsisTheme.palette.primary.main
      }
    },
    dangerButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.error.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.error.main
      }
    },
    icon: {
      height: 30,
      padding: '5px 10px',
      borderRight: '1px solid #C4E2FC',
      color: 'inherit'
    },
    text: {
      color: '#637082',
      padding: '1px 15px'
    }
  })
);

const DeleteButton: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionButtonToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        aria-label="Išskleisti daugiau veiksmų"
        variant="outlined"
        onClick={handleActionButtonToggle}
        className={`${classes.button} ${classes.themeButton} ${classes.margin}`}
        style={
          anchorEl
            ? {
                backgroundColor: '#DBEDFC',
                borderColor: virsisTheme.palette.primary.main
              }
            : {}
        }
      >
        <MoreVertIcon fontSize="small" className={classes.icon} style={{ borderRight: 'none' }} />
      </Button>
      <Popover
        id="delete-action"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setAnchorEl(null);
            onClick?.();
          }}
          className={`${classes.button} ${classes.dangerButton}`}
        >
          <DeleteIcon fontSize="small" className={classes.icon} />
          <Typography variant="h5" className={classes.text}>
            Pašalinti nepasirašytus duomenis
          </Typography>
        </Button>
      </Popover>
    </>
  );
};

export default DeleteButton;
