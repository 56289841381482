import React from 'react';
import { useField } from 'formik';
import { IconButton, TextField, TextFieldProps, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import { FormikFieldStyleProps } from './FieldStyles';

type FormikTextFieldProps = {
  formikKey: string;
  error: boolean;
  length: number;
  setFieldValue: (id: string, value: string) => void;
  clearIcon?: boolean;
  disabled?: boolean;
} & TextFieldProps &
  FormikFieldStyleProps;

interface Props extends FormikFieldStyleProps {
  styledClasses: Record<string, string>;
  error: string | undefined;
  formikKey: string;
  label?: string;
  values: string | undefined | null | number;
  setFieldValue: (id: string, value: string) => void;
  required?: boolean;
  maxLength?: number;
  withoutLabel?: boolean;
  isMultiline?: boolean;
  rowsCount?: number;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  fullWidth?: boolean;
  className?: string;
}

export const FormikTextField = ({
  error,
  formikKey,
  length,
  setFieldValue,
  clearIcon,
  disabled,
  style,
  ...props
}: FormikTextFieldProps) => {
  const [field] = useField(formikKey);
  return (
    <TextField
      error={error}
      id={field.name}
      name={field.name}
      value={field.value ? field.value : ''}
      onChange={(event) => setFieldValue(field.name, event.target.value)}
      onBlur={field.onBlur}
      FormHelperTextProps={{ style: { ...style?.helper } }}
      inputProps={
        field.value && clearIcon
          ? {
              maxLength: length,
              endAdornment: (
                <IconButton onClick={() => setFieldValue(field.name, '')}>
                  <ClearIcon />
                </IconButton>
              )
            }
          : { maxLength: length }
      }
      disabled={disabled}
      {...props}
    />
  );
};

FormikTextField.defaultProps = {
  clearIcon: undefined,
  disabled: undefined
};

export const FormikFormTextField: React.FC<Props> = ({
  styledClasses,
  error,
  formikKey,
  label,
  values,
  setFieldValue,
  maxLength,
  withoutLabel,
  rowsCount,
  isMultiline,
  placeholder,
  disabled,
  style,
  isClearable,
  className,
  ...props
}) => {
  return (
    <Box
      margin={1}
      className={styledClasses.inputContainer + ' ' + className}
      style={style?.container}
    >
      {!withoutLabel && (
        <Typography variant="h4" className={styledClasses.inputLabel}>
          {label}
        </Typography>
      )}

      <FormikTextField
        length={maxLength || 360}
        helperText={error}
        error={!!error}
        className={styledClasses.textField}
        formikKey={formikKey}
        style={style}
        variant="outlined"
        rows={rowsCount}
        multiline={isMultiline}
        placeholder={placeholder}
        disabled={disabled}
        setFieldValue={setFieldValue}
        {...props}
      />
      {values && isClearable !== false && (
        <IconButton
          className={styledClasses.clearIcon}
          disabled={disabled}
          onClick={() => {
            setFieldValue(formikKey, '');
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};
