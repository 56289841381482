import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, CircularProgress, makeStyles, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight, ErrorOutline } from '@material-ui/icons';

import { TimelineDisplayInner } from './TimelineDisplayInner';
import { TimelineCursorOverlay } from './TimelineCursor';
import { changeVisiblePeriod } from './state/actions';
import { selector } from './state/selector';

const useStyles = makeStyles({
  timelineContainer: {
    marginTop: 40,
    display: 'flex',
    height: '110px',
    '&>*': {
      position: 'relative',
      height: '100%',
      flex: 1,
      '&:first-child': {
        flex: '0 0 30px'
      },
      '&:last-child': {
        flex: '0 0 30px'
      }
    }
  },
  button: {
    borderRadius: 0,
    backgroundColor: '#f5f7ff',
    '&:hover': {
      backgroundColor: '#DBEDFC'
    },
    '&:first-child': {
      borderRight: 'solid 1px #c3e2fb'
    },
    '&:last-child': {
      borderLeft: 'solid 1px #c3e2fb'
    }
  },
  loadingIndicator: {
    position: 'absolute',
    left: '2px',
    bottom: '2px'
  }
});

export const TimelineContainer: React.FC = () => {
  const classes = useStyles();
  const isLoading = useSelector(selector.isLoading);
  const error = useSelector(selector.error);
  const dispatch = useDispatch();

  return (
    <Box className={classes.timelineContainer}>
      <Button className={classes.button} onClick={() => dispatch(changeVisiblePeriod(-1))}>
        <ChevronLeft color="primary" />
      </Button>

      <Box style={{ overflow: 'hidden' }}>
        <TimelineDisplayInner />

        <TimelineCursorOverlay />

        {isLoading && <CircularProgress size="18px" className={classes.loadingIndicator} />}

        {!isLoading && error && (
          <Tooltip title={error}>
            <ErrorOutline
              style={{ fontSize: '18px' }}
              className={classes.loadingIndicator}
              color="error"
            />
          </Tooltip>
        )}
      </Box>

      <Button className={classes.button} onClick={() => dispatch(changeVisiblePeriod(1))}>
        <ChevronRight color="primary" />
      </Button>
    </Box>
  );
};
