import MomentUtils from '@date-io/moment';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, TimePicker } from 'formik-material-ui-pickers';
import moment, { Moment } from 'moment';
import { FormikFormTextField } from '../FormikFields/FormikFormTextField';
import { AuditActionTypesDropdown } from './AuditActionTypesDropdown';
import { ApplicationState } from '../../store';
import { FormikFormSelectField } from '../FormikFields/FormikFormSelectField';
import { AuditLegalPersonSearchDialog } from './AuditLegalPersonSearchDialog';
import { AuditNaturalPersonSearchDialog } from './AuditNaturalPersonSearchDialog';
import { AuditVirsSearchDialog } from './AuditVirsSearchDialog';
import {
  fetchAuditActionTypesRequest,
  fetchAuditOutletTypesRequest,
  fetchAuditsDataRequest
} from '../../store/audits/auditsActions';
import { AuditActionType, AuditOutletType } from '../../store/audits/auditsTypes';
import { LegalPersonSearchData } from '../../store/legalPersonData/legalDataTypes';
import { NaturalPersonSearchData } from '../../store/persons/personsTypes';
import { resetSearchVirsState } from '../../store/virsis/actions';
import { clearFormikForm } from '../../utils/InputValueFunctions';
import { getAuditsDateTimeFrom, getAuditsDateTimeTo } from '../../utils/tableDataFunctions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Strings } from '../../utils/strings/Strings';

export interface Values {
  auditActionTypeNames: AuditActionType[];
  actionUserFullName: string;
  actionLegalPerson: LegalPersonSearchData;
  dateFrom: string | null;
  timeFrom: string | null;
  dateTo: string | null;
  timeTo: string | null;
  relatedVirs: string;
  relatedOutlet: string;
  relatedOutletType: AuditOutletType;
  relatedPerson: NaturalPersonSearchData;
  ipAddress: string;
  auditDetails: string;
}

const useFormStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 320
  },
  inputLabel: {
    margin: 'auto',
    width: 330,
    lineHeight: '25px',
    textAlign: 'right'
  },
  inputContainer: {
    margin: 4,
    display: 'grid',
    gridTemplateColumns: '330px 320px 30px',
    gap: '12px',
    '& button': {
      padding: '4px'
    }
  },
  noClear: {
    gridTemplateColumns: '330px 320px'
  },
  inputContainerTime: {
    margin: 4,
    display: 'grid',
    gridTemplateColumns: '330px auto',
    gap: '12px'
  },
  clearIcon: {
    size: '12px',
    padding: '4px',
    margin: '0 auto auto auto'
  },
  button: {
    height: '34px',
    width: '100px',
    float: 'right',
    marginRight: '334px'
  },
  dateField: {
    fontSize: '12px',
    padding: '4px'
  },
  dateInput: {
    width: 135,
    '& div': {
      paddingRight: 4
    },
    '& button': {
      padding: 0,
      '& svg': {
        transition: 'fill 0.1s'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          fill: '#006FB2'
        }
      }
    }
  },
  timeInput: {
    width: 92
  },
  selectInput: {
    width: '320px'
  },
  clearButton: {
    width: 30,
    height: 30,
    margin: 'auto 0 auto 10px'
  },
  clearButtonWrap: {
    display: 'flex',
    width: 25,
    marginRight: 4
  },
  clearButtonDateTime: {
    padding: 0,
    width: 20,
    height: 20,
    margin: 'auto',
    '& svg': {
      height: 18,
      width: 18
    }
  },
  linkContainer: {
    margin: 4,
    display: 'grid',
    gridTemplateColumns: '330px auto',
    gap: '12px'
  },
  link: {
    height: 'auto',
    padding: '0 !important',
    margin: 0,
    width: 'max-content',
    '&> span': {
      color: '#006FB2'
    },
    '&:hover': {
      backgroundColor: 'inherit',
      '&> span': {
        textDecoration: 'underline'
      }
    }
  },
  selectedPerson: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputContainerTimeInputWrapper: {
    display: 'flex'
  },
  inputContainerTimeWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  formGroup: {
    maxWidth: 'fit-content'
  },
  inputTypeDateToLabel: {
    margin: 'auto 10px auto 20px'
  },
  [theme.breakpoints.down('md')]: {
    inputContainerTimeWrapper: {
      flexWrap: 'wrap',
      gap: 10
    },
    inputTypeDateToLabel: {
      margin: 'auto 22px auto 0px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    formGroup: {
      maxWidth: 'unset'
    },
    inputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 5,
      width: '100%'
    },
    inputLabel: {
      width: '100%',
      textAlign: 'left'
    },
    selectInput: {
      width: '100%',
      minWidth: 'unset'
    },
    textField: {
      width: '100%',
      minWidth: 'unset'
    },
    buttonBox: {
      display: 'flex',
      gap: 10,
      margin: 4
    },
    button: {
      width: '100%',
      marginRight: '0 !important',
      marginLeft: 0
    },
    inputContainerTime: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    dateInput: {
      width: '100%'
    },
    timeInput: {
      width: '100%'
    },
    inputContainerTimeInputWrapper: {
      gap: 5
    },
    clearButtonWrap: {
      width: 'unset',
      margin: 0
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    }
  }
}));

export const AuditSearchForm = () => {
  const classes = useFormStyles();
  const reduxDispatch = useDispatch();
  const [legalDialogOpen, setLegalDialogOpen] = useState(false);
  const [naturalDialogOpen, setNaturalDialogOpen] = useState(false);
  const [virsDialogOpen, setVirsDialogOpen] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const {
    virsis: { selectedLegalRepresentative, selectedPersonRepresentative },
    auditsData: { auditOutletTypes, auditActionTypes, loadingAuditsData }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  useEffect(() => {
    if (!auditActionTypes) reduxDispatch(fetchAuditActionTypesRequest());
  }, [auditActionTypes, reduxDispatch]);

  useEffect(() => {
    if (!auditOutletTypes) reduxDispatch(fetchAuditOutletTypesRequest());
  }, [reduxDispatch, auditOutletTypes]);

  const initValues: Partial<Values> = {
    auditActionTypeNames: undefined,
    actionUserFullName: undefined,
    actionLegalPerson: undefined,
    dateFrom: null,
    timeFrom: null,
    dateTo: null,
    timeTo: null,
    relatedVirs: undefined,
    relatedOutlet: undefined,
    relatedOutletType: undefined,
    relatedPerson: undefined,
    ipAddress: undefined,
    auditDetails: undefined
  };

  const validationSchema = Yup.object().shape({
    auditActionTypeNames: Yup.array(),
    actionUserFullName: Yup.string(),
    actionLegalPerson: Yup.object().shape({
      personId: Yup.number(),
      personCode: Yup.string(),
      personFullName: Yup.string()
    }),
    relatedVirs: Yup.string(),
    relatedOutlet: Yup.string(),
    relatedOutletType: Yup.object().shape({
      auditOutletTypeId: Yup.number(),
      auditOutletTypeName: Yup.string()
    }),
    relatedPerson: Yup.object().shape({
      personId: Yup.number(),
      personCode: Yup.string(),
      personFullName: Yup.string()
    }),
    ipAddress: Yup.string(),
    auditDetails: Yup.string()
  });

  function handleClickOpenLegalPersonDialog() {
    setLegalDialogOpen(true);
  }

  function handleClickCloseLegalPersonDialog() {
    setLegalDialogOpen(false);
  }

  function handleClickOpenNaturalPersonDialog() {
    setNaturalDialogOpen(true);
  }

  function handleClickCloseNaturalPersonDialog() {
    setNaturalDialogOpen(false);
  }

  function handleClickOpenVirsPersonDialog() {
    setVirsDialogOpen(true);
    reduxDispatch(resetSearchVirsState());
  }

  function handleClickCloseVirsPersonDialog() {
    setVirsDialogOpen(false);
  }

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        const {
          auditActionTypeNames,
          actionUserFullName,
          actionLegalPerson,
          dateFrom,
          timeFrom,
          dateTo,
          timeTo,
          relatedVirs,
          relatedOutlet,
          relatedOutletType,
          relatedPerson,
          ipAddress,
          auditDetails
        } = values;

        reduxDispatch(
          fetchAuditsDataRequest({
            auditActionTypes: auditActionTypeNames || null,
            actionUserFullName: actionUserFullName || null,
            actionLegalPersonId: actionLegalPerson?.personId || null,
            dateTimeFrom: getAuditsDateTimeFrom(dateFrom, timeFrom),
            dateTimeTo: getAuditsDateTimeTo(dateTo, timeTo),
            relatedVirs: relatedVirs || null,
            relatedOutlet: relatedOutlet || null,
            relatedOutletTypeId: relatedOutletType?.auditOutletTypeId || null,
            relatedPersonId: relatedPerson?.personId || null,
            ipAddress: ipAddress || null,
            auditDetails: auditDetails || null
          })
        );
      }}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Form>
              <FormGroup className={classes.formGroup}>
                <AuditActionTypesDropdown<AuditActionType>
                  value={values.auditActionTypeNames || []}
                  styledClasses={classes}
                  formikKey="auditActionTypeNames"
                  options={auditActionTypes || []}
                  getOptionLabel={(option) => option.auditActionTypeName}
                  label="Veiksmo tipas"
                  setFieldValue={setFieldValue}
                  style={{ gridTemplateColumns: '330px 320px auto' }}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        color="primary"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.auditActionTypeName}
                    </>
                  )}
                />

                <FormikFormTextField
                  styledClasses={classes}
                  label="Naudotojo, kuris atliko veiksmą, vardas pavardė"
                  formikKey="actionUserFullName"
                  values={values.actionUserFullName}
                  error={errors.actionUserFullName}
                  setFieldValue={setFieldValue}
                />

                <Box className={classes.linkContainer}>
                  <Typography variant="h4" className={classes.inputLabel}>
                    Atstovaujamas juridinis asmuo
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <div>
                      <Button className={classes.link} onClick={handleClickOpenLegalPersonDialog}>
                        Surasti duomenų bazėje
                      </Button>

                      {values.actionLegalPerson && (
                        <Box className={classes.selectedPerson}>
                          <Typography variant="body2">
                            {values.actionLegalPerson.personFullName}
                          </Typography>
                          <Typography>{values.actionLegalPerson.personCode}</Typography>
                        </Box>
                      )}
                    </div>
                    {values.actionLegalPerson && (
                      <IconButton
                        className={classes.clearButton}
                        onClick={() => {
                          setFieldValue('actionLegalPerson', undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <Box className={classes.inputContainerTime}>
                  <Typography variant="h4" className={classes.inputLabel}>
                    Laikotarpis
                  </Typography>
                  <div className={classes.inputContainerTimeWrapper}>
                    <div className={classes.inputContainerTimeInputWrapper}>
                      <Typography style={{ margin: 'auto 10px auto 0' }}>nuo</Typography>
                      <Field
                        component={DatePicker}
                        format="YYYY-MM-DD"
                        name="dateFrom"
                        disabled={false}
                        inputVariant="outlined"
                        classes={{ root: classes.dateInput }}
                        placeholder="mmmm-mm-dd"
                        inputProps={{
                          value: values.dateFrom ? moment(values.dateFrom).format('YYYY-MM-DD') : ''
                        }}
                        value={values.dateTo ? moment(values.dateTo).subtract(1, 'days') : moment()}
                        cancelLabel={Strings.button__cancel}
                        disableFuture
                        okLabel="Pasirinkti"
                        onChange={(newValue: string) => {
                          if (!values.timeFrom) {
                            setFieldValue('timeFrom', moment(newValue).startOf('day'), true);
                          }
                          setFieldValue('dateFrom', moment(newValue), true);
                        }}
                        maxDate={values.dateTo ? values.dateTo : undefined}
                        maxDateMessage={`Data negali būti velesnė už ${moment(values.dateTo).format(
                          'YYYY-MM-DD'
                        )}`}
                      />
                      <div className={classes.clearButtonWrap}>
                        {values.dateFrom && (
                          <IconButton
                            className={classes.clearButtonDateTime}
                            onClick={() => {
                              setFieldValue('dateFrom', null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>
                      <Field
                        component={TimePicker}
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        name="timeFrom"
                        disabled={!values.dateFrom}
                        placeholder="hh:mm:ss"
                        inputVariant="outlined"
                        classes={{ root: classes.timeInput }}
                        inputProps={{
                          value: values.timeFrom ? moment(values.timeFrom).format('HH:mm:ss') : ''
                        }}
                        onAccept={(time: Moment) => {
                          if (
                            time.isAfter(moment()) &&
                            moment().startOf('day').isSame(moment(values.dateFrom).startOf('day'))
                          ) {
                            setFieldValue('timeFrom', moment());
                          }
                        }}
                        cancelLabel={Strings.button__cancel}
                        okLabel="Pasirinkti"
                      />
                      <div className={classes.clearButtonWrap}>
                        {values.timeFrom && (
                          <IconButton
                            className={classes.clearButtonDateTime}
                            onClick={() => {
                              setFieldValue('timeFrom', null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    <div className={classes.inputContainerTimeInputWrapper}>
                      <Typography className={classes.inputTypeDateToLabel}>iki</Typography>
                      <Field
                        component={DatePicker}
                        format="YYYY-MM-DD"
                        name="dateTo"
                        placeholder="mmmm-mm-dd"
                        disabled={false}
                        inputVariant="outlined"
                        classes={{ root: classes.dateInput }}
                        disableFuture
                        inputProps={{
                          value: values.dateTo ? moment(values.dateTo).format('YYYY-MM-DD') : ''
                        }}
                        onChange={(newValue: string) => {
                          if (!values.timeTo) {
                            setFieldValue('timeTo', moment(), true);
                          }
                          setFieldValue('dateTo', moment(newValue), true);
                        }}
                        cancelLabel={Strings.button__cancel}
                        okLabel="Pasirinkti"
                        minDate={values.dateFrom ? values.dateFrom : undefined}
                        minDateMessage={`Data negali būti ankstesnė už ${moment(
                          values.dateFrom
                        ).format('YYYY-MM-DD')}`}
                      />
                      <div className={classes.clearButtonWrap}>
                        {values.dateTo && (
                          <IconButton
                            className={classes.clearButtonDateTime}
                            onClick={() => {
                              setFieldValue('dateTo', null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>

                      <Field
                        component={TimePicker}
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes', 'seconds']}
                        placeholder="hh:mm:ss"
                        name="timeTo"
                        disabled={!values.dateTo}
                        inputVariant="outlined"
                        classes={{ root: classes.timeInput }}
                        inputProps={{
                          value: values.timeTo ? moment(values.timeTo).format('HH:mm:ss') : ''
                        }}
                        onAccept={(time: Moment) => {
                          if (
                            time.isAfter(moment()) &&
                            moment().startOf('day').isSame(moment(values.dateFrom).startOf('day'))
                          ) {
                            setFieldValue('timeTo', moment());
                          }
                        }}
                        cancelLabel={Strings.button__cancel}
                        okLabel="Pasirinkti"
                      />
                      <div className={classes.clearButtonWrap}>
                        {values.timeTo && (
                          <IconButton
                            className={classes.clearButtonDateTime}
                            onClick={() => {
                              setFieldValue('timeTo', null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>

                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <FormikFormTextField
                    styledClasses={classes}
                    label="Susijęs VIRS"
                    formikKey="relatedVirs"
                    isClearable={false}
                    placeholder="VIRS pavadinimas"
                    values={values.relatedVirs}
                    error={errors.relatedVirs}
                    setFieldValue={setFieldValue}
                    style={{
                      container: { gridTemplateColumns: '330px 320px' }
                    }}
                  />
                  <Button
                    className={classes.link}
                    style={{ marginLeft: 5 }}
                    onClick={handleClickOpenVirsPersonDialog}
                  >
                    Išplėstinė paieška
                  </Button>
                </Box>

                <FormikFormTextField
                  styledClasses={classes}
                  label="Susijusi VIP"
                  formikKey="relatedOutlet"
                  values={values.relatedOutlet}
                  error={errors.relatedOutlet}
                  setFieldValue={setFieldValue}
                />

                <FormikFormSelectField
                  isClearable
                  getOptionLabel={(option: AuditOutletType) => option.auditOutletTypeName}
                  formikKey="relatedOutletType"
                  value={values.relatedOutletType}
                  errors={errors.relatedOutletType}
                  options={auditOutletTypes || []}
                  isRequired={false}
                  styledClasses={classes}
                  label="VIP rūšis"
                  setFieldValue={setFieldValue}
                />

                <Box className={classes.linkContainer}>
                  <Typography variant="h4" className={classes.inputLabel}>
                    Susijęs asmuo
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <div>
                      <Button className={classes.link} onClick={handleClickOpenNaturalPersonDialog}>
                        Surasti duomenų bazėje
                      </Button>

                      {values.relatedPerson && (
                        <Box className={classes.selectedPerson}>
                          <Typography variant="body2">
                            {values.relatedPerson.personFullName}
                          </Typography>
                          <Typography>{values.relatedPerson.personCode}</Typography>
                        </Box>
                      )}
                    </div>
                    {values.relatedPerson && (
                      <IconButton
                        className={classes.clearButton}
                        onClick={() => {
                          setFieldValue('relatedPerson', undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <FormikFormTextField
                  styledClasses={classes}
                  label="IP adresas"
                  formikKey="ipAddress"
                  values={values.ipAddress}
                  error={errors.ipAddress}
                  setFieldValue={setFieldValue}
                />

                <FormikFormTextField
                  styledClasses={classes}
                  label="Papildomi duomenys"
                  formikKey="auditDetails"
                  values={values.auditDetails}
                  error={errors.auditDetails}
                  setFieldValue={setFieldValue}
                />

                <Box className={classes.buttonBox}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      isValidating ||
                      loadingAuditsData ||
                      ((!values.auditActionTypeNames || values.auditActionTypeNames.length === 0) &&
                        !values.actionUserFullName &&
                        !values.actionLegalPerson &&
                        !values.dateFrom &&
                        !values.dateTo &&
                        !values.dateTo &&
                        !values.relatedVirs &&
                        !values.relatedOutlet &&
                        !values.relatedOutletType &&
                        !values.relatedPerson &&
                        !values.ipAddress &&
                        !values.auditDetails)
                    }
                    onClick={submitForm}
                    className={classes.button}
                    startIcon={<SearchIcon />}
                  >
                    <Typography color="inherit" variant="h5">
                      Ieškoti
                    </Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    disabled={isValidating}
                    onClick={() => clearFormikForm(setFieldValue, values)}
                    className={classes.button}
                    style={{ marginRight: '4px' }}
                    startIcon={<ClearIcon />}
                  >
                    <Typography color="inherit">Išvalyti</Typography>
                  </Button>
                </Box>
              </FormGroup>
            </Form>
            <AuditLegalPersonSearchDialog
              dialogOpen={legalDialogOpen}
              closeDialog={handleClickCloseLegalPersonDialog}
              closeAndContinueAction={() => {
                setFieldValue('actionLegalPerson', {
                  personId: selectedLegalRepresentative?.personId,
                  personCode: selectedLegalRepresentative?.personCode,
                  personFullName: selectedLegalRepresentative?.personFullName
                });
              }}
            />
            <AuditNaturalPersonSearchDialog
              dialogOpen={naturalDialogOpen}
              closeDialog={handleClickCloseNaturalPersonDialog}
              closeAndContinueAction={() => {
                setFieldValue('relatedPerson', {
                  personId: selectedPersonRepresentative?.personId,
                  personCode: selectedPersonRepresentative?.personCode,
                  personFullName: selectedPersonRepresentative?.personFullName
                });
              }}
            />
            <AuditVirsSearchDialog
              dialogOpen={virsDialogOpen}
              closeDialog={handleClickCloseVirsPersonDialog}
              setFieldValue={setFieldValue}
              field="relatedVirs"
            />
          </MuiPickersUtilsProvider>
        );
      }}
    </Formik>
  );
};
