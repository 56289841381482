import React from 'react';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { OutletShortData } from '../../store/outlets/outletsTypes';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const getOutletMultiOption = (
  option: OutletShortData,
  state: AutocompleteRenderOptionState
) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <Checkbox
      color="primary"
      icon={icon}
      checkedIcon={checkedIcon}
      style={{ marginRight: 8 }}
      checked={state.selected}
    />
    {getOutletOption(option)}
  </Box>
);

export const getOutletOption = (option: OutletShortData) => (
  <div>
    {option.outletName}
    <br />
    <Typography color="textSecondary" style={{ fontSize: '0.8rem' }}>
      {option.outletClosureStatus ? '(uždarytas)' : ''}
    </Typography>
  </div>
);

export const disableOthersIfFictitiousSelected =
  (fictitiousOutlet?: OutletShortData) =>
  (option: OutletShortData, selectedOptions: OutletShortData[]) => {
    if (fictitiousOutlet) {
      return option.outletId === fictitiousOutlet.outletId
        ? false
        : selectedOptions.some(({ outletId }) => outletId === fictitiousOutlet.outletId);
    }
    return false;
  };
