import { PaginationParams, SortOrder } from '../../utils/tableTypes';
import {
  EditionCheckDataTableCustomFilter,
  EditionCheckDataTableField
} from '../../containers/EditionCheckTableInst/tableState/tableTypesAndActions';

export interface EditionChecksDataTableState {
  customFilter: EditionCheckDataTableCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  order: SortOrder;
  sortBy: EditionCheckDataTableField;
  page: number;
  rowsPerPage: number;
  recordCount: number;
}

export interface EditionChecksPageRequest extends PaginationParams {
  filterVirsId: number | null;
  filterCheckDate: string | null;
  filterDocNr: string | null;
  filterOutletId: number | null;
  filterPeriodId: number | null;
  filterConclusionId: number | null;
  sortingVirsName: SortOrder | null;
  sortingCheckDate: SortOrder | null;
  sortingDocNr: SortOrder | null;
  sortingOutletName: SortOrder | null;
  sortingPeriodName: SortOrder | null;
  sortingConclusion: SortOrder | null;
}

export const initialEditionCheckTableDataState: EditionChecksDataTableState = {
  customFilter: {
    virsName: [],
    conclusion: [],
    checkDate: [],
    docNr: [],
    outletName: [],
    periodName: []
  },
  order: 'desc',
  page: 0,
  rowsPerPage: 10,
  showStatusNotSigned: false,
  showStatusOutdated: false,
  showStatusRelevant: false,
  sortBy: 'virsName',
  statusNotSignedFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  recordCount: 0
};
