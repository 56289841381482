import { action } from 'typesafe-actions';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import {
  PAYMENT_DATA_REQUEST,
  PAYMENT_DATA_SUCCESS,
  PAYMENT_DATA_ERROR,
  PAYMENT_DOCUMENT_PDF_REQUEST,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  RESET_CREATE_PAYMENT_STATE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR,
  RESET_UPDATE_PAYMENT_STATE,
  REMOVE_PAYMENT_SUCCESS,
  REMOVE_PAYMENT_REQUEST,
  REMOVE_PAYMENT_ERROR,
  RESET_REMOVE_PAYMENT_STATE,
  VIRS_PAYMENT_DATA_REQUEST,
  VIRS_PAYMENT_DATA_SUCCESS,
  VIRS_PAYMENT_DATA_ERROR,
  VIRS_PAYMENT_DOCUMENT_PDF_REQUEST,
  RESET_ANNUL_PAYMENT_DATA_STATE,
  ANNUL_PAYMENT_DATA_SUCCESS,
  ANNUL_PAYMENT_DATA_REQUEST,
  ANNUL_PAYMENT_DATA_ERROR,
  CLOSE_PAYMENT_SUCCESS,
  CLOSE_PAYMENT_ERROR,
  RESET_CLOSING_PAYMENT_STATE,
  CLOSE_PAYMENT_REQUEST,
  PAYMENT_DATA_BY_DOC_ID_REQUEST
} from './paymentsActionTypes';
import { PaymentDataInst } from './paymentsTypes';

export const getPaymentDataRequest = () => action(PAYMENT_DATA_REQUEST);
export const getPaymentDataSuccess = (paymentData: PaymentDataInst) =>
  action(PAYMENT_DATA_SUCCESS, paymentData);
export const getPaymentDataError = (error: Error) => action(PAYMENT_DATA_ERROR, error);

export const getPaymentDocumentInNewTabRequest = (
  paymentDocumentId: number,
  documentNumber: string
) => action(PAYMENT_DOCUMENT_PDF_REQUEST, { paymentDocumentId, documentNumber });

export const createPayment = (formRecord: FormData) =>
  action(CREATE_PAYMENT_REQUEST, { formRecord });
export const createPaymentSuccess = (data: string) => action(CREATE_PAYMENT_SUCCESS, data);
export const createPaymentError = (error: Error) => action(CREATE_PAYMENT_ERROR, error);
export const resetPaymentCreatingState = () => action(RESET_CREATE_PAYMENT_STATE);

export const updatePayment = (formRecordUpdated: FormData, paymentId: number) =>
  action(UPDATE_PAYMENT_REQUEST, {
    formRecordUpdated,
    paymentId
  });
export const updatePaymentSuccess = (data: string) => action(UPDATE_PAYMENT_SUCCESS, data);
export const updatePaymentError = (error: Error) => action(UPDATE_PAYMENT_ERROR, error);
export const resetPaymentUpdatingState = () => action(RESET_UPDATE_PAYMENT_STATE);

export const removePayment = (paymentId: number) => action(REMOVE_PAYMENT_REQUEST, { paymentId });
export const removePaymentSuccess = () => action(REMOVE_PAYMENT_SUCCESS);
export const removePaymentError = (error: Error) => action(REMOVE_PAYMENT_ERROR, error);
export const resetPaymentRemovingState = () => action(RESET_REMOVE_PAYMENT_STATE);

export const getVirsPaymentDataRequest = (virsId: number) =>
  action(VIRS_PAYMENT_DATA_REQUEST, virsId);
export const getVirsPaymentDataSuccess = (paymentData: PaymentDataInst) =>
  action(VIRS_PAYMENT_DATA_SUCCESS, paymentData);
export const getVirsPaymentDataError = (error: Error) => action(VIRS_PAYMENT_DATA_ERROR, error);

export const getVirsPaymentDocumentInNewTabRequest = (
  paymentDocumentId: number,
  documentNumber: string
) =>
  action(VIRS_PAYMENT_DOCUMENT_PDF_REQUEST, {
    paymentDocumentId,
    documentNumber
  });

export const annulPayment = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_PAYMENT_DATA_REQUEST, {
    documentStatusId,
    annulRecord
  });
export const annulPaymentSuccess = () => action(ANNUL_PAYMENT_DATA_SUCCESS);
export const annulPaymentError = (error: Error) => action(ANNUL_PAYMENT_DATA_ERROR, error);
export const resetAnnulPaymentState = () => action(RESET_ANNUL_PAYMENT_DATA_STATE);
export const resetClosingPaymentState = () => action(RESET_CLOSING_PAYMENT_STATE);

export const closePayment = (paymentId: number, validTo: string | null) =>
  action(CLOSE_PAYMENT_REQUEST, {
    paymentId,
    validTo
  });
export const closePaymentSuccess = (data: string) => action(CLOSE_PAYMENT_SUCCESS, data);
export const closePaymentError = (error: Error) => action(CLOSE_PAYMENT_ERROR, error);

export const getPaymentDataByDocIdRequest = (docId: number) =>
  action(PAYMENT_DATA_BY_DOC_ID_REQUEST, docId);
