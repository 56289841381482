import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { useParams } from 'react-router';
import { ApplicationState } from '../../store';
import { EditionCheckDataTableCustomizationBar } from './CustomizationBar';
import { EditionCheckDataTableHeader } from './TableHeader';

import { EditionCheckDataTableDispatchContext, EditionCheckDataTableStateContext } from './Context';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';

import { Roles } from '../../store/virsis/dataTypes';
import EditionCheckDataTableCustomFilter from './FilterRow';
import AllowedTo from '../AllowedTo';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { editionCheckTableColumns } from './tableState/tableColumns';
import { EditionCheckDataTableNewRecord } from './TableRows/EditionCheckDataTableNewRecord';
import { EditionCheckDataTableRecord } from './TableRows/EditionCheckDataTableRecord';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import {
  initializeEditionChecksDataTableState,
  setPage,
  setRowsPerPage
} from '../../store/editionChecksTable/editionChecksTableActions';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { unsignedDataRemoveInst } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { InstDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';
import {
  fetchEditionCheckData,
  hideEditionCheckPostSaveWarning,
  fetchEditionCheckByDockIdRequest
} from '../../store/editionChecks/editionChecksActions';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { AlertDialog } from '../../components/Dialogs/AlertDialog';
import { TableLinearProgressRow } from '../../components/TableLinearProgressRow/TableLinearProgressRow';
import { AlertBox } from '../../components/Alert/AlertBox';

interface Props {
  documentId?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      tableBox: {
        flexWrap: 'wrap'
      },
      addButton: {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0,
        paddingLeft: 0
      },
      pageActionButtonBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  })
);

const EditionCheckDataTable: React.FC<Props> = ({ documentId }) => {
  const {
    editionData: {
      loadingEditionCheckData,
      editionCheckData,
      recordCount,
      editionChecksDataError,
      statusRelevantFilterEnabled,
      statusNotSignedFilterEnabled,
      statusOutdatedFilterEnabled,
      editionUpdated,
      postSaveWarningOpen,
      annullingEditionCheckSuccess
    },
    editionDataTable: { tableDataState, tempTableDataState }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const [showDocumentAnulledAlert, setShowDocumentAnulledAlert] = useState(false);

  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);

  const reduxDispatch = useDispatch();

  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const { companyCode } = useParams<{ companyCode: string }>();

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    reduxDispatch(
      initializeEditionChecksDataTableState(
        documentId ? false : statusNotSignedFilterEnabled,
        documentId ? false : statusRelevantFilterEnabled,
        documentId ? false : statusOutdatedFilterEnabled,
        documentId ? false : statusNotSignedFilterEnabled,
        documentId ? false : statusRelevantFilterEnabled,
        !statusNotSignedFilterEnabled && !statusRelevantFilterEnabled && statusOutdatedFilterEnabled
      )
    );
  }, [
    tableDispatch,
    reduxDispatch,
    statusNotSignedFilterEnabled,
    statusRelevantFilterEnabled,
    statusOutdatedFilterEnabled,
    documentId
  ]);

  useEffect(() => {
    if (companyCode) {
      tableDispatch({
        type: 'CREATE_RECORD_CLICKED',
        companyCode
      });
    }
  }, [tableDispatch, companyCode]);

  useEffect(() => {
    if (documentId) {
      reduxDispatch(fetchEditionCheckByDockIdRequest(Number(documentId)));
    }
    if (JSON.stringify(tempTableDataState) !== JSON.stringify(tableDataState) && !documentId) {
      reduxDispatch(fetchEditionCheckData());
    }
  }, [
    reduxDispatch,
    tableDataState,
    tempTableDataState,
    editionUpdated,
    documentId,
    tableState.customFilterOn
  ]);

  useEffect(() => {
    if (annullingEditionCheckSuccess) {
      setShowDocumentAnulledAlert(true);
    }
  }, [annullingEditionCheckSuccess]);

  const filteredData = editionCheckData || [];

  return (
    <>
      <Container maxWidth="xl">
        <AllowedTo roles={[Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT]}>
          <div className="data-page-header">
            <Typography variant="h1">Duomenų teikimas</Typography>
            <Typography style={{ margin: '10px 0' }} variant="subtitle1">
              Tiražo patikrinimo duomenys
            </Typography>
          </div>
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="space-between"
            flexWrap="wrap"
            className={classes.tableBox}
          >
            <AddNewRecordButton
              text="Pridėti tiražo patikrinimo įrašą"
              onClicked={() => tableDispatch({ type: 'CREATE_RECORD_CLICKED' })}
              disabled={!!editionChecksDataError || tableState.showNewRecordFields || !!documentId}
              className={classes.addButton}
            />
            <Box className={classes.pageActionButtonBox}>
              <PageActionButtons
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    filteredData,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, filteredData, navigationPath)
                }
                onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
              />
            </Box>
          </Box>
          <ConfirmRemoveUnsignedDialog
            onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
            onConfirm={() => reduxDispatch(unsignedDataRemoveInst(InstDocumentType.EDITION_CHECK))}
            onRemoveSuccess={() => reduxDispatch(fetchEditionCheckData())}
            open={confirmToDeleteUnsignedDialogOpen}
          />
        </AllowedTo>
      </Container>

      {showDocumentAnulledAlert && (
        <AlertBox
          handleClose={() => setShowDocumentAnulledAlert(false)}
          text={
            'Įrašas sėkmingai anuliuotas. Anuliuotus dokumentus galite peržiūrėti pasirinkę meniu „Duomenų istorija“.'
          }
        />
      )}

      <Container maxWidth="xl" style={{ marginTop: '1rem' }}>
        <Paper elevation={0}>
          <EditionCheckDataTableCustomizationBar
            columnNames={editionCheckTableColumns}
            disabled={tableState.showNewRecordFields || !!documentId}
          />
          <ScrollXContainer>
            <Table aria-label="simple table">
              <EditionCheckDataTableHeader />
              {tableState.customFilterOn && (
                <EditionCheckDataTableCustomFilter columnNames={editionCheckTableColumns} />
              )}
              <TableBody>
                <TableLinearProgressRow isLoading={loadingEditionCheckData} colSpan={8} />
                <TableErrorRow
                  error={
                    editionChecksDataError && 'Klaida. Nepavyko gauti tiražo tikrinimo duomenų'
                  }
                  colSpan={8}
                />
                {!editionChecksDataError && tableState.showNewRecordFields && (
                  <EditionCheckDataTableNewRecord />
                )}
                {!editionChecksDataError &&
                  !editionUpdated &&
                  filteredData &&
                  filteredData.map((record) => {
                    return (
                      <EditionCheckDataTableRecord key={record.editionCheckId} record={record} />
                    );
                  })}
              </TableBody>
            </Table>
          </ScrollXContainer>
          <TablePagination
            recordsCount={recordCount}
            pagesCount={Math.ceil(recordCount / tableDataState.rowsPerPage || 0)}
            rowsPerPage={tableDataState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableDataState.page}
            setPage={(value: number) => reduxDispatch(setPage(value))}
            setRowsPerPage={(rowsPerPage: number) => reduxDispatch(setRowsPerPage(rowsPerPage))}
            disabled={filteredData.length === 0 || recordCount === 0}
          />
        </Paper>
        <AlertDialog
          dialogText="Tiražo tikrinimas šiam laikotarpiui, VIRS ir VIP jau yra. Jei suklydote, įrašą pašalinkite arba ištaisykite."
          closeButtonText="Uždaryti"
          isOpen={postSaveWarningOpen}
          onClose={() => reduxDispatch(hideEditionCheckPostSaveWarning())}
        />
      </Container>
    </>
  );
};

export default EditionCheckDataTable;
