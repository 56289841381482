import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  addDays,
  getPeriodEnd,
  getPeriodRelativeValue,
  isMonthStart,
  isYearStart
} from './dateUtil';
import { selector } from './state/selector';
import { TimelinePeriod } from './state/types';

const useStyles = makeStyles({
  timelinePeriod: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    top: 0,
    bottom: 0,
    borderBottom: 'solid 2px #c3e2fb',
    boxSizing: 'border-box',
    '&:first-child>*': {
      borderLeft: 'none'
    },
    '&>*': {
      whiteSpace: 'nowrap',
      paddingLeft: '3px',
      borderLeft: 'solid 2px #c3e2fb',
      '&:last-child': {
        flex: '0 0 20px'
      },
      userSelect: 'none'
    }
  },
  semiBoldText: {
    color: '#9d9d9d',
    fontWeight: 600
  }
});

function getMonthName(month: number): string {
  return [
    'Sausis',
    'Vasaris',
    'Kovas',
    'Balandis',
    'Gegužė',
    'Birželis',
    'Liepa',
    'Rugpjūtis',
    'Rugsėjis',
    'Spalis',
    'Lapkritis',
    'Gruodis'
  ][month];
}

interface Props {
  period: TimelinePeriod;
}

export const TimelinePeriodDisplay: React.FC<Props> = ({ period }) => {
  const classes = useStyles();
  const periodName = useSelector(selector.periodName);
  const visibleRange = useSelector(selector.visiblePeriod);

  if (!visibleRange || !periodName) {
    return <></>;
  }

  let extendedLabelValue: string | undefined;
  switch (periodName) {
    case 'month':
      if (isYearStart(period.start) || period.start.valueOf() === visibleRange.start.valueOf()) {
        extendedLabelValue = period.start.year().toString();
      }
      break;
    case 'day':
      if (
        isMonthStart(period.start) ||
        (period.start.valueOf() === visibleRange.start.valueOf() &&
          period.start.valueOf() < addDays(getPeriodEnd(period.start, 'month'), -3).valueOf())
      ) {
        extendedLabelValue = `${period.start.year()} ${getMonthName(period.start.month())}`;
      }
      break;
    default:
      extendedLabelValue = undefined;
  }

  let labelValue: string | undefined;
  switch (periodName) {
    case 'day':
      labelValue = period.start.date().toString();
      break;
    case 'year':
      labelValue = period.start.year().toString();
      break;
    default:
      labelValue = getMonthName(period.start.month());
  }

  return (
    <div
      className={classes.timelinePeriod}
      style={{
        left: `${getPeriodRelativeValue(period.start, visibleRange) * 100}%`,
        right: `${(1 - getPeriodRelativeValue(period.end, visibleRange)) * 100}%`
      }}
    >
      <Typography
        className={classes.semiBoldText}
        style={{
          borderLeft: !extendedLabelValue ? 'none' : undefined
        }}
      >
        {extendedLabelValue}
      </Typography>

      <Typography className={classes.semiBoldText}>{labelValue}</Typography>

      <Typography />
    </div>
  );
};
