import { get, getWithQuery, post } from '../../utils/axiosApi';
import { LegalPersonRecord, LegalPersonSearchQuery } from './legalDataTypes';

export const getFindLegalPerson = (naturalPersonQuery: LegalPersonSearchQuery) => {
  const { countryCode, legalCode, legalName } = naturalPersonQuery;

  return getWithQuery('/person-data/legal', {
    countryCode,
    legalCode,
    legalName
  });
};

export const getJarCountries = () => get('/person-data/countries');

export const postCreateLegalForeignPerson = (naturalForeignPersonRecord: LegalPersonRecord) =>
  post('/person-data/legal-foreign', naturalForeignPersonRecord);
