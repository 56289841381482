import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import MomentUtils from '@date-io/moment';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { FieldGroup } from '../../components/FormikFields/GenericFormikWrappers/FieldGroup';

import { initialValues, ReportInput } from './types';
import { generateReportFile, loadReportOptions } from './store';
import { FormContent, getFieldGroupStyle } from './FormContent';
import {
  defaultDate,
  noUndefinedString
} from '../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { ApplicationState } from '../../store';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    box: {
      gap: 10
    },
    label: {
      marginRight: '0 !important'
    },
    button: {
      flex: 'unset !important',
      width: '100%'
    },
    buttonWrapper: {
      width: '100%'
    }
  }
}));

export const ReportForm: React.FC = () => {
  const dispatch = useDispatch();
  const { error, isLoading, options, selectedVirs, selectedPerson } = useSelector(
    (state: ApplicationState) => state.report
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(loadReportOptions());
  }, [dispatch]);

  const personFilter =
    options.reportSettings.personCodeFilter &&
    options.reportSettings.personFirstNameFilter &&
    options.reportSettings.personLastNameFilter &&
    options.reportSettings.personBirthDateFilter;

  const validationSchema: Yup.ObjectSchema<Yup.Shape<object | undefined, ReportInput>> =
    Yup.object().shape({
      report: noUndefinedString(),
      virsName: Yup.string().nullable(),
      virsCode: Yup.string().nullable(),
      dataBlock: noUndefinedString(),
      fileFormat: noUndefinedString(),
      sortByColumn: noUndefinedString(),
      columnFilter: noUndefinedString(),
      fromDate: defaultDate().when('todayReport', {
        is: false,
        then: Yup.date().required('Privaloma pasirinkti')
      }),
      toDate: defaultDate().min(Yup.ref('fromDate'), 'Privalo būti vėlesnė nei Nuo data'),
      detailedReport: Yup.boolean()
        .when('summarizedReport', {
          is: false,
          then: Yup.boolean().equals([true], 'Privaloma pasirinkti bent vieną'),
          otherwise: Yup.boolean()
        })
        .required(),
      includeActive: Yup.boolean().required(),
      includeAnnulled: Yup.boolean().required(),
      includeInactive: Yup.boolean().required(),
      summarizedReport: Yup.boolean().required(),
      todayReport: Yup.boolean().required(),
      filterEnabled: Yup.boolean().required(),
      personCodeFilter: Yup.string().nullable(),
      personFirstNameFilter: personFilter
        ? Yup.string().when('personCodeFilter', {
            is: (personCodeFilter) => !personCodeFilter,
            then: Yup.string().when(['personLastNameFilter', 'personBirthDateFilter'], {
              is: (valueLastName, valueBirthDate) => !valueLastName || !valueBirthDate,
              then: Yup.string()
                .nullable()
                .test(
                  'T1',
                  'Privaloma įvesti asmens kodą arba vardą, pavardę ir gimimo datą',
                  () => false
                ),
              otherwise: Yup.string()
                .nullable()
                .required('Privaloma įvesti asmens kodą arba vardą, pavardę ir gimimo datą')
            }),
            otherwise: Yup.string().nullable()
          })
        : Yup.string().nullable(),
      personLastNameFilter: personFilter
        ? Yup.string().when('personCodeFilter', {
            is: (personCodeFilter) => !personCodeFilter,
            then: Yup.string().typeError(' ').required(' '),
            otherwise: Yup.string().nullable()
          })
        : Yup.string().nullable(),
      personBirthDateFilter: personFilter
        ? Yup.date()
            .when('personCodeFilter', {
              is: (personCodeFilter) => !personCodeFilter,
              then: defaultDate(Yup.date().required(' ')),
              otherwise: defaultDate()
            })
            .defined()
        : defaultDate()
    });

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(generateReportFile(values));
        setSubmitting(false);
      }}
    >
      {(innerProps: FormikProps<ReportInput>) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Form>
            <Box
              style={{
                padding: '1em 0 0',
                display: 'flex',
                flexDirection: 'column'
              }}
              className={classes.box}
            >
              {!error && (
                <FormContent
                  selectedVirs={selectedVirs}
                  selectedPerson={selectedPerson}
                  formikProps={innerProps}
                />
              )}
            </Box>

            {error && <Typography style={{ padding: '13px' }}>Įvyko klaida.</Typography>}

            {!error && (
              <FieldGroup
                style={{
                  ...getFieldGroupStyle(),
                  container: { marginBottom: '2em' }
                }}
                labelClassName={classes.label}
                childrenClassName={classes.buttonWrapper}
              >
                <Button
                  onClick={innerProps.submitForm}
                  color="primary"
                  variant="contained"
                  style={{ flex: '0 0 300px' }}
                  disabled={isLoading}
                  className={classes.button}
                >
                  Formuoti ataskaitą
                </Button>
              </FieldGroup>
            )}
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
