import React from 'react';

import {
  EthicalNonComplianceColumnHeader,
  EthicalNonComplianceHeaderField
} from './tableState/tableInitialStateAndTypes';
import { useEthicalNonComplianceTableState, useEthicalNonComplianceTableDispatch } from './Context';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';

interface Props {
  columnNames: EthicalNonComplianceColumnHeader[];
}

export const EthicalNonComplianceTableCustomizationBar: React.FC<Props> = ({ columnNames }) => {
  const { state } = useEthicalNonComplianceTableState();
  const { dispatch } = useEthicalNonComplianceTableDispatch();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: EthicalNonComplianceHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: EthicalNonComplianceHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <>
            <TableCustomTools
              tableColumns={columnNames}
              showFilter={state.customFilterOn}
              onFilterTabToggle={toggleDisplayCustomFilter}
              sortOrder={state.order}
              sortBy={state.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnsDisplayStatus={state.columnsDisplayStatus}
              toggleDisplayColumn={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
