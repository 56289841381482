import React, { useState } from 'react';
import { Typography, Grid, Divider, CircularProgress } from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import { FindPersonResults } from './FindPersonResults';
import { ApplicationState } from '../../../store';
import {
  searchNaturalPersonData,
  resetSearchNaturalPersonData,
  searchNaturalPersonForeignData,
  resetPersonRepresentative
} from '../../../store/persons/personsActions';
import { AddNewRecordButton } from '../../TableButtons/AddNewRecordButton';
import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import { NaturalPersonSearchQuery } from '../../../store/persons/personsTypes';

import { FindPersonForeignResults } from './FindPersonForeignResults';
import { FindPersonAddNaturalForeignForm } from './FindPersonAddNaturalForeignForm';
import { FindPersonForm } from './FindPersonForm';
import { resetLegalRepresentative } from '../../../store/legalPersonData/legalDataActions';

const initialFindRepresentativeState: FindRepresentativeState = {
  searchClicked: false,
  showingAddNaturalForeign: false,
  lastSearchedCountry: undefined
};

interface FindRepresentativeState {
  searchClicked: boolean;
  showingAddNaturalForeign: boolean;
  lastSearchedCountry?: JarCountry;
}

export const FindPersonPage: React.FC = () => {
  const [dialogState, setDialogState] = useState<FindRepresentativeState>(
    initialFindRepresentativeState
  );

  const { jarCountries } = useSelector((state: ApplicationState) => state.virsis);

  const {
    loadingNaturalPersonSearchResults,
    naturalPersonSearchResults,
    naturalPersonSearchResultsError,
    loadingNaturalPersonForeignSearchResults,
    naturalPersonForeignSearchResults,
    naturalPersonForeignSearchResultsError
  } = useSelector((state: ApplicationState) => state.personData);

  const reduxDispatch = useDispatch();

  function searchForNaturalPersonFormik(
    personQuery: NaturalPersonSearchQuery,
    searchedCountry?: JarCountry
  ) {
    reduxDispatch(resetSearchNaturalPersonData());
    setDialogState({
      ...dialogState,
      searchClicked: true,
      showingAddNaturalForeign: false,
      lastSearchedCountry: searchedCountry
    });
    reduxDispatch(resetPersonRepresentative());
    reduxDispatch(resetLegalRepresentative());
    if (personQuery.countryCode === 'LTU') {
      reduxDispatch(searchNaturalPersonData(personQuery));
    } else {
      reduxDispatch(searchNaturalPersonForeignData(personQuery));
    }
  }

  function startAddingNaturalForeignPerson() {
    setDialogState({ ...dialogState, showingAddNaturalForeign: true });
  }

  function stopAddingNaturalForeignPerson() {
    setDialogState({ ...dialogState, showingAddNaturalForeign: false });
  }

  const filteredJarCountries =
    jarCountries?.filter((country) => country.countryCode !== 'NE') || [];

  return (
    <>
      <Divider />
      <FindPersonForm
        jarCountries={filteredJarCountries}
        searchPerson={searchForNaturalPersonFormik}
        isSearching={loadingNaturalPersonSearchResults}
      />
      <Divider />
      <Grid
        container
        item
        direction="column"
        justify="flex-end"
        alignItems="flex-start"
        style={{ paddingBottom: '50px' }}
      >
        {dialogState.showingAddNaturalForeign && (
          <FindPersonAddNaturalForeignForm
            searchPerson={searchForNaturalPersonFormik}
            close={stopAddingNaturalForeignPerson}
          />
        )}
        {loadingNaturalPersonSearchResults && <CircularProgress />}
        {loadingNaturalPersonForeignSearchResults && <CircularProgress />}
        {!dialogState.showingAddNaturalForeign && naturalPersonSearchResultsError && (
          <Typography>{naturalPersonSearchResultsError.message}</Typography>
        )}
        {!dialogState.showingAddNaturalForeign && naturalPersonForeignSearchResultsError && (
          <Typography>{naturalPersonForeignSearchResultsError.message}</Typography>
        )}
        {!dialogState.showingAddNaturalForeign &&
          !loadingNaturalPersonSearchResults &&
          dialogState.searchClicked &&
          naturalPersonSearchResults &&
          naturalPersonSearchResults.length === 0 && (
            <>
              <Typography variant="h5">{`Pagal Jūsų paiešką nebuvo rasta įrašų ${
                dialogState.lastSearchedCountry?.countryNameLTGenitiveCase || 'užsienio'
              } duomenyse.`}</Typography>
              <Typography>
                Pagal nurodytus Lietuvos fizinio asmens paieškos kriterijus, fizinis asmuo nerastas.
                Pasitikrinkite paieškos kriterijus ir pakartokite paiešką
              </Typography>
            </>
          )}
        {!dialogState.showingAddNaturalForeign &&
          dialogState.searchClicked &&
          !loadingNaturalPersonForeignSearchResults &&
          naturalPersonForeignSearchResults &&
          naturalPersonForeignSearchResults.length === 0 && (
            <>
              <Typography variant="h5">{`Pagal Jūsų paiešką nebuvo rasta įrašų ${
                dialogState.lastSearchedCountry?.countryNameLTGenitiveCase || 'užsienio'
              } duomenyse.`}</Typography>
              <Typography>
                Pagal nurodytus užsienio fizininio asmens paieškos kriterijus, fizinis asmuo
                nerastas.
                <br /> Pasitikrinkite nurodytus duomenis ir pakartokite paiešką.
              </Typography>
              <Typography>
                Jeigu duomenys nurodyti teisingai, tačiau užsienio fizinis asmuo nerastas,
                inicijuokite naujo užsienio fizinio asmens pridėjimą.
                <br /> Naujai pridedamas užsienio fizinis asmuo bus automatiškai pridėtas asmenų
                posistemėje.
              </Typography>
              <AddNewRecordButton
                onClicked={startAddingNaturalForeignPerson}
                text="Pridėti užsienio asmenį"
              />
            </>
          )}
        {dialogState.searchClicked &&
          !loadingNaturalPersonSearchResults &&
          naturalPersonSearchResults &&
          naturalPersonSearchResults.length > 0 && (
            <FindPersonResults personSearchResults={naturalPersonSearchResults} />
          )}
        {dialogState.searchClicked &&
          !loadingNaturalPersonForeignSearchResults &&
          naturalPersonForeignSearchResults &&
          naturalPersonForeignSearchResults.length > 0 && (
            <FindPersonForeignResults
              personForeignSearchResults={naturalPersonForeignSearchResults}
            />
          )}
      </Grid>
    </>
  );
};
