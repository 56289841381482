import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../utils/tableDataFunctions';
import { AdColumnHeaderField } from './adPrintingTableTypes';
import { OutletCategoryHeaderField } from './categoryTableTypes';
import { EditionColumnHeaderField } from './editionTableTypes';
import { OutletDataTableField } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import {
  initializeTablesByOutletInfoData,
  toggleOutdatedStatusFilterByTable,
  toggleRelevantStatusFilterByTable,
  toggleUnsignedStatusFilterByTable,
  toggleOutletTablesCustomFilter,
  changeOutletTablesPage,
  changeOutletTablesRowsPerPage,
  openOutletTablesNewRecordRow,
  closeOutletTablesNewRecordRow
} from './tablesStateFunctions';
import { OutletInfoTablesAction, OutletInfoTablesState } from './tablesTypesAndActions';

export const outletInfoTablesReducer = (
  state: OutletInfoTablesState,
  action: OutletInfoTablesAction
): OutletInfoTablesState => {
  switch (action.type) {
    case 'OUTLET_INFO_INITIALIZED':
      return initializeTablesByOutletInfoData(state, action.data, action.currentUser);
    case 'SHOW_STATUS_UNSIGNED_TOGGLED':
      return toggleUnsignedStatusFilterByTable(state, action.table);
    case 'SHOW_STATUS_RELEVANT_TOGGLED':
      return toggleRelevantStatusFilterByTable(state, action.table);
    case 'SHOW_STATUS_OUTDATED_TOGGLED':
      return toggleOutdatedStatusFilterByTable(state, action.table);
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return toggleOutletTablesCustomFilter(state, action.table);
    case 'PAGE_SET':
      return changeOutletTablesPage(state, action.table, action.page);
    case 'ROWS_PER_PAGE_SET':
      return changeOutletTablesRowsPerPage(state, action.table, action.rowsPerPage);
    case 'NEW_RECORD_ROW_OPENED':
      return openOutletTablesNewRecordRow(state, action.table);
    case 'NEW_RECORD_ROW_CLOSED':
      return closeOutletTablesNewRecordRow(state, action.table);
    case 'MAIN_OUTLET_CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        main: {
          ...state.main,
          customFilter: updateCustomFilterGeneric<OutletDataTableField>(
            state.main.customFilter,
            action.filterBy,
            action.value
          ),
          page: 0
        }
      };
    case 'MAIN_OUTLET_SORTING_CHANGED':
      return {
        ...state,
        main: {
          ...state.main,
          sortBy: action.sortBy === state.main.sortBy ? state.main.sortBy : action.sortBy,
          order: getToggledSortOrderGeneric(action.sortBy, state.main.sortBy, state.main.order)
        }
      };
    case 'MAIN_OUTLET_COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        main: {
          ...state.main,
          columnsDisplayStatus: {
            ...state.main.columnsDisplayStatus,
            [action.column]: !state.main.columnsDisplayStatus[action.column]
          }
        }
      };

    case 'AD_PRINTING_CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        adprinting: {
          ...state.adprinting,
          customFilter: updateCustomFilterGeneric<AdColumnHeaderField>(
            state.adprinting.customFilter,
            action.filterBy,
            action.value
          ),
          page: 0
        }
      };
    case 'AD_PRINTING_SORTING_CHANGED':
      return {
        ...state,
        adprinting: {
          ...state.adprinting,
          sortBy:
            action.sortBy === state.adprinting.sortBy ? state.adprinting.sortBy : action.sortBy,
          order: getToggledSortOrderGeneric(
            action.sortBy,
            state.adprinting.sortBy,
            state.adprinting.order
          )
        }
      };
    case 'AD_PRINTING_COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        adprinting: {
          ...state.adprinting,
          columnsDisplayStatus: {
            ...state.adprinting.columnsDisplayStatus,
            [action.column]: !state.adprinting.columnsDisplayStatus[action.column]
          }
        }
      };

    case 'CATEGORY_CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        categories: {
          ...state.categories,
          customFilter: updateCustomFilterGeneric<OutletCategoryHeaderField>(
            state.categories.customFilter,
            action.filterBy,
            action.value
          ),
          page: 0
        }
      };
    case 'CATEGORY_SORTING_CHANGED':
      return {
        ...state,
        categories: {
          ...state.categories,
          sortBy:
            action.sortBy === state.categories.sortBy ? state.categories.sortBy : action.sortBy,
          order: getToggledSortOrderGeneric(
            action.sortBy,
            state.categories.sortBy,
            state.categories.order
          )
        }
      };
    case 'CATEGORY_COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        categories: {
          ...state.categories,
          columnsDisplayStatus: {
            ...state.categories.columnsDisplayStatus,
            [action.column]: !state.categories.columnsDisplayStatus[action.column]
          }
        }
      };

    case 'EDITION_CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        editions: {
          ...state.editions,
          customFilter: updateCustomFilterGeneric<EditionColumnHeaderField>(
            state.editions.customFilter,
            action.filterBy,
            action.value
          ),
          page: 0
        }
      };
    case 'EDITION_SORTING_CHANGED':
      return {
        ...state,
        editions: {
          ...state.editions,
          sortBy: action.sortBy === state.editions.sortBy ? state.editions.sortBy : action.sortBy,
          order: getToggledSortOrderGeneric(
            action.sortBy,
            state.editions.sortBy,
            state.editions.order
          )
        }
      };
    case 'EDITION_COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        editions: {
          ...state.editions,

          columnsDisplayStatus: {
            ...state.editions.columnsDisplayStatus,
            [action.column]: !state.editions.columnsDisplayStatus[action.column]
          }
        }
      };

    case 'REPRESENTATIVES_SET_SORT_BY_COLUMN':
      return {
        ...state,
        representatives: {
          ...state.representatives,
          sortBy:
            action.sortBy === state.representatives.sortBy
              ? state.representatives.sortBy
              : action.sortBy,
          order: getToggledSortOrderGeneric(
            action.sortBy,
            state.representatives.sortBy,
            state.representatives.order
          )
        }
      };

    case 'REPRESENTATIVES_SET_CUSTOM_FILTER':
      return {
        ...state,
        representatives: {
          ...state.representatives,
          customFilter: updateCustomFilterGeneric(
            state.representatives.customFilter,
            action.filterBy,
            action.value
          ),
          page: 0
        }
      };
    case 'REPRESENTATIVES_TOGGLE_DISPLAY_COLUMN':
      return {
        ...state,
        representatives: {
          ...state.representatives,

          columnsDisplayStatus: {
            ...state.representatives.columnsDisplayStatus,
            [action.column]: !state.representatives.columnsDisplayStatus[action.column]
          }
        }
      };

    case 'REPRESENTATIVES_NEW_REPRESENTATIVE_DIALOG_OPENED':
      return {
        ...state,
        representatives: {
          ...state.representatives,
          showNewRepDialog: true
        }
      };
    case 'REPRESENTATIVES_NEW_REPRESENTATIVE_DIALOG_CLOSED':
      return {
        ...state,
        representatives: {
          ...state.representatives,

          showNewRepDialog: false
        }
      };

    default:
      return state;
  }
};
