import React from 'react';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';

interface Props {
  disabled?: boolean;
  onSuccess: () => void;
}
export const SuccessButton: React.FC<Props> = ({ disabled, onSuccess }) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        fill: 'none',
        stroke: disabled ? theme.palette.info.main : '#03b079',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 3
      }
    })
  )();

  return (
    <IconButton onClick={onSuccess} disabled={disabled}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.012"
        height="9.117"
        viewBox="0 0 13.012 9.117"
      >
        <path
          id="Path_1872"
          data-name="Path 1872"
          className={classes.icon}
          d="M-8873.219-20085.6l3.168,3.4,5.6-5.5"
          transform="translate(8875.339 20089.816)"
        />
      </svg>
    </IconButton>
  );
};
