import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AnnulmentType } from '../../../../store/classifiers/classifiersTypes';
import {
  EthicalNonComplianceData,
  EthicalNonComplianceType
} from '../../../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import {
  MINIMAL_RECORD_DATE_DEFAULT,
  MAXIMAL_RECORD_DATE_TODAY
} from '../../../../utils/InputValueFunctions';
import {
  DatePickerState,
  DropdownMultipleStateGeneric,
  DropdownStateGeneric,
  InputStateGeneric,
  VirsInputState
} from '../../../../utils/tableTypes';

export interface EthicalNonComplianceRecordState {
  virs: VirsInputState;
  decisionDate: DatePickerState;
  documentNumber: InputStateGeneric<string>;
  outlets: DropdownMultipleStateGeneric<OutletShortData>;
  decisionStatus: DecisonStatusState;
  validFrom: DatePickerState;
  validTo: DatePickerState;
  showFindVirsDialog: boolean;
  annulRecordConfirmationOn: boolean;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
  loading: boolean;
}

type DecisonStatusState = {
  id: number | undefined;
  error: boolean;
};

export const initialEthicalNonComplianceRecordState: EthicalNonComplianceRecordState = {
  virs: {
    value: null,
    error: false,
    validated: false,
    helperText: 'Privaloma pasirinkti VIRS',
    placeholder: 'VIRS pavadinimas'
  },
  decisionDate: {
    value: null,
    error: false,
    validated: false,
    minDates: [MINIMAL_RECORD_DATE_DEFAULT],
    maxDates: [
      {
        id: 'validFrom',
        date: null,
        text: 'Data negali būti vėlesnė už įrašo galiojimo pradžios datą'
      },
      MAXIMAL_RECORD_DATE_TODAY
    ],
    helperText: ''
  },
  documentNumber: {
    value: null,
    id: 'documentNumber',
    error: false,
    helperText: 'Dokumento numeris',
    placeholder: '',
    validated: false
  },

  outlets: {
    values: [],
    id: '',
    error: false,
    helperText: '',
    placeholder: 'Pasirinkti',
    validated: false,
    options: []
  },
  decisionStatus: { id: undefined, error: false },
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [
      MINIMAL_RECORD_DATE_DEFAULT,
      {
        id: 'decisionDate',
        date: null,
        text: 'Data negali būti ankstesnė už sprendimo datą'
      },
      {
        id: 'relevantDate',
        date: null,
        text: 'Įrašai negali persidengti'
      }
    ],
    maxDates: [
      {
        id: 'validTo',
        date: null,
        text: 'Data negali būti lygi arba vėlesnė už įrašo galiojimo pabaigos datą'
      }
    ],
    helperText: ''
  },
  validTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [
      MINIMAL_RECORD_DATE_DEFAULT,
      {
        id: 'validFrom',
        date: null,
        text: 'Data negali būti lygi arba ankstesnė už įrašo galiojimo pradžios datą'
      },
      {
        id: 'relevantDate',
        date: null,
        text: 'Įrašai negali persidengti'
      }
    ],
    maxDates: [],
    helperText: ''
  },
  showFindVirsDialog: false,
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  },
  loading: false
};

export interface EthicalNonComplianceNewRecordState extends EthicalNonComplianceRecordState {
  createRecordConfirmationOn: boolean;
}

export const initialEthicalNonComplianceNewRecordState: EthicalNonComplianceNewRecordState = {
  ...initialEthicalNonComplianceRecordState,
  createRecordConfirmationOn: false,
  showFindVirsDialog: true
};

export interface EthicalNonComplianceEditRecordState extends EthicalNonComplianceRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
  recordId?: number;
}

export const initialEthicalNonComplianceEditRecordState: EthicalNonComplianceEditRecordState = {
  ...initialEthicalNonComplianceRecordState,
  editingOn: false,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false
};

type EthicalNonComplianceRecordAction =
  | { type: 'FIND_VIRS_DIALOG_OPENED' }
  | { type: 'FIND_VIRS_DIALOG_CLOSED' }
  | {
      type: 'VIRS_SELECTED';
      virs: VirsSearchData;
      fictitiousOutlet: OutletShortData[];
    }
  | {
      type: 'DECISION_DATE_CHANGED';
      decisionDate: MaterialUiPickersDate | null;
    }
  | { type: 'DOCUMENT_NUMBER_CHANGED'; documentNumber: string }
  | { type: 'OUTLET_LIST_CHANGED'; outlets: OutletShortData[] }
  | { type: 'VALID_FROM_DATE_CHANGED'; validFrom: MaterialUiPickersDate | null }
  | { type: 'VALID_TO_DATE_CHANGED'; validTo: MaterialUiPickersDate | null }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type EthicalNonComplianceNewRecordAction =
  | EthicalNonComplianceRecordAction
  | {
      type: 'DECISION_STATUS_CHANGED';
      decisionStatus: EthicalNonComplianceType | null;
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };

export type EthicalNonComplianceEditRecordAction =
  | EthicalNonComplianceRecordAction
  | {
      type: 'DECISION_STATUS_CHANGED';
      decisionStatus: EthicalNonComplianceType;
    }
  | {
      type: 'EDITING_INITIALIZED';
      record: EthicalNonComplianceData;
      ethicalNonComplianceTypes: EthicalNonComplianceType[];
      fictitiousOutlet: OutletShortData[];
    }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'ANNUL_RECORD_CLICKED'; annulmentTypes: AnnulmentType[] }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' };
