import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BottomPageActionButtons from '../../components/PageButtons/BottomPageActionButtons';
import ShareholdersTableContainer from '../../containers/ShareholdersTable/ShareholdersTableContainer';
import { ShareholdersSelectedDateTableWithContext } from '../../containers/ShareholdersTable/SelectedDateTable/TableWithContext';
import { ApplicationState } from '../../store';

import { getSubsystem } from '../../utils/roleHelper';
import {
  fetchLegalFormTypesRequest,
  fetchShareTypesRequest
} from '../../store/classifiers/classifiersActions';
import { Redirect } from 'react-router-dom';

const ShareholdersSelectedDatePage: React.FC = () => {
  const history = useHistory();
  const { legalFormTypes, shareTypes } = useSelector(
    (state: ApplicationState) => state.classifiers
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (!legalFormTypes) {
      reduxDispatch(fetchLegalFormTypesRequest());
    }
    if (!shareTypes) {
      reduxDispatch(fetchShareTypesRequest());
    }
  }, [reduxDispatch, legalFormTypes, shareTypes]);

  const handleGoBackClick = () => {
    const path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones'
      : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones`;
    history.push(path);
  };

  const { currentUser, selectedVirsId } = useSelector((state: ApplicationState) => state.virsis);
  const selectedDate = useSelector(
    (state: ApplicationState) => state.shareholdersData.selectedDate
  );
  const isVirsis = getSubsystem(currentUser) === 'VIRSIS';

  const virsPath = isVirsis
    ? '/duomenu-perziura-ir-teikimas'
    : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas`;

  const handleGoToPageClick = () => {
    history.push(`${virsPath}/el-dokumentai`);
  };

  if (!selectedVirsId && !isVirsis) {
    return null;
  }

  if (!selectedDate) {
    return (
      <>
        <Redirect to={`${virsPath}/dalyviai`} />
      </>
    );
  }

  return (
    <>
      <ShareholdersTableContainer>
        <ShareholdersSelectedDateTableWithContext />
      </ShareholdersTableContainer>
      <BottomPageActionButtons
        handleGoToPageClick={handleGoToPageClick}
        handleGoBackClick={handleGoBackClick}
        pageToGo="El. dokumentai"
      />
    </>
  );
};

export default ShareholdersSelectedDatePage;
