import { DatePickerState } from '../../../../../utils/tableTypes';
import { AdPrintingRowState } from './rowInitialStateAndTypes';
import {
  validateMandatoryDate,
  validateOptionalDateImproved
} from '../../../../../utils/InputValueFunctions';

export function validateAdPrintingEditRecordState(state: AdPrintingRowState): AdPrintingRowState {
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateOptionalDateImproved(state.validTo);

  return {
    ...state,
    validFrom: validFromValidated,
    validTo: validToValidated,
    editStateValidated: validFromValidated.validated && validToValidated.validated
  };
}

export function validateAdPrintingNewRecordState(state: AdPrintingRowState): AdPrintingRowState {
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateOptionalDateImproved(state.validTo);

  return {
    ...state,
    validFrom: validFromValidated,
    validTo: validToValidated,
    createStateValidated: validFromValidated.validated && validToValidated.validated
  };
}
