import { HeaderType, SortOrder } from '../../../utils/tableTypes';
import {
  ColumnStyle as ExportedColumnStyle,
  CellStyle as ExportedCellStyle
} from '../../../utils/exporters/types';

export enum Classifiers {
  LEGAL_FORMS,
  ENTERPRISE_TYPES,
  OUTLET_GROUPS,
  OUTLET_TYPES,
  LICENCED_ENTERPRISES,
  SANCTION_TYPES,
  OUTLET_CATEGORIES,
  ANNULMENT_TYPES,
  EDITION_CHECK_CONCLUSIONS,
  TRANSACTION_TYPES,
  PAYMENT_AMOUNTS,
  ETHICAL_NON_COMPLIANCE_TYPES,
  MESSAGE_TYPES,
  EDITION_PERIODS,
  FILES,
  SYSTEM_PARAMETER,
  MISCONDUCT_TYPES
}

export interface ClassifierLabel {
  value: Classifiers;
  label: string;
}

export type Column<TData> = {
  field: keyof TData;
  header: string;
  valueMapper: (data: TData) => string[];
  sortFn: (data: TData[], direction?: SortOrder) => TData[];
  filterFn: (data: TData[], value?: string) => TData[];
  style?: ColumnStyle;
  exportedStyle?: ExportedColumnStyles;
  type?: HeaderType;
};

export type ColumnGroup<TData> = {
  header?: string;
  columns: Column<TData>[];
};

export type TableDefinition<TData> = {
  columnGroups: ColumnGroup<TData>[];
  tableActions: {
    edit: (data: TData) => void;
    remove?: (data: TData) => void;
    download?: (data: TData) => void;
    create?: () => void;
  };
  actionLabels?: {
    create?: string;
  };
  style?: { actionsColumn?: ColumnStyle };
};

export type Nullable<TData> = {
  [T in keyof TData]: TData[T] | null;
};

export interface ColumnStyle {
  cell?: React.CSSProperties;
}

export interface ExportedColumnStyles {
  cell?: ExportedCellStyle;
  column?: ExportedColumnStyle;
}
