import { TableCell } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { WordStatus } from '../../../utils/tableTypes';
import { EthicalNonComplianceColumnsDisplayStatus } from '../tableState/tableInitialStateAndTypes';

interface Props {
  columnsToDisplay: EthicalNonComplianceColumnsDisplayStatus;
  documentStatus?: WordStatus;
  virsName?: ReactNode;
  virsInput?: ReactNode;
  decisionDate?: ReactNode;
  documentNumber?: ReactNode;
  documentNumberInput?: ReactNode;
  outletList?: ReactNode;
  outletListInput?: ReactNode;
  decisionStatus?: ReactNode;
  validFrom?: ReactNode;
  validTo?: ReactNode;
  actions?: ReactNode;
  elDocument?: ReactNode;
}

export const EthicalNonComplianceRowBasic: React.FC<Props> = ({
  columnsToDisplay,
  documentStatus,
  virsName,
  virsInput,
  outletList,
  outletListInput,
  decisionDate,
  documentNumber,
  documentNumberInput,
  decisionStatus,
  validFrom,
  validTo,
  elDocument,
  actions
}) => {
  return (
    <RowStyleOnDifferentStatus status={documentStatus}>
      {columnsToDisplay.virsName && !virsInput && (
        <TooltipOnRowRibbon status={documentStatus}>
          <TableCell>{virsName}</TableCell>
        </TooltipOnRowRibbon>
      )}
      {columnsToDisplay.virsName && virsInput && virsInput}
      {columnsToDisplay.decisionDate && decisionDate}
      {columnsToDisplay.documentNumber && !documentNumberInput && (
        <TableCell>{documentNumber}</TableCell>
      )}
      {columnsToDisplay.documentNumber && !documentNumber && documentNumberInput}
      {columnsToDisplay.outletList && !outletListInput && <TableCell>{outletList}</TableCell>}
      {columnsToDisplay.outletList && outletListInput && outletListInput}

      {columnsToDisplay.decisionStatus && <TableCell>{decisionStatus}</TableCell>}
      {columnsToDisplay.validFrom && validFrom}
      {columnsToDisplay.validTo && validTo}
      <TableCell>{actions}</TableCell>
      <TableCell>{elDocument}</TableCell>
    </RowStyleOnDifferentStatus>
  );
};
