import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import { VirsSearchQuery } from '../../../store/virsis/dataTypes';
import { ApplicationState } from '../../../store';
import { resetSearchVirsState, searchVirsData } from '../../../store/virsis/actions';
import { VirsDataSubmissionSearchResults } from './VirsDataSubmissionSearchResults';
import { VirsDataSubmissionPageForm } from './VirsDataSearchForm';

const VirsDataSearch: React.FC = () => {
  const { loadingVirsSearchResults, virsSearchResults, virsSearchResultsError, currentUser } =
    useSelector((state: ApplicationState) => state.virsis);

  const [querySize, setQuerySize] = useState<number>(0);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(resetSearchVirsState());
  }, [reduxDispatch]);

  function searchForVirsis(virsQuery: VirsSearchQuery) {
    reduxDispatch(resetSearchVirsState());
    reduxDispatch(searchVirsData(virsQuery));
  }

  return (
    <Container disableGutters maxWidth="lg">
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Typography style={{ marginBottom: '10px' }} gutterBottom variant="h3">
          VIRS paieška
        </Typography>
      </Grid>
      <Divider />
      <VirsDataSubmissionPageForm
        setQuerySize={setQuerySize}
        searchVirs={searchForVirsis}
        isSearching={loadingVirsSearchResults}
      />
      <Grid container item direction="column" justify="flex-end" alignItems="flex-start">
        {virsSearchResultsError && <Typography>{`Klaida. ${virsSearchResultsError}`}</Typography>}
        {virsSearchResults && virsSearchResults.length === 0 && (
          <>
            <Typography variant="h5">Pagal Jūsų paiešką įrašų nebuvo rasta.</Typography>
            <Typography>
              {querySize === 1
                ? 'Pagal nurodytą paieškos kriterijų duomenų nerasta. Galite patikslinti nurodytą kriterijų arba ieškoti pagal kitą kriterijų.'
                : 'Pagal nurodytus paieškos kriterijus duomenų nerasta. Galite patikslinti nurodytus kriterijus arba ieškoti nurodant mažiau kriterijų'}
            </Typography>
          </>
        )}
        {virsSearchResults && virsSearchResults.length > 0 && (
          <VirsDataSubmissionSearchResults
            authorities={currentUser?.authorities}
            virsSearchResults={virsSearchResults}
          />
        )}
      </Grid>
    </Container>
  );
};

export default VirsDataSearch;
