import { takeEvery, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST,
  FUNDS_RECEIVED_DATA_VIRS_PDF_REQUEST,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST,
  CREATE_FUNDS_RECEIVED_REQUEST,
  UPDATE_FUNDS_RECEIVED_REQUEST,
  REMOVE_FUNDS_RECEIVED_REQUEST,
  ANNUL_FUNDS_RECEIVED_DATA_REQUEST,
  FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST
} from './fundsReceivedActionTypes';
import {
  annulFundsReceived,
  annulFundsReceivedError,
  annulFundsReceivedSuccess,
  createFundsReceived,
  createFundsReceivedError,
  createFundsReceivedSuccess,
  fetchFundsReceivedDataErrorLegal,
  fetchFundsReceivedDataLegal,
  fetchFundsReceivedDataSuccessLegal,
  fetchFundsReceivedDataVirs,
  fetchFundsReceivedDataVirsError,
  fetchFundsReceivedDataVirsSuccess,
  fetchFundsReceivedRecordByDocId,
  getFundsReceivedDataVirsDocRequest,
  removeFundsReceived,
  removeFundsReceivedError,
  removeFundsReceivedSuccess,
  resetAnnulFundsReceivedState,
  resetFundsReceivedRemovingState,
  updateFundsReceived,
  updateFundsReceivedError,
  updateFundsReceivedSuccess
} from './fundsReceivedActions';
import {
  annulFundsReceivedData,
  deleteRemoveFundsReceived,
  getFundsReceivedDataLegal,
  getFundsReceivedDataVirs,
  getFundsReceivedDocument,
  getFundsReceivedRecordByDocId,
  postCreateFundsReceived,
  putUpdateFundsReceived
} from './fundsReceivedApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  getFundsReceivedPageRequest,
  selectFundsReceivedTableDataState
} from '../fundsReceivedTable/fundsReceivedUtilities';
import { FundsReceivedDataTableState } from '../fundsReceivedTable/fundsReceivedTableReducer';
import { updateTempDataState } from '../fundsReceivedTable/fundsReceivedTableActions';

function* handleFundsReceivedDataLegalFetchSaga() {
  try {
    const { tableDataState }: FundsReceivedDataTableState = yield select(
      selectFundsReceivedTableDataState
    );
    yield put(updateTempDataState(tableDataState));
    const { data } = yield call(
      getFundsReceivedDataLegal,
      getFundsReceivedPageRequest(tableDataState)
    );
    yield put(fetchFundsReceivedDataSuccessLegal(data));
  } catch (err) {
    yield put(fetchFundsReceivedDataErrorLegal(err.response));
    yield put(sendMessage('error', err.response));
  }
}

function* handleFundsReceivedDataVirsFetchSaga(
  action: ActionType<typeof fetchFundsReceivedDataVirs>
) {
  try {
    const virsId = action.payload;
    const { data } = yield call(getFundsReceivedDataVirs, virsId);
    yield put(fetchFundsReceivedDataVirsSuccess(data));
  } catch (err) {
    yield put(fetchFundsReceivedDataVirsError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleFundsReceivedVirsDocumentRequestSaga(
  action: ActionType<typeof getFundsReceivedDataVirsDocRequest>
) {
  try {
    const docId = action.payload;
    const { data } = yield call(getFundsReceivedDocument, docId);
    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]));
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
    // TODO globalus pranesimas kai gaunama klaida
  }
}

function* handleCreateFundsReceivedSaga(action: ActionType<typeof createFundsReceived>) {
  try {
    const { record } = action.payload;
    yield call(postCreateFundsReceived, record);
    yield put(createFundsReceivedSuccess());
    yield put(sendMessage('success', 'Gautų lėšų įrašas išsaugotas'));
  } catch (err) {
    yield put(createFundsReceivedError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateFundsReceivedSaga(action: ActionType<typeof updateFundsReceived>) {
  try {
    const { recordUpdated, fundsReceivedId } = action.payload;
    yield call(putUpdateFundsReceived, recordUpdated, fundsReceivedId);
    yield put(updateFundsReceivedSuccess());
    yield put(sendMessage('success', 'Gautų lėšų įrašas atnaujintas'));
  } catch (err) {
    yield put(updateFundsReceivedError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveFundsReceivedSaga(action: ActionType<typeof removeFundsReceived>) {
  try {
    const { fundsReceivedId } = action.payload;
    yield call(deleteRemoveFundsReceived, fundsReceivedId);
    yield put(removeFundsReceivedSuccess());
    yield put(resetFundsReceivedRemovingState());
    yield put(fetchFundsReceivedDataLegal());
    yield put(sendMessage('success', 'Gautų lėšų įrašas pašalintas'));
  } catch (err) {
    yield put(removeFundsReceivedError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleAnnulFundsReceivedSaga(action: ActionType<typeof annulFundsReceived>) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulFundsReceivedData, documentStatusId, annulRecord);
    yield put(annulFundsReceivedSuccess());
    yield put(resetAnnulFundsReceivedState());
    yield put(fetchFundsReceivedDataLegal());
    yield put(sendMessage('success', 'Gautų lėšų įrašas anuliuotas'));
  } catch (err) {
    yield put(annulFundsReceivedError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleFetchFundsReceivedRecordByDocIdSaga(
  action: ActionType<typeof fetchFundsReceivedRecordByDocId>
) {
  try {
    const { data } = yield call(getFundsReceivedRecordByDocId, action.payload);
    yield put(fetchFundsReceivedDataSuccessLegal(data));
  } catch (err) {
    yield put(fetchFundsReceivedDataErrorLegal(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* fundsReceivedSaga() {
  yield takeLatest(FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST, handleFundsReceivedDataLegalFetchSaga);
  yield takeEvery(FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST, handleFundsReceivedDataVirsFetchSaga);
  yield takeEvery(FUNDS_RECEIVED_DATA_VIRS_PDF_REQUEST, handleFundsReceivedVirsDocumentRequestSaga);
  yield takeEvery(CREATE_FUNDS_RECEIVED_REQUEST, handleCreateFundsReceivedSaga);
  yield takeEvery(UPDATE_FUNDS_RECEIVED_REQUEST, handleUpdateFundsReceivedSaga);
  yield takeEvery(REMOVE_FUNDS_RECEIVED_REQUEST, handleRemoveFundsReceivedSaga);
  yield takeEvery(ANNUL_FUNDS_RECEIVED_DATA_REQUEST, handleAnnulFundsReceivedSaga);
  yield takeEvery(
    FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST,
    handleFetchFundsReceivedRecordByDocIdSaga
  );
}

export default fundsReceivedSaga;
