import React from 'react';
import { FormikProps } from 'formik';
import { isNumber, isString } from 'lodash';
import { FormikFieldStyleProps } from '../FieldStyles';
import { useDialogFormStyles } from './DialogFormStyles';
import { FormikFormFileField } from '../FormikFormFileField';

interface Props<TData> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label?: string;
  field: keyof TData;
  disabled?: boolean;
  placeholder?: string;
}

export const GenericFileInput = <TData,>({
  field,
  label,
  formikProps,
  disabled,
  style,
  placeholder
}: Props<TData>) => {
  const classes = useDialogFormStyles();
  const value = formikProps.values[field];
  const error = formikProps.errors[field];

  const typedError = isString(error) ? error : undefined;
  const typedValue = isNumber(value) || isString(value) ? value : null;

  return (
    <FormikFormFileField
      placeholder={placeholder}
      styledClasses={classes}
      label={label}
      formikKey={`${field}`}
      values={typedValue}
      error={typedError}
      setFieldValue={formikProps.setFieldValue}
      style={style}
      disabled={disabled}
      formikProps={formikProps}
      field={field}
    />
  );
};

GenericFileInput.defaultProps = {
  disabled: false,
  clearable: true,
  placeholder: null,
  multiline: false,
  rowsCount: 1,
  maxLength: 255
};
