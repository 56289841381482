import { WordStatus } from '../../utils/tableTypes';
import { OutletShortData } from '../outlets/outletsTypes';

export interface VirsisUser {
  name: string;
  personId: number;
  amnCode: string;
  varId: number;
  amnName: string;
  varNameInContext: string;
  recentActivity: string;
  authorities: string[];
  institutionRole: boolean;
}

export enum Roles {
  ROLE_VIRS,
  ROLE_VIRS_VIEW,
  ROLE_VIRS_EDIT,
  ROLE_KM_TIRAZO_TIKRINIMAS_VIEW,
  ROLE_KM_TIRAZO_TIKRINIMAS_EDIT,
  ROLE_KM_METINE_IMOKA_VIEW,
  ROLE_KM_METINE_IMOKA_EDIT,
  ROLE_VIEA_VIEW,
  ROLE_VIEA_EDIT,
  ROLE_ZEIT_VIEW,
  ROLE_ZEIT_EDIT,
  ROLE_LRTK_VIEW,
  ROLE_LRTK_EDIT,
  ROLE_ADMIN_KLSF,
  ROLE_ADMIN_GRF,
  ROLE_ADMIN_AUD,
  ROLE_ADMIN_ALL,
  ROLE_ADMIN_ARCH,
  ROLE_OUTBOX_VIEW,
  ROLE_ISTAIG_EDIT,
  ROLE_LEGAL,
  ROLE_REPORT_A1,
  ROLE_REPORT_A2,
  ROLE_REPORT_A3,
  ROLE_REPORT_A4,
  ROLE_REPORT_A5,
  ROLE_REPORT_A6,
  ROLE_REPORT_A7,
  ROLE_REPORT_A8,
  ROLE_REPORT_A9,
  ROLE_REPORT_A10,
  ROLE_REPORT_A11,
  ROLE_REPORT_A12,
  ROLE_REPORT_A13,
  ROLE_REPORT_A15,
  ROLE_REPORT_A13ARCH
}

export interface AgreementDateResponse {
  date: string;
}

export interface EmailResponse {
  email: string;
}
export interface VirsDataResponse {
  virsData: VirsData;
}

export interface VirsData {
  address: string;
  ceoPersonFullName: string;
  companyCode: string;
  contactNumber: string;
  documentStatus: WordStatus;
  documentStatusId: number;
  editionCheckCount: number;
  enterpriseCount: number;
  ethicalNonComplianceStatus: string;
  fundsReceivedSum: string;
  legalDeregistrationDate: string;
  legalPersonCount: number;
  legalRegistrationDate: string;
  legalState: string;
  legalType: string;
  licenseCount: number;
  misconductCount: number;
  name: string;
  naturalPersonCount: number;
  outletCount: number;
  paymentAmount: string;
  personId: number;
  personLastName: string;
  personName: string;
  politicalFinancialSupport: string;
  receivedLegalPersonSupport: string;
  receivedNaturalPersonSupport: string;
  recentProvidedDataDate: string;
  systemRegistrationDate: string;
  systemRegistrationState: string;
  virsId: number;
}

export interface LegalData {
  companyCode: string;
}

export interface ContactEmailResponse {
  contId: number;
}

export interface ContactRequest {
  personId: number;
  email: string;
}

export enum AccountType {
  VIRS = 'VIRS',
  LEGAL = 'LEGAL'
}

export interface AgreementFile {
  content: any;
}

export interface VirsSubmitDataResponse {
  virsId: number;
}

export interface VirsSearchQuery {
  personCode?: string;
  personFullName?: string;
  enterpriseTypeId?: number;
  outletName?: string;
  onlySigned: boolean;
}

export interface VirsSearchData extends VirsSearchShortData {
  personId: number;
  enterpriseTypes: VirsSearchDataEnterpriseType[];
  hasActiveData: boolean;
  canConnectAsVIRS: boolean;
  hasUnclosedPayment: boolean;
}

export interface VirsSearchShortData {
  virId: number;
  personCode: string;
  personFullName: string;
  outlets: OutletShortData[];
  virsLegalRegistrationDate: string;
  virsLegalDeregistrationDate: string;
}

export interface VirsSearchDataEnterpriseType {
  enterpriseTypeId: number;
  enterpriseTypeName: string;
}

export enum ErrorType {
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  NONCRITICAL = 'NONCRITICAL'
}

export interface RegisterAccountTypeRecord {
  accountName: string | null;
  virsId: number | null;
}

export interface VirsDataEditorInfo {
  documentStatusId: number;
  documentEditDate: Date;
  editorName: string;
  representedJa: string;
}

export interface KeyStringValueBoolean {
  [key: string]: boolean;
}
