import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import {
  getBooleanMappedColumn,
  getDateFromMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { defaultValues, MessageType } from './types';
import { loadData, setFormOpen, State } from './store';

import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import { MessageTypeForm } from './MessageTypeForm';

export const MessageTypeTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const messageTypeTableDefinition: TableDefinition<Nullable<MessageType>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('messageTypeId', 'Kodas', (data) => data.messageTypeId),
            getStringMappedColumn(
              'messageTypeName',
              'Pranešimo tipas',
              (data) => data.messageTypeName,
              { cell: { width: '120px' } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 30 }
              }
            ),
            getStringMappedColumn(
              'subject',
              'Laiško antraštė',
              (data) => data.subject,
              { cell: { width: '120px' } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getStringMappedColumn(
              'body',
              'Laiško šablonas',
              (data) => data.body,
              {
                cell: { width: undefined }
              },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 60 }
              }
            ),
            getBooleanMappedColumn('enabled', 'Ar siunčiamas', (data) => data.enabled),
            getDateFromMappedColumn('validFrom', 'Galioja nuo', (data) => data.validFrom)
          ]
        }
      ],
      tableActions: {
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              messageTypeId: data.messageTypeId,
              messageTypeName: data.messageTypeName,
              enabled: data.enabled || defaultValues.enabled,
              subject: data.subject || defaultValues.subject,
              body: data.body || defaultValues.body,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null
            })
          );
        }
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <MessageTypeForm />

      <ClassifierTable tableDefinition={messageTypeTableDefinition} {...dataState} />
    </>
  );
};
