import {
  ProfessionalMisconductsTableDataState,
  ProfessionalMisconductsTablePageRequest
} from './professionalMisconductsTableTypes';
import { SortOrder } from '../../utils/tableTypes';
import { ProfessionalMisconductInstTableField } from '../../containers/ProfessionalMisconductTableInst/tableState/misconductTableInitialStateAndTypes';
import { ApplicationState } from '../index';
import { ProfessionalMisconductDataTableState } from './professionalMisconductsTableReducer';
import moment from 'moment';

const getFilterDate = (
  state: ProfessionalMisconductsTableDataState,
  value: ProfessionalMisconductInstTableField
): string | null => {
  return state.customFilter[value][0]?.trim()
    ? moment(new Date(state.customFilter[value][0].trim())).format('YYYY-MM-DD')
    : null;
};

const getFilterString = (
  state: ProfessionalMisconductsTableDataState,
  value: ProfessionalMisconductInstTableField
): string | null => state.customFilter[value][0]?.trim() || null;

const getFilterNumber = (
  state: ProfessionalMisconductsTableDataState,
  value: ProfessionalMisconductInstTableField
): number | null => Number(state.customFilter[value][0]) || null;

const getSorting = (
  state: ProfessionalMisconductsTableDataState,
  value: ProfessionalMisconductInstTableField
): SortOrder | null => (state.sortBy === value ? state.order : null);

export const getProfessionalMisconductInstPageRequest = (
  state: ProfessionalMisconductsTableDataState
): ProfessionalMisconductsTablePageRequest => {
  return {
    filterVirsId: getFilterNumber(state, 'virsName'),
    filterDecisionDate: getFilterDate(state, 'decisionDate'),
    filterDocumentNumber: getFilterString(state, 'documentNumber'),
    filterOutletId: getFilterNumber(state, 'outletName'),
    filterMisconductTypeId: getFilterNumber(state, 'professionalMisconduct'),
    filterSanctionTypeId: getFilterNumber(state, 'sanction'),
    filterValidFrom: getFilterDate(state, 'validFrom'),
    filterValidTo: getFilterDate(state, 'validTo'),
    pageNr: state.rowsPerPage * state.page + 1,
    pageSize: state.rowsPerPage,
    sortingVirsName: getSorting(state, 'virsName'),
    sortingDecisionDate: getSorting(state, 'decisionDate'),
    sortingDocumentNumber: getSorting(state, 'documentNumber'),
    sortingOutletName: getSorting(state, 'outletName'),
    sortingProfessionalMisconduct: getSorting(state, 'professionalMisconduct'),
    sortingSanction: getSorting(state, 'sanction'),
    sortingValidFrom: getSorting(state, 'validFrom'),
    sortingValidTo: getSorting(state, 'validTo'),
    docStatusNotSigned: state.showStatusNotSigned,
    docStatusRelevant: state.showStatusRelevant,
    docStatusOutdated: state.showStatusOutdated
  };
};

export const selectProfessionalMisconductTableDataState = (
  state: ApplicationState
): ProfessionalMisconductDataTableState => state.professionalMisconductTableData;
