import React, { ReactNode } from 'react';
import { Button } from '@material-ui/core';
import { ConfirmRemoveDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import { BasicDarkTooltip } from '../../components/Tooltips/BasicDarkTooltip';
import { ConditionalWrapper } from '../../components/ConditionalWrapper/ConditionalWrapper';

interface Props {
  tooltipMessage: string;
  isOpen: boolean;
  selectedMessages: number[];
  isProcessing: boolean;
  error: Error | undefined;
  onClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirmationDo: () => void;
  onConfirmationCancel: () => void;
  onErrorClose: () => void;
  icon: ReactNode;
  buttonStyle: string;
}

export const RemoveSelectedMessagesButton: React.FC<Props> = ({
  tooltipMessage,
  isOpen,
  selectedMessages,
  isProcessing,
  error,
  onClicked,
  onConfirmationDo,
  onConfirmationCancel,
  onErrorClose,
  icon,
  buttonStyle
}) => (
  <>
    <ConditionalWrapper
      condition={selectedMessages.length > 0}
      wrapper={(children) => <BasicDarkTooltip text={tooltipMessage}>{children}</BasicDarkTooltip>}
    >
      <Button
        variant="outlined"
        onClick={onClicked}
        disabled={selectedMessages.length === 0}
        className={buttonStyle}
      >
        {icon}
      </Button>
    </ConditionalWrapper>
    <ConfirmRemoveDialog
      open={isOpen}
      dialogTitle="Pranešimų pašalinimas"
      dialogText="Ar tikrai norite pašalinti pažymėtus pranešimus?"
      mainButtonText="Pašalinti pranešimus"
      onClose={onConfirmationCancel}
      onSubmit={onConfirmationDo}
      isProcessing={isProcessing}
      errorMessage={error?.message || ''}
      isError={!!error}
      onErrorClose={onErrorClose}
    />
  </>
);
