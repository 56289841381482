import React from 'react';
import { isArray } from 'lodash';
import { FormikProps } from 'formik';

import { Box, Checkbox, Typography } from '@material-ui/core';

import { FormikFieldStyleProps } from '../FieldStyles';
import { DropdownMultipleInput } from '../../TableInputs/DropdownMultipleInput';

import { useDialogFormStyles } from './DialogFormStyles';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Option<TOption> {
  value: TOption;
  label: string;
}

interface Props<TData, TOption> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label: string;
  field: keyof TData;
  options: Option<TOption>[];
}

export const GenericMultipleSelectField = <TData, TOption>({
  field,
  label,
  formikProps,
  options,
  style
}: Props<TData, TOption>) => {
  const classes = useDialogFormStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getOptionLabel = (value: Option<TOption>) => value.label;
  const renderOption = (option: Option<TOption>, { selected }: AutocompleteRenderOptionState) => (
    <>
      <Checkbox
        color="primary"
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.label}
    </>
  );

  const data = formikProps.values[field];
  if (!isArray(data)) {
    throw new Error(
      `Field ${field} in object that was passed to GenericMultipleSelectField was not of Array type`
    );
  }

  return (
    <Box margin={1} className={classes.inputContainer} style={style?.container}>
      {label && (
        <Typography variant="h4" className={classes.inputLabel}>
          {label}
        </Typography>
      )}
      <DropdownMultipleInput<Option<TOption>>
        selectValues={(value) => formikProps.setFieldValue(`${field}`, value)}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) => (value ? option.value === value.value : false)}
        style={style}
        state={{
          values: data,
          error: !!formikProps.errors[field],
          helperText: (!!formikProps.errors[field] && `${formikProps.errors[field]}`) || '',
          placeholder: '',
          validated: false,
          options: options.map((option) => option)
        }}
        renderOption={renderOption}
      />
    </Box>
  );
};
