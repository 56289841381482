import React from 'react';
import { Box, BoxProps, Button, ButtonProps } from '@material-ui/core';
import {
  TooltipOnTableActionButton,
  TooltipOnTableActionButtonProps
} from './TooltipOnTableActionButton';

type ToolTipPropType = Omit<TooltipOnTableActionButtonProps, 'children' | 'text'> & {
  text?: TooltipOnTableActionButtonProps['text'];
};

export interface TooltipButtonProps {
  tooltipProps?: ToolTipPropType;
  buttonBoxProps?: BoxProps;
}

export const TooltipButton: React.FC<TooltipButtonProps & ButtonProps> = ({
  tooltipProps,
  buttonBoxProps,
  ...buttonProps
}) => {
  return (
    <>
      {tooltipProps && tooltipProps.text && (
        <TooltipOnTableActionButton {...tooltipProps} text={tooltipProps.text}>
          <Box {...buttonBoxProps}>
            <Button {...buttonProps} />
          </Box>
        </TooltipOnTableActionButton>
      )}
      {!tooltipProps?.text && (
        <Box {...buttonBoxProps}>
          <Button {...buttonProps} />
        </Box>
      )}
    </>
  );
};
