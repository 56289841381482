import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  getOutletInfoData,
  postCreateEdition,
  putUpdateEdition,
  deleteRemoveEdition,
  postCreateCategory,
  putUpdateCategory,
  deleteRemoveCategory,
  putUpdateRepresentative,
  postCreateRepresentative,
  deleteRemoveRepresentative,
  postCreateAdvertisement,
  putUpdateAdvertisement,
  deleteRemoveAdvertisement,
  getOutletEditionPeriods
} from './outletInfoDataApi';
import {
  fetchOutletInfoData,
  fetchOutletInfoDataSuccess,
  fetchOutletInfoDataError,
  createEdition,
  createEditionSuccess,
  createEditionError,
  updateEdition,
  updateEditionSuccess,
  updateEditionError,
  removeEditionSuccess,
  removeEditionError,
  removeEdition,
  createCategory,
  createCategorySuccess,
  createCategoryError,
  updateCategorySuccess,
  updateCategory,
  removeCategory,
  removeCategorySuccess,
  removeCategoryError,
  updateCategoryError,
  createRepresentative,
  updateRepresentative,
  removeRepresentative,
  createRepresentativeSuccess,
  updateRepresentativeSuccess,
  updateRepresentativeError,
  createRepresentativeError,
  removeRepresentativeError,
  removeRepresentativeSuccess,
  createAdvertisement,
  updateAdvertisement,
  updateAdvertisementSuccess,
  updateAdvertisementError,
  createAdvertisementSuccess,
  createAdvertisementError,
  removeAdvertisement,
  removeAdvertisementSuccess,
  removeAdvertisementError,
  resetAdvertisementRemovingState,
  resetCategoryRemovingState,
  resetEditionRemovingState,
  resetRepresentativeRemovingState,
  resetAdvertisementCreatingState,
  resetAdvertisementUpdatingState,
  resetRepresentativeUpdatingState,
  resetRepresentativeForNewRecord,
  resetCategoryUpdatingState,
  resetCategoryCreatingState,
  resetEditionUpdatingState,
  resetEditionCreatingState,
  fetchMissingPeriods,
  fetchMissingPeriodsSuccess,
  fetchMissingPeriodsError
} from './outletInfoDataActions';
import {
  FETCH_OUTLET_INFO_DATA_REQUEST,
  CREATE_EDITION_REQUEST,
  UPDATE_EDITION_REQUEST,
  REMOVE_EDITION_REQUEST,
  CREATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_REQUEST,
  REMOVE_CATEGORY_REQUEST,
  REMOVE_REPRESENTATIVE_REQUEST,
  UPDATE_REPRESENTATIVE_REQUEST,
  CREATE_REPRESENTATIVE_REQUEST,
  CREATE_ADVERTISEMENT_REQUEST,
  UPDATE_ADVERTISEMENT_REQUEST,
  REMOVE_ADVERTISEMENT_REQUEST,
  FETCH_MISSING_PERIODS_REQUEST
} from './outletInfoDataActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { fetchVirsDataRequest } from '../virsis/actions';

function* handleOutletInfoDataFetch(action: ActionType<typeof fetchOutletInfoData>) {
  try {
    const selectedOutletData = yield call(
      getOutletInfoData,
      action.payload.virId,
      action.payload.outletId
    );
    yield put(fetchOutletInfoDataSuccess(selectedOutletData.data));
  } catch (error) {
    yield put(fetchOutletInfoDataError(error));
    yield put(sendMessage('error', error.response.data.message));
  }
}

function* handleCreateEditionSaga(action: ActionType<typeof createEdition>) {
  try {
    const { virId, outletId, record } = action.payload;
    yield call(postCreateEdition, virId, outletId, record);
    yield put(createEditionSuccess());
    yield put(fetchVirsDataRequest());
    yield put(resetEditionCreatingState());
    yield put(sendMessage('success', 'Tiražų įrašas išsaugotas'));
  } catch (err) {
    yield put(createEditionError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateEditionSaga(action: ActionType<typeof updateEdition>) {
  try {
    const { virId, outletId, editionId, record } = action.payload;
    yield call(putUpdateEdition, virId, outletId, editionId, record);
    yield put(updateEditionSuccess());
    yield put(fetchVirsDataRequest());
    yield put(resetEditionUpdatingState());
    yield put(sendMessage('success', 'Tiražų įrašas atnaujintas'));
  } catch (err) {
    yield put(updateEditionError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveEditionSaga(action: ActionType<typeof removeEdition>) {
  try {
    const { virId, outletId, editionId } = action.payload;
    yield call(deleteRemoveEdition, virId, outletId, editionId);
    yield put(removeEditionSuccess());
    yield put(resetEditionRemovingState());
    yield put(fetchOutletInfoData(virId, outletId));
    yield put(fetchVirsDataRequest());
    yield put(resetEditionRemovingState());
    yield put(sendMessage('success', 'Tiražų įrašas pašalintas'));
  } catch (err) {
    yield put(removeEditionError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleMissingPeriodsFetchSaga(action: ActionType<typeof fetchMissingPeriods>) {
  try {
    const { outletId } = action.payload;
    const { data } = yield call(getOutletEditionPeriods, outletId);
    yield put(fetchMissingPeriodsSuccess(data, outletId));
  } catch (err) {
    yield put(fetchMissingPeriodsError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleCreateCategorySaga(action: ActionType<typeof createCategory>) {
  try {
    const { outletId, newCategory } = action.payload;
    yield call(postCreateCategory, outletId, newCategory);
    yield put(createCategorySuccess());
    yield put(fetchVirsDataRequest());
    yield put(resetCategoryCreatingState());
    yield put(sendMessage('success', 'Kategorijos įrašas išsaugotas'));
  } catch (err) {
    yield put(createCategoryError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateCategorySaga(action: ActionType<typeof updateCategory>) {
  try {
    const { outletId, outletCategoryId, record } = action.payload;
    yield call(putUpdateCategory, outletId, outletCategoryId, record);
    yield put(updateCategorySuccess());
    yield put(fetchVirsDataRequest());
    yield put(resetCategoryUpdatingState());
    yield put(sendMessage('success', 'Kategorijos įrašas atnaujintas'));
  } catch (err) {
    yield put(updateCategoryError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveCategorySaga(action: ActionType<typeof removeCategory>) {
  try {
    const { virId, outletId, outletCategoryId } = action.payload;
    yield call(deleteRemoveCategory, outletId, outletCategoryId);
    yield put(removeCategorySuccess());
    yield put(resetCategoryRemovingState());
    yield put(fetchOutletInfoData(virId, outletId));
    yield put(fetchVirsDataRequest());
    yield put(resetCategoryRemovingState());
    yield put(sendMessage('success', 'Kategorijos įrašas pašalintas'));
  } catch (err) {
    yield put(removeCategoryError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleCreateRepresentativeSaga(action: ActionType<typeof createRepresentative>) {
  try {
    const { virId, outletId, record } = action.payload;
    yield call(postCreateRepresentative, virId, outletId, record);
    yield put(createRepresentativeSuccess());
    yield put(fetchVirsDataRequest());
    yield put(resetRepresentativeForNewRecord());
    yield put(sendMessage('success', 'Už turinį atsakingo asmens įrašas išsaugotas'));
  } catch (err) {
    yield put(createRepresentativeError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateRepresentativeSaga(action: ActionType<typeof updateRepresentative>) {
  try {
    const { virId, outletId, representativeId, record, message } = action.payload;
    yield call(putUpdateRepresentative, virId, outletId, representativeId, record);
    yield put(updateRepresentativeSuccess());
    yield put(fetchVirsDataRequest());
    yield put(resetRepresentativeUpdatingState());
    yield put(sendMessage('success', message));
  } catch (err) {
    yield put(updateRepresentativeError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveRepresentativeSaga(action: ActionType<typeof removeRepresentative>) {
  try {
    const { virId, outletId, representativeId } = action.payload;
    yield call(deleteRemoveRepresentative, virId, outletId, representativeId);
    yield put(removeRepresentativeSuccess());

    yield put(resetRepresentativeRemovingState());
    yield put(fetchOutletInfoData(virId, outletId));
    yield put(resetRepresentativeRemovingState());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Už turinį atsakingo asmens įrašas pašalintas'));
  } catch (err) {
    yield put(removeRepresentativeError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleCreateAdvertisementSaga(action: ActionType<typeof createAdvertisement>) {
  try {
    const { outletId, newAdvertisement } = action.payload;
    yield call(postCreateAdvertisement, outletId, newAdvertisement);
    yield put(createAdvertisementSuccess());
    yield put(resetAdvertisementCreatingState());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Reklamos spausdinimo įrašas išsaugotas'));
  } catch (err) {
    yield put(createAdvertisementError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateAdvertisementSaga(action: ActionType<typeof updateAdvertisement>) {
  try {
    const { outletId, outletAdPrintingId, record } = action.payload;
    yield call(putUpdateAdvertisement, outletId, outletAdPrintingId, record);
    yield put(updateAdvertisementSuccess());
    yield put(resetAdvertisementUpdatingState());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Reklamos spausdinimo įrašas atnaujintas'));
  } catch (err) {
    yield put(updateAdvertisementError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveAdvertisementSaga(action: ActionType<typeof removeAdvertisement>) {
  try {
    const { outletId, advertisementId, virId } = action.payload;
    yield call(deleteRemoveAdvertisement, outletId, advertisementId);
    yield put(removeAdvertisementSuccess());
    yield put(resetAdvertisementRemovingState());
    yield put(fetchOutletInfoData(virId, outletId));
    yield put(resetAdvertisementRemovingState());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Reklamos spausdinimo įrašas pašalintas'));
  } catch (err) {
    yield put(removeAdvertisementError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* outletInfoDataSaga() {
  yield takeEvery(FETCH_OUTLET_INFO_DATA_REQUEST, handleOutletInfoDataFetch);
  yield takeEvery(CREATE_EDITION_REQUEST, handleCreateEditionSaga);
  yield takeEvery(UPDATE_EDITION_REQUEST, handleUpdateEditionSaga);
  yield takeEvery(REMOVE_EDITION_REQUEST, handleRemoveEditionSaga);
  yield takeEvery(FETCH_MISSING_PERIODS_REQUEST, handleMissingPeriodsFetchSaga);
  yield takeEvery(CREATE_CATEGORY_REQUEST, handleCreateCategorySaga);
  yield takeEvery(UPDATE_CATEGORY_REQUEST, handleUpdateCategorySaga);
  yield takeEvery(REMOVE_CATEGORY_REQUEST, handleRemoveCategorySaga);
  yield takeEvery(CREATE_REPRESENTATIVE_REQUEST, handleCreateRepresentativeSaga);
  yield takeEvery(UPDATE_REPRESENTATIVE_REQUEST, handleUpdateRepresentativeSaga);
  yield takeEvery(REMOVE_REPRESENTATIVE_REQUEST, handleRemoveRepresentativeSaga);
  yield takeEvery(CREATE_ADVERTISEMENT_REQUEST, handleCreateAdvertisementSaga);
  yield takeEvery(UPDATE_ADVERTISEMENT_REQUEST, handleUpdateAdvertisementSaga);
  yield takeEvery(REMOVE_ADVERTISEMENT_REQUEST, handleRemoveAdvertisementSaga);
}
export default outletInfoDataSaga;
