import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import { AdColumnHeaderField, AdDataTableState } from '../tablesState/adPrintingTableTypes';
import { TableSortingHeaderCell } from '../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../utils/tableDataFunctions';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';

const AdTableHead: React.FC = () => {
  const {
    state: { adprinting: adPrintingTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tablesDispatch } = useOutletInfoTablesDispatch();

  const handleSetSortByColumn = (column: AdColumnHeaderField) => {
    tablesDispatch({ type: 'AD_PRINTING_SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    AdDataTableState,
    typeof handleSetSortByColumn,
    AdColumnHeaderField
  >(handleSetSortByColumn, adPrintingTableState);

  return (
    <TableHead>
      <TableRow>
        {(adPrintingTableState.columnsDisplayStatus.validFrom ||
          adPrintingTableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              adPrintingTableState.columnsDisplayStatus.validFrom,
              adPrintingTableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">Spausdinama</Typography>
          </TableCell>
        )}

        <TableCell rowSpan={2} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {adPrintingTableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={adPrintingTableState}
          />
        )}
        {adPrintingTableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={adPrintingTableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default AdTableHead;
