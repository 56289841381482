import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  ProvidedDataHistoryColumnHeader,
  ProvidedDataHistoryHeaderField
} from './tableState/tableInitialStateAndTypes';
import { useProvidedDataHistoryTableState, useProvidedDataHistoryTableDispatch } from './Context';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { ProvidedDataHistoryTablePeriodFilter } from './FilterByPeriod';
import TableStatusFiltersWithAnnuled from '../../components/TableStatusFilters/TableStatusFiltersWithAnnuled';
import { ApplicationState } from '../../store';
import {
  customFilterReset,
  showStatusAnulledToggle,
  showStatusSignedToggle,
  showStatusUnsignedToggled,
  sortingChanged
} from '../../store/providedDataTable/providedDataTableActions';

interface Props {
  columnNames: ProvidedDataHistoryColumnHeader[];
}

export const ProvidedDataHistoryTableCustomizationBar: React.FC<Props> = ({ columnNames }) => {
  const { state } = useProvidedDataHistoryTableState();
  const { dispatch } = useProvidedDataHistoryTableDispatch();
  const { tableDataState } = useSelector(
    (applicationState: ApplicationState) => applicationState.providedDataTable
  );
  const reduxDispatch = useDispatch();

  const toggleDisplayCustomFilter = () => {
    reduxDispatch(customFilterReset());
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: ProvidedDataHistoryHeaderField) => {
    reduxDispatch(sortingChanged(column));
  };

  const toggleDisplayColumn = (column: ProvidedDataHistoryHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const toggleNotSignedFilter = () => {
    reduxDispatch(showStatusUnsignedToggled());
  };

  const toggleSignedFilter = () => {
    reduxDispatch(showStatusSignedToggle());
  };

  const toggleAnnuledFilter = () => {
    reduxDispatch(showStatusAnulledToggle());
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFiltersWithAnnuled
            notSignedFilterDisabled={!tableDataState.statusNotSignedFilterEnabled}
            notSignedFilterOn={tableDataState.showStatusNotSigned}
            onNotSignedFilterToggle={toggleNotSignedFilter}
            signedFilterDisabled={!tableDataState.statusSignedFilterEnabled}
            signedFilterOn={tableDataState.showStatusSigned}
            onSignedFilterToggle={toggleSignedFilter}
            annuledFilterDisabled={!tableDataState.statusAnnuledFilterEnabled}
            annuledFilterOn={tableDataState.showStatusAnnuled}
            onAnnuledFilterToggle={toggleAnnuledFilter}
          />
        }
        rightSideItem={
          <>
            <ProvidedDataHistoryTablePeriodFilter />
            <TableCustomTools
              tableColumns={columnNames}
              showFilter={state.customFilterOn}
              onFilterTabToggle={toggleDisplayCustomFilter}
              sortOrder={tableDataState.order}
              sortBy={tableDataState.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnsDisplayStatus={state.columnsDisplayStatus}
              toggleDisplayColumn={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
