import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
  ErrorProps,
  ShareholderPerson,
  ShareholdersProps
} from '../../store/shareholders/shareholdersTypes';
import { TriangleButton } from './TableButtons/TriangleButton';
import { ShareholderFullName } from './ShareholderFullName';
import { ShareholderNameCellButtons } from './ShareholderNameCellButtons';

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  person: ShareholderPerson;
  showTriangleButton: boolean;
  showChildShareholder: boolean;
  handleShowGroupShareholders: () => void;
  showAddShareholderButton?: boolean;
  handleAddShareholderButtonClicked?: () => void;
  criticalErrorMessages: ErrorProps[];
  nonCriticalErrorMessages: ErrorProps[];
  infoNotifications: ErrorProps[];
  selectedDate?: string;
  marginLeft: number;
}

const useStyles = makeStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
});

const GroupShareholderMemberName: React.FC<Props> = ({
  index,
  shareholder,
  person,
  showTriangleButton,
  showChildShareholder,
  handleShowGroupShareholders,
  showAddShareholderButton,
  handleAddShareholderButtonClicked,
  criticalErrorMessages,
  nonCriticalErrorMessages,
  selectedDate,
  infoNotifications,
  marginLeft
}) => {
  const classes = useStyles();
  return (
    <div className="name-row">
      <div className={classes.flexContainer}>
        <div className="expand-details-button-placeholder" />
        <TriangleButton
          expandChild={showChildShareholder}
          onClicked={handleShowGroupShareholders}
          showTriangleButton={showTriangleButton}
          marginLeft={marginLeft}
        />

        <Typography variant="h5">
          <ShareholderFullName
            key={`${person.personId}${person.personLegalCode}`}
            person={person}
            isItGroupShareholder
          />
        </Typography>
      </div>

      <ShareholderNameCellButtons
        index={index}
        shareholder={shareholder}
        mayShowErrorIcons
        showAddShareholderButton={showAddShareholderButton}
        criticalErrorMessages={criticalErrorMessages}
        nonCriticalErrorMessages={nonCriticalErrorMessages}
        infoNotifications={infoNotifications}
        handleAddShareholderButtonClicked={handleAddShareholderButtonClicked}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default GroupShareholderMemberName;
