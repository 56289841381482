import { Order } from '../../../components/TableTypes/TableTypes';
import { UserMessage } from '../../../store/userMessages/userMessageTypes';
import { ColumnHeader } from '../../../utils/tableTypes';

export enum MessagesTableColumn {
  RECEIVED_DATE = 'receivedDate',
  HEADER = 'header'
}

export const MESSAGE_TABLE_COLUMN_HEADERS: MessagesColumnHeader[] = [
  {
    id: MessagesTableColumn.RECEIVED_DATE,
    label: 'Gauta',
    type: 'date'
  },
  {
    id: MessagesTableColumn.HEADER,
    label: 'Antraštė'
  }
];

export const initialMessageTableState: MessageTableState = {
  order: 'desc',
  sortBy: MessagesTableColumn.RECEIVED_DATE,
  page: 0,
  rowsPerPage: 5,
  customFilterOn: false,
  customFilter: {
    receivedDate: [],
    header: []
  },
  messageToView: undefined,
  selectedMessages: [],
  selectAllChecked: false,
  showRemoveConfirmation: false
};

export type MessagesColumnHeader = ColumnHeader<MessagesTableColumn>;

export type MessageCustomFilter = {
  receivedDate: string[];
  header: string[];
};

export interface MessageTableState {
  sortBy: MessagesTableColumn;
  order: Order;
  page: number;
  rowsPerPage: number;
  customFilterOn: boolean;
  customFilter: MessageCustomFilter;
  messageToView: UserMessage | undefined;
  selectedMessages: UserMessage[];
  selectAllChecked: boolean;
  showRemoveConfirmation: boolean;
}

export type MessageTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: MessagesTableColumn;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: MessagesTableColumn }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'MESSAGE_DIALOG_OPENED'; message: UserMessage }
  | { type: 'MESSAGE_DIALOG_CLOSED' }
  | { type: 'MESSAGE_SELECTED'; message: UserMessage }
  | { type: 'MESSAGE_UNSELECTED'; message: UserMessage }
  | { type: 'ALL_MESSAGES_SELECTED'; messages: UserMessage[] }
  | { type: 'ALL_MESSAGES_UNSELECTED' }
  | { type: 'RESET_AFTER_ACTION_WITH_MESSAGES_SUCCESSFUL' }
  | { type: 'REMOVE_SELECTED_CLICKED' };
