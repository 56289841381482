import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Table, TableBody } from '@material-ui/core';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { ApplicationState } from '../../store';
import { pageTableData } from '../../utils/tableDataFunctions';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { useParametersDataTableDispatch, useParametersDataTableState } from './Context';
import { filterAndSortParameterDataVirs } from './tableState/tableStateFunctions';
import { ParametersDataTableCustomizationBar } from './TableCustomization';
import { parametersTableColumns } from './tableState/tableInitialStateAndTypes';
import { ParametersDataTableCustomFilter } from './FilterRow';
import { ParametersDataTableHead } from './TableHead';
import { ParameterRow } from './TableRow';
import { RowActionButtonGeneric } from '../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { VirsisParameter } from '../../store/parameters/parametersDataTypes';
import { ParameterDialogContainer } from './ParameterDialog/ParameterDialogContainer';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';

const ParametersTable: React.FC = () => {
  const { state: tableState } = useParametersDataTableState();
  const { dispatch: tableDispatch } = useParametersDataTableDispatch();

  const {
    loadingVirsisParameters,
    virsisParameters,
    virsisParametersError,
    updatingVirsisParameterSuccess
  } = useSelector((state: ApplicationState) => state.virsisParameters);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const openDialogWithRecord = (parameterRecord: VirsisParameter) => {
    return () => {
      tableDispatch({
        type: 'PARAMETER_DIALOG_OPENED',
        parameterRecord
      });
    };
  };

  const closeParameterDialog = () => {
    tableDispatch({
      type: 'PARAMETER_DIALOG_CLOSED'
    });
  };

  const filteredData = filterAndSortParameterDataVirs(tableState, virsisParameters || []);

  const { page: dataPage, pagesCount } = pageTableData(tableState, filteredData);

  return (
    <div className="named-data-table">
      <Paper elevation={0}>
        <ParametersDataTableCustomizationBar columnNames={parametersTableColumns} />
        {tableState.parameter && (
          <ParameterDialogContainer
            isOpen={!!tableState.parameter}
            record={tableState.parameter}
            close={closeParameterDialog}
            updateSucess={updatingVirsisParameterSuccess}
          />
        )}

        <ScrollXContainer>
          <Table>
            <ParametersDataTableHead />

            {tableState.customFilterOn && (
              <ParametersDataTableCustomFilter columnNames={parametersTableColumns} />
            )}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingVirsisParameters && !virsisParametersError}
                colSpan={13}
              />

              <TableErrorRow
                error={virsisParametersError && 'Klaida. Nepavyko gauti parametrų duomenų'}
                colSpan={13}
              />

              {!loadingVirsisParameters &&
                !virsisParametersError &&
                dataPage.map((record) => (
                  <ParameterRow
                    key={record.parameterId}
                    columnsToDisplay={tableState.columnsDisplayStatus}
                    record={record}
                    actions={
                      <RowActionButtonGeneric
                        label="Redaguoti"
                        onButtonClicked={openDialogWithRecord(record)}
                      />
                    }
                  />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={filteredData.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={filteredData.length === 0}
        />
      </Paper>
    </div>
  );
};
export default ParametersTable;
