import React from 'react';
import { FormGroup, FormControlLabel, Typography } from '@material-ui/core';

import { VirsisSwitch } from '../../../components/VirsisSwitch/VirsisSwitch';

interface Props {
  notSignedFilterOn: boolean;
  onNotSignedFilterToggle: () => void;
  notSignedFilterDisabled?: boolean;
}

const TableStatusFilters: React.FC<Props> = ({
  notSignedFilterOn,
  onNotSignedFilterToggle,
  notSignedFilterDisabled
}) => {
  return (
    <div className="TableStatusFilters">
      <FormGroup row>
        <Typography
          variant="h5"
          style={{
            color: '#637082',
            fontWeight: 600,
            marginRight: 15,
            fontSize: '13px'
          }}
        >
          Duomenų rodymas:
        </Typography>
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={notSignedFilterOn}
              onChange={onNotSignedFilterToggle}
              name="not-signed"
              disabled={notSignedFilterDisabled}
            />
          }
          label="Nepasirašyti"
        />
      </FormGroup>
    </div>
  );
};

export default TableStatusFilters;
