import { Reducer } from 'redux';
import { KeyStringValueBoolean } from '../virsis/dataTypes';
import { getAllShareholders } from '../../containers/ShareholdersTable/SelectedDateTable/utilities/utilityFunctions';
import {
  ADD_SHAREHOLDER_PERCENTAGE_ERROR,
  ADD_SHAREHOLDER_PERCENTAGE_REQUEST,
  ADD_SHAREHOLDER_PERCENTAGE_SUCCESS,
  CREATE_SHAREHOLDER_ERROR,
  CREATE_SHAREHOLDER_REQUEST,
  CREATE_SHAREHOLDER_STATE_RESET,
  CREATE_SHAREHOLDER_SUCCESS,
  DELETE_SHAREHOLDERS_REQUEST_ERROR,
  DELETE_SHAREHOLDERS_REQUEST_SUCCESS,
  DELETE_SHAREHOLDERS_REQUEST,
  FETCH_VIRS_PERSONS_LIST_ERROR,
  FETCH_VIRS_PERSONS_LIST_REQUEST,
  FETCH_VIRS_PERSONS_LIST_SUCCESS,
  SET_SELECTED_DATE,
  SHAREHOLDERS_DATA_REQUEST_ERROR,
  SHAREHOLDERS_DATA_REQUEST_SUCCESS,
  SHAREHOLDERS_DATA_REQUEST,
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_ERROR,
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_SUCCESS,
  SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST,
  SYNCHRONISE_WITH_JADIS_ERROR,
  SYNCHRONISE_WITH_JADIS_REQUEST,
  SYNCHRONISE_WITH_JADIS_SUCCESS,
  ADDING_SHAREHOLDER_DATE,
  ADDING_SHAREHOLDER_DATE_RESET,
  SHOW_DATE_DELETION_DIALOG,
  RESET_DATE_DELETION_DIALOG,
  SHOW_DATA_DELETION_BY_DATE_DIALOG,
  RESET_DATA_DELETION_BY_DATE_DIALOG,
  SHOW_SHAREHOLDER_DELETION_DIALOG,
  RESET_SHAREHOLDER_DELETION_DIALOG,
  SHOW_CLEAR_SHAREHOLDER_EVENT_DIALOG,
  RESET_CLEAR_SHAREHOLDER_EVENT_DIALOG,
  CLEAR_SHAREHOLDER_EVENT_REQUEST,
  CLEAR_SHAREHOLDER_EVENT_SUCCESS,
  CLEAR_SHAREHOLDER_EVENT_ERROR,
  DELETE_DATE_REQUEST,
  DELETE_DATE_SUCCESS,
  DELETE_DATE_ERROR,
  DELETE_DATA_BY_DATE_REQUEST,
  DELETE_DATA_BY_DATE_SUCCESS,
  SET_UPDATE_SHAREHOLDER_ID,
  UNSET_UPDATE_SHAREHOLDER_ID,
  UPDATE_SHAREHOLDER_ERROR,
  UPDATE_SHAREHOLDER_REQUEST,
  UPDATE_SHAREHOLDER_SUCCESS,
  UPDATE_SHAREHOLDER_STATE_RESET,
  DELETE_DATA_BY_DATE_ERROR,
  ADD_SHAREHOLDER_PERCENTAGE_RESET,
  SET_EXPAND_SHAREHOLDER,
  SET_EXPAND_PARENTS,
  SET_ALL_SHAREHOLDERS,
  SET_EXPAND_FILTERED_PARENTS,
  RESET_EXPAND_SHAREHOLDERS,
  RESET_SHAREHOLDERS_DATA,
  FILTER_UNSIGNED_SHAREHOLDERS_TOGGLED,
  SET_LAST_SIGNED_DATE
} from './shareholdersActionTypes';
import {
  ShareholdersData,
  AddPersonDialogElement,
  CreatedShareholderData,
  ShareholdersProps,
  ClearShareholderPercentageRecord
} from './shareholdersTypes';
import {
  getAllSelectedDates,
  setExpandParentPersons,
  setExpandParentsPath
} from './shareholdersUtils';
import { getHeaderDatesSortedUnique } from '../../containers/ShareholdersTable/utilityFunctions/sharedFunctions';
import { Moment } from 'moment';

export interface ShareholdersDataState {
  selectedShareholderId?: number;
  loadingShareholdersSelectedDateData: boolean;
  shareholdersSelectedDateData?: ShareholdersData;
  allSelectedDateDates?: string[];
  shareholdersSelectedDateDataError?: Error;
  loadingShareholdersData: boolean;
  shareholdersData?: ShareholdersData;
  allEventsDates?: string[];
  shareholdersDataError?: Error;
  selectedDate?: string;
  addingShareholderDate: boolean;
  loadingAddShareholderPercentage: boolean;
  isShareholderPercentageAdded: boolean;
  addShareholderPercentageError?: Error;
  loadingVirsPersonsList: boolean;
  virsPersonsList?: AddPersonDialogElement[];
  virsPersonsListError?: Error;
  loadingSynchroniseWithJADIS: boolean;
  synchroniseWithJADISSuccess: boolean;
  synchroniseWithJADISError?: Error;
  loadingCreateShareholder: boolean;
  isShareholderCreated: boolean;
  createShareholderError?: Error;
  createShareholderSuccess?: CreatedShareholderData;
  selectedDateForFunctions?: string;
  showDateDeletionDialog: boolean;
  showDataDeletionByDateDialog: boolean;
  dataByDateDeleted: boolean;
  shareholderDeleted: boolean;
  selectedPercentageRecord?: ClearShareholderPercentageRecord;
  loadingClearShareholderEvent: boolean;
  clearedShareholderEvent: boolean;
  showClearShareholderEventDialog: boolean;
  clearShareholderEventError?: Error;
  showShareholderDeletionDialog: boolean;
  loadingShareholderDeletion: boolean;
  shareholderDeletionError?: Error;
  dateDeletionError?: Error;
  loadingDateDeletion: boolean;
  dataDeletionByDateError?: Error;
  loadingDataDeletionByDate: boolean;
  dateDeleted: boolean;
  shareholderToEditId?: number;
  loadingUpdateShareholder: boolean;
  isShareholderUpdated: boolean;
  updateShareholderError?: Error;
  updateShareholderSuccess?: CreatedShareholderData;
  noValidShareholderParent: boolean;
  newDate?: string;
  expandedShareholders: KeyStringValueBoolean;
  expandedPersons: KeyStringValueBoolean;
  allShareholders?: ShareholdersProps[];
  showUnsignedSwitchEnabled?: boolean;
  showUnsigned: boolean | undefined;
  lastSignedDate: Moment | undefined;
}

export const initialState: ShareholdersDataState = {
  selectedShareholderId: undefined,
  loadingShareholdersSelectedDateData: false,
  shareholdersSelectedDateDataError: undefined,
  shareholdersSelectedDateData: undefined,
  allSelectedDateDates: undefined,

  loadingSynchroniseWithJADIS: false,
  synchroniseWithJADISSuccess: false,
  synchroniseWithJADISError: undefined,

  loadingShareholdersData: false,
  shareholdersDataError: undefined,
  shareholdersData: undefined,
  allEventsDates: undefined,

  selectedDate: undefined,
  addingShareholderDate: false,

  loadingAddShareholderPercentage: false,
  isShareholderPercentageAdded: false,
  addShareholderPercentageError: undefined,

  loadingVirsPersonsList: false,
  virsPersonsList: undefined,
  virsPersonsListError: undefined,

  loadingCreateShareholder: false,
  isShareholderCreated: false,
  createShareholderError: undefined,
  createShareholderSuccess: undefined,

  shareholderToEditId: undefined,
  loadingUpdateShareholder: false,
  isShareholderUpdated: false,
  updateShareholderError: undefined,
  updateShareholderSuccess: undefined,
  noValidShareholderParent: false,
  newDate: undefined,
  allShareholders: undefined,

  /** CLEAR SHAREHOLDER EVENT */
  selectedPercentageRecord: undefined,
  loadingClearShareholderEvent: false,
  clearedShareholderEvent: false,
  showClearShareholderEventDialog: false,
  clearShareholderEventError: undefined,

  /** DATA DELETION BY DATE */
  showDataDeletionByDateDialog: false,
  loadingDataDeletionByDate: false,
  dataDeletionByDateError: undefined,
  dataByDateDeleted: false,

  /** SHAREHOLDER DELETION */
  shareholderDeletionError: undefined,
  loadingShareholderDeletion: false,
  shareholderDeleted: false,
  showShareholderDeletionDialog: false,

  /** DATE DELETION */
  showDateDeletionDialog: false,
  loadingDateDeletion: false,
  dateDeleted: false,
  dateDeletionError: undefined,

  /** EXPANDING SHAREHOLDER */
  expandedShareholders: {},
  expandedPersons: {},

  /** FILTER UNSIGNED SHAREHOLDERS */
  showUnsignedSwitchEnabled: true,
  showUnsigned: undefined,

  /** LAST SIGNED DATE */
  lastSignedDate: undefined
};

export const shareholdersDataReducer: Reducer<ShareholdersDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST: {
      return {
        ...state,
        loadingShareholdersSelectedDateData: true,
        shareholdersSelectedDateDataError: undefined
      };
    }
    case SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingShareholdersSelectedDateData: false,
        shareholdersSelectedDateData: action.payload,
        allSelectedDateDates: getAllSelectedDates(action.payload)
      };
    }
    case SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_ERROR: {
      return {
        ...state,
        loadingShareholdersSelectedDateData: false,
        shareholdersSelectedDateDataError: action.payload
      };
    }

    case SHAREHOLDERS_DATA_REQUEST:
      return {
        ...state,
        loadingShareholdersData: true,
        shareholdersDataError: undefined
      };
    case SHAREHOLDERS_DATA_REQUEST_SUCCESS:
      if (action.payload) {
        const flattendShareholders = getAllShareholders(action.payload.shareholders, []);
        return {
          ...state,
          loadingShareholdersData: false,
          shareholdersData: action.payload,
          showUnsigned:
            state.showUnsigned === undefined
              ? action.payload.isUnsigned?.isUnsigned
              : state.showUnsigned,
          allEventsDates: action.payload.virsEndDate
            ? getHeaderDatesSortedUnique(false, action.payload)
            : getHeaderDatesSortedUnique(true, action.payload),
          allShareholders: flattendShareholders,
          expandedShareholders: setExpandParentsPath(
            state.expandedShareholders,
            String(action.payload.virsShareholderId)
          ),
          showUnsignedSwitchEnabled: action.payload.isUnsigned?.isUnsigned
        };
      }
      return {
        ...state,
        loadingShareholdersData: false,
        isShowUnsignedShareholders: false
      };
    case SHAREHOLDERS_DATA_REQUEST_ERROR:
      return {
        ...state,
        loadingShareholdersData: false,
        shareholdersDataError: action.payload
      };

    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload
      };
    /** ADD DATE */
    case ADDING_SHAREHOLDER_DATE:
      return {
        ...state,
        newDate: action.payload,
        selectedDate: action.payload,
        addingShareholderDate: true
      };
    case ADDING_SHAREHOLDER_DATE_RESET:
      return {
        ...state,
        addingShareholderDate: false,
        newDate: undefined
      };
    /** CLEAR SHAREHOLDER EVENT */
    case SHOW_CLEAR_SHAREHOLDER_EVENT_DIALOG:
      return {
        ...state,
        showClearShareholderEventDialog: true,
        selectedDateForFunctions: action.payload.date,
        selectedPercentageRecord: action.payload.tempPercentageRecord
      };
    case RESET_CLEAR_SHAREHOLDER_EVENT_DIALOG:
      return {
        ...state,
        showClearShareholderEventDialog: false,
        selectedDateForFunctions: undefined,
        selectedPercentageRecord: undefined,
        clearShareholderEventError: undefined
      };
    case CLEAR_SHAREHOLDER_EVENT_REQUEST:
      return {
        ...state,
        loadingClearShareholderEvent: true
      };
    case CLEAR_SHAREHOLDER_EVENT_SUCCESS:
      return {
        ...state,
        loadingClearShareholderEvent: false,
        addingShareholderDate: false,
        clearShareholderEventError: undefined
      };
    case CLEAR_SHAREHOLDER_EVENT_ERROR:
      return {
        ...state,
        loadingClearShareholderEvent: false,
        clearShareholderEventError: action?.payload?.data
      };

    /** DATE DELETION */
    case SHOW_DATE_DELETION_DIALOG:
      return {
        ...state,
        showDateDeletionDialog: true,
        selectedDateForFunctions: action.payload
      };
    case RESET_DATE_DELETION_DIALOG:
      return {
        ...state,
        showDateDeletionDialog: false,
        selectedDateForFunctions: undefined,
        dateDeletionError: undefined
      };
    case DELETE_DATE_REQUEST:
      return {
        ...state,
        loadingDateDeletion: true
      };
    case DELETE_DATE_SUCCESS:
      return {
        ...state,
        loadingDateDeletion: false,
        addingShareholderDate: false,
        dateDeletionError: undefined
      };
    case DELETE_DATE_ERROR:
      return {
        ...state,
        loadingDateDeletion: false,
        dateDeletionError: action?.payload?.data
      };

    /** DATA DELETION BY DATE */
    case SHOW_DATA_DELETION_BY_DATE_DIALOG:
      return {
        ...state,
        showDataDeletionByDateDialog: true,
        selectedDateForFunctions: action.payload
      };
    case RESET_DATA_DELETION_BY_DATE_DIALOG:
      return {
        ...state,
        showDataDeletionByDateDialog: false,
        selectedDateForFunctions: undefined,
        dataDeletionByDateError: undefined
      };
    case DELETE_DATA_BY_DATE_REQUEST:
      return {
        ...state,
        loadingDataDeletionByDate: true
      };
    case DELETE_DATA_BY_DATE_SUCCESS:
      return {
        ...state,
        loadingDataDeletionByDate: false,
        addingShareholderDate: false
      };
    case DELETE_DATA_BY_DATE_ERROR:
      return {
        ...state,
        loadingDataDeletionByDate: false,
        dataDeletionByDateError: action?.payload?.data
      };

    /** SHAREHOLDER DELETION */
    case SHOW_SHAREHOLDER_DELETION_DIALOG:
      return {
        ...state,
        showShareholderDeletionDialog: true,
        selectedShareholderId: action.payload.shareholderId,
        selectedDateForFunctions: action.payload.date
      };
    case RESET_SHAREHOLDER_DELETION_DIALOG:
      return {
        ...state,
        showShareholderDeletionDialog: false,
        selectedDateForFunctions: undefined,
        shareholderDeletionError: undefined
      };

    case DELETE_SHAREHOLDERS_REQUEST:
      return {
        ...state,
        loadingShareholderDeletion: true
      };
    case DELETE_SHAREHOLDERS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingShareholderDeletion: false,
        addingShareholderDate: false,
        shareholderDeletionError: undefined
      };
    case DELETE_SHAREHOLDERS_REQUEST_ERROR:
      return {
        ...state,
        loadingShareholderDeletion: false,
        shareholderDeletionError: action?.payload?.data
      };

    case ADD_SHAREHOLDER_PERCENTAGE_REQUEST: {
      return { ...state, loadingAddShareholderPercentage: true };
    }
    case ADD_SHAREHOLDER_PERCENTAGE_SUCCESS: {
      return {
        ...state,
        loadingAddShareholderPercentage: false,
        isShareholderPercentageAdded: true,
        addingShareholderDate: false
      };
    }
    case ADD_SHAREHOLDER_PERCENTAGE_RESET: {
      return {
        ...state,
        isShareholderPercentageAdded: false
      };
    }
    case ADD_SHAREHOLDER_PERCENTAGE_ERROR: {
      return {
        ...state,
        loadingAddShareholderPercentage: false,
        isShareholderPercentageAdded: false,
        addShareholderPercentageError: action.payload
      };
    }
    case SYNCHRONISE_WITH_JADIS_REQUEST: {
      return {
        ...state,
        loadingSynchroniseWithJADIS: true
      };
    }
    case SYNCHRONISE_WITH_JADIS_SUCCESS: {
      return {
        ...state,
        loadingSynchroniseWithJADIS: false,
        synchroniseWithJADISSuccess: action.payload
      };
    }
    case SYNCHRONISE_WITH_JADIS_ERROR: {
      return {
        ...state,
        loadingSynchroniseWithJADIS: false,
        synchroniseWithJADISError: action.payload
      };
    }

    case FETCH_VIRS_PERSONS_LIST_REQUEST:
      return {
        ...state,
        loadingVirsPersonsList: true
      };
    case FETCH_VIRS_PERSONS_LIST_SUCCESS:
      return {
        ...state,
        loadingVirsPersonsList: false,
        virsPersonsList: action.payload,
        noValidShareholderParent: action.payload.length === 0
      };
    case FETCH_VIRS_PERSONS_LIST_ERROR:
      return {
        ...state,
        loadingVirsPersonsList: false,
        virsPersonsListError: action.payload
      };

    case CREATE_SHAREHOLDER_REQUEST: {
      return { ...state, loadingCreateShareholder: true };
    }
    case CREATE_SHAREHOLDER_SUCCESS: {
      return {
        ...state,
        loadingCreateShareholder: false,
        isShareholderCreated: true,
        createShareholderSuccess: action.payload,
        addingShareholderDate: false
      };
    }
    case CREATE_SHAREHOLDER_ERROR: {
      return {
        ...state,
        loadingCreateShareholder: false,
        isShareholderCreated: false,
        createShareholderSuccess: undefined,
        createShareholderError: action?.payload?.data
      };
    }
    case CREATE_SHAREHOLDER_STATE_RESET: {
      return {
        ...state,
        loadingCreateShareholder: false,
        isShareholderCreated: false,
        createShareholderSuccess: undefined,
        createShareholderError: undefined
      };
    }

    case SET_UPDATE_SHAREHOLDER_ID: {
      return {
        ...state,
        shareholderToEditId: action.payload
      };
    }
    case UNSET_UPDATE_SHAREHOLDER_ID: {
      return {
        ...state,
        shareholderToEditId: undefined
      };
    }

    case UPDATE_SHAREHOLDER_REQUEST: {
      return { ...state, loadingUpdateShareholder: true };
    }
    case UPDATE_SHAREHOLDER_SUCCESS: {
      return {
        ...state,
        loadingUpdateShareholder: false,
        isShareholderUpdated: true,
        updateShareholderSuccess: action.payload
      };
    }
    case UPDATE_SHAREHOLDER_ERROR: {
      return {
        ...state,
        loadingUpdateShareholder: false,
        isShareholderUpdated: false,
        updateShareholderError: action?.payload?.data
      };
    }
    case UPDATE_SHAREHOLDER_STATE_RESET: {
      return {
        ...state,
        loadingUpdateShareholder: false,
        isShareholderUpdated: false,
        updateShareholderSuccess: undefined,
        updateShareholderError: undefined,
        shareholderToEditId: undefined
      };
    }
    case SET_LAST_SIGNED_DATE: {
      return {
        ...state,
        lastSignedDate: action.payload
      };
    }
    /** EXPANDING SHAREHOLDER */
    case SET_EXPAND_SHAREHOLDER: {
      return {
        ...state,
        expandedShareholders: action.payload.uniqueShareholderId
          ? {
              ...state.expandedShareholders,
              [action.payload.uniqueShareholderId + '__' + action.payload.index]:
                !state.expandedShareholders[
                  action.payload.uniqueShareholderId + '__' + action.payload.index
                ]
            }
          : { ...state.expandedShareholders },
        expandedPersons: action.payload.uniquePersonId
          ? {
              ...state.expandedPersons,
              [action.payload.uniquePersonId + '__' + action.payload.index]:
                !state.expandedPersons[action.payload.uniquePersonId + '__' + action.payload.index]
            }
          : { ...state.expandedPersons }
      };
    }
    case SET_EXPAND_PARENTS: {
      return {
        ...state,
        expandedShareholders: action.payload.shareholderPath
          ? setExpandParentsPath(state.expandedShareholders, action.payload.shareholderPath)
          : { ...state.expandedShareholders },
        expandedPersons: action.payload.personIdPath
          ? setExpandParentPersons(
              state.expandedPersons,
              action.payload.personIdPath,
              action.payload.index
            )
          : { ...state.expandedPersons }
      };
    }
    case SET_EXPAND_FILTERED_PARENTS: {
      return {
        ...state,
        expandedShareholders: action.payload.filteredShareholderPaths,
        expandedPersons: action.payload.filteredPersonIds
      };
    }
    case RESET_EXPAND_SHAREHOLDERS: {
      return {
        ...state,
        expandedShareholders: {},
        expandedPersons: {}
      };
    }

    case RESET_SHAREHOLDERS_DATA: {
      return {
        ...state,
        shareholdersSelectedDateData: undefined,
        shareholdersSelectedDateDataError: undefined,
        shareholdersData: undefined,
        shareholdersDataError: undefined,
        virsPersonsList: undefined,
        showUnsigned: initialState.showUnsigned,
        showUnsignedSwitchEnabled: initialState.showUnsignedSwitchEnabled
      };
    }

    /** SET LIST OF ALL SHAREHOLDERS */
    case SET_ALL_SHAREHOLDERS: {
      return {
        ...state
      };
    }

    /** FILTER UNSIGNED */
    case FILTER_UNSIGNED_SHAREHOLDERS_TOGGLED: {
      return {
        ...state,
        showUnsigned: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
