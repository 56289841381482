import React, { useEffect, useReducer, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  useProfessionalMisconductInstTableDispatch,
  useProfessionalMisconductInstTableState
} from '../Context';
import { ApplicationState } from '../../../store';
import AllowedTo from '../../AllowedTo';
import { Roles, VirsSearchData } from '../../../store/virsis/dataTypes';

import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { newMisconductReducer } from './rowState/newMisconductReducer';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import {
  DeterminedMisconductState,
  initialMisconductNewRowState,
  ProfessionalMisconductOutletState
} from './rowState/misconductInitialStateAndTypes';

import {
  ProfessionalMisconductRecord,
  ProfessionalMisconductSanctionType,
  ProfessionalMisconductType
} from '../../../store/professionalMisconducts/professionalMisconductsTypes';
import {
  createProfessionalMisconduct,
  getProfessionalMisconductInstDataRequest,
  resetProfessionalMisconductCreatingState
} from '../../../store/professionalMisconducts/professionalMisconductsActions';
import {
  mapIntoProfessionalMisconductOutletsRecordObject,
  validateNewMisconductRecordState
} from './rowState/misconductRowReducerFunctions';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { DocumentNumberInputCell } from '../../../components/TableInputs/DocumentNumberInput';
import { searchVirsData } from '../../../store/virsis/actions';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { getOutletOption } from '../../../components/TableInputs/Utilities';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { hasRole } from '../../../utils/roleHelper';
import { InstitutionSelectionDialog } from '../../../components/Dialogs/InstitutionSelectionDialog';
import { ROLE_CODE } from '../../../utils/tableTypes';

export const MisconductInstRowContainerNew: React.FC = () => {
  const [rowState, rowDispatch] = useReducer(newMisconductReducer, initialMisconductNewRowState);

  const { state: tableState } = useProfessionalMisconductInstTableState();

  const {
    virsis: { virsSearchResults, currentUser },
    classifiers: { enterpriseTypes, fictitiousOutlet },
    professionalMisconductData: {
      professionalMisconductTypes,
      professionalMisconductSanctionTypes,
      isProfessionalMisconductCreated,
      creatingProfessionalMisconductError,
      creatingProfessionalMisconduct
    }
  } = useSelector((state: ApplicationState) => state);

  const userCanAssignInstitution =
    currentUser &&
    hasRole(currentUser, Roles.ROLE_ZEIT_EDIT) &&
    hasRole(currentUser, Roles.ROLE_LRTK_EDIT);

  let selectedVirs: VirsSearchData | undefined;
  if (virsSearchResults) {
    [selectedVirs] = virsSearchResults;
  }

  const reduxDispatch = useDispatch();

  function openFindVirsDialog() {
    rowDispatch({ type: 'VIRS_INPUT_CLICKED' });
    tableDispatch({ type: 'RESET_COMPANY_CODE' });
  }

  function useSelectedVirs(virs: VirsSearchData) {
    rowDispatch({
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
      virs
    });
  }

  function closeFindVirsDialog() {
    rowDispatch({
      type: 'FIND_VIRS_DIALOG_CLOSED'
    });
  }

  function setDecisionDate(decisionDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'DECISION_DATE_CHANGED', decisionDate });
  }

  function changeDocumentNumber(documentNumber: string) {
    rowDispatch({ type: 'DOCUMENT_NUMBER_CHANGED', documentNumber });
  }

  function addOutlet() {
    rowDispatch({ type: 'OUTLET_FIELD_ADDED' });
  }

  function changeOutlet(outletIndex: number) {
    return (selectedValue: OutletShortData | null) => {
      rowDispatch({
        type: 'OUTLET_CHANGED',
        outlet: selectedValue,
        outletIndex
      });
    };
  }

  function removeOutlet(outletIndex: number) {
    return () => rowDispatch({ type: 'OUTLET_FIELD_REMOVED', outletIndex });
  }

  function addMisconduct(outletIndex: number) {
    return () => {
      rowDispatch({ type: 'MISCONDUCT_FIELD_ADDED', outletIndex });
    };
  }

  function changeMisconduct(outletIndex: number, misconductIndex: number) {
    return (selectedValue: ProfessionalMisconductType | null) => {
      rowDispatch({
        type: 'MISCONDUCT_CHANGED',
        misconduct: selectedValue,
        outletIndex,
        misconductIndex
      });
    };
  }

  function removeMisconduct(outletIndex: number, misconductIndex: number) {
    return () =>
      rowDispatch({
        type: 'MISCONDUCT_FIELD_REMOVED',
        outletIndex,
        misconductIndex
      });
  }

  function addSanction(outletIndex: number, misconductIndex: number) {
    return () =>
      rowDispatch({
        type: 'SANCTION_FIELD_ADDED',
        outletIndex,
        misconductIndex
      });
  }

  function changeSanction(outletIndex: number, misconductIndex: number, sanctionIndex: number) {
    return (selectedValue: ProfessionalMisconductSanctionType | null) => {
      rowDispatch({
        type: 'SANCTION_CHANGED',
        sanction: selectedValue,
        outletIndex,
        misconductIndex,
        sanctionIndex
      });
    };
  }

  function removeSanction(outletIndex: number, misconductIndex: number, sanctionIndex: number) {
    return () =>
      rowDispatch({
        type: 'SANCTION_FIELD_REMOVED',
        outletIndex,
        misconductIndex,
        sanctionIndex
      });
  }

  function setValidFromDate(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_FROM_CHANGED', validFrom });
  }

  function setValidToDate(validTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_TO_CHANGED', validTo });
  }

  const totalRowSpan = () => {
    let rowSpan = 0;
    rowState.outlets.forEach((outlet) => {
      outlet.misconducts.forEach((misconduct) => {
        rowSpan += misconduct.sanctions.length;
      });
    });
    return rowSpan;
  };

  const outletRowSpan = (outlet: ProfessionalMisconductOutletState) => {
    let rowSpan = 0;
    outlet.misconducts.forEach((misconduct: DeterminedMisconductState) => {
      rowSpan += misconduct.sanctions.length;
    });
    return rowSpan;
  };

  const { dispatch: tableDispatch } = useProfessionalMisconductInstTableDispatch();

  function cancelCreating() {
    tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
  }

  const [isOpen, setOpen] = useState(false);

  function handleCreateMisconductRecord() {
    rowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateNewMisconductRecordState(rowState).createRecordConfirmationOn) {
      if (userCanAssignInstitution) {
        setOpen(true);
      } else {
        createMisconductRecord();
      }
    }
  }

  function createMisconductRecord(roleCode?: number) {
    setOpen(false);
    rowDispatch({ type: 'START_LOADING' });
    const paymentRecord: ProfessionalMisconductRecord = {
      virsId: rowState.virs.value ? rowState.virs.value.virId : null,
      decisionDate: rowState.decisionDate.value
        ? rowState.decisionDate.value.format('L').toString()
        : '',
      documentNumber: rowState.documentNumber.value ? rowState.documentNumber.value : '',
      professionalMisconductOutlets: mapIntoProfessionalMisconductOutletsRecordObject(rowState),
      validFrom: rowState.validFrom.value ? rowState.validFrom.value.format('L').toString() : '',
      validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : '',
      roleNumber: roleCode || null
    };
    reduxDispatch(createProfessionalMisconduct(paymentRecord));
  }

  useEffect(() => {
    if (isProfessionalMisconductCreated) {
      rowDispatch({ type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
      reduxDispatch(resetProfessionalMisconductCreatingState());
      reduxDispatch(getProfessionalMisconductInstDataRequest());
      tableDispatch({ type: 'CLOSE_CREATING_RECORD_CLICKED' });
    }
  }, [tableDispatch, reduxDispatch, isProfessionalMisconductCreated]);

  useEffect(() => {
    if (creatingProfessionalMisconductError) rowDispatch({ type: 'STOP_LOADING' });
  }, [creatingProfessionalMisconductError]);

  useEffect(() => {
    rowDispatch({
      type: 'NEW_RECORD_STATE_INITIALIZED',
      misconductTypes: professionalMisconductTypes || [],
      sanctionTypes: professionalMisconductSanctionTypes || [],
      fictitiousOutlet: fictitiousOutlet || []
    });
  }, [professionalMisconductTypes, professionalMisconductSanctionTypes, fictitiousOutlet]);

  useEffect(() => {
    if (!selectedVirs && tableState.companyCode) {
      rowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' });
      reduxDispatch(searchVirsData({ personCode: tableState.companyCode, onlySigned: true }));
    }
    if (selectedVirs && !rowState.showFindVirsDialog && tableState.companyCode) {
      rowDispatch({ type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED', virs: selectedVirs });
    }
  }, [
    reduxDispatch,
    virsSearchResults,
    selectedVirs,
    tableState.companyCode,
    rowState.showFindVirsDialog
  ]);

  const rows: JSX.Element[] = [];

  const canAsignRole =
    (currentUser &&
      hasRole(currentUser, Roles.ROLE_ZEIT_EDIT) &&
      hasRole(currentUser, Roles.ROLE_LRTK_EDIT)) ||
    (currentUser && currentUser && hasRole(currentUser, Roles.ROLE_ADMIN_ALL));

  let rowIndex = 0;
  rowState.outlets.forEach((outlet, outletIndex) => {
    outlet.misconducts.forEach((misconduct, misconductIndex) => {
      misconduct.sanctions.forEach((sanction, sanctionIndex) => {
        rows.push(
          <TableRow key={`new-misconduct-${rowIndex}`}>
            {rowIndex === 0 && (
              <>
                {tableState.columnsDisplayStatus.virsName && (
                  <VirsInputCell
                    openSearchDialog={openFindVirsDialog}
                    state={rowState.virs}
                    rowSpan={totalRowSpan()}
                  />
                )}
                {tableState.columnsDisplayStatus.decisionDate && (
                  <DateInputImprovedCell
                    state={rowState.decisionDate}
                    setDate={setDecisionDate}
                    isRequired
                    rowSpan={totalRowSpan()}
                  />
                )}
                {tableState.columnsDisplayStatus.documentNumber && (
                  <DocumentNumberInputCell
                    setValue={changeDocumentNumber}
                    inputState={rowState.documentNumber}
                    rowSpan={totalRowSpan()}
                    isRequired
                  />
                )}
              </>
            )}
            {misconductIndex === 0 &&
              sanctionIndex === 0 &&
              tableState.columnsDisplayStatus.outletName && (
                <DropdownInputCell
                  selectValue={changeOutlet(outletIndex)}
                  dropdownState={rowState.outlets[outletIndex].outlet}
                  getOptionLabel={(option) =>
                    `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
                  }
                  getOptionSelected={(option, value) =>
                    value ? option.outletId === value.outletId : false
                  }
                  addUnit={addOutlet}
                  removeUnit={removeOutlet(outletIndex)}
                  showRemoveButton={rowIndex !== 0}
                  rowSpan={outletRowSpan(outlet)}
                  renderOption={getOutletOption}
                />
              )}
            {sanctionIndex === 0 && tableState.columnsDisplayStatus.professionalMisconduct && (
              <DropdownInputCell
                selectValue={changeMisconduct(outletIndex, misconductIndex)}
                dropdownState={
                  rowState.outlets[outletIndex].misconducts[misconductIndex].misconduct
                }
                getOptionLabel={(misc) => misc.misconductTypeName}
                getOptionSelected={(option, value) =>
                  value ? option.misconductTypeId === value.misconductTypeId : false
                }
                addUnit={addMisconduct(outletIndex)}
                removeUnit={removeMisconduct(outletIndex, misconductIndex)}
                showRemoveButton={misconductIndex !== 0}
                rowSpan={misconduct.sanctions.length}
              />
            )}
            {tableState.columnsDisplayStatus.sanction && (
              <DropdownInputCell
                selectValue={changeSanction(outletIndex, misconductIndex, sanctionIndex)}
                dropdownState={
                  rowState.outlets[outletIndex].misconducts[misconductIndex].sanctions[
                    sanctionIndex
                  ].sanction
                }
                getOptionLabel={(sanct) => sanct.sanctionTypeName}
                getOptionSelected={(option, value) =>
                  value ? option.sanctionTypeId === value.sanctionTypeId : false
                }
                addUnit={addSanction(outletIndex, misconductIndex)}
                removeUnit={removeSanction(outletIndex, misconductIndex, sanctionIndex)}
                showRemoveButton={sanctionIndex !== 0}
                rowSpan={1}
              />
            )}
            {rowIndex === 0 && (
              <>
                {tableState.columnsDisplayStatus.validFrom && (
                  <DateInputImprovedCell
                    rowSpan={totalRowSpan()}
                    state={rowState.validFrom}
                    setDate={setValidFromDate}
                    isRequired
                  />
                )}
                {tableState.columnsDisplayStatus.validTo && (
                  <DateInputImprovedCell
                    rowSpan={totalRowSpan()}
                    state={rowState.validTo}
                    setDate={setValidToDate}
                    allowFutureDate
                  />
                )}
                <TableCell align="left" rowSpan={totalRowSpan()}>
                  <AllowedTo roles={[Roles.ROLE_ZEIT_EDIT, Roles.ROLE_LRTK_EDIT]}>
                    <SaveAndCancelActions
                      handleCancelButtonClick={cancelCreating}
                      handleConfirmationYes={handleCreateMisconductRecord}
                      isProcessing={rowState.loading}
                    />
                  </AllowedTo>
                </TableCell>
                <TableCell align="left" rowSpan={totalRowSpan()} />
              </>
            )}
          </TableRow>
        );
        rowIndex++;
      });
    });
  });

  return (
    <>
      {canAsignRole && (
        <InstitutionSelectionDialog
          onLrtkSubmit={() => {
            createMisconductRecord(ROLE_CODE.LRTK_EDIT);
          }}
          onZeitSubmit={() => {
            createMisconductRecord(ROLE_CODE.ZEIT_EDIT);
          }}
          onClose={() => setOpen(false)}
          isProcessing={creatingProfessionalMisconduct}
          open={isOpen}
        />
      )}
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={closeFindVirsDialog}
          closeAndContinueWithVirs={useSelectedVirs}
        />
      )}
      {rows}
    </>
  );
};
