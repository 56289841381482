import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { loadData, setFormOpen, State } from './store';
import { OutletGroupForm } from './OutletGroupForm';
import { OutletGroup } from './types';

export const OutletGroupTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const outletGroupTableDefinition: TableDefinition<Nullable<OutletGroup>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('outletGroupId', 'Kodas', (data) => data.outletGroupId),
            getStringMappedColumn(
              'outletGroupName',
              'VIP grupės pavadinimas Lt',
              (data) => data.outletGroupName,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getStringMappedColumn(
              'outletGroupNameEn',
              'VIP grupės pavadinimas En',
              (data) => data.outletGroupNameEn,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            )
          ]
        },
        {
          header: 'Galioja',
          columns: [
            getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              outletGroupId: data.outletGroupId,
              outletGroupName: data.outletGroupName,
              outletGroupNameEn: data.outletGroupNameEn,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              validTo: (data.validTo && new Date(data.validTo)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują VIP grupę'
      }
    };
  }, [dispatch]);
  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={outletGroupTableDefinition} {...dataState} />

      <OutletGroupForm />
    </>
  );
};
