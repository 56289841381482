import { call, put, takeLatest, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { ActionType } from 'typesafe-actions';
import { api, get } from '../../utils/axiosApi';
import {
  loadReportOptions,
  generateReportFile,
  setLoading,
  setError,
  setReportOptions
} from './store';
import { FormOption, FormOptions, initialReportSettings, ReportSettings } from './types';
import { getDateString } from '../../components/FormikFields/FormikFormDateField';
import { sendMessage } from '../../store/errorOrSuccessMessage/errorOrSuccessMessageAction';
import { ApplicationState } from '../../store';

interface OptionsData {
  id: string;
  name: string;
}

function mapDataToOptions(data: OptionsData[]): FormOption[] {
  return data.map(({ id, name }) => ({
    value: id,
    label: name
  }));
}

function* loadReportOptionsTask(action: ActionType<typeof loadReportOptions>) {
  yield put(setLoading(true));

  const options: FormOptions = yield select((state: ApplicationState) => state.report.options);

  yield put(setReportOptions({ ...options, reportSettings: initialReportSettings }));

  try {
    const { data: reportOptions }: { data: OptionsData[] } = yield call(get, 'reports/types');

    const selectedReport = action.payload.report || reportOptions[0].id;

    const { data: dataBlockOptions }: { data: OptionsData[] } = yield call(
      get,
      `reports/data-blocks/${selectedReport}`
    );

    const {
      data: optionsResponse
    }: {
      data: {
        name: string;
        logicalName: string;
        usedForFiltering: boolean;
        usedForSorting: boolean;
      }[];
    } = yield call(get, `reports/report-columns/${selectedReport}`);

    const tableOptions = optionsResponse.map((value) => ({
      ...value,
      id: value.logicalName
    }));

    const sortOptions = mapDataToOptions(
      tableOptions.filter(({ usedForSorting }) => usedForSorting)
    );

    const columnOptions = mapDataToOptions(
      tableOptions.filter(({ usedForFiltering }) => usedForFiltering)
    );

    const { data: fileOptions }: { data: OptionsData[] } = yield call(
      get,
      `reports/report-formats/${selectedReport}`
    );

    const { data: reportSettings }: { data: ReportSettings } = yield call(
      get,
      `reports/report-settings/${selectedReport}`
    );

    yield put(
      setReportOptions({
        reportOptions: mapDataToOptions(reportOptions),
        sortOptions,
        columnOptions,
        dataBlockOptions: mapDataToOptions(dataBlockOptions),
        fileOptions: mapDataToOptions(fileOptions),
        reportSettings
      })
    );

    yield put(setError(false));
  } catch (error) {
    yield put(setError(true));
  }

  yield put(setLoading(false));
}

function* generateReportFileTask({ payload: { input } }: ActionType<typeof generateReportFile>) {
  yield put(setLoading(true));

  try {
    const { data }: { data: Blob } = yield call(
      api.post,
      `reports/${input.report}`,
      {
        ...input,
        virsName: input.virsName || null,
        virsCode: input.virsCode || null,
        personBirthDateFilter: getDateString(input.personBirthDateFilter),
        toDate: getDateString(input.toDate),
        fromDate: getDateString(input.fromDate),
        dataBlock: input.dataBlock !== 'null' ? input.dataBlock : null
      },
      { responseType: 'blob' }
    );

    saveAs(data, `document.${input.fileFormat?.toLocaleLowerCase()}`);

    yield put(setError(false));
  } catch (error) {
    const { message }: { message: string } = JSON.parse(yield error.response.data.text());
    yield put(sendMessage('error', message));
  }

  yield put(setLoading(false));
}

export function* reportSaga() {
  yield takeLatest(loadReportOptions, loadReportOptionsTask);
  yield takeLatest(generateReportFile, generateReportFileTask);
}
