import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  error: string | undefined;
  colSpan: number;
}

export const TableErrorRow: React.FC<Props> = ({ error, colSpan: colspan }) => {
  return (
    <>
      {error && (
        <TableRow>
          <TableCell colSpan={colspan}>
            <Typography>{error}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
