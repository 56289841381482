export interface EditionPeriod {
  periodId: number;
  periodName: string;
  periodNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface EditionPeriodInput {
  periodId: number | null;
  periodName: string | null;
  periodNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: EditionPeriodInput = {
  periodId: null,
  periodName: '',
  periodNameEn: '',
  validFrom: null,
  validTo: null
};
