import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FormikFieldStyleProps } from './FieldStyles';
import { FormikProps } from 'formik';

interface Props<TData> extends FormikFieldStyleProps {
  styledClasses: Record<string, string>;
  error: string | undefined;
  formikKey: string;
  label?: string;
  values: string | undefined | null | number | File;
  setFieldValue: (id: string, value: File | null) => void;
  placeholder?: string;
  disabled?: boolean;
  field: keyof TData;
  formikProps?: FormikProps<TData>;
}

export const FormikFormFileField = <TData,>({
  styledClasses,
  error,
  formikKey,
  label,
  values,
  disabled,
  setFieldValue,
  placeholder,
  style,
  field,
  formikProps
}: Props<TData>) => {
  const file = formikProps?.values[field];
  const placeholderText = placeholder ? placeholder : 'Įkelkite failą .pdf formatu';
  return (
    <Box style={style?.container}>
      <Box className={styledClasses.fileCard}>
        <input
          disabled={disabled}
          accept={'pdf'}
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          name={formikKey}
          key={formikKey}
          type={'file'}
          onChange={(event) => {
            // @ts-ignore
            setFieldValue(formikKey, event?.currentTarget?.files[0]);
          }}
        />
        <Typography variant="h3" className={styledClasses.inputLabel}>
          {label}
        </Typography>
        {file ? (
          <Typography variant="h4" className={styledClasses.inputLabel}>
            {/*@ts-ignore}*/}
            {file?.name}
          </Typography>
        ) : (
          <Typography variant="h4" className={styledClasses.inputLabel}>
            {placeholderText}
          </Typography>
        )}
        {error && (
          <Typography variant="h5" className={styledClasses.inputLabel}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
