import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import ShareholdersSelectedDateTable from './Table';
import {
  ShareholdersSelectedDateTableState,
  initialShareholdersSelectedDateTableState,
  ShareholdersSelectedDateTableAction
} from './tableState/tableInitialStateAndTypes';
import { shareholdersSelectedDateTableReducer } from './tableState/tableReducer';

const ShareholdersSelectedDateTableStateContext = createContext<{
  state: ShareholdersSelectedDateTableState;
}>({
  state: initialShareholdersSelectedDateTableState
});

const ShareholdersSelectedDateTableDispatchContext = createContext<{
  dispatch: Dispatch<ShareholdersSelectedDateTableAction>;
}>({
  dispatch: () => {}
});

export const ShareholdersSelectedDateTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(
    shareholdersSelectedDateTableReducer,
    initialShareholdersSelectedDateTableState
  );
  return (
    <ShareholdersSelectedDateTableStateContext.Provider value={{ state }}>
      <ShareholdersSelectedDateTableDispatchContext.Provider value={{ dispatch }}>
        <ShareholdersSelectedDateTable />
      </ShareholdersSelectedDateTableDispatchContext.Provider>
    </ShareholdersSelectedDateTableStateContext.Provider>
  );
};

export function useShareholdersSelectedDateTableState() {
  return useContext(ShareholdersSelectedDateTableStateContext);
}
export function useShareholdersSelectedDateTableDispatch() {
  return useContext(ShareholdersSelectedDateTableDispatchContext);
}
