import { IntegratedSystemsAuditData } from '../../../store/audits/auditsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import { ColumnDisplayStatus } from '../../../utils/tableTypes';
import {
  IntegratedSystemsAuditsColumnHeader,
  IntegratedSystemsAuditsHeaderField
} from './tableInitialStateAndTypes';

export const getTableExportDefinition = (
  columnHeaders: IntegratedSystemsAuditsColumnHeader[],
  displayStatus: ColumnDisplayStatus<IntegratedSystemsAuditsHeaderField>
): TableExportDefinition<IntegratedSystemsAuditData> => {
  const { stringValueColumn } = getColumnBuilder<
    IntegratedSystemsAuditData,
    IntegratedSystemsAuditsHeaderField
  >(columnHeaders, displayStatus);

  return {
    title: 'Integracinių sistemų panaudojimo auditas',
    columnGroups: [
      {
        columns: [
          stringValueColumn('systemDataIsReceivedFrom'),
          stringValueColumn('systemDataIsSentTo'),
          stringValueColumn('serviceTypeName'),
          stringValueColumn('service'),
          stringValueColumn('resultCode'),
          stringValueColumn('requestDate'),
          stringValueColumn('responseDate')
        ]
      }
    ]
  };
};
