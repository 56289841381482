export const INITIALIZE_TABLE_STATE = '@professionalMisconduct/INITIALIZE_TABLE_STATE';
export const SHOW_STATUS_OUTDATED_TOGGLED = '@professionalMisconduct/SHOW_STATUS_OUTDATED_TOGGLED';
export const SHOW_STATUS_NOT_SIGNED_TOGGLED =
  '@professionalMisconduct/SHOW_STATUS_NOT_SIGNED_TOGGLED';
export const SHOW_STATUS_RELEVANT_TOGGLED = '@professionalMisconduct/SHOW_STATUS_RELEVANT_TOGGLED';
export const CUSTOM_FILTER_VALUE_CHANGED = '@professionalMisconduct/CUSTOM_FILTER_VALUE_CHANGED';
export const CUSTOM_FILTER_VALUE_RESET = '@professionalMisconduct/CUSTOM_FILTER_VALUE_RESET';
export const SORTING_CHANGED = '@professionalMisconduct/SORTING_CHANGED';
export const PAGE_SET = '@professionalMisconduct/PAGE_SET';
export const ROWS_PER_PAGE_SET = '@professionalMisconduct/ROWS_PER_PAGE_SET';
export const UPDATE_TEMP_DATA_STATE = '@professionalMisconduct/UPDATE_TEMP_DATA_STATE';
