import React from 'react';
import { CircularProgress, Container, Typography } from '@material-ui/core';

interface Props {
  isSynchronizingJADIS: boolean;
}

const CreateShareholderLoadingContainer: React.FC<Props> = ({ isSynchronizingJADIS }) => {
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        alignContent: 'center'
      }}
    >
      <Typography variant="h5">Saugomi duomenys...</Typography>
      <Typography>
        {isSynchronizingJADIS
          ? 'Šio JA duomenys gaunami iš JADIS, vyksta sinchronizacija su JADIS.'
          : undefined}
      </Typography>
      <CircularProgress
        style={{
          height: '30px',
          width: '30px',
          margin: '5px auto'
        }}
      />
    </Container>
  );
};

export default CreateShareholderLoadingContainer;
