import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  palette: {
    primary: {
      main: '#006FB2',
      light: '#50C9F3'
    },
    secondary: {
      main: '#DBEDFC',
      light: '#FFFFFF'
    },
    success: {
      main: '#03B079'
    },
    warning: {
      main: '#FEA900'
    },
    error: {
      main: '#F11A1A',
      dark: '#FF6B6B',
      light: '#ffc0cb'
    },
    info: {
      main: '#AEAEAE',
      light: '#E5E5E5',
      dark: '#637082'
    },
    text: {
      primary: '#172D46', // dark-blue almost black
      secondary: '#172D46'
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: 'Open Sans',
        fontSize: '1.625rem',
        fontWeight: 800,
        color: '#172D46'
      },
      h2: {
        fontFamily: 'Open Sans',
        fontSize: '1.375rem',
        fontWeight: 800,
        color: '#172D46'
      },
      h3: {
        fontFamily: 'Open Sans',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#172D46'
      },
      h4: {
        fontFamily: 'Open Sans',
        fontSize: '0.85rem',
        fontWeight: 600,
        color: '#637082'
      },
      h5: {
        fontFamily: 'Open Sans',
        fontSize: '0.85rem',
        fontWeight: 600
      },
      h6: {
        fontFamily: 'Open Sans',
        fontSize: '0.8rem',
        fontWeight: 400
      },
      subtitle1: {
        fontFamily: 'Open Sans',
        fontSize: '1.125rem',
        fontWeight: 800,
        color: '#172D46'
      },
      subtitle2: {
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#637082',
        lineHeight: '1rem'
      },
      caption: {
        fontFamily: 'Open Sans',
        fontSize: '0.9rem',
        fontWeight: 600,
        color: '#686868'
      },
      body1: {
        fontFamily: 'Open Sans',
        fontSize: '0.875rem',
        color: '#172D46',
        whiteSpace: 'pre-line'
      },
      body2: {
        color: '#172D46',
        fontFamily: 'Open Sans',
        fontSize: '0.875rem',
        fontWeight: 600,
        whiteSpace: 'pre-line'
      },
      gutterBottom: {
        marginBottom: '0.125em'
      }
    },
    MuiContainer: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 35,
          paddingRight: 35
        }
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiToolbar: {
      root: {
        paddingLeft: 0,
        paddingRight: 0
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
        '@media (min-width: 600px)': {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      regular: {
        [breakpoints.up('sm')]: {
          minHeight: '78px'
        }
      }
    },
    MuiTabs: {
      root: {
        maxWidth: '100%',
        margin: '0px 20px'
      },
      indicator: {
        backgroundColor: '#C4E2FC',
        height: 5
      }
    },
    MuiTab: {
      root: {
        textTransform: 'unset',
        minWidth: 'unset',
        '@media (min-width: 600px)': {
          minWidth: 'unset'
        },
        paddingLeft: 0,
        paddingRight: 0,
        margin: '0px 15px',
        minHeight: 78
      }
    },
    MuiIconButton: {
      root: {
        color: 'rgba(0, 0, 0, 0.55)'
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Open Sans',
        textTransform: 'unset',
        margin: 5,
        minWidth: '35px',
        height: 40
      },
      outlinedPrimary: {
        borderColor: '#006FB2',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#DBEDFC'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.875rem'
      }
    },
    MuiDivider: {
      root: {
        marginBottom: 10,
        marginTop: 10
      }
    },
    MuiTableRow: {
      root: {
        borderLeft: '1px solid #dddddd',
        borderRight: '1px solid #dddddd'
      },
      head: {
        backgroundColor: '#f7f7f7'
      }
    },
    MuiTableCell: {
      root: {
        borderLeft: '1px solid #dddddd',
        borderRight: '1px solid #dddddd',
        padding: 15
      },
      body: {
        padding: '8px 20px 6px',
        height: 56
      }
    },
    MuiTableSortLabel: {
      root: {
        width: '100%'
      },
      icon: {
        marginRight: 0,
        marginLeft: 'auto',
        fontSize: 16,
        paddingBottom: 2
      }
    },
    MuiSelect: {
      outlined: {
        height: 4,
        minWidth: 11
      },
      iconOutlined: {
        right: 0
      },
      select: {
        '&:focus': {
          backgroundColor: 'none'
        }
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Open Sans'
      },
      input: {
        fontSize: '0.825rem'
      }
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none'
        },
        padding: 0,
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)'
      },
      rounded: {
        borderRadius: 10,
        '&:last-child': {
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0
      }
    },
    MuiDialogContent: {
      root: {
        padding: '0px',
        '&:first-child': {
          paddingTop: 'none'
        }
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '0.8rem',
        fontWeight: 600,
        color: '#637082'
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
        '&:hover $notchedOutline': {
          borderWidth: '1px',
          borderColor: '#949494'
        },
        '&$focused $notchedOutline': {
          borderWidth: '1px'
        }
      },
      input: {
        padding: '10px 14px'
      },
      notchedOutline: {
        border: '1px solid #949494'
      }
    },
    MuiAccordionSummary: {
      content: {
        flexGrow: 'unset'
      }
    },
    MuiCircularProgress: {
      root: {
        margin: 'auto'
      }
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        fontSize: '0.875rem'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#AEAEAE'
      }
    }
  }
});
export default theme;
