export const INITIALIZE_TABLE_STATE = '@fundsReceived/INITIALIZE_TABLE_STATE';
export const SHOW_STATUS_OUTDATED_TOGGLED = '@fundsReceived/SHOW_STATUS_OUTDATED_TOGGLED';
export const SHOW_STATUS_NOT_SIGNED_TOGGLED = '@fundsReceived/SHOW_STATUS_NOT_SIGNED_TOGGLED';
export const SHOW_STATUS_RELEVANT_TOGGLED = '@fundsReceived/SHOW_STATUS_RELEVANT_TOGGLED';
export const CUSTOM_FILTER_VALUE_CHANGED = '@fundsReceived/CUSTOM_FILTER_VALUE_CHANGED';
export const CUSTOM_FILTER_VALUE_RESET = '@fundsReceived/CUSTOM_FILTER_VALUE_RESET';
export const SORTING_CHANGED = '@fundsReceived/SORTING_CHANGED';
export const PAGE_SET = '@fundsReceived/PAGE_SET';
export const ROWS_PER_PAGE_SET = '@fundsReceived/ROWS_PER_PAGE_SET';
export const UPDATE_TEMP_DATA_STATE = '@fundsReceived/UPDATE_TEMP_DATA_STATE';
