import React from 'react';
import { TableCell, TableRow, TableSortLabel, Typography } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  EnterpriseDataTableColumnsDisplayStatus,
  EnterpriseDataTableField
} from './tableState/initialStateAndTypes';
import { SortOrder } from '../../utils/tableTypes';

interface Props {
  order: SortOrder;
  sortBy: EnterpriseDataTableField;
  columnsDisplayStatus: EnterpriseDataTableColumnsDisplayStatus;
  setSorting: (column: EnterpriseDataTableField) => void;
}

export const EnterpriseTableHeaders: React.FC<Props> = ({
  columnsDisplayStatus: {
    enterpriseTypeName: showEnterpriseTypeName,
    validFrom: showValidFrom,
    validTo: showValidTo,
    authorName: showAuthorName
  },
  order,
  sortBy,
  setSorting
}) => {
  const createSortHandler = (column: EnterpriseDataTableField) => () => {
    setSorting(column);
  };

  const calculateSharedCellColSpan = (displayingColumns: boolean[]): number =>
    displayingColumns.filter((value) => value === true).length;

  return (
    <>
      <TableRow>
        {showEnterpriseTypeName && (
          <TableCell
            key="enterpriseTypeName"
            sortDirection={sortBy === 'enterpriseTypeName' ? order : false}
            align="left"
            variant="head"
            rowSpan={2}
            className="TableCellSortable"
            onClick={createSortHandler('enterpriseTypeName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'enterpriseTypeName' ? order : 'asc'}
              IconComponent={sortBy === 'enterpriseTypeName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Veiklos rūšis</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(showValidFrom || showValidTo) && (
          <TableCell
            key={2}
            align="left"
            colSpan={calculateSharedCellColSpan([showValidFrom, showValidTo])}
            variant="head"
          >
            <Typography variant="h4">Vykdoma</Typography>
          </TableCell>
        )}

        {showAuthorName && (
          <TableCell
            key="authorName"
            sortDirection={sortBy === 'authorName' ? order : false}
            align="left"
            variant="head"
            rowSpan={2}
            className="TableCellSortable"
            onClick={createSortHandler('authorName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'authorName' ? order : 'asc'}
              IconComponent={sortBy === 'authorName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Duomenis pateikė</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell key="actions" align="right" variant="head" rowSpan={2}>
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {showValidFrom && (
          <TableCell
            key="validFrom"
            sortDirection={sortBy === 'validFrom' ? order : false}
            align="left"
            variant="head"
            className="TableCellSortable"
            onClick={createSortHandler('validFrom')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'validFrom' ? order : 'asc'}
              IconComponent={sortBy === 'validFrom' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {showValidTo && (
          <TableCell
            key="validTo"
            sortDirection={sortBy === 'validTo' ? order : false}
            align="left"
            variant="head"
            className="TableCellSortable"
            onClick={createSortHandler('validTo')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'validTo' ? order : 'asc'}
              IconComponent={sortBy === 'validTo' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Iki</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};
