import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import { Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { DropdownStateGeneric, InputStateGeneric } from '../../utils/tableTypes';
import { AnnulmentType, AnnulRecord } from '../../store/classifiers/classifiersTypes';
import virsisTheme from '../../style/virsisTheme';
import { GenericSelectField } from '../FormikFields/GenericFormikWrappers/GenericSelectField';
import { GenericTextField } from '../FormikFields/GenericFormikWrappers/GenericTextField';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../Dialogs/Dialog/CloseButton';
import { Strings } from '../../utils/strings/Strings';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 35px 10px 35px'
    },
    divider: {
      marginRight: 35,
      marginLeft: 35
    },
    contentWrap: {
      padding: '6px 35px 24px 35px'
    },
    contentText: {
      color: virsisTheme.palette.text.primary,
      fontSize: '1rem'
    },
    label: {
      margin: '20px 0 10px 0'
    },
    actions: {
      minWidth: 250,
      padding: '10px 34px',
      height: 50,
      backgroundColor: '#F3F3F3'
    },
    annul: {
      backgroundColor: 'transparent',
      color: virsisTheme.palette.error.main,
      borderColor: virsisTheme.palette.error.main,
      padding: '10px 40px'
    },
    btnIcon: {
      marginRight: 11
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
  onErrorClose: () => void;
  onSubmit: (record: AnnulRecord) => void;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
  isError: boolean;
  isProcessing: boolean;
  errorMessage?: Error;
}

export const AnnulDocumentDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  annulmentComment,
  isError,
  isProcessing,
  annulmentType,
  errorMessage
}) => {
  const classes = useStyles();

  function generateTitle() {
    if (isProcessing) return 'Anuliuojama';
    if (isError) return 'Klaida';
    return 'Ar tikrai norite anuliuoti pasirašytą el. dokumentą?';
  }
  const title: string = generateTitle();

  function generateText() {
    if (isError) return errorMessage?.message;
    return 'Anuliuodami el. dokumentą pašalinsite visus šiuo dokumentu pateiktus duomenis. Šis veiksmas nėra grąžinamas.\nDuomenys, pateikti kituose el. dokumentuose, išliks nepakitę.';
  }
  const contentText: string | undefined = generateText();

  const initValues = { type: '', text: '' };
  const validationSchema = Yup.object().shape({
    text: Yup.string()
      .required('Komentaras privalomas')
      .max(255, 'Maksimalus komentaro ilgis yra 255 simboliu'),
    type: Yup.number().typeError(' ').required('Tipas privalomas')
  });
  const submit = ({ type, text }: FormikValues) => {
    const record: AnnulRecord = {
      annulmentTypeId: type,
      annulmentReason: text
    };
    onSubmit(record);
  };
  const options = annulmentType.options.map((option) => {
    return {
      value: option.annulmentTypeId,
      label: option.annulmentTypeName
    };
  });
  return (
    <Dialog open={open} maxWidth="md">
      <Formik
        validateOnChange={false}
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => submit(values)}
      >
        {({ ...formikProps }) => (
          <>
            <div className={classes.title}>
              <Typography variant="h2">{title}</Typography>
              {!isProcessing && <CustomCloseIconButton onClick={onClose} />}
            </div>
            <Divider className={classes.divider} />
            <DialogContent
              className={classes.contentWrap}
              style={isProcessing ? { display: 'flex', justifyContent: 'center' } : undefined}
            >
              {isProcessing ? (
                <CircularProgress />
              ) : (
                <DialogContentText className={classes.contentText}>{contentText}</DialogContentText>
              )}
              {!isProcessing && !isError && (
                <>
                  <Form>
                    <Typography variant="h3" className={classes.label}>
                      Pasirinkite dokumento anuliavimo priežastį:
                    </Typography>
                    <GenericSelectField
                      clearable={false}
                      label=""
                      formikProps={formikProps}
                      field="type"
                      options={options}
                      style={{
                        container: { width: '360px' }
                      }}
                    />
                    <Typography className={classes.label} variant="h3">
                      Įrašykite dokumento anuliavimo komentarą:
                    </Typography>
                    <GenericTextField
                      placeholder={annulmentComment.placeholder}
                      multiline
                      clearable
                      rowsCount={4}
                      label=""
                      formikProps={formikProps}
                      field="text"
                      style={{
                        container: { flex: '0 0 500px' }
                      }}
                    />
                  </Form>
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.actions}>
              {!isProcessing && (
                <>
                  <CloseDialogButton
                    label={isError ? 'Uždaryti' : Strings.button__cancel}
                    onClick={onClose}
                  />

                  {!isError && (
                    <Button
                      variant="outlined"
                      className={classes.annul}
                      onClick={formikProps.submitForm}
                    >
                      <BlockIcon className={classes.btnIcon} />
                      <Typography variant="h5">Anuliuoti dokumentą</Typography>
                    </Button>
                  )}
                </>
              )}
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
