import { put, takeEvery, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { saveAs } from 'file-saver';
import { FETCH_FILE_REQUEST } from './helpPageActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { fetchHelpFileError, fetchHelpFileRequest, fetchHelpFileSuccess } from './helpPageAction';
import { getHelpFile } from './helpPageApi';
import { helpPageTypes } from './types';

function* fetchHelpFileSaga(action: ActionType<typeof fetchHelpFileRequest>) {
  const { type, fileName } = action.payload;
  try {
    const { data } = yield call(getHelpFile, helpPageTypes[type]);
    const blob = new Blob([data], { type: 'application/pdf' });
    if (blob) {
      saveAs(blob, fileName);
    }
    yield put(fetchHelpFileSuccess());
  } catch (err) {
    yield put(fetchHelpFileError(err));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* helpPageSaga() {
  yield takeEvery(FETCH_FILE_REQUEST, fetchHelpFileSaga);
}

export default helpPageSaga;
