import {
  PaymentNewRowState,
  PaymentNewRowAction,
  initialPaymentNewRowState,
  PaymentRecordState
} from './paymentInitialStateAndTypes';
import {
  validateNewPaymentRecordState,
  setPaymentAmountAndValidate,
  addFileAndValidateDocuments,
  removeFileAndValidateDocuments,
  setPaymentVirsAndUpdateOptions
} from './paymentRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  setDocumentNumberAndValidate,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../utils/InputValueFunctions';

export const newPaymentReducer = (
  state: PaymentNewRowState,
  action: PaymentNewRowAction
): PaymentNewRowState => {
  switch (action.type) {
    case 'NEW_RECORD_STATE_INITIALIZED':
      return {
        ...state,
        decisionDate: {
          ...state.decisionDate,
          minDates: [MINIMAL_RECORD_DATE_DEFAULT],
          maxDates: [MAXIMAL_RECORD_DATE_TODAY]
        },
        paymentFiles: { ...state.paymentFiles, validated: false },
        paymentOutlets: {
          ...state.paymentOutlets,
          options: action.fictitiousOutlet
        },
        validFrom: {
          ...state.validFrom,
          minDates: [MINIMAL_RECORD_DATE_DEFAULT],
          maxDates: [
            {
              id: 'validTo',
              date: null,
              text: 'Data negali būti vėlesnė už įrašo galiojimo pabaigos datą'
            }
          ]
        },
        validTo: {
          ...state.validTo,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'validFrom',
              date: null,
              text: 'Data negali būti ankstesnė už įrašo galiojimo pradžios datą'
            }
          ],
          maxDates: []
        }
      };
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      if (action.virs.hasUnclosedPayment) return { ...state, showUnclosedPaymentDialog: true };
      return {
        ...state,
        ...setPaymentVirsAndUpdateOptions(state as unknown as PaymentRecordState, action.virs),
        showFindVirsDialog: false
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'DECISION_DATE_CHANGED':
      return {
        ...state,
        decisionDate: updateDateValue(action.decisionDate, state.decisionDate),
        validFrom: updateMinDates(action.decisionDate, 'decisionDate', state.validFrom)
      };
    case 'PAYMENT_FILE_ADDED':
      return {
        ...state,
        paymentFiles: addFileAndValidateDocuments(
          action.file,
          state.paymentFiles,
          state.paymentDocuments.length
        )
      };
    case 'PAYMENT_FILE_REMOVED':
      return {
        ...state,
        paymentFiles: removeFileAndValidateDocuments(
          action.fileId,
          state.paymentFiles,
          state.paymentDocuments.length
        )
      };
    case 'FILE_ADDING_WRONG_FORMAT':
      return {
        ...state,
        paymentFiles: {
          ...state.paymentFiles,
          error: true,
          errorMessage: action.errorMessage
        }
      };
    case 'PAYMENT_FILES_CUSTOM_NAME_CHANGED':
      return {
        ...state,
        customDocumentsName: setDocumentNumberAndValidate(
          action.filesCustomName,
          state.customDocumentsName
        )
      };
    case 'OUTLETS_CHANGED':
      return {
        ...state,
        paymentOutlets: {
          ...state.paymentOutlets,
          values: action.outlets,
          validated: action.outlets.length > 0,
          error: !(action.outlets.length > 0),
          helperText: action.outlets.length > 0 ? '' : 'Būtinos VIP'
        }
      };
    case 'PAYMENT_AMOUNT_CHANGED':
      return {
        ...state,
        paymentAmount: setPaymentAmountAndValidate(action.paymentAmount)
      };
    case 'VALID_FROM_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        decisionDate: updateMaxDates(action.validFrom, 'validFrom', state.decisionDate),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'VALID_TO_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'CREATE_RECORD_CLICKED':
      return validateNewPaymentRecordState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return initialPaymentNewRowState;
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'CLOSE_UNCLOSED_PAYMENT_DIALOG':
      return {
        ...state,
        showUnclosedPaymentDialog: false,
        showFindVirsDialog: false
      };
    default:
      return state;
  }
};
