export interface OutletCategory {
  categoryTypeId: number;
  categoryTypeName: string;
  categoryTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface OutletCategoryInput {
  categoryTypeId: number | null;
  categoryTypeName: string | null;
  categoryTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: OutletCategoryInput = {
  categoryTypeId: null,
  categoryTypeName: '',
  categoryTypeNameEn: '',
  validFrom: null,
  validTo: null
};
