import { get, post, put, axiosDelete, getWithQuery } from '../../utils/axiosApi';
import { OutletRecord } from './outletsTypes';

export const getOutletData = (virId: number | undefined) => get(`/outlet-data/${virId}`);

export const postCreateOutlet = (virId: number | undefined, record: OutletRecord) => {
  return post(`/outlet-data/${virId}`, record);
};

export const postRenewOutlet = (
  virId: number | undefined,
  virOutletControlId: number,
  record: OutletRecord
) => {
  return post(`/outlet-data/${virId}/${virOutletControlId}/renew`, record);
};

export const putUpdateOutlet = (
  virId: number | undefined,
  virOutletControlId: number | null,
  record: OutletRecord
) => {
  return put(`/outlet-data/${virId}/${virOutletControlId}`, record);
};

export const deleteRemoveOutlet = (virId: number | undefined, virOutletControlId: number) => {
  return axiosDelete(`/outlet-data/${virId}/${virOutletControlId}`);
};

export const deleteOutlets = (outletIds: number[]) =>
  axiosDelete('/outlet-data/delete-outlets', outletIds);

export const getFindOutletOnLibisData = (title?: string, internationalNumber?: string) =>
  getWithQuery('/outlet-data/libis-lookup', {
    title,
    internationalNumber
  });
