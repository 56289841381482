import React from 'react';
import { Grid, makeStyles, TableCell } from '@material-ui/core';
import { OutletData } from '../../../store/outlets/outletsTypes';
import BtnOpenNewDoc from '../../../components/TableButtons/BtnOpenNewDoc';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';

const useStyles = makeStyles((theme) => ({
  extendeRowData: {
    padding: '15px 15px 15px 65px'
  },
  extendedRowItem: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontFamily: 'Open Sans'
  },
  extendedRowItemTitle: {
    display: 'block',
    minWidth: '220px',
    marginRight: '15px'
  },
  actions: {
    maxWidth: '200px'
  },
  outletName: {
    color: theme.palette.primary.main
  },
  outletNameContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface RowProps {
  record: OutletData;
  buttonLabel: string;
  redirectToRepresentatives: () => void;
  redirectToEditions: () => void;
  redirectToAdPrinting: () => void;
  redirectToCategories: () => void;
  rowStatus?: string;
}

export const OutletBasicRowExtension: React.FC<RowProps> = ({
  record,
  rowStatus,
  buttonLabel,
  redirectToRepresentatives,
  redirectToEditions,
  redirectToAdPrinting,
  redirectToCategories
}) => {
  const classes = useStyles();

  return (
    <RowStyleOnDifferentStatus
      status={rowStatus}
      key={`row-${record.virOutletControlId}-extension`}
    >
      <TableCell colSpan={8} style={{ padding: '0px', backgroundColor: '#f5f8ff' }}>
        <Grid container className={classes.extendeRowData}>
          <Grid container item className={classes.extendedRowItem}>
            <span className={classes.extendedRowItemTitle}>Už VIP turinį atsakingi asmenys</span>

            <BtnOpenNewDoc
              onClicked={redirectToRepresentatives}
              text={String(record.representativesCount ? record.representativesCount : 0)}
            />
            <BtnOpenNewDoc onClicked={redirectToRepresentatives} text={buttonLabel} />
          </Grid>

          {(record.editionsPeriodic || record.editionsNonPeriodic) && (
            <Grid container item className={classes.extendedRowItem}>
              <span className={classes.extendedRowItemTitle}>Tiražo duomenys</span>

              <BtnOpenNewDoc
                onClicked={redirectToEditions}
                text={String(record.circulationCount ? record.circulationCount : 0)}
              />

              <BtnOpenNewDoc onClicked={redirectToEditions} text={buttonLabel} />
            </Grid>
          )}

          {(record.mandatoryPeriodicEditions ||
            record.editionsPeriodic ||
            record.editionsNonPeriodic) && (
            <Grid container item className={classes.extendedRowItem}>
              <span className={classes.extendedRowItemTitle}>Reklamos spausdinimas</span>

              <BtnOpenNewDoc
                onClicked={redirectToAdPrinting}
                text={String(record.advertisementsCount ? record.advertisementsCount : 0)}
              />

              <BtnOpenNewDoc onClicked={redirectToAdPrinting} text={buttonLabel} />
            </Grid>
          )}

          <Grid container item className={classes.extendedRowItem}>
            <span className={classes.extendedRowItemTitle}>Kategorija</span>

            <BtnOpenNewDoc
              onClicked={redirectToCategories}
              text={String(record.categoriesCount ? record.categoriesCount : 0)}
            />

            <BtnOpenNewDoc onClicked={redirectToCategories} text={buttonLabel} />
          </Grid>
        </Grid>
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
