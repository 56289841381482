import React from 'react';
import { TableRow, TableCell, Typography, TableHead } from '@material-ui/core';
import { useAuditsTableDispatch, useAuditsTableState } from './Context';
import { AuditsHeaderField, AuditsTableState } from './tableState/tableInitialStateAndTypes';
import { getSortLabelPropsFactory } from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';

export const AuditsTableHeader: React.FC = () => {
  const { dispatch: tableDispatch } = useAuditsTableDispatch();
  const { state: tableState } = useAuditsTableState();

  const handleSetSortByColumn = (column: AuditsHeaderField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    AuditsTableState,
    typeof handleSetSortByColumn,
    AuditsHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.auditActionTypeName && (
          <TableSortingHeaderCell
            label="Veiksmo tipas"
            columnName="auditActionTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.actionUserFullName && (
          <TableSortingHeaderCell
            label="Naudotojas, kuris atliko veiksmą"
            columnName="actionUserFullName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.actionLegalPersonFullName && (
          <TableSortingHeaderCell
            label="Atstovaujamas juridinis asmuo"
            columnName="actionLegalPersonFullName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.actionDateTime && (
          <TableSortingHeaderCell
            label="Veiksmo atlikimo laikas"
            columnName="actionDateTime"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.relatedVirs && (
          <TableSortingHeaderCell
            label="Susijęs VIRS"
            columnName="relatedVirs"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.relatedOutlet && (
          <TableSortingHeaderCell
            label="Susijusi VIP"
            columnName="relatedOutlet"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.relatedOutletTypeName && (
          <TableSortingHeaderCell
            label="VIP rūšis"
            columnName="relatedOutletTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.relatedPersonFullName && (
          <TableSortingHeaderCell
            label="Susijęs asmuo"
            columnName="relatedPersonFullName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.ipAddress && (
          <TableSortingHeaderCell
            label="IP adresas"
            columnName="ipAddress"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.auditDetails && (
          <TableSortingHeaderCell
            label="Papildomi duomenys"
            columnName="auditDetails"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        <TableCell align="right" variant="head">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
