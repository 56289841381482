import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell, TextField } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { OutletRecord, OutletType, LibisSearchData } from '../../../store/outlets/outletsTypes';
import { ApplicationState } from '../../../store';
import { createOutlet, resetOutletCreatingState } from '../../../store/outlets/outletsActions';
import { newOutletReducer } from './rowState/outletReducerNew';
import { SearchLibisDialog } from './SearchLibisDialog/SearchLibisDialog';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { StringInputCell } from '../../../components/TableInputs/StringInput';
import { intialNewOutletRowState } from './rowState/outletRowStateAndTypes';
import BtnOpenNewDoc from '../../../components/TableButtons/BtnOpenNewDoc';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { useOutletsTableDispatch, useOutletsTableState } from '../Context';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { validatedNewOutletState } from './rowState/outletRowReducerFunctions';
import { VirsisYesNoString } from '../../../utils/tableTypes';

interface ContainerProps {
  enterprise: EnterpriseData;
  virsLegalRegistrationDate: string;
  virsLegalDeregistrationDate: string | null;
  showStatusNotSigned: boolean;
}

export const OutletRowContainerNew: React.FC<ContainerProps> = ({
  enterprise,
  virsLegalRegistrationDate,
  virsLegalDeregistrationDate,
  showStatusNotSigned
}) => {
  const [rowState, rowDispatch] = useReducer(newOutletReducer, intialNewOutletRowState);

  const { virsData } = useSelector((stateGlobal: ApplicationState) => stateGlobal.virsis);

  const { creatingOutlet, isOutletCreated, createdOutletId } = useSelector(
    (stateGlobal: ApplicationState) => stateGlobal.outletData
  );

  const { outletGroups } = useSelector((stateGlobal: ApplicationState) => stateGlobal.outletData);

  const reduxDispatch = useDispatch();

  const {
    state: { columnsDisplayStatus: columnsToDisplay }
  } = useOutletsTableState();
  const { dispatch: tableDispatch } = useOutletsTableDispatch();

  function setOutletName(name: string | null) {
    rowDispatch({ type: 'OUTLET_NAME_CHANGED', name });
  }

  function setOutletType(outletType: OutletType | null) {
    rowDispatch({ type: 'OUTLET_TYPE_CHANGED', outletType });
  }

  function setOutletEstablishedDate(established: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_ESTABLISHED_DATE_CHANGED', established });
  }

  function setOutletControlledFrom(controlledFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_CONTROLLED_FROM_CHANGED', controlledFrom });
  }

  function setOutletControlledTo(controlledTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_CONTROLLED_TO_CHANGED', controlledTo });
  }

  function setOutletInternationalNumber(value: string | null) {
    rowDispatch({ type: 'OUTLET_INTERNATIONAL_NUMBER_CHANGED', value });
  }

  function createNewRecord() {
    const message = `VIP įrašas išsaugotas. ${
      !showStatusNotSigned ? 'Įrašas matomas įjungus filtrą "Nepasirašyti"' : ''
    }`;
    rowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validatedNewOutletState(rowState).createRecordConfirmationOn) {
      const newOutlet: OutletRecord = {
        enterpriseId: enterprise.enterpriseId,
        outletTypeId: rowState.outletType.value ? rowState.outletType.value.outletTypeId : null,
        outletName: rowState.name.value,
        isbn: rowState.ISBN || rowState.internationalNumber.value,
        issn: rowState.ISSN,
        establishedDate: rowState.established.value ? rowState.established.value.format('L') : null,
        controlledFrom: rowState.controlledFrom.value
          ? rowState.controlledFrom.value.format('L')
          : null,
        controlledTo: rowState.controlledTo.value ? rowState.controlledTo.value.format('L') : null
      };
      if (virsData) {
        reduxDispatch(createOutlet(virsData.virsId, newOutlet, message));
      }
    }
  }

  function createRecordCancelled() {
    rowDispatch({ type: 'CLOSE_NEW_RECORD_CLICKED' });
    tableDispatch({ type: 'CANCEL_ADDING_OUTLET_CLICKED' });
  }

  function openLibisDialog() {
    rowDispatch({ type: 'LIBIS_SEARCH_OPENED' });
  }

  function closeLibisDialog() {
    rowDispatch({ type: 'LIBIS_SEARCH_CLOSED' });
  }

  function toggleLibisOutletSelection(outlet: LibisSearchData) {
    rowDispatch({ type: 'LIBIS_OUTLET_CLICKED', outlet });
  }

  function populateNewRecordWithLibisOutletData() {
    rowDispatch({ type: 'POPULATE_RECORD_WITH_LIBIS_DATA_CLICKED' });
  }

  useEffect(() => {
    if (!isOutletCreated) return;
    tableDispatch({ type: 'CANCEL_ADDING_OUTLET_CLICKED' });
    if (createdOutletId) {
      tableDispatch({ type: 'SET_NEWEST_RECORD_ID', virOutletControlId: createdOutletId });
    }
    reduxDispatch(resetOutletCreatingState());
  }, [createdOutletId, isOutletCreated, reduxDispatch, tableDispatch]);

  useEffect(() => {
    rowDispatch({
      type: 'NEW_OUTLET_ROW_INITIALIZED',
      enterprise,
      outletGroups: outletGroups || [],
      registrationDate: virsLegalRegistrationDate,
      deregistrationDate: virsLegalDeregistrationDate
    });
  }, [outletGroups, virsLegalDeregistrationDate, virsLegalRegistrationDate, enterprise]);

  const outletUsesLibis: boolean = rowState?.outletType.value?.usesLibis === VirsisYesNoString.YES;

  return (
    <>
      {rowState.showLibisSearch && outletUsesLibis && (
        <SearchLibisDialog
          dialogOpen={rowState.showLibisSearch}
          closeDialog={closeLibisDialog}
          selectedOutlet={rowState.selectedLibisOutlet}
          toggleLibisOutletSelection={toggleLibisOutletSelection}
          populateNewRecordWithLibisData={populateNewRecordWithLibisOutletData}
        />
      )}
      <RowStyleOnDifferentStatus>
        {columnsToDisplay.outletTypeName && (
          <DropdownInputCell
            selectValue={setOutletType}
            dropdownState={rowState.outletType}
            getOptionLabel={(outl) => outl.outletTypeName}
            getOptionSelected={(option, value) => option.outletTypeId === value.outletTypeId}
          />
        )}
        {columnsToDisplay.outletName && (
          <>
            {outletUsesLibis && (
              <StringInputCell
                outlined
                inputState={rowState.name}
                setValue={setOutletName}
                helperItem={<BtnOpenNewDoc onClicked={openLibisDialog} text="Ieškoti LIBIS" />}
              />
            )}
            {!outletUsesLibis && (
              <StringInputCell outlined setValue={setOutletName} inputState={rowState.name} />
            )}
          </>
        )}
        {columnsToDisplay.outletGroupName && (
          <TableCell>
            <TextField
              variant="outlined"
              value={rowState.outletGroup ? rowState.outletGroup.outletGroupName : ''}
            />
          </TableCell>
        )}
        {columnsToDisplay.establishedDate && (
          <DateInputImprovedCell
            state={rowState.established}
            setDate={setOutletEstablishedDate}
            isRequired
          />
        )}
        {columnsToDisplay.controlledFrom && (
          <DateInputImprovedCell
            state={rowState.controlledFrom}
            setDate={setOutletControlledFrom}
            isRequired
          />
        )}
        {columnsToDisplay.controlledTo && (
          <DateInputImprovedCell state={rowState.controlledTo} setDate={setOutletControlledTo} />
        )}
        {columnsToDisplay.internationalNumber && (
          <StringInputCell
            outlined
            isDisabled={!outletUsesLibis}
            inputState={rowState.internationalNumber}
            setValue={setOutletInternationalNumber}
          />
        )}
        <TableCell>
          <SaveAndCancelActions
            handleConfirmationYes={createNewRecord}
            isProcessing={creatingOutlet}
            handleCancelButtonClick={createRecordCancelled}
          />
        </TableCell>
      </RowStyleOnDifferentStatus>
    </>
  );
};
