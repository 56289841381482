import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import {
  ProvidedColumnsDisplay,
  ProvidedDataTableColumn,
  ProvidedDataTableField
} from './tableState/tableInitialStateAndTypes';
import { ProvidedDataVirs } from '../../store/providedData/providedDataTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { getStatus } from '../../store/document/documentTypes';

interface Props {
  columnParams: ProvidedDataTableColumn[];
  columnsDisplayStatus: ProvidedColumnsDisplay;
  onCustomFilterChange: (column: ProvidedDataTableField, value: string | null) => void;
  tableData: ProvidedDataVirs[];
}

const ProvidedDataTableFilterRow: React.FC<Props> = ({
  columnParams,
  columnsDisplayStatus,
  onCustomFilterChange,
  tableData
}) => {
  function getUniqueOptions(
    data: ProvidedDataVirs[],
    objectField: ProvidedDataTableField
  ): string[] {
    const editedData = data?.map((record) => ({
      ...record,
      documentStatus: getStatus(record.documentStatus.id)
    }));
    const allValues = editedData
      .filter((record) => record[objectField] && record[objectField] !== '')
      .map((record) => record[objectField]);
    return Array.from(new Set(allValues));
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnParams
          .filter((header) => columnsDisplayStatus[header.id])
          .map((header) => (
            <TableCustomFilterCellGeneric
              key={header.id}
              id={header.id}
              type={header.type}
              setFilter={onCustomFilterChange}
              uniqueOptions={getUniqueOptions(tableData, header.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default ProvidedDataTableFilterRow;
