import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { AnnulRecord } from '../../store/classifiers/classifiersTypes';

import {
  annulProvidedDataLegal,
  fetchProvidedDataLegal,
  removeProvidedData
} from '../../store/providedData/providedDataActions';
import { ApplicationState } from '../../store';
import { withLoadedUser } from '../../containers/hoc/withLoadedUser';
import { fetchAnnulmentTypesRequest } from '../../store/classifiers/classifiersActions';
import { unsignedDataRemoveLegal } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { ProvidedDataHistoryTableLegalWithContext } from '../../containers/ProvidedDataTableLegal/Context';
import { ProvidedDataTableType } from '../../store/providedData/providedDataTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    lgWidth: {
      margin: 'auto',
      maxWidth: 1210
    },
    button: {
      padding: '0px 20px',
      marginRight: '10px',
      marginLeft: '0px'
    },
    buttonBox: {
      width: '100%',
      display: 'flex'
    },
    icon: {
      height: 30,
      padding: '0px 10px',
      borderRight: '1px solid white'
    },
    text: {
      color: theme.palette.secondary.light,
      padding: '1px 15px'
    },
    titleContainer: {
      marginTop: '20px'
    },
    buttonContainer: {
      padding: '10px',
      marginTop: '20px',
      marginBottom: '30px'
    }
  })
);

const LegalProvidedDataPage: React.FC = () => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  const {
    providedData: { loadingProvidedDataLegal, providedDataLegal, providedDataLegalError },
    classifiers: { annulmentTypes },
    unsignData: { verifySuccess }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    reduxDispatch(fetchProvidedDataLegal());
  }, [reduxDispatch, verifySuccess]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  return (
    <>
      <div className="data-page">
        <Container maxWidth="lg">
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle1" gutterBottom>
              Duomenų teikimas
            </Typography>
          </Box>
        </Container>
        <ProvidedDataHistoryTableLegalWithContext
          loading={loadingProvidedDataLegal}
          data={providedDataLegal}
          error={providedDataLegalError}
          annul={(documentStatusId: number, annulRecord: AnnulRecord) => {
            reduxDispatch(annulProvidedDataLegal(documentStatusId, annulRecord));
          }}
          remove={(documentStatusId: number) => {
            reduxDispatch(removeProvidedData(documentStatusId, ProvidedDataTableType.LEGAL));
          }}
          onRemoveUnsignedSuccess={() => reduxDispatch(fetchProvidedDataLegal())}
          removeUnsigned={() => {
            reduxDispatch(unsignedDataRemoveLegal());
          }}
        />
      </div>
    </>
  );
};

export default withLoadedUser(LegalProvidedDataPage);
