import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, Formik } from 'formik';
import { FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { pickBy, identity } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import { useDispatch, useSelector } from 'react-redux';
import { VirsSearchQuery } from '../../../store/virsis/dataTypes';
import { FormikFormTextField } from '../../../components/FormikFields/FormikFormTextField';
import { useDialogFormStyles } from '../../../components/FormikFields/FieldStyles';
import { FormikFormSelectField } from '../../../components/FormikFields/FormikFormSelectField';
import { EnterpriseType } from '../../../store/classifiers/classifiersTypes';
import { ApplicationState } from '../../../store';
import { fetchEnterpriseTypesRequest } from '../../../store/classifiers/classifiersActions';

interface Props {
  setQuerySize: (size: number) => void;
  searchVirs: (virsQuery: VirsSearchQuery) => void;
  isSearching: boolean;
}
export interface Values {
  personCode?: string;
  personFullName?: string;
  enterpriseType: EnterpriseType | null;
  outletName?: string;
}

export const VirsDataSubmissionPageForm: React.FC<Props> = ({
  setQuerySize,
  searchVirs,
  isSearching
}) => {
  const classes = useDialogFormStyles();

  const { enterpriseTypes } = useSelector((state: ApplicationState) => state.classifiers);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!enterpriseTypes) {
      dispatch(fetchEnterpriseTypesRequest());
    }
  }, [enterpriseTypes, dispatch]);

  const initValues: Partial<Values> = {
    personCode: '',
    personFullName: '',
    enterpriseType: null,
    outletName: ''
  };

  const clearForm = (setFieldValue: (id: string, value: string | null | undefined) => void) => {
    setFieldValue('personCode', '');
    setFieldValue('personFullName', '');
    setFieldValue('enterpriseType', null);
    setFieldValue('outletName', '');
  };

  const validationSchema = Yup.object().shape({
    personCode: Yup.number()
      .typeError('Įmonės kodas privalo būti tik iš skaičių')
      .notRequired()
      .test('personCode', 'Įmonės kodas privalo būti 9 simbolių ilgio', (val) => {
        return val?.toString().length === 9 || val === undefined;
      }),
    personFullName: Yup.string().min(4, 'Minimalus pavadinimo ilgis 4 simboliai'),
    enterpriseType: Yup.object().nullable(),
    outletName: Yup.string().nullable()
  });

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery: VirsSearchQuery = {
          personCode: values.personCode !== '' ? values.personCode : undefined,
          personFullName: values.personFullName !== '' ? values.personFullName : undefined,
          enterpriseTypeId: values.enterpriseType
            ? Number(values.enterpriseType.enterpriseTypeId)
            : undefined,
          outletName: values.outletName !== '' ? values.outletName : undefined,
          onlySigned: false
        };
        setQuerySize(Object.keys(pickBy(searchQuery, identity)).length);
        searchVirs(searchQuery);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Form>
            <FormGroup className={classes.formGroup}>
              <FormikFormTextField
                styledClasses={classes}
                label="Įmonės kodas"
                formikKey="personCode"
                values={values.personCode}
                error={errors.personCode}
                setFieldValue={setFieldValue}
              />
              <FormikFormTextField
                styledClasses={classes}
                label="Pavadinimas"
                formikKey="personFullName"
                values={values.personFullName}
                error={errors.personFullName}
                setFieldValue={setFieldValue}
              />
              <FormikFormSelectField
                styledClasses={classes}
                isRequired={false}
                options={enterpriseTypes || []}
                errors={errors.enterpriseType}
                formikKey="enterpriseType"
                setFieldValue={setFieldValue}
                value={values.enterpriseType}
                label="VIRS rūšis"
                isClearable
                getOptionLabel={(option?: EnterpriseType) =>
                  option ? option.enterpriseTypeName : ''
                }
              />
              <FormikFormTextField
                styledClasses={classes}
                label="VIP pavadinimas"
                formikKey="outletName"
                values={values.outletName}
                error={errors.outletName}
                setFieldValue={setFieldValue}
              />
              <Box className={classes.buttonBox}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isValidating || isSearching}
                  onClick={submitForm}
                  className={classes.button}
                  startIcon={<SearchIcon />}
                  style={{ marginRight: '45px' }}
                >
                  <Typography color="inherit">Ieškoti</Typography>
                </Button>
                <Button
                  variant="outlined"
                  disabled={isValidating || isSearching}
                  onClick={() => clearForm(setFieldValue)}
                  className={classes.button}
                  style={{ marginRight: '4px' }}
                  startIcon={<ClearIcon />}
                >
                  <Typography color="inherit">Išvalyti</Typography>
                </Button>
              </Box>
              {isSearching && <LinearProgress style={{ marginTop: '6px' }} />}
            </FormGroup>
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
