import { ActionType } from 'typesafe-actions';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  documentApproveError,
  documentApproveRequest,
  documentApproveSuccess,
  documentValidationError,
  documentValidationRequest,
  documentValidationSuccess,
  resetDocumentValidation
} from './documentActions';
import { DOCUMENT_APPROVE_REQUEST, DOCUMENT_VALIDATE_REQUEST } from './documentTypes';
import { approveDocument, validateDocument } from './documentApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';

function* documentValidationRequestSaga(action: ActionType<typeof documentValidationRequest>) {
  if (action.payload === 0) {
    yield put(resetDocumentValidation());
  } else
    try {
      const { data } = yield call(validateDocument, action.payload);
      yield put(documentValidationSuccess(data));
    } catch (err) {
      yield put(documentValidationError(err));
      yield put(sendMessage('error', err.response.data?.message));
    }
}

function* documentApproveRequestSaga(action: ActionType<typeof documentApproveRequest>) {
  try {
    yield call(approveDocument, action.payload);
    yield put(documentApproveSuccess());
  } catch (err) {
    yield put(documentApproveError(err));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* documentSaga() {
  yield takeEvery(DOCUMENT_VALIDATE_REQUEST, documentValidationRequestSaga);
  yield takeEvery(DOCUMENT_APPROVE_REQUEST, documentApproveRequestSaga);
}

export default documentSaga;
