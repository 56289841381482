import React, { ChangeEvent, useRef } from 'react';
import { IconButton, makeStyles, FormHelperText, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FileUnit } from './FileUnit';
import GoInputDataButton from '../../TableButtons/GoInputDataButton';
import { InputStateGeneric, VirsisFilesState } from '../../../utils/tableTypes';
import { PaymentDocumentData } from '../../../store/payments/paymentsTypes';
import theme from '../../../style/virsisTheme';
import { BasicTooltip } from '../../Tooltips/BasicTooltip';

interface Props {
  state: VirsisFilesState;
  customName: InputStateGeneric<string>;
  paymentDocuments?: PaymentDocumentData[];
  removePaymentDocument?: (id: number) => void;
  addFile: (inputFile: File) => void;
  removeFile: (id: number) => void;
  changeCustomName: (name: string) => void;
  setWrongFormatError: () => void;
}

const useStyles = makeStyles({
  linkLikeButton: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    textTransform: 'none'
  },
  linkLikeRemoveButton: {
    color: 'red',
    textDecoration: 'underline',
    textTransform: 'none'
  }
});

export const PaymentFilesInput: React.FC<Props> = ({
  state,
  customName,
  paymentDocuments,
  removePaymentDocument,
  addFile,
  removeFile,
  setWrongFormatError
}) => {
  const classes = useStyles();

  const addFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const acceptableFormat = 'application/pdf';

    if (event.currentTarget.files) {
      if (event.currentTarget.files[0].type === acceptableFormat) {
        addFile(event.currentTarget.files[0] as File);
      } else {
        setWrongFormatError();
      }
    }
  };

  const newInputRef = useRef<any>(null);

  const generateName = (indexNo: number, name: string) =>
    indexNo === 0 ? name : `${name}-${indexNo + 1}`;

  const removeExisting = (id: number) =>
    removePaymentDocument ? () => removePaymentDocument(id) : undefined;

  return (
    <>
      {paymentDocuments &&
        paymentDocuments.map((document, index) => (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            key={document.paymentDocumentId}
          >
            <Grid item>
              <GoInputDataButton
                disabled
                text={
                  customName.value ? generateName(index, customName.value) : document.documentNumber
                }
                style={{
                  label: {
                    maxWidth: '100px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                className={classes.linkLikeRemoveButton}
                onClick={removeExisting(document.paymentDocumentId)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      {state.files.map((file, index) => (
        <React.Fragment key={file.id}>
          <FileUnit
            file={file}
            changeFile={addFile}
            removeFile={removeFile}
            displayName={
              customName.value
                ? generateName(
                    paymentDocuments ? index + paymentDocuments.length : index,
                    customName.value
                  )
                : ''
            }
            sizeLimit={state.sizeLimit}
          />
        </React.Fragment>
      ))}
      <BasicTooltip text="Galimas tik PDF formatas">
        <div key="new">
          <input
            type="file"
            style={{ visibility: 'hidden', display: 'none' }}
            placeholder="Prisegti dokumentą"
            ref={newInputRef}
            onChange={addFileHandler}
            accept=".pdf"
          />
          <GoInputDataButton
            onClicked={() => newInputRef.current?.click()}
            text="+Prisegti dokumentą"
          />
          <FormHelperText error={state.error}>{state.errorMessage}</FormHelperText>
        </div>
      </BasicTooltip>
    </>
  );
};
