export interface PaymentAmount {
  paymentAmountId: number;
  amount: number;
  validFrom: string;
  validTo: string;
  start: string;
  end: string;
}

export interface PaymentAmountInput {
  paymentAmountId: number | null;
  amount: number | null;
  validFrom: Date | null;
  validTo: Date | null;
  start: Date | null;
  end: Date | null;
}

export const defaultValues: PaymentAmountInput = {
  paymentAmountId: null,
  amount: null,
  validFrom: null,
  validTo: null,
  start: null,
  end: null
};
