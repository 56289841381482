import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { CloseButton } from '../../../../../../../components/TableButtons/CloseButton';
import { SuccessButton } from '../../../../../../../components/TableButtons/SuccessButton';

const useStyles = makeStyles({
  formTools: {
    alignItems: 'center',
    display: 'flex',
    height: 30,
    marginLeft: 14
  },
  buttonDivider: {
    borderTop: '1px solid #EEEEEE',
    margin: 0
  }
});

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  disabled?: boolean;
}

export const FormTools: React.FC<Props> = ({ onSubmit, onClose, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.formTools}>
      <SuccessButton onSuccess={onSubmit} disabled={disabled} />
      <Divider className={classes.buttonDivider} orientation="vertical" />
      <CloseButton onClose={onClose} />
    </div>
  );
};
