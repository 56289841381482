import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import AppHeader from '../../components/Header/AppHeader';
import { LEGAL_HEADER_MENU_FIELDS } from '../pages';
import { ApplicationState } from '../../store';

const LegalHeader: React.FC = () => {
  const currentUser = useSelector((state: ApplicationState) => state.virsis.currentUser);
  if (currentUser) {
    return (
      <AppHeader
        currentUser={currentUser}
        headerFields={LEGAL_HEADER_MENU_FIELDS}
        displayEmailEditing
      />
    );
  }
  return null;
};

export default withRouter(LegalHeader);
