import { action } from 'typesafe-actions';
import {
  FETCH_PARAMETERS_REQUEST,
  FETCH_PARAMETERS_ERROR,
  FETCH_PARAMETERS_SUCCESS,
  UPDATE_PARAMETER_REQUEST,
  UPDATE_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_ERROR,
  RESET_UPDATE_PARAMETER_STATE
} from './parametersActionTypes';

export const fetchVirsisParametersRequest = () => action(FETCH_PARAMETERS_REQUEST);
export const fetchVirsisParametersSuccess = (data: string) =>
  action(FETCH_PARAMETERS_SUCCESS, data);
export const fetchVirsisParametersError = (error: Error) => action(FETCH_PARAMETERS_ERROR, error);

export const updateVirsisParameter = (
  parameterId: number,
  parameterValue: string | null,
  parameterCode: string
) =>
  action(UPDATE_PARAMETER_REQUEST, {
    parameterId,
    parameterValue,
    parameterCode
  });
export const updateVirsisParameterSuccess = () => action(UPDATE_PARAMETER_SUCCESS);
export const updateVirsisParameterError = (error: Error) => action(UPDATE_PARAMETER_ERROR, error);
export const resetVirsisParameterUpdatingState = () => action(RESET_UPDATE_PARAMETER_STATE);
