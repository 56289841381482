import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import PageFilterButton from '../../../../components/PageButtons/PageFilterButton';
import { ApplicationState } from '../../../../store';

const VirsDataProvidedNavigation: React.FC = () => {
  const history = useHistory();

  const goToPath = (path: string) => {
    history.push(path);
  };
  const location = useLocation();
  const { pathname } = location;
  const { selectedVirsId } = useSelector((state: ApplicationState) => state.virsis);

  return (
    <Container maxWidth="lg">
      <PageFilterButton
        key="institutionProvidedPage"
        active={pathname.includes('instituciju-įstaigų')}
        onClick={() =>
          goToPath(`/virs-duomenys/${selectedVirsId}/pateikti-duomenys/instituciju-įstaigų`)
        }
        label="Institucijų/įstaigų pateikti duomenys"
      />
      <PageFilterButton
        key="externalSystemPage"
        active={pathname.includes('išorinių-sistemų')}
        onClick={() =>
          goToPath(`/virs-duomenys/${selectedVirsId}/pateikti-duomenys/išorinių-sistemų`)
        }
        label="Išorinių sistemų duomenys"
      />
    </Container>
  );
};

export default VirsDataProvidedNavigation;
