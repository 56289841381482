export interface HeaderMenuField {
  id: string;
  label: string;
  path: string;
  roles?: string[];
  subMenu?: HeaderMenuField[];
}

export const VIRS_HEADER_MENU_FIELDS: HeaderMenuField[] = [
  { id: 'start', label: 'Pradžia', path: '/' },
  {
    id: 'dataSubmission',
    label: 'Duomenų peržiūra ir teikimas',
    path: '/duomenu-perziura-ir-teikimas',
    subMenu: [
      {
        id: 'activityTypes',
        label: 'Veiklos rūšys',
        path: '/veiklos-rusys'
      },
      {
        id: 'outlet',
        label: 'Visuomenės informavimo priemonės',
        path: '/visuomenes-informavimo-priemones'
      },
      {
        id: 'participants',
        label: 'Dalyviai',
        path: '/dalyviai',
        subMenu: [
          {
            id: 'selectedDate',
            label: 'Konkrečios datos peržiūra',
            path: '/dalyviai/konkreti-data'
          }
        ]
      },
      { id: 'eDocuments', label: 'El. dokumentai', path: '/el-dokumentai' }
    ]
  },
  {
    id: 'receivedData',
    label: 'Duomenys iš institucijų',
    path: '/duomenys-is-instituciju',
    subMenu: [
      {
        id: 'establishmentData',
        label: 'Institucijų / įstaigų duomenys',
        path: '/instituciju-istaigu-duomenys/visi'
      },
      {
        id: 'externalSystemData',
        label: 'Išorinių sistemų duomenys',
        path: '/isoriniu-sistemu-duomenys/visi'
      }
    ]
  },
  { id: 'help', label: 'Pagalba', path: '/pagalba' }
];

export const INSTITUTION_HEADER_MENU_FIELDS: HeaderMenuField[] = [
  { id: 'start', label: 'Pradžia', path: '/' },
  {
    id: 'dataSubmission',
    label: 'Duomenų teikimas',
    path: '/duomenu-teikimas',
    subMenu: [
      {
        roles: ['ROLE_KM_TIRAZO_TIKRINIMAS_VIEW', 'ROLE_KM_TIRAZO_TIKRINIMAS_VIEW'],
        id: 'editionCheck',
        label: 'Tiražo patikrinimo duomenys',
        path: '/tirazo-patikrinimo-duomenys'
      },
      {
        roles: ['ROLE_KM_METINE_IMOKA_VIEW'],
        id: 'paymentData',
        label: 'Metinės įmokos duomenys',
        path: '/metines-imokos-duomenys'
      },
      {
        roles: ['ROLE_VIEA_VIEW'],
        id: 'ethicalNonCompliance',
        label: 'Profesinės etikos nesilaikymas',
        path: '/profesines-etikos-nesilaikymas'
      },
      {
        roles: ['ROLE_LRTK_VIEW', 'ROLE_ZEIT_VIEW'],
        id: 'misconducts',
        label: 'Rimtų profesinių pažeidimų duomenys',
        path: '/profesiniai-pazeidimai'
      },
      {
        roles: ['ROLE_ISTAIG_EDIT'],
        id: 'legalFundsReceived',
        label: 'VIRS gautos lėšos',
        path: '/virs-gautos-lesos'
      }
    ]
  },
  {
    // managed by getMenuFieldsByRole
    id: 'virsDataSubmission',
    label: 'VIRS duomenų teikimas',
    path: '/virs-duomenys'
  },
  {
    // managed by getMenuFieldsByRole
    id: 'virsDataReview',
    label: 'VIRS duomenų peržiūra',
    path: '/virs-duomenys'
  },
  {
    roles: [
      'ROLE_VIRS_EDIT',
      'ROLE_KM_TIRAZO_TIKRINIMAS_EDIT',
      'ROLE_KM_METINE_IMOKA_EDIT',
      'ROLE_VIEA_EDIT',
      'ROLE_ZEIT_EDIT',
      'ROLE_LRTK_EDIT',
      'ROLE_ISTAIG_EDIT',
      'ROLE_KM_TIRAZO_TIKRINIMAS_VIEW',
      'ROLE_KM_METINE_IMOKA_VIEW',
      'ROLE_VIEA_VIEW',
      'ROLE_ZEIT_VIEW',
      'ROLE_LRTK_VIEW'
    ],
    id: 'providedDataHistory',
    label: 'Duomenų istorija',
    path: '/duomenu-istorija'
  },
  {
    roles: ['ROLE_ADMIN_AUD'],
    id: 'audits',
    label: 'Auditai',
    path: '/auditai',
    subMenu: [
      {
        roles: ['ROLE_ADMIN_AUD'],
        id: 'auditsData',
        label: 'Audito duomenys',
        path: '/duomenys'
      },
      {
        roles: ['ROLE_ADMIN_AUD'],
        id: 'integrationalSystemsAudit',
        label: 'Integracinių sistemų panaudojimo auditas',
        path: '/integraciniu-sistemu-panaudojimo-auditas'
      },
      {
        roles: ['ROLE_ADMIN_ARCH'],
        id: 'deleteAchivedData',
        label: 'Archyvuotų duomenų naikinimas',
        path: '/archyvuotu-duomenu-naikinimas'
      }
    ]
  },
  {
    roles: ['ROLE_ADMIN_KLSF'],
    id: 'nustatymai',
    label: 'Nustatymai',
    path: '/nustatymai',
    subMenu: [
      {
        roles: ['ROLE_ADMIN_KLSF'],
        id: 'classifierManagement',
        label: 'Klasifikatorių tvarkymas',
        path: '/klasifikatoriu-tvarkymas'
      },
      {
        roles: ['ROLE_ADMIN_GRF'],
        id: 'graphicalRepresentationTools',
        label: 'Grafinio atvaizdavimo įrankio administravimas',
        path: '/grafinio-atvaizdavimo-įrankio-administravimas'
      }
    ]
  },
  {
    roles: ['ROLE_ADMIN_ALL'],
    id: 'inaccurateDataMessages',
    label: 'Pranešimai',
    path: '/netikslus-duomenys'
  },
  {
    // managed by getMenuFieldsByRole
    id: 'reports',
    label: 'Ataskaitos',
    path: '/ataskaitos'
  },
  { id: 'help', label: 'Pagalba', path: '/pagalba' }
];

export const LEGAL_HEADER_MENU_FIELDS: HeaderMenuField[] = [
  { id: 'start', label: 'Pradžia', path: '/' },
  {
    id: 'dataSubmission',
    label: 'Duomenų teikimas',
    path: '/duomenu-teikimas'
  },
  {
    id: 'providedDataHistory',
    label: 'Duomenų istorija',
    path: '/duomenu-istorija'
  },
  { id: 'help', label: 'Pagalba', path: '/pagalba' }
];
