import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, RouteComponentProps, withRouter } from 'react-router';
import BottomPageActionButtons from '../../components/PageButtons/BottomPageActionButtons';
import { OutletsDataTable } from '../../containers/OutletsDataTable/Context';
import { OutletRedirectFunctions } from '../../containers/OutletsDataTable/Table';
import { ApplicationState } from '../../store';
import { getSubsystem } from '../../utils/roleHelper';
import { fetchOutletData } from '../../store/outlets/outletsActions';
import { DataEditingAlert } from '../../components/Alert/DataEditingAlert';
import { getOutletTabPath } from '../../containers/OutletInfoTables/tablesState/tablesTypesAndActions';

const OutletsDataPage: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const { virsData, currentUser, selectedVirsId } = useSelector(
    (stateGlobal: ApplicationState) => stateGlobal.virsis
  );

  const handleGoBackClick = () => {
    const path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/veiklos-rusys'
      : `/virs-duomenys/${virsData?.virsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`;
    history.push(path);
  };

  const isVirsis = getSubsystem(currentUser) === 'VIRSIS';

  const handleGoToPageClick = () => {
    const path = isVirsis
      ? '/duomenu-perziura-ir-teikimas/dalyviai'
      : `/virs-duomenys/${virsData?.virsId}/duomenu-perziura-ir-teikimas/dalyviai`;
    history.push(path);
  };

  const outletRedirectFunctions: OutletRedirectFunctions = {
    redirectToEnterpriseNewRecord: () =>
      history.push({
        pathname: isVirsis
          ? '/duomenu-perziura-ir-teikimas/veiklos-rusys'
          : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`,
        state: {
          newRecordOpened: true
        }
      }),
    redirectToOutletInfoPage: (outletId: number) =>
      history.push({
        pathname: isVirsis
          ? `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'all'
            )}`
          : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'all'
            )}`
      }),
    redirectToOutletRepresentativesPage: (outletId: number) => {
      history.push({
        pathname: isVirsis
          ? `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'representatives'
            )}`
          : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'representatives'
            )}`
      });
    },
    redirectToOutletEditionsPage: (outletId: number) => {
      history.push({
        pathname: isVirsis
          ? `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'editions'
            )}`
          : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'editions'
            )}`
      });
    },
    redirectToOutletAdPrintingPage: (outletId: number) => {
      history.push({
        pathname: isVirsis
          ? `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'adprinting'
            )}`
          : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'adprinting'
            )}`
      });
    },
    redirectToOutletCategoriesPage: (outletId: number) => {
      history.push({
        pathname: isVirsis
          ? `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'categories'
            )}`
          : `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${outletId}/${getOutletTabPath(
              'categories'
            )}`
      });
    }
  };

  useEffect(() => {
    if (virsData && virsData.virsId !== 0) {
      reduxDispatch(fetchOutletData(virsData.virsId));
    }
  }, [virsData, reduxDispatch]);

  return (
    <>
      <div className="contentWrapper">
        <DataEditingAlert />
        {virsData && <OutletsDataTable outletRedirectFunctions={outletRedirectFunctions} />}
      </div>
      <BottomPageActionButtons
        pageToGo="Dalyviai"
        handleGoToPageClick={handleGoToPageClick}
        handleGoBackClick={handleGoBackClick}
      />
    </>
  );
};

export default withRouter(OutletsDataPage);
