import { getToggledSortOrderGeneric } from '../../../../utils/tableDataFunctions';
import { SortOrder, ColumnHeader } from '../../../../utils/tableTypes';

export type LibisResultsTableAction =
  | {
      type: 'SORTING_CHANGED';
      sortBy: LibisResultsDataField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export const initialLibisResultsTableState: LibisResultsTableState = {
  order: 'asc',
  sortBy: 'searchTermDistance',
  page: 0,
  rowsPerPage: 20
};

export type LibisResultsDataField =
  | 'searchTermDistance'
  | 'title'
  | 'type'
  | 'publisher'
  | 'firstPublishedDate'
  | 'internationalNumber'
  | 'circulation';

export type LibisResultsTableColumnHeader = ColumnHeader<LibisResultsDataField>;

export interface LibisResultsTableState {
  order: SortOrder;
  sortBy: LibisResultsDataField;
  page: number;
  rowsPerPage: number;
}

export const libisResultsTableReducer = (
  state: LibisResultsTableState,
  action: LibisResultsTableAction
): LibisResultsTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    default:
      return state;
  }
};
