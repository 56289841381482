import React from 'react';
import MoreVertButtonWithEditDeletePopover from '../TableButtons/RowActionButtons/MoreVertButtonWithEditDeletePopover';
import MoreVertButtonWithAnnulPopover from '../TableButtons/RowActionButtons/MoreVertButtonWithAnnulPopover';

interface Props {
  status: string;
  linkPath: string;
  annulmentDisablementReason: string | null;
  onClickContinue?: () => void;
  onClickRemove?: () => void;
  onClickAnnul?: () => void;
  isRemovable?: boolean;
  disabled?: boolean;
  editingWarningMessage?: string;
}

export const ChooseActionsOnDifferentStatus: React.FC<Props> = ({
  status,
  linkPath,
  annulmentDisablementReason,
  onClickContinue,
  onClickRemove,
  onClickAnnul,
  isRemovable,
  disabled,
  editingWarningMessage
}) => {
  if (status === 'RUOSIAMAS' && isRemovable) {
    return (
      <MoreVertButtonWithEditDeletePopover
        editingWarningMessage={editingWarningMessage}
        onclickRemove={onClickRemove}
        onClickContinue={onClickContinue}
        linkPath={linkPath}
        disabled={disabled}
      />
    );
  }
  if (status === 'PASIRASYTAS') {
    return (
      <MoreVertButtonWithAnnulPopover
        annulmentDisablementReason={annulmentDisablementReason}
        onClick={onClickAnnul}
        disabled={disabled}
      />
    );
  }
  return null;
};
