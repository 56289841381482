import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { noOptionsText } from '../../../components/TableCustomFilterCell/constants';
import { DATE_LABEL, YEAR_LABEL, MONTH_LABEL, DAY_LABEL } from '../constants';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../MainTable/ShareholdersTableWithContext';
import { Period } from '../MainTable/tableState/tableInitialStateAndTypes';

const useStyles = makeStyles(() => ({
  selectLabel: {
    color: '#5A5A5A',
    fontSize: '13px',
    marginRight: '10px'
  },
  textField: {
    width: '200px',
    marginRight: '10px'
  }
}));

const periods: Period[] = [
  {
    id: 'changeDate',
    label: DATE_LABEL
  },
  {
    id: 'year',
    label: YEAR_LABEL
  },
  {
    id: 'month',
    label: MONTH_LABEL
  },
  {
    id: 'day',
    label: DAY_LABEL
  }
];

interface Props {
  setPeriodFilter(value: Period | null): void;
  periodFilter: Period;
}

const SelectDropdown: React.FC<Props> = ({ setPeriodFilter, periodFilter }) => {
  const classes = useStyles();

  const { state: tableState } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  const handleChange = (event: React.ChangeEvent<unknown>, value: Period | null) => {
    if (value?.id !== 'day') {
      tableDispatch({
        type: 'RESET_CUSTOM_FILTER_VALUE',
        customFilter: {
          shareholderEventDate: [],
          shareholderPeriodDateFrom: [],
          shareholderPeriodDateTo: [],
          shareholerName: [],
          shareholderLevel: []
        },
        resetFilter: !tableState.resetFilter
      });
    }
    if (value?.id === 'day') {
      tableDispatch({
        type: 'CUSTOM_FILTER_DISPLAY_TOGGLED',
        filterButtonAnchor: tableState.filterButtonRef
      });
      setPeriodFilter(value);
    }
    if (value?.id !== 'day') {
      setPeriodFilter(value);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.selectLabel}>Žiūrėti pagal</Typography>
      <Autocomplete
        size="small"
        clearOnEscape
        disableClearable
        id="filter-period"
        options={periods}
        getOptionLabel={(period) => period.label}
        noOptionsText={noOptionsText}
        onChange={handleChange}
        value={periodFilter}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" className={classes.textField} />
        )}
      />
    </Box>
  );
};

export default SelectDropdown;
