import { put, takeEvery, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST,
  FETCH_AVAILABLE_VIRS_ID_REQUEST,
  FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
  FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST
} from './graphicalRepresentationToolsActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  createGraphicalRepresentationRecordError,
  createGraphicalRepresentationRecordRequest,
  createGraphicalRepresentationRecordSuccess,
  fetchAvailableVirsIdError,
  fetchAvailableVirsIdSuccess,
  fetchGraphicalRepresentationDataError,
  fetchGraphicalRepresentationDataRequest,
  fetchGraphicalRepresentationDataSuccess,
  fetchGraphicalRepresentationVersionRequest,
  fetchGraphicalRepresentationVersionsSuccess,
  publishGraphicalRepresentationVersionError,
  publishGraphicalRepresentationVersionRequest,
  publishGraphicalRepresentationVersionSuccess
} from './graphicalRepresentationToolsActions';
import {
  createGraphicalRepresentationRecord,
  getAvailableVirsId,
  getGraphicalRepresentationData,
  getGraphicalRepresentationVersion,
  publishGraphicalRepresentationVersion
} from './graphicalRepresentationToolsApi';

export function* handleFetchGraphicalRepresentationDataSaga(
  action: ActionType<typeof fetchGraphicalRepresentationDataRequest>
) {
  try {
    const { graphicalVersionId } = action.payload;
    const { data } = yield call(getGraphicalRepresentationData, graphicalVersionId);
    yield put(fetchGraphicalRepresentationDataSuccess(data.graphicalRecord));
  } catch (err) {
    yield put(fetchGraphicalRepresentationDataError(err.response));
    yield put(sendMessage('error', err.response.data));
  }
}

export function* handleFetchGraphicalRepresentationVersionSaga() {
  try {
    const { data } = yield call(getGraphicalRepresentationVersion);
    yield put(fetchGraphicalRepresentationVersionsSuccess(data.graphicalRepresentation));
  } catch (err) {
    yield put(fetchGraphicalRepresentationDataError(err.response));
    yield put(sendMessage('error', err.response.data));
  }
}

export function* createGraphicalRepresentationRecordSaga(
  action: ActionType<typeof createGraphicalRepresentationRecordRequest>
) {
  try {
    const { data } = yield call(createGraphicalRepresentationRecord, action.payload);
    yield put(createGraphicalRepresentationRecordSuccess(data.payload));
    yield put(fetchGraphicalRepresentationVersionRequest());
    yield put(sendMessage('success', 'Pakeitimai įšsaugoti'));
  } catch (err) {
    yield put(createGraphicalRepresentationRecordError(err.response));
    yield put(sendMessage('error', err.response.data.message || 'Pakeitimų išsaugoti nepavyko'));
  }
}

export function* publishGraphicalRepresentationVersionSaga(
  action: ActionType<typeof publishGraphicalRepresentationVersionRequest>
) {
  try {
    const { data } = yield call(publishGraphicalRepresentationVersion, action.payload);
    yield put(publishGraphicalRepresentationVersionSuccess(data.payload));
    yield put(fetchGraphicalRepresentationVersionRequest());
    yield put(sendMessage('success', 'Versija publikuota'));
  } catch (err) {
    yield put(publishGraphicalRepresentationVersionError(err.response));
    yield put(sendMessage('error', err.response.data.message || 'Publikuoti versijos nepavyko'));
  }
}

export function* handleFetchAvailableVirsSaga() {
  try {
    const { data } = yield call(getAvailableVirsId);
    yield put(fetchAvailableVirsIdSuccess(data));
  } catch (err) {
    yield put(fetchAvailableVirsIdError(err.response));
    yield put(sendMessage('error', err.response.data || 'Nėra aktualių VIRS'));
  }
}

function* graphicalRepresentationToolsSaga() {
  yield takeEvery(
    FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
    handleFetchGraphicalRepresentationDataSaga
  );
  yield takeEvery(
    FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
    handleFetchGraphicalRepresentationVersionSaga
  );
  yield takeEvery(
    CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST,
    createGraphicalRepresentationRecordSaga
  );
  yield takeEvery(
    PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
    publishGraphicalRepresentationVersionSaga
  );
  yield takeEvery(FETCH_AVAILABLE_VIRS_ID_REQUEST, handleFetchAvailableVirsSaga);
}

export default graphicalRepresentationToolsSaga;
