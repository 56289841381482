import { Switch } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

export const VirsisSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 30,
      height: 16,
      padding: 0,
      marginRight: 7,
      display: 'flex',
      overflow: 'unset'
    },
    switchBase: {
      padding: 3,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#28ADDB',
          border: '1px solid #28ADDB'
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.grey[300]
    },
    checked: {}
  })
)(Switch);
