import moment from 'moment';
import { CategoryNewRowState, CategoryNewRowAction } from './categoryInitialStateAndTypes';
import { validateNewCategoryState } from './categoryRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  validateSingleChoice
} from '../../../../../utils/InputValueFunctions';

export const categoryNewRowReducer = (
  state: CategoryNewRowState,
  action: CategoryNewRowAction
): CategoryNewRowState => {
  switch (action.type) {
    case 'NEW_RECORD_STATE_INITIALIZED':
      return {
        ...state,
        categoryType: {
          ...state.categoryType,
          options: action.availableCategories,
          value: null
        },
        validFrom: {
          ...state.validFrom,
          value: null,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.controlledFrom),
              text: 'Data negali būti ankstesnė už VIP valdymo pradžios datą'
            },
            {
              id: 'newCategoryFromDate',
              date: moment(action.fromDateForNewCategory),
              text: 'Anksčiausia galima data'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.controlledTo ? moment(action.controlledTo) : null,
              text: 'Data negali būti vėlesnė už VIP valdymo pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'CATEGORY_TYPE_CHANGED':
      return {
        ...state,
        categoryType: validateSingleChoice(
          { ...state.categoryType, value: action.categoryType },
          'Reikia pasirinkti iš sąrašo'
        )
      };
    case 'VALID_FROM_DATE_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.date, state.validFrom)
      };
    case 'CREATE_RECORD_CLICKED':
      return validateNewCategoryState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
