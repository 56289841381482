import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { ApplicationState } from '../../store';
import { useVirsTreeDispatch } from './VirsTreeContext';
import { getSortedAndTransformedVirsData } from './treeState/treeFunctions';
import {
  fetchVirsTreeData,
  loadTimelineEvents,
  setTimelineActiveDate
} from '../../store/shareholdersVirsTree/virsTreeActions';
import { ChartVirsData } from '../../store/shareholdersVirsTree/virsTreeDataTypes';
import { TimelinePanel } from './Timeline/TimelinePanel';
import TreeChart from './TreeChart/TreeChart';
import { VirsTreeHeader } from './VirsTreeHeader';
import moment from 'moment';

const useStyles = makeStyles({
  treeAndBar: {
    overflow: 'auto',
    position: 'relative',
    borderRadius: '5px'
  },
  tree: {
    overflow: 'auto',
    position: 'relative',
    paddingTop: 12,
    height: '1000px',
    backgroundColor: '#fff'
  },
  error: {
    marginTop: '200px',
    paddign: '20px'
  }
});

export interface VirsTreeProps {
  virsId: number;
  date?: string;
  virsName: string;
  handleGoBackClick: () => void;
}

/*
Any change regarding VirsTree component and logic should be reproduced in the public application virsis-public-ui,
and vice-versa
*/

export const VirsTree: React.FC<VirsTreeProps> = ({
  virsId,
  date,
  virsName,
  handleGoBackClick
}) => {
  const classes = useStyles();

  const {
    virsTreeData: {
      virsTreeData,
      loadingVirsTreeData,
      virsTreeError,
      timeline: { activeDate }
    }
  } = useSelector((state: ApplicationState) => state);
  const reduxDispatch = useDispatch();

  const { treeDispatch } = useVirsTreeDispatch();

  const chartData: ChartVirsData | undefined = getSortedAndTransformedVirsData(virsTreeData);

  useEffect(() => {
    reduxDispatch(loadTimelineEvents(virsId));
    const dateForVirsData: string = date ? moment(date).format('L') : moment().format('L');
    reduxDispatch(setTimelineActiveDate(moment(dateForVirsData)));
    reduxDispatch(fetchVirsTreeData(dateForVirsData, virsId));
  }, [date, reduxDispatch, virsId]);

  useEffect(() => {
    if (chartData) {
      treeDispatch({
        type: 'DATA_RECEIVED',
        data: chartData
      });
    }
    // eslint-disable-next-line
  }, [virsTreeData]);

  return (
    <>
      <VirsTreeHeader virsName={virsName} closeTree={handleGoBackClick} />

      <div className={classes.treeAndBar}>
        <TimelinePanel />

        <div
          className={classes.tree}
          style={{ pointerEvents: loadingVirsTreeData ? 'none' : 'auto' }}
        >
          {loadingVirsTreeData && (
            <div
              style={{
                display: 'flex',
                height: '100%'
              }}
            >
              <CircularProgress
                style={{
                  height: 60,
                  width: 60,
                  margin: 'auto',
                  marginTop: '200px'
                }}
              />
            </div>
          )}
          {!loadingVirsTreeData && !virsTreeData && (
            <Typography variant="h3" className={classes.error} align="center">
              Pateiktų duomenų nėra.
            </Typography>
          )}
          {!loadingVirsTreeData && virsTreeError && (
            <Typography variant="h3" className={classes.error}>
              Klaida. Nepavyko gauti duomenų.
            </Typography>
          )}
          {!loadingVirsTreeData && !virsTreeError && virsTreeData && (
            <TreeChart chartData={chartData} activeDate={activeDate} />
          )}
        </div>
      </div>
    </>
  );
};
