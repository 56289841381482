import { makeStyles } from '@material-ui/core';
import React from 'react';

interface Props {
  borderColor: string;
  fillWithColor: string;
}

const useStyles = makeStyles(() => ({
  bar: ({ fillWithColor, borderColor }: { fillWithColor: string; borderColor: string }) => ({
    width: '100%',
    left: 0,
    backgroundColor: fillWithColor,
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`
  })
}));

export const ColorBarPrevFollDate: React.FC<Props> = ({ borderColor, fillWithColor }) => {
  const { bar } = useStyles({ fillWithColor, borderColor });

  return (
    <>
      <div className={`color-bar-prev-foll-date ${bar}`} />
    </>
  );
};
