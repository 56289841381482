import { makeStyles, Theme } from '@material-ui/core/styles';
import { whiteColor } from '../../../../../../constants';

export const useFormStyles = makeStyles((theme: Theme) => ({
  shareTypesContainer: ({ justifyContent }: { justifyContent: string }) => ({
    alignItems: 'center',
    backgroundColor: whiteColor,
    justifyContent: justifyContent,
    display: 'flex',
    position: 'relative'
  }),
  radioButtonContainer: {
    flex: 1,
    '&:not(:first-child)': {
      marginLeft: 20
    }
  },
  shareLabel: {
    color: theme.palette.info.dark
  },
  errorLabel: {
    color: theme.palette.error.main
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontStyle: 'italic',
    position: 'absolute',
    bottom: -15
  },
  flexContainer: {
    alignItems: 'center',
    display: 'flex'
  }
}));
