import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  getProvidedDataInst,
  getProvidedDataByVirsId,
  getProvidedDataLegal,
  deleteProvidedRecord,
  annulProvidedRecord,
  getProvidedDataVirs
} from './providedDataApi';

import {
  fetchProvidedDataVirsSuccess,
  fetchProvidedDataVirsError,
  fetchProvidedDataByVirsId,
  fetchProvidedDataInstError,
  fetchProvidedDataInstSuccess,
  fetchProvidedDataLegalSuccess,
  fetchProvidedDataLegalError,
  removeProvidedDataSuccess,
  removeProvidedDataError,
  annulProvidedDataSuccess,
  annulProvidedDataError,
  annulProvidedDataInst,
  fetchProvidedDataInst,
  fetchProvidedDataLegal,
  resetAnnulProvidedDataState,
  annulProvidedDataLegal,
  resetProvidedDataRemoveState,
  annulProvidedData,
  removeProvidedData
} from './providedDataActions';

import {
  ANNUL_PROVIDED_DATA_INST_REQUEST,
  FETCH_PROVIDED_DATA_BY_VIRS_ID_REQUEST,
  FETCH_PROVIDED_DATA_INST_REQUEST,
  FETCH_PROVIDED_DATA_LEGAL_REQUEST,
  FETCH_PROVIDED_DATA_VIRS_REQUEST,
  ANNUL_PROVIDED_DATA_LEGAL_REQUEST,
  ANNUL_PROVIDED_DATA_REQUEST,
  REMOVE_PROVIDED_DATA_REQUEST
} from './providedDataActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  getProvidedDataHistoryPageRequest,
  selectProvidedDataTableState
} from '../providedDataTable/providedDataTableUtilities';
import { updateTempDataState } from '../providedDataTable/providedDataTableActions';
import { ProvidedDataTableState } from '../providedDataTable/providedDataTableReducer';
import { fetchVirsDataByVirsIdRequest, fetchVirsDataRequest } from '../virsis/actions';
import { ProvidedDataTableType } from './providedDataTypes';

function* handleProvidedDataVirsFetchSaga() {
  try {
    const data = yield call(getProvidedDataVirs);
    yield put(fetchProvidedDataVirsSuccess(data.data));
  } catch (err) {
    yield put(fetchProvidedDataVirsError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleProvidedDataInstByVirsIdFetchSaga(
  action: ActionType<typeof fetchProvidedDataByVirsId>
) {
  try {
    const virsId = action.payload;
    const { data } = yield call(getProvidedDataByVirsId, virsId);
    yield put(fetchProvidedDataVirsSuccess(data));
  } catch (err) {
    yield put(fetchProvidedDataVirsError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleAnnulProvidedDataSaga(action: ActionType<typeof annulProvidedData>) {
  try {
    const { documentStatusId, annulRecord, virsId } = action.payload;
    yield call(annulProvidedRecord, documentStatusId, annulRecord);
    yield put(annulProvidedDataSuccess());
    yield put(fetchProvidedDataByVirsId(virsId));
    yield put(resetAnnulProvidedDataState());
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', 'Įrašas anuliuotas'));
  } catch (err) {
    yield put(annulProvidedDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

export function* handleRemoveProvidedDataSaga(action: ActionType<typeof removeProvidedData>) {
  try {
    const { documentStatusId, tableType, virsId } = action.payload;
    yield call(deleteProvidedRecord, documentStatusId);
    yield put(removeProvidedDataSuccess());
    if (tableType === ProvidedDataTableType.VIRS) {
      // Table in the Institution context
      if (virsId) yield put(fetchVirsDataByVirsIdRequest(virsId));
      // Table in the VIRS context
      else yield put(fetchVirsDataRequest());
    }
    if (tableType === ProvidedDataTableType.INST) yield put(fetchProvidedDataInst());
    if (tableType === ProvidedDataTableType.LEGAL) yield put(fetchProvidedDataLegal());
    yield put(resetProvidedDataRemoveState());
    yield put(sendMessage('success', 'Įrašas pašalintas'));
  } catch (err) {
    yield put(removeProvidedDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

// INST
function* handleProvidedDataInstFetchSaga() {
  try {
    const { tableDataState, tempDataTableState }: ProvidedDataTableState = yield select(
      selectProvidedDataTableState
    );
    if (JSON.stringify(tempDataTableState) !== JSON.stringify(tableDataState)) {
      yield put(updateTempDataState(tableDataState));
    }
    const { data } = yield call(
      getProvidedDataInst,
      getProvidedDataHistoryPageRequest(tableDataState)
    );
    yield put(fetchProvidedDataInstSuccess(data));
  } catch (err) {
    yield put(fetchProvidedDataInstError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

export function* handleAnnulProvidedDataInstSaga(action: ActionType<typeof annulProvidedDataInst>) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulProvidedRecord, documentStatusId, annulRecord);
    yield put(annulProvidedDataSuccess());
    yield put(fetchProvidedDataInst());
    yield put(resetAnnulProvidedDataState());
    yield put(sendMessage('success', 'Įrašas anuliuotas'));
  } catch (err) {
    yield put(annulProvidedDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

// LEGAL
function* handleProvidedDataLegalFetchSaga() {
  try {
    const { data } = yield call(getProvidedDataLegal);
    yield put(fetchProvidedDataLegalSuccess(data));
  } catch (err) {
    yield put(fetchProvidedDataLegalError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

export function* handleAnnulProvidedDataLegalSaga(
  action: ActionType<typeof annulProvidedDataLegal>
) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulProvidedRecord, documentStatusId, annulRecord);
    yield put(annulProvidedDataSuccess());
    yield put(fetchProvidedDataLegal());
    yield put(resetAnnulProvidedDataState());
    yield put(sendMessage('success', 'Įrašas anuliuotas'));
  } catch (err) {
    yield put(annulProvidedDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* providedDataSaga() {
  yield takeEvery(FETCH_PROVIDED_DATA_VIRS_REQUEST, handleProvidedDataVirsFetchSaga);
  yield takeEvery(FETCH_PROVIDED_DATA_BY_VIRS_ID_REQUEST, handleProvidedDataInstByVirsIdFetchSaga);
  yield takeEvery(ANNUL_PROVIDED_DATA_REQUEST, handleAnnulProvidedDataSaga);
  yield takeEvery(REMOVE_PROVIDED_DATA_REQUEST, handleRemoveProvidedDataSaga);

  // INST
  yield takeLatest(FETCH_PROVIDED_DATA_INST_REQUEST, handleProvidedDataInstFetchSaga);
  yield takeEvery(ANNUL_PROVIDED_DATA_INST_REQUEST, handleAnnulProvidedDataInstSaga);

  // LEGAL
  yield takeEvery(FETCH_PROVIDED_DATA_LEGAL_REQUEST, handleProvidedDataLegalFetchSaga);
  yield takeEvery(ANNUL_PROVIDED_DATA_LEGAL_REQUEST, handleAnnulProvidedDataLegalSaga);
}

export default providedDataSaga;
