import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Button,
  Divider,
  DialogContent,
  Grid,
  Box,
  DialogProps,
  DialogActions
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { PdfPreview } from '../../components/PdfPreview/PdfPreview';
import { DocumentValidationError } from '../../store/document/documentTypes';
import { PaginationJumpPage } from '../../components/TablePagination/PaginationJumpPage';
import { PaginationIteratePage } from '../../components/TablePagination/PaginationIteratePage';
import downloadPdf from '../../utils/PdfDownload';
import virsisTheme from '../../style/virsisTheme';
import { CloseDialogButton } from '../../components/Dialogs/Dialog/CloseButton';

type Props = DialogProps & {
  documentPath: string;
  documentErrors?: DocumentValidationError[];
};

export const PreviewDocumentDialogMinimal: React.FC<Props> = ({
  documentPath,
  documentErrors,
  ...props
}) => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [page, setPage] = useState<number>(0);
  const [documentLoading, setDocumentLoading] = useState<boolean>(true);

  const handleDownload = () => {
    downloadPdf(documentPath);
  };

  return (
    <Dialog {...props}>
      <div className="dialog-title">
        <Typography variant="h2">El.dokumentas</Typography>

        <Button onClick={() => handleDownload()} variant="outlined" color="primary">
          <GetAppIcon fontSize="small" style={{ marginRight: 5 }} />

          <Typography variant="h5" color="primary">
            Atsisiųsti
          </Typography>
        </Button>
      </div>

      <Divider light style={{ margin: '0px 35px' }} />

      <DialogContent>
        <div className="dialog-main-content">
          <Grid container spacing={0}>
            <Grid item>
              <div
                style={{
                  width: '600px',
                  overflow: 'hidden'
                }}
              >
                <PdfPreview
                  width="600"
                  page={page + 1}
                  documentPath={documentPath}
                  onDocumentLoad={({ pageCount: count }) => {
                    setPageCount(count);
                    setDocumentLoading(false);
                  }}
                />
              </div>
            </Grid>

            {documentErrors && (
              <Grid item>
                <div
                  style={{
                    height: '500px',
                    width: '300px',
                    backgroundColor: virsisTheme.palette.secondary.light
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>

      <DialogActions
        className="dialog-actions"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box style={{ display: 'flex' }}>
          {!documentLoading && (
            <>
              <PaginationIteratePage
                currentPage={page}
                previousPage={() => setPage(page - 1)}
                nextPage={() => setPage(page + 1)}
                pagesCount={pageCount}
              />

              <PaginationJumpPage
                currentPage={page}
                setPage={setPage}
                pagesCount={pageCount}
                disabled={false}
              />
            </>
          )}
        </Box>

        <Box>
          <CloseDialogButton
            label="Uždaryti"
            onClick={(event) => {
              if (props.onClose) {
                props.onClose(event, 'escapeKeyDown');
              }
            }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
