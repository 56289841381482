import React from 'react';

import { makeStyles, TableSortLabel, Typography } from '@material-ui/core';

import { Column } from './types';
import { Actions } from './state/actions';
import { TableSortLabelProps } from '@material-ui/core/TableSortLabel/TableSortLabel';

interface Props<TData> extends TableSortLabelProps {
  column: Column<TData>;
  actions: Actions<TData>;
}

const useStyles = makeStyles({
  overrideSVGPosition: {
    '& svg': {
      paddingBottom: 0
    }
  }
});

export const ClassifierSortingLabel = <TData,>({
  column,
  actions,
  ...sortLabelProps
}: Props<TData>) => {
  const classes = useStyles();

  return (
    <TableSortLabel {...sortLabelProps} className={classes.overrideSVGPosition}>
      <Typography variant="h4">{column.header}</Typography>
    </TableSortLabel>
  );
};
