import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableHead,
  TableBody,
  Paper,
  Typography,
  Box,
  Container,
  makeStyles,
  useTheme
} from '@material-ui/core/';
import {
  EnterpriseDataTableField,
  enterpriseTableColumns
} from './tableState/initialStateAndTypes';
import { ApplicationState } from '../../store';
import { EnterpriseRecord } from '../../store/enterprises/enterprisesTypes';
import { filterAndSortEnterpriseTableData } from './tableState/tableFunctions';
import {
  dataContainsAnyOutdatedRecords,
  dataContainsAnyRelevantRecords,
  dataContainsAnyNotSignedRecords,
  pageTableData
} from '../../utils/tableDataFunctions';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import { EnterpriseTableHeaders } from './TableHeaders';
import { EnterpriseTableRowContainer } from './TableRows/RowContainer';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { EnterpriseTableRowContainerNew } from './TableRows/RowContainerNew';
import TableStatusFilters from '../../components/TableStatusFilters/TableStatusFilters';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import { Roles } from '../../store/virsis/dataTypes';
import SubmitVirsData from '../SubmitVirsData';
import EnterpriseTableFilterRow from './FilterRow';
import AllowedTo from '../AllowedTo';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { useEnterpriseTableState, useEnterpriseTableDispatch } from './Context';
import { getSubsystem } from '../../utils/roleHelper';
import AllowedToAllExcept from '../AllowedToAllExcept';
import { DataEditingAlert } from '../../components/Alert/DataEditingAlert';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { enterprisePageContext } from '../../pages/virs/EnterpriseDataPage';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import OutletsWillBeDeletedAlert from './OutletsWillBeDeletedAlert';
import OutletsHaveToBeUpdatedAlert from './OutletsHaveToBeUpdatedAlert';

export type EnterpriseDataApiFunctions = {
  getEnterpriseRecords: () => void;
  createEnterpriseRecord: (record: EnterpriseRecord, message: string) => void;
  updateEnterpriseRecord: (
    recordId: number,
    previousEnterpriseTypeId: number,
    record: EnterpriseRecord,
    message: string
  ) => void;
  removeEnterpriseRecord: (recordId: number) => void;
};

export interface EnterpriseTableProps {
  apiFunctions: EnterpriseDataApiFunctions;
  withNewRecordOpened?: true;
}

export const EnterpriseTable: React.FC<EnterpriseTableProps> = ({
  apiFunctions,
  withNewRecordOpened
}) => {
  const context = useContext(enterprisePageContext);

  const { state: tableState } = useEnterpriseTableState();
  const { dispatch: tableDispatch } = useEnterpriseTableDispatch();

  const {
    enterpriseData: { loadingEnterpriseData, enterprises, enterpriseDataError },
    virsis: { virsData, currentUser },
    classifiers: { enterpriseTypes }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  function toggleNewRecordRowAndClear() {
    tableDispatch({ type: 'RESET_COLUMN_DISPLAY' });
    tableDispatch({ type: 'TOGGLE_SHOW_ADD_NEW_RECORD' });
  }

  function resetColumnDisplay() {
    tableDispatch({ type: 'RESET_COLUMN_DISPLAY' });
  }

  function toggleCustomFilter() {
    tableDispatch({ type: 'TOGGLE_CUSTOM_FILTER_DISPLAY' });
  }

  function setCustomFilter(column: EnterpriseDataTableField, values: string[]) {
    tableDispatch({ type: 'SET_CUSTOM_FILTER', filterBy: column, values });
  }

  function toggleUnsignedFilter() {
    tableDispatch({ type: 'TOGGLE_SHOW_STATUS_UNSIGNED' });
  }

  function toggleRelevantFilter() {
    tableDispatch({ type: 'TOGGLE_SHOW_STATUS_RELEVANT' });
  }

  function togglePassedFilter() {
    tableDispatch({ type: 'TOGGLE_SHOW_STATUS_NOT_RELEVANT' });
  }

  function setSorting(column: EnterpriseDataTableField): void {
    tableDispatch({ type: 'SET_SORTING', sortBy: column });
  }

  function setPage(value: number): void {
    tableDispatch({ type: 'SET_PAGE', page: value });
  }

  function setRowsPerPage(rowsPerPage: number) {
    tableDispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  }

  function toggleColumnDisplay(column: EnterpriseDataTableField): void {
    tableDispatch({ type: 'TOGGLE_COLUMN_DISPLAY', column });
  }

  function toggleRowExtension(enterpriseId: number): void {
    tableDispatch({ type: 'ROW_EXTENSION_CLICKED', enterpriseId });
  }

  const filteredAndSorted = filterAndSortEnterpriseTableData(tableState, enterprises || []);

  const { page: dataPage, pagesCount } = pageTableData(tableState, filteredAndSorted);

  useEffect(() => {
    const hasNotSigned = dataContainsAnyNotSignedRecords(enterprises || []);
    const hasRelevant = dataContainsAnyRelevantRecords(enterprises || []);
    const hasOutdated = dataContainsAnyOutdatedRecords(enterprises || []);
    tableDispatch({
      type: 'SET_STATUS_FILTERS',
      notSignedEnabled: hasNotSigned,
      relevantEnabled: hasRelevant,
      outdatedEnabled: hasOutdated
    });
  }, [tableDispatch, enterprises]);

  useEffect(() => {
    if (withNewRecordOpened) {
      tableDispatch({
        type: 'INITIALIZE_WITH_NEW_RECORD_OPENED'
      });
    }
  }, [tableDispatch, withNewRecordOpened]);

  // pasinaudoti idėjomis centruojant input komponentus visų lentelių eilutėms,
  // pvz tą darant bendroje eilutėje src\components\TableRowStyle\RowColorsOnDifferentStatus.tsx

  const theme = useTheme();

  const useStyles = makeStyles({
    inputHelperPositionOverride: {
      '& .MuiTextField-root': {
        // marginBottom: 'calc(80px + 0.2em)'
      },
      '& .MuiFormHelperText-root': {
        // position: 'absolute',
        // top: 'calc(100% + 0.2em)',
        // margin: 0,
        // height: '80px',
        // maxWidth: '167px'
      },
    },
    buttonWrapper: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: "space-between",
      [theme.breakpoints.down('xs')]: {
        display: 'inline',
        blockSize: 'fit-content'
      },
    }
  });

  const subsystem = getSubsystem(currentUser);

  const classes = useStyles();

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  const HeaderActions: React.FC = () => (
    <Box display="flex" justifyContent="flex-end">
      <PageActionButtons
        dontShowDelete={subsystem === 'LRTK' || subsystem === 'ZEIT'}
        onDownload={(format, navigationPath) =>
          exportTableData(
            format,
            tableState.tableExportDefinition,
            filteredAndSorted,
            navigationPath
          )
        }
        onPrint={(navigationPath) =>
          printTableData(tableState.tableExportDefinition, filteredAndSorted, navigationPath)
        }
        onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
      />
      {context && (
        <ConfirmRemoveUnsignedDialog
          onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
          onConfirm={context.removeUnsigned}
          onRemoveSuccess={context.reloadTableData}
          open={confirmToDeleteUnsignedDialogOpen}
        />
      )}
    </Box>
  );

  return (
    <div id="enterpriseDataTable" className="contentWrapper">
      <OutletsWillBeDeletedAlert contentText="Pašalinus šią veiklos rūšį bus pašalintos susijusios VIP" />
      <OutletsHaveToBeUpdatedAlert
        contentText={
          'Pakeitus VIRS veiklos rūšį, įsitikinkite, ar skiltyje „Visuomenės informavimo priemonės (VIP)“ teisingai įvesta VIP rūšis.'
        }
      />
      <DataEditingAlert />
      <Container maxWidth="lg">
        <div className="data-page">
          <div className="data-page-header">
            <Box display="flex" justifyContent="space-between" style={{ minHeight: '50px' }}>
              <Typography style={{ margin: 'auto 0' }} variant="h1">
                Veiklos rūšys
              </Typography>
              <AllowedToAllExcept roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <HeaderActions />
              </AllowedToAllExcept>
            </Box>
          </div>
          <>
            {virsData && virsData.virsId ? (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <Box className={classes.buttonWrapper}>
                  <AddNewRecordButton
                    text="Pridėti veiklos rūšį"
                    onClicked={toggleNewRecordRowAndClear}
                  />
                  <HeaderActions />
                </Box>
              </AllowedTo>
            ) : (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <SubmitVirsData enterpriseTable />
              </AllowedTo>
            )}

            <div className="data-table">
              <Paper elevation={0}>
                <div className="CustomizationBar">
                  <TableToolsFrame
                    leftSideItem={
                      <TableStatusFilters
                        dontShowNotSigned={subsystem === 'LRTK' || subsystem === 'ZEIT'}
                        notSignedFilterDisabled={!tableState.statusNotSignedFilterEnabled}
                        notSignedFilterOn={tableState.showStatusNotSigned}
                        notSignedFilterToggler={toggleUnsignedFilter}
                        relevantFilterDisabled={!tableState.statusRelevantFilterEnabled}
                        relevantFilterOn={tableState.showStatusRelevant}
                        relevantFilterToggler={toggleRelevantFilter}
                        outdatedFilterDisabled={!tableState.statusOutdatedFilterEnabled}
                        outdatedFilterOn={tableState.showStatusOutdated}
                        outdatedFilterToggler={togglePassedFilter}
                      />
                    }
                    rightSideItem={
                      <TableCustomTools
                        tableColumns={enterpriseTableColumns}
                        showFilter={tableState.customFilterOn}
                        onFilterTabToggle={toggleCustomFilter}
                        sortOrder={tableState.order}
                        sortBy={tableState.sortBy}
                        onSortByColumnClick={setSorting}
                        columnsDisplayStatus={tableState.columnsDisplayStatus}
                        toggleDisplayColumn={toggleColumnDisplay}
                      />
                    }
                  />
                </div>

                <ScrollXContainer>
                  <Table>
                    <TableHead>
                      <EnterpriseTableHeaders
                        columnsDisplayStatus={tableState.columnsDisplayStatus}
                        order={tableState.order}
                        sortBy={tableState.sortBy}
                        setSorting={setSorting}
                      />
                    </TableHead>

                    {tableState.customFilterOn && (
                      <EnterpriseTableFilterRow
                        columnParams={enterpriseTableColumns}
                        columnsDisplayStatus={tableState.columnsDisplayStatus}
                        onCustomFilterChange={setCustomFilter}
                        tableData={enterprises || []}
                      />
                    )}

                    <TableBody className={classes.inputHelperPositionOverride}>
                      <TableCircularProgressRow isLoading={loadingEnterpriseData} colSpan={5} />

                      <TableErrorRow
                        error={
                          enterpriseDataError && 'Klaida. Nepavyko gauti veiklos rūšių duomenų'
                        }
                        colSpan={5}
                      />
                      {!loadingEnterpriseData &&
                        !enterpriseDataError &&
                        tableState.showAddNewRecord &&
                        virsData && (
                          <EnterpriseTableRowContainerNew
                            columnsDisplayStatus={tableState.columnsDisplayStatus}
                            enterpriseTypes={enterpriseTypes || []}
                            closeCreatingRecord={toggleNewRecordRowAndClear}
                            enterpriseDataApiFunctions={apiFunctions}
                            unconfirmedFilterOn={tableState.showStatusNotSigned}
                            legalRegistrationDate={virsData.legalRegistrationDate}
                            legalDeregistrationDate={virsData.legalDeregistrationDate}
                          />
                        )}
                      {!loadingEnterpriseData &&
                        !enterpriseDataError &&
                        virsData &&
                        dataPage.map((record) => {
                          return (
                            <EnterpriseTableRowContainer
                              key={record.enterpriseId}
                              record={record}
                              columnsToDisplay={tableState.columnsDisplayStatus}
                              resetColumnDisplay={resetColumnDisplay}
                              toggleRowExtension={toggleRowExtension}
                              isExtended={tableState.extendedRows.includes(record.enterpriseId)}
                              enterpriseTypes={enterpriseTypes || []}
                              apiFunctions={apiFunctions}
                              legalRegistrationDate={virsData.legalRegistrationDate}
                              legalDeregistrationDate={virsData.legalDeregistrationDate}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </ScrollXContainer>

                <TablePagination
                  recordsCount={filteredAndSorted.length}
                  pagesCount={pagesCount}
                  rowsPerPage={tableState.rowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  page={tableState.page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Paper>
            </div>
          </>
        </div>
      </Container>
    </div>
  );
};
