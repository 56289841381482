import React from 'react';
import { Typography, TableBody, TableRow, TableCell } from '@material-ui/core/';

import { LicensesData } from '../../../store/externalSystemData/externalSystemDataTypes';
import { ColumnsDisplayStatus } from './tableState/tableTypes';
import { stripHTML } from '../../../utils/stringHelper';

interface Props {
  data: LicensesData[];
  columnsDisplayStatus: ColumnsDisplayStatus;
}

const DataTableBody: React.FC<Props> = ({ data, columnsDisplayStatus }) => {
  return (
    <TableBody>
      {data.map((record) => (
        <TableRow key={record.licenseEnterpriseId}>
          {columnsDisplayStatus.licenseIssueDate && (
            <TableCell>
              <Typography>
                {record.licenseIssueDate
                  ? new Date(record.licenseIssueDate).toLocaleDateString('lt-LT')
                  : null}
              </Typography>
            </TableCell>
          )}
          {columnsDisplayStatus.licenseNumber && (
            <TableCell>
              <Typography>{record.licenseNumber}</Typography>
            </TableCell>
          )}
          {columnsDisplayStatus.licenseTypeName && (
            <TableCell>
              <Typography>{record.licenseTypeName}</Typography>
            </TableCell>
          )}

          {columnsDisplayStatus.enterpriseName && (
            <TableCell>
              <Typography>{record.enterpriseName}</Typography>
            </TableCell>
          )}

          {columnsDisplayStatus.licenseStatus && (
            <TableCell>
              <Typography>{record.licenseStatus}</Typography>
            </TableCell>
          )}

          {columnsDisplayStatus.validFrom && (
            <TableCell>
              <Typography>
                {record.validFrom ? new Date(record.validFrom).toLocaleDateString('lt-LT') : null}
              </Typography>
            </TableCell>
          )}
          {columnsDisplayStatus.validTo && (
            <TableCell>
              <Typography>
                {record.validTo ? new Date(record.validTo).toLocaleDateString('lt-LT') : null}
              </Typography>
            </TableCell>
          )}
          {columnsDisplayStatus.issuingInstitutionName && (
            <TableCell>
              <Typography>{record.issuingInstitutionName}</Typography>
            </TableCell>
          )}

          {columnsDisplayStatus.licenseNotes && (
            <TableCell>
              <Typography>{stripHTML(record.licenseNotes)}</Typography>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default DataTableBody;
