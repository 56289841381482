import { PoliticalAdColumnHeader } from './tableTypes';

export const columnNames: PoliticalAdColumnHeader[] = [
  {
    id: 'distributorName',
    label: 'Skleidejo pavadinimas'
  },
  {
    id: 'distributorCode',
    label: 'Kodas'
  },
  {
    id: 'interestedPartyName',
    label: 'Asmuo / grupė, kurių interesais skleista reklama'
  },
  {
    id: 'campaignName',
    label: 'Kampanijos pavadinimas'
  },
  {
    id: 'politicalAdDateFrom',
    label: 'Nuo',
    type: 'date'
  },
  {
    id: 'politicalAdDateTo',
    label: 'Iki',
    type: 'date'
  },
  {
    id: 'financialSupportAmount',
    label: 'Suma (EUR)'
  }
];
