import { makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import AllowedTo from '../../containers/AllowedTo';
import {
  NO_ADDING_TEXT,
  ADD_NEW_SHAREHOLDER_TEXT,
  VIRS_UNREGISTERED_TEXT
} from '../../containers/ShareholdersTable/constants';
import { getCurrentDateSigned } from '../../containers/ShareholdersTable/SelectedDateTable/utilities/utilityFunctions';
import { ApplicationState } from '../../store';
import {
  ClearShareholderPercentageRecord,
  ErrorProps,
  ShareholderPerson,
  ShareholdersData,
  ShareholdersProps
} from '../../store/shareholders/shareholdersTypes';
import { Roles } from '../../store/virsis/dataTypes';
import { ErrorIconWithTooltip } from '../ErrorIcon/ErrorIconWithTooltip';
import { ErrorFormatted } from './ErrorMessagesFormated';

import { ShareholderAddButton } from './TableButtons/ShareholderAddButton';
import ShareholderOptions from './TableButtons/ShareholderOptions';

interface Props {
  index: number;
  shareholder?: ShareholdersProps | ShareholdersData;
  mayShowErrorIcons: boolean;
  showAddShareholderButton?: boolean;
  mayShowOptionsButton?: boolean;
  criticalErrorMessages: ErrorProps[];
  nonCriticalErrorMessages: ErrorProps[];
  infoNotifications: ErrorProps[];
  handleAddShareholderButtonClicked?: () => void;
  tempShareholder?: ClearShareholderPercentageRecord;
  selectedDate?: string;
  isItGroupShareholder?: boolean;
}

const useStyles = makeStyles({
  flexEnd: {
    justifyContent: 'flex-end'
  }
});

const getShareholderEndDate = (
  shareholder: ShareholdersProps | ShareholdersData | undefined
): Moment | null => {
  const dateString = (shareholder as Partial<ShareholdersProps>)
    ?.shareholderStoppedParticipatingFromDate;
  if (dateString) {
    return moment(dateString);
  }
  return null;
};

export const ShareholderNameCellButtons: React.FC<Props> = ({
  index,
  shareholder,
  mayShowErrorIcons,
  showAddShareholderButton,
  mayShowOptionsButton,
  criticalErrorMessages,
  nonCriticalErrorMessages,
  handleAddShareholderButtonClicked,
  tempShareholder,
  infoNotifications,
  selectedDate,
  isItGroupShareholder
}) => {
  const classes = useStyles();
  const {
    shareholdersData: { shareholdersSelectedDateData, shareholdersData, lastSignedDate }
  } = useSelector((state: ApplicationState) => state);

  const shareholderEndDate = getShareholderEndDate(shareholder);
  const selectedDateObject = selectedDate ? moment(selectedDate) : null;

  const getAnyChildrenSigned = (shareholderElement: ShareholdersData | ShareholderPerson) =>
    shareholderElement.shareholders.some((item: ShareholdersProps) =>
      getCurrentDateSigned(item.shareholderHistory, selectedDate)
    );

  const getDisableAddButtonStatus = () => {
    if (lastSignedDate !== undefined && moment(selectedDate).isSameOrBefore(lastSignedDate)) {
      return true;
    }
    if (shareholdersData?.activityPeriods.length === 0) {
      return true;
    }
    if (shareholdersSelectedDateData?.virsStoppedParticipatingFromDate === selectedDate) {
      return true;
    }
    if (shareholder) {
      const { shareholderStartDate } = shareholder as ShareholdersProps;
      if (shareholderStartDate !== null && selectedDate && shareholderStartDate > selectedDate) {
        return true;
      }
      if ('virsName' in shareholder) {
        return getAnyChildrenSigned(shareholder);
      }
      if ('shareholderId' in shareholder) {
        return getAnyChildrenSigned(shareholder.shareholderPersons[0]);
      }
    }
    return false;
  };

  const shareholderParticipating =
    !!shareholderEndDate && !!selectedDateObject
      ? selectedDateObject.isSameOrBefore(shareholderEndDate)
      : true;

  const disableAddShareholderButton = getDisableAddButtonStatus() || !shareholderParticipating;

  const getTooltipText = () => {
    if (disableAddShareholderButton) {
      if (shareholdersSelectedDateData?.virsStoppedParticipatingFromDate === selectedDate) {
        return VIRS_UNREGISTERED_TEXT;
      }
      return NO_ADDING_TEXT;
    }
    return ADD_NEW_SHAREHOLDER_TEXT;
  };
  const tooltipText = getTooltipText();

  return (
    <div className={`flex-container ${classes.flexEnd}`}>
      {mayShowErrorIcons && !!nonCriticalErrorMessages.length && (
        <ErrorIconWithTooltip
          text={<ErrorFormatted errors={nonCriticalErrorMessages} index={index} />}
          errorType={nonCriticalErrorMessages[0].errorType}
          noWrap
        />
      )}

      {mayShowErrorIcons && !!criticalErrorMessages.length && (
        <ErrorIconWithTooltip
          text={<ErrorFormatted errors={criticalErrorMessages} index={index} />}
          errorType={criticalErrorMessages[0].errorType}
          noWrap
        />
      )}

      {mayShowErrorIcons && !!infoNotifications.length && (
        <ErrorIconWithTooltip
          text={<ErrorFormatted errors={infoNotifications} index={index} />}
          errorType={infoNotifications[0].errorType}
          noWrap
        />
      )}

      <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
        {showAddShareholderButton && handleAddShareholderButtonClicked && (
          <ShareholderAddButton
            onClick={handleAddShareholderButtonClicked}
            tooltipMessage={tooltipText}
            disabled={disableAddShareholderButton}
          />
        )}

        {mayShowOptionsButton && shareholder && 'shareholderId' in shareholder && (
          <ShareholderOptions
            index={index}
            percentageRecord={tempShareholder}
            selectedDate={selectedDate}
            isItGroupShareholder={isItGroupShareholder}
            shareholder={shareholder}
          />
        )}
      </AllowedTo>
    </div>
  );
};
