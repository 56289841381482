import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuditSearchForm } from '../../components/AuditSearch/AuditSearchForm';
import { AuditsTableWithContext } from '../../containers/AuditsTable/Context';
import { ApplicationState } from '../../store';
import { resetIntegratedSystemsAuditsData } from '../../store/audits/auditsActions';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('xs')]: {
    auditContainer: {
      padding: 0
    }
  }
}));

const InstitutionAuditsPage = () => {
  const {
    auditsData: { auditsData, loadingAuditsData, auditsDataError }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);
  const reduxDispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      reduxDispatch(resetIntegratedSystemsAuditsData());
    };
  }, [reduxDispatch]);

  return (
    <div className="data-page">
      <Container style={{ minHeight: '100%' }} maxWidth="xl">
        <Container maxWidth="lg" className={classes.auditContainer}>
          <div className="data-page-header">
            <Typography variant="h1">Auditai</Typography>
          </div>
          <Divider />
          <AuditSearchForm />
        </Container>
        {loadingAuditsData ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {auditsDataError ? (
              <>
                <Divider />
                <Typography variant="h3">{`Klaida. ${auditsDataError.message}.`}</Typography>
              </>
            ) : (
              <>
                <>
                  {auditsData ? (
                    <>
                      {auditsData.length === 0 ? (
                        <>
                          <Divider />
                          <Typography variant="h3">
                            Atsiprašome, pagal jūsų užklausą duomenų nerasta.
                          </Typography>
                        </>
                      ) : (
                        <AuditsTableWithContext />
                      )}
                    </>
                  ) : null}
                </>
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default InstitutionAuditsPage;
