import React, { useEffect } from 'react';
import { ConfirmRemoveDialog } from './ConfirmRemoveDialog';
import { unsignedDataRemoveResetState } from '../../../store/unsignedDataRemoval/unsignedDataActions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';

interface Props {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onRemoveSuccess?: () => void;
}

export const ConfirmRemoveUnsignedDialog: React.FC<Props> = ({
  open,
  onConfirm,
  onRemoveSuccess,
  onClose
}) => {
  const reduxDispatch = useDispatch();

  const {
    unsignedData: {
      removing: removingUnsignedProvidedData,
      removed: isUnsignedProvidedDataRemoved,
      removeError: removingUnsignedProvidedDataError
    }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  function closeOnRemoveUnsignedError() {
    onClose();
    reduxDispatch(unsignedDataRemoveResetState());
  }

  useEffect(() => {
    if (isUnsignedProvidedDataRemoved) {
      onRemoveSuccess?.();
      reduxDispatch(unsignedDataRemoveResetState());
    }
  }, [reduxDispatch, onRemoveSuccess, isUnsignedProvidedDataRemoved]);

  return (
    <ConfirmRemoveDialog
      dialogTitle="Nepasirašytų įrašų pašalinimas"
      dialogText="Ar tikrai norite pašalinti visus nepasirašytus įrašus?"
      mainButtonText="Pašalinti įrašus"
      open={open}
      onClose={onClose}
      onSubmit={onConfirm}
      isProcessing={removingUnsignedProvidedData}
      isSuccess={isUnsignedProvidedDataRemoved}
      isError={!!removingUnsignedProvidedDataError}
      errorMessage={removingUnsignedProvidedDataError?.message}
      onErrorClose={closeOnRemoveUnsignedError}
      onSuccessClose={onClose}
    />
  );
};
