import * as React from 'react';
import { Formik, Form } from 'formik';
import { Button, LinearProgress, Typography, FormGroup, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import * as Yup from 'yup';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import { FormikFormTextField } from '../../FormikFields/FormikFormTextField';
import { FormikFormDateField } from '../../FormikFields/FormikFormDateField';
import { FormikFormSelectField } from '../../FormikFields/FormikFormSelectField';
import { useDialogFormStyles } from '../../FormikFields/FieldStyles';
import { NaturalPersonSearchQuery } from '../../../store/persons/personsTypes';
import { todaysDateString } from '../../../utils/tableDataFunctions';
import { selectPersonSearchQuery } from '../../../store/persons/personsActions';
import { ApplicationState } from '../../../store';
import { NAME_CHECK_REGEX_LT } from '../../../utils/tableTypes';

export interface Props {
  jarCountries: JarCountry[];
  searchPerson: (personQuery: NaturalPersonSearchQuery, searchedCountry?: JarCountry) => void;
  isSearching: boolean;
}
interface Values {
  personType?: 'natural' | 'physical';
  country?: JarCountry;
  firstName?: string;
  lastName?: string;
  personCode?: string;
  birthDate?: Date | null;
}

const useStyles = makeStyles((theme) => ({
  formGroup: {
    maxWidth: 'fit-content'
  },
  [theme.breakpoints.down('sm')]: {
    formGroup: {
      maxWidth: 'unset'
    },
    searchButtonWrapper: {
      display: 'contents'
    },
    button: {
      marginLeft: 4
    }
  }
}));

export const FindPersonForm: React.FC<Props> = ({ jarCountries, searchPerson, isSearching }) => {
  const { selectedNaturalPersonSearchQuery } = useSelector(
    (state: ApplicationState) => state.personData
  );

  const reduxDispatch = useDispatch();
  const classes = useDialogFormStyles();
  const styledClasses = useStyles();

  const [initValues, setInitialValues] = useState<Partial<Values>>({
    personType: 'natural',
    country: jarCountries.find((country) => country?.countryCode === 'LTU'),
    firstName: '',
    lastName: '',
    personCode: '',
    birthDate: null
  });

  const lithuania = jarCountries.find((country) => country.countryCode === 'LTU');

  const clearForm = (
    setFieldValue: (id: string, value: string | null | undefined | JarCountry) => void
  ) => {
    setFieldValue('country', lithuania);
    setFieldValue('firstName', '');
    setFieldValue('lastName', '');
    setFieldValue('personCode', '');
    setFieldValue('birthDate', null);
  };

  const getBirthdate = (personBirthdate: Date | undefined | null): string | null => {
    return personBirthdate ? moment(personBirthdate).format('YYYY-MM-DD') : null;
  };

  const checkNationality = (country: string | null): boolean => {
    if (!country) return true;
    return country !== 'LTU';
  };

  const wrongSymbols = 'Įvesti netinkami simboliai';
  const dateWrongFormat = 'Data turi būti teisingo formato';

  const validationSchema = Yup.object({
    country: Yup.object().nullable(),
    firstName: Yup.string().trim().matches(NAME_CHECK_REGEX_LT, wrongSymbols),
    lastName: Yup.mixed().when('country', {
      is: (country: JarCountry) => country?.countryCode === 'LTU',
      then: Yup.string()
        .trim()
        .matches(NAME_CHECK_REGEX_LT, wrongSymbols)
        .required('Pavardė privaloma'),
      otherwise: Yup.string()
        .trim()
        .matches(NAME_CHECK_REGEX_LT, wrongSymbols)
        .required('Pavardė privaloma')
    }),
    personCode: Yup.mixed().when('country', {
      is: (country: JarCountry | null) => country?.countryCode !== 'LTU',
      then: Yup.mixed().when(['country', 'personCode', 'birthDate'], {
        is: (country: JarCountry, personCode: string, birthDate: Date | null) =>
          country?.countryCode !== 'LTU' && !personCode && !birthDate,
        then: Yup.string()
          .required('Asmens kodas arba gimimo data privaloma užsienio šalies atveju')
          .nullable(),
        otherwise: Yup.mixed().when(['country', 'personCode', 'birthDate'], {
          is: (country: JarCountry) => country?.countryCode === 'LTU',
          then: Yup.string().required('Asmens kodas privalomas').nullable(),
          otherwise: Yup.string()
        })
      }),
      otherwise: Yup.string().required().required('Asmens kodas privalomas').nullable()
    }),
    birthDate: Yup.mixed()
      .typeError('Data turi būti teisingo formato')
      .when('country', {
        is: (country: JarCountry | null) => country !== null,
        then: Yup.mixed().when(['country', 'personCode', 'birthDate'], {
          is: (country: JarCountry, personCode: string, birthDate: Date | null) =>
            country?.countryCode !== 'LTU' && !birthDate && !personCode,
          then: Yup.date()
            .required('Gimimo data arba asmens kodas privalomas užsienio šalies atveju')
            .typeError(dateWrongFormat)
            .max(
              new Date(moment().add(1, 'days').format('YYYY-MM-DD')),
              'Gimimo data negali būti vėlesnė negu šiandien'
            )
            .nullable(),
          otherwise: Yup.date().nullable().typeError(dateWrongFormat)
        }),
        otherwise: Yup.date().nullable().typeError(dateWrongFormat)
      })
  });

  useEffect(() => {
    setInitialValues({
      personType: 'natural',
      country: jarCountries.find(
        (country) =>
          country?.countryCode ===
          (selectedNaturalPersonSearchQuery?.countryCode || lithuania?.countryCode)
      ),
      firstName: selectedNaturalPersonSearchQuery?.naturalFirstname || '',
      lastName: selectedNaturalPersonSearchQuery?.naturalLastname || '',
      personCode: selectedNaturalPersonSearchQuery?.naturalPersonCode || '',
      birthDate: selectedNaturalPersonSearchQuery?.personBirthdate
        ? new Date(selectedNaturalPersonSearchQuery.personBirthdate)
        : null
    });
  }, [jarCountries, lithuania?.countryCode, selectedNaturalPersonSearchQuery]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery = {
          countryCode: values.country ? values.country?.countryCode : null,
          naturalFirstname: values.firstName ? values.firstName : null,
          naturalLastname: values.lastName ? values.lastName : null,
          personBirthdate: getBirthdate(values.birthDate),
          naturalPersonCode: values.personCode ? values.personCode : null
        };
        searchPerson(searchQuery, values.country);
        setSubmitting(false);
        reduxDispatch(selectPersonSearchQuery(searchQuery));
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        const isLithuanian = checkNationality(values.country?.countryCode || null);
        const personCodeRequired = isLithuanian && values.birthDate;
        const birthDateRequired = (!isLithuanian && !values.personCode) || values.personCode;
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Form>
              <FormGroup className={styledClasses.formGroup}>
                <FormikFormSelectField
                  styledClasses={classes}
                  getOptionLabel={(option?: JarCountry) => (option ? option.countryName : '')}
                  isClearable={isLithuanian}
                  isRequired
                  label="Šalis*"
                  formikKey="country"
                  value={values.country}
                  options={jarCountries}
                  errors={errors.country}
                  setFieldValue={setFieldValue}
                  defaultValue={lithuania}
                />
                <FormikFormTextField
                  styledClasses={classes}
                  label={`Vardas`}
                  formikKey="firstName"
                  values={values.firstName}
                  error={errors.firstName}
                  setFieldValue={setFieldValue}
                />
                <FormikFormTextField
                  styledClasses={classes}
                  label="Pavardė*"
                  formikKey="lastName"
                  values={values.lastName}
                  error={errors.lastName}
                  setFieldValue={setFieldValue}
                />
                <FormikFormTextField
                  styledClasses={classes}
                  maxLength={isLithuanian ? 30 : 11}
                  label={`Asmens kodas${personCodeRequired ? '' : '*'}`}
                  formikKey="personCode"
                  values={values.personCode}
                  error={errors.personCode}
                  setFieldValue={setFieldValue}
                />
                <FormikFormDateField
                  maxDate={todaysDateString()}
                  styledClasses={classes}
                  values={values.birthDate}
                  label={`Gimimo data${birthDateRequired ? '' : '*'}`}
                  formikKey="birthDate"
                  date={values.birthDate}
                  errors={errors.birthDate}
                  setFieldValue={setFieldValue}
                  isRequired={!isLithuanian}
                />
                <Box className={styledClasses.searchButtonWrapper}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isValidating || isSearching}
                    onClick={submitForm}
                    className={classes.button + ' ' + styledClasses.button}
                    startIcon={<SearchIcon />}
                  >
                    <Typography color="inherit">Ieškoti</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isValidating || isSearching}
                    onClick={() => clearForm(setFieldValue)}
                    className={classes.button + ' ' + styledClasses.button}
                    style={{ marginRight: '4px' }}
                    startIcon={<ClearIcon />}
                  >
                    <Typography color="inherit">Išvalyti</Typography>
                  </Button>
                </Box>
                {isSearching && <LinearProgress />}
              </FormGroup>
            </Form>
          </MuiPickersUtilsProvider>
        );
      }}
    </Formik>
  );
};
