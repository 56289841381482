import {
  dataContainsAnyNotSignedRecords,
  dataContainsAnyOutdatedRecords,
  dataContainsAnyRelevantRecords,
  dataContainsMoreThanOneRelevantRecord,
  Filter,
  filterData,
  getConfirmationAndRelevancyStatusFilter,
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { OutletInfoTablesState, OutletTableName } from './tablesTypesAndActions';
import {
  OutletAdPrinting,
  OutletCategory,
  OutletEdition,
  OutletInfo,
  OutletRepresentative
} from '../../../store/outletInfoData/outletInfoDataTypes';
import { Roles, VirsisUser } from '../../../store/virsis/dataTypes';
import { hasRoles } from '../../../utils/roleHelper';
import { AdDataTableState } from './adPrintingTableTypes';
import { OutletCategoryTableState } from './categoryTableTypes';
import { EditionColumnHeader, EditionDataTableState } from './editionTableTypes';
import { OutletData } from '../../../store/outlets/outletsTypes';
import { OutletDataTableField } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import { OutletMainDataTableState } from './mainOutletTableTypes';
import { OutletRepresentativesDataTableState } from './representativesTableTypes';
import { TableCustomFilter } from '../../../utils/tableTypes';

export function initializeTablesByOutletInfoData(
  state: OutletInfoTablesState,
  outletInfo: OutletInfo,
  currentUser: VirsisUser | undefined
): OutletInfoTablesState {
  const { outletData, representatives, editions, advertisements, categories } = outletInfo;

  const hideUnsignedReps =
    currentUser && !hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]);

  const mainOutletNotSignedEnabled =
    !hideUnsignedReps && dataContainsAnyNotSignedRecords(outletData);
  const mainOutletRelevantEnabled = dataContainsAnyRelevantRecords(outletData);
  const mainOutletOutdatedEnabled = dataContainsAnyOutdatedRecords(outletData);

  const representativesNotSignedEnabled =
    !hideUnsignedReps && dataContainsAnyNotSignedRecords(representatives);
  const representativesRelevantEnabled = dataContainsAnyRelevantRecords(representatives);
  const representativesOutdatedEnabled = dataContainsAnyOutdatedRecords(representatives);

  const editionsNotSignedEnabled = !hideUnsignedReps && dataContainsAnyNotSignedRecords(editions);
  const editionsRelevantEnabled = dataContainsAnyRelevantRecords(editions);
  const editionsOutdatedEnabled = dataContainsAnyOutdatedRecords(editions);

  const adPrintingNotSignedEnabled =
    !hideUnsignedReps && dataContainsAnyNotSignedRecords(advertisements);
  const adPrintingRelevantEnabled = dataContainsAnyRelevantRecords(advertisements);
  const adPrintingOutdatedEnabled = dataContainsAnyOutdatedRecords(advertisements);

  const categoriesNotSignedEnabled =
    !hideUnsignedReps && dataContainsAnyNotSignedRecords(categories);
  const categoriesRelevantEnabled = dataContainsAnyRelevantRecords(categories);
  const categoriesOutdatedEnabled =
    dataContainsMoreThanOneRelevantRecord(categories) || dataContainsAnyOutdatedRecords(categories);

  return {
    ...state,
    main: {
      ...state.main,
      statusNotSignedFilterEnabled: mainOutletNotSignedEnabled,
      statusRelevantFilterEnabled: mainOutletRelevantEnabled,
      statusOutdatedFilterEnabled: mainOutletOutdatedEnabled,
      showStatusNotSigned: mainOutletNotSignedEnabled,
      showStatusRelevant: mainOutletRelevantEnabled,
      showStatusOutdated: mainOutletOutdatedEnabled
    },
    representatives: {
      ...state.representatives,
      statusNotSignedFilterEnabled: representativesNotSignedEnabled,
      statusRelevantFilterEnabled: representativesRelevantEnabled,
      statusOutdatedFilterEnabled: representativesOutdatedEnabled,
      showStatusNotSigned: representativesNotSignedEnabled,
      showStatusRelevant: representativesRelevantEnabled,
      showStatusOutdated: representativesOutdatedEnabled
    },
    editions: {
      ...state.editions,
      periodic: outletInfo.outletIsPeriodic,
      statusNotSignedFilterEnabled: editionsNotSignedEnabled,
      statusRelevantFilterEnabled: editionsRelevantEnabled,
      statusOutdatedFilterEnabled: editionsOutdatedEnabled,
      showStatusNotSigned: editionsNotSignedEnabled,
      showStatusRelevant: editionsRelevantEnabled,
      showStatusOutdated: editionsOutdatedEnabled,
      showNewRecord: false
    },
    adprinting: {
      ...state.adprinting,
      statusNotSignedFilterEnabled: adPrintingNotSignedEnabled,
      statusRelevantFilterEnabled: adPrintingRelevantEnabled,
      statusOutdatedFilterEnabled: adPrintingOutdatedEnabled,
      showStatusNotSigned: adPrintingNotSignedEnabled,
      showStatusRelevant: adPrintingRelevantEnabled,
      showStatusOutdated: adPrintingOutdatedEnabled,
      showNewRecord: false
    },
    categories: {
      ...state.categories,
      statusNotSignedFilterEnabled: categoriesNotSignedEnabled,
      statusRelevantFilterEnabled: categoriesRelevantEnabled,
      statusOutdatedFilterEnabled: categoriesOutdatedEnabled,
      showStatusNotSigned: categoriesNotSignedEnabled,
      showStatusRelevant: categoriesRelevantEnabled,
      showStatusOutdated: categoriesOutdatedEnabled,
      showNewRecord: false
    }
  };
}

export function toggleUnsignedStatusFilterByTable(
  state: OutletInfoTablesState,
  table: OutletTableName
): OutletInfoTablesState {
  return {
    ...state,
    [table]: {
      ...state[table],
      showStatusNotSigned: !state[table].showStatusNotSigned,
      page: 0
    }
  };
}

export function toggleRelevantStatusFilterByTable(
  state: OutletInfoTablesState,
  table: OutletTableName
): OutletInfoTablesState {
  return {
    ...state,
    [table]: {
      ...state[table],
      showStatusRelevant: !state[table].showStatusRelevant,
      page: 0
    }
  };
}

export function toggleOutdatedStatusFilterByTable(
  state: OutletInfoTablesState,
  table: OutletTableName
): OutletInfoTablesState {
  return {
    ...state,
    [table]: {
      ...state[table],
      showStatusOutdated: !state[table].showStatusOutdated,
      page: 0
    }
  };
}

export function toggleOutletTablesCustomFilter(
  state: OutletInfoTablesState,
  table: OutletTableName
): OutletInfoTablesState {
  const resetCustomFilter: TableCustomFilter<OutletTableName> =
    {} as TableCustomFilter<OutletTableName>;
  Object.keys(state[table].customFilter).forEach((value: string) => {
    resetCustomFilter[value as OutletTableName] = [];
  });
  return {
    ...state,
    [table]: {
      ...state[table],
      customFilterOn: !state[table].customFilterOn,
      customFilter: resetCustomFilter
    }
  };
}

export function changeOutletTablesPage(
  state: OutletInfoTablesState,
  table: OutletTableName,
  page: number
) {
  return {
    ...state,
    [table]: {
      ...state[table],
      page
    }
  };
}

export function changeOutletTablesRowsPerPage(
  state: OutletInfoTablesState,
  table: OutletTableName,
  rowsPerPage: number
) {
  return {
    ...state,
    [table]: {
      ...state[table],
      rowsPerPage
    }
  };
}

export function openOutletTablesNewRecordRow(state: OutletInfoTablesState, table: OutletTableName) {
  return {
    ...state,
    [table]: {
      ...state[table],
      showNewRecord: true
    }
  };
}

export function closeOutletTablesNewRecordRow(
  state: OutletInfoTablesState,
  table: OutletTableName
) {
  return {
    ...state,
    [table]: {
      ...state[table],
      showNewRecord: false
    }
  };
}

export function filterAndSortAdPrintingData(
  data: OutletAdPrinting[],
  tableState: AdDataTableState
): OutletAdPrinting[] {
  if (data.length === 0) {
    return [];
  }
  const filters: Filter<OutletAdPrinting>[] = [
    getConfirmationAndRelevancyStatusFilter(
      tableState.showStatusNotSigned,
      tableState.showStatusRelevant,
      tableState.showStatusOutdated
    ),
    getStringCompareMoreFilter(tableState.customFilter.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(tableState.customFilter.validTo, ({ validTo }) => [validTo])
  ];

  const filtered = filterData(data, filters);

  return stableSortWrapped(filtered, tableState.order, tableState.sortBy);
}

export function filterAndSortCategoryData(
  data: OutletCategory[],
  tableState: OutletCategoryTableState
): OutletCategory[] {
  if (data.length === 0) {
    return [];
  }

  const { showStatusNotSigned, showStatusRelevant, showStatusOutdated } = tableState;
  const filters: Filter<OutletCategory>[] = [
    getConfirmationAndRelevancyStatusFilter(
      showStatusNotSigned,
      showStatusRelevant,
      showStatusOutdated
    ),
    getStringMappedColumnFilter(
      tableState?.customFilter?.categoryTypeName,
      ({ categoryTypeName }) => [categoryTypeName]
    ),
    getStringCompareMoreFilter(tableState.customFilter.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(tableState.customFilter.validTo, ({ validTo }) => [validTo])
  ];

  const filtered = filterData(data, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}

export function filterEditionColumnsByPeriodicOrNotPeriodic(
  columnNames: EditionColumnHeader[],
  isPeriodic: boolean
) {
  return columnNames.filter((column) => column.id !== (isPeriodic ? 'editionDate' : 'periodName'));
}

export function filterAndSortEditionTableData(
  data: OutletEdition[],
  tableState: EditionDataTableState
): OutletEdition[] {
  if (data.length === 0) {
    return [];
  }

  const { showStatusNotSigned, showStatusRelevant, showStatusOutdated } = tableState;
  const filters: Filter<OutletEdition>[] = [
    getConfirmationAndRelevancyStatusFilter(
      showStatusNotSigned,
      showStatusRelevant,
      showStatusOutdated
    ),
    getStringMappedColumnFilter(tableState.customFilter.periodName, ({ periodName }) => [
      periodName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.editionDate, ({ editionDate }) => [
      editionDate
    ]),
    getStringMappedColumnFilter(tableState.customFilter.circulation, ({ circulation }) => [
      `${circulation}`
    ])
  ];

  const filtered = filterData(data, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}

export function getUniqueOutletDataOptions(
  data: OutletData[],
  objectField: OutletDataTableField
): string[] {
  const allValues = data
    .filter((record) => record[objectField] && record[objectField] !== '')
    .map((record) => record[objectField]);
  return Array.from(new Set(allValues));
}

export function filterAndSortMainOutletTableData(
  data: OutletData[],
  tableState: OutletMainDataTableState
): OutletData[] {
  if (data.length === 0) {
    return [];
  }
  const {
    showStatusNotSigned: showStatusUnconfirmed,
    showStatusRelevant,
    showStatusOutdated: showStatusNotRelevant
  } = tableState;

  const filters: Filter<OutletData>[] = [
    getConfirmationAndRelevancyStatusFilter(
      showStatusUnconfirmed,
      showStatusRelevant,
      showStatusNotRelevant
    ),
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ outletName }) => [
      outletName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.outletTypeName, ({ outletTypeName }) => [
      outletTypeName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.outletGroupName, ({ outletGroupName }) => [
      outletGroupName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.establishedDate, ({ establishedDate }) => [
      establishedDate
    ]),
    getStringCompareMoreFilter(tableState.customFilter.controlledFrom, ({ controlledFrom }) => [
      controlledFrom
    ]),
    getStringCompareLessFilter(tableState.customFilter.controlledTo, ({ controlledTo }) => [
      controlledTo
    ]),
    getStringMappedColumnFilter(tableState.customFilter.controlledTo, ({ internationalNumber }) => [
      internationalNumber
    ])
  ];

  const filtered = filterData(data, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}

export function filterAndSortRepresentativesTableData(
  data: OutletRepresentative[],
  tableState: OutletRepresentativesDataTableState
): OutletRepresentative[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;

  const {
    showStatusNotSigned: showStatusUnconfirmed,
    showStatusRelevant,
    showStatusOutdated: showStatusNotRelevant
  } = tableState;

  const filters: Filter<OutletRepresentative>[] = [
    getConfirmationAndRelevancyStatusFilter(
      showStatusUnconfirmed,
      showStatusRelevant,
      showStatusNotRelevant
    ),
    getStringMappedColumnFilter(columnsFilters.representativeName, ({ representativeName }) => [
      representativeName
    ]),
    getStringCompareMoreFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(columnsFilters.representationNotes, ({ representationNotes }) => [
      representationNotes
    ])
  ];

  const filtered = filterData(data, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
