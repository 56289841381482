import { axiosDelete, get, put } from '../../utils/axiosApi';
import { UserMessageRecord } from './userMessageTypes';

export const getUserMessages = () => {
  return get('/messages');
};

export const deleteRemoveUserMessages = (messages: UserMessageRecord[]) => {
  return axiosDelete('/messages', messages);
};

export const putMarkUserMessagesRead = (messages: UserMessageRecord[]) => {
  return put('/messages/read', messages);
};

export const putMarkUserMessagesUnread = (messages: UserMessageRecord[]) => {
  return put('/messages/unread', messages);
};
