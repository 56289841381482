import theme from '../../style/virsisTheme';
import {
  CustomDatePickerProps,
  ShareholdersColumnsHeader
} from './MainTable/tableState/tableInitialStateAndTypes';
import { ShareholdersSelectedDateColumnHeader } from './SelectedDateTable/tableState/tableInitialStateAndTypes';

export const cssVariables = {
  // date column width in shareholders main table
  dateColumnWidthNarrow: {
    width: 100
  },
  dateColumnHeight: {
    minHeight: 85
  }
};

export const groupRowColor = '#f3f3f3';
export const naturalPersonColor = '#2C7837';

export const mainDarkColor = theme.palette.text.primary;
export const virsColor = theme.palette.primary.main;
export const whiteColor = theme.palette.secondary.light;

export const commonColumnNames: ShareholdersColumnsHeader[] = [
  {
    id: 'shareholderStartDate',
    label: 'Pagal dalyvavimo datą'
  },
  {
    id: 'shareholderName',
    label: 'Pagal abėcėlę'
  }
];

export const selectedDateTableColumnNames: ShareholdersSelectedDateColumnHeader[] = [
  {
    id: 'shareholderPercentage',
    label: 'Pagal dalį'
  }
];

export const VIRS_UNREGISTERED_TEXT = 'VIRS išregistruotas';
export const NO_ADDING_TEXT = 'Pridėjimas nėra galimas';
export const ADD_NEW_SHAREHOLDER_TEXT = 'Pridėti naują dalyvį';
export const NO_VALID_PARENT_TEXT = 'Šiam VIRS dalyviai ir jų įvykių datos yra gaunami iš JADIS';
export const NO_VALID_DATE_TEXT = 'Visos datos šiam VIRS jau yra pridėtos';
export const NO_DATA_ERROR_TEXT = 'Klaida. Nepavyko gauti duomenų';
export const DATE_NOT_SELECTED_ERROR_TEXT = 'Klaida. Nepasirinkta data';
export const VIRS_END_DATE_TEXT = 'VIRS išregistravimo iš JAR data, jos pašalinti negalima';

export const DATE_FROM_JADIS_TEXT = 'Gauta iš JADIS data';
export const ENTERPRISE_START_DATE_TEXT = 'VIRS veiklų pradžios data';
export const ENTERPRISE_UPDATE_DATE_TEXT = 'VIRS veiklų atnaujinimo data';
export const ENTERPRISE_END_DATE_TEXT = 'VIRS veiklų pabaigos data';
export const TODAYS_DATE_TEXT = 'Šiandienos data';
export const NO_EVENTS_TEXT = 'Šalinamų duomenų nėra';
export const SIGNED_DATE_TEXT = 'Data pasirašyta';

export const dateCalendarFormat: CustomDatePickerProps = {
  format: 'YYYY-MM-DD',
  invalidDateMessage: 'mmmm-mm-dd',
  openTo: 'date',
  placeholder: 'mmmm-mm-dd',
  views: ['year', 'month', 'date']
};

export const monthCalendarFormat: CustomDatePickerProps = {
  format: 'YYYY-MM',
  invalidDateMessage: 'mmmm-mm',
  openTo: 'month',
  placeholder: 'mmmm-mm',
  views: ['year', 'month']
};

export const yearCalendarFormat: CustomDatePickerProps = {
  format: 'YYYY',
  invalidDateMessage: 'mmmm',
  openTo: 'year',
  placeholder: 'mmmm',
  views: ['year']
};

export const DAY_LABEL = 'Dienas';
export const DATE_LABEL = 'Pasikeitimų datas';
export const MONTH_LABEL = 'Mėnesius';
export const YEAR_LABEL = 'Metus';

export type DateTypes = 'day' | 'year' | 'month' | 'date' | 'mixed' | 'changeDate';
