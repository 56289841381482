import React, { createContext, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Container, Typography } from '@material-ui/core';

import { ApplicationState } from '../../store';

import {
  ClassifierMenu,
  getClassifierTitle
} from '../../containers/classifiers/GenericClassifierTable/ClassifierMenu';
import { Classifiers } from '../../containers/classifiers/GenericClassifierTable/types';
import { LegalFormTypeTable } from '../../containers/classifiers/LegalFormTypeTable/LegalFormTypeTable';
import { EnterpriseTypeTable } from '../../containers/classifiers/EnterpriseTypeTable/EnterpriseTypeTable';
import { ParametersPage } from '../../containers/ParametersTableAdmin/ParametersPage';
import { getLegalFormStore } from '../../containers/classifiers/LegalFormTypeTable/store';
import { getEnterpriseTypeStore } from '../../containers/classifiers/EnterpriseTypeTable/store';
import { getOutletGroupStore } from '../../containers/classifiers/OutletGroupTable/store';
import { OutletGroupTable } from '../../containers/classifiers/OutletGroupTable/OutletGroupTable';
import { OutletTypeTable } from '../../containers/classifiers/OutletTypeTable/OutletTypeTable';
import { getOutletTypeStore } from '../../containers/classifiers/OutletTypeTable/store';
import { sendMessage } from '../../store/errorOrSuccessMessage/errorOrSuccessMessageAction';
import { FormSagaContext } from '../../components/FormikFields/GenericFormikWrappers/types';
import { LicencedEnterpriseTable } from '../../containers/classifiers/LicencedEnterprisesTable/LicencedEnterpriseTable';
import { getLicencedEnterpriseStore } from '../../containers/classifiers/LicencedEnterprisesTable/store';
import { getMessageTypeStore } from '../../containers/classifiers/MessageTypeTable/store';
import { MessageTypeTable } from '../../containers/classifiers/MessageTypeTable/MessageTypeTable';
import { getMisconductTypeStore } from '../../containers/classifiers/MisconductTypeTable/store';
import { MisconductTypeTable } from '../../containers/classifiers/MisconductTypeTable/MisconductTypeTable';
import { getSanctionTypeStore } from '../../containers/classifiers/SanctionTypeTable/store';
import { SanctionTypeTable } from '../../containers/classifiers/SanctionTypeTable/SanctionTypeTable';
import { getOutletCategoryStore } from '../../containers/classifiers/OutletCategotyTable/store';
import { OutletCategoryTable } from '../../containers/classifiers/OutletCategotyTable/OutletCategoryTable';
import { getAnnulmentTypeStore } from '../../containers/classifiers/AnnulmentTypeTable/store';
import { AnnulmentTypeTable } from '../../containers/classifiers/AnnulmentTypeTable/AnnulmentTypeTable';
import { getEditionCheckConclusionStore } from '../../containers/classifiers/EditionCheckConclusionTable/store';
import { EditionCheckConclusionTable } from '../../containers/classifiers/EditionCheckConclusionTable/EditionCheckConclusionTable';
import { getTransactionTypeStore } from '../../containers/classifiers/TransactionTypeTable/store';
import { TransactionTypeTable } from '../../containers/classifiers/TransactionTypeTable/TransactionTypeTable';
import { PaymentAmountTable } from '../../containers/classifiers/PaymentAmountTable/PaymentAmountTable';
import { getPaymentAmountStore } from '../../containers/classifiers/PaymentAmountTable/store';
import { getEthicalNonComplianceTypeStore } from '../../containers/classifiers/EthicalNonComplianceTypeTable/store';
import { EthicalNonComplianceTypeTable } from '../../containers/classifiers/EthicalNonComplianceTypeTable/EthicalNonComplianceTypeTable';
import { EditionPeriodTable } from '../../containers/classifiers/EditionPeriodTable/EditionPeriodTable';
import { getEditionPeriodStore } from '../../containers/classifiers/EditionPeriodTable/store';
import { getFilesStore } from '../../containers/classifiers/FilesTable/store';
import { FileTable } from '../../containers/classifiers/FilesTable/FileTable';

export const tableTitleContext = createContext<string | undefined>(undefined);

export const ClassifierManagementPage: React.FC = () => {
  const loading = useSelector((state: ApplicationState) => {
    return state.virsis.fetchUserLoading || state.userMessages.loadingUserMessages;
  });

  const [activeClassifier, setActiveClassifier] = useState<Classifiers>(Classifiers.LEGAL_FORMS);

  return (
    <Container maxWidth="xl" className="data-page">
      <h2>Klasifikatorių tvarkymas</h2>

      <ClassifierMenu activeClassifier={activeClassifier} onChange={setActiveClassifier} />

      {loading && <CircularProgress />}

      {!loading && (
        <tableTitleContext.Provider value={getClassifierTitle(activeClassifier)}>
          <ClassifierTable classifier={activeClassifier} />
        </tableTitleContext.Provider>
      )}
    </Container>
  );
};

interface Props {
  classifier: Classifiers;
}

const ClassifierTable: React.FC<Props> = ({ classifier }) => {
  const dispatch = useDispatch();
  const formSagaContext: FormSagaContext = {
    formSubmitSnackbar: (success: boolean) =>
      dispatch(
        sendMessage(
          success ? 'success' : 'error',
          success ? 'Įrašas išsaugotas sėkmingai' : 'Įrašo išsaugoti nepavyko'
        )
      )
  };

  switch (classifier) {
    case Classifiers.LEGAL_FORMS:
      return (
        <Provider store={getLegalFormStore(formSagaContext)}>
          <LegalFormTypeTable />
        </Provider>
      );
    case Classifiers.ENTERPRISE_TYPES:
      return (
        <Provider store={getEnterpriseTypeStore(formSagaContext)}>
          <EnterpriseTypeTable />
        </Provider>
      );
    case Classifiers.OUTLET_GROUPS:
      return (
        <Provider store={getOutletGroupStore(formSagaContext)}>
          <OutletGroupTable />
        </Provider>
      );
    case Classifiers.OUTLET_TYPES:
      return (
        <Provider store={getOutletTypeStore(formSagaContext)}>
          <OutletTypeTable />
        </Provider>
      );
    case Classifiers.LICENCED_ENTERPRISES:
      return (
        <Provider store={getLicencedEnterpriseStore(formSagaContext)}>
          <LicencedEnterpriseTable />
        </Provider>
      );
    case Classifiers.MESSAGE_TYPES:
      return (
        <Provider store={getMessageTypeStore(formSagaContext)}>
          <MessageTypeTable />
        </Provider>
      );
    case Classifiers.MISCONDUCT_TYPES:
      return (
        <Provider store={getMisconductTypeStore(formSagaContext)}>
          <MisconductTypeTable />
        </Provider>
      );
    case Classifiers.SANCTION_TYPES:
      return (
        <Provider store={getSanctionTypeStore(formSagaContext)}>
          <SanctionTypeTable />
        </Provider>
      );
    case Classifiers.OUTLET_CATEGORIES:
      return (
        <Provider store={getOutletCategoryStore(formSagaContext)}>
          <OutletCategoryTable />
        </Provider>
      );
    case Classifiers.ANNULMENT_TYPES:
      return (
        <Provider store={getAnnulmentTypeStore(formSagaContext)}>
          <AnnulmentTypeTable />
        </Provider>
      );
    case Classifiers.EDITION_CHECK_CONCLUSIONS:
      return (
        <Provider store={getEditionCheckConclusionStore(formSagaContext)}>
          <EditionCheckConclusionTable />
        </Provider>
      );
    case Classifiers.TRANSACTION_TYPES:
      return (
        <Provider store={getTransactionTypeStore(formSagaContext)}>
          <TransactionTypeTable />
        </Provider>
      );
    case Classifiers.PAYMENT_AMOUNTS:
      return (
        <Provider store={getPaymentAmountStore(formSagaContext)}>
          <PaymentAmountTable />
        </Provider>
      );
    case Classifiers.ETHICAL_NON_COMPLIANCE_TYPES:
      return (
        <Provider store={getEthicalNonComplianceTypeStore(formSagaContext)}>
          <EthicalNonComplianceTypeTable />
        </Provider>
      );
    case Classifiers.EDITION_PERIODS:
      return (
        <Provider store={getEditionPeriodStore(formSagaContext)}>
          <EditionPeriodTable />
        </Provider>
      );
    case Classifiers.FILES:
      return (
        <Provider store={getFilesStore(formSagaContext)}>
          <FileTable />
        </Provider>
      );
    case Classifiers.SYSTEM_PARAMETER:
      return <ParametersPage />;
    default:
      return <Typography>Nėra klasifikatoriaus implementacijos</Typography>;
  }
};
