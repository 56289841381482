import React from 'react';
import { useDispatch } from 'react-redux';
import { TableFilterBySharesAndVotes } from '../../../components/ShareholderComponents/TableButtons/FilterButtons/FilterBySharesAndVotes';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import { filterUnsignedToggled } from '../../../store/shareholders/shareholdersActions';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import SelectDropdown from '../TableFilters/SelectDropdown';
import ShareholdersCustomTools from '../TableFilters/ShareholdersCustomTools';
import TableStatusFilters from '../TableFilters/TableStatusFilters';
import {
  useShareholdersTableState,
  useShareholdersTableDispatch
} from './ShareholdersTableWithContext';
import {
  ShareholdersColumnsHeader,
  ShareholdersTableField,
  Period
} from './tableState/tableInitialStateAndTypes';

interface Props {
  columnNames: ShareholdersColumnsHeader[];
  allEventsDates?: string[];
  virsId?: number;
  isShowStatusUnsigned: boolean;
  isShowUnsignedShareholdersEnabled: boolean;
}

export const ShareholdersCustomizationBar: React.FC<Props> = ({
  columnNames,
  allEventsDates,
  virsId,
  isShowStatusUnsigned,
  isShowUnsignedShareholdersEnabled
}) => {
  const { state } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  const reduxDispatch = useDispatch();

  const handleSetSortByColumn = (column: ShareholdersTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleNotSignedFilter = () => {
    if (virsId) {
      reduxDispatch(filterUnsignedToggled(!isShowStatusUnsigned));
    }
  };

  const handlePeriodFilterChange = (value: Period) => {
    tableDispatch({ type: 'PERIOD_FILTER_VALUE_CHANGED', value });
  };

  const handleShowSharesClicked = () => {
    tableDispatch({
      type: 'SET_SHOW_SHARES_VOTES',
      showShares: true,
      showVotes: false
    });
  };

  const handleSetShowVotesClicked = () => {
    tableDispatch({
      type: 'SET_SHOW_SHARES_VOTES',
      showShares: false,
      showVotes: true
    });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <>
            <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
              <TableStatusFilters
                notSignedFilterDisabled={!isShowUnsignedShareholdersEnabled}
                notSignedFilterOn={isShowStatusUnsigned && isShowUnsignedShareholdersEnabled}
                onNotSignedFilterToggle={toggleNotSignedFilter}
              />
            </AllowedTo>
            <TableFilterBySharesAndVotes
              onShowSharesClicked={handleShowSharesClicked}
              onShowVotesClicked={handleSetShowVotesClicked}
              sharesButtonDisabled={!!state.showShares}
              votesButtonDisabled={!!state.showVotes}
            />
          </>
        }
        rightSideItem={
          <>
            <SelectDropdown
              setPeriodFilter={handlePeriodFilterChange}
              periodFilter={state.periodFilter}
            />
            <ShareholdersCustomTools
              tableColumns={columnNames}
              sortOrder={state.order}
              sortBy={state.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              allEventsDates={allEventsDates}
              mainTable
            />
          </>
        }
      />
    </div>
  );
};
