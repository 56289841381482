import { PaymentDataColumnHeader } from './tableState/paymentTableInitialStateAndTypes';

export const columnNames: PaymentDataColumnHeader[] = [
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'decisionDate',
    label: 'Sprendimo data',
    type: 'date'
  },
  {
    id: 'paymentDocuments',
    label: 'Sprendimo nr.'
  },
  {
    id: 'paymentOutlets',
    label: 'Susijusios VIP'
  },
  {
    id: 'paymentAmount',
    label: 'Įmokos dydis'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  }
];
