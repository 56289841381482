import { Typography, RadioGroup } from '@material-ui/core';
import React, { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { ShareholderShareTypes } from '../../../../../../../../store/classifiers/classifiersTypes';
import {
  ShareholdersData,
  ShareholderPerson
} from '../../../../../../../../store/shareholders/shareholdersTypes';
import { FormActions } from './FormActions';
import { useFormStyles } from './FormStyles';
import CreateShareholderLoadingContainer from '../../CreateShareholderLoadingContainer';
import { FormRadioButtonWithTooltip } from './FormRadioButtonWithTooltip';
import { radioGroupName } from './formTypes';
import { ApplicationState } from '../../../../../../../../store';

interface Props {
  isProcessing: boolean;
  isSynchronizingJADIS: boolean;
  onClose: () => void;
  onFormSubmit: (
    shareholderShareTypes: ShareholderShareTypes,
    selectedAnchorElement: HTMLButtonElement
  ) => void;
  parentShareholder: ShareholderPerson | ShareholdersData;
}

export const ForeignParentLegalFormTypesForm: React.FC<Props> = ({
  isProcessing,
  isSynchronizingJADIS,
  onClose,
  onFormSubmit,
  parentShareholder
}) => {
  const classes = useFormStyles({ justifyContent: 'space-between' });

  const { shareTypes } = useSelector((state: ApplicationState) => state.classifiers);

  const { shareTypeName, mayHaveSharePercentage } = parentShareholder;

  const initialShareTypeValue = () => {
    if (mayHaveSharePercentage) {
      const shareType = shareTypes?.find((element) => element.shareTypeName === shareTypeName);
      return shareType ? shareType.shareTypeId : 0;
    }
    return 0;
  };

  const [shareTypeValue, setShareTypeValue] = useState(initialShareTypeValue());
  const siblingCount = parentShareholder.shareholders.length;

  const radioGroupShares = shareTypes?.map((type) => ({
    label: `${type.shareTypeName} (%)`,
    value: type.shareTypeId
  }));

  const radioGroupSharesForeign = radioGroupShares && [
    ...radioGroupShares,
    {
      label: 'Netaikoma',
      value: 0
    }
  ];

  const handleShareTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShareTypeValue(Number(event.target.value));
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    onFormSubmit(
      {
        shareTypeId: shareTypeValue || null,
        hasVotePercentage: true,
        hasVotesOnRules: true
      },
      event.currentTarget
    );
  };

  return (
    <form className={classes.shareTypesContainer}>
      <div>
        <div className={classes.flexContainer}>
          <div className={classes.radioButtonContainer}>
            <Typography className={classes.shareLabel} variant="h6">
              {radioGroupName.shares}
            </Typography>

            <RadioGroup
              aria-label="shareType"
              name="shareType"
              value={shareTypeValue}
              onChange={handleShareTypeChange}
            >
              {radioGroupSharesForeign &&
                radioGroupSharesForeign.map((radioButton) => (
                  <span key={radioButton.label}>
                    <FormRadioButtonWithTooltip
                      radioButton={radioButton}
                      radioGroupValue={shareTypeValue}
                      siblingCount={siblingCount}
                    />
                  </span>
                ))}
            </RadioGroup>
          </div>
        </div>
      </div>

      {isSynchronizingJADIS || isProcessing ? (
        <CreateShareholderLoadingContainer isSynchronizingJADIS={isSynchronizingJADIS} />
      ) : (
        <FormActions
          onSubmit={handleSubmit}
          onClose={onClose}
          parentShareholder={parentShareholder}
        />
      )}
    </form>
  );
};
