import { SortOrder } from '../../utils/tableTypes';
import { EditionCheckDataTableField } from '../../containers/EditionCheckTableInst/tableState/tableTypesAndActions';
import { EditionChecksDataTableState, EditionChecksPageRequest } from './editionChecksTableTypes';
import { ApplicationState } from '../index';
import { EditionCheckDataTableState } from './editionChecksTableReducer';
import moment from 'moment';

const getFilterDate = (
  state: EditionChecksDataTableState,
  value: EditionCheckDataTableField
): string | null => {
  return state.customFilter[value][0]?.trim()
    ? moment(new Date(state.customFilter[value][0].trim())).format('YYYY-MM-DD')
    : null;
};

const getFilterString = (
  state: EditionChecksDataTableState,
  value: EditionCheckDataTableField
): string | null => state.customFilter[value][0]?.trim() || null;

const getFilterNumber = (
  state: EditionChecksDataTableState,
  value: EditionCheckDataTableField
): number | null => Number(state.customFilter[value][0]) || null;

const getSorting = (
  state: EditionChecksDataTableState,
  value: EditionCheckDataTableField
): SortOrder | null => (state.sortBy === value ? state.order : null);

export const getEditionCheckDataPageRequest = (
  state: EditionChecksDataTableState
): EditionChecksPageRequest => {
  return {
    docStatusOutdated: state.showStatusOutdated,
    docStatusNotSigned: state.showStatusNotSigned,
    docStatusRelevant: state.showStatusRelevant,
    filterCheckDate: getFilterDate(state, 'checkDate'),
    filterConclusionId: getFilterNumber(state, 'conclusion'),
    filterDocNr: getFilterString(state, 'docNr'),
    filterOutletId: getFilterNumber(state, 'outletName'),
    filterPeriodId: getFilterNumber(state, 'periodName'),
    filterVirsId: getFilterNumber(state, 'virsName'),
    pageNr: state.rowsPerPage * state.page + 1,
    pageSize: state.rowsPerPage,
    sortingCheckDate: getSorting(state, 'checkDate'),
    sortingConclusion: getSorting(state, 'conclusion'),
    sortingDocNr: getSorting(state, 'docNr'),
    sortingOutletName: getSorting(state, 'outletName'),
    sortingPeriodName: getSorting(state, 'periodName'),
    sortingVirsName: getSorting(state, 'virsName')
  };
};

export const selectEditionCheckTableDataState = (
  state: ApplicationState
): EditionCheckDataTableState => state.editionDataTable;
