import { LicensesColumnHeader } from './tableTypes';

export const columnNames: LicensesColumnHeader[] = [
  {
    id: 'licenseIssueDate',
    label: 'Licencijos data',
    type: 'date'
  },
  {
    id: 'licenseNumber',
    label: 'Licencijos nr.'
  },
  {
    id: 'licenseTypeName',
    label: 'Licencijos tipas'
  },
  {
    id: 'enterpriseName',
    label: 'Licencijuojamos veiklos pavadinimas'
  },
  {
    id: 'licenseStatus',
    label: 'Licencijos būsena'
  },
  {
    id: 'validFrom',
    label: 'Galioja nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Galioja iki',
    type: 'date'
  },
  {
    id: 'issuingInstitutionName',
    label: 'Licenciją išdavė'
  },
  {
    id: 'licenseNotes',
    label: 'Pastaba / komentaras'
  }
];
