import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import { NaturalForeignPersonRecord } from '../../../store/persons/personsTypes';

export interface Values {
  firstName?: string;
  lastName?: string;
  personCode?: string;
  birthDate?: Date | null;
  documentCountry: JarCountry | null;
  documentNr?: string;
  documentType?: PersonDocumentType | null;
  documentIssueDate?: Date | null;
  documentExpirationDate?: Date | null;
  documentInfoRequired: boolean;
}

export interface RowState {
  naturalForeignPerson?: NaturalForeignPersonRecord;
  showConfirmation: boolean;
  confirmationAnchor: HTMLButtonElement | null;
}

export const DOCUMENT_OPTIONS = [
  { value: 'P', title: 'Pasas' },
  { value: 'I', title: 'Tapatybės kortelė' }
];

export interface PersonDocumentType {
  value: string;
  title: string;
}
