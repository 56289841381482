import { DatePickerView } from '@material-ui/pickers';
import { Order } from '../../../../components/TableTypes/TableTypes';
import { ColumnHeader } from '../../../../utils/tableTypes';
import { DateTypes } from '../../constants';

export const initialShareholdersTableState: ShareholdersTableState = {
  order: 'asc',
  sortBy: 'shareholderName',
  columnsDisplaystatus: {
    shareholderName: true,
    shareholderStartDate: true
  },
  customFilter: {
    shareholderEventDate: [],
    shareholderPeriodDateFrom: [],
    shareholderPeriodDateTo: [],
    shareholerName: [],
    shareholderLevel: []
  },
  periodFilter: {
    id: 'changeDate',
    label: 'Pasikeitimų datas'
  },
  tableId: 'changeDate',
  showShares: false,
  showVotes: true,
  showDetailsPopoverDateId: {},
  expandedShareholdersId: {},
  expandedShareholdersGroupPersonId: {},
  lastColumnColor: 'transparent',
  lastColumnBorderColor: 'transparent',
  shareholderId: 0,
  lastColumnColors: {},
  shareholdersLevelList: [],
  defaultFilterLevelValue: false,

  monthsDateMix: [],

  datesInPerdiodWithEvents: [],
  calendarDisplayProps: {
    format: 'YYYY-MM-DD',
    invalidDateMessage: 'mmmm-mm-dd',
    openTo: 'date',
    placeholder: 'mmmm-mm-dd',
    views: ['year', 'month', 'date']
  },

  filterButtonAnchor: null,
  filterButtonRef: null,

  resetFilter: false
};

export interface DatesWithEvents {
  date: string;
  hasEvent: boolean;
}

export interface KeyStringValueNumType {
  [key: string]: string;
}
// KeyNumValuNumType
export interface ExpandedShareholdersIds {
  [key: string]: string;
}
export type ShareholdersTableFilterFields =
  | 'shareholderEventDate'
  | 'shareholderPeriodDateFrom'
  | 'shareholderPeriodDateTo'
  | 'shareholerName'
  | 'shareholderLevel';

export interface ShareholderDataTableCustomFilter {
  shareholderEventDate: string[];
  shareholderPeriodDateFrom: string[];
  shareholderPeriodDateTo: string[];
  shareholerName: string[];
  shareholderLevel: string[];
}

export type ShareholdersTableField = 'shareholderName' | 'shareholderStartDate';

export type ShareholdersColumnsDisplayStatus = {
  shareholderStartDate: boolean;
  shareholderName: boolean;
};

export type ShareholdersColumnsHeader = ColumnHeader<ShareholdersTableField>;

export interface Period {
  id: DateTypes;
  label: string;
}

export interface LastColumnData {
  [key: number]: { color: string; borderColor: string };
}

export interface CustomDatePickerProps {
  format: string;
  invalidDateMessage: string;
  openTo: DatePickerView;
  placeholder: string;
  views: DatePickerView[];
}

export interface ShareholdersTableState {
  sortBy: ShareholdersTableField;
  order: Order;
  columnsDisplaystatus: ShareholdersColumnsDisplayStatus;
  customFilter: ShareholderDataTableCustomFilter;
  periodFilter: Period;
  tableId: DateTypes;
  showShares: boolean;
  showVotes: boolean;
  showDetailsPopoverDateId: KeyStringValueNumType;
  expandedShareholdersId: ExpandedShareholdersIds;
  expandedShareholdersGroupPersonId: ExpandedShareholdersIds;
  lastColumnColor: string;
  lastColumnBorderColor: string;
  shareholderId: number;
  lastColumnColors: LastColumnData;
  shareholdersLevelList: string[];
  defaultFilterLevelValue: boolean;
  monthsDateMix: string[];

  datesInPerdiodWithEvents: DatesWithEvents[];
  calendarDisplayProps: CustomDatePickerProps;

  filterButtonAnchor: null | HTMLElement;
  filterButtonRef: null | HTMLElement;

  resetFilter: boolean;
}

export type ShareholdersTableAction =
  | {
      type: 'VALIDATE_HEADER_DATES';
    }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: ShareholdersTableFilterFields;
      value: string | null;
    }
  | {
      type: 'RESET_CUSTOM_FILTER_VALUE';
      customFilter: ShareholderDataTableCustomFilter;
      resetFilter: boolean;
    }
  | { type: 'SHOW_STATUS_UNSIGNED_TOGGLED' }
  | { type: 'SORTING_CHANGED'; sortBy: ShareholdersTableField }
  | {
      type: 'PERIOD_FILTER_VALUE_CHANGED';
      value: Period;
    }
  | { type: 'SET_SHOW_SHARES_VOTES'; showShares: boolean; showVotes: boolean }
  | {
      type: 'SHOW_CELL_DETAILS_POPOVER';
      id?: string;
      date?: string;
    }
  | {
      type: 'SET_EXPANDED_SHAREHOLDERS_IDS';
      level: number;
      id: number;
    }
  | {
      type: 'SET_EXPANDED_SHAREHOLDERS_GROUP_PERSON_IDS';
      level: number;
      id: number;
    }
  | {
      type: 'SET_LAST_COLUMN_BAR_COLOR';
      lastColumnColor: string;
      lastColumnBorderColor: string;
      shareholderId: number;
    }
  | { type: 'SET_SHAREHOLDERS_LEVEL_LIST'; shareholdersLevelList: string[] }
  | { type: 'SET_DEFAULT_LEVEL_VALUE'; defaultFilterLevelValue: boolean }
  | {
      type: 'INITIAL_HEADER_DATES';
      allEventsDates: string[];
      initialDates: DatesWithEvents[];
    }
  | {
      type: 'INITIAL_HEADER_DATES_BY_DAY';
      allEventsDates: string[];
      initialDates: string[];
    }
  | {
      type: 'EXTEND_HEADER_DATES';
      clickedDate?: string;
      allEventsDates: string[];
      months: string[];
      yearsWithEvents: DatesWithEvents[];
      value: Period;
      tableId: DateTypes;
    }
  | {
      type: 'INITIAL_MONTHS_HEADER_DATES';
      allEventsDates: string[];
      months: string[];
    }
  | {
      type: 'EXTEND_MONTHS_HEADER_DATES';
      allEventsDates: string[];
      clickedDate?: string;
      months: string[];
      days: string[];
      value: Period;
      tableId: DateTypes;
    }
  | {
      type: 'SAVE_FILTER_BUTTON_REFERENCE';
      filterButtonRef: null;
    }
  | {
      type: 'CUSTOM_FILTER_DISPLAY_TOGGLED';
      filterButtonAnchor: null | HTMLElement;
    };
