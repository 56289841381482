import {
  ProfessionalMisconductTableState,
  initialProfessionalMisconductTableState,
  ProfessionalMisconductTableAction
} from './tableInitialStateAndTypes';
import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../utils/tableDataFunctions';

export const ProfessionalMisconductTableReducer = (
  state: ProfessionalMisconductTableState,
  action: ProfessionalMisconductTableAction
): ProfessionalMisconductTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return {
        ...state
      };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialProfessionalMisconductTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    default:
      return state;
  }
};
