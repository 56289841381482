export const FETCH_INSTITUTION_DATA_REPORTS_REQUEST = '@institutionsReports/FETCH_DATA_REQUEST';
export const FETCH_INSTITUTION_DATA_REPORTS_SUCCESS = '@institutionsReports/FETCH_DATA_SUCCESS';
export const FETCH_INSTITUTION_DATA_REPORTS_ERROR = '@institutionsReports/FETCH_DATA_ERROR';

export const REPORT_INSTITUTION_DATA_REQUEST = '@institutionsData/REPORT_INSTITUTION_DATA_REQUEST';
export const REPORT_INSTITUTION_DATA_SUCCESS = '@institutionsData/REPORT_INSTITUTION_DATA_SUCCESS';
export const REPORT_INSTITUTION_DATA_ERROR = '@institutionsData/REPORT_INSTITUTION_DATA_ERROR';
export const RESET_INACCURATE_DATA_REPORTING_STATE =
  '@institutionsData/RESET_INACCURATE_DATA_REPORTING_STATE';

export const FETCH_INACCURATE_DATA_MESSAGE_REQUEST = '@institutionsReports/FETCH_MESSAGE_REQUEST';
export const FETCH_INACCURATE_DATA_MESSAGE_SUCCESS = '@institutionsReports/FETCH_MESSAGE_SUCCESS';
export const FETCH_INACCURATE_DATA_MESSAGE_ERROR = '@institutionsReports/FETCH_MESSAGE_ERROR';
