import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, Divider, Typography, Tooltip, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as virsisActions from '../../store/virsis/actions';
import { exitRepresentationTypeSelectDialog } from '../../store/virsis/actions';
import { ApplicationState } from '../../store';
import { AccountType, Roles } from '../../store/virsis/dataTypes';
import { hasRole } from '../../utils/roleHelper';
import virsisTheme from '../../style/virsisTheme';
import { CloseDialogButton } from './Dialog/CloseButton';
import { CloseContinueButton } from './Dialog/CloseContinueButton';
import { applicationStorage } from '../../utils/axiosApi';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '18px',
    backgroundColor: virsisTheme.palette.secondary.light,
    color: 'red',
    border: '1px solid #637082',
    marginBottom: '5px'
  },
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  }
}));

const RepresentationSelectDialog: React.FC = () => {
  const classes = useStyles();

  const { representationSelectDialogOpen, currentUser } = useSelector(
    (virsisState: ApplicationState) => virsisState.virsis
  );
  const dispatch = useDispatch();

  const [disabledNext, setDisableNext] = useState(true);
  const [getAccountType, setAccountType] = useState<AccountType>();

  const setContext = (accountType: AccountType) => {
    setDisableNext(false);
    setAccountType(accountType);
  };

  const handleRepresentationDialogExit = () => dispatch(exitRepresentationTypeSelectDialog());

  const handleNextStep = () => {
    if (getAccountType) {
      dispatch(virsisActions.setAccountType(getAccountType));
      applicationStorage.setItem('accountType', getAccountType.toString());
    }
  };

  const stl = {
    backgroundColor: '#006FB2',
    color: virsisTheme.palette.secondary.light
  };
  const stl2 = {
    color: '#006FB2'
  };

  const stlz = (type: AccountType) => (type === getAccountType ? stl : stl2);

  if (!currentUser) {
    return null;
  }

  const hasRoleVirs = hasRole(currentUser, Roles.ROLE_VIRS);
  const hasRoleLegal = hasRole(currentUser, Roles.ROLE_LEGAL);

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      margin: '0.8em 0',
      padding: 12,
      boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
      fontFamily: 'Open Sans',
      fontSize: '0.8rem',
      fontWeight: 600,
      '& span': {
        color: theme.palette.secondary.light
      }
    }
  }))(Tooltip);

  return (
    <div>
      <Dialog
        open={representationSelectDialogOpen}
        maxWidth="sm"
        onClose={handleRepresentationDialogExit}
        disableBackdropClick
      >
        <div className="dialog-title">
          <Typography variant="h2">Pasirinkite atstovaujamą juridinį asmenį</Typography>
        </div>

        <Divider light style={{ margin: '0px 35px' }} />

        <div
          className="dialog-main-content"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Tooltip
            title="Jūsų atstovaujamam juridiniam asmeniui,
              jo filialui ar atstovybei viešosios informacijos rengėjo
              ir (ar) skleidėjo duomenų teikimas pagal Visuomenės informavimo įstatymą nenumatytas."
            placement="top-end"
            leaveDelay={1000}
            disableHoverListener={hasRoleVirs}
            classes={{ tooltip: classes.tooltip }}
          >
            <span>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setContext(AccountType.VIRS)}
                style={stlz(AccountType.VIRS)}
                disabled={!hasRoleVirs}
                className={classes.buttonWrapper}
              >
                Viešosios informacijos rengėjas ir (ar) skleidėjas (VIRS)
              </Button>
            </span>
          </Tooltip>

          {(currentUser.institutionRole || hasRoleLegal) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setContext(AccountType.LEGAL)}
              style={stlz(AccountType.LEGAL)}
              className={classes.buttonWrapper}
            >
              Įstaiga
              <CustomTooltip
                title="Valstybės ar savivaldybės institucija / įstaiga / įmonė ar akcinė bendrovė / uždaroji akcinė bendrovė, kurioje valstybė ar savivaldybė turi daugiau kaip 50 procentų balsų visuotiniame akcininkų susirinkime suteikiančių akcijų arba skiria daugiau kaip pusę jų valdymo organų narių, ar šios bendrovės dukterinė bendrovė."
                arrow
                placement="top"
              >
                <ErrorIcon fontSize="inherit" style={{ color: '#9E9E9E', marginLeft: 5 }} />
              </CustomTooltip>
            </Button>
          )}
        </div>

        <div className="dialog-actions">
          <CloseDialogButton label="Atsijungti" onClick={handleRepresentationDialogExit} />
          <CloseContinueButton
            disabled={disabledNext}
            onClick={handleNextStep}
            label="Tęsti"
            withIcon
          />
        </div>
      </Dialog>
    </div>
  );
};

export default RepresentationSelectDialog;
