import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import {
  setExpandParents,
  unsetUpdateShareholderId
} from '../../../../../store/shareholders/shareholdersActions';

import { AddPersonDialogElement } from '../../../../../store/shareholders/shareholdersTypes';
import { getTableStartDate } from '../../../utilityFunctions/sharedFunctions';
import { useShareholdersSelectedDateTableDispatch } from '../../TableWithContext';
import { getParentPersonsPath } from '../../utilities/utilityFunctions';
import { AddShareholderButtonWithTooltip } from './AddShareholderButtonWithTooltip';
import { SelectParentDialog } from './SelectParentDialog';

const useStyles = makeStyles({
  width: {
    width: 'max-content'
  }
});

export const AddShareholderButtonContainer: React.FC = () => {
  const classes = useStyles();

  const {
    virsis: { virsData },
    shareholdersData: {
      loadingShareholdersSelectedDateData,
      shareholdersSelectedDateData,
      selectedDate,
      virsPersonsList,
      noValidShareholderParent,
      shareholdersData,
      lastSignedDate
    }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();
  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();

  const [openSelectParentDialog, setOpenSelectParentDialog] = useState(false);

  if (virsData && shareholdersSelectedDateData && selectedDate && virsPersonsList) {
    const { virsStartDate, virsStoppedParticipatingFromDate, shareholders } =
      shareholdersSelectedDateData;

    const handleSelectParentSubmit = ({
      shareholderLevel,
      shareholderId,
      personId,
      shareholderPath,
      index
    }: AddPersonDialogElement) => {
      const id = shareholderLevel === 0 ? virsData.virsId : shareholderId;
      const parentPersonsPath = getParentPersonsPath(
        shareholdersSelectedDateData.shareholders,
        personId
      );

      setOpenSelectParentDialog(false);
      tableDispatch({
        type: 'SHOW_SELECT_SHAREHOLDER_ROW',
        showSelectShareholderRow: true
      });
      tableDispatch({
        type: 'SET_PARENT_ID_TO_ADD_CHILD',
        id,
        index
      });
      tableDispatch({
        type: 'SET_PARENT_PERSON_LEVEL_ID_TO_ADD_CHILD',
        id: personId,
        level: shareholderLevel
      });
      reduxDispatch(setExpandParents(shareholderPath, parentPersonsPath, index));
      tableDispatch({
        type: 'SET_SHOULD_SCROLL_TO_SHAREHOLDER',
        shouldScroll: true
      });
      reduxDispatch(unsetUpdateShareholderId());
    };

    return (
      <div className={classes.width}>
        <AddShareholderButtonWithTooltip
          shareholders={shareholdersData?.shareholders || []}
          activityPeriods={shareholdersData?.activityPeriods || []}
          handleAddShareholder={() => setOpenSelectParentDialog(true)}
          noValidShareholderParent={noValidShareholderParent}
          loadingShareholdersSelectedDateData={loadingShareholdersSelectedDateData}
          selectedDate={selectedDate}
          virsHasShareholders={!!shareholders.length}
          tableStartDate={getTableStartDate(
            virsStartDate,
            shareholdersSelectedDateData.minimumDate
          )}
          virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
          lastSignedDate={lastSignedDate}
        />
        <SelectParentDialog
          open={openSelectParentDialog}
          setOpen={setOpenSelectParentDialog}
          onSubmit={(selectedShareholder) => handleSelectParentSubmit(selectedShareholder)}
          shareholders={virsPersonsList}
        />
      </div>
    );
  }

  return null;
};
