import {
  ProvidedDataHistoryCustomFilter,
  ProvidedDataHistoryHeaderField,
  Period,
  ProvidedDataHistoryColumnHeader
} from './tableInitialStateAndTypes';
import { ColumnDisplayStatus } from '../../../utils/tableTypes';
import { checkIfDateIsWithinPeriod } from '../../../utils/tableDataFunctions';
import { ProvidedDataInst } from '../../../store/providedData/providedDataTypes';
import { getStatus } from '../../../store/document/documentTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export const updateProvidedDataHistoryCustomFilter = (
  filter: ProvidedDataHistoryCustomFilter,
  filterBy: ProvidedDataHistoryHeaderField,
  value: string | null
): ProvidedDataHistoryCustomFilter => {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
};

const getCustomFilteredProvidedDataHistory = (
  data: ProvidedDataInst[],
  filter: ProvidedDataHistoryCustomFilter
): ProvidedDataInst[] => {
  function createCustomFilter(field: ProvidedDataHistoryHeaderField) {
    return (record: ProvidedDataInst): boolean => {
      const [valueToFilterBy] = filter[field];
      if (valueToFilterBy) {
        if (field === 'outletList') {
          const recordsMatchingOutlets = record.outletList.filter((outlet) =>
            outlet.outletName
              .toString()
              .toLowerCase()
              .includes(valueToFilterBy.toString().toLowerCase())
          );
          return recordsMatchingOutlets !== undefined && recordsMatchingOutlets.length > 0;
        }

        if (field === 'documentStatus') {
          return getStatus(record.documentStatus.id).includes(filter.documentStatus.toString());
        }

        const recordFieldToTest = record[field];
        if (recordFieldToTest) {
          return recordFieldToTest
            .toString()
            .toLowerCase()
            .includes(valueToFilterBy.toString().toLowerCase());
        }
        return false;
      }
      return true;
    };
  }

  const allTableFilters = [
    createCustomFilter('dataType'),
    createCustomFilter('virsName'),
    createCustomFilter('outletList'),
    createCustomFilter('documentStatus'),
    createCustomFilter('lastEditedDate'),
    createCustomFilter('lastEditedBy')
  ];

  const filteredData = data.filter((record) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });
  return filteredData;
};

const getStatusFilteredProvidedDataHistory = (
  data: ProvidedDataInst[],
  showStatusNotSigned: boolean,
  showStatusSigned: boolean,
  showStatusAnnuled: boolean
): ProvidedDataInst[] => {
  let filtered;
  const unsigned = data.filter((record) => record.documentStatus.id === 'RUOSIAMAS');
  const signed = data.filter((record) => record.documentStatus.id === 'PASIRASYTAS');
  const annuled = data.filter((record) => record.documentStatus.id === 'ANULIUOTAS');

  if (showStatusNotSigned && !showStatusSigned && !showStatusAnnuled) {
    filtered = unsigned || [];
  }
  if (!showStatusNotSigned && showStatusSigned && !showStatusAnnuled) {
    filtered = signed;
  }
  if (!showStatusNotSigned && !showStatusSigned && showStatusAnnuled) {
    filtered = annuled;
  }
  if (showStatusNotSigned && showStatusSigned && showStatusAnnuled) {
    filtered = data;
  }
  if (!showStatusNotSigned && showStatusSigned && showStatusAnnuled) {
    filtered = [...signed, ...annuled];
  }
  if (showStatusNotSigned && !showStatusSigned && showStatusAnnuled) {
    filtered = [...unsigned, ...annuled];
  }
  if (showStatusNotSigned && showStatusSigned && !showStatusAnnuled) {
    filtered = [...unsigned, ...signed];
  }
  return filtered || [];
};

export const getPeriodFilterProvidedDataHistory = (
  data: ProvidedDataInst[],
  periodFilter: Period
): ProvidedDataInst[] => {
  switch (periodFilter.id) {
    case 'PASKUTINIS_MENUO':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 30));
    case 'PASKUTINIS_KETVIRTIS':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 90));
    case 'PASKUTINIS_PUSMETIS':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 180));
    case 'PASKUTINIAI_METAI':
      return data.filter((document) => checkIfDateIsWithinPeriod(document.lastEditedDate, 365));
    case 'VISI':
      return data;
    default:
      return data;
  }
};

export const getStatusFilteredCustomFilteredProvidedDataHistory = (
  data: ProvidedDataInst[],
  filter: ProvidedDataHistoryCustomFilter,
  showStatusNotSigned: boolean,
  showStatusSigned: boolean,
  showStatusAnnuled: boolean
): ProvidedDataInst[] => {
  const statusFilteredData = getStatusFilteredProvidedDataHistory(
    data,
    showStatusNotSigned,
    showStatusSigned,
    showStatusAnnuled
  );
  return getCustomFilteredProvidedDataHistory(statusFilteredData, filter);
};

export function getTableExportDefinition(
  columnHeaders: ProvidedDataHistoryColumnHeader[],
  displayStatus: ColumnDisplayStatus<ProvidedDataHistoryHeaderField>
): TableExportDefinition<ProvidedDataInst> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    ProvidedDataInst,
    ProvidedDataHistoryHeaderField
  >(columnHeaders, displayStatus);

  return {
    title: 'Duomenų istorija',
    columnGroups: [
      {
        columns: [
          stringValueColumn('dataType', { width: 25 }),
          stringValueColumn('virsName', { width: 45 }),
          mappedValueColumn(
            'outletList',
            ({ outletList }) => [
              {
                values: outletList.map(({ outletName }) => outletName)
              }
            ],
            { width: 45 }
          ),
          mappedValueColumn(
            'documentStatus',
            ({ documentStatus }) => [{ values: [documentStatus.label] }],
            { width: 25 }
          ),
          stringValueColumn('lastEditedDate', { width: 25 }),
          stringValueColumn('lastEditedBy', { width: 25 })
        ]
      }
    ]
  };
}
