import { Reducer } from 'redux';
import {
  DOCUMENT_APPROVE_ERROR,
  DOCUMENT_APPROVE_REQUEST,
  DOCUMENT_APPROVE_SUCCESS,
  DOCUMENT_VALIDATE_ERROR,
  DOCUMENT_VALIDATE_REQUEST,
  DOCUMENT_VALIDATE_SUCCESS,
  DocumentValidationResponse,
  RESET_DOCUMENT_VALIDATION
} from './documentTypes';

export interface DocumentState {
  documentValidateRequest: boolean;
  documentValidateError?: Error;
  loadingDocumentValidation: boolean;
  documentValidation?: DocumentValidationResponse;
  documentApproveRequest: boolean;
  documentApproveError?: Error;
}

export const initialState: DocumentState = {
  documentValidateRequest: false,
  documentValidateError: undefined,
  documentValidation: undefined,
  loadingDocumentValidation: false,
  documentApproveRequest: false,
  documentApproveError: undefined
};

const reducer: Reducer<DocumentState> = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_VALIDATE_REQUEST: {
      return {
        ...state,
        documentValidateRequest: true,
        loadingDocumentValidation: true
      };
    }
    case DOCUMENT_VALIDATE_SUCCESS: {
      return {
        ...state,
        documentValidateRequest: false,
        documentValidation: action.payload,
        loadingDocumentValidation: false
      };
    }
    case DOCUMENT_VALIDATE_ERROR: {
      return {
        ...state,
        documentValidateRequest: false,
        documentValidateError: action.payload,
        loadingDocumentValidation: false
      };
    }
    case DOCUMENT_APPROVE_REQUEST: {
      return { ...state, documentApproveRequest: true };
    }
    case DOCUMENT_APPROVE_SUCCESS: {
      return { ...state, documentApproveRequest: false };
    }
    case DOCUMENT_APPROVE_ERROR: {
      return { ...state, documentApproveError: action.payload };
    }
    case RESET_DOCUMENT_VALIDATION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as documentReducer };
