import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { OutletsTableNewRecordDialogAutocomplete } from './TableNewRecordDialogAutocomplete';
import { EnterpriseDataWithOutlets } from '../../store/outlets/outletsTypes';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import { CloseDialogButton } from '../../components/Dialogs/Dialog/CloseButton';
import { Strings } from '../../utils/strings/Strings';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
  options: EnterpriseDataWithOutlets[];
  openNewRecord: (enterprise: EnterpriseDataWithOutlets) => void;
  redirectToEnterpriseNewRecord: () => void;
}

const OutletsTableNewRecordDialog: React.FC<Props> = ({
  isOpen,
  closeDialog,
  options,
  openNewRecord,
  redirectToEnterpriseNewRecord
}) => {
  const [selectedEnterprise, setSelectedEnterprise] = useState<
    EnterpriseDataWithOutlets | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  useEffect(() => {
    setSelectedEnterprise(undefined);
  }, [isOpen]);

  function onEnterpriseSelectedDo(): void {
    closeDialog();
    if (selectedEnterprise) {
      openNewRecord(selectedEnterprise);
      setErrorMessage(null);
    } else {
      setErrorMessage('Būtina pasirinkti galiojančią VIRS veiklos rūšį');
    }
  }

  function handleAddEnterpriseClick(): void {
    redirectToEnterpriseNewRecord();
  }

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={closeDialog}>
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="h2">
          Pasirinkite veiklos rūšį, kuriai norite priskirti naują VIP
        </Typography>
      </DialogTitle>
      <Divider light style={{ margin: '10px 35px' }} />
      <DialogContent>
        <div className="dialog-main-content">
          <Typography variant="h3" style={{ margin: '10px 0' }}>
            Rinktis veiklos rūšį:
          </Typography>
          <OutletsTableNewRecordDialogAutocomplete
            options={options}
            errorMessage={errorMessage}
            setEnterprise={setSelectedEnterprise}
          />

          <Typography
            variant="body1"
            style={{ marginTop: '15px', marginBottom: '-5px', fontSize: '1rem' }}
          >
            Jei neradote veiklos rūšies, pridėkite naują:
          </Typography>
          <AddNewRecordButton
            text="Pridėti veiklos rūšį"
            onClicked={handleAddEnterpriseClick}
            variant="outlined"
            color="secondary"
          />
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" m={1}>
          <CloseDialogButton label={Strings.button__cancel} onClick={closeDialog} />
        </Box>
        <Box right={30} component="span" m={1}>
          <Button
            style={{ width: '124px' }}
            variant="contained"
            color="primary"
            disabled={!selectedEnterprise}
            onClick={onEnterpriseSelectedDo}
          >
            <Typography variant="h5">Tęsti</Typography>
            <ChevronRightIcon fontSize="inherit" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default OutletsTableNewRecordDialog;
