import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../../store';
import { ShareholderShareTypes } from '../../../../../../../store/classifiers/classifiersTypes';
import {
  ShareholdersData,
  ShareholderPerson
} from '../../../../../../../store/shareholders/shareholdersTypes';
import { getLegalFormType } from '../../../../utilities/utilityFunctions';
import { ForeignParentLegalFormTypesForm } from './Form/ForeignParentLegalFormTypesForm';
import { ParentLegalFormTypesForm } from './Form/ParentLegalFormTypesForm';

interface Props {
  isProcessing: boolean;
  isSynchronizingJADIS: boolean;
  onClose: () => void;
  onFormSubmit: (
    shareholderShareTypes: ShareholderShareTypes,
    selectedAnchorEl: HTMLButtonElement
  ) => void;
  parentLegalFormCode: number | null;
  parentShareholder: ShareholderPerson | ShareholdersData;
}

export const ParentDetails: React.FC<Props> = ({
  isProcessing,
  isSynchronizingJADIS,
  onClose,
  onFormSubmit,
  parentLegalFormCode,
  parentShareholder
}) => {
  const { legalFormTypes } = useSelector((state: ApplicationState) => state.classifiers);

  const parentLegalFormType =
    parentLegalFormCode && legalFormTypes && getLegalFormType(parentLegalFormCode, legalFormTypes);

  return (
    <>
      {parentLegalFormType ? (
        <ParentLegalFormTypesForm
          isProcessing={isProcessing}
          isSynchronizingJADIS={isSynchronizingJADIS}
          legalFormType={parentLegalFormType}
          onClose={onClose}
          onFormSubmit={onFormSubmit}
          parentShareholder={parentShareholder}
        />
      ) : (
        <ForeignParentLegalFormTypesForm
          isProcessing={isProcessing}
          isSynchronizingJADIS={isSynchronizingJADIS}
          onClose={onClose}
          onFormSubmit={onFormSubmit}
          parentShareholder={parentShareholder}
        />
      )}
    </>
  );
};
