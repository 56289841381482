export const FETCH_ENTERPRISE_DATA_REQUEST = '@enterprise/FETCH_ENTERPRISE_DATA_REQUEST';
export const FETCH_ENTERPRISE_DATA_SUCCESS = '@enterprise/FETCH_ENTERPRISE_DATA_SUCCESS';
export const FETCH_ENTERPRISE_DATA_ERROR = '@enterprise/FETCH_ENTERPRISE_DATA_ERROR';

export const CREATE_ENTERPRISE_REQUEST = '@enterprise/CREATE_ENTERPRISE_REQUEST';
export const CREATE_ENTERPRISE_SUCCESS = '@enterprise/CREATE_ENTERPRISE_SUCCESS';
export const CREATE_ENTERPRISE_ERROR = '@enterprise/CREATE_ENTERPRISE_ERROR';
export const RESET_CREATE_ENTERPRISE_STATE = '@enterprise/RESET_CREATE_ENTERPRISE_STATE';

export const UPDATE_ENTERPRISE_REQUEST = '@enterprise/UPDATE_ENTERPRISE_REQUEST';
export const UPDATE_ENTERPRISE_SUCCESS = '@enterprise/UPDATE_ENTERPRISE_SUCCESS';
export const UPDATE_ENTERPRISE_ERROR = '@enterprise/UPDATE_ENTERPRISE_ERROR';
export const RESET_UPDATE_ENTERPRISE_STATE = '@enterprise/RESET_UPDATE_ENTERPRISE_STATE';

export const REMOVE_ENTERPRISE_REQUEST = '@enterprise/REMOVE_ENTERPRISE_REQUEST';
export const REMOVE_ENTERPRISE_SUCCESS = '@enterprise/REMOVE_ENTERPRISE_SUCCESS';
export const REMOVE_ENTERPRISE_ERROR = '@enterprise/REMOVE_ENTERPRISE_ERROR';
export const RESET_REMOVE_ENTERPRISE_STATE = '@enterprise/RESET_REMOVE_ENTERPRISE_STATE';

export const GET_OUTLET_IDS_REQUEST = '@enterprise/GET_OUTLET_IDS_REQUEST';
export const GET_OUTLET_IDS_SUCCESS = '@enterprise/GET_OUTLET_IDS_SUCCESS';
export const GET_OUTLET_IDS_ERROR = '@enterprise/GET_OUTLET_IDS_ERROR';

export const DELETE_ENTERPRISE_ALERT_OPEN = '@enterprise/DELETE_ENTERPRISE_ALERT_OPEN';
export const DELETE_ENTERPRISE_ALERT_CLOSE = '@enterprise/DELETE_ENTERPRISE_ALERT_CLOSE';

export const ENTERPRISE_DATA_PDF_REQUEST = '@enterprise/ENTERPRISE_DATA_PDF_REQUEST';

export const UPDATE_ENTERPRISE_OUTLETS_ALERT_OPEN =
  '@enterprise/UPDATE_ENTERPRISE_OUTLETS_ALERT_OPEN';
export const UPDATE_ENTERPRISE_OUTLETS_ALERT_CLOSE =
  '@enterprise/UPDATE_ENTERPRISE_OUTLETS_ALERT_CLOSE';
