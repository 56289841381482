import { SortOrder } from '../../utils/tableTypes';
import { ApplicationState } from '../index';
import { EthicalNonComplianceHeaderField } from '../../containers/EthicalNonComplianceTableInst/tableState/tableInitialStateAndTypes';
import {
  EthicalNonCompliancePageRequest,
  EthicalNonComplianceTableDataState
} from './ethicalNonComplienceTableTypes';
import { EthicalNonComplianceDataTableState } from './ethicalNonComplianceTableReducer';
import moment from 'moment';

const getFilterDate = (
  state: EthicalNonComplianceTableDataState,
  value: EthicalNonComplianceHeaderField
): string | null => {
  return state.customFilter[value][0]?.trim()
    ? moment(new Date(state.customFilter[value][0].trim())).format('YYYY-MM-DD')
    : null;
};

const getFilterString = (
  state: EthicalNonComplianceTableDataState,
  value: EthicalNonComplianceHeaderField
): string | null => state.customFilter[value][0]?.trim() || null;

const getFilterNumber = (
  state: EthicalNonComplianceTableDataState,
  value: EthicalNonComplianceHeaderField
): number | null => Number(state.customFilter[value][0]) || null;

const getSorting = (
  state: EthicalNonComplianceTableDataState,
  value: EthicalNonComplianceHeaderField
): SortOrder | null => (state.sortBy === value ? state.order : null);

export const getEthicalNonComplianceInstPageRequest = (
  state: EthicalNonComplianceTableDataState
): EthicalNonCompliancePageRequest => {
  return {
    filterDecisionDate: getFilterDate(state, 'decisionDate'),
    filterDecisionStatusId: getFilterNumber(state, 'decisionStatus'),
    filterDocumentNumber: getFilterString(state, 'documentNumber'),
    filterOutletId: getFilterNumber(state, 'outletList'),
    filterValidFrom: getFilterDate(state, 'validFrom'),
    filterValidTo: getFilterDate(state, 'validTo'),
    filterVirsId: getFilterNumber(state, 'virsName'),
    pageNr: state.rowsPerPage * state.page + 1,
    pageSize: state.rowsPerPage,
    sortingDecisionDate: getSorting(state, 'decisionDate'),
    sortingDecisionStatus: getSorting(state, 'decisionStatus'),
    sortingDocumentNumber: getSorting(state, 'documentNumber'),
    sortingOutletName: getSorting(state, 'outletList'),
    sortingValidFrom: getSorting(state, 'validFrom'),
    sortingValidTo: getSorting(state, 'validTo'),
    sortingVirsName: getSorting(state, 'virsName'),
    docStatusNotSigned: state?.showStatusNotSigned,
    docStatusRelevant: state?.showStatusRelevant,
    docStatusOutdated: state?.showStatusOutdated
  };
};

export const selectEthicalNonComplianceTableDataState = (
  state: ApplicationState
): EthicalNonComplianceDataTableState => state.ethicalNonComplianceTableData;
