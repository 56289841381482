import { CircularProgress, makeStyles } from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialValues, ReportInput } from './types';

import { FieldGroup } from '../../components/FormikFields/GenericFormikWrappers/FieldGroup';
import { GenericTextField } from '../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import { GenericDateField } from '../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericBooleanField } from '../../components/FormikFields/GenericFormikWrappers/GenericBooleanField';
import { GenericRadioGroup } from '../../components/FormikFields/GenericFormikWrappers/GenericRadioGroup';
import { GenericSelectField } from '../../components/FormikFields/GenericFormikWrappers/GenericSelectField';
import { loadReportOptions, showPersonSearchDialog, showVirsSearchDialog } from './store';
import { ApplicationState } from '../../store';
import BtnOpenNewDoc from '../../components/TableButtons/BtnOpenNewDoc';
import { VirsSearchData } from '../../store/virsis/dataTypes';
import { todaysDateString } from '../../utils/tableDataFunctions';
import moment from 'moment';
import { NaturalPersonInVirsis } from '../../components/FindNaturalPersonInVirsis/Utilities';

export const getFieldGroupStyle = (marginBottom?: string) => {
  return {
    container: { margin: 4, marginBottom },
    labelBox: { flex: '0 0 220px' }
  };
};

interface FormProps {
  selectedVirs?: VirsSearchData;
  selectedPerson?: NaturalPersonInVirsis;
  formikProps: FormikProps<ReportInput>;
}

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    container: {
      flex: 'unset !important',
      width: '100%'
    },
    fieldGroup: {
      gap: 10,
      flexWrap: 'wrap',

      '& > div': {
        width: '100%'
      }
    },
    dateFieldGroup: {
      flexWrap: 'wrap',
      gap: 10
    },
    dateFieldGroupChildren: {
      flexWrap: 'wrap',
      width: '100%'
    },
    todayCheckbox: {
      width: '100%'
    },
    dateField: {
      width: '100%',
      margin: '0 !important'
    },
    dateSelectField: {
      width: '100%'
    },
    nameFieldGroup: {
      flexWrap: 'wrap',
      gap: 10
    },
    nameChildrenFieldGroup: {
      flexWrap: 'wrap',
      gap: 10,
      width: '100%',

      '& > div': {
        margin: '0 !important',
        padding: 0,
        width: '100%',
        flex: 'unset !important'
      }
    },
    nameDateSelectField: {
      width: '100%',
      padding: 0
    },
    label: {
      margin: '0 !important'
    }
  }
}));

export const FormContent: React.FC<FormProps> = ({ formikProps, selectedVirs, selectedPerson }) => {
  const dispatch = useDispatch();
  const {
    options: {
      reportOptions,
      dataBlockOptions,
      sortOptions,
      fileOptions,
      columnOptions,
      reportSettings
    },
    isLoading
  } = useSelector((state: ApplicationState) => state.report);
  const { values, setValues, setErrors, setFieldValue } = formikProps;
  const { todayReport, filterEnabled } = values;
  const classes = useStyles();

  useEffect(() => {
    setValues({
      ...initialValues,
      report: values.report || reportOptions[0]?.value || initialValues.report,
      dataBlock: dataBlockOptions[0] ? 'null' : initialValues.dataBlock,
      fileFormat: fileOptions[0]?.value || initialValues.fileFormat
    });
    setErrors({});
  }, [
    reportOptions,
    dataBlockOptions,
    sortOptions,
    fileOptions,
    reportSettings,
    setValues,
    values.report,
    setErrors
  ]);

  useEffect(() => {
    if (selectedVirs?.personCode != null && selectedVirs)
      setFieldValue('virsCode', selectedVirs.personCode);
    if (selectedVirs?.personFullName != null && selectedVirs)
      setFieldValue('virsName', selectedVirs.personFullName);
    if (selectedPerson != null && selectedPerson) {
      setFieldValue('personFirstNameFilter', selectedPerson.personFirstName);
      setFieldValue('personLastNameFilter', selectedPerson.personLastName);
      setFieldValue('personBirthDateFilter', selectedPerson.personBirthDate);
      setFieldValue('personCodeFilter', selectedPerson.personCode);
    }
  }, [selectedPerson, selectedVirs, setFieldValue]);

  return (
    <>
      <FieldGroup
        className={classes.fieldGroup}
        label="Ataskaitos pavadinimas"
        style={getFieldGroupStyle()}
      >
        <GenericSelectField
          field="report"
          propClasses={classes.container}
          style={{ container: { flex: '0 0 500px', margin: 0 } }}
          options={reportOptions}
          formikProps={formikProps}
          onChange={(value) => dispatch(loadReportOptions(value))}
          clearable={false}
        />

        {isLoading && <CircularProgress style={{ flex: '0 0 40px' }} />}
      </FieldGroup>

      <FieldGroup
        label="Laikotarpis"
        style={getFieldGroupStyle('1em')}
        disabled={!reportSettings.timeFilter}
        className={classes.dateFieldGroup}
        childrenClassName={classes.dateFieldGroupChildren}
      >
        <GenericBooleanField
          label="Šiandienos"
          field="todayReport"
          style={{ container: { margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.timeFilter}
          className={classes.todayCheckbox}
          onChange={(value) =>
            value &&
            formikProps.setValues({
              ...formikProps.values,
              fromDate: null,
              toDate: null
            })
          }
        />

        <GenericDateField
          label="Nuo"
          field="fromDate"
          style={{ container: { margin: '0 25px 0 0' } }}
          formikProps={formikProps}
          disabled={!reportSettings.timeFilter || todayReport}
          maxDate={values.toDate ? moment(values.toDate).format('YYYY-MM-DD') : todaysDateString()}
          className={classes.dateField}
          fieldClassName={classes.dateSelectField}
        />

        <GenericDateField
          label="Iki"
          field="toDate"
          style={{ container: { margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.timeFilter || todayReport}
          minDate={values.fromDate ? moment(values.fromDate).format('YYYY-MM-DD') : undefined}
          maxDate={todaysDateString()}
          className={classes.dateField}
          fieldClassName={classes.dateSelectField}
        />
      </FieldGroup>

      <FieldGroup label="VIRS" disabled={!reportSettings.virsFilter} style={getFieldGroupStyle()}>
        <GenericRadioGroup
          field="filterEnabled"
          options={[
            { value: false, label: 'Visi' },
            { value: true, label: 'Pasirinktas VIRS:' }
          ]}
          style={{ container: { margin: 0 } }}
          formikProps={formikProps}
          clearable={false}
          disabled={!reportSettings.virsFilter}
          onChange={(value) => {
            if (!value) {
              formikProps.setValues({
                ...formikProps.values,
                virsCode: null,
                virsName: null
              });
            }
          }}
        />
      </FieldGroup>

      <FieldGroup
        label="Įmonės kodas"
        disabled={!filterEnabled}
        style={getFieldGroupStyle()}
        className={classes.fieldGroup}
      >
        <GenericTextField
          field="virsCode"
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          formikProps={formikProps}
          disabled={!filterEnabled}
          propClasses={classes.container}
        />
      </FieldGroup>

      <FieldGroup
        label="Pavadinimas"
        disabled={!filterEnabled}
        style={getFieldGroupStyle()}
        className={classes.fieldGroup}
      >
        <GenericTextField
          field="virsName"
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          formikProps={formikProps}
          disabled={!filterEnabled}
          propClasses={classes.container}
        />
      </FieldGroup>

      <FieldGroup
        disabled={!filterEnabled}
        style={getFieldGroupStyle()}
        labelClassName={classes.label}
      >
        <div>
          <BtnOpenNewDoc
            disabled={!filterEnabled}
            onClicked={() => dispatch(showVirsSearchDialog(true))}
            text={'Išplėstinė paieška'}
          />
        </div>
      </FieldGroup>

      <FieldGroup
        label="VIRS būsena"
        style={getFieldGroupStyle('1.2em')}
        disabled={!reportSettings.virsStateFilter}
      >
        <GenericBooleanField
          label="Aktyvūs VIRS"
          field="includeActive"
          style={{ container: { margin: 0 } }}
          disabled={!reportSettings.virsStateFilter}
          formikProps={formikProps}
        />

        <GenericBooleanField
          label="Neaktyvūs VIRS"
          field="includeInactive"
          style={{ container: { margin: 0 } }}
          disabled={!reportSettings.virsStateFilter}
          formikProps={formikProps}
        />
      </FieldGroup>

      <FieldGroup
        label="Asmens kodas"
        disabled={!reportSettings.personCodeFilter}
        style={getFieldGroupStyle()}
        className={classes.fieldGroup}
      >
        <GenericTextField
          field="personCodeFilter"
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.personCodeFilter}
          propClasses={classes.container}
        />
      </FieldGroup>

      <FieldGroup
        label="Vardas, pavardė ir gimimo data"
        disabled={!reportSettings.personBirthDateFilter}
        style={getFieldGroupStyle('8px')}
        className={classes.nameFieldGroup}
        childrenClassName={classes.nameChildrenFieldGroup}
      >
        <GenericTextField
          field="personFirstNameFilter"
          style={{
            container: { flex: '0 0 300px', margin: 0 },
            helper: { whiteSpace: 'nowrap' }
          }}
          placeholder="Vardas"
          formikProps={formikProps}
          disabled={!reportSettings.personFirstNameFilter}
        />

        <GenericTextField
          field="personLastNameFilter"
          style={{ container: { flex: '0 0 300px', margin: '0 2em' } }}
          placeholder="Pavardė"
          formikProps={formikProps}
          disabled={!reportSettings.personLastNameFilter}
        />

        <GenericDateField
          field="personBirthDateFilter"
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.personBirthDateFilter}
          fieldClassName={classes.nameDateSelectField}
        />
      </FieldGroup>

      <FieldGroup
        disabled={!reportSettings.personBirthDateFilter}
        style={getFieldGroupStyle()}
        labelClassName={classes.label}
      >
        <div>
          <BtnOpenNewDoc
            disabled={!reportSettings.personBirthDateFilter}
            onClicked={() => dispatch(showPersonSearchDialog(true))}
            text={'Išplėstinė paieška'}
          />
        </div>
      </FieldGroup>

      <FieldGroup
        label="Duomenų blokas"
        style={getFieldGroupStyle()}
        disabled={!reportSettings.dataBlockFilter}
        className={classes.fieldGroup}
      >
        <GenericSelectField
          field="dataBlock"
          options={[{ value: 'null', label: 'Visi' }, ...dataBlockOptions]}
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          disabled={!reportSettings.dataBlockFilter}
          formikProps={formikProps}
          clearable={false}
          propClasses={classes.container}
        />
      </FieldGroup>

      <FieldGroup
        label="Duomenų būsena"
        disabled={!reportSettings.dataStateFilter}
        style={getFieldGroupStyle()}
      >
        <GenericBooleanField
          style={{ container: { margin: 0 } }}
          label="Rodyti anuliuotus"
          field="includeAnnulled"
          formikProps={formikProps}
          disabled={!reportSettings.dataStateFilter}
        />
      </FieldGroup>

      <FieldGroup
        label="Ataskaitos tipas"
        style={getFieldGroupStyle('1.2em')}
        disabled={!reportSettings.reportType}
      >
        <GenericBooleanField
          label="Detali"
          field="detailedReport"
          style={{ container: { margin: 0 }, helper: { whiteSpace: 'nowrap' } }}
          formikProps={formikProps}
          disabled={!reportSettings.reportType}
        />
        <GenericBooleanField
          label="Suvestinė"
          field="summarizedReport"
          style={{ container: { margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.reportType}
        />
      </FieldGroup>

      <FieldGroup
        label="Rūšiavimas"
        style={getFieldGroupStyle()}
        disabled={!reportSettings.sorting}
        className={classes.fieldGroup}
      >
        <GenericSelectField
          field="sortByColumn"
          options={sortOptions}
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.sorting}
          propClasses={classes.container}
        />
      </FieldGroup>

      <FieldGroup
        label="Duomens filtras"
        style={getFieldGroupStyle()}
        disabled={!reportSettings.columnFilter}
        className={classes.fieldGroup}
      >
        <GenericSelectField
          field="columnFilter"
          options={columnOptions}
          style={{ container: { flex: '0 0 300px', margin: 0 } }}
          formikProps={formikProps}
          disabled={!reportSettings.columnFilter}
          propClasses={classes.container}
        />
      </FieldGroup>

      <FieldGroup label="Duomenų formatas" style={getFieldGroupStyle()}>
        <GenericRadioGroup
          field="fileFormat"
          style={{ container: { margin: 0 } }}
          options={fileOptions}
          clearable={false}
          formikProps={formikProps}
        />
      </FieldGroup>
    </>
  );
};
