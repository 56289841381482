import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';
import { GenericSelectField } from '../../../components/FormikFields/GenericFormikWrappers/GenericSelectField';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';

import { resetForm, submitForm, State } from './store';
import { defaultValues, LicencedEnterpriseInput } from './types';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<LicencedEnterpriseInput>> = (formikProps) => {
  const { licenceTypes, enterpriseTypes } = useSelector((state: State) => state.form.options);

  const licenceTypeOptions = licenceTypes.map(({ licenceTypeId, licenceTypeName }) => ({
    value: licenceTypeId,
    label: licenceTypeName
  }));

  const enterpriseTypeOptions = enterpriseTypes.map(({ enterpriseTypeId, enterpriseTypeName }) => ({
    value: enterpriseTypeId,
    label: enterpriseTypeName
  }));

  const {
    values: { licencedEnterpriseId }
  } = formikProps;

  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{licencedEnterpriseId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="VIRS veiklos rūšies pavadinimas" spacing="1em">
        <GenericSelectField
          label=""
          field="enterpriseTypeId"
          formikProps={formikProps}
          options={enterpriseTypeOptions}
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Licencijuojama veikla LIS'e" spacing="1em">
        <GenericSelectField
          label=""
          field="licenceTypeId"
          formikProps={formikProps}
          options={licenceTypeOptions}
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const LicencedEnterpriseForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error } = useSelector((state: State) => state.form);

  const validationSchema = Yup.object<LicencedEnterpriseInput>().shape({
    licencedEnterpriseId: noUndefinedNumber(),
    enterpriseTypeId: noUndefinedNumber().required('Būtina pasirinkti iš sąrašo'),
    licenceTypeId: noUndefinedNumber().required('Būtina pasirinkti iš sąrašo'),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="VIRS licencijuojamos veiklos įvedimas"
    />
  );
};
