import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { OutletCategoryTypeShort } from '../../../../../store/classifiers/classifiersTypes';
import { OutletCategory } from '../../../../../store/outletInfoData/outletInfoDataTypes';
import { DatePickerState, DropdownStateGeneric } from '../../../../../utils/tableTypes';

export interface CategoryRecordState {
  categoryType: DropdownStateGeneric<OutletCategoryTypeShort>;
  validFrom: DatePickerState;
  loading: boolean;
}

export const initialCategoryRecordState: CategoryRecordState = {
  categoryType: {
    value: null,
    id: 'categoryName',
    error: false,
    helperText: '',
    placeholder: 'Kategorija',
    validated: false,
    options: []
  },
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  loading: false
};

export interface CategoryNewRowState extends CategoryRecordState {
  createRecordConfirmationOn: boolean;
}

export const initialCategoryNewRowState: CategoryNewRowState = {
  ...initialCategoryRecordState,
  createRecordConfirmationOn: false
};

export interface CategoryEditRowState extends CategoryRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

export const initialCategoryEditRowState: CategoryEditRowState = {
  ...initialCategoryRecordState,
  editingOn: false,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false
};

export type CategoryRowAction =
  | {
      type: 'CATEGORY_TYPE_CHANGED';
      categoryType: OutletCategoryTypeShort | null;
    }
  | { type: 'VALID_FROM_DATE_CHANGED'; date: MaterialUiPickersDate | null }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type CategoryNewRowAction =
  | CategoryRowAction
  | {
      type: 'NEW_RECORD_STATE_INITIALIZED';
      availableCategories: OutletCategoryTypeShort[];
      controlledFrom: string | null;
      controlledTo: string | null;
      fromDateForNewCategory: string | null;
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };

export type CategoryEditRowAction =
  | CategoryRowAction
  | {
      type: 'EDITING_INITIALIZED';
      record: OutletCategory;
      availableCategories: OutletCategoryTypeShort[];
      previousCategoryValidFrom: string | null;
      previousCategoryValidTo: string | null;
      nextCategoryValidFrom: string | null;
      controlledFrom: string | null;
      controlledTo: string | null;
    }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };
