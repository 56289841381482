import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';

import { resetForm, State, submitForm } from './store';
import { defaultValues, PaymentAmountInput } from './types';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { GenericNumberField } from '../../../components/FormikFields/GenericFormikWrappers/GenericNumberField';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<PaymentAmountInput>> = (formikProps) => {
  const {
    values: { paymentAmountId }
  } = formikProps;
  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{paymentAmountId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="BSI dydis" spacing="1em">
        <GenericNumberField
          label=""
          formikProps={formikProps}
          field="amount"
          style={{ helper: { whiteSpace: 'nowrap' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Pradžios data" spacing="1em">
        <GenericDateField label="" field="start" formikProps={formikProps} style={dateFieldStyle} />
      </GridFieldGroup>

      <GridFieldGroup label="Pabaigos data" spacing="1em">
        <GenericDateField label="" field="end" formikProps={formikProps} style={dateFieldStyle} />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const PaymentAmountForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error } = useSelector((state: State) => ({
    ...state.form,
    data: state.data
  }));

  const validationSchema = Yup.object().shape({
    paymentAmountId: noUndefinedNumber(),
    amount: Yup.number()
      .typeError('Būtina įvesti reikšmę')
      .required('Būtina įvesti reikšmę')
      .max(1000000, 'Privalo būti mažesnis nei 1000000')
      .min(-1000000, 'Privalo būti didesnis nei -1000000'),
    validFrom: defaultDate().required('Privaloma pasirinkti datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data'),
    start: defaultDate().required('Privaloma pasirinkti Pradžios datą'),
    end: defaultDate()
      .required('Privaloma pasirinkti Pabaigos datą')
      .min(Yup.ref('start'), 'Privalo buti vėlesnė nei Pradžios data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="BSI dydžio įvedimas"
    />
  );
};
