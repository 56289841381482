import React from 'react';
import { Typography, TableSortLabel, TableHead, TableRow, TableCell } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import { useDispatch, useSelector } from 'react-redux';
import { ProvidedDataHistoryHeaderField } from './tableState/tableInitialStateAndTypes';
import { ApplicationState } from '../../store';
import { sortingChanged } from '../../store/providedDataTable/providedDataTableActions';
import { useProvidedDataHistoryTableState } from './Context';

const ProvidedDataHistoryTableHead: React.FC = () => {
  const { tableDataState } = useSelector((state: ApplicationState) => state.providedDataTable);
  const { state: tableState } = useProvidedDataHistoryTableState();
  const reduxDispatch = useDispatch();
  const handleSetSortByColumn = (column: ProvidedDataHistoryHeaderField) => {
    reduxDispatch(sortingChanged(column));
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.dataType && (
          <TableCell
            rowSpan={1}
            key="dataType"
            sortDirection={tableDataState.sortBy === 'dataType' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('dataType')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'dataType' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'dataType' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Duomenų tipas</Typography>
            </TableSortLabel>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.virsName && (
          <TableCell
            rowSpan={1}
            key="VIRS"
            sortDirection={tableDataState.sortBy === 'virsName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('virsName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'virsName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'virsName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIRS</Typography>
            </TableSortLabel>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.outletList && (
          <TableCell
            rowSpan={1}
            key="VIP"
            sortDirection={tableDataState.sortBy === 'outletList' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('outletList')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'outletList' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'outletList' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.documentStatus && (
          <TableCell
            rowSpan={1}
            key="documentStatusObject"
            sortDirection={
              tableDataState.sortBy === 'documentStatus' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('documentStatus')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'documentStatus' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'documentStatus' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Būsena</Typography>
            </TableSortLabel>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.lastEditedDate && (
          <TableCell
            rowSpan={1}
            key="lastEditedDate"
            sortDirection={
              tableDataState.sortBy === 'lastEditedDate' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('lastEditedDate')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'lastEditedDate' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'lastEditedDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Paskutinio įvedimo / redagavimo data</Typography>
            </TableSortLabel>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.lastEditedBy && (
          <TableCell
            rowSpan={1}
            key="lastEditedBy"
            sortDirection={tableDataState.sortBy === 'lastEditedBy' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('lastEditedBy')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'lastEditedBy' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'lastEditedBy' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Paskutinis įvedęs / redagavęs asmuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}

        <TableCell rowSpan={1}>
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>

        <TableCell rowSpan={1} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ProvidedDataHistoryTableHead;
