export const Strings = {
  shareholderTooltip__dateTaken: 'Data jau pridėta',
  shareholderTooltip__virsNonActive: 'VIRS šiuo laikotarpiu veiklos nevykdė',
  shareholderTooltip__dateSigned:
    'Data pasirašyta arba ankstesnė už paskutinę pasirašytą datą, anuliuokite pasirašytą dokumentą norėdami pridėti ankstesnę datą',
  shareholderTooltip__editShareholder: 'Redaguoti dalyvį',
  shareholderTooltip__clearShareholder: 'Išvalyti dalies duomenis',
  shareholderTooltip__clearShareholderApproval:
    'Ar tikrai norite išvalyti dalies duomenis pažymėtam dalyviui?',
  shareholderTooltip__removeShareholderTitle: 'Dalyvio pašalinimas',
  shareholderTooltip__removeShareholder: 'Pašalinti dalyvį',
  shareholderTooltip__removeShareholderApproval: 'Ar tikrai norite pašalinti pažymėtą dalyvį?',

  shareholder__addShareholder: 'Pridėti dalyvį',
  shareholder__notParticipating: 'Dalyvis nebedalyvauja',
  shareholder__dateFromJADIS: 'Data gauta iš JADIS, jos redagavimas negalimas',

  button__cancel: 'Atšaukti',

  addDate: 'Pridėti datą'
};
