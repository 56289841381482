import { LegalEntityFinancialSupportColumnHeader } from '../LegalEntityFinancialSupportTable';

export const columnNames: LegalEntityFinancialSupportColumnHeader[] = [
  {
    id: 'supportProviderName',
    label: 'Paramos teikėjo pavadinimas'
  },
  {
    id: 'supportProviderCode',
    label: 'Kodas'
  },
  {
    id: 'financialSupportAmount',
    label: 'Paramos suma (EUR)'
  },
  {
    id: 'supportReceivedYear',
    label: 'Metai'
  },
  {
    id: 'dataCreatedDate',
    label: 'Duomenų sukūrimo data',
    type: 'date'
  },
  {
    id: 'dataProvidedDate',
    label: 'Duomenų gavimo data',
    type: 'date'
  }
];
