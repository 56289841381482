import { DataTableState, TableAction } from './tableTypes';

import {
  updateCustomFilter,
  getUpdatedPageData,
  getCustomFilteredData,
  sortByColumn,
  getPagesCount
} from './tableReducerFunctions';

export const tableReducer = (state: DataTableState, action: TableAction): DataTableState => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.data
      };
    case 'SYNC_TABLE_STATE':
      return {
        ...state,
        recordsPassed: getCustomFilteredData(state.data, state.customFilter).length,
        pagesCount: getPagesCount(
          getCustomFilteredData(state.data, state.customFilter).length,
          state.rowsPerPage
        ),
        pageData: getUpdatedPageData(
          state.page,
          state.rowsPerPage,
          state.customFilterOn,
          state.data,
          state.customFilter,
          state.order,
          state.sortBy
        )
      };
    case 'SET_SORT_BY_COLUMN':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: sortByColumn(action.sortBy, state.sortBy, state.order)
      };

    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };

    case 'TOGGLE_DISPLAY_CUSTOM_FILTER':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          licenseNumber: [],
          licenseIssueDate: [],
          licenseTypeName: [],
          enterpriseName: [],
          licenseStatus: [],
          validFrom: [],
          validTo: [],
          issuingInstitutionName: [],
          licenseNotes: []
        }
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: updateCustomFilter(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'TOGGLE_DISPLAY_COLUMN':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    default:
      return state;
  }
};
