import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core/';

import { PoliticalAdData } from '../../../store/externalSystemData/externalSystemDataTypes';
import { ColumnsDisplayStatus } from './tableState/tableTypes';
import { replaceDecimalPoint } from '../../../utils/tableDataFunctions';

interface Props {
  data: PoliticalAdData[];
  columnsDisplayStatus: ColumnsDisplayStatus;
}

const DataTableBody: React.FC<Props> = ({ data, columnsDisplayStatus }) => {
  return (
    <TableBody>
      {data.map((record) => (
        <TableRow key={record.vprId}>
          {columnsDisplayStatus.distributorName && <TableCell>{record.distributorName}</TableCell>}
          {columnsDisplayStatus.distributorCode && <TableCell>{record.distributorCode}</TableCell>}
          {columnsDisplayStatus.interestedPartyName && (
            <TableCell>{record.interestedPartyName}</TableCell>
          )}
          {columnsDisplayStatus.campaignName && <TableCell>{record.campaignName}</TableCell>}
          {columnsDisplayStatus.politicalAdDateFrom && (
            <TableCell>
              {record.politicalAdDateFrom
                ? new Date(record.politicalAdDateFrom).toLocaleDateString('lt-LT')
                : null}
            </TableCell>
          )}
          {columnsDisplayStatus.politicalAdDateTo && (
            <TableCell>
              {record.politicalAdDateTo
                ? new Date(record.politicalAdDateTo).toLocaleDateString('lt-LT')
                : null}
            </TableCell>
          )}
          {columnsDisplayStatus.financialSupportAmount && (
            <TableCell>{replaceDecimalPoint(record.financialSupportAmount.toString())}</TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default DataTableBody;
