export const FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST = '@ethicalNonCompliance/FETCH_DATA_REQUEST';
export const FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS =
  '@thicalNonCompliance/FETCH_DATA_REQUEST_SUCCESS';
export const FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR =
  '@ethicalNonCompliance/FETCH_DATA_REQUEST_ERROR';

export const FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST =
  '@ethicalNonCompliance/FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST';
export const FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS =
  '@ethicalNonCompliance/FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS';
export const FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR =
  '@ethicalNonCompliance/FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR';

export const FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DOCUMENT_PDF_REQUEST =
  '@ethicalNonCompliance/FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DOCUMENT_PDF_REQUEST';

export const CREATE_ETHICAL_NON_COMPLIANCE_REQUEST =
  '@ethicalNonCompliance/CREATE_ETHICAL_NON_COMPLIANCE_REQUEST';
export const CREATE_ETHICAL_NON_COMPLIANCE_SUCCESS =
  '@ethicalNonCompliance/CREATE_ETHICAL_NON_COMPLIANCE_SUCCESS';
export const CREATE_ETHICAL_NON_COMPLIANCE_ERROR =
  '@ethicalNonCompliance/CREATE_ETHICAL_NON_COMPLIANCE_ERROR';
export const RESET_CREATE_ETHICAL_NON_COMPLIANCE_STATE =
  '@ethicalNonCompliance/RESET_CREATE_ETHICAL_NON_COMPLIANCE_STATE';
export const CONTINUE_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED =
  '@ethicalNonCompliance/CONTINUE_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED';
export const CANCEL_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED =
  '@ethicalNonCompliance/CANCEL_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED';

export const UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST =
  '@ethicalNonCompliance/UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST';
export const UPDATE_ETHICAL_NON_COMPLIANCE_SUCCESS =
  '@ethicalNonCompliance/UPDATE_ETHICAL_NON_COMPLIANCE_SUCCESS';
export const UPDATE_ETHICAL_NON_COMPLIANCE_ERROR =
  '@ethicalNonCompliance/UPDATE_ETHICAL_NON_COMPLIANCE_ERROR';
export const RESET_UPDATE_ETHICAL_NON_COMPLIANCE_STATE =
  '@ethicalNonCompliance/RESET_UPDATE_ETHICAL_NON_COMPLIANCE_STATE';

export const REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST =
  '@ethicalNonCompliance/REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST';
export const REMOVE_ETHICAL_NON_COMPLIANCE_SUCCESS =
  '@ethicalNonCompliance/REMOVE_ETHICAL_NON_COMPLIANCE_SUCCESS';
export const REMOVE_ETHICAL_NON_COMPLIANCE_ERROR =
  '@ethicalNonCompliance/REMOVE_ETHICAL_NON_COMPLIANCE_ERROR';
export const RESET_REMOVE_ETHICAL_NON_COMPLIANCE_STATE =
  '@ethicalNonCompliance/RESET_REMOVE_ETHICAL_NON_COMPLIANCE_STATE';

export const ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST =
  '@ethicalNonCompliance/ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST';
export const ANNUL_ETHICAL_NON_COMPLIANCE_SUCCESS =
  '@ethicalNonCompliance/ANNUL_ETHICAL_NON_COMPLIANCE_SUCCESS';
export const ANNUL_ETHICAL_NON_COMPLIANCE_ERROR =
  '@ethicalNonCompliance/ANNUL_ETHICAL_NON_COMPLIANCE_ERROR';
export const RESET_ANNUL_ETHICAL_NON_COMPLIANCE_STATE =
  '@ethicalNonCompliance/RESET_ANNUL_ETHICAL_NON_COMPLIANCE_STATE';

export const FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID =
  '@ethicalNonCompliance/FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID';
