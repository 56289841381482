import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import {
  ProfessionalMisconductTableState,
  initialProfessionalMisconductTableState,
  ProfessionalMisconductTableAction
} from './tableState/tableInitialStateAndTypes';
import { ProfessionalMisconductTableReducer } from './tableState/tableReducer';
import ProfessionalMisconductTable from './Table';

const ProfessionalMisconductTableStateContext = createContext<{
  state: ProfessionalMisconductTableState;
}>({
  state: initialProfessionalMisconductTableState
});

const ProfessionalMisconductTableDispatchContext = createContext<{
  dispatch: Dispatch<ProfessionalMisconductTableAction>;
}>({
  dispatch: () => {}
});

export const ProfessionalMisconductTableWithContext: React.FC = () => {
  const [state, dispatch] = useReducer(
    ProfessionalMisconductTableReducer,
    initialProfessionalMisconductTableState
  );

  return (
    <ProfessionalMisconductTableStateContext.Provider value={{ state }}>
      <ProfessionalMisconductTableDispatchContext.Provider value={{ dispatch }}>
        <ProfessionalMisconductTable />
      </ProfessionalMisconductTableDispatchContext.Provider>
    </ProfessionalMisconductTableStateContext.Provider>
  );
};

export function useProfessionalMisconductTableState() {
  return useContext(ProfessionalMisconductTableStateContext);
}
export function useProfessionalMisconductTableDispatch() {
  return useContext(ProfessionalMisconductTableDispatchContext);
}
