import React, { useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  Button,
  Dialog,
  Divider,
  createStyles,
  makeStyles,
  Grid
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { UserMessage } from '../../store/userMessages/userMessageTypes';
import { TooltipOnTableActionButton } from '../Tooltips/TooltipOnTableActionButton';
import { ConfirmRemoveDialog } from '../Dialogs/ConfirmRemoveDialog/ConfirmRemoveDialog';
import virsisTheme from '../../style/virsisTheme';
import GoBackButton from '../PageButtons/GoBackButton';
import { generateAbsoluteDocumentTablePathByDataType } from '../../utils/tableDataFunctions';
import { VirsisUser } from '../../store/virsis/dataTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const useStyles = makeStyles(() =>
  createStyles({
    removeButton: {
      color: virsisTheme.palette.info.dark,
      '&:hover': {
        backgroundColor: 'transparent',
        color: virsisTheme.palette.error.main,
        borderColor: virsisTheme.palette.error.main
      }
    }
  })
);

export interface Props {
  message: UserMessage;
  closeDialog: (message: UserMessage) => void;
  removeMessage: () => void;
  isProcessing: boolean;
  error: Error | undefined;
  onRemoveError: () => void;
  currentUser?: VirsisUser;
  closeList?: () => void;
}

export const UserMessageDialog: React.FC<Props> = ({
  message,
  closeDialog,
  removeMessage,
  isProcessing,
  error,
  onRemoveError,
  currentUser,
  closeList
}) => {
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);

  const classes = useStyles();

  const { header, content, reportingDataType, reportingDocumentStatusId } = message;
  const { selectedAccountType } = useSelector((state: ApplicationState) => state.virsis);

  const pathToViewOnTable =
    reportingDataType && reportingDocumentStatusId
      ? generateAbsoluteDocumentTablePathByDataType(
          reportingDataType,
          reportingDocumentStatusId,
          currentUser,
          selectedAccountType
        )
      : undefined;

  const handleDocumentClick = () => {
    closeDialog(message);
    closeList?.();
  };

  return (
    <Dialog
      open
      onClose={() => {
        closeDialog(message);
      }}
      onExit={() => {
        closeDialog(message);
      }}
      fullWidth
      maxWidth="md"
    >
      {showRemoveConfirmation && (
        <ConfirmRemoveDialog
          open={showRemoveConfirmation}
          dialogTitle="Pranešimo pašalinimas"
          dialogText="Ar tikrai norite pašalinti šį pranešimą?"
          mainButtonText="Pašalinti pranešimą"
          onClose={() => setShowRemoveConfirmation(false)}
          onSubmit={removeMessage}
          isProcessing={isProcessing}
          errorMessage={error?.message || ''}
          isError={!!error}
          onErrorClose={() => {
            setShowRemoveConfirmation(false);
            onRemoveError();
          }}
        />
      )}
      <Paper>
        <div style={{ padding: '25px 35px 50px' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="h3">{header}</Typography>
            <div>
              <TooltipOnTableActionButton text="Pašalinti žinutę">
                <Button
                  className={classes.removeButton}
                  variant="outlined"
                  onClick={() => setShowRemoveConfirmation(true)}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </TooltipOnTableActionButton>
            </div>
          </Grid>
          <Divider />
          {pathToViewOnTable && (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              style={{ paddingBottom: '10px' }}
            >
              <Grid item>
                <Typography>Dokumento pavadinimas: </Typography>
              </Grid>
              <Grid item>
                <Link to={pathToViewOnTable} onClick={handleDocumentClick}>
                  {`${reportingDocumentStatusId}.pdf`}{' '}
                </Link>
              </Grid>
            </Grid>
          )}
          <Grid container item direction="column" justify="flex-end" alignItems="flex-start">
            <Typography style={{ color: '#637082', paddingTop: 15 }}>{content}</Typography>
          </Grid>
        </div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          style={{ backgroundColor: '#f6f6f6' }}
        >
          <Box left={30} component="span" m={1}>
            <GoBackButton
              btnText="Atgal"
              onClick={() => {
                closeDialog(message);
              }}
            />
          </Box>
        </Grid>
      </Paper>
    </Dialog>
  );
};
