import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import virsisTheme from '../../../style/virsisTheme';

interface Props {
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  labelBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridColumnStart: 1,
    gridColumnEnd: 2
  },
  inputBox: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: 2,
    gridColumnEnd: 3
  },
  [theme.breakpoints.down('sm')]: {
    labelBox: {
      justifyContent: 'flex-start',
      gridColumnStart: 1,
      gridColumnEnd: 3
    },
    inputBox: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      flexWrap: 'wrap'
    }
  }
}));

export const GridForm: React.FC<PropsWithChildren<Props>> = ({ style, children }) => {
  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: '20px',
        ...style
      }}
    >
      {children}
    </Box>
  );
};

export type GridFieldGroupProps = PropsWithChildren<{
  label?: string;
  disabled?: boolean;
  spacing?: string;
}>;

export const GridFieldGroup: React.FC<GridFieldGroupProps> = ({
  label,
  disabled,
  children,
  spacing
}) => {
  const color = disabled ? virsisTheme.palette.text.disabled : undefined;
  const classes = useStyles();

  return (
    <>
      {label && (
        <Box className={classes.labelBox}>
          <Typography variant="h3" style={{ color }}>
            {label}
          </Typography>
        </Box>
      )}

      <Box className={classes.inputBox}>{children}</Box>

      {spacing && <Box style={{ gridColumnStart: 1, gridColumnEnd: 3, paddingTop: spacing }} />}
    </>
  );
};
