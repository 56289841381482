import { get, post } from '../../utils/axiosApi';
import { ReportInstitutionDataRecord } from './institutionDataReportsTypes';

export const getInstitutionDataReportData = () => get('/institution-data-reports');

export const postReportErrorInInstitutionData = (report: ReportInstitutionDataRecord) => {
  return post('/institution-data-reports', report);
};

export const getInaccurateDataMessage = (docId: number) => get(`/messages/${docId}`);
