import React, { ReactNode, useCallback, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Box, TableCell } from '@material-ui/core';
import { OutletRecord, OutletData } from '../../../store/outlets/outletsTypes';
import { ApplicationState } from '../../../store';
import { updateOutlet } from '../../../store/outlets/outletsActions';
import { intialSemiEditRowState } from './rowState/outletRowStateAndTypes';
import { OutletBasicRow } from './RowBasic';
import { RowActionButtonGeneric } from '../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { RowActionButton } from '../../../components/TableRowActions/RowActionButton';

import { SaveAndCancelIcons } from '../../../components/TableRowActions/SaveAndCancelIcons';
import { semiEditOutletReducer } from './rowState/outletReducerSemi';
import { determineRecordStatus } from '../../../utils/tableDataFunctions';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { OutletDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { validatedOutletRecordRowStateForDate } from './rowState/outletRowReducerFunctions';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { CustomEditIconButton } from '../../../components/Icons/IconButtons/CustomEditIconButton';
import { useOutletsTableDispatch, useOutletsTableState } from '../Context';

interface RowContainerProps {
  enterprise: EnterpriseData;
  record: OutletData;
  toggleRowExtension?: () => void;
  isExtended?: boolean;
  virsLegalRegistrationDate: string;
  virsLegalDeregistrationDate: string | null;
  redirectButtonLabel?: string;
  redirectToOutletInfo?: () => void;
  redirectToEditions: () => void;
  rowExtensionComponent?: ReactNode;
  display: OutletDataTableColumnsDisplayStatus;
  showStatusNotSigned: boolean;
  resetColumnDisplay?: () => void;
}

const OutletRowContainerSemiEditable: React.FC<RowContainerProps> = ({
  enterprise,
  record,
  toggleRowExtension,
  isExtended,
  virsLegalRegistrationDate,
  virsLegalDeregistrationDate,
  redirectButtonLabel,
  redirectToOutletInfo,
  redirectToEditions,
  rowExtensionComponent,
  display,
  resetColumnDisplay
}) => {
  const [rowState, rowDispatch] = useReducer(semiEditOutletReducer, intialSemiEditRowState);
  const { dispatch: tableDispatch } = useOutletsTableDispatch();

  const {
    virsis: { virsData },
    outletData: { updatingOutlet, isOutletUpdated }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const { state: tableState } = useOutletsTableState();

  const recordStatus = determineRecordStatus(record);

  function activateEditing() {
    rowDispatch({
      type: 'SEMI_EDIT_BUTTON_CLICKED',
      outlet: record,
      enterpriseValidFrom: enterprise.validFrom,
      enterpriseValidTo: enterprise.validTo,
      registrationDate: virsLegalRegistrationDate,
      deregistrationDate: virsLegalDeregistrationDate
    });
    tableDispatch({ type: 'EDITING_ON' });
    if (resetColumnDisplay) {
      resetColumnDisplay();
    }
  }

  function setOutletControlledTo(controlledTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'OUTLET_CONTROLLED_TO_CHANGED', controlledTo });
  }

  function updateValidToDateForRecord() {
    rowDispatch({ type: 'ADD_END_DATE_CLICKED' });
    if (validatedOutletRecordRowStateForDate(rowState).updateDateConfirmationOn) {
      const message = 'VIP pabaigos data atnaujinta';
      const updated: OutletRecord = {
        outletName: record.outletName,
        outletTypeId: record.outletTypeId,
        establishedDate: record.establishedDate,
        controlledFrom: record.controlledFrom,
        controlledTo: rowState.controlledTo.value ? rowState.controlledTo.value.format('L') : null,
        isbn: record.isbn,
        issn: record.issn,
        enterpriseId: record.enterpriseId
      };
      reduxDispatch(updateOutlet(virsData?.virsId, record.virOutletControlId, updated, message));
    }
  }

  function unsetOutletDate() {
    const message = 'VIP pabaigos data išvalyta';
    const updated: OutletRecord = {
      outletName: record.outletName,
      outletTypeId: record.outletTypeId,
      establishedDate: record.establishedDate,
      controlledFrom: record.controlledFrom,
      controlledTo: null,
      isbn: record.isbn,
      issn: record.issn,
      enterpriseId: record.enterpriseId
    };
    reduxDispatch(updateOutlet(virsData?.virsId, record.virOutletControlId, updated, message));
  }

  const deactivateEditing = useCallback(() => {
    rowDispatch({ type: 'CANCEL_EDITING_BUTTON_CLICKED' });
    tableDispatch({ type: 'EDITING_OFF' });
  }, [tableDispatch]);

  useEffect(() => {
    deactivateEditing();
  }, [isOutletUpdated, deactivateEditing]);

  return (
    <>
      {!rowState.editingOn && (
        <OutletBasicRow
          rowStatus={recordStatus}
          toggleExtension={toggleRowExtension}
          isExtended={isExtended}
          record={record}
          display={display}
          rowExtensionComponent={rowExtensionComponent}
          outletControlledToInput={
            <TooltipOnRowRibbon
              status={record.confirmationStatus === 'U' ? 'RUOSIAMAS' : undefined}
            >
              <TableCell
                style={
                  record.confirmationStatus === 'U' ? { boxShadow: '8px 0 0 #50C9F3 inset' } : {}
                }
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={record.controlledTo ? 'space-between' : 'flex-end'}
                  alignItems="center"
                >
                  {record.controlledTo && record.controlledTo}
                  {!tableState.tableInputOn && (
                    <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                      <CustomEditIconButton onClick={activateEditing} />
                    </AllowedTo>
                  )}
                </Box>
              </TableCell>
            </TooltipOnRowRibbon>
          }
          outletRowActions={
            <Box style={{ maxWidth: 130 }}>
              {redirectToOutletInfo && (
                <RowActionButtonGeneric
                  id={`view-record-button-${record.outletId}`}
                  label={redirectButtonLabel || ''}
                  onButtonClicked={redirectToOutletInfo}
                />
              )}
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                {!record.controlledTo && (
                  <RowActionButtonGeneric
                    // style={{ label: { whiteSpace: 'nowrap' } }}
                    label="Įvesti pabaigos datą"
                    onButtonClicked={activateEditing}
                    disabled={tableState.tableInputOn}
                  />
                )}
                {record.controlledTo && (
                  <RowActionButton
                    label="Išvalyti"
                    onButtonClick={unsetOutletDate}
                    isProcessing={updatingOutlet}
                  />
                )}
              </AllowedTo>
            </Box>
          }
        />
      )}
      {rowState.editingOn && (
        <OutletBasicRow
          toggleExtension={toggleRowExtension}
          isExtended={isExtended}
          display={display}
          record={record}
          rowExtensionComponent={rowExtensionComponent}
          outletControlledToInput={
            <DateInputImprovedCell
              state={rowState.controlledTo}
              setDate={setOutletControlledTo}
              isRequired
              helperItem={
                <SaveAndCancelIcons
                  handleConfirmationYes={updateValidToDateForRecord}
                  isProcessing={updatingOutlet}
                  handleCancelButtonClick={deactivateEditing}
                />
              }
            />
          }
        />
      )}
    </>
  );
};

export default OutletRowContainerSemiEditable;
