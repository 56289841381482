import React, { ReactNode, useState } from 'react';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import 'moment/locale/lt';
import { CalendarButton } from './CalendarButton';
import { Strings } from '../../../../../utils/strings/Strings';

interface DatePickerCoreProps {
  isRequired?: boolean;
  helperItem?: ReactNode;
  disabled?: boolean;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  customMaxDate?: string;
  customMinDate?: string;
}

interface DatePickerImprovedProps extends DatePickerCoreProps {
  state: FilterDatePickerState;
  setDate: (date: MaterialUiPickersDate | null) => void;
  calendarDisplayProps: any;
}

export const DateInput: React.FC<DatePickerImprovedProps> = ({
  state,
  setDate,
  isRequired,
  disabled,
  shouldDisableDate,
  customMaxDate,
  customMinDate,
  calendarDisplayProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openCaledar = () => setIsOpen(true);
  const closeCaledar = () => setIsOpen(false);

  const handleDateChange = (value: MaterialUiPickersDate | null) => {
    setDate(value || null);
  };

  const handleCalendarClick = () => {
    setIsOpen(true);
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk
            value={state.value}
            onChange={handleDateChange}
            minDate={customMinDate}
            maxDate={customMaxDate}
            error={state.error}
            helperText={state.helperText}
            placeholder={calendarDisplayProps.placeholder}
            label={state.label || undefined}
            required={isRequired}
            inputVariant="outlined"
            format={calendarDisplayProps.format}
            invalidDateMessage={calendarDisplayProps.invalidDateMessage}
            InputAdornmentProps={{
              style: {
                display: 'none'
              }
            }}
            open={isOpen}
            onOpen={openCaledar}
            onClose={closeCaledar}
            okLabel="Pasirinkti"
            cancelLabel={Strings.button__cancel}
            clearable
            clearLabel="Išvalyti"
            id={state.id}
            style={{ width: '130px' }}
            shouldDisableDate={shouldDisableDate}
            disabled={disabled}
            openTo={calendarDisplayProps.openTo}
            views={calendarDisplayProps.views}
          />
        </MuiPickersUtilsProvider>
        <CalendarButton id={state.id} onClick={handleCalendarClick} disabled={disabled} />
      </div>
    </Grid>
  );
};

export interface FilterDatePickerState {
  value: MaterialUiPickersDate | null;
  error: boolean;
  validated: boolean;
  helperText?: string;
  label?: string;
  id?: string;
}
