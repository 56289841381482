import * as React from 'react';
import { Form, Formik } from 'formik';
import { Button, FormGroup, LinearProgress, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';
import { identity, pickBy } from 'lodash';
import { VirsSearchQuery } from '../../store/virsis/dataTypes';
import { EnterpriseType } from '../../store/classifiers/classifiersTypes';
import { FormikFormTextField } from '../FormikFields/FormikFormTextField';
import { useDialogFormStyles } from '../FormikFields/FieldStyles';
import { FormikFormSelectField } from '../FormikFields/FormikFormSelectField';

interface Props {
  setQuerySize: (size: number) => void;
  enterpriseTypes: EnterpriseType[];
  searchVirs: (virsQuery: VirsSearchQuery) => void;
  isSearching: boolean;
}
export interface Values {
  personCode?: string;
  personFullName?: string;
  enterpriseType: EnterpriseType | null;
  outletName?: string;
}

export const FindVirsDialogForm: React.FC<Props> = ({
  setQuerySize,
  enterpriseTypes,
  searchVirs,
  isSearching
}) => {
  const classes = useDialogFormStyles();

  const initValues: Partial<Values> = {
    personCode: '',
    personFullName: '',
    enterpriseType: null,
    outletName: ''
  };

  const validationSchema = Yup.object({
    personCode: Yup.string(),
    name: Yup.string(),
    enterpriseType: Yup.object().nullable(),
    outletName: Yup.string()
  });

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery: VirsSearchQuery = {
          personCode: values.personCode !== '' ? values.personCode : undefined,
          personFullName: values.personFullName !== '' ? values.personFullName : undefined,
          enterpriseTypeId: values.enterpriseType
            ? Number(values.enterpriseType.enterpriseTypeId)
            : undefined,
          outletName: values.outletName !== '' ? values.outletName : undefined,
          onlySigned: true
        };
        setQuerySize(Object.keys(pickBy(searchQuery, identity)).length);
        searchVirs(searchQuery);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Form>
            <FormGroup style={{ maxWidth: 'fit-content', paddingTop: '30px' }}>
              <FormikFormTextField
                styledClasses={classes}
                label="Įmonės kodas"
                formikKey="personCode"
                values={values.personCode}
                error={errors.personCode}
                setFieldValue={setFieldValue}
              />
              <FormikFormTextField
                styledClasses={classes}
                label="Pavadinimas"
                formikKey="personFullName"
                values={values.personFullName}
                error={errors.personFullName}
                setFieldValue={setFieldValue}
              />
              <FormikFormSelectField
                styledClasses={classes}
                isRequired={false}
                options={enterpriseTypes}
                errors={errors.enterpriseType}
                formikKey="enterpriseType"
                setFieldValue={setFieldValue}
                value={values.enterpriseType}
                defaultValue=""
                label="VIRS rūšis"
                isClearable
                getOptionLabel={(option?: EnterpriseType) =>
                  option ? option.enterpriseTypeName : ''
                }
              />
              <FormikFormTextField
                styledClasses={classes}
                label="VIP pavadinimas"
                formikKey="outletName"
                values={values.outletName}
                error={errors.outletName}
                setFieldValue={setFieldValue}
              />
              <Box margin={1}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isValidating || isSearching}
                  onClick={submitForm}
                  className={classes.button}
                >
                  <Typography color="inherit">Ieškoti</Typography>
                </Button>
              </Box>
              {isSearching && <LinearProgress />}
            </FormGroup>
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
