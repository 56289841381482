import React from 'react';
import { Typography, Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import virsisTheme from '../../style/virsisTheme';

interface Props {
  onClick?: () => void;
  additionalStyles?: React.CSSProperties;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    margin: {
      margin: '15px 0px',
      marginLeft: '5px'
    },
    button: {
      padding: 0,
      borderColor: 'transparent',
      backgroundColor: virsisTheme.palette.secondary.light,
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      color: '#637082',
      [theme.breakpoints.down('xs')]: {
        // flex: 1,
        justifyContent: 'flex-start',
        marginTop: 15,
        wordWrap: 'break-word',
        hyphens: 'auto',
        display: 'flex'
      }
    },
    themeButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.primary.main
      },
      '&:active': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: '#DBEDFC',
        color: virsisTheme.palette.primary.main
      }
    },
    icon: {
      height: 30,
      padding: '5px 10px',
      borderRight: '1px solid #C4E2FC',
      color: 'inherit'
    },
    text: {
      color: '#637082',
      padding: '1px 15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 0,
        display: 'none',
      }
    }
  })
);

const PrintButton: React.FC<Props> = ({ onClick, additionalStyles }) => {
  const classes = useStyles();

  return (
    <>
      <Button
        variant="outlined"
        onClick={onClick}
        className={`${classes.button} ${classes.themeButton} ${classes.margin}`}
        style={additionalStyles}
      >
        <PrintIcon fontSize="small" className={classes.icon} />
        <Typography variant="h5" className={classes.text}>
          Spausdinti
        </Typography>
      </Button>
    </>
  );
};
export default PrintButton;
