import React from 'react';
import { TableCell, makeStyles, TableRow, Typography } from '@material-ui/core/';

import { useDispatch, useSelector } from 'react-redux';
import { ShareholderRows } from './ShareholderRows';
import { ColorBarVirs } from '../Components/ColorBar/ColorBarVirs';
import { ApplicationState } from '../../../../store';
import {
  useShareholdersSelectedDateTableState,
  useShareholdersSelectedDateTableDispatch
} from '../TableWithContext';
import { ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import { ColorBarPrevFollDate } from '../Components/ColorBar/ColorBarPrevFollDate';
import { mainDarkColor, virsColor } from '../../constants';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { TriangleButton } from '../../../../components/ShareholderComponents/TableButtons/TriangleButton';
import {
  filterErrorObjs,
  getCurrentPeriod,
  getMinimumDate
} from '../../utilityFunctions/sharedFunctions';
import { EditShareholderRow } from './EditShareholderRow/EditShareholderRow';
import theme from '../../../../style/virsisTheme';
import { SelectShareholderRow } from './SelectShareholderRow';
import { ShareholderNameCellButtons } from '../../../../components/ShareholderComponents/ShareholderNameCellButtons';
import {
  setExpandParents,
  setExpandShareholder,
  unsetUpdateShareholderId
} from '../../../../store/shareholders/shareholdersActions';
import { LevelBar } from '../../../../components/ShareholderComponents/TableButtons/LevelBar';
import { getIsLastShareholder } from '../utilities/utilityFunctions';

interface Props {
  index: number;
  data: ShareholdersData;
  previousDate?: string;
  followingDate?: string;
  selectedDate: string;
  filteredShareholderLevel?: string;
}

const useStyles = makeStyles({
  prevOrFollDateCell: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.light,
    opacity: '0.5'
  },
  virsAddShareholderButton: {
    marginRight: 25
  },
  positionRel: {
    position: 'relative'
  },
  flexSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  inlineBlock: {
    display: 'inline-block'
  }
});

export const ShareholdersSelectedDateRowContainer: React.FC<Props> = ({
  index,
  data,
  previousDate,
  followingDate,
  selectedDate,
  filteredShareholderLevel
}) => {
  const classes = useStyles();

  const { state: tableState } = useShareholdersSelectedDateTableState();
  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();

  const {
    virsis: { virsData, currentUser, selectedPersonId },
    shareholdersData: { shareholdersData }
  } = useSelector((state: ApplicationState) => state);
  const reduxDispatch = useDispatch();

  const { shareholders, errors, virsShareholderId } = data;

  const virsIdString = String(virsShareholderId);

  const showChildShareholder = true;

  const handleShowChildShareholder = () => {
    reduxDispatch(setExpandShareholder(virsIdString, undefined, index));

    if (filteredShareholderLevel) {
      tableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: true });
      tableDispatch({
        type: 'CUSTOM_FILTER_VALUE_CHANGED',
        filterBy: 'shareholderLevel',
        value: null
      });
    }
  };

  const showEditShareholderRow =
    tableState.showAddShareholderRow &&
    tableState.parentIdToAddChild &&
    virsData &&
    tableState.parentIdToAddChild === virsData.virsId;

  const showSelectShareholderRow =
    tableState.showSelectShareholderRow &&
    tableState.parentIdToAddChild &&
    tableState.index === index &&
    virsData &&
    tableState.parentIdToAddChild === virsData.virsId;

  const handleSelectShareholderRowOpen = () => {
    if (virsData) {
      tableDispatch({
        type: 'SET_PARENT_ID_TO_ADD_CHILD',
        id: virsData.virsId,
        index
      });
    }

    tableDispatch({
      type: 'SHOW_SELECT_SHAREHOLDER_ROW',
      showSelectShareholderRow: true
    });
    reduxDispatch(setExpandParents(virsIdString));
    reduxDispatch(unsetUpdateShareholderId());
  };

  const criticalErrorMessages = filterErrorObjs(errors, ErrorType.CRITICAL);
  const nonCriticalErrorMessages = filterErrorObjs(errors, ErrorType.NONCRITICAL);
  const infoNotifications = filterErrorObjs(errors, ErrorType.INFO);

  const showTriangleButton = !!shareholders.length;

  const currentActivityPeriod = getCurrentPeriod(
    selectedDate,
    shareholdersData?.activityPeriods || []
  );

  const minimumDate = getMinimumDate(data);

  const getStartDate = () => {
    const activateStartDate =
      currentActivityPeriod?.startDate > minimumDate
        ? currentActivityPeriod?.startDate
        : minimumDate;
    return activateStartDate || data.virsStartDate;
  };

  return (
    <>
      {virsData ? (
        <>
          <TableRow>
            <TableCell className="header-level-column first-col">
              <Typography variant="h5" style={{ color: mainDarkColor }}>
                0
              </Typography>
            </TableCell>
            <TableCell className={classes.positionRel}>
              {showTriangleButton && (
                <LevelBar level={0} virs halfBottom showChildShareholder={showChildShareholder} />
              )}

              <div className={`${classes.positionRel} ${classes.flexSpaceBetween}`}>
                <div className="flex-container">
                  <TriangleButton
                    showTriangleButton={showTriangleButton}
                    expandChild={showChildShareholder}
                    onClicked={handleShowChildShareholder}
                    marginLeft={getMarginLeft(0)}
                  />
                  <Typography variant="h5" className={classes.inlineBlock}>
                    {data.virsName}
                  </Typography>
                </div>

                <div className="margin-right-25">
                  <ShareholderNameCellButtons
                    index={index}
                    shareholder={data}
                    mayShowErrorIcons
                    showAddShareholderButton={data.mayShareholderHaveChildren}
                    criticalErrorMessages={criticalErrorMessages}
                    nonCriticalErrorMessages={nonCriticalErrorMessages}
                    infoNotifications={infoNotifications}
                    handleAddShareholderButtonClicked={handleSelectShareholderRowOpen}
                    selectedDate={selectedDate}
                  />
                </div>
              </div>
            </TableCell>

            <TableCell className={classes.prevOrFollDateCell}>
              {previousDate && data.virsStartDate <= previousDate && (
                <ColorBarPrevFollDate
                  borderColor={theme.palette.primary.main}
                  fillWithColor={theme.palette.primary.main}
                />
              )}
            </TableCell>

            <TableCell className={classes.positionRel}>
              {data.virsStartDate <= selectedDate &&
                (data.virsStoppedParticipatingFromDate === null ||
                  data.virsStoppedParticipatingFromDate >= selectedDate) && (
                  <ColorBarVirs
                    selectedDate={selectedDate}
                    startDate={getStartDate()}
                    endDate={data.virsStoppedParticipatingFromDate}
                    fillWithColor={virsColor}
                    borderColor={virsColor}
                    textColor={theme.palette.secondary.light}
                    value="VIRS"
                    virsEndDate={data.virsEndDate}
                    currentActivityPeriod={currentActivityPeriod}
                  />
                )}
            </TableCell>

            <TableCell className={classes.prevOrFollDateCell}>
              {followingDate &&
                (data.virsStoppedParticipatingFromDate === null ||
                  data.virsStoppedParticipatingFromDate >= followingDate) && (
                  <ColorBarPrevFollDate
                    borderColor={theme.palette.primary.main}
                    fillWithColor={theme.palette.primary.main}
                  />
                )}
            </TableCell>
          </TableRow>
          {showEditShareholderRow && currentUser && (
            <EditShareholderRow
              index={index}
              shareholderLevel={1}
              parentLegalFormCode={data.virsLegalFormCode}
              parentPersonId={selectedPersonId || currentUser.personId}
              parentShareholder={data}
              mayShareholderHaveChildren={data.mayShareholderHaveChildren}
            />
          )}
          {showSelectShareholderRow && <SelectShareholderRow shareholderLevel={1} index={index} />}
          {showChildShareholder &&
            !!shareholders.length &&
            shareholders.map((shareholder, newIndex) => (
              <ShareholderRows
                index={newIndex + index}
                key={`${shareholder.shareholderId}` + selectedDate + (newIndex + index)}
                parentShareholder={data}
                shareholder={shareholder}
                previousDate={previousDate}
                followingDate={followingDate}
                selectedDate={selectedDate}
                virId={virsData.virsId}
                isLastShareholder={getIsLastShareholder(newIndex, shareholders)}
                virsEndDate={data.virsEndDate}
                virsStoppedParticipatingFromDate={data.virsStoppedParticipatingFromDate}
                filteredShareholderLevel={filteredShareholderLevel}
              />
            ))}
        </>
      ) : null}
    </>
  );
};
