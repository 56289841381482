import React from 'react';
import { Typography, TableSortLabel, TableHead, TableRow, TableCell } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import { Order, ColumnsDisplayStatus, ColumnHeaderField } from './tableState/tableTypes';
import { LegalEntityFinancialSupportColumnHeader } from './LegalEntityFinancialSupportTable';

interface Props {
  columnNames: LegalEntityFinancialSupportColumnHeader[];
  order: Order;
  sortBy: ColumnHeaderField;
  onSortByColumnClick: (column: ColumnHeaderField) => void;
  columnsDisplayStatus: ColumnsDisplayStatus;
}
const DataTableHead: React.FC<Props> = ({
  columnNames,
  sortBy,
  order,
  onSortByColumnClick,
  columnsDisplayStatus
}) => {
  return (
    <TableHead>
      <TableRow>
        {columnNames
          .filter((column) => columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              sortDirection={sortBy === column.id ? order : false}
              className="TableCellSortable"
              onClick={() => onSortByColumnClick(column.id)}
            >
              <TableSortLabel
                active
                direction={sortBy === column.id ? order : 'asc'}
                IconComponent={sortBy === column.id ? ActiveSortIcon : SortIcon}
              >
                <Typography variant="h4">{column.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
export default DataTableHead;
