import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OutletShortData } from '../../store/outlets/outletsTypes';
import { ApplicationState } from '../../store';
import { applicationStorage } from '../../utils/axiosApi';
import { outletTabs } from '../../containers/OutletInfoTables/tablesState/tablesTypesAndActions';

interface Props {
  outlets: OutletShortData[];
  virsId: number;
  outletId?: number;
}

const useStyles = makeStyles({
  displayColumn: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const setOutletEndpoint = (virsId: number | undefined): string | null => {
  const context = applicationStorage.getItem('context');
  if (context !== 'INST') {
    return '/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones';
  }
  return `/virs-duomenys/${virsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones`;
};

export const OutletsAsLinksList: React.FC<Props> = ({ outlets, virsId }) => {
  const classes = useStyles();
  const {
    classifiers: { fictitiousOutlet }
  } = useSelector((state: ApplicationState) => state);

  const endpoint = setOutletEndpoint(virsId);

  return (
    <>
      {outlets.map((outlet) =>
        fictitiousOutlet && outlet.outletId === fictitiousOutlet[0].outletId ? (
          outlet.outletName
        ) : (
          <Link
            className={classes.displayColumn}
            key={`${outlet.outletId}${outlet.outletName}`}
            to={`${endpoint}/${outlet.outletId}/${
              outletTabs.find((tab) => tab.tab === 'all')?.path
            }`}
            target="_blank"
          >{`${outlet.outletName}`}</Link>
        )
      )}
    </>
  );
};
