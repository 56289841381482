import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core/';

import {
  OutletDataTableField,
  OutletDataTableColumnsDisplayStatus
} from './tableState/initialStateAndTypes';
import { SortOrder } from '../../utils/tableTypes';
import { OutletData } from '../../store/outlets/outletsTypes';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../utils/tableDataFunctions';
import { Order } from '../../components/TableTypes/TableTypes';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';

interface Props {
  order: SortOrder;
  sortBy: OutletDataTableField;
  columnsDisplayStatus: OutletDataTableColumnsDisplayStatus;
  setSorting: (column: OutletDataTableField) => void;
  sortingDisabled?: boolean;
  tableData: OutletData[];
  setFilter: (column: OutletDataTableField, value: string | null) => void;
  customFilterOn: boolean;
}

export const OutletsTableHeaders: React.FC<Props> = ({
  columnsDisplayStatus,
  order,
  sortBy,
  setSorting,
  sortingDisabled
}) => {
  const handleSetSortByColumn = (column: OutletDataTableField) => {
    if (!sortingDisabled) {
      setSorting(column);
    }
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    { sortBy: typeof sortBy; order: Order },
    typeof setSorting,
    OutletDataTableField
  >(handleSetSortByColumn, { order, sortBy });

  return (
    <>
      <TableRow>
        {columnsDisplayStatus.outletTypeName && (
          <TableSortingHeaderCell
            key="outletTypeName"
            rowSpan={2}
            label="Rūšis"
            columnName="outletTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}

        {columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            key="outletName"
            rowSpan={2}
            label="VIP pavadinimas"
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}

        {columnsDisplayStatus.outletGroupName && (
          <TableSortingHeaderCell
            key="outletGroupName"
            rowSpan={2}
            label="Grupė"
            columnName="outletGroupName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}

        {columnsDisplayStatus.establishedDate && (
          <TableSortingHeaderCell
            key="establishedDate"
            rowSpan={2}
            label="Steigimo data"
            columnName="establishedDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}

        {(columnsDisplayStatus.controlledFrom || columnsDisplayStatus.controlledTo) && (
          <TableCell
            key={2}
            rowSpan={1}
            align="left"
            colSpan={getSharedHeaderCellColSpan([
              columnsDisplayStatus.controlledTo,
              columnsDisplayStatus.controlledFrom
            ])}
            variant="head"
          >
            <Typography variant="h4">VIP valdoma</Typography>
          </TableCell>
        )}

        {columnsDisplayStatus.internationalNumber && (
          <TableSortingHeaderCell
            key="internationalNumber"
            rowSpan={2}
            label="Tarptautinis Nr."
            columnName="internationalNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}

        <TableCell rowSpan={2} key="actions" align="right" variant="head">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {columnsDisplayStatus.controlledFrom && (
          <TableSortingHeaderCell
            key="controlledFrom"
            rowSpan={1}
            label="Nuo"
            columnName="controlledFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}
        {columnsDisplayStatus.controlledTo && (
          <TableSortingHeaderCell
            key="controlledTo"
            rowSpan={1}
            label="Iki"
            columnName="controlledTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={{ order, sortBy }}
          />
        )}
      </TableRow>
    </>
  );
};
