import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  errorBg: {
    position: 'absolute',
    backgroundColor: 'rgba(241, 26, 26, .1)',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  }
}));

export const ErrorBg: React.FC = () => {
  const classes = useStyles();

  return <div className={classes.errorBg} />;
};
