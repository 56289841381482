import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { ChangeEvent, useContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { DocumentNumberInputCell } from '../../../components/TableInputs/DocumentNumberInput';
import { DropdownMultipleInputCell } from '../../../components/TableInputs/DropdownMultipleInput';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { ApplicationState } from '../../../store';
import { EnterpriseType } from '../../../store/classifiers/classifiersTypes';
import {
  createEthicalNonCompliance,
  resetEthicalNonComplianceCreatingState
} from '../../../store/ethicalNonCompliances/ethicalNonCompliancesActions';
import {
  EthicalNonComplianceRecord,
  EthicalNonComplianceType
} from '../../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import { newOrUpdatedEthicalNonCompliance } from '../Table';
import {
  EthicalNonComplianceTableDispatchContext,
  EthicalNonComplianceTableStateContext
} from '../Context';
import { EthicalNonComplianceRowBasic } from './RowBasic';
import { initialEthicalNonComplianceNewRecordState } from './RowState/initialEthicalNonComplianceStateAndTypes';
import { newEthicalNonComplianceRowReducer } from './RowState/newEthicalNonComplianceRowReducer';
import { validateNewRowState } from './RowState/rowReducerFunctions';
import { searchVirsData } from '../../../store/virsis/actions';
import {
  disableOthersIfFictitiousSelected,
  getOutletMultiOption
} from '../../../components/TableInputs/Utilities';
import { noOptionsText } from '../../../components/TableCustomFilterCell/constants';

interface Props {
  enterpriseTypes: EnterpriseType[] | undefined;
  ethicalNonComplianceTypes: EthicalNonComplianceType[];
}

export const EthicalNonComplianceRowContainerNew: React.FC<Props> = ({
  enterpriseTypes,
  ethicalNonComplianceTypes
}) => {
  const [newRowState, newRowDispatch] = useReducer(
    newEthicalNonComplianceRowReducer,
    initialEthicalNonComplianceNewRecordState
  );

  const { state: tableState } = useContext(EthicalNonComplianceTableStateContext);
  const { dispatch: tableDispatch } = useContext(EthicalNonComplianceTableDispatchContext);

  const {
    virsis: { virsSearchResults },
    ethicalNonComplianceData: { ethicalNonComplianceCreated, creatingEthicalNonComplianceError },
    classifiers: { fictitiousOutlet }
  } = useSelector((state: ApplicationState) => state);

  let selectedVirs: VirsSearchData | undefined;
  if (virsSearchResults) {
    [selectedVirs] = virsSearchResults;
  }

  const reduxDispatch = useDispatch();

  const onCreateRecordConfirmed = () => {
    newRowDispatch({ type: 'CREATE_RECORD_CLICKED' });
    if (validateNewRowState(newRowState).createRecordConfirmationOn) {
      newRowDispatch({ type: 'START_LOADING' });
      const object: EthicalNonComplianceRecord = newOrUpdatedEthicalNonCompliance(
        undefined,
        newRowState.virs.value ? newRowState.virs.value.virId : null,
        newRowState.decisionDate,
        newRowState.documentNumber,
        newRowState.outlets,
        newRowState.decisionStatus.id,
        newRowState.validFrom,
        newRowState.validTo
      );
      reduxDispatch(createEthicalNonCompliance(object));
    }
  };

  const cancelCreation = () => {
    tableDispatch({ type: 'SHOW_NEW_RECORD_CLICKED', companyCode: null });
  };

  const openSearchDialogHandler = () => {
    newRowDispatch({ type: 'FIND_VIRS_DIALOG_OPENED' });
    tableDispatch({ type: 'RESET_COMPANY_CODE' });
  };

  useEffect(() => {
    if (creatingEthicalNonComplianceError) newRowDispatch({ type: 'STOP_LOADING' });
  }, [creatingEthicalNonComplianceError]);

  useEffect(() => {
    if (ethicalNonComplianceCreated) {
      tableDispatch({ type: 'NEW_RECORD_CREATED' });
      reduxDispatch(resetEthicalNonComplianceCreatingState());
    }
  }, [ethicalNonComplianceCreated, reduxDispatch, tableDispatch]);

  useEffect(() => {
    if (!selectedVirs && tableState.companyCode && tableState.companyCode) {
      newRowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' });
      reduxDispatch(searchVirsData({ personCode: tableState.companyCode, onlySigned: true }));
    }
    if (
      selectedVirs &&
      tableState.companyCode &&
      !newRowState.showFindVirsDialog &&
      tableState.companyCode
    ) {
      newRowDispatch({
        type: 'VIRS_SELECTED',
        virs: selectedVirs,
        fictitiousOutlet: fictitiousOutlet || []
      });
    }
  }, [
    reduxDispatch,
    tableState.companyCode,
    virsSearchResults,
    fictitiousOutlet,
    selectedVirs,
    newRowState.showFindVirsDialog
  ]);

  return (
    <>
      {newRowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={newRowState.virs.value as unknown as VirsSearchData}
          dialogOpen={newRowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={() => newRowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' })}
          closeAndContinueWithVirs={(virs: VirsSearchData) =>
            newRowDispatch({
              type: 'VIRS_SELECTED',
              virs,
              fictitiousOutlet: fictitiousOutlet || []
            })
          }
        />
      )}
      <EthicalNonComplianceRowBasic
        columnsToDisplay={tableState.columnsDisplayStatus}
        virsInput={
          <VirsInputCell openSearchDialog={openSearchDialogHandler} state={newRowState.virs} />
        }
        decisionDate={
          <DateInputImprovedCell
            state={newRowState.decisionDate}
            setDate={(decisionDate: MaterialUiPickersDate | null) =>
              newRowDispatch({
                type: 'DECISION_DATE_CHANGED',
                decisionDate
              })
            }
            isRequired
          />
        }
        documentNumberInput={
          <DocumentNumberInputCell
            setValue={(documentNumber: string) =>
              newRowDispatch({
                type: 'DOCUMENT_NUMBER_CHANGED',
                documentNumber
              })
            }
            inputState={newRowState.documentNumber}
            isRequired
          />
        }
        outletListInput={
          <DropdownMultipleInputCell
            selectValues={(values) =>
              newRowDispatch({
                type: 'OUTLET_LIST_CHANGED',
                outlets: values
              })
            }
            state={newRowState.outlets}
            getOptionLabel={(option) =>
              `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
            }
            getOptionSelected={(option, value) =>
              value ? option.outletId === value.outletId : false
            }
            getOptionDisabled={disableOthersIfFictitiousSelected(
              fictitiousOutlet && fictitiousOutlet[0]
            )}
            renderOption={getOutletMultiOption}
          />
        }
        decisionStatus={
          <Autocomplete
            size="small"
            autoComplete
            clearOnEscape
            noOptionsText={noOptionsText}
            style={{ width: '100%' }}
            options={ethicalNonComplianceTypes}
            getOptionLabel={(option) => option.ethicalNonComplianceTypeName}
            renderInput={(params) => (
              <form noValidate autoComplete="off">
                <TextField
                  required
                  error={newRowState.decisionStatus.error}
                  helperText={
                    newRowState.decisionStatus.error ? 'reikia pasirinkti iš sąrašo' : undefined
                  }
                  label="Pasirinkti būseną"
                  {...params}
                  variant="outlined"
                />
              </form>
            )}
            onChange={(event: ChangeEvent<object>, value: EthicalNonComplianceType | null) =>
              newRowDispatch({
                type: 'DECISION_STATUS_CHANGED',
                decisionStatus: value
              })
            }
          />
        }
        validFrom={
          <DateInputImprovedCell
            state={newRowState.validFrom}
            setDate={(validFrom: MaterialUiPickersDate | null) =>
              newRowDispatch({
                type: 'VALID_FROM_DATE_CHANGED',
                validFrom
              })
            }
            isRequired
          />
        }
        validTo={
          <DateInputImprovedCell
            state={newRowState.validTo}
            setDate={(validTo: MaterialUiPickersDate | null) =>
              newRowDispatch({
                type: 'VALID_TO_DATE_CHANGED',
                validTo
              })
            }
            isRequired
          />
        }
        actions={
          <SaveAndCancelActions
            handleConfirmationYes={onCreateRecordConfirmed}
            isProcessing={newRowState.loading}
            handleCancelButtonClick={cancelCreation}
          />
        }
      />
    </>
  );
};
