import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { saga } from './saga';

import { getOptionedTableReducer } from '../GenericClassifierTable/genericReducers/optionedTableReducer';
import { FormSagaContext } from '../../../components/FormikFields/GenericFormikWrappers/types';
import { LicencedEnterprise, LicencedEnterpriseInput, LicencedEnterpriseOptions } from './types';

export const {
  loadData,
  resetForm,
  setData,
  submitForm,
  setLoading,
  setFormOpen,
  setFormLoading,
  setFormError,
  setError,
  setFormOptions,
  initialState,
  reducer
} = getOptionedTableReducer<LicencedEnterprise, LicencedEnterpriseInput, LicencedEnterpriseOptions>(
  {
    licenceTypes: [],
    enterpriseTypes: []
  }
);

export function getLicencedEnterpriseStore(context: FormSagaContext) {
  const middleware = createSagaMiddleware({ context });
  const store = createStore(reducer, applyMiddleware(middleware));
  middleware.run(saga);
  return store;
}

export type State = typeof initialState;
