import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { BasicDarkTooltip } from '../Tooltips/BasicDarkTooltip';
import { ButtonProps } from '@material-ui/core/Button/Button';

interface Props extends ButtonProps {
  text: string;
  onClicked: () => void;
  disabled?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '10px 0px'
  },
  button: {
    padding: 0,
    margin: '5px 0px',
    color: theme.palette.secondary.light
  },
  outlinedButton: {
    color: theme.palette.info.dark,
    border: `1px solid ${theme.palette.info.dark}`,
    backgroundColor: 'transparent',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      border: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary
    }
  },
  icon: {
    height: 30,
    padding: '5px 10px',
    borderRight: '1px solid white'
  },
  outlinedButtonIcon: {
    borderColor: theme.palette.info.dark
  },
  text: {
    padding: '1px 15px'
  },
  [theme.breakpoints.down('xs')]: {
    wrapper: {
      width: '100%',
      marginLeft: 5,
      marginBottom: 0
    },
    button: {
      margin: 0
    }
  }
}));

export const AddNewRecordButton: React.FC<Props> = ({
  text,
  onClicked,
  disabled,
  showTooltip,
  tooltipText,
  color = 'primary',
  variant = 'contained',
  ...buttonProps
}) => {
  const classes = useStyles();
  const isOutlined = variant === 'outlined';

  return (
    <div className={classes.wrapper}>
      <ConditionalWrapper
        condition={!!showTooltip}
        wrapper={(children) => (
          <BasicDarkTooltip text={tooltipText || ''}>
            <div>{children}</div>
          </BasicDarkTooltip>
        )}
      >
        <Button
          onClick={onClicked}
          disabled={disabled}
          variant={variant}
          color={color}
          className={`${classes.button} ${isOutlined ? classes.outlinedButton : ''}`}
          {...buttonProps}
        >
          <AddIcon
            fontSize="small"
            className={`${classes.icon} ${isOutlined ? classes.outlinedButtonIcon : ''}`}
          />
          <Typography variant="h5" className={classes.text}>
            {text}
          </Typography>
        </Button>
      </ConditionalWrapper>
    </div>
  );
};
