import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { LinearProgress, Paper, Table, TableBody, Box } from '@material-ui/core';
import { ApplicationState } from '../../../store';
import {
  fetchShareholdersSelectedDateDataRequest,
  fetchVirsPersonsListRequest,
  resetShareholdersData,
  setExpandFilteredParents,
  setLastSignedDate,
  unsetUpdateShareholderId
} from '../../../store/shareholders/shareholdersActions';
import { ShareholdersProps } from '../../../store/shareholders/shareholdersTypes';
import { Roles } from '../../../store/virsis/dataTypes';
import { getFollowingItemInArr, getPreviousItemInArr } from '../../../utils/tableDataFunctions';
import AllowedTo from '../../AllowedTo';
import {
  commonColumnNames,
  DATE_NOT_SELECTED_ERROR_TEXT,
  NO_DATA_ERROR_TEXT,
  selectedDateTableColumnNames
} from '../constants';
import ClearShareholderEventDialog from '../MainTable/Components/ClearShareholderEventDialog';
import DateInputDialog from '../MainTable/Components/DateInputDialog';
import DeleteShareholderDialog from '../MainTable/Components/DeleteShareholderDialog';
import DataDeletionByDateDialog from '../MainTable/Components/ShareholderDataDeletionByDateDialog';
import DeleteDateDialog from '../MainTable/Components/ShareholderDeleteDateDialog';
import {
  checkAllShareholdersIfDateFromJADIS,
  getFilteredPathsAndIds,
  getShareholderLevelList,
  getMinimumDate,
  getSortedShareholdersData,
  getValidDates,
  getFilteredDatesForSelectedDateTable,
  getLastSignedDate
} from '../utilityFunctions/sharedFunctions';
import { getTableExportRows } from '../utilityFunctions/shareholderExportMapping';
import { AddShareholderButtonContainer } from './Components/AddShareholderComponents/AddShareholderButtonContainer';
import { FindPersonDialog } from './Components/AddShareholderComponents/FindPersonDialog';
import ShareholderSelectedDateHeader from './Components/ShareholderSelectedDateHeader';
import { ShareholdersSelectedDateTableCustomizationBar } from './CustomizationBar';
import ShareholdersSelectedDateTableHead from './TableHead';
import { ShareholdersSelectedDateRowContainer } from './TableRows/RowContainer';
import { getTableExportDefinition } from './tableState/tableStateFunctions';
import {
  useShareholdersSelectedDateTableDispatch,
  useShareholdersSelectedDateTableState
} from './TableWithContext';
import { checkIfColorBarNeeded } from './utilities/UIFunctions';
import { getparticipatingShareholders } from './utilities/utilityFunctions';
import ErrorText from '../SharedComponents/ErrorText';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import { VirsTreeWithContext } from '../../ShareholdersVirsTree/VirsTreeContext';
import { hasNoEvents, isDateUnsigned } from '../MainTable/tableState/tableFunctions';

const ShareholdersSelectedDateTable: React.FC<RouteComponentProps> = () => {
  const [isCalendarOpen, setCalenderOpen] = useState(false);
  const [showTree, setShowTree] = useState<boolean>(false);
  const { state: tableState } = useShareholdersSelectedDateTableState();
  const { sortBy, order, showShares, showVotes } = tableState;
  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();
  const {
    state: {
      customFilter: { shareholderLevel }
    }
  } = useShareholdersSelectedDateTableState();
  const reduxDispatch = useDispatch();

  const {
    virsis: { virsData },
    shareholdersData: {
      loadingShareholdersSelectedDateData,
      shareholdersSelectedDateData,
      shareholdersSelectedDateDataError,
      selectedDate,
      shareholdersData,
      loadingSynchroniseWithJADIS,
      allShareholders,
      allEventsDates
    }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    if (virsData && selectedDate && !loadingSynchroniseWithJADIS && !shareholdersSelectedDateData) {
      reduxDispatch(fetchShareholdersSelectedDateDataRequest(virsData.virsId, selectedDate));
    }
    if (virsData && selectedDate && !loadingSynchroniseWithJADIS) {
      reduxDispatch(fetchVirsPersonsListRequest(virsData.virsId, selectedDate));
    }
  }, [
    virsData,
    selectedDate,
    reduxDispatch,
    loadingSynchroniseWithJADIS,
    shareholdersSelectedDateData
  ]);

  const nonParticipatingShareholdersFilterEnabled = useMemo(() => {
    if (allShareholders && selectedDate) {
      return allShareholders.some(
        ({
          shareholderStartDate,
          shareholderParentStartDate,
          shareholderStoppedParticipatingFromDate
        }: ShareholdersProps) =>
          !checkIfColorBarNeeded(
            shareholderStartDate,
            shareholderParentStartDate,
            shareholderStoppedParticipatingFromDate,
            selectedDate
          )
      );
    }
  }, [allShareholders, selectedDate]);

  useEffect(() => {
    if (allShareholders && selectedDate) {
      tableDispatch({
        type: 'NON_PARTICIPATING_SHAREHOLDERS_DISABLED',
        participationFilterDisabled: !nonParticipatingShareholdersFilterEnabled
      });
    }
  }, [
    shareholdersSelectedDateData,
    selectedDate,
    nonParticipatingShareholdersFilterEnabled,
    allShareholders,
    tableDispatch
  ]);

  useEffect(() => {
    if (shareholdersSelectedDateData) {
      const shareholdersLevelList = getShareholderLevelList(shareholdersSelectedDateData);

      tableDispatch({
        type: 'SET_SHAREHOLDERS_LEVEL_LIST',
        shareholdersLevelList
      });
      tableDispatch({
        type: 'EDIT_SHAREHOLDER_ROW_CLOSE'
      });
      reduxDispatch(unsetUpdateShareholderId());
    }
  }, [shareholdersSelectedDateData, tableDispatch, reduxDispatch]);

  const [filteredShareholderLevel] = shareholderLevel;

  useEffect(() => {
    if (
      shareholdersSelectedDateData &&
      allShareholders &&
      filteredShareholderLevel &&
      shareholdersData
    ) {
      // esant levelio filtrui suskaiciuoja isskleidziamu shareholder paths ir unique personIds (bendraturciams)
      const { filteredShareholderPaths, filteredPersonIds } = getFilteredPathsAndIds(
        filteredShareholderLevel,
        shareholdersSelectedDateData.virsShareholderId,
        allShareholders,
        shareholdersData
      );
      reduxDispatch(setExpandFilteredParents(filteredShareholderPaths, filteredPersonIds));
    }
  }, [shareholdersSelectedDateData, allShareholders, reduxDispatch, filteredShareholderLevel]);

  useEffect(() => {
    return () => {
      reduxDispatch(resetShareholdersData());
    };
  }, [reduxDispatch]);

  const mappedData =
    shareholdersSelectedDateData && selectedDate && !tableState.showNonParticipatingShareholders
      ? {
          ...shareholdersSelectedDateData,
          shareholders: getparticipatingShareholders(
            shareholdersSelectedDateData.shareholders,
            selectedDate
          )
        }
      : shareholdersSelectedDateData;

  const virsStartDate = shareholdersSelectedDateData && shareholdersSelectedDateData.virsStartDate;

  const isSelectedDateFromJADIS =
    shareholdersSelectedDateData && selectedDate
      ? checkAllShareholdersIfDateFromJADIS(shareholdersSelectedDateData.shareholders, selectedDate)
      : false;

  const dates = getFilteredDatesForSelectedDateTable(
    allEventsDates || [],
    shareholdersData?.activityPeriods || [],
    getMinimumDate(shareholdersData),
    shareholdersData
  )
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();

  const datesWithAddedSelectedDate = dates && [...dates, selectedDate].sort();
  const uniqueDatesWithSelectedDate =
    datesWithAddedSelectedDate && Array.from(new Set(datesWithAddedSelectedDate));

  const followingDate =
    uniqueDatesWithSelectedDate && getFollowingItemInArr(uniqueDatesWithSelectedDate, selectedDate);
  const previousDate =
    uniqueDatesWithSelectedDate && getPreviousItemInArr(uniqueDatesWithSelectedDate, selectedDate);

  const validDates =
    virsStartDate && shareholdersData
      ? getValidDates(virsStartDate, shareholdersData.minimumDate, shareholdersData.virsEndDate)
      : undefined;
  const noValidDates =
    !validDates?.map((date) => allEventsDates?.includes(date)).includes(false) ||
    shareholdersData?.activityPeriods.length === 0;

  const openCalendar = () => {
    setCalenderOpen(true);
  };

  const closeCalendar = () => {
    setCalenderOpen(false);
  };

  const sortedShareholdersSelectedDateData =
    mappedData &&
    getSortedShareholdersData(mappedData, order, sortBy, {
      showShares,
      showVotes,
      selectedDate
    });

  const shareholderTableExportSettings = {
    ...tableState,
    selectedDates: selectedDate ? [selectedDate] : []
  };

  function openTree() {
    setShowTree(true);
  }
  function closeTree() {
    setShowTree(false);
  }
  let isDateUnsignedDoc = false;
  let noEventsOnDate = false;

  if (shareholdersData && selectedDate) {
    isDateUnsignedDoc = isDateUnsigned(
      shareholdersData.shareholders,
      shareholdersData.activityPeriods,
      selectedDate
    );
    noEventsOnDate = hasNoEvents(shareholdersData.shareholders, selectedDate);
  }

  useEffect(() => {
    reduxDispatch(
      setLastSignedDate(
        getLastSignedDate(shareholdersData?.shareholders, shareholdersData?.activityPeriods)
      )
    );
  }, [shareholdersData?.shareholders, shareholdersData?.activityPeriods]);

  return (
    <>
      {showTree && virsData && (
        <div className="named-data-table">
          <VirsTreeWithContext
            virsId={virsData?.virsId}
            date={selectedDate}
            virsName={virsData.name}
            handleGoBackClick={closeTree}
          />
        </div>
      )}

      {!showTree && (
        <>
          <DeleteDateDialog />
          <ClearShareholderEventDialog />
          <DataDeletionByDateDialog location="SELECTED" />
          <DeleteShareholderDialog location="SELECTED" />

          {shareholdersData && (
            <AllowedTo roles={[Roles.ROLE_VIRS, Roles.ROLE_VIRS_EDIT]}>
              <DateInputDialog
                isOpen={isCalendarOpen}
                closeCalendar={closeCalendar}
                openCalendar={openCalendar}
                daysToDisable={allEventsDates}
                virsStartDate={shareholdersData.virsStartDate}
                virsEndDate={shareholdersData?.virsEndDate}
                selectedDateTable
                shareholdersData={shareholdersData}
              />
            </AllowedTo>
          )}

          <ShareholderSelectedDateHeader
            onDownload={(format, navigationPath) =>
              exportTableData(
                format,
                getTableExportDefinition(shareholderTableExportSettings),
                getTableExportRows(
                  shareholderTableExportSettings,
                  sortedShareholdersSelectedDateData
                ),
                navigationPath
              )
            }
            onPrint={(navigationPath) =>
              printTableData(
                getTableExportDefinition(shareholderTableExportSettings),
                getTableExportRows(
                  shareholderTableExportSettings,
                  sortedShareholdersSelectedDateData
                ),
                navigationPath
              )
            }
            onVizualizeClick={openTree}
          />

          <div className="data-table">
            <Paper elevation={0}>
              <ShareholdersSelectedDateTableCustomizationBar
                columnNames={[...commonColumnNames, ...selectedDateTableColumnNames]}
                allEventsDates={allEventsDates}
              />

              {loadingShareholdersSelectedDateData && !shareholdersSelectedDateDataError && (
                <LinearProgress />
              )}

              {shareholdersSelectedDateDataError && <ErrorText text={NO_DATA_ERROR_TEXT} />}

              {!selectedDate && <ErrorText text={DATE_NOT_SELECTED_ERROR_TEXT} />}

              {shareholdersSelectedDateData &&
                selectedDate &&
                sortedShareholdersSelectedDateData && (
                  <Box style={{overflow: 'auto'}}>
                    <Table>
                      <ShareholdersSelectedDateTableHead
                        virsStartDate={shareholdersData?.virsStartDate || null}
                        noValidDates={noValidDates}
                        openCalendar={openCalendar}
                        previousDate={previousDate}
                        followingDate={followingDate}
                        selectedDate={selectedDate}
                        isSelectedDateFromJADIS={isSelectedDateFromJADIS}
                        loadingShareholders={loadingShareholdersSelectedDateData}
                        virsEndDate={shareholdersSelectedDateData.virsEndDate}
                        virsStoppedParticipatingFromDate={
                          shareholdersSelectedDateData.virsStoppedParticipatingFromDate
                        }
                        virsId={virsData?.virsId}
                        isDateUnsignedDoc={isDateUnsignedDoc}
                        noEventsOnDate={noEventsOnDate}
                        activityPeriods={shareholdersData?.activityPeriods || []}
                      />

                      <TableBody>
                        <ShareholdersSelectedDateRowContainer
                          index={0}
                          data={sortedShareholdersSelectedDateData}
                          previousDate={previousDate}
                          followingDate={followingDate}
                          selectedDate={selectedDate}
                          filteredShareholderLevel={filteredShareholderLevel}
                        />
                      </TableBody>
                    </Table>
                  </Box>
                )}

              <FindPersonDialog />
            </Paper>
          </div>

          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <AddShareholderButtonContainer />
          </AllowedTo>
        </>
      )}
    </>
  );
};

export default withRouter(ShareholdersSelectedDateTable);
