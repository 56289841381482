import React from 'react';
import { TableCell } from '@material-ui/core';
import { HeaderType } from '../../utils/tableTypes';
import { TableCustomFilterAutocomplete } from './TableCustomFilterAutocomplete';
import { DateInputFilter } from '../TableInputs/DateInput';
import { TableCustomFilterMultipleAutocomplete } from './TableCustomFilterMultipleAutocomplete';
import moment from 'moment';

interface Props {
  id: string;
  setFilter(column: string, value: string | string[] | null): void;
  uniqueOptions: string[];
  type?: HeaderType;
}

export const TableCustomFilterCellGeneric: React.FC<Props> = ({
  id,
  setFilter,
  uniqueOptions,
  type
}) => (
  <TableCell
    align="center"
    component="th"
    scope="row"
    style={{
      borderColor: '#C4E2FC',
      borderTop: 'none',
      borderBottom: 'none',
      padding: '4px 10px 3px'
    }}
  >
    {!type && (
      <TableCustomFilterAutocomplete
        name={id}
        uniqueOptions={uniqueOptions}
        setFilter={setFilter}
      />
    )}
    {type === 'date' && <DateInputFilter name={id} setFilter={setFilter} />}
    {type === 'nonFutureDate' && (
      <DateInputFilter
        name={id}
        setFilter={setFilter}
        customMaxDate={moment().format('YYYY-MM-DD')}
      />
    )}
    {type === 'multiple' && (
      <TableCustomFilterMultipleAutocomplete
        name={id}
        uniqueOptions={uniqueOptions}
        setFilter={setFilter}
      />
    )}
  </TableCell>
);
