import React, { ReactNode } from 'react';
import { Box, makeStyles, TableCell, Typography } from '@material-ui/core';
import { OutletData } from '../../../store/outlets/outletsTypes';
import { OutletDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { ExpandDetailsButton } from '../../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';

const useStyles = makeStyles((theme) => ({
  extendeRowData: {
    padding: '15px 15px 15px 65px'
  },
  extendedRowItem: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontFamily: 'Open Sans'
  },
  extendedRowItemTitle: {
    display: 'block',
    minWidth: '220px',
    marginRight: '15px'
  },
  actions: {
    width: '130px'
  },
  outletName: {
    color: theme.palette.primary.main
  },
  outletNameContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface RowProps {
  record: OutletData;
  display: OutletDataTableColumnsDisplayStatus;
  outletNameInput?: ReactNode;
  outletTypeNameInput?: ReactNode;
  outletTypeInput?: ReactNode;
  outletGroupNameInput?: ReactNode;
  outletEstablishedInput?: ReactNode;
  outletControlledFromInput?: ReactNode;
  outletControlledToInput?: ReactNode;
  outletInternationalNumberInput?: ReactNode;
  outletRowActions?: ReactNode;
  toggleExtension?: () => void;
  isExtended?: boolean;
  rowStatus?: string;
  rowExtensionComponent?: ReactNode;
}

export const OutletBasicRow: React.FC<RowProps> = ({
  record,
  display,
  outletRowActions,
  outletNameInput,
  outletTypeNameInput,
  outletTypeInput,
  outletGroupNameInput,
  outletEstablishedInput,
  outletControlledFromInput,
  outletControlledToInput,
  outletInternationalNumberInput,
  toggleExtension,
  isExtended,
  rowStatus,
  rowExtensionComponent
}) => {
  const classes = useStyles();

  return (
    <>
      <RowStyleOnDifferentStatus status={rowStatus} key={`row-${record.virOutletControlId}`}>
        {display.outletTypeName && !outletTypeInput && (
          <TooltipOnRowRibbon status={rowStatus}>
            <TableCell>
              {outletTypeNameInput && outletTypeNameInput}
              {!outletTypeNameInput && record.outletTypeName}
            </TableCell>
          </TooltipOnRowRibbon>
        )}
        {display.outletTypeName && !outletTypeNameInput && outletTypeInput}
        {display.outletName && outletNameInput && outletNameInput}
        {display.outletName && !outletNameInput && (
          <TableCell>
            {outletNameInput && outletNameInput}
            {!outletNameInput && rowExtensionComponent && (
              <Box className={classes.outletNameContainer}>
                <ExpandDetailsButton showExtension={isExtended} onClick={toggleExtension} />
                <Typography variant="h5" className={classes.outletName}>
                  {record.outletName}
                </Typography>
              </Box>
            )}
            {!outletNameInput && !rowExtensionComponent && (
              <Typography variant="h5" className={classes.outletName}>
                {record.outletName}
              </Typography>
            )}
          </TableCell>
        )}
        {display.outletGroupName && (
          <TableCell>
            {outletGroupNameInput && outletGroupNameInput}
            {!outletGroupNameInput && record.outletGroupName}
          </TableCell>
        )}

        {display.establishedDate && !outletEstablishedInput && (
          <TableCell>{record.establishedDate}</TableCell>
        )}

        {display.establishedDate && outletEstablishedInput && outletEstablishedInput}

        {display.controlledFrom && !outletControlledFromInput && (
          <TableCell>{record.controlledFrom}</TableCell>
        )}

        {display.controlledFrom && outletControlledFromInput && outletControlledFromInput}

        {display.controlledTo && !outletControlledToInput && (
          <TableCell>{record.controlledTo}</TableCell>
        )}

        {display.controlledTo && outletControlledToInput && outletControlledToInput}

        {display.internationalNumber && !outletInternationalNumberInput && (
          <TableCell>{record.isbn || record.issn || record.internationalNumber}</TableCell>
        )}

        {display.internationalNumber &&
          outletInternationalNumberInput &&
          outletInternationalNumberInput}

        <TableCell className={classes.actions} align="right">
          {outletRowActions}
        </TableCell>
      </RowStyleOnDifferentStatus>
      {rowExtensionComponent && isExtended && rowExtensionComponent}
    </>
  );
};
