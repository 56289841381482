import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';

export const initialOutletCategoryTableState: OutletCategoryTableState = {
  order: 'desc',
  sortBy: 'validFrom',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    categoryTypeName: true,
    validFrom: true,
    validTo: true
  },
  customFilterOn: false,
  customFilter: {
    categoryTypeName: [],
    validFrom: [],
    validTo: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: true,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showNewRecord: false
};

export const outletCategoryTableColumns: OutletCategoryColumnHeader[] = [
  {
    id: 'categoryTypeName',
    label: 'Kategorija'
  },
  {
    id: 'validFrom',
    label: 'Taikoma nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Taikoma iki',
    type: 'date'
  }
];

export type OutletCategoryHeaderField = 'categoryTypeName' | 'validFrom' | 'validTo';

export type OutletCategoryColumnsDisplayStatus = {
  categoryTypeName: boolean;
  validFrom: boolean;
  validTo: boolean;
};

export type OutletCategoryCustomFilter = {
  categoryTypeName: string[];
  validFrom: string[];
  validTo: string[];
};

export type OutletCategoryColumnHeader = ColumnHeader<OutletCategoryHeaderField>;

export interface OutletCategoryTableState {
  sortBy: OutletCategoryHeaderField;
  order: SortOrder;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: OutletCategoryColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: OutletCategoryCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showNewRecord: boolean;
}

export type OutletCategoryTableAction =
  | {
      type: 'CATEGORY_CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: OutletCategoryHeaderField;
      value: string | null;
    }
  | { type: 'CATEGORY_SORTING_CHANGED'; sortBy: OutletCategoryHeaderField }
  | {
      type: 'CATEGORY_COLUMN_DISPLAY_TOGGLED';
      column: OutletCategoryHeaderField;
    };
