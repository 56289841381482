import React, { ReactNode } from 'react';
import { Grid, createStyles, makeStyles } from '@material-ui/core/';
import { GridSize } from '@material-ui/core/Grid/Grid';

interface Props {
  leftSideItem?: ReactNode;
  rightSideItem: ReactNode;
  numberOfGridsLeft?: GridSize;
  numberOfGridsLeftMobile?: GridSize;
  numberOfGridsRight?: GridSize;
  numberOfGridsRightMobile?: GridSize;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    leftGrid: {
      justifyContent: 'flex-start'
    },
    rightGrid: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      wrapperGrid: {
        gap: 10
      },
      leftGrid: {
        justifyContent: 'center'
      },
      rightGrid: {
        justifyContent: 'center'
      }
    }
  })
);

export const TableToolsFrame: React.FC<Props> = ({
  leftSideItem,
  rightSideItem,
  numberOfGridsLeft = 6,
  numberOfGridsLeftMobile = 12,
  numberOfGridsRight = 6,
  numberOfGridsRightMobile = 12
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.wrapperGrid}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        className={classes.leftGrid}
        xs={numberOfGridsLeftMobile}
        md={numberOfGridsLeft}
      >
        {leftSideItem}
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.rightGrid}
        xs={numberOfGridsRightMobile}
        md={numberOfGridsRight}
      >
        {rightSideItem}
      </Grid>
    </Grid>
  );
};
