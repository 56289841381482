import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  Typography,
  TableBody,
  Container,
  Box,
  makeStyles,
  createStyles
} from '@material-ui/core';

import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ProvidedDataHistoryTableCustomizationBar } from './TableCustomization';
import ProvidedDataHistoryTableHead from './TableHead';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { useProvidedDataHistoryTableState } from './Context';
import ProvidedDataHistoryTableCustomFilter from './FilterRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { ProvidedDataHistoryRowContainer } from './TableRow/RowContainer';
import { ApplicationState } from '../../store';
import {
  initializeProvidedDataTableState,
  setPage,
  setRowsPerPage
} from '../../store/providedDataTable/providedDataTableActions';
import { AnnulRecord } from '../../store/classifiers/classifiersTypes';
import { providedDataInstColumns } from './tableState/tableInitialStateAndTypes';
import { AlertBox } from '../../components/Alert/AlertBox';
import { closeUnsignedDataAlert } from '../../store/virsis/actions';
import { ProvidedDataInst } from '../../store/providedData/providedDataTypes';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { TableLinearProgressRow } from '../../components/TableLinearProgressRow/TableLinearProgressRow';
import { fetchProvidedDataInst } from '../../store/providedData/providedDataActions';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { useLocation } from 'react-router-dom';
import { fetchDataDeletionPeriodRequest } from '../../store/classifiers/classifiersActions';

export interface ProvidedDataInstTableProps {
  loading: boolean;
  data: ProvidedDataInst[] | undefined;
  error: Error | undefined;
  remove: (documentStatusId: number) => void;
  removeUnsigned: () => void;
  onRemoveUnsignedSuccess: () => void;
  annul: (documentStatusId: number, annulRecord: AnnulRecord) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      pageActionButtonBox: {
        width: '100%'
      }
    }
  })
);

const ProvidedDataHistoryTable: React.FC<RouteComponentProps & ProvidedDataInstTableProps> = ({
  loading,
  data,
  error,
  remove,
  removeUnsigned,
  annul,
  onRemoveUnsignedSuccess
}) => {
  const { state: tableState } = useProvidedDataHistoryTableState();
  const location = useLocation();
  const classes = useStyles();
  const {
    virsis: { showUnsignedDataAlert, currentUser, virsDataLoading },
    providedDataTable: { tableDataState, tempDataTableState },
    providedData: {
      recordCount,
      statusSigneFilterEnabled,
      statusNotSignedFilterEnabled,
      statusAnnuledFilterEnabled
    },
    classifiers: { dataDeletionPeriod, loadingDataDeletionPeriod }
  } = useSelector((state: ApplicationState) => state);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  const reduxDispatch = useDispatch();

  const handleSetPage = (value: number) => {
    reduxDispatch(setPage(value));
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    reduxDispatch(setRowsPerPage(rowsPerPage));
  };

  useEffect(() => {
    reduxDispatch(
      initializeProvidedDataTableState(
        statusNotSignedFilterEnabled,
        statusSigneFilterEnabled,
        statusAnnuledFilterEnabled,
        statusNotSignedFilterEnabled,
        statusSigneFilterEnabled,
        false,
        tableDataState.periodFilter
      )
    );
  }, [
    reduxDispatch,
    statusNotSignedFilterEnabled,
    statusSigneFilterEnabled,
    statusAnnuledFilterEnabled
  ]);

  useEffect(() => {
    if (JSON.stringify(tempDataTableState) !== JSON.stringify(tableDataState) || !virsDataLoading) {
      reduxDispatch(fetchProvidedDataInst());
    }
    if (!dataDeletionPeriod) {
      reduxDispatch(fetchDataDeletionPeriodRequest());
    }
  }, [reduxDispatch, tableDataState, location.pathname, virsDataLoading, dataDeletionPeriod]);

  const pagedData = useSelector((state: ApplicationState) => state.providedData.providedDataInst);

  return (
    <>
      {showUnsignedDataAlert && dataDeletionPeriod && !loadingDataDeletionPeriod && (
        <AlertBox
          handleClose={() => reduxDispatch(closeUnsignedDataAlert())}
          text={`Dėmesio! Nepasirašyti duomenys bus automatiškai pašalinami po ${dataDeletionPeriod} d. nuo jų paskutinio redagavimo.`}
        />
      )}

      <ConfirmRemoveUnsignedDialog
        onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
        onConfirm={removeUnsigned}
        onRemoveSuccess={onRemoveUnsignedSuccess}
        open={confirmToDeleteUnsignedDialogOpen}
      />

      <Container maxWidth="lg">
        <div className="data-page">
          <div className="data-page-header">
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Typography variant="subtitle1">Duomenų istorija</Typography>
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="flex-end"
                flexWrap="wrap"
                className={classes.pageActionButtonBox}
              >
                {data && (
                  <PageActionButtons
                    onDownload={(format, navigationPath) =>
                      exportTableData(
                        format,
                        tableState.tableExportDefinition,
                        data,
                        navigationPath
                      )
                    }
                    onPrint={(navigationPath) =>
                      printTableData(tableState.tableExportDefinition, data, navigationPath)
                    }
                    onClickDelete={() => {
                      setConfirmToDeleteUnsignedDialogOpen(true);
                    }}
                  />
                )}
              </Box>
            </Box>
          </div>

          <div className="named-data-table">
            <Paper elevation={0}>
              <ProvidedDataHistoryTableCustomizationBar columnNames={providedDataInstColumns} />
              <ScrollXContainer>
                <Box style={{overflow: 'auto'}}>
                  <Table>
                    <ProvidedDataHistoryTableHead />

                    {tableState.customFilterOn && (
                      <ProvidedDataHistoryTableCustomFilter columnNames={providedDataInstColumns} />
                    )}

                    <TableBody>
                      <TableLinearProgressRow isLoading={loading && !error} colSpan={8} />
                      <TableErrorRow
                        error={error && 'Klaida. Nepavyko gauti istorijos duomenų'}
                        colSpan={8}
                      />
                      {currentUser &&
                        !error &&
                        pagedData &&
                        pagedData.map((record) => {
                          return (
                            <ProvidedDataHistoryRowContainer
                              key={record.documentStatusId}
                              currentUser={currentUser}
                              columnsToDisplay={tableState.columnsDisplayStatus}
                              record={record}
                              remove={() => {
                                remove(record.documentStatusId);
                              }}
                              annul={(documentId: number, anullRecord: AnnulRecord) => {
                                annul(documentId, anullRecord);
                              }}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </ScrollXContainer>
              <TablePagination
                recordsCount={recordCount || 0}
                pagesCount={Math.ceil(recordCount / tableDataState.rowsPerPage || 0)}
                rowsPerPage={tableDataState.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                page={tableDataState.page}
                setPage={handleSetPage}
                setRowsPerPage={handleSetRowsPerPage}
                disabled={recordCount === 0 || data?.length === 0}
              />
            </Paper>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withRouter(ProvidedDataHistoryTable);
