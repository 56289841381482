import { OutletInfo } from '../../../store/outletInfoData/outletInfoDataTypes';
import { VirsisUser } from '../../../store/virsis/dataTypes';
import {
  AdDataTableState,
  AdTableAction,
  initialOutletAdPrintingTableState
} from './adPrintingTableTypes';
import {
  initialOutletCategoryTableState,
  OutletCategoryTableAction,
  OutletCategoryTableState
} from './categoryTableTypes';
import {
  initialOutletEditionDataTableState,
  EditionDataTableState,
  EditionTableAction
} from './editionTableTypes';
import {
  initialOutletMainDataTableState,
  OutletMainDataTableAction,
  OutletMainDataTableState
} from './mainOutletTableTypes';

import {
  initialOutletRepresentativesTableState,
  OutletRepresentativesDataTableState,
  OutletRepresentativesTableAction
} from './representativesTableTypes';

export type OutletTableName = 'main' | 'representatives' | 'editions' | 'adprinting' | 'categories';
export type OutletTabName = OutletTableName | 'all';

export interface OutletTabDefinition {
  tab: OutletTabName;
  label: string;
  path: string;
}

const defaultTabPath = 'visi';

export const outletTabs: OutletTabDefinition[] = [
  { tab: 'all', label: 'Visi', path: defaultTabPath },
  { tab: 'main', label: 'Pagrindiniai VIP duomenys', path: 'pagrindiniai-duomenys' },
  {
    tab: 'representatives',
    label: 'Už VIP turinį atsakingi asmenys',
    path: 'uz-turini-atsakingi-asmenys'
  },
  {
    tab: 'editions',
    label: 'Tiražo duomenys',
    path: 'tirazo-duomenys'
  },
  {
    tab: 'adprinting',
    label: 'Reklamos spausdinimas',
    path: 'reklamos-spausdinimas'
  },
  {
    tab: 'categories',
    label: 'Kategorija',
    path: 'kategorija'
  }
];

export function getOutletTabPath(tab: OutletTabName): string {
  return outletTabs.reduce(
    (previous, value) => (tab === value.tab ? value.path : previous),
    defaultTabPath
  );
}

export function getOutletTab(path?: string): OutletTabName {
  return outletTabs.reduce(
    (previous, value) => (path === value.path ? value.tab : previous),
    'all' as OutletTabName
  );
}

export interface OutletInfoTablesState {
  main: OutletMainDataTableState;
  representatives: OutletRepresentativesDataTableState;
  editions: EditionDataTableState;
  adprinting: AdDataTableState;
  categories: OutletCategoryTableState;
}

export const initialOutletInfoTablesState: OutletInfoTablesState = {
  main: initialOutletMainDataTableState,
  representatives: initialOutletRepresentativesTableState,
  editions: initialOutletEditionDataTableState,
  adprinting: initialOutletAdPrintingTableState,
  categories: initialOutletCategoryTableState
};

export type OutletInfoTablesAction =
  | OutletMainDataTableAction
  | AdTableAction
  | OutletCategoryTableAction
  | EditionTableAction
  | OutletRepresentativesTableAction
  | {
      type: 'OUTLET_INFO_INITIALIZED';
      data: OutletInfo;
      currentUser: VirsisUser | undefined;
    }
  | { type: 'SHOW_STATUS_UNSIGNED_TOGGLED'; table: OutletTableName }
  | { type: 'SHOW_STATUS_RELEVANT_TOGGLED'; table: OutletTableName }
  | { type: 'SHOW_STATUS_OUTDATED_TOGGLED'; table: OutletTableName }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED'; table: OutletTableName }
  | { type: 'PAGE_SET'; page: number; table: OutletTableName }
  | {
      type: 'ROWS_PER_PAGE_SET';
      rowsPerPage: number;
      table: OutletTableName;
    }
  | { type: 'NEW_RECORD_ROW_OPENED'; table: OutletTableName }
  | { type: 'NEW_RECORD_ROW_CLOSED'; table: OutletTableName };
