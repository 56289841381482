import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  FundsReceivedDataTableStateContext,
  FundsReceivedDataTableDispatchContext
} from './Context';
import { ApplicationState } from '../../store';
import {
  FundsReceivedDataTableHeaderType,
  FundsReceivedDataVirsTableField
} from './tableState/tableTypesAndActions';
import { FundsReceivedDataVirs } from '../../store/fundsReceived/fundsReceivedDataTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { replaceDecimalPoint } from '../../utils/tableDataFunctions';

interface Props {
  columnNames: FundsReceivedDataTableHeaderType[];
}

const FundsReceivedDataTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { dispatch: tableDispatch } = useContext(FundsReceivedDataTableDispatchContext);
  const { state: tableState } = useContext(FundsReceivedDataTableStateContext);

  const handleSetCustomFilter = (column: FundsReceivedDataVirsTableField, value: string | null) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector((state: ApplicationState) => state.fundsReceivedData.fundsReceivedDataVirs) || [];

  function getUniqueOptions(name: FundsReceivedDataVirsTableField, data: FundsReceivedDataVirs[]) {
    if (name === 'outletName') {
      return Array.from(
        new Set(data.flatMap((item) => item.fundsReceivedOutlets.map((vip) => vip.outletName)))
      );
    }

    if (data) {
      return Array.from(
        new Set(
          data.filter((item) => item[name] !== null).map((item) => replaceDecimalPoint(item[name]))
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FundsReceivedDataTableCustomFilter;
