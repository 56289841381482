export const SEARCH_NATURAL_PERSON_REQUEST = '@personData/SEARCH_NATURAL_PERSON_REQUEST';
export const SEARCH_NATURAL_PERSON_SUCCESS = '@personData/SEARCH_NATURAL_PERSON_SUCCESS';
export const SEARCH_NATURAL_PERSON_ERROR = '@personData/SEARCH_NATURAL_PERSON_ERROR';
export const RESET_SEARCH_NATURAL_PERSON_STATE = '@personData/RESET_SEARCH_NATURAL_PERSON_STATE';

export const SEARCH_NATURAL_PERSON_FOREIGN_REQUEST =
  '@personData/SEARCH_NATURAL_PERSON_FOREIGN_REQUEST';
export const SEARCH_NATURAL_PERSON_FOREIGN_SUCCESS =
  '@personData/SEARCH_NATURAL_PERSON_FOREIGN_SUCCESS';
export const SEARCH_NATURAL_PERSON_FOREIGN_ERROR =
  '@personData/SEARCH_NATURAL_PERSON_FOREIGN_ERROR';
export const RESET_SEARCH_NATURAL_PERSON_FOREIGN_STATE =
  '@personData/RESET_SEARCH_NATURAL_PERSON_FOREIGN_STATE';

export const CREATE_NATURAL_FOREIGN_PERSON_REQUEST =
  '@personData/CREATE_NATURAL_FOREIGN_PERSON_REQUEST';
export const CREATE_NATURAL_FOREIGN_PERSON_SUCCESS =
  '@personData/CREATE_NATURAL_FOREIGN_PERSON_SUCCESS';
export const CREATE_NATURAL_FOREIGN_PERSON_ERROR =
  '@personData/CREATE_NATURAL_FOREIGN_PERSON_ERROR';
export const RESET_CREATE_NATURAL_FOREIGN_PERSON_STATE =
  '@personData/RESET_CREATE_NATURAL_FOREIGN_PERSON_STATE';

export const SELECT_PERSON_REPRESENTATIVE = '@personData/SELECT_PERSON_REPRESENTATIVE';
export const RESET_PERSON_REPRESENTATIVE = '@personData/RESET_PERSON_REPRESENTATIVE';

export const SELECT_PERSON_SEARCH_QUERY = '@personData/SELECT_PERSON_SEARCH_QUERY';
export const RESET_PERSON_SEARCH_QUERY = '@personData/RESET_PERSON_SEARCH_QUERY';
