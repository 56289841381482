import React, { useEffect } from 'react';
import { TooltipOnTableActionButton } from '../../../../components/Tooltips/TooltipOnTableActionButton';
import {
  ShareholdersHistory,
  ShareholdersProps
} from '../../../../store/shareholders/shareholdersTypes';
import { getFirstNoNParticipationDay } from '../../utilityFunctions/sharedFunctions';
import { getBorder } from '../../utilityFunctions/sharedUIFunctions';
import {
  useShareholdersTableState,
  useShareholdersTableDispatch
} from '../ShareholdersTableWithContext';
import { checkDateFormat } from '../utilities/functionsWithDateFormat';
import { colorBarStylesOnDiffViews } from '../CustomStyles/rowStyles';
import { replaceDecimalPoint } from '../../../../utils/tableDataFunctions';

interface Props {
  index: number;
  allEventsNumber: number;
  borderColor: string;
  event: ShareholdersHistory;
  fillWithColor: string;
  isSelectedDateEventDate?: boolean;
  date: string;
  shareholder: ShareholdersProps;
  textColor: string;
  value: string;
  allEventsDates: string[];
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
}

export const ColorBarForFilteredView: React.FC<Props> = ({
  index,
  allEventsNumber,
  borderColor,
  event,
  fillWithColor,
  isSelectedDateEventDate,
  date,
  shareholder,
  textColor,
  value,
  allEventsDates,
  virsEndDate,
  virsStoppedParticipatingFromDate
}) => {
  const {
    state: { showShares, showVotes, tableId }
  } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();

  useEffect(() => {
    if (date === allEventsDates[allEventsDates.length - 1]) {
      tableDispatch({
        type: 'SET_LAST_COLUMN_BAR_COLOR',
        lastColumnColor: fillWithColor,
        lastColumnBorderColor: borderColor,
        shareholderId: shareholder.shareholderId
      });
    }
  }, [fillWithColor, showShares, showVotes]);

  const {
    shareholderParentStartDate,
    shareholderStartDate,
    shareholderStoppedParticipatingFromDate,
    isShareholderFromJADIS
  } = shareholder;

  const { eventDate, votesOnRules } = event;
  const firstNoNParticipationDay = getFirstNoNParticipationDay(
    shareholderStoppedParticipatingFromDate,
    virsStoppedParticipatingFromDate
  );

  const startWithTriangle = !shareholderStartDate;

  const formatedStartDate = checkDateFormat(
    date,
    shareholderStartDate || shareholderParentStartDate
  );
  const formatedEndDate = firstNoNParticipationDay
    ? checkDateFormat(date, firstNoNParticipationDay)
    : null;
  const formatedEventDate = checkDateFormat(date, eventDate);

  const customStylesTriangle = (borderColor: string, fillWithColor: string) => {
    return {
      borderLeft: getBorder(borderColor),
      borderBottom: getBorder(borderColor),
      background: `linear-gradient(to bottom left, transparent 50%, ${fillWithColor} 50%)`,
      left: '1%',
      minHeight: '17px',
      minWidth: '17px'
    };
  };

  const customStyles = colorBarStylesOnDiffViews(
    tableId,
    index,
    allEventsNumber,
    isShareholderFromJADIS,
    formatedStartDate,
    formatedEndDate,
    virsEndDate,
    date,
    fillWithColor,
    startWithTriangle,
    borderColor,
    textColor,
    formatedEventDate,
    isSelectedDateEventDate
  );

  const formattedValue = replaceDecimalPoint(value);

  return (
    <>
      {startWithTriangle && formatedStartDate === date && index === 0 ? (
        <div
          className="color-row-triangle"
          style={customStylesTriangle(borderColor, fillWithColor)}
        />
      ) : null}
      <div className="color-row-bar" style={customStyles}>
        {date === formatedEventDate && index === allEventsNumber - 1 && (
          <>
            {showVotes && votesOnRules ? (
              <TooltipOnTableActionButton text="Balsai pagal taisyklę">
                <span>{formattedValue}</span>
              </TooltipOnTableActionButton>
            ) : (
              <span style={{ zIndex: 10 }}>{formattedValue}</span>
            )}
          </>
        )}
      </div>
    </>
  );
};
