import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  Popover,
  Theme,
  Typography
} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { ApplicationState } from '../../../../../store';
import { ShareholdersProps } from '../../../../../store/shareholders/shareholdersTypes';
import { addShareholderPercentageRequest } from '../../../../../store/shareholders/shareholdersActions';
import { getParentShareholderId } from '../../utilities/utilityFunctions';
import { FormTools } from './AddPercentageForm/FormFields/FormTools';
import { Strings } from '../../../../../utils/strings/Strings';

const useStyles = makeStyles((theme: Theme) => ({
  endOfParticipationContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateY(-50%)'
  },
  endOfParticipationDialog: {
    alignItems: 'center',
    display: 'flex',
    padding: '10px 14px'
  },
  editIcon: {
    color: theme.palette.info.dark,
    fontSize: 15
  },
  thinLabel: {
    fontWeight: 400
  },
  icon: {
    margin: '5px 5px 4px 4px',
    width: 13,
    height: 13,
    borderRadius: 2,
    border: `1px solid ${theme.palette.primary.main}`
  }
}));

interface Props {
  selectedDate: string;
  shareholder: ShareholdersProps;
  endOfparticipation: boolean;
}

export const EndOfParticipationContainer: React.FC<Props> = ({
  selectedDate,
  shareholder,
  endOfparticipation
}) => {
  const classes = useStyles();

  const { virsData } = useSelector((state: ApplicationState) => state.virsis);

  const reduxDispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [endOfParticipation, setEndOfParticipation] = useState(endOfparticipation);

  const handleDialogClose = () => {
    setAnchorEl(null);
    setEndOfParticipation(endOfParticipation);
  };

  const handleDialogSubmit = () => {
    if (virsData) {
      const parentId = getParentShareholderId(shareholder.shareholderPath);

      reduxDispatch(
        addShareholderPercentageRequest(
          {
            virsId: virsData.virsId,
            parentId,
            shareholderId: shareholder.shareholderId,
            eventDate: selectedDate,
            endOfParticipation,
            participateBeforeSelectedDate: false,
            sharePercentage: null,
            votePercentage: null,
            votesOnRules: false,
            endOfParticipationFromBefore: false,
            sharePercentageLessThanPermitted: false,
            votePercentageLessThanPermitted: false
          },
          virsData.virsId,
          selectedDate
        )
      );
    }

    setAnchorEl(null);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndOfParticipation(event.target.checked);
  };

  return (
    <div className={classes.endOfParticipationContainer}>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <EditRoundedIcon className={classes.editIcon} />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleDialogClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.endOfParticipationDialog}>
          <FormControlLabel
            control={
              <Checkbox
                checked={endOfParticipation}
                color="primary"
                icon={<span className={classes.icon} />}
                onChange={handleCheckboxChange}
              />
            }
            label={
              <Typography variant="h5">
                <span className={classes.thinLabel}>{Strings.shareholder__notParticipating}</span>
              </Typography>
            }
            labelPlacement="end"
          />
          <FormTools onSubmit={handleDialogSubmit} onClose={handleDialogClose} />
        </div>
      </Popover>
    </div>
  );
};
