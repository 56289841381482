import React, { ReactElement } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { ErrorType } from '../../store/virsis/dataTypes';
import virsisTheme from '../../style/virsisTheme';

export interface TooltipOnTableActionButtonProps {
  text: string | ReactElement;
  errorType?: ErrorType;
  children: ReactElement;
  noWrap?: boolean;
  placement?: TooltipProps['placement'];
}

const useStyles = makeStyles((theme) => ({
  tooltip: ({ customWidth, selectedColor }: { customWidth: string; selectedColor: string }) => ({
    maxWidth: customWidth,
    width: 'auto',
    backgroundColor: theme.palette.secondary.light,
    color: selectedColor,
    border: '1px solid',
    borderColor: selectedColor,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '5px',
    fontSize: '12px'
  }),
  arrow: ({ selectedColor }: { selectedColor: string }) => ({
    color: theme.palette.secondary.light,
    '&.MuiTooltip-arrow:before': {
      border: '1px solid',
      borderColor: selectedColor
    }
  }),
  flexContainer: {
    display: 'flex'
  }
}));

export const TooltipOnTableActionButton: React.FC<TooltipOnTableActionButtonProps> = ({
  text,
  errorType,
  children,
  noWrap,
  placement
}) => {
  let colorClass = virsisTheme.palette.info.main;

  switch (errorType) {
    case ErrorType.CRITICAL:
      colorClass = virsisTheme.palette.error.main;
      break;
    case ErrorType.NONCRITICAL:
      colorClass = virsisTheme.palette.warning.main;
      break;
    case ErrorType.INFO:
      colorClass = virsisTheme.palette.info.main;
      break;
  }

  const classes = useStyles({
    customWidth: noWrap ? 'none' : '160px',
    selectedColor: colorClass
  });

  return (
    <>
      <Tooltip
        title={text}
        placement={placement || 'top'}
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        {children}
      </Tooltip>
      {/* SAFARI, šis div reikalingas, kad matytųsi tooltip ant disabled mygtuko */}
      <div className={classes.flexContainer} />
    </>
  );
};
