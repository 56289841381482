import {
  FundsReceivedNewRowAction,
  FundsReceivedNewRowState,
  FundsReceivedRecordState,
  initialFundsReceivedNewRowState
} from './rowInitialStateAndTypes';
import {
  setFundsReceivedSumAndValidate,
  setFundsReceivedVirsAndUpdateOptions,
  setTransactionAndValidate,
  updateYearOnlyDateValue,
  validateNewFundsReceivedState
} from './reducerFunctions';

export const newFundsReceivedReducer = (
  state: FundsReceivedNewRowState,
  action: FundsReceivedNewRowAction
): FundsReceivedNewRowState => {
  switch (action.type) {
    case 'NEW_RECORD_STATE_INITIALIZED':
      return {
        ...state,
        showFindVirsDialog: true,
        fundsReceivedOutlets: {
          ...state.fundsReceivedOutlets,
          options: action.fictitiousOutlet
        },
        transaction: { ...state.transaction, options: action.transactionTypes }
      };
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return {
        ...state,
        ...setFundsReceivedVirsAndUpdateOptions(
          state as unknown as FundsReceivedRecordState,
          action.virs
        ),
        showFindVirsDialog: false
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'FUNDS_RECEIVED_YEAR_CHANGED':
      return {
        ...state,
        fundsReceivedYear: updateYearOnlyDateValue(
          action.fundsReceivedYear,
          state.fundsReceivedYear
        )
      };
    case 'FUNDS_RECEIVED_SUM_CHANGED':
      return {
        ...state,
        fundsReceivedSum: setFundsReceivedSumAndValidate(action.fundsReceivedSum)
      };
    case 'OUTLETS_CHANGED':
      return {
        ...state,
        fundsReceivedOutlets: {
          ...state.fundsReceivedOutlets,
          values: action.outlets,
          validated: action.outlets.length > 0,
          error: action.outlets.length < 1,
          helperText: action.outlets.length > 0 ? '' : 'Būtinos VIP'
        }
      };
    case 'TRANSACTION_CHANGED':
      return {
        ...state,
        transaction: setTransactionAndValidate(state.transaction, action.transaction)
      };
    case 'CREATE_RECORD_CLICKED':
      return validateNewFundsReceivedState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return initialFundsReceivedNewRowState;
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
