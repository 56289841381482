import { useDispatch, useSelector } from 'react-redux';
import { AlertBox } from './AlertBox';
import { closeUnsignedDataAlert } from '../../store/virsis/actions';
import { ApplicationState } from '../../store';
import AllowedTo from '../../containers/AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';

export const DataEditingAlert = () => {
  const reduxDispatch = useDispatch();

  const {
    virsis: { dataEditorInfo }
  } = useSelector((state: ApplicationState) => state);

  return (
    <AllowedTo roles={[Roles.ROLE_VIRS, Roles.ROLE_VIRS_EDIT]}>
      {dataEditorInfo &&
      dataEditorInfo.editorName !== undefined &&
      dataEditorInfo.documentEditDate !== undefined &&
      dataEditorInfo.representedJa !== undefined ? (
        <AlertBox
          notClosable
          handleClose={() => reduxDispatch(closeUnsignedDataAlert())}
          text={`Paskutinius duomenis pateikė: ${dataEditorInfo.editorName}, ${dataEditorInfo.representedJa}, ${dataEditorInfo.documentEditDate}`}
        />
      ) : (
        <></>
      )}
    </AllowedTo>
  );
};
