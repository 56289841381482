import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

export const ScrollXContainer: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box width="100%" style={{ overflowX: 'auto' }} {...props}>
      {children}
    </Box>
  );
};
