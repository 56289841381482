import { Reducer } from 'redux';
import {
  FETCH_INSTITUTION_DATA_REPORTS_REQUEST,
  FETCH_INSTITUTION_DATA_REPORTS_ERROR,
  FETCH_INSTITUTION_DATA_REPORTS_SUCCESS,
  REPORT_INSTITUTION_DATA_REQUEST,
  REPORT_INSTITUTION_DATA_SUCCESS,
  REPORT_INSTITUTION_DATA_ERROR,
  RESET_INACCURATE_DATA_REPORTING_STATE,
  FETCH_INACCURATE_DATA_MESSAGE_REQUEST,
  FETCH_INACCURATE_DATA_MESSAGE_SUCCESS,
  FETCH_INACCURATE_DATA_MESSAGE_ERROR
} from './institutionDataReportsActionTypes';
import { InstitutionDataReport, ReportInaccurateDataMessage } from './institutionDataReportsTypes';

export interface InstitutionDataReportDataState {
  loadingInstitutionDataReportData: boolean;
  institutionDataReportData: InstitutionDataReport[];
  institutionDataReportDataError?: Error;

  reportingInstitutionData: boolean;
  isInstitutionDataReported: boolean;
  reportingInstitutionDataError?: Error;

  loadingReportInaccurateDataMessage: boolean;
  reportInaccurateDataMessage?: ReportInaccurateDataMessage;
  reportInaccurateDataMessageError?: Error;
}

export const initialState: InstitutionDataReportDataState = {
  loadingInstitutionDataReportData: false,
  institutionDataReportData: [],
  institutionDataReportDataError: undefined,
  reportingInstitutionData: false,
  isInstitutionDataReported: false,
  reportingInstitutionDataError: undefined,
  loadingReportInaccurateDataMessage: false,
  reportInaccurateDataMessage: undefined,
  reportInaccurateDataMessageError: undefined
};

export const institutionDataReportDataReducer: Reducer<InstitutionDataReportDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_INSTITUTION_DATA_REPORTS_REQUEST:
      return { ...state, loadingInstitutionDataReportData: true };
    case FETCH_INSTITUTION_DATA_REPORTS_ERROR:
      return {
        ...state,
        loadingInstitutionDataReportData: false,
        institutionDataReportDataError: action.payload
      };

    case FETCH_INSTITUTION_DATA_REPORTS_SUCCESS:
      return {
        ...state,
        loadingInstitutionDataReportData: false,
        institutionDataReportData: action.payload
      };

    case REPORT_INSTITUTION_DATA_REQUEST:
      return { ...state, reportingInstitutionData: true };
    case REPORT_INSTITUTION_DATA_SUCCESS:
      return {
        ...state,
        reportingInstitutionData: false,
        isInstitutionDataReported: true,
        reportingInstitutionDataError: undefined
      };
    case REPORT_INSTITUTION_DATA_ERROR:
      return {
        ...state,
        reportingInstitutionData: false,
        isInstitutionDataReported: false,
        reportingInstitutionDataError: action?.payload?.data
      };
    case RESET_INACCURATE_DATA_REPORTING_STATE:
      return {
        ...state,
        reportingInstitutionData: false,
        isInstitutionDataReported: false,
        reportingInstitutionDataError: undefined,
        loadingReportInaccurateDataMessage: false,
        reportInaccurateDataMessage: undefined,
        reportInaccurateDataMessageError: undefined
      };
    case FETCH_INACCURATE_DATA_MESSAGE_REQUEST:
      return {
        ...state,
        loadingReportInaccurateDataMessage: true
      };
    case FETCH_INACCURATE_DATA_MESSAGE_SUCCESS:
      return {
        ...state,
        loadingReportInaccurateDataMessage: false,
        reportInaccurateDataMessage: action.payload
      };
    case FETCH_INACCURATE_DATA_MESSAGE_ERROR:
      return {
        ...state,
        loadingReportInaccurateDataMessage: false,
        reportInaccurateDataMessageError: action.payload
      };
    default: {
      return state;
    }
  }
};
