import { FundsReceivedDataTableState, FundsReceivedDataTableAction } from './tableTypesAndActions';
import { updateFundsReceivedDataCustomFilter } from './tableStateFunctions';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';

export const initialFundsReceivedDataTableState: FundsReceivedDataTableState = {
  order: 'desc',
  sortBy: 'fundsReceivedYear',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    fundsReceivedYear: true,
    fundsReceivedSum: true,
    fundsSourceName: true,
    fundsSourceCode: true,
    transactionType: true,
    outletName: true
  },
  customFilterOn: false,
  customFilter: {
    fundsReceivedYear: [],
    fundsReceivedSum: [],
    fundsSourceName: [],
    fundsSourceCode: [],
    transactionType: [],
    outletName: []
  }
};

export const fundsReceivedDataTableReducer = (
  state: FundsReceivedDataTableState,
  action: FundsReceivedDataTableAction
): FundsReceivedDataTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return { ...state };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialFundsReceivedDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateFundsReceivedDataCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    default:
      return state;
  }
};
