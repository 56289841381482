import { ColumnHeader } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';

export const initialPaymentDataTableState: PaymentDataTableState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    decisionDate: true,
    paymentDocuments: true,
    paymentOutlets: true,
    paymentAmount: true,
    validFrom: true,
    validTo: true,
    paymentDeterminedBy: true
  },
  customFilterOn: false,
  customFilter: {
    decisionDate: [],
    paymentDocuments: [],
    paymentOutlets: [],
    paymentAmount: [],
    validFrom: [],
    validTo: [],
    paymentDeterminedBy: []
  },
  showNewRecord: false,
  showDocumentDialog: false
};

export type PaymentDataHeaderField =
  | 'decisionDate'
  | 'paymentDocuments'
  | 'paymentOutlets'
  | 'paymentAmount'
  | 'validFrom'
  | 'validTo'
  | 'paymentDeterminedBy';

export type PaymentDataColumnsDisplayStatus = {
  decisionDate: boolean;
  paymentDocuments: boolean;
  paymentOutlets: boolean;
  paymentAmount: boolean;
  validFrom: boolean;
  validTo: boolean;
  paymentDeterminedBy: boolean;
};

export type PaymentDataCustomFilter = {
  decisionDate: string[];
  paymentDocuments: string[];
  paymentOutlets: string[];
  paymentAmount: string[];
  validFrom: string[];
  validTo: string[];
  paymentDeterminedBy: string[];
};

export type PaymentDataColumnHeader = ColumnHeader<PaymentDataHeaderField>;

export interface PaymentDataTableState {
  sortBy: PaymentDataHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: PaymentDataColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: PaymentDataCustomFilter;
  showNewRecord: boolean;
  showDocumentDialog: boolean;
}

export type PaymentDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: PaymentDataHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: PaymentDataHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: PaymentDataHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'DOCUMENT_DIALOG_OPENED'; paymentDocumentId: number }
  | { type: 'DOCUMENT_DIALOG_CLOSED' };
