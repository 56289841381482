import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';
import { GenericSelectField } from '../../../components/FormikFields/GenericFormikWrappers/GenericSelectField';
import { GenericBooleanField } from '../../../components/FormikFields/GenericFormikWrappers/GenericBooleanField';
import { GenericRadioGroup } from '../../../components/FormikFields/GenericFormikWrappers/GenericRadioGroup';

import { resetForm, submitForm, State } from './store';
import { defaultValues, LegalFormInput } from './types';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<LegalFormInput>> = (formikProps) => {
  const { legalForms, shareTypes } = useSelector((state: State) => state.form.options);
  const legalFormTypeOptions = legalForms.map(({ legalFormCode, legalFormName }) => ({
    value: legalFormCode,
    label: `${legalFormName} (${legalFormCode})`
  }));

  const shareTypeOptions = shareTypes.map(({ shareTypeId, shareTypeName }) => ({
    value: shareTypeId,
    label: shareTypeName
  }));

  const {
    values: { legalFormCode }
  } = formikProps;

  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{legalFormCode}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Teisinė forma" spacing="2em">
        <GenericSelectField
          options={legalFormTypeOptions}
          label=""
          formikProps={formikProps}
          field="legalFormCode"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField label="Yra JADIS" field="isFromJadis" formikProps={formikProps} />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Reikia dalyvių iki fizinio asmens"
          field="isNaturalPersonRequired"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Privalomas dalyvių įvedimas"
          field="isShareholderRequired"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Teikia VIRS duomenis"
          field="providesVirsData"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Galima pažymėti balsų pagal taisyklę požymį dalies įvedimo metu"
          field="hasVotesOnRules"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Reikia nurodyti balsų dalį procentais"
          field="hasVotePercentage"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Dalies tipas" spacing="1em">
        <GenericRadioGroup
          field="shareTypeId"
          options={shareTypeOptions}
          formikProps={formikProps}
          style={{ helper: { whiteSpace: 'nowrap' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const LegalFormTypeForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error } = useSelector((state: State) => state.form);

  const data = useSelector((state: State) => state.data);

  const validationSchema = Yup.object().shape({
    legalFormId: noUndefinedNumber(),
    legalFormCode: Yup.number()
      .required('Būtina pasirinkti iš sąrašo')
      .test('is_unique_ongoing', 'Pasirinkta teisinė forma negali sutapti su esama', function () {
        const value: LegalFormInput = this.parent;
        return !data.some(
          ({ legalFormCode, legalFormId }) =>
            legalFormId !== value.legalFormId && value.legalFormCode === legalFormCode
        );
      })
      .typeError('Būtina pasirinkti iš sąrašo'),
    shareTypeId: noUndefinedNumber(),
    isConfiguredByVirs: Yup.boolean().required(),
    hasVotePercentage: Yup.boolean().required(),
    hasVotesOnRules: Yup.boolean().required(),
    providesVirsData: Yup.boolean().required(),
    isFromJadis: Yup.boolean().required(),
    isShareholderRequired: Yup.boolean().required(),
    isNaturalPersonRequired: Yup.boolean().required(),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="VIRSIS teisinės formos įvedimas"
    />
  );
};
