import { Reducer } from 'redux';
import {
  PAYMENT_DATA_REQUEST,
  PAYMENT_DATA_SUCCESS,
  PAYMENT_DATA_ERROR,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  RESET_CREATE_PAYMENT_STATE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR,
  RESET_UPDATE_PAYMENT_STATE,
  RESET_REMOVE_PAYMENT_STATE,
  REMOVE_PAYMENT_ERROR,
  REMOVE_PAYMENT_SUCCESS,
  REMOVE_PAYMENT_REQUEST,
  VIRS_PAYMENT_DATA_REQUEST,
  VIRS_PAYMENT_DATA_SUCCESS,
  VIRS_PAYMENT_DATA_ERROR,
  ANNUL_PAYMENT_DATA_REQUEST,
  ANNUL_PAYMENT_DATA_SUCCESS,
  ANNUL_PAYMENT_DATA_ERROR,
  RESET_ANNUL_PAYMENT_DATA_STATE,
  CLOSE_PAYMENT_ERROR,
  CLOSE_PAYMENT_REQUEST,
  CLOSE_PAYMENT_SUCCESS,
  RESET_CLOSING_PAYMENT_STATE,
  PAYMENT_DATA_BY_DOC_ID_REQUEST
} from './paymentsActionTypes';
import { PaymentDataInst, PaymentDataVirs } from './paymentsTypes';
import { PaymentDataCustomFilterOptions } from '../../containers/PaymentTableInst/tableState/paymentTableInitialStateAndTypes';

export interface PaymentDataState {
  loadingPaymentData: boolean;
  paymentData?: PaymentDataInst[];
  paymentDataError?: Error;
  creatingPayment: boolean;
  isPaymentCreated: boolean;
  creatingPaymentError?: Error;
  updatingPayment: boolean;
  isPaymentUpdated: boolean;
  updatingPaymentError?: Error;
  removingPayment: boolean;
  isPaymentRemoved: boolean;
  removingPaymentError?: Error;
  virsLoadingPaymentData: boolean;
  virsPaymentData?: PaymentDataVirs[];
  virsPaymentDataError?: Error;
  virsPaymentDataRecordCount: number;
  annullingPaymentRecord: boolean;
  annullingPaymentError?: Error;
  annullingPaymentSuccess: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  recordCount: number;
  instTableFilters?: PaymentDataCustomFilterOptions;
  closingPayment: boolean;
  isPaymentClosed: boolean;
  closingPaymentError?: Error;
}

const initialState: PaymentDataState = {
  loadingPaymentData: false,
  paymentData: undefined,
  paymentDataError: undefined,
  creatingPayment: false,
  isPaymentCreated: false,
  creatingPaymentError: undefined,
  updatingPayment: false,
  isPaymentUpdated: false,
  updatingPaymentError: undefined,
  removingPayment: false,
  isPaymentRemoved: false,
  removingPaymentError: undefined,
  virsLoadingPaymentData: true,
  virsPaymentData: undefined,
  virsPaymentDataError: undefined,
  virsPaymentDataRecordCount: 0,
  annullingPaymentRecord: false,
  annullingPaymentError: undefined,
  annullingPaymentSuccess: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  recordCount: 0,
  instTableFilters: undefined,
  closingPayment: false,
  isPaymentClosed: false,
  closingPaymentError: undefined
};

export const paymentsReducer: Reducer<PaymentDataState> = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_DATA_REQUEST:
      return { ...state, loadingPaymentData: true };
    case PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        loadingPaymentData: false,
        paymentData: action.payload.paymentData,
        paymentDataError: undefined,
        statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
        statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
        statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
        recordCount: action.payload.recordCount,
        instTableFilters: action.payload.filterValues
      };
    case PAYMENT_DATA_ERROR:
      return {
        ...state,
        loadingPaymentData: false,
        paymentDataError: action.payload
      };
    case CREATE_PAYMENT_REQUEST:
      return {
        ...state,
        creatingPayment: true
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        creatingPayment: false,
        isPaymentCreated: true
      };
    case CREATE_PAYMENT_ERROR:
      return {
        ...state,
        creatingPayment: false,
        isPaymentCreated: false,
        creatingPaymentError: action?.payload?.data
      };
    case RESET_CREATE_PAYMENT_STATE:
      return {
        ...state,
        creatingPayment: false,
        isPaymentCreated: false,
        creatingPaymentError: undefined
      };
    case UPDATE_PAYMENT_REQUEST:
      return {
        ...state,
        updatingPayment: true
      };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        updatingPayment: false,
        isPaymentUpdated: true
      };
    case UPDATE_PAYMENT_ERROR:
      return {
        ...state,
        updatingPayment: false,
        isPaymentUpdated: false,
        updatingPaymentError: action?.payload?.data
      };
    case RESET_UPDATE_PAYMENT_STATE:
      return {
        ...state,
        updatingPayment: false,
        isPaymentUpdated: false,
        updatingPaymentError: undefined
      };
    case REMOVE_PAYMENT_REQUEST:
      return {
        ...state,
        removingPayment: true
      };
    case REMOVE_PAYMENT_SUCCESS:
      return {
        ...state,
        removingPayment: false,
        isPaymentRemoved: true
      };
    case REMOVE_PAYMENT_ERROR:
      return {
        ...state,
        removingPayment: false,
        isPaymentRemoved: false,
        removingPaymentError: action?.payload?.data
      };
    case RESET_REMOVE_PAYMENT_STATE:
      return {
        ...state,
        removingPayment: false,
        isPaymentRemoved: false,
        removingPaymentError: undefined
      };
    case VIRS_PAYMENT_DATA_REQUEST:
      return { ...state, virsLoadingPaymentData: true };
    case VIRS_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        virsLoadingPaymentData: false,
        virsPaymentData: action.payload.paymentData,
        virsPaymentDataRecordCount: action.payload.paymentData.length
      };
    case VIRS_PAYMENT_DATA_ERROR:
      return {
        ...state,
        virsLoadingPaymentData: false,
        virsPaymentDataError: action?.payload?.data
      };
    case ANNUL_PAYMENT_DATA_REQUEST:
      return {
        ...state,
        annullingPaymentRecord: true
      };
    case ANNUL_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        annullingPaymentRecord: false,
        annullingPaymentSuccess: true
      };
    case ANNUL_PAYMENT_DATA_ERROR:
      return {
        ...state,
        annullingPaymentRecord: false,
        annullingPaymentSuccess: false,
        annullingPaymentError: action?.payload?.data
      };
    case RESET_ANNUL_PAYMENT_DATA_STATE:
      return {
        ...state,
        annullingPaymentRecord: false,
        annullingPaymentSuccess: false,
        annullingPaymentError: undefined
      };
    case CLOSE_PAYMENT_REQUEST:
      return {
        ...state,
        closingPayment: true
      };
    case CLOSE_PAYMENT_SUCCESS:
      return {
        ...state,
        closingPayment: false,
        isPaymentClosed: true
      };
    case CLOSE_PAYMENT_ERROR:
      return {
        ...state,
        closingPayment: false,
        isPaymentClosed: false,
        closingPaymentError: action?.payload?.data
      };
    case RESET_CLOSING_PAYMENT_STATE:
      return {
        ...state,
        closingPayment: false,
        isPaymentClosed: false,
        closingPaymentError: undefined
      };
    case PAYMENT_DATA_BY_DOC_ID_REQUEST:
      return {
        ...state,
        loadingPaymentData: true
      };
    default:
      return state;
  }
};
