import { Field, FieldProps } from 'formik';
import React from 'react';
import { Checkbox, Typography, makeStyles } from '@material-ui/core';
import { FormikFieldStyleProps } from '../../../../components/FormikFields/FieldStyles';

export const ArrayCheckBox = ({ field }: FieldProps) => {
  return (
    <>
      <Checkbox
        color="primary"
        {...field}
        value={field.value || false}
        checked={field.value || false}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    marginLeft: '190px',
    width: '85px'
  },
  [theme.breakpoints.down('sm')]: {
    wrapper: {
      marginLeft: 0,
      '& span': {
        paddingLeft: 0
      }
    }
  }
}));

interface Props extends FormikFieldStyleProps {
  arrayName: string;
  index: number;
  field: string;
  label?: string;
  styledClasses: Record<string, string>;
}

export const FormikArrayCheckBox: React.FC<Props> = ({
  arrayName,
  index,
  field,
  label,
  styledClasses
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Field name={`${arrayName}[${index}].${field}`} component={ArrayCheckBox} />
      <Typography variant="h5" className={styledClasses.inputLabel} style={{ margin: 'auto 0' }}>
        {label}
      </Typography>
    </div>
  );
};
