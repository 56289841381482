import { get, post, axiosDelete, put, getBlob } from '../../utils/axiosApi';
import { EnterpriseRecord } from './enterprisesTypes';

export const getEnterpriseData = (virId: number, personId: number) =>
  get(`/enterprise-data/${virId}/${personId}`);

export const postCreateEnterprise = (virId: number, personId: number, record: EnterpriseRecord) => {
  return post(`/enterprise-data/${virId}/${personId}`, record);
};

export const putUpdateEnterprise = (
  virId: number,
  personId: number,
  enterpriseId: number,
  record: EnterpriseRecord
) => {
  return put(`/enterprise-data/${virId}/${personId}/${enterpriseId}`, record);
};

export const deleteRemoveEnterprise = (virId: number, enterpriseId: number) => {
  return axiosDelete(`/enterprise-data/${virId}/${enterpriseId}`);
};

export const getOutletIds = (enterpriseId: number) =>
  get(`/outlet-data/vir-outlet-control-ids?enterpriseId=${enterpriseId}`);

export const getEnterpriseDataDocument = (enterpriseDataId: number) =>
  getBlob(`virs/legals/documents/${enterpriseDataId}`);
