import { PaginationParams, SortOrder } from '../../utils/tableTypes';
import { Order } from '../../components/TableTypes/TableTypes';
import {
  ProfessionalMisconductInstCustomFilter,
  ProfessionalMisconductInstTableField
} from '../../containers/ProfessionalMisconductTableInst/tableState/misconductTableInitialStateAndTypes';

export interface ProfessionalMisconductsTablePageRequest extends PaginationParams {
  filterVirsId: number | null;
  filterDecisionDate: string | null;
  filterDocumentNumber: string | null;
  filterOutletId: number | null;
  filterMisconductTypeId: number | null;
  filterSanctionTypeId: number | null;
  filterValidFrom: string | null;
  filterValidTo: string | null;
  sortingVirsName: SortOrder | null;
  sortingDecisionDate: SortOrder | null;
  sortingDocumentNumber: SortOrder | null;
  sortingOutletName: SortOrder | null;
  sortingProfessionalMisconduct: SortOrder | null;
  sortingSanction: SortOrder | null;
  sortingValidFrom: SortOrder | null;
  sortingValidTo: SortOrder | null;
}

export interface ProfessionalMisconductsTableDataState {
  sortBy: ProfessionalMisconductInstTableField;
  order: Order;
  page: number;
  rowsPerPage: number;
  customFilter: ProfessionalMisconductInstCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  showStatusCanceled: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
}

export const initialProfessionalMisconductTableDataState: ProfessionalMisconductsTableDataState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 10,
  customFilter: {
    virsName: [],
    decisionDate: [],
    documentNumber: [],
    outletName: [],
    professionalMisconduct: [],
    sanction: [],
    validFrom: [],
    validTo: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: false,
  showStatusCanceled: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false
};
