import React, { useContext } from 'react';
import { TableRow, TableCell, Typography, TableHead, TableSortLabel } from '@material-ui/core';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import { FundsReceivedLegalTableStateContext } from './Context';
import { FundsReceivedLegalTableField } from './tableState/tableTypesAndActions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { sortingChanged } from '../../store/fundsReceivedTable/fundsReceivedTableActions';

export const FundsReceivedLegalTableHeader: React.FC = () => {
  const { state: tableState } = useContext(FundsReceivedLegalTableStateContext);
  const reduxDispatch = useDispatch();
  const { tableDataState } = useSelector((state: ApplicationState) => state.fundsReceivedTableData);
  const handleSetSortByColumn = (column: FundsReceivedLegalTableField) => {
    reduxDispatch(sortingChanged(column));
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.virsName && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            key="virsName"
            sortDirection={tableDataState.sortBy === 'virsName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('virsName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'virsName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'virsName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIRS</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsReceivedYear && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            key="fundsReceivedYear"
            sortDirection={
              tableDataState.sortBy === 'fundsReceivedYear' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsReceivedYear')}
          >
            <TableSortLabel
              active
              direction={
                tableDataState.sortBy === 'fundsReceivedYear' ? tableDataState.order : 'asc'
              }
              IconComponent={
                tableDataState.sortBy === 'fundsReceivedYear' ? ActiveSortIcon : SortIcon
              }
            >
              <Typography variant="h4">Metai</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsReceivedSum && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            key="fundsReceivedSum"
            sortDirection={
              tableDataState.sortBy === 'fundsReceivedSum' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsReceivedSum')}
          >
            <TableSortLabel
              active
              direction={
                tableDataState.sortBy === 'fundsReceivedSum' ? tableDataState.order : 'asc'
              }
              IconComponent={
                tableDataState.sortBy === 'fundsReceivedSum' ? ActiveSortIcon : SortIcon
              }
            >
              <Typography variant="h4">Suma (EUR)</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceName && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={
              tableDataState.sortBy === 'fundsSourceName' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsSourceName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'fundsSourceName' ? tableDataState.order : 'asc'}
              IconComponent={
                tableDataState.sortBy === 'fundsSourceName' ? ActiveSortIcon : SortIcon
              }
            >
              <Typography variant="h4">Lėšų šaltinio pavadinimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.fundsSourceCode && (
          <TableCell
            align="left"
            rowSpan={2}
            variant="head"
            sortDirection={
              tableDataState.sortBy === 'fundsSourceCode' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsSourceCode')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'fundsSourceCode' ? tableDataState.order : 'asc'}
              IconComponent={
                tableDataState.sortBy === 'fundsSourceCode' ? ActiveSortIcon : SortIcon
              }
            >
              <Typography variant="h4">Lėšų šaltinio kodas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.fundsReceivedOutlets && (
          <TableCell
            align="left"
            variant="head"
            sortDirection={
              tableDataState.sortBy === 'fundsReceivedOutlets' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('fundsReceivedOutlets')}
          >
            <TableSortLabel
              active
              direction={
                tableDataState.sortBy === 'fundsReceivedOutlets' ? tableDataState.order : 'asc'
              }
              IconComponent={
                tableDataState.sortBy === 'fundsReceivedOutlets' ? ActiveSortIcon : SortIcon
              }
            >
              <Typography variant="h4">Susijusios VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.transaction && (
          <TableCell
            align="left"
            variant="head"
            sortDirection={tableDataState.sortBy === 'transaction' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('transaction')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'transaction' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'transaction' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Sandorio rūšis</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell align="left" variant="head" className="column-of-buttons">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
        <TableCell align="left" variant="head" className="column-of-buttons">
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
