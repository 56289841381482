import React from 'react';
import { TableRow, TableHead } from '@material-ui/core';
import { getSortLabelPropsFactory } from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  useIntegratedSystemsAuditsTableDispatch,
  useIntegratedSystemsAuditsTableState
} from './Context';
import {
  IntegratedSystemsAuditsHeaderField,
  IntegratedSystemsAuditsTableState
} from './tableState/tableInitialStateAndTypes';

export const IntegratedSystemsAuditsTableHeader: React.FC = () => {
  const { dispatch: tableDispatch } = useIntegratedSystemsAuditsTableDispatch();
  const { state: tableState } = useIntegratedSystemsAuditsTableState();

  const handleSetSortByColumn = (column: IntegratedSystemsAuditsHeaderField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    IntegratedSystemsAuditsTableState,
    typeof handleSetSortByColumn,
    IntegratedSystemsAuditsHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        <TableSortingHeaderCell
          label="Sistema, iš kurios gaunami duomenys"
          columnName="systemDataIsReceivedFrom"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Sistema, į kurią siunčiami duomenys"
          columnName="systemDataIsSentTo"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Sąsajos tipas"
          columnName="serviceTypeName"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Sąsaja"
          columnName="service"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Rezultato kodas"
          columnName="resultCode"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Užklausos data ir laikas"
          columnName="requestDate"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Užklausos turinys"
          columnName="requestContent"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Atsakymo data ir laikas"
          columnName="responseDate"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
        <TableSortingHeaderCell
          label="Atsakymo turinys"
          columnName="responseContent"
          sortLabelPropsFactory={sortLabelPropsFactory}
          tableSettings={tableState}
        />
      </TableRow>
    </TableHead>
  );
};
