import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';

import { resetForm, State, submitForm } from './store';
import { GenericTextField } from '../../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import { defaultValues, SanctionTypeInput } from './types';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<SanctionTypeInput>> = (formikProps) => {
  const {
    values: { sanctionTypeId }
  } = formikProps;
  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{sanctionTypeId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Poveikio priemonės pavadinimas Lt" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="sanctionTypeName"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Poveikio priemonės pavadinimas En" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="sanctionTypeNameEn"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const SanctionTypeForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error, data } = useSelector((state: State) => ({
    ...state.form,
    data: state.data
  }));

  const validationSchema = Yup.object().shape({
    sanctionTypeId: noUndefinedNumber(),
    sanctionTypeName: Yup.string()
      .required('Būtina įvesti reikšmę')
      .max(200, 'Negali būti ilgesnis nei 200 simbolių')
      .notOneOf(
        data
          .map(({ sanctionTypeName }) => sanctionTypeName)
          .filter((value) => initialValues?.sanctionTypeName !== value),
        'Privalo būti unikalus'
      ),
    sanctionTypeNameEn: Yup.string()
      .nullable()
      .required('Būtina įvesti reikšmę')
      .max(200, 'Negali būti ilgesnis nei 200 simbolių')
      .notOneOf(
        data
          .map(({ sanctionTypeNameEn }) => sanctionTypeNameEn)
          .filter((value) => initialValues?.sanctionTypeNameEn !== value),
        'Privalo būti unikalus'
      ),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="Poveikio priemonės įvedimas"
    />
  );
};
