import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../style/virsisTheme';

const useStyles = makeStyles({
  linkButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    margin: '0',
    padding: '0',
    textAlign: 'left',
    height: 'auto',
    marginTop: 5,
    '&:disabled': {
      color: 'inherit'
    },
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  }
});

interface ButtonProps {
  text: string;
  onClicked?: () => void;
  disabled?: boolean;
  style?: {
    label: React.CSSProperties;
  };
}

const GoInputDataButton: React.FC<ButtonProps> = ({ text, onClicked, disabled, style }) => {
  const classes = useStyles();

  return (
    <Button className={classes.linkButton} onClick={onClicked} disabled={disabled}>
      <span style={style?.label}>{text}</span>
    </Button>
  );
};
export default GoInputDataButton;
