import {
  EnterpriseDataTableState,
  EnterpriseDataTableAction,
  enterpriseTableColumns
} from './initialStateAndTypes';

import {
  addNewOrRemoveExisting,
  getToggledSortOrderGeneric,
  updateCustomFilterMultiple
} from '../../../utils/tableDataFunctions';
import { getTableExportDefinition } from './tableFunctions';

export const enterpriseDataTableReducer = (
  state: EnterpriseDataTableState,
  action: EnterpriseDataTableAction
): EnterpriseDataTableState => {
  switch (action.type) {
    case 'SET_STATUS_FILTERS':
      return {
        ...state,
        statusNotSignedFilterEnabled: action.notSignedEnabled,
        statusRelevantFilterEnabled: action.relevantEnabled,
        statusOutdatedFilterEnabled: action.outdatedEnabled,
        showStatusNotSigned: action.notSignedEnabled,
        showStatusRelevant: action.relevantEnabled,
        showStatusOutdated: action.outdatedEnabled
      };
    case 'TOGGLE_SHOW_ADD_NEW_RECORD':
      return { ...state, showAddNewRecord: !state.showAddNewRecord };
    case 'TOGGLE_CUSTOM_FILTER_DISPLAY':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          enterpriseTypeName: [],
          validFrom: [],
          validTo: [],
          authorName: []
        }
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: updateCustomFilterMultiple(
          state.customFilter,
          action.filterBy,
          action.values
        ),
        page: 0
      };
    case 'TOGGLE_SHOW_STATUS_UNSIGNED':
      return {
        ...state,
        showStatusNotSigned: !state.showStatusNotSigned,
        page: 0
      };
    case 'TOGGLE_SHOW_STATUS_RELEVANT':
      return {
        ...state,
        showStatusRelevant: !state.showStatusRelevant,
        page: 0
      };
    case 'TOGGLE_SHOW_STATUS_NOT_RELEVANT':
      return {
        ...state,
        showStatusOutdated: !state.showStatusOutdated,
        page: 0
      };
    case 'SET_SORTING':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'TOGGLE_COLUMN_DISPLAY': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(
          enterpriseTableColumns,
          columnsDisplayStatus
        )
      };
    }
    case 'RESET_COLUMN_DISPLAY':
      return {
        ...state,
        columnsDisplayStatus: {
          enterpriseTypeName: true,
          validFrom: true,
          validTo: true,
          authorName: true
        }
      };
    case 'ROW_EXTENSION_CLICKED':
      return {
        ...state,
        extendedRows: addNewOrRemoveExisting(action.enterpriseId, state.extendedRows)
      };
    case 'INITIALIZE_WITH_NEW_RECORD_OPENED':
      return {
        ...state,
        showAddNewRecord: true
      };
    default:
      return state;
  }
};
