import { Cell, Column, Workbook, Worksheet } from 'exceljs';
import { range } from 'lodash';
import moment from 'moment';
import {
  ColumnGroup,
  TableExportDefinition,
  CellDefinition,
  ColumnDefinition,
  defaultCellStyle
} from './types';

async function getBlob(wb: Workbook): Promise<Blob> {
  const buffer = await wb.xlsx.writeBuffer();
  return new Blob([buffer], { type: 'application/octet-stream' });
}

function getHeadersHeight<TData>(columnGroups: ColumnGroup<TData>[]): number {
  return columnGroups.some(
    ({ columns, header }) => header && columns.filter(({ visible }) => visible).length > 0
  )
    ? 2
    : 1;
}

function applyCellStyle(definition: CellDefinition, cell: Cell) {
  cell.style = {
    alignment: {
      horizontal: definition.style?.alignment?.horizontal || defaultCellStyle.alignment?.horizontal,
      vertical: definition.style?.alignment?.vertical || defaultCellStyle.alignment?.vertical,
      wrapText: true
    },
    border: {
      bottom: { style: definition.style?.border || defaultCellStyle.border },
      left: { style: definition.style?.border || defaultCellStyle.border },
      top: { style: definition.style?.border || defaultCellStyle.border },
      right: { style: definition.style?.border || defaultCellStyle.border }
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: definition.style?.fill || defaultCellStyle.fill }
    }
  };
}

function applyCellMerge({ style, ...definition }: CellDefinition, cell: Cell) {
  const colSpan = style?.colSpan || 1;
  const rowSpan = style?.rowSpan || 1;

  if (colSpan === 1 && rowSpan === 1) {
    return;
  }

  const { worksheet: sheet, row, col } = cell;
  const targetRow = parseInt(row, 10) + rowSpan - 1;
  const targetCol = parseInt(col, 10) + colSpan - 1;

  range(parseInt(row, 10), targetRow + 1).forEach((r) =>
    range(parseInt(col, 10), targetCol + 1).forEach((c) =>
      applyCellStyle({ ...definition, style }, sheet.getCell(r, c))
    )
  );

  sheet.getCell(targetRow, targetCol).merge(cell, false);
}

function applyCellValue(definition: CellDefinition, cell: Cell) {
  cell.value = definition.values.join('\n');
}

function applyColumnStyle<TData>(definition: ColumnDefinition<TData>, column: Column) {
  column.width = definition.style?.width;
}

function addHeaderCells<TData>(
  columnGroups: ColumnGroup<TData>[],
  sheet: Worksheet,
  rowOffset: number
): number {
  let row = rowOffset + 1;
  columnGroups.forEach(({ columns: groupColumns, header: groupHeader }, groupIndex) => {
    const groupStartIndex = columnGroups
      .slice(0, groupIndex)
      .map(({ columns }) => columns.filter(({ visible }) => visible))
      .map(({ length }) => length)
      .reduce((prev, value) => prev + value, 1);

    const visibleColumns = groupColumns.filter(({ visible }) => visible);

    if (groupHeader && visibleColumns.length > 0) {
      const cell = sheet.getCell(row, groupStartIndex);
      const cellDefinition: CellDefinition = {
        values: [groupHeader],
        style: {
          alignment: { horizontal: 'center', vertical: 'middle' },
          border: 'medium',
          colSpan: visibleColumns.length
        }
      };
      applyCellStyle(cellDefinition, cell);
      applyCellMerge(cellDefinition, cell);
      applyCellValue(cellDefinition, cell);
      row++;
    }
    const secondRow = rowOffset + (groupHeader && visibleColumns.length > 0 ? 2 : 1);
    row = row < secondRow ? secondRow : row;

    visibleColumns
      .map(
        ({ header }): CellDefinition => ({
          values: header ? [header] : [],
          style: {
            alignment: { horizontal: 'center', vertical: 'middle' },
            border: 'medium',
            rowSpan: groupHeader ? 1 : getHeadersHeight(columnGroups)
          }
        })
      )
      .forEach((cellDefinition, index) => {
        const cell = sheet.getCell(secondRow, groupStartIndex + index);
        applyCellStyle(cellDefinition, cell);
        applyCellMerge(cellDefinition, cell);
        applyCellValue(cellDefinition, cell);
      });
  });
  return row;
}

function applyColumnDefinitions<TData>(columnGroups: ColumnGroup<TData>[], sheet: Worksheet) {
  columnGroups
    .map(({ columns }) => columns)
    .flat()
    .filter(({ visible }) => visible)
    .forEach((definition, index) => {
      const column = sheet.getColumn(index + 1);
      applyColumnStyle(definition, column);
    });
}

function addDataCells<TData>(
  data: TData[],
  columnGroups: ColumnGroup<TData>[],
  sheet: Worksheet,
  rowOffset: number
): number {
  const cellMappers = columnGroups
    .map(({ columns }) => columns)
    .flat()
    .filter(({ visible }) => visible)
    .map(({ cellMapper }) => cellMapper);

  let currentRow = rowOffset + 1;

  data.forEach((entry) => {
    const cellDefinitions = cellMappers.map((mapper) => mapper(entry));

    cellDefinitions.forEach((cellGroup, columnIndex) => {
      cellGroup.forEach((cellDefinition, rowIndex) => {
        const rowOffset = cellGroup
          .slice(0, rowIndex)
          .map(({ style }) => style?.rowSpan || 1)
          .reduce((prev, value) => prev + value, 0);
        const cell = sheet.getCell(currentRow + rowOffset, columnIndex + 1);
        applyCellStyle(cellDefinition, cell);
        applyCellMerge(cellDefinition, cell);
        applyCellValue(cellDefinition, cell);
      });
    });

    currentRow += cellDefinitions.reduce(
      (prev, value) => (value.length > prev ? value.length : prev),
      0
    );
  });

  return currentRow;
}

function addInfoCells<TData>(
  sheet: Worksheet,
  { user, title, header }: TableExportDefinition<TData>,
  rowOffset?: number
): number {
  let row = (rowOffset || 0) + 1;
  const date = moment().format('YYYY-MM-DD');
  sheet.getCell(row, 1).value = date;
  row++;

  sheet.getCell(row, 1).value = 'Sistema: VIRSIS';
  row++;

  if (user) {
    sheet.getCell(row, 1).value = `Dokumentą suformavęs asmuo: ${user}`;
    row++;
  }

  row++;

  if (header) {
    sheet.getCell(row, 1).value = header;
    row++;
    row++;
  }

  if (title) {
    sheet.getCell(row, 1).value = title;
  }

  return row;
}

export function getXLSXExporter<TData>(
  definition: TableExportDefinition<TData>
): (data: TData[]) => void {
  const { onBlob, title, columnGroups } = definition;
  return (data) => {
    const wb = new Workbook();
    const sheet = wb.addWorksheet(title || 'SHEET_1');

    applyColumnDefinitions(columnGroups, sheet);
    let rowOffset = addInfoCells(sheet, definition);
    rowOffset = addHeaderCells(columnGroups, sheet, rowOffset);
    addDataCells(data, columnGroups, sheet, rowOffset);

    getBlob(wb).then((blob) => onBlob && onBlob(blob));
  };
}

export type Exporter<TData> = (data: TData[]) => void;
