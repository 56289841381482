import React from 'react';
import MarkunreadRoundedIcon from '@material-ui/icons/MarkunreadRounded';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import {
  markMessagesRead,
  markMessagesUnread,
  removeUserMessages,
  resetUserMessageRemovingState
} from '../../store/userMessages/userMessagesActions';
import { ApplicationState } from '../../store';
import { useMessagesTableDispatch, useMessagesTableState } from './Context';
import { UserMessage, UserMessageRecord } from '../../store/userMessages/userMessageTypes';
import { RemoveSelectedMessagesButton } from './RemoveSelectedMessagesButton';
import { ChangeSelectedMessagesStatusButton } from './ChangeSelectedMessagesStatusButton';
import { CustomCheckboxButton } from '../../components/TableButtons/CustomCheckboxButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      color: theme.palette.info.dark,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: 'transparent'
      },
      '&:disabled': {
        color: theme.palette.info.main
      }
    }
  })
);

interface Props {
  allPageMessages: UserMessage[];
  hasMessages: boolean;
}

export const MessagesTableCustomizationBarActions: React.FC<Props> = ({
  allPageMessages,
  hasMessages
}) => {
  const classes = useStyles();

  const {
    removingUserMessages,
    removingUserMessagesError,
    markingUserMessagesRead,
    markingUserMessagesUnread
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal.userMessages);
  const reduxDispatch = useDispatch();

  const { state: tableState } = useMessagesTableState();
  const { dispatch: tableDispatch } = useMessagesTableDispatch();

  function selectAllClicked() {
    return tableState.selectAllChecked
      ? tableDispatch({ type: 'ALL_MESSAGES_UNSELECTED' })
      : tableDispatch({
          type: 'ALL_MESSAGES_SELECTED',
          messages: allPageMessages
        });
  }

  function openRemoveConfirmation(event: any) {
    tableDispatch({ type: 'REMOVE_SELECTED_CLICKED' });
  }

  function closeRemoveConfirmation() {
    tableDispatch({ type: 'REMOVE_SELECTED_CLICKED' });
  }

  function removeSelectedMessages() {
    const messages: UserMessageRecord[] = tableState.selectedMessages.map((msg) => ({
      messageId: msg.messageId,
      messageStatusId: msg.messageStatusId
    }));
    reduxDispatch(removeUserMessages(messages));
  }

  function closeRemoveConfirmationOnError() {
    closeRemoveConfirmation();
    reduxDispatch(resetUserMessageRemovingState());
  }

  function markSelectedMessagesAsUnread() {
    const messages: UserMessageRecord[] = tableState.selectedMessages.map((msg) => ({
      messageId: msg.messageId,
      messageStatusId: msg.messageStatusId
    }));
    reduxDispatch(markMessagesUnread(messages));
  }

  function markSelectedMessagesAsRead() {
    const messages: UserMessageRecord[] = tableState.selectedMessages.map((msg) => ({
      messageId: msg.messageId,
      messageStatusId: msg.messageStatusId
    }));
    reduxDispatch(markMessagesRead(messages));
  }

  const anyMessageSelected = !!tableState.selectedMessages.length;

  return (
    <>
      <span style={{ marginLeft: 12 }}>
        <CustomCheckboxButton
          setCheckboxState={selectAllClicked}
          checked={tableState.selectAllChecked}
          disabled={!hasMessages}
        />
      </span>
      <RemoveSelectedMessagesButton
        tooltipMessage="Pašalinti pasirinktas žinutes"
        isOpen={tableState.showRemoveConfirmation}
        selectedMessages={tableState.selectedMessages.map((msg) => msg.messageId)}
        isProcessing={removingUserMessages}
        error={removingUserMessagesError}
        onClicked={openRemoveConfirmation}
        onConfirmationDo={removeSelectedMessages}
        onConfirmationCancel={closeRemoveConfirmation}
        onErrorClose={closeRemoveConfirmationOnError}
        icon={
          <>
            {removingUserMessages ? (
              <CircularProgress style={{ width: '20px', height: '20px' }} color="secondary" />
            ) : (
              <DeleteIcon fontSize="small" />
            )}
          </>
        }
        buttonStyle={classes.button}
      />
      <ChangeSelectedMessagesStatusButton
        onClicked={markSelectedMessagesAsRead}
        isProcessing={markingUserMessagesRead}
        showToolTip={anyMessageSelected}
        tooltipText={anyMessageSelected ? 'Pasirinktas žinutes pažymėti kaip perskaitytas' : ''}
        icon={<DraftsRoundedIcon fontSize="small" />}
        buttonStyle={classes.button}
      />
      <ChangeSelectedMessagesStatusButton
        onClicked={markSelectedMessagesAsUnread}
        isProcessing={markingUserMessagesUnread}
        showToolTip={anyMessageSelected}
        tooltipText="Pasirinktas žinutes pažymėti kaip neperskaitytas"
        icon={<MarkunreadRoundedIcon fontSize="small" />}
        buttonStyle={classes.button}
      />
    </>
  );
};
