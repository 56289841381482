import moment from 'moment';
import {
  intialSemiEditRowState,
  OutletSemiEditRowAction,
  OutletSemiEditRowState
} from './outletRowStateAndTypes';
import { validatedOutletRecordRowStateForDate } from './outletRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue
} from '../../../../utils/InputValueFunctions';

export const semiEditOutletReducer = (
  state: OutletSemiEditRowState,
  action: OutletSemiEditRowAction
): OutletSemiEditRowState => {
  switch (action.type) {
    case 'SEMI_EDIT_BUTTON_CLICKED':
      return {
        ...state,
        editingOn: true,
        name: {
          ...state.name,
          value: action.outlet.outletName
        },
        controlledTo: {
          ...state.controlledTo,
          value: action.outlet.controlledTo ? moment(action.outlet.controlledTo) : null,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.registrationDate),
              text: 'Data negali būti ankstesnė už įsiregistravimo datą JAR'
            },
            {
              id: 'enterpriseValidFrom',
              date: moment(action.enterpriseValidFrom),
              text: 'Data negali būti ankstesnė už veiklos pradžios datą'
            },
            {
              id: 'established',
              date: moment(action.outlet.establishedDate),
              text: 'Data negali būti ankstesnė už VIP įsteigimo datą'
            },
            {
              id: 'controlledFrom',
              date: moment(action.outlet.controlledFrom),
              text: 'Data negali būti vėlesnė už valdymo pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date: action.deregistrationDate !== null ? moment(action.deregistrationDate) : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo iš JAR datą'
            },
            {
              id: 'enterpriseValidTo',
              date: action.enterpriseValidTo ? moment(action.enterpriseValidTo) : null,
              text: 'Data negali būti vėlesnė už veiklos pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'CANCEL_EDITING_BUTTON_CLICKED':
      return intialSemiEditRowState;
    case 'OUTLET_NAME_CHANGED':
      return {
        ...state,
        name: {
          ...state.name,
          value: action.name,
          validated: false,
          error: false
        }
      };
    case 'OUTLET_CONTROLLED_TO_CHANGED':
      return {
        ...state,
        controlledTo: updateDateValue(action.controlledTo, state.controlledTo)
      };
    case 'ADD_END_DATE_CLICKED':
      return validatedOutletRecordRowStateForDate(state);
    case 'UPDATE_END_DATE_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateDateConfirmationOn: false
      };
    case 'UPDATE_END_DATE_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateDateConfirmationOn: false
      };
    case 'CANCEL_ADDING_END_DATE_CLICKED':
      return {
        ...state,
        updateDateConfirmationOn: false
      };
    case 'REMOVE_END_DATE_CLICKED':
      return {
        ...state,
        updateDateConfirmationOn: true
      };
    case 'CANCEL_REMOVING_END_DATE_CLICKED':
      return {
        ...state,
        updateDateConfirmationOn: false
      };
    case 'CLOSED_ON_CIRCULATION_MISSING':
      return {
        ...state,
        editingOn: false,
        updateDateConfirmationOn: false
      };
    default:
      return state;
  }
};
