import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface Props {
  title: string | ReactNode;
  value?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: '0px',
    color: theme.palette.text.primary
  },
  alignExtendedRowData: {
    width: '155px',
    display: 'inline-block',
    textAlign: 'right',
    fontWeight: 'bold',
    marginRight: '10px',
    color: theme.palette.text.primary
  }
}));

export const LineWithTitle: React.FC<Props> = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.noMargin}>
      <span className={classes.alignExtendedRowData}>{title}</span>
      {value}
    </Typography>
  );
};

export const LineWithoutTitle: React.FC<any> = ({ value }) => {
  const classes = useStyles();
  return <Typography className={classes.noMargin}>{value}</Typography>;
};
