import { ColumnHeader, DatePeriod } from '../../../utils/tableTypes';
import { Order } from '../../../components/TableTypes/TableTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { ProvidedDataInst } from '../../../store/providedData/providedDataTypes';
import { getTableExportDefinition } from '../../ProvidedDataTableInstLegal/tableState/tableStateFunctions';
import { initialDisplayStatus } from '../../ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';

const providedDatatColumns: ProvidedDataHistoryColumnHeader[] = [
  {
    id: 'dataType',
    label: 'Duomenų tipas'
  },
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'outletList',
    label: 'VIP'
  },
  {
    id: 'documentStatus',
    label: 'Būsena'
  },
  {
    id: 'lastEditedDate',
    label: 'Paskutinio įvedimo / redagavimo data',
    type: 'nonFutureDate'
  },
  {
    id: 'lastEditedBy',
    label: 'Paskutinis įvedęs / redagavęs asmuo'
  }
];

export const initialProvidedDataHistoryTableState: ProvidedDataHistoryTableState = {
  order: 'desc',
  sortBy: 'lastEditedDate',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    dataType: true,
    virsName: true,
    outletList: true,
    documentStatus: true,
    lastEditedDate: true,
    lastEditedBy: true
  },
  customFilterOn: false,
  customFilter: {
    dataType: [],
    virsName: [],
    outletList: [],
    documentStatus: [],
    lastEditedDate: [],
    lastEditedBy: []
  },
  periodFilter: {
    id: 'PASKUTINIS_MENUO',
    label: 'Paskutinis mėnuo'
  },
  showStatusNotSigned: false,
  showStatusSigned: false,
  showStatusAnnuled: false,
  statusNotSignedFilterEnabled: false,
  statusSignedFilterEnabled: false,
  statusAnnuledFilterEnabled: false,
  showNewRecord: false,
  showDocumentDialog: false,
  tableExportDefinition: getTableExportDefinition(providedDatatColumns, initialDisplayStatus)
};

export type ProvidedDataHistoryHeaderField =
  | 'dataType'
  | 'virsName'
  | 'outletList'
  | 'documentStatus'
  | 'lastEditedDate'
  | 'lastEditedBy';

export type ProvidedDataHistoryColumnsDisplayStatus = {
  dataType: boolean;
  virsName: boolean;
  outletList: boolean;
  documentStatus: boolean;
  lastEditedDate: boolean;
  lastEditedBy: boolean;
};

export type ProvidedDataHistoryCustomFilter = {
  dataType: string[];
  virsName: string[];
  outletList: string[];
  documentStatus: string[];
  lastEditedDate: string[];
  lastEditedBy: string[];
};

export type ProvidedDataHistoryColumnHeader = ColumnHeader<ProvidedDataHistoryHeaderField>;

export interface Period {
  id: DatePeriod;
  label: string;
}

export interface ProvidedDataHistoryTableState {
  sortBy: ProvidedDataHistoryHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: ProvidedDataHistoryColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: ProvidedDataHistoryCustomFilter;
  periodFilter: Period;
  showStatusNotSigned: boolean;
  showStatusSigned: boolean;
  showStatusAnnuled: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusSignedFilterEnabled: boolean;
  statusAnnuledFilterEnabled: boolean;
  showNewRecord: boolean;
  showDocumentDialog: boolean;
  tableExportDefinition: TableExportDefinition<ProvidedDataInst>;
}

export type ProvidedDataHistoryTableAction =
  | {
      type: 'TABLE_INITIALIZED';
      notSignedEnabled: boolean;
      signedEnabled: boolean;
      annuledEnabled: boolean;
    }
  | { type: 'SHOW_STATUS_UNSIGNED_TOGGLED' }
  | { type: 'SHOW_STATUS_SIGNED_TOGGLED' }
  | { type: 'SHOW_STATUS_ANNULED_TOGGLED' }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: ProvidedDataHistoryHeaderField;
      value: string | null;
    }
  | {
      type: 'PERIOD_FILTER_VALUE_CHANGED';
      value: Period | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: ProvidedDataHistoryHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: ProvidedDataHistoryHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'DOCUMENT_DIALOG_OPENED'; documentId: number }
  | { type: 'DOCUMENT_DIALOG_CLOSED' };

export const providedDataInstColumns: ProvidedDataHistoryColumnHeader[] = [
  {
    id: 'dataType',
    label: 'Duomenų tipas'
  },
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'outletList',
    label: 'VIP'
  },
  {
    id: 'documentStatus',
    label: 'Būsena'
  },
  {
    id: 'lastEditedDate',
    label: 'Paskutinio įvedimo / redagavimo data',
    type: 'nonFutureDate'
  },
  {
    id: 'lastEditedBy',
    label: 'Paskutinis įvedęs / redagavęs asmuo'
  }
];
