export const INITIALIZE_TABLE_STATE = '@providedData/INITIALIZE_TABLE_STATE';
export const SHOW_STATUS_UNSIGNED_TOGGLED = '@providedData/SHOW_STATUS_UNSIGNED_TOGGLED';
export const SHOW_STATUS_SIGNED_TOGGLED = '@providedData/SHOW_STATUS_SIGNED_TOGGLED';
export const SHOW_STATUS_ANNULLED_TOGGLED = '@providedData/SHOW_STATUS_ANNULLED_TOGGLED';
export const CUSTOM_FILTER_VALUE_CHANGED = '@providedData/CUSTOM_FILTER_VALUE_CHANGED';
export const PERIOD_FILTER_VALUE_CHANGED = '@providedData/PERIOD_FILTER_VALUE_CHANGED';
export const SORTING_CHANGED = '@providedData/SORTING_CHANGED';
export const PAGE_SET = '@providedData/PAGE_SET';
export const ROWS_PER_PAGE_SET = '@providedData/ROWS_PER_PAGE_SET';
export const UPDATE_TEMP_DATA_STATE = '@providedData/UPDATE_TEMP_DATA_STATE';
export const CUSTOM_FILTER_RESET = '@providedData/CUSTOM_FILTER_RESET';
