import { makeStyles } from '@material-ui/core';
import React from 'react';

interface Props {
  hideLastColumnColorBar: boolean;
  colors?: { color: string; borderColor: string };
}

const useStyles = makeStyles(() => ({
  colors: ({ color, borderColor }: { color?: string; borderColor?: string }) => ({
    backgroundColor: color ? color : 'inherit',
    borderTop: borderColor ? `1px solid ${borderColor}` : 'none',
    borderBottom: borderColor ? `1px solid ${borderColor}` : 'none',
    left: 0,
    width: '100%'
  })
}));

export const LastColumnColorBar: React.FC<Props> = ({ hideLastColumnColorBar, colors }) => {
  const classes = useStyles({
    color: colors?.color,
    borderColor: colors?.borderColor
  });
  return <>{!hideLastColumnColorBar && <div className={`color-row-bar ${classes.colors}`} />}</>;
};
