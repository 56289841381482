import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import { Form } from 'formik';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../../../../store';
import { CheckboxField } from './FormFields/CheckboxField/CheckboxField';
import { CustomErrorField } from './FormFields/ErrorField/CustomErrorField';
import { CustomFormikField } from './FormFields/PercentageField/CustomFormikField';
import { PercentageFieldContainer } from './FormFields/PercentageField/PercentageFieldContainer';
import {
  ShareholderPercentages,
  ShareholderPerson,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps,
  ShareTypeNames,
  VoteTypeNames
} from '../../../../../../store/shareholders/shareholdersTypes';
import { FormTools } from './FormFields/FormTools';
import { EndOfParticipationField } from './FormFields/EndOfParticipationField';

const useStyles = makeStyles({
  editCardContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: '10px 14px'
  }
});

interface Props {
  formValues: ShareholderPercentages;
  onClose: () => void;
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson;
  shareholderEvent: ShareholdersHistory;
  showEndOfParticipationCheckbox: boolean;
  showParticipateBeforeCheckbox: boolean;
  showStoppedParticipatingBeforeCheckbox: boolean;
  submitForm: () => void;
  setFieldValue: (field: string, value: any) => void;
}

export const AddPercentageForm: React.FC<Props> = ({
  formValues,
  onClose,
  parentShareholder,
  shareholderEvent,
  showEndOfParticipationCheckbox,
  showParticipateBeforeCheckbox,
  showStoppedParticipatingBeforeCheckbox,
  submitForm,
  setFieldValue
}) => {
  const classes = useStyles();

  const {
    shareholdersData: { loadingAddShareholderPercentage, selectedDate, allShareholders }
  } = useSelector((state: ApplicationState) => state);

  const {
    endOfParticipation,
    votePercentage,
    votesOnRules,
    endOfParticipationFromBefore,
    isEventFromJadis,
    participateBeforeSelectedDate,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  } = formValues;

  useEffect(() => {
    setFieldValue('votesValueSelected', !!votePercentage || votesOnRules);
  }, [setFieldValue, votePercentage, votesOnRules]);

  useEffect(() => {
    if (sharePercentageLessThanPermitted) {
      setFieldValue('sharePercentage', '0.01');
      if (votesOnRules) {
        setFieldValue('votesOnRules', false);
      }
    }
    if (votePercentageLessThanPermitted) {
      setFieldValue('votePercentage', '0.01');
      if (votesOnRules) {
        setFieldValue('votesOnRules', false);
      }
    }
  }, [
    setFieldValue,
    votesOnRules,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  ]);

  const { mayHaveSharePercentage, mayHaveVotePercentage, mayHaveVotesOnRules, shareTypeName } =
    parentShareholder;

  return (
    <Form>
      <Box className={classes.editCardContainer}>
        <div>
          {mayHaveSharePercentage && shareTypeName && (
            <>
              <PercentageFieldContainer
                label={
                  parentShareholder.shareTypeName === ShareTypeNames.PART_OF_SHARES
                    ? ShareTypeNames.PART_OF_SHARES
                    : ShareTypeNames.PART_OF_CONTRIBUTIONS
                }
                disabled={endOfParticipationFromBefore || sharePercentageLessThanPermitted}
              >
                <CustomFormikField
                  fieldName="sharePercentage"
                  disabled={endOfParticipationFromBefore || sharePercentageLessThanPermitted}
                />
              </PercentageFieldContainer>
              <CustomErrorField name="sharePercentage" />
            </>
          )}

          {mayHaveVotePercentage && (
            <>
              <PercentageFieldContainer
                label={VoteTypeNames.PART_OF_VOTES}
                disabled={
                  votesOnRules || endOfParticipationFromBefore || votePercentageLessThanPermitted
                }
              >
                <CustomFormikField
                  fieldName="votePercentage"
                  disabled={
                    votesOnRules || endOfParticipationFromBefore || votePercentageLessThanPermitted
                  }
                />
              </PercentageFieldContainer>
              <CustomErrorField name="votePercentage" />
            </>
          )}

          {mayHaveVotesOnRules && (
            <>
              <Tooltip
                title="Pažymima, jei pagal steigimo dokumentus balsų dalis kintanti"
                placement="top"
                arrow
              >
                <div>
                  <CheckboxField
                    name="votesOnRules"
                    type="checkbox"
                    value={VoteTypeNames.VOTES_ON_RULES}
                    disabled={
                      !!votePercentage ||
                      (!mayHaveVotePercentage && mayHaveVotesOnRules) ||
                      endOfParticipationFromBefore ||
                      sharePercentageLessThanPermitted ||
                      votePercentageLessThanPermitted
                    }
                  />
                </div>
              </Tooltip>
              <CustomErrorField name="votesOnRules" />
            </>
          )}

          {showParticipateBeforeCheckbox && (
            <CheckboxField
              disabled={endOfParticipationFromBefore}
              name="participateBeforeSelectedDate"
              type="checkbox"
              value="Dalyvis dalyvauja anksčiau nei"
              currentDate={selectedDate}
            />
          )}

          {showEndOfParticipationCheckbox && allShareholders && (
            <EndOfParticipationField disabled={endOfParticipationFromBefore} />
          )}

          {showStoppedParticipatingBeforeCheckbox && (
            <>
              <CheckboxField
                name="endOfParticipationFromBefore"
                type="checkbox"
                value={'Nedalyvauja iš anksčiau'}
                disabled={endOfParticipation || isEventFromJadis || !participateBeforeSelectedDate}
              />
              <CustomErrorField name="endOfParticipationFromBefore" />
            </>
          )}
          {mayHaveSharePercentage && shareTypeName && (
            <>
              <CheckboxField
                name="sharePercentageLessThanPermitted"
                type="checkbox"
                value={'Akcijų/įnašų dalis mažesnė nei 0,01 %'}
                onControlChange={(e) => {
                  if (!e.target.checked) {
                    setFieldValue('sharePercentage', '');
                  }
                }}
              />
            </>
          )}
          {mayHaveVotePercentage && (
            <>
              <CheckboxField
                name="votePercentageLessThanPermitted"
                type="checkbox"
                value={'Balsų dalis mažesnė nei 0,01 %'}
                onControlChange={(e) => {
                  if (!e.target.checked) {
                    setFieldValue('votePercentage', '');
                  }
                }}
              />
            </>
          )}
        </div>

        <FormTools
          onSubmit={submitForm}
          onClose={onClose}
          disabled={loadingAddShareholderPercentage}
        />
      </Box>
    </Form>
  );
};
