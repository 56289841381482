import { DropdownStateGeneric, DatePickerState } from '../../../../../utils/tableTypes';
import { CategoryNewRowState, CategoryEditRowState } from './categoryInitialStateAndTypes';
import { OutletCategoryTypeShort } from '../../../../../store/classifiers/classifiersTypes';
import {
  validateMandatoryDate,
  validateSingleChoice
} from '../../../../../utils/InputValueFunctions';

export function validateNewCategoryState(state: CategoryNewRowState): CategoryNewRowState {
  const categoryValidated: DropdownStateGeneric<OutletCategoryTypeShort> = validateSingleChoice(
    state.categoryType,
    'Reikia pasirinkti iš sąrašo'
  );
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  return {
    ...state,
    categoryType: categoryValidated,
    validFrom: validFromValidated,
    createRecordConfirmationOn: categoryValidated.validated && validFromValidated.validated
  };
}

export function validateEditCategoryState(state: CategoryEditRowState): CategoryEditRowState {
  const categoryValidated: DropdownStateGeneric<OutletCategoryTypeShort> = validateSingleChoice(
    state.categoryType,
    'Reikia pasirinkti iš sąrašo'
  );
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  return {
    ...state,
    categoryType: categoryValidated,
    validFrom: validFromValidated,
    updateRecordConfirmationOn: categoryValidated.validated && validFromValidated.validated
  };
}
