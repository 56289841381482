import React from 'react';
import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from './Dialog/CloseButton';
import virsisTheme from '../../style/virsisTheme';
import { CloseContinueButton } from './Dialog/CloseContinueButton';
import { Strings } from '../../utils/strings/Strings';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onContinue?: () => void;
  closeButtonText?: string;
  mainButtonText?: string;
  dialogText: string;
  showCloseButton?: boolean;
}

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 35px 10px 35px'
  },
  divider: {
    marginRight: 35,
    marginLeft: 35
  },
  contentWrap: {
    padding: '6px 35px 24px 35px'
  },
  contentText: {
    color: virsisTheme.palette.text.primary,
    fontSize: '1rem'
  },
  actions: {
    minWidth: 250,
    padding: '10px 34px',
    height: 50,
    backgroundColor: '#F3F3F3'
  },
  close: {
    backgroundColor: 'transparent',
    color: virsisTheme.palette.error.main,
    borderColor: virsisTheme.palette.error.main,
    minWidth: '124px'
  }
}));

export const AlertDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onContinue,
  closeButtonText,
  mainButtonText,
  dialogText,
  showCloseButton
}) => {
  const classes = useStyles();
  return (
    <Dialog maxWidth="md" open={isOpen} onClose={onClose}>
      {isOpen && (
        <>
          <div className={classes.title}>
            <Typography variant="h2">Perspėjimas</Typography>
            <CustomCloseIconButton onClick={onClose} />
          </div>

          <Divider className={classes.divider} />

          <DialogContent className={classes.contentWrap}>
            <Typography className={classes.contentText}>{dialogText}</Typography>
          </DialogContent>

          <DialogActions className={classes.actions}>
            {showCloseButton && <CloseDialogButton label={closeButtonText} onClick={onClose} />}
            {onContinue && <CloseContinueButton onClick={onContinue} label={mainButtonText} />}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  mainButtonText: 'Gerai',
  closeButtonText: Strings.button__cancel,
  showCloseButton: true
};
