import moment from 'moment';
import {
  setRepresentativeNotesAndValidate,
  validateRepresentativeNewRecordState
} from './rowReducerFunctions';
import {
  RepresentativeNewRowState,
  RepresentativeNewRowAction,
  initialRepresentativeNewRowState
} from './rowStateAndTypes';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../../utils/InputValueFunctions';

export const newRepresentativeReducer = (
  state: RepresentativeNewRowState,
  action: RepresentativeNewRowAction
): RepresentativeNewRowState => {
  switch (action.type) {
    case 'NEW_RECORD_INITIALIZED':
      return {
        ...initialRepresentativeNewRowState,
        validFrom: {
          ...state.validFrom,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.outletControlledFrom),
              text: 'Pradžios data negali būti ankstesnė už VIP valdymo pradžią'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.outletControlledTo !== null ? moment(action.outletControlledTo) : null,
              text: 'Pradžios data negali būti vėlesnė už VIP valdymo pabaigą'
            },
            {
              id: 'validTo',
              date: null,
              text: 'Pradžios data negali būti vėlesnė už pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.outletControlledFrom),
              text: 'Pradžios data negali būti ankstesnė už VIP valdymo pradžią'
            },
            {
              id: 'validFrom',
              date: null,
              text: 'Pabaigos data negali būti ankstesnė už pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.outletControlledTo !== null ? moment(action.outletControlledTo) : null,
              text: 'Pabaigos data negali būti vėlesnė už VIP valdymo pabaigą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'CREATING_CANCELLED':
      return initialRepresentativeNewRowState;
    case 'REPRESENTATION_FROM_DATE_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'REPRESENTATION_TO_DATE_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'REPRESENTATION_NOTES_CHANGED':
      return {
        ...state,
        representationNotes: setRepresentativeNotesAndValidate(
          action.representationNotes,
          state.representationNotes
        )
      };
    case 'CREATE_RECORD_CLICKED':
      return validateRepresentativeNewRecordState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    default:
      return state;
  }
};
