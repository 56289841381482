import React, { useContext, useState, useReducer } from 'react';
import { makeStyles, TableCell, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { EditionCheckDataInst } from '../../../store/editionChecks/editionChecksTypes';
import { EditionCheckDataTableStateContext } from '../Context';
import PreviewAndSignDocumentDialog from '../../document/PreviewAndSignDocumentDialog';
import { initialEditionCheckEditRecordState } from './editionCheckRecordState/initialStateAndTypes';
import { editEditionCheckRecordReducer } from './editionCheckRecordState/editEditionCheckRecordReducer';
import {
  annulEditionCheck,
  fetchEditionCheckData,
  getEditionCheckDataVirsDocRequest,
  removeEditionCheck,
  resetAnnulEditionCheckState,
  resetEditionCheckRemovingState
} from '../../../store/editionChecks/editionChecksActions';
import { ApplicationState } from '../../../store';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import { EditionCheckDataTableEditRecord } from './EditionCheckDataTableEditRecord';
import { SignDocumentButton } from '../../../components/TableButtons/RowActionButtons/SignDocumentButton';
import BtnOpenNewDoc from '../../../components/TableButtons/BtnOpenNewDoc';
import AllowedToAllExcept from '../../AllowedToAllExcept';
import { ViewDocumentButton } from '../../../components/TableButtons/RowActionButtons/ViewDocumentButton';
import { AnnulDocumentButton } from '../../../components/TableButtons/RowActionButtons/AnnulDocumentButton';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import { AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import {
  fetchMissingPeriods,
  resetMissingPeriods
} from '../../../store/outletInfoData/outletInfoDataActions';
import { AuthorisedEditAndRemoveActions } from '../../../components/TableRowActions/AuthorisedEditAndRemoveActions';
import { OutletsAsLinksList } from '../../../components/TableLinks/OutletsAsLinksList';

interface EditionCheckDataTableRecordProps {
  record: EditionCheckDataInst;
}

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

export const EditionCheckDataTableRecord: React.FC<EditionCheckDataTableRecordProps> = ({
  record
}) => {
  const classes = useStyles();
  const [rowState, rowDispatch] = useReducer(
    editEditionCheckRecordReducer,
    initialEditionCheckEditRecordState
  );

  const {
    editionData: {
      editionCheckConclusionTypes,
      removingEdition,
      editionRemoved,
      removingEditionError,
      annullingEditionCheckError,
      annullingEditionCheckRecord
    },
    classifiers: { annulmentTypes }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleDialogClose = () => setOpenDialog(false);

  const totalRowSpan = () => {
    switch (rowState.editingOn) {
      case true:
        return rowState.editionCheckOutlets
          .map((outlet) => outlet.editionCheckConclusions.length)
          .reduce((accumulator, currentValue) => accumulator + currentValue);
      case false:
        return record.editionCheckOutlets
          .map((outlet) => outlet.editionCheckConclusions.length)
          .reduce((accumulator, currentValue) => accumulator + currentValue);
      default:
        return 0;
    }
  };

  const turnOnEditing = () => {
    reduxDispatch(resetMissingPeriods());
    record.editionCheckOutlets.forEach((outlet) => {
      reduxDispatch(fetchMissingPeriods(outlet.outletId));
    });
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      editionCheckConclusionTypes
    });
  };

  const onRemoveRecordClicked = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  };

  const removeRecord = () => reduxDispatch(removeEditionCheck(record.editionCheckId));

  const closeRemoveConfirmation = () =>
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });

  const closeOnRemoveError = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetEditionCheckRemovingState());
  };

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || []
    });
  }

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulEditionCheckState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    reduxDispatch(annulEditionCheck(record.documentStatusId, annulRecord));
    closeAnnulDocumentDialog();
  }

  const openDocumentInNewTab = (docId: number) => {
    reduxDispatch(getEditionCheckDataVirsDocRequest(docId));
  };

  const specificKmRoles = [
    Roles.ROLE_REPORT_A11,
    Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT,
    Roles.ROLE_KM_TIRAZO_TIKRINIMAS_VIEW
  ];

  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  record.editionCheckOutlets.forEach((outlet) => {
    outlet.editionCheckConclusions.forEach((conclusion, conclusionIndex) => {
      rows.push(
        <RowStyleOnDifferentStatus status={record.documentStatus} key={rowIndex}>
          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.virsName && (
                <TooltipOnRowRibbon status={record.documentStatus}>
                  <TableCell align="left" rowSpan={totalRowSpan()}>
                    <AllowedTo roles={specificKmRoles}>
                      <VirsNameLink
                        virsName={record.virsName}
                        virsLegalCode={record.virsLegalCode}
                        virsId={record.virsId}
                      />
                    </AllowedTo>
                    <AllowedToAllExcept roles={specificKmRoles}>
                      <Typography>{record.virsName}</Typography>
                      <Typography style={{ paddingTop: '5px' }}>{record.virsLegalCode}</Typography>
                    </AllowedToAllExcept>
                  </TableCell>
                </TooltipOnRowRibbon>
              )}
              {tableState.columnsDisplayStatus.checkDate && (
                <TableCell align="left" rowSpan={totalRowSpan()} className={classes.dateColumn}>
                  <div>{record.checkDate}</div>
                </TableCell>
              )}
              {tableState.columnsDisplayStatus.docNr && (
                <TableCell align="left" rowSpan={totalRowSpan()}>
                  {record.editionCheckDocs.map((doc) => (
                    <div key={doc.docId}>
                      <BtnOpenNewDoc
                        onClicked={() => openDocumentInNewTab(doc.docId)}
                        text={doc.docNr}
                      />
                    </div>
                  ))}
                </TableCell>
              )}
            </>
          )}
          {conclusionIndex === 0 && tableState.columnsDisplayStatus.outletName && (
            <TableCell align="left" rowSpan={outlet.editionCheckConclusions.length}>
              <AllowedTo roles={specificKmRoles}>
                <OutletsAsLinksList outlets={[outlet]} virsId={record.virsId} />
              </AllowedTo>
              <AllowedToAllExcept roles={specificKmRoles}>
                <>{outlet.outletName}</>
              </AllowedToAllExcept>
            </TableCell>
          )}
          {tableState.columnsDisplayStatus.periodName && (
            <TableCell align="left">{conclusion.periodName}</TableCell>
          )}
          {tableState.columnsDisplayStatus.conclusion && (
            <TableCell align="left">{conclusion.conclusion}</TableCell>
          )}

          {rowIndex === 0 && (
            <>
              <TableCell align="left" rowSpan={totalRowSpan()}>
                {record.documentStatus === 'RUOSIAMAS' && record.removable && (
                  <AuthorisedEditAndRemoveActions
                    handleEditButtonClick={turnOnEditing}
                    handleRemoveButtonClick={onRemoveRecordClicked}
                    confirmationOn={rowState.removeRecordConfirmationOn}
                    handleConfirmationYes={removeRecord}
                    handleConfirmationNo={closeRemoveConfirmation}
                    isProcessing={removingEdition}
                    isSuccess={editionRemoved}
                    error={removingEditionError}
                    onErrorClose={closeOnRemoveError}
                    recordCreatedByCode={record.checkedByCode}
                    editingWarningMessage={record.editingWarningMessage}
                  />
                )}
              </TableCell>

              <TableCell align="right" rowSpan={totalRowSpan()}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {record.documentStatus === 'RUOSIAMAS' && (
                    <SignDocumentButton onClick={() => setOpenDialog(true)} />
                  )}
                  {record.documentStatus === 'PASIRASYTAS' && (
                    <>
                      <ViewDocumentButton onClick={() => setOpenDialog(true)} />
                      <AnnulDocumentButton
                        annulmentDisablementReason={record.annulmentDisablementReason}
                        onClick={openAnnulDocumentDialog}
                      />
                    </>
                  )}
                </div>
              </TableCell>
            </>
          )}
        </RowStyleOnDifferentStatus>
      );
      rowIndex++;
    });
  });

  return (
    <>
      {!rowState.editingOn && rows}
      {rowState.editingOn && (
        <EditionCheckDataTableEditRecord
          rowState={rowState}
          totalRowSpan={totalRowSpan()}
          record={record}
          rowDispatch={rowDispatch}
        />
      )}

      <PreviewAndSignDocumentDialog
        openDialog={openDialog}
        documentPath={`edition-check/document/${record.documentStatusId}`}
        documentId={record.documentStatusId}
        documentStatus={record.documentStatus}
        onClose={handleDialogClose}
        onSignSuccess={() => reduxDispatch(fetchEditionCheckData())}
      />

      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingEditionCheckError}
        isProcessing={annullingEditionCheckRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingEditionCheckError}
      />
    </>
  );
};
