import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    borderRadius: 2,
    height: 13,
    margin: '5px 5px 4px 4px',
    width: 13,
    border: `1px solid ${theme.palette.primary.main}`,
    'input:disabled ~ &': {
      border: `1px solid ${theme.palette.info.main}`
    }
  }
}));

interface Props {
  setCheckboxState: (checkboxState: boolean) => void;
  checked: boolean;
  disabled?: boolean;
}

export const CustomCheckboxButton: React.FC<Props> = ({ setCheckboxState, checked, disabled }) => {
  const classes = useStyles();

  return (
    <Checkbox
      checked={checked}
      disabled={disabled}
      color="primary"
      onChange={(event) => {
        setCheckboxState(event.target.checked);
      }}
      icon={<span className={classes.icon} />}
    />
  );
};
