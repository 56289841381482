import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';

import { useParams } from 'react-router';
import { PaymentTableCustomizationBar } from './CustomizationBar';
import PaymentTableHead from './TableHead';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { ApplicationState } from '../../store';
import { usePaymentTableState, usePaymentTableDispatch } from './Context';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import PaymentTableFilterRow from './FilterRow';
import { Roles } from '../../store/virsis/dataTypes';
import { PaymentRowContainerNew } from './PaymentTableRows/PaymentRowContainerNew';
import PaymentRowContainer from './PaymentTableRows/PaymentRowContainer';
import AllowedTo from '../AllowedTo';
import { columnNames } from './TableColumns';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { InstDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';
import { unsignedDataRemoveInst } from '../../store/unsignedDataRemoval/unsignedDataActions';
import {
  initializePaymentsDataTableState,
  setPage,
  setRowsPerPage
} from '../../store/paymentsTable/paymentsTableActions';
import { TableLinearProgressRow } from '../../components/TableLinearProgressRow/TableLinearProgressRow';
import {
  getPaymentDataByDocIdRequest,
  getPaymentDataRequest
} from '../../store/payments/paymentsActions';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { AlertBox } from '../../components/Alert/AlertBox';

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      tableBox: {
        flexWrap: 'wrap'
      },
      addButton: {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0,
        paddingLeft: 0
      },
      pageActionButtonBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  })
);

export const PaymentTable: React.FC = () => {
  const reduxDispatch = useDispatch();

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);
  const { documentStatusId, companyCode } = useParams<{
    documentStatusId?: string;
    companyCode?: string;
  }>();

  const { state: tableState } = usePaymentTableState();
  const { dispatch: tableDispatch } = usePaymentTableDispatch();
  const {
    paymentData: {
      loadingPaymentData,
      isPaymentUpdated,
      isPaymentClosed,
      paymentData: allData,
      paymentDataError,
      recordCount,
      statusRelevantFilterEnabled,
      statusOutdatedFilterEnabled,
      statusNotSignedFilterEnabled,
      annullingPaymentSuccess
    },
    paymentTableData: { tableDataState, tempTableDataState }
  } = useSelector((state: ApplicationState) => state);

  const [showDocumentAnulledAlert, setShowDocumentAnulledAlert] = useState(false);

  const classes = useStyles();

  const handleSetPage = (value: number) => {
    reduxDispatch(setPage(value));
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    reduxDispatch(setRowsPerPage(rowsPerPage));
  };

  function showNewRecordRow() {
    tableDispatch({
      type: 'CREATE_RECORD_CLICKED',
      companyCode: null
    });
  }

  const filteredData = allData || [];

  useEffect(() => {
    reduxDispatch(
      initializePaymentsDataTableState(
        documentStatusId ? false : statusNotSignedFilterEnabled,
        documentStatusId ? false : statusRelevantFilterEnabled,
        documentStatusId ? false : statusOutdatedFilterEnabled,
        documentStatusId ? false : statusNotSignedFilterEnabled,
        documentStatusId ? false : statusRelevantFilterEnabled,
        !statusNotSignedFilterEnabled && !statusRelevantFilterEnabled && statusOutdatedFilterEnabled
      )
    );
  }, [
    reduxDispatch,
    statusNotSignedFilterEnabled,
    statusRelevantFilterEnabled,
    statusOutdatedFilterEnabled,
    documentStatusId
  ]);

  useEffect(() => {
    if (documentStatusId) {
      reduxDispatch(getPaymentDataByDocIdRequest(Number(documentStatusId)));
      return;
    }
    if (
      JSON.stringify(tableDataState) !== JSON.stringify(tempTableDataState) &&
      !documentStatusId
    ) {
      reduxDispatch(getPaymentDataRequest());
    }
  }, [documentStatusId, reduxDispatch, tableDataState, tempTableDataState]);

  useEffect(() => {
    if (companyCode) {
      tableDispatch({
        type: 'CREATE_RECORD_CLICKED',
        companyCode
      });
    }
  }, [companyCode, tableDispatch]);

  useEffect(() => {
    if (annullingPaymentSuccess) {
      setShowDocumentAnulledAlert(true);
    }
  }, [annullingPaymentSuccess]);

  return (
    <>
      <Container maxWidth="xl">
        <AllowedTo roles={[Roles.ROLE_KM_METINE_IMOKA_EDIT]}>
          <div className="data-page-header">
            <Typography variant="h1">Duomenų teikimas</Typography>
            <Typography style={{ margin: '10px 0' }} variant="subtitle1">
              Metinės įmokos duomenys
            </Typography>
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            className={classes.tableBox}
          >
            <AddNewRecordButton
              text="Pridėti metinės įmokos įrašą"
              onClicked={showNewRecordRow}
              disabled={!!paymentDataError || tableState.showNewRecord || !!documentStatusId}
              className={classes.addButton}
            />
            <Box display="flex" justifyContent="flex-end" className={classes.pageActionButtonBox}>
              <PageActionButtons
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    filteredData,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, filteredData, navigationPath)
                }
                onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
              />
            </Box>
          </Box>
          <ConfirmRemoveUnsignedDialog
            onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
            onConfirm={() => reduxDispatch(unsignedDataRemoveInst(InstDocumentType.ANNUAL_PAYMENT))}
            onRemoveSuccess={() => reduxDispatch(getPaymentDataRequest())}
            open={confirmToDeleteUnsignedDialogOpen}
          />
        </AllowedTo>
      </Container>

      {showDocumentAnulledAlert && (
        <AlertBox
          handleClose={() => setShowDocumentAnulledAlert(false)}
          text={
            'Įrašas sėkmingai anuliuotas. Anuliuotus dokumentus galite peržiūrėti pasirinkę meniu „Duomenų istorija“.'
          }
        />
      )}
      <Container maxWidth="xl" style={{ marginTop: '1rem' }}>
        <Paper elevation={0}>
          <PaymentTableCustomizationBar
            columnNames={columnNames}
            disabled={tableState.showNewRecord || !!documentStatusId}
          />

          <ScrollXContainer>
            <Table>
              <PaymentTableHead />

              {tableState.customFilterOn && <PaymentTableFilterRow columnNames={columnNames} />}

              <TableBody>
                <TableLinearProgressRow isLoading={loadingPaymentData} colSpan={9} />

                <TableErrorRow
                  error={paymentDataError && 'Klaida. Nepavyko gauti įmokos duomenų'}
                  colSpan={9}
                />

                {!loadingPaymentData && !paymentDataError && tableState.showNewRecord && (
                  <PaymentRowContainerNew />
                )}

                {!paymentDataError &&
                  filteredData &&
                  !isPaymentUpdated &&
                  !isPaymentClosed &&
                  filteredData.map((record) => (
                    <PaymentRowContainer key={record.paymentId} record={record} />
                  ))}
              </TableBody>
            </Table>
          </ScrollXContainer>

          <TablePagination
            recordsCount={recordCount}
            pagesCount={Math.ceil(recordCount / tableDataState.rowsPerPage || 0)}
            rowsPerPage={tableDataState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableDataState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={allData?.length === 0 || recordCount === 0}
          />
        </Paper>
      </Container>
    </>
  );
};
