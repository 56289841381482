import { action } from 'typesafe-actions';
import {
  CUSTOM_FILTER_RESET,
  CUSTOM_FILTER_VALUE_CHANGED,
  INITIALIZE_TABLE_STATE,
  PAGE_SET,
  PERIOD_FILTER_VALUE_CHANGED,
  ROWS_PER_PAGE_SET,
  SHOW_STATUS_ANNULLED_TOGGLED,
  SHOW_STATUS_SIGNED_TOGGLED,
  SHOW_STATUS_UNSIGNED_TOGGLED,
  SORTING_CHANGED,
  UPDATE_TEMP_DATA_STATE
} from './providedDataTableActionTypes';
import { ProvidedDataHistoryHeaderField } from '../../containers/ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';
import { ProvidedDataHistoryDataState } from './providedDataTableTypes';
import { Period } from '../../containers/ProvidedDataTableLegal/tableState/tableInitialStateAndTypes';

export const initializeProvidedDataTableState = (
  statusNotSignedFilterEnabled: boolean,
  statusSigneFilterEnabled: boolean,
  statusAnnuledFilterEnabled: boolean,
  showStatusNotSigned: boolean,
  showStatusSigned: boolean,
  showStatusAnnuled: boolean,
  periodFilter: Period
) =>
  action(INITIALIZE_TABLE_STATE, {
    statusNotSignedFilterEnabled,
    statusSigneFilterEnabled,
    statusAnnuledFilterEnabled,
    showStatusNotSigned,
    showStatusSigned,
    showStatusAnnuled,
    periodFilter
  });
export const showStatusUnsignedToggled = () => action(SHOW_STATUS_UNSIGNED_TOGGLED);
export const showStatusSignedToggle = () => action(SHOW_STATUS_SIGNED_TOGGLED);
export const showStatusAnulledToggle = () => action(SHOW_STATUS_ANNULLED_TOGGLED);
export const customFilterReset = () => action(CUSTOM_FILTER_RESET);
export const customFilterValueChanged = (
  filterBy: ProvidedDataHistoryHeaderField,
  value: string | null
) => action(CUSTOM_FILTER_VALUE_CHANGED, { filterBy, value });
export const periodFilterChanged = (period: Period) => action(PERIOD_FILTER_VALUE_CHANGED, period);
export const sortingChanged = (sortBy: ProvidedDataHistoryHeaderField) =>
  action(SORTING_CHANGED, sortBy);
export const setPage = (pageNr: number) => action(PAGE_SET, pageNr);
export const setRowsPerPage = (rowsPerPage: number) => action(ROWS_PER_PAGE_SET, rowsPerPage);
export const updateTempDataState = (tableState: ProvidedDataHistoryDataState) =>
  action(UPDATE_TEMP_DATA_STATE, tableState);
