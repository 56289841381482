import React, { useEffect } from 'react';
import { TableBody } from '@material-ui/core';
import { ActivityPeriod, ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../ShareholdersTableWithContext';
import { TableRowsContainer } from '../TableRows/TableRowsContainer';
import { getYearsArray } from '../utilities/functionsWithDateFormat';
import FilteredViewTableHeader from './FilteredViewTableHeader';

interface Props {
  allEventsDates: string[];
  allFilteredDays: string[];
  sortedShareholderData: ShareholdersData;
  openCalendar: () => void;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  loadingShareholdersData: boolean;
  activityPeriods: ActivityPeriod[];
}

const TableByDay: React.FC<Props> = ({
  allEventsDates,
  allFilteredDays,
  sortedShareholderData,
  openCalendar,
  noValidDates,
  allShareholdersFromJADIS,
  activityPeriods,
  loadingShareholdersData
}) => {
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const {
    state: { datesInPerdiodWithEvents }
  } = useShareholdersTableState();

  const yearsArray = getYearsArray(sortedShareholderData.virsStartDate);
  useEffect(() => {
    tableDispatch({
      type: 'INITIAL_HEADER_DATES_BY_DAY',
      allEventsDates: allEventsDates,
      initialDates: allFilteredDays
    });
  }, [allFilteredDays.length]);

  const onlyDates = datesInPerdiodWithEvents.map((x) => x.date);

  return (
    <>
      <FilteredViewTableHeader
        openCalendar={openCalendar}
        allEventsDates={allEventsDates}
        yearsArray={yearsArray}
        shareholdersData={sortedShareholderData}
        noValidDates={noValidDates}
        allShareholdersFromJADIS={allShareholdersFromJADIS}
        loadingShareholdersData={loadingShareholdersData}
        datesInPerdiodWithEvents={datesInPerdiodWithEvents}
      />

      <TableBody>
        <TableRowsContainer data={sortedShareholderData} allEventsDates={onlyDates} />
      </TableBody>
    </>
  );
};

export default TableByDay;
