import React from 'react';
import { Box, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import LabelTextField from '../TextField/LabelTextField';
import LabelFieldLink from '../LinkField/LabelFieldLink';
import { openEditContactEmailDialog } from '../../store/virsis/actions';
import { Roles, VirsData } from '../../store/virsis/dataTypes';
import AllowedTo from '../../containers/AllowedTo';
import { ApplicationState } from '../../store';

export const useAcordianStyles = makeStyles((theme) => ({
  infoRow: {
    padding: '5px 0',
    '&:not(:last-child)': {
      borderBottom: '1px solid #e5e5e5'
    }
  },
  boxPadding: {
    padding: 15
  },
  accordionBox: {
    '&.MuiAccordion-root.Mui-expanded:before': {
      backgroundColor: '#aeaeae',
      opacity: 1
    },
    '&.MuiAccordion-root:before': {
      top: '70px'
    },
    borderRadius: '10px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden'
  },
  overflow: {
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  accordionTitleWrapper: {
    '&:hover': {
      backgroundColor: '#F5F8FF',
      transition: 'background-color .3s'
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '70px',
      padding: '0px 25px'
    },
    justifyContent: 'flex-start',
    padding: '0px 25px',
    minHeight: '70px'
  },
  accordionTitle: {
    fontSize: '1rem',
    color: '#5A5A5A',
    fontWeight: 800,
    fontFamily: 'Open Sans'
  },
  icon: {
    width: 17,
    height: 17,
    margin: 'auto 0',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  classChangeGrid: {
    width: '170px'
  },
  classChangeGrid2: {
    width: '250px'
  }
}));

interface Props {
  virsData: VirsData;
}

export const VirsDataContainer: React.FC<Props> = ({ virsData }) => {
  const classes = useAcordianStyles();
  const dispatch = useDispatch();
  const {
    virsis: { selectedVirsContactEmail, getContactEmailLoading }
  } = useSelector((state: ApplicationState) => state);

  const handleContactEmailEdit = () => {
    dispatch(openEditContactEmailDialog(true));
  };

  const getDateType = () => {
    let type = 'VIRSIS būsenos data';
    if (virsData.systemRegistrationState.includes('Užregistruotas')) {
      type = 'VIRSIS užregistravimo data';
    }
    if (virsData.systemRegistrationState.includes('Sukurtas')) {
      type = 'VIRSIS sukūrimo data';
    }
    if (virsData.systemRegistrationState.includes('Išregistruotas')) {
      type = 'VIRSIS išregistravimo data';
    }
    if (virsData.systemRegistrationState.includes('Neaktyvus')) {
      type = 'VIRSIS neaktyvumo data';
    }
    return type;
  };

  const getContactEmail = () => {
    return (!getContactEmailLoading && selectedVirsContactEmail) || '';
  };

  return (
    <>
      <Grid container className={classes.overflow}>
        <Grid item xs={12}>
          <Grid container>
            <Grid key={0} item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.boxPadding}>
                    <LabelTextField
                      label="Pavadinimas"
                      value={virsData.name}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Įmonės kodas"
                      value={virsData.companyCode}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Vadovas"
                      value={virsData.ceoPersonFullName || ''}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Adresas"
                      value={virsData.address}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Tel.nr."
                      value={virsData.contactNumber}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                  </Box>

                  <Box className={classes.boxPadding}>
                    <LabelTextField
                      label="Teisinė forma"
                      value={virsData.legalType}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Teisinis statusas "
                      value={virsData.legalState}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Įregistravimo data"
                      value={virsData.legalRegistrationDate}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                    <LabelTextField
                      label="Išregistravimo data"
                      value={virsData.legalDeregistrationDate}
                      className={classes.infoRow}
                      customGrid={4}
                      customGrid2={8}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid key={2} item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.boxPadding}>
                    <LabelTextField
                      label="VIRSIS būsena"
                      value={virsData.systemRegistrationState}
                      className={classes.infoRow}
                      customGrid={6}
                      customGrid2={6}
                    />
                    <LabelTextField
                      label={getDateType()}
                      value={virsData.systemRegistrationDate}
                      className={classes.infoRow}
                      customGrid={6}
                      customGrid2={6}
                    />
                    <LabelTextField
                      label="Paskutiniai duomenys pateikti"
                      value={virsData.recentProvidedDataDate}
                      className={classes.infoRow}
                      customGrid={6}
                      customGrid2={6}
                    />

                    <LabelFieldLink
                      className={classes.infoRow}
                      customGrid={6}
                      customGrid2={6}
                      label="Kontaktinis VIRSIS el.paštas"
                      value={getContactEmail()}
                      linkName={
                        <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                          <EditIcon className={classes.icon} color="action" />
                        </AllowedTo>
                      }
                      onClick={handleContactEmailEdit}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
