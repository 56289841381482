export interface LegalFormInput {
  legalFormId: number | null;
  legalFormCode: number | null;
  shareTypeId: number | null;
  isConfiguredByVirs: boolean;
  hasVotePercentage: boolean;
  hasVotesOnRules: boolean;
  providesVirsData: boolean;
  isFromJadis: boolean;
  isShareholderRequired: boolean;
  isNaturalPersonRequired: boolean;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: LegalFormInput = {
  legalFormId: null,
  legalFormCode: null,
  shareTypeId: null,
  isConfiguredByVirs: false,
  hasVotePercentage: false,
  hasVotesOnRules: false,
  providesVirsData: false,
  isFromJadis: false,
  isShareholderRequired: false,
  isNaturalPersonRequired: false,
  validFrom: null,
  validTo: null
};

export interface LegalForm {
  legalFormId: number;
  legalFormCode: number;
  legalFormName: string;
  shareTypeId: number;
  shareTypeName: string;
  isConfiguredByVirs: boolean;
  hasVotePercentage: boolean;
  hasVotesOnRules: boolean;
  providesVirsData: boolean;
  isFromJadis: boolean;
  isShareholderRequired: boolean;
  isNaturalPersonRequired: boolean;
  validFrom: string;
  validTo: string;
}

export interface LegalFormOptions {
  legalForms: { legalFormCode: number; legalFormName: string }[];
  shareTypes: { shareTypeId: number; shareTypeName: string }[];
}
