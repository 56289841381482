import {
  ProvidedDataTableRecordActions,
  ProvidedDataTableRecordState
} from './providedDataRecordInitialStateAndTypes';

export const providedDataRecordReducer = (
  state: ProvidedDataTableRecordState,
  action: ProvidedDataTableRecordActions
): ProvidedDataTableRecordState => {
  switch (action.type) {
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },

        annulRecordConfirmationOn: true
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        annulRecordConfirmationOn: false
      };
    default:
      return state;
  }
};
