import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShareholderNameCellButtons } from '../../../../components/ShareholderComponents/ShareholderNameCellButtons';
import { LevelBar } from '../../../../components/ShareholderComponents/TableButtons/LevelBar';
import { TriangleButton } from '../../../../components/ShareholderComponents/TableButtons/TriangleButton';

import { ApplicationState } from '../../../../store';
import { setExpandShareholder } from '../../../../store/shareholders/shareholdersActions';
import { ShareholdersData } from '../../../../store/shareholders/shareholdersTypes';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { virsColor } from '../../constants';
import {
  currentDate,
  filterErrorObjs,
  getActivityPeriod,
  getMinimumDate
} from '../../utilityFunctions/sharedFunctions';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import { getIsLastShareholder } from '../../SelectedDateTable/utilities/utilityFunctions';
import { LastColumnColorBar } from '../Components/LastColumnColorBar';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from '../ShareholdersTableWithContext';
import { findVirsStartDateToDrawColorBar } from '../tableState/tableFunctions';
import ShareholdersTree from './ShareholdersTree';
import { ColorBarVirsByDay } from '../Components/ColorBarVirsByday';
import theme from '../../../../style/virsisTheme';

interface Props {
  data: ShareholdersData;
  allEventsDates: string[];
  filteredShareholderLevel?: string;
}

const useStyles = makeStyles({
  inlineBlock: {
    display: 'inline-block'
  }
});

export const TableRowsContainer: React.FC<Props> = ({
  data,
  allEventsDates,
  filteredShareholderLevel
}) => {
  const classes = useStyles();
  const {
    state: {
      datesInPerdiodWithEvents,
      tableId,
      customFilter: { shareholderPeriodDateFrom, shareholderPeriodDateTo }
    }
  } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const { expandedShareholders } = useSelector((state: ApplicationState) => state.shareholdersData);
  const reduxDispatch = useDispatch();

  const [periodFilterToDate] = shareholderPeriodDateTo;
  const [periodFilterFromDate] = shareholderPeriodDateFrom;

  const {
    errors,
    mayHaveVotesOnRules,
    shareholders,
    shareTypeName,
    virsEndDate,
    virsName,
    virsShareholderId,
    virsStoppedParticipatingFromDate,
    mayHaveSharePercentage,
    mayHaveVotePercentage,
    virsStartDate,
    activityPeriods
  } = data;

  const virsIdString = String(virsShareholderId);

  const showChildShareholder = useMemo(
    () => expandedShareholders[virsIdString],
    [expandedShareholders, virsIdString]
  );

  const handleShowChildShareholder = () => {
    reduxDispatch(setExpandShareholder(virsIdString));

    if (filteredShareholderLevel) {
      tableDispatch({ type: 'SET_DEFAULT_LEVEL_VALUE', defaultFilterLevelValue: true });
      tableDispatch({
        type: 'CUSTOM_FILTER_VALUE_CHANGED',
        filterBy: 'shareholderLevel',
        value: null
      });
    }
  };

  const minimumDate = getMinimumDate(data);
  const criticalErrorMessages = filterErrorObjs(errors, ErrorType.CRITICAL);
  const nonCriticalErrorMessages = filterErrorObjs(errors, ErrorType.NONCRITICAL);
  const infoNotifications = filterErrorObjs(errors, ErrorType.INFO);

  const showTriangleButton = !!shareholders.length;

  const hideLastColumnColorBar = !!(
    virsEndDate ||
    (periodFilterToDate ? !allEventsDates.includes(currentDate) : false) ||
    (periodFilterToDate
      ? !datesInPerdiodWithEvents.map((x) => x.date).includes(currentDate)
      : false) ||
    (tableId === 'day' && !(!!periodFilterFromDate && !!periodFilterToDate))
  );

  return (
    <>
      {data && (
        <>
          <TableRow className="shareholders-table-row">
            <TableCell className="sticky-col first-col">
              <div className="fake-cell level-cell">0</div>
            </TableCell>
            <TableCell className="shareholders-column sticky-col second-col">
              <div className="fake-cell first-shareholder">
                {showTriangleButton && (
                  <LevelBar level={0} virs halfBottom showChildShareholder={showChildShareholder} />
                )}
                <div className="flex-container">
                  <TriangleButton
                    showTriangleButton={!!shareholders.length}
                    expandChild={showChildShareholder}
                    onClicked={handleShowChildShareholder}
                    marginLeft={getMarginLeft(0)}
                  />
                  <Typography variant="h5" className={classes.inlineBlock}>
                    {virsName}
                  </Typography>
                </div>

                <ShareholderNameCellButtons
                  index={0}
                  shareholder={data}
                  mayShowErrorIcons
                  criticalErrorMessages={criticalErrorMessages}
                  nonCriticalErrorMessages={nonCriticalErrorMessages}
                  infoNotifications={infoNotifications}
                />
              </div>
            </TableCell>

            {allEventsDates.map((date, index) => {
              const { startDate, currentActivityPeriod } = getActivityPeriod(
                date,
                activityPeriods,
                virsStartDate,
                tableId
              );
              return (
                <TableCell key={date + '__' + index} className="data-column">
                  <ColorBarVirsByDay
                    date={date}
                    startDate={findVirsStartDateToDrawColorBar(
                      tableId,
                      minimumDate < startDate ? startDate : minimumDate,
                      date
                    )}
                    endDate={
                      currentActivityPeriod && currentActivityPeriod?.endDate == null
                        ? null
                        : currentActivityPeriod?.endDate || null
                    }
                    fillWithColor={virsColor}
                    borderColor={virsColor}
                    textColor={theme.palette.secondary.light}
                    value="VIRS"
                    allEventsDates={allEventsDates}
                  />
                </TableCell>
              );
            })}

            <TableCell className="last-column">
              <LastColumnColorBar
                hideLastColumnColorBar={hideLastColumnColorBar}
                colors={{ color: virsColor, borderColor: virsColor }}
              />
            </TableCell>
          </TableRow>

          {showChildShareholder &&
            shareholders.map((shareholder, index) => {
              return (
                <ShareholdersTree
                  index={index}
                  key={shareholder.shareholderId + '__' + index}
                  shareholder={shareholder}
                  allEventsDates={allEventsDates}
                  parentShareTypeName={shareTypeName}
                  parentErrors={errors}
                  parentMayHaveVotesOnRules={mayHaveVotesOnRules}
                  isLastShareholder={getIsLastShareholder(index, shareholders)}
                  virsEndDate={virsEndDate}
                  virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
                  parentMayHaveSharePercentage={mayHaveSharePercentage}
                  parentMayHaveVotePercentage={mayHaveVotePercentage}
                  filteredShareholderLevel={filteredShareholderLevel}
                />
              );
            })}
        </>
      )}
    </>
  );
};
