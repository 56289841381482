import {
  getJustifyContent,
  getLeftParameter,
  getLeftBorderRadius,
  getRightBorderRadius,
  getVerticalBorder
} from '../../SelectedDateTable/utilities/UIFunctions';
import { getBorder } from '../../utilityFunctions/sharedUIFunctions';
import { getRightParameter, getScaledFontSize, getWidthByDay } from '../utilities/UIfunctions';
import { mixedDatesRowStyles } from './mixedDatesRowStyle';
import { monthsRowStyles } from './monthsRowStyles';
import { yearRowStyles } from './yearRowStyles';

export const rowStyles = (
  isShareholderFromJADIS: boolean,
  startDate: string,
  endDate: string | null,
  virsEndDate: string | null,
  date: string,
  fillWithColor: string,
  startWithTriangle: boolean,
  borderColor: string,
  textColor: string,
  isActivityPeriodEndDate: boolean,
  eventDate?: string,
  isSelectedDateEventDate?: boolean,
  valueLength?: number,
  singleDayShareholder?: boolean
) => {
  const verticalBorder = getVerticalBorder(
    startDate,
    endDate,
    date,
    startWithTriangle,
    borderColor,
    eventDate
  );

  const getVerticalBorderRight = () => {
    if (endDate !== date) {
      return undefined;
    }
    return verticalBorder;
  };

  const getVerticalBorderLeft = () => {
    if (startDate === date) {
      return verticalBorder;
    }
    if (startDate !== endDate && startDate !== null && eventDate !== endDate) {
      return undefined;
    }
    if (endDate === date && startDate !== date) {
      return undefined;
    }
    return verticalBorder;
  };

  const fontSize = getScaledFontSize(valueLength, singleDayShareholder);

  return {
    fontSize: fontSize,
    display: 'flex',
    alignItems: 'center',
    width: getWidthByDay(endDate, date, isSelectedDateEventDate, eventDate),
    justifyContent: getJustifyContent(
      isShareholderFromJADIS,
      startDate,
      endDate,
      virsEndDate,
      date,
      isSelectedDateEventDate,
      eventDate
    ),
    backgroundColor: fillWithColor,
    left: getLeftParameter(startDate, date, virsEndDate, eventDate),
    right: getRightParameter(endDate, date, isSelectedDateEventDate, eventDate),
    borderTopLeftRadius: getLeftBorderRadius(startDate, date, startWithTriangle),
    borderBottomLeftRadius: getLeftBorderRadius(startDate, date, startWithTriangle),
    borderTopRightRadius: getRightBorderRadius(endDate, date, eventDate),
    borderBottomRightRadius: getRightBorderRadius(endDate, date, eventDate),
    borderTop: getBorder(borderColor),
    borderBottom: getBorder(borderColor),
    borderRight: getVerticalBorderRight(),
    borderLeft: getVerticalBorderLeft(),
    color: textColor
  };
};

export const colorBarStylesOnDiffViews = (
  period: string,
  index: number,
  allEventsNumber: number,
  isShareholderFromJADIS: boolean,
  formatedStartDate: string,
  formatedEndDate: string | null,
  virsEndDate: string | null,
  date: string,
  fillWithColor: string,
  startWithTriangle: boolean,
  borderColor: string,
  textColor: string,
  formatedEventDate: string,
  isSelectedDateEventDate?: boolean
) => {
  if (period === 'year') {
    return yearRowStyles(
      index,
      allEventsNumber,
      formatedStartDate,
      formatedEndDate,
      virsEndDate,
      date,
      fillWithColor,
      startWithTriangle,
      borderColor,
      textColor,
      formatedEventDate
    );
  }
  if (period === 'month') {
    return monthsRowStyles(
      index,
      allEventsNumber,
      isShareholderFromJADIS,
      formatedStartDate,
      formatedEndDate,
      virsEndDate,
      date,
      fillWithColor,
      startWithTriangle,
      borderColor,
      textColor,
      formatedEventDate,
      isSelectedDateEventDate
    );
  }
  if (period === 'mixed') {
    return mixedDatesRowStyles(
      index,
      allEventsNumber,
      isShareholderFromJADIS,
      formatedStartDate,
      formatedEndDate,
      virsEndDate,
      date,
      fillWithColor,
      startWithTriangle,
      borderColor,
      textColor,
      formatedEventDate,
      isSelectedDateEventDate
    );
  }
};
