import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import { paymentTableReducer } from './tableState/paymentTableReducer';
import {
  PaymentDataTableState,
  PaymentDataTableAction,
  initialPaymentDataTableState
} from './tableState/paymentTableInitialStateAndTypes';
import { PaymentTable } from './Table';

const PaymentDataTableStateContext = createContext<{
  state: PaymentDataTableState;
}>({
  state: initialPaymentDataTableState
});

const PaymentDataTableDispatchContext = createContext<{
  dispatch: Dispatch<PaymentDataTableAction>;
}>({
  dispatch: () => {}
});

const PaymentTableContext: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(paymentTableReducer, initialPaymentDataTableState);
  return (
    <PaymentDataTableStateContext.Provider value={{ state }}>
      <PaymentDataTableDispatchContext.Provider value={{ dispatch }}>
        {children}
      </PaymentDataTableDispatchContext.Provider>
    </PaymentDataTableStateContext.Provider>
  );
};

const withPaymentTableContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC =>
  (props) =>
    (
      <PaymentTableContext>
        <Component {...(props as P)} />
      </PaymentTableContext>
    );

const PaymentDataTable = withPaymentTableContext(PaymentTable);

function usePaymentTableState() {
  return useContext(PaymentDataTableStateContext);
}
function usePaymentTableDispatch() {
  return useContext(PaymentDataTableDispatchContext);
}

export { PaymentDataTable, usePaymentTableState, usePaymentTableDispatch };
