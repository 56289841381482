import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import { messagesTableReducer } from './tableState/tableReducer';
import {
  MessageTableState,
  MessageTableAction,
  initialMessageTableState
} from './tableState/tableInitialStateAndTypes';
import { MessagesTable } from './Table';

const MessagesTableStateContext = createContext<{
  state: MessageTableState;
}>({
  state: initialMessageTableState
});

const MessagesTableDispatchContext = createContext<{
  dispatch: Dispatch<MessageTableAction>;
}>({
  dispatch: () => {}
});

const withMessagesTableContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC =>
  (props) => {
    const [state, dispatch] = useReducer(messagesTableReducer, initialMessageTableState);
    return (
      <MessagesTableStateContext.Provider value={{ state }}>
        <MessagesTableDispatchContext.Provider value={{ dispatch }}>
          <Component {...(props as P)} />
        </MessagesTableDispatchContext.Provider>
      </MessagesTableStateContext.Provider>
    );
  };

const UserMessagesTable = withMessagesTableContext(MessagesTable);

function useMessagesTableState() {
  return useContext(MessagesTableStateContext);
}
function useMessagesTableDispatch() {
  return useContext(MessagesTableDispatchContext);
}

export { UserMessagesTable, useMessagesTableState, useMessagesTableDispatch };
