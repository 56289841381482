import React from 'react';
import { IconButton, Paper } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ArrayHelpers, FormikHelpers, FormikState } from 'formik';
import { useSelector } from 'react-redux';
import theme from '../../../style/virsisTheme';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikArrayTextField } from './formFields/ArrayTextField';
import { useDialogFormStyles } from '../../../components/FormikFields/FieldStyles';
import { GraphicalRecord } from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';
import { FormikArrayCheckBox } from './formFields/ArrayCheckBox';
import { ApplicationState } from '../../../store';
import { RowActionButton } from '../../../components/TableRowActions/RowActionButton';

interface Props {
  id: string;
  index: number;
  graphicalRecord: GraphicalRecord;
  fieldArrayProps: ArrayHelpers;
  formikProps: FormikHelpers<any> & FormikState<{ records: GraphicalRecord[] }>;
  setTabRecords: React.Dispatch<React.SetStateAction<GraphicalRecord[]>>;
}

const GraphicalRepresentationRecordRow: React.FC<Props> = ({
  index,
  id,
  graphicalRecord,
  setTabRecords,
  formikProps
}) => {
  const styledClasses = useDialogFormStyles();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const { showActiveRecords } = useSelector(
    (state: ApplicationState) => state.graphicalRepresentation
  );

  const {
    values: { records }
  } = formikProps;

  const { isActive } = records[index];

  const handleRecordRemoval = () => {
    setTabRecords(
      formikProps.values.records
        .filter((record, i) => i !== index)
        .map((record, i) => ({
          ...record,
          rowNr: String(i)
        }))
    );
  };

  if (showActiveRecords && !graphicalRecord.isActive) {
    return <></>;
  }

  return (
    <>
      <Paper
        style={{
          margin: '1rem 0',
          padding: '1rem',
          background: theme.palette.background.default,
          transform: CSS.Transform.toString(transform),
          transition: transition || 'inherit'
        }}
      >
        <IconButton
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={{ float: 'right', width: '30px', height: '30px' }}
        >
          <DragIndicator />
        </IconButton>
        <FormikArrayTextField
          label="Kortelės pavadinimas LT*"
          arrayName="records"
          field="tableNameLt"
          index={index}
          styledClasses={styledClasses}
          isActive={isActive}
        />
        <FormikArrayTextField
          label="Kortelės pavadinimas ENG*"
          arrayName="records"
          field="tableNameEn"
          index={index}
          styledClasses={styledClasses}
          isActive={isActive}
        />
        <FormikArrayTextField
          label="URL LT*"
          arrayName="records"
          field="urlLt"
          index={index}
          styledClasses={styledClasses}
          isActive={isActive}
        />
        <FormikArrayTextField
          label="URL ENG*"
          arrayName="records"
          field="urlEn"
          index={index}
          styledClasses={styledClasses}
          isActive={isActive}
        />
        <FormikArrayTextField
          label="Stilius*"
          arrayName="records"
          field="styleFileName"
          index={index}
          styledClasses={styledClasses}
          isActive={isActive}
        />
        <FormikArrayCheckBox
          label="Aktyvi"
          arrayName="records"
          field="isActive"
          index={index}
          styledClasses={styledClasses}
        />
        <div style={{ marginLeft: 'auto', marginRight: '16px', width: '120px' }}>
          <RowActionButton
            label="Pašalinti"
            onButtonClick={handleRecordRemoval}
            icon={<DeleteIcon />}
          />
        </div>
      </Paper>
    </>
  );
};

export default GraphicalRepresentationRecordRow;
