import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  InputStateGeneric,
  DropdownMultipleStateGeneric,
  DatePickerState,
  VirsisFilesState,
  DropdownStateGeneric,
  VirsInputState
} from '../../../../utils/tableTypes';
import { PaymentDataInst, PaymentDocumentData } from '../../../../store/payments/paymentsTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import { AnnulmentType } from '../../../../store/classifiers/classifiersTypes';

export interface PaymentRecordState {
  virs: VirsInputState;
  decisionDate: DatePickerState;
  customDocumentsName: InputStateGeneric<string>;
  paymentDocuments: PaymentDocumentData[];
  paymentFiles: VirsisFilesState;
  paymentOutlets: DropdownMultipleStateGeneric<OutletShortData>;
  paymentAmount: InputStateGeneric<string>;
  validFrom: DatePickerState;
  validTo: DatePickerState;
  showFindVirsDialog: boolean;
  annulRecordConfirmationOn: boolean;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
  loading: boolean;
}

export const initialPaymentFilesState: VirsisFilesState = {
  files: [],
  sizeLimit: 5242880, // TODO parametrizuoti
  filesLimit: 5, // TODO parametrizuoti
  error: false,
  validated: true,
  errorMessage: ''
};

export const initialPaymentRecordState: PaymentRecordState = {
  virs: {
    value: null,
    error: false,
    validated: false,
    helperText: 'Privaloma pasirinkti VIRS',
    placeholder: 'VIRS pavadinimas'
  },
  decisionDate: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  customDocumentsName: {
    value: null,
    id: 'customName',
    error: false,
    helperText: 'Dokumento numeris',
    placeholder: '',
    validated: false
  },
  paymentDocuments: [],
  paymentFiles: initialPaymentFilesState,
  paymentOutlets: {
    values: [],
    id: '',
    error: false,
    helperText: '',
    placeholder: 'Pasirinkti',
    validated: false,
    options: []
  },
  paymentAmount: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: 'Įvesti',
    validated: false
  },
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  validTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: []
  },
  showFindVirsDialog: false,
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  },
  loading: false
};

export interface PaymentEditRowState extends PaymentRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

export const initialPaymentEditRowState: PaymentEditRowState = {
  editingOn: false,
  ...initialPaymentRecordState,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false
};

export interface PaymentNewRowState extends PaymentRecordState {
  createRecordConfirmationOn: boolean;
  showUnclosedPaymentDialog: boolean;
}

export const initialPaymentNewRowState: PaymentNewRowState = {
  ...initialPaymentRecordState,
  createRecordConfirmationOn: false,
  showFindVirsDialog: true,
  showUnclosedPaymentDialog: false
};

type PaymentRowAction =
  | { type: 'VIRS_INPUT_CLICKED' }
  | { type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED'; virs: VirsSearchData }
  | { type: 'FIND_VIRS_DIALOG_CLOSED' }
  | {
      type: 'DECISION_DATE_CHANGED';
      decisionDate: MaterialUiPickersDate | null;
    }
  | { type: 'PAYMENT_FILE_ADDED'; file: File }
  | { type: 'PAYMENT_FILE_REMOVED'; fileId: number }
  | { type: 'FILE_ADDING_WRONG_FORMAT'; errorMessage: string }
  | { type: 'PAYMENT_FILES_CUSTOM_NAME_CHANGED'; filesCustomName: string }
  | { type: 'PAYMENT_AMOUNT_CHANGED'; paymentAmount: string | null }
  | { type: 'OUTLETS_CHANGED'; outlets: OutletShortData[] }
  | { type: 'VALID_FROM_CHANGED'; validFrom: MaterialUiPickersDate | null }
  | { type: 'VALID_TO_CHANGED'; validTo: MaterialUiPickersDate | null }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type PaymentNewRowAction =
  | PaymentRowAction
  | {
      type: 'NEW_RECORD_STATE_INITIALIZED';
      fictitiousOutlet: OutletShortData[];
    }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'CLOSE_UNCLOSED_PAYMENT_DIALOG' };

export type PaymentEditRowAction =
  | PaymentRowAction
  | { type: 'EDITING_INITIALIZED'; record: PaymentDataInst; fictitiousOutlet: OutletShortData[] }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'PAYMENT_DOCUMENT_REMOVED'; paymentDocumentId: number }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'DOCUMENT_REMOVED'; documentId: number }
  | { type: 'ANNUL_RECORD_CLICKED'; annulmentTypes: AnnulmentType[] }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' }
  | { type: 'CLOSE_RECORD_CLICKED'; isRequired: boolean };
