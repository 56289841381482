import { DataTableState } from './tableTypes';

export const initialState: DataTableState = {
  data: [],
  order: 'asc',
  sortBy: 'licenseIssueDate',
  pageData: [],
  page: 0,
  recordsPassed: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  relevantFilter: false,
  outdatedFilter: false,
  columnsDisplayStatus: {
    licenseIssueDate: true,
    licenseNumber: true,
    licenseTypeName: true,
    enterpriseName: true,
    licenseStatus: true,
    validFrom: true,
    validTo: true,
    issuingInstitutionName: true,
    licenseNotes: true
  },
  customFilterOn: false,
  customFilter: {
    licenseIssueDate: [],
    licenseNumber: [],
    licenseTypeName: [],
    enterpriseName: [],
    licenseStatus: [],
    validFrom: [],
    validTo: [],
    issuingInstitutionName: [],
    licenseNotes: []
  }
};
