import { OutletData } from '../../../store/outlets/outletsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import { ColumnHeader } from '../../../utils/tableTypes';
import { OutletDataTableField } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import { OutletMainDataTableState } from '../tablesState/mainOutletTableTypes';

const headers: ColumnHeader<OutletDataTableField>[] = [
  { label: 'VIP pavadinimas', id: 'outletName' },
  { label: 'Rūšis', id: 'outletTypeName' },
  { label: 'Grupė', id: 'outletGroupName' },
  { label: 'Steigimo data', id: 'establishedDate' },
  { label: 'Tarptautinis Nr.', id: 'internationalNumber' },
  { label: 'Nuo', id: 'controlledFrom' },
  { label: 'Iki', id: 'controlledTo' }
];

export function getTableExportDefinition(
  tableState: OutletMainDataTableState
): TableExportDefinition<OutletData> {
  const { stringValueColumn } = getColumnBuilder<OutletData, OutletDataTableField>(
    headers,
    tableState.columnsDisplayStatus
  );
  return {
    title: 'Pagrindiniai VIP duomenys',
    columnGroups: [
      {
        columns: [
          stringValueColumn('outletName', { width: 45 }),
          stringValueColumn('outletTypeName', { width: 45 }),
          stringValueColumn('outletGroupName', { width: 45 }),
          stringValueColumn('establishedDate', { width: 15 })
        ]
      },
      {
        header: 'VIP valdoma',
        columns: [
          stringValueColumn('controlledFrom', { width: 15 }),
          stringValueColumn('controlledTo', { width: 15 })
        ]
      },
      { columns: [stringValueColumn('internationalNumber', { width: 25 })] }
    ]
  };
}
