import { makeStyles, TableCell } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ShareholdersProps,
  ShareholdersData,
  ShareholderPerson,
  ActivityPeriod,
  ShareholdersHistory
} from '../../../../store/shareholders/shareholdersTypes';
import { getBorderColor, getColor, getTextColor } from '../../utilityFunctions/sharedUIFunctions';
import { ColorBarPrevFollDate } from '../Components/ColorBar/ColorBarPrevFollDate';
import { ColorBarSelectedDate } from '../Components/ColorBar/ColorBarSelectedDate';
import { useShareholdersSelectedDateTableState } from '../TableWithContext';
import { checkIfColorBarNeeded } from '../utilities/UIFunctions';
import { ApplicationState } from '../../../../store';
import theme from '../../../../style/virsisTheme';
import { getCurrentPeriod, isOneDayShareholder } from '../../utilityFunctions/sharedFunctions';
import { isDateUnsigned } from '../../MainTable/tableState/tableFunctions';

interface Props {
  shareholder: ShareholdersProps;
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson;
  previousDate?: string;
  followingDate?: string;
  selectedDate: string;
  isLegalPerson: boolean;
  isSelectedDateEventDate: boolean;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
}

const useStyles = makeStyles({
  selectedDateCell: {
    position: 'relative'
  },
  previousOrFollowingDateCell: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.light,
    opacity: '0.5'
  }
});

export const ShareholdersChartSelectedDate: React.FC<Props> = ({
  shareholder,
  parentShareholder,
  previousDate,
  followingDate,
  selectedDate,
  isLegalPerson,
  isSelectedDateEventDate,
  virsEndDate,
  virsStoppedParticipatingFromDate
}) => {
  const classes = useStyles();
  const { state: tableState } = useShareholdersSelectedDateTableState();
  const { newDate, loadingShareholdersSelectedDateData, shareholdersData } = useSelector(
    (state: ApplicationState) => state.shareholdersData
  );
  const {
    shareholderStartDate,
    shareholderParentStartDate,
    shareholderStoppedParticipatingFromDate,
    shareholderHistory
  } = shareholder;

  const previousDateEvent = shareholderHistory.find((event) => event.eventDate < selectedDate);

  const selectedDateEvent = shareholderHistory.find((event) => event.eventDate === selectedDate);
  const followingDateEvent = shareholderHistory.find((event) => event.eventDate === followingDate);

  const getColorPrevFollDate = (
    isPrevDateCollumn: boolean,
    fnGetColor: (
      showShares: boolean,
      sharePercentage: number | null,
      showVotes: boolean,
      votesPercentage: number | null,
      isLegalPerson: boolean,
      votesOnRules: boolean | null
    ) => string
  ) => {
    if (!isPrevDateCollumn && selectedDateEvent && !followingDateEvent) {
      return fnGetColor(
        tableState.showShares,
        selectedDateEvent.sharePercentage,
        tableState.showVotes,
        selectedDateEvent.votePercentage,
        isLegalPerson,
        selectedDateEvent.votesOnRules
      );
    }
    if (!isPrevDateCollumn && followingDateEvent) {
      return fnGetColor(
        tableState.showShares,
        followingDateEvent.sharePercentage,
        tableState.showVotes,
        followingDateEvent.votePercentage,
        isLegalPerson,
        followingDateEvent.votesOnRules
      );
    }
    if (previousDateEvent) {
      return fnGetColor(
        tableState.showShares,
        previousDateEvent.sharePercentage,
        tableState.showVotes,
        previousDateEvent.votePercentage,
        isLegalPerson,
        previousDateEvent.votesOnRules
      );
    }
    return theme.palette.secondary.light;
  };

  const activityPeriod: ActivityPeriod = getCurrentPeriod(
    selectedDate,
    shareholdersData?.activityPeriods || []
  );
  const previousAndSelectedDateEvents: ShareholdersHistory[] = shareholderHistory.filter(
    (event) => {
      const result =
        (event.eventDate !== shareholderStoppedParticipatingFromDate ||
          event.eventDate === shareholderStartDate ||
          activityPeriod?.startDate === selectedDate) &&
        event.eventDate <= selectedDate &&
        (virsStoppedParticipatingFromDate
          ? event.eventDate <= virsStoppedParticipatingFromDate
          : true);

      if (event.eventDate === shareholderStoppedParticipatingFromDate) {
        return isOneDayShareholder(
          activityPeriod,
          shareholderHistory,
          event,
          shareholderStoppedParticipatingFromDate
        );
      }

      return result;
    }
  );
  const isDateSigned = !isDateUnsigned(
    shareholdersData?.shareholders || [],
    [activityPeriod],
    selectedDate
  );

  return (
    <>
      <TableCell className={classes.previousOrFollowingDateCell}>
        {previousDate &&
          checkIfColorBarNeeded(
            shareholderStartDate,
            shareholderParentStartDate,
            shareholderStoppedParticipatingFromDate,
            previousDate
          ) && (
            <ColorBarPrevFollDate
              borderColor={getColorPrevFollDate(true, getBorderColor)}
              fillWithColor={getColorPrevFollDate(true, getColor)}
            />
          )}
      </TableCell>

      <TableCell className={classes.selectedDateCell}>
        {checkIfColorBarNeeded(
          shareholderStartDate,
          shareholderParentStartDate,
          shareholderStoppedParticipatingFromDate,
          selectedDate
        ) &&
          shareholderHistory &&
          previousAndSelectedDateEvents.map((event) => {
            return (
              <ColorBarSelectedDate
                key={`${event.eventId}${event.eventDate}`}
                selectedDate={selectedDate}
                shareholder={shareholder}
                parentShareholder={parentShareholder}
                event={event}
                fillWithColor={getColor(
                  tableState.showShares,
                  event.sharePercentage,
                  tableState.showVotes,
                  event.votePercentage,
                  isLegalPerson,
                  event.votesOnRules
                )}
                borderColor={getBorderColor(
                  tableState.showShares,
                  event.sharePercentage,
                  tableState.showVotes,
                  event.votePercentage,
                  isLegalPerson,
                  event.votesOnRules
                )}
                textColor={getTextColor(
                  tableState.showShares,
                  tableState.showVotes,
                  event.sharePercentage,
                  event.votePercentage
                )}
                isSelectedDateEventDate={isSelectedDateEventDate}
                newDate={newDate}
                virsEndDate={virsEndDate}
                virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
                loadingShareholdersSelectedDateData={loadingShareholdersSelectedDateData}
                isDateSigned={isDateSigned}
                currentActivityPeriod={activityPeriod}
              />
            );
          })}
      </TableCell>

      <TableCell className={classes.previousOrFollowingDateCell}>
        {followingDate &&
          checkIfColorBarNeeded(
            shareholderStartDate,
            shareholderParentStartDate,
            shareholderStoppedParticipatingFromDate,
            followingDate
          ) && (
            <ColorBarPrevFollDate
              borderColor={getColorPrevFollDate(false, getBorderColor)}
              fillWithColor={getColorPrevFollDate(false, getColor)}
            />
          )}
      </TableCell>
    </>
  );
};
