import {
  ColumnDisplayStatus,
  ColumnHeader,
  TableCustomFilter,
  TableGenericFilterOption
} from '../../../utils/tableTypes';
import { TableExportDefinition } from '../../../utils/exporters/types';
import { FundsReceivedDataLegal } from '../../../store/fundsReceived/fundsReceivedDataTypes';

export const fundsReceivedLegalColumnNames: FundsReceivedLegalTableHeader[] = [
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'fundsReceivedYear',
    label: 'Metai',
    type: 'year'
  },
  {
    id: 'fundsReceivedSum',
    label: 'Suma(EUR)'
  },
  {
    id: 'fundsSourceName',
    label: 'Lėšų šaltinio pavadinimas'
  },
  {
    id: 'fundsSourceCode',
    label: 'Lešų šaltinio kodas'
  },
  {
    id: 'fundsReceivedOutlets',
    label: 'Susijusios VIP'
  },
  {
    id: 'transaction',
    label: 'Sandorio rūšis'
  }
];

export type FundsReceivedLegalTableHeader = ColumnHeader<FundsReceivedLegalTableField>;

export type FundsReceivedLegalTableField =
  | 'virsName'
  | 'fundsReceivedYear'
  | 'fundsReceivedSum'
  | 'fundsSourceName'
  | 'fundsSourceCode'
  | 'transaction'
  | 'fundsReceivedOutlets';

export interface FundsReceivedLegalTableState {
  customFilterOn: boolean;
  columnsDisplayStatus: FundsReceivedLegalTableColumnsDisplayStatus;
  showNewRecord: boolean;
  companyCode?: string;
  tableExportDefinition: TableExportDefinition<FundsReceivedDataLegal>;
}

export type FundsReceivedLegalTableCustomFilter = TableCustomFilter<FundsReceivedLegalTableField>;

export type FundsReceivedLegalTableColumnsDisplayStatus =
  ColumnDisplayStatus<FundsReceivedLegalTableField>;

export type FundsReceivedCustomFilterOptions =
  TableGenericFilterOption<FundsReceivedLegalTableField>;

export type FundsReceivedLegalTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: FundsReceivedLegalTableField }
  | { type: 'CREATE_RECORD_CLICKED'; companyCode?: string }
  | { type: 'CLOSE_CREATING_RECORD_CLICKED' }
  | { type: 'RESET_COMPANY_CODE' };

export const initialColumnDisplay = {
  virsName: true,
  fundsReceivedYear: true,
  fundsReceivedSum: true,
  fundsSourceName: true,
  fundsSourceCode: true,
  transaction: true,
  fundsReceivedOutlets: true
};
