import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { Link } from 'react-router-dom';
import { ErrorIconWithTooltip } from '../ErrorIcon/ErrorIconWithTooltip';
import { DocumentValidationError } from '../../store/document/documentTypes';
import { ErrorType } from '../../store/virsis/dataTypes';

interface Props {
  errors?: DocumentValidationError[];
  title: string;
  data: Record<string, any>[];
  url: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '31%',
    height: 250,
    backgroundColor: '#F5F8FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
    display: 'block',
    width: '100%',
    marginRight: '10px'
    }
  },
  errorIcon: {
    position: 'relative',
    top: -25,
    flex: 1,
    display: 'flex'
  },
  title: {
    flex: 1
  },
  data: {
    flex: 1
  },
  button: {
    flex: 1
  }
}));

export const VirsDataWrapper: React.FC<Props> = ({ errors, title, data, url }) => {
  const classes = useStyles();

  const filterErrors = (errorArray?: DocumentValidationError[]) => {
    if (errorArray) {
      const errorPriorityFilter = (x: string) =>
        errorArray.filter(({ priority }) => priority === x);

      const criticalErrors = errorPriorityFilter('E');
      const nonCriticalErrors = errorPriorityFilter('W');
      const infoErrors = errorPriorityFilter('I');

      const getUniqueValuesAndCount = (someErrorArray: DocumentValidationError[]) => {
        const errorTextArray = someErrorArray.map(({ text }) => text);
        return Array.from(new Set(errorTextArray)).map((errorText) => ({
          text: errorText,
          count: errorTextArray.filter((error) => error === errorText).length,
          errorType: someErrorArray[0].code
        }));
      };

      return [
        getUniqueValuesAndCount(criticalErrors),
        getUniqueValuesAndCount(nonCriticalErrors),
        getUniqueValuesAndCount(infoErrors)
      ];
    }

    return [undefined, undefined, undefined];
  };

  const [criticalUniqueErrors, nonCriticalUniqueErrors, infoUniqueErrors] = filterErrors(errors);

  const criticalErrorTooltipText = (
    <>
      {criticalUniqueErrors?.map(({ text, count }) => (
        <div key={text}>
          {`${text} - ${count}`} <br />
        </div>
      ))}
    </>
  );

  const nonCriticalErrorTooltipText = (
    <>
      {nonCriticalUniqueErrors?.map(({ text, count }) => (
        <div key={text}>
          {`${text} - ${count}`} <br />
        </div>
      ))}
    </>
  );

  const infoErrorTooltipText = (
    <>
      {infoUniqueErrors?.map(({ text, count }) => (
        <div key={text}>
          {`${text} - ${count}`} <br />
        </div>
      ))}
    </>
  );

  return (
    <Paper elevation={0} className={classes.container}>
      <div className={classes.errorIcon}>
        {!errors?.length && (
          <CheckCircleOutlinedIcon
            fontSize="large"
            style={{ color: 'green', verticalAlign: 'middle', margin: '5px' }}
          />
        )}
        {criticalUniqueErrors && criticalUniqueErrors.length > 0 && (
          <ErrorIconWithTooltip
            text={criticalErrorTooltipText}
            outlined
            errorType={ErrorType.CRITICAL}
            large
            noWrap
          />
        )}
        {nonCriticalUniqueErrors && nonCriticalUniqueErrors.length > 0 && (
          <ErrorIconWithTooltip
            text={nonCriticalErrorTooltipText}
            outlined
            errorType={ErrorType.NONCRITICAL}
            large
            noWrap
          />
        )}
        {infoUniqueErrors && infoUniqueErrors.length > 0 && (
          <ErrorIconWithTooltip
            text={infoErrorTooltipText}
            outlined
            errorType={ErrorType.INFO}
            large
            noWrap
          />
        )}
      </div>
      <Typography className={classes.title} component="div">
        <Box fontWeight="bold">{title}</Box>
      </Typography>
      <div className={classes.data}>
        {data.map(({ name, count }) => (
          <Typography key={name} component="div">
            <Box display="inline">{name}</Box>
            <Box style={{ marginLeft: 10 }} display="inline" fontWeight="bold">
              {count}
            </Box>
          </Typography>
        ))}
      </div>
      <div className={classes.button}>
        <Link style={{ textDecoration: 'none' }} to={url}>
          <Button variant="outlined" color="primary">
            Pildyti
          </Button>
        </Link>
      </div>
    </Paper>
  );
};
