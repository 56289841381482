import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import { useSelector } from 'react-redux';
import {
  PaymentDataHeaderField,
  PaymentDataColumnHeader
} from './tableState/tableInitialStateAndTypes';
import { usePaymentDataTableState, usePaymentDataTableDispatch } from './Context';
import { ApplicationState } from '../../store';
import { PaymentDataVirs } from '../../store/payments/paymentsTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { replaceDecimalPoint } from '../../utils/tableDataFunctions';

interface Props {
  columnNames: PaymentDataColumnHeader[];
}

const PaymentDataTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = usePaymentDataTableState();
  const { dispatch } = usePaymentDataTableDispatch();

  const handleCustomFilterChange = (column: PaymentDataHeaderField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData = useSelector((state: ApplicationState) => state.paymentData.virsPaymentData) || [];

  function getUniqueOptions(name: PaymentDataHeaderField, data: PaymentDataVirs[]) {
    if (name === 'paymentDocuments') {
      const documentNumbers: string[] = data.flatMap((record) =>
        record.paymentDocuments.map((doc) => doc.documentNumber)
      );
      return Array.from(new Set(documentNumbers));
    }
    if (name === 'paymentOutlets') {
      const outletNames: string[] = data.flatMap((record) =>
        record.paymentOutlets.map((outlet) => outlet.outletName)
      );
      return Array.from(new Set(outletNames));
    }
    if (name === 'paymentAmount') {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] !== null)
            .map((item) => replaceDecimalPoint(String(item[name])))
        )
      );
    }
    if (data) {
      return Array.from(
        new Set(
          data.filter((item) => item[name] !== null).map((item) => replaceDecimalPoint(item[name]))
        )
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default PaymentDataTableCustomFilter;
