import React from 'react';
import { Button, Popover } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import virsisTheme from '../../../style/virsisTheme';
import { TooltipOnTableActionButton } from '../../Tooltips/TooltipOnTableActionButton';
import { AnnulDocumentButton } from './AnnulDocumentButton';

interface Props {
  annulmentDisablementReason: string | null;
  onClick?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      backgroundColor: 'transparent',
      boxShadow: 'unset'
    },
    margin: {
      margin: '15px 0px',
      marginLeft: '5px'
    },
    moreVertButton: {
      height: '40px',
      width: '40px',
      border: `1px solid ${virsisTheme.palette.info.light}`,
      borderRadius: 5,
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      backgroundColor: virsisTheme.palette.secondary.light,
      color: '#637082',
      paddingTop: '0',
      paddingBottom: '0'
    },
    themeButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.primary.main
      },
      '&:active': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: '#DBEDFC',
        color: virsisTheme.palette.primary.main
      }
    },
    dangerButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.error.main,
        backgroundColor: '#FFEFEF',
        color: virsisTheme.palette.error.main
      }
    },
    icon: {
      height: 30,
      padding: '5px 10px',
      color: 'inherit'
    }
  })
);

const MoreVertButtonWithAnnulPopover: React.FC<Props> = ({
  annulmentDisablementReason,
  onClick,
  disabled
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionButtonToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <TooltipOnTableActionButton
        text={`${disabled ? 'Neturite prieigos prie įrašo redagavimo' : ''}`}
      >
        <span>
          <Button
            aria-label="Išskleisti daugiau veiksmų"
            disabled={disabled}
            variant="text"
            onClick={handleActionButtonToggle}
            className={`${classes.moreVertButton} ${classes.themeButton} ${classes.margin}`}
            style={
              anchorEl
                ? {
                    backgroundColor: '#DBEDFC',
                    borderColor: virsisTheme.palette.primary.main
                  }
                : {}
            }
          >
            <MoreVertIcon
              fontSize="small"
              className={classes.icon}
              style={{ borderRight: 'none' }}
            />
          </Button>
        </span>
      </TooltipOnTableActionButton>

      <Popover
        id="delete-action"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <AnnulDocumentButton
          annulmentDisablementReason={annulmentDisablementReason}
          onClick={onClick}
        />
      </Popover>
    </>
  );
};

export default MoreVertButtonWithAnnulPopover;
