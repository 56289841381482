import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { LinkedError, GroupedError } from './documentErrorMapping';
import virsisTheme from '../../style/virsisTheme';
import { LocationDescriptor } from 'history';

interface Props {
  error: LinkedError | GroupedError;
  onErrorLinkClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const ErrorItem: React.FC<Props> = ({ error, onErrorLinkClicked }) => {
  return (
    <Box key={error.sivId} pt={1}>
      <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
        <ErrorIcon
          style={{
            color:
              error.priority === 'E'
                ? `${virsisTheme.palette.error.main}`
                : `${virsisTheme.palette.warning.main}`,
            fontSize: '1.2em',
            marginRight: '5px'
          }}
        />
        {error.label}
      </Typography>

      <Typography
        variant="h6"
        style={{
          fontStyle: 'italic'
        }}
      >
        {error.text} {'count' in error && error.count !== 1 && ` - ${error.count}`}
      </Typography>

      {error.url && (
        <Link
          to={(): LocationDescriptor => {
            return { pathname: error.url };
          }}
          onClick={onErrorLinkClicked}
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography color="primary" variant="h4">
            Taisyti klaidą
          </Typography>
          <ArrowRightAltIcon color="primary" style={{ marginLeft: '5px' }} />
        </Link>
      )}
    </Box>
  );
};
