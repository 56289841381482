import {
  ProvidedDataTableAction,
  providedDataTableColumns,
  ProvidedDataTableState
} from './tableInitialStateAndTypes';
import {
  updateCustomFilterGeneric,
  getToggledSortOrderGeneric
} from '../../../utils/tableDataFunctions';
import { getTableExportDefinition } from './tableStateFunctions';

export const providedDataTableReducer = (
  state: ProvidedDataTableState,
  action: ProvidedDataTableAction
): ProvidedDataTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return {
        ...state
      };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          dataType: [],
          documentStatus: [],
          confirmationDate: [],
          confirmationPersonFullName: [],
          annulmentDate: [],
          annulmentReasonTypeName: []
        }
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(
          providedDataTableColumns,
          columnsDisplayStatus
        )
      };
    }
    default:
      return state;
  }
};
