import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeStyles, TableCell } from '@material-ui/core';
import { PaymentBasicRow } from './PaymentBasicRow';
import { ApplicationState } from '../../../store';
import { PaymentDataInst, PaymentRecordWithoutFiles } from '../../../store/payments/paymentsTypes';
import { paymentEditRowReducer } from './paymentRowState/editPaymentReducer';
import { initialPaymentEditRowState } from './paymentRowState/paymentInitialStateAndTypes';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import {
  getPaymentDocumentInNewTabRequest,
  updatePayment,
  getPaymentDataRequest,
  resetPaymentRemovingState,
  removePayment,
  annulPayment,
  resetAnnulPaymentState,
  closePayment
} from '../../../store/payments/paymentsActions';
import { usePaymentTableState } from '../Context';
import { OutletShortData } from '../../../store/outlets/outletsTypes';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { VirsSearchData } from '../../../store/virsis/dataTypes';
import {
  joinPaymentIntoFormData,
  validateEditedValidTo,
  validateEditPaymentRecordState
} from './paymentRowState/paymentRowReducerFunctions';
import { OutletsAsLinksList } from '../../../components/TableLinks/OutletsAsLinksList';
import { ElDocumentLinks } from '../../../components/TableElDocumentLinks/ElDocumentLinks';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { DropdownMultipleInputCell } from '../../../components/TableInputs/DropdownMultipleInput';
import { MoneyInputCell } from '../../../components/TableInputs/MoneyInput';
import { formatStringOfNumber } from '../../../utils/tableDataFunctions';
import PreviewAndSignDocumentDialog from '../../document/PreviewAndSignDocumentDialog';
import { DocumentNumberInput } from '../../../components/TableInputs/DocumentNumberInput';
import { PaymentFilesInput } from '../../../components/TableInputs/FilesUploadInput/PaymentFilesInput';
import { AnnulDocumentButton } from '../../../components/TableButtons/RowActionButtons/AnnulDocumentButton';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import { AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';
import { SignDocumentButton } from '../../../components/TableButtons/RowActionButtons/SignDocumentButton';
import theme from '../../../style/virsisTheme';
import { SaveAndCancelIcons } from '../../../components/TableRowActions/SaveAndCancelIcons';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { RowActionButtonGeneric } from '../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { ViewDocumentButton } from '../../../components/TableButtons/RowActionButtons/ViewDocumentButton';
import { getOutletMultiOption } from '../../../components/TableInputs/Utilities';
import { AuthorisedEditAndRemoveActions } from '../../../components/TableRowActions/AuthorisedEditAndRemoveActions';

interface Props {
  record: PaymentDataInst;
}

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

const PaymentRowContainer: React.FC<Props & RouteComponentProps> = ({ record }) => {
  const classes = useStyles();
  const [rowState, rowDispatch] = useReducer(paymentEditRowReducer, initialPaymentEditRowState);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleDialogClose = () => setOpenDialog(false);

  const {
    paymentData: {
      updatingPaymentError,
      removingPayment,
      isPaymentRemoved,
      removingPaymentError,
      annullingPaymentError,
      annullingPaymentRecord,
      closingPayment,
      closingPaymentError
    },
    classifiers: { enterpriseTypes, fictitiousOutlet, annulmentTypes }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const { state: tableState } = usePaymentTableState();

  const documentId = record.documentClosureId ? record.documentClosureId : record.documentStatusId;

  function turnOnEditing() {
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      fictitiousOutlet: fictitiousOutlet || []
    });
  }

  function openFindVirsDialog() {
    rowDispatch({
      type: 'VIRS_INPUT_CLICKED'
    });
  }

  function closeFindVirsDialog() {
    rowDispatch({
      type: 'FIND_VIRS_DIALOG_CLOSED'
    });
  }

  function selectVirs(virs: VirsSearchData) {
    rowDispatch({
      type: 'CONTINUE_WITH_SELECTED_VIRS_CLICKED',
      virs
    });
  }

  function setDecisionDate(decisionDate: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'DECISION_DATE_CHANGED', decisionDate });
  }

  function addPaymentFile(file?: File) {
    if (file) {
      rowDispatch({ type: 'PAYMENT_FILE_ADDED', file });
    }
  }

  function removePaymentFile(fileId: number) {
    rowDispatch({ type: 'PAYMENT_FILE_REMOVED', fileId });
  }

  function changeCustomName(filesCustomName: string) {
    rowDispatch({ type: 'PAYMENT_FILES_CUSTOM_NAME_CHANGED', filesCustomName });
  }

  function removeExistingPaymentDocument(paymentDocumentId: number) {
    rowDispatch({ type: 'PAYMENT_DOCUMENT_REMOVED', paymentDocumentId });
  }

  function setOutlets(outlets: OutletShortData[]) {
    rowDispatch({ type: 'OUTLETS_CHANGED', outlets });
  }

  function setValidFromDate(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_FROM_CHANGED', validFrom });
  }

  function setValidToDate(validTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'VALID_TO_CHANGED', validTo });
  }

  function setPaymentAmount(paymentAmount: string | null) {
    rowDispatch({ type: 'PAYMENT_AMOUNT_CHANGED', paymentAmount });
  }

  function cancelEditing() {
    rowDispatch({ type: 'EDITING_CANCELLED' });
  }

  function updatePaymentRecord() {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateEditPaymentRecordState(rowState).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const paymentRecord: PaymentRecordWithoutFiles = {
        virId: rowState.virs.value ? rowState.virs.value.virId : record.virId,
        decisionDate: rowState.decisionDate.value
          ? rowState.decisionDate.value.format('L').toString()
          : null,
        paymentDocuments: rowState.paymentDocuments,
        paymentOutlets: rowState.paymentOutlets.values,
        paymentAmount: rowState.paymentAmount.value ? Number(rowState.paymentAmount.value) : null,
        validFrom: rowState.validFrom.value
          ? rowState.validFrom.value.format('L').toString()
          : null,
        validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null,
        customDocumentsName: rowState.customDocumentsName.value
      };
      const asFormData = joinPaymentIntoFormData(paymentRecord, rowState.paymentFiles.files);
      reduxDispatch(updatePayment(asFormData, record.paymentId));
    }
  }

  function onRemoveRecordClicked() {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  }

  function removeRecord() {
    reduxDispatch(removePayment(record.paymentId));
  }

  function closeRemoveConfirmation() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' });
  }

  function closeOnRemoveError() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetPaymentRemovingState());
  }

  function closeOnSuccess() {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
  }

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || []
    });
  }

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulPaymentState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    reduxDispatch(annulPayment(documentId, annulRecord));
    closeAnnulDocumentDialog();
  }

  function openDocumentInNewTab(paymentDocumentId: number, documentNumber: string) {
    reduxDispatch(getPaymentDocumentInNewTabRequest(paymentDocumentId, documentNumber));
  }

  function handleFileWrongFormat() {
    rowDispatch({
      type: 'FILE_ADDING_WRONG_FORMAT',
      errorMessage: 'netinkamas dokumento formatas'
    });
  }

  function closePaymentRecord() {
    const isRequired = !record.hasUnsignedValidTo;
    rowDispatch({ type: 'CLOSE_RECORD_CLICKED', isRequired });
    if (validateEditedValidTo(rowState, isRequired).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      reduxDispatch(
        closePayment(record.paymentId, rowState.validTo.value?.format('L').toString() || null)
      );
    }
  }

  useEffect(() => {
    if (updatingPaymentError) rowDispatch({ type: 'STOP_LOADING' });
  }, [updatingPaymentError]);

  useEffect(() => {
    if (closingPaymentError) rowDispatch({ type: 'STOP_LOADING' });
  }, [closingPaymentError]);

  return (
    <>
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={(rowState.virs.value as unknown as VirsSearchData) || undefined}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={closeFindVirsDialog}
          closeAndContinueWithVirs={selectVirs}
        />
      )}
      {record.documentStatus.id === 'RUOSIAMAS' && (
        <PaymentBasicRow
          editOn={rowState.editingOn}
          recordStatus={record.documentStatus.id}
          columnsToDisplay={tableState.columnsDisplayStatus}
          virs={
            <>
              {!rowState.editingOn && (
                <VirsNameLink
                  virsName={record.virsName}
                  virsLegalCode={Number(record.virsLegalCode)}
                  virsId={record.virId}
                />
              )}
              {rowState.editingOn && (
                <VirsInputCell openSearchDialog={openFindVirsDialog} state={rowState.virs} />
              )}
            </>
          }
          decisionDate={
            rowState.editingOn ? (
              <DateInputImprovedCell
                state={rowState.decisionDate}
                setDate={setDecisionDate}
                isRequired
              />
            ) : (
              record.decisionDate
            )
          }
          paymentDocumentsInput={
            rowState.editingOn && (
              <TableCell
                style={{
                  backgroundColor:
                    rowState.customDocumentsName.error || rowState.paymentFiles.error
                      ? theme.palette.error.light
                      : undefined
                }}
              >
                <DocumentNumberInput
                  setValue={changeCustomName}
                  inputState={rowState.customDocumentsName}
                  isRequired
                />
                <PaymentFilesInput
                  paymentDocuments={rowState.paymentDocuments}
                  removePaymentDocument={removeExistingPaymentDocument}
                  state={rowState.paymentFiles}
                  customName={rowState.customDocumentsName}
                  addFile={addPaymentFile}
                  removeFile={removePaymentFile}
                  changeCustomName={changeCustomName}
                  setWrongFormatError={handleFileWrongFormat}
                />
              </TableCell>
            )
          }
          paymentDocuments={
            !rowState.editingOn && (
              <ElDocumentLinks
                documents={record.paymentDocuments}
                onClickedOpenInDialog={openDocumentInNewTab}
              />
            )
          }
          paymentOutlets={
            !rowState.editingOn && (
              <OutletsAsLinksList outlets={record.paymentOutlets} virsId={record.virId} />
            )
          }
          paymentOutletsInput={
            rowState.editingOn && (
              <DropdownMultipleInputCell
                renderOption={getOutletMultiOption}
                selectValues={setOutlets}
                state={rowState.paymentOutlets}
                getOptionLabel={(outlet) => outlet.outletName}
                getOptionSelected={(option, value) =>
                  value ? option.outletId === value.outletId : false
                }
              />
            )
          }
          paymentAmount={
            rowState.editingOn ? (
              <MoneyInputCell
                setValue={setPaymentAmount}
                inputState={rowState.paymentAmount}
                isRequired
              />
            ) : (
              formatStringOfNumber(record.paymentAmount)
            )
          }
          validFrom={
            rowState.editingOn ? (
              <DateInputImprovedCell
                state={rowState.validFrom}
                setDate={setValidFromDate}
                isRequired
              />
            ) : (
              record.validFrom
            )
          }
          validTo={
            rowState.editingOn ? (
              <DateInputImprovedCell state={rowState.validTo} setDate={setValidToDate} />
            ) : (
              <TableCell className={classes.dateColumn}>{record.validTo}</TableCell>
            )
          }
          actions={
            record.removable && (
              <>
                {rowState.editingOn && (
                  <SaveAndCancelActions
                    handleConfirmationYes={updatePaymentRecord}
                    isProcessing={rowState.loading}
                    handleCancelButtonClick={cancelEditing}
                  />
                )}
                {!rowState.editingOn && (
                  <AuthorisedEditAndRemoveActions
                    handleEditButtonClick={turnOnEditing}
                    handleRemoveButtonClick={onRemoveRecordClicked}
                    confirmationOn={rowState.removeRecordConfirmationOn}
                    handleConfirmationYes={removeRecord}
                    handleConfirmationNo={closeRemoveConfirmation}
                    isProcessing={removingPayment}
                    isSuccess={isPaymentRemoved}
                    error={removingPaymentError}
                    onErrorClose={closeOnRemoveError}
                    onSuccessClose={closeOnSuccess}
                    recordCreatedByCode={record.paymentDeterminedByCode}
                    editingWarningMessage={record.editingWarningMessage}
                  />
                )}
              </>
            )
          }
          elDocument={
            record.removable && <SignDocumentButton onClick={() => setOpenDialog(true)} />
          }
        />
      )}
      {record.documentStatus.id === 'PASIRASYTAS' && (
        <PaymentBasicRow
          columnsToDisplay={tableState.columnsDisplayStatus}
          virs={
            <VirsNameLink
              virsName={record.virsName}
              virsLegalCode={Number(record.virsLegalCode)}
              virsId={record.virId}
            />
          }
          decisionDate={record.decisionDate}
          paymentDocuments={
            <ElDocumentLinks
              documents={record.paymentDocuments}
              onClickedOpenInDialog={openDocumentInNewTab}
            />
          }
          paymentOutlets={
            <OutletsAsLinksList outlets={record.paymentOutlets} virsId={record.virId} />
          }
          paymentAmount={formatStringOfNumber(record.paymentAmount)}
          validFrom={record.validFrom}
          validTo={
            <>
              {rowState.editingOn && (
                <DateInputImprovedCell
                  state={rowState.validTo}
                  setDate={setValidToDate}
                  helperItem={
                    <SaveAndCancelIcons
                      handleConfirmationYes={closePaymentRecord}
                      isProcessing={closingPayment}
                      handleCancelButtonClick={cancelEditing}
                    />
                  }
                />
              )}
              {!rowState.editingOn && record.hasUnsignedValidTo && (
                <TooltipOnRowRibbon status="RUOSIAMAS">
                  <TableCell
                    className={classes.dateColumn}
                    style={{ boxShadow: '8px 0 0 #50C9F3 inset' }}
                  >
                    {record.validTo}
                  </TableCell>
                </TooltipOnRowRibbon>
              )}
              {!rowState.editingOn && !record.hasUnsignedValidTo && (
                <TableCell className={classes.dateColumn}>{record.validTo}</TableCell>
              )}
            </>
          }
          actions={
            (!record.validTo || record.hasUnsignedValidTo) &&
            !rowState.editingOn && (
              <RowActionButtonGeneric
                label="Įvesti pabaigos datą"
                onButtonClicked={turnOnEditing}
              />
            )
          }
          elDocument={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ViewDocumentButton onClick={() => setOpenDialog(true)} />
              <AnnulDocumentButton
                annulmentDisablementReason={record.annulmentDisablementReason}
                onClick={openAnnulDocumentDialog}
              />
              {record.hasUnsignedValidTo && (
                <SignDocumentButton onClick={() => setOpenDialog(true)} />
              )}
            </div>
          }
        />
      )}

      <PreviewAndSignDocumentDialog
        openDialog={openDialog}
        documentPath={`payment-data/${documentId}`}
        documentId={documentId}
        documentStatus={record.documentStatus.id}
        hasUnsignedValidTo={record.hasUnsignedValidTo}
        onClose={handleDialogClose}
        onSignSuccess={() => reduxDispatch(getPaymentDataRequest())}
      />
      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingPaymentError}
        isProcessing={annullingPaymentRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingPaymentError}
      />
    </>
  );
};

export default withRouter(PaymentRowContainer);
