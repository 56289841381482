import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import VirsRoute from './virs/VirsRoute';
import InstitutionRoute from './institution/InstitutionRoute';
import LoginPage from './authorisation/LoginPage';
import { ApplicationState } from '../store';
import { fetchUser } from '../store/virsis/actions';
import AgreementDialog from '../components/Dialogs/AgreementDialog';
import LegalRoute from './legal/LegalRoute';
import { AccountType } from '../store/virsis/dataTypes';
import ScrollToTop from '../components/Router/ScrollToTop';
import MessageDialog from '../components/MessageDialog/MessageDialog';
import EditEmailDialog from '../components/Dialogs/EditEmailDialog';
import FlowEmailDialog from '../components/Dialogs/FlowEmailDialog';
import RepresentationSelectDialog from '../components/Dialogs/RepresentationSelectDialog';
import { HeaderMenuField } from './pages';
import { BreadcrumbsContext } from '../components/Header/HeaderBreadCrumbs';
import { applicationStorage } from '../utils/axiosApi';

const LandingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser, authorise, fetchUserLoading, selectedAccountType } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  const context = applicationStorage.getItem('context');
  //const devEnvironment = !!process.env.REACT_APP_DEV;
  const devEnvironment = false;

  useEffect(() => {
    if (!currentUser) dispatch(fetchUser());
  }, [dispatch, currentUser]);

  const virsContext = () => {
    if (selectedAccountType === AccountType.VIRS) {
      return <VirsRoute />;
    }
    if (selectedAccountType === AccountType.LEGAL) {
      return <LegalRoute />;
    }
    return <div />;
  };

  const selectedContext = (): JSX.Element => {
    if (currentUser && context) {
      if (context === 'INST') {
        return <InstitutionRoute />;
      }
      if (context === 'VIRS') {
        return virsContext();
      }
    }
    return fetchUserLoading || authorise ? <CircularProgress /> : <></>;
  };

  const [breadcrumbs, setBreadcrumbs] = useState<HeaderMenuField[]>([]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      <MessageDialog />
      <Router basename={devEnvironment ? '' : '/web'}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route>{selectedContext()}</Route>
        </Switch>
      </Router>
      <FlowEmailDialog />
      <EditEmailDialog />
      <RepresentationSelectDialog />
      {currentUser && <AgreementDialog />}
    </BreadcrumbsContext.Provider>
  );
};
export default LandingPage;
