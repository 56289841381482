import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { InputStateGeneric, DatePickerState } from '../../../../../utils/tableTypes';
import { OutletRepresentative } from '../../../../../store/outletInfoData/outletInfoDataTypes';

export interface RepresentativeRecordState {
  validFrom: DatePickerState;
  validTo: DatePickerState;
  representationNotes: InputStateGeneric<string>;
  loading: boolean;
}

export const initialRepresentativeRecordState: RepresentativeRecordState = {
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  validTo: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  representationNotes: {
    value: null,
    id: 'representationNotes',
    error: false,
    helperText: '',
    placeholder: '',
    validated: true
  },
  loading: false
};

export interface RepresentativeNewRowState extends RepresentativeRecordState {
  createRecordConfirmationOn: boolean;
}

export const initialRepresentativeNewRowState: RepresentativeNewRowState = {
  ...initialRepresentativeRecordState,
  createRecordConfirmationOn: false
};

export interface RepresentativeEditRowState extends RepresentativeRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

export const initialRepresentativeEditRowState: RepresentativeEditRowState = {
  editingOn: false,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false,
  ...initialRepresentativeRecordState
};

type RepresentativeRowAction =
  | {
      type: 'REPRESENTATION_FROM_DATE_CHANGED';
      validFrom: MaterialUiPickersDate | null;
    }
  | {
      type: 'REPRESENTATION_TO_DATE_CHANGED';
      validTo: MaterialUiPickersDate | null;
    }
  | {
      type: 'REPRESENTATION_NOTES_CHANGED';
      representationNotes: string | null;
    };

export type RepresentativeNewRowAction =
  | RepresentativeRowAction
  | {
      type: 'NEW_RECORD_INITIALIZED';
      outletControlledFrom: string | null;
      outletControlledTo: string | null;
    }
  | { type: 'CREATING_CANCELLED' }
  | { type: 'CREATE_RECORD_CLICKED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };

export type RepresentativeEditRowAction =
  | RepresentativeRowAction
  | {
      type: 'EDITING_INITIALIZED';
      record: OutletRepresentative;
      outletControlledFrom: string | null;
      outletControlledTo: string | null;
    }
  | { type: 'EDITING_CANCELLED' }
  | { type: 'UPDATE_RECORD_CLICKED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'CLEAR_RECORD_VALID_TO_DATE_CLICKED' }
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' };
