import { AuditData } from '../../../store/audits/auditsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import { ColumnDisplayStatus } from '../../../utils/tableTypes';
import { AuditsColumnHeader, AuditsHeaderField } from './tableInitialStateAndTypes';

export const getTableExportDefinition = (
  columnHeaders: AuditsColumnHeader[],
  displayStatus: ColumnDisplayStatus<AuditsHeaderField>
): TableExportDefinition<AuditData> => {
  const { stringValueColumn } = getColumnBuilder<AuditData, AuditsHeaderField>(
    columnHeaders,
    displayStatus
  );

  return {
    title: 'Auditai',
    columnGroups: [
      {
        columns: [
          stringValueColumn('auditActionTypeName', { width: 15 }),
          stringValueColumn('actionUserFullName', { width: 15 }),
          stringValueColumn('actionLegalPersonFullName', { width: 15 }),
          stringValueColumn('actionDateTime', { width: 15 }),
          stringValueColumn('relatedVirs', { width: 15 }),
          stringValueColumn('relatedOutlet', { width: 15 }),
          stringValueColumn('relatedOutletTypeName', { width: 15 }),
          stringValueColumn('relatedPersonFullName', { width: 15 }),
          stringValueColumn('ipAddress', { width: 15 }),
          stringValueColumn('auditDetails', { width: 45 })
        ]
      }
    ]
  };
};
