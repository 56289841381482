import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Prop {
  label: string;
  value: string | number;
  url: string;
}

export const LabelLinkField: React.FC<Prop> = ({ label, value, url }) => {
  const classes = makeStyles((theme) => ({
    infoRow: {
      width: 'auto',
      padding: 8,
      alignItems: 'center',
      margin: '5px 30px',
      borderRadius: 5,
      '&:hover': {
        backgroundColor: '#F5F8FF'
      },
      [theme.breakpoints.down('xs')]: {
        wordWrap: 'break-word',
        hyphens: 'auto',
        margin: '0px 0px',
        width: '150%'
      }
    },
    label: {
      fontSize: '0.9rem',
      fontWeight: 400,
      textAlign: 'right',
      marginRight: 8,
      color: '#5A5A5A'
    }
  }))();

  return (
    <Link to={url} style={{ textDecoration: 'none' }}>
      <Grid container className={classes.infoRow}>
        <Grid item xs={6}>
          <Box className={classes.label}>{label}</Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="primary" style={{ marginLeft: 8 }}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};
