import React, { ChangeEvent, ReactNode } from 'react';
import { TableCell, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import { DropdownMultipleStateGeneric } from '../../utils/tableTypes';
import { FormikFieldStyleProps } from '../FormikFields/FieldStyles';
import { noOptionsText } from '../TableCustomFilterCell/constants';
import theme from '../../style/virsisTheme';

interface DropdownMultipleInputProps<T> extends FormikFieldStyleProps {
  selectValues: (selectedValues: T[]) => void;
  state: DropdownMultipleStateGeneric<T>;
  getOptionLabel: (object: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
  getOptionDisabled?: (option: T, selected: T[]) => boolean;
  renderOption: (option: T, state: AutocompleteRenderOptionState) => ReactNode;
}

export function DropdownMultipleInput<T>({
  selectValues,
  state,
  getOptionLabel,
  getOptionSelected,
  getOptionDisabled,
  style,
  renderOption
}: DropdownMultipleInputProps<T>) {
  function handleOptionsSelected(event: ChangeEvent<object>, values: T[]) {
    selectValues(values.filter((value) => !getOptionDisabled?.(value, values)));
  }

  return (
    <Autocomplete
      size="small"
      autoComplete
      clearOnEscape
      multiple
      noOptionsText={noOptionsText}
      style={{ width: '100%' }}
      options={state.options}
      disabled={!state.options}
      value={state.values}
      getOptionLabel={getOptionLabel}
      onChange={handleOptionsSelected}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={(option) => getOptionDisabled?.(option, state.values) || false}
      renderOption={renderOption}
      disableCloseOnSelect
      renderInput={(params) => (
        <form noValidate autoComplete="off">
          <TextField
            required
            label={state.placeholder}
            error={state.error}
            helperText={state.helperText}
            FormHelperTextProps={{ style: style?.helper }}
            {...params}
            variant="outlined"
          />
        </form>
      )}
      ChipProps={{ style: { maxWidth: '250px' } }}
      limitTags={2}
    />
  );
}

DropdownMultipleInput.defaultProps = {
  renderOption: undefined
};

interface CellProps {
  rowSpan?: number;
}

export function DropdownMultipleInputCell<T>({
  rowSpan,
  ...props
}: DropdownMultipleInputProps<T> & CellProps) {
  return (
    <TableCell
      style={{
        minWidth: '100px',
        backgroundColor: props.state.error ? theme.palette.error.light : undefined
      }}
      rowSpan={rowSpan}
    >
      <DropdownMultipleInput {...props} />
    </TableCell>
  );
}

DropdownMultipleInputCell.defaultProps = {
  rowSpan: undefined,
  renderOption: undefined
};
