import { OutletGroup, OutletType } from '../../../../store/outlets/outletsTypes';
import { Roles, VirsisUser } from '../../../../store/virsis/dataTypes';
import {
  getIsNotEmptyValidator,
  getIsShorterThanValidator,
  getIsUnTrimmedValidator,
  validateInputValue,
  validateMandatoryDate,
  validateOptionalDateImproved,
  validateSingleChoice
} from '../../../../utils/InputValueFunctions';
import { hasRoles } from '../../../../utils/roleHelper';
import { DatePickerState, RelevancyStatus, VirsisYesNoString } from '../../../../utils/tableTypes';
import {
  OutletFullyEditRowState,
  OutletNewRowState,
  OutletRenewRowState,
  OutletSemiEditRowState
} from './outletRowStateAndTypes';

export function getRedirectButtonLabelsByRoles(
  currentUser: VirsisUser | undefined,
  relevancyStatus: RelevancyStatus
): string {
  return currentUser?.authorities &&
    hasRoles(currentUser, [Roles.ROLE_VIRS, Roles.ROLE_VIRS_EDIT]) &&
    relevancyStatus !== 'I'
    ? 'Pildyti'
    : 'Peržiurėti';
}

export function findOutletTypeOrNull(
  outletTypes: OutletType[],
  outletTypeId: number
): OutletType | null {
  const type = outletTypes.find((outletType) => outletType.outletTypeId === outletTypeId);
  return type || null;
}
export function findOutletGroupOrNull(
  outletTypes: OutletGroup[],
  outletGroupId: number
): OutletGroup | null {
  const group = outletTypes.find((outletGroup) => outletGroup.outletGroupId === outletGroupId);
  return group || null;
}

const nameValidators = [
  getIsNotEmptyValidator('Privalomas pavadinimas'),
  getIsUnTrimmedValidator('Pavadinimas negali prasidėti arba pasibaigti tarpu'),
  getIsShorterThanValidator(500, 'Pavadinimas turi būti trumpesnis nei 500 simbolių')
];

const internationalNumberValidatorsWhenLibis = [
  getIsNotEmptyValidator('Privalomas tarptautinis nr.'),
  getIsUnTrimmedValidator('Tarptautinis nr. negali prasidėti arba pasibaigti tarpu'),
  getIsShorterThanValidator(500, 'Tarptautinis nr. turi būti trumpesnis nei 500 simbolių')
];

export function validatedNewOutletState(state: OutletNewRowState): OutletNewRowState {
  const validatedName = validateInputValue(state.name, nameValidators, 'Privaloma užpildyti');

  const validatedType = validateSingleChoice(state.outletType, 'Būtina pasirinkti iš sąrašo');

  const establishedValidated: DatePickerState = validateMandatoryDate({
    ...state.established
  });

  const controlledFromValidated: DatePickerState = validateMandatoryDate({
    ...state.controlledFrom
  });

  const controlledToValidated: DatePickerState = validateOptionalDateImproved({
    ...state.controlledTo
  });

  const usesLibis = state?.outletType?.value?.usesLibis === VirsisYesNoString.YES;

  const internationalNumberValidated = validateInputValue(
    state.internationalNumber,
    usesLibis ? internationalNumberValidatorsWhenLibis : []
  );

  return {
    ...state,
    name: validatedName,
    outletType: validatedType,
    established: establishedValidated,
    controlledFrom: controlledFromValidated,
    controlledTo: controlledToValidated,
    internationalNumber: internationalNumberValidated,
    createRecordConfirmationOn:
      validatedName.validated &&
      validatedType.validated &&
      establishedValidated.validated &&
      controlledFromValidated.validated &&
      controlledToValidated.validated &&
      internationalNumberValidated.validated
  };
}

export function validatedEditOutletState(state: OutletFullyEditRowState): OutletFullyEditRowState {
  const validatedName = validateInputValue(state.name, nameValidators, 'Privaloma užpildyti');

  const validatedType = validateSingleChoice(state.outletType, 'Būtina pasirinkti iš sąrašo');

  const establishedValidated: DatePickerState = validateMandatoryDate({
    ...state.established
  });

  const controlledFromValidated: DatePickerState = validateMandatoryDate({
    ...state.controlledFrom
  });
  const controlledToValidated: DatePickerState = validateOptionalDateImproved({
    ...state.controlledTo
  });

  const usesLibis = state?.outletType?.value?.usesLibis === VirsisYesNoString.YES;

  const internationalNumberValidated = validateInputValue(
    state.internationalNumber,
    usesLibis ? internationalNumberValidatorsWhenLibis : []
  );

  return {
    ...state,
    name: validatedName,
    outletType: validatedType,
    established: establishedValidated,
    controlledFrom: controlledFromValidated,
    controlledTo: controlledToValidated,
    internationalNumber: internationalNumberValidated,
    updateConfirmationOn:
      validatedName.validated &&
      validatedType.validated &&
      establishedValidated.validated &&
      controlledFromValidated.validated &&
      controlledToValidated.validated &&
      internationalNumberValidated.validated
  };
}

export function validatedOutletRecordRowStateForDate(
  state: OutletSemiEditRowState
): OutletSemiEditRowState {
  const controlledToValidated: DatePickerState = validateMandatoryDate({
    ...state.controlledTo
  });
  return {
    ...state,
    controlledTo: controlledToValidated,
    updateDateConfirmationOn: controlledToValidated.validated
  };
}

export function validatedRenewState(state: OutletRenewRowState): OutletRenewRowState {
  const controlledFromValidated: DatePickerState = validateMandatoryDate({
    ...state.controlledFrom
  });

  const controlledToValidated: DatePickerState = validateOptionalDateImproved({
    ...state.controlledTo
  });

  return {
    ...state,
    controlledFrom: controlledFromValidated,
    controlledTo: controlledToValidated,
    renewConfirmationOn: controlledFromValidated.validated && controlledToValidated.validated
  };
}
