import { action } from 'typesafe-actions';
import {
  SEARCH_NATURAL_PERSON_REQUEST,
  SEARCH_NATURAL_PERSON_SUCCESS,
  SEARCH_NATURAL_PERSON_ERROR,
  CREATE_NATURAL_FOREIGN_PERSON_REQUEST,
  CREATE_NATURAL_FOREIGN_PERSON_SUCCESS,
  CREATE_NATURAL_FOREIGN_PERSON_ERROR,
  RESET_CREATE_NATURAL_FOREIGN_PERSON_STATE,
  RESET_SEARCH_NATURAL_PERSON_STATE,
  SEARCH_NATURAL_PERSON_FOREIGN_ERROR,
  SEARCH_NATURAL_PERSON_FOREIGN_SUCCESS,
  SEARCH_NATURAL_PERSON_FOREIGN_REQUEST,
  RESET_SEARCH_NATURAL_PERSON_FOREIGN_STATE,
  SELECT_PERSON_REPRESENTATIVE,
  RESET_PERSON_REPRESENTATIVE,
  SELECT_PERSON_SEARCH_QUERY,
  RESET_PERSON_SEARCH_QUERY
} from './personsActionTypes';
import {
  NaturalPersonSearchQuery,
  NaturalPersonSearchData,
  NaturalForeignPersonRecord
} from './personsTypes';

export const searchNaturalPersonData = (naturalPersonQuery: NaturalPersonSearchQuery) =>
  action(SEARCH_NATURAL_PERSON_REQUEST, naturalPersonQuery);
export const searchNaturalPersonDataSuccess = (naturalPersonData: NaturalPersonSearchData) =>
  action(SEARCH_NATURAL_PERSON_SUCCESS, naturalPersonData);
export const searchNaturalPersonDataError = (error: Error) =>
  action(SEARCH_NATURAL_PERSON_ERROR, error);
export const resetSearchNaturalPersonData = () => action(RESET_SEARCH_NATURAL_PERSON_STATE);

export const searchNaturalPersonForeignData = (naturalPersonQuery: NaturalPersonSearchQuery) =>
  action(SEARCH_NATURAL_PERSON_FOREIGN_REQUEST, naturalPersonQuery);
export const searchNaturalPersonForeignDataSuccess = (naturalPersonData: NaturalPersonSearchData) =>
  action(SEARCH_NATURAL_PERSON_FOREIGN_SUCCESS, naturalPersonData);
export const searchNaturalPersonForeignDataError = (error: Error) =>
  action(SEARCH_NATURAL_PERSON_FOREIGN_ERROR, error);
export const resetSearchNaturalForeignPersonData = () =>
  action(RESET_SEARCH_NATURAL_PERSON_FOREIGN_STATE);

export const createNaturalForeignPerson = (
  naturalForeignPersonRecord: NaturalForeignPersonRecord
) => action(CREATE_NATURAL_FOREIGN_PERSON_REQUEST, naturalForeignPersonRecord);
export const createNaturalForeignPersonSuccess = () =>
  action(CREATE_NATURAL_FOREIGN_PERSON_SUCCESS);
export const createNaturalForeignPersonError = (error: Error) =>
  action(CREATE_NATURAL_FOREIGN_PERSON_ERROR, error);
export const resetCreateNaturalForeignPersonState = () =>
  action(RESET_CREATE_NATURAL_FOREIGN_PERSON_STATE);

export const selectPersonRepresentative = (representative: NaturalPersonSearchData | undefined) =>
  action(SELECT_PERSON_REPRESENTATIVE, representative);
export const resetPersonRepresentative = () => action(RESET_PERSON_REPRESENTATIVE);

export const selectPersonSearchQuery = (query: NaturalPersonSearchQuery) =>
  action(SELECT_PERSON_SEARCH_QUERY, query);
export const resetPersonSearchQuery = () => action(RESET_PERSON_SEARCH_QUERY);
