import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { fetchJarCountriesRequest } from '../../store/virsis/actions';
import {
  resetPersonRepresentative,
  resetPersonSearchQuery
} from '../../store/persons/personsActions';

import { FindPersonPage } from '../FindPersonDialog/FindPerson/FindPersonPage';
import { resetLegalRepresentative } from '../../store/legalPersonData/legalDataActions';
import { CloseContinueButton } from '../Dialogs/Dialog/CloseContinueButton';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';

interface Props {
  dialogOpen: boolean;
  closeDialog: () => void;
  closeAndContinueAction: () => void;
  checkIfPersonCanBeSelected?: (
    legalFormCode: number,
    personType: string,
    personTypeCode: number
  ) => boolean;
  tooltipTextOnToggleButton?: string;
}

const useStyles = makeStyles((theme) => ({
  dialogActionsButton: {
    margin: 8
  },
  [theme.breakpoints.down('sm')]: {
    dialogTitle: {
      padding: '20px 15px 10px 15px !important',
      justifyContent: 'space-between',
      flexWrap: 'nowrap'
    },
    dialogTitleButton: {
      paddingLeft: 0,
      paddingRight: 0
    },
    dialogMainContent: {
      padding: '10px 15px 10px 15px'
    },
    dialogActionsButton: {
      margin: 0
    }
  }
}));

export const AuditNaturalPersonSearchDialog: React.FC<Props> = ({
  dialogOpen,
  closeDialog,
  closeAndContinueAction
}) => {
  const reduxDispatch = useDispatch();
  const classes = useStyles();

  const { jarCountries } = useSelector((state: ApplicationState) => state.virsis);

  const { selectedPersonRepresentative } = useSelector((state: ApplicationState) => state.virsis);

  useEffect(() => {
    if (!jarCountries) {
      reduxDispatch(fetchJarCountriesRequest());
    }
  }, [jarCountries, reduxDispatch]);

  function closeNewRepDialogAndGoBack() {
    closeDialog();
    reduxDispatch(resetPersonSearchQuery());
    reduxDispatch(resetPersonRepresentative());
    reduxDispatch(resetLegalRepresentative());
  }

  function closeNewRepDialogAndContinue() {
    closeDialog();
    closeAndContinueAction();
    reduxDispatch(resetPersonSearchQuery());
  }

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} onExit={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className={'dialog-title ' + classes.dialogTitle}>
        <Typography variant="subtitle1">Fizinio asmens paieška</Typography>
        <CustomCloseIconButton
          className={classes.dialogTitleButton}
          onClick={closeNewRepDialogAndGoBack}
        />
      </DialogTitle>
      <DialogContent>
        <div className={'dialog-main-content ' + classes.dialogMainContent}>
          <FindPersonPage />
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Box right={30} component="span" className={classes.dialogActionsButton}>
          <Button variant="outlined" onClick={closeNewRepDialogAndGoBack}>
            <Typography>Atgal</Typography>
          </Button>
        </Box>
        <Box right={30} component="span" className={classes.dialogActionsButton}>
          <CloseContinueButton
            onClick={closeNewRepDialogAndContinue}
            disabled={!selectedPersonRepresentative}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
