import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { EditAndRemoveActions, EditAndRemoveActionsProps } from './EditAndRemoveActions';

export const AuthorisedEditAndRemoveActions: React.FC<
  EditAndRemoveActionsProps & { recordCreatedByCode?: string; editingWarningMessage?: string }
> = ({ recordCreatedByCode, editingWarningMessage, ...props }) => {
  const user = useSelector((state: ApplicationState) => state.virsis.currentUser);

  const userCanModify = user && user.amnCode === recordCreatedByCode;
  return (
    <EditAndRemoveActions
      editWarningReason={!userCanModify ? editingWarningMessage : undefined}
      {...props}
    />
  );
};
