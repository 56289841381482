import React, { useReducer, useContext, Dispatch, createContext } from 'react';

import { paymentTableReducer } from './tableState/tableReducer';
import {
  PaymentDataTableState,
  PaymentDataTableAction,
  initialPaymentDataTableState
} from './tableState/tableInitialStateAndTypes';
import PaymentDataTable from './Table';

const PaymentDataTableStateContext = createContext<{
  state: PaymentDataTableState;
}>({
  state: initialPaymentDataTableState
});

const PaymentDataTableDispatchContext = createContext<{
  dispatch: Dispatch<PaymentDataTableAction>;
}>({
  dispatch: () => {}
});

export const PaymentDataVirsTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(paymentTableReducer, initialPaymentDataTableState);
  return (
    <PaymentDataTableStateContext.Provider value={{ state }}>
      <PaymentDataTableDispatchContext.Provider value={{ dispatch }}>
        <PaymentDataTable />
      </PaymentDataTableDispatchContext.Provider>
    </PaymentDataTableStateContext.Provider>
  );
};

export function usePaymentDataTableState() {
  return useContext(PaymentDataTableStateContext);
}
export function usePaymentDataTableDispatch() {
  return useContext(PaymentDataTableDispatchContext);
}
