import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  OutletDataTableColumn,
  OutletDataTableField,
  OutletDataTableColumnsDisplayStatus
} from './tableState/initialStateAndTypes';
import { OutletData } from '../../store/outlets/outletsTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnParams: OutletDataTableColumn[];
  columnsDisplayStatus: OutletDataTableColumnsDisplayStatus;
  onCustomFilterChange: (column: OutletDataTableField, value: string | null) => void;
  tableData: OutletData[];
}

const FilterRow: React.FC<Props> = ({
  columnParams,
  columnsDisplayStatus,
  onCustomFilterChange,
  tableData
}) => {
  function getUniqueOptions(data: OutletData[], objectField: OutletDataTableField): string[] {
    const allValues = data
      .filter((record) => record[objectField] && record[objectField] !== '')
      .map((record) => record[objectField]);
    return Array.from(new Set(allValues));
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnParams
          .filter((header) => columnsDisplayStatus[header.id])
          .map((header) => (
            <TableCustomFilterCellGeneric
              key={header.id}
              id={header.id}
              type={header.type}
              setFilter={onCustomFilterChange}
              uniqueOptions={getUniqueOptions(tableData, header.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FilterRow;
