import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ApplicationState } from '../../store';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { pageTableData } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import {
  useInstitutionDataReportTableDispatch,
  useInstitutionDataReportTableState
} from './Context';
import InstitutionDataReportTableCustomFilter from './FilterRow';
import { InstitutionDataReportTableCustomizationBar } from './TableCustomization';
import InstitutionDataReportTableHead from './TableHeader';
import { InstitutionDataReportTableRow } from './TableRow';
import {
  filterAndSortTableData,
  getPeriodFilterInstitutionDataReportData,
  getTableExportDefinition
} from './tableState/tableStateFunctions';

const InstitutionDataReportDataTable: React.FC = () => {
  const {
    institutionDataReportData,
    loadingInstitutionDataReportData,
    institutionDataReportDataError
  } = useSelector((state: ApplicationState) => state.institutionDataReportData);

  const { dispatch: tableDispatch } = useInstitutionDataReportTableDispatch();
  const { state: tableState } = useInstitutionDataReportTableState();

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const periodFilteredData = getPeriodFilterInstitutionDataReportData(
    institutionDataReportData || [],
    tableState.periodFilter
  );

  const filteredData = filterAndSortTableData(tableState, periodFilteredData);

  const { page: pagedData, pagesCount } = pageTableData(tableState, filteredData);

  return (
    <div className="named-data-table">
      <Box display="flex" flexGrow={1} justifyContent="flex-end" flexWrap="wrap">
        <PageActionButtons
          dontShowDelete
          onDownload={(format, navigationPath) =>
            exportTableData(
              format,
              getTableExportDefinition(tableState),
              filteredData,
              navigationPath
            )
          }
          onPrint={(navigationPath) =>
            printTableData(getTableExportDefinition(tableState), filteredData, navigationPath)
          }
        />
      </Box>

      <Paper elevation={0}>
        <InstitutionDataReportTableCustomizationBar />
        <div style={{ overflowX: 'auto' }}>
          <Table aria-label="simple table">
            <InstitutionDataReportTableHead />

            {tableState.customFilterOn && <InstitutionDataReportTableCustomFilter />}

            <TableBody>
              {loadingInstitutionDataReportData && (
                <TableRow>
                  <TableCell colSpan={9} style={{ padding: 0 }}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}

              <TableErrorRow
                error={
                  institutionDataReportDataError &&
                  'Klaida. Nepavyko gauti pranešimų apie netikslius duomenis'
                }
                colSpan={9}
              />

              {!loadingInstitutionDataReportData &&
                !institutionDataReportDataError &&
                pagedData.map((record) => {
                  return <InstitutionDataReportTableRow key={record.reportId} record={record} />;
                })}
            </TableBody>
          </Table>
        </div>

        <TablePagination
          recordsCount={filteredData.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={filteredData.length === 0}
        />
      </Paper>
    </div>
  );
};

export default InstitutionDataReportDataTable;
