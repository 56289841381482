import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TableLoaderRow } from '../../components/TableLoaderRow/TableLoaderRow';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ApplicationState } from '../../store';
import { stableSortWrapped, pageTableData } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { useAuditsTableDispatch, useAuditsTableState } from './Context';
import { AuditsTableCustomizationBar } from './TableCustomization';
import { AuditsTableHeader } from './TableHeader';
import { AuditsTableRow } from './TableRow';
import { AuditsContentDialog } from '../../components/AuditsContentDialog/AuditsContentDialog';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';

const useStyles = makeStyles((theme) =>
createStyles({
  tableBox: {
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    addButton: {
      width: '100%',
      justifyContent: 'flex-start',
      margin: 0,
      paddingLeft: 0,
    },
    pageActionButtonBox: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      blockSize: 'fit-content'
    }
  }
})
);

export const AuditsTable: React.FC = () => {
  const { dispatch: tableDispatch } = useAuditsTableDispatch();
  const { state: tableState } = useAuditsTableState();
  const classes = useStyles();
  const { loadingAuditsData, auditsData, auditsDataError } = useSelector(
    (stateGlobal: ApplicationState) => stateGlobal.auditsData
  );

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };
  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const viewInDialog = (content: string) => {
    return () => {
      tableDispatch({
        type: 'CONTENT_OPENED',
        content
      });
    };
  };

  const closeDialog = () => {
    tableDispatch({
      type: 'CONTENT_CLOSED'
    });
  };

  const sortedData = stableSortWrapped(auditsData || [], tableState.order, tableState.sortBy);

  const { page: pagedData, pagesCount } = pageTableData(tableState, sortedData);

  return (
    <>
      {tableState.contentToView && (
        <AuditsContentDialog
          title="Papildomi duomenys"
          content={tableState.contentToView}
          close={closeDialog}
        />
      )}
      <Divider />

      <div className="named-data-table">
        <div className="table-title">
          <Box className={classes.buttonWrapper}>
            <Typography variant="subtitle1">Paieškos rezultatai</Typography>
            <Box className={classes.pageActionButtonBox}>
              <PageActionButtons
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    pagedData,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, pagedData, navigationPath)
                }
                dontShowDelete
              />
            </Box>
          </Box>
        </div>
        <Paper elevation={0}>
          <AuditsTableCustomizationBar />
          <Box className={classes.tableBox}>
          <Table aria-label="simple table">
            <AuditsTableHeader />
            <TableBody>
              <TableLoaderRow colSpan={11} isLoading={loadingAuditsData} />
              <TableErrorRow
                error={auditsDataError && 'Klaida. Nepavyko gauti audito duomenų'}
                colSpan={11}
              />
              {pagedData.map((record) => {
                return (
                  <AuditsTableRow
                    key={record.auditId}
                    record={record}
                    viewInDialog={viewInDialog(record.auditDetails)}
                  />
                );
              })}
            </TableBody>
          </Table>
          </Box>
          <TablePagination
            recordsCount={sortedData.length}
            pagesCount={pagesCount}
            rowsPerPage={tableState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={sortedData.length === 0}
          />
        </Paper>
      </div>
    </>
  );
};
