import React, { ChangeEvent, useEffect } from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../../../store';

import {
  createShareholderRequest,
  updateShareholderRequest,
  unsetUpdateShareholderId
} from '../../../../../store/shareholders/shareholdersActions';
import {
  useShareholdersSelectedDateTableState,
  useShareholdersSelectedDateTableDispatch
} from '../../TableWithContext';
import {
  getIsPersonUnique,
  getShareholderPersonsIds,
  formAddShareholderPerson
} from '../../utilities/utilityFunctions';
import { mainDarkColor } from '../../../constants';
import { ShareholderShareTypes } from '../../../../../store/classifiers/classifiersTypes';
import { ParentDetails } from './components/ParentDetails/ParentDetails';
import { ShareholderDetails } from './components/ShareholderDetails/ShareholderDetails';
import {
  ShareholdersData,
  ShareholderPerson
} from '../../../../../store/shareholders/shareholdersTypes';

interface Props {
  isShareholderFromJadis?: boolean;
  mayShareholderHaveChildren: boolean;
  parentLegalFormCode: number | null;
  parentPersonId: number;
  index: number;
  parentShareholder: ShareholderPerson | ShareholdersData;
  shareholderLevel: number;
}

export const EditShareholderRow: React.FC<Props> = ({
  isShareholderFromJadis,
  mayShareholderHaveChildren,
  parentLegalFormCode,
  index,
  parentPersonId,
  parentShareholder,
  shareholderLevel
}) => {
  const reduxDispatch = useDispatch();
  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();

  const {
    virsis: { virsData },
    classifiers: { legalFormTypes },
    shareholdersData: {
      loadingCreateShareholder,
      loadingUpdateShareholder,
      loadingShareholdersSelectedDateData,
      loadingSynchroniseWithJADIS,
      selectedDate,
      shareholdersData,
      shareholderToEditId
    }
  } = useSelector((state: ApplicationState) => state);

  const {
    state: {
      authorizedPersonId,
      isGroup,
      personToAdd,
      shareholderPersonsList,
      shareholderToEditList
    }
  } = useShareholdersSelectedDateTableState();

  const isSelectedPersonUnique = getIsPersonUnique(
    personToAdd?.personId || 0,
    shareholderPersonsList
  );

  useEffect(() => {
    if (personToAdd) {
      if (isSelectedPersonUnique && legalFormTypes) {
        tableDispatch({
          type: 'SET_SHAREHOLDER_PERSONS_LIST',
          shareholderPerson: formAddShareholderPerson(personToAdd, legalFormTypes)
        });
      }
    }

    if (shareholderToEditList?.length && legalFormTypes) {
      tableDispatch({
        type: 'SET_SHAREHOLDER_PERSONS_LIST',
        shareholderPerson: shareholderToEditList
      });
    }
  }, [isSelectedPersonUnique, legalFormTypes, personToAdd, shareholderToEditList, tableDispatch]);

  const handleSaveButtonClick = (selectedShareholderShareTypes: ShareholderShareTypes) => {
    if (virsData && shareholdersData && selectedShareholderShareTypes && selectedDate) {
      const record = {
        virsId: virsData.virsId,
        shareholderPersonsIds: getShareholderPersonsIds(shareholderPersonsList),
        parentPersonId,
        authorizedPersonId,
        selectedDate: shareholderToEditId ? shareholdersData.virsStartDate : selectedDate,
        ...selectedShareholderShareTypes
      };
      const personsFromJADIS = shareholderPersonsList.filter(
        (shareholderPerson) => shareholderPerson.shouldSynchronize === true
      );

      if (shareholderToEditId) {
        reduxDispatch(
          updateShareholderRequest(
            record,
            shareholderToEditId,
            virsData.virsId,
            selectedDate,
            personsFromJADIS
          )
        );
      } else {
        reduxDispatch(
          createShareholderRequest(record, virsData.virsId, selectedDate, personsFromJADIS)
        );
      }
    }
  };

  const handleFindPersonDialogOpen = () => {
    tableDispatch({
      type: 'IS_FIND_PERSON_DIALOG_OPEN',
      isPersonSearchDialogOpen: true,
      index: index
    });
  };

  const handleRemovePersonFromPersonsList = (personId: number) => {
    tableDispatch({
      type: 'REMOVE_SHAREHOLDER_PERSON',
      personId
    });

    if (authorizedPersonId === personId) {
      tableDispatch({
        type: 'SET_AUTHORIZED_PERSON_ID',
        authorizedPersonId: null
      });
    }
  };

  const handleAuthorizedPersonChange = (event: ChangeEvent<HTMLInputElement>) => {
    tableDispatch({
      type: 'SET_AUTHORIZED_PERSON_ID',
      authorizedPersonId: Number(event.target.value)
    });
  };

  const handleEditShareholderRowClose = () => {
    tableDispatch({
      type: 'EDIT_SHAREHOLDER_ROW_CLOSE'
    });

    reduxDispatch(unsetUpdateShareholderId());
  };

  return (
    <TableRow>
      <TableCell>
        <Typography variant="h5" style={{ color: mainDarkColor }}>
          {shareholderLevel}
        </Typography>
      </TableCell>
      <TableCell colSpan={1}>
        <ShareholderDetails
          index={index}
          authorizedPersonId={authorizedPersonId || null}
          isGroup={isGroup}
          onAddPersonClick={handleFindPersonDialogOpen}
          onIconClick={handleFindPersonDialogOpen}
          onRadioChange={handleAuthorizedPersonChange}
          onRemove={handleRemovePersonFromPersonsList}
          shareholderLevel={shareholderLevel}
          shareholderPersonsList={shareholderPersonsList}
          isShareholderFromJadis={!!isShareholderFromJadis}
          isSelectedPersonUnique={isSelectedPersonUnique}
          mayShareholderHaveChildren={mayShareholderHaveChildren}
        />
      </TableCell>
      <TableCell colSpan={3}>
        <ParentDetails
          onClose={handleEditShareholderRowClose}
          onFormSubmit={handleSaveButtonClick}
          parentLegalFormCode={parentLegalFormCode}
          parentShareholder={parentShareholder}
          isProcessing={
            loadingCreateShareholder ||
            loadingUpdateShareholder ||
            loadingShareholdersSelectedDateData
          }
          isSynchronizingJADIS={loadingSynchroniseWithJADIS}
        />
      </TableCell>
    </TableRow>
  );
};
