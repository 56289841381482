import React from 'react';
import { Container, makeStyles, Tab, Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Roles } from '../../../../store/virsis/dataTypes';
import { PreviewOrSignDocument } from '../../../document/PreviewOrSignDocument';
import AllowedTo from '../../../AllowedTo';
import { ApplicationState } from '../../../../store';
import {
  ErrorDescriptorsContextDefault,
  errorDescriptorsInst
} from '../../../document/PreviewErrorsPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 3
  },
  tab: {
    fontFamily: 'open sans',
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 800,
    '&:nth-child(1)': {
      marginLeft: 0
    }
  },
  secondaryHeaderWrapper: {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      blockSize: 'fit-content'
    },
    justifyContent: 'space-between',
    wordWrap: 'break-word',
    hyphens: 'auto'
  },
  secondaryHeaderContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 'lg',
    wordWrap: 'break-word',
    hyphens: 'auto'
  }
}));

const SecondaryVirsDataEditingTabMenuHeader: React.FC = () => {
  const classes = useStyles();

  const { selectedPersonId, selectedVirsId } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  const history = useHistory();
  const match = useRouteMatch();

  const goToPath = (path: string) => {
    history.push(path);
  };

  if (!selectedVirsId) return null;

  return (
    <Container maxWidth="lg">
      <div className={classes.secondaryHeaderWrapper}>
        <div style={{ padding: ' 0 10px' }} className={classes.secondaryHeaderContainer}>
          <Tabs
            classes={{
              indicator: classes.indicator,
              root: classes.root
            }}
            value={match?.url.substring(match?.url.lastIndexOf('/'))}
            variant="scrollable"
          >
            <Tab
              value={`/duomenu-perziura-ir-teikimas`}
              className={classes.tab}
              onClick={() =>
                goToPath(
                  `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas/veiklos-rusys`
                )
              }
              label="Duomenų teikimas"
            />
            <Tab
              value={`/pateikti-duomenys`}
              className={classes.tab}
              onClick={() =>
                goToPath(`/virs-duomenys/${selectedVirsId}/pateikti-duomenys/instituciju-įstaigų`)
              }
              label="Institucijų / Įstaigų pateikti duomenys"
            />
          </Tabs>
        </div>
        <Box style={{ margin: 'auto 10px auto 0' }}>
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <ErrorDescriptorsContextDefault.Provider value={errorDescriptorsInst(selectedVirsId)}>
              <PreviewOrSignDocument personId={selectedPersonId} flexWrap="nowrap" />
            </ErrorDescriptorsContextDefault.Provider>
          </AllowedTo>
        </Box>
      </div>
    </Container>
  );
};

export default SecondaryVirsDataEditingTabMenuHeader;
