import React from 'react';
import { Button, createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      color: '#637082',
      border: '1px solid #637082',
      backgroundColor: 'transparent',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      width: '124px',
      '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary
      }
    }
  })
);
interface Props {
  label?: string;
  onClick: (event?: any) => void;
}

export const CloseDialogButton: React.FC<Props> = ({ label, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} variant="outlined" onClick={onClick}>
      <Typography variant="h5">{label}</Typography>
    </Button>
  );
};
