import React, { useEffect, useState } from 'react';
import { Paper, Table, Typography, TableBody, Container, Box, makeStyles } from '@material-ui/core';

import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ProvidedDataHistoryTableCustomizationBar } from './TableCustomization';
import ProvidedDataHistoryTableHead from './TableHead';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS, DataWithDocumentStatusProvidedData } from '../../utils/tableTypes';
import {
  dataContainsAnyDocumentStatusSignedRecord,
  dataContainsDocumentStatusAnnuledRecord,
  dataContainsRecordDocumentStatusNotSigned,
  pageTableData
} from '../../utils/tableDataFunctions';
import {
  getStatusFilteredCustomFilteredProvidedDataHistory,
  getSortedProvidedDataHistory,
  getPeriodFilterProvidedDataHistory
} from './tableState/tableStateFunctions';
import { useProvidedDataHistoryTableState, useProvidedDataHistoryTableDispatch } from './Context';
import ProvidedDataHistoryTableCustomFilter from './FilterRow';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { TableCircularProgressRow } from '../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { ProvidedDataHistoryRowContainer } from './TableRow/RowContainer';
import { ApplicationState } from '../../store';
import { AnnulRecord } from '../../store/classifiers/classifiersTypes';
import { providedDataInstColumns } from './tableState/tableInitialStateAndTypes';
import { AlertBox } from '../../components/Alert/AlertBox';
import { closeUnsignedDataAlert } from '../../store/virsis/actions';
import { fetchDataDeletionPeriodRequest } from '../../store/classifiers/classifiersActions';
import { ProvidedDataInst } from '../../store/providedData/providedDataTypes';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';

export interface ProvidedDataInstTableProps {
  loading: boolean;
  data: ProvidedDataInst[] | undefined;
  error: Error | undefined;
  remove: (documentStatusId: number) => void;
  removeUnsigned: () => void;
  onRemoveUnsignedSuccess: () => void;
  annul: (documentStatusId: number, annulRecord: AnnulRecord) => void;
}

const ProvidedDataHistoryTable: React.FC<RouteComponentProps & ProvidedDataInstTableProps> = ({
  loading,
  data,
  error,
  remove,
  removeUnsigned,
  onRemoveUnsignedSuccess,
  annul
}) => {
  const { state: tableState } = useProvidedDataHistoryTableState();
  const { dispatch: tableDispatch } = useProvidedDataHistoryTableDispatch();
  const {
    virsis: { showUnsignedDataAlert, currentUser },
    classifiers: { dataDeletionPeriod, loadingDataDeletionPeriod }
  } = useSelector((state: ApplicationState) => state);

  const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: "flex-end",
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        blockSize: 'fit-content'
      }
    }
  }));

  const reduxDispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    if (data) {
      const notSignedEnabled = dataContainsRecordDocumentStatusNotSigned(
        data as DataWithDocumentStatusProvidedData[]
      );
      const signedEnabled = dataContainsAnyDocumentStatusSignedRecord(
        data as DataWithDocumentStatusProvidedData[]
      );
      const annuledEnabled = dataContainsDocumentStatusAnnuledRecord(
        data as DataWithDocumentStatusProvidedData[]
      );

      tableDispatch({
        type: 'TABLE_INITIALIZED',
        notSignedEnabled,
        signedEnabled,
        annuledEnabled
      });
    }
    if (!dataDeletionPeriod) reduxDispatch(fetchDataDeletionPeriodRequest());
  }, [data, dataDeletionPeriod, reduxDispatch, tableDispatch]);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const periodFilteredData = getPeriodFilterProvidedDataHistory(
    data || [],
    tableState.periodFilter
  );

  const filteredData = getStatusFilteredCustomFilteredProvidedDataHistory(
    periodFilteredData || [],
    tableState.customFilter,
    tableState.showStatusNotSigned,
    tableState.showStatusSigned,
    tableState.showStatusAnnuled
  );

  const sortedData = getSortedProvidedDataHistory(
    filteredData,
    tableState.order,
    tableState.sortBy
  );

  const { page: dataPage, pagesCount } = pageTableData(tableState, sortedData);

  return (
    <>
      {showUnsignedDataAlert && dataDeletionPeriod && !loadingDataDeletionPeriod && (
        <AlertBox
          handleClose={() => reduxDispatch(closeUnsignedDataAlert())}
          text={`Dėmesio! Nepasirašyti duomenys bus automatiškai pašalinami po ${dataDeletionPeriod} d. nuo jų paskutinio redagavimo.`}
        />
      )}

      <ConfirmRemoveUnsignedDialog
        onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
        onConfirm={removeUnsigned}
        onRemoveSuccess={onRemoveUnsignedSuccess}
        open={confirmToDeleteUnsignedDialogOpen}
      />

      <Container maxWidth="lg">
        <div className="data-page">
          <div className="data-page-header">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">Duomenų istorija</Typography>
              <Box className={classes.buttonWrapper}>
                <PageActionButtons
                  onDownload={(format, navigationPath) =>
                    exportTableData(
                      format,
                      tableState.tableExportDefinition,
                      data || [],
                      navigationPath
                    )
                  }
                  onPrint={(navigationPath) =>
                    printTableData(tableState.tableExportDefinition, data || [], navigationPath)
                  }
                  onClickDelete={() => {
                    setConfirmToDeleteUnsignedDialogOpen(true);
                  }}
                />
              </Box>
            </Box>
          </div>
          <div className="named-data-table">
            <Paper elevation={0}>
              <ProvidedDataHistoryTableCustomizationBar columnNames={providedDataInstColumns} />
              <Box style={{overflow: 'auto'}}>
                <Table>
                  <ProvidedDataHistoryTableHead />
                  {tableState.customFilterOn && (
                    <ProvidedDataHistoryTableCustomFilter columnNames={providedDataInstColumns} />
                  )}
                  <TableBody>
                    <TableCircularProgressRow isLoading={loading && !error} colSpan={8} />
                    <TableErrorRow
                      error={error && 'Klaida. Nepavyko gauti istorijos duomenų'}
                      colSpan={8}
                    />
                    {!loading &&
                      currentUser &&
                      !error &&
                      dataPage.map((record) => {
                        return (
                          <ProvidedDataHistoryRowContainer
                            key={record.documentStatusId}
                            columnsToDisplay={tableState.columnsDisplayStatus}
                            record={record}
                            remove={() => {
                              remove(record.documentStatusId);
                            }}
                            annul={(documentId: number, anullRecord: AnnulRecord) => {
                              annul(documentId, anullRecord);
                            }}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
              <TablePagination
                recordsCount={filteredData.length}
                pagesCount={pagesCount}
                rowsPerPage={tableState.rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                page={tableState.page}
                setPage={handleSetPage}
                setRowsPerPage={handleSetRowsPerPage}
                disabled={filteredData.length === 0}
              />
            </Paper>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withRouter(ProvidedDataHistoryTable);
