import { CircularProgress, Container, Divider, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportForm } from '../../containers/reports/ReportForm';
import { ApplicationState } from '../../store';
import { FindVirsDialog } from '../../components/TableDialogFindVirs/FindVirsDialog';
import {
  selectPerson,
  selectVirs,
  showPersonSearchDialog,
  showVirsSearchDialog
} from '../../containers/reports/store';
import { VirsSearchData } from '../../store/virsis/dataTypes';
import { fetchEnterpriseTypesRequest } from '../../store/classifiers/classifiersActions';
import { FindNaturalPersonInVirsis } from '../../components/FindNaturalPersonInVirsis/FindNaturalPersoninVirsisDialog';

export const ReportsPage: React.FC = () => {
  const loading = useSelector((state: ApplicationState) => {
    return state.virsis.fetchUserLoading || state.userMessages.loadingUserMessages;
  });

  const dispatch = useDispatch();

  const {
    classifiers: { enterpriseTypes },
    report: { showVirsSearch, showPersonSearch, selectedVirs },
    findNaturalPersonInVirsis: { selectedVirsByNaturalPerson }
  } = useSelector((state: ApplicationState) => state);

  const closeVirsSearchDialog = () => dispatch(showVirsSearchDialog(false));
  const closePersonSearchDialog = () => dispatch(showPersonSearchDialog(false));

  const closeAndContinueWithVirs = (virs: VirsSearchData) => {
    closeVirsSearchDialog();
    dispatch(selectVirs(virs));
  };

  const closeAndContinueWithPerson = () => {
    closePersonSearchDialog();
    dispatch(selectPerson(selectedVirsByNaturalPerson));
  };

  useEffect(() => {
    if (!enterpriseTypes) {
      dispatch(fetchEnterpriseTypesRequest());
    }
  }, [dispatch, enterpriseTypes]);

  return (
    <Container maxWidth="lg">
      <div className="data-page">
        <Typography variant="h2">Ataskaitos</Typography>
        <Divider />
        {loading && <CircularProgress />}
        {!loading && <ReportForm />}
        {showVirsSearch && (
          <FindVirsDialog
            selectedVirs={selectedVirs || undefined}
            dialogOpen={showVirsSearch}
            enterpriseTypes={enterpriseTypes || []}
            closeDialog={closeVirsSearchDialog}
            closeAndContinueWithVirs={closeAndContinueWithVirs}
          />
        )}
        {showPersonSearch && (
          <FindNaturalPersonInVirsis
            dialogOpen={showPersonSearch}
            closeDialog={closePersonSearchDialog}
            closeAndContinueAction={closeAndContinueWithPerson}
          />
        )}
      </div>
    </Container>
  );
};
