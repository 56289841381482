import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import {
  LinearProgress,
  Typography,
  FormGroup,
  Dialog,
  DialogTitle,
  makeStyles,
  createStyles,
  Divider,
  DialogActions,
  DialogContent,
  Grid
  // Grid
} from '@material-ui/core';
import * as Yup from 'yup';
import { FormikFormTextField } from '../FormikFields/FormikFormTextField';
import { ApplicationState } from '../../store';
import { REPORT_EXTERNAL_DATA_COMMENT_MAX_LENGTH } from '../../utils/tableTypes';
import {
  fetchInaccurateDataMessageRequest,
  reportInaccurateInstitutionData,
  resetReportInaccurateInstitutionDataState
} from '../../store/InstitutionDataReports/institutionDataReportsActions';
import { CloseDialogButton } from '../Dialogs/Dialog/CloseButton';
import BtnOpenNewDoc from '../TableButtons/BtnOpenNewDoc';
import { useEffect } from 'react';
import { CloseContinueButton } from '../Dialogs/Dialog/CloseContinueButton';
import { Strings } from '../../utils/strings/Strings';

export interface Props {
  documentStatusId: number;
  close: () => void;
  isOpen: boolean;
  openDocumentPreview: () => void;
}
export interface ReportCommentValues {
  reportComment: string | null;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      padding: '20px 35px 10px 35px'
    },
    divider: {
      margin: '10px 35px'
    },
    mainContainer: {
      padding: '10px 35px'
    },
    actions: {
      minWidth: 250,
      padding: '10px 34px',
      height: 50,
      backgroundColor: '#F3F3F3'
    },
    inputContainer: {
      display: 'flex',
      width: '100%',
      margin: 0,
      '& > button': {
        padding: 0,
        marginLeft: 4,
        width: 36,
        height: 36
      },
      '& > div > div > div > button': {
        padding: 0
      }
    },
    textField: {
      width: '100%'
    },
    errorMessage: {
      margin: '0 35px',
      fontWeight: 'normal',
      '& span': {
        color: '#F11A1A',
        fontWeight: 600
      }
    },
    textLine: {
      display: 'flex',
      alignItems: 'center'
    },
    overflowWrapperOne: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    overflowWrapperTwo: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    }
  })
);

const initValues: ReportCommentValues = {
  reportComment: null
};

const validationSchema = Yup.object({
  reportComment: Yup.string()
    .max(
      REPORT_EXTERNAL_DATA_COMMENT_MAX_LENGTH,
      `Komentaras neturi viršyti ${REPORT_EXTERNAL_DATA_COMMENT_MAX_LENGTH} simbolių`
    )
    .nullable()
    .required('Komentaras privalomas')
});

const LightWeightText = ({ text }: { text: string }) => {
  return <span style={{ fontWeight: 400 }}>{text}</span>;
};

export const ReportInstitutionDataDialog: React.FC<Props> = ({
  documentStatusId,
  close,
  isOpen,
  openDocumentPreview
}) => {
  const classes = useStyles();

  const {
    reportingInstitutionData,
    isInstitutionDataReported,
    reportingInstitutionDataError,
    loadingReportInaccurateDataMessage,
    reportInaccurateDataMessage,
    reportInaccurateDataMessageError
  } = useSelector((state: ApplicationState) => state.institutionDataReportData);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(fetchInaccurateDataMessageRequest(documentStatusId));
  }, [documentStatusId, reduxDispatch]);

  function sendReport(reportComment: string) {
    reduxDispatch(reportInaccurateInstitutionData({ documentStatusId, reportComment }));
  }

  function closeOrCloseAndResetError() {
    reduxDispatch(resetReportInaccurateInstitutionDataState());
    close();
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (values.reportComment) {
          sendReport(values.reportComment);
        }
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => {
        return (
          <Dialog
            open={isOpen}
            onClose={closeOrCloseAndResetError}
            onExit={closeOrCloseAndResetError}
            maxWidth="md"
          >
            <DialogTitle disableTypography className={classes.title}>
              {loadingReportInaccurateDataMessage && <LinearProgress />}
              {!loadingReportInaccurateDataMessage && reportInaccurateDataMessage && (
                <Typography variant="h2">{reportInaccurateDataMessage.contents}</Typography>
              )}
            </DialogTitle>

            <Divider className={classes.divider} />

            <DialogContent className={classes.mainContainer}>
              {!loadingReportInaccurateDataMessage && reportInaccurateDataMessage && (
                <>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    style={{ paddingBottom: '15px' }}
                  >
                    <Grid item>
                      <Typography variant="h5">
                        Pranešimą teikia:
                        <LightWeightText text={` ${reportInaccurateDataMessage.virsName},`} />
                        <LightWeightText text={` ${reportInaccurateDataMessage.virsCode}`} />
                        {reportInaccurateDataMessage.virsEmail && (
                          <LightWeightText text={`, ${reportInaccurateDataMessage.virsEmail}`} />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        Pranešimas teikiamas:
                        <LightWeightText
                          text={` ${reportInaccurateDataMessage.institutionName},`}
                        />
                        <LightWeightText
                          text={` ${reportInaccurateDataMessage.institutionCode},`}
                        />
                        {reportInaccurateDataMessage.institutionEmail && (
                          <LightWeightText
                            text={` ${reportInaccurateDataMessage.institutionEmail}`}
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" className={classes.textLine}>
                        Dokumento, kuriuo pateikti duomenys, Nr.:
                        <span style={{ marginLeft: '5px' }}>
                          <BtnOpenNewDoc
                            text={` ${reportInaccurateDataMessage.docNumber}.pdf`}
                            onClicked={openDocumentPreview}
                          />
                        </span>
                        <span className={classes.overflowWrapperOne}>
                          data:
                          <LightWeightText text={` ${reportInaccurateDataMessage.docDate}`} />
                        </span>
                      </Typography>
                    </Grid>
                    <Grid className={classes.overflowWrapperTwo}>
                    <Typography variant="h5" className={classes.textLine}>
                          <span style={{ marginLeft: '5px' }}>
                            data:
                            <LightWeightText text={` ${reportInaccurateDataMessage.docDate}`} />
                          </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        Aprašykite neteisingai pateiktus duomenis ir kaip jie turi būti ištaisyti.
                        Šis pranešimas bus išsiųstas iš sistemos be galimybės į jį atsakyti. Jei
                        norite gauti atsakymą iš Gavėjo, nurodykite tai pranešimo tekste kartu su
                        savo el. pašto adresu.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Form>
                    <FormGroup row>
                      <FormikFormTextField
                        formikKey="reportComment"
                        values={values.reportComment}
                        error={errors.reportComment}
                        setFieldValue={setFieldValue}
                        maxLength={REPORT_EXTERNAL_DATA_COMMENT_MAX_LENGTH}
                        required
                        withoutLabel
                        styledClasses={classes}
                        isMultiline
                        rowsCount={4}
                        disabled={isInstitutionDataReported}
                        placeholder="Jūsų komentaras..."
                      />
                    </FormGroup>
                  </Form>
                </>
              )}

              {(reportingInstitutionDataError || reportInaccurateDataMessageError) && (
                <>
                  <Divider className={classes.divider} />
                  <Typography className={classes.errorMessage} variant="body2">
                    <span>Klaida. </span>
                    {reportingInstitutionDataError?.message ||
                      reportInaccurateDataMessageError?.message}
                  </Typography>
                  <Divider className={classes.divider} />
                </>
              )}
              {isInstitutionDataReported && (
                <>
                  <Divider className={classes.divider} />
                  <Typography variant="body2">
                    <span>Pranešimas išsiųstas. </span>
                  </Typography>
                  <Divider className={classes.divider} />
                </>
              )}
            </DialogContent>

            {reportingInstitutionData && <LinearProgress />}

            <DialogActions className={classes.actions}>
              {!isInstitutionDataReported && (
                <CloseContinueButton
                  onClick={submitForm}
                  disabled={
                    isValidating || reportingInstitutionData || loadingReportInaccurateDataMessage
                  }
                  label="Išsiųsti"
                />
              )}

              <CloseDialogButton
                label={isInstitutionDataReported ? 'Uždaryti' : Strings.button__cancel}
                onClick={closeOrCloseAndResetError}
              />
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};
