import React from 'react';
import { useSelector } from 'react-redux';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import TableCustomTools from '../../../components/TableCustomTools/TableCustomTools';
import TableStatusFilters from '../../../components/TableStatusFilters/TableStatusFilters';
import { OutletDataTableField } from '../../OutletsDataTable/tableState/initialStateAndTypes';
import { OutletDataTableHeader } from '../tablesState/mainOutletTableTypes';
import { getSubsystem } from '../../../utils/roleHelper';
import { ApplicationState } from '../../../store';
import { useOutletInfoTablesDispatch, useOutletInfoTablesState } from '../OutletInfoTablesContext';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';

interface Props {
  columnNames: OutletDataTableHeader[];
  tableName: OutletTableName;
}

export const OutletMainDataTableCustomizationBar: React.FC<Props> = ({
  columnNames,
  tableName
}) => {
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const {
    state: { main: mainTableState }
  } = useOutletInfoTablesState();

  const { currentUser } = useSelector((appState: ApplicationState) => appState.virsis);

  function toggleDisplayCustomFilter() {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED', table: tableName });
  }

  function handleSetSortByColumn(column: OutletDataTableField) {
    tableDispatch({ type: 'MAIN_OUTLET_SORTING_CHANGED', sortBy: column });
  }

  function toggleDisplayColumn(column: OutletDataTableField) {
    tableDispatch({ type: 'MAIN_OUTLET_COLUMN_DISPLAY_TOGGLED', column });
  }

  function toggleNotSignedFilter() {
    tableDispatch({ type: 'SHOW_STATUS_UNSIGNED_TOGGLED', table: tableName });
  }

  function toggleRelevantFilter() {
    tableDispatch({ type: 'SHOW_STATUS_RELEVANT_TOGGLED', table: tableName });
  }

  function toggleOutdatedFilter() {
    tableDispatch({ type: 'SHOW_STATUS_OUTDATED_TOGGLED', table: tableName });
  }

  const subsystem = getSubsystem(currentUser);

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <TableStatusFilters
            dontShowNotSigned={subsystem === 'LRTK' || subsystem === 'ZEIT'}
            notSignedFilterDisabled={!mainTableState.statusNotSignedFilterEnabled}
            notSignedFilterOn={mainTableState.showStatusNotSigned}
            notSignedFilterToggler={toggleNotSignedFilter}
            relevantFilterDisabled={!mainTableState.statusRelevantFilterEnabled}
            relevantFilterOn={mainTableState.showStatusRelevant}
            relevantFilterToggler={toggleRelevantFilter}
            outdatedFilterDisabled={!mainTableState.statusOutdatedFilterEnabled}
            outdatedFilterOn={mainTableState.showStatusOutdated}
            outdatedFilterToggler={toggleOutdatedFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={columnNames}
            showFilter={mainTableState.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={mainTableState.order}
            sortBy={mainTableState.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={mainTableState.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
