import React from 'react';
import { Typography, Button, SvgIconTypeMap } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import virsisTheme from '../../style/virsisTheme';

interface Props {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    signButton: {
      padding: '0 10px',
      backgroundColor: virsisTheme.palette.secondary.light,
      border: `1px solid ${virsisTheme.palette.secondary.light}`,
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      color: '#637082',
      '&:hover': {
        border: `1px solid ${virsisTheme.palette.primary.main}`,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.primary.main
      },
      '&:active': {
        border: `1px solid ${virsisTheme.palette.primary.main}`,
        backgroundColor: '#DBEDFC',
        color: virsisTheme.palette.primary.main
      }
    },
    icon: {
      height: 30,
      padding: '5px 10px 5px 0',
      color: 'inherit'
    },
    text: {
      color: 'inherit',
      '&:hover': {
        color: 'inherit'
      }
    }
  })
);

export const TableActionButton: React.FC<Props> = ({ onClick, text, IconComponent, disabled }) => {
  const classes = useStyles();
  return (
    <Button variant="outlined" onClick={onClick} className={classes.signButton} disabled={disabled}>
      <IconComponent fontSize="small" className={classes.icon} />
      <Typography variant="h5" className={classes.text}>
        {text}
      </Typography>
    </Button>
  );
};
