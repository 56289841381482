import React, { useEffect, useMemo, useState } from 'react';
import { Box, LinearProgress, Paper, Table, TableBody, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewRecordButton } from '../../../components/TableButtons/AddNewRecordButton';
import { ApplicationState } from '../../../store';
import {
  useShareholdersTableDispatch,
  useShareholdersTableState
} from './ShareholdersTableWithContext';
import ShareholdersTableHeader from './TableHeader';
import { ShareholdersCustomizationBar } from './CustomizationBar';
import {
  fetchVirsPersonsListRequest,
  filterUnsignedToggled,
  getShareholdersRequest,
  setExpandFilteredParents,
  setLastSignedDate,
  showShareholderDataDeletionByDateDialog
} from '../../../store/shareholders/shareholdersActions';
import {
  getFilteredDatesForMainTable,
  getFilteredPathsAndIds,
  getLastSignedDate,
  getMinimumDate,
  getShareholderLevelList,
  getSortedShareholdersData,
  getValidDates
} from '../utilityFunctions/sharedFunctions';
import { TableRowsContainer } from './TableRows/TableRowsContainer';
import DateInputDialog from './Components/DateInputDialog';
import ShareholderDataDeletionByDateDialog from './Components/ShareholderDataDeletionByDateDialog';
import DeleteShareholderDialog from './Components/DeleteShareholderDialog';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import { commonColumnNames, NO_DATA_ERROR_TEXT } from '../constants';
import { getShareholderDateTooltip } from './utilities/UIfunctions';
import { getTableExportDefinition } from './tableState/tableFunctions';
import { getTableExportRows } from '../utilityFunctions/shareholderExportMapping';
import DeleteDateDialog from './Components/ShareholderDeleteDateDialog';
import ShareholderHeader from './Components/ShareholderHeader';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import ErrorText from '../SharedComponents/ErrorText';
import { VirsTreeWithContext } from '../../ShareholdersVirsTree/VirsTreeContext';
import MixeDatesTable from './FilteredViewComponents/MixedDatesTable';
import TableByMonth from './FilteredViewComponents/TableByMonth';
import TableByYear from './FilteredViewComponents/TableByYear';
import TableByDay from './FilteredViewComponents/TableByDay';
import { getAllDaysInPeriod } from './utilities/functionsWithDateFormat';
import {
  JADISSynchronise,
  setJADISSynchronised,
  setJADISSynchroniseError,
  setJADISSynchronising
} from '../../../store/jadisSync/store';
import { useLocation } from 'react-router';
import { hasRoles } from '../../../utils/roleHelper';
import { todaysDateString } from '../../../utils/tableDataFunctions';
import { Strings } from '../../../utils/strings/Strings';

const ShareholdersMainTable: React.FC = () => {
  const syncJADISParam = new URLSearchParams(useLocation().search).get('syncJADIS');
  const resyncJADIS = syncJADISParam === null || syncJADISParam === 'true';

  const [isCalendarOpen, setCalenderOpen] = useState(false);
  const [showTree, setShowTree] = useState<boolean>(false);
  const { state: tableState } = useShareholdersTableState();
  const {
    state: {
      sortBy,
      order,
      customFilter: {
        shareholderPeriodDateFrom,
        shareholderPeriodDateTo,
        shareholderEventDate,
        shareholderLevel
      },
      tableId
    }
  } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const reduxDispatch = useDispatch();
  const {
    shareholdersData: {
      loadingShareholdersData,
      shareholdersDataError,
      shareholdersData,
      noValidShareholderParent,
      allShareholders,
      allEventsDates,
      showUnsignedSwitchEnabled,
      showUnsigned
    },
    virsis: { virsData, currentUser },
    JADISSync: {
      loaded: JADISSynchronised,
      loading: JADISSynchronising,
      error: JADISSynchroniseError
    }
  } = useSelector((state: ApplicationState) => state);

  // shareholder level filtras išskleidimui
  const [filteredShareholderLevel] = shareholderLevel;
  const [filteredEventDate] = shareholderEventDate;
  const [periodFilterFromDate] = shareholderPeriodDateFrom;
  const [periodFilterToDate] = shareholderPeriodDateTo;

  useEffect(() => {
    if (!JADISSynchronised && resyncJADIS && !!virsData?.virsId) {
      reduxDispatch(JADISSynchronise());
    }
  }, [JADISSynchronised, resyncJADIS, reduxDispatch, virsData?.virsId]);

  useEffect(() => {
    if (JADISSynchronised) {
      return () => {
        reduxDispatch(setJADISSynchronised(false));
        reduxDispatch(setJADISSynchronising(false));
        reduxDispatch(setJADISSynchroniseError());
      };
    }
  }, [JADISSynchronised, reduxDispatch]);

  useEffect(() => {
    if ((JADISSynchronised || !resyncJADIS) && virsData && virsData.virsId !== 0) {
      reduxDispatch(getShareholdersRequest(virsData.virsId, !!showUnsigned));
      reduxDispatch(fetchVirsPersonsListRequest(virsData.virsId, null));
    }
  }, [reduxDispatch, virsData, resyncJADIS, JADISSynchronised, showUnsigned]);

  useEffect(() => {
    if (currentUser && hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS])) {
      reduxDispatch(filterUnsignedToggled(true));
    } else {
      reduxDispatch(filterUnsignedToggled(false));
    }
  }, [reduxDispatch, currentUser]);

  useEffect(() => {
    if (shareholdersData) {
      const shareholdersLevelList = getShareholderLevelList(shareholdersData);

      // suskaiciuoja kiek leveliu is viso yra medyje (levelio filtravimo dropdownui)
      tableDispatch({
        type: 'SET_SHAREHOLDERS_LEVEL_LIST',
        shareholdersLevelList
      });
    }
  }, [shareholdersData, tableDispatch]);

  useEffect(() => {
    if (shareholdersData && allShareholders) {
      if (filteredShareholderLevel) {
        // esant levelio filtrui suskaiciuoja isskleidziamu shareholder paths ir unique personIds (bendraturciams)
        const { filteredShareholderPaths, filteredPersonIds } = getFilteredPathsAndIds(
          filteredShareholderLevel,
          shareholdersData.virsShareholderId,
          allShareholders,
          shareholdersData
        );
        reduxDispatch(setExpandFilteredParents(filteredShareholderPaths, filteredPersonIds));
      }
    }
  }, [shareholdersData, reduxDispatch, filteredShareholderLevel, allShareholders]);

  // dienos su įvykiais ir be pasirinkus periodą nuo iki
  const allFilteredDays = getFilteredDatesForMainTable(
    getAllDaysInPeriod(
      periodFilterFromDate || getMinimumDate(shareholdersData),
      periodFilterToDate || todaysDateString()
    ),
    periodFilterFromDate,
    periodFilterToDate,
    filteredEventDate,
    shareholdersData?.activityPeriods || [],
    getMinimumDate(shareholdersData),
    shareholdersData
  );

  const allFilteredEventDates = useMemo(() => {
    return (
      allFilteredDays &&
      getFilteredDatesForMainTable(
        allFilteredDays,
        periodFilterFromDate,
        periodFilterToDate,
        filteredEventDate,
        shareholdersData?.activityPeriods || [],
        getMinimumDate(shareholdersData),
        shareholdersData
      )
    );
  }, [
    allFilteredDays,
    periodFilterFromDate,
    periodFilterToDate,
    filteredEventDate,
    shareholdersData
  ]);

  const validDates =
    shareholdersData &&
    getValidDates(
      shareholdersData.virsStartDate,
      shareholdersData.minimumDate,
      shareholdersData?.virsEndDate
    );
  const lastValidDate = validDates?.length ? validDates[validDates.length - 1] : undefined;

  const noValidDates =
    (validDates && !validDates.map((date) => allEventsDates?.includes(date)).includes(false)) ||
    shareholdersData?.activityPeriods.length === 0;

  const openCalendar = () => {
    setCalenderOpen(true);
  };

  const closeCalendar = () => {
    setCalenderOpen(false);
  };

  const sortedShareholderData = useMemo(() => {
    return shareholdersData && getSortedShareholdersData(shareholdersData, order, sortBy);
  }, [shareholdersData, order, sortBy]);

  const shareholderTableExportSettings = {
    ...tableState,
    showNonParticipatingShareholders: true,
    selectedDates: allEventsDates || []
  };

  function openTree() {
    setShowTree(true);
  }
  function closeTree() {
    setShowTree(false);
  }

  useEffect(() => {
    reduxDispatch(
      setLastSignedDate(
        getLastSignedDate(shareholdersData?.shareholders, shareholdersData?.activityPeriods)
      )
    );
  }, [shareholdersData?.activityPeriods, shareholdersData?.shareholders]);

  const dataLoading = loadingShareholdersData && !shareholdersDataError;

  return (
    <>
      {showTree && virsData && (
        <div className="named-data-table">
          <VirsTreeWithContext
            virsId={virsData?.virsId}
            date={lastValidDate}
            virsName={virsData.name}
            handleGoBackClick={closeTree}
          />
        </div>
      )}

      {!showTree && (
        <>
          <DeleteDateDialog />
          <ShareholderDataDeletionByDateDialog location="MAIN" />
          <DeleteShareholderDialog location="MAIN" />
          {shareholdersData && (
            <DateInputDialog
              isOpen={isCalendarOpen}
              closeCalendar={closeCalendar}
              openCalendar={openCalendar}
              daysToDisable={allEventsDates}
              virsStartDate={shareholdersData.virsStartDate}
              virsEndDate={shareholdersData?.virsEndDate}
              shareholdersData={shareholdersData}
            />
          )}
          <div className="named-data-table shareholders">
            <ShareholderHeader
              openCalendar={openCalendar}
              loadingShareholdersData={loadingShareholdersData}
              noValidDates={!!noValidDates}
              allShareholdersFromJADIS={noValidShareholderParent}
              virsData={virsData}
              onDownload={(format, navigationPath) =>
                exportTableData(
                  format,
                  getTableExportDefinition(shareholderTableExportSettings),
                  getTableExportRows(shareholderTableExportSettings, sortedShareholderData),
                  navigationPath
                )
              }
              onPrint={(navigationPath) =>
                printTableData(
                  getTableExportDefinition(shareholderTableExportSettings),
                  getTableExportRows(shareholderTableExportSettings, sortedShareholderData),
                  navigationPath
                )
              }
              onClickDelete={() => {
                reduxDispatch(showShareholderDataDeletionByDateDialog());
              }}
              onVizualizeClick={openTree}
            />

            <Paper elevation={0} className="shareholders-table-wrapper">
              <ShareholdersCustomizationBar
                columnNames={commonColumnNames}
                allEventsDates={allEventsDates}
                virsId={virsData?.virsId}
                isShowStatusUnsigned={!!showUnsigned}
                isShowUnsignedShareholdersEnabled={!!showUnsignedSwitchEnabled}
              />

              {(dataLoading || JADISSynchronising) && <LinearProgress />}

              {(shareholdersDataError || JADISSynchroniseError) && (
                <ErrorText text={JADISSynchroniseError || NO_DATA_ERROR_TEXT} />
              )}

              {JADISSynchronising && (
                <Typography style={{ padding: 15 }}>
                  Palaukite kol sinchronizuojami JADIS duomenys
                </Typography>
              )}

              {!JADISSynchronising && (
                <div className="table-scroller">
                  <Table className="shareholders-table">
                    {(tableId === 'changeDate' || filteredEventDate) && (
                      <>
                        {shareholdersData && allEventsDates && allFilteredEventDates && (
                          <ShareholdersTableHeader
                            openCalendar={openCalendar}
                            allEventsDates={allFilteredEventDates}
                            shareholdersData={shareholdersData}
                            noValidDates={!!noValidDates}
                            allShareholdersFromJADIS={noValidShareholderParent}
                            loadingShareholdersData={loadingShareholdersData}
                            activityPeriods={shareholdersData.activityPeriods}
                            virsStartDate={shareholdersData.virsStartDate}
                          />
                        )}

                        <TableBody>
                          {sortedShareholderData && allEventsDates && allFilteredEventDates && (
                            <TableRowsContainer
                              data={sortedShareholderData}
                              allEventsDates={allFilteredEventDates}
                              filteredShareholderLevel={filteredShareholderLevel}
                            />
                          )}
                        </TableBody>
                      </>
                    )}

                    {allEventsDates &&
                      sortedShareholderData &&
                      tableId === 'month' &&
                      !filteredEventDate && (
                        <TableByMonth
                          allEventsDates={allEventsDates}
                          openCalendar={openCalendar}
                          sortedShareholderData={sortedShareholderData}
                          noValidDates={!!noValidDates}
                          allShareholdersFromJADIS={noValidShareholderParent}
                          loadingShareholdersData={loadingShareholdersData}
                          periodFilterFromDate={periodFilterFromDate}
                          periodFilterToDate={periodFilterToDate}
                        />
                      )}

                    {allEventsDates &&
                      sortedShareholderData &&
                      tableId === 'year' &&
                      !filteredEventDate && (
                        <TableByYear
                          allEventsDates={allEventsDates}
                          openCalendar={openCalendar}
                          sortedShareholderData={sortedShareholderData}
                          noValidDates={!!noValidDates}
                          allShareholdersFromJADIS={noValidShareholderParent}
                          loadingShareholdersData={loadingShareholdersData}
                          periodFilterFromDate={periodFilterFromDate}
                          periodFilterToDate={periodFilterToDate}
                        />
                      )}

                    {allEventsDates &&
                      sortedShareholderData &&
                      tableId === 'mixed' &&
                      !filteredEventDate && (
                        <MixeDatesTable
                          allEventsDates={allEventsDates}
                          openCalendar={openCalendar}
                          sortedShareholderData={sortedShareholderData}
                          noValidDates={!!noValidDates}
                          allShareholdersFromJADIS={noValidShareholderParent}
                          loadingShareholdersData={loadingShareholdersData}
                          periodFilterFromDate={periodFilterFromDate}
                          periodFilterToDate={periodFilterToDate}
                          activityPeriods={shareholdersData?.activityPeriods || []}
                        />
                      )}

                    {allEventsDates &&
                      sortedShareholderData &&
                      tableId === 'day' &&
                      !filteredEventDate &&
                      allFilteredDays && (
                        <TableByDay
                          allEventsDates={allEventsDates}
                          allFilteredDays={allFilteredDays}
                          openCalendar={openCalendar}
                          sortedShareholderData={sortedShareholderData}
                          noValidDates={!!noValidDates}
                          allShareholdersFromJADIS={noValidShareholderParent}
                          loadingShareholdersData={loadingShareholdersData}
                          activityPeriods={shareholdersData?.activityPeriods || []}
                        />
                      )}
                  </Table>
                </div>
              )}
            </Paper>
            {virsData && virsData.virsId !== 0 && (
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                <Box display="flex">
                  <AddNewRecordButton
                    text={Strings.addDate}
                    onClicked={openCalendar}
                    disabled={noValidDates || noValidShareholderParent || loadingShareholdersData}
                    tooltipText={getShareholderDateTooltip(
                      !!noValidDates,
                      noValidShareholderParent
                    )}
                    showTooltip
                  />
                </Box>
              </AllowedTo>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default ShareholdersMainTable;
