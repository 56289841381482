import {
  PaymentDataTableAction,
  PaymentDataTableState,
  initialPaymentDataTableState
} from './tableInitialStateAndTypes';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import { updatePaymentDataCustomFilter } from './tableStateFunctions';

export const paymentTableReducer = (
  state: PaymentDataTableState,
  action: PaymentDataTableAction
): PaymentDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialPaymentDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updatePaymentDataCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'DOCUMENT_DIALOG_OPENED':
      return {
        ...state,
        showDocumentDialog: true
      };
    case 'DOCUMENT_DIALOG_CLOSED':
      return {
        ...state,
        showDocumentDialog: false
      };
    default:
      return state;
  }
};
