import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  InputStateGeneric,
  DropdownStateGeneric,
  DatePickerState
} from '../../../../../utils/tableTypes';
import {
  OutletEdition,
  OutletEditionPeriod
} from '../../../../../store/outletInfoData/outletInfoDataTypes';
import { EditionPeriodShort } from '../../../../../store/classifiers/classifiersTypes';

export interface EditionRecordState {
  editionPeriod: DropdownStateGeneric<OutletEditionPeriod>;
  editionDate: DatePickerState;
  circulation: InputStateGeneric<string>;
  periodic: boolean;
}

export const initialEditionRecordState: EditionRecordState = {
  periodic: false,
  editionPeriod: {
    value: null,
    error: false,
    helperText: '',
    placeholder: 'Laikotarpis',
    validated: false,
    options: []
  },
  editionDate: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  circulation: {
    value: null,
    id: 'circulation',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false
  }
};

export interface EditionEditRowState extends EditionRecordState {
  editingOn: boolean;
  missingPeriods?: EditionPeriodShort[];
  updateRecordConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

export const initialEditionEditRowState: EditionEditRowState = {
  editingOn: false,
  ...initialEditionRecordState,
  updateRecordConfirmationOn: false,
  removeRecordConfirmationOn: false,
  missingPeriods: undefined
};

export interface EditionNewRowState extends EditionRecordState {
  createRecordConfirmationOn: boolean;
}

export const initialEditionNewRecordRowState: EditionNewRowState = {
  ...initialEditionRecordState,
  createRecordConfirmationOn: false
};

type EditionRowAction =
  | {
      type: 'EDITION_PERIOD_CHANGED';
      editionPeriod: EditionPeriodShort | null;
    }
  | { type: 'EDITION_DATE_CHANGED'; editionDate: MaterialUiPickersDate | null }
  | { type: 'CIRCULATION_CHANGED'; circulation: string | null };

export type EditionNewRowAction =
  | EditionRowAction
  | {
      type: 'NEW_RECORD_STATE_INITIALIZED';
      missingPeriods: EditionPeriodShort[];
      periodic: boolean;
      controlledFrom: string | null;
      controlledTo: string | null;
    }
  | {
      type: 'CREATE_RECORD_CLICKED';
      onValid?: (state: EditionNewRowState) => void;
    }
  | { type: 'CREATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };

export type EditionEditRowAction =
  | EditionRowAction
  | {
      type: 'EDITING_INITIALIZED';
      record: OutletEdition;
      periodic: boolean;
      missingPeriods: EditionPeriodShort[];
      controlledFrom: string | null;
      controlledTo: string | null;
    }
  | { type: 'EDITING_CANCELLED' }
  | {
      type: 'UPDATE_RECORD_CLICKED';
      onValid?: (state: EditionEditRowState) => void;
    }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' };
