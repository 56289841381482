import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Typography, Divider, DialogActions } from '@material-ui/core';

import {
  closeContactEmailDialog,
  saveVirsContactEmailRequest
} from '../../../store/virsis/actions';
import { CloseDialogButton } from './CloseButton';
import { CloseContinueButton } from './CloseContinueButton';
import { EMAIL_VALIDATION_REGEX } from '../../../utils/tableTypes';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { GenericBooleanField } from '../../FormikFields/GenericFormikWrappers/GenericBooleanField';
import LabelInputField from '../../InputField/LabelInputField';
import { Strings } from '../../../utils/strings/Strings';

interface Props {
  personId: number;
  contactEmail?: string;
  emailDialogOpen: boolean;
  disableBackdropClick: boolean;
}

interface EmailDialogForm {
  email: string;
  agreement: boolean;
}

const initialValues: EmailDialogForm = {
  email: '',
  agreement: false
};

const EmailDialog: React.FC<Props> = ({
  children,
  contactEmail,
  emailDialogOpen,
  disableBackdropClick,
  personId
}) => {
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(EMAIL_VALIDATION_REGEX, 'Neteisingai įvestas el. pašto adresas')
      .required('El.paštas privalomas'),
    agreement: Yup.boolean().oneOf([true], 'Norint tęsti prašome sutikti su pranešimų taisyklėmis')
  });

  useEffect(() => {
    if (contactEmail) {
      initialValues.email = contactEmail;
      setEmailExists(true);
    }
  }, [contactEmail, emailDialogOpen]);

  const handleClose = () => {
    dispatch(closeContactEmailDialog());
  };

  const handleSaveContact = (data: EmailDialogForm) => {
    dispatch(
      saveVirsContactEmailRequest({
        email: data.email,
        personId: personId
      })
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      open={emailDialogOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick={disableBackdropClick}
    >
      <div className="dialog-title">
        <Typography variant="h2">Kontaktinis el. paštas</Typography>
      </div>

      <Divider light style={{ margin: '0px 35px' }} />

      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => handleSaveContact(values)}
        enableReinitialize={true}
      >
        {(formikProps: FormikProps<EmailDialogForm>) => (
          <>
            <div className="dialog-main-content">
              <Typography style={{ marginBottom: 20 }}>
                {emailExists
                  ? 'Įveskite el. pašto adresą, kuriuo norėsite gauti pranešimus iš VIRSIS, arba patvirtinkite žemiau nurodytą:'
                  : 'Įveskite el. pašto adresą, kuriuo norėsite gauti pranešimus iš VIRSIS'}
              </Typography>
              <Form>
                <LabelInputField
                  label="Kontaktinis el. paštas"
                  value={formikProps.values.email}
                  onChange={(e) => formikProps.setFieldValue('email', e.target.value)}
                  errorText={formikProps.errors.email}
                />

                <GenericBooleanField
                  label="Sutinku į el. paštą gauti VIRSIS ir su VIRSIS duomenimis susijusių teikėjų informacinius pranešimus."
                  field="agreement"
                  formikProps={formikProps}
                  style={{ container: { margin: '10px 0' }, helper: { position: 'static' } }}
                />
              </Form>
            </div>

            <DialogActions className="dialog-actions">
              <div>{children}</div>
              {!children && (
                <CloseDialogButton label={Strings.button__cancel} onClick={handleClose} />
              )}
              <CloseContinueButton
                onClick={formikProps.submitForm}
                disabled={formikProps.isSubmitting}
                label="Išsaugoti"
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default EmailDialog;
