import {
  FindNaturalPersonInVirsisQuery,
  NaturalPersonInVirsis
} from '../../components/FindNaturalPersonInVirsis/Utilities';
import { action } from 'typesafe-actions';
import {
  SELECT_NATURAL_PERSON_IN_VIRSIS,
  SELECT_NATURAL_PERSON_IN_VIRSIS_ERROR,
  SELECT_NATURAL_PERSON_IN_VIRSIS_REQUEST,
  SELECT_NATURAL_PERSON_IN_VIRSIS_SUCCESS
} from './actionTypes';

export const selectNaturalPersonInVirsis = (naturalPersonData?: NaturalPersonInVirsis) =>
  action(SELECT_NATURAL_PERSON_IN_VIRSIS, naturalPersonData);
export const searchNaturalPersonInVirsisRequest = (virsQuery: FindNaturalPersonInVirsisQuery) =>
  action(SELECT_NATURAL_PERSON_IN_VIRSIS_REQUEST, virsQuery);
export const searchNaturalPersonInVirsisSuccess = (naturalPersonData: NaturalPersonInVirsis[]) =>
  action(SELECT_NATURAL_PERSON_IN_VIRSIS_SUCCESS, naturalPersonData);
export const searchNaturalPersonInVirsisError = (error: string) =>
  action(SELECT_NATURAL_PERSON_IN_VIRSIS_ERROR, error);
