import { action } from 'typesafe-actions';
import {
  InstDocumentType,
  LegalDocumentType,
  OutletDocumentType,
  UNSIGNED_DATA_REMOVE_ERROR,
  UNSIGNED_DATA_REMOVE_REQUEST_INST,
  UNSIGNED_DATA_REMOVE_REQUEST_LEGAL,
  UNSIGNED_DATA_REMOVE_REQUEST_OUTLET,
  UNSIGNED_DATA_REMOVE_REQUEST_VIRS,
  UNSIGNED_DATA_REMOVE_RESET_STATE,
  UNSIGNED_DATA_REMOVE_SUCCESS,
  VirsDocumentType
} from './unsignedDataTypes';

export const unsignedDataRemoveLegal = (documentType?: LegalDocumentType) =>
  action(UNSIGNED_DATA_REMOVE_REQUEST_LEGAL, { documentType });

export const unsignedDataRemoveInst = (documentType?: InstDocumentType) =>
  action(UNSIGNED_DATA_REMOVE_REQUEST_INST, { documentType });

export const unsignedDataRemoveVirs = (virsId: number, documentType?: VirsDocumentType) =>
  action(UNSIGNED_DATA_REMOVE_REQUEST_VIRS, { documentType, virsId });

export const unsignedDataRemoveOutlet = (outledId: number, documentType?: OutletDocumentType) =>
  action(UNSIGNED_DATA_REMOVE_REQUEST_OUTLET, { outledId, documentType });

export const unsignedDataRemoveSuccess = () => action(UNSIGNED_DATA_REMOVE_SUCCESS);

export const unsignedDataRemoveError = (error: Error) => action(UNSIGNED_DATA_REMOVE_ERROR, error);

export const unsignedDataRemoveResetState = () => action(UNSIGNED_DATA_REMOVE_RESET_STATE);
