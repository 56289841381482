import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { FileProps, getBlob, postPdfFile } from '../../../utils/axiosApi';

import {
  FormSagaContext,
  getFormSagaContext
} from '../../../components/FormikFields/GenericFormikWrappers/types';

import {
  loadData,
  setData,
  setError,
  setLoading,
  submitForm,
  setFormLoading,
  setFormOpen,
  setFormError,
  download
} from './store';

import { ValueType } from '../../../utils/tableTypes';
import { FormState } from './types';
import { saveAs } from 'file-saver';
import { getFileName } from './utilities';

function* loadDataTask() {
  yield put(setLoading(true));
  try {
    yield put(setData(FormState));
  } catch (error) {
    yield put(setError(true));
  }

  yield put(setLoading(false));
}

function* submitFormTask(action: ActionType<typeof submitForm>) {
  const sendSnackbar: ValueType<FormSagaContext, 'formSubmitSnackbar'> = yield getFormSagaContext(
    'formSubmitSnackbar'
  );

  yield put(setFormLoading(true));
  if (action.payload.data.fileContents !== null && action.payload.data.fileType !== null) {
    try {
      const fileInput: FileProps = {
        url: 'files',
        fileContents: action.payload.data.fileContents,
        fileType: action.payload.data.fileType
      };

      yield call(postPdfFile, fileInput);

      yield put(setFormOpen(false));
      yield put(setFormError(false));

      yield call(sendSnackbar, true);
    } catch (error) {
      yield call(sendSnackbar, false);
    }
  }
  yield put(setFormLoading(false));
}

function* fetchFileTask(action: ActionType<typeof download>) {
  const sendSnackbar: ValueType<FormSagaContext, 'formSubmitSnackbar'> = yield getFormSagaContext(
    'formSubmitSnackbar'
  );

  yield put(setLoading(true));
  try {
    const fileType = action.payload.fileType;
    const { data } = yield call(getBlob, `files?fileType=${fileType}`);
    const blob = new Blob([data], { type: 'application/pdf' });
    if (blob) {
      saveAs(blob, getFileName(fileType));
    }
  } catch (e) {
    yield call(sendSnackbar, false);
  } finally {
    yield put(setLoading(false));
  }
}

export function* saga() {
  yield takeLatest(loadData, loadDataTask);
  yield takeLatest(submitForm, submitFormTask);
  yield takeLatest(download, fetchFileTask);
}
