import { TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ExpandDetailsButton } from '../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { InstitutionDataReport } from '../../store/InstitutionDataReports/institutionDataReportsTypes';
import { useInstitutionDataReportTableState } from './Context';

interface Props {
  record: InstitutionDataReport;
}

export const InstitutionDataReportTableRow: React.FC<Props> = ({ record }) => {
  const [extendedRow, setExtendedRow] = useState(false);
  const {
    state: { columnsDisplayStatus }
  } = useInstitutionDataReportTableState();

  const handleClickExpandButton = () => {
    setExtendedRow(!extendedRow);
  };

  return (
    <>
      <TableRow>
        {columnsDisplayStatus.reportType && (
          <TableCell>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <ExpandDetailsButton
                showExtension={extendedRow}
                onClick={handleClickExpandButton}
                extraStyles={{ marginRight: 5 }}
              />
              {record.reportType}
            </div>
          </TableCell>
        )}
        {columnsDisplayStatus.reportedDateTime && <TableCell>{record.reportedDateTime}</TableCell>}
        {columnsDisplayStatus.institutionCode && <TableCell>{record.institutionCode}</TableCell>}
        {columnsDisplayStatus.institutionName && <TableCell>{record.institutionName}</TableCell>}
        {columnsDisplayStatus.institutionEmail && <TableCell>{record.institutionEmail}</TableCell>}
        {columnsDisplayStatus.virsCode && <TableCell>{record.virsCode}</TableCell>}
        {columnsDisplayStatus.virsName && <TableCell>{record.virsName}</TableCell>}
        {columnsDisplayStatus.reportedBy && <TableCell>{record.reportedBy}</TableCell>}
        {columnsDisplayStatus.reporterEmail && <TableCell>{record.reporterEmail}</TableCell>}
      </TableRow>
      {columnsDisplayStatus.reportType && extendedRow && (
        <TableRow>
          <TableCell colSpan={9} style={{ padding: '20px 44px' }}>
            <Typography variant="h5">{record.reportHeader}</Typography>
            <Typography style={{ marginTop: 15, whiteSpace: 'pre-wrap' }}>
              {record.reportComment}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
