import { SortOrder, VirsisParameterType, VirsisYesNoString } from '../../../utils/tableTypes';
import {
  convertVirsisYesNoToLabel,
  Filter,
  filterData,
  getStringCompareLessFilter,
  getStringCompareMoreFilter,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { VirsisParameter } from '../../../store/parameters/parametersDataTypes';
import {
  ParametersDataCustomFilter,
  ParametersDataHeaderField,
  ParametersDataTableState
} from './tableInitialStateAndTypes';

export function updateParameterDataCustomFilter(
  filter: ParametersDataCustomFilter,
  filterBy: ParametersDataHeaderField,
  value: string | null
): ParametersDataCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

function getSortedPaymentData(
  data: VirsisParameter[],
  order: SortOrder,
  sortBy: string
): VirsisParameter[] {
  return stableSortWrapped(data, order, sortBy);
}

export function filterAndSortParameterDataVirs(
  tableState: ParametersDataTableState,
  data: VirsisParameter[]
): VirsisParameter[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;

  const filters: Filter<VirsisParameter>[] = [
    getStringMappedColumnFilter(columnsFilters.parameterCode, ({ parameterCode }) => [
      parameterCode
    ]),
    getStringMappedColumnFilter(columnsFilters.parameterName, ({ parameterName }) => [
      parameterName
    ]),
    getStringMappedColumnFilter(columnsFilters.parameterType, ({ parameterType }) => [
      parameterType
    ]),
    getStringMappedColumnFilter(columnsFilters.parameterScope, ({ parameterScope }) => [
      parameterScope
    ]),
    getStringMappedColumnFilter(columnsFilters.minValue, ({ minValue }) => [`${minValue}`]),
    getStringMappedColumnFilter(columnsFilters.maxValue, ({ maxValue }) => [`${maxValue}`]),
    getStringMappedColumnFilter(columnsFilters.isUrl, ({ isUrl }) => [
      convertVirsisYesNoToLabel(isUrl)
    ]),
    getStringMappedColumnFilter(columnsFilters.isRequired, ({ isRequired }) => [
      convertVirsisYesNoToLabel(isRequired)
    ]),
    getStringMappedColumnFilter(columnsFilters.measurementUnit, ({ measurementUnit }) => [
      measurementUnit
    ]),
    getStringMappedColumnFilter(
      columnsFilters.parameterValue,
      ({ parameterValue, parameterType }) => [
        parameterType === VirsisParameterType.YesNo &&
        (parameterValue === VirsisYesNoString.NO || parameterValue === VirsisYesNoString.YES)
          ? convertVirsisYesNoToLabel(parameterValue)
          : parameterValue
      ]
    ),
    getStringCompareMoreFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringCompareLessFilter(columnsFilters.validTo, ({ validTo }) => [validTo])
  ];

  const filtered = filterData(data, filters);

  const sorted = getSortedPaymentData(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
