import { makeStyles } from '@material-ui/core/styles';

export const useDialogPersonCreationFormStyles = makeStyles((theme) => ({
  textField: {
    minWidth: '220px'
  },
  inputLabel: {
    margin: '4px auto auto auto',
    width: '205px',
    lineHeight: '25px',
    textAlign: 'right'
  },
  inputContainer: {
    margin: '4px',
    display: 'grid',
    gridTemplateColumns: '205px 255px 30px',
    gap: '12px',
    '& button': {
      padding: '4px'
    }
  },
  clearIcon: {
    size: '12px',
    padding: '4px',
    margin: '0 auto auto auto'
  },
  button: {
    height: '34px',
    width: '100px',
    float: 'right',
    marginRight: '70px'
  },
  dateField: {
    fontSize: '12px',
    padding: '4px',
    minWidth: '220px'
  },
  selectInput: {
    minWidth: '220px'
  },

  [theme.breakpoints.down('sm')]: {
    inputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 5,
      width: '100%'
    },
    inputLabel: {
      width: '100%',
      textAlign: 'left'
    },
    selectInput: {
      flex: 1,
      minWidth: 'unset'
    },
    textField: {
      flex: 1,
      minWidth: 'unset'
    },
    dateField: {
      flex: 1,
      minWidth: 'unset',
      padding: 0
    },
    formGroup: {
      maxWidth: 'unset'
    },
    buttonBox: {
      display: 'flex',
      gap: 10,
      margin: 4
    },
    button: {
      width: '100%',
      marginRight: '0 !important',
      marginLeft: 0
    }
  }
}));
