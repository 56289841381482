import React, { useReducer, useContext, Dispatch, createContext } from 'react';
import {
  initialOutletInfoTablesState,
  OutletInfoTablesAction,
  OutletInfoTablesState
} from './tablesState/tablesTypesAndActions';
import { outletInfoTablesReducer } from './tablesState/tablesReducer';
import { OutletInfoTables, OutletInfoTablesProps } from './OutletInfoTables';

const OutletInfoTablesStateContext = createContext<{
  state: OutletInfoTablesState;
}>({
  state: initialOutletInfoTablesState
});

const OutletInfoTablesDispatchContext = createContext<{
  dispatch: Dispatch<OutletInfoTablesAction>;
}>({
  dispatch: () => {}
});

const OutletInfoTablesWithContext: React.FC<OutletInfoTablesProps> = (props) => {
  const [state, dispatch] = useReducer(outletInfoTablesReducer, initialOutletInfoTablesState);
  return (
    <OutletInfoTablesStateContext.Provider value={{ state }}>
      <OutletInfoTablesDispatchContext.Provider value={{ dispatch }}>
        <OutletInfoTables {...props} />
      </OutletInfoTablesDispatchContext.Provider>
    </OutletInfoTablesStateContext.Provider>
  );
};

function useOutletInfoTablesState() {
  return useContext(OutletInfoTablesStateContext);
}
function useOutletInfoTablesDispatch() {
  return useContext(OutletInfoTablesDispatchContext);
}

export { OutletInfoTablesWithContext, useOutletInfoTablesState, useOutletInfoTablesDispatch };
