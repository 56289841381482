import React from 'react';
import {
  ShareholdersProps,
  ShareholdersHistory
} from '../../../../store/shareholders/shareholdersTypes';
import { checkIfColorBarNeeded } from '../../SelectedDateTable/utilities/UIFunctions';
import { getCurrentPeriod, getEventsForSelectedDate } from '../../utilityFunctions/sharedFunctions';
import { getColor, getBorderColor, getTextColor } from '../../utilityFunctions/sharedUIFunctions';
import { ColorBarForFilteredView } from '../FilteredViewComponents/ColorBarForFilteredView';
import { useShareholdersTableState } from '../ShareholdersTableWithContext';
import { checkDateFormat } from '../utilities/functionsWithDateFormat';
import { getValue } from '../utilities/UIfunctions';
import { ColorBarByDay } from './ColorBarByDay';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';

interface Props {
  shareholder: ShareholdersProps;
  date: string;
  isLegalPerson: boolean;
  parentMayHaveSharePercentage: boolean;
  parentMayHaveVotePercentage: boolean;
  allEventsDates: string[];
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
}

const ColorRowBarContainer: React.FC<Props> = ({
  shareholder,
  date,
  isLegalPerson,
  allEventsDates,
  virsEndDate,
  virsStoppedParticipatingFromDate,
  parentMayHaveSharePercentage,
  parentMayHaveVotePercentage
}) => {
  const { shareholdersData } = useSelector((state: ApplicationState) => state.shareholdersData);

  const {
    state: {
      showShares,
      showVotes,
      tableId,
      customFilter: { shareholderEventDate, shareholderPeriodDateFrom, shareholderPeriodDateTo }
    }
  } = useShareholdersTableState();
  const [filteredEventDate] = shareholderEventDate;
  const [periodFilterFromDate] = shareholderPeriodDateFrom;
  const [periodFilterToDate] = shareholderPeriodDateTo;

  const {
    shareholderId,
    shareholderStartDate,
    shareholderParentStartDate,
    shareholderStoppedParticipatingFromDate,
    shareholderHistory
  } = shareholder;

  const currentActivityPeriod = getCurrentPeriod(date, shareholdersData?.activityPeriods || []);

  const shareholderHistoryForOneDate = getEventsForSelectedDate(
    shareholderHistory,
    date,
    virsEndDate,
    tableId,
    filteredEventDate,
    shareholderStoppedParticipatingFromDate,
    shareholderStartDate || shareholderParentStartDate,
    shareholdersData?.activityPeriods || []
  );

  const filteredEvents = shareholderHistoryForOneDate.filter(
    (shareholderEvent: ShareholdersHistory, index: number) => {
      if (
        (date === shareholderParentStartDate || date === currentActivityPeriod?.startDate) &&
        shareholderEvent.documentId === null
      ) {
        return index !== 0 || shareholderHistoryForOneDate.length === 1;
      }
      return true;
    }
  );

  const formatedShareholderStartDate = shareholderStartDate
    ? checkDateFormat(date, shareholderStartDate)
    : null;
  const formatedParentStartDate = checkDateFormat(date, shareholderParentStartDate);
  const formatedEndDate = shareholderStoppedParticipatingFromDate
    ? checkDateFormat(date, shareholderStoppedParticipatingFromDate)
    : null;

  const ColorBar = (event: ShareholdersHistory, index: number) => {
    return (
      <ColorBarForFilteredView
        key={`${event.eventId}${event.eventDate}${shareholderId}${date}`}
        index={index}
        allEventsNumber={filteredEvents.length}
        date={date}
        shareholder={shareholder}
        event={event}
        fillWithColor={getColor(
          showShares,
          event.sharePercentage,
          showVotes,
          event.votePercentage,
          isLegalPerson,
          event.votesOnRules
        )}
        borderColor={getBorderColor(
          showShares,
          event.sharePercentage,
          showVotes,
          event.votePercentage,
          isLegalPerson,
          event.votesOnRules
        )}
        textColor={getTextColor(showShares, showVotes, event.sharePercentage, event.votePercentage)}
        value={getValue(
          showShares,
          event.sharePercentage,
          parentMayHaveSharePercentage,
          showVotes,
          event.sharePercentageLessThanPermitted,
          event.votePercentageLessThanPermitted,
          event.votePercentage,
          event.votesOnRules
        )}
        allEventsDates={allEventsDates}
        virsEndDate={virsEndDate}
        virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
      />
    );
  };

  return (
    <>
      {(tableId === 'changeDate' ||
        filteredEventDate ||
        (tableId === 'day' && periodFilterFromDate && periodFilterToDate)) &&
        checkIfColorBarNeeded(
          shareholderStartDate,
          shareholderParentStartDate,
          shareholderStoppedParticipatingFromDate,
          date
        ) &&
        filteredEvents.map((event: ShareholdersHistory) => {
          return (
            <ColorBarByDay
              activityPeriod={shareholdersData?.activityPeriods || []}
              key={`${event.eventId}${event.eventDate}${shareholderId}${date}`}
              date={date}
              shareholder={shareholder}
              event={event}
              fillWithColor={getColor(
                showShares,
                event.sharePercentage,
                showVotes,
                event.votePercentage,
                isLegalPerson,
                event.votesOnRules,
                event.eventDate,
                date
              )}
              borderColor={getBorderColor(
                showShares,
                event.sharePercentage,
                showVotes,
                event.votePercentage,
                isLegalPerson,
                event.votesOnRules,
                event.eventDate,
                date
              )}
              textColor={getTextColor(
                showShares,
                showVotes,
                event.sharePercentage,
                event.votePercentage
              )}
              value={getValue(
                showShares,
                event.sharePercentage,
                parentMayHaveSharePercentage,
                showVotes,
                event.sharePercentageLessThanPermitted,
                event.votePercentageLessThanPermitted,
                event.votePercentage,
                event.votesOnRules
              )}
              isSelectedDateEventDate={!(filteredEvents.length === 1 && date !== event.eventDate)}
              allEventsDates={allEventsDates}
              virsEndDate={virsEndDate}
              virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
            />
          );
        })}

      {(tableId === 'year' || tableId === 'month' || tableId === 'mixed') &&
        !filteredEventDate &&
        checkIfColorBarNeeded(
          formatedShareholderStartDate,
          formatedParentStartDate,
          formatedEndDate,
          date
        ) &&
        filteredEvents.map((event: ShareholdersHistory, index: number) => {
          return ColorBar(event, index);
        })}
    </>
  );
};

export default ColorRowBarContainer;
