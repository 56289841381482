import { Reducer } from 'redux';
import {
  FETCH_ENTERPRISE_DATA_REQUEST,
  FETCH_ENTERPRISE_DATA_SUCCESS,
  FETCH_ENTERPRISE_DATA_ERROR,
  CREATE_ENTERPRISE_REQUEST,
  CREATE_ENTERPRISE_SUCCESS,
  CREATE_ENTERPRISE_ERROR,
  RESET_CREATE_ENTERPRISE_STATE,
  UPDATE_ENTERPRISE_REQUEST,
  UPDATE_ENTERPRISE_SUCCESS,
  UPDATE_ENTERPRISE_ERROR,
  RESET_UPDATE_ENTERPRISE_STATE,
  REMOVE_ENTERPRISE_REQUEST,
  REMOVE_ENTERPRISE_SUCCESS,
  REMOVE_ENTERPRISE_ERROR,
  RESET_REMOVE_ENTERPRISE_STATE,
  GET_OUTLET_IDS_REQUEST,
  GET_OUTLET_IDS_SUCCESS,
  GET_OUTLET_IDS_ERROR,
  DELETE_ENTERPRISE_ALERT_OPEN,
  DELETE_ENTERPRISE_ALERT_CLOSE,
  UPDATE_ENTERPRISE_OUTLETS_ALERT_OPEN,
  UPDATE_ENTERPRISE_OUTLETS_ALERT_CLOSE
} from './enterprisesActionsTypes';
import { EnterpriseData } from './enterprisesTypes';

export interface EnterpriseDataState {
  loadingEnterpriseData: boolean;
  enterprises?: EnterpriseData[];
  enterpriseDataError?: Error;
  creatingEnterprise: boolean;
  isEnterpriseCreated: boolean;
  creatingEnterpriseError?: Error;
  updatingEnterprise: boolean;
  updatingEnterpriseId: number | undefined;
  isEnterpriseUpdated: boolean;
  updatingEnterpriseError?: Error;
  removingEnterprise: boolean;
  removingEnterpriseId: number | undefined;
  isEnterpriseRemoved: boolean;
  removingEnterpriseError?: Error;
  getOutletIdsLoading: boolean;
  outletIds?: number[];
  getOutletIdsError?: Error;
  deleteEnterpriseAlertOpen: boolean;
  editEnterpriseAlertOpen: boolean;
}

export const initialState: EnterpriseDataState = {
  loadingEnterpriseData: false,
  enterprises: undefined,
  enterpriseDataError: undefined,
  creatingEnterprise: false,
  isEnterpriseCreated: false,
  creatingEnterpriseError: undefined,
  updatingEnterprise: false,
  updatingEnterpriseId: undefined,
  isEnterpriseUpdated: false,
  updatingEnterpriseError: undefined,
  removingEnterprise: false,
  removingEnterpriseId: undefined,
  isEnterpriseRemoved: false,
  removingEnterpriseError: undefined,
  getOutletIdsLoading: false,
  outletIds: undefined,
  getOutletIdsError: undefined,
  deleteEnterpriseAlertOpen: false,
  editEnterpriseAlertOpen: false
};

export const enterprisesReducer: Reducer<EnterpriseDataState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENTERPRISE_DATA_REQUEST:
      return {
        ...state,
        loadingEnterpriseData: true
      };
    case FETCH_ENTERPRISE_DATA_SUCCESS:
      return {
        ...state,
        loadingEnterpriseData: false,
        enterprises: action.payload.enterpriseData
      };
    case FETCH_ENTERPRISE_DATA_ERROR:
      return {
        ...state,
        enterprises: undefined,
        loadingEnterpriseData: false,
        enterpriseDataError: action?.payload?.data
      };
    case CREATE_ENTERPRISE_REQUEST:
      return {
        ...state,
        creatingEnterprise: true
      };
    case CREATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        creatingEnterprise: false,
        isEnterpriseCreated: true
      };
    case CREATE_ENTERPRISE_ERROR:
      return {
        ...state,
        creatingEnterprise: false,
        isEnterpriseCreated: false,
        creatingEnterpriseError: action?.payload?.data
      };
    case RESET_CREATE_ENTERPRISE_STATE:
      return {
        ...state,
        creatingEnterprise: false,
        isEnterpriseCreated: false,
        creatingEnterpriseError: undefined
      };
    case UPDATE_ENTERPRISE_REQUEST:
      return {
        ...state,
        updatingEnterprise: true,
        updatingEnterpriseId: action.payload.enterpriseId
      };
    case UPDATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        updatingEnterprise: false,
        isEnterpriseUpdated: true,
        updatingEnterpriseId: undefined
      };
    case UPDATE_ENTERPRISE_ERROR:
      return {
        ...state,
        updatingEnterprise: false,
        isEnterpriseUpdated: false,
        updatingEnterpriseError: action?.payload?.data,
        updatingEnterpriseId: undefined
      };
    case RESET_UPDATE_ENTERPRISE_STATE:
      return {
        ...state,
        updatingEnterprise: false,
        isEnterpriseUpdated: false,
        updatingEnterpriseError: undefined
      };
    case REMOVE_ENTERPRISE_REQUEST:
      return {
        ...state,
        removingEnterprise: true,
        removingEnterpriseId: action.payload.enterpriseId
      };
    case REMOVE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        removingEnterprise: false,
        isEnterpriseRemoved: true,
        removingEnterpriseId: undefined
      };
    case REMOVE_ENTERPRISE_ERROR:
      return {
        ...state,
        removingEnterprise: false,
        isEnterpriseRemoved: false,
        removingEnterpriseError: action?.payload?.data,
        removingEnterpriseId: undefined
      };
    case RESET_REMOVE_ENTERPRISE_STATE:
      return {
        ...state,
        removingEnterprise: false,
        isEnterpriseRemoved: false,
        removingEnterpriseError: undefined
      };
    case GET_OUTLET_IDS_REQUEST:
      return {
        ...state,
        getOutletIdsLoading: true
      };
    case GET_OUTLET_IDS_SUCCESS:
      return {
        ...state,
        getOutletIdsLoading: false,
        outletIds: action.payload
      };
    case GET_OUTLET_IDS_ERROR:
      return {
        ...state,
        getOutletIdsLoading: false,
        getOutletIdsError: action.payload
      };
    case DELETE_ENTERPRISE_ALERT_OPEN:
      return {
        ...state,
        deleteEnterpriseAlertOpen: true,
        removingEnterprise: false
      };
    case DELETE_ENTERPRISE_ALERT_CLOSE:
      return {
        ...state,
        deleteEnterpriseAlertOpen: false,
        outletIds: undefined
      };
    case UPDATE_ENTERPRISE_OUTLETS_ALERT_OPEN:
      return {
        ...state,
        editEnterpriseAlertOpen: true
      };
    case UPDATE_ENTERPRISE_OUTLETS_ALERT_CLOSE:
      return {
        ...state,
        editEnterpriseAlertOpen: false,
        outletIds: undefined
      };
    default:
      return state;
  }
};
