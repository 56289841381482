import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';

import { ColumnHeaderField, ColumnsDisplayStatus } from '../tableState/tableTypes';
import { LegalEntityData } from '../../../../store/externalSystemData/externalSystemDataTypes';
import { LegalEntityFinancialSupportColumnHeader } from '../LegalEntityFinancialSupportTable';
import { TableCustomFilterCellGeneric } from '../../../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { replaceDecimalPoint } from '../../../../utils/tableDataFunctions';

interface Props {
  columnsDisplayStatus: ColumnsDisplayStatus;
  onCustomFilterChange: (column: ColumnHeaderField, value: string | null) => void;
  columnNames: LegalEntityFinancialSupportColumnHeader[];
  pageData: LegalEntityData[];
}

const CustomFilter: React.FC<Props> = ({
  columnsDisplayStatus,
  onCustomFilterChange,
  columnNames,
  pageData
}) => {
  function getUniqueOptions(name: ColumnHeaderField, data: LegalEntityData[]) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] !== null)
            .map((item) => replaceDecimalPoint(item[name]?.toString()))
        )
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={onCustomFilterChange}
              uniqueOptions={getUniqueOptions(column.id, pageData)}
            />
          ))}
      </TableRow>
    </TableBody>
  );
};
export default CustomFilter;
