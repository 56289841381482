import moment from 'moment';
import {
  ProvidedColumnsDisplay,
  ProvidedDataTableColumn,
  ProvidedDataTableField,
  ProvidedDataTableState
} from './tableInitialStateAndTypes';
import { ProvidedDataVirs } from '../../../store/providedData/providedDataTypes';
import {
  Filter,
  filterData,
  getConfirmationAndRelevancyStatusFilter,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function filterByStatus(
  records: ProvidedDataVirs[],
  showUnconfirmed: boolean,
  showRelevant: boolean,
  showNotRelevant: boolean
): ProvidedDataVirs[] {
  return getConfirmationAndRelevancyStatusFilter<ProvidedDataVirs>(
    showUnconfirmed,
    showRelevant,
    showNotRelevant
  )(records);
}

export function filterAndSortProvidedVirsTableData(
  tableState: ProvidedDataTableState,
  data: ProvidedDataVirs[]
): ProvidedDataVirs[] {
  if (data.length === 0) {
    return data;
  }

  const filters: Filter<ProvidedDataVirs>[] = [
    getStringMappedColumnFilter(tableState.customFilter.dataType, ({ dataType }) => [dataType]),
    getStringMappedColumnFilter(tableState.customFilter.documentStatus, ({ documentStatus }) => [
      documentStatus.label
    ]),
    getStringMappedColumnFilter(
      tableState.customFilter.confirmationDate,
      ({ confirmationDate }) => [confirmationDate]
    ),
    getStringMappedColumnFilter(
      tableState.customFilter.confirmationPersonFullName,
      ({ confirmationPersonFullName }) => [confirmationPersonFullName]
    ),
    getStringMappedColumnFilter(tableState.customFilter.annulmentDate, ({ annulmentDate }) => [
      annulmentDate
    ]),
    getStringMappedColumnFilter(
      tableState.customFilter.annulmentReasonTypeName,
      ({ annulmentReasonTypeName }) => [annulmentReasonTypeName]
    )
  ];

  const filtered = filterData(data, filters);
  return stableSortWrapped(filtered, tableState.order, tableState.sortBy);
}

export function getTableExportDefinition(
  columnHeaders: ProvidedDataTableColumn[],
  displayStatus: ProvidedColumnsDisplay
): TableExportDefinition<ProvidedDataVirs> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    ProvidedDataVirs,
    ProvidedDataTableField
  >(columnHeaders, displayStatus);

  return {
    title: 'El. dokumentai',
    columnGroups: [
      {
        columns: [
          stringValueColumn('dataType', { width: 25 }),
          mappedValueColumn(
            'documentStatus',
            ({ documentStatus: { label } }) => [{ values: [label] }],
            { width: 25 }
          ),
          mappedValueColumn(
            'confirmationDate',
            ({ confirmationDate }) => [
              {
                values: [confirmationDate ? moment(confirmationDate).format('YYYY-MM-DD') : '']
              }
            ],
            { width: 25 }
          ),
          stringValueColumn('confirmationPersonFullName', { width: 25 }),
          stringValueColumn('annulmentDate', { width: 25 }),
          stringValueColumn('annulmentReasonTypeName', { width: 25 })
        ]
      }
    ]
  };
}
