import _ from 'lodash';
import { EnterpriseDataWithOutlets, OutletData } from '../../../store/outlets/outletsTypes';
import {
  OutletDataTableColumn,
  OutletDataTableColumnsDisplayStatus,
  OutletDataTableField,
  OutletDataTableState
} from './initialStateAndTypes';
import {
  getConfirmationAndRelevancyStatusFilter,
  filterData,
  stableSortWrapped,
  Filter,
  getStringMappedColumnFilter,
  getStringCompareLessFilter,
  getStringCompareMoreFilter
} from '../../../utils/tableDataFunctions';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function outletsToEnterprisesWithOutlets(
  enterprises: EnterpriseDataWithOutlets[],
  outlets: OutletData[]
): EnterpriseDataWithOutlets[] {
  const enterpriseIdDictionary = _.groupBy(outlets, 'enterpriseId');
  return enterprises
    .filter((enterprise) => enterpriseIdDictionary[enterprise.enterpriseId])
    .map((ent) => ({
      ...ent,
      outlets: [...enterpriseIdDictionary[ent.enterpriseId]]
    }));
}

export function enterprisesOutletsOrOnlySingleEnterpriseOutlets(
  enterprises: EnterpriseDataWithOutlets[],
  singleEnterpriseGroup: EnterpriseDataWithOutlets | undefined
): EnterpriseDataWithOutlets[] {
  if (singleEnterpriseGroup) {
    const singleEnterpriseWithOutlets: EnterpriseDataWithOutlets | undefined = enterprises.find(
      (ent) => ent.enterpriseId === singleEnterpriseGroup.enterpriseId
    );
    return singleEnterpriseWithOutlets
      ? [singleEnterpriseWithOutlets]
      : [{ ...singleEnterpriseGroup, outlets: [] }];
  }
  return enterprises;
}

export function filterTable(
  enterpriseWithOutlets: EnterpriseDataWithOutlets[],
  tableState: OutletDataTableState
): EnterpriseDataWithOutlets[] {
  const {
    showStatusNotSigned: showStatusUnconfirmed,
    showStatusRelevant,
    showStatusOutdated: showStatusNotRelevant
  } = tableState;

  let ouletsOfSingleEnterpriseOrAll = enterpriseWithOutlets;

  if (!(tableState.showGroupOfSingleEnterprise === undefined && !tableState.showNewRecordInput)) {
    const shownSingleEnterprise = ouletsOfSingleEnterpriseOrAll.find(
      (group) => group.enterpriseId === tableState.showGroupOfSingleEnterprise?.enterpriseId
    );

    ouletsOfSingleEnterpriseOrAll = shownSingleEnterprise ? [shownSingleEnterprise] : [];
  }

  const filters: Filter<OutletData>[] = [
    getConfirmationAndRelevancyStatusFilter(
      showStatusUnconfirmed,
      showStatusRelevant,
      showStatusNotRelevant
    ),
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ outletName }) => [
      outletName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.outletTypeName, ({ outletTypeName }) => [
      outletTypeName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.outletGroupName, ({ outletGroupName }) => [
      outletGroupName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.establishedDate, ({ establishedDate }) => [
      establishedDate
    ]),
    getStringCompareMoreFilter(tableState.customFilter.controlledFrom, ({ controlledFrom }) => [
      controlledFrom
    ]),
    getStringCompareLessFilter(tableState.customFilter.controlledTo, ({ controlledTo }) => [
      controlledTo
    ]),
    getStringMappedColumnFilter(
      tableState.customFilter.internationalNumber,
      ({ internationalNumber }) => [internationalNumber]
    )
  ];
  ouletsOfSingleEnterpriseOrAll = ouletsOfSingleEnterpriseOrAll.map((enterprise) => ({
    ...enterprise,
    outlets: filterData(enterprise.outlets, filters)
  }));

  return ouletsOfSingleEnterpriseOrAll;
}

export function filterAndSortTableData(
  enterpriseWithOutlets: EnterpriseDataWithOutlets[],
  tableState: OutletDataTableState
): EnterpriseDataWithOutlets[] {
  if (enterpriseWithOutlets.length === 0) {
    return [];
  }

  const ouletsOfSingleEnterpriseOrAll = filterTable(enterpriseWithOutlets, tableState);

  const { order, sortBy } = tableState;

  const sorted = ouletsOfSingleEnterpriseOrAll.map((enterprise) => ({
    ...enterprise,
    outlets: stableSortWrapped(enterprise.outlets, order, sortBy)
  }));

  return sorted;
}

export function getTableExportDefinition(
  columnHeaders: OutletDataTableColumn[],
  displayStatus: OutletDataTableColumnsDisplayStatus
): TableExportDefinition<OutletData> {
  const { stringValueColumn } = getColumnBuilder<OutletData, OutletDataTableField>(
    columnHeaders,
    displayStatus
  );

  return {
    title: 'Visuomenės informavimo priemonės (VIP)',
    columnGroups: [
      {
        columns: [
          stringValueColumn('outletName', { width: 45 }),
          {
            visible: true,
            identifier: 'VIRSName',
            cellMapper: ({ enterpriseTypeName }) => [{ values: [enterpriseTypeName] }],
            header: 'VIRS veiklos rūšis',
            style: { width: 45 }
          },
          stringValueColumn('outletTypeName', { width: 45 }),
          stringValueColumn('outletGroupName', { width: 45 }),
          stringValueColumn('establishedDate', { width: 15 })
        ]
      },
      {
        header: 'VIP valdoma',
        columns: [
          stringValueColumn('controlledFrom', { width: 15 }),
          stringValueColumn('controlledTo', { width: 15 })
        ]
      },
      {
        columns: [stringValueColumn('internationalNumber', { width: 15 })]
      }
    ]
  };
}
