import {
  MessageCustomFilter,
  MessagesTableColumn,
  MessageTableState
} from './tableInitialStateAndTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../utils/tableDataFunctions';
import { UserMessage } from '../../../store/userMessages/userMessageTypes';

export function updateMessagesCustomFilter(
  filter: MessageCustomFilter,
  filterBy: MessagesTableColumn,
  value: string | null
): MessageCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function filterAndSortMessages(
  tableState: MessageTableState,
  data: UserMessage[]
): UserMessage[] {
  if (data.length === 0) {
    return [];
  }
  const columnsFilters = tableState.customFilter;
  const filters: Filter<UserMessage>[] = [
    getStringMappedColumnFilter(columnsFilters.header, ({ header }) => [header]),
    getStringMappedColumnFilter(columnsFilters.receivedDate, ({ receivedDate }) => [receivedDate])
  ];

  const filtered = filterData(data, filters);

  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
