import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Divider,
  Paper,
  Typography
} from '@material-ui/core';
import React from 'react';

import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, FormikProps } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CloseDialogButton } from '../../Dialogs/Dialog/CloseButton';
import { CloseContinueButton } from '../../Dialogs/Dialog/CloseContinueButton';
import { Strings } from '../../../utils/strings/Strings';

interface Props<TData extends object> {
  onClose: () => void;
  onExit: () => void;
  onSubmit: (data: TData) => void;
  loading: boolean;
  error: boolean;
  initialValues: TData;
  validationSchema?: Yup.ObjectSchema<Yup.Shape<object | undefined, TData>>;
  formContent: React.FC<FormikProps<TData>>;
}

export const GenericDialogForm = <TData extends object>({
  formContent: FormContent,
  loading,
  open,
  onClose,
  onExit,
  onSubmit,
  initialValues,
  validationSchema,
  error,
  title,
  ...props
}: Omit<DialogProps, keyof Props<TData>> & Props<TData>) => {
  return (
    <Dialog open={open} onClose={onClose} onExit={onExit} fullWidth maxWidth="md" {...props}>
      {open && (
        <Paper>
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h2">{title}</Typography>

            <Button onClick={onClose} style={{ margin: '0' }}>
              <CloseIcon />
            </Button>
          </DialogTitle>

          <Divider light style={{ margin: '0px 24px' }} />

          {loading && (
            <Box
              style={{
                padding: '13px',
                height: '100px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <CircularProgress size="50px" />
            </Box>
          )}

          {!loading && (
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values);
                setSubmitting(false);
              }}
            >
              {(innerProps: FormikProps<TData>) => (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Form>
                    {!error && <FormContent {...innerProps} />}

                    {error && <Typography style={{ padding: '13px' }}>Įvyko klaida.</Typography>}

                    <DialogActions className="dialog-actions">
                      <CloseDialogButton label={Strings.button__cancel} onClick={onClose} />

                      {!error && (
                        <CloseContinueButton onClick={innerProps.submitForm} label="Išsaugoti" />
                      )}
                    </DialogActions>
                  </Form>
                </MuiPickersUtilsProvider>
              )}
            </Formik>
          )}
        </Paper>
      )}
    </Dialog>
  );
};
