import React, { ChangeEvent, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  AddPersonDialogElement,
  ShareholdersProps
} from '../../../../../store/shareholders/shareholdersTypes';
import { CustomCloseIconButton } from '../../../../../components/Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../../../../../components/Dialogs/Dialog/CloseButton';
import { noOptionsText } from '../../../../../components/TableCustomFilterCell/constants';
import { CloseContinueButton } from '../../../../../components/Dialogs/Dialog/CloseContinueButton';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { Strings } from '../../../../../utils/strings/Strings';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onSubmit: (selectedShareholder: AddPersonDialogElement) => void;
  shareholders: AddPersonDialogElement[];
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 35px 10px 35px'
  },
  icon: {
    color: theme.palette.info.dark,
    width: 20,
    height: 20
  },
  divider: {
    padding: '0 35px'
  },
  contentWrap: {
    padding: '6px 35px 24px 35px'
  },
  actions: {
    padding: '10px 34px',
    backgroundColor: '#F3F3F3'
  },
  text: {
    fontFamily: 'Open Sans',
    fontSize: '0.8rem',
    fontWeight: 600,
    color: theme.palette.info.dark
  }
}));

export const SelectParentDialog: React.FC<Props> = ({ open, setOpen, onSubmit, shareholders }) => {
  const classes = useStyles();

  const [selectedShareholder, setSelectedShareholder] = useState<AddPersonDialogElement | null>(
    null
  );

  const shareholderData = useSelector(
    (state: ApplicationState) => state.shareholdersData.shareholdersData
  );

  const handleChange = (event: ChangeEvent<object>, value: AddPersonDialogElement | null) => {
    if (value) {
      const { shareholderLevel, shareholderId, personId, shareholderPath } = value;

      const getIndex = (): number => {
        return getIndexFromShareholder(shareholderData?.shareholders || []);
      };

      const getIndexFromShareholder = (shareholderList: ShareholdersProps[]): number => {
        let i = 0;
        for (; i < shareholderList.length; i++) {
          if (
            shareholderList[i].shareholderId === shareholderId &&
            shareholderList[i].shareholderLevel === shareholderLevel
          ) {
            return i;
          }
        }
        const [_index] = shareholderList
          .map((s) => s.shareholderPersons.map((p) => getIndexFromShareholder(p.shareholders)))
          .flatMap((a) => a);
        return _index || 0;
      };

      const index = getIndex();

      setSelectedShareholder({
        shareholderLevel: Number(shareholderLevel),
        shareholderId: Number(shareholderId),
        personId: Number(personId),
        shareholderPath: shareholderPath + '__' + index,
        index
      });
    }
  };

  const handleClose = () => {
    setSelectedShareholder(null);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (selectedShareholder) {
      onSubmit(selectedShareholder);
    }
    setSelectedShareholder(null);
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <div className={`${classes.title}`}>
        <Typography variant="h2">Naujo dalyvio pridėjimas</Typography>
        <CustomCloseIconButton onClick={handleClose} />
      </div>
      <Divider className={classes.divider} />
      <DialogContent className={classes.contentWrap}>
        <DialogContentText>
          Pasirinkite dalyvį, kuriam norite priskirti naują dalyvį:
        </DialogContentText>
        <Autocomplete
          size="small"
          clearOnEscape
          id="filter-period"
          options={shareholders}
          getOptionLabel={(shareholder) =>
            shareholder.personLegalFormAbbreviation
              ? `${shareholder.nameToDisplay} (${shareholder.personLegalFormAbbreviation})`
              : `${shareholder.nameToDisplay}`
          }
          noOptionsText={noOptionsText}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          classes={{ option: classes.text }}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CloseDialogButton label={Strings.button__cancel} onClick={handleClose} />
        <CloseContinueButton
          label="Tęsti"
          disabled={!selectedShareholder}
          onClick={handleSubmit}
          withIcon={true}
        />
      </DialogActions>
    </Dialog>
  );
};
