import React from 'react';
import { Box, Button, ButtonProps, Popover, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import virsisTheme from '../../../style/virsisTheme';
import { TooltipOnTableActionButton } from '../../Tooltips/TooltipOnTableActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { TooltipButton, TooltipButtonProps } from '../../Tooltips/TooltipButton';
import { ErrorType } from '../../../store/virsis/dataTypes';

interface Props {
  onClickContinue?: () => void;
  onclickRemove?: () => void;
  linkPath: string;
  editingWarningMessage?: string;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      backgroundColor: 'transparent',
      boxShadow: 'unset'
    },
    margin: {
      margin: '15px 0px',
      marginLeft: '5px'
    },
    moreVertButton: {
      height: '40px',
      width: '40px',
      border: `1px solid ${virsisTheme.palette.info.light}`,
      borderRadius: 5,
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      backgroundColor: virsisTheme.palette.secondary.light,
      color: '#637082',
      paddingTop: '0',
      paddingBottom: '0'
    },
    popUpButton: {
      padding: 0,
      backgroundColor: virsisTheme.palette.secondary.light,
      color: '#637082',
      justifyContent: 'left',
      margin: '2px'
    },
    topButton: {
      borderBottom: '1px solid white',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      marginBottom: '0px'
    },
    bottomButton: {
      borderTop: '1px solid white',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      marginTop: '0px'
    },
    link: {
      textDecoration: 'none'
    },
    themeButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.primary.main,
        borderRadius: '5px'
      },
      '&:active': {
        borderColor: virsisTheme.palette.primary.main,
        backgroundColor: '#DBEDFC',
        color: virsisTheme.palette.primary.main,
        borderRadius: '5px'
      }
    },
    dangerButton: {
      '&:hover': {
        borderColor: virsisTheme.palette.error.main,
        backgroundColor: virsisTheme.palette.secondary.light,
        color: virsisTheme.palette.error.main,
        borderRadius: '5px'
      }
    },
    buttonsBox: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: virsisTheme.palette.secondary.light
    },
    icon: {
      height: 30,
      padding: '5px 10px',
      color: 'inherit',
      justifyContent: 'left'
    },
    text: {
      color: 'inherit',
      padding: '1px 12px 1px 5px'
    }
  })
);

const MoreVertButtonWithEditDeletePopover: React.FC<Props> = ({
  editingWarningMessage,
  onClickContinue,
  onclickRemove,
  linkPath,
  disabled
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionButtonToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickContinue = () => {
    onClickContinue?.();
    history.push(linkPath);
  };

  const baseButtonProps: Partial<TooltipButtonProps & ButtonProps> = {
    variant: 'outlined',
    buttonBoxProps: { display: 'flex', flexDirection: 'column' }
  };

  return (
    <>
      <TooltipOnTableActionButton
        text={`${disabled ? 'Neturite prieigos prie įrašo redagavimo' : ''}`}
      >
        <span>
          <Button
            aria-label="Išskleisti daugiau veiksmų"
            disabled={disabled}
            variant="text"
            onClick={handleActionButtonToggle}
            className={`${classes.moreVertButton} ${classes.themeButton} ${classes.margin}`}
            style={
              anchorEl
                ? {
                    backgroundColor: '#DBEDFC',
                    borderColor: virsisTheme.palette.primary.main
                  }
                : {}
            }
          >
            <MoreVertIcon
              fontSize="small"
              className={classes.icon}
              style={{ borderRight: 'none' }}
            />
          </Button>
        </span>
      </TooltipOnTableActionButton>
      <Popover
        id="delete-action"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <TooltipButton
            {...baseButtonProps}
            className={`${classes.popUpButton} ${classes.themeButton} ${classes.topButton}`}
            onClick={handleClickContinue}
            tooltipProps={{
              text: editingWarningMessage ? editingWarningMessage : undefined
            }}
          >
            <EditIcon fontSize="small" className={classes.icon} />
            <Typography variant="h5" className={classes.text}>
              Tęsti pildymą
            </Typography>
          </TooltipButton>

          <TooltipButton
            {...baseButtonProps}
            onClick={onclickRemove}
            className={`${classes.popUpButton} ${classes.dangerButton} ${classes.bottomButton}`}
            tooltipProps={{
              errorType: ErrorType.CRITICAL,
              text: 'Įrašo šalinimas. Patvirtinus įrašo šalinimą - įrašas pašalinamas iš sistemos'
            }}
          >
            <DeleteIcon fontSize="small" className={classes.icon} />
            <Typography variant="h5" className={classes.text}>
              Pašalinti
            </Typography>
          </TooltipButton>
        </Box>
      </Popover>
    </>
  );
};

export default MoreVertButtonWithEditDeletePopover;
