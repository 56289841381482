import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST,
  CREATE_PROFESSIONAL_MISCONDUCT_REQUEST,
  PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST,
  PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST,
  PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST,
  PROFESSIONAL_MISCONDUCT_VIRS_DOCUMENT_PDF_REQUEST,
  REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST,
  UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST
} from './professionalMisconductsActionTypes';
import {
  annulProfessionalMisconduct,
  annulProfessionalMisconductError,
  annulProfessionalMisconductSuccess,
  createProfessionalMisconduct,
  createProfessionalMisconductError,
  createProfessionalMisconductSuccess,
  getProfessionalMisconductByDocId,
  getProfessionalMisconductInstDataError,
  getProfessionalMisconductInstDataRequest,
  getProfessionalMisconductInstDataSuccess,
  getProfessionalMisconductVirsDataError,
  getProfessionalMisconductVirsDataRequest,
  getProfessionalMisconductVirsDataSuccess,
  getProfessionalMisconductVirsDocumentRequest,
  removeProfessionalMisconduct,
  removeProfessionalMisconductError,
  removeProfessionalMisconductSuccess,
  resetAnnulProfessionalMisconductState,
  resetProfessionalMisconductRemovingState,
  updateProfessionalMisconduct,
  updateProfessionalMisconductError,
  updateProfessionalMisconductSuccess
} from './professionalMisconductsActions';
import {
  annulProfessionalMisconductRecord,
  deleteRemoveProfessionalMisconduct,
  getProfessionalMisconductDataByDocId,
  getProfessionalMisconductInstDataPage,
  getProfessionalMisconductVirsData,
  postCreateProfessionalMisconduct,
  putUpdateProfessionalMisconduct
} from './professionalMisconductsApi';
import { getPaymentDocument } from '../payments/paymentsApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  getProfessionalMisconductInstPageRequest,
  selectProfessionalMisconductTableDataState
} from '../professionalMisconductTable/professionalMisconductsUtilities';
import { ProfessionalMisconductDataTableState } from '../professionalMisconductTable/professionalMisconductsTableReducer';
import { updateTempDataState } from '../professionalMisconductTable/professionalMisconductsActions';

function* handleProfessionalMisconductVirsDataRequestSaga(
  action: ActionType<typeof getProfessionalMisconductVirsDataRequest>
) {
  try {
    const virsId = action.payload;
    const { data } = yield call(getProfessionalMisconductVirsData, virsId);
    yield put(getProfessionalMisconductVirsDataSuccess(data));
  } catch (err) {
    yield put(getProfessionalMisconductVirsDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleProfessionalMisconductVirsDocumentRequestSaga(
  action: ActionType<typeof getProfessionalMisconductVirsDocumentRequest>
) {
  try {
    const docId = action.payload;

    const { data } = yield call(getPaymentDocument, docId);
    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]));
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleProfessionalMisconductInstDataRequestSaga() {
  try {
    const { tableDataState }: ProfessionalMisconductDataTableState = yield select(
      selectProfessionalMisconductTableDataState
    );
    yield put(updateTempDataState(tableDataState));
    const { data } = yield call(
      getProfessionalMisconductInstDataPage,
      getProfessionalMisconductInstPageRequest(tableDataState)
    );
    yield put(getProfessionalMisconductInstDataSuccess(data));
  } catch (err) {
    yield put(getProfessionalMisconductInstDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleCreateProfessionalMisconductSaga(
  action: ActionType<typeof createProfessionalMisconduct>
) {
  try {
    const { record } = action.payload;
    yield call(postCreateProfessionalMisconduct, record);
    yield put(createProfessionalMisconductSuccess());
    yield put(sendMessage('success', 'Rimtų profesinių pažeidimų įrašas išsaugotas'));
  } catch (err) {
    yield put(createProfessionalMisconductError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdateProfessionalMisconductSaga(
  action: ActionType<typeof updateProfessionalMisconduct>
) {
  try {
    const { recordUpdated, professionalMisconductId } = action.payload;
    yield call(putUpdateProfessionalMisconduct, recordUpdated, professionalMisconductId);
    yield put(updateProfessionalMisconductSuccess());
    yield put(sendMessage('success', 'Rimtų profesinių pažeidimų įrašas atnaujintas'));
  } catch (err) {
    yield put(updateProfessionalMisconductError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemoveProfessionalMisconductSaga(
  action: ActionType<typeof removeProfessionalMisconduct>
) {
  try {
    const { professionalMisconductId } = action.payload;
    yield call(deleteRemoveProfessionalMisconduct, professionalMisconductId);
    yield put(removeProfessionalMisconductSuccess());
    yield put(getProfessionalMisconductInstDataRequest());
    yield put(resetProfessionalMisconductRemovingState());
    yield put(sendMessage('success', 'Rimtų profesinių pažeidimų įrašas pašalintas'));
  } catch (err) {
    yield put(removeProfessionalMisconductError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleAnnulProfessionalMisconductSaga(
  action: ActionType<typeof annulProfessionalMisconduct>
) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulProfessionalMisconductRecord, documentStatusId, annulRecord);
    yield put(annulProfessionalMisconductSuccess());
    yield put(getProfessionalMisconductInstDataRequest());
    yield put(resetAnnulProfessionalMisconductState());
    yield put(sendMessage('success', 'Rimtų profesinių pažeidimų įrašas anuliuotas'));
  } catch (err) {
    yield put(annulProfessionalMisconductError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleGetProfessionalMisconductByDocIdSaga(
  action: ActionType<typeof getProfessionalMisconductByDocId>
) {
  try {
    const { data } = yield call(getProfessionalMisconductDataByDocId, action.payload);
    yield put(getProfessionalMisconductInstDataSuccess(data));
  } catch (err) {
    yield put(getProfessionalMisconductInstDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* professionalMisconductsSaga() {
  yield takeEvery(
    PROFESSIONAL_MISCONDUCT_VIRS_DATA_REQUEST,
    handleProfessionalMisconductVirsDataRequestSaga
  );
  yield takeEvery(
    PROFESSIONAL_MISCONDUCT_VIRS_DOCUMENT_PDF_REQUEST,
    handleProfessionalMisconductVirsDocumentRequestSaga
  );
  yield takeLatest(
    PROFESSIONAL_MISCONDUCT_INST_DATA_REQUEST,
    handleProfessionalMisconductInstDataRequestSaga
  );
  yield takeEvery(CREATE_PROFESSIONAL_MISCONDUCT_REQUEST, handleCreateProfessionalMisconductSaga);
  yield takeEvery(UPDATE_PROFESSIONAL_MISCONDUCT_REQUEST, handleUpdateProfessionalMisconductSaga);
  yield takeEvery(REMOVE_PROFESSIONAL_MISCONDUCT_REQUEST, handleRemoveProfessionalMisconductSaga);
  yield takeEvery(ANNUL_PROFESSIONAL_MISCONDUCT_REQUEST, handleAnnulProfessionalMisconductSaga);
  yield takeEvery(
    PROFESSIONAL_MISCONDUCT_BY_DOC_ID_REQUEST,
    handleGetProfessionalMisconductByDocIdSaga
  );
}

export default professionalMisconductsSaga;
