import React from 'react';
import { TableHead, TableRow } from '@material-ui/core/';
import {
  useInstitutionDataReportTableDispatch,
  useInstitutionDataReportTableState
} from './Context';
import {
  InstitutionDataReportHeaderField,
  InstitutionDataReportTableState
} from './tableState/initialTableStateAndTypes';
import { getSortLabelPropsFactory } from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';

const InstitutionDataReportTableHead: React.FC = () => {
  const { state: tableState } = useInstitutionDataReportTableState();
  const { dispatch } = useInstitutionDataReportTableDispatch();

  const handleSetSortByColumn = (column: InstitutionDataReportHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    InstitutionDataReportTableState,
    typeof handleSetSortByColumn,
    InstitutionDataReportHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.reportType && (
          <TableSortingHeaderCell
            label="Pranešimo tipas"
            columnName="reportType"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.reportedDateTime && (
          <TableSortingHeaderCell
            label="Data ir laikas"
            columnName="reportedDateTime"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.institutionCode && (
          <TableSortingHeaderCell
            label="Institucijos kodas"
            columnName="institutionCode"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.institutionName && (
          <TableSortingHeaderCell
            label="Institucijos pavadinimas"
            columnName="institutionName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.institutionEmail && (
          <TableSortingHeaderCell
            label="Institucijos el. paštas"
            columnName="institutionEmail"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.virsCode && (
          <TableSortingHeaderCell
            label="VIRS kodas"
            columnName="virsCode"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.virsName && (
          <TableSortingHeaderCell
            label="VIRS pavadinimas"
            columnName="virsName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.reportedBy && (
          <TableSortingHeaderCell
            label="Pranešimą pateikė"
            columnName="reportedBy"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.reporterEmail && (
          <TableSortingHeaderCell
            label="El. paštas"
            columnName="reporterEmail"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default InstitutionDataReportTableHead;
