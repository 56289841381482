import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, makeStyles } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import { Form, Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import {
  createLegalForeignPerson,
  resetCreateLegalForeignPersonState,
  selectLegalSearchQuery
} from '../../../store/legalPersonData/legalDataActions';
import {
  LegalPersonRecord,
  LegalPersonSearchQuery
} from '../../../store/legalPersonData/legalDataTypes';
import { ApplicationState } from '../../../store';
import { FormikFormTextField } from '../../FormikFields/FormikFormTextField';
import { FormikFormSelectField } from '../../FormikFields/FormikFormSelectField';
import { useDialogPersonCreationFormStyles } from '../styles';
import { filterOutLithuaniaFromJarCountries } from '../Utilities';
import { RowState, Values } from './types';
import { FIELD_CHECK_REGEX } from '../../../utils/tableTypes';
import { Strings } from '../../../utils/strings/Strings';

interface Props {
  close: () => void;
  searchPerson: (personQuery: LegalPersonSearchQuery, searchedCountry?: JarCountry) => void;
}

const initialRowState: RowState = {
  legalForeignPerson: undefined,
  showConfirmation: false,
  confirmationAnchor: null
};

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    boxContainer: {
      width: '100%'
    }
  }
}));

export const FindPersonAddLegalForeignForm: React.FC<Props> = ({ close, searchPerson }) => {
  const classes = useDialogPersonCreationFormStyles();
  const styledClasses = useStyles();

  const { jarCountries } = useSelector((state: ApplicationState) => state.virsis);

  const [rowState, setRowState] = useState<RowState>(initialRowState);

  const { creatingLegalForeignPerson, legalForeignPersonCreated, selectedLegalPersonSearchQuery } =
    useSelector((state: ApplicationState) => state.legalData);

  const reduxDispatch = useDispatch();

  const initValues: Partial<Values> = {
    legalName: selectedLegalPersonSearchQuery?.legalName || '',
    legalCode: selectedLegalPersonSearchQuery?.legalCode || '',
    jarCountry:
      jarCountries?.find(
        (country) => country.countryCode === selectedLegalPersonSearchQuery?.countryCode
      ) || null
  };

  const validationSchema = Yup.object().shape(
    {
      legalName: Yup.string()
        .trim()
        .matches(FIELD_CHECK_REGEX, 'Įvesti netinkami simboliai')
        .required('Įmonės pavadinimas privalomas'),
      legalCode: Yup.string()
        .max(40, 'Maksimalus simboliu skaičius 40')
        .matches(FIELD_CHECK_REGEX, 'Įvesti netinkami simboliai')
        .trim()
        .required('Įmonės kodas privalomas'),
      jarCountry: Yup.string().nullable().required('Dokumentą išdavusi valstybė privaloma')
    },
    []
  );

  const setSelectedLegalSearchQuery = (legal: LegalPersonRecord) => {
    const query = {
      legalName: legal.legalName,
      legalCode: legal.legalCode,
      countryCode: legal.countryCode
    };
    reduxDispatch(selectLegalSearchQuery(query));
  };

  useEffect(() => {
    if (legalForeignPersonCreated) {
      setRowState({ ...rowState, showConfirmation: false });
      reduxDispatch(resetCreateLegalForeignPersonState());
      close();
      if (selectedLegalPersonSearchQuery) {
        searchPerson(
          selectedLegalPersonSearchQuery,
          jarCountries?.find(
            (country) => country.countryCode === selectedLegalPersonSearchQuery.countryCode
          )
        );
      }
    }
  }, [
    reduxDispatch,
    legalForeignPersonCreated,
    rowState,
    close,
    selectedLegalPersonSearchQuery,
    searchPerson,
    jarCountries
  ]);

  return (
    <>
      {jarCountries && (
        <Box style={{ paddingBottom: '50px' }} className={styledClasses.boxContainer}>
          <Formik
            validateOnChange
            validationSchema={validationSchema}
            initialValues={initValues}
            onSubmit={(values, { setSubmitting }) => {
              const legalPersonRecord: LegalPersonRecord = {
                countryCode: values.jarCountry ? values.jarCountry.countryCode : null,
                legalName: values.legalName ? values.legalName : null,
                legalCode: values.legalCode ? values.legalCode : null
              };
              setRowState({
                ...rowState,
                showConfirmation: true
              });
              reduxDispatch(createLegalForeignPerson(legalPersonRecord));
              setSelectedLegalSearchQuery(legalPersonRecord);
              setSubmitting(false);
            }}
          >
            {({ setFieldValue, errors, values, isValidating, submitForm }) => {
              return (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Form>
                    <Typography
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '20px',
                        fontWeight: 'bolder'
                      }}
                    >
                      Pridėti užsienio juridinį asmenį
                    </Typography>
                    <FormikFormTextField
                      styledClasses={classes}
                      label="Įmonės kodas"
                      formikKey="legalCode"
                      values={values.legalCode}
                      error={errors.legalCode}
                      setFieldValue={setFieldValue}
                    />
                    <FormikFormTextField
                      styledClasses={classes}
                      label="Įmonės pavadinimas"
                      formikKey="legalName"
                      values={values.legalName}
                      error={errors.legalName}
                      setFieldValue={setFieldValue}
                    />
                    <FormikFormSelectField
                      styledClasses={classes}
                      getOptionLabel={(option?: JarCountry) => (option ? option.countryName : '')}
                      isClearable={
                        values.jarCountry ? values.jarCountry.countryCode !== 'LTU' : false
                      }
                      isRequired
                      label="Dokumentą išdavusi valstybė*"
                      formikKey="jarCountry"
                      value={values.jarCountry}
                      options={filterOutLithuaniaFromJarCountries(jarCountries)}
                      errors={errors.jarCountry}
                      setFieldValue={setFieldValue}
                      defaultValue={null}
                    />
                    <Box>
                      <Button
                        style={{
                          marginRight: '45px'
                        }}
                        variant="outlined"
                        disabled={isValidating || creatingLegalForeignPerson}
                        onClick={submitForm}
                        className={classes.button}
                        startIcon={
                          creatingLegalForeignPerson ? (
                            <CircularProgress style={{ height: '20px', width: '20px' }} />
                          ) : (
                            <CheckIcon style={{ color: '#03B079' }} />
                          )
                        }
                      >
                        <Typography color="inherit">Išsaugoti</Typography>
                      </Button>
                      <Button
                        style={{
                          marginRight: '4px'
                        }}
                        variant="outlined"
                        disabled={isValidating}
                        onClick={() => close()}
                        className={classes.button}
                        startIcon={<ClearIcon style={{ color: '#f44336' }} />}
                      >
                        <Typography color="inherit">{Strings.button__cancel}</Typography>
                      </Button>
                    </Box>
                  </Form>
                </MuiPickersUtilsProvider>
              );
            }}
          </Formik>
        </Box>
      )}
    </>
  );
};
