import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import {
  OutletCategoryHeaderField,
  OutletCategoryTableState
} from '../tablesState/categoryTableTypes';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';

const OutletCategoryTableHead: React.FC = () => {
  const {
    state: { categories: categoryTableState }
  } = useOutletInfoTablesState();

  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const handleSetSortByColumn = (column: OutletCategoryHeaderField) => {
    tableDispatch({ type: 'CATEGORY_SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletCategoryTableState,
    typeof handleSetSortByColumn,
    OutletCategoryHeaderField
  >(handleSetSortByColumn, categoryTableState);

  return (
    <TableHead>
      <TableRow>
        {categoryTableState.columnsDisplayStatus.categoryTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Kategorija"
            columnName="categoryTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={categoryTableState}
          />
        )}

        {(categoryTableState.columnsDisplayStatus.validFrom ||
          categoryTableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              categoryTableState.columnsDisplayStatus.validFrom,
              categoryTableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">Taikoma</Typography>
          </TableCell>
        )}

        <TableCell rowSpan={2} className="column-of-buttons" align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {categoryTableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={categoryTableState}
          />
        )}

        {categoryTableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={categoryTableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default OutletCategoryTableHead;
