import { put, select, takeLatest } from '@redux-saga/core/effects';
import { call } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { get } from '../../utils/axiosApi';
import { getLoaderStore } from '../../utils/loaderStore';
import { hasRoles } from '../../utils/roleHelper';
import { Roles } from '../virsis/dataTypes';

const {
  actions: { load, setLoaded, setError, setLoading },
  reducer
} = getLoaderStore('@JADISSynchronise', undefined);

export const JADISSynchronise = load;
export const setJADISSynchronised = setLoaded;
export const setJADISSynchronising = setLoading;
export const setJADISSynchroniseError = setError;
export const JADISSyncReducer = reducer;

function* synchronizeTask() {
  yield put(setLoaded(false));
  yield put(setLoading(true));
  try {
    const { selectedVirsId, selectedAccountType, currentUser } = yield select(
      (state: ApplicationState) => state.virsis
    );
    if (selectedAccountType !== 'LEGAL') {
      if (hasRoles(currentUser, [Roles.ROLE_VIRS, Roles.ROLE_VIRS_EDIT])) {
        if (selectedVirsId) {
          yield call(get, `/shareholders/${selectedVirsId}/synchronise`);
        } else {
          yield call(get, `/shareholders/synchronise`);
        }
      }
    }
    yield put(setLoaded(true));
  } catch (err) {
    const message = err?.response?.data?.message;
    yield put(setError(message));
  }
  yield put(setLoading(false));
}

export function* JADISSyncSaga() {
  yield takeLatest(load, synchronizeTask);
}
