import {
  AuditsTableAction,
  auditsTableColumns,
  AuditsTableState
} from './tableInitialStateAndTypes';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';
import { getTableExportDefinition } from './tableStateFunctions';

export const auditsTableReducer = (
  state: AuditsTableState,
  action: AuditsTableAction
): AuditsTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(auditsTableColumns, columnsDisplayStatus)
      };
    }
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case 'CONTENT_OPENED':
      return {
        ...state,
        contentToView: action.content
      };
    case 'CONTENT_CLOSED':
      return {
        ...state,
        contentToView: undefined
      };
    default:
      return state;
  }
};
