export const LOGIN = '@virsis/LOGIN';
export const LOGOUT_REQUEST = '@virsis/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@virsis/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = '@virsis/LOGOUT_ERROR';
export const AUTHORISE_REQUEST = '@virsis/AUTHORISE_REQUEST';
export const AUTHORISE_REQUEST_SUCCESS = '@virsis/AUTHORISE_REQUEST_SUCCESS';
export const AUTHORISE_REQUEST_ERROR = '@virsis/AUTHORISE_REQUEST_ERROR';
export const FETCH_USER_DATA_REQUEST = '@virsis/FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = '@virsis/FFETCH_USER_DATA_SUCCESS';
export const APPLICATION_LOADING = '@virsis/APPLICATION_LOADING';
export const APPLICATION_LOADING_STOP = '@virsis/APPLICATION_LOADING_STOP';

export const OPEN_REPRESENTATION_TYPE_SELECT_MODAL =
  '@virsis/OPEN_REPRESENTATION_TYPE_SELECT_MODAL';

export const OPEN_AGREEMENT_DIALOG = '@virsis/OPEN_AGREEMENT_DIALOG';

export const FETCH_AGREEMENT_DATE_REQUEST = '@virsis/FETCH_AGREEMENT_DATE_REQUEST';
export const FETCH_AGREEMENT_DATE_SUCCESS = '@virsis/FETCH_AGREEMENT_DATE_SUCCESS';
export const FETCH_AGREEMENT_DATE_ERROR = '@virsis/FETCH_AGREEMENT_DATE_ERROR';

export const ACCEPT_AGREEMENT_REQUEST = '@virsis/ACCEPT_AGREEMENT_REQUEST';
export const ACCEPT_AGREEMENT_SUCCESS = '@virsis/ACCEPT_AGREEMENT_SUCCESS';
export const ACCEPT_AGREEMENT_ERROR = '@virsis/ACCEPT_AGREEMENT_ERROR';

export const OPEN_CONTACT_EMAIL_DIALOG = '@virsis/OPEN_CONTACT_EMAIL_DIALOG';
export const CLOSE_CONTACT_EMAIL_DIALOG = '@virsis/CLOSE_CONTACT_EMAIL_DIALOG';
export const OPEN_EDIT_CONTACT_EMAIL_DIALOG = '@virsis/OPEN_EDIT_CONTACT_EMAIL_DIALOG';

export const FETCH_USER_CONTACT_EMAIL_REQUEST = '@virsis/FETCH_USER_CONTACT_EMAIL_REQUEST';
export const FETCH_USER_CONTACT_EMAIL_SUCCESS = '@virsis/FETCH_USER_CONTACT_EMAIL_SUCCESS';
export const FETCH_USER_CONTACT_EMAIL_ERROR = '@virsis/FETCH_USER_CONTACT_EMAIL_ERROR';

export const FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST =
  '@virsis/FETCH_SELECTED_VIRS_CONTACT_EMAIL_REQUEST';
export const FETCH_SELECTED_VIRS_CONTACT_EMAIL_SUCCESS =
  '@virsis/FETCH_SELECTED_VIRS_CONTACT_EMAIL_SUCCESS';
export const FETCH_SELECTED_VIRS_CONTACT_EMAIL_ERROR =
  '@virsis/FETCH_SELECTED_VIRS_CONTACT_EMAIL_ERROR';

export const FETCH_JAR_CONTACT_EMAIL_REQUEST = '@virsis/FETCH_JAR_CONTACT_EMAIL_REQUEST';
export const FETCH_JAR_CONTACT_EMAIL_SUCCESS = '@virsis/FETCH_JAR_CONTACT_EMAIL_SUCCESS';
export const FETCH_JAR_CONTACT_EMAIL_ERROR = '@virsis/FETCH_JAR_CONTACT_EMAIL_ERROR';

export const RESET_VIRS_DATA_REQUEST = '@virsis/RESET_VIRS_DATA_REQUEST';
export const FETCH_VIRS_DATA_REQUEST = '@virsis/FETCH_VIRS_DATA_REQUEST';
export const FETCH_VIRS_DATA_SUCCESS = '@virsis/FETCH_VIRS_DATA_SUCCESS';
export const FETCH_VIRS_DATA_ERROR = '@virsis/FETCH_VIRS_DATA_ERROR';

export const FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST = '@virsis/FETCH_VIRS_DATA_BY_VIRS_ID_REQUEST';
export const FETCH_VIRS_DATA_BY_VIRS_ID_SUCCESS = '@virsis/FETCH_VIRS_DATA_BY_VIRS_ID_SUCCESS';
export const FETCH_VIRS_DATA_BY_VIRS_ID_ERROR = '@virsis/FETCH_VIRS_DATA_BY_VIRS_ID_ERROR';

export const SAVE_CONTACT_EMAIL_REQUEST = '@virsis/SAVE_CONTACT_EMAIL_REQUEST';
export const SAVE_CONTACT_EMAIL_SUCCESS = '@virsis/SAVE_CONTACT_EMAIL_SUCCESS';
export const SAVE_CONTACT_EMAIL_ERROR = '@virsis/SAVE_CONTACT_EMAIL_ERROR';

export const LOAD_VIRS_CONTEXT_PAGE = '@virsis/LOAD_VIRS_CONTEXT_PAGE';

export const SET_ACCOUNT_TYPE = '@virsis/SET_ACCOUNT_TYPE';

export const SET_AGREEMENT_VERSION = '@virsis/SET_AGREEMENT_VERSION';
export const FETCH_AGREEMENT_FILE = '@virsis/FETCH_AGREEMENT_FILE';
export const FETCH_AGREEMENT_FILE_SUCCESS = '@virsis/FETCH_AGREEMENT_FILE_SUCCESS';
export const FETCH_AGREEMENT_FILE_ERROR = '@virsis/FETCH_AGREEMENT_FILE_ERROR';

export const START_VIRSIS_FLOW = '@virsis/START_VIRSIS_FLOW';
export const START_VIRSIS_FLOW_ERROR = '@virsis/START_VIRSIS_FLOW_ERROR';

export const EXIT_EMAIL_DIALOG = '@virsis/EXIT_EMAIL_DIALOG';
export const EXIT_REPRESENTATION_TYPE_SELECT_DIALOG =
  '@virsis/EXIT_REPRESENTATION_TYPE_SELECT_DIALOG';
export const EXIT_AGREEMENT_DIALOG = '@virsis/EXIT_AGREEMENT_DIALOG';

export const SUBMIT_VIRS_DATA_REQUEST = '@virsis/SUBMIT_VIRS_DATA_REQUEST';
export const SUBMIT_VIRS_DATA_SUCCESS = '@virsis/SUBMIT_VIRS_DATA_SUCCESS';
export const SUBMIT_VIRS_DATA_ERROR = '@virsis/SUBMIT_VIRS_DATA_ERROR';
export const SUBMIT_VIRS_DATA_RESET = '@virsis/SUBMIT_VIRS_DATA_RESET';

export const SEARCH_VIRS_REQUEST = '@personData/SEARCH_VIRS_REQUEST';
export const SEARCH_VIRS_SUCCESS = '@personData/SEARCH_VIRS_SUCCESS';
export const SEARCH_VIRS_ERROR = '@personData/SEARCH_VIRS_ERROR';
export const RESET_SEARCH_VIRS_STATE = '@personData/RESET_SEARCH_VIRS_STATE';
export const SEARCH_VIRS_ACTIVE_ONLY_REQUEST = '@personData/SEARCH_VIRS_ACTIVE_ONLY_REQUEST';

export const FETCH_JAR_COUNTRIES_SUCCESS = '@virsis/FETCH_JAR_COUNTRIES_SUCCESS';
export const FETCH_JAR_COUNTRIES_REQUEST = '@virsis/FETCH_JAR_COUNTRIES_REQUEST';
export const FETCH_JAR_COUNTRIES_ERROR = '@virsis/FETCH_JAR_COUNTRIES_ERROR';

export const SET_PERSON_ID = '@virsis/SET_PERSON_ID';
export const RESET_PERSON_ID = '@virsis/RESET_PERSON_ID';
export const SET_VIRS_ID = '@virsis/SET_VIRS_ID';

export const CLOSE_UNSIGNED_ALERT = '@virsis/CLOSE_UNSIGNED_ALERT';

export const CLOSE_DATA_EDITING_ALERT = '@virsis/CLOSE_DATA_EDITING_ALERT';

export const FETCH_DATA_EDITOR_REQUEST = '@virsis/FETCH_DATA_EDITOR_REQUEST';
export const FETCH_DATA_EDITOR_SUCCESS = '@virsis/FETCH_DATA_EDITOR_SUCCESS';
export const FETCH_DATA_EDITOR_ERROR = '@virsis/FETCH_DATA_EDITOR_ERROR';

export const REGISTER_ACCOUNT_TYPE_CHOSEN = '@virsis/REGISTER_ACCOUNT_TYPE_CHOSEN';
export const REGISTER_ACCOUNT_TYPE_CHOSEN_SUCCESS = '@virsis/REGISTER_ACCOUNT_TYPE_CHOSEN_SUCCESS';
export const REGISTER_ACCOUNT_TYPE_CHOSEN_ERROR = '@virsis/REGISTER_ACCOUNT_TYPE_CHOSEN_ERROR';

export const SYNCHRONIZE_VIRS = '@virsis/SYNCHRONIZE_VIRS';
