import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { useParams, withRouter } from 'react-router';
import { ApplicationState } from '../../store';
import { FundsReceivedLegalTableCustomizationBar } from './TableCustomization';
import { FundsReceivedLegalTableHeader } from './TableHeader';
import {
  FundsReceivedLegalTableDispatchContext,
  FundsReceivedLegalTableStateContext
} from './Context';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import FundsReceivedLegalTableFilterRow from './FilterRow';
import { fundsReceivedLegalColumnNames } from './tableState/tableTypesAndActions';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import FundsReceivedLegalRowContainer from './TableRows/RowContainer';
import { FundsReceivedLegalRowContainerNew } from './TableRows/RowContainerNew';
import { AddNewRecordButton } from '../../components/TableButtons/AddNewRecordButton';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import {
  initializeFundsReceivedDataTableState,
  setPage,
  setRowsPerPage
} from '../../store/fundsReceivedTable/fundsReceivedTableActions';
import { fetchFundsReceivedDataLegal } from '../../store/fundsReceived/fundsReceivedActions';
import { TableLinearProgressRow } from '../../components/TableLinearProgressRow/TableLinearProgressRow';
import { ConfirmRemoveUnsignedDialog } from '../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import {
  InstDocumentType,
  LegalDocumentType
} from '../../store/unsignedDataRemoval/unsignedDataTypes';
import {
  unsignedDataRemoveInst,
  unsignedDataRemoveLegal
} from '../../store/unsignedDataRemoval/unsignedDataActions';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';
import { ScrollXContainer } from '../../components/ScrollXContainer/ScrollXContainer';
import { AlertBox } from '../../components/Alert/AlertBox';
import { hasRole } from '../../utils/roleHelper';
import { Roles } from '../../store/virsis/dataTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      tableBox: {
        flexWrap: 'wrap'
      },
      addButton: {
        width: '100%',
        justifyContent: 'flex-start',
        margin: 0,
        paddingLeft: 0
      },
      pageActionButtonBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  })
);

const FundsReceivedLegalTable: React.FC = () => {
  const { documentStatusId, companyCode } = useParams<{
    documentStatusId?: string;
    companyCode: string;
  }>();
  const {
    fundsReceivedData: {
      fundsReceivedDataLegal,
      loadingFundsReceivedDataLegal,
      fundsReceivedDataErrorLegal,
      statusNotSignedFilterEnabled,
      statusOutdatedFilterEnabled,
      statusRelevantFilterEnabled,
      recordCount,
      annullingFundsReceivedSuccess
    },
    fundsReceivedTableData: { tableDataState },
    virsis: { currentUser }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const [showDocumentAnulledAlert, setShowDocumentAnulledAlert] = useState(false);

  const reduxDispatch = useDispatch();
  const classes = useStyles();

  const { dispatch: tableDispatch } = useContext(FundsReceivedLegalTableDispatchContext);
  const { state: tableState } = useContext(FundsReceivedLegalTableStateContext);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  const handleSetPage = (value: number) => {
    reduxDispatch(setPage(value));
  };
  const handleSetRowsPerPage = (rowsPerPage: number) => {
    reduxDispatch(setRowsPerPage(rowsPerPage));
  };

  function showNewRecordRow() {
    tableDispatch({
      type: 'CREATE_RECORD_CLICKED'
    });
  }

  function handleConfirmRemoveUnsignedRecords() {
    if (currentUser && currentUser.institutionRole && hasRole(currentUser, Roles.ROLE_VIRS)) {
      reduxDispatch(unsignedDataRemoveLegal(LegalDocumentType.FUNDS_RECEIVED));
    } else {
      reduxDispatch(unsignedDataRemoveInst(InstDocumentType.FUNDS_RECEIVED));
    }
  }

  useEffect(() => {
    reduxDispatch(
      initializeFundsReceivedDataTableState(
        documentStatusId ? false : statusNotSignedFilterEnabled,
        documentStatusId ? false : statusRelevantFilterEnabled,
        documentStatusId ? false : statusOutdatedFilterEnabled,
        documentStatusId ? false : statusNotSignedFilterEnabled,
        documentStatusId ? false : statusRelevantFilterEnabled,
        !statusNotSignedFilterEnabled && !statusRelevantFilterEnabled && statusOutdatedFilterEnabled
      )
    );
  }, [
    documentStatusId,
    reduxDispatch,
    statusNotSignedFilterEnabled,
    statusOutdatedFilterEnabled,
    statusRelevantFilterEnabled
  ]);

  useEffect(() => {
    if (companyCode) {
      tableDispatch({
        type: 'CREATE_RECORD_CLICKED',
        companyCode
      });
    }
  }, [tableDispatch, companyCode]);

  const filteredData = fundsReceivedDataLegal || [];

  useEffect(() => {
    if (annullingFundsReceivedSuccess) {
      setShowDocumentAnulledAlert(true);
    }
  }, [annullingFundsReceivedSuccess]);

  return (
    <div>
      <Container maxWidth="xl">
        <div className="data-page-header">
          <Typography variant="h1">Duomenų teikimas</Typography>
          <Typography style={{ margin: '10px 0' }} variant="subtitle1">
            VIRS gautos lėšos
          </Typography>
        </div>
      </Container>

      <Container maxWidth="xl">
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="space-between"
          flexWrap="wrap"
          className={classes.tableBox}
        >
          <AddNewRecordButton
            text="Pridėti VIRS gautų lėšų įrašą"
            onClicked={showNewRecordRow}
            disabled={
              !!fundsReceivedDataErrorLegal || tableState.showNewRecord || !!documentStatusId
            }
            className={classes.addButton}
          />
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="flex-end"
            className={classes.pageActionButtonBox}
          >
            <PageActionButtons
              onDownload={(format, navigationPath) => {
                exportTableData(
                  format,
                  tableState.tableExportDefinition,
                  filteredData,
                  navigationPath
                );
              }}
              onPrint={(navigationPath) =>
                printTableData(tableState.tableExportDefinition, filteredData, navigationPath)
              }
              onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
            />
          </Box>
          <ConfirmRemoveUnsignedDialog
            onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
            onConfirm={handleConfirmRemoveUnsignedRecords}
            onRemoveSuccess={() => reduxDispatch(fetchFundsReceivedDataLegal())}
            open={confirmToDeleteUnsignedDialogOpen}
          />
        </Box>
      </Container>

      {showDocumentAnulledAlert && (
        <AlertBox
          handleClose={() => setShowDocumentAnulledAlert(false)}
          text={
            'Įrašas sėkmingai anuliuotas. Anuliuotus dokumentus galite peržiūrėti pasirinkę meniu „Duomenų istorija“.'
          }
        />
      )}

      <Container maxWidth="xl" style={{ marginTop: '1rem' }}>
        <Paper elevation={0}>
          <FundsReceivedLegalTableCustomizationBar
            columnNames={fundsReceivedLegalColumnNames}
            disabled={tableState.showNewRecord || !!documentStatusId}
          />
          <ScrollXContainer>
            <Table aria-label="simple table">
              <FundsReceivedLegalTableHeader />
              {tableState.customFilterOn && (
                <FundsReceivedLegalTableFilterRow columnNames={fundsReceivedLegalColumnNames} />
              )}

              <TableBody>
                <TableLinearProgressRow isLoading={loadingFundsReceivedDataLegal} colSpan={9} />

                <TableErrorRow
                  error={fundsReceivedDataErrorLegal && 'Klaida. Nepavyko gauti gautų lėšų duomenų'}
                  colSpan={9}
                />
                {!loadingFundsReceivedDataLegal &&
                  !fundsReceivedDataErrorLegal &&
                  tableState.showNewRecord && (
                    <FundsReceivedLegalRowContainerNew tableState={tableState} />
                  )}
                {!fundsReceivedDataErrorLegal &&
                  filteredData.map((record) => {
                    return (
                      <FundsReceivedLegalRowContainer
                        key={record.fundsReceivedId}
                        record={record}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </ScrollXContainer>
          <TablePagination
            recordsCount={recordCount}
            pagesCount={Math.ceil(recordCount / tableDataState.rowsPerPage || 0)}
            rowsPerPage={tableDataState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableDataState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={filteredData.length === 0 || recordCount === 0}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default withRouter(FundsReceivedLegalTable);
