import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getBooleanMappedColumn,
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { loadData, setFormOpen, State } from './store';
import { EnterpriseTypeForm } from './EnterpriseTypeForm';
import { EnterpriseType } from './types';

export const EnterpriseTypeTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const legalFormTableDefinition: TableDefinition<Nullable<EnterpriseType>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('enterpriseTypeId', 'Kodas', (data) => data.enterpriseTypeId),
            getStringMappedColumn(
              'enterpriseTypeName',
              'VIRS rūšies pavadinimas Lt',
              (data) => data.enterpriseTypeName,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getStringMappedColumn(
              'enterpriseTypeNameEn',
              'VIRS rūšies pavadinimas En',
              (data) => data.enterpriseTypeNameEn,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getBooleanMappedColumn(
              'vipRequired',
              'Ar VIRS rūšiai privaloma VIP',
              (data) => data.vipRequired
            )
          ]
        },
        {
          header: 'Galioja',
          columns: [
            getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              enterpriseTypeId: data.enterpriseTypeId,
              vipRequired: data.vipRequired || false,
              enterpriseTypeName: data.enterpriseTypeName,
              enterpriseTypeNameEn: data.enterpriseTypeNameEn,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              validTo: (data.validTo && new Date(data.validTo)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują VIRS rūšį'
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={legalFormTableDefinition} {...dataState} />

      <EnterpriseTypeForm />
    </>
  );
};
