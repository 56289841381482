export const FETCH_AUDITS_DATA_REQUEST = '@audits/FETCH_AUDITS_DATA_REQUEST';
export const FETCH_AUDITS_DATA_SUCCESS = '@audits/FETCH_AUDITS_DATA_SUCCESS';
export const FETCH_AUDITS_DATA_ERROR = '@audits/FETCH_AUDITS_DATA_ERROR';
export const RESET_AUDITS_DATA_STATE = '@audits/RESET_AUDITS_DATA_STATE';

export const FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST =
  '@audits/FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_REQUEST';
export const FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_SUCCESS =
  '@audits/FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_SUCCESS';
export const FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_ERROR =
  '@audits/FETCH_INTEGRATED_SYSTEMS_AUDITS_DATA_ERROR';
export const RESET_INTEGRATED_SYSTEMS_AUDITS_DATA_STATE =
  '@audits/RESET_INTEGRATED_SYSTEMS_AUDITS_DATA_STATE';

export const FETCH_AUDIT_OUTLET_TYPES_REQUEST = '@audits/FETCH_OUTLET_TYPES_REQUEST';
export const FETCH_AUDIT_OUTLET_TYPES_SUCCESS = '@audits/FETCH_OUTLET_TYPES_SUCCESS';
export const FETCH_AUDIT_OUTLET_TYPES_ERROR = '@audits/FETCH_OUTLET_TYPES_ERROR';

export const FETCH_AUDIT_ACTION_TYPES_REQUEST =
  '@classifiers/FETCH_AUDIT_ACTION_TYPES_TYPES_REQUEST';
export const FETCH_AUDIT_ACTION_TYPES_SUCCESS =
  '@classifiers/FETCH_AUDIT_ACTION_TYPES_TYPES_SUCCESS';
export const FETCH_AUDIT_ACTION_TYPES_ERROR =
  '@classifiers/FETCH_AUDIT_ACTION_TYPESAUDIT_TYPES_ERROR';

export const FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST =
  '@classifiers/FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_REQUEST';
export const FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_SUCCESS =
  '@classifiers/FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_SUCCESS';
export const FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_ERROR =
  '@classifiers/FETCH_VIRSIS_RECEIVES_FROM_SYSTEM_ERROR';

export const FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST =
  '@classifiers/FETCH_VIRSIS_SENDS_TO_SYSTEM_REQUEST';
export const FETCH_VIRSIS_SENDS_TO_SYSTEM_SUCCESS =
  '@classifiers/FETCH_VIRSIS_SENDS_TO_SYSTEM_SUCCESS';
export const FETCH_VIRSIS_SENDS_TO_SYSTEM_ERROR = '@classifiers/FETCH_VIRSIS_SENDS_TO_SYSTEM_ERROR';

export const FETCH_ARCHIVED_DATA_REQUEST = '@audits/FETCH_ARCHIVED_DATA_REQUEST';
export const FETCH_ARCHIVED_DATA_SUCCESS = '@audits/FETCH_ARCHIVED_DATA_SUCCESS';
export const FETCH_ARCHIVED_DATA_ERROR = '@audits/FETCH_ARCHIVED_DATA_ERROR';

export const DELETE_ARCHIVED_DATA_REQUEST = '@audits/DELETE_ARCHIVED_DATA_REQUEST';
export const DELETE_ARCHIVED_DATA_SUCCESS = '@audits/DELETE_ARCHIVED_DATA_SUCCESS';
export const DELETE_ARCHIVED_DATA_ERROR = '@audits/DELETE_ARCHIVED_DATA_ERROR';
export const RESET_DELETE_ARCHIVED_DATA = '@audits/RESET_DELETE_ARCHIVED_DATA';
