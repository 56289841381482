import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProvidedDataHistoryHeaderField,
  ProvidedDataHistoryColumnHeader,
  ProvidedDataFilterOptions
} from './tableState/tableInitialStateAndTypes';
import { ApplicationState } from '../../store';
import { customFilterValueChanged } from '../../store/providedDataTable/providedDataTableActions';
import { TableCustomFilterForOptionCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterForOptionCellGeneric';
import { useProvidedDataHistoryTableState } from './Context';
import { getUniqueOptions } from '../../utils/tableDataFunctions';

interface Props {
  columnNames: ProvidedDataHistoryColumnHeader[];
}

const ProvidedDataHistoryTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = useProvidedDataHistoryTableState();

  const reduxDispatch = useDispatch();

  const { instTableFilters } = useSelector((state: ApplicationState) => state.providedData);

  const handleCustomFilterChange = (
    column: ProvidedDataHistoryHeaderField,
    value: string | null
  ) => {
    reduxDispatch(customFilterValueChanged(column, value));
  };

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterForOptionCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions<
                ProvidedDataFilterOptions,
                ProvidedDataHistoryHeaderField
              >(instTableFilters, column.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default ProvidedDataHistoryTableCustomFilter;
