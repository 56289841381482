import React from 'react';
import { Typography, TableSortLabel, TableHead, TableRow, TableCell } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  EthicalNonComplianceHeaderField,
  EthicalNonComplianceColumnsDisplayStatus
} from './tableState/tableInitialStateAndTypes';
import { useEthicalNonComplianceTableState } from './Context';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { sortingChanged } from '../../store/ethicalNonComplianceTable/ethicalNonComplianceTableAction';

const EthicalNonComplianceTableHead: React.FC = () => {
  const { state: tableState } = useEthicalNonComplianceTableState();
  const { tableDataState } = useSelector(
    (state: ApplicationState) => state.ethicalNonComplianceTableData
  );
  const reduxDispatch = useDispatch();

  const handleSetSortByColumn = (column: EthicalNonComplianceHeaderField) => {
    reduxDispatch(sortingChanged(column));
  };

  const calculateSharedDecisionCellColSpan = (
    displayStatus: EthicalNonComplianceColumnsDisplayStatus
  ): number => {
    const displayingColumns = [displayStatus.decisionDate, displayStatus.documentNumber];
    return displayingColumns.filter((value) => value === true).length;
  };

  const calculateSharedDatesCellColSpan = (
    displayStatus: EthicalNonComplianceColumnsDisplayStatus
  ): number => {
    const displayingColumns = [displayStatus.validFrom, displayStatus.validTo];
    return displayingColumns.filter((value) => value === true).length;
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.virsName && (
          <TableCell
            rowSpan={2}
            key="virsName"
            sortDirection={tableDataState.sortBy === 'virsName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('virsName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'virsName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'virsName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIRS</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.documentNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedDecisionCellColSpan(tableState.columnsDisplayStatus)}
          >
            <Typography variant="h4">Sprendimo</Typography>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.outletList && (
          <TableCell
            rowSpan={2}
            key="outletList"
            sortDirection={tableDataState.sortBy === 'outletList' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('outletList')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'outletList' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'outletList' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Susijusios VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.decisionStatus && (
          <TableCell
            rowSpan={2}
            key="decisionStatus"
            sortDirection={
              tableDataState.sortBy === 'decisionStatus' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('decisionStatus')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'decisionStatus' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'decisionStatus' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Būsena</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedDatesCellColSpan(tableState.columnsDisplayStatus)}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}
        <TableCell rowSpan={2} className="column-of-buttons">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
        <TableCell rowSpan={2} className="column-of-buttons">
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableCell
            rowSpan={1}
            key="decisionDate"
            sortDirection={tableDataState.sortBy === 'decisionDate' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('decisionDate')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'decisionDate' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'decisionDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Data</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.documentNumber && (
          <TableCell
            rowSpan={1}
            key="documentNumber"
            sortDirection={
              tableDataState.sortBy === 'documentNumber' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('documentNumber')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'documentNumber' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'documentNumber' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nr.</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.validFrom && (
          <TableCell
            rowSpan={1}
            key="validFrom"
            sortDirection={tableDataState.sortBy === 'validFrom' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('validFrom')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'validFrom' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'validFrom' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.validTo && (
          <TableCell
            rowSpan={1}
            key="validTo"
            sortDirection={tableDataState.sortBy === 'validTo' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('validTo')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'validTo' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'validTo' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Iki</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default EthicalNonComplianceTableHead;
