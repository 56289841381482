import { store } from '../..';
import { getHTMLExporter } from './htmlExporter';
import { getPDFPrinter, getPDFExporter } from './pdfExporter';
import { FileFormats, TableExportDefinition } from './types';
import { getXLSXExporter } from './xlsxExporter';

export function exportTableData<TData>(
  format: FileFormats,
  tableDefinition: TableExportDefinition<TData>,
  data: TData[],
  navigationPath?: string[]
) {
  const {
    virsis: { virsData, currentUser }
  } = store.getState();

  const header = virsData && `${virsData.name} (${virsData.companyCode})`;

  if (currentUser) {
    switch (format) {
      case FileFormats.XLSX:
        getXLSXExporter({
          ...tableDefinition,
          navigationPath,
          header,
          user: currentUser.name,
          onBlob: (blob) => saveAs(blob, `${tableDefinition.title}.xlsx`)
        })(data);
        break;
      case FileFormats.PDF:
        getPDFExporter({
          ...tableDefinition,
          navigationPath,
          header,
          user: currentUser.name,
          onBlob: (blob) => saveAs(blob, `${tableDefinition.title}.pdf`)
        })(data);
        break;
      case FileFormats.HTML:
        getHTMLExporter({
          ...tableDefinition,
          navigationPath,
          header,
          user: currentUser.name,
          onBlob: (blob) => saveAs(blob, `${tableDefinition.title}.html`)
        })(data);
        break;
      default:
        break;
    }
  }
}

export function printTableData<TData>(
  tableDefinition: TableExportDefinition<TData>,
  data: TData[],
  navigationPath?: string[]
) {
  const {
    virsis: { virsData, currentUser }
  } = store.getState();

  const header = virsData && `${virsData.name} (${virsData.companyCode})`;

  if (currentUser) {
    getPDFPrinter({
      ...tableDefinition,
      navigationPath,
      header,
      user: currentUser.name
    })(data);
  }
}
