import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';

import { resetForm, State, submitForm } from './store';
import { GenericTextField } from '../../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import { defaultValues, EditionCheckConclusionInput } from './types';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<EditionCheckConclusionInput>> = (formikProps) => {
  const {
    values: { editionCheckConclusionId }
  } = formikProps;
  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{editionCheckConclusionId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Tiražo atitikties išvados pavadinimas LT" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="editionCheckConclusionTypeName"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Tiražo atitikties išvados pavadinimas En" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="editionCheckConclusionTypeNameEn"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const EditionCheckConclusionForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error, data } = useSelector((state: State) => ({
    ...state.form,
    data: state.data
  }));

  const nameValidation = Yup.string()
    .required('Būtina įvesti reikšmę')
    .max(200, 'Negali būti ilgesnis nei 200 simbolių');

  const validationSchema = Yup.object().shape({
    editionCheckConclusionId: noUndefinedNumber(),
    editionCheckConclusionTypeName: nameValidation.notOneOf(
      data
        .map(({ editionCheckConclusionTypeName }) => editionCheckConclusionTypeName)
        .filter((value) => initialValues?.editionCheckConclusionTypeName !== value),
      'Privalo būti unikalus'
    ),
    editionCheckConclusionTypeNameEn: nameValidation.notOneOf(
      data
        .map(({ editionCheckConclusionTypeName }) => editionCheckConclusionTypeName)
        .filter((value) => initialValues?.editionCheckConclusionTypeNameEn !== value),
      'Privalo būti unikalus'
    ),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="Tiražo atitikties išvados įvedimas"
    />
  );
};
