import React, { useReducer, useContext, Dispatch, createContext } from 'react';
import { VirsDataSubmissionProps } from '../VirsDataSubmision/VirsDataSubmision/VirsDataPage';
import { OutletsTable, OutletTableProps } from './Table';
import {
  initialOutletDataTableState,
  OutletDataTableAction,
  OutletDataTableState
} from './tableState/initialStateAndTypes';
import { outletsDataTableReducer } from './tableState/tableReducer';

const OutletsTableStateContext = createContext<{
  state: OutletDataTableState;
}>({
  state: initialOutletDataTableState
});

const OutletsTableDispatchContext = createContext<{
  dispatch: Dispatch<OutletDataTableAction>;
}>({
  dispatch: () => {}
});

const OutletsDataTable: React.FC<OutletTableProps & VirsDataSubmissionProps> = (props) => {
  const [state, dispatch] = useReducer(outletsDataTableReducer, initialOutletDataTableState);
  return (
    <OutletsTableStateContext.Provider value={{ state }}>
      <OutletsTableDispatchContext.Provider value={{ dispatch }}>
        <OutletsTable {...props} />
      </OutletsTableDispatchContext.Provider>
    </OutletsTableStateContext.Provider>
  );
};

function useOutletsTableState() {
  return useContext(OutletsTableStateContext);
}
function useOutletsTableDispatch() {
  return useContext(OutletsTableDispatchContext);
}

export { OutletsDataTable, useOutletsTableState, useOutletsTableDispatch };
