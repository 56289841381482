import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

interface Props {
  isLoading: boolean;
  colSpan: number;
}

export const TableCircularProgressRow: React.FC<Props> = ({ isLoading, colSpan: colspan }) => {
  return (
    <>
      {isLoading && (
        <TableRow>
          <TableCell colSpan={colspan}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
