import React from 'react';
import { Typography, Button } from '@material-ui/core/';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Props {
  onToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  anchorEl?: null | HTMLElement;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      border: '1px solid transparent',
      borderLeftColor: '#E5E5E5',
      borderRadius: 0,
      margin: 0,
      height: 48,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
        padding: '3px 4px'
      }
    },
    activeButton: {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    icon: {
      paddingLeft: 5,
      fontSize: 15,
      color: theme.palette.primary.main
    }
  })
);

const SortByButton: React.FC<Props> = ({ onToggle, disabled, anchorEl }) => {
  const classes = useStyles();

  return (
    <>
      <Button
        value="sort"
        className={anchorEl ? `${classes.button} ${classes.activeButton}` : `${classes.button}`}
        onClick={onToggle}
        disabled={disabled}
      >
        <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
          Rūšiavimas
        </Typography>
        <UnfoldMoreIcon className={classes.icon} />
      </Button>
    </>
  );
};
export default SortByButton;
