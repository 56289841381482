import { AnnulmentType } from '../../../store/classifiers/classifiersTypes';
import { DropdownStateGeneric, InputStateGeneric } from '../../../utils/tableTypes';

export interface ProvidedDataTableRecordState {
  removeRecordConfirmationOn: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  annulRecordConfirmationOn: boolean;
  annulmentType: DropdownStateGeneric<AnnulmentType>;
  annulmentComment: InputStateGeneric<string>;
}

export const initialProvidedDataTableRecordState: ProvidedDataTableRecordState = {
  removeRecordConfirmationOn: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  annulRecordConfirmationOn: false,
  annulmentType: {
    value: null,
    id: '',
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options: []
  },
  annulmentComment: {
    value: null,
    id: 'annulmentComment',
    error: false,
    helperText: '',
    placeholder: 'Dokumento anuliavimo priežasties aprašymas',
    validated: false
  }
};

export type ProvidedDataTableRecordActions =
  | { type: 'REMOVE_RECORD_CLICKED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' }
  | { type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' }
  | { type: 'ANNUL_RECORD_CLICKED'; annulmentTypes: AnnulmentType[] }
  | { type: 'ANNUL_RECORD_CANCELLED' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_ERROR' }
  | { type: 'ANNUL_RECORD_CLOSED_ON_SUCCESS' };
