import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierTable } from '../GenericClassifierTable/ClassifierTable';
import {
  getBooleanMappedColumn,
  getDateFromMappedColumn,
  getDateToMappedColumn,
  getNumericMappedColumn,
  getStringArrayMappedColumn,
  getStringMappedColumn
} from '../GenericClassifierTable/utility/mappedColumnDefinitions';
import { Nullable, TableDefinition } from '../GenericClassifierTable/types';
import { LegalFormTypeForm } from './LegalFormTypeForm';

import { loadData, setFormOpen, State } from './store';
import { LegalForm } from './types';

export const LegalFormTypeTable: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const legalFormTableDefinition: TableDefinition<Nullable<LegalForm>> = useMemo(() => {
    return {
      columnGroups: [
        {
          columns: [
            getNumericMappedColumn('legalFormId', 'Kodas', (data) => data.legalFormId),
            getNumericMappedColumn(
              'legalFormCode',
              'Teisinės formos kodas',
              (data) => data.legalFormCode
            ),
            getStringMappedColumn(
              'legalFormName',
              'Teisinės formos pavadinimas',
              (data) => data.legalFormName,
              { cell: { width: undefined } },
              {
                cell: { alignment: { horizontal: 'left' } },
                column: { width: 45 }
              }
            ),
            getBooleanMappedColumn('isFromJadis', 'Ar yra JADIS', (data) => data.isFromJadis),
            getBooleanMappedColumn(
              'isNaturalPersonRequired',
              'Ar reikia dalyvių iki fizinio asmens',
              (data) => data.isNaturalPersonRequired
            ),
            getBooleanMappedColumn(
              'isShareholderRequired',
              'Ar privalomas dalyvių įvedimas',
              (data) => data.isShareholderRequired
            ),
            getStringArrayMappedColumn(
              'shareTypeName',
              'Dalies procentų tipas',
              (data) => {
                const values: string[] = [];
                if (data.hasVotePercentage) {
                  values.push('Balsų dalis');
                }
                if (data.hasVotesOnRules) {
                  values.push('Pagal taisyklę');
                }
                if (data.shareTypeName) {
                  values.push(data.shareTypeName);
                }
                return values;
              },
              { cell: { width: '100px' } },
              {
                column: { width: 30 }
              }
            ),
            getBooleanMappedColumn(
              'providesVirsData',
              'Teikia VIRS duomenis',
              (data) => data.providesVirsData
            )
          ]
        },
        {
          header: 'Galioja',
          columns: [
            getDateFromMappedColumn('validFrom', 'Nuo', (data) => data.validFrom),
            getDateToMappedColumn('validTo', 'Iki', (data) => data.validTo)
          ]
        }
      ],
      tableActions: {
        create: () => dispatch(setFormOpen(true)),
        edit: (data) => {
          dispatch(
            setFormOpen(true, {
              legalFormId: data.legalFormId,
              hasVotePercentage: data.hasVotePercentage || false,
              hasVotesOnRules: data.hasVotesOnRules || false,
              isConfiguredByVirs: data.isConfiguredByVirs || false,
              isFromJadis: data.isFromJadis || false,
              isNaturalPersonRequired: data.isNaturalPersonRequired || false,
              isShareholderRequired: data.isShareholderRequired || false,
              legalFormCode: data.legalFormCode,
              shareTypeId: data.shareTypeId,
              validFrom: (data.validFrom && new Date(data.validFrom)) || null,
              providesVirsData: data.providesVirsData || false,
              validTo: (data.validTo && new Date(data.validTo)) || null
            })
          );
        }
      },
      actionLabels: {
        create: 'Pridėti naują teisinę formą'
      }
    };
  }, [dispatch]);

  const dataState = useSelector((state: State) => state);
  return (
    <>
      <ClassifierTable tableDefinition={legalFormTableDefinition} {...dataState} />

      <LegalFormTypeForm />
    </>
  );
};
