import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import { FundsReceivedLegalTableStateContext } from './Context';
import { ApplicationState } from '../../store';
import {
  FundsReceivedLegalTableHeader,
  FundsReceivedLegalTableField,
  FundsReceivedCustomFilterOptions
} from './tableState/tableTypesAndActions';
import { customFilterValueChanged } from '../../store/fundsReceivedTable/fundsReceivedTableActions';
import { TableCustomFilterForOptionCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterForOptionCellGeneric';
import { getUniqueOptions } from '../../utils/tableDataFunctions';

interface Props {
  columnNames: FundsReceivedLegalTableHeader[];
}

const FundsReceivedLegalTableFilterRow: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = useContext(FundsReceivedLegalTableStateContext);
  const reduxDispatch = useDispatch();

  const handleSetCustomFilter = (column: FundsReceivedLegalTableField, value: string | null) => {
    reduxDispatch(customFilterValueChanged(column, value));
  };
  const { instFilterOptions } = useSelector((state: ApplicationState) => state.fundsReceivedData);

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => {
            return (
              <TableCustomFilterForOptionCellGeneric
                key={column.id}
                id={column.id}
                type={column.type}
                setFilter={handleSetCustomFilter}
                uniqueOptions={getUniqueOptions<
                  FundsReceivedCustomFilterOptions,
                  FundsReceivedLegalTableField
                >(instFilterOptions, column.id)}
              />
            );
          })}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default FundsReceivedLegalTableFilterRow;
