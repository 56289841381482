import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
  fetchAnnulmentTypesRequest,
  fetchEnterpriseTypesRequest,
  fetchFictitiousOutletRequest
} from '../../store/classifiers/classifiersActions';
import { FundsReceivedDataTableLegal } from '../../containers/FundsReceivedTableInstLegal/Context';
import {
  fetchFundsReceivedDataLegal,
  fetchFundsReceivedRecordByDocId
} from '../../store/fundsReceived/fundsReceivedActions';
import { useParams } from 'react-router';

export const LegalFundsReceivedPage: React.FC = () => {
  const { documentStatusId } = useParams<{
    documentStatusId?: string;
    companyCode: string;
  }>();
  const {
    fundsReceivedData: { isFundsReceivedCreated, isFundsReceivedUpdated },
    classifiers: { annulmentTypes, enterpriseTypes, fictitiousOutlet },
    fundsReceivedTableData: { tableDataState, tempTableDataState }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (documentStatusId) {
      reduxDispatch(fetchFundsReceivedRecordByDocId(Number(documentStatusId)));
    } else if (
      JSON.stringify(tableDataState) !== JSON.stringify(tempTableDataState) ||
      isFundsReceivedCreated ||
      isFundsReceivedUpdated
    ) {
      reduxDispatch(fetchFundsReceivedDataLegal());
    }
  }, [
    documentStatusId,
    reduxDispatch,
    tableDataState,
    tempTableDataState,
    isFundsReceivedCreated,
    isFundsReceivedUpdated
  ]);

  useEffect(() => {
    reduxDispatch(fetchFundsReceivedDataLegal());
  }, [reduxDispatch]);

  useEffect(() => {
    if (!enterpriseTypes) {
      reduxDispatch(fetchEnterpriseTypesRequest());
    }
  }, [reduxDispatch, enterpriseTypes]);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [reduxDispatch, annulmentTypes]);

  useEffect(() => {
    if (!fictitiousOutlet) {
      reduxDispatch(fetchFictitiousOutletRequest());
    }
  }, [reduxDispatch, fictitiousOutlet]);

  return (
    <div className="data-page">
      <div className="data-page-content">
        <FundsReceivedDataTableLegal />
      </div>
    </div>
  );
};
