import React from 'react';
import { Period } from '../../utils/tableTypes';
import { FilterByPeriodCell } from '../../components/TableCustomFilterCell/FilterByPeriodCell';
import TableCustomTools from '../../components/TableCustomTools/TableCustomTools';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import {
  useInstitutionDataReportTableDispatch,
  useInstitutionDataReportTableState
} from './Context';
import {
  InstitutionDataReportHeaderField,
  institutionDataReportTableColumns
} from './tableState/initialTableStateAndTypes';

export const InstitutionDataReportTableCustomizationBar: React.FC = () => {
  const { dispatch: tableDispatch } = useInstitutionDataReportTableDispatch();
  const { state: tableState } = useInstitutionDataReportTableState();

  const toggleDisplayCustomFilter = () => {
    tableDispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: InstitutionDataReportHeaderField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: InstitutionDataReportHeaderField) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const handlePeriodFilterChange = (value: Period) => {
    tableDispatch({ type: 'PERIOD_FILTER_VALUE_CHANGED', value });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <>
            <FilterByPeriodCell
              setPeriodFilter={handlePeriodFilterChange}
              periodFilter={tableState.periodFilter}
            />

            <TableCustomTools
              tableColumns={institutionDataReportTableColumns}
              showFilter={tableState.customFilterOn}
              onFilterTabToggle={toggleDisplayCustomFilter}
              sortOrder={tableState.order}
              sortBy={tableState.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnsDisplayStatus={tableState.columnsDisplayStatus}
              toggleDisplayColumn={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
