import React from 'react';
import {
  FormGroup,
  FormControlLabel,
  Typography,
  makeStyles,
  createStyles
} from '@material-ui/core';

import { VirsisSwitch } from '../VirsisSwitch/VirsisSwitch';

interface Props {
  notSignedFilterOn: boolean;
  onNotSignedFilterToggle: () => void;
  signedFilterOn: boolean;
  onSignedFilterToggle: () => void;
  annuledFilterOn: boolean;
  onAnnuledFilterToggle: () => void;
  notSignedFilterDisabled?: boolean;
  signedFilterDisabled?: boolean;
  annuledFilterDisabled?: boolean;
  allFiltersDisabled?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      header: {
        width: '100%'
      },
      formGroup: {
        gap: 10,
        marginBottom: 15
      }
    }
  })
);

const TableStatusFiltersWithAnnuled: React.FC<Props> = ({
  notSignedFilterOn,
  onNotSignedFilterToggle,
  signedFilterOn,
  onSignedFilterToggle,
  annuledFilterOn,
  onAnnuledFilterToggle,
  notSignedFilterDisabled,
  signedFilterDisabled,
  annuledFilterDisabled,
  allFiltersDisabled
}) => {
  const classes = useStyles();
  return (
    <div className="TableStatusFilters">
      <FormGroup row className={classes.formGroup}>
        <Typography
          variant="h6"
          className={classes.header}
          style={{ color: '#637082', fontWeight: 600, marginRight: 15 }}
        >
          Duomenų rodymas:
        </Typography>
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={notSignedFilterOn}
              onChange={onNotSignedFilterToggle}
              name="not-signed"
              disabled={allFiltersDisabled || notSignedFilterDisabled}
            />
          }
          label="Nepasirašyti"
        />
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={signedFilterOn}
              onChange={onSignedFilterToggle}
              name="signed"
              disabled={allFiltersDisabled || signedFilterDisabled}
            />
          }
          label="Pasirašyti"
        />
        <FormControlLabel
          control={
            <VirsisSwitch
              checked={annuledFilterOn}
              onChange={onAnnuledFilterToggle}
              name="annuled"
              disabled={allFiltersDisabled || annuledFilterDisabled}
            />
          }
          label="Anuliuoti"
        />
      </FormGroup>
    </div>
  );
};

export default TableStatusFiltersWithAnnuled;
