import React from 'react';
import { VirsTreeCustomTooltip } from '../VirsTreeCustomTooltip';

interface Props {
  color: string;
  withTooltip?: boolean;
}

export const IncomeFromDeals: React.FC<Props> = ({ color, withTooltip }) => {
  const svg = (
    <svg width="24.857" height="17.081">
      <path
        d="M24.293 11.204h-1.474L19.467 2.44a21.216 21.216 0 014.88-1.166.537.537 0 10-.054-1.07 23.967 23.967 0 00-5.711 1.421.535.535 0 00-.308.7l.013.027c-.925-.349-2.333-.858-3.472-1.367a4.319 4.319 0 00-5.095.55H5.376a.5.5 0 00-.161-.268C5.094 1.16 3.941.235.576.007a.538.538 0 10-.08 1.073 9.552 9.552 0 013.754.845l-2.078 8.879H.536a.536.536 0 100 1.073h2.068a.532.532 0 00.523-.416l.147-.63c.5.523.992 1.032 1.327 1.314 2.226 1.864 4.907 3.888 5.443 4.237a4.559 4.559 0 002.132.7 2.016 2.016 0 001.354-.4 1.924 1.924 0 001.367-.013 2.333 2.333 0 001.247-1.153 2.308 2.308 0 001.5-.215 2.054 2.054 0 001.006-1.046 1.925 1.925 0 001.367-.5 1.862 1.862 0 00.644-1.81l1.032-.684.255.67a.552.552 0 00.5.349h1.837a.538.538 0 00.536-.536.527.527 0 00-.528-.54zm-5.054 1.64a.659.659 0 01-.711.161c-.442-.362-2.346-2.306-3.526-3.526a.6.6 0 10-.858.831c.282.295 2.52 2.628 3.419 3.446a.8.8 0 01-.442.469.936.936 0 01-.925.027c-.013-.013-.027-.027-.04-.027-.724-.576-2.52-2.494-2.923-2.936a.6.6 0 00-.885.8c.013.027 1.783 1.917 2.775 2.8a1.127 1.127 0 01-.644.644.637.637 0 01-.684-.107l-.013-.013c-.858-.724-2.6-2.574-2.936-2.949a.6.6 0 00-.871.818c.4.429 1.609 1.716 2.52 2.574a1.8 1.8 0 01-.282.013 3.45 3.45 0 01-1.461-.5c-.416-.282-3.057-2.239-5.336-4.156-.322-.268-.871-.845-1.367-1.354-.161-.161-.308-.322-.456-.469l1.569-6.7h3.231c-.818.818-1.917 2.467-1.636 3.687A1.444 1.444 0 007.789 7.45a3 3 0 003.515-1.202 3.066 3.066 0 00.9-.282 4.7 4.7 0 01.416-.174c.684.644 1.609 1.394 2.588 2.172 1.756 1.421 3.754 3.043 4.21 3.968a.7.7 0 01-.179.912zm1.984-2.708l-1.086.724a27.528 27.528 0 00-4.17-3.808c-1.086-.885-2.118-1.716-2.775-2.36a.606.606 0 00-.63-.134 8.355 8.355 0 00-.845.349 1.965 1.965 0 01-.8.215.584.584 0 00-.442.268 1.848 1.848 0 01-2.306.965c-.188-.067-.215-.147-.241-.215a4.011 4.011 0 011.327-2.574c1.944-1.944 2.949-2.44 5.068-1.488a70.13 70.13 0 004.558 1.823l2.011 5.242c.112.349.224.684.331.993z"
        fill={color}
      />
    </svg>
  );

  if (withTooltip)
    return <VirsTreeCustomTooltip text="Lėšos iš sandorių">{svg}</VirsTreeCustomTooltip>;
  return svg;
};
