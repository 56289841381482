import React from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  makeStyles,
  createStyles
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AllowedTo from '../../containers/AllowedTo';
import { Roles } from '../../store/virsis/dataTypes';
import { LinkRouter } from '../Router/LinkRouter';

const useStyles = makeStyles((theme) =>
  createStyles({
    titleContainer: {
      marginTop: '20px'
    },
    buttonContainer: {
      padding: '10px',
      marginTop: '20px',
      marginBottom: '30px'
    },
    button: {
      padding: '0px 20px',
      marginRight: '10px',
      marginLeft: '0px',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    text: {
      color: 'white',
      padding: '1px 15px'
    }
  })
);

export const DataSubmission: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Box className={classes.titleContainer}>
        <Typography variant="subtitle1" gutterBottom>
          Duomenų teikimas
        </Typography>
      </Box>
      <Paper className={classes.buttonContainer}>
        <AllowedTo roles={[Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT]}>
          <LinkRouter path="/duomenu-teikimas/tirazo-patikrinimo-duomenys">
            <Button variant="contained" color="primary" className={classes.button}>
              <Typography variant="h5" className={classes.text}>
                Tiražo patikrinimas
              </Typography>
              <ChevronRightIcon />
            </Button>
          </LinkRouter>
        </AllowedTo>
        <AllowedTo roles={[Roles.ROLE_KM_METINE_IMOKA_EDIT]}>
          <LinkRouter path="/duomenu-teikimas/metines-imokos-duomenys">
            <Button variant="contained" color="primary" className={classes.button}>
              <Typography variant="h5" className={classes.text}>
                Metinė įmoka
              </Typography>
              <ChevronRightIcon />
            </Button>
          </LinkRouter>
        </AllowedTo>
        <AllowedTo roles={[Roles.ROLE_VIEA_EDIT]}>
          <LinkRouter path="/duomenu-teikimas/profesines-etikos-nesilaikymas">
            <Button variant="contained" color="primary" className={classes.button}>
              <Typography variant="h5" className={classes.text}>
                Profesnės etikos nesilaikymas
              </Typography>
              <ChevronRightIcon />
            </Button>
          </LinkRouter>
        </AllowedTo>
        <AllowedTo roles={[Roles.ROLE_ZEIT_EDIT, Roles.ROLE_LRTK_EDIT]}>
          <LinkRouter path="/duomenu-teikimas/profesiniai-pazeidimai">
            <Button variant="contained" color="primary" className={classes.button}>
              <Typography variant="h5" className={classes.text}>
                Rimti profesiniai pažeidimai
              </Typography>
              <ChevronRightIcon />
            </Button>
          </LinkRouter>
        </AllowedTo>
        <AllowedTo roles={[Roles.ROLE_ISTAIG_EDIT]}>
          <LinkRouter path="/duomenu-teikimas/virs-gautos-lesos">
            <Button variant="contained" color="primary" className={classes.button}>
              <Typography variant="h5" className={classes.text}>
                VIRS gautos lėšos
              </Typography>
              <ChevronRightIcon />
            </Button>
          </LinkRouter>
        </AllowedTo>
      </Paper>
    </Container>
  );
};
