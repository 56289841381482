import { action } from 'typesafe-actions';
import {
  FETCH_USER_MESSAGES_REQUEST,
  FETCH_USER_MESSAGES_SUCCESS,
  FETCH_USER_MESSAGES_ERROR,
  MARK_MESSAGES_READ_REQUEST,
  MARK_MESSAGES_READ_SUCCESS,
  MARK_MESSAGES_READ_ERROR,
  RESET_MARK_MESSAGES_READ_STATE,
  MARK_MESSAGES_UNREAD_REQUEST,
  MARK_MESSAGES_UNREAD_SUCCESS,
  MARK_MESSAGES_UNREAD_ERROR,
  RESET_MARK_MESSAGES_UNREAD_STATE,
  REMOVE_MESSAGES_REQUEST,
  REMOVE_MESSAGES_SUCCESS,
  REMOVE_MESSAGES_ERROR,
  RESET_REMOVE_MESSAGES_STATE
} from './userMessagesActionTypes';
import { UserMessageRecord } from './userMessageTypes';

export const fetchUserMessages = () => action(FETCH_USER_MESSAGES_REQUEST);
export const fetchUserMessagesSuccess = (data: string) => action(FETCH_USER_MESSAGES_SUCCESS, data);
export const fetchUserMessagesError = (error: Error) => action(FETCH_USER_MESSAGES_ERROR, error);

export const removeUserMessages = (messages: UserMessageRecord[]) =>
  action(REMOVE_MESSAGES_REQUEST, messages);
export const removeUserMessageSuccess = () => action(REMOVE_MESSAGES_SUCCESS);
export const removeUserMessageError = (error: Error) => action(REMOVE_MESSAGES_ERROR, error);
export const resetUserMessageRemovingState = () => action(RESET_REMOVE_MESSAGES_STATE);

export const markMessagesRead = (messages: UserMessageRecord[]) =>
  action(MARK_MESSAGES_READ_REQUEST, messages);
export const markMessagesReadSuccess = () => action(MARK_MESSAGES_READ_SUCCESS);
export const markMessagesReadError = (error: Error) => action(MARK_MESSAGES_READ_ERROR, error);
export const resetMarkMessagesReadState = () => action(RESET_MARK_MESSAGES_READ_STATE);

export const markMessagesUnread = (messages: UserMessageRecord[]) =>
  action(MARK_MESSAGES_UNREAD_REQUEST, messages);
export const markMessagesUnreadSuccess = () => action(MARK_MESSAGES_UNREAD_SUCCESS);
export const markMessagesUnreadError = (error: Error) => action(MARK_MESSAGES_UNREAD_ERROR, error);
export const resetMarkMessagesUnreadState = () => action(RESET_MARK_MESSAGES_UNREAD_STATE);
