import { get } from '../../utils/axiosApi';

export const getLegalFormTypes = () => get('/classifiers/legal-form-types');

export const getShareTypes = () => get('/classifiers/share-types');

export const getAnnulmentTypes = () => get('/classifiers/annulment-types');

export const getEnterpriseTypes = () => get('/classifiers/enterprise-types');

export const getDataDeletionPeriod = () => get('/classifiers/data-deletion-period');

export const getFicticiousOutletData = () => get('/classifiers/fictitious-outlet');
