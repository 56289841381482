import React from 'react';

import { Container, Box } from '@material-ui/core';
import GoBackButton from './GoBackButton';
import GoToPageButton from './GoToPageButton';

interface Props {
  handleGoToPageClick?: () => void;
  handleGoBackClick?: () => void;
  pageToGo: string;
}

const BottomPageActionButtons: React.FC<Props> = ({
  handleGoToPageClick,
  handleGoBackClick,
  pageToGo
}) => {
  return (
    <div className="action-row-top-box-shadow">
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
          paddingTop={2}
          paddingBottom={2}
        >
          {handleGoBackClick && <GoBackButton btnText="Atgal" onClick={handleGoBackClick} />}
          <GoToPageButton btnText={pageToGo} onClick={handleGoToPageClick} />
        </Box>
      </Container>
    </div>
  );
};

export default BottomPageActionButtons;
