import { LegalFormType } from '../../../../store/classifiers/classifiersTypes';
import { LegalPersonSearchData } from '../../../../store/legalPersonData/legalDataTypes';
import { NaturalPersonSearchData } from '../../../../store/persons/personsTypes';
import {
  ShareholdersProps,
  ShareholderPersonsIds,
  LegalPerson,
  ShareholdersHistory,
  DocumentProps,
  ShareholderPercentages,
  ShareholderPerson,
  ShareholdersData,
  AddPercentageRecord
} from '../../../../store/shareholders/shareholdersTypes';
import { splitShareholderPath } from '../../utilityFunctions/sharedFunctions';

import {
  ShareholderGroupPerson,
  ShareholderChildParents,
  ShareholderChildParentPersons
} from '../tableState/tableInitialStateAndTypes';
import { checkIfColorBarNeeded } from './UIFunctions';

export function getLegalFormType(
  shareholderLegalFormCode: number,
  legalFormTypes: LegalFormType[]
) {
  return legalFormTypes.find(
    (legalFormType) => legalFormType.legalFormCode === shareholderLegalFormCode
  );
}

export function getIsPersonUnique(personId: number, personList: ShareholderGroupPerson[]) {
  return !personList.find((person) => personId === person.personId);
}

export function getShareholderPersonsIds(
  shareholderPersonsList: ShareholderGroupPerson[]
): ShareholderPersonsIds[] {
  return shareholderPersonsList.map((person) => ({
    personId: person.personId
  }));
}

export function getIsSelectedPersonFromJadis(
  legalFormCode: number,
  legalFormTypes: LegalFormType[]
) {
  const personsLegalFormTypes = getLegalFormType(legalFormCode, legalFormTypes);

  return personsLegalFormTypes && personsLegalFormTypes.isFromJadis;
}

/** *patikrina, ar reikia renderinti dalyvį */
export const checkIfRenderShareholderRow = (
  showNonParticipatingShareholders: boolean,
  shareholderStartDate: string | null,
  shareholderParentStartDate: string,
  shareholderStoppedParticipatingFromDate: string | null,
  selectedDate: string
) => {
  const drawColorBarSelectedDate = checkIfColorBarNeeded(
    shareholderStartDate,
    shareholderParentStartDate,
    shareholderStoppedParticipatingFromDate,
    selectedDate
  );

  if (showNonParticipatingShareholders) {
    return true;
  }
  if (drawColorBarSelectedDate) {
    return true;
  }

  return false;
};

export const getLastArrItem = <T>(arr: T[]) => {
  return arr[arr.length - 1];
};

export const showExtendedDetailsButton = (
  shareholder: ShareholdersProps,
  selectedDate?: string
) => {
  if (shareholder.isShareholderFromJADIS) {
    return true;
  }
  if (selectedDate) {
    const previousAndSelectedDateEvents = shareholder.shareholderHistory.filter(
      (event) => event.eventDate <= selectedDate
    );
    return (
      previousAndSelectedDateEvents.length > 0 &&
      previousAndSelectedDateEvents.some((event) => event.documentStatus === 'PASIRASYTAS')
    );
  }
  const lastEvent = getLastArrItem(
    shareholder.shareholderHistory.filter((event) => event.documentId !== null)
  );
  return lastEvent?.documentStatus === 'PASIRASYTAS' || lastEvent?.documentStatus === null;
};

export function formEditShareholderPerson(
  shareholderToEdit: ShareholdersProps,
  legalFormTypes: LegalFormType[]
) {
  return shareholderToEdit.shareholderPersons.map((personToEdit) => {
    const { personId, personType, personName, personLegalCode, personLegalFormCode } = personToEdit;
    const isLegal = personType === LegalPerson.LJA || personType === LegalPerson.UJA;

    return {
      personId,
      personType,
      personName,
      legalPersonCode: isLegal ? personLegalCode : undefined,
      shouldSynchronize:
        isLegal && personLegalFormCode
          ? getIsSelectedPersonFromJadis(personLegalFormCode, legalFormTypes)
          : false
    };
  });
}

export function formAddShareholderPerson(
  personToAdd: NaturalPersonSearchData | LegalPersonSearchData,
  legalFormTypes: LegalFormType[]
) {
  function isLegal(
    person: NaturalPersonSearchData | LegalPersonSearchData
  ): person is LegalPersonSearchData {
    const { personType } = person as LegalPersonSearchData;
    return personType === LegalPerson.LJA || personType === LegalPerson.UJA;
  }

  const { personId, personType, personFullName, personCode } = personToAdd;

  return [
    {
      personId,
      personType,
      personName: personFullName,
      legalPersonCode: isLegal(personToAdd) ? personCode : undefined,
      shouldSynchronize:
        isLegal(personToAdd) &&
        getIsSelectedPersonFromJadis(personToAdd.legalFormCode, legalFormTypes)
    }
  ];
}

//
export function hasShareholderUnsignedDocs(
  shareholderHistory: ShareholdersHistory[],
  shareholderStoppedParticipatingFromDate: string | null,
  closedWithDocument: DocumentProps | null,
  selectedDate: string
) {
  const selectedDateEvent = shareholderHistory.find((event) => event.eventDate === selectedDate);
  return (
    (shareholderStoppedParticipatingFromDate === selectedDate &&
      closedWithDocument?.documentStatus === 'RUOSIAMAS') ||
    selectedDateEvent?.documentStatus === 'RUOSIAMAS'
  );
}

export function getShareholderEmptyPercentages(
  shareholderId: number,
  canHavePercentage: { mayHaveSharePercentage: boolean; mayHaveVotePercentage: boolean },
  allShareholders?: ShareholdersProps[]
) {
  const shareholderWithAllEvents = allShareholders?.find(
    (shareholder) => shareholder.shareholderId === shareholderId
  );

  if (shareholderWithAllEvents) {
    const shareholdersCopy = JSON.parse(JSON.stringify(shareholderWithAllEvents));
    const shareholderWithoutLastEvent = shareholdersCopy.shareholderHistory.splice(0, 1);

    const allPercentages: ShareholderPercentages[] = shareholderWithoutLastEvent.map(
      (event: ShareholdersHistory) => ({
        sharePercentage: canHavePercentage.mayHaveSharePercentage ? event.sharePercentage : true,
        votePercentage: canHavePercentage.mayHaveVotePercentage
          ? event.votePercentage || event.votePercentage === 0
          : true
      })
    );

    return allPercentages.filter((percentageObject: ShareholderPercentages) => {
      return !percentageObject.sharePercentage || !percentageObject.votePercentage;
    });
  }

  return undefined;
}

export function getCurrentDateSigned(
  shareholderHistory: ShareholdersHistory[],
  selectedDate?: string
): boolean {
  if (selectedDate) {
    return shareholderHistory.some(
      (event: ShareholdersHistory) =>
        event.eventDate === selectedDate && event.documentStatus === 'PASIRASYTAS'
    );
  }
  return false;
}

export const getIsLastShareholder = (index: number, shareholders: ShareholdersProps[]) => {
  return index === shareholders.length - 1;
};

// grąžina tėvų-vaikų pagal path
const computeParents = (data: ShareholdersProps[], parents: ShareholderChildParents) => {
  data.forEach((shareholder, index) => {
    const isLast = getIsLastShareholder(index, data);
    shareholder.shareholderPersons.forEach((person) => {
      person.shareholders.forEach((childSh) => {
        parents[childSh.shareholderPath] = {
          parentId: shareholder.shareholderPath,
          parentLevel: shareholder.shareholderLevel,
          isParentLastChild: isLast,
          parentPersonId: person.personId
        };
        computeParents(person.shareholders, parents);
      });
    });
  });
  return parents;
};

export function getParentStatus(
  id: string,
  result: boolean[],
  parentsInfo: ShareholderChildParents
): boolean[] {
  const parent = parentsInfo[id];

  if (!parent) {
    return result.reverse().slice(0, 10);
  }
  const { parentId, isParentLastChild } = parent;
  return getParentStatus(parentId, [...result, isParentLastChild], parentsInfo);
}

export const getParentPathAndStatus = (data: ShareholdersProps[], shareholderPath: string) => {
  const parentInfo = computeParents(data, {});
  const parentStatuses = getParentStatus(shareholderPath, [], parentInfo);
  return parentStatuses;
};

// grąžina tėvų-vaikų ryšius su personId
export const computeParentPersons = (
  data: ShareholdersProps[],
  parentsPersons: ShareholderChildParentPersons
) => {
  data.forEach((shareholder, index) => {
    shareholder.shareholderPersons.forEach((person) => {
      person.shareholders.forEach((childSh) => {
        childSh.shareholderPersons.forEach((childPerson) => {
          parentsPersons[childPerson.personId] = {
            parentId: shareholder.shareholderPath,
            parentLevel: shareholder.shareholderLevel,
            parentPersonId: person.personId
          };
          computeParentPersons(person.shareholders, parentsPersons);
        });
      });
    });
  });
  return parentsPersons;
};

const getParentPersonPath = (
  id: number,
  result: string[],
  parentsInfo: ShareholderChildParentPersons
): string[] => {
  const parent = parentsInfo[id];
  if (!parent) {
    return result;
  }

  const { parentPersonId, parentLevel } = parent;
  const uniqueId = `${parentLevel}${parentPersonId}`;
  return getParentPersonPath(parentPersonId, [...result, uniqueId], parentsInfo);
};

export const getParentPersonsPath = (data: ShareholdersProps[], personId: number) => {
  const parentsInfo = computeParentPersons(data, {});
  const parentPersonPath = getParentPersonPath(personId, [], parentsInfo);
  return parentPersonPath;
};

export const getParentShareholderId = (path: string) => {
  const shareholdersIdArray = splitShareholderPath(path);
  return Number(shareholdersIdArray[shareholdersIdArray.length - 2]);
};

export const getAllShareholders = (
  shareholders: ShareholdersProps[],
  acc: any
): ShareholdersProps[] =>
  shareholders.reduce((acc, shareholder) => {
    if (shareholder.shareholderPersons) {
      shareholder.shareholderPersons.forEach(
        (person) => (acc = getAllShareholders(person.shareholders, acc))
      );
    }

    return [...acc, shareholder];
  }, acc);

export function getparticipatingShareholders(
  shareholders: ShareholdersProps[],
  selectedDate: string
): ShareholdersProps[] {
  return shareholders
    .filter((shareholder) =>
      checkIfColorBarNeeded(
        shareholder.shareholderStartDate,
        shareholder.shareholderParentStartDate,
        shareholder.shareholderStoppedParticipatingFromDate,
        selectedDate
      )
    )
    .map((shareholder) => ({
      ...shareholder,
      shareholderPersons: [
        ...shareholder.shareholderPersons.map((person) => ({
          ...person,
          shareholders: getparticipatingShareholders(person.shareholders, selectedDate)
        }))
      ]
    }));
}

export function getInitialPercentageFormValues(
  parentShareholder: ShareholdersProps | ShareholdersData | ShareholderPerson,
  shareholderEvent: ShareholdersHistory,
  shareholderStartDate: string | null,
  shareholderEndDate: string | null,
  selectedDate?: string
) {
  const votesOnRulesInitialValue =
    parentShareholder.mayHaveVotesOnRules && !parentShareholder.mayHaveVotePercentage
      ? true
      : shareholderEvent.votesOnRules;

  return {
    mayHaveSharePercentage: !!parentShareholder.shareTypeName,
    mayHaveVotePercentage: parentShareholder.mayHaveVotePercentage,
    mayHaveVotesOnRules: parentShareholder.mayHaveVotesOnRules,
    sharePercentage: shareholderEvent.sharePercentage?.toString() || null,
    votePercentage: shareholderEvent.votePercentage?.toString() || null,
    votesOnRules: !!votesOnRulesInitialValue,
    votesValueSelected: false,
    participateBeforeSelectedDate: !shareholderStartDate,
    endOfParticipation: shareholderEndDate === selectedDate,
    isEventFromJadis: !!shareholderEvent.isEventFromJADIS,
    endOfParticipationFromBefore: false,
    sharePercentageLessThanPermitted: !!shareholderEvent.sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted: !!shareholderEvent.votePercentageLessThanPermitted
  };
}

export function getFormedPercentageRecord(
  values: any,
  virsId: number,
  parentId: number,
  shareholderId: number,
  eventDate: string
): AddPercentageRecord {
  const {
    sharePercentage,
    votePercentage,
    votesOnRules,
    participateBeforeSelectedDate,
    endOfParticipation,
    endOfParticipationFromBefore,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  } = values;

  return {
    virsId,
    parentId,
    shareholderId,
    eventDate,
    sharePercentage: sharePercentage,
    votePercentage: votePercentage || votePercentage === '0' ? Number(votePercentage) : null,
    votesOnRules,
    participateBeforeSelectedDate,
    endOfParticipation,
    endOfParticipationFromBefore,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  };
}

export function getIsDateStartDate(
  date: string | undefined,
  shareholderStartDate: string | null,
  shareholderParentStartDate: string | null
) {
  if (date) {
    return shareholderStartDate
      ? date === shareholderStartDate
      : date === shareholderParentStartDate;
  }
  return null;
}

export const getHasLaterEvents = (events: ShareholdersHistory[], date: string) =>
  events.some((event) => event.eventDate > date);
