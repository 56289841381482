export const FETCH_EDITION_CHECK_DATA_REQUEST = '@editionCheck/FETCH_EDITION_CHECK_DATA_REQUEST';
export const FETCH_EDITION_CHECK_DATA_SUCCESS = '@editionCheck/FETCH_EDITION_CHECK_DATA_SUCCESS';
export const FETCH_EDITION_CHECK_DATA_ERROR = '@editionCheck/FETCH_EDITION_CHECK_DATA_ERROR';

export const CREATE_EDITION_REQUEST = '@editionCheck/CREATE_EDITION_REQUEST';
export const CREATE_EDITION_SUCCESS = '@editionCheck/CREATE_EDITION_SUCCESS';
export const CREATE_EDITION_ERROR = '@editionCheck/CREATE_EDITION_ERROR';
export const RESET_CREATE_EDITION_STATE = '@editionCheck/RESET_CREATE_EDITION_STATE';
export const CONTINUE_ADDING_EDITION_CLICKED = '@editionCheck/CONTINUE_ADDING_EDITION_CLICKED';
export const CANCEL_ADDING_EDITION_CLICKED = '@editionCheck/CANCEL_ADDING_EDITION_CLICKED';

export const UPDATE_EDITION_REQUEST = '@editionCheck/UPDATE_EDITION_REQUEST';
export const UPDATE_EDITION_SUCCESS = '@editionCheck/UPDATE_EDITION_SUCCESS';
export const UPDATE_EDITION_ERROR = '@editionCheck/UPDATE_EDITION_ERROR';
export const RESET_UPDATE_EDITION_STATE = '@editionCheck/RESET_UPDATE_EDITION_STATE';

export const REMOVE_EDITION_REQUEST = '@editionCheck/REMOVE_EDITION_REQUEST';
export const REMOVE_EDITION_SUCCESS = '@editionCheck/REMOVE_EDITION_SUCCESS';
export const REMOVE_EDITION_ERROR = '@editionCheck/REMOVE_EDITION_ERROR';
export const RESET_REMOVE_EDITION_STATE = '@editionCheck/RESET_REMOVE_EDITION_STATE';

export const FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST';
export const FETCH_EDITION_CHECK_DATA_BY_VIRID_SUCCESS =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_BY_VIRID_SUCCESS';
export const FETCH_EDITION_CHECK_DATA_BY_VIRID_ERROR =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_BY_VIRID_ERROR';

export const FETCH_EDITION_CHECK_DATA_VIRS_REQUEST =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_VIRS_REQUEST';
export const FETCH_EDITION_CHECK_DATA_VIRS_SUCCESS =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_VIRS_SUCCESS';
export const FETCH_EDITION_CHECK_DATA_VIRS_ERROR =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_VIRS_ERROR';

export const EDITION_CHECK_DATA_VIRS_PDF_REQUEST =
  '@editionCheck/EDITION_CHECK_DATA_VIRS_PDF_REQUEST';

export const ANNUL_EDITION_CHECK_DATA_REQUEST = '@editionCheck/ANNUL_EDITION_CHECK_DATA_REQUEST';
export const ANNUL_EDITION_CHECK_DATA_SUCCESS = '@editionCheck/ANNUL_EDITION_CHECK_DATA_SUCCESS';
export const ANNUL_EDITION_CHECK_DATA_ERROR = '@editionCheck/ANNUL_EDITION_CHECK_DATA_ERROR';
export const RESET_ANNUL_EDITION_CHECK_DATA_STATE =
  '@editionCheck/RESET_ANNUL_EDITION_CHECK_DATA_STATE';

export const FETCH_PUBLICATION_OUTLETS_REQUEST = '@editionCheck/FETCH_PUBLICATION_OUTLETS_REQUEST';
export const FETCH_PUBLICATION_OUTLETS_SUCCESS = '@editionCheck/FETCH_PUBLICATION_OUTLETS_SUCCESS';
export const FETCH_PUBLICATION_OUTLETS_ERROR = '@editionCheck/FETCH_PUBLICATION_OUTLETS_ERROR';
export const RESET_PUBLICATION_OUTLETS = '@editionCheck/RESET_PUBLICATION_OUTLETS';

export const HIDE_POST_SAVE_WARNING = '@editionCheck/HIDE_POST_SAVE_WARNING';
export const FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST =
  '@editionCheck/FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST';
