import { EditionCheckDataInstTableHeaderType } from './tableTypesAndActions';

export const editionCheckTableColumns: EditionCheckDataInstTableHeaderType[] = [
  {
    id: 'virsName',
    label: 'VIRS'
  },
  {
    id: 'checkDate',
    label: 'Data',
    type: 'date'
  },
  {
    id: 'docNr',
    label: 'Nr.'
  },
  {
    id: 'outletName',
    label: 'Susijusios VIP'
  },
  {
    id: 'periodName',
    label: 'Tiražo laikotarpis'
  },
  {
    id: 'conclusion',
    label: 'Išvada dėl patikrinto tiražo atitikties'
  }
];
