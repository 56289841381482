import { Moment } from 'moment';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getReducer } from './reducer';
import { saga } from './saga';
import {
  PeriodName,
  TimelineEvent
} from '../../../../store/shareholdersVirsTree/virsTreeDataTypes';

export const getStore = (
  periodName: PeriodName,
  activeDate: Moment,
  timelineEvents: TimelineEvent[],
  isLoading: boolean,
  error: string | undefined,
  onChange: (activeDate: Moment) => void
) => {
  const sagaMiddleware = createSagaMiddleware();
  const reducer = getReducer(periodName, activeDate, timelineEvents, isLoading, error, onChange);

  const store = createStore(reducer, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(saga);
  return store;
};
