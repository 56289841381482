import {
  getLeftBorderRadius,
  getRightBorderRadius
} from '../../SelectedDateTable/utilities/UIFunctions';
import { getBorder } from '../../utilityFunctions/sharedUIFunctions';
import { Period } from '../tableState/tableInitialStateAndTypes';
import {
  getVirsWidthByDay,
  getJustifyContentByDay,
  getVirsLeftParameter,
  getVirsRightParameter
} from '../utilities/UIfunctions';

export const virsRowStyles = (
  startDate: string,
  endDate: string | null,
  date: string,
  fillWithColor: string,
  startWithTriangle: boolean,
  borderColor: string,
  textColor: string,
  periodFilterFromDate: string,
  periodFilter: Period
) => {
  return {
    width: getVirsWidthByDay(endDate, date, periodFilter),
    justifyContent: getJustifyContentByDay(startDate, date, periodFilterFromDate),
    backgroundColor: fillWithColor,
    left: getVirsLeftParameter(startDate, date, periodFilterFromDate, periodFilter),
    right: getVirsRightParameter(startDate, endDate, date, periodFilterFromDate),
    borderTopLeftRadius: getLeftBorderRadius(startDate, date, startWithTriangle),
    borderBottomLeftRadius: getLeftBorderRadius(startDate, date, startWithTriangle),
    borderTopRightRadius: getRightBorderRadius(endDate, date),
    borderBottomRightRadius: getRightBorderRadius(endDate, date),
    borderTop: getBorder(borderColor),
    borderBottom: getBorder(borderColor),
    color: textColor
  };
};
