import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../utils/tableDataFunctions';
import { getLastColumnColorList } from '../../utilityFunctions/sharedFunctions';
import {
  getCalenderDisplayProps,
  getDatesWithEventsForDays,
  getDatesWithEventsForHeader,
  getDatesWithEventsForYears,
  setInitialDatesToMonthsHeader,
  setShowCellDetailsPopover
} from './tableFunctions';
import { ShareholdersTableAction, ShareholdersTableState } from './tableInitialStateAndTypes';

export const ShareholdersTableReducer = (
  state: ShareholdersTableState,
  action: ShareholdersTableAction
): ShareholdersTableState => {
  switch (action.type) {
    case 'SET_SHOW_SHARES_VOTES':
      return {
        ...state,
        showShares: action.showShares,
        showVotes: action.showVotes
      };
    case 'SHOW_CELL_DETAILS_POPOVER':
      return {
        ...state,
        showDetailsPopoverDateId: setShowCellDetailsPopover(
          state.showDetailsPopoverDateId,
          action.date,
          action.id
        )
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value)
      };
    case 'RESET_CUSTOM_FILTER_VALUE':
      return {
        ...state,
        customFilter: action.customFilter,
        resetFilter: action.resetFilter
      };
    case 'SET_LAST_COLUMN_BAR_COLOR':
      return {
        ...state,
        lastColumnColors: getLastColumnColorList(
          state.lastColumnColors,
          action.lastColumnColor,
          action.lastColumnBorderColor,
          action.shareholderId
        )
      };
    case 'SET_SHAREHOLDERS_LEVEL_LIST':
      return {
        ...state,
        shareholdersLevelList: action.shareholdersLevelList
      };
    case 'SET_DEFAULT_LEVEL_VALUE':
      return {
        ...state,
        defaultFilterLevelValue: action.defaultFilterLevelValue
      };
    case 'PERIOD_FILTER_VALUE_CHANGED':
      return {
        ...state,
        tableId: action.value.id,
        periodFilter: action.value,
        calendarDisplayProps: getCalenderDisplayProps(action.value)
      };

    case 'INITIAL_HEADER_DATES_BY_DAY':
      return {
        ...state,
        datesInPerdiodWithEvents: getDatesWithEventsForDays(
          action.allEventsDates,
          action.initialDates
        )
      };

    case 'INITIAL_HEADER_DATES':
      return {
        ...state,
        datesInPerdiodWithEvents: action.initialDates
      };

    case 'EXTEND_HEADER_DATES':
      return {
        ...state,
        tableId: action.tableId,
        periodFilter: action.value,
        calendarDisplayProps: getCalenderDisplayProps(action.value),
        datesInPerdiodWithEvents: getDatesWithEventsForYears(
          state.datesInPerdiodWithEvents,
          action.allEventsDates,
          action.months,
          action.yearsWithEvents,
          action.clickedDate
        )
      };
    case 'INITIAL_MONTHS_HEADER_DATES':
      return {
        ...state,
        monthsDateMix: action.months,
        datesInPerdiodWithEvents: setInitialDatesToMonthsHeader(
          state.datesInPerdiodWithEvents,
          action.allEventsDates,
          action.months
        )
      };
    case 'EXTEND_MONTHS_HEADER_DATES':
      return {
        ...state,
        tableId: action.tableId,
        periodFilter: action.value,
        calendarDisplayProps: getCalenderDisplayProps(action.value),
        datesInPerdiodWithEvents: getDatesWithEventsForHeader(
          state.datesInPerdiodWithEvents,
          action.allEventsDates,
          state.monthsDateMix,
          action.clickedDate,
          action.days
        )
      };

    case 'SAVE_FILTER_BUTTON_REFERENCE':
      return {
        ...state,
        filterButtonRef: action.filterButtonRef
      };

    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        filterButtonAnchor: action.filterButtonAnchor
      };

    default:
      return state;
  }
};
