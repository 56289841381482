import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import {
  ShareholdersProps,
  ShareholdersData,
  ShareholderPerson
} from '../../../../../store/shareholders/shareholdersTypes';
import { useShareholdersSelectedDateTableState } from '../../TableWithContext';
import { EditShareholderRow } from './EditShareholderRow';

interface Props {
  index: number;
  shareholder: ShareholdersProps;
  parentShareholder: ShareholdersProps | ShareholdersData;
  groupMemberParentPerson?: ShareholderPerson;
}

export const EditShareholderRowContainer: React.FC<Props> = ({
  index,
  shareholder,
  parentShareholder,
  groupMemberParentPerson
}) => {
  const {
    virsis: { currentUser, selectedPersonId },
    shareholdersData: { shareholderToEditId }
  } = useSelector((state: ApplicationState) => state);

  const {
    state: {
      showAddShareholderRow,
      showEditShareholderRow,
      parentIdToAddChild,
      index: selectedIndex
    }
  } = useShareholdersSelectedDateTableState();

  const {
    shareholderId,
    shareholderGroupId,
    shareholderLevel,
    shareholderPersons,
    isShareholderFromJADIS
  } = shareholder;

  const [{ personLegalFormCode, personId }] = shareholderPersons;

  const parentNotVirs = (
    selectedParentShareholder: ShareholdersProps | ShareholdersData
  ): selectedParentShareholder is ShareholdersProps =>
    (selectedParentShareholder as ShareholdersProps).shareholderId !== undefined;

  const selectedShareholderMatch = parentIdToAddChild && parentIdToAddChild === shareholderId;

  const showAddRow = showAddShareholderRow && !shareholderGroupId && selectedShareholderMatch;

  const showEditRow =
    showEditShareholderRow &&
    shareholderToEditId &&
    !groupMemberParentPerson &&
    selectedShareholderMatch;

  const showEditShareholderGroupRow = showEditShareholderRow && selectedShareholderMatch;

  const getShareholderLevel = showAddRow ? shareholderLevel + 1 : shareholderLevel;

  const setParentLegalFormCode = () => {
    if (showAddRow) {
      return personLegalFormCode;
    }
    if (showEditRow) {
      return parentNotVirs(parentShareholder)
        ? parentShareholder.shareholderPersons[0].personLegalFormCode
        : parentShareholder.virsLegalFormCode;
    }
    if (showEditShareholderGroupRow && groupMemberParentPerson) {
      return groupMemberParentPerson.personLegalFormCode;
    }
    return null;
  };

  const setParentPersonId = () => {
    if (showAddRow) {
      return personId;
    }
    if (showEditRow && currentUser) {
      return (
        (parentNotVirs(parentShareholder) && parentShareholder.shareholderPersons[0].personId) ||
        selectedPersonId ||
        currentUser.personId
      );
    }
    if (showEditShareholderGroupRow && groupMemberParentPerson) {
      return groupMemberParentPerson.personId;
    }
    return null;
  };

  const setParentShareholder = () => {
    if (showAddRow) {
      return shareholderPersons[0];
    }
    if (showEditRow) {
      return parentNotVirs(parentShareholder)
        ? parentShareholder.shareholderPersons[0]
        : parentShareholder;
    }
    if (showEditShareholderGroupRow && groupMemberParentPerson) {
      return groupMemberParentPerson;
    }
    return null;
  };

  const getParentLegalFormCode = setParentLegalFormCode();
  const getParentPersonId = setParentPersonId();
  const getParentShareholder = setParentShareholder();

  return (
    <>
      {getParentShareholder && getParentPersonId && selectedIndex === index && (
        <EditShareholderRow
          index={index}
          shareholderLevel={getShareholderLevel}
          parentLegalFormCode={getParentLegalFormCode}
          parentPersonId={getParentPersonId}
          parentShareholder={getParentShareholder}
          isShareholderFromJadis={isShareholderFromJADIS}
          mayShareholderHaveChildren={shareholder.mayShareholderHaveChildren}
        />
      )}
    </>
  );
};
