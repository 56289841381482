export const INITIALIZE_TABLE_STATE = '@ethicalNonCompliance/INITIALIZE_TABLE_STATE';
export const SHOW_STATUS_OUTDATED_TOGGLED = '@ethicalNonCompliance/SHOW_STATUS_OUTDATED_TOGGLED';
export const SHOW_STATUS_NOT_SIGNED_TOGGLED =
  '@ethicalNonCompliance/SHOW_STATUS_NOT_SIGNED_TOGGLED';
export const SHOW_STATUS_RELEVANT_TOGGLED = '@ethicalNonCompliance/SHOW_STATUS_RELEVANT_TOGGLED';
export const CUSTOM_FILTER_VALUE_CHANGED = '@ethicalNonCompliance/CUSTOM_FILTER_VALUE_CHANGED';
export const CUSTOM_FILTER_VALUE_RESET = '@ethicalNonCompliance/CUSTOM_FILTER_VALUE_RESET';
export const SORTING_CHANGED = '@ethicalNonCompliance/SORTING_CHANGED';
export const PAGE_SET = '@ethicalNonCompliance/PAGE_SET';
export const ROWS_PER_PAGE_SET = '@ethicalNonCompliance/ROWS_PER_PAGE_SET';
export const UPDATE_TEMP_DATA_STATE = '@ethicalNonCompliance/UPDATE_TEMP_DATA_STATE';
