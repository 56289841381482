import React, { useContext } from 'react';
import { TableRow, TableCell, Typography, TableHead } from '@material-ui/core';
import { EditionCheckDataTableStateContext, EditionCheckDataTableDispatchContext } from './Context';
import { calculateEditionCheckColSpan } from './tableState/tableStateFunctions';
import {
  EditionCheckDataVirsTableField,
  EditionCheckDataTableState
} from './tableState/tableTypesAndActions';
import { getSortLabelPropsFactory } from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';

export const EditionCheckDataTableHeader: React.FC = () => {
  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const handleSetSortByColumn = (column: EditionCheckDataVirsTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    EditionCheckDataTableState,
    typeof handleSetSortByColumn,
    EditionCheckDataVirsTableField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.checkDate || tableState.columnsDisplayStatus.docNr) && (
          <TableCell
            align="left"
            colSpan={calculateEditionCheckColSpan(tableState.columnsDisplayStatus)}
            variant="head"
          >
            <Typography variant="h4">Tiražo tikrinimo</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Susijusios VIP"
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.periodName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Tiražo laikotarpis"
            columnName="periodName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.conclusion && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Tiražo patikrinimo išvada"
            columnName="conclusion"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.checkedBy && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Tiražą patikrino"
            columnName="checkedBy"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        <TableCell align="right" rowSpan={2} variant="head">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.checkDate && (
          <TableSortingHeaderCell
            label="Data"
            columnName="checkDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.docNr && (
          <TableSortingHeaderCell
            label="Nr."
            columnName="docNr"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
