import React from 'react';

import { LicensesData } from '../../../store/externalSystemData/externalSystemDataTypes';
import { RowsPerPageOptions } from './tableState/tableTypes';
import DataTableWrapper from './DataTableWrapper';
import { columnNames } from './tableState/tableColumns';

interface Props {
  data: LicensesData[];
  rowsPerPageOptions: RowsPerPageOptions;
}

const LicensesTable: React.FC<Props> = ({ data, rowsPerPageOptions }) => {
  const originalData = data;

  return (
    <div className="named-data-table">
      <DataTableWrapper
        originalData={originalData}
        columnNames={columnNames}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};

export default LicensesTable;
