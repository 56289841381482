import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../style/virsisTheme';
import { BasicDarkTooltip } from '../../Tooltips/BasicDarkTooltip';

interface Props {
  onClick: () => void;
  outlined?: boolean;
  tooltipMessage: string;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  button: {
    justifyContent: 'center',
    height: 40,
    width: 40,
    margin: 0
  },
  contained: {
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.secondary.light
    },
    '&:hover': {
      backgroundColor: '#0A5C8E'
    },
    '&:disabled': {
      backgroundColor: theme.palette.info.main
    }
  },
  outlined: {
    border: `1px solid ${theme.palette.info.light}`,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    '& svg': {
      fill: theme.palette.info.dark
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.primary.main}`,
      '& svg': {
        fill: theme.palette.primary.main
      }
    },
    '&:disabled': {
      backgroundColor: theme.palette.info.light
    }
  },
  icon: {
    width: 20
  }
}));

export const ShareholderAddButton: React.FC<Props> = ({
  onClick,
  outlined,
  tooltipMessage,
  disabled
}) => {
  const classes = useStyles();

  return (
    <BasicDarkTooltip text={tooltipMessage}>
      <span>
        <Button
          className={`${classes.button} ${outlined ? classes.outlined : classes.contained}`}
          onClick={onClick}
          disabled={disabled}
        >
          <AddIcon className={classes.icon} />
        </Button>
      </span>
    </BasicDarkTooltip>
  );
};
