import React from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import {
  ProfessionalMisconductDataVirs,
  ProfessionalMisconductOutlet
} from '../../store/professionalMisconducts/professionalMisconductsTypes';
import { useProfessionalMisconductTableState } from './Context';
import { OutletsAsLinksList } from '../../components/TableLinks/OutletsAsLinksList';
import { ReportErrorAction } from '../../components/TableRowActions/ReportErrorAction';
import { ExpandDetailsButton } from '../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { ReportInstitutionDataDialog } from '../../components/ReportInstitutionDataDialog/ReportInstitutionDataDialog';
import { PreviewDocumentDialogMinimal } from '../document/PreviewDocumentDialogMinimal';
import useInstitutionTableRow from '../../utils/hooks/institutionTableRowHook';
import { TableRowExtension } from '../../components/institutionProvidedDataTable/TableRowExtension';
import { Roles } from '../../store/virsis/dataTypes';

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  },
  validDataColumnWidth: {
    width: '105px',
    boxSizing: 'border-box'
  },
  removePadding: {
    padding: '5px',
    position: 'relative',
    left: '-5px'
  }
});

interface Props {
  record: ProfessionalMisconductDataVirs;
  openDocumentInNewTab: (documentId: number) => void;
  virsId: number;
}

export const ProfessionalMisconductBasicRow: React.FC<Props> = ({ record, virsId }) => {
  const classes = useStyles();
  const {
    state: { columnsDisplayStatus }
  } = useProfessionalMisconductTableState();
  const {
    creationDate,
    decisionDate,
    documentStatusId: documentId,
    documentNumber,
    documentStatus,
    determinedBy,
    professionalMisconductOutlets,
    signDate,
    signedByPerson,
    validFrom,
    validTo
  } = record;

  const {
    rowState,
    toggleExtension,
    closeDocumentPreview,
    openDocumentPreview,
    closeReportDialog,
    openReportDialog
  } = useInstitutionTableRow();

  const summingReducer = (total: number, currentValue: number) => total + currentValue;

  const totalRowSpan = professionalMisconductOutlets
    .flatMap((outlet) => outlet.determinedMisconducts)
    .map((misconduct) => misconduct.sanctions.length)
    .reduce(summingReducer, 0);

  const outletRowSpan = ({
    determinedMisconducts: detectedProfessionalMisconducts
  }: ProfessionalMisconductOutlet) =>
    detectedProfessionalMisconducts
      .map((misconduct) => misconduct.sanctions.length)
      .reduce(summingReducer);

  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  professionalMisconductOutlets.forEach((outlet) => {
    outlet.determinedMisconducts.forEach((misconduct, misconductIndex) => {
      misconduct.sanctions.forEach((sanction, sanctionIndex) => {
        rows.push(
          <TableRow key={rowIndex}>
            {rowIndex === 0 && (
              <>
                {columnsDisplayStatus.decisionDate && (
                  <TableCell align="left" rowSpan={totalRowSpan} className={classes.dateColumn}>
                    <ExpandDetailsButton
                      showExtension={rowState.showExtension}
                      onClick={toggleExtension}
                    />
                    {decisionDate}
                  </TableCell>
                )}
                {columnsDisplayStatus.documentNumber && (
                  <TableCell
                    align="left"
                    rowSpan={totalRowSpan}
                    className={classes.validDataColumnWidth}
                  >
                    {documentNumber}
                  </TableCell>
                )}
              </>
            )}
            {misconductIndex === 0 && sanctionIndex === 0 && columnsDisplayStatus.outletName && (
              <TableCell align="left" rowSpan={outletRowSpan(outlet)}>
                <OutletsAsLinksList outlets={[outlet]} virsId={virsId} />
              </TableCell>
            )}
            {sanctionIndex === 0 && columnsDisplayStatus.professionalMisconduct && (
              <TableCell align="left" rowSpan={misconduct.sanctions.length}>
                {misconduct.misconductTypeName}
              </TableCell>
            )}
            {columnsDisplayStatus.sanction && (
              <TableCell align="left">{sanction.sanctionTypeName}</TableCell>
            )}
            {rowIndex === 0 && (
              <>
                {columnsDisplayStatus.validFrom && (
                  <TableCell align="left" rowSpan={totalRowSpan} className={classes.dateColumn}>
                    {validFrom}
                  </TableCell>
                )}
                {columnsDisplayStatus.validTo && (
                  <TableCell align="left" rowSpan={totalRowSpan} className={classes.dateColumn}>
                    {validTo}
                  </TableCell>
                )}
                {columnsDisplayStatus.misconductDeterminedBy && (
                  <TableCell align="left" rowSpan={totalRowSpan}>
                    {determinedBy}
                  </TableCell>
                )}
              </>
            )}
            {rowIndex === 0 && (
              <TableCell rowSpan={totalRowSpan} align="right">
                <ReportErrorAction onClicked={openReportDialog(record.documentStatusId)} />
              </TableCell>
            )}
          </TableRow>
        );
        rowIndex++;
      });
    });
  });

  return (
    <>
      {rowState.documentStatusId && rowState.showReport && (
        <ReportInstitutionDataDialog
          documentStatusId={rowState.documentStatusId}
          close={closeReportDialog}
          openDocumentPreview={() => openDocumentPreview(record.documentStatusId)}
          isOpen
        />
      )}
      {rowState.documentStatusId && rowState.showPreview && (
        <PreviewDocumentDialogMinimal
          maxWidth="lg"
          open={true}
          onClose={closeDocumentPreview}
          documentPath={`virs/legals/documents/${rowState.documentStatusId}`}
        />
      )}
      {record && rows}
      {rowState.showExtension && (
        <TableRowExtension
          creationDate={creationDate}
          documentId={documentId}
          documentStatus={documentStatus.id}
          openDocumentPreview={openDocumentPreview}
          signDate={signDate}
          signedByPerson={signedByPerson}
          authorizedRolesToViewDocumentRow={[
            Roles.ROLE_LRTK_VIEW,
            Roles.ROLE_ZEIT_VIEW,
            Roles.ROLE_VIRS
          ]}
        />
      )}
    </>
  );
};
