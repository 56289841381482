import moment from 'moment';
import {
  PaymentEditRowState,
  PaymentEditRowAction,
  initialPaymentEditRowState,
  initialPaymentFilesState,
  PaymentRecordState
} from './paymentInitialStateAndTypes';
import {
  validateEditPaymentRecordState,
  setCustomNameByExistingOrNull,
  setPaymentAmountAndValidate,
  addFileAndValidateDocuments,
  removeFileAndValidateDocuments,
  removeExistingDocumentAndValidateDocuments,
  setPaymentVirsAndUpdateOptions
} from './paymentRowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateMaxDates,
  updateMinDates,
  updateDateValue,
  setDocumentNumberAndValidate,
  validateMandatoryDate,
  validateOptionalDateImproved
} from '../../../../utils/InputValueFunctions';

export const paymentEditRowReducer = (
  state: PaymentEditRowState,
  action: PaymentEditRowAction
): PaymentEditRowState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return {
        ...state,
        editingOn: true,
        virs: {
          ...state.virs,
          value: {
            ...state.virs.value,
            virId: action.record.virId,
            personFullName: action.record.virsName,
            personCode: String(action.record.virsLegalCode),
            outlets: action.record.outlets,
            virsLegalRegistrationDate: '',
            virsLegalDeregistrationDate: ''
          },
          validated: true
        },
        decisionDate: {
          ...state.decisionDate,
          value: action.record.decisionDate ? moment(action.record.decisionDate) : null,
          minDates: [MINIMAL_RECORD_DATE_DEFAULT],
          maxDates: [MAXIMAL_RECORD_DATE_TODAY]
        },
        customDocumentsName: setCustomNameByExistingOrNull(
          action.record.paymentDocuments,
          state.customDocumentsName
        ),
        paymentDocuments: action.record.paymentDocuments,
        paymentFiles: initialPaymentFilesState,
        paymentOutlets: {
          ...state.paymentOutlets,
          values: action.record.paymentOutlets,
          validated: true,
          options: [action.fictitiousOutlet[0], ...action.record.outlets]
        },
        paymentAmount: {
          ...state.paymentAmount,
          value: String(action.record.paymentAmount),
          validated: true
        },
        validFrom: {
          ...state.validFrom,
          value: action.record.validFrom ? moment(action.record.validFrom) : null,
          validated: true,
          minDates: [MINIMAL_RECORD_DATE_DEFAULT],
          maxDates: [
            {
              id: 'validTo',
              date: action.record.validTo ? moment(action.record.validTo) : null,
              text: 'Data negali būti vėlesnė už įrašo galiojimo pabaigos datą'
            }
          ]
        },
        validTo: {
          ...state.validTo,
          value: action.record.validTo ? moment(action.record.validTo) : null,
          validated: true,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'validFrom',
              date: moment(action.record.validFrom),
              text: 'Data negali būti ankstesnė už įrašo galiojimo pradžios datą'
            }
          ],
          maxDates: []
        }
      };
    case 'EDITING_CANCELLED':
      return initialPaymentEditRowState;
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return {
        ...state,
        ...setPaymentVirsAndUpdateOptions(state as unknown as PaymentRecordState, action.virs),
        showFindVirsDialog: false
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'DECISION_DATE_CHANGED':
      return {
        ...state,
        decisionDate: updateDateValue(action.decisionDate, state.decisionDate),
        validFrom: updateMinDates(action.decisionDate, 'decisionDate', state.validFrom)
      };
    case 'PAYMENT_FILE_ADDED':
      return {
        ...state,
        paymentFiles: addFileAndValidateDocuments(
          action.file,
          state.paymentFiles,
          state.paymentDocuments.length
        )
      };
    case 'PAYMENT_FILE_REMOVED':
      return {
        ...state,
        paymentFiles: removeFileAndValidateDocuments(
          action.fileId,
          state.paymentFiles,
          state.paymentDocuments.length
        )
      };
    case 'FILE_ADDING_WRONG_FORMAT':
      return {
        ...state,
        paymentFiles: {
          ...state.paymentFiles,
          error: true,
          errorMessage: action.errorMessage
        }
      };
    case 'PAYMENT_FILES_CUSTOM_NAME_CHANGED':
      return {
        ...state,
        customDocumentsName: setDocumentNumberAndValidate(
          action.filesCustomName,
          state.customDocumentsName
        )
      };
    case 'PAYMENT_DOCUMENT_REMOVED':
      return removeExistingDocumentAndValidateDocuments(action.paymentDocumentId, state);
    case 'OUTLETS_CHANGED':
      return {
        ...state,
        paymentOutlets: {
          ...state.paymentOutlets,
          values: action.outlets,
          validated: action.outlets.length > 0,
          error: !(action.outlets.length > 0),
          helperText: action.outlets.length > 0 ? '' : 'Būtinos VIP'
        }
      };
    case 'PAYMENT_AMOUNT_CHANGED':
      return {
        ...state,
        paymentAmount: setPaymentAmountAndValidate(action.paymentAmount)
      };
    case 'VALID_FROM_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        decisionDate: updateMaxDates(action.validFrom, 'validFrom', state.decisionDate),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'VALID_TO_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'UPDATE_RECORD_CLICKED':
      return validateEditPaymentRecordState(state);
    case 'CLOSE_RECORD_CLICKED':
      return {
        ...state,
        validTo: action.isRequired
          ? validateMandatoryDate(state.validTo)
          : validateOptionalDateImproved(state.validTo)
      };
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },
        annulRecordConfirmationOn: true
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        annulRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
