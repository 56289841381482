import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { LinkRouter } from '../../../../components/Router/LinkRouter';
import BtnOpenNewDoc from '../../../../components/TableButtons/BtnOpenNewDoc';
import { ApplicationState } from '../../../../store';
import { ShareholdersHistory } from '../../../../store/shareholders/shareholdersTypes';
import { getSubsystem } from '../../../../utils/roleHelper';
import { getPercentageValue } from '../../utilityFunctions/sharedFunctions';

const useStyles = makeStyles((theme) => ({
  detailsPopper: {
    flexDirection: 'row'
  },
  primaryColorText: {
    color: theme.palette.text.primary
  }
}));

interface Props {
  events: ShareholdersHistory[];
  handleDateClick: (date: string) => void;
  className: string;
  parentShareTypeName: string | null;
  parentMayHaveSharePercentage: boolean;
  parentMayHaveVotePercentage: boolean;
}

export const DateDetailsPopover: React.FC<Props> = ({
  events,
  handleDateClick,
  className,
  parentShareTypeName,
  parentMayHaveSharePercentage,
  parentMayHaveVotePercentage
}) => {
  const classes = useStyles();

  const { currentUser, selectedVirsId } = useSelector((state: ApplicationState) => state.virsis);

  const isUserVirsis = getSubsystem(currentUser) === 'VIRSIS';

  return (
    <div className={className}>
      {events.map((event) => {
        return (
          <div
            key={`${event.eventId}${event.sharePercentage}`}
            className={`flex-container ${classes}`}
          >
            <LinkRouter
              path={
                isUserVirsis
                  ? '/duomenu-perziura-ir-teikimas/dalyviai/konkreti-data'
                  : `/virs-duomenys/perziura/${selectedVirsId}/duomenų-teikimas/dalyviai/konkreti-data`
              }
            >
              <BtnOpenNewDoc
                text={event.eventDate}
                onClicked={() => handleDateClick(event.eventDate)}
              />
            </LinkRouter>
            <Typography variant="h6" className={classes.primaryColorText}>
              {getPercentageValue(
                event.votesOnRules,
                event.sharePercentage,
                event.votePercentage,
                parentMayHaveSharePercentage,
                parentMayHaveVotePercentage,
                parentShareTypeName
              )}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
