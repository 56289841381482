import { TableExportDefinition } from '../../../../utils/exporters/types';
import {
  buildMappedEventColumn,
  getValueAfterDate,
  getValueBeforeDate,
  mapCellStyle,
  ShareholderExportRowData,
  ShareholderExportSettings,
  textBoolean
} from '../../utilityFunctions/shareholderExportMapping';
import { ShareholderGroupPerson } from './tableInitialStateAndTypes';

export const formatDateLT = (date: string) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const newDate = new Date(date);
  return newDate.toLocaleDateString('lt-LT', options);
};

export const formPersonsList = (
  isGroup: boolean,
  shareholderPersonsList: ShareholderGroupPerson[],
  shareholderPerson: ShareholderGroupPerson[]
) =>
  isGroup
    ? shareholderPersonsList
        .concat(shareholderPerson)
        .sort((person1, person2) =>
          person1.personType.substring(1).localeCompare(person2.personType.substring(1))
        )
    : shareholderPerson;

export function getTableExportDefinition(
  settings: ShareholderExportSettings
): TableExportDefinition<ShareholderExportRowData> {
  const [mappedEventColumn] = buildMappedEventColumn(settings);

  return {
    title: 'Dalyviai',
    columnGroups: [
      {
        columns: [
          {
            header: 'Lygis',
            cellMapper: (value) => [{ values: [`${value.level}`], style: mapCellStyle(value) }],
            identifier: 'level',
            visible: true
          },
          {
            header: 'Pavadinimas',
            cellMapper: (value) => [
              {
                values: value.name,
                style: mapCellStyle(value)
              }
            ],
            identifier: 'name',
            visible: true,
            style: { width: 60 }
          }
        ]
      },
      {
        header: 'Dalyvavimo',
        columns: [
          mappedEventColumn(
            'Pradžia',
            'start',
            true,
            ({ start }) => textBoolean(start),
            getValueAfterDate
          ),
          mappedEventColumn(
            'Pabaiga',
            'end',
            true,
            ({ end }) => textBoolean(end),
            getValueAfterDate
          )
        ]
      },
      {
        header: 'Įnašų / akcijų dalis',
        columns: [
          mappedEventColumn(
            'Prieš',
            'shareBefore',
            settings.showShares,
            ({ shareholderEvent }) => shareholderEvent?.sharePercentage?.toString(),
            getValueBeforeDate
          ),
          mappedEventColumn(
            'Po',
            'shareAfter',
            settings.showShares,
            ({ shareholderEvent }) => shareholderEvent?.sharePercentage?.toString(),
            getValueAfterDate
          )
        ]
      },
      {
        header: 'Balsų dalis',
        columns: [
          mappedEventColumn(
            'Prieš',
            'voteBefore',
            settings.showVotes,
            ({ shareholderEvent }) => shareholderEvent?.votePercentage?.toString(),
            getValueBeforeDate
          ),
          mappedEventColumn(
            'Po',
            'voteAfter',
            settings.showVotes,
            ({ shareholderEvent }) => shareholderEvent?.votePercentage?.toString(),
            getValueAfterDate
          )
        ]
      }
    ]
  };
}
