import {
  InputStateGeneric,
  DropdownMultipleStateGeneric,
  VirsisFile,
  DatePickerState,
  VirsInputState,
  VirsisFilesState
} from '../../../../utils/tableTypes';
import {
  PaymentEditRowState,
  PaymentNewRowState,
  PaymentRecordState
} from './paymentInitialStateAndTypes';
import { PaymentDocumentData } from '../../../../store/payments/paymentsTypes';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import {
  validateDocumentNumber,
  validatedSelectedVirs,
  validateMandatoryDate,
  validateOptionalDateImproved
} from '../../../../utils/InputValueFunctions';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';

export function setPaymentVirsAndUpdateOptions(
  state: PaymentRecordState,
  virs: VirsSearchData
): PaymentRecordState {
  return {
    ...state,
    virs: {
      ...state.virs,
      value: virs,
      error: false
    },
    paymentOutlets: {
      ...state.paymentOutlets,
      values: virs.outlets.length === 0 ? [state.paymentOutlets.options[0]] : [],
      options: [state.paymentOutlets.options[0], ...virs.outlets]
    }
  };
}

export function setCustomNameByExistingOrNull(
  documents: PaymentDocumentData[],
  state: InputStateGeneric<string>
): InputStateGeneric<string> {
  if (!documents.length) {
    return state;
  }
  return { ...state, value: documents[0].documentNumber, validated: true };
}

export function setPaymentAmountAndValidate(
  paymentAmount: string | null
): InputStateGeneric<string> {
  if (!paymentAmount) {
    return {
      value: paymentAmount,
      id: 'paymentAmount',
      error: true,
      helperText: 'Privalomas skaičius',
      placeholder: '',
      validated: false
    };
  }
  const paymentAmountParsedToNumber = Number(paymentAmount);
  if (paymentAmountParsedToNumber === 0) {
    return {
      value: paymentAmount,
      id: 'paymentAmount',
      error: false,
      helperText: '',
      placeholder: '',
      validated: true
    };
  }
  if (!paymentAmountParsedToNumber) {
    return {
      value: paymentAmount,
      id: 'paymentAmount',
      error: true,
      helperText: '0 <= x <= 9999999999999.99',
      placeholder: '',
      validated: false
    };
  }
  if (paymentAmountParsedToNumber >= 0 && paymentAmountParsedToNumber <= 9999999999999.99) {
    return {
      value: paymentAmount,
      id: 'paymentAmount',
      error: false,
      helperText: '',
      placeholder: '',
      validated: true
    };
  }
  return {
    value: paymentAmount,
    id: 'paymentAmount',
    error: true,
    helperText: '0 <= x <= 9999999999999.99',
    placeholder: '',
    validated: false
  };
}

export function validatePaymentAmount(
  paymentAmount: InputStateGeneric<string>
): InputStateGeneric<string> {
  if (!paymentAmount.value) {
    return {
      value: null,
      id: 'paymentAmount',
      error: true,
      helperText: '0 <= x <= 9999999999999.99',
      placeholder: '',
      validated: false
    };
  }
  return paymentAmount;
}

function validatePaymentOutlets(
  paymentOutlets: DropdownMultipleStateGeneric<OutletShortData>
): DropdownMultipleStateGeneric<OutletShortData> {
  return paymentOutlets.values.length === 0
    ? {
        ...paymentOutlets,
        error: true,
        helperText: 'Būtinos VIP',
        validated: false
      }
    : {
        ...paymentOutlets,
        error: false,
        helperText: '',
        validated: true
      };
}

export function addFileAndValidateDocuments(
  file: File,
  state: VirsisFilesState,
  documentsCount: number
) {
  const filesCountWithinLimit: boolean =
    state.files.length + documentsCount + 1 <= state.filesLimit;
  const allFilesValid: boolean =
    state.files.every((f) => f.size <= state.sizeLimit) &&
    file.size <= state.sizeLimit &&
    filesCountWithinLimit;
  return {
    ...state,
    files: [
      ...state.files,
      {
        file,
        title: file.name,
        size: file.size,
        id: state.files.length + 1
      }
    ],
    error: !allFilesValid,
    validated: allFilesValid,
    errorMessage: !filesCountWithinLimit ? 'Daugiausia 5 dokumentai' : ''
  };
}

export function removeFileAndValidateDocuments(
  fileId: number,
  state: VirsisFilesState,
  documentsCount: number
) {
  const filesUpdated = state.files.filter((file) => file.id !== fileId);
  const filesCountWithinLimit: boolean = filesUpdated.length + documentsCount <= state.filesLimit;
  const allDocumentsValid: boolean =
    filesUpdated.every((f) => f.size <= state.sizeLimit) && filesCountWithinLimit;
  return {
    ...state,
    files: filesUpdated,
    error: !allDocumentsValid,
    validated: allDocumentsValid,
    errorMessage: !filesCountWithinLimit ? 'Daugiausia 5 dokumentai' : ''
  };
}

export function removeExistingDocumentAndValidateDocuments(
  paymentDocumentId: number,
  state: PaymentEditRowState
): PaymentEditRowState {
  const withoutDocument = state.paymentDocuments.filter(
    (doc) => doc.paymentDocumentId !== paymentDocumentId
  );
  const filesCountWithinLimit: boolean =
    withoutDocument.length + state.paymentFiles.files.length <= state.paymentFiles.filesLimit;
  const allDocumentsValid: boolean =
    state.paymentFiles.files.every((f) => f.size <= state.paymentFiles.sizeLimit) &&
    filesCountWithinLimit;
  return {
    ...state,
    paymentDocuments: withoutDocument,
    paymentFiles: {
      ...state.paymentFiles,
      error: !allDocumentsValid,
      validated: allDocumentsValid,
      errorMessage: !filesCountWithinLimit ? 'Daugiausia 5 dokumentai' : ''
    }
  };
}

function validatePaymentFiles(state: PaymentRecordState): VirsisFilesState {
  const atLeastOneDoc: boolean =
    state.paymentFiles.files.length + state.paymentDocuments.length > 0;
  return {
    ...state.paymentFiles,
    error: !atLeastOneDoc,
    validated: atLeastOneDoc,
    errorMessage: !atLeastOneDoc ? 'Būtinas bent 1 dokumentas' : ''
  };
}

export function validateNewPaymentRecordState(state: PaymentNewRowState): PaymentNewRowState {
  const virsValidated: VirsInputState = validatedSelectedVirs(state.virs);

  const decisionDateValidated: DatePickerState = validateMandatoryDate(state.decisionDate);
  const validatedCustomDocumentsName: InputStateGeneric<string> = validateDocumentNumber(
    state.customDocumentsName
  );
  const paymentFilesValidated: VirsisFilesState = validatePaymentFiles(state);
  const paymentOutletsValidated: DropdownMultipleStateGeneric<OutletShortData> =
    validatePaymentOutlets(state.paymentOutlets);
  const paymentAmountValidated: InputStateGeneric<string> = validatePaymentAmount(
    state.paymentAmount
  );
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateOptionalDateImproved(state.validTo);

  return {
    ...state,
    virs: virsValidated,
    decisionDate: decisionDateValidated,
    customDocumentsName: validatedCustomDocumentsName,
    paymentFiles: paymentFilesValidated,
    paymentOutlets: paymentOutletsValidated,
    paymentAmount: paymentAmountValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    createRecordConfirmationOn:
      virsValidated.validated &&
      decisionDateValidated.validated &&
      validatedCustomDocumentsName.validated &&
      paymentFilesValidated.validated &&
      paymentOutletsValidated.validated &&
      paymentAmountValidated.validated &&
      validFromValidated.validated &&
      validToValidated.validated
  };
}

export function validateEditPaymentRecordState(state: PaymentEditRowState): PaymentEditRowState {
  const virsValidated: VirsInputState = validatedSelectedVirs(state.virs);
  const decisionDateValidated: DatePickerState = validateMandatoryDate(state.decisionDate);
  const validatedCustomDocumentsName: InputStateGeneric<string> = validateDocumentNumber(
    state.customDocumentsName
  );
  const paymentFilesValidated: VirsisFilesState = validatePaymentFiles(state);
  const paymentOutletsValidated: DropdownMultipleStateGeneric<OutletShortData> =
    validatePaymentOutlets(state.paymentOutlets);
  const paymentAmountValidated: InputStateGeneric<string> = validatePaymentAmount(
    state.paymentAmount
  );
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateOptionalDateImproved(state.validTo);
  return {
    ...state,
    virs: virsValidated,
    decisionDate: decisionDateValidated,
    customDocumentsName: validatedCustomDocumentsName,
    paymentFiles: paymentFilesValidated,
    paymentOutlets: paymentOutletsValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    updateRecordConfirmationOn:
      virsValidated.validated &&
      decisionDateValidated.validated &&
      validatedCustomDocumentsName.validated &&
      paymentFilesValidated.validated &&
      paymentOutletsValidated.validated &&
      paymentAmountValidated.validated &&
      validFromValidated.validated &&
      validToValidated.validated
  };
}

export function joinPaymentIntoFormData(
  data: Record<string, any> | undefined,
  virsisFiles: VirsisFile[]
): FormData {
  const form = new FormData();
  if (virsisFiles) {
    virsisFiles.forEach((virsisFile) => {
      form.append('files', virsisFile.file);
    });
  }
  if (data) {
    form.append('record', JSON.stringify(data));
  }
  return form;
}

export function validateEditedValidTo(
  state: PaymentEditRowState,
  isRequired = true
): PaymentEditRowState {
  const toDateValidated: DatePickerState = isRequired
    ? validateMandatoryDate(state.validTo)
    : validateOptionalDateImproved(state.validTo);
  return {
    ...state,
    validTo: toDateValidated,
    updateRecordConfirmationOn: toDateValidated.validated
  };
}
