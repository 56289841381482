import { axiosDelete, get, getBlob, getWithQuery, post, put } from '../../utils/axiosApi';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { FundsReceivedRecord } from './fundsReceivedDataTypes';
import { FundsReceivedDataPageRequest } from '../fundsReceivedTable/fundsReceivedDataTableTypes';

export const getFundsReceivedDataVirs = (virsId: number) =>
  get(`/virs/${virsId}/institutions-data/funds-received`);

// export const getFundsReceivedDataLegal = () => get('/funds-received/legal');
export const getFundsReceivedDataLegal = (query: FundsReceivedDataPageRequest) =>
  getWithQuery('/funds-received/legal', query);

export const getFundsReceivedDocument = (fundsReceivedId: number) =>
  getBlob(`/funds-received/${fundsReceivedId}`);

export const postCreateFundsReceived = (record: FundsReceivedRecord) => {
  return post('/funds-received/', record);
};

export const putUpdateFundsReceived = (record: FundsReceivedRecord, fundsReceivedId: number) => {
  return put(`/funds-received/${fundsReceivedId}`, record);
};

export const deleteRemoveFundsReceived = (fundsReceivedId: number) => {
  return axiosDelete(`/funds-received/${fundsReceivedId}`);
};

export const annulFundsReceivedData = (documentStatusId: number, annulRecord: AnnulRecord) =>
  put(`/provided-data/inst/${documentStatusId}`, annulRecord);

export const getFundsReceivedRecordByDocId = (docId: number) =>
  get(`/funds-received/inst/${docId}`);
