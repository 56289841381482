import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Divider, Button, DialogContent, Dialog, Box } from '@material-ui/core';

import { ApplicationState } from '../../store';
import { acceptAgreementRequest, exitAgreementDialog } from '../../store/virsis/actions';
import { PdfPreviewScrollable } from '../PdfPreview/PdfPreview';
import { CloseDialogButton } from './Dialog/CloseButton';

const AgreementDialog: React.FC = () => {
  const virsisState = useSelector((state: ApplicationState) => state.virsis);
  const dispatch = useDispatch();
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const handleClose = () => dispatch(exitAgreementDialog());

  const handleAgreeAndNext = () => {
    if (virsisState.currentUser && virsisState.agreementFile) {
      dispatch(acceptAgreementRequest(virsisState.currentUser.personId));
    }
  };

  const [documentLoading, setDocumentLoading] = useState<boolean>(true);

  const handleScroll = (e: any) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1 && !documentLoading) {
      setDisabledNextButton(false);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={virsisState.agreementDialogOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
      >
        <div className="dialog-title">
          <Typography variant="h2">Naudojimosi taisyklės</Typography>
        </div>

        <Divider light style={{ margin: '0px 35px' }} />

        <DialogContent onScroll={handleScroll}>
          <div className="dialog-main-content" style={{ paddingRight: 0, paddingLeft: 0 }}>
            <PdfPreviewScrollable onDocumentLoad={() => setDocumentLoading(false)} />
          </div>
        </DialogContent>

        <div
          className="dialog-actions"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box>
            <CloseDialogButton label="Nesutinku" onClick={handleClose} />

            <Button
              variant="contained"
              color="primary"
              disabled={disabledNextButton}
              onClick={handleAgreeAndNext}
            >
              <Typography variant="h5">Sutinku su naudojimosi taisyklėmis</Typography>
            </Button>
          </Box>
        </div>
      </Dialog>
    </div>
  );
};

export default AgreementDialog;
