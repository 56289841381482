import React from 'react';
import { TextField, TableCell, makeStyles } from '@material-ui/core';
import BtnOpenNewDoc from '../TableButtons/BtnOpenNewDoc';
import { VirsInputState } from '../../utils/tableTypes';
import theme from '../../style/virsisTheme';

interface Props {
  openSearchDialog: () => void;
  state: VirsInputState;
  rowSpan?: number;
  openButtonText?: string;
}

export const VirsInputCell: React.FC<Props> = ({
  openSearchDialog,
  state,
  rowSpan,
  openButtonText
}) => {
  const classes = makeStyles({
    input: {
      color: '#686868 !important'
    }
  })();
  return (
    <TableCell
      align="left"
      rowSpan={rowSpan}
      style={state.error ? { backgroundColor: theme.palette.error.light } : undefined}
    >
      <TextField
        value={state.value ? state.value.personFullName : ''}
        variant="outlined"
        disabled
        InputProps={{
          className: classes.input
        }}
      />
      <div>
        <BtnOpenNewDoc onClicked={openSearchDialog} text={openButtonText || 'Išplėstinė paieška'} />
      </div>
    </TableCell>
  );
};
