import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { TooltipOnTableActionButton } from '../Tooltips/TooltipOnTableActionButton';

interface Props {
  text: string;
  disabled?: boolean;
  helperText: string;
  to: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: 0
    },
    icon: {
      height: 30,
      padding: '5px 10px',
      borderRight: '1px solid white'
    },
    text: {
      color: theme.palette.secondary.light,
      padding: '1px 15px'
    }
  })
);

export const AddNewRecordButtonLink: React.FC<Props> = ({ text, disabled, helperText, to }) => {
  const classes = useStyles();
  return (
    <TooltipOnTableActionButton text={disabled ? helperText : ''}>
      <div>
        <Button
          component={Link}
          to={to}
          target="_blank"
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classes.button}
        >
          <AddIcon fontSize="small" className={classes.icon} />
          <Typography variant="h5" className={classes.text}>
            {text}
          </Typography>
        </Button>
      </div>
    </TooltipOnTableActionButton>
  );
};
