import React from 'react';
import { TableToolsFrame } from '../../components/TableToolsFrame/TableToolsFrame';
import { auditsTableColumns } from './tableState/tableInitialStateAndTypes';
import { useAuditsTableDispatch, useAuditsTableState } from './Context';
import SortByTool from '../../components/TableCustomTools/SortByTool';
import SelectionTool from '../../components/TableCustomTools/SelectionTool';

export const AuditsTableCustomizationBar: React.FC = () => {
  const { dispatch: tableDispatch } = useAuditsTableDispatch();
  const { state: tableState } = useAuditsTableState();

  const handleSetSortByColumn = (column: any) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: any) => {
    tableDispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <>
            <SortByTool
              order={tableState.order}
              sortBy={tableState.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnNames={auditsTableColumns}
            />

            <SelectionTool
              columnNames={auditsTableColumns}
              columnsDisplayStatus={tableState.columnsDisplayStatus}
              onDisplayColumnChecked={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
