import { TableCell } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setSelectedDate,
  addShareholderDateReset
} from '../../../../store/shareholders/shareholdersActions';
import { ErrorProps, ShareholdersProps } from '../../../../store/shareholders/shareholdersTypes';
import { ErrorType } from '../../../../store/virsis/dataTypes';
import { checkIfDateIsAfterOrEqualStartDate } from '../../utilityFunctions/sharedFunctions';
import ColorRowBarContainer from '../Components/ColorRowBarContainer';
import { DateDetailsCellWrapper } from '../Components/DateDetailsCellWrapper';
import { ErrorBg } from '../Components/ErrorBg';
import {
  useShareholdersTableState,
  useShareholdersTableDispatch
} from '../ShareholdersTableWithContext';
import { getEventsHistoryWithChanges } from '../tableState/tableFunctions';

interface Props {
  shareholder: ShareholdersProps;
  index: number;
  date: string;
  allEventsDates: string[];
  parentErrors: ErrorProps[];
  parentMayHaveVotesOnRules: boolean;
  parentShareTypeName: string | null;
  virsEndDate: string | null;
  virsStoppedParticipatingFromDate: string | null;
  parentMayHaveSharePercentage: boolean;
  parentMayHaveVotePercentage: boolean;
}

const ShareholdersChart: React.FC<Props> = ({
  index,
  shareholder,
  date,
  allEventsDates,
  parentErrors,
  parentShareTypeName,
  parentMayHaveSharePercentage,
  parentMayHaveVotePercentage,
  virsEndDate,
  virsStoppedParticipatingFromDate
}) => {
  const {
    state: { showDetailsPopoverDateId, showVotes, showShares, tableId }
  } = useShareholdersTableState();
  const { dispatch: tableDispatch } = useShareholdersTableDispatch();
  const reduxDispatch = useDispatch();
  const parentRef = useRef<HTMLDivElement>(null);
  const [refReady, setRefReady] = useState(false);

  const {
    shareholderId,
    shareholderStartDate,
    shareholderParentStartDate,
    shareholderStoppedParticipatingFromDate,
    shareholderPersons,
    shareholderHistory
  } = shareholder;

  const showDetailsPopoverMemoized = useMemo(
    () => showDetailsPopoverDateId[date] === `${shareholderId + '__' + index}`,
    [showDetailsPopoverDateId, date, shareholderId, index]
  );

  const parentHeight = parentRef.current && parentRef.current.offsetHeight;

  useEffect(() => {
    setRefReady(true);
  }, []);

  const handleChartCellClick = useCallback(() => {
    tableDispatch({
      type: 'SHOW_CELL_DETAILS_POPOVER',
      date,
      id: shareholderId + '__' + index
    });
  }, [shareholderId, date, tableDispatch, index]);

  const handleDateClick = (clickedDate: string) => {
    reduxDispatch(setSelectedDate(clickedDate));
    reduxDispatch(addShareholderDateReset());
  };

  const isLegalPerson =
    shareholderPersons.length > 1
      ? false
      : shareholderPersons.some(
          (person) => person.personType === 'LJA' || person.personType === 'UJA'
        );

  const eventToShowInDetailsPopover = useMemo(() => {
    return getEventsHistoryWithChanges(shareholderHistory, date, tableId);
  }, [shareholderHistory, date, tableId]);

  const isDateAfterOrEqualStartDate = checkIfDateIsAfterOrEqualStartDate(
    shareholderStartDate,
    shareholderParentStartDate,
    date
  );

  const openDetailsPopover =
    showDetailsPopoverMemoized &&
    (!shareholderStoppedParticipatingFromDate || shareholderStoppedParticipatingFromDate > date) &&
    !!eventToShowInDetailsPopover.length;

  const showErrorBackground = parentErrors.find((error) => {
    const errorOnThisDate = error.errorMessages.find(
      (errorMessage) =>
        errorMessage.eventDate === date &&
        (shareholder.shareholderStoppedParticipatingFromDate === null ||
          date < shareholder.shareholderStoppedParticipatingFromDate)
    );

    return (
      error.errorType === ErrorType.CRITICAL &&
      errorOnThisDate &&
      ((error.errorWithVotes && showVotes) || (error.errorWithShares && showShares))
    );
  });

  return (
    <TableCell
      className={showDetailsPopoverMemoized ? 'data-column cell-selected' : 'data-column'}
      onClick={handleChartCellClick}
      ref={parentRef}
    >
      {openDetailsPopover && parentHeight && (
        <DateDetailsCellWrapper
          date={date}
          handleDateClick={handleDateClick}
          open={openDetailsPopover}
          events={eventToShowInDetailsPopover}
          parentHeight={parentHeight}
          parentShareTypeName={parentShareTypeName}
          parentMayHaveSharePercentage={parentMayHaveSharePercentage}
          parentMayHaveVotePercentage={parentMayHaveVotePercentage}
        >
          <div />
        </DateDetailsCellWrapper>
      )}

      {/* klaidos fonas */}
      {refReady && showErrorBackground && isDateAfterOrEqualStartDate && <ErrorBg />}

      {/* juostelės paišomos kiekvienam langeliui/datai */}
      <ColorRowBarContainer
        shareholder={shareholder}
        date={date}
        isLegalPerson={isLegalPerson}
        allEventsDates={allEventsDates}
        virsEndDate={virsEndDate}
        virsStoppedParticipatingFromDate={virsStoppedParticipatingFromDate}
        parentMayHaveVotePercentage={parentMayHaveVotePercentage}
        parentMayHaveSharePercentage={parentMayHaveSharePercentage}
      />
    </TableCell>
  );
};

export default ShareholdersChart;
