import React from 'react';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { ArrayHelpers, FormikHelpers, FormikState } from 'formik';
import { GraphicalRecord } from '../../../store/graphicalRepresentationTools/graphicalRepresentationToolsTypes';
import GraphicalRepresentationRecordRow from './GraphicalRepresentationRecordRow';
import GraphicalRepresentationStaticRecordRow from './GraphicalRepresentationStaticRecordRow';

interface Props {
  formikProps: FormikHelpers<any> & FormikState<{ records: GraphicalRecord[] }>;
  fieldArrayProps: ArrayHelpers;
  setTabRecords: React.Dispatch<React.SetStateAction<GraphicalRecord[]>>;
}

const GraphicalRepresentationSortablePanelContext: React.FC<Props> = ({
  formikProps,
  fieldArrayProps,
  setTabRecords
}) => {
  const {
    values: { records }
  } = formikProps;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over !== null && active.id !== over.id) {
      setTabRecords((record) => {
        const oldIndex = record.map((i) => i.rowNr).indexOf(active.id);
        const newIndex = record.map((i) => i.rowNr).indexOf(over.id);
        return arrayMove(records, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={records.map((item) => item.rowNr)}
        strategy={verticalListSortingStrategy}
      >
        {records.map((record, index) => {
          if (record.staticId !== null) {
            return (
              <GraphicalRepresentationStaticRecordRow
                graphicalRecord={record}
                key={record.rowNr}
                id={record.rowNr}
                index={index}
              />
            );
          }
          return (
            <GraphicalRepresentationRecordRow
              graphicalRecord={record}
              key={record.rowNr}
              id={record.rowNr}
              index={index}
              fieldArrayProps={fieldArrayProps}
              setTabRecords={setTabRecords}
              formikProps={formikProps}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

export default GraphicalRepresentationSortablePanelContext;
