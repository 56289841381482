import React from 'react';
import { LinearProgress, TableCell, TableRow } from '@material-ui/core';

interface Props {
  isLoading: boolean;
  colSpan: number;
}

export const TableLoaderRow: React.FC<Props> = ({ isLoading, colSpan: colspan }) => {
  return (
    <>
      {isLoading && (
        <TableRow>
          <TableCell colSpan={colspan} style={{ padding: 0 }}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
