import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';
import {
  OutletDataTableCustomFilter,
  OutletDataTableColumnsDisplayStatus,
  OutletDataTableField
} from '../../OutletsDataTable/tableState/initialStateAndTypes';

export type OutletDataTableHeader = ColumnHeader<OutletDataTableField>;

export interface OutletMainDataTableState {
  customFilter: OutletDataTableCustomFilter;
  customFilterOn: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  order: SortOrder;
  sortBy: OutletDataTableField;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: OutletDataTableColumnsDisplayStatus;
}

export type OutletMainDataTableAction =
  | {
      type: 'MAIN_OUTLET_CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: OutletDataTableField;
      value: string | null;
    }
  | { type: 'MAIN_OUTLET_SORTING_CHANGED'; sortBy: OutletDataTableField }
  | {
      type: 'MAIN_OUTLET_COLUMN_DISPLAY_TOGGLED';
      column: OutletDataTableField;
    };

export const initialOutletMainDataTableState: OutletMainDataTableState = {
  customFilter: {
    outletName: [],
    outletTypeName: [],
    outletGroupName: [],
    establishedDate: [],
    controlledFrom: [],
    controlledTo: [],
    internationalNumber: []
  },
  customFilterOn: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: true,
  order: 'asc',
  sortBy: 'outletName',
  page: 0,
  rowsPerPage: 20,
  columnsDisplayStatus: {
    outletName: true,
    outletTypeName: true,
    outletGroupName: true,
    establishedDate: true,
    controlledFrom: true,
    controlledTo: true,
    internationalNumber: true
  }
};
