import moment from 'moment';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  updateMaxDates,
  updateMinDates,
  validateSingleChoice
} from '../../../../utils/InputValueFunctions';
import {
  EnterpriseEditRecordState,
  initialEnterpriseRecordState,
  EnterpriseDataRowAction
} from './rowInitialStateAndTypes';
import { validateEditedEnterpriseRecord, validateEditedValidTo } from './rowReducerFunctions';

export const editRnterpriseRecordReducer = (
  state: EnterpriseEditRecordState,
  action: EnterpriseDataRowAction
): EnterpriseEditRecordState => {
  switch (action.type) {
    case 'INITIALIZE_RECORD_STATE':
      return {
        ...state,
        ...initialEnterpriseRecordState,
        enterpriseType: {
          ...initialEnterpriseRecordState.enterpriseType,
          value: {
            enterpriseTypeId: action.record.enterpriseTypeId,
            enterpriseTypeName: action.record.enterpriseTypeName
          },
          options: action.enterpriseTypes,
          validated: true
        },
        validFrom: {
          ...state.validFrom,
          value: moment(action.record.validFrom),
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.legalRegistrationDate),
              text: 'Data negali būti ankstesnė už įregistravimo datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date:
                action.legalDeregistrationDate !== null
                  ? moment(action.legalDeregistrationDate)
                  : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo datą'
            },
            {
              id: 'validTo',
              date: action.record.validTo ? moment(action.record.validTo) : null,
              text: 'Data negali būti vėlesnė už pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          value: action.record.validTo ? moment(action.record.validTo) : null,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.legalRegistrationDate),
              text: 'Data negali būti ankstesnė už įregistravimo datą'
            },
            {
              id: 'validFrom',
              date: moment(action.record.validFrom),
              text: 'Data negali būti vėlesnė už įrašo galiojimo pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date:
                action.legalDeregistrationDate !== null
                  ? moment(action.legalDeregistrationDate)
                  : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'TOGGLE_EDITING_RECORD':
      return { ...state, editingOn: !state.editingOn };
    case 'ENTERPRISE_TYPE_CHANGED':
      return {
        ...state,
        enterpriseType: validateSingleChoice(
          { ...state.enterpriseType, value: action.enterpriseType },
          'Reikia pasirinkti iš sąrašo'
        )
      };
    case 'VALID_FROM_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'VALID_TO_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'SHOW_UPDATE_RECORD_CONFIRMATION_OR_ERRORS':
      return validateEditedEnterpriseRecord(state);
    case 'SHOW_UPDATE_DATE_CONFIRMATION_OR_ERRORS':
      return validateEditedValidTo(state);
    case 'DISPLAY_REMOVE_RECORD_CONFIRMATION':
      return {
        ...state,
        removeRecordConfirmationOn: action.status
      };
    case 'RESET_RECORD_STATE':
      return {
        ...state,
        ...initialEnterpriseRecordState
      };
    default:
      return state;
  }
};
