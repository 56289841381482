import React from 'react';
import { TableCell } from '@material-ui/core';
import { EnterpriseTableRowExtension } from './RowExtension';
import { EnterpriseDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { ExpandDetailsButton } from '../../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import { EditAndRemoveActions } from '../../../components/TableRowActions/EditAndRemoveActions';
import { RowActionButtonGeneric } from '../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { RowActionButton } from '../../../components/TableRowActions/RowActionButton';
import { EnterpriseEditRecordState } from './rowState/rowInitialStateAndTypes';
import { determineRecordStatus } from '../../../utils/tableDataFunctions';
import { TooltipOnRowRibbon } from '../../../components/Tooltips/TooltipOnRowRibbon';
import { CustomEditIconButton } from '../../../components/Icons/IconButtons/CustomEditIconButton';
import { Box } from '@material-ui/core/';

interface Props {
  record: EnterpriseData;
  rowState: EnterpriseEditRecordState;
  columnsToDisplay: EnterpriseDataTableColumnsDisplayStatus;
  colSpanForExtensionRow: number;
  toggleExtension: () => void;
  isExtended: boolean;
  toggleEditing: () => void;
  handleRemoveRecordButtonClick: () => void;
  removeEnterprise: () => void;
  closeRemoveRecordConfirmation: () => void;
  removingEnterprise: boolean;
  isEnterpriseRemoved: boolean;
  removingEnterpriseError: Error | undefined;
  onRemoveError: () => void;
  unsetValidToDate: () => void;
  updatingEnterprise: boolean;
}

export const EnterpriseTableRowEditOff: React.FC<Props> = ({
  record,
  rowState,
  columnsToDisplay,
  colSpanForExtensionRow,
  toggleExtension,
  isExtended,
  toggleEditing,
  handleRemoveRecordButtonClick,
  removeEnterprise,
  closeRemoveRecordConfirmation,
  removingEnterprise,
  isEnterpriseRemoved,
  removingEnterpriseError,
  onRemoveError,
  unsetValidToDate,
  updatingEnterprise
}) => {
  const recordStatus = determineRecordStatus(record);

  return (
    <>
      <RowStyleOnDifferentStatus id={`row-${record.enterpriseId}`} status={recordStatus}>
        {columnsToDisplay.enterpriseTypeName && (
          <TooltipOnRowRibbon status={recordStatus}>
            <TableCell>
              {record.relevancyStatus !== null && (
                <ExpandDetailsButton onClick={toggleExtension} showExtension={isExtended} />
              )}
              {record.enterpriseTypeName}
            </TableCell>
          </TooltipOnRowRibbon>
        )}

        {columnsToDisplay.validFrom && <TableCell>{record.validFrom}</TableCell>}
        {columnsToDisplay.validTo && (
          <TooltipOnRowRibbon
            status={record.relevancyStatus === 'A' && record.validTo ? 'RUOSIAMAS' : undefined}
          >
            <TableCell
              style={
                record.relevancyStatus === 'A' && record.validTo
                  ? { boxShadow: '8px 0 0 #50C9F3 inset' }
                  : undefined
              }
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={record.validTo ? 'space-between' : 'flex-end'}
                alignItems="center"
              >
                {record.validTo && record.validTo}
                <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                  {(record.editableValidToOnly || record.editable) && (
                    <CustomEditIconButton onClick={toggleEditing} />
                  )}
                </AllowedTo>
              </Box>
            </TableCell>
          </TooltipOnRowRibbon>
        )}
        {columnsToDisplay.authorName && <TableCell>{record.authorName}</TableCell>}
        <TableCell style={{ width: 130 }} align="right">
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            {record.editable && (
              <EditAndRemoveActions
                handleEditButtonClick={toggleEditing}
                handleRemoveButtonClick={handleRemoveRecordButtonClick}
                confirmationOn={rowState.removeRecordConfirmationOn}
                handleConfirmationYes={removeEnterprise}
                handleConfirmationNo={closeRemoveRecordConfirmation}
                isProcessing={removingEnterprise}
                isSuccess={isEnterpriseRemoved}
                error={removingEnterpriseError}
                onErrorClose={onRemoveError}
              />
            )}
            {record.editableValidToOnly && !record.validTo && (
              <RowActionButtonGeneric
                label="Įvesti pabaigos datą"
                onButtonClicked={toggleEditing}
              />
            )}
            {record.editableValidToOnly && record.validTo && (
              <>
                <RowActionButton
                  label="Išvalyti"
                  onButtonClick={unsetValidToDate}
                  isProcessing={updatingEnterprise}
                />
              </>
            )}
          </AllowedTo>
        </TableCell>
      </RowStyleOnDifferentStatus>
      {isExtended && (
        <EnterpriseTableRowExtension
          colSpan={colSpanForExtensionRow}
          record={record}
          recordStatus={recordStatus}
        />
      )}
    </>
  );
};
