import { takeLatest } from '@redux-saga/core/effects';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  unsignedDataRemoveError,
  unsignedDataRemoveInst,
  unsignedDataRemoveLegal,
  unsignedDataRemoveOutlet,
  unsignedDataRemoveSuccess,
  unsignedDataRemoveVirs
} from './unsignedDataActions';
import {
  deleteInstUnsigned,
  deleteLegalUnsigned,
  deleteOutletUnsigned,
  deleteVirsUnsigned
} from './unsignedDataApi';
import {
  UNSIGNED_DATA_REMOVE_REQUEST_INST,
  UNSIGNED_DATA_REMOVE_REQUEST_LEGAL,
  UNSIGNED_DATA_REMOVE_REQUEST_OUTLET,
  UNSIGNED_DATA_REMOVE_REQUEST_VIRS
} from './unsignedDataTypes';

function* unsignedDataRemoveInstTask(action: ActionType<typeof unsignedDataRemoveInst>) {
  try {
    yield call(deleteInstUnsigned, action.payload.documentType);
    yield put(unsignedDataRemoveSuccess());
    yield put(sendMessage('success', 'Nepasirašyti įrašai pašalinti'));
  } catch (err) {
    yield put(unsignedDataRemoveError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* unsignedDataRemoveLegalTask(action: ActionType<typeof unsignedDataRemoveLegal>) {
  try {
    yield call(deleteLegalUnsigned, action.payload.documentType);
    yield put(unsignedDataRemoveSuccess());
    yield put(sendMessage('success', 'Nepasirašyti įrašai pašalinti'));
  } catch (err) {
    yield put(unsignedDataRemoveError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* unsignedDataRemoveOutletTask(action: ActionType<typeof unsignedDataRemoveOutlet>) {
  try {
    yield call(deleteOutletUnsigned, action.payload.outledId, action.payload.documentType);
    yield put(unsignedDataRemoveSuccess());
    yield put(sendMessage('success', 'Nepasirašyti įrašai pašalinti'));
  } catch (err) {
    yield put(unsignedDataRemoveError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* unsignedDataRemoveVirsTask(action: ActionType<typeof unsignedDataRemoveVirs>) {
  try {
    yield call(deleteVirsUnsigned, action.payload.virsId, action.payload.documentType);
    yield put(unsignedDataRemoveSuccess());
    yield put(sendMessage('success', 'Nepasirašyti įrašai pašalinti'));
  } catch (err) {
    yield put(unsignedDataRemoveError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

export function* unsignedDataSaga() {
  yield takeLatest(UNSIGNED_DATA_REMOVE_REQUEST_INST, unsignedDataRemoveInstTask);
  yield takeLatest(UNSIGNED_DATA_REMOVE_REQUEST_LEGAL, unsignedDataRemoveLegalTask);
  yield takeLatest(UNSIGNED_DATA_REMOVE_REQUEST_OUTLET, unsignedDataRemoveOutletTask);
  yield takeLatest(UNSIGNED_DATA_REMOVE_REQUEST_VIRS, unsignedDataRemoveVirsTask);
}
