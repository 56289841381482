import React, { useState } from 'react';
import { Menu, MenuItem, Tab, TabProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { HeaderMenuField } from '../../pages/pages';

interface Props {
  subMenu: HeaderMenuField[] | undefined;
}

type PropsType = RouteComponentProps & TabProps & Props;

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    borderRadius: 0
  }
}));

const TabMenu: React.FC<PropsType> = (props) => {
  const classes = useStyles();

  const { subMenu, history, staticContext, ...tabProps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onTabClick = (event: React.MouseEvent<HTMLElement>) => {
    switch (tabProps.value) {
      case '/':
      case '/pagalba':
      case '/ataskaitos':
      case '/virs-duomenys':
      case '/duomenu-istorija':
      case '/netikslus-duomenys':
        history.push(tabProps.value);
        break;
      case '/duomenu-teikimas':
        if (subMenu && subMenu?.length > 1) {
          setAnchorEl(event.currentTarget);
        } else if (subMenu?.length === 1) {
          const [tabMenuItem] = subMenu;
          history.push(`${tabProps.value}${tabMenuItem.path}`);
        } else {
          history.push(tabProps.value);
        }
        break;
      default:
        setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    handleMenuClose();
    history.push(`${tabProps.value}${item}`);
  };

  return (
    <>
      <Tab {...tabProps} onClick={onTabClick} disableRipple />
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        elevation={0}
        classes={{ paper: classes.headerMenu }}
      >
        {subMenu?.map((i) => (
          <MenuItem key={i.id} onClick={() => handleMenuItemClick(i.path)}>
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default withRouter(TabMenu);
