import React, { useContext, createContext, Dispatch, useReducer } from 'react';
import {
  EthicalNonComplianceTableAction,
  EthicalNonComplianceTableState,
  initialEthicalNonComplianceTableState
} from './tableState/tableInitialStateAndTypes';
import { ethicalNonComplianceTableReducer } from './tableState/tableReducer';
import EthicalNonComplianceTable from './Table';

const EthicalNonComplianceTableStateContext = createContext<{
  state: EthicalNonComplianceTableState;
}>({
  state: initialEthicalNonComplianceTableState
});

const EthicalNonComplianceTableDispatchContext = createContext<{
  dispatch: Dispatch<EthicalNonComplianceTableAction>;
}>({
  dispatch: (action) => action
});

export const EthicalNonComplianceTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(
    ethicalNonComplianceTableReducer,
    initialEthicalNonComplianceTableState
  );
  return (
    <EthicalNonComplianceTableStateContext.Provider value={{ state }}>
      <EthicalNonComplianceTableDispatchContext.Provider value={{ dispatch }}>
        <EthicalNonComplianceTable />
      </EthicalNonComplianceTableDispatchContext.Provider>
    </EthicalNonComplianceTableStateContext.Provider>
  );
};

export function useEthicalNonComplianceTableState() {
  return useContext(EthicalNonComplianceTableStateContext);
}
export function useEthicalNonComplianceTableDispatch() {
  return useContext(EthicalNonComplianceTableDispatchContext);
}
