import { call, takeEvery, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  fetchInaccurateDataMessageError,
  fetchInaccurateDataMessageRequest,
  fetchInaccurateDataMessageSuccess,
  fetchInsitutionsDataReportsError,
  fetchInsitutionsDataReportsSuccess,
  reportInaccurateInstitutionData,
  reportInaccurateInstitutionDataError,
  reportInaccurateInstitutionDataSuccess
} from './institutionDataReportsActions';
import {
  FETCH_INACCURATE_DATA_MESSAGE_REQUEST,
  FETCH_INSTITUTION_DATA_REPORTS_REQUEST,
  REPORT_INSTITUTION_DATA_REQUEST
} from './institutionDataReportsActionTypes';
import {
  getInaccurateDataMessage,
  getInstitutionDataReportData,
  postReportErrorInInstitutionData
} from './institutionDataReportsApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';

function* handleInstitutionDataReportDataFetch() {
  try {
    const { data } = yield call(getInstitutionDataReportData);
    yield put(fetchInsitutionsDataReportsSuccess(data.institutionDataReports));
  } catch (error) {
    yield put(fetchInsitutionsDataReportsError(error));
    yield put(sendMessage('error', error.response.data?.message));
  }
}

function* handleReportInstitutionDataSaga(
  action: ActionType<typeof reportInaccurateInstitutionData>
) {
  try {
    yield call(postReportErrorInInstitutionData, action.payload);
    yield put(reportInaccurateInstitutionDataSuccess());
  } catch (err) {
    yield put(reportInaccurateInstitutionDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleReportInaccurateDataMessage(
  action: ActionType<typeof fetchInaccurateDataMessageRequest>
) {
  try {
    const { data } = yield call(getInaccurateDataMessage, action.payload);
    yield put(fetchInaccurateDataMessageSuccess(data));
  } catch (error) {
    yield put(fetchInaccurateDataMessageError(error));
    yield put(sendMessage('error', error.response.data?.message));
  }
}

function* institutionDataReportsSaga() {
  yield takeEvery(FETCH_INSTITUTION_DATA_REPORTS_REQUEST, handleInstitutionDataReportDataFetch);
  yield takeEvery(REPORT_INSTITUTION_DATA_REQUEST, handleReportInstitutionDataSaga);
  yield takeEvery(FETCH_INACCURATE_DATA_MESSAGE_REQUEST, handleReportInaccurateDataMessage);
}

export default institutionDataReportsSaga;
