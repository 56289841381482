import React from 'react';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { Typography, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.info.light}`,
    '&:hover': {
      background: '#fff',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& $icon': {
        color: theme.palette.primary.main
      },
      '& $text': {
        color: theme.palette.primary.main
      }
    },
    '&:focus': {
      background: '#fff',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& $icon': {
        color: theme.palette.primary.main
      },
      '& $text': {
        color: theme.palette.primary.main
      }
    },
    '&:disabled': {
      background: '#fff',
      borderColor: theme.palette.info.light,
      color: theme.palette.info.main,
      '& $icon': {
        color: theme.palette.info.main
      }
    }
  },
  icon: {
    color: theme.palette.info.dark,
    marginRight: 10
  },
  text: {
    color: theme.palette.info.dark,
    fontWeight: 400
  }
}));

export const AddButtonLight: React.FC<Props> = ({ text, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <Button variant="outlined" onClick={onClick} disabled={disabled} className={classes.button}>
      <AddRoundedIcon fontSize="small" className={classes.icon} />
      <Typography variant="h5" className={classes.text}>
        {text}
      </Typography>
    </Button>
  );
};
