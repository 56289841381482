import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles({
  greenColor: { color: 'green' }
});

export const AddIconButton: React.FC<Props> = ({ onClick }) => {
  const { greenColor } = useStyles();
  return (
    <IconButton className={greenColor} onClick={onClick} aria-label="Pridėti">
      <AddIcon />
    </IconButton>
  );
};
