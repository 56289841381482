import React from 'react';

import { Field, useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FormikFieldStyleProps } from './FieldStyles';

type CheckProps = {
  formikKey: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
};

export const FormikCheckBoxField: React.FC<CheckProps> = ({ formikKey, disabled, onChange }) => {
  const [field] = useField(formikKey);
  return (
    <Checkbox
      color="primary"
      id={field.name}
      name={field.name}
      value={field.value}
      onChange={(event) => {
        if (onChange) {
          onChange(event.target.value !== 'true');
        }

        field.onChange(event);
      }}
      checked={field.value}
      onBlur={field.onBlur}
      disabled={disabled}
      style={{ padding: 3, marginRight: 3 }}
    />
  );
};

interface Props extends FormikFieldStyleProps {
  styledClasses: any;
  errors: any;
  touched: any;
  formikKey: string;
  label: string;
  setFieldValue: (id: string, value: any) => void;
  onChange?: (value: any) => void;
  isRequired?: boolean;
  disabled?: boolean;
  className?: string;
}

export const FormikFormCheckBoxField: React.FC<Props> = ({
  styledClasses,
  errors,
  formikKey,
  label,
  isRequired,
  onChange,
  disabled,
  style,
  className
}) => {
  return (
    <Box
      margin={1}
      className={styledClasses.inputContainer + ' ' + className}
      style={style?.container}
    >
      <FormControl required={isRequired} error={!!errors}>
        <FormGroup>
          <FormControlLabel
            control={
              <Field
                errors={errors}
                formikKey={formikKey}
                disabled={disabled}
                onChange={onChange}
                component={FormikCheckBoxField}
              />
            }
            label={label}
          />
        </FormGroup>
        {errors && <FormHelperText style={style?.helper}>{errors}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
