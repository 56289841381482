import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Table, Typography, TableBody, Box, makeStyles } from '@material-ui/core';
import { OutletCategoryTableCustomizationBar } from './CustomizationBar';
import OutletCategoryTableHead from './TableHead';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import { ApplicationState } from '../../../store';
import { pageTableData, getRecordWithMaxMappedValue } from '../../../utils/tableDataFunctions';
import { AddNewRecordButton } from '../../../components/TableButtons/AddNewRecordButton';
import { OutletCategoryRowContainer } from './TableRows/RowContainer';
import { CategoryRowContainerNew } from './TableRows/RowContainerNew';
import FilterRow from './FilterRow';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import { outletCategoryTableColumns } from '../tablesState/categoryTableTypes';
import { TableErrorRow } from '../../../components/TableErrorRow/TableErrorRow';
import { TableCircularProgressRow } from '../../../components/TableCircularProgressRow/TableCircularProgressRow';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { filterAndSortCategoryData } from '../tablesState/tablesStateFunctions';
import PageActionButtons from '../../../components/PageButtons/PageActionButtons';
import { subsystemContext } from '../../../pages/virs/OutletInfoTablesPage';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import { getTableExportDefinition } from '../exportDefinitions/outletCategoryTableExportDefinition';
import { ConfirmRemoveUnsignedDialog } from '../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { unsignedDataRemoveOutlet } from '../../../store/unsignedDataRemoval/unsignedDataActions';
import { OutletDocumentType } from '../../../store/unsignedDataRemoval/unsignedDataTypes';
import { ScrollXContainer } from '../../../components/ScrollXContainer/ScrollXContainer';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';
import { fetchVirsDataRequest } from '../../../store/virsis/actions';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  primaryButtonWrapper: {
    [theme.breakpoints.down('xs')]: {
       marginBottom: '10px'
    }
  }
}));

interface Props {
  tableName: OutletTableName;
}

const OutletCategoryTable: React.FC<Props> = ({ tableName }) => {
  const reduxDispatch = useDispatch();

  const {
    state: { categories: categoryTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const classes = useStyles();
  
  const { loadingOutletInfo, outletInfo, outletInfoError } = useSelector(
    (state: ApplicationState) => state.outletInfoData
  );

  function handleSetPage(value: number) {
    tableDispatch({ type: 'PAGE_SET', page: value, table: tableName });
  }

  function handleSetRowsPerPage(rowsPerPage: number) {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage,
      table: tableName
    });
  }

  function closeNewRecordRow() {
    tableDispatch({ type: 'NEW_RECORD_ROW_CLOSED', table: tableName });
  }

  function toggleAddNewCategory() {
    return categoryTableState.showNewRecord
      ? closeNewRecordRow()
      : tableDispatch({ type: 'NEW_RECORD_ROW_OPENED', table: tableName });
  }

  const data = filterAndSortCategoryData(outletInfo?.categories || [], categoryTableState);
  const { page: dataPage, pagesCount } = pageTableData(categoryTableState, data);

  const latestRecord = getRecordWithMaxMappedValue(
    outletInfo?.categories || [],
    ({ outletCategoryId }) => outletCategoryId
  );

  const subsystem = useContext(subsystemContext);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  return (
    <div className="named-data-table">
      <div className="table-title">
        {outletInfo?.active && <Typography variant="subtitle1">Kategorija</Typography>}
        {outletInfo && (
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <Box className={classes.buttonWrapper}>
              {outletInfo.active ? (
                <Box className={classes.primaryButtonWrapper}>
                <AddNewRecordButton
                  text="Pridėti naują kategoriją"
                  onClicked={toggleAddNewCategory}
                />
                </Box>
              ) : (
                <Typography variant="subtitle1">Kategorija</Typography>
              )}
              <Box>
                <PageActionButtons
                  dontShowDelete={subsystem === 'LRTK' || subsystem === 'ZEIT'}
                  onDownload={(format, navigationPath) =>
                    exportTableData(
                      format,
                      getTableExportDefinition(categoryTableState),
                      data,
                      navigationPath
                    )
                  }
                  onPrint={(navigationPath) =>
                    printTableData(
                      getTableExportDefinition(categoryTableState),
                      data,
                      navigationPath
                    )
                  }
                  onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
                />
              </Box>

              <ConfirmRemoveUnsignedDialog
                onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
                onConfirm={() =>
                  reduxDispatch(
                    unsignedDataRemoveOutlet(outletInfo.outletId, OutletDocumentType.CATEGORY)
                  )
                }
                onRemoveSuccess={() => reduxDispatch(fetchVirsDataRequest())}
                open={confirmToDeleteUnsignedDialogOpen}
              />
            </Box>
          </AllowedTo>
        )}
      </div>

      <Paper elevation={0}>
        <OutletCategoryTableCustomizationBar
          columnNames={outletCategoryTableColumns}
          tableName={tableName}
        />

        <ScrollXContainer>
          <Table>
            <OutletCategoryTableHead />
            {categoryTableState.customFilterOn && (
              <FilterRow columnNames={outletCategoryTableColumns} />
            )}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingOutletInfo && !outletInfoError}
                colSpan={4}
              />

              <TableErrorRow
                error={outletInfoError && 'Klaida. Nepavyko gauti VIP duomenų'}
                colSpan={4}
              />

              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                categoryTableState.showNewRecord && (
                  <CategoryRowContainerNew
                    outletInfo={outletInfo}
                    columnsDisplay={categoryTableState.columnsDisplayStatus}
                    closeNewRecordRow={closeNewRecordRow}
                  />
                )}
              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                dataPage.map((record) => (
                  <OutletCategoryRowContainer
                    latestRecordId={latestRecord && latestRecord.outletCategoryId}
                    outletInfo={outletInfo}
                    key={record.outletCategoryId}
                    record={record}
                    columnsDisplay={categoryTableState.columnsDisplayStatus}
                  />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={categoryTableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={categoryTableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </Paper>
    </div>
  );
};

export default OutletCategoryTable;
