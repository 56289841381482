import { action } from 'typesafe-actions';
import {
  CREATE_ENTERPRISE_ERROR,
  CREATE_ENTERPRISE_REQUEST,
  CREATE_ENTERPRISE_SUCCESS,
  FETCH_ENTERPRISE_DATA_ERROR,
  FETCH_ENTERPRISE_DATA_REQUEST,
  FETCH_ENTERPRISE_DATA_SUCCESS,
  REMOVE_ENTERPRISE_ERROR,
  REMOVE_ENTERPRISE_REQUEST,
  REMOVE_ENTERPRISE_SUCCESS,
  RESET_CREATE_ENTERPRISE_STATE,
  UPDATE_ENTERPRISE_REQUEST,
  UPDATE_ENTERPRISE_SUCCESS,
  UPDATE_ENTERPRISE_ERROR,
  RESET_UPDATE_ENTERPRISE_STATE,
  RESET_REMOVE_ENTERPRISE_STATE,
  GET_OUTLET_IDS_REQUEST,
  GET_OUTLET_IDS_SUCCESS,
  GET_OUTLET_IDS_ERROR,
  DELETE_ENTERPRISE_ALERT_OPEN,
  DELETE_ENTERPRISE_ALERT_CLOSE,
  ENTERPRISE_DATA_PDF_REQUEST,
  UPDATE_ENTERPRISE_OUTLETS_ALERT_OPEN,
  UPDATE_ENTERPRISE_OUTLETS_ALERT_CLOSE
} from './enterprisesActionsTypes';
import { EnterpriseRecord, OutletIdsResponse } from './enterprisesTypes';

export const fetchEnterpriseData = (virId: number, personId: number) =>
  action(FETCH_ENTERPRISE_DATA_REQUEST, { virId, personId });
export const fetchEnterpriseDataSuccess = (data: string) =>
  action(FETCH_ENTERPRISE_DATA_SUCCESS, data);
export const fetchEnterpriseDataError = (error: Error) =>
  action(FETCH_ENTERPRISE_DATA_ERROR, error);

export const createEnterprise = (
  virId: number,
  personId: number,
  record: EnterpriseRecord,
  message: string
) =>
  action(CREATE_ENTERPRISE_REQUEST, {
    virId,
    personId,
    record,
    message
  });
export const createEnterpriseSuccess = (data: string) => action(CREATE_ENTERPRISE_SUCCESS, data);
export const createEnterpriseError = (error: Error) => action(CREATE_ENTERPRISE_ERROR, error);
export const resetCreateEnterpriseState = () => action(RESET_CREATE_ENTERPRISE_STATE);

export const updateEnterprise = (
  virId: number,
  personId: number,
  enterpriseId: number,
  previousEnterpriseTypeId: number,
  record: EnterpriseRecord,
  message: string
) =>
  action(UPDATE_ENTERPRISE_REQUEST, {
    virId,
    personId,
    enterpriseId,
    previousEnterpriseTypeId,
    record,
    message
  });
export const updateEnterpriseSuccess = (data: string) => action(UPDATE_ENTERPRISE_SUCCESS, data);
export const updateEnterpriseError = (error: Error) => action(UPDATE_ENTERPRISE_ERROR, error);
export const resetUpdateEnterpriseState = () => action(RESET_UPDATE_ENTERPRISE_STATE);

export const removeEnterprise = (virId: number, enterpriseId: number, personId: number) =>
  action(REMOVE_ENTERPRISE_REQUEST, { virId, enterpriseId, personId });
export const removeEnterpriseSuccess = (personId: number) =>
  action(REMOVE_ENTERPRISE_SUCCESS, personId);
export const removeEnterpriseError = (error: Error) => action(REMOVE_ENTERPRISE_ERROR, error);
export const resetRemoveEnterpriseState = () => action(RESET_REMOVE_ENTERPRISE_STATE);

export const getOutletIdsRequest = (enterpriseId: number) =>
  action(GET_OUTLET_IDS_REQUEST, enterpriseId);
export const getOutletIdsSuccess = (response: OutletIdsResponse) =>
  action(GET_OUTLET_IDS_SUCCESS, response);
export const getOutletIdsError = (error: Error) => action(GET_OUTLET_IDS_ERROR, error);

export const openDeleteEnterpriseAlert = () => action(DELETE_ENTERPRISE_ALERT_OPEN);
export const cancelDeleteEnterpriseAlert = () => action(DELETE_ENTERPRISE_ALERT_CLOSE);

export const getEnterpriseDocRequest = (docId: number) =>
  action(ENTERPRISE_DATA_PDF_REQUEST, docId);

export const openEditEnterpriseAlert = () => action(UPDATE_ENTERPRISE_OUTLETS_ALERT_OPEN);
export const cancelEditEnterpriseAlert = () => action(UPDATE_ENTERPRISE_OUTLETS_ALERT_CLOSE);
