import React from 'react';
import { makeStyles } from '@material-ui/core';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';
import virsisTheme from '../../../../style/virsisTheme';
import { ErrorType } from '../../../../store/virsis/dataTypes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    boxSizing: 'border-box',
    background: '#fff',
    padding: '2px 8px'
  },
  icon: {
    marginRight: '5px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  text: {
    color: '#F11A1A',
    fontSize: '0.7rem',
    position: 'relative'
  },
  errorColor: {
    color: `${virsisTheme.palette.error.main}`
  },
  warningColor: {
    color: `${virsisTheme.palette.warning.main}`
  },
  infoColor: {
    color: `${virsisTheme.palette.info.main}`
  },
  singleError: {
    position: 'absolute',
    top: '-8px',
    width: '167px',
    background: '#fff'
  }
}));

interface Props {
  errorText?: string[];
}

const ErrorBox: React.FC<Props> = ({ errorText }) => {
  const classes = useStyles();

  const getErrorColor = (errorType: string) => {
    let colorClass = classes.errorColor;

    if (
      errorType.includes('Dalyviai pateikti') ||
      errorType.includes('Participants shown at level')
    ) {
      colorClass = classes.infoColor;
    }

    switch (errorType) {
      case ErrorType.CRITICAL:
        colorClass = classes.errorColor;
        break;
      case ErrorType.NONCRITICAL:
        colorClass = classes.warningColor;
        break;
      case ErrorType.INFO:
        colorClass = classes.infoColor;
        break;
    }

    return colorClass;
  };

  return (
    <VirsTreeCustomTooltip text={errorText?.join(', ') || ''}>
      <div className={classes.root}>
        {errorText?.map((x, index) => (
          <div
            key={x + index}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <div className={classes.icon}>
              <ErrorOutlinedIcon className={getErrorColor(x)} fontSize="small" />
            </div>
            <div className={`${classes.text} ${getErrorColor(x)}`}>
              <span key={x + index} className={classes.singleError}>
                {x}
              </span>
            </div>
          </div>
        ))}
      </div>
    </VirsTreeCustomTooltip>
  );
};

export default ErrorBox;
