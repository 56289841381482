import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  EthicalNonComplianceHeaderField,
  EthicalNonComplianceColumnHeader,
  EthicalNonComplianceCustomFilterOptions
} from './tableState/tableInitialStateAndTypes';
import { useEthicalNonComplianceTableState } from './Context';
import { EthicalNonComplianceData } from '../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { useDispatch, useSelector } from 'react-redux';
import { customFilterValueChanged } from '../../store/ethicalNonComplianceTable/ethicalNonComplianceTableAction';
import { TableCustomFilterForOptionCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterForOptionCellGeneric';
import { ApplicationState } from '../../store';
import { getUniqueOptions } from '../../utils/tableDataFunctions';

interface Props {
  columnNames: EthicalNonComplianceColumnHeader[];
  dataToDisplay: EthicalNonComplianceData[];
}

const EthicalNonComplianceTableFilterRow: React.FC<Props> = ({ columnNames, dataToDisplay }) => {
  const {
    ethicalNonComplianceData: { instTableFilters }
  } = useSelector((state: ApplicationState) => state);
  const { state: tableState } = useEthicalNonComplianceTableState();
  const reduxDispatch = useDispatch();
  const handleCustomFilterChange = (
    column: EthicalNonComplianceHeaderField,
    value: string | null
  ) => {
    reduxDispatch(customFilterValueChanged(column, value));
  };

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterForOptionCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions<
                EthicalNonComplianceCustomFilterOptions,
                EthicalNonComplianceHeaderField
              >(instTableFilters, column.id)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default EthicalNonComplianceTableFilterRow;
