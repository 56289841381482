import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { saga } from './saga';
import { getBasicTableReducer } from '../GenericClassifierTable/genericReducers/basicTableReducer';
import { EnterpriseType, EnterpriseTypeInput } from './types';
import { FormSagaContext } from '../../../components/FormikFields/GenericFormikWrappers/types';

export const {
  loadData,
  resetForm,
  setData,
  setError,
  setFormError,
  setFormLoading,
  setFormOpen,
  setLoading,
  submitForm,
  reducer,
  initialState
} = getBasicTableReducer<EnterpriseType, EnterpriseTypeInput>();

export function getEnterpriseTypeStore(context: FormSagaContext) {
  const middleware = createSagaMiddleware({ context });
  const store = createStore(reducer, applyMiddleware(middleware));
  middleware.run(saga);
  return store;
}

export type State = typeof initialState;
