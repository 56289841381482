import {
  SvgIconTypeMap,
  TableCell,
  TableCellProps,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Order } from '../TableTypes/TableTypes';

type Props<TColumnName> = TableCellProps & {
  label: string;
  columnName: TColumnName;
  sortLabelPropsFactory: (columnName: TColumnName) => {
    active: boolean;
    direction: Order;
    IconComponent: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
    onClick: () => void;
  };
  tableSettings: object & { sortBy: TColumnName; order: Order };
};

export const TableSortingHeaderCell = <TColumnName,>({
  rowSpan,
  columnName,
  label,
  sortLabelPropsFactory,
  tableSettings: { sortBy, order }
}: Props<TColumnName>) => {
  const { onClick, ...sortLabelProps } = sortLabelPropsFactory(columnName);

  return (
    <TableCell
      rowSpan={rowSpan}
      sortDirection={sortBy === columnName ? order : false}
      className="TableCellSortable"
      onClick={onClick}
    >
      <TableSortLabel {...sortLabelProps}>
        <Typography variant="h4">{label}</Typography>
      </TableSortLabel>
    </TableCell>
  );
};
