import React, { useReducer } from 'react';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
  TableSortLabel
} from '@material-ui/core/';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { LibisSearchData } from '../../../../store/outlets/outletsTypes';
import {
  libisResultsTableReducer,
  initialLibisResultsTableState,
  LibisResultsDataField,
  LibisResultsTableColumnHeader
} from './searchLibisResultsReducerAndTypes';
import { TablePagination } from '../../../../components/TablePagination/TablePagination';
import { ROWS_PER_PAGE_OPTIONS, VirsisData } from '../../../../utils/tableTypes';
import {
  getPagesCountGeneric,
  getSortedDataGeneric,
  getDataSlicedToPageGeneric
} from '../../../../utils/tableDataFunctions';
import { useSearchStyles } from '../../../../style/searchStyles';

const columns: LibisResultsTableColumnHeader[] = [
  {
    id: 'title',
    label: 'Pavadinimas'
  },
  {
    id: 'type',
    label: 'Paantraštės duomenys'
  },
  {
    id: 'publisher',
    label: 'Leidėjo pavadinimas'
  },
  {
    id: 'firstPublishedDate',
    label: 'Pirmojo leidimo data'
  },
  {
    id: 'internationalNumber',
    label: 'ISBN / ISSN kodas'
  },
  {
    id: 'circulation',
    label: 'Tiražas'
  }
];

interface Props {
  outletOnLibisResults: LibisSearchData[];
  selectedOutlet?: LibisSearchData;
  selectLibisOutlet: (outlet: LibisSearchData) => void;
}

export const SearchLibisDialogResultsTable: React.FC<Props> = ({
  outletOnLibisResults,
  selectedOutlet,
  selectLibisOutlet
}) => {
  const [tableState, tableDispatch] = useReducer(
    libisResultsTableReducer,
    initialLibisResultsTableState
  );

  const classes = useSearchStyles();

  const createSortHandler = (column: LibisResultsDataField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  function setPage(page: number): void {
    tableDispatch({ type: 'PAGE_SET', page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    tableDispatch({ type: 'ROWS_PER_PAGE_SET', rowsPerPage });
  }

  const pagesCount = getPagesCountGeneric(outletOnLibisResults.length, tableState.rowsPerPage);
  const sortedData = getSortedDataGeneric(
    outletOnLibisResults as unknown as VirsisData[],
    tableState.order,
    tableState.sortBy
  );

  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    tableState.page,
    tableState.rowsPerPage
  ) as unknown as LibisSearchData[];

  return (
    <>
      <Typography
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          fontWeight: 'bolder'
        }}
      >
        Paieškos rezultatai
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((header) => (
                <TableCell
                  key={header.id}
                  sortDirection={tableState.sortBy === header.id ? tableState.order : false}
                  align="left"
                  variant="head"
                  className="TableCellSortable"
                  onClick={() => {
                    createSortHandler(header.id);
                  }}
                >
                  <TableSortLabel
                    active
                    direction={tableState.sortBy === header.id ? tableState.order : 'asc'}
                    IconComponent={tableState.sortBy === header.id ? ActiveSortIcon : SortIcon}
                  >
                    <Typography variant="h4">{header.label}</Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>
                <Typography variant="h4">Veiksmai</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedData.map((outlet) => (
              <TableRow key={outlet.id}>
                <TableCell>{outlet.title}</TableCell>
                <TableCell>{outlet.type}</TableCell>
                <TableCell>{outlet.publisher}</TableCell>
                <TableCell>{outlet.firstPublishedDate}</TableCell>
                <TableCell>{outlet.isbn ? outlet.isbn : outlet.issn}</TableCell>
                <TableCell>{outlet.circulation}</TableCell>
                <TableCell>
                  <ToggleButton
                    classes={{ selected: classes.selected, root: classes.root }}
                    selected={selectedOutlet ? selectedOutlet.id === outlet.id : false}
                    value="select-libis-outlet"
                    onClick={() => selectLibisOutlet(outlet)}
                  >
                    Pasirinkti
                  </ToggleButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          recordsCount={outletOnLibisResults.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          disabled={outletOnLibisResults.length === 0}
        />
      </TableContainer>
    </>
  );
};
