import React from 'react';
import { Typography, TableSortLabel, TableHead, TableRow, TableCell } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import { Order, ColumnsDisplayStatus, ColumnHeaderField } from './tableState/tableTypes';

interface Props {
  order: Order;
  sortBy: ColumnHeaderField;
  onSortByColumnClick: (column: ColumnHeaderField) => void;
  columnsDisplayStatus: ColumnsDisplayStatus;
}

const DataTableHead: React.FC<Props> = ({
  sortBy,
  order,
  onSortByColumnClick,
  columnsDisplayStatus
}) => {
  const calculateSharedCellColSpan = (displayingColumns: boolean[]): number =>
    displayingColumns.filter((value) => value === true).length;
  return (
    <TableHead>
      <TableRow>
        {columnsDisplayStatus.distributorName && (
          <TableCell
            rowSpan={2}
            key="distributorName"
            sortDirection={sortBy === 'distributorName' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('distributorName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'distributorName' ? order : 'asc'}
              IconComponent={sortBy === 'distributorName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Skleidėjo pavadinimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.distributorCode && (
          <TableCell
            rowSpan={2}
            key="distributorCode"
            sortDirection={sortBy === 'distributorCode' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('distributorCode')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'distributorCode' ? order : 'asc'}
              IconComponent={sortBy === 'distributorCode' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Kodas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.interestedPartyName && (
          <TableCell
            rowSpan={2}
            key="interestedPartyName"
            sortDirection={sortBy === 'interestedPartyName' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('interestedPartyName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'interestedPartyName' ? order : 'asc'}
              IconComponent={sortBy === 'interestedPartyName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Asmuo / grupė, kurių interesais skleista reklama</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.campaignName && (
          <TableCell
            rowSpan={2}
            key="campaignName"
            sortDirection={sortBy === 'campaignName' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('campaignName')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'campaignName' ? order : 'asc'}
              IconComponent={sortBy === 'campaignName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Kampanijos pavadinimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(columnsDisplayStatus.politicalAdDateFrom || columnsDisplayStatus.politicalAdDateTo) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedCellColSpan([
              columnsDisplayStatus.politicalAdDateFrom,
              columnsDisplayStatus.politicalAdDateTo
            ])}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}
        {columnsDisplayStatus.financialSupportAmount && (
          <TableCell
            rowSpan={2}
            key="financialSupportAmount"
            sortDirection={sortBy === 'financialSupportAmount' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('financialSupportAmount')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'financialSupportAmount' ? order : 'asc'}
              IconComponent={sortBy === 'financialSupportAmount' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Suma (EUR)</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {columnsDisplayStatus.politicalAdDateFrom && (
          <TableCell
            rowSpan={1}
            key="politicalAdDateFrom"
            sortDirection={sortBy === 'politicalAdDateFrom' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('politicalAdDateFrom')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'politicalAdDateFrom' ? order : 'asc'}
              IconComponent={sortBy === 'politicalAdDateFrom' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {columnsDisplayStatus.politicalAdDateTo && (
          <TableCell
            rowSpan={1}
            key="politicalAdDateTo"
            sortDirection={sortBy === 'politicalAdDateTo' ? order : false}
            className="TableCellSortable"
            onClick={() => onSortByColumnClick('politicalAdDateTo')}
          >
            <TableSortLabel
              active
              direction={sortBy === 'politicalAdDateTo' ? order : 'asc'}
              IconComponent={sortBy === 'politicalAdDateTo' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Iki</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
export default DataTableHead;
