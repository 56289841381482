import React from 'react';
import { TableCell, Typography, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { InnerTableContainer } from './InnerLincencesTable/InnerTableContainer';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import BtnOpenNewDoc from '../../../components/TableButtons/BtnOpenNewDoc';
import { getEnterpriseDocRequest } from '../../../store/enterprises/enterprisesActions';
import { hasRoles } from '../../../utils/roleHelper';
import { Roles } from '../../../store/virsis/dataTypes';
import { ApplicationState } from '../../../store';

const useStyles = makeStyles((theme) => ({
  extensionWrapper: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginLeft: '20px',
    }
  },
}));

interface Props {
  colSpan: number;
  record: EnterpriseData;
  recordStatus?: string;
}

export const EnterpriseTableRowExtension: React.FC<Props> = ({ colSpan, record, recordStatus }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: ApplicationState) => state.virsis.currentUser);

  const classes = useStyles();

  const openDocumentInNewTab = (docId: number) => {
    dispatch(getEnterpriseDocRequest(docId));
  };

  return (
    <RowStyleOnDifferentStatus id={`extension-${record.enterpriseId}`} status={recordStatus}>
      {record.licences.length > 0 && (
        <TableCell colSpan={colSpan}>
          <InnerTableContainer data={record.licences} />
        </TableCell>
      )}
      {record.licences.length === 0 && (
        <TableCell colSpan={colSpan}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <Typography variant="body2" style={{ textAlign: 'right' }}>
                Pateikta:
              </Typography>
            </Grid>
            {record.documentStatusDate && (
              <>
                <Grid item xs={1} className={classes.extensionWrapper}>
                  <Typography className={classes.extensionWrapper}>
                    {record.documentStatusDate}
                  </Typography>
                </Grid>
                {currentUser &&
                  hasRoles(currentUser, [
                    Roles.ROLE_VIRS_EDIT,
                    Roles.ROLE_VIRS,
                    Roles.ROLE_ADMIN_ALL
                  ]) && (
                    <Grid item>
                      <BtnOpenNewDoc
                        onClicked={() => {
                          openDocumentInNewTab(record.documentStatusId);
                        }}
                        text="Peržiūrėti el. dokumentą"
                      />
                    </Grid>
                  )}
              </>
            )}
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <Typography variant="body2" style={{ textAlign: 'right' }}>
                Atnaujinta:
              </Typography>
            </Grid>
            {record.closingDocumentDate && (
              <>
                <Grid item xs={1} className={classes.extensionWrapper}>
                  <Typography className={classes.extensionWrapper}>
                  {record.closingDocumentDate}
                  </Typography>
                </Grid>
                <Grid item>
                  {currentUser &&
                    hasRoles(currentUser, [
                      Roles.ROLE_VIRS_EDIT,
                      Roles.ROLE_VIRS,
                      Roles.ROLE_ADMIN_ALL
                    ]) && (
                      <BtnOpenNewDoc
                        onClicked={() => {
                          openDocumentInNewTab(record.closingDocumentId);
                        }}
                        text="Peržiūrėti el. dokumentą"
                      />
                    )}
                </Grid>
              </>
            )}
          </Grid>
        </TableCell>
      )}
    </RowStyleOnDifferentStatus>
  );
};
