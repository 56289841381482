import moment, { Moment } from 'moment';
import { TimelineEventGroup, TimelinePeriod } from './state/types';
import { PeriodName, TimelineEvent } from '../../../store/shareholdersVirsTree/virsTreeDataTypes';
import { ActivityPeriod, ShareholdersProps } from '../../../store/shareholders/shareholdersTypes';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getCurrentPeriod } from '../../ShareholdersTable/utilityFunctions/sharedFunctions';

export function getPeriodLength(period: TimelinePeriod): number {
  return period.end.diff(period.start);
}

export function getPeriodDateByRelativeValue(value: number, period: TimelinePeriod): Moment {
  const tStart = period.start.valueOf();
  const tEnd = period.end.valueOf();
  return moment(tStart + (tEnd - tStart) * value).startOf('day');
}

export function getPeriodRelativeValue(date: Moment, period: TimelinePeriod): number {
  return date.diff(period.start) / getPeriodLength(period);
}

export function addDays(date: Moment, days: number): Moment {
  return date.clone().add(days, 'day');
}

export function getPeriodStart(date: Moment, periodName: PeriodName): Moment {
  return date.clone().startOf(periodName);
}

export function getPeriodEnd(date: Moment, periodName: PeriodName): Moment {
  return date.clone().startOf(periodName).add(1, periodName);
}

export function getTimelinePeriods(
  periodName: PeriodName,
  startDate: Moment,
  periodCount: number
): TimelinePeriod[] {
  const periods: TimelinePeriod[] = [
    {
      start: getPeriodStart(startDate, periodName),
      end: getPeriodEnd(startDate, periodName)
    }
  ];

  while (periods.length < periodCount) {
    periods.push({
      start: periods[periods.length - 1].end,
      end: getPeriodEnd(periods[periods.length - 1].end, periodName)
    });
  }

  return periods;
}

export function isYearStart(date: Moment): boolean {
  return date.month() === 0 && date.date() === 1;
}

export function isMonthStart(date: Moment): boolean {
  return date.date() === 1;
}

export function getAdjacentEvents(
  activeDate: Moment,
  timelineEvents: TimelineEvent[]
): { following?: TimelineEvent; previous?: TimelineEvent } {
  const activeTime = activeDate.valueOf();

  return timelineEvents
    .filter(({ date }) => activeDate.valueOf() !== date.valueOf())
    .reduce(
      ({ previous, following }, value) => {
        const tPrevious = previous && previous.date.valueOf();
        const tFollowing = following && following.date.valueOf();
        const tValue = value.date.valueOf();
        return (
          ((!tPrevious || tPrevious < tValue) &&
            tValue < activeTime && { previous: value, following }) ||
          ((!tFollowing || tFollowing > tValue) &&
            tValue > activeTime && { previous, following: value }) || {
            previous,
            following
          }
        );
      },
      {
        previous: undefined,
        following: undefined
        // eslint-disable-next-line
      } as { following?: TimelineEvent; previous?: TimelineEvent }
    );
}

export function addEvent(
  event: TimelineEvent,
  group: TimelineEventGroup,
  period: TimelinePeriod
): TimelineEventGroup {
  const events = [...group.events, event];
  const sumLocation = events
    .map((value) => getPeriodRelativeValue(value.date, period))
    .reduce((a, b) => a + b);

  const averageLocation = sumLocation / events.length;

  return {
    date: getPeriodDateByRelativeValue(averageLocation, period),
    events
  };
}

export function groupTimelineEvents(
  period: TimelinePeriod,
  timelineEvents: TimelineEvent[],
  groupingDistance: number
): TimelineEventGroup[] {
  return timelineEvents.reduce((prev, current) => {
    const found = prev.find(
      (group) =>
        Math.abs(
          getPeriodRelativeValue(group.date, period) - getPeriodRelativeValue(current.date, period)
        ) <= groupingDistance
    );

    if (!found) {
      const newGroup = addEvent(
        current,
        {
          date: moment(),
          events: []
        },
        period
      );
      return [newGroup, ...prev];
    }

    return [...prev.filter((value) => value !== found), addEvent(current, found, period)];
  }, [] as TimelineEventGroup[]);
}

export function getPeriodCount(periodName: PeriodName, width: number): number {
  switch (periodName) {
    case 'year':
      return Math.floor(width / 150);
    case 'month':
      return Math.floor(width / 100);
    default:
      return Math.floor(width / 30);
  }
}

export function getInitialStartDate(
  activeDate: Moment,
  periodCount: number,
  periodName: PeriodName
): Moment {
  const date = activeDate.clone();
  for (let i = 0; i < periodCount / 2; i += 1) {
    date.add(-1, 'd').startOf(periodName);
  }
  return date;
}

const getDaysArray = (s: Date, e: Date): string[] => {
  const startDate: string[] = [];
  const endDate = new Date(s);
  for (; endDate <= e; endDate.setDate(endDate.getDate() + 1)) {
    startDate.push(moment(endDate).format('YYYY-MM-DD'));
  }
  return startDate;
};

export const shouldDisableDate = (
  activityPeriods: ActivityPeriod[],
  date: MaterialUiPickersDate,
  shareholders: ShareholdersProps[],
  lastSignedDate?: Moment | undefined
): boolean => {
  if (activityPeriods.length > 0 && date !== null) {
    const selectedDate = date.format('YYYY-MM-DD');
    const currentActivityPeriod = getCurrentPeriod(selectedDate, activityPeriods);
    const daysToEnable: string[] = [];

    if (
      shareholders.length > 0 &&
      lastSignedDate !== undefined &&
      date.isSameOrBefore(lastSignedDate)
    ) {
      return true;
    }

    for (let i = 0; activityPeriods.length > i; i++) {
      const start = activityPeriods[i].startDate;
      const end = activityPeriods[i].endDate;
      if (end !== null) {
        getDaysArray(new Date(start), new Date(end)).forEach((day) => daysToEnable.push(day));
      }
    }
    if (date && daysToEnable?.includes(date.format('YYYY-MM-DD'))) {
      return false;
    }
    return !(currentActivityPeriod && selectedDate >= currentActivityPeriod?.startDate);
  }
  return true;
};

export const getDateAvailablePredicate = (activityPeriods: ActivityPeriod[]) => {
  return (date: Moment): boolean => {
    return !shouldDisableDate(activityPeriods, date, []);
  };
};
