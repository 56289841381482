import moment from 'moment';

import {
  MAXIMAL_RECORD_YEAR_TODAY,
  MINIMAL_RECORD_YEAR_DEFAULT
} from '../../../../utils/InputValueFunctions';
import {
  FundsReceivedEditRowAction,
  FundsReceivedEditRowState,
  FundsReceivedRecordState,
  initialFundsReceivedEditRowState
} from './rowInitialStateAndTypes';
import {
  setFundsReceivedSumAndValidate,
  setFundsReceivedVirsAndUpdateOptions,
  setTransactionAndValidate,
  updateYearOnlyDateValue,
  validateEditFundsReceivedRecordState
} from './reducerFunctions';

export const editFundsReceivedReducer = (
  state: FundsReceivedEditRowState,
  action: FundsReceivedEditRowAction
): FundsReceivedEditRowState => {
  switch (action.type) {
    case 'EDITING_INITIALIZED':
      return {
        ...state,
        editingOn: true,
        virs: {
          ...state.virs,
          value: {
            ...state.virs.value,
            virId: action.record.virsId,
            personFullName: action.record.virsName,
            personCode: String(action.record.virsLegalCode),
            outlets: action.record.outlets,
            virsLegalRegistrationDate: action.record.virsLegalRegistrationDate,
            virsLegalDeregistrationDate: action.record.virsLegalDeregistrationDate
          },
          validated: true
        },
        fundsReceivedYear: {
          ...state.fundsReceivedYear,
          value: action.record.fundsReceivedYear ? moment(action.record.fundsReceivedYear) : null,
          minDates: [
            MINIMAL_RECORD_YEAR_DEFAULT,
            {
              id: 'virsLegalRegistrationDate',
              date: action.record.virsLegalRegistrationDate
                ? moment(action.record.virsLegalRegistrationDate).startOf('year')
                : undefined,
              text: 'Metai negali būti ankstesni už VIRS įsiregistravimo JAR metus'
            }
          ],
          maxDates: [
            MAXIMAL_RECORD_YEAR_TODAY,
            {
              id: 'virsLegalDeregistrationDate',
              date: action.record.virsLegalDeregistrationDate
                ? moment(action.record.virsLegalDeregistrationDate).endOf('year')
                : undefined,
              text: 'Metai negali būti vėlesni už VIRS išsiregistravimo iš JAR metus'
            }
          ]
        },
        fundsReceivedSum: {
          ...state.fundsReceivedSum,
          value: action.record.fundsReceivedSum
        },
        fundsReceivedOutlets: {
          ...state.fundsReceivedOutlets,
          values: action.record.fundsReceivedOutlets,
          validated: true,
          options: [action.fictitiousOutlet[0], ...action.record.outlets]
        },
        transaction: {
          ...state.transaction,
          value: action.record.transaction,
          validated: true,
          options: action.transactionTypes
        }
      };
    case 'EDITING_CANCELLED':
      return initialFundsReceivedEditRowState;
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return {
        ...state,
        ...setFundsReceivedVirsAndUpdateOptions(
          state as unknown as FundsReceivedRecordState,
          action.virs
        ),
        showFindVirsDialog: false
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'FUNDS_RECEIVED_YEAR_CHANGED':
      return {
        ...state,
        fundsReceivedYear: updateYearOnlyDateValue(
          action.fundsReceivedYear,
          state.fundsReceivedYear
        )
      };
    case 'FUNDS_RECEIVED_SUM_CHANGED':
      return {
        ...state,
        fundsReceivedSum: setFundsReceivedSumAndValidate(action.fundsReceivedSum)
      };
    case 'OUTLETS_CHANGED':
      return {
        ...state,
        fundsReceivedOutlets: {
          ...state.fundsReceivedOutlets,
          values: action.outlets,
          validated: action.outlets.length > 0,
          error: action.outlets.length < 1,
          helperText: action.outlets.length > 0 ? '' : 'Būtinos VIP'
        }
      };
    case 'TRANSACTION_CHANGED':
      return {
        ...state,
        transaction: setTransactionAndValidate(state.transaction, action.transaction)
      };
    case 'UPDATE_RECORD_CLICKED':
      return validateEditFundsReceivedRecordState(state);
    case 'UPDATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        updateRecordConfirmationOn: false,
        editingOn: false
      };
    case 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        updateRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeRecordConfirmationOn: true
      };
    case 'REMOVE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        removeRecordConfirmationOn: false
      };
    case 'ANNUL_RECORD_CLICKED':
      return {
        ...state,
        annulmentType: {
          ...state.annulmentType,
          options: action.annulmentTypes
        },
        annulRecordConfirmationOn: true
      };
    case 'ANNUL_RECORD_CANCELLED':
    case 'ANNUL_RECORD_CLOSED_ON_SUCCESS':
    case 'ANNUL_RECORD_CLOSED_ON_ERROR':
      return {
        ...state,
        annulRecordConfirmationOn: false
      };
    default:
      return state;
  }
};
