import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DocumentActionButton } from './DocumentActionButton';

interface Props {
  onClick?: () => void;
}

export const ViewDocumentButton: React.FC<Props> = ({ onClick }) => (
  <DocumentActionButton onClick={onClick} IconComponent={VisibilityIcon} text="Peržiūrėti" />
);
