import { Action, createReducer } from 'typesafe-actions';
import { ROWS_PER_PAGE_OPTIONS, SortOrder } from '../../../../utils/tableTypes';
import { Column, TableDefinition } from '../types';
import { getInitialFilters } from '../utility/tableDataUtility';
import { Actions } from './actions';

export interface Visibility<TData> {
  field: keyof TData;
  visible: boolean;
}

export interface Filter<TData> {
  field: keyof TData;
  value?: string;
}

export interface Pagination {
  activePage: number;
  rowsInPage: number;
}

export interface Sort<TData> {
  field?: keyof TData;
  direction?: SortOrder;
}

export interface State<TData> {
  tableDefinition: TableDefinition<TData>;
  columns: Column<TData>[];
  columnVisibility: Visibility<TData>[];
  columnFilters: Filter<TData>[];
  columnSort: Sort<TData>;
  pagination: Pagination;
  showFilters: boolean;
}

export function getReducer<TData>(
  tableDefinition: TableDefinition<TData>,
  actions: Actions<TData>
) {
  return createReducer<State<TData>, Action>({
    tableDefinition,
    columnVisibility: tableDefinition.columnGroups
      .map(({ columns }) => columns)
      .flatMap((columns) => columns)
      .map(({ field }) => ({ field, visible: true })),
    columns: tableDefinition.columnGroups.flatMap(({ columns: column }) => column),
    columnFilters: getInitialFilters(tableDefinition),
    pagination: {
      activePage: 0,
      rowsInPage: ROWS_PER_PAGE_OPTIONS[0]
    },
    columnSort: {},
    showFilters: false
  })
    .handleAction(actions.toggleColumnVisibility, (state, action) => ({
      ...state,
      columnVisibility: state.columnVisibility.map((visibility) =>
        visibility.field === action.payload.field
          ? { ...visibility, visible: action.payload.visible }
          : visibility
      )
    }))
    .handleAction(actions.setFilterValue, (state, action) => ({
      ...state,
      pagination: { ...state.pagination, activePage: 0 },
      columnFilters: state.columnFilters.map((filter) =>
        filter.field === action.payload.field ? { ...filter, value: action.payload.value } : filter
      )
    }))
    .handleAction(actions.resetFilter, (state) => ({
      ...state,
      pagination: { ...state.pagination, activePage: 0 },
      columnFilters: getInitialFilters(tableDefinition)
    }))
    .handleAction(actions.setSort, (state, action) => ({
      ...state,
      columnSort: action.payload
    }))
    .handleAction(actions.setShowFilters, (state, action) => ({
      ...state,
      showFilters: action.payload.showFilters
    }))
    .handleAction(actions.setPagination, (state, action) => ({
      ...state,
      pagination: action.payload.pagination
    }))
    .handleAction(actions.edit, (state, action) => {
      tableDefinition.tableActions.edit(action.payload.data);
      return {
        ...state
      };
    })
    .handleAction(actions.remove, (state, action) => {
      if (tableDefinition.tableActions.remove) {
        tableDefinition.tableActions.remove(action.payload.data);
      }
      return {
        ...state
      };
    })
    .handleAction(actions.download, (state, action) => {
      if (tableDefinition.tableActions.download) {
        tableDefinition.tableActions.download(action.payload.data);
      }
      return {
        ...state
      };
    })
    .handleAction(actions.create, (state) => {
      if (tableDefinition.tableActions.create) {
        tableDefinition.tableActions.create();
      }
      return {
        ...state
      };
    });
}
