import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import theme from '../../style/virsisTheme';

interface Props {
  active?: boolean;
  onClick: () => void;
  label: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    filter: {
      fontWeight: 'bold',
      minWidth: 'unset',
      marginRight: 10,
      color: theme.palette.primary.main
    },
    activeFilter: {
      fontWeight: 'bold',
      minWidth: 'unset',
      marginRight: 10,
      color: theme.palette.text.primary
    }
  })
);

const PageFilterButton: React.FC<Props> = ({ active, onClick, label }) => {
  const classes = useStyles();

  return (
    <Button
      className={active ? classes.activeFilter : classes.filter}
      size="small"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default PageFilterButton;
