import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { noOptionsText } from './constants';
import { Option } from './TableCustomFilterForOptionCellGeneric';

interface Props {
  uniqueOptions: Option[];
  name: string;
  setFilter(column: string, value: string | number | null): void;
}

export const TableCustomFilterForOptionAutocomplete: React.FC<Props> = ({
  uniqueOptions,
  name,
  setFilter
}) => {
  function handleChange(event: React.ChangeEvent<unknown>, value: Option | null) {
    setFilter(name, value?.value || null);
  }

  function getOptionLabel(option: Option) {
    return option?.label || '';
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      clearOnEscape
      id={`filter-${name}`}
      options={uniqueOptions}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};
