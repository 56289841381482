export const SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST =
  '@shareholders/FETCH_SELECTED_DATE_DATA_REQUEST';
export const SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_SUCCESS =
  '@shareholders/FETCH_SELECTED_DATE_DATA_REQUEST_SUCCESS';
export const SHAREHOLDERS_SELECTED_DATE_DATA_REQUEST_ERROR =
  '@shareholders/FETCH_SELECTED_DATE_DATA_REQUEST_ERROR';

export const SHAREHOLDERS_DATA_REQUEST = '@shareholders/SHAREHOLDERS_DATA_REQUEST';
export const SHAREHOLDERS_DATA_REQUEST_SUCCESS = '@shareholders/SHAREHOLDERS_DATA_SUCCESS';
export const SHAREHOLDERS_DATA_REQUEST_ERROR = '@shareholders/SHAREHOLDERS_DATA_ERROR';

export const SET_SELECTED_DATE = '@shareholders/SET_SELECTED_DATE';

export const SHAREHOLDER_DOCUMENT_PDF_REQUEST = '@shareholders/DOCUMENT_PDF_REQUEST';

export const ADD_SHAREHOLDER_PERCENTAGE_REQUEST =
  '@shareholders/ADD_SHAREHOLDER_PERCENTAGE_REQUEST';
export const ADD_SHAREHOLDER_PERCENTAGE_SUCCESS =
  '@shareholders/ADD_SHAREHOLDER_PERCENTAGE_SUCCESS';
export const ADD_SHAREHOLDER_PERCENTAGE_RESET = '@shareholders/ADD_SHAREHOLDER_PERCENTAGE_RESET';
export const ADD_SHAREHOLDER_PERCENTAGE_ERROR = '@shareholders/ADD_SHAREHOLDER_PERCENTAGE_ERROR';

export const FETCH_VIRS_PERSONS_LIST_REQUEST = '@shareholders/FETCH_VIRS_PERSONS_LIST_REQUEST';
export const FETCH_VIRS_PERSONS_LIST_SUCCESS = '@shareholders/FETCH_VIRS_PERSONS_LIST_SUCCESS';
export const FETCH_VIRS_PERSONS_LIST_ERROR = '@shareholders/FETCH_VIRS_PERSONS_LIST_ERROR';

export const SYNCHRONISE_WITH_JADIS_REQUEST = '@shareholders/SYNCHRONISE_WITH_JADIS_REQUEST';
export const SYNCHRONISE_WITH_JADIS_SUCCESS = '@shareholders/SYNCHRONISE_WITH_JADIS_SUCCESS';
export const SYNCHRONISE_WITH_JADIS_ERROR = '@shareholders/SYNCHRONISE_WITH_JADIS_ERROR';

export const CREATE_SHAREHOLDER_REQUEST = '@shareholders/CREATE_SHAREHOLDER_REQUEST';
export const CREATE_SHAREHOLDER_SUCCESS = '@shareholders/CREATE_SHAREHOLDER_SUCCESS';
export const CREATE_SHAREHOLDER_ERROR = '@shareholders/CREATE_SHAREHOLDER_ERROR';
export const CREATE_SHAREHOLDER_STATE_RESET = '@shareholders/CREATE_SHAREHOLDER_STATE_RESET';

export const UPDATE_SHAREHOLDER_REQUEST = '@shareholders/UPDATE_SHAREHOLDER_REQUEST';
export const UPDATE_SHAREHOLDER_SUCCESS = '@shareholders/UPDATE_SHAREHOLDER_SUCCESS';
export const UPDATE_SHAREHOLDER_ERROR = '@shareholders/UPDATE_SHAREHOLDER_ERROR';
export const SET_UPDATE_SHAREHOLDER_ID = '@shareholders/SET_UPDATE_SHAREHOLDER_ID';
export const UNSET_UPDATE_SHAREHOLDER_ID = '@shareholders/UNSET_UPDATE_SHAREHOLDER_ID';
export const UPDATE_SHAREHOLDER_STATE_RESET = '@shareholders/UPDATE_SHAREHOLDER_STATE_RESET';

export const ADDING_SHAREHOLDER_DATE = '@shareholders/ADD_SHAREHOLDER_DATE';
export const ADDING_SHAREHOLDER_DATE_RESET = '@shareholders/ADDING_SHAREHOLDER_DATE_RESET';

/** DATE DELETION */
export const SHOW_DATE_DELETION_DIALOG = '@shareholders/SHOW_DATE_DELETION_DIALOG';
export const RESET_DATE_DELETION_DIALOG = '@shareholders/RESET_DATE_DELETION_DIALOG';
export const DELETE_DATE_REQUEST = '@shareholders/DELETE_DATE_REQUEST';
export const DELETE_DATE_SUCCESS = '@shareholders/DELETE_DATE_SUCCESS';
export const DELETE_DATE_ERROR = '@shareholders/DELETE_DATE_ERROR';

/** DATA DELETION BY DATE */
export const SHOW_DATA_DELETION_BY_DATE_DIALOG = '@shareholders/SHOW_DATA_DELETION_BY_DATE_DIALOG';
export const RESET_DATA_DELETION_BY_DATE_DIALOG =
  '@shareholders/RESET_DATA_DELETION_BY_DATE_DIALOG';
export const DELETE_DATA_BY_DATE_REQUEST = '@shareholders/DELETE_DATA_BY_DATE_REQUEST';
export const DELETE_DATA_BY_DATE_SUCCESS = '@shareholders/DELETE_DATA_BY_DATE_SUCCESS';
export const DELETE_DATA_BY_DATE_ERROR = '@shareholders/DELETE_DATA_BY_DATE_ERROR';

/** SHAREHOLDER DELETION */
export const DELETE_SHAREHOLDERS_REQUEST = '@shareholders/DELETE_SHAREHOLDERS_REQUEST';
export const DELETE_SHAREHOLDERS_REQUEST_SUCCESS =
  '@shareholders/DELETE_SHAREHOLDERS_REQUEST_SUCCESS';
export const DELETE_SHAREHOLDERS_REQUEST_ERROR = '@shareholders/DELETE_SHAREHOLDERS_REQUEST_ERROR';
export const SHOW_SHAREHOLDER_DELETION_DIALOG = '@shareholders/SHOW_SHAREHOLDER_DELETION_DIALOG';
export const RESET_SHAREHOLDER_DELETION_DIALOG = '@shareholders/RESET_SHAREHOLDER_DELETION_DIALOG';

/** CLEAR SHAREHOLDER EVENT */
export const CLEAR_SHAREHOLDER_EVENT_REQUEST = '@shareholders/CLEAR_SHAREHOLDER_EVENT_REQUEST';
export const CLEAR_SHAREHOLDER_EVENT_SUCCESS = '@shareholders/CLEAR_SHAREHOLDER_EVENT_SUCCESS';
export const CLEAR_SHAREHOLDER_EVENT_ERROR = '@shareholders/CLEAR_SHAREHOLDER_EVENT_ERROR';
export const SHOW_CLEAR_SHAREHOLDER_EVENT_DIALOG =
  '@shareholders/SHOW_CLEAR_SHAREHOLDER_EVENT_DIALOG';
export const RESET_CLEAR_SHAREHOLDER_EVENT_DIALOG =
  '@shareholders/RESET_CLEAR_SHAREHOLDER_EVENT_DIALOG';

/** EXPANDING SHAREHOLDER */
export const SET_EXPAND_SHAREHOLDER = '@shareholders/SET_EXPAND';
export const SET_EXPAND_PARENTS = '@shareholders/SET_EXPAND_PARENTS';
export const SET_EXPAND_FILTERED_PARENTS = '@shareholders/SET_EXPAND_FILTERED_PARENTS';
export const RESET_EXPAND_SHAREHOLDERS = '@shareholders/RESET_EXPAND';

/** reset shareholdersData ir shareholdersSelectedDateData */
export const RESET_SHAREHOLDERS_DATA = '@shareholders/RESET_DATA';

/** set all shareholder list flat */
export const SET_ALL_SHAREHOLDERS = '@shareholders/SET_ALL_SHAREHOLDERS';

/** FILTER SHOW UNSIGNED */
export const FILTER_UNSIGNED_SHAREHOLDERS_TOGGLED = '@shareholders/FILTER_UNSIGNED';

export const SET_LAST_SIGNED_DATE = '@shareholders/SET_LAST_SIGNED_DATE';
