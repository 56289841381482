import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';

import { MessagesColumnHeader, MessagesTableColumn } from './tableState/tableInitialStateAndTypes';
import { useMessagesTableDispatch } from './Context';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';
import { UserMessage } from '../../store/userMessages/userMessageTypes';

interface Props {
  columnNames: MessagesColumnHeader[];
  allData: UserMessage[];
}

const MessagesTableFilterRow: React.FC<Props> = ({ columnNames, allData }) => {
  const { dispatch } = useMessagesTableDispatch();

  const handleCustomFilterChange = (column: MessagesTableColumn, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  function getUniqueOptions(name: MessagesTableColumn, data: UserMessage[]) {
    if (data) {
      return Array.from(
        new Set(data.filter((item) => item[name] !== null).map((item) => item[name]))
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames.map((column) => (
          <TableCustomFilterCellGeneric
            key={column.id}
            id={column.id}
            type={column.type}
            setFilter={handleCustomFilterChange}
            uniqueOptions={getUniqueOptions(column.id, allData)}
          />
        ))}
      </TableRow>
    </TableBody>
  );
};
export default MessagesTableFilterRow;
