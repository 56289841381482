import { getBorder } from '../../utilityFunctions/sharedUIFunctions';
import {
  getJustifyContent,
  getLeftBorderRadius,
  getLeftParameter,
  getRightBorderRadius,
  getVerticalBorder,
  getWidth
} from '../utilities/UIFunctions';
import {
  ActivityPeriod,
  ShareholdersHistory
} from '../../../../store/shareholders/shareholdersTypes';

export const customStylesColorBar = (
  isShareholderFromJADIS: boolean,
  startDate: string,
  endDate: string | null,
  shareholderStoppedParticipatingFromDate: string | null,
  virsEndDate: string | null,
  date: string,
  fillWithColor: string,
  startWithTriangle: boolean,
  borderColor: string,
  textColor: string,
  eventDate?: string,
  isSelectedDateEventDate?: boolean,
  newDate?: string,
  event?: ShareholdersHistory,
  currentActivityPeriod?: ActivityPeriod
) => {
  const isActivityPeriodEndDate = currentActivityPeriod?.endDate === eventDate;

  return {
    width: getWidth(
      isShareholderFromJADIS,
      startDate,
      endDate,
      shareholderStoppedParticipatingFromDate,
      virsEndDate,
      date,
      isSelectedDateEventDate,
      eventDate,
      newDate
    ),
    justifyContent: getJustifyContent(
      isShareholderFromJADIS,
      startDate,
      endDate,
      virsEndDate,
      date,
      isSelectedDateEventDate,
      eventDate,
      newDate
    ),
    backgroundColor: fillWithColor,
    left: getLeftParameter(startDate, date, virsEndDate, eventDate),
    borderTopLeftRadius: getLeftBorderRadius(startDate, date, startWithTriangle),
    borderBottomLeftRadius: getLeftBorderRadius(startDate, date, startWithTriangle),
    borderTopRightRadius: getRightBorderRadius(endDate, date, eventDate),
    borderBottomRightRadius: getRightBorderRadius(endDate, date, eventDate),
    borderTop: getBorder(borderColor),
    borderBottom: getBorder(borderColor),
    borderRight:
      startDate === date
        ? undefined
        : getVerticalBorder(
            startDate,
            endDate,
            date,
            startWithTriangle,
            borderColor,
            eventDate,
            isActivityPeriodEndDate
          ),
    borderLeft:
      eventDate === endDate && startDate !== eventDate
        ? undefined
        : getVerticalBorder(startDate, endDate, date, startWithTriangle, borderColor, eventDate),
    color: textColor
  };
};

export const customStylesTriangle = (borderColor: string, fillWithColor: string) => {
  return {
    borderLeft: getBorder(borderColor),
    borderBottom: getBorder(borderColor),
    background: `linear-gradient(to bottom left, transparent 50%, ${fillWithColor} 50%)`
  };
};
