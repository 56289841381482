import React from 'react';
import { Box, Container, Typography, Divider } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { withLoadedUser } from '../../containers/hoc/withLoadedUser';
import InstProvidedDataPage from './InstProvidedDataPage';
import { Roles, VirsisUser } from '../../store/virsis/dataTypes';
import { DataSubmission } from '../../components/InstitutionContextComponents/DataSubmission';
import AllowedTo from '../../containers/AllowedTo';

interface Props {
  currentUser: VirsisUser;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    lgWidth: {
      margin: 'auto',
      maxWidth: 1210
    },
    buttonBox: {
      width: '100%',
      display: 'flex'
    },
    icon: {
      height: 30,
      padding: '0px 10px',
      borderRight: '1px solid white'
    },
    text: {
      color: theme.palette.secondary.light,
      padding: '1px 15px'
    },
    titleContainer: {
      marginTop: '20px'
    },
    buttonContainer: {
      padding: '10px',
      marginTop: '20px',
      marginBottom: '30px'
    }
  })
);

const InstitutionPage: React.FC<Props> = ({ currentUser }) => {
  const classes = useStyles();

  return (
    <div className="data-page">
      <Container maxWidth="lg">
        <div className="table-title">
          <Box>
            <Typography variant="h2" className="text-black" gutterBottom>
              {`Jūs esate prisijungę kaip: ${currentUser.name}, ${currentUser.amnName}`}
            </Typography>
            <Typography className="text-grey">
              Jūsų paskutinis apsilankymas: {currentUser.recentActivity}
            </Typography>
          </Box>
        </div>
      </Container>
      <Divider className={classes.lgWidth} />
      <AllowedTo
        roles={[
          Roles.ROLE_VIRS_EDIT,
          Roles.ROLE_KM_TIRAZO_TIKRINIMAS_EDIT,
          Roles.ROLE_KM_METINE_IMOKA_EDIT,
          Roles.ROLE_VIEA_EDIT,
          Roles.ROLE_ZEIT_EDIT,
          Roles.ROLE_LRTK_EDIT,
          Roles.ROLE_ISTAIG_EDIT,
          Roles.ROLE_KM_TIRAZO_TIKRINIMAS_VIEW,
          Roles.ROLE_KM_METINE_IMOKA_VIEW,
          Roles.ROLE_VIEA_VIEW,
          Roles.ROLE_ZEIT_VIEW,
          Roles.ROLE_LRTK_VIEW
        ]}
      >
        <DataSubmission />
        <Divider className={classes.lgWidth} />
        <InstProvidedDataPage />
      </AllowedTo>
    </div>
  );
};

export default withLoadedUser(InstitutionPage);
