import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
  ParametersDataColumnHeader,
  ParametersDataHeaderField
} from './tableState/tableInitialStateAndTypes';
import { useParametersDataTableDispatch, useParametersDataTableState } from './Context';
import { VirsisParameter } from '../../store/parameters/parametersDataTypes';
import { convertVirsisYesNoToLabel } from '../../utils/tableDataFunctions';
import { VirsisYesNoString } from '../../utils/tableTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: ParametersDataColumnHeader[];
}

export const ParametersDataTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = useParametersDataTableState();
  const { dispatch } = useParametersDataTableDispatch();

  const handleCustomFilterChange = (column: ParametersDataHeaderField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData =
    useSelector((state: ApplicationState) => state.virsisParameters.virsisParameters) || [];

  function getUniqueOptions(name: ParametersDataHeaderField, data: VirsisParameter[]): string[] {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] !== null)
            .map((item) => {
              if (name === 'isUrl' || name === 'isRequired') {
                return convertVirsisYesNoToLabel(item[name]);
              }
              if (
                name === 'parameterValue' &&
                (item.parameterValue === VirsisYesNoString.NO ||
                  item.parameterValue === VirsisYesNoString.YES)
              ) {
                return convertVirsisYesNoToLabel(item.parameterValue);
              }
              return String(item[name]);
            })
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleCustomFilterChange}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
