import React from 'react';
import { CellBorderThickness, CellDefinition, TableExportDefinition } from './types';
import { renderToString } from 'react-dom/server';
import moment from 'moment';

interface TableHeaderProps<TData> {
  definition: TableExportDefinition<TData>;
}

interface TableProps<TData> {
  definition: TableExportDefinition<TData>;
  data: TData[];
}

const WIDTH_SCALE_INITIAL = 8;

function getBorderWidth(definition: CellBorderThickness) {
  switch (definition) {
    case 'hair':
      return '1px';
    case 'thin':
      return '2px';
    case 'medium':
      return '3px';
    case 'thick':
      return '4px';
  }
}

function filterVisibleColumns<TData>(
  definition: TableExportDefinition<TData>
): TableExportDefinition<TData> {
  const columnGroups = definition.columnGroups
    .map(({ columns, ...columnGroup }) => ({
      ...columnGroup,
      columns: columns.filter(({ visible }) => visible)
    }))
    .filter(({ columns: { length } }) => length > 0);
  return { ...definition, columnGroups };
}

function getTableTotalWidth<TData>(definition: TableExportDefinition<TData>) {
  return definition.columnGroups
    .flatMap(({ columns }) => columns)
    .map(({ style }) => style?.width || 0)
    .reduce((prev, value) => prev + value * WIDTH_SCALE_INITIAL, 0);
}

function TableHeader<TData>({ definition }: TableHeaderProps<TData>) {
  const hasSecondHeaderRow = definition.columnGroups.some(({ header }) => header);

  return (
    <thead>
      <tr>
        {definition.columnGroups.flatMap(({ header, columns }) =>
          columns.slice(0, header ? 1 : columns.length).map((column) => (
            <th
              rowSpan={header || !hasSecondHeaderRow ? 1 : 2}
              colSpan={(header && columns.length) || 1}
              align="center"
              style={{
                border: 'solid black 2px',
                width:
                  (column.style?.width || 1) *
                  WIDTH_SCALE_INITIAL *
                  ((header && columns.length) || 1),
                padding: 5
              }}
              key={Math.random()}
            >
              {header || column.header}
            </th>
          ))
        )}
      </tr>

      {hasSecondHeaderRow && (
        <tr>
          {definition.columnGroups
            .filter(({ header }) => !!header)
            .flatMap(({ header, columns }) =>
              columns.map((column) => (
                <th
                  align="center"
                  style={{
                    border: 'solid black 2px',
                    width: (column.style?.width || 1) * WIDTH_SCALE_INITIAL,
                    padding: 5
                  }}
                  key={Math.random()}
                >
                  {header && column.header}
                </th>
              ))
            )}
        </tr>
      )}
    </thead>
  );
}

function TableBody<TData>({ definition, data }: TableProps<TData>) {
  const columns = definition.columnGroups.flatMap(({ columns }) => columns);

  const cells: CellDefinition[][][] = data.map((value) => {
    const rowGroup = columns.map(({ cellMapper }) => cellMapper(value));
    const groupRowCount = rowGroup.reduce((prev, { length }) => (length > prev ? length : prev), 0);

    const rowGroupCells: CellDefinition[][] = [];
    for (let i = 0; i < groupRowCount; i++) {
      const rowCells = rowGroup.map((row) => row[i]).filter((value) => !!value);
      rowGroupCells.push(rowCells);
    }

    return rowGroupCells;
  });

  return (
    <tbody>
      {cells.flatMap((rowGroup) =>
        rowGroup.map((row) => (
          <tr key={Math.random()}>
            {row.map((cell) => (
              <TableCell {...cell} key={Math.random()} />
            ))}
          </tr>
        ))
      )}
    </tbody>
  );
}

function TableCell(definition: CellDefinition) {
  const { style } = definition;

  return (
    <td
      align={style?.alignment?.horizontal || 'center'}
      rowSpan={style?.rowSpan}
      colSpan={style?.colSpan}
      style={{
        borderColor: 'black',
        borderWidth: getBorderWidth(style?.border || 'hair'),
        borderStyle: 'solid',
        backgroundColor: style?.fill && `#${style.fill.slice(2)}`,
        padding: 5
      }}
    >
      {definition.values
        .flatMap((value) => [<br key={Math.random()} />, <span key={Math.random()}>{value}</span>])
        .slice(1)}
    </td>
  );
}

function Table<TData>({ data, definition }: TableProps<TData>) {
  const { user, navigationPath, header, title } = definition;
  const tableWidth = getTableTotalWidth(definition);

  return (
    <body style={{ padding: 0, margin: 0, fontFamily: 'Open Sans', display: 'flex' }}>
      <div style={{ padding: '0 20px' }}>
        {user && (
          <p style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{moment().format('YYYY-MM-DD')}</span>
            <span>VIRSIS</span>
            <span></span>
          </p>
        )}

        {user && <p>Dokumentą suformavęs asmuo: {user}</p>}
        {navigationPath && navigationPath.length > 0 && <p>{navigationPath.join(' / ')}</p>}
        {header && <h2>{header}</h2>}
        {title && <h3>{title}</h3>}

        <table style={{ width: tableWidth, borderCollapse: 'collapse' }}>
          <TableHeader definition={definition} />
          <TableBody definition={definition} data={data} />
        </table>
      </div>
    </body>
  );
}

export function getHTMLExporter<TData>(
  definition: TableExportDefinition<TData>
): (data: TData[]) => void {
  const visibleDefinition = filterVisibleColumns(definition);
  const { onBlob } = visibleDefinition;

  return (data) => {
    const blob: Blob = new Blob([renderToString(<Table definition={definition} data={data} />)]);
    onBlob?.(blob);
  };
}
