import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  path: string;
  noHoverUnderline?: boolean;
}

export const LinkRouter: React.FC<Props> = ({ path, children, noHoverUnderline }) => {
  const classes = makeStyles({
    noUnderline: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: noHoverUnderline ? 'none' : 'auto'
      }
    }
  })();
  return (
    <Link to={path} className={classes.noUnderline}>
      {children}
    </Link>
  );
};
