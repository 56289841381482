import React from 'react';
import { Grid, TableCell, Typography } from '@material-ui/core/';
import { UserMessage } from '../../store/userMessages/userMessageTypes';
import { ViewDocumentButton } from '../../components/TableButtons/RowActionButtons/ViewDocumentButton';
import { RowStyleOnDifferentStatus } from '../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { CustomCheckboxButton } from '../../components/TableButtons/CustomCheckboxButton';

interface Props {
  record: UserMessage;
  viewInDialog: () => void;
  checkMessage: () => void;
  checked: boolean;
}

export const MessageTableRow: React.FC<Props> = ({
  record: { read, header, receivedDate },
  viewInDialog,
  checkMessage,
  checked
}) => {
  return (
    <RowStyleOnDifferentStatus status={read ? 'PASIRASYTAS' : 'RUOSIAMAS'}>
      <TableCell style={{ width: '150px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckboxButton setCheckboxState={checkMessage} checked={checked} />
          <Typography>{receivedDate}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant={read ? 'body1' : 'body2'}> {header}</Typography>
          <ViewDocumentButton onClick={viewInDialog} />
        </Grid>
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
