import {
  GraphicalPerceptionTableActions,
  GraphicalPerceptionTableState
} from './initialStateAndTypes';

export const graphicalPerceptionPageReducer = (
  state: GraphicalPerceptionTableState,
  action: GraphicalPerceptionTableActions
): GraphicalPerceptionTableState => {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        activeTab: action.recordType
      };
    case 'SET_ACTIVE_FILTER':
      return {
        ...state,
        isActive: action.isActive
      };
    default:
      return state;
  }
};
