import {
  FundsReceivedDataTableCustomFilter,
  FundsReceivedDataTableState,
  FundsReceivedDataVirsTableField,
  fundsReceivedTableVirsColumns
} from './tableTypesAndActions';
import { FundsReceivedDataVirs } from '../../../store/fundsReceived/fundsReceivedDataTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';
import { replaceDecimalPoint, stableSortWrapped } from '../../../utils/tableDataFunctions';

export function updateFundsReceivedDataCustomFilter(
  filter: FundsReceivedDataTableCustomFilter,
  filterBy: FundsReceivedDataVirsTableField,
  value: string | null
): FundsReceivedDataTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

function getCustomFilteredEditionCheckData(
  data: FundsReceivedDataVirs[],
  filter: FundsReceivedDataTableCustomFilter
): FundsReceivedDataVirs[] {
  let filteredData = JSON.parse(JSON.stringify(data));

  function createCustomFilter(field: FundsReceivedDataVirsTableField) {
    return (record: FundsReceivedDataVirs): boolean => {
      const [valueToFilterBy] = filter[field];
      if (valueToFilterBy) {
        if (field === 'outletName') {
          const recordsMatchingOutlets = record.fundsReceivedOutlets.filter((outlet) =>
            outlet.outletName
              .toString()
              .toLowerCase()
              .includes(valueToFilterBy.toString().toLowerCase())
          );
          return recordsMatchingOutlets !== undefined && recordsMatchingOutlets.length > 0;
        }
        const recordFieldToTest = record[field];
        if (recordFieldToTest) {
          return replaceDecimalPoint(recordFieldToTest.toString())
            .toLowerCase()
            .includes(replaceDecimalPoint(valueToFilterBy.toString().toLowerCase()));
        }
        return false;
      }
      return true;
    };
  }

  const allTableFilters = [
    createCustomFilter('fundsReceivedYear'),
    createCustomFilter('fundsReceivedSum'),
    createCustomFilter('fundsSourceCode'),
    createCustomFilter('fundsSourceName'),
    createCustomFilter('transactionType'),
    createCustomFilter('outletName')
  ];

  filteredData = filteredData.filter((record: FundsReceivedDataVirs) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });
  return filteredData;
}

const getSortBy = (sortBy: FundsReceivedDataVirsTableField): string => {
  if (sortBy === 'outletName') {
    return 'fundsReceivedOutlets.outletName';
  }
  return sortBy;
};

export function getFilterAndSortTableData(
  data: FundsReceivedDataVirs[],
  tableState: FundsReceivedDataTableState
) {
  const filteredData: FundsReceivedDataVirs[] = getCustomFilteredEditionCheckData(
    data,
    tableState.customFilter
  );
  const sortBy = getSortBy(tableState.sortBy);
  return stableSortWrapped(filteredData, tableState.order, sortBy);
}

export function getTableExportDefinition(
  tableState: FundsReceivedDataTableState
): TableExportDefinition<FundsReceivedDataVirs> {
  const { stringValueColumn, mappedValueColumn } = getColumnBuilder<
    FundsReceivedDataVirs,
    FundsReceivedDataVirsTableField
  >(fundsReceivedTableVirsColumns, tableState.columnsDisplayStatus);

  return {
    title: 'VIRS gautos lėšos',
    columnGroups: [
      {
        columns: [
          stringValueColumn('fundsReceivedYear', { width: 10 }),
          stringValueColumn('fundsReceivedSum', { width: 15 }),
          stringValueColumn('fundsSourceName', { width: 45 }),
          stringValueColumn('fundsSourceCode', { width: 25 }),
          mappedValueColumn(
            'outletName',
            ({ fundsReceivedOutlets }) => [
              {
                values: fundsReceivedOutlets.map(({ outletName }) => outletName)
              }
            ],
            { width: 45 }
          ),
          stringValueColumn('transactionType', { width: 25 })
        ]
      }
    ]
  };
}
