import {
  setDocumentNumberAndValidate,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../utils/InputValueFunctions';
import {
  EthicalNonComplianceNewRecordAction,
  EthicalNonComplianceNewRecordState,
  EthicalNonComplianceRecordState
} from './initialEthicalNonComplianceStateAndTypes';
import {
  setEthicalNonComplianceVirsAndUpdateOptions,
  validateNewRowState,
  setDynamicValidationConstraints
} from './rowReducerFunctions';

export const newEthicalNonComplianceRowReducer = (
  state: EthicalNonComplianceNewRecordState,
  action: EthicalNonComplianceNewRecordAction
): EthicalNonComplianceNewRecordState => {
  switch (action.type) {
    case 'VIRS_SELECTED':
      return setDynamicValidationConstraints({
        ...state,
        ...setEthicalNonComplianceVirsAndUpdateOptions(
          state as unknown as EthicalNonComplianceRecordState,
          action.virs,
          action.fictitiousOutlet
        ),
        showFindVirsDialog: false
      });
    case 'DECISION_DATE_CHANGED':
      return {
        ...state,
        decisionDate: updateDateValue(action.decisionDate, state.decisionDate),
        validFrom: updateMinDates(action.decisionDate, 'decisionDate', state.validFrom)
      };
    case 'DOCUMENT_NUMBER_CHANGED':
      return {
        ...state,
        documentNumber: setDocumentNumberAndValidate(action.documentNumber, state.documentNumber)
      };
    case 'OUTLET_LIST_CHANGED':
      return setDynamicValidationConstraints({
        ...state,
        outlets: {
          ...state.outlets,
          values: action.outlets,
          validated: action.outlets.length > 0,
          error: !(action.outlets.length > 0),
          helperText: action.outlets.length > 0 ? '' : 'Būtina VIP(-ai)'
        }
      });
    case 'DECISION_STATUS_CHANGED':
      return {
        ...state,
        decisionStatus: {
          ...state.decisionStatus,
          id: action.decisionStatus?.ethicalNonComplianceId,
          error: !action.decisionStatus
        }
      };
    case 'VALID_FROM_DATE_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        decisionDate: updateMaxDates(action.validFrom, 'validFrom', state.decisionDate),
        validTo: updateMinDates(
          action.validFrom ? action.validFrom.add(1, 'day') : null,
          'validFrom',
          state.validTo
        )
      };
    case 'VALID_TO_DATE_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(
          action.validTo ? action.validTo.subtract(1, 'day') : null,
          'validTo',
          state.validFrom
        )
      };
    case 'CREATE_RECORD_CLICKED':
      return validateNewRowState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'FIND_VIRS_DIALOG_OPENED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
