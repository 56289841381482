import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TableCell } from 'pdfmake/interfaces';
import { CellDefinition, defaultCellStyle, TableExportDefinition } from './types';

const SIZE_A4_WIDTH = 595.28;
const SIZE_A4_HEIGHT = 841.89;
const PAGE_MARGINS = 40;
const CELL_PADDING = 4.5;

const WIDTH_SCALE_INITIAL = 5.5;
const FONT_SIZE_INITIAL = 9;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getPageOrientation(tableWidth: number) {
  if (tableWidth > SIZE_A4_WIDTH - PAGE_MARGINS * 2) {
    return 'landscape';
  }
  return 'portrait';
}

function filterVisibleColumns<TData>(
  definition: TableExportDefinition<TData>
): TableExportDefinition<TData> {
  const columnGroups = definition.columnGroups
    .map(({ columns, ...columnGroup }) => ({
      ...columnGroup,
      columns: columns.filter(({ visible }) => visible)
    }))
    .filter(({ columns: { length } }) => length > 0);
  return { ...definition, columnGroups };
}

function getTableTotalWidth<TData>(definition: TableExportDefinition<TData>) {
  return definition.columnGroups
    .flatMap(({ columns }) => columns)
    .map(({ style }) => style?.width || 0)
    .reduce((prev, value) => prev + value * WIDTH_SCALE_INITIAL, 0);
}

function getWidths<TData>(widthScale: number, definition: TableExportDefinition<TData>) {
  return definition.columnGroups
    .flatMap(({ columns }) => columns)
    .map(({ style }) => (style?.width && style?.width * widthScale - CELL_PADDING * 2) || 'auto');
}

function getHeaderCells<TData>(definition: TableExportDefinition<TData>): TableCell[][] {
  const hasSecondHeaderRow = definition.columnGroups.some(({ header }) => header);

  const headerCells: TableCell[][] = [
    definition.columnGroups.flatMap(({ header, columns }) =>
      columns.map((column) => ({
        text: header || column.header || '',
        colSpan: (header && columns.length) || 1,
        rowSpan: header || !hasSecondHeaderRow ? 1 : 2,
        alignment: 'center'
      }))
    )
  ];

  if (hasSecondHeaderRow) {
    headerCells.push(
      definition.columnGroups.flatMap(({ header, columns }) =>
        columns.map((column) => ({
          text: (header && column.header) || '',
          alignment: 'center'
        }))
      )
    );
  }

  return headerCells;
}

function mapCellDefinition(definition: CellDefinition): TableCell[] {
  const rowSpan = definition.style?.rowSpan || 1;
  const cells: TableCell[] = [
    {
      text: definition.values.join('\n'),
      fillColor: definition.style?.fill && `#${definition.style.fill.slice(2)}`,
      fillOpacity: 1,
      alignment: definition.style?.alignment?.horizontal || defaultCellStyle.alignment?.horizontal,
      rowSpan
    }
  ];
  for (let i = 1; i < rowSpan; i++) {
    cells.push('');
  }
  return cells;
}

function getItemCells<TData>(definition: TableExportDefinition<TData>, data: TData): TableCell[][] {
  const columns = definition.columnGroups
    .flatMap(({ columns }) => columns)
    .map(({ cellMapper }) => cellMapper(data).flatMap(mapCellDefinition));

  const totalRows = columns.reduce((prev, { length }) => (length > prev ? length : prev), 0);
  const rows: TableCell[][] = [];

  for (let i = 0; i < totalRows; i++) {
    rows.push(columns.map((cells) => cells[i] || ''));
  }

  return rows;
}

function getDataCells<TData>(
  definition: TableExportDefinition<TData>,
  data: TData[]
): TableCell[][] {
  return data
    .map((dataItem) => getItemCells(definition, dataItem))
    .reduce((prev, value) => [...prev, ...value], []);
}

function getAdjustedWidthScale<TData>(definition: TableExportDefinition<TData>) {
  const tableWidth = getTableTotalWidth(definition);
  const maxContentWidth = SIZE_A4_HEIGHT - PAGE_MARGINS * 2;
  return tableWidth > maxContentWidth
    ? (maxContentWidth / tableWidth) * WIDTH_SCALE_INITIAL
    : WIDTH_SCALE_INITIAL;
}

function getPdfTable<TData>(definition: TableExportDefinition<TData>, data: TData[]): Content {
  const adjustedWidthScale = getAdjustedWidthScale(definition);

  return {
    table: {
      widths: getWidths(adjustedWidthScale, definition),
      body: [...getHeaderCells(definition), ...getDataCells(definition, data)]
    },
    fontSize: FONT_SIZE_INITIAL * (adjustedWidthScale / WIDTH_SCALE_INITIAL)
  };
}

function getPdfContent<TData>(definition: TableExportDefinition<TData>, data: TData[]) {
  const { title, header } = definition;
  const navigationPath = definition.navigationPath;

  const content: Content[] = [
    {
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              text: moment().format('YYYY-MM-DD'),
              border: [false, false, false, false]
            },
            { text: 'VIRSIS', alignment: 'center', border: [false, false, false, false] }
          ]
        ]
      },
      margin: [-CELL_PADDING, 0, 0, -CELL_PADDING],
      fontSize: 9
    },
    {
      table: {
        widths: ['auto'],
        body: [
          [
            {
              text: `Dokumentą suformavęs asmuo: ${definition.user}`,
              alignment: 'center',
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: [-CELL_PADDING, 0, 0, 10],
      fontSize: 9
    }
  ];

  if (navigationPath && navigationPath.length > 0) {
    content.push({
      text: navigationPath.join(' / '),
      fontSize: 9,
      margin: [0, 0, 0, 20]
    });
  }

  if (header) {
    content.push({ text: header, fontSize: 13, margin: [0, 0, 0, 10] });
  }

  if (title) {
    content.push({ text: title, margin: [0, 0, 0, 10] });
  }
  content.push(getPdfTable(definition, data));

  return content;
}

export function getPDFExporter<TData>(
  definition: TableExportDefinition<TData>
): (data: TData[]) => void {
  const visibleDefinition = filterVisibleColumns(definition);
  const { onBlob } = visibleDefinition;

  return (data) => {
    const tableWidth = getTableTotalWidth(visibleDefinition);
    const document = pdfMake.createPdf({
      content: getPdfContent(visibleDefinition, data),
      pageOrientation: getPageOrientation(tableWidth),
      pageMargins: PAGE_MARGINS
    });
    document.getBlob((blob: Blob) => onBlob?.(blob));
  };
}

export function getPDFPrinter<TData>(
  definition: TableExportDefinition<TData>
): (data: TData[]) => void {
  const visibleDefinition = filterVisibleColumns(definition);

  return (data) => {
    const tableWidth = getTableTotalWidth(visibleDefinition);
    const document = pdfMake.createPdf({
      content: getPdfContent(visibleDefinition, data),
      pageOrientation: getPageOrientation(tableWidth),
      pageMargins: PAGE_MARGINS
    });
    document.print();
  };
}
