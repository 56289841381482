import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import virsisTheme from '../../../style/virsisTheme';
import { ShareholderOptionsPopover } from './ShareholderOptionsPopover';
import {
  ClearShareholderPercentageRecord,
  ShareholdersProps
} from '../../../store/shareholders/shareholdersTypes';

export interface Props {
  index: number;
  percentageRecord?: ClearShareholderPercentageRecord;
  selectedDate?: string;
  isItGroupShareholder?: boolean;
  shareholder: ShareholdersProps;
}

const useStyles = makeStyles(() => ({
  button: {
    padding: '10px 0px 10px 5px',
    '&:hover': {
      backgroundColor: virsisTheme.palette.secondary.light
    },
    '&:hover svg': {
      fill: virsisTheme.palette.primary.main
    }
  }
}));

const ShareholderOptions: React.FC<Props> = ({
  index,
  percentageRecord,
  selectedDate,
  isItGroupShareholder,
  shareholder
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className={classes.button} onClick={handleClick}>
        <MoreVertIcon
          fontSize="small"
          style={
            anchorEl
              ? {
                  fill: virsisTheme.palette.primary.main
                }
              : {}
          }
        />
      </IconButton>
      {percentageRecord && (
        <ShareholderOptionsPopover
          index={index}
          percentageRecord={percentageRecord}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          selectedDate={selectedDate}
          isItGroupShareholder={isItGroupShareholder}
          shareholder={shareholder}
        />
      )}
    </>
  );
};

export default ShareholderOptions;
