import React from 'react';
import { makeStyles, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ActivityPeriod, ShareholdersData } from '../../../store/shareholders/shareholdersTypes';
import {
  addShareholderDateReset,
  setSelectedDate
} from '../../../store/shareholders/shareholdersActions';
import { TableHeadDateCell } from './Components/TableHeadDateCell';
import { ShareholderAddButton } from '../../../components/ShareholderComponents/TableButtons/ShareholderAddButton';
import AllowedTo from '../../AllowedTo';
import { Roles } from '../../../store/virsis/dataTypes';
import { getShareholderDateTooltip } from './utilities/UIfunctions';

const useStyles = makeStyles({
  headerRow: {
    borderLeft: 'none',
    borderRight: 'none',
    height: '88px'
  }
});

interface Props {
  allEventsDates: string[];
  shareholdersData: ShareholdersData;
  openCalendar: () => void;
  noValidDates: boolean;
  allShareholdersFromJADIS: boolean;
  loadingShareholdersData: boolean;
  activityPeriods: ActivityPeriod[];
  virsStartDate: string;
}

const ShareholdersTableHeader: React.FC<Props> = ({
  allEventsDates,
  shareholdersData,
  openCalendar,
  noValidDates,
  allShareholdersFromJADIS,
  activityPeriods,
  loadingShareholdersData,
  virsStartDate
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  const allShareholders = shareholdersData.shareholders;

  const handleDateClick = (date: string) => {
    reduxDispatch(addShareholderDateReset());
    reduxDispatch(setSelectedDate(date));
  };

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        <TableCell className="header-level-column header-sticky-col first-col">
          <div className="fake-header-cell">
            <Typography variant="h4">Lygis</Typography>
          </div>
        </TableCell>
        <TableCell className="header-share-column header-sticky-col second-col">
          <div className="fake-header-cell shareholder-cell">
            <Typography variant="h4">Dalyvis</Typography>
          </div>
        </TableCell>
        {allEventsDates.map((date) => (
          <TableHeadDateCell
            key={date + shareholdersData.virsShareholderId}
            date={date}
            allShareholders={allShareholders}
            handleDateClick={handleDateClick}
          />
        ))}
        <TableCell className="last-column">
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <ShareholderAddButton
              onClick={openCalendar}
              tooltipMessage={getShareholderDateTooltip(noValidDates, allShareholdersFromJADIS)}
              disabled={noValidDates || allShareholdersFromJADIS || loadingShareholdersData}
            />
          </AllowedTo>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ShareholdersTableHeader;
