import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EnterpriseData } from '../../../../store/enterprises/enterprisesTypes';
import {
  EnterpriseType,
  EnterpriseTypeShort
} from '../../../../store/classifiers/classifiersTypes';
import { DatePickerState, DropdownStateGeneric } from '../../../../utils/tableTypes';

export const initialEnterpriseRecordState: EnterpriseRecordState = {
  enterpriseType: {
    value: null,
    id: 'enterpriseTypeName',
    error: false,
    helperText: 'reikia pasirinkti iš sąrašo',
    placeholder: 'Veiklos rūšis',
    validated: false,
    options: []
  },
  registrationDate: null,
  deregistrationDate: null,
  validFrom: {
    value: null,
    error: false,
    validated: false,
    minDates: [],
    maxDates: [],
    helperText: ''
  },
  validTo: {
    value: null,
    error: false,
    validated: true,
    minDates: [],
    maxDates: [],
    helperText: ''
  }
};

export interface EnterpriseRecordState {
  enterpriseType: DropdownStateGeneric<EnterpriseTypeShort>;
  registrationDate: string | null;
  deregistrationDate: string | null;
  validFrom: DatePickerState;
  validTo: DatePickerState;
}

export const intialEnterpriseEditRecordState: EnterpriseEditRecordState = {
  editingOn: false,
  ...initialEnterpriseRecordState,
  updateRecordConfirmationOn: false,
  updateDateConfirmationOn: false,
  unsetDateConfirmationOn: false,
  removeRecordConfirmationOn: false
};

export interface EnterpriseEditRecordState extends EnterpriseRecordState {
  editingOn: boolean;
  updateRecordConfirmationOn: boolean;
  updateDateConfirmationOn: boolean;
  unsetDateConfirmationOn: boolean;
  removeRecordConfirmationOn: boolean;
}

export const initialEnterpriseNewRecordState: EnterpriseNewRecordState = {
  ...initialEnterpriseRecordState,
  editingOn: false,
  createRecordConfirmationOn: false
};

export interface EnterpriseNewRecordState extends EnterpriseRecordState {
  editingOn: boolean;
  createRecordConfirmationOn: boolean;
}

type EnterpriseDataRecordAction =
  | {
      type: 'ENTERPRISE_TYPE_CHANGED';
      enterpriseType: EnterpriseTypeShort | null;
    }
  | { type: 'VALID_FROM_CHANGED'; validFrom: MaterialUiPickersDate | null }
  | { type: 'VALID_TO_CHANGED'; validTo: MaterialUiPickersDate | null };

export type EnterpriseDataNewRecordAction =
  | EnterpriseDataRecordAction
  | {
      type: 'NEW_RECORD_STATE_INITIALIZED';
      enterpriseTypes: EnterpriseType[];
      legalRegistrationDate: string;
      legalDeregistrationDate: string | null;
    }
  | { type: 'SHOW_CREATE_RECORD_CONFIRMATION_OR_ERRORS' }
  | { type: 'CLOSE_CREATE_RECORD_CONFIRMATION' };

export type EnterpriseDataRowAction =
  | EnterpriseDataRecordAction
  | {
      type: 'INITIALIZE_RECORD_STATE';
      record: EnterpriseData;
      enterpriseTypes: EnterpriseType[];
      legalRegistrationDate: string;
      legalDeregistrationDate: string | null;
    }
  | { type: 'TOGGLE_EDITING_RECORD' }
  | { type: 'SHOW_UPDATE_RECORD_CONFIRMATION_OR_ERRORS' }
  | { type: 'SHOW_UPDATE_DATE_CONFIRMATION_OR_ERRORS' }
  | { type: 'DISPLAY_REMOVE_RECORD_CONFIRMATION'; status: boolean }
  | { type: 'RESET_RECORD_STATE' };
