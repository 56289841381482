import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core/';
import { useShareholdersTableState } from '../ShareholdersTableWithContext';
import { virsRowStyles } from '../CustomStyles/virsRowStyles';

interface Props {
  date: string;
  startDate: string;
  endDate: string | null;
  fillWithColor: string;
  borderColor: string;
  textColor: string;
  value: string | ReactNode;
  allEventsDates: string[];
}

export const ColorBarVirsByDay: React.FC<Props> = ({
  date,
  startDate,
  endDate,
  fillWithColor,
  borderColor,
  textColor,
  value,
  allEventsDates
}) => {
  const {
    state: {
      periodFilter,
      customFilter: { shareholderPeriodDateFrom }
    }
  } = useShareholdersTableState();

  const [periodFilterFromDate] = shareholderPeriodDateFrom;
  const [firstDateOfFilteredDates] = allEventsDates;

  const customStyles = virsRowStyles(
    startDate,
    endDate,
    date,
    fillWithColor,
    false,
    borderColor,
    textColor,
    periodFilterFromDate,
    periodFilter
  );

  return (
    <>
      <div className="color-row-bar" style={customStyles}>
        {firstDateOfFilteredDates === date && (
          <Typography
            variant="h5"
            style={{
              display: 'flex',
              textAlign:
                date !== startDate || (!periodFilterFromDate && startDate === date)
                  ? 'left'
                  : 'center'
            }}
          >
            {value}
          </Typography>
        )}
      </div>
    </>
  );
};
