import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormikProps } from 'formik';

import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';

import { resetForm, State, submitForm } from './store';
import { defaultValues, FileInput } from './types';
import { GenericFileInput } from '../../../components/FormikFields/GenericFormikWrappers/GenericFileField';
import { getFileName } from './utilities';
import * as Yup from 'yup';
import { FILE_TYPE_CHECK } from '../../../utils/tableTypes';

const FormContent: React.FC<FormikProps<FileInput>> = (formikProps) => {
  return (
    <div>
      <GenericFileInput
        label={getFileName(formikProps.values.fileType)}
        formikProps={formikProps}
        field="fileContents"
      />
    </div>
  );
};

export const FileForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error } = useSelector((state: State) => ({
    ...state.form,
    data: state.data
  }));

  const validationSchema = Yup.object().shape({
    fileContents: Yup.mixed().test({
      message: `Failas turi būti .pdf formato`,
      test: (file) => {
        console.log(file);
        return FILE_TYPE_CHECK.test(file?.name);
      }
    }),
    fileType: Yup.string()
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      // @ts-ignore
      validationSchema={validationSchema}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => {
        // @ts-ignore
        dispatch(submitForm(value));
      }}
      loading={isLoading}
      open={open}
      // @ts-ignore
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="Naudotojų vadovų ir naudojimo salygų įvedimas"
    />
  );
};
