import {
  EthicalNonComplianceTableAction,
  EthicalNonComplianceTableState
} from './tableInitialStateAndTypes';
import { getTableExportDefinition } from './tableStateFunctions';
import { columnNames } from '../Table';

export const ethicalNonComplianceTableReducer = (
  state: EthicalNonComplianceTableState,
  action: EthicalNonComplianceTableAction
): EthicalNonComplianceTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn
      };
    case 'COLUMN_DISPLAY_TOGGLED': {
      const columnsDisplayStatus = {
        ...state.columnsDisplayStatus,
        [action.column]: !state.columnsDisplayStatus[action.column]
      };
      return {
        ...state,
        columnsDisplayStatus,
        tableExportDefinition: getTableExportDefinition(columnNames, columnsDisplayStatus)
      };
    }
    // case 'DOCUMENT_DIALOG_OPENED':
    //   return {
    //     ...state,
    //     showDocumentDialog: true
    //   };
    // case 'DOCUMENT_DIALOG_CLOSED':
    //   return {
    //     ...state,
    //     showDocumentDialog: false
    //   };
    // case 'HIDE_NEW_RECORD':
    //   return {
    //     ...state,
    //     showNewRecord: false
    //   };
    case 'SHOW_NEW_RECORD_CLICKED':
      return {
        ...state,
        showNewRecord: !state.showNewRecord,
        companyCode: action.companyCode
      };
    case 'NEW_RECORD_CREATED':
      return {
        ...state,
        showNewRecord: false
      };
    case 'RESET_COMPANY_CODE':
      return {
        ...state,
        companyCode: null
      };
    default:
      return state;
  }
};
