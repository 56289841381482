import { ActionType } from 'typesafe-actions';
import {
  initSign,
  resetUnisign,
  setCancelSuccess,
  setError,
  setLoading,
  setUnisignData,
  setUnisignStatus,
  setVerifySuccess,
  verifySign
} from './unisignActions';

export interface UnisignDataState {
  error?: string;
  verifySuccess?: boolean;
  cancelSuccess?: boolean;
  unisignData?: UnisignData;
  isLoading: boolean;
  unisignStatus: UnisignStatus;
}

export interface UnisignData {
  transactionId: string;
  signUrl: string;
}

export enum UnisignStatus {
  LOADING,
  SIGNING,
  COMPLETE
}

export const INIT_SIGN = '@unsign/INIT_SIGN';
export const SET_LOADING = '@unsign/SET_LOADING';
export const VERIFY_SIGN = '@unsign/VERIFY_SIGN';
export const SET_UNISIGN_DATA = '@unsign/SET_UNISIGN_DATA';
export const SET_ERROR = '@unsign/SET_ERROR';
export const SET_VERIFY_SUCCESS = '@unsign/SET_VERIFY_SUCCESS';
export const SET_UNISIGN_STATUS = '@unsign/SET_UNISIGN_STATUS';
export const RESET = '@unsign/RESET';
export const SET_CANCEL_SUCCESS = '@unsign/SET_CANCEL_SUCCESS';

export type Action =
  | ActionType<typeof initSign>
  | ActionType<typeof verifySign>
  | ActionType<typeof setUnisignData>
  | ActionType<typeof setLoading>
  | ActionType<typeof setError>
  | ActionType<typeof setVerifySuccess>
  | ActionType<typeof setCancelSuccess>
  | ActionType<typeof setUnisignStatus>
  | ActionType<typeof resetUnisign>;
