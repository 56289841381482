import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserMessagesTable } from '../../containers/UserMessagesTable/Context';
import { fetchUserMessages } from '../../store/userMessages/userMessagesActions';

const UserMessagesPage: React.FC = () => {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(fetchUserMessages());
  }, [reduxDispatch]);

  return (
    <Container maxWidth="lg" className="data-page">
      <UserMessagesTable />
    </Container>
  );
};

export default UserMessagesPage;
