import React, { ReactNode } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { SideBox } from './SideBox';
import { groupBoxColor } from '../treeChartFunctions/constants';
import ErrorBox from './ErrorBox';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontStyle: 'italic',
    fontSize: '0.75rem'
  },
  boxContainer: {
    maxWidth: '430px',
    minHeight: '40px',
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'stretch',
    border: '1px solid',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  mainBox: {
    color: 'white',
    width: '200px',
    boxSizing: 'border-box',
    padding: '8px',
    cursor: 'pointer',
    margin: '0',
    height: '100%',
    borderRadius: '0',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  boxButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'initial',
    justifyContent: 'initial',
    height: '100%'
  },
  boxTitle: {
    flexGrow: 1
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  foregroundGrid: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '500%',
      height: '500%',
      top: '-250%',
      left: '-250%',
      transform: 'rotate(45deg)',
      backgroundImage:
        'linear-gradient(rgba(255,255,255,0.5) 1px, transparent 1px), linear-gradient(90deg, rgba(255,255,255,0.5) 1px, transparent 1px)',
      backgroundSize: '10px 10px'
    }
  }
}));

export interface Props {
  boxTitle: ReactNode;
  additionalInfo?: ReactNode;
  leftChildrenNumber?: number;
  rightChildrenNumber?: number;
  color: string;
  showLeftSideBox?: boolean;
  showRightSideBox?: boolean;
  onClick?: () => void;
  handleLeftSideBoxClick?: () => void;
  handleRightSideBoxClick?: () => void;
  fromGroup?: boolean;
  minHeight: number;
  maxHeight: number;
  hasErrors?: boolean;
  errorText?: string[];
  leftSideButtonDisabled?: boolean;
  virsClickableIcons?: ReactNode;
  rightSideButtonDisabled?: boolean;
  foregroundGrid?: boolean;
}

export const TreeSingleBox: React.FC<Props> = ({
  boxTitle,
  additionalInfo,
  leftChildrenNumber,
  rightChildrenNumber,
  color,
  showLeftSideBox,
  showRightSideBox,
  onClick,
  handleLeftSideBoxClick,
  handleRightSideBoxClick,
  fromGroup,
  minHeight,
  maxHeight,
  hasErrors,
  errorText,
  leftSideButtonDisabled,
  virsClickableIcons,
  rightSideButtonDisabled,
  foregroundGrid
}) => {
  const classes = useStyles();
  const containerCustomStyle = {
    borderColor: color,
    minHeight: `${minHeight}px`,
    maxHeight: `${maxHeight}px`
  };
  const mainBoxCustomStyle = {
    backgroundColor: color
  };

  const customSideboxStyle = fromGroup ? { background: groupBoxColor } : { background: '#fff' };

  const customBoxStyle = {
    borderLeft: `1px solid ${color}`,
    borderRight: `1px solid ${color}`
  };

  return (
    <div className={classes.boxContainer} style={containerCustomStyle}>
      {showLeftSideBox && (
        <SideBox
          childrenNumber={leftChildrenNumber}
          handleSideBoxClick={handleLeftSideBoxClick}
          customSideboxStyle={customSideboxStyle}
          isDisabled={leftSideButtonDisabled}
        />
      )}
      <div className={classes.centerBox} style={customBoxStyle}>
        <Button
          variant="contained"
          className={`${classes.mainBox} ${foregroundGrid ? classes.foregroundGrid : ''}`}
          classes={{ label: classes.boxButtonLabel }}
          style={mainBoxCustomStyle}
          onClick={onClick}
        >
          <div className={classes.boxTitle}>
            {boxTitle}
            {additionalInfo}
          </div>
        </Button>
        {virsClickableIcons}
        {hasErrors && <ErrorBox errorText={errorText} />}
      </div>
      {showRightSideBox && (
        <SideBox
          childrenNumber={rightChildrenNumber}
          handleSideBoxClick={handleRightSideBoxClick}
          customSideboxStyle={customSideboxStyle}
          isDisabled={rightSideButtonDisabled}
        />
      )}
    </div>
  );
};
