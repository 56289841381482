import { EnterpriseTypeShort } from '../../../../store/classifiers/classifiersTypes';
import { DatePickerState, DropdownStateGeneric } from '../../../../utils/tableTypes';
import { EnterpriseNewRecordState, EnterpriseEditRecordState } from './rowInitialStateAndTypes';
import {
  validateMandatoryDate,
  validateOptionalDateImproved,
  validateSingleChoice
} from '../../../../utils/InputValueFunctions';

export function validateNewEnterpriseRecord(
  state: EnterpriseNewRecordState
): EnterpriseNewRecordState {
  const enterpriseTypeValidated: DropdownStateGeneric<EnterpriseTypeShort> = validateSingleChoice(
    state.enterpriseType,
    'Reikia pasirinkti iš sąrašo'
  );
  const validFromValidated: DatePickerState = validateMandatoryDate({
    ...state.validFrom
  });
  const validToValidated: DatePickerState = validateOptionalDateImproved({
    ...state.validTo
  });

  return {
    ...state,
    enterpriseType: enterpriseTypeValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    createRecordConfirmationOn:
      enterpriseTypeValidated.validated &&
      validFromValidated.validated &&
      validToValidated.validated
  };
}

export function validateEditedEnterpriseRecord(
  state: EnterpriseEditRecordState
): EnterpriseEditRecordState {
  const enterpriseTypeValidated: DropdownStateGeneric<EnterpriseTypeShort> = validateSingleChoice(
    state.enterpriseType,
    'Reikia pasirinkti iš sąrašo'
  );
  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateOptionalDateImproved(state.validTo);

  return {
    ...state,
    enterpriseType: enterpriseTypeValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    updateRecordConfirmationOn:
      enterpriseTypeValidated.validated &&
      validFromValidated.validated &&
      validToValidated.validated
  };
}

export function validateEditedValidTo(state: EnterpriseEditRecordState): EnterpriseEditRecordState {
  const toDateValidated: DatePickerState = validateMandatoryDate(state.validTo);
  return {
    ...state,
    validTo: toDateValidated,
    updateDateConfirmationOn: toDateValidated.validated
  };
}
