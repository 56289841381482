import React from 'react';
import { createStyles, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Period } from '../../containers/ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';
import { noOptionsText } from './constants';

interface Props {
  setPeriodFilter(value: Period): void;
  periodFilter: Period;
}

const periods: Period[] = [
  {
    id: 'PASKUTINIS_MENUO',
    label: 'Paskutinis mėnuo'
  },
  {
    id: 'PASKUTINIS_KETVIRTIS',
    label: 'Paskutinis ketvirtis'
  },
  {
    id: 'PASKUTINIS_PUSMETIS',
    label: 'Paskutinis pusmetis'
  },
  {
    id: 'PASKUTINIAI_METAI',
    label: 'Paskutiniai metai'
  },
  {
    id: 'VISI',
    label: 'Visi duomenys'
  }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      fontFamily: 'Open Sans',
      fontSize: '0.8rem',
      fontWeight: 600,
      color: '#637082'
    },
    input: {
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '200px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    autoComplete: {
      flex: 1,
      marginRight: 10,
      marginLeft: 10,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        flex: 'unset'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10
      }
    }
  })
);

export const FilterByPeriodCell: React.FC<Props> = ({ setPeriodFilter, periodFilter }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: Period | null) => {
    if (value) {
      setPeriodFilter(value);
    }
  };

  return (
    <Autocomplete
      size="small"
      clearOnEscape
      id="filter-period"
      options={periods}
      getOptionLabel={(period) => period.label}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      value={periodFilter}
      getOptionSelected={(option, value) => option.id === value.id}
      className={classes.autoComplete}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" className={classes.input} />
      )}
      classes={{ option: classes.text, input: classes.text }}
    />
  );
};
