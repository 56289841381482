import produce from 'immer';
import moment from 'moment';
import {
  InputStateGeneric,
  DatePickerState,
  DropdownStateGeneric,
  VirsInputState,
  ValidationDate
} from '../../../../utils/tableTypes';

import { OutletShortData } from '../../../../store/outlets/outletsTypes';
import {
  MINIMAL_RECORD_DATE_DEFAULT,
  validateDocumentNumber,
  validatedSelectedVirs,
  validateMandatoryDate
} from '../../../../utils/InputValueFunctions';
import {
  DeterminedMisconductState,
  MisconductEditRowState,
  MisconductNewRowState,
  ProfessionalMisconductOutletState,
  ProfessionalMisconductRecordState
} from './misconductInitialStateAndTypes';
import {
  ProfessionalMisconductDataInst,
  ProfessionalMisconductRecordOutlet,
  ProfessionalMisconductSanctionType,
  ProfessionalMisconductType,
  ProfessionalMisconductOutletMisconduct,
  ProfessionalMisconductOutlet
} from '../../../../store/professionalMisconducts/professionalMisconductsTypes';
import { VirsSearchData } from '../../../../store/virsis/dataTypes';
import { filterEmpty } from '../../../../utils/tableDataFunctions';

function initNullOrTheOnlyOption(options: Record<string, any>[]) {
  if (options.length === 1) {
    return {
      value: options[0],
      error: false,
      helperText: '',
      placeholder: '',
      validated: false,
      options: []
    };
  }
  return {
    value: null,
    error: false,
    helperText: '',
    placeholder: '',
    validated: false,
    options
  };
}

export function setDynamicValidationConstraints<T extends ProfessionalMisconductRecordState>(
  state: T
): T {
  const minDates: (ValidationDate | undefined | null)[] = [
    MINIMAL_RECORD_DATE_DEFAULT,
    state.virs.value && {
      id: 'virsLegalRegistrationDate',
      date: moment(state.virs.value.virsLegalRegistrationDate),
      text: 'Data negali būti ankstesnė nei VIRS registracijos data'
    },
    ...filterEmpty(
      state.outlets
        .map(({ outlet: { value } }) => value)
        .map((selectedOutlet) =>
          state.virs.value?.outlets.find(({ outletId }) => outletId === selectedOutlet?.outletId)
        )
        .map((value) => value?.controlledFrom)
    )
      .sort((a, b) => b.localeCompare(a))
      .slice(0, 1)
      .map((controlledFrom) => ({
        id: 'outletControlledFrom',
        date: moment(controlledFrom),
        text: 'Negali būti ankstestė nei vip valdymo pradžios data'
      }))
  ];

  return {
    ...state,
    decisionDate: {
      ...state.decisionDate,
      minDates
    }
  };
}

export function setMisconductVirsAndUpdateOptions(
  state: ProfessionalMisconductRecordState,
  virs: VirsSearchData
): ProfessionalMisconductRecordState {
  const virsInput = { ...state.virs, value: virs, validated: true, error: false };
  return setDynamicValidationConstraints({
    ...state,
    virs: virsInput,
    outlets: [
      {
        outlet: initNullOrTheOnlyOption([
          state.fictitiousOutlet,
          ...virs.outlets
        ]) as unknown as DropdownStateGeneric<OutletShortData>,
        misconducts: [
          {
            misconduct: initNullOrTheOnlyOption(
              state.misconductTypes
            ) as unknown as DropdownStateGeneric<ProfessionalMisconductType>,
            sanctions: [
              {
                ...state.outlets[0].misconducts[0].sanctions[0],
                sanction: initNullOrTheOnlyOption(
                  state.sanctionTypes
                ) as unknown as DropdownStateGeneric<ProfessionalMisconductSanctionType>
              }
            ]
          }
        ]
      }
    ],
    showFindVirsDialog: false
  });
}

function getAvaibleVirsOutlets(
  allOutlets: OutletShortData[],
  takenOutlets: ProfessionalMisconductOutlet[]
): OutletShortData[] {
  const takenIds: number[] = [];
  takenOutlets.forEach((taken) => {
    takenIds.push(taken.outletId);
  });
  return allOutlets.filter((o) => !takenIds.includes(o.outletId));
}

function getAvaibleOutletMisconducts(
  allMisconductTypes: ProfessionalMisconductType[],
  takenMisconducts: ProfessionalMisconductOutletMisconduct[]
): ProfessionalMisconductType[] {
  const takenIds: number[] = [];
  takenMisconducts.forEach((taken) => {
    takenIds.push(taken.misconductTypeId);
  });
  return allMisconductTypes.filter((m) => !takenIds.includes(m.misconductTypeId));
}

function getAvaibleMisconductSanctions(
  allSanctionTypes: ProfessionalMisconductSanctionType[],
  takenSanctions: ProfessionalMisconductSanctionType[]
): ProfessionalMisconductSanctionType[] {
  const takenIds: number[] = [];
  takenSanctions.forEach((taken) => {
    takenIds.push(taken.sanctionTypeId);
  });
  return allSanctionTypes.filter((s) => !takenIds.includes(s.sanctionTypeId));
}

export function initRecordsOutletsToRowState(
  record: ProfessionalMisconductDataInst,
  misconductTypes: ProfessionalMisconductType[],
  sanctionTypes: ProfessionalMisconductSanctionType[],
  fictitiousOutlet: OutletShortData
): ProfessionalMisconductOutletState[] {
  const availableOutlets = getAvaibleVirsOutlets(
    record.outlets,
    record.professionalMisconductOutlets
  );
  return record.professionalMisconductOutlets.map((outlet) => {
    const availableMisconducts = getAvaibleOutletMisconducts(
      misconductTypes,
      outlet.determinedMisconducts
    );
    return {
      outlet: {
        value: {
          outletName: outlet.outletName,
          outletId: outlet.outletId,
          outletClosureStatus: outlet.outletClosureStatus,
          controlledFrom: outlet.controlledFrom,
          controlledTo: outlet.controlledTo
        },
        error: false,
        helperText: '',
        placeholder: '',
        validated: false,
        options:
          outlet.outletId === fictitiousOutlet.outletId
            ? [
                ...availableOutlets,
                {
                  outletName: outlet.outletName,
                  outletId: outlet.outletId,
                  outletClosureStatus: outlet.outletClosureStatus,
                  controlledFrom: outlet.controlledFrom,
                  controlledTo: outlet.controlledTo
                }
              ]
            : [
                fictitiousOutlet,
                ...availableOutlets,
                {
                  outletName: outlet.outletName,
                  outletId: outlet.outletId,
                  outletClosureStatus: outlet.outletClosureStatus,
                  controlledFrom: outlet.controlledFrom,
                  controlledTo: outlet.controlledTo
                }
              ]
      },
      misconducts: outlet.determinedMisconducts.map((misconduct) => {
        const availableSanctionTypes = getAvaibleMisconductSanctions(
          sanctionTypes,
          misconduct.sanctions
        );
        return {
          misconduct: {
            value: {
              misconductTypeName: misconduct.misconductTypeName,
              misconductTypeId: misconduct.misconductTypeId
            },
            error: false,
            helperText: '',
            placeholder: '',
            validated: false,
            options: [
              ...availableMisconducts,
              {
                misconductTypeName: misconduct.misconductTypeName,
                misconductTypeId: misconduct.misconductTypeId
              }
            ]
          },
          sanctions: misconduct.sanctions.map((sanction) => {
            return {
              sanction: {
                value: {
                  sanctionTypeName: sanction.sanctionTypeName,
                  sanctionTypeId: sanction.sanctionTypeId
                },
                error: false,
                helperText: '',
                placeholder: '',
                validated: false,
                options: [
                  ...availableSanctionTypes,
                  {
                    sanctionTypeName: sanction.sanctionTypeName,
                    sanctionTypeId: sanction.sanctionTypeId
                  }
                ]
              }
            };
          })
        };
      })
    };
  });
}

function getUpdatedOutletOptions(state: ProfessionalMisconductRecordState): OutletShortData[] {
  const selectedOutletIds: number[] = [];
  state.outlets.forEach((outletInput) => {
    if (outletInput.outlet.value) {
      selectedOutletIds.push(outletInput.outlet.value.outletId);
    }
  });

  return state.virs.value
    ? [state.fictitiousOutlet, ...state.virs.value.outlets].filter(
        (outlet) => !selectedOutletIds.includes(outlet.outletId)
      )
    : [];
}

export function addOutletField(
  state: ProfessionalMisconductRecordState
): ProfessionalMisconductRecordState {
  return {
    ...state,
    outlets: [
      ...state.outlets,
      {
        outlet: {
          value: null,
          error: false,
          helperText: '',
          placeholder: '',
          validated: false,
          options: getUpdatedOutletOptions(state)
        },
        misconducts: [
          {
            misconduct: {
              value: null,
              error: false,
              helperText: '',
              placeholder: '',
              validated: false,
              options: state.misconductTypes
            },
            sanctions: [
              {
                sanction: {
                  value: null,
                  error: false,
                  helperText: '',
                  placeholder: '',
                  validated: false,
                  options: state.sanctionTypes
                }
              }
            ]
          }
        ]
      }
    ]
  };
}

export function setAndValidateOutletAndUpdateOthersOptions(
  state: ProfessionalMisconductRecordState,
  outlet: OutletShortData | null,
  outletIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].outlet = outlet
      ? {
          ...draftState.outlets[outletIndex].outlet,
          value: outlet,
          error: false,
          helperText: '',
          validated: true
        }
      : {
          ...draftState.outlets[outletIndex].outlet,
          value: outlet,
          error: false,
          helperText: '',
          validated: false
        };
    draftState.outlets.forEach((outletInput) => {
      outletInput.outlet.options = getUpdatedOutletOptions(draftState);
    });
  });
}

function validateOutletSelection(state: DropdownStateGeneric<OutletShortData>) {
  return state.value === null
    ? {
        ...state,
        error: true,
        helperText: 'Būtina VIP',
        validated: false
      }
    : {
        ...state,
        error: false,
        helperText: '',
        validated: true
      };
}

export function removeOutletAndUpdateOthersOptions(
  state: ProfessionalMisconductRecordState,
  outletIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets.splice(outletIndex, 1);
    draftState.outlets.forEach((outletInput) => {
      outletInput.outlet.options = getUpdatedOutletOptions(draftState);
    });
  });
}

export function getUpdatedMisconductOptions(
  allMisconductTypes: ProfessionalMisconductType[],
  state: ProfessionalMisconductOutletState,
  newValue?: ProfessionalMisconductType | null
): ProfessionalMisconductType[] {
  const selectedMisconductIds: number[] = [];
  state.misconducts.forEach((misconductInput) => {
    if (misconductInput.misconduct.value) {
      selectedMisconductIds.push(misconductInput.misconduct.value.misconductTypeId);
    }
  });
  if (newValue) {
    selectedMisconductIds.push(newValue.misconductTypeId);
  }
  return (
    allMisconductTypes.filter(
      (misconduct) => !selectedMisconductIds.includes(misconduct.misconductTypeId)
    ) || []
  );
}

export function addMisconductField(
  state: ProfessionalMisconductRecordState,
  outletIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].misconducts.push({
      misconduct: {
        value: null,
        error: false,
        helperText: '',
        placeholder: '',
        validated: false,
        options: getUpdatedMisconductOptions(state.misconductTypes, state.outlets[outletIndex])
      },
      sanctions: [
        {
          sanction: {
            value: null,
            error: false,
            helperText: '',
            placeholder: '',
            validated: false,
            options: draftState.sanctionTypes
          }
        }
      ]
    });
  });
}

export function setAndValidateMisconductAndUpdateOthersOptions(
  state: ProfessionalMisconductRecordState,
  misconduct: ProfessionalMisconductType | null,
  outletIndex: number,
  misconductIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].misconducts[misconductIndex].misconduct = misconduct
      ? {
          ...draftState.outlets[outletIndex].misconducts[misconductIndex].misconduct,
          value: misconduct,
          error: false,
          helperText: '',
          validated: false
        }
      : {
          ...draftState.outlets[outletIndex].misconducts[misconductIndex].misconduct,
          value: misconduct,
          error: true,
          helperText: 'Būtinas pažeidimas',
          validated: false
        };
    draftState.outlets[outletIndex].misconducts.forEach((misconductInput) => {
      misconductInput.misconduct.options = getUpdatedMisconductOptions(
        state.misconductTypes,
        draftState.outlets[outletIndex]
      );
    });
  });
}

export function removeMisconductAndUpdateOthersOptions(
  state: ProfessionalMisconductRecordState,
  outletIndex: number,
  misconductIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].misconducts.splice(misconductIndex, 1);
    draftState.outlets[outletIndex].misconducts.forEach((misconductInput) => {
      misconductInput.misconduct.options = getUpdatedMisconductOptions(
        state.misconductTypes,
        draftState.outlets[outletIndex]
      );
    });
  });
}

function validateMisconductSelection(state: DropdownStateGeneric<ProfessionalMisconductType>) {
  return state.value === null
    ? {
        ...state,
        error: true,
        helperText: 'Būtinas pažeidimas',
        validated: false
      }
    : {
        ...state,
        error: false,
        helperText: '',
        validated: true
      };
}

function getUpdatedSanctionOptions(
  allSanctionTypes: ProfessionalMisconductSanctionType[],
  misconduct: DeterminedMisconductState,
  newValue?: ProfessionalMisconductSanctionType | null
): ProfessionalMisconductSanctionType[] {
  const selectedSanctionIds: number[] = [];
  misconduct.sanctions.forEach((sanctionInput) => {
    if (sanctionInput.sanction.value) {
      selectedSanctionIds.push(sanctionInput.sanction.value.sanctionTypeId);
    }
  });
  if (newValue) {
    selectedSanctionIds.push(newValue.sanctionTypeId);
  }
  return (
    allSanctionTypes.filter((sanction) => !selectedSanctionIds.includes(sanction.sanctionTypeId)) ||
    []
  );
}

export function addSanctionField(
  state: ProfessionalMisconductRecordState,
  outletIndex: number,
  misconductIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions.push({
      sanction: {
        value: null,
        error: false,
        helperText: '',
        placeholder: '',
        validated: false,
        options: getUpdatedSanctionOptions(
          state.sanctionTypes,
          draftState.outlets[outletIndex].misconducts[misconductIndex]
        )
      }
    });
  });
}

export function setAndValidateSanctionAndUpdateOthersOptions(
  state: ProfessionalMisconductRecordState,
  sanction: ProfessionalMisconductSanctionType | null,
  outletIndex: number,
  misconductIndex: number,
  sanctionIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions[sanctionIndex].sanction =
      sanction
        ? {
            ...draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions[sanctionIndex]
              .sanction,
            value: sanction,
            error: false,
            helperText: '',
            validated: false
          }
        : {
            ...draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions[sanctionIndex]
              .sanction,
            value: sanction,
            error: true,
            helperText: 'Būtina priemonė',
            validated: false
          };
    draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions.forEach(
      (sanctionInput) => {
        sanctionInput.sanction.options = getUpdatedSanctionOptions(
          state.sanctionTypes,
          draftState.outlets[outletIndex].misconducts[misconductIndex]
        );
      }
    );
  });
}

export function removeSanctionAndUpdateOthersOptions(
  state: ProfessionalMisconductRecordState,
  outletIndex: number,
  misconductIndex: number,
  sanctionIndex: number
): ProfessionalMisconductRecordState {
  return produce(state, (draftState) => {
    draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions.splice(sanctionIndex, 1);
    draftState.outlets[outletIndex].misconducts[misconductIndex].sanctions.forEach(
      (sanctionInput) => {
        sanctionInput.sanction.options = getUpdatedSanctionOptions(
          state.sanctionTypes,
          draftState.outlets[outletIndex].misconducts[misconductIndex]
        );
      }
    );
  });
}

function validateMisconductSanctionSelection(
  state: DropdownStateGeneric<ProfessionalMisconductSanctionType>
) {
  return state.value === null
    ? {
        ...state,
        error: true,
        helperText: 'Būtina priemonė',
        validated: false
      }
    : {
        ...state,
        error: false,
        helperText: '',
        validated: true
      };
}

function validateMisconductOutlets(
  outlets: {
    outlet: DropdownStateGeneric<OutletShortData>;
    misconducts: {
      misconduct: DropdownStateGeneric<ProfessionalMisconductType>;
      sanctions: {
        sanction: DropdownStateGeneric<ProfessionalMisconductSanctionType>;
      }[];
    }[];
  }[]
): {
  outlet: DropdownStateGeneric<OutletShortData>;
  misconducts: {
    misconduct: DropdownStateGeneric<ProfessionalMisconductType>;
    sanctions: {
      sanction: DropdownStateGeneric<ProfessionalMisconductSanctionType>;
    }[];
  }[];
}[] {
  return outlets.map((outletInput) => {
    return {
      outlet: validateOutletSelection(outletInput.outlet),
      misconducts: outletInput.misconducts.map((misconductInput) => {
        return {
          misconduct: validateMisconductSelection(misconductInput.misconduct),
          sanctions: misconductInput.sanctions.map((sanctionInput) => {
            return {
              sanction: validateMisconductSanctionSelection(sanctionInput.sanction)
            };
          })
        };
      })
    };
  });
}

export function mapIntoProfessionalMisconductOutletsRecordObject(
  state: ProfessionalMisconductRecordState
): ProfessionalMisconductRecordOutlet[] {
  return state.outlets.map((outletInput) => {
    return {
      outletId: outletInput.outlet.value ? outletInput.outlet.value.outletId : undefined,
      determinedMisconducts: outletInput.misconducts.map((misconductInput) => {
        return {
          misconductTypeId: misconductInput.misconduct.value
            ? misconductInput.misconduct.value.misconductTypeId
            : undefined,
          sanctions: misconductInput.sanctions.map((sanctionInput) => {
            return {
              sanctionTypeId: sanctionInput.sanction.value
                ? sanctionInput.sanction.value.sanctionTypeId
                : undefined
            };
          })
        };
      })
    };
  });
}

function seeIfAllNestedValuesPresent(state: ProfessionalMisconductRecordState): boolean {
  const allPresent: boolean[] = [];

  state.outlets.forEach((outletInput) => {
    allPresent.push(!!outletInput.outlet.value);
    outletInput.misconducts.forEach((misconductInput) => {
      allPresent.push(!!misconductInput.misconduct.value);
      misconductInput.sanctions.forEach((sanctionInput) => {
        allPresent.push(!!sanctionInput.sanction.value);
      });
    });
  });
  return allPresent.every((value) => value);
}

export function validateNewMisconductRecordState(
  state: MisconductNewRowState
): MisconductNewRowState {
  const virsValidated: VirsInputState = validatedSelectedVirs(state.virs);
  const decisionDateValidated: DatePickerState = validateMandatoryDate(state.decisionDate);
  const documentNumberValidated: InputStateGeneric<string> = validateDocumentNumber(
    state.documentNumber
  );
  const outletsValidated: {
    outlet: DropdownStateGeneric<OutletShortData>;
    misconducts: {
      misconduct: DropdownStateGeneric<ProfessionalMisconductType>;
      sanctions: {
        sanction: DropdownStateGeneric<ProfessionalMisconductSanctionType>;
      }[];
    }[];
  }[] = validateMisconductOutlets(state.outlets);

  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateMandatoryDate(state.validTo);

  return {
    ...state,
    virs: virsValidated,
    decisionDate: decisionDateValidated,
    documentNumber: documentNumberValidated,
    outlets: outletsValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    createRecordConfirmationOn:
      virsValidated.validated &&
      decisionDateValidated.validated &&
      documentNumberValidated.validated &&
      seeIfAllNestedValuesPresent(state) &&
      validFromValidated.validated &&
      validToValidated.validated
  };
}

export function validateEditedMisconductRecordState(
  state: MisconductEditRowState
): MisconductEditRowState {
  const decisionDateValidated: DatePickerState = validateMandatoryDate(state.decisionDate);
  const documentNumberValidated: InputStateGeneric<string> = validateDocumentNumber(
    state.documentNumber
  );

  const outletsValidated: {
    outlet: DropdownStateGeneric<OutletShortData>;
    misconducts: {
      misconduct: DropdownStateGeneric<ProfessionalMisconductType>;
      sanctions: {
        sanction: DropdownStateGeneric<ProfessionalMisconductSanctionType>;
      }[];
    }[];
  }[] = validateMisconductOutlets(state.outlets);

  const validFromValidated: DatePickerState = validateMandatoryDate(state.validFrom);
  const validToValidated: DatePickerState = validateMandatoryDate(state.validTo);

  return {
    ...state,
    decisionDate: decisionDateValidated,
    documentNumber: documentNumberValidated,
    outlets: outletsValidated,
    validFrom: validFromValidated,
    validTo: validToValidated,
    updateRecordConfirmationOn:
      decisionDateValidated.validated &&
      documentNumberValidated.validated &&
      seeIfAllNestedValuesPresent(state) &&
      validFromValidated.validated &&
      validToValidated.validated
  };
}
