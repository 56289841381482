import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../utils/tableDataFunctions';
import {
  ShareholdersSelectedDateTableState,
  ShareholdersSelectedDateTableAction
} from './tableInitialStateAndTypes';
import { formPersonsList } from './tableStateFunctions';

export const shareholdersSelectedDateTableReducer = (
  state: ShareholdersSelectedDateTableState,
  action: ShareholdersSelectedDateTableAction
): ShareholdersSelectedDateTableState => {
  switch (action.type) {
    case 'NON_PARTICIPATING_SHAREHOLDERS_DISABLED':
      return {
        ...state,
        participationFilterDisabled: action.participationFilterDisabled,
        showNonParticipatingShareholders: !action.participationFilterDisabled
      };
    case 'NON_PARTICIPATING_SHAREHOLDERS_TOGGLED':
      return {
        ...state,
        showNonParticipatingShareholders: !state.showNonParticipatingShareholders
      };
    case 'SET_SHOW_SHARES_VOTES':
      return {
        ...state,
        showShares: action.showShares,
        showVotes: action.showVotes
      };
    case 'IS_FIND_PERSON_DIALOG_OPEN':
      return {
        ...state,
        isPersonSearchDialogOpen: action.isPersonSearchDialogOpen
      };
    case 'SET_PARENT_ID_TO_ADD_CHILD':
      return {
        ...state,
        shareholderPersonsList: [],
        parentIdToAddChild: action.id,
        index: action.index
      };
    case 'SET_PARENT_PERSON_LEVEL_ID_TO_ADD_CHILD':
      return {
        ...state,
        parentPersonLevelIdToAddChild: {
          level: action.level,
          id: action.id
        }
      };
    case 'SHOW_EDIT_SHAREHOLDER_ROW':
      return {
        ...state,
        showEditShareholderRow: action.showEditShareholderRow,
        shareholderToEditList: action.shareholderToEditList,
        authorizedPersonId: action.authorizedPersonId || state.authorizedPersonId,
        personToAdd: action.personToAdd,
        showAddShareholderRow: false,
        showSelectShareholderRow: false,
        index: action.index
      };
    case 'SHOW_ADD_SHAREHOLDER_ROW':
      return {
        ...state,
        showEditShareholderRow: false,
        showAddShareholderRow: action.showAddShareholderRow,
        showSelectShareholderRow: false,
        personToAdd: action.personToAdd
      };
    case 'SHOW_SELECT_SHAREHOLDER_ROW':
      return {
        ...state,
        showEditShareholderRow: false,
        showAddShareholderRow: false,
        shareholderToEditList: [],
        showSelectShareholderRow: action.showSelectShareholderRow
      };
    case 'IS_SELECTED_SHAREHOLDER_GROUP':
      return {
        ...state,
        isGroup: action.isGroup
      };
    case 'SET_SHAREHOLDER_PERSONS_LIST':
      return {
        ...state,
        personToAdd: undefined,
        shareholderPerson: action.shareholderPerson,
        shareholderPersonsList: formPersonsList(
          state.isGroup,
          state.shareholderPersonsList,
          action.shareholderPerson
        )
      };
    case 'EDIT_SHAREHOLDER_ROW_CLOSE':
      return {
        ...state,
        showAddShareholderRow: false,
        showEditShareholderRow: false,
        authorizedPersonId: null,
        isGroup: false,
        parentIdToAddChild: undefined,
        personToAdd: undefined,
        shareholderPersonsList: []
      };
    case 'REMOVE_SHAREHOLDER_PERSON':
      return {
        ...state,
        personId: action.personId,
        shareholderPersonsList: state.shareholderPersonsList.filter(
          (person) => person.personId !== action.personId
        )
      };
    case 'SET_AUTHORIZED_PERSON_ID':
      return { ...state, authorizedPersonId: action.authorizedPersonId };
    case 'SET_SHOULD_SCROLL_TO_SHAREHOLDER':
      return { ...state, shouldScroll: action.shouldScroll };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'SET_SHAREHOLDERS_LEVEL_LIST':
      return {
        ...state,
        shareholdersLevelList: action.shareholdersLevelList
      };
    case 'SET_DEFAULT_LEVEL_VALUE':
      return {
        ...state,
        defaultFilterLevelValue: action.defaultFilterLevelValue
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value)
      };

    default:
      return state;
  }
};
