import React, { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import virsisTheme from '../../../style/virsisTheme';
import { CustomCloseIconButton } from '../../Icons/IconButtons/CustomCloseIconButton';
import { CloseDialogButton } from '../Dialog/CloseButton';
import { Strings } from '../../../utils/strings/Strings';

interface Props {
  dialogTitle: string;
  dialogText: string;
  mainButtonText: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onErrorClose?: () => void;
  onSuccessClose?: () => void;
  isProcessing?: boolean;
  processingMessage?: string;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 35px 10px 35px'
    },
    divider: {
      marginRight: 35,
      marginLeft: 35
    },
    contentWrap: {
      padding: '6px 35px 24px 35px'
    },
    contentText: {
      color: virsisTheme.palette.text.primary,
      fontSize: '1rem'
    },
    form: {
      width: '100%'
    },
    select: {
      width: '100%'
    },
    iconS: {
      marginLeft: 14,
      width: 12,
      height: 12
    },
    actions: {
      minWidth: 250,
      padding: '10px 34px',
      height: 50,
      backgroundColor: '#F3F3F3'
    },
    delete: {
      backgroundColor: 'transparent',
      color: virsisTheme.palette.error.main,
      borderColor: virsisTheme.palette.error.main,
      minWidth: '124px'
    },
    success: {
      backgroundColor: 'transparent',
      color: virsisTheme.palette.success.main,
      borderColor: virsisTheme.palette.success.main
    }
  })
);

export const ConfirmRemoveDialog: React.FC<Props> = ({
  dialogTitle,
  dialogText,
  mainButtonText,
  open,
  onClose,
  onSubmit,
  isProcessing,
  processingMessage,
  onErrorClose,
  onSuccessClose,
  isSuccess,
  isError,
  errorMessage
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (isSuccess && onSuccessClose) {
      onSuccessClose();
    }
  }, [isSuccess, onSuccessClose]);

  function generateTitle() {
    if (isProcessing) return processingMessage || 'Šalinama';
    if (isError) return 'Klaida';
    return dialogTitle;
  }
  const title: string = generateTitle();

  function generateText() {
    if (isError) return errorMessage;
    return dialogText;
  }

  const text: string | undefined = generateText();

  const contentText = <DialogContentText className={classes.contentText}>{text}</DialogContentText>;

  return (
    <Dialog maxWidth="md" open={open} onClose={isProcessing ? undefined : onClose}>
      {open && (
        <>
          <div className={classes.title}>
            <Typography variant="h2">{title}</Typography>
            {isProcessing || isError ? null : <CustomCloseIconButton onClick={onClose} />}
          </div>

          <Divider className={classes.divider} />

          <DialogContent
            className={classes.contentWrap}
            style={isProcessing ? { display: 'flex', justifyContent: 'center' } : undefined}
          >
            {isProcessing ? <CircularProgress /> : contentText}
          </DialogContent>

          {isProcessing ? null : (
            <DialogActions className={classes.actions}>
              {!isError && <CloseDialogButton label={Strings.button__cancel} onClick={onClose} />}

              {!isError ? (
                <Button variant="outlined" className={classes.delete} onClick={onSubmit}>
                  <Typography variant="h5">{mainButtonText}</Typography>
                </Button>
              ) : (
                <Button variant="outlined" className={classes.delete} onClick={onErrorClose}>
                  <Typography variant="h5">Uždaryti</Typography>
                </Button>
              )}
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};
