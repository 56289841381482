import React, { useState } from 'react';
import { Typography, Grid, Divider, CircularProgress } from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../store';
import { JarCountry } from '../../../store/classifiers/classifiersTypes';
import { FindLegalResults } from './FindLegalResults';
import { FindLegalForm } from './FindLegaForm';
import {
  resetLegalRepresentative,
  resetSearchLegalPersonData,
  searchLegalPersonData
} from '../../../store/legalPersonData/legalDataActions';
import { LegalPersonSearchQuery } from '../../../store/legalPersonData/legalDataTypes';
import { resetPersonRepresentative } from '../../../store/persons/personsActions';
import { AddNewRecordButton } from '../../TableButtons/AddNewRecordButton';
import { FindPersonAddLegalForeignForm } from './FindPersonAddLegalForeignForm';

interface FindRepresentativeState {
  searchClicked: boolean;
  showingAddLegalForeign: boolean;
  lastSearchedCountry?: JarCountry;
}

const initialFindRepresentativeState: FindRepresentativeState = {
  searchClicked: false,
  showingAddLegalForeign: false,
  lastSearchedCountry: undefined
};

interface Props {
  checkIfPersonCanBeSelected?: (
    legalFormCode: number,
    personType: string,
    personTypeCode: number
  ) => boolean;
  tooltipTextOnToggleButton?: string;
}

export const FindLegalPage: React.FC<Props> = ({
  checkIfPersonCanBeSelected,
  tooltipTextOnToggleButton
}) => {
  const [dialogState, setDialogState] = useState<FindRepresentativeState>(
    initialFindRepresentativeState
  );

  const { jarCountries } = useSelector((state: ApplicationState) => state.virsis);

  const {
    loadingLegalPersonSearchResults,
    legalPersonSearchResults,
    legalPersonSearchResultsError,
    selectedLegalPersonSearchQuery
  } = useSelector((state: ApplicationState) => state.legalData);

  const reduxDispatch = useDispatch();

  function searchForLegalForeignPersonFormik(
    legalQuery: LegalPersonSearchQuery,
    searchedCountry?: JarCountry
  ) {
    reduxDispatch(resetSearchLegalPersonData());
    setDialogState({
      ...dialogState,
      searchClicked: true,
      lastSearchedCountry: searchedCountry,
      showingAddLegalForeign: false
    });
    reduxDispatch(resetLegalRepresentative());
    reduxDispatch(resetPersonRepresentative());
    reduxDispatch(searchLegalPersonData(legalQuery));
  }

  function startAddingLegalForeignPerson() {
    setDialogState({ ...dialogState, showingAddLegalForeign: true });
  }

  function stopAddingLegalForeignPerson() {
    setDialogState({ ...dialogState, showingAddLegalForeign: false });
  }

  const isLithuanianCountryCode = (countryCode: string | undefined | null): boolean =>
    countryCode === 'LTU';

  const foreignSearch = isLithuanianCountryCode(selectedLegalPersonSearchQuery?.countryCode);

  const filteredJarCountries =
    jarCountries?.filter((country) => country.countryCode !== 'NE') || [];

  return (
    <>
      {jarCountries && (
        <>
          <Divider />
          <FindLegalForm
            jarCountries={filteredJarCountries}
            searchLegal={searchForLegalForeignPersonFormik}
            isSearching={loadingLegalPersonSearchResults}
          />
          <Divider />
          <Grid
            container
            item
            direction="column"
            justify="flex-end"
            alignItems="flex-start"
            style={{ paddingBottom: '50px' }}
          >
            {dialogState.showingAddLegalForeign && (
              <FindPersonAddLegalForeignForm
                searchPerson={searchForLegalForeignPersonFormik}
                close={stopAddingLegalForeignPerson}
              />
            )}
            {loadingLegalPersonSearchResults && <CircularProgress />}
            {legalPersonSearchResultsError && (
              <Typography>{legalPersonSearchResultsError.message}</Typography>
            )}
            {!dialogState.showingAddLegalForeign &&
              dialogState.searchClicked &&
              !loadingLegalPersonSearchResults &&
              legalPersonSearchResults &&
              legalPersonSearchResults.length === 0 && (
                <>
                  <Typography variant="h5">{`Pagal Jūsų paiešką nebuvo rasta įrašų ${
                    dialogState.lastSearchedCountry?.countryNameLTGenitiveCase || 'užsienio'
                  } duomenyse.`}</Typography>
                  <Typography>
                    Pasitikrinkite ar teisingai suvedėte duomenis arba pabandykite sumažinti
                    paieškos kriterijų kiekį.
                  </Typography>
                  {!foreignSearch && (
                    <>
                      <Typography>
                        Jeigu duomenys nurodyti teisingai, tačiau užsienio juridinio asmuo nerastas,
                        inicijuokite naujo užsienio juridinio asmens pridėjimą.
                      </Typography>
                      <AddNewRecordButton
                        onClicked={startAddingLegalForeignPerson}
                        text="Pridėti užsienio juridini asmenį"
                      />
                    </>
                  )}
                </>
              )}
            {dialogState.searchClicked &&
              legalPersonSearchResults &&
              legalPersonSearchResults.length > 0 && (
                <FindLegalResults
                  legalSearchResults={legalPersonSearchResults}
                  checkIfPersonCanBeSelected={checkIfPersonCanBeSelected}
                  tooltipTextOnToggleButton={tooltipTextOnToggleButton}
                />
              )}
          </Grid>
        </>
      )}
    </>
  );
};
