import moment from 'moment';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  ConfirmationStatus,
  DataWithDocumentStatus,
  DataWithDocumentStatusProvidedData,
  DataWithStatuses,
  ProvidedDataTypes,
  SortOrder,
  TableCustomFilter,
  VirsisData,
  VirsisYesNoString,
  WordStatus
} from './tableTypes';
import { Order } from '../components/TableTypes/TableTypes';
import { Option } from '../components/TableCustomFilterCell/TableCustomFilterForOptionCellGeneric';
import { AccountType, VirsisUser } from '../store/virsis/dataTypes';
import { getSubsystem } from './roleHelper';

function getNestedValue(obj: any, keys: string[]): any {
  let currentValue = obj;
  for (const key of keys) {
    if (Array.isArray(currentValue)) {
      if (currentValue.length === 0) return undefined;
      return currentValue.flatMap((item) => getNestedValue(item, keys));
    }
    currentValue = currentValue?.[key];
    if (typeof currentValue === 'object' && currentValue !== null) {
      const nestedKeys = keys.slice(1);
      return getNestedValue(currentValue, nestedKeys);
    }
  }
  return currentValue;
}

function compareValues(v1: any, v2: any, order: SortOrder) {
  const sortOrder = order === 'desc' ? -1 : 1;

  if (v1 === undefined && v2 === undefined) return 0;
  if (v1 === undefined) return sortOrder * -1;
  if (v2 === undefined) return sortOrder;

  if (typeof v1 === 'string' && typeof v2 === 'string') {
    const date1 = Date.parse(v1);
    const date2 = Date.parse(v2);
    const isDate1 = !isNaN(date1);
    const isDate2 = !isNaN(date2);

    if (isDate1 && isDate2) {
      return sortOrder * (date1 - date2);
    }

    const num1 = parseFloat(v1);
    const num2 = parseFloat(v2);
    const isNum1 = !isNaN(num1);
    const isNum2 = !isNaN(num2);

    if (isNum1 && isNum2) {
      return sortOrder * (num1 - num2);
    }

    return sortOrder * v1.localeCompare(v2);
  }

  if (typeof v1 === 'number' && typeof v2 === 'number') {
    return sortOrder * (v1 - v2);
  }

  if (v1 instanceof Date && v2 instanceof Date) {
    return sortOrder * (v1.getTime() - v2.getTime());
  }

  return 0;
}

export function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key
): (
  a: { [key in Key]: string | number | Date | { [key: string]: any } },
  b: { [key in Key]: string | number | Date | { [key: string]: any } }
) => number {
  return (a, b) => {
    // checks for nested values seperated by .
    // example paymentDocuments.documentNumber
    // example professionalMisconductOutlets.determinedMisconducts.sanctions.sanctionTypeName
    const orderByNested = orderBy.toString().split('.');

    const aValue = getNestedValue(a, orderByNested);
    const bValue = getNestedValue(b, orderByNested);

    if (Array.isArray(aValue) && Array.isArray(bValue)) {
      const aMinValue = aValue.reduce(
        (prev, curr) => (compareValues(prev, curr, order) <= 0 ? prev : curr),
        aValue[0]
      );
      const bMinValue = bValue.reduce(
        (prev, curr) => (compareValues(prev, curr, order) <= 0 ? prev : curr),
        bValue[0]
      );
      return compareValues(aMinValue, bMinValue, order);
    } else {
      return compareValues(aValue, bValue, order);
    }
  };
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const firstValue = a[0];
    const secondValue = b[0];
    const order = comparator(firstValue, secondValue);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function stableSortWrapped<T>(array: T[], order: SortOrder, sortBy: string) {
  const data = array as unknown as VirsisData[];
  const sorted = stableSort(data, getComparator(order, sortBy));
  return (sorted as unknown as T[]) || [];
}

export function todaysDateString(): string {
  return moment().format('YYYY-MM-DD');
}

export function determineLithuanianNumberDefinitionCase(count: number): string {
  const endsWithZeroOrTeens = 'įrašų';
  const endsWithOne = 'įrašas';
  const endsWithOdd = 'įrašai';
  const zeroMessage = 'Įrašų nėra';

  if (!count) {
    return `${zeroMessage}`;
  }
  if (count % 10 === 1 && count % 100 === 11) {
    return `${count} ${endsWithZeroOrTeens}`;
  }
  if (count % 10 === 1) {
    return `${count} ${endsWithOne}`;
  }
  if (count % 10 === 0) {
    return `${count} ${endsWithZeroOrTeens}`;
  }
  if (count % 10 !== 0) {
    if (count % 100 > 10 && count % 100 < 20) {
      return `${count} ${endsWithZeroOrTeens}`;
    }
    return `${count} ${endsWithOdd}`;
  }
  return '';
}

export function dataContainsAnyNotSignedRecords<T extends DataWithStatuses>(data: T[]): boolean {
  return data.some((record) => {
    return record.confirmationStatus !== 'P';
  });
}

export function dataContainsAnySignedRecords<T extends DataWithStatuses>(data: T[]): boolean {
  return data.some((record) => record.confirmationStatus === 'P');
}

export function dataContainsAnyRelevantRecords<T extends DataWithStatuses>(data: T[]): boolean {
  return data.some((record) => record.relevancyStatus === 'A');
}

export function dataContainsAnyOutdatedRecords(data: DataWithStatuses[]): boolean {
  return data.some((record) => record.relevancyStatus === 'I');
}

export function dataContainsMoreThanOneRelevantRecord(data: DataWithStatuses[]): boolean {
  const relevantData = data.filter((record) => record.relevancyStatus === 'A');
  return relevantData.length > 1 || false;
}

export function getDataSlicedToPageGeneric<T>(data: T[], page: number, rowsPerPage: number) {
  return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export function getPagesCountGeneric(numOfRecords: number, rowsPerPage: number) {
  return Math.ceil(numOfRecords / rowsPerPage);
}

export function pageTableData<T extends { rowsPerPage: number; page: number }, TData>(
  { rowsPerPage, page: activePage }: T,
  data: TData[]
): { page: TData[]; pagesCount: number } {
  const pagesCount = getPagesCountGeneric(data.length, rowsPerPage);

  const page = getDataSlicedToPageGeneric(data, activePage, rowsPerPage);

  return { page, pagesCount };
}

export const dataContainsRecordDocStatusNotSigned = (data: DataWithDocumentStatus[]): boolean => {
  return data.some((record) => {
    return record.documentStatus.id === 'RUOSIAMAS';
  });
};

export const dataContainsRecordDocumentStatusNotSigned = (
  data: DataWithDocumentStatusProvidedData[]
): boolean => {
  return data.some((record) => {
    return record.documentStatus.id === 'RUOSIAMAS';
  });
};

export const dataContainsAnyDocStatusSignedRecord = (data: DataWithDocumentStatus[]): boolean => {
  return data.some((record) => {
    return record.documentStatus.id === 'PASIRASYTAS';
  });
};

export const dataContainsAnyDocumentStatusSignedRecord = (
  data: DataWithDocumentStatusProvidedData[]
): boolean => {
  return data.some((record) => {
    return record.documentStatus.id === 'PASIRASYTAS';
  });
};

export const dataContainsMoreThanOneRecordDocStatusSigned = (
  data: DataWithDocumentStatus[]
): boolean => {
  const relevantData = data.filter((record) => record.documentStatus.id === 'PASIRASYTAS');
  return relevantData.length > 1 || false;
};

export const dataContainsMoreThanOneRecordDocumentStatusSigned = (
  data: DataWithDocumentStatusProvidedData[]
): boolean => {
  const relevantData = data.filter((record) => record.documentStatus.id === 'PASIRASYTAS');
  return relevantData.length > 1 || false;
};

export const dataContainsDocStatusAnnuledRecord = (data: DataWithDocumentStatus[]): boolean => {
  return data.some((record) => {
    return record.documentStatus.id === 'ANULIUOTAS';
  });
};

export const dataContainsDocumentStatusAnnuledRecord = (
  data: DataWithDocumentStatusProvidedData[]
): boolean => {
  return data.some((record) => {
    return record.documentStatus.id === 'ANULIUOTAS';
  });
};

export function getConfirmationAndRelevancyStatusFilter<TData extends DataWithStatuses>(
  showUnconfirmed: boolean,
  showRelevant: boolean,
  showHistoric: boolean
): Filter<TData> {
  return (data) =>
    data
      ? data.filter((record) => {
          if (showUnconfirmed && !showRelevant && !showHistoric) {
            return record.confirmationStatus !== 'P';
          }
          if (showUnconfirmed && showRelevant && !showHistoric) {
            return (
              record.confirmationStatus === 'N' ||
              record.confirmationStatus === 'U' ||
              record.relevancyStatus === 'A'
            );
          }
          if (showUnconfirmed && showRelevant && showHistoric) {
            return true;
          }
          if (!showUnconfirmed && showRelevant && showHistoric) {
            return record.relevancyStatus === 'A' || record.relevancyStatus === 'I';
          }
          if (!showUnconfirmed && !showRelevant && showHistoric) {
            return record.relevancyStatus === 'I';
          }
          if (showUnconfirmed && !showRelevant && showHistoric) {
            return record.confirmationStatus !== 'P' || record.relevancyStatus === 'I';
          }
          if (!showUnconfirmed && showRelevant && !showHistoric) {
            return record.relevancyStatus === 'A';
          }
          return false;
        })
      : [];
}

export function getConfirmationStatusFilter<
  TData extends { confirmationStatus: ConfirmationStatus },
  TTableSettings extends {
    showStatusNotSigned: boolean;
    showStatusRelevant: boolean;
    showStatusOutdated: boolean;
  }
>(
  { showStatusNotSigned, showStatusRelevant, showStatusOutdated }: TTableSettings,
  signedRecordComparator: (a: TData, b: TData) => number
): Filter<TData> {
  return (data) => {
    let filtered;
    const unsigned = data.filter((record) => record.confirmationStatus !== 'P');
    const onlySigned = data.filter((record) => record.confirmationStatus === 'P');

    const [first, ...rest] = onlySigned.sort(signedRecordComparator);

    if (showStatusNotSigned && !showStatusRelevant && !showStatusOutdated) {
      filtered = data.filter((record) => record.confirmationStatus !== 'P') || [];
    }
    if (!showStatusNotSigned && showStatusRelevant && !showStatusOutdated) {
      filtered = first ? [first] : [];
    }
    if (!showStatusNotSigned && !showStatusRelevant && showStatusOutdated) {
      filtered = rest;
    }
    if (showStatusNotSigned && showStatusRelevant && showStatusOutdated) {
      filtered = data;
    }
    if (!showStatusNotSigned && showStatusRelevant && showStatusOutdated) {
      filtered = onlySigned;
    }
    if (showStatusNotSigned && !showStatusRelevant && showStatusOutdated) {
      filtered = rest ? [...unsigned, ...rest] : unsigned;
    }
    if (showStatusNotSigned && showStatusRelevant && !showStatusOutdated) {
      filtered = first ? [...unsigned, first] : unsigned;
    }
    return filtered || [];
  };
}

export function getToggledSortOrderGeneric<T>(
  actionSortBy: T,
  stateSortBy: T,
  stateOrder: SortOrder
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'desc';
}

export function getSortedDataGeneric<T extends VirsisData>(
  data: T[],
  order: SortOrder,
  sortBy: string
): T[] {
  return stableSort(data, getComparator(order, sortBy)) || [];
}

export function getSortLabelPropsFactory<
  TTableSettings extends { sortBy: TColumnName; order: Order },
  TTableSettingsUpdateFn extends (value: TColumnName) => void,
  TColumnName
>(sortingStateUpdateHandler: TTableSettingsUpdateFn, { sortBy, order }: TTableSettings) {
  return (columnName: TColumnName) => {
    const direction = sortBy === columnName ? order : 'asc';
    const IconComponent = sortBy === columnName ? ActiveSortIcon : SortIcon;
    const onClick = () => sortingStateUpdateHandler(columnName);
    return { active: true, direction, IconComponent, onClick };
  };
}

export function updateCustomFilterGeneric<T extends string>(
  filter: TableCustomFilter<T>,
  filterBy: T,
  value: string | null
): TableCustomFilter<T> {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function updateCustomFilterMultiple<T extends string>(
  filter: TableCustomFilter<T>,
  filterBy: T,
  values: string[]
): TableCustomFilter<T> {
  return {
    ...filter,
    [filterBy]: values || []
  };
}

export type Filter<T> = (entry: T[]) => T[];

export function filterData<T>(data: T[], filters: Filter<T>[]): T[] {
  let filtered = data;
  filters.forEach((filter) => {
    filtered = filter(filtered);
  });
  return filtered;
}

export type ValueMapper<TSource, TMapped> = (value: TSource) => TMapped;

export function getMappedValueFilter<TData, TValue>(
  value: TValue,
  mapper: ValueMapper<TData, TValue>,
  matcher: ValueMatcher<TValue>
): Filter<TData> {
  return (data) => data.filter((element) => matcher(value, mapper(element)));
}

export const stringIncludesIgnoreCaseMatcher: ValueMatcher<string> = (value, data) =>
  !!data && data.toLocaleLowerCase().includes(value.toLocaleLowerCase());

export const stringCompareMoreMatcher: ValueMatcher<string> = (value, data) =>
  !!data && data.toLocaleLowerCase() >= value.toLocaleLowerCase();

export const stringCompareLessMatcher: ValueMatcher<string> = (value, data) =>
  !!data && data.toLocaleLowerCase() <= value.toLocaleLowerCase();

export type ValueMatcher<T> = (value: T, data: T) => boolean;

export function getArrayIntersectMatcher<T>(matcher: ValueMatcher<T>): ValueMatcher<T[]> {
  return (values, data) => {
    if (values.length === 0) {
      return true;
    }
    let result = false;

    values.forEach((value): void => {
      data.forEach((entry): void => {
        if (matcher(value, entry)) {
          result = true;
        }
      });
    });
    return result;
  };
}

export const stringArrayIntersectMatcher = getArrayIntersectMatcher(
  stringIncludesIgnoreCaseMatcher
);

export function getStringMappedColumnFilter<T>(
  filterValues: string[],
  valueMapper: ValueMapper<T, string[]>
): Filter<T> {
  return getMappedValueFilter<T, string[]>(filterValues, valueMapper, stringArrayIntersectMatcher);
}

export const stringArrayCompareMoreMatcher = getArrayIntersectMatcher(stringCompareMoreMatcher);

export function getStringCompareMoreFilter<T>(
  filterValues: string[],
  valueMapper: ValueMapper<T, string[]>
): Filter<T> {
  return getMappedValueFilter<T, string[]>(
    filterValues,
    valueMapper,
    stringArrayCompareMoreMatcher
  );
}

export const stringArrayCompareLessMatcher = getArrayIntersectMatcher(stringCompareLessMatcher);

export function getStringCompareLessFilter<T>(
  filterValues: string[],
  valueMapper: ValueMapper<T, string[]>
): Filter<T> {
  return getMappedValueFilter<T, string[]>(
    filterValues,
    valueMapper,
    stringArrayCompareLessMatcher
  );
}

export function getStatusFilter<T>(
  showNotSigned: boolean,
  showRelevant: boolean,
  showOutdated: boolean,
  signatureStateMapper: ValueMapper<T, WordStatus>,
  decisionDateMapper: ValueMapper<T, Date>,
  relevancyMapper: ValueMapper<T, number>
): Filter<T> {
  return (data): T[] => {
    const unsigned = data.filter((entry) => signatureStateMapper(entry) === 'RUOSIAMAS');
    const signed = data
      .filter((entry) => signatureStateMapper(entry) === 'PASIRASYTAS')
      .sort((a, b) => decisionDateMapper(b).valueOf() - decisionDateMapper(a).valueOf());

    const [first, ...rest] = signed;

    const relevant: T[] = [];

    signed.filter((record) => {
      const isUnique =
        relevant.findIndex(
          (relevantRecord) => relevancyMapper(relevantRecord) === relevancyMapper(record)
        ) <= -1;
      if (isUnique) relevant.push(record);
      return null;
    });

    if (showNotSigned && showRelevant && showOutdated) {
      return data.filter((entry) => signatureStateMapper(entry) !== 'ANULIUOTAS');
    }
    if (showNotSigned && showRelevant && !showOutdated && unsigned && first) {
      return [...unsigned, ...relevant];
    }
    if (showNotSigned && !showRelevant && showOutdated && unsigned && rest) {
      return [...unsigned, ...rest];
    }
    if (showNotSigned && !showRelevant && !showOutdated && unsigned) {
      return unsigned;
    }
    if (!showNotSigned && showRelevant && showOutdated && signed) {
      return signed;
    }
    if (!showNotSigned && showRelevant && !showOutdated && first) {
      return relevant;
    }
    if (!showNotSigned && !showRelevant && showOutdated && rest) {
      return rest;
    }
    return [];
  };
}

export function checkIfDateIsWithinPeriod(dateToCheck: string, periodInDays: number) {
  const date = new Date();
  const numberOfDaysAgoObject = moment(date.setDate(date.getDate() - periodInDays));
  return moment(dateToCheck, 'YYYY-MM-DD') >= numberOfDaysAgoObject;
}

export function getSharedHeaderCellColSpan(displayStatuses: boolean[]): number {
  return displayStatuses.filter((value) => value).length;
}

export function getRecordWithMaxMappedValue<T>(
  data: T[],
  mapper: ValueMapper<T, number>
): T | null {
  if (data.length === 0) {
    return null;
  }
  const sorted = data.sort((a, b) => mapper(b) - mapper(a));
  return sorted[0];
}

export function getRecordWithSecondMaxMappedValue<T>(
  data: T[],
  mapper: ValueMapper<T, number>
): T | null {
  if (data.length === 0) {
    return null;
  }
  const sorted = data.sort((a, b) => mapper(b) - mapper(a));
  return sorted[1];
}

export function getUniqueOptionsGeneric<TData>(
  data: TData[],
  mapper: (value: TData[]) => string[]
) {
  return Array.from(new Set(mapper(data)));
}

export function formatStringOfNumber(number: string) {
  return Number(number).toLocaleString('lt');
}

export function determineRecordStatus(record: DataWithStatuses): string | undefined {
  if (
    record.relevancyStatus === null &&
    (record.confirmationStatus === 'N' || record.confirmationStatus === 'U')
  ) {
    return 'RUOSIAMAS';
  }
  if (record.relevancyStatus === 'A') {
    return 'PASIRASYTAS';
  }
  if (record.relevancyStatus === 'I') {
    return 'ISTORINIS';
  }
  return undefined;
}

export function addNewOrRemoveExisting(newId: number, ids: number[]) {
  const isPresent = ids.find((id) => id === newId);
  return isPresent ? ids.filter((id) => id !== newId) : [...ids, newId];
}

function getPrimitiveItemIndexInArr<T>(arr: T[], item: T) {
  return arr.indexOf(item);
}

export function getPreviousItemInArr<T>(arr: T[], item: T) {
  const index = getPrimitiveItemIndexInArr(arr, item);
  return index !== -1 ? arr[index - 1] : undefined;
}

export function getFollowingItemInArr<T>(arr: T[], item: T) {
  const index = getPrimitiveItemIndexInArr(arr, item);
  return index !== -1 ? arr[index + 1] : undefined;
}

export function convertVirsisYesNoToLabel(value: VirsisYesNoString): string {
  return value === VirsisYesNoString.YES ? 'Taip' : 'Ne';
}

export function convertLabelToVirsisYesNo(value: string): VirsisYesNoString {
  return value === 'Taip' ? VirsisYesNoString.YES : VirsisYesNoString.NO;
}

export function getDaysArray(start: string, end: string) {
  const arr = [];
  const dt = new Date(start);
  for (dt; dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(moment(dt).format('YYYY-MM-DD'));
  }
  if (arr.length < 367) {
    arr.push(moment(dt).format('YYYY-MM-DD'));
  }
  return arr;
}

export function generateAbsoluteDocumentTablePathByDataType(
  dataType: ProvidedDataTypes,
  documentStatusId: number,
  currentUser?: VirsisUser,
  accountType?: AccountType
): string {
  const subSystem = getSubsystem(currentUser);
  if (subSystem === 'VIRSIS') {
    return generateVirsDocumentTablePath(dataType, documentStatusId, currentUser, accountType);
  }

  switch (dataType) {
    case 'Profesinės etikos nesilaikymas':
      return `/duomenu-teikimas/profesines-etikos-nesilaikymas/${documentStatusId}`;
    case 'Rimti profesiniai pažeidimai':
      return `/duomenu-teikimas/profesiniai-pazeidimai/${documentStatusId}`;
    case 'VIRS gautos lėšos':
      return `/duomenu-teikimas/virs-gautos-lesos/${documentStatusId}`;
    case 'Tiražo patikrinimas':
      return `/duomenu-teikimas/tirazo-patikrinimo-duomenys/${documentStatusId}`;
    case 'Metinė įmoka':
      return `/duomenu-teikimas/metines-imokos-duomenys/${documentStatusId}`;
    case 'VIRS duomenys':
      return '/duomenu-perziura-ir-teikimas/veiklos-rusys';
    default:
      return '';
  }
}

function generateVirsDocumentTablePath(
  dataType: ProvidedDataTypes,
  documentStatusId: number,
  currentUser?: VirsisUser,
  accountType?: AccountType
): string {
  switch (dataType) {
    case 'Profesinės etikos nesilaikymas':
      return '/duomenys-is-instituciju/instituciju-istaigu-duomenys/etikos-nesilaikymas';
    case 'Rimti profesiniai pažeidimai':
      return '/duomenys-is-instituciju/instituciju-istaigu-duomenys/pazeidimai';
    case 'VIRS gautos lėšos':
      return currentUser && accountType === AccountType.VIRS
        ? '/duomenys-is-instituciju/instituciju-istaigu-duomenys/gautos-lesos'
        : `/duomenu-teikimas/${documentStatusId}`;
    case 'Tiražo patikrinimas':
      return '/duomenys-is-instituciju/instituciju-istaigu-duomenys/tirazo-patikrinimas';
    case 'Metinė įmoka':
      return '/duomenys-is-instituciju/instituciju-istaigu-duomenys/metine-imoka';
    case 'VIRS duomenys':
      return '/duomenu-perziura-ir-teikimas/el-dokumentai';
    default:
      return '/duomenys-is-instituciju/instituciju-istaigu-duomenys/visi';
  }
}

export function filterEmpty<T>(data: (T | null | undefined)[]): T[] {
  const result: T[] = [];

  data.forEach((value) => {
    if (value !== undefined && value !== null) {
      result.push(value);
    }
  });
  return result;
}

export function getUniqueOptions<Filter, Key extends keyof Filter>(
  filters: Filter | undefined,
  key: Key
): Option[] {
  if (!filters) return [];
  return (filters[key] as unknown as Option[]) || [];
}

export function getAuditsDateTimeFrom(
  dateFrom: string | null | undefined,
  timeFrom: string | null | undefined
): string | null {
  if (dateFrom && timeFrom) {
    const utcDateFrom = moment(dateFrom).utc();
    const momentTimeFrom = moment(timeFrom);
    utcDateFrom.set({
      hour: momentTimeFrom.get('hour'),
      minute: momentTimeFrom.get('minute'),
      second: momentTimeFrom.get('second'),
      millisecond: 0
    });
    return utcDateFrom.toISOString();
  }
  if (dateFrom && !timeFrom) {
    const utcDateFrom = moment(dateFrom).utc();
    const readyDateTimeString = utcDateFrom.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    return readyDateTimeString.toISOString();
  }
  return null;
}

export function getAuditsDateTimeTo(
  dateTo: string | null | undefined,
  timeTo: string | null | undefined
): string | null {
  if (dateTo && timeTo) {
    const utcDateTo = moment(dateTo).utc();
    const momentTimeTo = moment(timeTo);
    utcDateTo.set({
      hour: momentTimeTo.get('hour'),
      minute: momentTimeTo.get('minute'),
      second: momentTimeTo.get('second'),
      millisecond: 0
    });

    return utcDateTo.toISOString();
  }
  if (dateTo && !timeTo) {
    const momentDateTo = moment(dateTo).utc();
    const momentTimeTo = moment();
    momentDateTo.set({
      hour: momentTimeTo.get('hour'),
      minute: momentTimeTo.get('minute'),
      second: momentTimeTo.get('second'),
      millisecond: 0
    });

    return momentDateTo.toISOString();
  }
  return null;
}

export function replaceDecimalPoint(string: string): string {
  string = string.replace(/\.(?=\d)/g, ',');
  string = string.replace(/(?:^|\D)(,\d+)/g, (match, p1) =>
    match.charAt(0) === ',' ? '0' + match : match.charAt(0) + '0' + match.slice(1)
  );

  return string;
}
