import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { SELECT_NATURAL_PERSON_IN_VIRSIS_REQUEST } from './actionTypes';
import {
  searchNaturalPersonInVirsisError,
  searchNaturalPersonInVirsisRequest,
  searchNaturalPersonInVirsisSuccess
} from './actions';
import { getNaturalPersonInVirsis } from './api';

function* handleSearchVirByNaturalPersonsSaga(
  action: ActionType<typeof searchNaturalPersonInVirsisRequest>
) {
  try {
    const { data } = yield call(getNaturalPersonInVirsis, action.payload);
    yield put(searchNaturalPersonInVirsisSuccess(data.virsResultByNaturalPerson));
  } catch (err) {
    yield put(
      searchNaturalPersonInVirsisError(
        err?.response?.data?.message || 'Sistemos klaida, kreipkitės į sistemos administratorių'
      )
    );
  }
}

function* findNaturalPersonInVirsisSaga() {
  yield takeLatest(SELECT_NATURAL_PERSON_IN_VIRSIS_REQUEST, handleSearchVirByNaturalPersonsSaga);
}

export default findNaturalPersonInVirsisSaga;
