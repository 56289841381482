import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  fetchEnterpriseData,
  fetchEnterpriseDataSuccess,
  fetchEnterpriseDataError,
  createEnterprise,
  createEnterpriseSuccess,
  createEnterpriseError,
  updateEnterprise,
  updateEnterpriseSuccess,
  updateEnterpriseError,
  removeEnterprise,
  removeEnterpriseSuccess,
  removeEnterpriseError,
  getOutletIdsRequest,
  getOutletIdsSuccess,
  getOutletIdsError,
  openDeleteEnterpriseAlert,
  resetRemoveEnterpriseState,
  cancelDeleteEnterpriseAlert,
  resetCreateEnterpriseState,
  getEnterpriseDocRequest,
  openEditEnterpriseAlert
} from './enterprisesActions';
import {
  getEnterpriseData,
  postCreateEnterprise,
  putUpdateEnterprise,
  deleteRemoveEnterprise,
  getOutletIds,
  getEnterpriseDataDocument
} from './enterprisesApi';
import {
  FETCH_ENTERPRISE_DATA_REQUEST,
  CREATE_ENTERPRISE_REQUEST,
  UPDATE_ENTERPRISE_REQUEST,
  REMOVE_ENTERPRISE_REQUEST,
  REMOVE_ENTERPRISE_SUCCESS,
  ENTERPRISE_DATA_PDF_REQUEST
} from './enterprisesActionsTypes';
import { outletIds } from './enterpiseSelectors';
import { REMOVE_OUTLETS_SUCCESS } from '../outlets/outletsActionsTypes';
import { virsData } from '../virsis/selectors';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { VirsData } from '../virsis/dataTypes';
import { fetchVirsDataRequest } from '../virsis/actions';

function* handleEnterpriseDataFetchSaga(action: ActionType<typeof fetchEnterpriseData>) {
  try {
    const { virId, personId } = action.payload;
    const { data } = yield call(getEnterpriseData, virId, personId);
    yield put(fetchEnterpriseDataSuccess(data));
  } catch (err) {
    yield put(fetchEnterpriseDataError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleCreateEnterpriseSaga(action: ActionType<typeof createEnterprise>) {
  const { virId, personId, record, message } = action.payload;
  try {
    const { data } = yield call(postCreateEnterprise, virId, personId, record);
    yield put(createEnterpriseSuccess(data));
    yield put(fetchVirsDataRequest());
    yield put(resetCreateEnterpriseState());
    yield put(sendMessage('success', message));
  } catch (err) {
    yield put(createEnterpriseError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleUpdateEnterpriseSaga(action: ActionType<typeof updateEnterprise>) {
  try {
    const { virId, personId, enterpriseId, previousEnterpriseTypeId, record, message } =
      action.payload;
    const { data } = yield call(putUpdateEnterprise, virId, personId, enterpriseId, record);
    yield put(updateEnterpriseSuccess(data));
    yield put(fetchVirsDataRequest());
    yield put(sendMessage('success', message));
    yield call(getOutletIdsSaga, getOutletIdsRequest(enterpriseId));
    const outletIdsList: number[] = yield select(outletIds);
    if (outletIdsList.length && previousEnterpriseTypeId !== record.enterpriseTypeId) {
      yield put(openEditEnterpriseAlert());
    }
  } catch (err) {
    yield put(updateEnterpriseError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

export function* getOutletIdsSaga(action: ActionType<typeof getOutletIdsRequest>) {
  try {
    const { data } = yield call(getOutletIds, action.payload);
    yield put(getOutletIdsSuccess(data.virOutletControlIds));
  } catch (err) {
    yield put(getOutletIdsError(err));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

export function* handleRemoveEnterpriseSaga(action: ActionType<typeof removeEnterprise>) {
  try {
    const { virId, enterpriseId, personId } = action.payload;
    yield call(getOutletIdsSaga, getOutletIdsRequest(enterpriseId));
    const outletIdsList: number[] = yield select(outletIds);
    if (outletIdsList.length) {
      yield put(openDeleteEnterpriseAlert());
      yield take(REMOVE_OUTLETS_SUCCESS);
    }
    yield call(deleteRemoveEnterprise, virId, enterpriseId);
    yield put(removeEnterpriseSuccess(personId));
    yield put(fetchVirsDataRequest());
    yield put(resetRemoveEnterpriseState());
    yield put(sendMessage('success', 'Įrašas pašalintas'));
  } catch (err) {
    yield put(removeEnterpriseError(err.response));
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* reloadEnterpriseDataSaga(action: ActionType<typeof removeEnterpriseSuccess>) {
  try {
    const currentVirs: VirsData | undefined = yield select(virsData);
    if (currentVirs) {
      yield put(fetchEnterpriseData(currentVirs.virsId, action.payload));
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* closeDeleteEnterpriseAlertSaga() {
  try {
    yield put(cancelDeleteEnterpriseAlert());
  } catch (err) {
    yield put(sendMessage('error', err.response.data?.message));
  }
}

function* handleEnterpriseDocumentRequestSaga(action: ActionType<typeof getEnterpriseDocRequest>) {
  try {
    const docId = action.payload;
    const { data } = yield call(getEnterpriseDataDocument, docId);

    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]));
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
  }
}
function* enterprisesSaga() {
  yield takeEvery(FETCH_ENTERPRISE_DATA_REQUEST, handleEnterpriseDataFetchSaga);
  yield takeEvery(CREATE_ENTERPRISE_REQUEST, handleCreateEnterpriseSaga);
  yield takeEvery(REMOVE_ENTERPRISE_REQUEST, handleRemoveEnterpriseSaga);
  yield takeEvery(UPDATE_ENTERPRISE_REQUEST, handleUpdateEnterpriseSaga);
  yield takeEvery(REMOVE_ENTERPRISE_SUCCESS, reloadEnterpriseDataSaga);
  yield takeEvery(REMOVE_OUTLETS_SUCCESS, closeDeleteEnterpriseAlertSaga);
  yield takeEvery(ENTERPRISE_DATA_PDF_REQUEST, handleEnterpriseDocumentRequestSaga);
}

export default enterprisesSaga;
