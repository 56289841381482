import React, { ReactNode } from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { PaymentDataColumnsDisplayStatus } from './tableState/tableInitialStateAndTypes';
import { ExpandDetailsButton } from '../../components/TableExpandDetailsButton/ExpandDetailsButton';
import { ReportInstitutionDataDialog } from '../../components/ReportInstitutionDataDialog/ReportInstitutionDataDialog';
import { ReportErrorAction } from '../../components/TableRowActions/ReportErrorAction';
import { PreviewDocumentDialogMinimal } from '../document/PreviewDocumentDialogMinimal';
import { WordStatus } from '../../utils/tableTypes';
import useInstitutionTableRow from '../../utils/hooks/institutionTableRowHook';
import { TableRowExtension } from '../../components/institutionProvidedDataTable/TableRowExtension';
import { makeStyles } from '@material-ui/core';
import { Roles } from '../../store/virsis/dataTypes';

interface Props {
  columnsToDisplay: PaymentDataColumnsDisplayStatus;
  decisionDate?: string;
  paymentDocuments?: ReactNode;
  paymentOutlets?: ReactNode;
  paymentAmount?: ReactNode;
  validFrom?: ReactNode;
  validTo?: ReactNode;
  paymentDeterminedBy?: ReactNode;
  documentStatus: WordStatus;
  originalDocumentDate: string;
  confirmedByPersonFullName: string;
  elDocument?: ReactNode;
  documentStatusId: number;
}

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

export const PaymentBasicRow: React.FC<Props> = ({
  columnsToDisplay,
  decisionDate,
  paymentDocuments,
  paymentOutlets,
  paymentAmount,
  validFrom,
  validTo,
  paymentDeterminedBy,
  documentStatus,
  originalDocumentDate,
  confirmedByPersonFullName,
  documentStatusId
}) => {
  const classes = useStyles();
  const {
    rowState,
    toggleExtension,
    closeDocumentPreview,
    openDocumentPreview,
    closeReportDialog,
    openReportDialog
  } = useInstitutionTableRow();

  return (
    <>
      {rowState.documentStatusId && rowState.showReport && (
        <ReportInstitutionDataDialog
          documentStatusId={rowState.documentStatusId}
          close={closeReportDialog}
          openDocumentPreview={() => openDocumentPreview(documentStatusId)}
          isOpen
        />
      )}
      {rowState.showPreview && rowState.documentStatusId && (
        <PreviewDocumentDialogMinimal
          maxWidth="lg"
          open={true}
          onClose={closeDocumentPreview}
          documentPath={`virs/legals/documents/${rowState.documentStatusId}`}
        />
      )}
      <TableRow>
        {columnsToDisplay.decisionDate && (
          <TableCell className={classes.dateColumn}>
            <ExpandDetailsButton showExtension={rowState.showExtension} onClick={toggleExtension} />
            {decisionDate}
          </TableCell>
        )}

        {columnsToDisplay.paymentDocuments && <TableCell>{paymentDocuments}</TableCell>}

        {columnsToDisplay.paymentOutlets && <TableCell>{paymentOutlets}</TableCell>}

        {columnsToDisplay.paymentAmount && <TableCell>{paymentAmount}</TableCell>}

        {columnsToDisplay.validFrom && (
          <TableCell className={classes.dateColumn}>{validFrom}</TableCell>
        )}

        {columnsToDisplay.validTo && (
          <TableCell className={classes.dateColumn}>{validTo}</TableCell>
        )}

        {columnsToDisplay.paymentDeterminedBy && <TableCell>{paymentDeterminedBy}</TableCell>}

        <TableCell align="right">
          <ReportErrorAction onClicked={openReportDialog(documentStatusId)} />
        </TableCell>
      </TableRow>

      {rowState.showExtension && (
        <TableRowExtension
          creationDate={decisionDate}
          documentId={documentStatusId}
          documentStatus={documentStatus}
          openDocumentPreview={openDocumentPreview}
          signDate={originalDocumentDate}
          signedByPerson={confirmedByPersonFullName}
          authorizedRolesToViewDocumentRow={[
            Roles.ROLE_KM_METINE_IMOKA_VIEW,
            Roles.ROLE_KM_METINE_IMOKA_EDIT,
            Roles.ROLE_VIRS
          ]}
        />
      )}
    </>
  );
};
