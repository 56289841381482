import { Reducer } from 'redux';

import {
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS,
  FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS,
  FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR,
  CANCEL_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED,
  CONTINUE_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED,
  CREATE_ETHICAL_NON_COMPLIANCE_ERROR,
  CREATE_ETHICAL_NON_COMPLIANCE_REQUEST,
  CREATE_ETHICAL_NON_COMPLIANCE_SUCCESS,
  REMOVE_ETHICAL_NON_COMPLIANCE_ERROR,
  REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST,
  REMOVE_ETHICAL_NON_COMPLIANCE_SUCCESS,
  RESET_CREATE_ETHICAL_NON_COMPLIANCE_STATE,
  RESET_REMOVE_ETHICAL_NON_COMPLIANCE_STATE,
  RESET_UPDATE_ETHICAL_NON_COMPLIANCE_STATE,
  UPDATE_ETHICAL_NON_COMPLIANCE_ERROR,
  UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST,
  UPDATE_ETHICAL_NON_COMPLIANCE_SUCCESS,
  ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST,
  ANNUL_ETHICAL_NON_COMPLIANCE_SUCCESS,
  ANNUL_ETHICAL_NON_COMPLIANCE_ERROR,
  RESET_ANNUL_ETHICAL_NON_COMPLIANCE_STATE,
  FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID
} from './ethicalNonCompliancesActionTypes';
import {
  EthicalNonComplianceData,
  EthicalNonComplianceType,
  VirsEthicalNonComplianceData
} from './ethicalNonCompliancesTypes';
import { TableGenericFilterOption } from '../../utils/tableTypes';
import { EthicalNonComplianceHeaderField } from '../../containers/EthicalNonComplianceTableInst/tableState/tableInitialStateAndTypes';

export interface EthicalNonComplianceDataState {
  ethicalNonComplianceData?: EthicalNonComplianceData[];
  ethicalNonComplianceTypes: EthicalNonComplianceType[];
  virsEthicalNonComplianceData: VirsEthicalNonComplianceData[];
  virsEthicalNonComplianceDataRecordCount: number;
  loadingEthicalNonComplianceData: boolean;
  ethicalNonComplianceDataError?: Error;
  ethicalNonComplianceNewRecordRowOn: boolean;
  creatingEthicalNonCompliance: boolean;
  ethicalNonComplianceCreated: boolean;
  creatingEthicalNonComplianceError: undefined;
  updatingEthicalNonCompliance: boolean;
  ethicalNonComplianceUpdated: boolean;
  updatingEthicalNonComplianceError?: Error;
  removingEthicalNonCompliance: boolean;
  ethicalNonComplianceRemoved: boolean;
  removingEthicalNonComplianceError?: Error;
  annullingEthicalNonComplianceRecord: boolean;
  annullingEthicalNonComplianceError?: Error;
  annullingEthicalNonComplianceSuccess: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  recordCount: number;
  instTableFilters?: TableGenericFilterOption<EthicalNonComplianceHeaderField>;
}

export const initialState: EthicalNonComplianceDataState = {
  loadingEthicalNonComplianceData: true,
  ethicalNonComplianceData: undefined,
  ethicalNonComplianceTypes: [],
  ethicalNonComplianceDataError: undefined,
  virsEthicalNonComplianceData: [],
  virsEthicalNonComplianceDataRecordCount: 0,
  ethicalNonComplianceNewRecordRowOn: false,
  creatingEthicalNonCompliance: false,
  ethicalNonComplianceCreated: false,
  creatingEthicalNonComplianceError: undefined,
  updatingEthicalNonCompliance: false,
  ethicalNonComplianceUpdated: false,
  updatingEthicalNonComplianceError: undefined,
  removingEthicalNonCompliance: false,
  ethicalNonComplianceRemoved: false,
  removingEthicalNonComplianceError: undefined,
  annullingEthicalNonComplianceRecord: false,
  annullingEthicalNonComplianceError: undefined,
  annullingEthicalNonComplianceSuccess: false,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  recordCount: 0,
  instTableFilters: undefined
};

export const ethicalNonCompliancesReducer: Reducer<EthicalNonComplianceDataState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST: {
      return {
        ...state,
        loadingEthicalNonComplianceData: true,
        creatingEthicalNonCompliance: false,
        ethicalNonComplianceCreated: false,
        creatingEthicalNonComplianceError: undefined,
        updatingEthicalNonCompliance: false,
        ethicalNonComplianceUpdated: false,
        updatingEthicalNonComplianceError: undefined,
        removingEthicalNonCompliance: false,
        ethicalNonComplianceRemoved: false,
        removingEthicalNonComplianceError: undefined
      };
    }
    case FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingEthicalNonComplianceData: false,
        ethicalNonComplianceData: action.payload.ethicalNonCompliancesData,
        ethicalNonComplianceTypes: action.payload.ethicalNonComplianceTypes,
        ethicalNonComplianceDataError: undefined,
        statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
        statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
        statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
        recordCount: action.payload.recordCount,
        instTableFilters: action.payload.filterValues
      };
    }
    case FETCH_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR: {
      return {
        ...state,
        loadingEthicalNonComplianceData: false,
        ethicalNonComplianceDataError: action.payload
      };
    }
    case FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST: {
      return { ...state, loadingEthicalNonComplianceData: true };
    }
    case FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingEthicalNonComplianceData: false,
        virsEthicalNonComplianceData: action.payload,
        ethicalNonComplianceDataError: undefined,
        virsEthicalNonComplianceDataRecordCount: action.payload.length
      };
    }
    case FETCH_VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST_ERROR: {
      return {
        ...state,
        loadingEthicalNonComplianceData: false,
        ethicalNonComplianceDataError: action.payload
      };
    }
    case CREATE_ETHICAL_NON_COMPLIANCE_REQUEST:
      return {
        ...state,
        creatingEthicalNonCompliance: true
      };
    case CREATE_ETHICAL_NON_COMPLIANCE_SUCCESS:
      return {
        ...state,
        creatingEthicalNonCompliance: false,
        ethicalNonComplianceCreated: true
      };
    case CREATE_ETHICAL_NON_COMPLIANCE_ERROR:
      return {
        ...state,
        creatingEthicalNonCompliance: false,
        creatingEthicalNonComplianceError: action?.payload?.data
      };
    case RESET_CREATE_ETHICAL_NON_COMPLIANCE_STATE:
      return {
        ...state,
        creatingEthicalNonCompliance: false,
        ethicalNonComplianceCreated: false,
        creatingEthicalNonComplianceError: undefined
      };
    case UPDATE_ETHICAL_NON_COMPLIANCE_REQUEST:
      return {
        ...state,
        updatingEthicalNonCompliance: true
      };
    case UPDATE_ETHICAL_NON_COMPLIANCE_SUCCESS:
      return {
        ...state,
        updatingEthicalNonCompliance: false,
        ethicalNonComplianceUpdated: true
      };
    case UPDATE_ETHICAL_NON_COMPLIANCE_ERROR:
      return {
        ...state,
        updatingEthicalNonCompliance: false,
        ethicalNonComplianceUpdated: false,
        updatingEthicalNonComplianceError: action?.payload?.data
      };
    case RESET_UPDATE_ETHICAL_NON_COMPLIANCE_STATE:
      return {
        ...state,
        updatingEthicalNonCompliance: false,
        ethicalNonComplianceUpdated: false,
        updatingEthicalNonComplianceError: undefined
      };
    case CONTINUE_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED:
      return {
        ...state,
        ethicalNonComplianceCheckNewRecordRowOn: true
      };
    case CANCEL_ADDING_ETHICAL_NON_COMPLIANCE_CLICKED:
      return {
        ...state,
        ethicalNonComplianceNewRecordRowOn: false
      };
    case RESET_REMOVE_ETHICAL_NON_COMPLIANCE_STATE:
      return {
        ...state,
        removingEthicalNonCompliance: false,
        ethicalNonComplianceRemoved: false,
        removingEthicalNonComplianceError: undefined
      };
    case REMOVE_ETHICAL_NON_COMPLIANCE_REQUEST:
      return {
        ...state,
        removingEthicalNonCompliance: true
      };
    case REMOVE_ETHICAL_NON_COMPLIANCE_SUCCESS:
      return {
        ...state,
        ethicalNonComplianceRemoved: true
      };
    case REMOVE_ETHICAL_NON_COMPLIANCE_ERROR:
      return {
        ...state,
        removingEthicalNonCompliance: false,
        removingEthicalNonComplianceError: action?.payload?.data
      };
    case ANNUL_ETHICAL_NON_COMPLIANCE_REQUEST:
      return {
        ...state,
        annullingEthicalNonComplianceRecord: true
      };
    case ANNUL_ETHICAL_NON_COMPLIANCE_SUCCESS:
      return {
        ...state,
        annullingEthicalNonComplianceRecord: false,
        annullingEthicalNonComplianceSuccess: true
      };
    case ANNUL_ETHICAL_NON_COMPLIANCE_ERROR:
      return {
        ...state,
        annullingEthicalNonComplianceRecord: false,
        annullingEthicalNonComplianceSuccess: false,
        annullingEthicalNonComplianceError: action?.payload?.data
      };
    case RESET_ANNUL_ETHICAL_NON_COMPLIANCE_STATE:
      return {
        ...state,
        removingEthicalNonCompliance: false,
        annullingProfessionalMisconductSuccess: false,
        annullingEthicalNonComplianceError: undefined
      };
    case FETCH_ETHICAL_NON_COMPLIANCE_BY_DOC_ID:
      return {
        ...state,
        loadingEthicalNonComplianceData: true
      };
    default: {
      return state;
    }
  }
};
