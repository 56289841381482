import React from 'react';
import { useSelector } from 'react-redux';
import {
  createStyles,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Accordion from '@material-ui/core/Accordion';
import { ApplicationState } from '../../store';
import { withLoadedUser } from '../../containers/hoc/withLoadedUser';
import { VirsData } from '../../components/VirsContextComponents/VirsData';
import { InstitutionData } from '../../components/VirsContextComponents/InstitutionData';
import { VirsDataContainer } from '../../components/VirsDataContainer/VirsDataContainer';

const useStyles = makeStyles(() =>
  createStyles({
    infoRow: {
      padding: '5px 0',
      '&:not(:last-child)': {
        borderBottom: '1px solid #e5e5e5'
      }
    },
    boxPadding: {
      padding: 15
    },
    accordionBox: {
      '&.MuiAccordion-root.Mui-expanded:before': {
        backgroundColor: '#aeaeae',
        opacity: 1
      },
      '&.MuiAccordion-root:before': {
        top: '70px'
      },
      borderRadius: '10px',
      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden'
    },
    accordionTitleWrapper: {
      '&:hover': {
        backgroundColor: '#F5F8FF',
        transition: 'background-color .3s'
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '70px',
        padding: '0px 25px'
      },
      justifyContent: 'flex-start',
      padding: '0px 25px',
      minHeight: '70px'
    },
    accordionTitle: {
      fontSize: '1rem',
      color: '#5A5A5A',
      fontWeight: 800,
      fontFamily: 'Open Sans'
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: '15px',
      display: 'inline-block',
      fontSize: '1.1rem'
    },
    classChangeGrid: {
      width: '170px'
    },
    classChangeGrid2: {
      width: '250px'
    },
    relevantData: {
      padding: '10px',
      marginTop: '20px'
    },
    borderRight: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)'
    },
    textAlignRight: {
      textAlign: 'right'
    }
  })
);

const VirsPage: React.FC = () => {
  const classes = useStyles();

  const { virsData, currentUser } = useSelector((state: ApplicationState) => state.virsis);

  if (!virsData || !currentUser) {
    return null;
  }

  return (
    <div className="data-page">
      <Container maxWidth="lg">
        <div className="table-title">
          <Box>
            <Typography variant="h2" className="text-black" gutterBottom>
              {`${virsData.name} ${virsData.personName} ${virsData.personLastName}`}
            </Typography>
            <Typography className="text-grey">
              Jūsų paskutinis apsilankymas: {currentUser.recentActivity}
            </Typography>
          </Box>
        </div>
        <Accordion className={classes.accordionBox}>
          <AccordionSummary
            className={classes.accordionTitleWrapper}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="subtitle1"
              className={classes.accordionTitle}
            >{`${virsData.name} duomenys`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <VirsDataContainer virsData={virsData} />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <VirsData />
        <InstitutionData />
      </Container>
    </div>
  );
};

export default withLoadedUser(VirsPage);
