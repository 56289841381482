import React from 'react';
import { TableCell, TableHead, TableRow, Typography, TableSortLabel } from '@material-ui/core';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import { useProfessionalMisconductInstTableState } from './Context';
import {
  ProfessionalMisconductInstTableField,
  ProfessionalMisconductInstColumnsDisplayStatus
} from './tableState/misconductTableInitialStateAndTypes';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { sortingChanged } from '../../store/professionalMisconductTable/professionalMisconductsActions';

const MisconductTableHeader = () => {
  const { state: tableState } = useProfessionalMisconductInstTableState();
  const { tableDataState } = useSelector(
    (state: ApplicationState) => state.professionalMisconductTableData
  );

  const reduxDispatch = useDispatch();

  const setSorting = (column: ProfessionalMisconductInstTableField) => {
    reduxDispatch(sortingChanged(column));
  };

  const createSortHandler =
    (column: ProfessionalMisconductInstTableField) => (_event: React.MouseEvent<unknown>) => {
      setSorting(column);
    };

  const calculateSharedDecisionCellColSpan = (
    displayStatus: ProfessionalMisconductInstColumnsDisplayStatus
  ): number => {
    const displayingColumns = [displayStatus.decisionDate, displayStatus.documentNumber];
    return displayingColumns.filter((value) => value === true).length;
  };

  const calculateSharedDatesCellColSpan = (
    displayStatus: ProfessionalMisconductInstColumnsDisplayStatus
  ): number => {
    const displayingColumns = [displayStatus.validFrom, displayStatus.validTo];
    return displayingColumns.filter((value) => value === true).length;
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.virsName && (
          <TableCell
            rowSpan={2}
            key="virsName"
            sortDirection={tableDataState.sortBy === 'virsName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('virsName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'virsName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'virsName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIRS</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.documentNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedDecisionCellColSpan(tableState.columnsDisplayStatus)}
          >
            <Typography variant="h4">Sprendimo</Typography>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.outletName && (
          <TableCell
            rowSpan={2}
            key="outletName"
            sortDirection={tableDataState.sortBy === 'outletName' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('outletName')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'outletName' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'outletName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Susijusios VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.professionalMisconduct && (
          <TableCell
            rowSpan={2}
            key="professionalMisconduct"
            sortDirection={
              tableDataState.sortBy === 'professionalMisconduct' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={createSortHandler('professionalMisconduct')}
          >
            <TableSortLabel
              active
              direction={
                tableDataState.sortBy === 'professionalMisconduct' ? tableDataState.order : 'asc'
              }
              IconComponent={
                tableDataState.sortBy === 'professionalMisconduct' ? ActiveSortIcon : SortIcon
              }
            >
              <Typography variant="h4">Nustatytas pažeidimas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.sanction && (
          <TableCell
            rowSpan={2}
            key="sanction"
            sortDirection={tableDataState.sortBy === 'sanction' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('sanction')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'sanction' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'sanction' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Poveikio priemonė(-s)</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={calculateSharedDatesCellColSpan(tableState.columnsDisplayStatus)}
          >
            <Typography variant="h4">Pažeidimas galioja</Typography>
          </TableCell>
        )}
        <TableCell rowSpan={2}>
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableCell
            rowSpan={1}
            key="decisionDate"
            sortDirection={tableDataState.sortBy === 'decisionDate' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('decisionDate')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'decisionDate' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'decisionDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Data</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.documentNumber && (
          <TableCell
            rowSpan={1}
            key="documentNumber"
            sortDirection={
              tableDataState.sortBy === 'documentNumber' ? tableDataState.order : false
            }
            className="TableCellSortable"
            onClick={createSortHandler('documentNumber')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'documentNumber' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'documentNumber' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nr.</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.validFrom && (
          <TableCell
            rowSpan={1}
            key="validFrom"
            sortDirection={tableDataState.sortBy === 'validFrom' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('validFrom')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'validFrom' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'validFrom' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Nuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.validTo && (
          <TableCell
            rowSpan={1}
            key="validTo"
            sortDirection={tableDataState.sortBy === 'validTo' ? tableDataState.order : false}
            className="TableCellSortable"
            onClick={createSortHandler('validTo')}
          >
            <TableSortLabel
              active
              direction={tableDataState.sortBy === 'validTo' ? tableDataState.order : 'asc'}
              IconComponent={tableDataState.sortBy === 'validTo' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Iki</Typography>
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default MisconductTableHeader;
