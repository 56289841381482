import React, { ChangeEvent, useContext, useEffect, useReducer, useState } from 'react';
import { makeStyles, TableCell, TextField } from '@material-ui/core/';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { EthicalNonComplianceRowBasic } from './RowBasic';
import { Roles, VirsSearchData } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import {
  EthicalNonComplianceData,
  EthicalNonComplianceRecord,
  EthicalNonComplianceType
} from '../../../store/ethicalNonCompliances/ethicalNonCompliancesTypes';
import { FindVirsDialog } from '../../../components/TableDialogFindVirs/FindVirsDialog';
import { EnterpriseType, AnnulRecord } from '../../../store/classifiers/classifiersTypes';
import AllowedToAllExcept from '../../AllowedToAllExcept';
import { OutletsAsLinksList } from '../../../components/TableLinks/OutletsAsLinksList';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import {
  annulEthicalNonCompliance,
  fetchEthicalNonComplianceData,
  removeEthicalNonCompliance,
  resetAnnulEthicalNonComplianceState,
  resetEthicalNonComplianceRemovingState,
  resetEthicalNonComplianceUpdatingState,
  updateEthicalNonCompliance
} from '../../../store/ethicalNonCompliances/ethicalNonCompliancesActions';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { ApplicationState } from '../../../store';
import { newOrUpdatedEthicalNonCompliance } from '../Table';
import { VirsInputCell } from '../../../components/TableInputs/VirsInputCell';
import { DropdownMultipleInputCell } from '../../../components/TableInputs/DropdownMultipleInput';
import PreviewAndSignDocumentDialog from '../../document/PreviewAndSignDocumentDialog';
import { DocumentNumberInputCell } from '../../../components/TableInputs/DocumentNumberInput';
import { AnnulDocumentDialog } from '../../../components/AnnulDocumentDialog/AnnulDocumentDialog';
import { SignDocumentButton } from '../../../components/TableButtons/RowActionButtons/SignDocumentButton';
import { AnnulDocumentButton } from '../../../components/TableButtons/RowActionButtons/AnnulDocumentButton';
import { EthicalNonComplianceTableStateContext } from '../Context';
import { editEthicalNonComplianceRowReducer } from './RowState/editEthicalNonComplianceReducer';
import { initialEthicalNonComplianceEditRecordState } from './RowState/initialEthicalNonComplianceStateAndTypes';
import { VirsNameLink } from '../../../components/TableLinks/VirsNameLink';
import { validateEditRowState } from './RowState/rowReducerFunctions';
import { noOptionsText } from '../../../components/TableCustomFilterCell/constants';
import { ViewDocumentButton } from '../../../components/TableButtons/RowActionButtons/ViewDocumentButton';
import {
  disableOthersIfFictitiousSelected,
  getOutletMultiOption
} from '../../../components/TableInputs/Utilities';
import { AuthorisedEditAndRemoveActions } from '../../../components/TableRowActions/AuthorisedEditAndRemoveActions';

interface Props {
  record: EthicalNonComplianceData;
  enterpriseTypes: EnterpriseType[] | undefined;
  ethicalNonComplianceTypes: EthicalNonComplianceType[];
}

const useStyles = makeStyles({
  dateColumn: {
    whiteSpace: 'nowrap'
  }
});

export const EthicalNonComplianceRowContainer: React.FC<Props> = ({
  record,
  enterpriseTypes,
  ethicalNonComplianceTypes
}) => {
  const [openDocumentDialog, setOpenDocumentDialog] = useState<boolean>(false);

  const [rowState, rowDispatch] = useReducer(
    editEthicalNonComplianceRowReducer,
    initialEthicalNonComplianceEditRecordState
  );

  const {
    classifiers: { annulmentTypes, fictitiousOutlet },
    ethicalNonComplianceData: {
      ethicalNonComplianceUpdated,
      updatingEthicalNonComplianceError,
      removingEthicalNonCompliance,
      ethicalNonComplianceRemoved,
      removingEthicalNonComplianceError,
      annullingEthicalNonComplianceRecord,
      annullingEthicalNonComplianceError
    }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  const { state: tableState } = useContext(EthicalNonComplianceTableStateContext);

  const classes = useStyles();

  const onUpdateRecordConfirmed = () => {
    rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
    if (validateEditRowState(rowState).updateRecordConfirmationOn) {
      rowDispatch({ type: 'START_LOADING' });
      const object: EthicalNonComplianceRecord = newOrUpdatedEthicalNonCompliance(
        rowState.recordId,
        rowState.virs.value ? rowState.virs.value.virId : null,
        rowState.decisionDate,
        rowState.documentNumber,
        rowState.outlets,
        rowState.decisionStatus.id,
        rowState.validFrom,
        rowState.validTo
      );
      reduxDispatch(updateEthicalNonCompliance(object, rowState.recordId));
    }
  };

  const onRemoveRecordClicked = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  };

  const closeOnRemoveError = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_ERROR' });
    reduxDispatch(resetEthicalNonComplianceRemovingState());
  };

  function openAnnulDocumentDialog() {
    rowDispatch({
      type: 'ANNUL_RECORD_CLICKED',
      annulmentTypes: annulmentTypes || []
    });
  }

  function closeAnnulDocumentDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
  }

  function closeAnnulDocumentOnErrorDialog() {
    rowDispatch({ type: 'ANNUL_RECORD_CANCELLED' });
    reduxDispatch(resetAnnulEthicalNonComplianceState());
  }

  function submitAnnulRecord(annulRecord: AnnulRecord) {
    reduxDispatch(annulEthicalNonCompliance(record.documentStatusId, annulRecord));
    closeAnnulDocumentDialog();
  }

  function setValidToDate(validTo: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'VALID_TO_DATE_CHANGED',
      validTo
    });
  }

  useEffect(() => {
    if (ethicalNonComplianceUpdated) {
      rowDispatch({ type: 'UPDATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
      reduxDispatch(resetEthicalNonComplianceUpdatingState());
    }
  }, [reduxDispatch, ethicalNonComplianceUpdated]);

  useEffect(() => {
    if (updatingEthicalNonComplianceError) rowDispatch({ type: 'STOP_LOADING' });
  }, [rowDispatch, updatingEthicalNonComplianceError]);

  useEffect(() => {
    rowDispatch({ type: 'REMOVE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS' });
  }, [ethicalNonComplianceRemoved]);

  return (
    <>
      <EthicalNonComplianceRowBasic
        documentStatus={record.documentStatus.id}
        columnsToDisplay={tableState.columnsDisplayStatus}
        virsName={
          !rowState.editingOn && (
            <>
              <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
                <VirsNameLink
                  virsName={record.virsName}
                  virsLegalCode={record.virsLegalCode}
                  virsId={record.virsId}
                />
              </AllowedTo>
              <AllowedToAllExcept
                roles={[Roles.ROLE_VIRS_VIEW]}
              >{`${record.virsName} ${record.virsLegalCode}`}</AllowedToAllExcept>
            </>
          )
        }
        virsInput={
          rowState.editingOn && (
            <VirsInputCell
              openSearchDialog={() => rowDispatch({ type: 'FIND_VIRS_DIALOG_OPENED' })}
              state={rowState.virs}
            />
          )
        }
        decisionDate={
          rowState.editingOn ? (
            <DateInputImprovedCell
              state={rowState.decisionDate}
              setDate={(decisionDate: MaterialUiPickersDate | null) =>
                rowDispatch({
                  type: 'DECISION_DATE_CHANGED',
                  decisionDate
                })
              }
              isRequired
            />
          ) : (
            <TableCell className={classes.dateColumn}>{record.decisionDate}</TableCell>
          )
        }
        documentNumberInput={
          rowState.editingOn && (
            <DocumentNumberInputCell
              setValue={(documentNumber: string) =>
                rowDispatch({
                  type: 'DOCUMENT_NUMBER_CHANGED',
                  documentNumber
                })
              }
              inputState={rowState.documentNumber}
              isRequired
            />
          )
        }
        documentNumber={!rowState.editingOn && record.documentNumber}
        outletList={
          !rowState.editingOn && (
            <>
              <AllowedTo roles={[Roles.ROLE_VIRS_VIEW]}>
                <OutletsAsLinksList outlets={record.outletList} virsId={record.virsId} />
              </AllowedTo>
              <AllowedToAllExcept roles={[Roles.ROLE_VIRS_VIEW]}>
                {record.outletList.map((outlet) => (
                  <div key={outlet.outletId}>{outlet.outletName}</div>
                ))}
              </AllowedToAllExcept>
            </>
          )
        }
        outletListInput={
          rowState.editingOn && (
            <DropdownMultipleInputCell
              selectValues={(values) =>
                rowDispatch({
                  type: 'OUTLET_LIST_CHANGED',
                  outlets: values
                })
              }
              state={rowState.outlets}
              getOptionLabel={(option) =>
                `${option.outletName} ${option.outletClosureStatus ? '(Uždarytas)' : ''}`
              }
              getOptionSelected={(option, value) =>
                value ? option.outletId === value.outletId : false
              }
              getOptionDisabled={disableOthersIfFictitiousSelected(
                fictitiousOutlet && fictitiousOutlet[0]
              )}
              renderOption={getOutletMultiOption}
            />
          )
        }
        decisionStatus={
          rowState.editingOn ? (
            <Autocomplete
              size="small"
              autoComplete
              disableClearable
              noOptionsText={noOptionsText}
              style={{ width: '100%' }}
              options={ethicalNonComplianceTypes}
              getOptionLabel={(option) => option.ethicalNonComplianceTypeName}
              renderInput={(params) => (
                <form noValidate autoComplete="off">
                  <TextField
                    required
                    error={rowState.decisionStatus.error}
                    helperText={
                      rowState.decisionStatus.error ? 'reikia pasirinkti iš sąrašo' : undefined
                    }
                    label="Pasirinkti būseną"
                    {...params}
                    variant="outlined"
                  />
                </form>
              )}
              onChange={(event: ChangeEvent<object>, value: EthicalNonComplianceType) =>
                rowDispatch({
                  type: 'DECISION_STATUS_CHANGED',
                  decisionStatus: value
                })
              }
              value={
                ethicalNonComplianceTypes[
                  ethicalNonComplianceTypes.findIndex(
                    (option) => option.ethicalNonComplianceId === rowState.decisionStatus.id
                  )
                ]
              }
            />
          ) : (
            record.decisionStatus
          )
        }
        validFrom={
          rowState.editingOn ? (
            <DateInputImprovedCell
              state={rowState.validFrom}
              setDate={(validFrom: MaterialUiPickersDate | null) =>
                rowDispatch({
                  type: 'VALID_FROM_DATE_CHANGED',
                  validFrom
                })
              }
              isRequired
            />
          ) : (
            <TableCell className={classes.dateColumn}>{record.validFrom}</TableCell>
          )
        }
        validTo={
          rowState.editingOn ? (
            <DateInputImprovedCell state={rowState.validTo} setDate={setValidToDate} isRequired />
          ) : (
            <TableCell className={classes.dateColumn}>{record.validTo}</TableCell>
          )
        }
        actions={
          rowState.editingOn ? (
            <SaveAndCancelActions
              handleConfirmationYes={onUpdateRecordConfirmed}
              isProcessing={rowState.loading}
              handleCancelButtonClick={() => rowDispatch({ type: 'EDITING_CANCELLED' })}
            />
          ) : (
            record.removable && (
              <AuthorisedEditAndRemoveActions
                handleEditButtonClick={() =>
                  rowDispatch({
                    type: 'EDITING_INITIALIZED',
                    record,
                    ethicalNonComplianceTypes,
                    fictitiousOutlet: fictitiousOutlet || []
                  })
                }
                handleRemoveButtonClick={onRemoveRecordClicked}
                confirmationOn={rowState.removeRecordConfirmationOn}
                handleConfirmationYes={() =>
                  reduxDispatch(removeEthicalNonCompliance(record.ethicalNonComplianceId))
                }
                handleConfirmationNo={() =>
                  rowDispatch({
                    type: 'REMOVE_RECORD_CONFIRMATION_CANCELLED'
                  })
                }
                isSuccess={ethicalNonComplianceRemoved}
                isProcessing={removingEthicalNonCompliance}
                error={removingEthicalNonComplianceError}
                onErrorClose={closeOnRemoveError}
                recordCreatedByCode={record.checkedByCode}
                editingWarningMessage={record.editingWarningMessage}
              />
            )
          )
        }
        elDocument={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {record.removable && <SignDocumentButton onClick={() => setOpenDocumentDialog(true)} />}
            {record.documentStatus.id === 'PASIRASYTAS' && (
              <>
                <ViewDocumentButton onClick={() => setOpenDocumentDialog(true)} />
                <AnnulDocumentButton
                  annulmentDisablementReason={record.annulmentDisablementReason}
                  onClick={openAnnulDocumentDialog}
                />
              </>
            )}
          </div>
        }
      />
      {rowState.showFindVirsDialog && (
        <FindVirsDialog
          selectedVirs={rowState.virs.value as unknown as VirsSearchData}
          dialogOpen={rowState.showFindVirsDialog}
          enterpriseTypes={enterpriseTypes || []}
          closeDialog={() => rowDispatch({ type: 'FIND_VIRS_DIALOG_CLOSED' })}
          closeAndContinueWithVirs={(virs: VirsSearchData) =>
            rowDispatch({
              type: 'VIRS_SELECTED',
              virs,
              fictitiousOutlet: fictitiousOutlet || []
            })
          }
        />
      )}
      <PreviewAndSignDocumentDialog
        openDialog={openDocumentDialog}
        documentPath={`ethical-non-compliance-data/documents/${record.documentStatusId}`}
        documentId={record.documentStatusId}
        documentStatus={record.documentStatus.id}
        onClose={() => setOpenDocumentDialog(false)}
        documentErrors={[]}
        onSignSuccess={() => reduxDispatch(fetchEthicalNonComplianceData())}
      />
      <AnnulDocumentDialog
        open={rowState.annulRecordConfirmationOn}
        isError={!!annullingEthicalNonComplianceError}
        isProcessing={annullingEthicalNonComplianceRecord}
        annulmentComment={rowState.annulmentComment}
        annulmentType={rowState.annulmentType}
        onClose={closeAnnulDocumentDialog}
        onErrorClose={closeAnnulDocumentOnErrorDialog}
        onSubmit={submitAnnulRecord}
        errorMessage={annullingEthicalNonComplianceError}
      />
    </>
  );
};
