import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Box } from '@material-ui/core';

interface Prop {
  label: string;
  value: string;
  className?: string;
  customGrid?: any;
  customGrid2?: any;
}

const LabelTextField: React.FC<Prop> = ({ label, value, className, customGrid, customGrid2 }) => {
  return (
    <Grid container spacing={0} className={className}>
      <Grid key={0} item xs={customGrid}>
        <Typography variant="caption" display="block" gutterBottom>
          <Box mr={2} textAlign="right">
            {label}
          </Box>
        </Typography>
      </Grid>
      <Grid key={1} item xs={customGrid2}>
        <Box>
          <Typography variant="body1" display="block" gutterBottom style={{ fontSize: '0.9rem' }}>
            {value}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LabelTextField;
