import React, { createContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import BottomPageActionButtons from '../../components/PageButtons/BottomPageActionButtons';
import { ApplicationState } from '../../store';
import {
  createEnterprise,
  fetchEnterpriseData,
  removeEnterprise,
  updateEnterprise
} from '../../store/enterprises/enterprisesActions';
import { fetchEnterpriseTypesRequest } from '../../store/classifiers/classifiersActions';
import { EnterpriseRecord } from '../../store/enterprises/enterprisesTypes';
import { EnterpriseDataTable } from '../../containers/EnterpriseTable/Context';
import { EnterpriseDataApiFunctions } from '../../containers/EnterpriseTable/Table';
import { getSubsystem } from '../../utils/roleHelper';
import { unsignedDataRemoveVirs } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { VirsDocumentType } from '../../store/unsignedDataRemoval/unsignedDataTypes';

interface Props {
  institutionContext?: boolean;
}

export const enterprisePageContext = createContext<
  { reloadTableData: () => void; removeUnsigned: () => void } | undefined
>(undefined);

const EnterpriseDataPage: React.FC<Props> = ({ institutionContext }) => {
  const history = useHistory();

  const showWithNewRecordOpened = useLocation().state as any;

  const {
    virsis: { currentUser, virsData, selectedPersonId },
    classifiers: { enterpriseTypes }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();
  const isVirsis = getSubsystem(currentUser) !== 'VIRSIS';
  const handleGoToPageClick = () => {
    const path = isVirsis
      ? `/virs-duomenys/${virsData?.virsId}/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones`
      : '/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones';
    history.push(path);
  };

  const enterpriseDataApiFunctions: EnterpriseDataApiFunctions = {
    getEnterpriseRecords: () => {
      if (currentUser && virsData) {
        reduxDispatch(
          fetchEnterpriseData(virsData?.virsId, selectedPersonId || currentUser?.personId)
        );
      }
    },
    createEnterpriseRecord: (record: EnterpriseRecord, message: string) => {
      if (currentUser && virsData) {
        reduxDispatch(
          createEnterprise(
            virsData?.virsId,
            selectedPersonId || currentUser?.personId,
            record,
            message
          )
        );
      }
    },
    updateEnterpriseRecord: (
      recordId: number,
      previousEnterpriseTypeId: number,
      record: EnterpriseRecord,
      message: string
    ) => {
      if (currentUser && virsData) {
        reduxDispatch(
          updateEnterprise(
            virsData?.virsId,
            selectedPersonId || currentUser?.personId,
            recordId,
            previousEnterpriseTypeId,
            record,
            message
          )
        );
      }
    },
    removeEnterpriseRecord: (recordId: number) => {
      if (currentUser && virsData) {
        reduxDispatch(
          removeEnterprise(virsData?.virsId, recordId, selectedPersonId || currentUser.personId)
        );
      }
    }
  };

  const pageContext = useMemo(() => {
    const context = {
      reloadTableData: () => {
        if (institutionContext && selectedPersonId && virsData && virsData.virsId !== 0) {
          reduxDispatch(fetchEnterpriseData(virsData.virsId, selectedPersonId));
        }
        if (!institutionContext && currentUser && virsData && virsData.virsId !== 0) {
          reduxDispatch(fetchEnterpriseData(virsData.virsId, currentUser.personId));
        }
      },
      removeUnsigned: () => {
        if (virsData) {
          reduxDispatch(unsignedDataRemoveVirs(virsData.virsId, VirsDocumentType.ENTERPRISE));
        }
      }
    };
    return context;
  }, [reduxDispatch, currentUser, virsData, institutionContext, selectedPersonId]);

  useEffect(() => {
    pageContext.reloadTableData();
  }, [pageContext]);

  useEffect(() => {
    if (!enterpriseTypes) {
      reduxDispatch(fetchEnterpriseTypesRequest());
    }
  }, [enterpriseTypes, reduxDispatch]);

  return (
    <enterprisePageContext.Provider value={pageContext}>
      <EnterpriseDataTable
        apiFunctions={enterpriseDataApiFunctions}
        withNewRecordOpened={showWithNewRecordOpened}
      />
      <BottomPageActionButtons
        pageToGo="Visuomenės informavimo priemonės (VIP)"
        handleGoToPageClick={handleGoToPageClick}
      />
    </enterprisePageContext.Provider>
  );
};

export default EnterpriseDataPage;
