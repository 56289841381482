import React, { useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCell, TextField } from '@material-ui/core';
import { EnterpriseType, EnterpriseTypeShort } from '../../../store/classifiers/classifiersTypes';
import { EnterpriseDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { ApplicationState } from '../../../store';
import { EnterpriseDataApiFunctions } from '../Table';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { DropdownInputCell } from '../../../components/TableInputs/DropdownInput';
import { newEnterpriseRecordReducer } from './rowState/newRecordReducer';
import { initialEnterpriseNewRecordState } from './rowState/rowInitialStateAndTypes';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { validateNewEnterpriseRecord } from './rowState/rowReducerFunctions';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { BasicTooltip } from '../../../components/Tooltips/BasicTooltip';

interface Props {
  unconfirmedFilterOn: boolean;
  enterpriseTypes: EnterpriseType[];
  enterpriseDataApiFunctions: EnterpriseDataApiFunctions;
  columnsDisplayStatus: EnterpriseDataTableColumnsDisplayStatus;
  closeCreatingRecord: () => void;
  legalRegistrationDate: string;
  legalDeregistrationDate: string | null;
}

const TextFieldWithTooltip: React.FC<TextFieldProps> = (props) => (
  <BasicTooltip text={'Data įvedama, kai veikla nebevykdoma'}>
    <TextField {...props} />
  </BasicTooltip>
);

const TextFieldWithTooltipDateFrom: React.FC<TextFieldProps> = (props) => (
  <BasicTooltip text={'Įveskite datą, nuo kurios pradėjote vykdyti pasirinktą veiklą'}>
    <TextField {...props} />
  </BasicTooltip>
);

export const EnterpriseTableRowContainerNew: React.FC<Props> = ({
  enterpriseTypes,
  columnsDisplayStatus,
  closeCreatingRecord,
  enterpriseDataApiFunctions,
  unconfirmedFilterOn,
  legalRegistrationDate,
  legalDeregistrationDate
}) => {
  const [rowState, rowDispatch] = useReducer(
    newEnterpriseRecordReducer,
    initialEnterpriseNewRecordState
  );

  const { creatingEnterprise, isEnterpriseCreated } = useSelector(
    (state: ApplicationState) => state.enterpriseData
  );

  function setEnterpriseType(enterpriseType: EnterpriseTypeShort | null) {
    rowDispatch({
      type: 'ENTERPRISE_TYPE_CHANGED',
      enterpriseType
    });
  }

  function setValidFrom(validFrom: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'VALID_FROM_CHANGED',
      validFrom
    });
  }

  function setValidTo(validTo: MaterialUiPickersDate | null) {
    rowDispatch({
      type: 'VALID_TO_CHANGED',
      validTo
    });
  }

  function cancelAddingNewRecord() {
    closeCreatingRecord();
  }

  function createNewRecord() {
    rowDispatch({ type: 'SHOW_CREATE_RECORD_CONFIRMATION_OR_ERRORS' });
    if (validateNewEnterpriseRecord(rowState).createRecordConfirmationOn) {
      const message = `Įrašas išsaugotas. ${
        !unconfirmedFilterOn ? 'Įrašas matomas įjungus filtrą "Nepasirašyti"' : ''
      }`;
      enterpriseDataApiFunctions.createEnterpriseRecord(
        {
          enterpriseTypeId: rowState.enterpriseType.value
            ? rowState.enterpriseType.value.enterpriseTypeId
            : null,
          validFrom: rowState.validFrom.value
            ? rowState.validFrom.value.format('L').toString()
            : null,
          validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null
        },
        message
      );
    }
  }

  useEffect(() => {
    if (isEnterpriseCreated) {
      closeCreatingRecord();
    }
  }, [closeCreatingRecord, isEnterpriseCreated]);

  useEffect(() => {
    rowDispatch({
      type: 'NEW_RECORD_STATE_INITIALIZED',
      enterpriseTypes,
      legalRegistrationDate,
      legalDeregistrationDate
    });
  }, [enterpriseTypes, legalRegistrationDate, legalDeregistrationDate]);

  return (
    <RowStyleOnDifferentStatus>
      {columnsDisplayStatus.enterpriseTypeName && (
        <DropdownInputCell
          selectValue={setEnterpriseType}
          dropdownState={rowState.enterpriseType}
          getOptionLabel={(ent) => ent.enterpriseTypeName}
          getOptionSelected={(option, value) => option.enterpriseTypeId === value.enterpriseTypeId}
        />
      )}
      {columnsDisplayStatus.validFrom && (
        <DateInputImprovedCell
          state={rowState.validFrom}
          setDate={setValidFrom}
          TextFieldComponent={TextFieldWithTooltipDateFrom}
          isRequired
        />
      )}
      {columnsDisplayStatus.validTo && (
        <DateInputImprovedCell
          state={rowState.validTo}
          setDate={setValidTo}
          TextFieldComponent={TextFieldWithTooltip}
        />
      )}
      {columnsDisplayStatus.authorName && <TableCell />}
      <TableCell align="right">
        <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
          <SaveAndCancelActions
            handleConfirmationYes={createNewRecord}
            isProcessing={creatingEnterprise}
            handleCancelButtonClick={cancelAddingNewRecord}
          />
        </AllowedTo>
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
