import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';
import { GenericSelectField } from '../../../components/FormikFields/GenericFormikWrappers/GenericSelectField';
import { GenericBooleanField } from '../../../components/FormikFields/GenericFormikWrappers/GenericBooleanField';

import { resetForm, submitForm, State } from './store';
import { defaultValues, OutletTypeInput } from './types';
import { GenericTextField } from '../../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import { GenericMultipleSelectField } from '../../../components/FormikFields/GenericFormikWrappers/GenericMultipleSelectField';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle, gridFormStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<OutletTypeInput>> = (formikProps) => {
  const { outletGroups, enterpriseTypes } = useSelector((state: State) => state.form.options);

  const outletGroupOptions = outletGroups.map(({ outletGroupId, outletGroupName }) => ({
    value: outletGroupId,
    label: outletGroupName
  }));

  const enterpriseTypeOptions = enterpriseTypes.map(({ enterpriseTypeId, enterpriseTypeName }) => ({
    value: enterpriseTypeId,
    label: enterpriseTypeName
  }));

  const {
    values: { outletTypeId, usesLibis },
    setFieldValue
  } = formikProps;

  useEffect(() => {
    if (!usesLibis) setFieldValue('requiresIsbn', false);
  }, [usesLibis, setFieldValue]);

  return (
    <GridForm style={gridFormStyle}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{outletTypeId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="VIP rūšies pavadinimas Lt" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="outletTypeName"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="VIP rūšies pavadinimas En" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="outletTypeNameEn"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="VIP grupė" spacing="1em">
        <GenericSelectField
          label=""
          field="outletGroupId"
          formikProps={formikProps}
          options={outletGroupOptions}
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Ar VIP rūšiai privalomas periodinis tiražo duomenų teikimas"
          field="requiresEditions"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup>
        <GenericBooleanField
          label="Ar VIP rūšiai privalomas duomenų iš LIBIS gavimas"
          field="usesLibis"
          formikProps={formikProps}
        />
      </GridFieldGroup>

      <GridFieldGroup spacing="1em">
        <GenericBooleanField
          label="Ar VIP rūšiai privalomas ISSN/ISBN"
          field="requiresIsbn"
          formikProps={formikProps}
          disabled={!formikProps.values.usesLibis}
        />
      </GridFieldGroup>

      <GridFieldGroup label="VIRS rūšis" spacing="1em">
        <GenericMultipleSelectField
          label=""
          field="enterpriseTypeIds"
          formikProps={formikProps}
          options={enterpriseTypeOptions}
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const OutletTypeForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error } = useSelector((state: State) => state.form);

  const data = useSelector((state: State) => state.data);

  const validationSchema = Yup.object().shape({
    outletTypeId: noUndefinedNumber(),
    outletTypeName: Yup.string()
      .required('Būtina įvesti reikšmę')
      .max(200, 'Negali būti ilgesnis nei 200 simbolių')
      .notOneOf(
        data
          .map(({ outletTypeName }) => outletTypeName)
          .filter((value) => initialValues?.outletTypeName !== value),
        'Privalo būti unikalus'
      ),
    outletTypeNameEn: Yup.string()
      .nullable()
      .required('Būtina įvesti reikšmę')
      .max(200, 'Negali būti ilgesnis nei 200 simbolių')
      .notOneOf(
        data
          .map(({ outletTypeNameEn }) => outletTypeNameEn)
          .filter((value) => initialValues?.outletTypeNameEn !== value),
        'Privalo būti unikalus'
      ),
    outletGroupId: noUndefinedNumber().required('Privaloma pasirinkti'),
    usesLibis: Yup.boolean().required(),
    requiresIsbn: Yup.boolean().required(),
    requiresEditions: Yup.boolean().required(),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data'),
    enterpriseTypeIds: Yup.array<{ value: number; label: string }>().required(
      'Būtina pasirinkti bent vieną'
    )
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="VIP rūšies įvedimas"
    />
  );
};
