import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProvidedDataHistoryTableWithContext } from '../../containers/ProvidedDataTableInstLegal/Context';
import {
  annulProvidedDataInst,
  fetchProvidedDataInst,
  removeProvidedData
} from '../../store/providedData/providedDataActions';
import { ApplicationState } from '../../store';
import { withLoadedUser } from '../../containers/hoc/withLoadedUser';
import {
  fetchAnnulmentTypesRequest,
  fetchFictitiousOutletRequest
} from '../../store/classifiers/classifiersActions';
import { unsignedDataRemoveInst } from '../../store/unsignedDataRemoval/unsignedDataActions';
import { ProvidedDataTableType } from '../../store/providedData/providedDataTypes';

const InstProvidedDataPage: React.FC = () => {
  const reduxDispatch = useDispatch();

  const {
    providedData: { loadingProvidedDataInst, providedDataInst, providedDataInstError },
    classifiers: { annulmentTypes, fictitiousOutlet }
  } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    if (!annulmentTypes) {
      reduxDispatch(fetchAnnulmentTypesRequest());
    }
  }, [annulmentTypes, reduxDispatch]);

  useEffect(() => {
    if (!fictitiousOutlet) {
      reduxDispatch(fetchFictitiousOutletRequest());
    }
  }, [reduxDispatch, fictitiousOutlet]);

  return (
    <>
      <ProvidedDataHistoryTableWithContext
        loading={loadingProvidedDataInst}
        data={providedDataInst}
        error={providedDataInstError}
        remove={(documentId) => {
          reduxDispatch(removeProvidedData(documentId, ProvidedDataTableType.INST));
        }}
        removeUnsigned={() => {
          reduxDispatch(unsignedDataRemoveInst());
        }}
        onRemoveUnsignedSuccess={() => reduxDispatch(fetchProvidedDataInst())}
        annul={(documentId, annulRecord) => {
          reduxDispatch(annulProvidedDataInst(documentId, annulRecord));
        }}
      />
    </>
  );
};

export default withLoadedUser(InstProvidedDataPage);
