import React from 'react';

interface Props {
  iconColor?: string;
}

const DocumentIcon: React.FC<Props> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28.001">
      <g data-name="Group 4088" transform="translate(-1120 -94)">
        <g fill="#9e9e9e">
          <path
            data-name="Path 808"
            d="M1142.606 107.393l-1.125-1.125a1.349 1.349 0 00-1.906 0l-2.369 2.369v-7.615a2.024 2.024 0 00-2.022-2.022h-13.162a2.024 2.024 0 00-2.022 2.022v18.957a2.024 2.024 0 002.022 2.021h13.162a2.024 2.024 0 002.022-2.022v-5.346l5.4-5.333a1.349 1.349 0 000-1.906zm-8.059 7.97l-2.027.946.945-2.025 5.03-5.03 1.112 1.112zm1.311 4.616a.675.675 0 01-.674.674h-13.162a.675.675 0 01-.674-.674v-18.957a.675.675 0 01.674-.674h13.162a.675.675 0 01.674.674v8.964c-3.737 3.737-3.493 3.468-3.564 3.622l-1.779 3.812a.674.674 0 00.9.9l3.812-1.779c.14-.065.163-.106.636-.573zm4.708-10.56l-1.118-1.119 1.08-1.08 1.125 1.126z"
          />
          <path
            data-name="Path 809"
            d="M1123.594 104.706h10.063a.674.674 0 100-1.348h-10.063a.674.674 0 000 1.348z"
          />
          <path
            data-name="Path 810"
            d="M1123.594 108.299h7.906a.674.674 0 100-1.348h-7.906a.674.674 0 100 1.348z"
          />
          <path
            data-name="Path 811"
            d="M1129.344 110.545h-5.75a.674.674 0 100 1.348h5.75a.674.674 0 100-1.348z"
          />
        </g>
        <circle
          data-name="Ellipse 369"
          cx="5"
          cy="5"
          r="5"
          transform="translate(1133 94)"
          fill={iconColor || '#fea900'}
        />
      </g>
    </svg>
  );
};

export default DocumentIcon;
