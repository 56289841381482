export interface MisconductType {
  misconductTypeId: number;
  misconductTypeName: string;
  misconductTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface MisconductTypeInput {
  misconductTypeId: number | null;
  misconductTypeName: string | null;
  misconductTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: MisconductTypeInput = {
  misconductTypeId: null,
  misconductTypeName: '',
  misconductTypeNameEn: '',
  validFrom: null,
  validTo: null
};
