import { axiosDelete, get, getBlob, getWithQuery, post, put } from '../../utils/axiosApi';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { EthicalNonComplianceRecord } from './ethicalNonCompliancesTypes';
import { EthicalNonCompliancePageRequest } from '../ethicalNonComplianceTable/ethicalNonComplienceTableTypes';

export const getEthicalNonComplianceData = () => get('/ethical-non-compliance-data');

export const getEthicalNonComplianceDataByDocId = (docId: number) =>
  get(`/ethical-non-compliance-data/${docId}`);

export const getEthicalNonComplianceDataPage = (query: EthicalNonCompliancePageRequest) =>
  getWithQuery('/ethical-non-compliance-data', query);

export const getAnnulmentTypes = () => get('/classifiers/annulment-types');

export const getEthicalNonComplianceDataVirs = (virsId: number) =>
  get(`/virs/${virsId}/institutions-data/ethical-non-compliances/`);

export const getEthicalNonComplianceDocument = (documentId: number) =>
  getBlob(`/ethical-non-compliance-data/documents/${documentId}`);

export const postCreateEthicalNonCompliance = (
  newEthicalNonCompliance: EthicalNonComplianceRecord
) => post('/ethical-non-compliance-data', newEthicalNonCompliance);

export const putUpdateEthicalNonCompliance = (
  newEthicalNonCompliance: EthicalNonComplianceRecord,
  id: number | undefined
) => put(`/ethical-non-compliance-data/${id}`, newEthicalNonCompliance);

export const deleteRemoveEthicalNonCompliance = (EthicalNonComplianceId: number) =>
  axiosDelete(`/ethical-non-compliance-data/${EthicalNonComplianceId}`);

export const annulEthicalNonComplianceRecord = (
  documentStatusId: number,
  annulRecord: AnnulRecord
) => put(`/provided-data/inst/${documentStatusId}`, annulRecord);
