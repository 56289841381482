import moment from 'moment';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  updateMaxDates,
  updateMinDates,
  validateSingleChoice
} from '../../../../utils/InputValueFunctions';
import {
  EnterpriseNewRecordState,
  initialEnterpriseNewRecordState,
  EnterpriseDataNewRecordAction
} from './rowInitialStateAndTypes';
import { validateNewEnterpriseRecord } from './rowReducerFunctions';

export const newEnterpriseRecordReducer = (
  state: EnterpriseNewRecordState,
  action: EnterpriseDataNewRecordAction
): EnterpriseNewRecordState => {
  switch (action.type) {
    case 'NEW_RECORD_STATE_INITIALIZED':
      return {
        ...state,
        enterpriseType: {
          ...initialEnterpriseNewRecordState.enterpriseType,
          options: action.enterpriseTypes
        },
        validFrom: {
          ...state.validFrom,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.legalRegistrationDate),
              text: 'Data negali būti ankstesnė už įregistravimo datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date:
                action.legalDeregistrationDate !== null
                  ? moment(action.legalDeregistrationDate)
                  : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo datą'
            },
            {
              id: 'validTo',
              date: null,
              text: 'Data negali būti vėlesnė už pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'legalRegistrationDate',
              date: moment(action.legalRegistrationDate),
              text: 'Data negali būti ankstesnė už įregistravimo datą'
            },
            {
              id: 'validFrom',
              date: null,
              text: 'Data negali būti vėlesnė už įrašo galiojimo pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'legalDeregistrationDate',
              date:
                action.legalDeregistrationDate !== null
                  ? moment(action.legalDeregistrationDate)
                  : null,
              text: 'Data negali būti vėlesnė už išsiregistravimo datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'ENTERPRISE_TYPE_CHANGED':
      return {
        ...state,
        enterpriseType: validateSingleChoice(
          { ...state.enterpriseType, value: action.enterpriseType },
          'Reikia pasirinkti iš sąrašo'
        )
      };
    case 'VALID_FROM_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'VALID_TO_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'SHOW_CREATE_RECORD_CONFIRMATION_OR_ERRORS':
      return validateNewEnterpriseRecord(state);
    case 'CLOSE_CREATE_RECORD_CONFIRMATION':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    default:
      return state;
  }
};
