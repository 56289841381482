import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Container, Divider } from '@material-ui/core';
import { ApplicationState } from '../../store';
import PrivateRoute from '../../components/Router/PrivateRoute';
import EnterpriseDataPage from './EnterpriseDataPage';
import ProvidedDataPage from './ProvidedDataPage';
import OutletsDataPage from './OutletsDataPage';
import ExternalSystemDataPage from './ExternalSystemDataPage';
import HomePage from '../HomePage';
import VirsHeader from '../header/VirsHeader';
import InstEditionCheckPage from '../institution/InstEditionCheckPage';
import { InstPaymentPage } from '../institution/InstPaymentPage';
import InstitutionProvidedDataPage from './InstitutionProvidedDataPage';
import VirsPage from './VirsPage';
import AppFooter from '../../components/Footer/AppFooter';
import { VirsData } from '../../components/VirsContextComponents/VirsData';
import { InstitutionData } from '../../components/VirsContextComponents/InstitutionData';
import UserMessagesPage from './UserMessagesPage';
import OutletInfoTablesPage from './OutletInfoTablesPage';
import { HelpPage } from '../../containers/HelpPage/HelpPage';
import { poolingVirsDataEditorInfoRequest } from '../../store/virsis/actions';
import { Roles } from '../../store/virsis/dataTypes';
import { ShareholdersPages } from './ShareholdersPages';
import ShareholdersMainTablePage from './ShareholdersMainTablePage';
import ShareholdersSelectedDatePage from './ShareholdersSelectedDateDataPage';

const VirsRoute: React.FC = () => {
  const { authoriseError, logoutSuccess, currentUser, virsData } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  const reduxDispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (currentUser && virsData) {
      const roles = [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS];
      const startPoolingInfo =
        location.pathname.includes('/duomenu-perziura-ir-teikimas') &&
        roles.find((role: Roles) => currentUser.authorities.includes(Roles[role])) !== undefined;
      reduxDispatch(poolingVirsDataEditorInfoRequest(startPoolingInfo, virsData.virsId));
    }
  }, [location.pathname, reduxDispatch, location, currentUser, virsData]);

  if (authoriseError || logoutSuccess) {
    return <HomePage />;
  }

  return (
    <>
      <VirsHeader />
      <div style={{ flex: '1 0 auto' }}>
        <Switch>
          <PrivateRoute exact path="/duomenu-perziura-ir-teikimas/el-dokumentai">
            <Container maxWidth="lg">
              <VirsData />
              <Divider style={{ marginTop: 40 }} />
            </Container>
            <ProvidedDataPage />
          </PrivateRoute>
          <Route exact path="/km">
            <>
              <Container>
                <Typography variant="h1">Duomenų teikimas (rodoma prisijungus kaip KM)</Typography>
              </Container>
              <InstPaymentPage />
              <InstEditionCheckPage />
            </>
          </Route>
          <PrivateRoute
            exact
            path="/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/:outletId/:tablePath"
          >
            <OutletInfoTablesPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/:outletId"
          >
            <OutletInfoTablesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <VirsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/duomenu-perziura-ir-teikimas">
            <Container maxWidth="lg" style={{ marginBottom: 40 }}>
              <h1 style={{ padding: 25 }}>Duomenų peržiūra ir teikimas</h1>
              <VirsData />
            </Container>
          </PrivateRoute>
          <PrivateRoute exact path="/duomenu-perziura-ir-teikimas/veiklos-rusys">
            <EnterpriseDataPage />
          </PrivateRoute>
          <PrivateRoute exact path="/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones">
            <OutletsDataPage />
          </PrivateRoute>
          <PrivateRoute exact path="/duomenys-is-instituciju">
            <Container maxWidth="lg" style={{ marginBottom: 40 }}>
              <h1>Duomenys iš institucijų</h1>
              <InstitutionData />
            </Container>
          </PrivateRoute>
          <PrivateRoute path="/duomenys-is-instituciju/instituciju-istaigu-duomenys/:displayedTable">
            <InstitutionProvidedDataPage />
          </PrivateRoute>
          <PrivateRoute path="/duomenys-is-instituciju/isoriniu-sistemu-duomenys/:displayedTable">
            <ExternalSystemDataPage />
          </PrivateRoute>
          <PrivateRoute exact path="/pagalba">
            <HelpPage />
          </PrivateRoute>
          <PrivateRoute exact path="/pranesimai">
            <UserMessagesPage />
          </PrivateRoute>
          <ShareholdersPages>
            <PrivateRoute exact path="/duomenu-perziura-ir-teikimas/dalyviai/konkreti-data">
              <ShareholdersSelectedDatePage />
            </PrivateRoute>
            <PrivateRoute exact path="/duomenu-perziura-ir-teikimas/dalyviai">
              <ShareholdersMainTablePage />
            </PrivateRoute>
          </ShareholdersPages>
        </Switch>
      </div>
      <AppFooter />
    </>
  );
};

export default VirsRoute;
