import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { CloseButton } from '../TableButtons/CloseButton';
import { SuccessButton } from '../TableButtons/SuccessButton';

interface Props {
  handleConfirmationYes: () => void;
  isProcessing: boolean;
  handleCancelButtonClick: () => void;
}

export const SaveAndCancelIcons: React.FC<Props> = ({
  handleConfirmationYes,
  isProcessing,
  handleCancelButtonClick
}) => {
  const classes = makeStyles({
    circularProgress: { height: '20px', width: '20px' },
    tools: {
      marginTop: 5,
      alignItems: 'center',
      display: 'flex',
      height: 20
    },
    buttonDivider: {
      borderTop: '1px solid #EEEEEE',
      margin: 0
    }
  })();

  return (
    <>
      {isProcessing ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <div className={classes.tools}>
          <SuccessButton onSuccess={handleConfirmationYes} />
          <CloseButton onClose={handleCancelButtonClick} />
        </div>
      )}
    </>
  );
};
