import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import AppHeader from '../../components/Header/AppHeader';
import { HeaderMenuField, INSTITUTION_HEADER_MENU_FIELDS } from '../pages';
import { ApplicationState } from '../../store';

const getMenuFieldsByRole = (currentRoles: string[], menuFields: HeaderMenuField[]) => {
  let filteredMenuFields = menuFields;

  if (currentRoles.includes('ROLE_VIRS_EDIT')) {
    filteredMenuFields = filteredMenuFields.filter((field) => field.id !== 'virsDataReview');
  }

  if (currentRoles.includes('ROLE_VIRS_VIEW') && !currentRoles.includes('ROLE_VIRS_EDIT')) {
    filteredMenuFields = filteredMenuFields.filter((field) => field.id !== 'virsDataSubmission');
  }

  if (!currentRoles.includes('ROLE_VIRS_VIEW') && !currentRoles.includes('ROLE_VIRS_EDIT')) {
    filteredMenuFields = filteredMenuFields.filter(
      (field) => field.id !== 'virsDataSubmission' && field.id !== 'virsDataReview'
    );
  }

  if (
    !currentRoles.includes('ROLE_REPORT_A1') &&
    !currentRoles.includes('ROLE_REPORT_A2') &&
    !currentRoles.includes('ROLE_REPORT_A3') &&
    !currentRoles.includes('ROLE_REPORT_A4') &&
    !currentRoles.includes('ROLE_REPORT_A5') &&
    !currentRoles.includes('ROLE_REPORT_A6') &&
    !currentRoles.includes('ROLE_REPORT_A7') &&
    !currentRoles.includes('ROLE_REPORT_A8') &&
    !currentRoles.includes('ROLE_REPORT_A9') &&
    !currentRoles.includes('ROLE_REPORT_A10') &&
    !currentRoles.includes('ROLE_REPORT_A11') &&
    !currentRoles.includes('ROLE_REPORT_A12') &&
    !currentRoles.includes('ROLE_REPORT_A13') &&
    !currentRoles.includes('ROLE_REPORT_A15') &&
    !currentRoles.includes('ROLE_REPORT_A13ARCH')
  ) {
    filteredMenuFields = filteredMenuFields.filter((field) => field.id !== 'reports');
  }

  return filteredMenuFields;
};

const InstitutionHeader: React.FC = () => {
  const { currentUser } = useSelector((state: ApplicationState) => state.virsis);

  if (currentUser) {
    return (
      <AppHeader
        currentUser={currentUser}
        headerFields={getMenuFieldsByRole(currentUser.authorities, INSTITUTION_HEADER_MENU_FIELDS)}
        displayEmailEditing
      />
    );
  }
  return null;
};

export default withRouter(InstitutionHeader);
