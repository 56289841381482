import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { History } from 'history';

import { virsisReducer, VirsisState } from './virsis/reducer';
import virsisSaga, { startApplication } from './virsis/virsisSaga';
import { providedDataReducer, ProvidedDataState } from './providedData/providedDataReducer';
import providedDataSaga from './providedData/providedDataSaga';
import { EnterpriseDataState, enterprisesReducer } from './enterprises/enterprisesReducer';

import enterprisesSaga from './enterprises/enterprisesSaga';
import { OutletDataState, outletsReducer } from './outlets/outletsReducer';

import outletsSaga from './outlets/outletsSaga';
import outletInfoDataSaga from './outletInfoData/outletInfoDataSaga';
import { outletInfoDataReducer, OutletInfoDataState } from './outletInfoData/outletInfoDataReducer';
import {
  externalSystemDataReducer,
  ExternalSystemDataReducerState
} from './externalSystemData/externalSystemDataReducer';
import externalSystemDataSaga from './externalSystemData/externalSystemDataSaga';
import personsSaga from './persons/personsSaga';
import { PersonDataState, personsReducer } from './persons/personsReducer';
import { documentReducer, DocumentState } from './document/documentReducer';
import documentSaga from './document/documentSaga';
import { EditionCheckDataState, editionChecksReducer } from './editionChecks/editionChecksReducer';
import editionChecksSaga from './editionChecks/editionChecksSaga';
import { PaymentDataState, paymentsReducer } from './payments/paymentsReducer';
import paymentsSaga from './payments/paymentsSaga';
import { classifiersReducer, ClassifiersState } from './classifiers/classifiersReducer';
import classifiersSaga from './classifiers/classifiersSaga';
import {
  EthicalNonComplianceDataState,
  ethicalNonCompliancesReducer
} from './ethicalNonCompliances/ethicalNonCompliancesReducer';
import ethicalNonCompliancesSaga from './ethicalNonCompliances/ethicalNonCompliancesSaga';
import { FundsReceivedDataState, fundsReceivedReducer } from './fundsReceived/fundsReceivedReducer';
import fundsReceivedSaga from './fundsReceived/fundsReceivedSaga';
import { legalDataReducer, LegalDataState } from './legalPersonData/legalDataReducer';
import legalDataSaga from './legalPersonData/legalDataSaga';
import {
  ProfessionalMisconductDataState,
  professionalMisconductsReducer
} from './professionalMisconducts/professionalMisconductsReducer';
import professionalMisconductsSaga from './professionalMisconducts/professionalMisconductsSaga';
import { shareholdersDataReducer, ShareholdersDataState } from './shareholders/shareholdersReducer';
import shareholdersDataSaga from './shareholders/shareholdersSaga';
import { userMessagesReducer, UserMessagesState } from './userMessages/userMessagesReducer';
import userMessagesSaga from './userMessages/userMessagesSaga';
import { parametersReducer, VirsParametersState } from './parameters/parametersReducer';
import parametersSaga from './parameters/parametersSaga';
import {
  institutionDataReportDataReducer,
  InstitutionDataReportDataState
} from './InstitutionDataReports/institutionDataReportsReducer';
import institutionDataReportsSaga from './InstitutionDataReports/institutionDataReportsSaga';
import {
  ErrorOrSuccessMessageState,
  errorOrSuccessMessageReducer
} from './errorOrSuccessMessage/errorOrSuccessMessageReducer';
import {
  GraphicalRepresentationState,
  graphicalRepresentationToolsReducer
} from './graphicalRepresentationTools/graphicalRepresentationToolsReducer';
import graphicalRepresentationToolsSaga from './graphicalRepresentationTools/graphicalRepresentationToolsSaga';
import { AuditsDataState, auditsReducer } from './audits/auditsReducer';
import AuditsDataSaga from './audits/auditsDataSaga';
import { helpPageReducer, HelpPageReducerState } from './helpPage/helpPageReducer';
import helpPageSaga from './helpPage/helpPageSaga';
import { reportReducer } from '../containers/reports/store';
import { reportSaga } from '../containers/reports/saga';
import { ReportFormState } from '../containers/reports/types';
import {
  EditionCheckDataTableState,
  editionChecksDataTableReducer
} from './editionChecksTable/editionChecksTableReducer';
import {
  ProfessionalMisconductDataTableState,
  professionalMisconductsTableDataReducer
} from './professionalMisconductTable/professionalMisconductsTableReducer';
import {
  EthicalNonComplianceDataTableState,
  ethicalNonComplianceTableDataReducer
} from './ethicalNonComplianceTable/ethicalNonComplianceTableReducer';
import {
  FundsReceivedDataTableState,
  fundsReceivedTableDataReducer
} from './fundsReceivedTable/fundsReceivedTableReducer';
import {
  providedDataTableReducer,
  ProvidedDataTableState
} from './providedDataTable/providedDataTableReducer';
import { unsignedDataReducer, UnsignedDataState } from './unsignedDataRemoval/unsignedDataReducer';
import { unsignedDataSaga } from './unsignedDataRemoval/unsignedDataSaga';
import {
  PaymentsDataTableState,
  paymentsTableDataReducer
} from './paymentsTable/paymentsTableReducer';
import { virsTreeReducer, VirsTreeState } from './shareholdersVirsTree/virsTreeReducer';
import virsTreeSaga from './shareholdersVirsTree/virsTreeSaga';
import { LoaderState } from '../utils/loaderStore';
import { JADISSyncReducer, JADISSyncSaga } from './jadisSync/store';
import {
  VirsProvidedDataStatus,
  virsProvidedDataStatusReducer,
  virsProvidedDataStatusSaga
} from './virsProvidedDataStatus/store';
import {
  findNaturalPersonInVirsisReducer,
  findNaturalPersonInVirsisState
} from './findnaturalpersoninvirsis/reducer';
import findNaturalPersonInVirsisSaga from './findnaturalpersoninvirsis/findNaturalPersonSaga';
import { UnisignDataState } from './unisign/unisignTypes';
import { unisignReducer } from './unisign/unisignReducer';
import unisignSaga from './unisign/unisignSaga';

export interface ApplicationState {
  virsis: VirsisState;
  providedData: ProvidedDataState;
  providedDataTable: ProvidedDataTableState;
  classifiers: ClassifiersState;
  enterpriseData: EnterpriseDataState;
  editionData: EditionCheckDataState;
  editionDataTable: EditionCheckDataTableState;
  outletData: OutletDataState;
  outletInfoData: OutletInfoDataState;
  externalSystemData: ExternalSystemDataReducerState;
  personData: PersonDataState;
  legalData: LegalDataState;
  documentData: DocumentState;
  paymentData: PaymentDataState;
  paymentTableData: PaymentsDataTableState;
  professionalMisconductData: ProfessionalMisconductDataState;
  professionalMisconductTableData: ProfessionalMisconductDataTableState;
  ethicalNonComplianceData: EthicalNonComplianceDataState;
  ethicalNonComplianceTableData: EthicalNonComplianceDataTableState;
  shareholdersData: ShareholdersDataState;
  fundsReceivedData: FundsReceivedDataState;
  fundsReceivedTableData: FundsReceivedDataTableState;
  userMessages: UserMessagesState;
  virsisParameters: VirsParametersState;
  virsTreeData: VirsTreeState;
  institutionDataReportData: InstitutionDataReportDataState;
  errorOrSuccessMessage: ErrorOrSuccessMessageState;
  graphicalRepresentation: GraphicalRepresentationState;
  report: ReportFormState;
  auditsData: AuditsDataState;
  helpPageData: HelpPageReducerState;
  unsignData: UnisignDataState;
  unsignedData: UnsignedDataState;
  JADISSync: LoaderState<undefined>;
  virsProvidedDataStatus: LoaderState<VirsProvidedDataStatus>;
  findNaturalPersonInVirsis: findNaturalPersonInVirsisState;
}

export const createRootReducer = (history: History) =>
  combineReducers({
    virsis: virsisReducer,
    classifiers: classifiersReducer,
    providedData: providedDataReducer,
    providedDataTable: providedDataTableReducer,
    enterpriseData: enterprisesReducer,
    editionData: editionChecksReducer,
    editionDataTable: editionChecksDataTableReducer,
    outletData: outletsReducer,
    outletInfoData: outletInfoDataReducer,
    externalSystemData: externalSystemDataReducer,
    personData: personsReducer,
    legalData: legalDataReducer,
    documentData: documentReducer,
    paymentData: paymentsReducer,
    paymentTableData: paymentsTableDataReducer,
    professionalMisconductData: professionalMisconductsReducer,
    professionalMisconductTableData: professionalMisconductsTableDataReducer,
    ethicalNonComplianceData: ethicalNonCompliancesReducer,
    ethicalNonComplianceTableData: ethicalNonComplianceTableDataReducer,
    shareholdersData: shareholdersDataReducer,
    fundsReceivedData: fundsReceivedReducer,
    fundsReceivedTableData: fundsReceivedTableDataReducer,
    userMessages: userMessagesReducer,
    virsisParameters: parametersReducer,
    virsTreeData: virsTreeReducer,
    institutionDataReportData: institutionDataReportDataReducer,
    errorOrSuccessMessage: errorOrSuccessMessageReducer,
    graphicalRepresentation: graphicalRepresentationToolsReducer,
    report: reportReducer,
    auditsData: auditsReducer,
    helpPageData: helpPageReducer,
    unsignData: unisignReducer,
    unsignedData: unsignedDataReducer,
    JADISSync: JADISSyncReducer,
    virsProvidedDataStatus: virsProvidedDataStatusReducer,
    findNaturalPersonInVirsis: findNaturalPersonInVirsisReducer
  });

export function* rootSaga() {
  yield all([
    fork(startApplication),
    fork(virsisSaga),
    fork(classifiersSaga),
    fork(providedDataSaga),
    fork(enterprisesSaga),
    fork(editionChecksSaga),
    fork(outletsSaga),
    fork(outletInfoDataSaga),
    fork(externalSystemDataSaga),
    fork(personsSaga),
    fork(legalDataSaga),
    fork(documentSaga),
    fork(paymentsSaga),
    fork(professionalMisconductsSaga),
    fork(ethicalNonCompliancesSaga),
    fork(shareholdersDataSaga),
    fork(virsTreeSaga),
    fork(fundsReceivedSaga),
    fork(userMessagesSaga),
    fork(parametersSaga),
    fork(institutionDataReportsSaga),
    fork(graphicalRepresentationToolsSaga),
    fork(reportSaga),
    fork(AuditsDataSaga),
    fork(helpPageSaga),
    fork(unisignSaga),
    fork(unsignedDataSaga),
    fork(JADISSyncSaga),
    fork(virsProvidedDataStatusSaga),
    fork(findNaturalPersonInVirsisSaga)
  ]);
}
