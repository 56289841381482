import React, { MouseEvent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { TooltipOnTableActionButton } from '../Tooltips/TooltipOnTableActionButton';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customButton: {
      alignItems: 'center',
      borderColor: theme.palette.info.light,
      color: '#637082',
      fontWeight: 400,
      minWidth: 120,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        background: '#fff'
      },
      '&:disabled': {
        borderColor: theme.palette.info.light,
        color: theme.palette.info.main,
        background: '#fff',
        '& $icon': {
          stroke: theme.palette.info.main
        }
      }
    },
    icon: {
      marginRight: 12,
      stroke: theme.palette.success.main
    }
  })
);

export interface Props {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
}

export const SuccessIconButtonWithTooltip: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  showTooltip,
  tooltipText
}) => {
  const classes = useStyles();

  return (
    <ConditionalWrapper
      condition={!!showTooltip}
      wrapper={(tooltipChildren) => (
        <TooltipOnTableActionButton text={tooltipText || ''}>
          <span>{tooltipChildren}</span>
        </TooltipOnTableActionButton>
      )}
    >
      <Button
        variant="outlined"
        className={classes.customButton}
        onClick={onClick}
        disabled={disabled}
      >
        <svg width="13.012" height="9.117" viewBox="0 0 13.012 9.117" className={classes.icon}>
          <path
            id="Path_1232"
            data-name="Path 1232"
            d="M-8873.219-20085.6l3.168,3.4,5.6-5.5"
            transform="translate(8875.339 20089.816)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </svg>
        {children}
      </Button>
    </ConditionalWrapper>
  );
};
