function downloadPdf(documentPath: string) {
  setTimeout(() => {
    const devEnvironment = false;
    //const devEnvironment = !!process.env.REACT_APP_DEV;
    const hostname = window && window.location && window.location.hostname;
    const filePath = devEnvironment ? `http://${hostname}:8080` : '';

    const response = {
      file: `${filePath}/virsis-api/${documentPath}`
    };

    window.location.href = response.file;
  }, 100);
}

export default downloadPdf;
