export const ExpandTreeIcon = () => {
  return (
    <svg width="18.477" height="18.729">
      <path
        fill="#006fb2"
        d="M6.598 3.542h1.52v3.662a.373.373 0 00.374.373h1.493a.374.374 0 00.374-.373V3.543h1.52a.374.374 0 00.263-.637L9.502.266a.373.373 0 00-.527 0l-2.64 2.64a.374.374 0 00.263.636zM14.935 6.598v1.52h-3.662a.373.373 0 00-.373.374v1.493a.374.374 0 00.373.374h3.661v1.52a.374.374 0 00.637.263l2.64-2.64a.373.373 0 000-.527l-2.64-2.64a.374.374 0 00-.636.263zM3.541 11.879v-1.52h3.662a.373.373 0 00.373-.374V8.492a.374.374 0 00-.373-.374H3.542v-1.52a.374.374 0 00-.637-.263l-2.64 2.64a.373.373 0 000 .527l2.64 2.64a.374.374 0 00.636-.263zM8.118 11.528l-.003 3.657-1.517.004a.374.374 0 00-.264.637l2.64 2.64a.373.373 0 00.527 0l2.64-2.64a.373.373 0 00-.264-.637l-1.516-.004-.003-3.657a.373.373 0 00-.374-.373H8.491a.373.373 0 00-.373.373z"
      />
    </svg>
  );
};
