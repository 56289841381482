import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      textAlign: 'end'
    },
    button: {
      padding: 8,
      '&:hover': {
        color: theme.palette.primary.main
      },
      '&:active': {
        color: theme.palette.primary.main
      }
    },
    icon: {
      color: theme.palette.info.main
    }
  })
);
interface Props {
  onClick: () => void;
}

export const CustomEditIconButton: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <IconButton className={classes.button} onClick={onClick} aria-label="Įvesti pabaigos datą">
        <EditIcon fontSize="small" />
      </IconButton>
    </div>
  );
};
