import React, { useRef, useEffect } from 'react';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';

import {
  useShareholdersSelectedDateTableDispatch,
  useShareholdersSelectedDateTableState
} from '../TableWithContext';
import { CancelIconButton } from '../../../../components/TableButtons/CancelIconButton';
import { AddPersonButton } from './EditShareholderRow/components/ShareholderDetails/components/AddPersonIconButton';
import { getMarginLeft } from '../../utilityFunctions/sharedUIFunctions';
import { Strings } from '../../../../utils/strings/Strings';

interface Props {
  shareholderLevel: number;
  index: number;
}

export const SelectShareholderRow: React.FC<Props> = ({ shareholderLevel, index }) => {
  const classes = makeStyles({
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    addButtonContainer: {
      marginLeft: getMarginLeft(shareholderLevel) + 33
    }
  })();

  const {
    state: { shouldScroll }
  } = useShareholdersSelectedDateTableState();

  const { dispatch: tableDispatch } = useShareholdersSelectedDateTableDispatch();

  const resultsRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (shouldScroll && resultsRef.current !== null) {
      window.scrollTo({
        behavior: 'smooth',
        top: resultsRef.current.offsetTop + resultsRef.current.offsetHeight
      });
    }
    tableDispatch({
      type: 'SET_SHOULD_SCROLL_TO_SHAREHOLDER',
      shouldScroll: false
    });
  }, [shouldScroll, tableDispatch]);

  const handleSelectRowClose = () => {
    tableDispatch({
      type: 'SHOW_SELECT_SHAREHOLDER_ROW',
      showSelectShareholderRow: false
    });
  };

  const openFindDialog = () => {
    tableDispatch({
      type: 'IS_FIND_PERSON_DIALOG_OPEN',
      isPersonSearchDialogOpen: true,
      index: index
    });
  };

  const handleAddPerson = () => {
    tableDispatch({
      type: 'IS_SELECTED_SHAREHOLDER_GROUP',
      isGroup: false
    });
    openFindDialog();
  };

  const handleAddPersonsGroup = () => {
    tableDispatch({
      type: 'IS_SELECTED_SHAREHOLDER_GROUP',
      isGroup: true
    });
    openFindDialog();
  };

  return (
    <TableRow ref={resultsRef}>
      <TableCell>
        <Typography variant="h5" style={{ color: '#172D46' }}>
          {shareholderLevel}
        </Typography>
      </TableCell>
      <TableCell>
        <div className={classes.addButtonContainer}>
          <AddPersonButton
            onAddPersonClick={handleAddPerson}
            text="Pridėti juridinį arba fizinį asmenį"
          />
          <AddPersonButton
            onAddPersonClick={handleAddPersonsGroup}
            text="Pridėti juridinių arba fizinių asmenų grupę"
          />
        </div>
      </TableCell>
      <TableCell colSpan={3}>
        <div className={classes.closeButtonContainer}>
          <CancelIconButton onClose={handleSelectRowClose}>
            {Strings.button__cancel}
          </CancelIconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};
