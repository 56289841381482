import React, { ReactNode } from 'react';
import { TableCell } from '@material-ui/core/';
import { AdColumnsDisplayStatus } from '../../tablesState/adPrintingTableTypes';
import { RowStyleOnDifferentStatus } from '../../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { TooltipOnRowRibbon } from '../../../../components/Tooltips/TooltipOnRowRibbon';

interface Props {
  id?: string;
  columnsToDisplay: AdColumnsDisplayStatus;
  validFrom?: ReactNode;
  validFromInput?: ReactNode;
  validTo?: ReactNode;
  validToTableCell?: ReactNode;
  validToInput?: ReactNode;
  actions?: ReactNode;
  fullyEditableStyle?: boolean;
  outdatedStyle?: boolean;
  newRecordStyle?: boolean;
  rowStatus?: string;
}

export const AdBasicRow: React.FC<Props> = ({
  id,
  columnsToDisplay: display,
  validFrom,
  validFromInput,
  validTo,
  validToInput,
  actions,
  rowStatus,
  validToTableCell
}) => {
  return (
    <RowStyleOnDifferentStatus id={id} status={rowStatus}>
      {display.validFrom && !validFromInput && (
        <TooltipOnRowRibbon status={rowStatus}>
          <TableCell>{validFrom} </TableCell>
        </TooltipOnRowRibbon>
      )}
      {display.validFrom && validFromInput && validFromInput}
      {display.validTo && !validToInput && (validToTableCell || <TableCell>{validTo}</TableCell>)}
      {display.validTo && validToInput && validToInput}
      <TableCell align="right" scope="row">
        {actions}
      </TableCell>
    </RowStyleOnDifferentStatus>
  );
};
