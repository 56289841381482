export interface LicencedEnterpriseInput {
  licencedEnterpriseId: number | null;
  enterpriseTypeId: number | null;
  licenceTypeId: number | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: LicencedEnterpriseInput = {
  licencedEnterpriseId: null,
  enterpriseTypeId: null,
  licenceTypeId: null,
  validFrom: null,
  validTo: null
};

export interface LicencedEnterprise {
  licencedEnterpriseId: number;
  enterpriseTypeId: number;
  enterpriseTypeName: string;
  licenceTypeId: number;
  licenceTypeName: string;
  validFrom: string;
  validTo: string;
}

export interface LicencedEnterpriseOptions {
  licenceTypes: { licenceTypeId: number; licenceTypeName: string }[];
  enterpriseTypes: { enterpriseTypeId: number; enterpriseTypeName: string }[];
}
