import { DataTableState } from './tableTypes';

export const initialState: DataTableState = {
  data: [],
  order: 'asc',
  sortBy: 'supportProviderName',
  pageData: [],
  page: 0,
  recordsPassed: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    supportProviderName: true,
    financialSupportAmount: true,
    supportReceivedYear: true,
    dataCreatedDate: true,
    dataProvidedDate: true
  },
  customFilterOn: false,
  customFilter: {
    supportProviderName: [],
    financialSupportAmount: [],
    supportReceivedYear: [],
    dataCreatedDate: [],
    dataProvidedDate: []
  }
};
