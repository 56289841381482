import { PoliticalAdData } from '../../../../store/externalSystemData/externalSystemDataTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../../utils/exporters/types';
import {
  getComparator,
  replaceDecimalPoint,
  stableSort
} from '../../../../utils/tableDataFunctions';
import { columnNames } from './tableColumns';
import { Order, ColumnHeaderField, TableCustomFilter, DataTableState } from './tableTypes';

export function sortByColumn(
  actionSortBy: ColumnHeaderField,
  stateSortBy: ColumnHeaderField,
  stateOrder: Order
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export function updateCustomFilter(
  filter: TableCustomFilter,
  filterBy: ColumnHeaderField,
  value: string | null
): TableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function getCustomFilteredData(
  data: PoliticalAdData[],
  filter: TableCustomFilter
): PoliticalAdData[] {
  if (data.length === 0) {
    return [];
  }
  function createCustomFilter(field: ColumnHeaderField) {
    return (record: PoliticalAdData): boolean => {
      const [valueToFilterBy] = filter[field];
      if (valueToFilterBy) {
        const recordField = record[field];
        if (field === 'politicalAdDateFrom') {
          return recordField?.toString().toLowerCase() >= valueToFilterBy?.toString().toLowerCase();
        }
        if (field === 'politicalAdDateTo') {
          return recordField?.toString().toLowerCase() <= valueToFilterBy?.toString().toLowerCase();
        }
        if (recordField) {
          return replaceDecimalPoint(recordField?.toString())
            .toLowerCase()
            .includes(replaceDecimalPoint(valueToFilterBy?.toString()).toLowerCase());
        }
        return false;
      }
      return true;
    };
  }

  const allTableFilters = [
    createCustomFilter('distributorName'),
    createCustomFilter('distributorCode'),
    createCustomFilter('interestedPartyName'),
    createCustomFilter('campaignName'),
    createCustomFilter('politicalAdDateFrom'),
    createCustomFilter('politicalAdDateTo'),
    createCustomFilter('financialSupportAmount')
  ];

  const filteredData = data.filter((record) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });

  return filteredData;
}

function getSortedData(
  data: PoliticalAdData[],
  order: Order,
  sortBy: ColumnHeaderField
): PoliticalAdData[] {
  if (data) {
    return stableSort(data, getComparator(order, sortBy));
  }
  return [];
}

export function getFilteredAndSortedData(
  isFilterOn: boolean,
  data: PoliticalAdData[],
  filter: TableCustomFilter,
  order: Order,
  sortBy: ColumnHeaderField
): PoliticalAdData[] {
  const dataPassedCustomFilter = isFilterOn ? getCustomFilteredData(data, filter) : data;

  return getSortedData(dataPassedCustomFilter, order, sortBy);
}

function getDataWithPagination(
  data: PoliticalAdData[],
  page: number,
  rowsPerPage: number
): PoliticalAdData[] {
  return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export function getUpdatedPageData(
  page: number,
  rowsPerPage: number,
  isCustomFilterOn: boolean,
  data: PoliticalAdData[],
  filter: TableCustomFilter,
  order: Order,
  sortBy: ColumnHeaderField
): PoliticalAdData[] {
  const customFilteredAndSortedData = getFilteredAndSortedData(
    isCustomFilterOn,
    data,
    filter,
    order,
    sortBy
  );
  return getDataWithPagination(customFilteredAndSortedData, page, rowsPerPage);
}

export function getPagesCount(numOfRecords: number, rowsPerPage: number) {
  return Math.ceil(numOfRecords / rowsPerPage);
}

export function getTableExportDefinition(
  tableState: DataTableState
): TableExportDefinition<PoliticalAdData> {
  const { stringValueColumn } = getColumnBuilder<PoliticalAdData, ColumnHeaderField>(
    columnNames,
    tableState.columnsDisplayStatus
  );
  return {
    title: 'Pajamos iš politinės reklamos',
    columnGroups: [
      {
        columns: [
          stringValueColumn('distributorName', { width: 45 }),
          stringValueColumn('distributorCode', { width: 25 }),
          stringValueColumn('interestedPartyName', { width: 45 }),
          stringValueColumn('campaignName', { width: 45 })
        ]
      },
      {
        header: 'Galioja',
        columns: [
          stringValueColumn('politicalAdDateFrom', { width: 15 }),
          stringValueColumn('politicalAdDateTo', { width: 15 })
        ]
      },
      { columns: [stringValueColumn('financialSupportAmount', { width: 15 })] }
    ]
  };
}
