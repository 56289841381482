import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { CancelIconButton } from '../../../../../../../../components/TableButtons/CancelIconButton';
import { SuccessIconButtonWithTooltip } from '../../../../../../../../components/TableButtons/SuccessIconButtonWithTooltip';
import { ApplicationState } from '../../../../../../../../store';
import {
  ShareholderPerson,
  ShareholdersData
} from '../../../../../../../../store/shareholders/shareholdersTypes';
import { useShareholdersSelectedDateTableState } from '../../../../../TableWithContext';
import { Strings } from '../../../../../../../../utils/strings/Strings';

export interface Props {
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  parentShareholder: ShareholderPerson | ShareholdersData;
}

export const FormActions: React.FC<Props> = ({ onSubmit, onClose, parentShareholder }) => {
  const {
    virsis: { virsData }
  } = useSelector((state: ApplicationState) => state);
  const {
    state: { isGroup, authorizedPersonId, shareholderPersonsList }
  } = useShareholdersSelectedDateTableState();

  const parentId = 'personId' in parentShareholder ? parentShareholder.personId : virsData?.virsId;

  const tooltipProps = {
    showTooltip: false,
    tooltipText: ''
  };

  function getTooltipProps() {
    if (shareholderPersonsList.length === 1) {
      const [shareholderPerson] = shareholderPersonsList;

      if (shareholderPerson.personId === parentId) {
        tooltipProps.showTooltip = true;
        tooltipProps.tooltipText = 'Pridėti negalima';
      }
    }

    if (isGroup) {
      if (!authorizedPersonId) {
        tooltipProps.showTooltip = true;
        tooltipProps.tooltipText = 'Prašome pasirinkti įgaliotą asmenį';
      }
      if (shareholderPersonsList.length <= 1) {
        tooltipProps.showTooltip = true;
        tooltipProps.tooltipText = 'Prašome pasirinkti mažiausiai du asmenis';
      }
    }

    return tooltipProps;
  }

  const { showTooltip, tooltipText } = getTooltipProps();

  return (
    <div>
      <SuccessIconButtonWithTooltip
        onClick={onSubmit}
        disabled={showTooltip}
        showTooltip={showTooltip}
        tooltipText={tooltipText}
      >
        Išsaugoti
      </SuccessIconButtonWithTooltip>
      <CancelIconButton onClose={onClose}>{Strings.button__cancel}</CancelIconButton>
    </div>
  );
};
