import {
  ProfessionalMisconductColumnHeader,
  ProfessionalMisconductInstColumnsDisplayStatus,
  ProfessionalMisconductInstCustomFilter,
  ProfessionalMisconductInstTableField
} from './misconductTableInitialStateAndTypes';
import {
  ProfessionalMisconductDataInst,
  ProfessionalMisconductOutlet
} from '../../../store/professionalMisconducts/professionalMisconductsTypes';
import { getColumnBuilder, TableExportDefinition } from '../../../utils/exporters/types';

export function updateMisconductInstDataCustomFilter(
  filter: ProfessionalMisconductInstCustomFilter,
  filterBy: ProfessionalMisconductInstTableField,
  value: string | null
): ProfessionalMisconductInstCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function getTableExportDefinition(
  columnHeaders: ProfessionalMisconductColumnHeader[],
  displayStatus: ProfessionalMisconductInstColumnsDisplayStatus
): TableExportDefinition<ProfessionalMisconductDataInst> {
  const { mappedValueColumn } = getColumnBuilder<
    ProfessionalMisconductDataInst,
    ProfessionalMisconductInstTableField
  >(columnHeaders, displayStatus);

  const getRowGroupHeight = (outlets: ProfessionalMisconductOutlet[]) =>
    outlets
      .flatMap(({ determinedMisconducts }) => determinedMisconducts)
      .flatMap(({ sanctions }) => sanctions).length || 1;

  return {
    title: 'Rimtų profesinių pažeidimų duomenys',
    columnGroups: [
      {
        columns: [
          mappedValueColumn(
            'virsName',
            ({ virsName, virsLegalCode, professionalMisconductOutlets }) => [
              {
                values: [virsName, `${virsLegalCode}`],
                style: {
                  alignment: { horizontal: 'left' },
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 45 }
          )
        ]
      },
      {
        header: 'Sprendimo',
        columns: [
          mappedValueColumn(
            'decisionDate',
            ({ decisionDate, professionalMisconductOutlets }) => [
              {
                values: [decisionDate],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          ),
          mappedValueColumn(
            'documentNumber',
            ({ documentNumber, professionalMisconductOutlets }) => [
              {
                values: [documentNumber],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          )
        ]
      },
      {
        columns: [
          mappedValueColumn(
            'outletName',
            ({ professionalMisconductOutlets }) =>
              professionalMisconductOutlets.map(({ outletName, determinedMisconducts }) => ({
                values: [outletName],
                style: {
                  rowSpan: determinedMisconducts.flatMap(({ sanctions }) => sanctions).length || 1
                }
              })),
            { width: 45 }
          ),
          mappedValueColumn(
            'professionalMisconduct',
            ({ professionalMisconductOutlets }) =>
              professionalMisconductOutlets
                .flatMap(({ determinedMisconducts }) => determinedMisconducts)
                .map(({ misconductTypeName, sanctions }) => ({
                  values: [misconductTypeName],
                  style: { rowSpan: sanctions.length || 1 }
                })),
            { width: 45 }
          ),
          mappedValueColumn(
            'sanction',
            ({ professionalMisconductOutlets }) =>
              professionalMisconductOutlets
                .flatMap(({ determinedMisconducts }) => determinedMisconducts)
                .flatMap(({ sanctions }) => sanctions)
                .map(({ sanctionTypeName }) => ({
                  values: [sanctionTypeName]
                })),
            { width: 25 }
          )
        ]
      },
      {
        header: 'Pažeidimas galioja',
        columns: [
          mappedValueColumn(
            'validFrom',
            ({ validFrom, professionalMisconductOutlets }) => [
              {
                values: [validFrom],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          ),
          mappedValueColumn(
            'validTo',
            ({ validTo, professionalMisconductOutlets }) => [
              {
                values: [validTo],
                style: {
                  rowSpan: getRowGroupHeight(professionalMisconductOutlets)
                }
              }
            ],
            { width: 15 }
          )
        ]
      }
    ]
  };
}
