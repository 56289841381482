import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import BtnOpenNewDoc from '../../../../components/TableButtons/BtnOpenNewDoc';
import ExtendedRowDetailsBlock from '../../../../components/ShareholderComponents/ExtendedRowDetailsBlock';
import { ShareholdersProps } from '../../../../store/shareholders/shareholdersTypes';
import { currentDate, getPercentageValue } from '../../utilityFunctions/sharedFunctions';
import { getShareholderDocumentRequest } from '../../../../store/shareholders/shareholdersActions';
import { getLastArrItem } from '../../SelectedDateTable/utilities/utilityFunctions';
import { getEndDateLabel } from '../../../../store/shareholders/shareholdersUtils';
import { hasRoles } from '../../../../utils/roleHelper';
import { Roles, VirsisUser } from '../../../../store/virsis/dataTypes';

interface Props {
  datesColCount: number;
  shareholder: ShareholdersProps;
  parentShareTypeName: string | null;
  parentMayHaveSharePercentage: boolean;
  parentMayHaveVotePercentage: boolean;
  currentUser?: VirsisUser;
}

const ExtendedDetailsRow: React.FC<Props> = ({
  datesColCount,
  shareholder,
  parentShareTypeName,
  parentMayHaveSharePercentage,
  parentMayHaveVotePercentage,
  currentUser
}) => {
  const reduxDispatch = useDispatch();

  const {
    shareholderStoppedParticipatingFromDate,
    isShareholderFromJADIS,
    closedWithDocument,
    createdWithDocument,
    shareholderHistory,
    JADISStartDate,
    endDateType,
    JADISUpdateDate
  } = shareholder;

  const lastHistoryEvent = getLastArrItem(shareholderHistory);

  const {
    sharePercentage,
    votePercentage,
    votesOnRules,
    documentDate,
    documentId,
    documentStatus
  } = lastHistoryEvent;

  function openDocumentInNewTab(docId: number) {
    return reduxDispatch(getShareholderDocumentRequest(docId));
  }

  return (
    <TableRow>
      <TableCell colSpan={2} className="sticky-col extended-cell">
        {/* AR YRA ŠIAI DIENAI DALIES PASIKEITIMAS */}
        {isShareholderFromJADIS &&
          lastHistoryEvent.eventDate === currentDate &&
          JADISUpdateDate !== null &&
          JADISUpdateDate && (
            <ExtendedRowDetailsBlock
              title="Dalies pasikeitimas"
              label="Gauta iš JADIS:"
              date={JADISUpdateDate || ''}
            />
          )}

        {/* PATEIKTI ŠIOS DIENOS % IR DOKUMENTAS */}
        {(shareholderStoppedParticipatingFromDate !== currentDate ||
          !shareholderStoppedParticipatingFromDate) &&
          documentId &&
          documentStatus === 'PASIRASYTAS' && (
            <ExtendedRowDetailsBlock
              title={getPercentageValue(
                votesOnRules,
                sharePercentage,
                votePercentage,
                parentMayHaveSharePercentage,
                parentMayHaveVotePercentage,
                parentShareTypeName
              )}
              label="Pateikta"
              date={documentDate}
              linkToDocument={
                currentUser &&
                hasRoles(currentUser, [
                  Roles.ROLE_VIRS_EDIT,
                  Roles.ROLE_VIRS,
                  Roles.ROLE_ADMIN_ALL
                ]) && (
                  <BtnOpenNewDoc
                    onClicked={() => openDocumentInNewTab(documentId)}
                    text="peržiūrėti el. dokumentą"
                  />
                )
              }
            />
          )}
        {/*  DALYVAVIMO PRADŽIA */}
        {isShareholderFromJADIS && (
          <ExtendedRowDetailsBlock
            title="Dalyvavimo pradžia"
            label="Gauta iš JADIS:"
            date={JADISStartDate}
          />
        )}

        {!isShareholderFromJADIS &&
          createdWithDocument?.documentStatus === 'PASIRASYTAS' &&
          currentUser &&
          hasRoles(currentUser, [Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS, Roles.ROLE_ADMIN_ALL]) && (
            <ExtendedRowDetailsBlock
              title="Dalyvavimo pradžia"
              label="Pateikta"
              date={createdWithDocument.documentDate}
              linkToDocument={
                <BtnOpenNewDoc
                  onClicked={() => openDocumentInNewTab(createdWithDocument.documentId)}
                  text="peržiūrėti el. dokumentą"
                />
              }
            />
          )}

        {/* DALYVAVIMO PABAIGA */}
        {shareholderStoppedParticipatingFromDate && (
          <ExtendedRowDetailsBlock
            title="Dalyvavimo pabaiga"
            label={getEndDateLabel(endDateType)}
            date={shareholderStoppedParticipatingFromDate}
          />
        )}

        {!isShareholderFromJADIS && closedWithDocument?.documentStatus === 'PASIRASYTAS' && (
          <ExtendedRowDetailsBlock
            title="Dalyvavimo pabaiga"
            label="Pateikta"
            date={closedWithDocument.documentDate}
            linkToDocument={
              currentUser &&
              hasRoles(currentUser, [
                Roles.ROLE_VIRS_EDIT,
                Roles.ROLE_VIRS,
                Roles.ROLE_ADMIN_ALL
              ]) && (
                <BtnOpenNewDoc
                  onClicked={() => openDocumentInNewTab(closedWithDocument.documentId)}
                  text="peržiūrėti el. dokumentą"
                />
              )
            }
          />
        )}
      </TableCell>
      <TableCell colSpan={datesColCount} style={{ borderLeft: 'none' }} />
    </TableRow>
  );
};

export default ExtendedDetailsRow;
