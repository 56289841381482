import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { DocumentActionButton } from './DocumentActionButton';
import { TooltipButtonProps } from '../../Tooltips/TooltipButton';
import { ErrorType } from '../../../store/virsis/dataTypes';

interface Props {
  onClick?: () => void;
  noTooltip?: boolean;
  disabled?: boolean;
  tooltipProps?: TooltipButtonProps;
}

export const RemoveDocumentButton: React.FC<Props> = ({ onClick, disabled, tooltipProps }) => {
  return (
    <DocumentActionButton
      onClick={onClick}
      IconComponent={DeleteIcon}
      text="Pašalinti"
      dangerButton
      disabled={disabled}
      tooltipProps={{
        ...tooltipProps,
        tooltipProps: tooltipProps?.tooltipProps || {
          errorType: ErrorType.CRITICAL,
          text: 'Įrašo šalinimas. Patvirtinus įrašo šalinimą - įrašas pašalinamas iš sistemos'
        }
      }}
    />
  );
};
