import { Reducer } from 'redux';
import {
  SEARCH_NATURAL_PERSON_SUCCESS,
  SEARCH_NATURAL_PERSON_ERROR,
  SEARCH_NATURAL_PERSON_REQUEST,
  CREATE_NATURAL_FOREIGN_PERSON_REQUEST,
  CREATE_NATURAL_FOREIGN_PERSON_SUCCESS,
  CREATE_NATURAL_FOREIGN_PERSON_ERROR,
  RESET_CREATE_NATURAL_FOREIGN_PERSON_STATE,
  RESET_SEARCH_NATURAL_PERSON_STATE,
  SEARCH_NATURAL_PERSON_FOREIGN_REQUEST,
  SEARCH_NATURAL_PERSON_FOREIGN_SUCCESS,
  SEARCH_NATURAL_PERSON_FOREIGN_ERROR,
  RESET_SEARCH_NATURAL_PERSON_FOREIGN_STATE,
  SELECT_PERSON_SEARCH_QUERY,
  RESET_PERSON_SEARCH_QUERY
} from './personsActionTypes';
import {
  NaturalPersonSearchData,
  NaturalPersonForeignSearchData,
  NaturalPersonSearchQuery
} from './personsTypes';

export interface PersonDataState {
  loadingNaturalPersonSearchResults: boolean;
  naturalPersonSearchResults?: NaturalPersonSearchData[];
  naturalPersonSearchResultsError?: Error;

  loadingNaturalPersonForeignSearchResults: boolean;
  naturalPersonForeignSearchResults?: NaturalPersonForeignSearchData[];
  naturalPersonForeignSearchResultsError?: Error;

  naturalForeignPersonCreated: boolean;
  creatingNaturalForeignPerson: boolean;
  creatingNaturalForeignPersonError?: Error;

  selectedNaturalPersonSearchQuery?: NaturalPersonSearchQuery;
}

export const initialState: PersonDataState = {
  loadingNaturalPersonSearchResults: false,
  naturalPersonSearchResults: undefined,
  naturalPersonSearchResultsError: undefined,

  loadingNaturalPersonForeignSearchResults: false,
  naturalPersonForeignSearchResults: undefined,
  naturalPersonForeignSearchResultsError: undefined,

  creatingNaturalForeignPerson: false,
  naturalForeignPersonCreated: false,
  creatingNaturalForeignPersonError: undefined,

  selectedNaturalPersonSearchQuery: undefined
};

export const personsReducer: Reducer<PersonDataState> = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_NATURAL_PERSON_REQUEST:
      return { ...state, loadingNaturalPersonSearchResults: true };
    case SEARCH_NATURAL_PERSON_SUCCESS:
      return {
        ...state,
        loadingNaturalPersonSearchResults: false,
        naturalPersonSearchResults: action.payload,
        naturalPersonForeignSearchResults: undefined
      };
    case SEARCH_NATURAL_PERSON_ERROR:
      return {
        ...state,
        loadingNaturalPersonSearchResults: false,
        naturalPersonSearchResultsError: action?.payload?.data
      };
    case RESET_SEARCH_NATURAL_PERSON_STATE:
      return {
        ...state,
        loadingNaturalPersonSearchResults: false,
        naturalPersonSearchResults: undefined,
        naturalPersonSearchResultsError: undefined
      };
    case SEARCH_NATURAL_PERSON_FOREIGN_REQUEST:
      return { ...state, loadingNaturalPersonForeignSearchResults: true };
    case SEARCH_NATURAL_PERSON_FOREIGN_SUCCESS:
      return {
        ...state,
        loadingNaturalPersonForeignSearchResults: false,
        naturalPersonForeignSearchResults: action.payload,
        naturalPersonSearchResults: undefined
      };
    case SEARCH_NATURAL_PERSON_FOREIGN_ERROR:
      return {
        ...state,
        loadingNaturalPersonSearchResults: false,
        naturalPersonForeignSearchResultsError: action?.payload?.data
      };
    case RESET_SEARCH_NATURAL_PERSON_FOREIGN_STATE:
      return {
        ...state,
        loadingNaturalPersonForeignSearchResults: false,
        naturalPersonForeignSearchResults: undefined,
        naturalPersonForeignSearchResultsError: undefined
      };
    case CREATE_NATURAL_FOREIGN_PERSON_REQUEST:
      return { ...state, creatingNaturalForeignPerson: true };
    case CREATE_NATURAL_FOREIGN_PERSON_SUCCESS:
      return {
        ...state,
        creatingNaturalForeignPerson: false,
        naturalForeignPersonCreated: true
      };
    case CREATE_NATURAL_FOREIGN_PERSON_ERROR:
      return {
        ...state,
        creatingNaturalForeignPerson: false,
        creatingNaturalForeignPersonError: action?.payload?.data
      };
    case RESET_CREATE_NATURAL_FOREIGN_PERSON_STATE:
      return {
        ...state,
        creatingNaturalForeignPerson: false,
        naturalForeignPersonCreated: false,
        creatingNaturalForeignPersonError: undefined
      };
    case SELECT_PERSON_SEARCH_QUERY:
      return {
        ...state,
        selectedNaturalPersonSearchQuery: action.payload
      };
    case RESET_PERSON_SEARCH_QUERY:
      return {
        ...state,
        selectedNaturalPersonSearchQuery: undefined
      };
    default:
      return state;
  }
};
