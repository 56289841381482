import {
  NaturalPerson,
  ShareholderPerson,
  ShareholdersData,
  ShareholdersHistory,
  ShareholdersProps
} from '../../../store/shareholders/shareholdersTypes';
import { CellStyle, ColumnDefinition } from '../../../utils/exporters/types';

export interface HistoryEvent {
  date: string;
  end?: boolean;
  start?: boolean;
  shareholderEvent?: ShareholdersHistory;
}

export interface ShareholderExportRowData {
  level: number;
  name: string[];
  events: HistoryEvent[];
  groupMember: boolean;
}

export interface ShareholderExportSettings {
  showShares: boolean;
  showVotes: boolean;
  showNonParticipatingShareholders: boolean;
  selectedDates: string[];
}

function getNameValues(person: ShareholderPerson, shareholder: ShareholdersProps): string[] {
  const name = [person.personName];
  const groupMember = shareholder.shareholderPersons.length > 1;

  if (person.personType !== NaturalPerson.LFA && person.personType !== NaturalPerson.UFA) {
    name.push(`${person.personLegalCode}`);
  }

  if (groupMember && !person.isAuthorized) {
    name.push('Bendraturtis');
  }

  if (groupMember && person.isAuthorized) {
    name.push('Bendraturčių įgaliotas asmuo');
  }
  return name;
}

function getTableExportRow(
  level: number,
  person: ShareholderPerson,
  shareholder: ShareholdersProps
): ShareholderExportRowData {
  return {
    name: getNameValues(person, shareholder),
    events: shareholder.shareholderHistory.map((event) => ({
      date: event.eventDate,
      start: shareholder.shareholderStartDate === event.eventDate,
      end: shareholder.shareholderStoppedParticipatingFromDate === event.eventDate,
      shareholderEvent: event
    })),
    groupMember: shareholder.shareholderPersons.length > 1,
    level
  };
}

function isParticipating(
  { shareholderStoppedParticipatingFromDate, shareholderStartDate }: ShareholdersProps,
  dates: string[]
): boolean {
  if (!shareholderStartDate) {
    return false;
  }

  const shareholderStart = Date.parse(shareholderStartDate);

  if (dates.some((date) => shareholderStart <= Date.parse(date))) {
    return false;
  }

  if (!shareholderStoppedParticipatingFromDate) {
    return true;
  }

  const shareholderEnd = Date.parse(shareholderStoppedParticipatingFromDate);

  if (dates.some((date) => shareholderEnd >= Date.parse(date))) {
    return true;
  }
  return false;
}

function getRecursiveTableExportRows(
  level: number,
  shareholders: ShareholdersProps[],
  settings: ShareholderExportSettings
): ShareholderExportRowData[] {
  return shareholders
    .filter(
      (shareholder) =>
        settings.showNonParticipatingShareholders ||
        isParticipating(shareholder, settings.selectedDates)
    )
    .map((shareholder) => {
      const persons = shareholder.shareholderPersons;

      const rows = persons.map((person) => {
        const row = getTableExportRow(level, person, shareholder);

        if (person.shareholders.length === 0) {
          return [row];
        }

        return [row, ...getRecursiveTableExportRows(level + 1, person.shareholders, settings)];
      });

      if (persons.length > 1) {
        const name = persons
          .map((person) => [...getNameValues(person, shareholder), ''])
          .flat()
          .slice(0, -1);
        return [
          {
            name,
            events: shareholder.shareholderHistory.map((event) => ({
              date: event.eventDate,
              start: shareholder.shareholderStartDate === event.eventDate,
              end: shareholder.shareholderStoppedParticipatingFromDate === event.eventDate,
              shareholderEvent: event
            })),
            groupMember: false,
            level
          },
          ...rows.flat()
        ];
      }

      return rows.flat();
    })
    .flat();
}

export function getTableExportRows(
  settings: ShareholderExportSettings,
  data?: ShareholdersData
): ShareholderExportRowData[] {
  if (!data || settings.selectedDates.length < 1) {
    return [];
  }

  const virsEvents: HistoryEvent[] = [];

  if (data.virsEndDate) {
    virsEvents.push({ date: data.virsEndDate, end: true, start: false });
  }
  if (data.virsStartDate) {
    virsEvents.push({ date: data.virsStartDate, end: false, start: true });
  }

  return [
    {
      name: [data.virsName],
      level: 0,
      events: virsEvents,
      groupMember: false
    },
    ...getRecursiveTableExportRows(1, data.shareholders, settings)
  ];
}

export function getFill(groupMember: boolean): string | undefined {
  return groupMember ? 'FFCCCCCC' : undefined;
}

export function getValueBeforeDate(
  date: string,
  { events }: ShareholderExportRowData
): HistoryEvent | undefined {
  return events.reduce<HistoryEvent | undefined>(
    (prev, value) => (Date.parse(value.date) < Date.parse(date) ? value : prev),
    undefined
  );
}

export function getValueAfterDate(
  date: string,
  { events }: ShareholderExportRowData
): HistoryEvent | undefined {
  return events.reduce<HistoryEvent | undefined>(
    (prev, value) => (Date.parse(value.date) <= Date.parse(date) ? value : prev),
    undefined
  );
}

export const mapCellStyle: (value: ShareholderExportRowData) => CellStyle = ({ groupMember }) => ({
  fill: getFill(groupMember)
});

export function buildMappedEventColumn(settings: ShareholderExportSettings) {
  return settings.selectedDates.map(
    (selectedDate) =>
      (
        header: string,
        identifier: string,
        visible: boolean,
        eventMapper: (event: HistoryEvent) => string | undefined | null,
        eventProvider: (date: string, data: ShareholderExportRowData) => HistoryEvent | undefined
      ): ColumnDefinition<ShareholderExportRowData> => {
        return {
          header,
          identifier,
          visible,
          cellMapper: (row) => {
            const event = eventProvider(selectedDate, row);
            const value = event && eventMapper(event);
            return [
              {
                values: value ? [value] : [],
                style: mapCellStyle(row)
              }
            ];
          }
        };
      }
  );
}

export function textBoolean(value?: boolean): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  return value ? 'Taip' : 'Ne';
}
