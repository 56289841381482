import { axiosDelete } from '../../utils/axiosApi';
import {
  InstDocumentType,
  LegalDocumentType,
  OutletDocumentType,
  VirsDocumentType
} from './unsignedDataTypes';

export const deleteInstUnsigned = (documentType?: InstDocumentType) => {
  const basePath = '/documents/unsigned/inst';

  const paramsPath = documentType ? `${basePath}?documentType=${documentType}` : basePath;

  return axiosDelete(paramsPath);
};

export const deleteLegalUnsigned = (documentType?: LegalDocumentType) => {
  const basePath = '/documents/unsigned/legal';

  const paramsPath = documentType ? `${basePath}?documentType=${documentType}` : basePath;

  return axiosDelete(paramsPath);
};

export const deleteVirsUnsigned = (virsId: number, documentType?: VirsDocumentType) => {
  const basePath = '/documents/unsigned/virs';
  const variablePath = `${basePath}/${virsId}`;

  const paramsPath = documentType ? `${variablePath}?documentType=${documentType}` : variablePath;

  return axiosDelete(paramsPath);
};

export const deleteOutletUnsigned = (outletId: number, documentType?: OutletDocumentType) => {
  const basePath = '/documents/unsigned/outlet';
  const variablePath = `${basePath}/${outletId}`;

  const paramsPath = documentType ? `${variablePath}?documentType=${documentType}` : variablePath;

  return axiosDelete(paramsPath);
};
