import React from 'react';
import { makeStyles, createStyles, Box, Typography, Container } from '@material-ui/core';
import virsisTheme from '../../style/virsisTheme';
import { CustomCloseIconButton } from '../Icons/IconButtons/CustomCloseIconButton';

const useStyles = makeStyles(() =>
  createStyles({
    alertBox: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '30px',
      padding: '5px 15px',
      backgroundColor: virsisTheme.palette.secondary.light,
      border: '1px solid',
      borderColor: virsisTheme.palette.error.main,
      borderRadius: 5,
      justifyContent: 'space-between'
    },
    content: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      lineHeight: '32px'
    },
    closeButton: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center'
    },
    icon: {
      color: '#006FB2'
    }
  })
);

interface Props {
  handleClose: () => void;
  text: string;
  notClosable?: boolean;
}
export const AlertBox: React.FC<Props> = ({ handleClose, text, notClosable }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box className={classes.alertBox}>
        <Typography variant="body1" className={classes.content}>
          {text}
        </Typography>
        {!notClosable && <CustomCloseIconButton onClick={handleClose} />}
      </Box>
    </Container>
  );
};
