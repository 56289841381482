import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { EditionCheckDataTableStateContext, EditionCheckDataTableDispatchContext } from './Context';
import { ApplicationState } from '../../store';
import {
  EditionCheckDataTableHeaderType,
  EditionCheckDataVirsTableField
} from './tableState/tableTypesAndActions';
import { EditionCheckDataVirs } from '../../store/editionChecks/editionChecksTypes';
import { TableCustomFilterCellGeneric } from '../../components/TableCustomFilterCell/TableCustomFilterCellGeneric';

interface Props {
  columnNames: EditionCheckDataTableHeaderType[];
}

const EditionCheckDataTableCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { dispatch: tableDispatch } = useContext(EditionCheckDataTableDispatchContext);
  const { state: tableState } = useContext(EditionCheckDataTableStateContext);

  const handleSetCustomFilter = (column: EditionCheckDataVirsTableField, value: string | null) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector((state: ApplicationState) => state.editionData.editionCheckDataVirs) || [];

  function getUniqueOptions(name: EditionCheckDataVirsTableField, data: EditionCheckDataVirs[]) {
    if (name === 'docNr') {
      const array: string[] = [];
      data.forEach((item) => item.editionCheckDocs.forEach((doc) => array.push(doc.docNr)));
      return Array.from(new Set(array));
    }

    if (name === 'outletName') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckOutlets.forEach((outlet) => array.push(outlet.outletName))
      );
      return Array.from(new Set(array));
    }

    if (name === 'periodName') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckOutlets.forEach((vip) =>
          vip.editionCheckConclusions.forEach((conclusion) => array.push(conclusion.periodName))
        )
      );
      return Array.from(new Set(array));
    }
    if (name === 'conclusion') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckOutlets.forEach((vip) =>
          vip.editionCheckConclusions.forEach((conclusion) => array.push(conclusion.conclusion))
        )
      );
      return Array.from(new Set(array));
    }

    if (data) {
      return Array.from(
        new Set(data.filter((item) => item[name] !== null).map((item) => item[name]))
      );
    }

    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCustomFilterCellGeneric
              key={column.id}
              id={column.id}
              type={column.type}
              setFilter={handleSetCustomFilter}
              uniqueOptions={getUniqueOptions(column.id, allData)}
            />
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
export default EditionCheckDataTableCustomFilter;
