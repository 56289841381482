export interface OutletGroup {
  outletGroupId: number;
  outletGroupNameEn: string;
  outletGroupName: string;
  validFrom: string;
  validTo: string;
}

export interface OutletGroupInput {
  outletGroupId: number | null;
  outletGroupName: string | null;
  outletGroupNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: OutletGroupInput = {
  outletGroupId: null,
  outletGroupName: '',
  outletGroupNameEn: '',
  validFrom: null,
  validTo: null
};
