import { TableFilterBySharesAndVotes } from '../../../components/ShareholderComponents/TableButtons/FilterButtons/FilterBySharesAndVotes';
import { TableToolsFrame } from '../../../components/TableToolsFrame/TableToolsFrame';
import ShareholdersCustomTools from '../TableFilters/ShareholdersCustomTools';
import { NonParticipatingShareholdersFilters } from './Components/NonParticipatingShareholdersFilter';
import {
  ShareholdersSelectedDateColumnHeader,
  ShareholdersSelectedDateHeaderField
} from './tableState/tableInitialStateAndTypes';

import {
  useShareholdersSelectedDateTableState,
  useShareholdersSelectedDateTableDispatch
} from './TableWithContext';

interface Props {
  columnNames: ShareholdersSelectedDateColumnHeader[];
  allEventsDates?: string[];
}

export const ShareholdersSelectedDateTableCustomizationBar = ({
  columnNames,
  allEventsDates
}: Props) => {
  const { state } = useShareholdersSelectedDateTableState();
  const { dispatch } = useShareholdersSelectedDateTableDispatch();

  const toggleNonParticipatingShareholdersFilter = () => {
    dispatch({ type: 'NON_PARTICIPATING_SHAREHOLDERS_TOGGLED' });
  };

  const handleShowSharesClicked = () => {
    dispatch({
      type: 'SET_SHOW_SHARES_VOTES',
      showShares: true,
      showVotes: false
    });
  };

  const handleSetShowVotesClicked = () => {
    dispatch({
      type: 'SET_SHOW_SHARES_VOTES',
      showShares: false,
      showVotes: true
    });
  };

  const handleSetSortByColumn = (column: ShareholdersSelectedDateHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        numberOfGridsLeft={9}
        numberOfGridsRight={3}
        leftSideItem={
          <>
            <NonParticipatingShareholdersFilters
              filterDisabled={state.participationFilterDisabled}
              filterOn={state.showNonParticipatingShareholders}
              onFilterToggle={toggleNonParticipatingShareholdersFilter}
            />
            <TableFilterBySharesAndVotes
              onShowSharesClicked={handleShowSharesClicked}
              onShowVotesClicked={handleSetShowVotesClicked}
              sharesButtonDisabled={!!state.showShares}
              votesButtonDisabled={!!state.showVotes}
            />
          </>
        }
        rightSideItem={
          <ShareholdersCustomTools
            tableColumns={columnNames}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            allEventsDates={allEventsDates}
          />
        }
      />
    </div>
  );
};
