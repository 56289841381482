import { Box, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  text: string;
}

const ErrorText: React.FC<Props> = ({ text }) => (
  <Box p={2}>
    <Typography>{text}</Typography>
  </Box>
);

export default ErrorText;
