import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

import moment from 'moment';
import { Field } from 'formik';
import { IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box';
import { FormikFieldStyleProps } from './FieldStyles';
import { Strings } from '../../utils/strings/Strings';

export function getDateString(date: Date | null): string | null {
  return date ? moment(date).format('YYYY-MM-DD') : null;
}

interface Props extends FormikFieldStyleProps {
  styledClasses?: any;
  values?: any;
  label?: string;
  isRequired: boolean;
  disabled?: boolean;
  formikKey: string;
  date: any;
  setFieldValue: any;
  errors: any;
  maxDate?: string;
  minDate?: string;
  className?: string;
  containerClassName?: string;
  fieldClassName?: string;
}

export const FormikDateField: React.FC<Props> = ({
  date,
  isRequired,
  disabled,
  setFieldValue,
  formikKey,
  errors,
  maxDate,
  minDate,
  style,
  className
}) => {
  return (
    <KeyboardDatePicker
      minDate={minDate}
      maxDate={maxDate}
      required={isRequired}
      id={formikKey}
      placeholder="mmmm-mm-dd"
      inputVariant="outlined"
      format="YYYY-MM-DD"
      okLabel="Pasirinkti"
      cancelLabel={Strings.button__cancel}
      clearable
      clearLabel="Išvalyti"
      error={!!errors}
      helperText={errors}
      disabled={disabled}
      FormHelperTextProps={{ style: { ...style?.helper } }}
      value={date}
      className={className}
      onChange={(value) => {
        if (value && value.isValid()) {
          setFieldValue(formikKey, new Date(value.startOf('day').toISOString()));
        } else {
          setFieldValue(formikKey, value);
        }
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
    />
  );
};

export const FormikFormDateField: React.FC<Props> = ({
  styledClasses,
  date,
  values,
  label,
  setFieldValue,
  formikKey,
  errors,
  style,
  disabled,
  containerClassName,
  fieldClassName,
  ...props
}) => {
  return (
    <Box
      margin={1}
      className={styledClasses.inputContainer + ' ' + containerClassName}
      style={style?.container}
    >
      {label !== undefined && (
        <Typography variant="h4" className={styledClasses.inputLabel}>
          {label}
        </Typography>
      )}
      <Field
        date={date}
        errors={errors}
        formikKey={formikKey}
        component={FormikDateField}
        setFieldValue={setFieldValue}
        className={styledClasses.dateField + ' ' + fieldClassName}
        {...{ ...props, disabled, style }}
      />

      {values && (
        <IconButton
          className={styledClasses.clearIcon}
          disabled={disabled}
          onClick={() => {
            setFieldValue(formikKey, null);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};
