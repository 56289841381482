import { action } from 'typesafe-actions';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import {
  FETCH_PROVIDED_DATA_VIRS_REQUEST,
  FETCH_PROVIDED_DATA_VIRS_SUCCESS,
  FETCH_PROVIDED_DATA_VIRS_ERROR,
  FETCH_PROVIDED_DATA_BY_VIRS_ID_REQUEST,
  FETCH_PROVIDED_DATA_INST_REQUEST,
  FETCH_PROVIDED_DATA_INST_SUCCESS,
  FETCH_PROVIDED_DATA_INST_ERROR,
  REMOVE_PROVIDED_DATA_SUCCESS,
  REMOVE_PROVIDED_DATA_ERROR,
  RESET_PROVIDED_DATA_REMOVE_STATE,
  ANNUL_PROVIDED_DATA_INST_REQUEST,
  ANNUL_PROVIDED_DATA_SUCCESS,
  ANNUL_PROVIDED_DATA_ERROR,
  RESET_PROVIDED_DATA_ANNUL_STATE,
  FETCH_PROVIDED_DATA_LEGAL_REQUEST,
  FETCH_PROVIDED_DATA_LEGAL_SUCCESS,
  FETCH_PROVIDED_DATA_LEGAL_ERROR,
  ANNUL_PROVIDED_DATA_LEGAL_REQUEST,
  ANNUL_PROVIDED_DATA_REQUEST,
  REMOVE_PROVIDED_DATA_REQUEST
} from './providedDataActionTypes';
import { ProvidedDataInst, ProvidedDataTableType } from './providedDataTypes';

export const fetchProvidedDataVirs = () => action(FETCH_PROVIDED_DATA_VIRS_REQUEST);
export const fetchProvidedDataVirsSuccess = (providedData: string) =>
  action(FETCH_PROVIDED_DATA_VIRS_SUCCESS, providedData);
export const fetchProvidedDataVirsError = (error: Error) =>
  action(FETCH_PROVIDED_DATA_VIRS_ERROR, error);

export const fetchProvidedDataByVirsId = (virsId: number | null) =>
  action(FETCH_PROVIDED_DATA_BY_VIRS_ID_REQUEST, virsId);

export const fetchProvidedDataInst = () => action(FETCH_PROVIDED_DATA_INST_REQUEST);
export const fetchProvidedDataInstSuccess = (providedDataInst: ProvidedDataInst[]) =>
  action(FETCH_PROVIDED_DATA_INST_SUCCESS, providedDataInst);
export const fetchProvidedDataInstError = (error: Error) =>
  action(FETCH_PROVIDED_DATA_INST_ERROR, error);

export const fetchProvidedDataLegal = () => action(FETCH_PROVIDED_DATA_LEGAL_REQUEST);
export const fetchProvidedDataLegalSuccess = (providedDataLegal: ProvidedDataInst[]) =>
  action(FETCH_PROVIDED_DATA_LEGAL_SUCCESS, providedDataLegal);
export const fetchProvidedDataLegalError = (error: Error) =>
  action(FETCH_PROVIDED_DATA_LEGAL_ERROR, error);

export const removeProvidedData = (
  documentStatusId: number,
  tableType: ProvidedDataTableType,
  virsId?: number
) => action(REMOVE_PROVIDED_DATA_REQUEST, { documentStatusId, tableType, virsId });
export const removeProvidedDataSuccess = () => action(REMOVE_PROVIDED_DATA_SUCCESS);
export const removeProvidedDataError = (error: Error) => action(REMOVE_PROVIDED_DATA_ERROR, error);
export const resetProvidedDataRemoveState = () => action(RESET_PROVIDED_DATA_REMOVE_STATE);

export const annulProvidedData = (
  documentStatusId: number,
  annulRecord: AnnulRecord,
  virsId: number
) =>
  action(ANNUL_PROVIDED_DATA_REQUEST, {
    documentStatusId,
    annulRecord,
    virsId
  });
export const annulProvidedDataInst = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_PROVIDED_DATA_INST_REQUEST, { documentStatusId, annulRecord });
export const annulProvidedDataLegal = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_PROVIDED_DATA_LEGAL_REQUEST, { documentStatusId, annulRecord });
export const annulProvidedDataSuccess = () => action(ANNUL_PROVIDED_DATA_SUCCESS);
export const annulProvidedDataError = (error: Error) => action(ANNUL_PROVIDED_DATA_ERROR, error);
export const resetAnnulProvidedDataState = () => action(RESET_PROVIDED_DATA_ANNUL_STATE);
