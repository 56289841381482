import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Divider, CircularProgress, Container } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../../store';
import { fetchExternalSystemData } from '../../store/externalSystemData/externalSystemDataActions';
import { externalSystemDataFilters } from '../../store/externalSystemData/externalSystemDataTypes';
import PageFilterButton from '../../components/PageButtons/PageFilterButton';
import LicensesTable from '../../containers/ExternalSystemData/LicensesTable/LicensesTable';
import PoliticalAdFinancialSupportTable from '../../containers/ExternalSystemData/PoliticalAdFinancialSupportTable/PoliticalAdFinancialSupportTable';
import LegalEntityFinancialSupportTable from '../../containers/ExternalSystemData/LegalEntityFinancialSupportTable/LegalEntityFinancialSupportTable';
import IndividualPersonFinancialSupportTable from '../../containers/ExternalSystemData/IndividualPersonFinancialSupportTable/IndividualPersonFinancialSupportTable';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import { useParams } from 'react-router-dom';

type Props = {
  kmSubsystem?: boolean;
} & RouteComponentProps;

const ExternalSystemDataPage: React.FC<Props> = () => {
  const { displayedTable } = useParams<{ displayedTable: string }>();

  const {
    externalSystemData: { loadingExternalSystemData, externalSystemData },
    virsis: { virsData }
  } = useSelector((state: ApplicationState) => state);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (virsData) {
      dispatch(fetchExternalSystemData(virsData.virsId));
    }
  }, [dispatch, virsData]);

  const [activeFilterValue, setActiveFilterValue] = useState<string>('all');
  const [tableDisplayStatus, setTableDisplayStatus] = useState({
    licenses: true,
    politicalAdFinancialSupport: true,
    legalEntityFinancialSupport: true,
    individualPersonFinancialSupport: true
  });

  const handleDisplayFilteredTables = (filterName: string) => {
    setActiveFilterValue(filterName);
    setTableDisplayStatus({
      licenses: filterName === 'all',
      politicalAdFinancialSupport: filterName === 'all',
      legalEntityFinancialSupport: filterName === 'all',
      individualPersonFinancialSupport: filterName === 'all',
      [filterName]: true
    });
  };

  useEffect(() => {
    switch (displayedTable) {
      case 'licencijos':
        handleDisplayFilteredTables('licenses');
        break;
      case 'pajamos-is-politines-reklamos':
        handleDisplayFilteredTables('politicalAdFinancialSupport');
        break;
      case 'juridiniu-asmenu-parama':
        handleDisplayFilteredTables('legalEntityFinancialSupport');
        break;
      case 'fiziniu-asmenu-parama':
        handleDisplayFilteredTables('individualPersonFinancialSupport');
        break;
      case 'visi':
        handleDisplayFilteredTables('all');
        break;
      default:
        handleDisplayFilteredTables('all');
        break;
    }
  }, [displayedTable]);

  return (
    <Container maxWidth="lg">
      <div className="data-page">
        <div className="data-page-header">
          <Box display="flex">
            <Typography variant="h1">Išorinių sistemų duomenys</Typography>
          </Box>
        </div>

        <Divider light />

        {loadingExternalSystemData ? (
          <CircularProgress />
        ) : (
          <>
            <div className="data-page-filters">
              <Box>
                {externalSystemDataFilters.map((filter) => (
                  <PageFilterButton
                    key={filter.value}
                    active={filter.value === activeFilterValue}
                    onClick={() => handleDisplayFilteredTables(filter.value)}
                    label={filter.label}
                  />
                ))}
              </Box>
            </div>

            <Divider light />

            <div className="data-page-content">
              {tableDisplayStatus.licenses && (
                <LicensesTable
                  data={externalSystemData ? externalSystemData.licensesData : []}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              )}

              {tableDisplayStatus.politicalAdFinancialSupport && (
                <PoliticalAdFinancialSupportTable
                  data={externalSystemData ? externalSystemData.politicalAdData : []}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              )}

              {tableDisplayStatus.legalEntityFinancialSupport && (
                <LegalEntityFinancialSupportTable
                  data={externalSystemData ? externalSystemData.legalEntityData : []}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              )}

              {tableDisplayStatus.individualPersonFinancialSupport && (
                <IndividualPersonFinancialSupportTable
                  data={externalSystemData ? externalSystemData.individualPersonData : []}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default withRouter(ExternalSystemDataPage);
