import React, { createContext } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { Card } from '@material-ui/core';

import { getStore } from './state/store';

import { TimelineNavigation } from './TimelineNavigation';
import { TimelineContainer } from './TimelineDisplay';
import { ApplicationState } from '../../../store';
import { fetchVirsDataOfDate } from '../../../store/shareholdersVirsTree/virsTreeActions';
import { currentDate } from '../../ShareholdersTable/utilityFunctions/sharedFunctions';
import moment, { Moment } from 'moment';
import { getDateAvailablePredicate } from './dateUtil';

export const VirsDatesContext = createContext<{
  virsStart?: Moment | null;
  virsEnd?: Moment | null;
  isDateAvailable: (date: Moment) => boolean;
}>({ virsStart: undefined, virsEnd: undefined, isDateAvailable: () => false });

export const TimelinePanel: React.FC = () => {
  const dispatch = useDispatch();
  const {
    virsTreeData: {
      activityPeriods,
      loadingVirsTreeData: loadingVirs,
      timeline: { activeDate, periodName, timelineEvents, isLoading, error },
      virsTreeData
    }
  } = useSelector((state: ApplicationState) => state);
  const virsStart =
    virsTreeData?.virsStartDate === undefined || virsTreeData?.virsStartDate === null
      ? virsTreeData?.virsStartDate
      : moment(virsTreeData?.virsStartDate);
  const virsEnd =
    virsTreeData?.virsEndDate === undefined || virsTreeData?.virsEndDate === null
      ? moment(currentDate)
      : moment(virsTreeData?.virsEndDate);

  return (
    <Provider
      store={getStore(periodName, activeDate, timelineEvents, isLoading, error, (value) =>
        dispatch(fetchVirsDataOfDate(value.format('YYYY-MM-DD')))
      )}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: 11,
          pointerEvents: isLoading || loadingVirs ? 'none' : 'auto'
        }}
      >
        <Card
          style={{
            boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.1)',
            position: 'relative',
            borderRadius: '0'
          }}
        >
          <TimelineNavigation
            minDate={virsTreeData?.virsStartDate}
            maxDate={virsTreeData?.virsEndDate || currentDate}
            activityPeriods={activityPeriods}
          />
          <VirsDatesContext.Provider
            value={{
              virsStart,
              virsEnd: virsEnd,
              isDateAvailable: getDateAvailablePredicate(activityPeriods)
            }}
          >
            <TimelineContainer />
          </VirsDatesContext.Provider>
        </Card>
      </div>
    </Provider>
  );
};
