import React, { ReactElement } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import theme from '../../style/virsisTheme';

const useStyles = makeStyles({
  tooltip: {
    maxWidth: 'none',
    margin: '0.8em 0',
    padding: 12,
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fontFamily: 'Open Sans',
    fontSize: '0.8rem',
    fontWeight: 400,
    '& span': {
      color: theme.palette.secondary.light
    }
  }
});

interface Props {
  children: ReactElement;
  text: string;
  className?: string;
}

export const BasicTooltip: React.FC<Props> = ({ children, text, className }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={text}
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip }}
      className={className}
    >
      {children}
    </Tooltip>
  );
};
