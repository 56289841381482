import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { getExternalSystemData } from './externalSystemDataApi';
import {
  fetchExternalSystemData,
  fetchExternalSystemDataSuccess,
  fetchExternalSystemDataError
} from './externalSystemDataActions';
import { FETCH_EXTERNAL_SYSTEM_DATA_REQUEST } from './externalSystemDataActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';

function* handleExternalSystemDataFetch(action: ActionType<typeof fetchExternalSystemData>) {
  try {
    const externalSystemData = yield call(getExternalSystemData, action.payload);
    yield put(fetchExternalSystemDataSuccess(externalSystemData.data));
  } catch (error) {
    yield put(fetchExternalSystemDataError(error));
    yield put(sendMessage('error', error.response.data?.message));
  }
}

function* externalSystemDataSaga() {
  yield takeEvery(FETCH_EXTERNAL_SYSTEM_DATA_REQUEST, handleExternalSystemDataFetch);
}

export default externalSystemDataSaga;
