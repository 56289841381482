export const UNSIGNED_DATA_REMOVE_REQUEST_OUTLET = '@unsignedData/REMOVE_REQUEST_OUTLET';
export const UNSIGNED_DATA_REMOVE_REQUEST_INST = '@unsignedData/REMOVE_REQUEST_INST';
export const UNSIGNED_DATA_REMOVE_REQUEST_VIRS = '@unsignedData/REMOVE_REQUEST_VIRS';
export const UNSIGNED_DATA_REMOVE_REQUEST_LEGAL = '@unsignedData/REMOVE_REQUEST_LEGAL';
export const UNSIGNED_DATA_REMOVE_RESET_STATE = '@unsignedData/RESET_STATE';
export const UNSIGNED_DATA_REMOVE_SUCCESS = '@unsignedData/REMOVE_SUCCESS';
export const UNSIGNED_DATA_REMOVE_ERROR = '@unsignedData/REMOVE_ERROR';

export enum LegalDocumentType {
  FUNDS_RECEIVED = 'FUNDS_RECEIVED'
}

export enum InstDocumentType {
  FUNDS_RECEIVED = 'FUNDS_RECEIVED',
  ANNUAL_PAYMENT = 'ANNUAL_PAYMENT',
  EDITION_CHECK = 'EDITION_CHECK',
  MISCONDUCT = 'MISCONDUCT',
  ETHICAL_NONCOMPLIANCE = 'ETHICAL_NONCOMPLIANCE'
}

export enum VirsDocumentType {
  ENTERPRISE = 'ENTERPRISE',
  OUTLET = 'OUTLET'
}

export enum OutletDocumentType {
  REPRESENTATIVE = 'REPRESENTATIVE',
  CATEGORY = 'CATEGORY',
  EDITION = 'EDITION',
  AD_PRINTING = 'AD_PRINTING'
}
