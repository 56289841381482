import React from 'react';
import { makeStyles } from '@material-ui/core';
import { replaceDecimalPoint } from '../../../../utils/tableDataFunctions';

const useStyles = makeStyles(() => ({
  percentageInfo: {
    fontSize: '0.73rem'
  },
  percentages: {
    fontWeight: 600
  }
}));

export interface Props {
  shares?: number;
  votes?: number;
  shareType: string;
  votesOnRule: boolean;
  sharePercentageLessThanPermitted: boolean | null;
  votePercentageLessThanPermitted: boolean | null;
}

export const PercentageInfo: React.FC<Props> = ({ shares, votes, votesOnRule, shareType, sharePercentageLessThanPermitted, votePercentageLessThanPermitted }) => {
  const classes = useStyles();

  return (
    <div className={classes.percentageInfo}>
      <span>
        <span className={classes.percentages}>
          {shares !== null ? replaceDecimalPoint(`${sharePercentageLessThanPermitted ? '< ' : ''}${shares}% `) : '- %'}
        </span>{' '}
        {shareType === 'Akcijų dalis' ? 'akcijų' : 'įnašų'}
      </span>
      <span> / </span>
      <span>
        {votesOnRule ? (
          'Balsai pagal taisyklę'
        ) : (
          <>
            <span className={classes.percentages}>
              {votes !== null ? replaceDecimalPoint(`${votePercentageLessThanPermitted ? '< ' : ''}${votes}% `) : '- %'}
            </span>{' '}
            balsų
          </>
        )}
      </span>
    </div>
  );
};
