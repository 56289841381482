import { action } from 'typesafe-actions';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import {
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST,
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_SUCCESS,
  FETCH_FUNDS_RECEIVED_DATA_LEGAL_ERROR,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_SUCCESS,
  FETCH_FUNDS_RECEIVED_DATA_VIRS_ERROR,
  FUNDS_RECEIVED_DATA_VIRS_PDF_REQUEST,
  CREATE_FUNDS_RECEIVED_REQUEST,
  CREATE_FUNDS_RECEIVED_SUCCESS,
  CREATE_FUNDS_RECEIVED_ERROR,
  RESET_CREATE_FUNDS_RECEIVED_STATE,
  UPDATE_FUNDS_RECEIVED_REQUEST,
  UPDATE_FUNDS_RECEIVED_SUCCESS,
  UPDATE_FUNDS_RECEIVED_ERROR,
  REMOVE_FUNDS_RECEIVED_REQUEST,
  REMOVE_FUNDS_RECEIVED_SUCCESS,
  REMOVE_FUNDS_RECEIVED_ERROR,
  RESET_REMOVE_FUNDS_RECEIVED_STATE,
  RESET_FUNDS_RECEIVED_UPDATE_STATE,
  ANNUL_FUNDS_RECEIVED_DATA_SUCCESS,
  ANNUL_FUNDS_RECEIVED_DATA_ERROR,
  RESET_ANNUL_FUNDS_RECEIVED_DATA_STATE,
  ANNUL_FUNDS_RECEIVED_DATA_REQUEST,
  FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST
} from './fundsReceivedActionTypes';
import { FundsReceivedRecord } from './fundsReceivedDataTypes';

export const fetchFundsReceivedDataLegal = () => action(FETCH_FUNDS_RECEIVED_DATA_LEGAL_REQUEST);
export const fetchFundsReceivedDataSuccessLegal = (data: string) =>
  action(FETCH_FUNDS_RECEIVED_DATA_LEGAL_SUCCESS, data);
export const fetchFundsReceivedDataErrorLegal = (error: Error) =>
  action(FETCH_FUNDS_RECEIVED_DATA_LEGAL_ERROR, error);

export const createFundsReceived = (record: FundsReceivedRecord) =>
  action(CREATE_FUNDS_RECEIVED_REQUEST, { record });
export const createFundsReceivedSuccess = () => action(CREATE_FUNDS_RECEIVED_SUCCESS);
export const createFundsReceivedError = (error: Error) =>
  action(CREATE_FUNDS_RECEIVED_ERROR, error);
export const resetFundsReceivedCreatingState = () => action(RESET_CREATE_FUNDS_RECEIVED_STATE);

export const updateFundsReceived = (recordUpdated: FundsReceivedRecord, fundsReceivedId: number) =>
  action(UPDATE_FUNDS_RECEIVED_REQUEST, {
    recordUpdated,
    fundsReceivedId
  });
export const updateFundsReceivedSuccess = () => action(UPDATE_FUNDS_RECEIVED_SUCCESS);
export const updateFundsReceivedError = (error: Error) =>
  action(UPDATE_FUNDS_RECEIVED_ERROR, error);
export const resetFundsReceivedUpdatingState = () => action(RESET_FUNDS_RECEIVED_UPDATE_STATE);

export const removeFundsReceived = (fundsReceivedId: number) =>
  action(REMOVE_FUNDS_RECEIVED_REQUEST, { fundsReceivedId });
export const removeFundsReceivedSuccess = () => action(REMOVE_FUNDS_RECEIVED_SUCCESS);
export const removeFundsReceivedError = (error: Error) =>
  action(REMOVE_FUNDS_RECEIVED_ERROR, error);
export const resetFundsReceivedRemovingState = () => action(RESET_REMOVE_FUNDS_RECEIVED_STATE);

export const fetchFundsReceivedDataVirs = (virsId: number) =>
  action(FETCH_FUNDS_RECEIVED_DATA_VIRS_REQUEST, virsId);
export const fetchFundsReceivedDataVirsSuccess = (data: string) =>
  action(FETCH_FUNDS_RECEIVED_DATA_VIRS_SUCCESS, data);
export const fetchFundsReceivedDataVirsError = (error: Error) =>
  action(FETCH_FUNDS_RECEIVED_DATA_VIRS_ERROR, error);

export const getFundsReceivedDataVirsDocRequest = (docId: number) =>
  action(FUNDS_RECEIVED_DATA_VIRS_PDF_REQUEST, docId);

export const annulFundsReceived = (documentStatusId: number, annulRecord: AnnulRecord) =>
  action(ANNUL_FUNDS_RECEIVED_DATA_REQUEST, {
    documentStatusId,
    annulRecord
  });
export const annulFundsReceivedSuccess = () => action(ANNUL_FUNDS_RECEIVED_DATA_SUCCESS);
export const annulFundsReceivedError = (error: Error) =>
  action(ANNUL_FUNDS_RECEIVED_DATA_ERROR, error);
export const resetAnnulFundsReceivedState = () => action(RESET_ANNUL_FUNDS_RECEIVED_DATA_STATE);

export const fetchFundsReceivedRecordByDocId = (docId: number) =>
  action(FETCH_FUNDS_RECEIVED_BY_DOC_ID_REQUEST, docId);
