import React from 'react';
import { Button, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  label?: string;
  onClick: (event?: React.MouseEvent) => void;
  disabled?: boolean;
  withIcon?: boolean;
}

const useStyles = makeStyles({
  buttonWidth: {
    width: '124px'
  }
});

export const CloseContinueButton: React.FC<Props> = ({ label, onClick, disabled, withIcon }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonWidth}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={!!disabled}
    >
      <Typography color="inherit">{label || 'Toliau'}</Typography>
      {withIcon && <ChevronRightIcon />}
    </Button>
  );
};
