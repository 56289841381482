import { Box, Divider, Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableErrorRow } from '../../components/TableErrorRow/TableErrorRow';
import { TableLoaderRow } from '../../components/TableLoaderRow/TableLoaderRow';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import { ApplicationState } from '../../store';
import { stableSortWrapped, pageTableData } from '../../utils/tableDataFunctions';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tableTypes';
import {
  useIntegratedSystemsAuditsTableDispatch,
  useIntegratedSystemsAuditsTableState
} from './Context';
import { IntegratedSystemsAuditsTableCustomizationBar } from './TableCustomization';
import { IntegratedSystemsAuditsTableHeader } from './TableHeader';
import { IntegratedSystemsAuditsTableRow } from './TableRow';
import { AuditsContentDialog } from '../../components/AuditsContentDialog/AuditsContentDialog';
import { AuditContentToView } from './tableState/tableInitialStateAndTypes';
import PageActionButtons from '../../components/PageButtons/PageActionButtons';
import { exportTableData, printTableData } from '../../utils/exporters/tableExporter';

export const IntegratedSystemsAuditsTable: React.FC = () => {
  const { dispatch: tableDispatch } = useIntegratedSystemsAuditsTableDispatch();
  const { state: tableState } = useIntegratedSystemsAuditsTableState();

  const {
    loadingIntegratedSystemsAudits,
    integratedSystemsAuditsDataError,
    integratedSystemsAuditsData
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal.auditsData);

  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value });
  };
  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const viewInDialog = (contentToView: AuditContentToView) => {
    return () => {
      tableDispatch({
        type: 'CONTENT_OPENED',
        contentToView
      });
    };
  };

  const closeDialog = () => {
    tableDispatch({
      type: 'CONTENT_CLOSED'
    });
  };

  const sortedData = stableSortWrapped(
    integratedSystemsAuditsData || [],
    tableState.order,
    tableState.sortBy
  );

  const { page: pagedData, pagesCount } = pageTableData(tableState, sortedData);

  return (
    <>
      {tableState.contentToView && (
        <AuditsContentDialog
          title={tableState.contentToView.title}
          content={tableState.contentToView.content}
          close={closeDialog}
        />
      )}
      <Divider />
      <div className="named-data-table">
        <div className="table-title">
          <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">Paieškos rezultatai</Typography>
            <div>
              <PageActionButtons
                onDownload={(format, navigationPath) =>
                  exportTableData(
                    format,
                    tableState.tableExportDefinition,
                    pagedData,
                    navigationPath
                  )
                }
                onPrint={(navigationPath) =>
                  printTableData(tableState.tableExportDefinition, pagedData, navigationPath)
                }
                dontShowDelete
              />
            </div>
          </Box>
        </div>
        <Paper elevation={0}>
          <IntegratedSystemsAuditsTableCustomizationBar />
          <div style={{ overflowX: 'auto' }}>
            <Table aria-label="simple table">
              <IntegratedSystemsAuditsTableHeader />
              <TableBody>
                <TableLoaderRow colSpan={11} isLoading={loadingIntegratedSystemsAudits} />
                <TableErrorRow
                  error={
                    integratedSystemsAuditsDataError && 'Klaida. Nepavyko gauti audito duomenų'
                  }
                  colSpan={11}
                />
                {pagedData.map((record) => {
                  return (
                    <IntegratedSystemsAuditsTableRow
                      key={record.systemsAuditId}
                      record={record}
                      viewRequestInDialog={viewInDialog({
                        content: record.requestContent,
                        title: 'Užklausos turinys'
                      })}
                      viewResponseInDialog={viewInDialog({
                        content: record.responseContent,
                        title: 'Atsakymo turinys'
                      })}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            recordsCount={sortedData.length}
            pagesCount={pagesCount}
            rowsPerPage={tableState.rowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={tableState.page}
            setPage={handleSetPage}
            setRowsPerPage={handleSetRowsPerPage}
            disabled={sortedData.length === 0}
          />
        </Paper>
      </div>
    </>
  );
};
