import { Reducer } from 'redux';
import { getToggledSortOrderGeneric } from '../../utils/tableDataFunctions';
import {
  CUSTOM_FILTER_VALUE_CHANGED,
  CUSTOM_FILTER_VALUE_RESET,
  INITIALIZE_TABLE_STATE,
  PAGE_SET,
  ROWS_PER_PAGE_SET,
  SHOW_STATUS_NOT_SIGNED_TOGGLED,
  SHOW_STATUS_OUTDATED_TOGGLED,
  SHOW_STATUS_RELEVANT_TOGGLED,
  SORTING_CHANGED,
  UPDATE_TEMP_DATA_STATE
} from './fundsReceivedTableActionTypes';
import {
  FundsReceivedTableDataState,
  initialFundsReceivedTableDataState
} from './fundsReceivedDataTableTypes';
import { updateFundsReceivedLegalTableCustomFilter } from '../../containers/FundsReceivedTableInstLegal/tableState/tableStateFunctions';

export interface FundsReceivedDataTableState {
  tableDataState: FundsReceivedTableDataState;
  tempTableDataState?: FundsReceivedTableDataState;
}

export const initialState: FundsReceivedDataTableState = {
  tableDataState: initialFundsReceivedTableDataState,
  tempTableDataState: undefined
};

export const fundsReceivedTableDataReducer: Reducer<FundsReceivedDataTableState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INITIALIZE_TABLE_STATE:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
          statusRelevantFilterEnabled: action.payload.statusRelevantFilterEnabled,
          statusOutdatedFilterEnabled: action.payload.statusOutdatedFilterEnabled,
          showStatusNotSigned: action.payload.showStatusNotSigned,
          showStatusRelevant: action.payload.showStatusRelevant,
          showStatusOutdated: action.payload.showStatusOutdated
        }
      };
    case SHOW_STATUS_OUTDATED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusOutdated: !state.tableDataState.showStatusOutdated,
          page: 0
        }
      };
    case SHOW_STATUS_NOT_SIGNED_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusNotSigned: !state.tableDataState.showStatusNotSigned,
          page: 0
        }
      };
    case SHOW_STATUS_RELEVANT_TOGGLED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          showStatusRelevant: !state.tableDataState.showStatusRelevant,
          page: 0
        }
      };
    case CUSTOM_FILTER_VALUE_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          customFilter: updateFundsReceivedLegalTableCustomFilter(
            state.tableDataState.customFilter,
            action.payload.filterBy,
            action.payload.value
          ),
          page: 0
        }
      };
    case SORTING_CHANGED:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          sortBy:
            action.payload === state.tableDataState.sortBy
              ? state.tableDataState.sortBy
              : action.payload,
          order: getToggledSortOrderGeneric(
            action.payload,
            state.tableDataState.sortBy,
            state.tableDataState.order
          )
        }
      };
    case PAGE_SET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          page: action.payload
        }
      };
    case ROWS_PER_PAGE_SET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          rowsPerPage: action.payload
        }
      };
    case UPDATE_TEMP_DATA_STATE:
      return {
        ...state,
        tempTableDataState: action.payload
      };
    case CUSTOM_FILTER_VALUE_RESET:
      return {
        ...state,
        tableDataState: {
          ...state.tableDataState,
          customFilter: initialFundsReceivedTableDataState.customFilter
        }
      };
    default:
      return state;
  }
};
