import React, { ReactNode, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCell } from '@material-ui/core';
import { OutletRecord, OutletData } from '../../../store/outlets/outletsTypes';
import { ApplicationState } from '../../../store';
import { renewOutlet } from '../../../store/outlets/outletsActions';
import { intialRenewOutletState } from './rowState/outletRowStateAndTypes';
import { OutletBasicRow } from './RowBasic';
import { RowActionButtonGeneric } from '../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { SaveAndCancelActions } from '../../../components/TableRowActions/SaveAndCancelActions';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import { RowStyleOnDifferentStatus } from '../../../components/TableRowStyle/RowColorsOnDifferentStatus';
import { DateInputImprovedCell } from '../../../components/TableInputs/DateInput';
import { renewOutletReducer } from './rowState/outletReducerRenew';
import { determineRecordStatus } from '../../../utils/tableDataFunctions';
import { EnterpriseData } from '../../../store/enterprises/enterprisesTypes';
import { OutletDataTableColumnsDisplayStatus } from '../tableState/initialStateAndTypes';
import { validatedRenewState } from './rowState/outletRowReducerFunctions';
import { useOutletsTableDispatch, useOutletsTableState } from '../Context';

interface RowContainerProps {
  enterprise: EnterpriseData;
  record: OutletData;
  virsLegalRegistrationDate: string;
  virsLegalDeregistrationDate: string | null;
  redirectButtonLabel?: string;
  redirectToOutletInfo?: () => void;
  toggleRowExtension?: () => void;
  isExtended?: boolean;
  rowExtensionComponent?: ReactNode;
  display: OutletDataTableColumnsDisplayStatus;
  showStatusNotSigned: boolean;
  resetColumnDisplay?: () => void;
}

const OutletRowContainerRenewable: React.FC<RowContainerProps> = ({
  enterprise,
  record,
  virsLegalRegistrationDate,
  virsLegalDeregistrationDate,
  redirectButtonLabel,
  toggleRowExtension,
  isExtended,
  redirectToOutletInfo,
  rowExtensionComponent,
  display,
  showStatusNotSigned,
  resetColumnDisplay
}) => {
  const [rowState, rowDispatch] = useReducer(renewOutletReducer, intialRenewOutletState);

  const {
    virsis: { virsData },
    outletData: { renewingOutlet }
  } = useSelector((stateGlobal: ApplicationState) => stateGlobal);

  const reduxDispatch = useDispatch();

  const { dispatch: tableDispatch } = useOutletsTableDispatch();

  const { state: tableState } = useOutletsTableState();

  const recordStatus = determineRecordStatus(record);

  function activateRenewEditing() {
    rowDispatch({
      type: 'START_RENEWING_CLICKED',
      outlet: record,
      enterpriseValidFrom: enterprise.validFrom,
      enterpriseValidTo: enterprise.validTo,
      registrationDate: virsLegalRegistrationDate,
      deregistrationDate: virsLegalDeregistrationDate
    });
    tableDispatch({ type: 'EDITING_ON' });
    if (resetColumnDisplay) {
      resetColumnDisplay();
    }
  }

  function deactivateRenewEditing() {
    rowDispatch({ type: 'CANCEL_RENEWING_CLICKED' });
    tableDispatch({ type: 'EDITING_OFF' });
    if (resetColumnDisplay) {
      resetColumnDisplay();
    }
  }

  function renewControlledFrom(controlledFrom: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'RENEW_FROM_CHANGED', controlledFrom });
  }

  function renewControlledTo(controlledTo: MaterialUiPickersDate | null) {
    rowDispatch({ type: 'RENEW_TO_CHANGED', controlledTo });
  }

  function renewOutletRecord() {
    rowDispatch({ type: 'RENEW_RECORD_CLICKED' });
    if (validatedRenewState(rowState).renewConfirmationOn) {
      const message = `Įrašas išsaugotas pagal anksčiau valdyto VIP duomenis. ${
        !showStatusNotSigned ? 'Įrašas matomas įjungus filtrą "Nepasirašyti"' : ''
      }`;
      const renewed: OutletRecord = {
        outletName: record.outletName,
        outletTypeId: record.outletTypeId,
        establishedDate: record.establishedDate,
        controlledFrom: rowState.controlledFrom.value
          ? rowState.controlledFrom.value.format('L')
          : null,
        controlledTo: rowState.controlledTo.value ? rowState.controlledTo.value.format('L') : null,
        isbn: record.isbn,
        issn: record.issn,
        enterpriseId: record.enterpriseId
      };
      if (virsData) {
        reduxDispatch(renewOutlet(virsData?.virsId, record.virOutletControlId, renewed, message));
      }
    }
  }

  return (
    <>
      <OutletBasicRow
        record={record}
        rowStatus={recordStatus}
        display={display}
        toggleExtension={toggleRowExtension}
        isExtended={isExtended}
        rowExtensionComponent={rowExtensionComponent}
        outletRowActions={
          <>
            {!rowState.showRenewRow && (
              <>
                {redirectToOutletInfo && (
                  <RowActionButtonGeneric
                    label={redirectButtonLabel || ''}
                    onButtonClicked={redirectToOutletInfo}
                  />
                )}
                {!record.outletRenewed && (
                  <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                    <RowActionButtonGeneric
                      id={`toggle-renew-record-button-${record.virOutletControlId}`}
                      label="Atnaujinti valdymą"
                      onButtonClicked={activateRenewEditing}
                      disabled={tableState.tableInputOn}
                    />
                  </AllowedTo>
                )}
              </>
            )}
          </>
        }
      />
      {rowState.showRenewRow && (
        <RowStyleOnDifferentStatus>
          {display.outletTypeName && <TableCell> </TableCell>}
          {display.outletName && <TableCell> </TableCell>}
          {display.outletGroupName && <TableCell />}
          {display.establishedDate && <TableCell> </TableCell>}
          {display.controlledFrom && (
            <DateInputImprovedCell
              state={rowState.controlledFrom}
              setDate={renewControlledFrom}
              isRequired
            />
          )}
          {display.controlledTo && (
            <DateInputImprovedCell
              state={rowState.controlledTo}
              setDate={renewControlledTo}
              isRequired
            />
          )}
          {display.internationalNumber && <TableCell />}
          <TableCell align="right">
            <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
              <SaveAndCancelActions
                handleConfirmationYes={renewOutletRecord}
                isProcessing={renewingOutlet}
                handleCancelButtonClick={deactivateRenewEditing}
              />
            </AllowedTo>
          </TableCell>
        </RowStyleOnDifferentStatus>
      )}
    </>
  );
};

export default OutletRowContainerRenewable;
