import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  FETCH_USER_MESSAGES_REQUEST,
  MARK_MESSAGES_READ_REQUEST,
  MARK_MESSAGES_UNREAD_REQUEST,
  REMOVE_MESSAGES_REQUEST
} from './userMessagesActionTypes';
import {
  fetchUserMessages,
  fetchUserMessagesError,
  fetchUserMessagesSuccess,
  removeUserMessages,
  removeUserMessageSuccess,
  removeUserMessageError,
  resetUserMessageRemovingState,
  markMessagesRead,
  markMessagesUnread,
  resetMarkMessagesReadState,
  markMessagesReadSuccess,
  markMessagesReadError,
  markMessagesUnreadSuccess,
  markMessagesUnreadError,
  resetMarkMessagesUnreadState
} from './userMessagesActions';
import {
  getUserMessages,
  deleteRemoveUserMessages,
  putMarkUserMessagesRead,
  putMarkUserMessagesUnread
} from './userMessagesApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';

function* handleUserMessagesFetchSaga() {
  try {
    const { data } = yield call(getUserMessages);
    yield put(fetchUserMessagesSuccess(data));
  } catch (err) {
    yield put(fetchUserMessagesError(err.response));
  }
}

export function* handleRemoveUserMessagesSaga(action: ActionType<typeof removeUserMessages>) {
  try {
    const messages = action.payload;
    yield call(deleteRemoveUserMessages, messages);
    yield put(removeUserMessageSuccess());
    yield put(fetchUserMessages());
    yield put(resetUserMessageRemovingState());
    yield put(sendMessage('success', 'Pašalinta'));
  } catch (err) {
    yield put(removeUserMessageError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleMarkMessagesReadSaga(action: ActionType<typeof markMessagesRead>) {
  try {
    const messages = action.payload;
    yield call(putMarkUserMessagesRead, messages);
    yield put(markMessagesReadSuccess());
    yield put(fetchUserMessages());
    yield put(resetMarkMessagesReadState());
    yield put(sendMessage('success', 'Pažymėta kaip perskaityta'));
  } catch (err) {
    yield put(markMessagesReadError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleMarkMessagesUnreadSaga(action: ActionType<typeof markMessagesUnread>) {
  try {
    const messages = action.payload;
    yield call(putMarkUserMessagesUnread, messages);
    yield put(markMessagesUnreadSuccess());
    yield put(fetchUserMessages());
    yield put(resetMarkMessagesUnreadState());
    yield put(sendMessage('success', 'Pažymėta kaip neperskaityta'));
  } catch (err) {
    yield put(markMessagesUnreadError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* userMessagesSaga() {
  yield takeEvery(FETCH_USER_MESSAGES_REQUEST, handleUserMessagesFetchSaga);
  yield takeEvery(REMOVE_MESSAGES_REQUEST, handleRemoveUserMessagesSaga);
  yield takeEvery(MARK_MESSAGES_READ_REQUEST, handleMarkMessagesReadSaga);
  yield takeEvery(MARK_MESSAGES_UNREAD_REQUEST, handleMarkMessagesUnreadSaga);
}

export default userMessagesSaga;
