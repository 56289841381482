import { Reducer } from 'redux';
import {
  FETCH_PROVIDED_DATA_VIRS_REQUEST,
  FETCH_PROVIDED_DATA_VIRS_SUCCESS,
  FETCH_PROVIDED_DATA_VIRS_ERROR,
  FETCH_PROVIDED_DATA_INST_SUCCESS,
  FETCH_PROVIDED_DATA_INST_ERROR,
  FETCH_PROVIDED_DATA_LEGAL_REQUEST,
  FETCH_PROVIDED_DATA_LEGAL_SUCCESS,
  FETCH_PROVIDED_DATA_LEGAL_ERROR,
  REMOVE_PROVIDED_DATA_SUCCESS,
  REMOVE_PROVIDED_DATA_ERROR,
  RESET_PROVIDED_DATA_REMOVE_STATE,
  ANNUL_PROVIDED_DATA_INST_REQUEST,
  ANNUL_PROVIDED_DATA_LEGAL_REQUEST,
  ANNUL_PROVIDED_DATA_ERROR,
  ANNUL_PROVIDED_DATA_SUCCESS,
  RESET_PROVIDED_DATA_ANNUL_STATE,
  ANNUL_PROVIDED_DATA_VIRS_REQUEST,
  FETCH_PROVIDED_DATA_INST_REQUEST,
  REMOVE_PROVIDED_DATA_REQUEST
} from './providedDataActionTypes';
import { ProvidedDataInst, ProvidedDataVirs } from './providedDataTypes';
import { ProvidedDataFilterOptions } from '../../containers/ProvidedDataTableInstLegal/tableState/tableInitialStateAndTypes';

export interface ProvidedDataState {
  loadingProvidedData: boolean;
  providedData?: ProvidedDataVirs[];
  providedDataError?: Error;
  loadingProvidedDataInst: boolean;
  providedDataInst?: ProvidedDataInst[];
  providedDataInstError?: Error;
  removingProvidedData: boolean;
  isProvidedDataRemoved: boolean;
  removingProvidedDataError?: Error;
  loadingProvidedDataLegal: boolean;
  providedDataLegal?: ProvidedDataInst[];
  providedDataLegalError?: Error;
  isProvidedDataRecordAnulled: boolean;
  annullingProvidedDataRecord: boolean;
  annullingProvidedDataRecordError?: Error;
  statusAnnuledFilterEnabled: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusSigneFilterEnabled: boolean;
  recordCount: number;
  instTableFilters?: ProvidedDataFilterOptions;
}

export const initialState: ProvidedDataState = {
  loadingProvidedData: false,
  providedData: undefined,
  providedDataError: undefined,
  loadingProvidedDataInst: false,
  providedDataInst: undefined,
  providedDataInstError: undefined,
  removingProvidedData: false,
  isProvidedDataRemoved: false,
  removingProvidedDataError: undefined,
  loadingProvidedDataLegal: false,
  providedDataLegal: undefined,
  providedDataLegalError: undefined,
  isProvidedDataRecordAnulled: false,
  annullingProvidedDataRecord: false,
  annullingProvidedDataRecordError: undefined,
  statusAnnuledFilterEnabled: false,
  statusNotSignedFilterEnabled: false,
  statusSigneFilterEnabled: false,
  recordCount: 0,
  instTableFilters: undefined
};

export const providedDataReducer: Reducer<ProvidedDataState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROVIDED_DATA_VIRS_REQUEST: {
      return { ...state, loadingProvidedData: true };
    }
    case FETCH_PROVIDED_DATA_VIRS_SUCCESS: {
      return {
        ...state,
        loadingProvidedData: false,
        providedData: action.payload.providedData
      };
    }
    case FETCH_PROVIDED_DATA_VIRS_ERROR: {
      return {
        ...state,
        loadingProvidedData: false,
        providedDataError: action?.payload?.data
      };
    }

    case FETCH_PROVIDED_DATA_INST_REQUEST:
      return { ...state, loadingProvidedDataInst: true };
    case FETCH_PROVIDED_DATA_INST_SUCCESS:
      return {
        ...state,
        loadingProvidedDataInst: false,
        statusAnnuledFilterEnabled: action.payload.statusAnnuledFilterEnabled,
        statusNotSignedFilterEnabled: action.payload.statusNotSignedFilterEnabled,
        statusSigneFilterEnabled: action.payload.statusSigneFilterEnabled,
        recordCount: action.payload.recordCount,
        providedDataInst: action.payload.providedData,
        instTableFilters: action.payload.filterValues
      };
    case FETCH_PROVIDED_DATA_INST_ERROR:
      return {
        ...state,
        loadingProvidedDataInst: false,
        providedDataInstError: action?.payload?.data
      };

    case FETCH_PROVIDED_DATA_LEGAL_REQUEST:
      return { ...state, loadingProvidedDataLegal: true };
    case FETCH_PROVIDED_DATA_LEGAL_SUCCESS:
      return {
        ...state,
        loadingProvidedDataLegal: false,
        providedDataLegal: action.payload.providedData
      };
    case FETCH_PROVIDED_DATA_LEGAL_ERROR:
      return {
        ...state,
        loadingProvidedDataLegal: false,
        providedDataLegalError: action.payload
      };
    case REMOVE_PROVIDED_DATA_REQUEST:
      return {
        ...state,
        removingProvidedData: true
      };
    case REMOVE_PROVIDED_DATA_SUCCESS:
      return {
        ...state,
        removingProvidedData: false,
        isProvidedDataRemoved: true
      };
    case REMOVE_PROVIDED_DATA_ERROR:
      return {
        ...state,
        removingProvidedData: false,
        isProvidedDataRemoved: false,
        removingProvidedDataError: action?.payload?.data
      };
    case RESET_PROVIDED_DATA_REMOVE_STATE:
      return {
        ...state,
        removingProvidedData: false,
        isProvidedDataRemoved: false,
        removingProvidedDataError: undefined
      };
    case ANNUL_PROVIDED_DATA_INST_REQUEST:
    case ANNUL_PROVIDED_DATA_VIRS_REQUEST:
    case ANNUL_PROVIDED_DATA_LEGAL_REQUEST:
      return {
        ...state,
        annullingProvidedDataRecord: true
      };
    case ANNUL_PROVIDED_DATA_ERROR:
      return {
        ...state,
        annullingProvidedDataRecord: false,
        isProvidedDataRecordAnulled: false,
        annullingProvidedDataRecordError: action?.payload?.data
      };
    case ANNUL_PROVIDED_DATA_SUCCESS:
      return {
        ...state,
        annullingProvidedDataRecord: false,
        isProvidedDataRecordAnulled: true
      };
    case RESET_PROVIDED_DATA_ANNUL_STATE:
      return {
        ...state,
        annullingProvidedDataRecord: false,
        isProvidedDataRecordAnulled: false,
        annullingProvidedDataRecordError: undefined
      };
    default: {
      return state;
    }
  }
};
