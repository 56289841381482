import React, { useContext, useState } from 'react';
import { Table, Paper, TableBody, Typography, Box, makeStyles } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import { ROWS_PER_PAGE_OPTIONS } from '../../../utils/tableTypes';
import { OutletRepresentativesTableHead } from './TableHead';
import OutletRepresentativesCustomizationBar from './CustomizationBar';
import { TablePagination } from '../../../components/TablePagination/TablePagination';
import { ApplicationState } from '../../../store';
import { pageTableData } from '../../../utils/tableDataFunctions';
import { RepresentativeRowContainerNew } from './TableRows/RowContainerNew';
import { RepresentativeRowContainer } from './TableRows/RowContainer';
import FilterRow from './FilterRow';
import { outletRepresentativesTableColumns } from '../tablesState/representativesTableTypes';
import { Roles } from '../../../store/virsis/dataTypes';
import AllowedTo from '../../AllowedTo';
import { AddNewRecordButton } from '../../../components/TableButtons/AddNewRecordButton';
import { FindByPersonDialogContainer } from '../../../components/FindPersonDialog/FindByPersonDialogContainer';
import { showAddRepresentativeRow } from '../../../store/outletInfoData/outletInfoDataActions';
import { TableCircularProgressRow } from '../../../components/TableCircularProgressRow/TableCircularProgressRow';
import { TableErrorRow } from '../../../components/TableErrorRow/TableErrorRow';
import { useOutletInfoTablesState, useOutletInfoTablesDispatch } from '../OutletInfoTablesContext';
import { filterAndSortRepresentativesTableData } from '../tablesState/tablesStateFunctions';
import PageActionButtons from '../../../components/PageButtons/PageActionButtons';
import { subsystemContext } from '../../../pages/virs/OutletInfoTablesPage';
import { exportTableData, printTableData } from '../../../utils/exporters/tableExporter';
import { getTableExportDefinition } from '../exportDefinitions/outletRepresentativesTableExportDefinition';
import { ConfirmRemoveUnsignedDialog } from '../../../components/Dialogs/ConfirmRemoveDialog/ConfirmRemoveUnsignedDialog';
import { OutletDocumentType } from '../../../store/unsignedDataRemoval/unsignedDataTypes';
import { unsignedDataRemoveOutlet } from '../../../store/unsignedDataRemoval/unsignedDataActions';
import { ScrollXContainer } from '../../../components/ScrollXContainer/ScrollXContainer';
import { OutletTableName } from '../tablesState/tablesTypesAndActions';
import { fetchVirsDataRequest } from '../../../store/virsis/actions';

interface Props {
  tableName: OutletTableName;
}
const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  primaryButtonWrapper: {
    [theme.breakpoints.down('xs')]: {
       marginBottom: '10px'
    }
  }
}));
export const OutletRepresentativesTable: React.FC<Props> = ({ tableName }) => {
  const {
    state: { representatives: representativesTableState }
  } = useOutletInfoTablesState();
  const { dispatch: tableDispatch } = useOutletInfoTablesDispatch();

  const classes = useStyles();

  const {
    virsis: { selectedPersonRepresentative },
    outletInfoData: { loadingOutletInfo, outletInfo, outletInfoError, representativeNewRecordRowOn }
  } = useSelector((state: ApplicationState) => state);
  const allData = outletInfo?.representatives || [];

  const reduxDispatch = useDispatch();
  const handleSetPage = (value: number) => {
    tableDispatch({ type: 'PAGE_SET', page: value, table: tableName });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    tableDispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage,
      table: tableName
    });
  };

  function showNewRepDialog() {
    tableDispatch({
      type: 'REPRESENTATIVES_NEW_REPRESENTATIVE_DIALOG_OPENED'
    });
  }

  function closeNewRepDialog() {
    tableDispatch({
      type: 'REPRESENTATIVES_NEW_REPRESENTATIVE_DIALOG_CLOSED'
    });
  }

  const data = filterAndSortRepresentativesTableData(allData, representativesTableState);
  const { page: pagedData, pagesCount } = pageTableData(representativesTableState, data);

  const subsystem = useContext(subsystemContext);

  const [confirmToDeleteUnsignedDialogOpen, setConfirmToDeleteUnsignedDialogOpen] = useState(false);

  return (
    <div className="named-data-table">
      <div className="table-title">
        <Typography variant="subtitle1">Už VIP turinį atsakingi asmenys</Typography>
        {outletInfo && (
          <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
            <Box className={classes.buttonWrapper}>
              <Box className={classes.primaryButtonWrapper}>
                <AddNewRecordButton
                  text="Pridėti už turinį atsakingą asmenį"
                  onClicked={showNewRepDialog}
                />
              </Box>
              <Box>
                <PageActionButtons
                  dontShowDelete={subsystem === 'LRTK' || subsystem === 'ZEIT'}
                  onDownload={(format, navigationPath) =>
                    exportTableData(
                      format,
                      getTableExportDefinition(representativesTableState),
                      data,
                      navigationPath
                    )
                  }
                  onPrint={(navigationPath) =>
                    printTableData(
                      getTableExportDefinition(representativesTableState),
                      data,
                      navigationPath
                    )
                  }
                  onClickDelete={() => setConfirmToDeleteUnsignedDialogOpen(true)}
                />

                <ConfirmRemoveUnsignedDialog
                  onClose={() => setConfirmToDeleteUnsignedDialogOpen(false)}
                  onConfirm={() =>
                    reduxDispatch(
                      unsignedDataRemoveOutlet(
                        outletInfo.outletId,
                        OutletDocumentType.REPRESENTATIVE
                      )
                    )
                  }
                  onRemoveSuccess={() => reduxDispatch(fetchVirsDataRequest())}
                  open={confirmToDeleteUnsignedDialogOpen}
                />
              </Box>
            </Box>
          </AllowedTo>
        )}
        <FindByPersonDialogContainer
          dialogOpen={representativesTableState.showNewRepDialog}
          closeDialog={closeNewRepDialog}
          closeAndContinueAction={() => reduxDispatch(showAddRepresentativeRow())}
        />
      </div>

      <Paper elevation={0}>
        <OutletRepresentativesCustomizationBar
          columnNames={outletRepresentativesTableColumns}
          tableName={tableName}
        />

        <ScrollXContainer>
          <Table>
            <OutletRepresentativesTableHead />

            {representativesTableState.customFilterOn && (
              <FilterRow allData={allData} columnNames={outletRepresentativesTableColumns} />
            )}

            <TableBody>
              <TableCircularProgressRow
                isLoading={loadingOutletInfo && !outletInfoError}
                colSpan={5}
              />

              <TableErrorRow
                error={outletInfoError && 'Klaida. Nepavyko gauti VIP duomenų'}
                colSpan={5}
              />

              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                selectedPersonRepresentative &&
                representativeNewRecordRowOn && (
                  <RepresentativeRowContainerNew
                    outletInfo={outletInfo}
                    columnsDisplay={representativesTableState.columnsDisplayStatus}
                  />
                )}
              {!loadingOutletInfo &&
                !outletInfoError &&
                outletInfo &&
                pagedData.map((record) => (
                  <RepresentativeRowContainer
                    record={record}
                    columnsDisplay={representativesTableState.columnsDisplayStatus}
                    outletInfo={outletInfo}
                    key={record.representativeId}
                  />
                ))}
            </TableBody>
          </Table>
        </ScrollXContainer>

        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={representativesTableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={representativesTableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </Paper>
    </div>
  );
};
