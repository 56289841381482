import React, { memo, useCallback } from 'react';
import { useZoomPanHelper } from 'react-flow-renderer';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import theme from '../../../style/virsisTheme';
import { ExpandTreeIcon } from '../SvgIconsAsComponents/ExpandTreeIcon';
import { CollapseTreeIcon } from '../SvgIconsAsComponents/CollapseTreeIcon';
import { FitView } from '../SvgIconsAsComponents/FitView';
import {
  arrayfromOneToTheNumber,
  maximumShareholderLevel,
  objectOfLevelsFullyShown
} from '../treeState/treeFunctions';
import { KeyNumberValueBoolean, StateShareholder } from '../treeState/treeTypes';

const useStyles = makeStyles({
  toolsBtn: {
    background: '#ffffff',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px 0',
    color: theme.palette.primary.main,
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: '#DBEDFC',
      cursor: 'pointer'
    }
  },
  container: {
    display: 'flex',
    paddingLeft: '40px',
    position: 'relative'
  },
  ctrls: {
    zIndex: 5,
    left: '10px',
    bottom: 'auto',
    top: '150px',
    boxShadow: 'none',
    position: 'absolute'
  }
});

export interface ControlProps extends React.HTMLAttributes<HTMLDivElement> {
  showZoom?: boolean;
  showFitView?: boolean;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onFitView?: () => void;
  expandTree: () => void;
  collapseTree: () => void;
  setLevel: (showLevel: number) => void;
  showingShareholders: StateShareholder[];
  allShareholders: StateShareholder[];
}

const TreeChartControls = ({
  showZoom = true,
  showFitView = true,
  expandTree,
  collapseTree,
  setLevel,
  showingShareholders,
  allShareholders
}: ControlProps) => {
  const { zoomIn, zoomOut, fitView } = useZoomPanHelper();

  const onZoomInHandler = useCallback(() => {
    zoomIn?.();
  }, [zoomIn]);

  const onZoomOutHandler = useCallback(() => {
    zoomOut?.();
  }, [zoomOut]);

  const onFitViewHandler = useCallback(() => {
    fitView?.();
  }, [fitView]);

  const expandTreeTreeHandler = () => {
    expandTree();
    setTimeout(() => onFitViewHandler(), 0);
  };

  const collapseTreeHandler = () => {
    collapseTree();
    setTimeout(() => onFitViewHandler(), 0);
  };

  const setLevelHandler = (lvl: number) => () => {
    setLevel(lvl);
    setTimeout(() => onFitViewHandler(), 0);
  };

  const classes = useStyles();

  const fullyShownLevels: KeyNumberValueBoolean = objectOfLevelsFullyShown(
    allShareholders,
    showingShareholders
  );

  const clickedStyleIfFullyShown = (lvl: number) =>
    fullyShownLevels[lvl] ? { backgroundColor: theme.palette.secondary.main } : undefined;

  const maxLevel: number = maximumShareholderLevel(allShareholders);

  const allShareholderLevels: number[] = arrayfromOneToTheNumber(maxLevel);

  return (
    <div className={classes.ctrls}>
      <>
        {showFitView && (
          <Tooltip title="Talpinti ekrane" placement="right" arrow>
            <Button className={classes.toolsBtn} onClick={onFitViewHandler}>
              <FitView />
            </Button>
          </Tooltip>
        )}
        {showZoom && (
          <>
            <Tooltip title="Padidinti" placement="right" arrow>
              <Button className={classes.toolsBtn} onClick={onZoomInHandler}>
                <ZoomInIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Sumažinti" placement="right" arrow>
              <Button className={classes.toolsBtn} onClick={onZoomOutHandler}>
                <ZoomOutIcon />
              </Button>
            </Tooltip>
          </>
        )}

        <Tooltip title="Išskleisti" placement="right" arrow>
          <Button className={classes.toolsBtn} onClick={expandTreeTreeHandler}>
            <ExpandTreeIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Suskleisti" placement="right" arrow>
          <Button className={classes.toolsBtn} onClick={collapseTreeHandler}>
            <CollapseTreeIcon />
          </Button>
        </Tooltip>
        {allShareholderLevels.map((level) => (
          <Tooltip key={level} title="Išskleisti iki nurodyto lygio" placement="right" arrow>
            <Button
              onClick={setLevelHandler(level)}
              className={classes.toolsBtn}
              style={clickedStyleIfFullyShown(level)}
            >
              {level}
            </Button>
          </Tooltip>
        ))}
      </>
    </div>
  );
};

TreeChartControls.displayName = 'Controls';

export default memo(TreeChartControls);
