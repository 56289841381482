import React, { MouseEvent } from 'react';
import {
  LegalFormType,
  ShareholderShareTypes
} from '../../../../../../../../store/classifiers/classifiersTypes';
import { useFormStyles } from './FormStyles';
import {
  ShareholdersData,
  ShareholderPerson
} from '../../../../../../../../store/shareholders/shareholdersTypes';
import { FormActions } from './FormActions';
import CreateShareholderLoadingContainer from '../../CreateShareholderLoadingContainer';

export interface Props {
  isProcessing: boolean;
  isSynchronizingJADIS: boolean;
  legalFormType: LegalFormType;
  onClose: () => void;
  onFormSubmit: (
    shareholderShareTypes: ShareholderShareTypes,
    selectedAnchorElement: HTMLButtonElement
  ) => void;
  parentShareholder: ShareholderPerson | ShareholdersData;
}

export const ParentLegalFormTypesForm: React.FC<Props> = ({
  isProcessing,
  isSynchronizingJADIS,
  legalFormType,
  onClose,
  onFormSubmit,
  parentShareholder
}) => {
  const classes = useFormStyles({ justifyContent: 'flex-end' });
  const { hasVotePercentage, hasVotesOnRules, shareTypeId } = legalFormType;

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    onFormSubmit(
      {
        shareTypeId,
        hasVotePercentage,
        hasVotesOnRules
      },
      event.currentTarget
    );
  };

  return (
    <form className={classes.shareTypesContainer}>
      {isSynchronizingJADIS || isProcessing ? (
        <CreateShareholderLoadingContainer isSynchronizingJADIS={isSynchronizingJADIS} />
      ) : (
        <FormActions
          onSubmit={handleSubmit}
          onClose={onClose}
          parentShareholder={parentShareholder}
        />
      )}
    </form>
  );
};
