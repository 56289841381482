import React, { useReducer, useContext, Dispatch, createContext } from 'react';
import InstitutionDataReportDataTable from './Table';
import {
  InstitutionDataReportTableAction,
  InstitutionDataReportTableState,
  initialInstitutionDataReportTableState
} from './tableState/initialTableStateAndTypes';
import { institutionDataReportTableReducer } from './tableState/tableReducer';

export const InstitutionDataReportTableStateContext = createContext<{
  state: InstitutionDataReportTableState;
}>({
  state: initialInstitutionDataReportTableState
});

export const InstitutionDataReportTableDispatchContext = createContext<{
  dispatch: Dispatch<InstitutionDataReportTableAction>;
}>({
  dispatch: () => {}
});

export const InstitutionDataReportTableWithContext: React.FC<object> = () => {
  const [state, dispatch] = useReducer(
    institutionDataReportTableReducer,
    initialInstitutionDataReportTableState
  );
  return (
    <InstitutionDataReportTableStateContext.Provider value={{ state }}>
      <InstitutionDataReportTableDispatchContext.Provider value={{ dispatch }}>
        <InstitutionDataReportDataTable />
      </InstitutionDataReportTableDispatchContext.Provider>
    </InstitutionDataReportTableStateContext.Provider>
  );
};

export function useInstitutionDataReportTableState() {
  return useContext(InstitutionDataReportTableStateContext);
}
export function useInstitutionDataReportTableDispatch() {
  return useContext(InstitutionDataReportTableDispatchContext);
}
