import { FormikProps } from 'formik';
import React from 'react';
import { FormikFieldStyleProps } from '../FieldStyles';
import { FormikFormDateField, getDateString } from '../FormikFormDateField';
import { useDialogFormStyles } from './DialogFormStyles';
import { maxDate as maxDateDefault, minDate as minDateDefault } from './validationSchemas';

interface Props<TData> extends FormikFieldStyleProps {
  formikProps: FormikProps<TData>;
  label?: string;
  field: keyof TData;
  isRequired?: boolean;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  className?: string;
  fieldClassName?: string;
}

export const GenericDateField = <TData,>({
  isRequired,
  field,
  label,
  formikProps,
  disabled,
  style,
  minDate,
  maxDate,
  className,
  fieldClassName
}: Props<TData>) => {
  const classes = useDialogFormStyles();
  return (
    <FormikFormDateField
      styledClasses={classes}
      values={formikProps.values}
      label={label}
      formikKey={`${field}`}
      date={formikProps.values[field]}
      errors={formikProps.errors[field]}
      setFieldValue={formikProps.setFieldValue}
      isRequired={!!isRequired}
      disabled={disabled}
      style={style}
      containerClassName={className}
      fieldClassName={fieldClassName}
      minDate={minDate || getDateString(minDateDefault.toDate()) || undefined}
      maxDate={maxDate || getDateString(maxDateDefault.toDate()) || undefined}
    />
  );
};

GenericDateField.defaultProps = {
  isRequired: false,
  disabled: false
};
