export interface EnterpriseType {
  enterpriseTypeId: number;
  enterpriseTypeCode: string;
  enterpriseTypeName: string;
  enterpriseTypeNameEn: string;
  vipRequired: boolean;
  validFrom: string;
  validTo: string;
}

export interface EnterpriseTypeInput {
  enterpriseTypeId: number | null;
  enterpriseTypeName: string | null;
  enterpriseTypeNameEn: string | null;
  vipRequired: boolean;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: EnterpriseTypeInput = {
  enterpriseTypeId: null,
  enterpriseTypeName: '',
  enterpriseTypeNameEn: '',
  vipRequired: false,
  validFrom: null,
  validTo: null
};
