import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  annulPaymentData,
  deleteRemovePayment,
  getPaymentDataByDocId,
  getPaymentDataPage,
  getPaymentDocument,
  getVirsPayments,
  postCreatePayment,
  putClosePayment,
  putUpdatePayment
} from './paymentsApi';
import {
  annulPayment,
  annulPaymentError,
  annulPaymentSuccess,
  closePayment,
  closePaymentError,
  closePaymentSuccess,
  createPayment,
  createPaymentError,
  createPaymentSuccess,
  getPaymentDataByDocIdRequest,
  getPaymentDataError,
  getPaymentDataRequest,
  getPaymentDataSuccess,
  getPaymentDocumentInNewTabRequest,
  getVirsPaymentDataError,
  getVirsPaymentDataRequest,
  getVirsPaymentDataSuccess,
  getVirsPaymentDocumentInNewTabRequest,
  removePayment,
  removePaymentError,
  removePaymentSuccess,
  resetAnnulPaymentState,
  resetClosingPaymentState,
  resetPaymentRemovingState,
  resetPaymentUpdatingState,
  updatePayment,
  updatePaymentError,
  updatePaymentSuccess
} from './paymentsActions';
import {
  ANNUL_PAYMENT_DATA_REQUEST,
  CLOSE_PAYMENT_REQUEST,
  CREATE_PAYMENT_REQUEST,
  PAYMENT_DATA_BY_DOC_ID_REQUEST,
  PAYMENT_DATA_REQUEST,
  PAYMENT_DOCUMENT_PDF_REQUEST,
  REMOVE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_REQUEST,
  VIRS_PAYMENT_DATA_REQUEST,
  VIRS_PAYMENT_DOCUMENT_PDF_REQUEST
} from './paymentsActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  getPaymentsPageRequest,
  selectPaymentsTableDataState
} from '../paymentsTable/paymentsTableUtilities';
import { PaymentsDataTableState } from '../paymentsTable/paymentsTableReducer';
import { updateTempDataState } from '../paymentsTable/paymentsTableActions';

function* handlePaymenDataRequestSaga() {
  try {
    const { tableDataState }: PaymentsDataTableState = yield select(selectPaymentsTableDataState);
    yield put(updateTempDataState(tableDataState));
    const { data } = yield call(getPaymentDataPage, getPaymentsPageRequest(tableDataState));
    yield put(getPaymentDataSuccess(data));
  } catch (err) {
    yield put(getPaymentDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handlePaymentDocumentRequestSaga(
  action: ActionType<typeof getPaymentDocumentInNewTabRequest>
) {
  try {
    const { paymentDocumentId, documentNumber } = action.payload;
    const { data } = yield call(getPaymentDocument, paymentDocumentId);
    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]), `${documentNumber}.pdf`);
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleCreatePaymentSaga(action: ActionType<typeof createPayment>) {
  try {
    const { formRecord } = action.payload;
    const { data } = yield call(postCreatePayment, formRecord);
    yield put(createPaymentSuccess(data));
    yield put(getPaymentDataRequest());
    yield put(sendMessage('success', 'Metinės įmokos įrašas išsaugotas'));
  } catch (err) {
    yield put(createPaymentError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleUpdatePaymentSaga(action: ActionType<typeof updatePayment>) {
  try {
    const { formRecordUpdated, paymentId } = action.payload;
    const { data } = yield call(putUpdatePayment, formRecordUpdated, paymentId);
    yield put(updatePaymentSuccess(data));
    yield put(resetPaymentUpdatingState());
    yield put(getPaymentDataRequest());
    yield put(sendMessage('success', 'Metinės įmokos įrašas atnaujintas'));
  } catch (err) {
    yield put(updatePaymentError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

export function* handleRemovePaymentSaga(action: ActionType<typeof removePayment>) {
  try {
    const { paymentId } = action.payload;
    yield call(deleteRemovePayment, paymentId);
    yield put(removePaymentSuccess());
    yield put(resetPaymentRemovingState());
    yield put(getPaymentDataRequest());
    yield put(sendMessage('success', 'Metinės įmokos įrašas pašalintas'));
  } catch (err) {
    yield put(removePaymentError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleVirsPaymenDataRequestSaga(action: ActionType<typeof getVirsPaymentDataRequest>) {
  try {
    const virsId = action.payload;
    const { data } = yield call(getVirsPayments, virsId);
    yield put(getVirsPaymentDataSuccess(data));
  } catch (err) {
    yield put(getVirsPaymentDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleVirsPaymentDocumentRequestSaga(
  action: ActionType<typeof getVirsPaymentDocumentInNewTabRequest>
) {
  try {
    const { paymentDocumentId, documentNumber } = action.payload;
    const { data } = yield call(getPaymentDocument, paymentDocumentId);
    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]), `${documentNumber}.pdf`);
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response.data.message));
    // TODO globalus pranesimas kai gaunama klaida
  }
}

export function* handleAnnulPaymentSaga(action: ActionType<typeof annulPayment>) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulPaymentData, documentStatusId, annulRecord);
    yield put(annulPaymentSuccess());
    yield put(resetAnnulPaymentState());
    yield put(getPaymentDataRequest());
    yield put(sendMessage('success', 'Metinės įmokos įrašas anuliuotas'));
  } catch (err) {
    yield put(annulPaymentError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleClosePaymentSaga(action: ActionType<typeof closePayment>) {
  try {
    const { paymentId, validTo } = action.payload;
    const { data } = yield call(putClosePayment, paymentId, validTo);
    yield put(closePaymentSuccess(data));
    yield put(resetClosingPaymentState());
    yield put(getPaymentDataRequest());
    yield put(sendMessage('success', 'Metinės įmokos įrašas uždarytas'));
  } catch (err) {
    yield put(closePaymentError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* handleGetPaymentDataByDocIdSaga(action: ActionType<typeof getPaymentDataByDocIdRequest>) {
  try {
    const { data } = yield call(getPaymentDataByDocId, action.payload);
    yield put(getPaymentDataSuccess(data));
  } catch (err) {
    yield put(getPaymentDataError(err.response));
    yield put(sendMessage('error', err.response.data.message));
  }
}

function* paymentsSaga() {
  yield takeLatest(PAYMENT_DATA_REQUEST, handlePaymenDataRequestSaga);
  yield takeEvery(PAYMENT_DOCUMENT_PDF_REQUEST, handlePaymentDocumentRequestSaga);
  yield takeEvery(CREATE_PAYMENT_REQUEST, handleCreatePaymentSaga);
  yield takeEvery(UPDATE_PAYMENT_REQUEST, handleUpdatePaymentSaga);
  yield takeEvery(REMOVE_PAYMENT_REQUEST, handleRemovePaymentSaga);
  yield takeEvery(VIRS_PAYMENT_DATA_REQUEST, handleVirsPaymenDataRequestSaga);
  yield takeEvery(VIRS_PAYMENT_DOCUMENT_PDF_REQUEST, handleVirsPaymentDocumentRequestSaga);
  yield takeEvery(ANNUL_PAYMENT_DATA_REQUEST, handleAnnulPaymentSaga);
  yield takeEvery(CLOSE_PAYMENT_REQUEST, handleClosePaymentSaga);
  yield takeEvery(PAYMENT_DATA_BY_DOC_ID_REQUEST, handleGetPaymentDataByDocIdSaga);
}

export default paymentsSaga;
