import React from 'react';
import { Box, Button } from '@material-ui/core';
import virsisLogo from '../assets/virsis_logo.png';

const HomePage: React.FC = () => {
  const devEnvironment = false;
  //const devEnvironment = !!process.env.REACT_APP_DEV;
  return (
    <div className="HomePage">
      <Box maxWidth="sm">
        <Box position="center" textAlign="center">
          <div className="logo">
            <div className={'logo__wrapper'}>
              <img src={virsisLogo} />
            </div>
          </div>
          {!devEnvironment && (
            <Button variant="contained" href="/">
              Pradžia
            </Button>
          )}
          <Button variant="contained" href={devEnvironment ? 'https://ipasdev.registrucentras.lt/?app=virsis_dev' : '/web'}>
            Prisijungti
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;
