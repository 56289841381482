import React from 'react';
import { Field, getIn } from 'formik';
import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import { FormikFieldStyleProps } from '../../../../components/FormikFields/FieldStyles';

const useStyles = makeStyles((theme) => ({
  textField: {
    maxWidth: '320px',
    margin: '6px 0'
  },
  label: {
    width: '185px'
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      maxWidth: 'unset',
      width: '100%'
    },
    label: {
      width: '100%'
    }
  }
}));

export const ArrayTextField = (Props: any) => {
  const {
    field,
    form: { errors },
    props: { disabled }
  } = Props;

  const classes = useStyles();
  const errorMessage = getIn(errors, field.name);
  return (
    <>
      <TextField
        disabled={disabled}
        {...field}
        variant="outlined"
        className={classes.textField}
        helperText={errorMessage}
        error={!!errorMessage}
      />
    </>
  );
};

interface Props extends FormikFieldStyleProps {
  arrayName: string;
  index: number;
  field: string;
  label?: string;
  styledClasses: Record<string, string>;
  isActive: boolean;
}

export const FormikArrayTextField: React.FC<Props> = ({
  arrayName,
  index,
  field,
  label,
  styledClasses,
  style,
  isActive
}) => {
  const classes = useStyles();
  return (
    <Box
      className={styledClasses.inputContainer}
      style={{ ...style?.container, gridTemplateColumns: '185px 320px 30px' }}
    >
      <Typography
        variant="h5"
        className={
          (isActive ? styledClasses.inputLabel : styledClasses.inactiveInputLabel) +
          ' ' +
          classes.label
        }
      >
        {label}
      </Typography>
      <Field
        name={`${arrayName}[${index}].${field}`}
        component={ArrayTextField}
        props={{
          disabled: !isActive
        }}
      />
    </Box>
  );
};
