import React, { useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  OutletAdPrinting,
  OutletAdRecord,
  OutletInfo
} from '../../../../store/outletInfoData/outletInfoDataTypes';
import { AdBasicRow } from './RowBasic';
import { ApplicationState } from '../../../../store';
import {
  updateAdvertisement,
  resetAdvertisementRemovingState,
  removeAdvertisement
} from '../../../../store/outletInfoData/outletInfoDataActions';
import { SaveAndCancelActions } from '../../../../components/TableRowActions/SaveAndCancelActions';
import { EditAndRemoveActions } from '../../../../components/TableRowActions/EditAndRemoveActions';
import AllowedTo from '../../../AllowedTo';
import { Roles } from '../../../../store/virsis/dataTypes';
import { DateInputImprovedCell } from '../../../../components/TableInputs/DateInput';
import { AdColumnsDisplayStatus } from '../../tablesState/adPrintingTableTypes';
import { validateAdPrintingEditRecordState } from './rowState/rowReducerFunctions';
import { CustomEditIconButton } from '../../../../components/Icons/IconButtons/CustomEditIconButton';
import { RowActionButtonGeneric } from '../../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { validateMandatoryDate } from '../../../../utils/InputValueFunctions';
import { SaveAndCancelIcons } from '../../../../components/TableRowActions/SaveAndCancelIcons';
import { determineRecordStatus } from '../../../../utils/tableDataFunctions';
import { adPrintingRowReducer } from './rowState/adPrintingRowReducer';
import { initialAdPrintingRowState } from './rowState/rowInitialStateAndTypes';
import { RowActionButton } from '../../../../components/TableRowActions/RowActionButton';

interface Props {
  record: OutletAdPrinting;
  outletInfo: OutletInfo;
  columnsDisplay: AdColumnsDisplayStatus;
}

export const AdDataRowContainer: React.FC<Props> = ({ record, outletInfo, columnsDisplay }) => {
  const [rowState, rowDispatch] = useReducer(adPrintingRowReducer, initialAdPrintingRowState);

  const {
    outletInfoData: {
      updatingAdvertisement,
      updatingAdvertisementId,
      removingAdvertisement,
      advertisementRemoved,
      removingAdvertisementError
    },
    virsis: { virsData, currentUser }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  const turnOnEditing = () => {
    rowDispatch({
      type: 'EDITING_INITIALIZED',
      record,
      outletControlledFrom: outletInfo.controlledFrom,
      outletControlledTo: outletInfo.controlledTo || null
    });
  };

  const setValidFromDate = (validFrom: MaterialUiPickersDate | null) => {
    rowDispatch({ type: 'AD_PRINTING_FROM_DATE_CHANGED', validFrom });
  };

  const setValidToDate = (validTo: MaterialUiPickersDate | null) => {
    rowDispatch({ type: 'AD_PRINTING_TO_DATE_CHANGED', validTo });
  };

  const cancelEditing = () => {
    rowDispatch({ type: 'EDITING_CANCELLED' });
  };

  const updateAdPrintingRecord = () => {
    if (outletInfo.outletId) {
      rowDispatch({ type: 'UPDATE_RECORD_CLICKED' });
      if (validateAdPrintingEditRecordState(rowState).editStateValidated) {
        const updatedAdvertisement: OutletAdRecord = {
          validFrom: rowState.validFrom.value
            ? rowState.validFrom.value.format('L').toString()
            : null,
          validTo: rowState.validTo.value ? rowState.validTo.value.format('L').toString() : null
        };
        reduxDispatch(
          updateAdvertisement(outletInfo.outletId, record.advertisementId, updatedAdvertisement)
        );
      }
    }
  };

  const updateValidToDate = () => {
    if (validateMandatoryDate(rowState.validTo).validated) {
      const updatedAdvertisement: OutletAdRecord = {
        validFrom: record.validFrom,
        validTo: rowState.validTo.value?.format('L').toString() || null
      };
      reduxDispatch(
        updateAdvertisement(outletInfo.outletId, record.advertisementId, updatedAdvertisement)
      );
    }
  };

  const unsetValidToDate = () => {
    const updatedAdvertisement: OutletAdRecord = {
      validFrom: record.validFrom,
      validTo: null
    };
    reduxDispatch(
      updateAdvertisement(outletInfo.outletId, record.advertisementId, updatedAdvertisement)
    );
  };

  const onRemoveRecordClicked = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CLICKED' });
  };

  const removeRecord = () => {
    if (virsData && currentUser) {
      reduxDispatch(
        removeAdvertisement(outletInfo.outletId, record.advertisementId, virsData.virsId)
      );
    }
  };

  const closeRemoveConfirmation = () => {
    rowDispatch({ type: 'REMOVE_RECORD_CANCELLED' });
  };

  const closeOnRemoveError = () => {
    reduxDispatch(resetAdvertisementRemovingState());
  };

  const isOnlyClosableRecord =
    record.relevancyStatus === 'A' &&
    (record.confirmationStatus === 'P' || record.confirmationStatus === 'U');
  const isFullyEditableRecord =
    record.relevancyStatus === null &&
    (record.confirmationStatus === 'N' || record.confirmationStatus === 'U');
  const isUpdatingThisRecord =
    updatingAdvertisement && updatingAdvertisementId === record.advertisementId;
  return (
    <>
      <AdBasicRow
        id={`${record.advertisementId}-${record.documentStatusId}`}
        rowStatus={determineRecordStatus(record)}
        columnsToDisplay={columnsDisplay}
        validFrom={record.validFrom}
        validFromInput={
          rowState.editingOn &&
          isFullyEditableRecord && (
            <DateInputImprovedCell
              state={rowState.validFrom}
              setDate={setValidFromDate}
              isRequired
            />
          )
        }
        validToInput={
          <>
            {rowState.editingOn && isFullyEditableRecord && (
              <DateInputImprovedCell state={rowState.validTo} setDate={setValidToDate} />
            )}
            {rowState.editingOn && isOnlyClosableRecord && (
              <DateInputImprovedCell
                state={rowState.validTo}
                setDate={setValidToDate}
                isRequired
                helperItem={
                  <SaveAndCancelIcons
                    handleConfirmationYes={updateValidToDate}
                    isProcessing={updatingAdvertisement}
                    handleCancelButtonClick={cancelEditing}
                  />
                }
              />
            )}
            {!rowState.editingOn && (
              <TableCell
                style={{
                  boxShadow: record.validTo && isOnlyClosableRecord ? '8px 0 0 #50C9F3 inset' : ''
                }}
              >
                {!record.validTo && (
                  <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                    <CustomEditIconButton onClick={turnOnEditing} />
                  </AllowedTo>
                )}
                {record.validTo}
              </TableCell>
            )}
          </>
        }
        actions={
          rowState.editingOn ? (
            <>
              {isFullyEditableRecord && (
                <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                  <SaveAndCancelActions
                    handleConfirmationYes={updateAdPrintingRecord}
                    isProcessing={isUpdatingThisRecord}
                    handleCancelButtonClick={cancelEditing}
                  />
                </AllowedTo>
              )}
            </>
          ) : (
            <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
              <>
                {isFullyEditableRecord && (
                  <EditAndRemoveActions
                    handleEditButtonClick={turnOnEditing}
                    handleRemoveButtonClick={onRemoveRecordClicked}
                    confirmationOn={rowState.removeConfirmationOn}
                    handleConfirmationYes={removeRecord}
                    handleConfirmationNo={closeRemoveConfirmation}
                    isProcessing={removingAdvertisement}
                    isSuccess={advertisementRemoved}
                    error={removingAdvertisementError}
                    onErrorClose={closeOnRemoveError}
                  />
                )}
                {isOnlyClosableRecord && !record.validTo && (
                  <RowActionButtonGeneric
                    label="Įvesti pabaigos datą"
                    onButtonClicked={turnOnEditing}
                  />
                )}
                {isOnlyClosableRecord && record.validTo && (
                  <RowActionButton
                    label="Išvalyti"
                    onButtonClick={unsetValidToDate}
                    isProcessing={isUpdatingThisRecord}
                  />
                )}
              </>
            </AllowedTo>
          )
        }
      />
    </>
  );
};
