import React from 'react';

import { TableToolsFrame } from '../TableToolsFrame/TableToolsFrame';
import { PaginationInfo } from './PaginationInfo';
import { PaginationIteratePage } from './PaginationIteratePage';
import { PaginationSelectRowsPerPage } from './PaginationSelectRowsPerPage';
import { PaginationJumpPage } from './PaginationJumpPage';
import { RowsPerPageOptions } from '../../utils/tableTypes';

interface Props {
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: RowsPerPageOptions;
  pagesCount: number;
  recordsCount: number;
  setPage: (value: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  disabled?: boolean;
}

export const TablePagination: React.FC<Props> = ({
  page,
  rowsPerPage,
  rowsPerPageOptions,
  pagesCount,
  recordsCount,
  setPage,
  setRowsPerPage,
  disabled
}) => {
  function previousPage() {
    if (page) {
      setPage(page - 1);
    }
  }

  function nextPage() {
    if (pagesCount > page + 1) {
      setPage(page + 1);
    }
  }

  return (
    <div className="TablePagination">
      <TableToolsFrame
        leftSideItem={
          <>
            <PaginationInfo recordsCount={recordsCount} />
            <PaginationSelectRowsPerPage
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              setRowsPerPage={setRowsPerPage}
              disabled={disabled}
            />
          </>
        }
        rightSideItem={
          <>
            <PaginationIteratePage
              currentPage={page}
              previousPage={previousPage}
              nextPage={nextPage}
              pagesCount={pagesCount}
            />
            <PaginationJumpPage
              currentPage={page}
              setPage={setPage}
              pagesCount={pagesCount}
              disabled={disabled}
            />
          </>
        }
      />
    </div>
  );
};
