import {
  EditionCheckNewRecordState,
  EditionCheckEditRecordState,
  EditionCheckRecordState
} from './initialStateAndTypes';
import {
  DatePickerState,
  InputStateGeneric,
  VirsisFile,
  VirsisFilesState
} from '../../../../utils/tableTypes';
import {
  EditionCheckDocs,
  EditionCheckRecord
} from '../../../../store/editionChecks/editionChecksTypes';
import {
  validateDocumentNumber,
  validateMandatoryDate
} from '../../../../utils/InputValueFunctions';
import { OutletShortData } from '../../../../store/outlets/outletsTypes';

export const setCustomNameByExistingOrNull = (
  documents: EditionCheckDocs[],
  state: InputStateGeneric<string>
): InputStateGeneric<string> => {
  if (!documents.length) {
    return state;
  }
  return { ...state, value: documents[0].docNr, validated: true };
};

function validateEditionCheckFiles(state: EditionCheckRecordState): VirsisFilesState {
  const atLeastOneDoc: boolean =
    state.addedDocuments.files.length + state.editionCheckDocs.length > 0;
  return {
    ...state.addedDocuments,
    error: !atLeastOneDoc,
    validated: atLeastOneDoc,
    errorMessage: !atLeastOneDoc ? 'Būtinas bent 1 dokumentas' : ''
  };
}

export const validateEditionCheckNewRecordState = (
  state: EditionCheckNewRecordState
): EditionCheckNewRecordState => {
  const checkDateValidated: DatePickerState = validateMandatoryDate(state.checkDate);
  const validatedCustomDocumentsName: InputStateGeneric<string> = validateDocumentNumber(
    state.customDocumentsName
  );
  const validateAddedDocuments: VirsisFilesState = validateEditionCheckFiles(state);
  const tempState: EditionCheckNewRecordState = {
    ...state,
    checkDate: checkDateValidated,
    customDocumentsName: validatedCustomDocumentsName,
    addedDocuments: validateAddedDocuments,
    editionCheckOutlets: state.editionCheckOutlets.map((vip) => ({
      ...vip,
      outlet: {
        ...vip.outlet,
        error: !vip.outlet.value,
        helperText: !vip.outlet.value ? 'Būtina VIP' : ''
      },
      editionCheckConclusions: vip.editionCheckConclusions.map((conclusion, conclusionIndex) => {
        const samePeriodError = vip.editionCheckConclusions.some(
          (conclusionInside, conclusionInsideIndex) =>
            conclusionInsideIndex !== conclusionIndex &&
            conclusionInside.period.value &&
            conclusion.period.value
              ? conclusionInside.period.value.periodId === conclusion.period.value.periodId
              : false
        );

        return {
          ...conclusion,
          period: {
            ...conclusion.period,
            error: !conclusion.period.value || samePeriodError,
            helperText: samePeriodError
              ? 'Negalima išsaugoti dviejų išvadų tam pačiam laikotarpiui. Pasirinkite skirtingus tiražo laikotarpius'
              : ''
          },
          samePeriodError,
          conclusion: {
            ...conclusion.conclusion,
            error: !conclusion.conclusion.value
          }
        };
      })
    }))
  };
  const emptyPeriodOrConclusionFieldOrDuplicatePeriod: boolean[] = [];
  tempState.editionCheckOutlets.forEach((vip) => {
    vip.editionCheckConclusions.forEach((conclusion) => {
      emptyPeriodOrConclusionFieldOrDuplicatePeriod.push(
        conclusion.conclusion.error || conclusion.period.error || conclusion.samePeriodError
      );
    });
  });
  return {
    ...tempState,
    createRecordConfirmationOn:
      validateAddedDocuments.validated &&
      checkDateValidated.validated &&
      validatedCustomDocumentsName.validated &&
      !tempState.editionCheckOutlets.some((vip) => vip.outlet.error) &&
      !emptyPeriodOrConclusionFieldOrDuplicatePeriod.some((value) => value === true)
  };
};

export const validateEditionCheckEditRecordState = (
  state: EditionCheckEditRecordState
): EditionCheckEditRecordState => {
  const checkDateValidated: DatePickerState = validateMandatoryDate(state.checkDate);
  const validatedCustomDocumentsName: InputStateGeneric<string> = validateDocumentNumber(
    state.customDocumentsName
  );

  const validateAddedDocuments: VirsisFilesState = validateEditionCheckFiles(state);
  const tempState = {
    ...state,
    checkDate: checkDateValidated,
    customDocumentsName: validatedCustomDocumentsName,
    addedDocuments: validateAddedDocuments,
    editionCheckOutlets: state.editionCheckOutlets.map((vip) => ({
      ...vip,
      error: !vip.outlet.value,
      editionCheckConclusions: vip.editionCheckConclusions.map((conclusion, conclusionIndex) => {
        const samePeriodError = vip.editionCheckConclusions.some(
          (conclusionInside, conclusionInsideIndex) =>
            conclusionInsideIndex !== conclusionIndex &&
            conclusionInside.period.value &&
            conclusion.period.value
              ? conclusionInside.period.value.periodId === conclusion.period.value.periodId
              : false
        );

        return {
          ...conclusion,
          period: {
            ...conclusion.period,
            error: !conclusion.period.value || samePeriodError,
            helperText: samePeriodError
              ? 'Negalima išsaugoti dviejų išvadų tam pačiam laikotarpiui. Pasirinkite skirtingus tiražo laikotarpius'
              : ''
          },
          samePeriodError,
          conclusion: {
            ...conclusion.conclusion,
            error: !conclusion.conclusion.value
          }
        };
      })
    }))
  };
  const emptyPeriodOrConclusionFieldOrDuplicatePeriod: boolean[] = [];
  tempState.editionCheckOutlets.forEach((vip) => {
    vip.editionCheckConclusions.forEach((conclusion) => {
      emptyPeriodOrConclusionFieldOrDuplicatePeriod.push(
        conclusion.conclusion.error || conclusion.period.error || conclusion.samePeriodError
      );
    });
  });
  return {
    ...tempState,
    updateRecordConfirmationOn:
      validateAddedDocuments.validated &&
      checkDateValidated.validated &&
      validatedCustomDocumentsName.validated &&
      !tempState.editionCheckOutlets.some((vip) => vip.error) &&
      !emptyPeriodOrConclusionFieldOrDuplicatePeriod.some((value) => value === true)
  };
};

export const addFileAndValidateDocuments = (
  file: File,
  state: VirsisFilesState,
  documentsCount: number
) => {
  const filesCountWithinLimit: boolean =
    state.files.length + documentsCount + 1 <= state.filesLimit;
  const allFilesValid: boolean =
    state.files.every((f) => f.size <= state.sizeLimit) &&
    file.size <= state.sizeLimit &&
    filesCountWithinLimit;
  return {
    ...state,
    files: [
      ...state.files,
      {
        file,
        title: file.name,
        size: file.size,
        id: state.files.length + 1
      }
    ],
    error: !allFilesValid,
    validated: allFilesValid,
    errorMessage: !filesCountWithinLimit ? 'Daugiausia 5 dokumentai' : ''
  };
};

export const removeFileAndValidateDocuments = (
  fileId: number,
  state: VirsisFilesState,
  documentsCount: number
) => {
  const filesUpdated = state.files.filter((file) => file.id !== fileId);
  const filesCountWithinLimit: boolean = filesUpdated.length + documentsCount <= state.filesLimit;
  const allDocumentsValid: boolean =
    filesUpdated.every((f) => f.size <= state.sizeLimit) && filesCountWithinLimit;
  return {
    ...state,
    files: filesUpdated,
    error: !allDocumentsValid,
    validated: allDocumentsValid,
    errorMessage: !filesCountWithinLimit ? 'Daugiausia 5 dokumentai' : ''
  };
};

export const removeExistingDocumentAndValidateDocuments = (
  documentId: number,
  state: EditionCheckEditRecordState
): EditionCheckEditRecordState => {
  const withoutDocument = state.editionCheckDocs.filter(
    (doc) => doc.editionCheckDocId !== documentId
  );
  const renamedWithoutDocument = withoutDocument.map((doc, docIndex) => ({
    ...doc,
    docNr:
      state.customDocumentsName.value && docIndex === 0
        ? state.customDocumentsName.value
        : `${state.customDocumentsName.value}-${docIndex + 1}`
  }));
  const filesCountWithinLimit: boolean =
    renamedWithoutDocument.length + state.addedDocuments.files.length <=
    state.addedDocuments.filesLimit;
  const allDocumentsValid: boolean =
    state.addedDocuments.files.every((f) => f.size <= state.addedDocuments.sizeLimit) &&
    filesCountWithinLimit;
  return {
    ...state,
    editionCheckDocs: renamedWithoutDocument,
    addedDocuments: {
      ...state.addedDocuments,
      error: !allDocumentsValid,
      validated: allDocumentsValid,
      errorMessage: !filesCountWithinLimit ? 'Daugiausia 5 dokumentai' : ''
    }
  };
};

export const joinEditionCheckIntoFormData = (
  data: EditionCheckRecord,
  virsisFiles: VirsisFile[],
  customName?: string
): FormData => {
  const form = new FormData();
  const generateName = (indexNo: number, name: string) =>
    indexNo === 0 ? name : `${name}-${indexNo + 1}`;
  virsisFiles.forEach((virsisFile, index) => {
    form.append(
      'files',
      virsisFile.file,
      customName ? generateName(index + data.editionCheckDocuments.length, customName) : undefined
    );
  });
  if (data) {
    form.append('record', JSON.stringify(data));
  }
  return form;
};

export function getUpdatedOutletOptions(state: EditionCheckRecordState): OutletShortData[] {
  const selectedOutletIds: number[] = [];
  state.editionCheckOutlets.forEach((outletInput) => {
    if (outletInput.outlet.value) {
      selectedOutletIds.push(outletInput.outlet.value.outletId);
    }
  });

  return state.virs.value
    ? [...state.virs.value.outlets].filter((outlet) => !selectedOutletIds.includes(outlet.outletId))
    : [];
}
