import React from 'react';
import { UnisignContainerBase } from './UnisignContainerBase';

export type UnisignProps = {
  onClose: () => void;
  documentId: number;
  onSignSuccess: () => void;
  unsignedPdfPath: string;
};

export const UnisignContainer: React.FC<UnisignProps> = (props) => {
  return <UnisignContainerBase {...props} />;
};
