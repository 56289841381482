import { Moment } from 'moment';
import { Action, createReducer } from 'typesafe-actions';

import {
  setVisiblePeriod,
  setCursorDate,
  setGroupingDistance,
  setContainerWidth,
  setActiveDate,
  setPeriods
} from './actions';
import { TimelinePeriod } from './types';
import {
  PeriodName,
  TimelineEvent
} from '../../../../store/shareholdersVirsTree/virsTreeDataTypes';

export interface State {
  visiblePeriod?: TimelinePeriod;
  cursorDate?: Moment;
  periods?: TimelinePeriod[];
  containerWidth?: number;
  groupingDistance?: number;
  periodName: PeriodName;
  activeDate: Moment;
  timelineEvents: TimelineEvent[];
  error: string | undefined;
  isLoading: boolean;
}

export function getReducer(
  periodName: PeriodName,
  activeDate: Moment,
  timelineEvents: TimelineEvent[],
  isLoading: boolean,
  error: string | undefined,
  onChange: (activeDate: Moment) => void
) {
  return createReducer<State, Action>({
    periodName,
    activeDate,
    timelineEvents,
    error,
    isLoading
  })
    .handleAction(setVisiblePeriod, (state, action) => ({
      ...state,
      visiblePeriod: action.payload.visiblePeriod
    }))
    .handleAction(setCursorDate, (state, action) => ({
      ...state,
      cursorDate: action.payload.cursorDate
    }))
    .handleAction(setGroupingDistance, (state, action) => ({
      ...state,
      groupingDistance: action.payload.distance
    }))
    .handleAction(setActiveDate, (state, action) => {
      onChange(action.payload.activeDate);
      return { ...state };
    })
    .handleAction(setContainerWidth, (state, action) => ({
      ...state,
      containerWidth: action.payload.containerWidth
    }))
    .handleAction(setPeriods, (state, action) => ({
      ...state,
      periods: action.payload.periods
    }));
}
