export const getEventWidth = (
  endDate: string | null,
  startDate: string,
  date: string,
  allEventsNumber: number,
  startWithTriangle: boolean,
  index: number,
  eventDate?: string
) => {
  if (allEventsNumber === 1) {
    if (startDate === date && startWithTriangle) {
      return 'calc(90% + 1px)';
    }
    return 'calc(100% + 1px)';
  }
  if (allEventsNumber > 1 && index === allEventsNumber - 1) {
    return `calc(50% + 1px)`;
  }
  if (startDate === date && startWithTriangle) {
    return `${(100 - 10 - 50) / (allEventsNumber - 1)}%`;
  }
  return `${(100 - 50) / (allEventsNumber - 1)}%`;
};

export const getLeftParameter = (
  startDate: string,
  date: string,
  allEventsNumber: number,
  index: number,
  startWithTriangle: boolean,
  eventDate?: string
) => {
  if (allEventsNumber > 1 && index === allEventsNumber - 1) {
    return `50%`;
  }
  if (startDate === date && startWithTriangle && allEventsNumber > 1) {
    return `${10 + ((90 - 50) / (allEventsNumber - 1)) * index + 1}%`;
  }
  if (startDate === date && startWithTriangle && allEventsNumber === 1) {
    return `10%`;
  }
  if (((startDate === date && !startWithTriangle) || eventDate === date) && allEventsNumber > 1) {
    return `${((100 - 50) / (allEventsNumber - 1)) * index}%`;
  }
  return '0px';
};
