import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  ANNUL_EDITION_CHECK_DATA_REQUEST,
  CREATE_EDITION_REQUEST,
  EDITION_CHECK_DATA_VIRS_PDF_REQUEST,
  FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST,
  FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST,
  FETCH_EDITION_CHECK_DATA_REQUEST,
  FETCH_EDITION_CHECK_DATA_VIRS_REQUEST,
  FETCH_PUBLICATION_OUTLETS_REQUEST,
  REMOVE_EDITION_REQUEST,
  UPDATE_EDITION_REQUEST
} from './editionChecksActionTypes';
import {
  annulEditionCheck,
  annulEditionCheckError,
  annulEditionCheckSuccess,
  createEditionCheck,
  createEditionCheckError,
  createEditionCheckSuccess,
  fetchEditionCheckByDockIdRequest,
  fetchEditionCheckData,
  fetchEditionCheckDataByVirId,
  fetchEditionCheckDataByVirIdError,
  fetchEditionCheckDataByVirIdSuccess,
  fetchEditionCheckDataError,
  fetchEditionCheckDataSuccess,
  fetchEditionCheckDataVirs,
  fetchEditionCheckDataVirsError,
  fetchEditionCheckDataVirsSuccess,
  fetchPublicationOutlets,
  fetchPublicationOutletsError,
  fetchPublicationOutletsSuccess,
  getEditionCheckDataVirsDocRequest,
  removeEditionCheck,
  removeEditionCheckError,
  removeEditionCheckSuccess,
  resetAnnulEditionCheckState,
  resetEditionCheckRemovingState,
  resetEditionCheckUpdatingState,
  updateEditionCheck,
  updateEditionCheckError,
  updateEditionCheckSuccess
} from './editionChecksActions';
import {
  annulEditionCheckRecord,
  deleteRemoveEditionCheck,
  getEditionCheckByDockIdRequest,
  getEditionCheckDataByVirs,
  getEditionCheckDataPage,
  getEditionCheckDataVirsisDocument,
  getPublicationOutlets,
  getVirsEditionChecks,
  postCreateEditionCheck,
  putUpdateEditionCheck
} from './editionChecksApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  getEditionCheckDataPageRequest,
  selectEditionCheckTableDataState
} from '../editionChecksTable/editionCheckTableUtilities';
import { updateTempEditionCheckDataTableState } from '../editionChecksTable/editionChecksTableActions';
import { EditionCheckDataTableState } from '../editionChecksTable/editionChecksTableReducer';

function* handleEditionCheckDataFetchSaga() {
  try {
    const { tableDataState }: EditionCheckDataTableState = yield select(
      selectEditionCheckTableDataState
    );
    yield put(updateTempEditionCheckDataTableState(tableDataState));
    const { data } = yield call(
      getEditionCheckDataPage,
      getEditionCheckDataPageRequest(tableDataState)
    );
    yield put(fetchEditionCheckDataSuccess(data));
  } catch (err) {
    yield put(fetchEditionCheckDataError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleEditionCheckDataFetchByVirIdSaga(
  action: ActionType<typeof fetchEditionCheckDataByVirId>
) {
  try {
    const { data } = yield call(getEditionCheckDataByVirs, action.payload);
    yield put(fetchEditionCheckDataByVirIdSuccess(data));
  } catch (err) {
    yield put(fetchEditionCheckDataByVirIdError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleCreateEditionCheckSaga(action: ActionType<typeof createEditionCheck>) {
  try {
    const { newEditionCheck } = action.payload;
    const { data }: { data: boolean } = yield call(postCreateEditionCheck, newEditionCheck);

    yield put(fetchEditionCheckData());
    yield put(createEditionCheckSuccess(data));
    yield put(sendMessage('success', 'Tiražo patikrinimo įrašas išsaugotas'));
  } catch (err) {
    yield put(createEditionCheckError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleUpdateEditionCheckSaga(action: ActionType<typeof updateEditionCheck>) {
  try {
    const { updatedEditionCheck, id } = action.payload;
    const { data }: { data: boolean } = yield call(putUpdateEditionCheck, updatedEditionCheck, id);

    yield put(fetchEditionCheckData());
    yield put(updateEditionCheckSuccess(data));
    yield put(resetEditionCheckUpdatingState());
    yield put(sendMessage('success', 'Tiražo patikrinimo įrašas atnaujintas'));
  } catch (err) {
    yield put(updateEditionCheckError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleRemoveEditionCheckSaga(action: ActionType<typeof removeEditionCheck>) {
  try {
    const { editionCheckId } = action.payload;
    yield call(deleteRemoveEditionCheck, editionCheckId);
    yield put(removeEditionCheckSuccess());
    yield put(fetchEditionCheckData());
    yield put(resetEditionCheckRemovingState());
    yield put(sendMessage('success', 'Tiražo patikrinimo įrašas pašalintas'));
  } catch (err) {
    yield put(removeEditionCheckError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleEditionCheckDataVirsFetchSaga(
  action: ActionType<typeof fetchEditionCheckDataVirs>
) {
  try {
    const virsId = action.payload;
    const { data } = yield call(getVirsEditionChecks, virsId);
    yield put(fetchEditionCheckDataVirsSuccess(data));
  } catch (err) {
    yield put(fetchEditionCheckDataVirsError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleEditionCheckVirsDocumentRequestSaga(
  action: ActionType<typeof getEditionCheckDataVirsDocRequest>
) {
  try {
    const docId = action.payload;

    const { data } = yield call(getEditionCheckDataVirsisDocument, docId);
    const ie = window.navigator && window.navigator.msSaveOrOpenBlob;
    if (ie && data) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]));
    } else if (!ie && data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  } catch (err) {
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

export function* handleAnnulEditionCheckSaga(action: ActionType<typeof annulEditionCheck>) {
  try {
    const { documentStatusId, annulRecord } = action.payload;
    yield call(annulEditionCheckRecord, documentStatusId, annulRecord);
    yield put(annulEditionCheckSuccess());
    yield put(fetchEditionCheckData());
    yield put(resetAnnulEditionCheckState());
    yield put(sendMessage('success', 'Tiražo patikrinimo įrašas anuliuotas'));
  } catch (err) {
    yield put(annulEditionCheckError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleFetchPublicationOutletsSaga(action: ActionType<typeof fetchPublicationOutlets>) {
  try {
    const { virs, id } = action.payload;
    const { data } = yield call(getPublicationOutlets, virs.virId);
    yield put(fetchPublicationOutletsSuccess(data, virs, id));
  } catch (err) {
    yield put(fetchPublicationOutletsError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* handleFetchByDocId(action: ActionType<typeof fetchEditionCheckByDockIdRequest>) {
  try {
    const { data } = yield call(getEditionCheckByDockIdRequest, action.payload);
    yield put(fetchEditionCheckDataSuccess(data));
  } catch (err) {
    yield put(fetchEditionCheckDataError(err.response));
    yield put(sendMessage('error', err.response?.data?.message));
  }
}

function* editionChecksSaga() {
  yield takeLatest(FETCH_EDITION_CHECK_DATA_REQUEST, handleEditionCheckDataFetchSaga);
  yield takeEvery(CREATE_EDITION_REQUEST, handleCreateEditionCheckSaga);
  yield takeEvery(UPDATE_EDITION_REQUEST, handleUpdateEditionCheckSaga);
  yield takeEvery(REMOVE_EDITION_REQUEST, handleRemoveEditionCheckSaga);
  yield takeEvery(
    FETCH_EDITION_CHECK_DATA_BY_VIRID_REQUEST,
    handleEditionCheckDataFetchByVirIdSaga
  );
  yield takeEvery(FETCH_EDITION_CHECK_DATA_VIRS_REQUEST, handleEditionCheckDataVirsFetchSaga);
  yield takeEvery(EDITION_CHECK_DATA_VIRS_PDF_REQUEST, handleEditionCheckVirsDocumentRequestSaga);
  yield takeEvery(ANNUL_EDITION_CHECK_DATA_REQUEST, handleAnnulEditionCheckSaga);
  yield takeEvery(FETCH_PUBLICATION_OUTLETS_REQUEST, handleFetchPublicationOutletsSaga);
  yield takeEvery(FETCH_EDITION_CHECK_DATA_BY_DOC_ID_REQUEST, handleFetchByDocId);
}

export default editionChecksSaga;
