export interface EthicalNonComplianceType {
  ethicalNonComplianceId: number;
  ethicalNonComplianceTypeName: string;
  ethicalNonComplianceTypeNameEn: string;
  validFrom: string;
  validTo: string;
}

export interface EthicalNonComplianceTypeInput {
  ethicalNonComplianceId: number | null;
  ethicalNonComplianceTypeName: string | null;
  ethicalNonComplianceTypeNameEn: string | null;
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: EthicalNonComplianceTypeInput = {
  ethicalNonComplianceId: null,
  ethicalNonComplianceTypeName: '',
  ethicalNonComplianceTypeNameEn: '',
  validFrom: null,
  validTo: null
};
