import React, { createContext, useContext } from 'react';
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';

import { DocumentValidationError } from '../../store/document/documentTypes';

import virsisTheme from '../../style/virsisTheme';
import { ErrorItem } from './PreviewErrorItem';
import { ErrorDescriptor, getLinkedError, mapGroupedErrors } from './documentErrorMapping';
import { getOutletTabPath } from '../OutletInfoTables/tablesState/tablesTypesAndActions';

interface Props {
  errors: DocumentValidationError[];
  warnings: DocumentValidationError[];
  onErrorLinkClicked?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

function getOutletTabPathByCode(code: string): string {
  switch (code) {
    case 'VIRS-066':
      return getOutletTabPath('representatives');
    case 'VIRS-067':
      return getOutletTabPath('categories');
    case 'VIRS-200':
      return getOutletTabPath('editions');
    case 'VIRS-208':
      return getOutletTabPath('adprinting');
    default:
      return getOutletTabPath('all');
  }
}

export const errorDescriptorsInst = (selectedVirsId: number): ErrorDescriptor[] => {
  const basePath = `/virs-duomenys/${selectedVirsId}/duomenu-perziura-ir-teikimas`;
  return [
    {
      key: 'vip_id',
      label: 'VIP',
      getLinkByAttributeValue: (value: string, error?: DocumentValidationError) => {
        if (!error) {
          return `${basePath}/visuomenes-informavimo-priemones/${value}/${getOutletTabPath('all')}`;
        }
        return `${basePath}/visuomenes-informavimo-priemones/${value}/${getOutletTabPathByCode(
          error.code
        )}`;
      }
    },
    {
      key: 'vip',
      label: 'VIP',
      getLinkByAttributeValue: () => `${basePath}/visuomenes-informavimo-priemones`
    },
    {
      key: 'category',
      label: 'VIP',
      getLinkByAttributeValue: (value: string) =>
        `${basePath}/visuomenes-informavimo-priemones/${value}/${getOutletTabPath('categories')}`
    },
    {
      key: 'representative',
      label: 'VIP',
      getLinkByAttributeValue: (value: string) =>
        `${basePath}/visuomenes-informavimo-priemones/${value}/${getOutletTabPath(
          'representatives'
        )}`
    },
    {
      key: 'edition',
      label: 'VIP',
      getLinkByAttributeValue: (value: string) =>
        `${basePath}/visuomenes-informavimo-priemones/${value}/${getOutletTabPath('editions')}`
    },
    {
      key: 'adPrinting',
      label: 'VIP',
      getLinkByAttributeValue: (value: string) =>
        `${basePath}/visuomenes-informavimo-priemones/${value}/${getOutletTabPath('adprinting')}`
    },
    {
      key: 'dal_data',
      label: 'Dalyviai',
      getLinkByAttributeValue: () => `${basePath}/dalyviai`
    },
    {
      key: 'dal_id',
      label: 'Dalyviai',
      getLinkByAttributeValue: () => `${basePath}/dalyviai`
    },
    {
      key: 'das_id_tevo',
      label: 'Dalyviai',
      getLinkByAttributeValue: () => `${basePath}/dalyviai`
    },
    {
      key: 'vvr_id',
      label: 'Veiklos rūšys',
      getLinkByAttributeValue: () => `${basePath}/veiklos-rusys`
    }
  ];
};

export const ErrorDescriptorsContextDefault = createContext<ErrorDescriptor[]>([
  {
    key: 'vip_id',
    label: 'VIP',
    getLinkByAttributeValue: (value: string, error?: DocumentValidationError) => {
      if (!error) {
        return `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${value}/${getOutletTabPath(
          'all'
        )}`;
      }
      return `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${value}/${getOutletTabPathByCode(
        error.code
      )}`;
    }
  },
  {
    key: 'vip',
    label: 'VIP',
    getLinkByAttributeValue: () => '/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones'
  },
  {
    key: 'category',
    label: 'VIP',
    getLinkByAttributeValue: (value: string) =>
      `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${value}`
  },
  {
    key: 'representative',
    label: 'VIP',
    getLinkByAttributeValue: (value: string) =>
      `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${value}/${getOutletTabPath(
        'representatives'
      )}`
  },
  {
    key: 'edition',
    label: 'VIP',
    getLinkByAttributeValue: (value: string) =>
      `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${value}/${getOutletTabPath(
        'editions'
      )}`
  },
  {
    key: 'adPrinting',
    label: 'VIP',
    getLinkByAttributeValue: (value: string) =>
      `/duomenu-perziura-ir-teikimas/visuomenes-informavimo-priemones/${value}/${getOutletTabPath(
        'adprinting'
      )}`
  },
  {
    key: 'vvr_id',
    label: 'Veiklos rūšys',
    getLinkByAttributeValue: () => '/duomenu-perziura-ir-teikimas/veiklos-rusys'
  },
  {
    key: 'dal_id',
    label: 'Dalyviai',
    getLinkByAttributeValue: () => '/duomenu-perziura-ir-teikimas/dalyviai'
  },
  {
    key: 'das_id_tevo',
    label: 'Dalyviai',
    getLinkByAttributeValue: () => 'virs-duomenys/duomenu-perziura-ir-teikimas/dalyviai'
  }
]);

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      height: '500px',
      width: '300px'
    },
    wrapperBox: {
      paddingTop: 32,
      paddingLeft: 32
    },
    errorBox: {
      paddingLeft: 32,
      paddingTop: 8
    },
    [theme.breakpoints.down('xs')]: {
      wrapper: {
        height: 'auto',
        width: 'auto'
      },
      wrapperBox: {
        padding: 0
      },
      errorBox: {
        paddingLeft: 0
      }
    }
  })
);

export const PreviewErrorsPanel: React.FC<Props> = ({ errors, warnings, onErrorLinkClicked }) => {
  const errorDescriptors = useContext(ErrorDescriptorsContextDefault);
  const classes = useStyles();

  const { mainErrors, mainWarnings, shareholderGroupErrors, shareholderGroupWarnings } =
    mapGroupedErrors(errors, warnings, errorDescriptors);

  return (
    <div
      style={{
        backgroundColor: virsisTheme.palette.secondary.light
      }}
      className={classes.wrapper}
    >
      <Box className={classes.wrapperBox}>
        <Typography variant="h3">
          <b>Dokumento klaidos</b>
        </Typography>
        {mainErrors &&
          mainErrors.length === 0 &&
          shareholderGroupErrors &&
          shareholderGroupErrors.length === 0 &&
          warnings &&
          warnings.length > 0 && (
            <Typography
              variant="h6"
              style={{
                fontStyle: 'italic'
              }}
            >
              Pastaba! Pasirašius šiuos duomenis, juos pakeisti galėsite tik anuliavus šio dokumento
              pasirašymą.
            </Typography>
          )}
      </Box>

      {errors && errors.length > 0 && (
        <Box className={classes.errorBox}>
          <Typography variant="h5">
            <b>Kritinės klaidos:</b>
          </Typography>

          {mainErrors.map((error) => {
            return (
              <ErrorItem
                key={`${error}${Math.random()}`}
                error={getLinkedError(error, errorDescriptors)}
                onErrorLinkClicked={onErrorLinkClicked}
              />
            );
          })}

          {shareholderGroupErrors.map((error) => {
            return (
              <ErrorItem
                key={`${error.text}${error.count}`}
                error={error}
                onErrorLinkClicked={onErrorLinkClicked}
              />
            );
          })}
        </Box>
      )}

      {warnings && warnings.length > 0 && (
        <Box pl={4} pt={4}>
          <Typography variant="h5">
            <b>Nekritinės klaidos:</b>
          </Typography>

          {mainWarnings.map((warning) => (
            <ErrorItem
              key={`${warning}${Math.random()}`}
              error={getLinkedError(warning, errorDescriptors)}
              onErrorLinkClicked={onErrorLinkClicked}
            />
          ))}

          {shareholderGroupWarnings.map((warning) => {
            return (
              <ErrorItem
                key={`${warning.text}${warning.count}`}
                error={warning}
                onErrorLinkClicked={onErrorLinkClicked}
              />
            );
          })}
        </Box>
      )}
    </div>
  );
};
