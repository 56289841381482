import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  setDocumentNumberAndValidate,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../utils/InputValueFunctions';
import {
  initialMisconductNewRowState,
  MisconductNewRowAction,
  MisconductNewRowState
} from './misconductInitialStateAndTypes';
import {
  validateNewMisconductRecordState,
  addOutletField,
  setAndValidateOutletAndUpdateOthersOptions,
  setAndValidateMisconductAndUpdateOthersOptions,
  setAndValidateSanctionAndUpdateOthersOptions,
  removeOutletAndUpdateOthersOptions,
  removeMisconductAndUpdateOthersOptions,
  removeSanctionAndUpdateOthersOptions,
  addMisconductField,
  addSanctionField,
  setMisconductVirsAndUpdateOptions,
  setDynamicValidationConstraints
} from './misconductRowReducerFunctions';

export const newMisconductReducer = (
  state: MisconductNewRowState,
  action: MisconductNewRowAction
): MisconductNewRowState => {
  switch (action.type) {
    case 'NEW_RECORD_STATE_INITIALIZED':
      return setDynamicValidationConstraints({
        ...state,
        showFindVirsDialog: true,
        misconductTypes: action.misconductTypes,
        sanctionTypes: action.sanctionTypes,
        fictitiousOutlet: action.fictitiousOutlet[0],
        decisionDate: {
          ...state.decisionDate,
          minDates: [MINIMAL_RECORD_DATE_DEFAULT],
          maxDates: [
            {
              id: 'validFrom',
              date: null,
              text: 'Data negali būti vėlesnė už įrašo galiojimo pradžios datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validFrom: {
          ...state.validFrom,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'decisionDate',
              date: null,
              text: 'Data negali būti ankstesnė už sprendimo datą'
            }
          ],
          maxDates: [
            {
              id: 'validTo',
              date: null,
              text: 'Data negali būti vėlesnė už įrašo galiojimo pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'validFrom',
              date: null,
              text: 'Data negali būti ankstesnė už įrašo galiojimo pradžios datą'
            }
          ],
          maxDates: []
        }
      });
    case 'VIRS_INPUT_CLICKED':
      return {
        ...state,
        showFindVirsDialog: true
      };
    case 'CONTINUE_WITH_SELECTED_VIRS_CLICKED':
      return setDynamicValidationConstraints({
        ...state,
        ...setMisconductVirsAndUpdateOptions(state, action.virs)
      });
    case 'FIND_VIRS_DIALOG_CLOSED':
      return {
        ...state,
        showFindVirsDialog: false
      };
    case 'DECISION_DATE_CHANGED':
      return {
        ...state,
        decisionDate: updateDateValue(action.decisionDate, state.decisionDate),
        validFrom: updateMinDates(action.decisionDate, 'decisionDate', state.validFrom)
      };
    case 'DOCUMENT_NUMBER_CHANGED':
      return {
        ...state,
        documentNumber: setDocumentNumberAndValidate(action.documentNumber, state.documentNumber)
      };
    case 'OUTLET_FIELD_ADDED':
      return setDynamicValidationConstraints({
        ...state,
        ...addOutletField(state)
      });
    case 'OUTLET_CHANGED':
      return setDynamicValidationConstraints({
        ...state,
        ...setAndValidateOutletAndUpdateOthersOptions(state, action.outlet, action.outletIndex)
      });
    case 'OUTLET_FIELD_REMOVED':
      return setDynamicValidationConstraints({
        ...state,
        ...removeOutletAndUpdateOthersOptions(state, action.outletIndex)
      });
    case 'MISCONDUCT_FIELD_ADDED':
      return { ...state, ...addMisconductField(state, action.outletIndex) };
    case 'MISCONDUCT_CHANGED':
      return {
        ...state,
        ...setAndValidateMisconductAndUpdateOthersOptions(
          state,
          action.misconduct,
          action.outletIndex,
          action.misconductIndex
        )
      };
    case 'MISCONDUCT_FIELD_REMOVED':
      return {
        ...state,
        ...removeMisconductAndUpdateOthersOptions(state, action.outletIndex, action.misconductIndex)
      };
    case 'SANCTION_FIELD_ADDED':
      return {
        ...state,
        ...addSanctionField(state, action.outletIndex, action.misconductIndex)
      };
    case 'SANCTION_CHANGED':
      return {
        ...state,
        ...setAndValidateSanctionAndUpdateOthersOptions(
          state,
          action.sanction,
          action.outletIndex,
          action.misconductIndex,
          action.sanctionIndex
        )
      };
    case 'SANCTION_FIELD_REMOVED':
      return {
        ...state,
        ...removeSanctionAndUpdateOthersOptions(
          state,
          action.outletIndex,
          action.misconductIndex,
          action.sanctionIndex
        )
      };
    case 'VALID_FROM_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        decisionDate: updateMaxDates(action.validFrom, 'validFrom', state.decisionDate),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'VALID_TO_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'CREATE_RECORD_CLICKED':
      return validateNewMisconductRecordState(state);
    case 'CREATE_RECORD_CONFIRMATION_CANCELLED':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_SUCCESS':
      return initialMisconductNewRowState;
    case 'CREATE_RECORD_CONFIRMATION_CLOSED_ON_ERROR':
      return {
        ...state,
        createRecordConfirmationOn: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
