import React, { ChangeEvent, useRef } from 'react';
import { makeStyles, IconButton, FormHelperText, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../../style/virsisTheme';
import GoInputDataButton from '../../TableButtons/GoInputDataButton';
import { VirsisFile } from '../../../utils/tableTypes';

const useStyles = makeStyles({
  linkLikeButton: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    textTransform: 'none'
  },
  linkLikeRemoveButton: {
    color: 'red',
    textDecoration: 'underline',
    textTransform: 'none'
  }
});

interface Props {
  file: VirsisFile;
  changeFile: (file: File) => void;
  removeFile: (id: number) => void;
  displayName: string;
  sizeLimit: number;
}

export const FileUnit: React.FC<Props> = ({
  file,
  removeFile,
  changeFile,
  displayName,
  sizeLimit
}) => {
  const classes = useStyles();

  const inputRef = useRef<any>(null);

  const changeFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      changeFile(event.currentTarget.files[0] as File);
    }
  };

  const removeFileHandler = (event: any) => {
    removeFile(file.id);
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <input
          type="file"
          style={{ visibility: 'hidden', display: 'none' }}
          placeholder="Prisegti dokumentą"
          ref={inputRef}
          onChange={changeFileHandler}
          accept=".pdf"
        />
        <GoInputDataButton
          text={displayName && displayName !== '' ? displayName : file.title}
          onClicked={() => inputRef.current?.click()}
          style={{
            label: {
              maxWidth: '100px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }
          }}
        />
        <FormHelperText error={file.size >= sizeLimit}>
          {file.size >= sizeLimit ? 'Maksimalus dydis 5MB' : undefined}
        </FormHelperText>
      </Grid>
      <Grid item>
        <IconButton
          id="remove"
          className={classes.linkLikeRemoveButton}
          onClick={removeFileHandler}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
