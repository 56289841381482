import React, { ReactNode } from 'react';
import {
  Grid,
  TableRow,
  Button,
  TableCell,
  makeStyles,
  createStyles,
  Theme,
  Typography
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import { useSelector } from 'react-redux';
import { EnterpriseDataWithOutlets, OutletData } from '../../../store/outlets/outletsTypes';
import { OutletRowContainerNew } from './RowContainerNew';
import AllowedTo from '../../AllowedTo';
import { AddButtonLight } from '../../../components/TableButtons/AddButtonLight';
import { CustomTextButton } from '../../../components/TableButtons/CustomTextButton';
import { Roles } from '../../../store/virsis/dataTypes';
import OutletRowContainerRenewable from './RowContainerRenewable';
import OutletRowContainerSemiEditable from './RowContainerSemiEditable';
import OutletRowContainerFullyEditable from './RowContainerFullyEditable';
import { RowActionButtonGeneric } from '../../../components/TableButtons/RowActionButtons/RowActionButtonGeneric';
import { useOutletsTableDispatch, useOutletsTableState } from '../Context';
import { ApplicationState } from '../../../store';
import { determineRecordStatus } from '../../../utils/tableDataFunctions';
import { OutletRedirectFunctions } from '../Table';
import { OutletBasicRow } from './RowBasic';
import { OutletBasicRowExtension } from './RowBasicExtension';
import { getRedirectButtonLabelsByRoles } from './rowState/outletRowReducerFunctions';
import { RelevancyStatus } from '../../../utils/tableTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowLabelButton: { margin: 0 },
    cellActive: {
      backgroundColor: theme.palette.info.dark,
      color: '#fff',
      padding: '5px 9px'
    },
    cellNotActive: {
      backgroundColor: '#949494',
      color: '#fff',
      padding: '5px 9px'
    },
    outletLength: {
      color: '#fff',
      paddingLeft: 14
    },
    rowLabel: {
      color: '#fff',
      margin: '0 20px'
    },
    rowDate: {
      color: '#fff'
    },
    sortIcon: {
      color: '#fff',
      transform: 'rotateX(180deg)'
    },
    expandIcon: {
      color: '#fff',
      borderRight: '1px solid #fff',
      paddingRight: 8
    }
  })
);

interface GroupRowProps {
  groupingEnterprise: EnterpriseDataWithOutlets;
  toggleEnterpriseRow: () => void;
  isExtended: boolean;
  outletRedirectFunctions: OutletRedirectFunctions;
  returnToTable: () => void;
  virsRegistrationDate: string;
  virsDeregistrationDate: string | null;
  openNewRecordInputRowInEnterprise: () => void;
}

export const OutletEnterpriseRow: React.FC<GroupRowProps> = ({
  groupingEnterprise,
  toggleEnterpriseRow,
  isExtended,
  returnToTable,
  outletRedirectFunctions: {
    redirectToOutletInfoPage,
    redirectToOutletRepresentativesPage,
    redirectToOutletEditionsPage,
    redirectToOutletAdPrintingPage,
    redirectToOutletCategoriesPage
  },
  virsRegistrationDate,
  virsDeregistrationDate,
  openNewRecordInputRowInEnterprise
}) => {
  const classes = useStyles();

  const { currentUser } = useSelector((state: ApplicationState) => state.virsis);

  const { state: tableState } = useOutletsTableState();
  const { dispatch: tableDispatch } = useOutletsTableDispatch();

  const {
    extendedOutlets,
    showNewRecordInput,
    showGroupOfSingleEnterprise,
    columnsDisplayStatus,
    showStatusNotSigned
  } = tableState;

  const toggleOutletRowExtension = (virOutletControlId: number) => {
    return () => tableDispatch({ type: 'OUTLET_EXTENSION_CLICKED', virOutletControlId });
  };

  function resetColumnDisplay() {
    tableDispatch({ type: 'RESET_COLUMN_DISPLAY' });
  }
  const redirectButtonLabelByRoles = (relevancyStatus: RelevancyStatus) =>
    getRedirectButtonLabelsByRoles(currentUser, relevancyStatus);

  const showAddOutletButton = groupingEnterprise.active && !showNewRecordInput;

  const showNewOutletRow = groupingEnterprise.active && showNewRecordInput;

  const redirectToOutletInfo = (outletId: number) => () => redirectToOutletInfoPage(outletId);

  const redirectToEditions = (outletId: number) => () => redirectToOutletEditionsPage(outletId);

  function getRowExtensionComponent(
    record: OutletData,
    recordStatus: string | undefined
  ): ReactNode {
    return (
      <OutletBasicRowExtension
        record={record}
        rowStatus={recordStatus}
        buttonLabel={redirectButtonLabelByRoles(record.relevancyStatus)}
        redirectToRepresentatives={() => redirectToOutletRepresentativesPage(record.outletId)}
        redirectToEditions={redirectToEditions(record.outletId)}
        redirectToAdPrinting={() => redirectToOutletAdPrintingPage(record.outletId)}
        redirectToCategories={() => redirectToOutletCategoriesPage(record.outletId)}
      />
    );
  }

  return (
    <>
      <TableRow key={groupingEnterprise.enterpriseId}>
        <TableCell
          colSpan={8}
          className={groupingEnterprise.active ? classes.cellActive : classes.cellNotActive}
        >
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid container item direction="row" justify="flex-start" alignItems="center" xs={9}>
              {groupingEnterprise.outlets.length > 0 && (
                <Button onClick={toggleEnterpriseRow} className={classes.rowLabelButton}>
                  {isExtended && <ExpandLessRoundedIcon className={classes.expandIcon} />}
                  {!isExtended && <ExpandMoreRoundedIcon className={classes.expandIcon} />}
                  <Typography variant="body2" className={classes.outletLength}>
                    {groupingEnterprise.outlets.length}
                  </Typography>
                  <SortRoundedIcon fontSize="small" className={classes.sortIcon} />
                  <Typography variant="body2" className={classes.rowLabel}>
                    {groupingEnterprise.enterpriseTypeName}
                  </Typography>
                  <Typography variant="body1" className={classes.rowDate}>{`${
                    groupingEnterprise.validFrom
                  } - ${
                    groupingEnterprise.validTo ? groupingEnterprise.validTo : ''
                  } `}</Typography>
                </Button>
              )}
              {groupingEnterprise.outlets.length === 0 && (
                <Button>
                  <Typography
                    variant="body2"
                    className={classes.outletLength}
                    style={{
                      paddingLeft: groupingEnterprise.outlets.length ? '0px' : '42px'
                    }}
                  >
                    {groupingEnterprise.outlets.length}
                  </Typography>
                  <SortRoundedIcon fontSize="small" className={classes.sortIcon} />
                  <Typography variant="body2" className={classes.rowLabel}>
                    {groupingEnterprise.enterpriseTypeName}
                  </Typography>
                  <Typography variant="body1" className={classes.rowDate}>{`${
                    groupingEnterprise.validFrom
                  } - ${
                    groupingEnterprise.validTo ? groupingEnterprise.validTo : ''
                  } `}</Typography>
                </Button>
              )}
            </Grid>
            <Grid container item direction="row" justify="flex-end" alignItems="center" xs={3}>
              <AllowedTo roles={[Roles.ROLE_VIRS_EDIT, Roles.ROLE_VIRS]}>
                {showAddOutletButton && (
                  <AddButtonLight onClick={openNewRecordInputRowInEnterprise} text="Pridėti VIP" />
                )}
                {showGroupOfSingleEnterprise && (
                  <CustomTextButton onClick={returnToTable} text="Grįžti į lentelę" />
                )}
              </AllowedTo>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {showNewOutletRow && (
        <OutletRowContainerNew
          enterprise={groupingEnterprise}
          virsLegalRegistrationDate={virsRegistrationDate}
          virsLegalDeregistrationDate={virsDeregistrationDate}
          showStatusNotSigned={tableState.showStatusNotSigned}
        />
      )}
      {isExtended &&
        groupingEnterprise.outlets.map((record) => {
          const recordStatus = determineRecordStatus(record);
          if (record.enterpriseActive && record.editableFully) {
            return (
              <OutletRowContainerFullyEditable
                enterprise={groupingEnterprise}
                record={record}
                key={record.virOutletControlId}
                isExtended={extendedOutlets.includes(record.virOutletControlId)}
                toggleRowExtension={toggleOutletRowExtension(record.virOutletControlId)}
                rowExtensionComponent={getRowExtensionComponent(record, recordStatus)}
                redirectToOutletInfo={redirectToOutletInfo(record.outletId)}
                redirectButtonLabel={redirectButtonLabelByRoles(record.relevancyStatus)}
                virsLegalRegistrationDate={virsRegistrationDate}
                virsLegalDeregistrationDate={virsDeregistrationDate}
                display={columnsDisplayStatus}
                resetColumnDisplay={resetColumnDisplay}
              />
            );
          }
          if (record.enterpriseActive && record.editableValidToOnly) {
            return (
              <OutletRowContainerSemiEditable
                enterprise={groupingEnterprise}
                record={record}
                key={record.virOutletControlId}
                virsLegalRegistrationDate={virsRegistrationDate}
                virsLegalDeregistrationDate={virsDeregistrationDate}
                toggleRowExtension={toggleOutletRowExtension(record.virOutletControlId)}
                rowExtensionComponent={getRowExtensionComponent(record, recordStatus)}
                isExtended={extendedOutlets.includes(record.virOutletControlId)}
                redirectToOutletInfo={redirectToOutletInfo(record.outletId)}
                redirectButtonLabel={redirectButtonLabelByRoles(record.relevancyStatus)}
                redirectToEditions={redirectToEditions(record.outletId)}
                display={columnsDisplayStatus}
                showStatusNotSigned={showStatusNotSigned}
                resetColumnDisplay={resetColumnDisplay}
              />
            );
          }
          if (record.enterpriseActive && !record.editableFully && !record.editableValidToOnly) {
            return (
              <OutletRowContainerRenewable
                enterprise={groupingEnterprise}
                record={record}
                key={record.virOutletControlId}
                toggleRowExtension={toggleOutletRowExtension(record.virOutletControlId)}
                rowExtensionComponent={getRowExtensionComponent(record, recordStatus)}
                isExtended={extendedOutlets.includes(record.virOutletControlId)}
                virsLegalRegistrationDate={virsRegistrationDate}
                virsLegalDeregistrationDate={virsDeregistrationDate}
                redirectToOutletInfo={redirectToOutletInfo(record.outletId)}
                redirectButtonLabel="Peržiūrėti"
                display={columnsDisplayStatus}
                showStatusNotSigned={showStatusNotSigned}
                resetColumnDisplay={resetColumnDisplay}
              />
            );
          }
          return (
            <OutletBasicRow
              key={record.virOutletControlId}
              record={record}
              rowStatus={recordStatus}
              toggleExtension={toggleOutletRowExtension(record.virOutletControlId)}
              rowExtensionComponent={getRowExtensionComponent(record, recordStatus)}
              isExtended={extendedOutlets.includes(record.virOutletControlId)}
              outletRowActions={
                <RowActionButtonGeneric
                  label="Peržiūrėti"
                  onButtonClicked={redirectToOutletInfo(record.outletId)}
                />
              }
              display={columnsDisplayStatus}
            />
          );
        })}
    </>
  );
};
