export interface OutletTypeInput {
  outletTypeId: number | null;
  outletTypeName: string | null;
  outletTypeNameEn: string | null;
  outletGroupId: number | null;
  usesLibis: boolean;
  requiresIsbn: boolean;
  requiresEditions: boolean;
  enterpriseTypeIds: { value: number; label: string }[];
  validFrom: Date | null;
  validTo: Date | null;
}

export const defaultValues: OutletTypeInput = {
  outletTypeId: null,
  outletTypeName: '',
  outletTypeNameEn: '',
  outletGroupId: null,
  usesLibis: false,
  requiresIsbn: false,
  requiresEditions: false,
  enterpriseTypeIds: [],
  validFrom: null,
  validTo: null
};

export interface OutletType {
  outletTypeId: number;
  outletTypeName: string;
  outletTypeNameEn: string;
  usesLibis: boolean;
  requiresIsbn: boolean;
  requiresEditions: boolean;
  outletGroup: { outletGroupId: number; outletGroupName: string };
  enterpriseTypes: { enterpriseTypeId: number; enterpriseTypeName: string }[];
  validFrom: string;
  validTo: string;
}

export interface OutletTypeOptions {
  outletGroups: { outletGroupId: number; outletGroupName: string }[];
  enterpriseTypes: { enterpriseTypeId: number; enterpriseTypeName: string }[];
}
