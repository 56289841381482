import moment from 'moment';
import {
  AdPrintingRowState,
  initialAdPrintingRowState,
  AdPrintingRowAction
} from './rowInitialStateAndTypes';
import {
  validateAdPrintingEditRecordState,
  validateAdPrintingNewRecordState
} from './rowReducerFunctions';
import {
  MAXIMAL_RECORD_DATE_TODAY,
  MINIMAL_RECORD_DATE_DEFAULT,
  updateDateValue,
  updateMaxDates,
  updateMinDates
} from '../../../../../utils/InputValueFunctions';

export const adPrintingRowReducer = (
  state: AdPrintingRowState,
  action: AdPrintingRowAction
): AdPrintingRowState => {
  switch (action.type) {
    case 'NEW_RECORD_INITIALIZED':
      return {
        ...initialAdPrintingRowState,
        validFrom: {
          ...state.validFrom,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.outletControlledFrom),
              text: 'Pradžios data negali būti ankstesnė už VIP valdymo pradžią'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.outletControlledTo !== null ? moment(action.outletControlledTo) : null,
              text: 'Pradžios data negali būti vėlesnė už VIP valdymo pabaigą'
            },
            {
              id: 'validTo',
              date: null,
              text: 'Pradžios data negali būti vėlesnė už pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.outletControlledFrom),
              text: 'Pradžios data negali būti ankstesnė už VIP valdymo pradžią'
            },
            {
              id: 'validFrom',
              date: null,
              text: 'Pabaigos data negali būti ankstesnė už pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.outletControlledTo !== null ? moment(action.outletControlledTo) : null,
              text: `Pabaigos data negali būti vėlesnė už VIP valdymo pabaigą ${action.outletControlledTo}`
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'AD_PRINTING_FROM_DATE_CHANGED':
      return {
        ...state,
        validFrom: updateDateValue(action.validFrom, state.validFrom),
        validTo: updateMinDates(action.validFrom, 'validFrom', state.validTo)
      };
    case 'AD_PRINTING_TO_DATE_CHANGED':
      return {
        ...state,
        validTo: updateDateValue(action.validTo, state.validTo),
        validFrom: updateMaxDates(action.validTo, 'validTo', state.validFrom)
      };
    case 'CREATE_RECORD_CLICKED':
      return validateAdPrintingNewRecordState(state);
    case 'EDITING_INITIALIZED':
      return {
        ...state,
        editingOn: true,
        validFrom: {
          ...state.validFrom,
          value: moment(action.record.validFrom),
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.outletControlledFrom),
              text: 'Pradžios data negali būti ankstesnė už VIP valdymo pradžią'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.outletControlledTo !== null ? moment(action.outletControlledTo) : null,
              text: 'Pradžios data negali būti vėlesnė už VIP valdymo pabaigą'
            },
            {
              id: 'validTo',
              date: action.record.validTo ? moment(action.record.validTo) : null,
              text: 'Pradžios data negali būti vėlesnė už pabaigos datą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        },
        validTo: {
          ...state.validTo,
          value: action.record.validTo ? moment(action.record.validTo) : null,
          minDates: [
            MINIMAL_RECORD_DATE_DEFAULT,
            {
              id: 'outletControlledFrom',
              date: moment(action.outletControlledFrom),
              text: 'Pabaigos data negali būti ankstesnė už VIP valdymo pradžią'
            },
            {
              id: 'validFrom',
              date: moment(action.record.validFrom),
              text: 'Pabaigos data negali būti ankstesnė už pradžios datą'
            }
          ],
          maxDates: [
            {
              id: 'outletControlledTo',
              date: action.outletControlledTo !== null ? moment(action.outletControlledTo) : null,
              text: 'Pabaigos data negali būti vėlesnė už VIP valdymo pabaigą'
            },
            MAXIMAL_RECORD_DATE_TODAY
          ]
        }
      };
    case 'EDITING_CANCELLED':
      return initialAdPrintingRowState;
    case 'UPDATE_RECORD_CLICKED':
      return validateAdPrintingEditRecordState(state);
    case 'REMOVE_RECORD_CLICKED':
      return {
        ...state,
        removeConfirmationOn: true
      };
    case 'REMOVE_RECORD_CANCELLED':
      return {
        ...state,
        removeConfirmationOn: false
      };
    default:
      return state;
  }
};
