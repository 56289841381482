import React from 'react';
import { Typography, TableSortLabel, TableHead, TableRow, TableCell } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import { ProvidedDataHistoryHeaderField } from './tableState/tableInitialStateAndTypes';
import { useProvidedDataHistoryTableState, useProvidedDataHistoryTableDispatch } from './Context';

const ProvidedDataHistoryTableHead: React.FC = () => {
  const { state: tableState } = useProvidedDataHistoryTableState();
  const { dispatch } = useProvidedDataHistoryTableDispatch();

  const handleSetSortByColumn = (column: ProvidedDataHistoryHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.dataType && (
          <TableCell
            rowSpan={2}
            key="dataType"
            sortDirection={tableState.sortBy === 'dataType' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('dataType')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'dataType' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'dataType' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Duomenų tipas</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.virsName && (
          <TableCell
            rowSpan={1}
            key="VIRS"
            sortDirection={tableState.sortBy === 'virsName' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('virsName')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'virsName' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'virsName' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIRS</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.outletList && (
          <TableCell
            rowSpan={1}
            key="VIP"
            sortDirection={tableState.sortBy === 'outletList' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('outletList')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'outletList' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'outletList' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">VIP</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.documentStatus && (
          <TableCell
            rowSpan={1}
            key="documentStatusObject"
            sortDirection={tableState.sortBy === 'documentStatus' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('documentStatus')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'documentStatus' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'documentStatus' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Būsena</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.lastEditedDate && (
          <TableCell
            rowSpan={1}
            key="lastEditedDate"
            sortDirection={tableState.sortBy === 'lastEditedDate' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('lastEditedDate')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'lastEditedDate' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'lastEditedDate' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Paskutinio įvedimo / redagavimo data</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.lastEditedBy && (
          <TableCell
            rowSpan={1}
            key="lastEditedBy"
            sortDirection={tableState.sortBy === 'lastEditedBy' ? tableState.order : false}
            className="TableCellSortable"
            onClick={() => handleSetSortByColumn('lastEditedBy')}
          >
            <TableSortLabel
              active
              direction={tableState.sortBy === 'lastEditedBy' ? tableState.order : 'asc'}
              IconComponent={tableState.sortBy === 'lastEditedBy' ? ActiveSortIcon : SortIcon}
            >
              <Typography variant="h4">Paskutinis įvedęs / redagavęs asmuo</Typography>
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell rowSpan={1}>
          <Typography variant="h4">El. dokumentas</Typography>
        </TableCell>
        <TableCell rowSpan={1} align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ProvidedDataHistoryTableHead;
