import { getWithQuery, post } from '../../utils/axiosApi';
import { NaturalPersonSearchQuery, NaturalForeignPersonRecord } from './personsTypes';

export const getFindNaturalPerson = (naturalPersonQuery: NaturalPersonSearchQuery) => {
  const { countryCode, naturalFirstname, naturalLastname, naturalPersonCode, personBirthdate } =
    naturalPersonQuery;

  return getWithQuery('/person-data/natural', {
    countryCode,
    naturalFirstname,
    naturalLastname,
    naturalPersonCode,
    personBirthdate
  });
};

export const getFindNaturalPersonForeign = (naturalPersonQuery: NaturalPersonSearchQuery) => {
  const { countryCode, naturalFirstname, naturalLastname, naturalPersonCode, personBirthdate } =
    naturalPersonQuery;

  return getWithQuery('/person-data/natural-foreign', {
    countryCode,
    naturalFirstname,
    naturalLastname,
    naturalPersonCode,
    personBirthdate
  });
};

export const postCreateNaturalForeignPerson = (
  naturalForeignPersonRecord: NaturalForeignPersonRecord
) => post('/person-data/natural-foreign-doc', naturalForeignPersonRecord);
