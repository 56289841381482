import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ParametersDataVirsTableWithContext } from './Context';
import { fetchVirsisParametersRequest } from '../../store/parameters/parametersActions';

export const ParametersPage: React.FC = () => {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(fetchVirsisParametersRequest());
  }, [reduxDispatch]);

  return <ParametersDataVirsTableWithContext />;
};
