import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { FormikProps } from 'formik';

import { Typography } from '@material-ui/core';

import { GenericDateField } from '../../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericDialogForm } from '../../../components/FormikFields/GenericFormikWrappers/GenericDialogForm';

import { resetForm, State, submitForm } from './store';
import { GenericTextField } from '../../../components/FormikFields/GenericFormikWrappers/GenericTextField';
import { defaultValues, AnnulmentTypeInput } from './types';
import {
  defaultDate,
  noUndefinedNumber
} from '../../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { dateFieldStyle } from '../styleOverrides';
import {
  GridFieldGroup,
  GridForm
} from '../../../components/FormikFields/GenericFormikWrappers/GridForm';

const FormContent: React.FC<FormikProps<AnnulmentTypeInput>> = (formikProps) => {
  const {
    values: { annulmentTypeId }
  } = formikProps;
  return (
    <GridForm style={{ padding: '1em 24px 2em 24px' }}>
      <GridFieldGroup label="Kodas" spacing="1em">
        <Typography variant="h4">{annulmentTypeId}</Typography>
      </GridFieldGroup>

      <GridFieldGroup label="Anuliavimo priežasties pavadinimas Lt" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="annulmentTypeName"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Anuliavimo priežasties pavadinimas En" spacing="1em">
        <GenericTextField
          label=""
          formikProps={formikProps}
          field="annulmentTypeNameEn"
          style={{ container: { width: '100%' } }}
        />
      </GridFieldGroup>

      <GridFieldGroup label="Galioja">
        <GenericDateField
          label="Nuo"
          field="validFrom"
          formikProps={formikProps}
          style={dateFieldStyle}
        />

        <GenericDateField
          label="Iki"
          field="validTo"
          formikProps={formikProps}
          style={dateFieldStyle}
        />
      </GridFieldGroup>
    </GridForm>
  );
};

export const AnnulmentTypeForm: React.FC = () => {
  const dispatch = useDispatch();

  const { open, initialValues, isLoading, error, data } = useSelector((state: State) => ({
    ...state.form,
    data: state.data
  }));

  const nameValidation = Yup.string()
    .nullable()
    .required('Būtina įvesti reikšmę')
    .max(200, 'Negali būti ilgesnis nei 200 simbolių');

  const validationSchema = Yup.object().shape({
    annulmentTypeId: noUndefinedNumber(),
    annulmentTypeName: nameValidation.notOneOf(
      data
        .map(({ annulmentTypeName }) => annulmentTypeName)
        .filter((value) => initialValues?.annulmentTypeName !== value),
      'Privalo būti unikalus'
    ),
    annulmentTypeNameEn: nameValidation.notOneOf(
      data
        .map(({ annulmentTypeName }) => annulmentTypeName)
        .filter((value) => initialValues?.annulmentTypeNameEn !== value),
      'Privalo būti unikalus'
    ),
    validFrom: defaultDate().required('Privaloma pasirinkti pradžios datą'),
    validTo: defaultDate().min(Yup.ref('validFrom'), 'Privalo buti vėlesnė nei Nuo data')
  });

  return (
    <GenericDialogForm
      initialValues={initialValues || defaultValues}
      onClose={() => dispatch(resetForm())}
      onExit={() => dispatch(resetForm())}
      onSubmit={(value) => dispatch(submitForm(value))}
      loading={isLoading}
      open={open}
      validationSchema={validationSchema}
      formContent={FormContent}
      error={error}
      fullWidth={false}
      maxWidth="xl"
      title="Anuliavimo priežasties įvedimas"
    />
  );
};
