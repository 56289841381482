import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { ErrorProps } from '../../store/shareholders/shareholdersTypes';

const useStyles = makeStyles({
  container: {
    padding: 2
  },
  errorGroup: {
    '&:not(:last-child)': {
      marginBottom: 10
    }
  },
  label: {
    fontSize: '0.75rem'
  }
});

interface Props {
  errors: ErrorProps[];
  index: number;
}

export const ErrorFormatted: React.FC<Props> = ({ errors, index }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {errors.map((error) => {
        return (
          <div key={error.errorGroup} className={classes.errorGroup}>
            <Typography variant="h5" className={classes.label}>
              {error.errorGroup}
            </Typography>
            {error.errorMessages.map((message) => (
              <div key={message.eventDate + '__' + index + message.errorText}>
                {message.errorText}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
