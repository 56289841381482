import { action } from 'typesafe-actions';
import {
  FETCH_OUTLET_DATA_REQUEST,
  FETCH_OUTLET_DATA_SUCCESS,
  FETCH_OUTLET_DATA_ERROR,
  CREATE_OUTLET_REQUEST,
  CREATE_OUTLET_SUCCESS,
  CREATE_OUTLET_ERROR,
  RESET_CREATE_OUTLET_STATE,
  RESET_UPDATE_OUTLET_STATE,
  UPDATE_OUTLET_ERROR,
  UPDATE_OUTLET_SUCCESS,
  UPDATE_OUTLET_REQUEST,
  REMOVE_OUTLET_REQUEST,
  REMOVE_OUTLET_SUCCESS,
  REMOVE_OUTLET_ERROR,
  RESET_REMOVE_OUTLET_STATE,
  RENEW_OUTLET_REQUEST,
  RENEW_OUTLET_SUCCESS,
  RENEW_OUTLET_ERROR,
  RESET_RENEW_OUTLET_STATE,
  REMOVE_OUTLETS_REQUEST,
  REMOVE_OUTLETS_SUCCESS,
  REMOVE_OUTLETS_ERROR,
  FIND_OUTLET_ON_LIBIS_REQUEST,
  FIND_OUTLET_ON_LIBIS_SUCCESS,
  RESET_FIND_OUTLET_ON_LIBIS_STATE,
  FIND_OUTLET_ON_LIBIS_ERROR
} from './outletsActionsTypes';
import { OutletRecord, OutletData, LibisSearchData } from './outletsTypes';

export const fetchOutletData = (virId: number) => action(FETCH_OUTLET_DATA_REQUEST, { virId });
export const fetchOutletDataSuccess = (data: string) => action(FETCH_OUTLET_DATA_SUCCESS, data);
export const fetchOutletDataError = (error: Error) => action(FETCH_OUTLET_DATA_ERROR, error);

export const createOutlet = (virId: number, record: OutletRecord, message: string) =>
  action(CREATE_OUTLET_REQUEST, { virId, record, message });
export const createOutletSuccess = (outlet: OutletData) => action(CREATE_OUTLET_SUCCESS, outlet);
export const createOutletError = (error: Error) => action(CREATE_OUTLET_ERROR, error);
export const resetOutletCreatingState = () => action(RESET_CREATE_OUTLET_STATE);

export const renewOutlet = (
  virId: number,
  virOutletControllId: number,
  record: OutletRecord,
  message: string
) =>
  action(RENEW_OUTLET_REQUEST, {
    virId,
    virOutletControllId,
    record,
    message
  });
export const renewOutletSuccess = (outlet: OutletData) => action(RENEW_OUTLET_SUCCESS, outlet);
export const renewOutletError = (error: Error) => action(RENEW_OUTLET_ERROR, error);
export const resetOutletRenewingState = () => action(RESET_RENEW_OUTLET_STATE);

export const updateOutlet = (
  virId: number | undefined,
  virOutletControllId: number,
  record: OutletRecord,
  message: string
) =>
  action(UPDATE_OUTLET_REQUEST, {
    virId,
    virOutletControllId,
    record,
    message
  });
export const updateOutletSuccess = () => action(UPDATE_OUTLET_SUCCESS);
export const updateOutletError = (error: Error) => action(UPDATE_OUTLET_ERROR, error);
export const resetOutletUpdatingState = () => action(RESET_UPDATE_OUTLET_STATE);

export const removeOutlet = (virId: number, virOutletControllId: number) =>
  action(REMOVE_OUTLET_REQUEST, { virId, virOutletControllId });
export const removeOutletSuccess = () => action(REMOVE_OUTLET_SUCCESS);
export const removeOutletError = (error: Error) => action(REMOVE_OUTLET_ERROR, error);
export const resetOutletRemovingState = () => action(RESET_REMOVE_OUTLET_STATE);

export const removeOutletsRequest = (outletIds: number[]) =>
  action(REMOVE_OUTLETS_REQUEST, outletIds);
export const removeOutletsSuccess = () => action(REMOVE_OUTLETS_SUCCESS);
export const removeOutletsError = (error: Error) => action(REMOVE_OUTLETS_ERROR, error);

export const findOutletOnLibis = (title?: string, internationalNumber?: string) =>
  action(FIND_OUTLET_ON_LIBIS_REQUEST, { title, internationalNumber });
export const findOutletOnLibisSuccess = (data: LibisSearchData[]) =>
  action(FIND_OUTLET_ON_LIBIS_SUCCESS, data);
export const findOutletOnLibisError = (error: Error) => action(FIND_OUTLET_ON_LIBIS_ERROR, error);
export const resetFindOutletOnLibisState = () => action(RESET_FIND_OUTLET_ON_LIBIS_STATE);
