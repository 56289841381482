import { get, getBlob, post, put, axiosDelete, getWithQuery } from '../../utils/axiosApi';
import { AnnulRecord } from '../classifiers/classifiersTypes';
import { PaymentsTablePageRequest } from '../paymentsTable/paymentsTableTypes';

export const getPaymentData = () => get('/payment-data');
export const getPaymentDataPage = (query: PaymentsTablePageRequest) =>
  getWithQuery('/payment-data', query);

export const getVirsPayments = (virsId: number) =>
  get(`/virs/${virsId}/institutions-data/yearly-payments/`);

export const getPaymentDocument = (paymentDocumentId: number) =>
  getBlob(`/payment-data/attachments/${paymentDocumentId}`);

export const postCreatePayment = (record: FormData) => {
  return post('/payment-data', record);
};

export const putUpdatePayment = (record: FormData, paymentId: number) => {
  return put(`/payment-data/${paymentId}`, record);
};

export const deleteRemovePayment = (paymentId: number) => {
  return axiosDelete(`/payment-data/${paymentId}`);
};

export const annulPaymentData = (documentStatusId: number, annulRecord: AnnulRecord) =>
  put(`/provided-data/inst/${documentStatusId}`, annulRecord);

export const putClosePayment = (paymentId: number, validTo: string | null) =>
  put(`/payment-data/close-record/${paymentId}`, { validTo });

export const getPaymentDataByDocId = (docId: number) => get(`/payment-data/inst/${docId}`);
