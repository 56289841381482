import React from 'react';
import { Typography, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Props {
  onFilterTabToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showFilter: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      border: '1px solid transparent',
      borderLeftColor: '#E5E5E5',
      borderRadius: 0,
      margin: 0,
      height: 48,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('xs')]: {
        border: 'none',
        flexGrow: 1,
        padding: '3px 4px'
      }
    },
    activeButton: {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    icon: {
      paddingLeft: 5,
      fontSize: 15,
      color: theme.palette.primary.main
    }
  })
);

const FilterButton: React.FC<Props> = ({ onFilterTabToggle, showFilter, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      value="filter"
      className={showFilter ? `${classes.button} ${classes.activeButton}` : `${classes.button}`}
      onClick={onFilterTabToggle}
      disabled={disabled}
    >
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        Filtravimas
      </Typography>
      <FilterListIcon className={classes.icon} />
    </Button>
  );
};
export default FilterButton;
