import * as Yup from 'yup';

export const validationFromClassifier = Yup.object().shape({
  sharePercentage: Yup.mixed().when(
    ['endOfParticipation', 'mayHaveSharePercentage', 'endOfParticipationFromBefore'],
    {
      is: (endOfParticipation, mayHaveSharePercentage, endOfParticipationFromBefore) =>
        mayHaveSharePercentage && !endOfParticipation && !endOfParticipationFromBefore,
      then: Yup.number()
        .typeError('Privaloma įvesti procentų dalį')
        .required('Privaloma įvesti procentų dalį')
        .min(0.01, 'Procento reikšmė gali būti nuo 0,01 iki 100')
        .max(100, 'Procento reikšmė gali būti nuo 0,01 iki 100')
    }
  ),
  votePercentage: Yup.mixed()
    .when(
      [
        'endOfParticipation',
        'mayHaveVotePercentage',
        'mayHaveVotesOnRules',
        'endOfParticipationFromBefore'
      ],
      {
        is: (
          endOfParticipation,
          mayHaveVotePercentage,
          mayHaveVotesOnRules,
          endOfParticipationFromBefore
        ) =>
          mayHaveVotePercentage &&
          !mayHaveVotesOnRules &&
          !endOfParticipation &&
          !endOfParticipationFromBefore,
        then: Yup.number()
          .typeError('Privaloma įvesti procentų dalį')
          .required('Privaloma įvesti procentų dalį')
          .min(0, 'Procento reikšmė gali būti nuo 0 iki 100')
          .max(100, 'Procento reikšmė gali būti nuo 0 iki 100')
      }
    )
    .when(
      [
        'endOfParticipation',
        'mayHaveVotePercentage',
        'mayHaveVotesOnRules',
        'votesValueSelected',
        'endOfParticipationFromBefore'
      ],
      {
        is: (
          endOfParticipation,
          mayHaveVotePercentage,
          mayHaveVotesOnRules,
          votesValueSelected,
          endOfParticipationFromBefore
        ) =>
          !endOfParticipation &&
          !endOfParticipationFromBefore &&
          mayHaveVotePercentage &&
          mayHaveVotesOnRules &&
          !votesValueSelected,
        then: Yup.number()
          .typeError('Privaloma įvesti procentų dalį arba pažymėti "Balsai pagal taisyklę"')
          .min(0, 'Procento reikšmė gali būti nuo 0 iki 100')
          .max(100, 'Procento reikšmė gali būti nuo 0 iki 100')
      }
    )
    .when(
      [
        'endOfParticipation',
        'mayHaveVotePercentage',
        'mayHaveVotesOnRules',
        'votesValueSelected',
        'votesOnRules'
      ],
      {
        is: (
          endOfParticipation,
          mayHaveVotePercentage,
          mayHaveVotesOnRules,
          votesValueSelected,
          votesOnRules,
          endOfParticipationFromBefore
        ) =>
          !endOfParticipation &&
          !endOfParticipationFromBefore &&
          mayHaveVotePercentage &&
          mayHaveVotesOnRules &&
          votesValueSelected &&
          !votesOnRules,
        then: Yup.number()
          .min(0, 'Procento reikšmė gali būti nuo 0 iki 100')
          .max(100, 'Procento reikšmė gali būti nuo 0 iki 100')
      }
    ),
  votesOnRules: Yup.mixed().when(
    [
      'endOfParticipation',
      'mayHaveVotesOnRules',
      'mayHaveVotePercentage',
      'votesValueSelected',
      'endOfParticipationFromBefore'
    ],
    {
      is: (
        endOfParticipation,
        mayHaveVotesOnRules,
        mayHaveVotePercentage,
        votesValueSelected,
        endOfParticipationFromBefore
      ) =>
        !endOfParticipation &&
        mayHaveVotesOnRules &&
        mayHaveVotePercentage &&
        !votesValueSelected &&
        !endOfParticipationFromBefore,
      then: Yup.bool().oneOf(
        [true],
        'Privaloma įvesti procentų dalį arba pažymėti "Balsai pagal taisyklę"'
      )
    }
  )
});
