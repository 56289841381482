import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import {
  EthicalNonComplianceHeaderField,
  EthicalNonComplianceTableState
} from './tableState/tableInitialStateAndTypes';
import { useEthicalNonComplianceTableState, useEthicalNonComplianceTableDispatch } from './Context';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../components/TableSortingHeaderCell/TableSortingHeaderCell';

const EthicalNonComplianceTableHead: React.FC = () => {
  const { state: tableState } = useEthicalNonComplianceTableState();
  const { dispatch } = useEthicalNonComplianceTableDispatch();

  const handleSetSortByColumn = (column: EthicalNonComplianceHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    EthicalNonComplianceTableState,
    typeof handleSetSortByColumn,
    EthicalNonComplianceHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.documentNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.decisionDate,
              tableState.columnsDisplayStatus.documentNumber
            ])}
          >
            <Typography variant="h4">Sprendimo</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.outletList && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Susijusios VIP"
            columnName="outletList"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.decisionStatus && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Būsena"
            columnName="decisionStatus"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">Galioja</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.reportingInstitution && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Pripažino"
            columnName="reportingInstitution"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        <TableCell rowSpan={2} className="column-of-buttons" align="right">
          <Typography variant="h4">Veiksmai</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Data"
            columnName="decisionDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.documentNumber && (
          <TableSortingHeaderCell
            rowSpan={1}
            label="Nr."
            columnName="documentNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Nuo"
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={2}
            label="Iki"
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default EthicalNonComplianceTableHead;
