import { Reducer } from 'redux';
import {
  FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_ERROR,
  FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
  FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_SUCCESS,
  SET_GRAPHICAL_REPRESENTATION_VERSION,
  FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
  FETCH_GRAPHICAL_REPRESENTATION_DATA_SUCCESS,
  FETCH_GRAPHICAL_REPRESENTATION_DATA_ERROR,
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST,
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_SUCCESS,
  CREATE_GRAPHICAL_REPRESENTATION_RECORD_ERROR,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_SUCCESS,
  PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_ERROR,
  FETCH_AVAILABLE_VIRS_ID_SUCCESS,
  FETCH_AVAILABLE_VIRS_ID_ERROR
} from './graphicalRepresentationToolsActionTypes';
import { GraphicalRecord, GraphicalVersion } from './graphicalRepresentationToolsTypes';

export interface GraphicalRepresentationState {
  selectedGraphicalVersionId?: number;
  showActiveRecords: boolean;
  graphicalRepresentationVersion?: GraphicalVersion[];
  graphicalRepresentationRecord?: GraphicalRecord[];
  loadingGraphicalRepresentationVersion: boolean;
  loadingGraphicalRepresentationRecord: boolean;
  loadingCreateGraphicalRepresentationRecord: boolean;
  createGraphicalRepresentationRecordError: boolean;
  graphicalRepresentationRecordCreated: boolean;
  loadingDeleteGraphicalRepresentationRecord: boolean;
  deleteGraphicalRepresentationRecordError: boolean;
  graphicalRepresentationRecordDeleted: boolean;
  loadingPublishGraphicalRepresentationRecord: boolean;
  publishGraphicalRepresentationRecordError: boolean;
  graphicalRepresentationRecordPublished: boolean;
  availableVirsError?: Error;
  selectedVirsId?: number;
}

const initialGraphicalRepresentationState: GraphicalRepresentationState = {
  createGraphicalRepresentationRecordError: false,
  deleteGraphicalRepresentationRecordError: false,
  publishGraphicalRepresentationRecordError: false,
  selectedGraphicalVersionId: undefined,
  showActiveRecords: false,
  graphicalRepresentationVersion: undefined,
  graphicalRepresentationRecord: undefined,
  loadingGraphicalRepresentationVersion: false,
  loadingGraphicalRepresentationRecord: false,
  loadingCreateGraphicalRepresentationRecord: false,
  graphicalRepresentationRecordCreated: false,
  loadingDeleteGraphicalRepresentationRecord: false,
  graphicalRepresentationRecordDeleted: false,
  loadingPublishGraphicalRepresentationRecord: false,
  graphicalRepresentationRecordPublished: false,
  availableVirsError: undefined,
  selectedVirsId: undefined
};

export const graphicalRepresentationToolsReducer: Reducer<GraphicalRepresentationState> = (
  state = initialGraphicalRepresentationState,
  action
) => {
  switch (action.type) {
    case SET_GRAPHICAL_REPRESENTATION_VERSION: {
      return {
        ...state,
        selectedGraphicalVersionId: action.payload.graphicalVersionId,
        showActiveRecords: action.payload.showActive
      };
    }

    case FETCH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST: {
      return {
        ...state,
        loadingGraphicalRepresentationVersion: true
      };
    }
    case FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_SUCCESS: {
      return {
        ...state,
        graphicalRepresentationVersion: action.payload,
        loadingGraphicalRepresentationVersion: false
      };
    }
    case FETCH_GRAPHICAL_REPRESENTATION_VERSIONS_ERROR: {
      return {
        ...state,
        loadingGraphicalRepresentationVersion: false
      };
    }

    case FETCH_GRAPHICAL_REPRESENTATION_DATA_REQUEST: {
      return {
        ...state,
        loadingGraphicalRepresentationRecord: true
      };
    }
    case FETCH_GRAPHICAL_REPRESENTATION_DATA_SUCCESS: {
      return {
        ...state,
        graphicalRepresentationRecord: action.payload,
        loadingGraphicalRepresentationRecord: false
      };
    }
    case FETCH_GRAPHICAL_REPRESENTATION_DATA_ERROR: {
      return {
        ...state,
        loadingGraphicalRepresentationRecord: false
      };
    }

    case CREATE_GRAPHICAL_REPRESENTATION_RECORD_REQUEST: {
      return {
        ...state,
        createGraphicalRepresentationRecordError: false,
        loadingCreateGraphicalRepresentationRecord: true,
        graphicalRepresentationRecordCreated: false
      };
    }
    case CREATE_GRAPHICAL_REPRESENTATION_RECORD_SUCCESS: {
      return {
        ...state,
        selectedGraphicalVersionId: action.payload,
        loadingCreateGraphicalRepresentationRecord: false,
        graphicalRepresentationRecordCreated: true
      };
    }
    case CREATE_GRAPHICAL_REPRESENTATION_RECORD_ERROR: {
      return {
        ...state,
        loadingCreateGraphicalRepresentationRecord: false,
        createGraphicalRepresentationRecordError: true
      };
    }

    case PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_REQUEST: {
      return {
        ...state,
        publishGraphicalRepresentationRecordError: false,
        loadingPublishGraphicalRepresentationRecord: true,
        graphicalRepresentationRecordPublished: false
      };
    }
    case PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_SUCCESS: {
      return {
        ...state,
        selectedGraphicalVersionId: action.payload,
        loadingPublishGraphicalRepresentationRecord: false,
        graphicalRepresentationRecordPublished: true
      };
    }
    case PUBLISH_GRAPHICAL_REPRESENTATION_VERSION_ERROR: {
      return {
        ...state,
        loadingPublishGraphicalRepresentationRecord: false,
        publishGraphicalRepresentationRecordError: true
      };
    }

    case FETCH_AVAILABLE_VIRS_ID_SUCCESS: {
      return {
        ...state,
        selectedVirsId: action.payload
      };
    }
    case FETCH_AVAILABLE_VIRS_ID_ERROR: {
      return {
        ...state,
        availableVirsError: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
