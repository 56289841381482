import React from 'react';
import { TableCell } from '@material-ui/core';
import { TableCustomFilterForOptionAutocomplete } from './TableCustomFilterForOptionAutocomplete';
import { HeaderType, WordStatus, WordStatusLabel } from '../../utils/tableTypes';
import { DateInputFilter } from '../TableInputs/DateInput';
import { TableCustomFilterForMultipleOptionAutocomplete } from './TableCustomFilterForMultipleOptionAutocomplete';
import { TableCustomYearFilter } from './TableCustomYearFilter';
import moment from 'moment';

export interface Option {
  label: string | WordStatusLabel;
  value: string | number | WordStatus;
}

interface Props {
  id: string;
  setFilter(column: string, value: string | string[] | number | null): void;
  uniqueOptions: Option[];
  type?: HeaderType;
}

export const TableCustomFilterForOptionCellGeneric: React.FC<Props> = ({
  id,
  setFilter,
  uniqueOptions,
  type
}) => (
  <TableCell
    align="center"
    component="th"
    scope="row"
    style={{
      borderColor: '#C4E2FC',
      borderTop: 'none',
      borderBottom: 'none'
    }}
  >
    {!type && (
      <TableCustomFilterForOptionAutocomplete
        name={id}
        uniqueOptions={uniqueOptions}
        setFilter={setFilter}
      />
    )}
    {type === 'date' && <DateInputFilter name={id} setFilter={setFilter} />}
    {type === 'nonFutureDate' && (
      <DateInputFilter
        name={id}
        setFilter={setFilter}
        customMaxDate={moment().format('YYYY-MM-DD')}
      />
    )}
    {type === 'year' && <TableCustomYearFilter name={id} setFilter={setFilter} />}
    {type === 'multiple' && (
      <TableCustomFilterForMultipleOptionAutocomplete
        name={id}
        uniqueOptions={uniqueOptions}
        setFilter={setFilter}
      />
    )}
  </TableCell>
);
