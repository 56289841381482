import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';

export interface AdDataTableState {
  sortBy: AdColumnHeaderField;
  order: SortOrder;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: AdColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: AdTableCustomFilter;
  showStatusNotSigned: boolean;
  showStatusRelevant: boolean;
  showStatusOutdated: boolean;
  statusNotSignedFilterEnabled: boolean;
  statusRelevantFilterEnabled: boolean;
  statusOutdatedFilterEnabled: boolean;
  showNewRecord: boolean;
}

export const initialOutletAdPrintingTableState: AdDataTableState = {
  order: 'desc',
  sortBy: 'validFrom',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    validFrom: true,
    validTo: true
  },
  customFilterOn: false,
  customFilter: {
    validFrom: [],
    validTo: []
  },
  showStatusNotSigned: false,
  showStatusRelevant: false,
  showStatusOutdated: true,
  statusNotSignedFilterEnabled: false,
  statusRelevantFilterEnabled: false,
  statusOutdatedFilterEnabled: false,
  showNewRecord: false
};

export type AdColumnHeaderField = 'validFrom' | 'validTo';

export type AdColumnsDisplayStatus = {
  validFrom: boolean;
  validTo: boolean;
};

export type AdTableCustomFilter = {
  validFrom: string[];
  validTo: string[];
};

export type AdColumnHeader = ColumnHeader<AdColumnHeaderField>;

export type AdTableAction =
  | {
      type: 'AD_PRINTING_CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: AdColumnHeaderField;
      value: string | null;
    }
  | { type: 'AD_PRINTING_SORTING_CHANGED'; sortBy: AdColumnHeaderField }
  | {
      type: 'AD_PRINTING_COLUMN_DISPLAY_TOGGLED';
      column: AdColumnHeaderField;
    };

export const adPrintingTablecolumns: AdColumnHeader[] = [
  {
    id: 'validFrom',
    label: 'Nuo',
    type: 'date'
  },
  {
    id: 'validTo',
    label: 'Iki',
    type: 'date'
  }
];
