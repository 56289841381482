import { getToggledSortOrderGeneric } from '../../../../utils/tableDataFunctions';
import { SortOrder } from '../../../../utils/tableTypes';
import { EnterpriseLicenceData } from '../../../../store/enterprises/enterprisesTypes';

export const enterpriseLicencesTableColumns: EnterpriseDataTableInnerTableColumn[] = [
  {
    id: 'licenceNumber',
    numeric: false,
    label: 'Licencijos Nr.'
  },
  {
    id: 'licenceTypeCode',
    numeric: false,
    label: 'Licencijos tipas'
  },
  {
    id: 'licenceTypeName',
    numeric: false,
    label: 'Licencijuojamos veiklos pavadinimas'
  },
  {
    id: 'issuingDate',
    numeric: false,
    label: 'Išdavimo data'
  },
  {
    id: 'licenceStatus',
    numeric: false,
    label: 'Būsena'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Galioja nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Galioja iki'
  },
  {
    id: 'issuer',
    numeric: false,
    label: 'Licenciją išdavė'
  },
  {
    id: 'licenceNotes',
    numeric: false,
    label: 'Licencijos pastaba/komentaras'
  }
];

export type EnterpriseDataTableInnerTableAction = {
  type: 'SORTING_CHANGED';
  sortBy: EnterpriseDataLicenceDataField;
};

export const initialInnerTableState: EnterpriseDataTableInnerTableState = {
  licences: [],
  order: 'desc',
  sortBy: 'licenceTypeCode'
};

export type EnterpriseDataLicenceDataField =
  | 'licenceNumber'
  | 'licenceTypeCode'
  | 'licenceTypeName'
  | 'issuingDate'
  | 'licenceStatus'
  | 'validFrom'
  | 'validTo'
  | 'issuer'
  | 'licenceNotes';

export interface EnterpriseDataTableInnerTableColumn {
  id: EnterpriseDataLicenceDataField;
  numeric: boolean;
  label: string;
}

export interface EnterpriseDataTableInnerTableState {
  licences: EnterpriseLicenceData[];
  order: SortOrder;
  sortBy: EnterpriseDataLicenceDataField;
}

export const innerTableReducer = (
  state: EnterpriseDataTableInnerTableState,
  action: EnterpriseDataTableInnerTableAction
): EnterpriseDataTableInnerTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    default:
      return state;
  }
};
